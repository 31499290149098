import React from 'react'
import ButtonGreen from "./controls3.0/button/ButtonGreen";

const NotFound = () => (
    <div className='modal-error-404-wrapper'>
        <div className='modal-error-404-block text-center font-weight-500'>
            <div className='text-error-404'>
                Error 404
            </div>
            <div className='text-error-404-descr error-404-descr-padding'>
                The link does not exist or has expired.
            </div>

            <div className='modal-error-404-button'>
                <ButtonGreen
                    TitleButton={'Go to the main page'}
                    EventOnClick={() => {window.location.href='/'}}
                    paddingButton={'29'}
                    disabled={false}
                />
            </div>
            <div className='modal-error-404-bg-person'/>
        </div>

        <div className='modal-error-404-bg'>

        </div>
    </div>
)

export default NotFound
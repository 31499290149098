import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect} from 'react-router-dom'

const GuestRoute = ({isAuthenticated, role, component: Component, ...rest}) => (
  <Route {...rest} render={props => <Component {...props}/>}/>
  // <Route {...rest} render={props => isAuthenticated ? <Redirect to="/profile"/> : <Component {...props}/>}/>
)

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated:PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return{
    isAuthenticated: state.auth.token
  }
}

export default connect(mapStateToProps)(GuestRoute)
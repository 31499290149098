import {NotificationManager} from 'react-notifications';

export const showMessage = (msg = {type: 'info'}) => {
    let text = ''
    switch (msg.type) {
        case 'error' :
            // TODO make an error handler depending on the object
            if(
                msg.data instanceof Object &&
                msg.data.response instanceof Object &&
                msg.data.response.data instanceof Object &&
                msg.data.response.data.error
            ) {
                text = msg.data.response.data.error
            } else {
                text = 'Oops! Something went wrong...'
            }
            break;
        case 'info' :
            if(msg.data instanceof String || typeof(msg.data) == 'string') text = msg.data
            break;
        default :
    }
    NotificationManager[msg.type](text)
}

import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ButtonSocialReg from '../controls3.0/buttonSocialReg/ButtonSocialReg'
import ButtonBlue from '../controls3.0/button/ButtonBlue'
import Input from '../controls3.0/input/Input'
// import iconMail from '../../images/svg/ic-mail.svg'
import RocketDAOBlue from '../../images/logo_beta.svg'
import FooterImg from '../../images/RocketDAO-Experts@1x.png'
// import Loader from '../test-controls/preloader/preloader'
import {register, signUpFacebook, signUpGoogle, resendMail, createRole, tokenLogin} from "../../actions/Login";
import {showMessage} from "../../utils/showMessage";
import {regexEmail, regexPass} from '../../utils/RegExp'
import packageJSON from './../../../package.json'
// import PropTypes from "prop-types";
// import {getLastProject} from "../../actions/Project";
import {connect} from "react-redux";
import history from "../../history";
import {newToken} from "../../actions/User";

class Start extends React.Component {
    constructor(props) {
        super();
        this.state = {
            data: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: ''
            },
            errors: {},
            showSocBlock: true,
            typeName: '',
            ref_b: '',
            created: false,
            loading: true
        };
        window.scrollTo(0,0);
    }

    componentDidMount() {
        let url = new URL(window.location.href),
            type = url.searchParams.get("type"),
            ref_b = url.searchParams.get("ref_b"),
            ref_f = url.searchParams.get("ref_f"),
            team_r = url.searchParams.get("team_r"),
            team_f = url.searchParams.get("team_f"),
            team_a = url.searchParams.get("team_a"),
            event = url.searchParams.get("event"),
            typeName = team_f ? 'fund' : '';

        if(type == 1) typeName = 'investor';
        if(type == 2) typeName = 'expert';
        if(type == 3) typeName = 'startup';
        if(type == 6) typeName = 'investor';
        if(type == 7) typeName = 'accelerator';

        this.setState({ typeName: typeName, type: type, ref_b: ref_b, ref_f, team_r: team_r, team_f:team_f, team_a})

        localStorage.setItem('tempType', typeName);
        localStorage.setItem('ref_b', ref_b);
        localStorage.setItem('ref_f', ref_f);
        localStorage.setItem('team_r', team_r);
        localStorage.setItem('team_f', team_f);
        localStorage.setItem('team_a', team_a);


        if(this.props.location.state){
            localStorage.setItem("commandProps", JSON.stringify(this.props.location.state))
        }

        if(event){
            localStorage.setItem('event', event);
        }


        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
            localStorage.removeItem('rFrom');

            if(type == 1 || type == 2){
                let data = {
                    role: typeName,
                }

                createRole(data)
                    .then((res)=>{
                        this.goToRegister(res.data)
                    })
                    .catch(err => {
                        showMessage({type: 'error', data: err})
                        this.setState({loading: false})
                    })
            } else {
                this.setState({loading: false})


                if(type == 6){
                    history.replace(`/start_investor`);
                }

                if(type == 3){
                    if(event){
                        localStorage.removeItem('event')
                        history.replace(`/registration/startup/1?event=${event}`);
                    } else {
                        history.replace(`/registration/startup/1`);
                    }
                }

                if(type == 7){
                    history.replace('/start_accelerator')
                }

                if(type == 8){
                    history.replace(`/join`);
                }
            }
        } else {
            this.setState({loading: false});
            localStorage.removeItem('referer');
            localStorage.removeItem('referer_startup');
            // localStorage.removeItem('rFrom');
            // localStorage.setItem('rFrom', history.location.pathname + history.location.search || '');
        }
    }

    goToRegister = (user) => {
        let commandProps = JSON.parse(localStorage.commandProps || "{}");

        localStorage.removeItem("commandProps");
        localStorage.removeItem('referer');
        localStorage.removeItem('referer_startup');
        localStorage.removeItem('rFrom');

        let event = localStorage.getItem('event');
        let type = this.state.type;

        switch (Number(type)) {
            case 1:
                if(user.investor && user.investor.id && user.investor.isFinished === false) {
                    history.replace(`/registration/investor/${user.investor.registrationStep}`)
                } else {
                    if(user.investor && user.investor.isFinished){
                        history.replace('/')
                    } else {
                        history.replace(`/registration/investor/1`);
                    }
                }
                break;
            case 2:
                if(user.expert && user.expert.id && user.expert.isFinished === false) {
                    history.replace(`/registration/expert/${user.expert.registrationStep}`);
                } else {
                    if(user.expert && user.expert.isFinished){
                        history.replace('/')
                    } else {
                        history.replace(`/registration/expert/1`);
                    }
                }
                break;
            case 3:
                if(event){
                    localStorage.removeItem('event');
                    if (this.state.ref_b){
                        history.replace(`/registration/startup/1?event=${event}&ref_b=${this.state.ref_b}`);
                    }
                    history.replace(`/registration/startup/1?event=${event}`);
                } else if (this.state.ref_b){
                    history.replace(`/registration/startup/1?ref_b=${this.state.ref_b}`);
                } else {
                    history.replace(`/registration/startup/1`);
                }

                break;
            case 4:
                history.replace(`/create/fund/1${history.location.search}`);
                break;
            case 5:
                history.replace(`/create/angel_network/1${history.location.search}`);
                break;
            case 6:
                history.replace(`/start_investor`);
                break;
            case 7:
                history.replace(`/start_accelerator${history.location.search}`);
                break;
            case 8:
                history.replace(`/join`);
                break;
            default:{

                try{
                    if( (this.state.referer_startup || this.state.rFrom || this.state.referer) && this.state.rFrom !== '/start_reg' && this.state.referer !== '/start_reg'){
                        history.replace(this.state.referer_startup || this.state.rFrom || this.state.referer || commandProps.to ||  '/', commandProps || {});
                    } else {
                        commandProps = commandProps || this.props.location.state;

                        if(commandProps && commandProps.prevLocation !== '/start_reg'){
                            history.replace(commandProps.prevLocation || commandProps.to ||  '/', commandProps || {})
                        } else {
                            history.replace('/', commandProps || {});
                        }
                    }
                } catch (e) {
                    console.error(e);
                }

                break;
            }
        }
    }


    sendFunction = (signUpFunc, response) => {
        if(response.error) {
            NotificationManager.error(response.error)
            return
        }
        this.setState({loaded: false})
        signUpFunc(response.accessToken, this.state.typeName, this.state.ref_b, this.state.ref_f, this.state.team_r, this.state.team_f, this.state.team_a)
            .then((res)=>{
                localStorage.setItem('RocketToken', res.data.token);
                newToken(res.data.token)
                this.goToRegister(res.data)
            })
            .catch(err => {
                this.setState({loaded: true})
                showMessage({type: 'error', data: err})
            })
    }
    
    responseFacebook = (response) => {
        this.sendFunction (signUpFacebook, response)
    }


    responseGoogle = (response) => {
        this.sendFunction (signUpGoogle, response)
    }

    onSuccessTwitter = (response) => {
        // const token = response.headers.get('x-auth-token');
        response.json().then(user => {
            localStorage.setItem('RocketToken', user.token);
            newToken(user.token)
            this.goToRegister(user)
        });
    };

    onFailedTwitter = (err) => {
        showMessage({type: 'error', data: err})
    };

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({data: {...this.state.data, [name]: value }}, () => {
            let data = this.state.data,
                obj = {}
            for (let name in data) {
               if(data[name]) obj[name] = data[name]
            }
            obj[name] = value
            let errors = this.validate(obj)
            this.setState({ errors })
        })
    }

    validate(data) {
        const errors = this.state.errors;
        for (let name in data) {
            let value = data[name],
                required = false,
                limit = 300,
                minLength = 0;
            switch (name) {
                case 'firstName':
                case 'lastName':
                    required = true;
                    break;
                case 'email':
                    // required = true;
                    if(!regexEmail.test(value)) {
                        errors[name] = 'Empty or invalid e-mail address'
                    } else {
                        delete errors[name]
                    }
                    break;
                case 'password':
                    // required = true;
                    if(!regexPass.test(value)){
                        errors[name] = 'Minimum 8 characters - lower and upper case symbols and digit(s)';
                    } else {
                        delete errors[name]
                    }
                    break;
                case 'passwordConfirm':
                    // required = true;
                    if(data.password != data.passwordConfirm) {
                        errors[name] = 'Passwords don\'t match';
                    } else {
                        delete errors[name]
                    }
                    break;
            }

            if (minLength && value.length < minLength) {
                errors[name] = 'min length ' + minLength + ' symbols';
            }

            if (required && !value) {
                errors[name] = 'Field is required'
            } else if (required && value) {
                delete errors[name]
            }

        }
        return errors;
    }

    register = () => {
        let data = this.state.data;
        let errors = this.validate(data)
        this.setState({ errors })
        if(!Object.keys(errors).length){
            // this.setState({ loading: true })
            data.type = this.state.type;
            // data.role = this.state.role;
            register(data, this.state.typeName, this.state.ref_b, this.state.ref_f, this.state.team_r, this.state.team_f, this.state.team_a, history.location.search)
                .then((res)=>{
                    this.setState({created: true})
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                })
        }
    }

    resendMail = () => {
        resendMail(this.state.data.email, this.state.data.type)
            .then((res)=>{
                showMessage({type: 'info', data: 'Successfully'})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    }


    handleSuccess = (data) => {
        console.log(data)
        this.setState({
            code: data.code,
            errorMessage: '',
        });
    }
    handleFailure = (error) => {
        console.log(error)
        this.setState({
            code: '',
            errorMessage: error.errorMessage,
        });
    }


    render () {
        let {data, errors} = this.state;
        const typeName = this.state.typeName === 'fund' ? 'Fund or Angel Network' : this.state.typeName;

        return (
            <div className='reg-full-wrap'>

                {!this.state.loading ?
                <React.Fragment>
                    <div className="container text-center">
                        <div className={'block-logo-top'}>
                            <a href="/">
                                <img src={RocketDAOBlue} alt="logo"/>
                            </a>
                        </div>
                        <p className="text-h1">
                            Join Rocket DAO {typeName.capitalize()}
                        </p>

                        {!this.state.created &&
                        <React.Fragment>
                            {this.state.showSocBlock &&
                            <div className='block-btns-soc'>
                                <div className="btn-google">
                                    <GoogleLogin
                                        clientId="839740061712-mi7grbk5uvgst4qo55ml9kvk3ic8a9e2.apps.googleusercontent.com"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        render={renderProps => (
                                            <ButtonSocialReg
                                                TitleButton={'Register with Google'}
                                                EventOnClick={() => renderProps.onClick()}
                                                disabled={false}
                                                color={'#DC4E41'}
                                                iconSVG={{
                                                    d: 'M0.00463809195,7.44246163 C-0.0603414999,10.5438424 2.11043401,13.5035211 5.09655646,14.4687664 C7.90929115,15.3986666 11.3675769,14.7030087 13.2392503,12.332631 C14.6057809,10.6717278 14.9163116,8.45751156 14.7683932,6.38885329 C12.3595769,6.36764617 9.95076054,6.37471521 7.54553605,6.37824973 C7.54161768,7.22267874 7.54553605,8.06389454 7.54912789,8.90832355 C8.9914136,8.94752459 10.4340258,8.92953067 11.8763116,8.95812815 C11.5122299,10.1858919 10.6828422,11.3285059 9.42406666,11.7719275 C6.99369932,12.8258572 3.89263809,11.4490009 3.1279034,8.94013423 C2.20480136,6.53762446 3.71598503,3.63835394 6.21100544,2.93948291 C7.75810748,2.40384244 9.3519034,3.00696011 10.6756585,3.79837131 C11.3463524,3.18079424 11.9739442,2.52080292 12.590434,1.85374257 C11.086434,0.61537522 9.15369932,-0.143903981 7.16676054,0.022861108 C3.26831156,0.0684885504 -0.143280275,3.61007778 0.00463809195,7.44246163 Z',
                                                    viewBox: '0 0 17 17'
                                                }}
                                                styleSVG={{
                                                    width: '28px',
                                                    height: '20px'
                                                }}
                                            />
                                        )}
                                    />
                                </div>

                                <div className="btn-twitter">
                                    <TwitterLogin loginUrl={`${packageJSON.proxy}/auth/twitter`}
                                                  requestTokenUrl={`${packageJSON.proxy}/auth/twitter/reverse`}
                                                  onFailure={this.onFailedTwitter}
                                                  onSuccess={this.onSuccessTwitter}
                                    >
                                        <ButtonSocialReg
                                            TitleButton={'Register with Twitter'}
                                            EventOnClick={null}
                                            disabled={false}
                                            color={'#55ACEE'}
                                            iconSVG={{
                                                d: 'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
                                                viewBox: '0 0 512 512'
                                            }}
                                            styleSVG={{
                                                width: '28px',
                                                height: '20px'
                                            }}
                                        />
                                    </TwitterLogin>
                                </div>

                                {/*<FacebookLogin*/}
                                    {/*appId="415695435674008"*/}
                                    {/*autoLoad={false}*/}
                                    {/*fields="name,email,picture"*/}
                                    {/*redirectUri={window.location.href}*/}
                                    {/*callback={this.responseFacebook}>*/}
                                    {/*<ButtonSocialReg*/}
                                        {/*TitleButton={'Register with Facebook'}*/}
                                        {/*EventOnClick={null}*/}
                                        {/*disabled={false}*/}
                                        {/*color={'#3B5998'}*/}
                                        {/*iconSVG={{*/}
                                            {/*d: 'M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229',*/}
                                            {/*viewBox: '0 0 264 512'*/}
                                        {/*}} // {"d":"", "viewBox": ""}*/}
                                        {/*styleSVG={{*/}
                                            {/*width: '28px',*/}
                                            {/*height: '20px'*/}
                                        {/*}} // {width:'',height:'',}*/}
                                    {/*/>*/}
                                {/*</FacebookLogin>*/}

                                <FacebookLogin
                                    appId="340838513264681"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    disableMobileRedirect={true}
                                    redirectUri={window.location.href}
                                    render={renderProps => (
                                        <ButtonSocialReg
                                            TitleButton={'Register with Facebook'}
                                            EventOnClick={() => renderProps.onClick()}
                                            disabled={false}
                                            color={'#3B5998'}
                                            iconSVG={{
                                                d: 'M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229',
                                                viewBox: '0 0 264 512'
                                            }} // {"d":"", "viewBox": ""}
                                            styleSVG={{
                                                width: '28px',
                                                height: '20px'
                                            }} // {width:'',height:'',}
                                        />
                                    )}
                                    callback={this.responseFacebook}>

                                </FacebookLogin>

                                <ButtonSocialReg
                                    TitleButton={'Register with Linkedin'}
                                    EventOnClick={() => window.location.href=`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86psnkb7tmaudy&redirect_uri=https://rocketdao.io/linkedin_auth&state=987654321&scope=r_liteprofile&role=${this.state.role}&ref_b=${this.state.ref_b}&team_r=${this.state.team_r}&team_f=${this.state.team_f}`}
                                    disabled={false}
                                    color={'#0E76A8'}
                                    iconSVG={{
                                        d: 'M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z',
                                        viewBox: '0 0 448 512'
                                    }} // {"d":"", "viewBox": ""}
                                    styleSVG={{
                                        width: '28px',
                                        height: '20px'
                                    }} // {width:'',height:'',}
                                />

                                {/*<div style={{marginBottom: '20px'}}>*/}
                                {/*<a*/}
                                {/*href={'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86psnkb7tmaudy&redirect_uri=https://test.rocketico.io/linkedin_auth&state=987654321&scope=r_basicprofile'}>*/}
                                {/*Sign in with Linkedin*/}
                                {/*</a>*/}
                                {/*</div>*/}


                                <ButtonSocialReg
                                    TitleButton={'Register with E-mail'}
                                    EventOnClick={() => {
                                        this.setState({showSocBlock: false})
                                        console.log(this.props.state)
                                    }}
                                    disabled={false}
                                    color={'#C5B399'}
                                    iconSVG={{
                                        d: 'M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z',
                                        viewBox: '0 0 512 512'
                                    }} // {"d":"", "viewBox": ""}
                                    styleSVG={{
                                        width: '28px',
                                        height: '20px'
                                    }} // {width:'',height:'',}
                                />

                                <div className='block-login-sign-up sign-up-text text-align-center'>Already signed up?<a onClick={() => {
                                    history.replace({...this.props.location, pathname: '/login'})}
                                }>Log in</a></div>
                                <div className='terms-links-text'>
                                    By proceeding with using this website you agree to our <a href={'/document/general-terms'} target='_blank'>Terms</a> and <a href='/document/privacy-policy' target='_blank'>Privacy Policy</a>
                                </div>
                            </div>
                            }


                            {!this.state.showSocBlock &&
                            <React.Fragment>
                                <div className='block-btns-soc block-email'>
                                    <div className="row-b no-gutters">
                                        <div className="col-5" style={{paddingRight: '15px'}}>
                                            <Input
                                                type={'text'}
                                                name={'firstName'}
                                                value={data.firstName}
                                                onChange={this.onChange}
                                                error={errors.firstName}
                                                placeholder={'Name'}
                                                iconClass={'fal fa-user'}
                                            />
                                        </div>
                                        <div className="col-7">
                                            <Input
                                                type={'text'}
                                                name={'lastName'}
                                                value={data.lastName}
                                                onChange={this.onChange}
                                                error={errors.lastName}
                                                placeholder={'Surname'}
                                                iconClass={'fal fa-user'}
                                            />
                                        </div>
                                    </div>

                                    <Input
                                        type={'email'}
                                        name={'email'}
                                        value={data.email}
                                        onChange={this.onChange}
                                        error={errors.email}
                                        placeholder={'Email'}
                                        iconClass={'fal fa-envelope'}
                                    />
                                    <Input
                                        type={'password'}
                                        name={'password'}
                                        value={data.password}
                                        onChange={this.onChange}
                                        error={errors.password}
                                        placeholder={'Password'}
                                        iconClass={'fal fa-lock-alt'}
                                    />
                                    <Input
                                        type={'password'}
                                        name={'passwordConfirm'}
                                        value={data.passwordConfirm}
                                        onChange={this.onChange}
                                        error={errors.passwordConfirm}
                                        placeholder={'Repeat Password'}
                                        iconClass={'fal fa-lock-alt'}
                                    />

                                    <ButtonBlue
                                        TitleButton={'Register'}
                                        EventOnClick={() => this.register()}
                                        paddingButton={'55'}
                                        disabled={Object.keys(errors).length}
                                    />
                                </div>
                                <div className='block-btns-soc block-email back'>
                                    <span onClick={() => this.setState({showSocBlock: true})}>Back</span>
                                </div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                        }

                        {this.state.created &&
                            <div className={'block-created'}>
                                Thank you, <span className="bold">{data.firstName} {data.lastName}! </span>
                                Your account has been created. <br/>
                                We sent you a confirmation email to <span className="bold">{data.email}</span> - please
                                click the link in the email to activate your account. <br/>
                                If you didn't receive the confirmation email please check
                                your spam folder or click <span onClick={() => this.resendMail()} className={'click-here'}>here</span> to resend.
                            </div>
                        }
                    </div>
                    <div className={'block-img-footer'}>
                        <img src={FooterImg} alt="footer_img" style={{position:'relative'}}/>
                    </div>
                    <NotificationContainer/>
                </React.Fragment>
                :
                <React.Fragment>

                </React.Fragment>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})


export default connect(mapStateToProps, {tokenLogin})(Start)


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonBorder from "../../controls3.0/button/ButtonBorder"

import CubeCheckBox from "../../controls3.0/checkBox/cubeCheckBox";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {getLastProject, getListFocuses, updateProject} from "../../../actions/Project";


class Step5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {marketFocus: []},
            listF: []
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()

        getListFocuses()
            .then((res) => {
                this.setState({listF: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = item => {
        let marketFocus = this.state.project.marketFocus

        let isFound = marketFocus.find(x => x === item.id)
        if (!isFound) {
            marketFocus.push(item.id)
        } else {
            marketFocus = marketFocus.filter(x => x !== item.id)
        }

        if (item.title == 'All') {
            if(isFound){
                marketFocus = []
            } else {
                marketFocus = this.state.listF.map((item) => item.id);
            }
        } else {
            marketFocus = marketFocus.filter(id => id != 18)
        }

        this.setState({project: {...this.state.project, marketFocus: marketFocus}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            marketFocus: project.marketFocus,
            fundraisingInstruments: "VC"
        }

        obj.event_type = 'startup_market_5';

        if(!obj.marketFocus) return
        if(!onlyStep) this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let project = this.state.project

        let list = this.state.listF.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup"  onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CubeCheckBox
                        onClick={null}
                        active={this.state.project.marketFocus.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup">{item.title}</div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">



                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Geographic market focus
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-10">
                                    Where does your target audience live? <br/>
                                    Which countries will you sell your product to?
                                </p>
                                <div className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                    Please choose the regions.
                                </div>

                                <div className="block-list-check-box-reg-startup bl-min-height">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(4, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(6)}
                                paddingButton={'54'}
                                disabled={!project.marketFocus.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step5.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step5)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {updateStartup} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";

import {getListParametersStartup} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";

import {getLastProject, updateProject} from "../../../actions/Project";
import Loader from '../../test-controls/preloader/preloader';


class Step21 extends React.Component {
    constructor(props) {
        super(props);
        let startup = props.user.startup || {};
        this.state = {
            project: props.project || {team: []},
            errors: {},
            listParameters: [],
            startup: startup
        }
    }

    componentDidMount() {
        if(!this.state.project.id) {
            this.props.getLastProject()
        }
        getListParametersStartup()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({listParameters: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })
    }



    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({[name]: value})
    }

    addToParameters = param => {
        param.projectId = this.state.project.id;
        param.parameterId = param.id;
        let parameters = this.state.project.parameters;

        let isFound = parameters.find(x => {
            return x.parameterId === param.id
        })


        if (isFound && isFound.id) {
            parameters = parameters.filter(x => x.parameterId !== param.id)
        } else {
            parameters.push(param)
        }


        this.setState({project: {...this.state.project, parameters}, empty: false})
    }


    update = (nextStep, onlyStep) => {
        // if(onlyStep){
        //     this.updateS(onlyStep, nextStep)
        //     return
        // }

        let project = this.state.project




        let obj = {
            id: project.id,
            parameters: project.parameters,
            registrationStep: nextStep,
            role: 'startup'
        }

        if (nextStep === 16 && onlyStep) {
            obj.event_data = {
                skip: true
            }
        }

        obj.event_type = 'startup_achievements_17';



        this.setState({loading: true})


        this.props.updateStartup(obj , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })

        // axios
        //     .put("/v2/startup", obj)
        //     .then(res => {
        //         this.props.updateProject(project.id, obj)
        //             .then((res) => {
        //                 this.updateS(onlyStep, nextStep)
        //             })
        //             .catch(error => {
        //                 this.setState({loading: false})
        //             })
        //     })
        //     .catch(err => {
        //         showMessage({type: 'error', data: err})
        //         throw err
        //     })

    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render () {
        let {project, startup, listParameters} = this.state;


        if(!project.parameters) project.parameters = []

        let list = listParameters.map((item, key) => {
            return (
                <div className="new-col c-2 new-pad-8-hor new-width-100-down-620 new-no-padding-down-620" key={key}>
                    <div className={`tab-info-big ${project.parameters.find((i) => (i.parameterId || i.id) === item.id) ? 'active': ''}`} onClick={() => this.addToParameters(item)}>
                        <div className='tib-title'>
                            {item.title}
                        </div>
                        <div className='tib-description'>
                            {item.description}
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <div className="container-step-2-reg container-step-21"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <p className="title-top-page-reg-expert text-h1 color-text-reg-black left-text shell-margin-bottom-15">
                                Achievements
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20">
                                If you have something you are proud of, what you woulk like to share with <br/>
                                the potential investors and users, it’s high time you did it!
                                <br/><br/>
                                There are some categories of ahievements below. Please choose the ones you <br/>
                                can provide the information for.
                            </p>

                            <div className="row block-list-tabs">
                                {list}
                                <Loader style={{backgroundColor: 'inherit'}} loaded={this.state.loaded} isLocal={true} ></Loader>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(16, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(22)}
                                paddingButton={'54'}
                                disabled={!project.parameters.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step21.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step21)


import React, {useEffect, useState} from 'react';
import './style.scss'
import AchieveBlock from "./AchieveBlock";
import UseCloseButton from "../../closeButton";
import {Row} from "../../Grid/Row";
import ExpertParamBlock from "../../ExpetParamBlock";

const expertParams = {
    23: { //
        7: { //Team
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        4: { //Tokenomics
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    },
    69: { // Дробышевский
        10: { //id	10 title	Market
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        2: { //id	2 title	Marketing and PR
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    28: { // Koltun
        3: { //id	3 title	Technologies
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    25: { // Mikhailov
        8: { //id	8 title	Risk
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        30: { //id	8 title	fina
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    31: { // Kuryan
        9: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        1: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    216: { // Sanchuk
        6: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    30: { // Grablevski
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    34: { // Inga
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    221: { // Torri
        1: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    252: { // Skvorc
        9: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    327: { // Dominik Franek
        10: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    }
}

const ParameterDetailsBlock = ({ parameterDetails=[], backClick=()=>{}, expert, ...props }) => {

    const getParamVal = (expertId, paramId, type, ach, line) => {
        try {
            if (!line)
                return expertParams[expertId][paramId][type][ach];
            else
                return expertParams[expertId][paramId].line;
        } catch (e) {
            return 0;
        }
    };

    return (
        <React.Fragment>
            <div className='parameter-details-block'>

                <Row className='m6 align-items-center back-block'>
                    <div className='col back-icon' onClick={ ()=> {backClick()} } ><UseCloseButton type={'back-black'}/></div>
                    <div className='col-auto back-title' onClick={ ()=> {backClick()} } >Expert Parameters</div>
                </Row>

                <div className='parameter-details-content'>
                    <ExpertParamBlock
                        parameter={parameterDetails}
                        expert={expert}
                    />

                    <div className='param-details-wrapper'>
                            <Row className="block-list-state-cart-achievements justify-content-between">
                                <div className="col-12 col-sm-6 block-left-state-cart-achievements">
                                    <p className="title-state-cart-achievements">
                                        Expert
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Projects evaluated
                                        <span
                                            className="rating">{getParamVal(expert.id, parameterDetails.RoleParameter.id, 'Expert', 'Projects_evaluated')}</span>
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Platform task
                                        <span
                                            className="rating">{getParamVal(expert.id, parameterDetails.RoleParameter.id, 'Expert', 'Platform_task')}</span>
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Since
                                        <span className="rating">2019</span>
                                    </p>
                                </div>
                                <div className="col-12 col-sm-6 block-center-state-cart-achievements">
                                    <p className="title-state-cart-achievements">
                                        Supervisor
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Experts evaluated
                                        <span
                                            className="rating expert">{getParamVal(expert.id, parameterDetails.RoleParameter.id, 'Supervisor', 'Experts_evaluated')}</span>
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Since
                                        <span className="rating">2019</span>
                                    </p>
                                </div>
                                <div className="col-12 col-sm-6 block-right-state-cart-achievements">
                                    <p className="title-state-cart-achievements">
                                        Methodologist
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Methodologies developed
                                        <span
                                            className="rating">{getParamVal(expert.id, parameterDetails.RoleParameter.id, 'Methodologist', 'Methodologies_developed')}</span>
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Methodologies in use
                                        <span
                                            className="rating">{getParamVal(expert.id, parameterDetails.RoleParameter.id, 'Methodologist', 'Methodologies_in_use')}</span>
                                    </p>
                                    <p className="description-state-cart-achievements">
                                        Since
                                        <span className="rating">2019</span>
                                    </p>
                                </div>
                            </Row>
                    </div>

                    <div className='achieve-wrapper'>
                        <div className='achieve-gradient'/>
                        <div className='achieve-descr'>In what way would you like to provide information about your professional experience?</div>
                        <AchieveBlock
                            AchieveList={parameterDetails.achievements}
                            parameterDetails={parameterDetails}
                            {...props}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )

};

export default ParameterDetailsBlock;
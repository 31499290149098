import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import TabsCounter from "../../controls3.0/tabs/TabsCounter";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import CheckBox from '../../controls3.0/checkBox/checkBox'
import ButtonGray from "../../controls3.0/button/ButtonGray";

import illustration from '../../../images/svg/illustration.svg'

class Step14 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            workingDays: expert.workingDays? ('' + expert.workingDays).split(',') : []
        }
    }
    handleOptionChange = param => {
        let workingDays = this.state.workingDays;
        let isFound = workingDays.find(item => item === param)


        if (!isFound) {
            workingDays.push(param)
        } else {
            workingDays = workingDays.filter(item => item !== param)
        }
        this.setState({workingDays})

        // if(workingDays === param) param = null
        // this.setState({
        //     expert: {...this.state.expert, workingDays: param}
        // })
    }
    update = (nextStep, onlyStep) => {
        let data = {};

        if(!onlyStep) {
            data.workingDays = this.state.workingDays.join(',');
        }

        let loading = nextStep == 13? 'loadingBack' : 'loading';
        this.setState({[loading]: true})
        this.props.updateExpert(data, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }



    render () {
        let {user, expert, workingDays} = this.state;
        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>

                    <div className="block-img-right new-no-display-down-1199">
                        <img src={illustration} alt=""/>
                    </div>

                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>


                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Work calendar
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                            In order to make your working experience on the platform a truly enjoyable one, <br/>
                            please let us know the most convenient time for you to receive and perform the <br/>
                            expert tasks.
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-60" style={{fontWeight:'600'}}>
                            Note that one project will take about 2 hours of your time.
                        </p>

                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-30">
                            Mostly I am available on:
                        </p>

                        <div className="block-list-check-box-reg-expert" style={{width:'320px'}}>
                            <div className="block-top-list-check-box-reg-expert">
                                <div className="block-left-top-list-check-box-reg-expert">
                                    <div className="block-item-check-box-double-title">
                                        <div className="block-left-item-check-box-double-title">
                                            <CheckBox
                                                onClick={() => this.handleOptionChange('0')}
                                                active={workingDays.includes('0')}
                                            />
                                        </div>
                                        <div className="block-right-item-check-box-double-title">
                                            <p className="title">Weekdays</p>
                                            <p className="description">(business hours)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-right-top-list-check-box-reg-expert">
                                    <div className="block-item-check-box-double-title">
                                        <div className="block-left-item-check-box-double-title">
                                            <CheckBox
                                                onClick={() => this.handleOptionChange('1')}
                                                active={workingDays.includes('1')}
                                            />
                                        </div>
                                        <div className="block-right-item-check-box-double-title">
                                            <p className="title">Weekdays</p>
                                            <p className="description">(evenings)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-body-list-check-box-reg-expert">
                                <div className="block-item-check-box-double-title">
                                    <div className="block-left-item-check-box-double-title">
                                        <CheckBox
                                            onClick={() => this.handleOptionChange('2')}
                                            active={workingDays.includes('2')}
                                        />
                                    </div>
                                    <div className="block-right-item-check-box-double-title">
                                        <p className="title">Weekends</p>
                                        <p className="description">
                                            We will send you tasks on Fridays, <br/>
                                            Saturdays or Sundays depending <br/>
                                            on the time zone.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(13, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 16px'}}>
                            <p className="title-block-button-reg-expert text-right">
                                You will be able to setup work <br/>
                                calendar later in settings
                            </p>
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0', verticalAlign: 'top'}}>
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(15)}
                                paddingButton={'40'}
                                disabled={!workingDays.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step14.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step14)
import React from 'react';
import axios from "axios";
import {showMessage} from "../../utils/showMessage";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import history from "../../history";
import {newToken} from "../../actions/User";

export default class LinkedinAuth extends React.Component {
    constructor(props) {
        super();
        this.state = {

        }

        if(localStorage.RocketToken){
            props.history.go(-3);
        }
    }

    componentDidMount() {
        let url = new URL(window.location.href),
            code = url.searchParams.get("code"),
            ref_b = url.searchParams.get("ref_b") || localStorage.getItem('ref_b'),
            ref_f = url.searchParams.get("ref_f") || localStorage.getItem('ref_f'),
            team_r = url.searchParams.get("team_r") || localStorage.getItem('team_r'),
            team_f = url.searchParams.get("team_f") || localStorage.getItem('team_f'),
            team_a = url.searchParams.get("team_a") || localStorage.getItem('team_a'),
            referer = url.searchParams.get("referer") || localStorage.getItem('referer'),
            rFrom = url.searchParams.get("rFrom") || localStorage.getItem('rFrom'),
            event = url.searchParams.get("event") || localStorage.getItem('event'),
            session_id = url.searchParams.get("event") || localStorage.getItem('user_identifier'),
            commandProps = localStorage.getItem("commandProps") || "{}";
            commandProps = JSON.parse(commandProps);

        let role = localStorage.getItem('tempType');

        axios.get(`/v1/linkedin/auth?code=${code}&role=${role}&ref_b=${ref_b}&ref_f=${ref_f}&team_r=${team_r}&team_f=${team_f}&team_a=${team_a}&session_id=${session_id}`)
            .then((res) => {
                let user = res.data;

                localStorage.removeItem('tempType');
                localStorage.removeItem('ref_b');
                localStorage.removeItem('ref_f');
                localStorage.removeItem('team_r');
                localStorage.removeItem('team_f');
                localStorage.removeItem('team_a');
                localStorage.removeItem('referer');
                localStorage.removeItem('commandProps');
                localStorage.removeItem('event');
                localStorage.removeItem('rFrom');

                if(user.token){

                    if( referer === '/startup_test' ){
                        localStorage.setItem('ViralTest', JSON.stringify({
                            ...JSON.parse(localStorage.getItem('ViralTest')),
                            isResultsLoad: true
                        }));
                    }

                    localStorage.setItem('RocketToken', user.token);
                    newToken(user.token);

                }

                if(role) {
                    switch (role) {
                        case 'investor':
                            if(user.investor && user.investor.id && user.investor.isFinished === false) {
                                window.location.href = `/registration/investor/${user.investor.registrationStep}`
                            } else {
                                if(user.investor && user.investor.isFinished){
                                    history.push('/')
                                } else {
                                    history.push(`/start_investor`);
                                }
                            }
                            break;
                        case 'expert':
                            if(user.expert && user.expert.id && user.expert.isFinished === false) {
                                window.location.href = `/registration/expert/${user.expert.registrationStep}`
                            } else {
                                if(user.expert && user.expert.isFinished){
                                    history.push('/')
                                } else {
                                    history.push(`/registration/expert/1`);
                                }
                            }
                            break;
                        case 'startup':
                            if(event){
                                history.push(`/registration/startup/1?event=${event}`);
                            } else {
                                history.push(`/registration/startup/1`);
                            }

                            break;
                        default:
                            history.push(rFrom || referer || commandProps.to || '/', commandProps)
                    }
                } else {
                    history.push(rFrom || referer  || commandProps.to || '/', commandProps);
                }

            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                history.push(rFrom || referer  || commandProps.to || '/', commandProps);
            })

    }

    render () {
        return (
            <React.Fragment>
                <div>Загрузка...</div>
                <NotificationContainer/>
            </React.Fragment>
        )
    }
}


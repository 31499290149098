import axios from "axios";
import _ from "lodash";
import {
  getFundInfo,
  getProjectInfo,
    updateFundInfo
} from "./FundActions";

export const createFund = data => {
    return axios.post("/funds", data);
};

export const finishFund = data => {
    return axios.put(`/v1/funds/${data.id}`, data);
};
export const deleteFund = id => {
    return axios.delete(`/v1/funds/${id}`);
};

export const getProject = id => {
  return dispatch => {
    return axios
      .get(`/projects/${id}`)
      .then(res => {
        const project = res.data;
        if (_.isNil(res.data.error)) {
          dispatch(getProjectInfo(project));
        }
      })
      .catch(error => {
        //console.log("ERROR: " + error.response);
      });
  };
};



export const getFunds = (skip, take, tag, sum, minmax1, minmax2) => {
    if(!skip) skip = '';
    if(!take) take = '';
    if(!tag) tag = '';
    if(!sum) sum = '';
    if(!minmax1) minmax1 = '';
    if(!minmax2) minmax2 = '';

    return axios.get(`/funds?skip=${skip}&take=${take}&tag=${tag}&sum=${sum}&participationsSizeRange=${minmax1}&participantsCountRange=${minmax2}`)


    // return axios.get('/funds', {
    //     params: {
    //         filters: {
    //             where: {
    //                 kind: 'Antminer S9',
    //                 status: 'OK'
    //             },
    //             orderBy: ['created_at', 'desc'],
    //             limit: 5
    //         }
    //     }
    // })

};

export const getFundsPopular = () => {
    return axios.get(`/funds/popular`)
};


export const getFundSearch = (str) => {
    return axios.get(`/v2/funds?search=${str}`)
}

export const getAngelSearch = (str) => {
    return axios.get(`/v2/angels?search=${str}`)
}



export const getMyFund = (id) => {
    return dispatch => {
        const url =  `/v2/funds/my/${id}`;
        return axios
            .get(url)
            .then(res => {
                dispatch(getFundInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};

export const getMyAngelNetwork = (id) => {
    return dispatch => {
        const url =  `/v2/angels/my/${id}`;
        return axios
            .get(url)
            .then(res => {
                dispatch(getFundInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};

export const updateFund = (fund = {}, id) => {
    let obj = fund;

    return dispatch => {
        return axios
            .put(`/v2/funds/${id}`, obj)
            .then(res => {
                dispatch(updateFundInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};

export const updateAngelNetwork = (fund = {}, id) => {
    let obj = fund;

    return dispatch => {
        return axios
            .put(`/v2/angels/${id}`, obj)
            .then(res => {
                dispatch(updateFundInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};

export const sendInvitation = (id, data) => {
    return axios.post(`/v2/funds/${id}/team/invite/`, data)
};

export const searchExternalFund = async (title) => {
    return axios.get(`/v2/funds/external/${title}`);
}

export const searchExternalAngelNetwork = async (title) => {
    return axios.get(`/v2/angels/external/${title}`);
}

export const searchExternalAccelerator = async (title) => {
    return axios.get(`/v2/accelerator/external/${title}`);
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import ButtonBlue from '../controls3.0/button/ButtonBlue'
import ButtonGray from "../controls3.0/button/ButtonGray";
import CheckBox from "../controls3.0/checkBox/checkBox";
import history from "../../history";
import Header from "../header";
import {tokenLogin} from "../../actions/Login";

class Step10 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type:''
        }

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        } else {
            history.push(`/login?type=8`)
        }
    }

    set = type => {
        let currentType = this.state.type;
        if (currentType === type) type = '';
        this.setState( {type: type})
    }
    
    go = () => {
        switch (this.state.type){
            case 'startup':{
                history.push('/registration/startup/1');
                break;
            }
            case 'expert':{
                history.push('/registration/expert/1');
                break;
            }
            case 'investor':{
                history.push('/start_investor');
                break;
            }
            case 'accelerator':{
                history.push('/start_accelerator');
                break;
            }
        }
    }

    render() {


        return (
            <React.Fragment>
                <div className='reg-wrap'>
                    <Header user={this.props.user} noShowCreate={true}/>
                    <div className="container-100-reg">
                        <div className="step-1 is-startup" style={{overflowX:'hidden'}}>
                            <div className="block-reg-startup-step-2" style={{padding:'0 0 250px 0'}}>
                                <div className="container-full-reg" style={{position: 'relative'}}>
                                    <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15 text-align-center" style={{padding:'60px 20px 0 20px'}}>
                                        What is your role on the platform?
                                    </p>
                                    <p className="text-button-2 color-rgba-88 margin-0 text-align-center font-weight-300" style={{lineHeight: '22px', padding:'0 20px 30px 20px'}}>
                                        Prior we start the registration let's first define who you are.<br/>
                                        We have several categories of users on the platform. Which of them do you represent?<br/><br/>
                                        Please choose only one role, to begin with. Later you will be able to add more roles.
                                    </p>

                                    <div
                                        className="block-list-cart-round-reg-startup block-gradient-gray-reg padding-top-60 margin-top-10">
                                        <div
                                            className={this.state.type === 'startup' ? 'block-item-cart-round-reg-startup active block-item-choice-role' : 'block-item-cart-round-reg-startup block-item-choice-role'}
                                            onClick={() => this.set('startup')}>
                                            <div className='block-checkbox-front-cart'>
                                                <CheckBox
                                                    active={this.state.type === 'startup' ? true : false}
                                                />
                                            </div>
                                            <div className="choice-role-main-title color-rgba-08 font-weight-500">
                                                Startup
                                            </div>
                                            <div className="choice-role-descr color-rgba-08">
                                                Create your startup <br/>profile and become <br/>visible to experts and <br/>investors
                                            </div>

                                            <div className="block-button-cart">
                                                <p className="title-button-cart">
                                                    {this.state.type === 'startup' ? 'Cancel' : 'Choose'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state.type === 'investor' ? 'block-item-cart-round-reg-startup active block-item-choice-role' : 'block-item-cart-round-reg-startup block-item-choice-role'}
                                            onClick={() => this.set('investor')}>
                                            <div className='block-checkbox-front-cart'>
                                                <CheckBox
                                                    active={this.state.type === 'investor' ? true : false}
                                                />
                                            </div>
                                            <div className="choice-role-main-title color-rgba-08 font-weight-500">
                                                Investor
                                            </div>
                                            <div className="choice-role-descr color-rgba-08">
                                                Register as individual <br/>investor, venture fund <br/>or business angel <br/>network
                                            </div>

                                            <div className="block-button-cart">
                                                <p className="title-button-cart">
                                                    {this.state.type === 'investor' ? 'Cancel' : 'Choose'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state.type === 'expert' ? 'block-item-cart-round-reg-startup active block-item-choice-role' : 'block-item-cart-round-reg-startup block-item-choice-role'}
                                            onClick={() => this.set('expert')}>
                                            <div className='block-checkbox-front-cart'>
                                                <CheckBox
                                                    active={this.state.type === 'expert' ? true : false}
                                                />
                                            </div>

                                            <div className="choice-role-main-title color-rgba-08 font-weight-500">
                                                Expert
                                            </div>
                                            <div className="choice-role-descr color-rgba-08">
                                                Join expert community <br/>to conduct evaluation <br/>of startups and earn <br/>money on it
                                            </div>

                                            <div className="block-button-cart">
                                                <p className="title-button-cart">
                                                    {this.state.type === 'expert' ? 'Cancel' : 'Choose'}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state.type === 'accelerator' ? 'block-item-cart-round-reg-startup active block-item-choice-role' : 'block-item-cart-round-reg-startup block-item-choice-role'}
                                            onClick={() => this.set('accelerator')}>
                                            <div className='block-checkbox-front-cart'>
                                                <CheckBox
                                                    active={this.state.type === 'accelerator' ? true : false}
                                                />
                                            </div>

                                            <div className="choice-role-main-title color-rgba-08 font-weight-500">
                                                Accelerator
                                            </div>
                                            <div className="choice-role-descr color-rgba-08">
                                                Register private, <br/>corporate, or <br/>government <br/>accelerator
                                            </div>

                                            <div className="block-button-cart">
                                                <p className="title-button-cart">
                                                    {this.state.type === 'accelerator' ? 'Cancel' : 'Choose'}
                                                </p>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back to the platform'}
                                    EventOnClick={() => {history.push('/')}}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Done!'}
                                    EventOnClick={() => {this.go()}}
                                    paddingButton={'56'}
                                    disabled={!this.state.type}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step10.propTypes = {
    tokenLogin: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin})(Step10)
import React, {useEffect, useState} from 'react';
import $ from 'jquery';

import './style.scss'
import Swipe from "../../controls3.0/swipe/Swipe";
import {Box} from "../Grid/Box";
import {Row} from "../Grid/Row";


const NotificationStartup = ({title, notifyRules, parentCallback}) => {
    const [showSwipes, setShowSwipes] = useState({});
    const [resetSwipes, setResetSwipes] = useState(false);

    useState(() => {
        return () => false;
    }, []);

    useEffect(() => {
        if (document.documentElement.scrollWidth < 500)
            $('.block-new-top-menu-rocket-dao').css('display', 'block');

        if ( JSON.stringify(showSwipes) === JSON.stringify({}) ) {
            let swipesRules = {};
            notifyRules.forEach((item, key)=>{
                swipesRules[item.id] = false;
            });
            setShowSwipes(swipesRules);
        }

    }, [resetSwipes, notifyRules]);

    useEffect(() => {

        let swipesRules = {};
        notifyRules.forEach((item, key)=>{
            swipesRules[item.id] = false;
        });
        setShowSwipes(swipesRules);


    }, [resetSwipes]);

    const handleSwipes = (id) => {
        if (document.documentElement.scrollWidth < 500)
            $('.block-new-top-menu-rocket-dao').css('display', 'none');

        let swipesRules = {};
        notifyRules.forEach((item, key)=>{
            swipesRules[item.id] = false;
            if (item.id === id) swipesRules[item.id] = true;
        });
        setShowSwipes(swipesRules);
    };

    const resetSwipe = () => {
        setResetSwipes(!resetSwipes);
    };
        let list = notifyRules.map((item, key)=>{
            return <Row className={`notification-line ${!item.descr?'short-line':''} ${item.withoutBorder?'without-border-line':''}`} onClick={()=>{handleSwipes(item.id)}}>
                <div className='col-8 descr-line'>
                    <div className='notification-title'>{item.title}</div>
                    <div className='notification-descr'>{item.descr}</div>
                </div>
                <div className='col-4 swipes-countainer'>
                    <Row className={`swipes-line ${item.withoutBorder?'hide-swipes':''}`}>
                        <div className='col-6 swipe-block business-model-list-search text-align-center'>
                            <Swipe
                                onClick={() => {parentCallback(item, 'mail')}}
                                active={item.mail}
                            />
                        </div>
                        <div className='col-6 swipe-block business-model-list-search text-left'>
                            <Swipe
                                onClick={() => {parentCallback(item, 'telegram')}}
                                active={item.telegram}
                                disable={true}
                            />
                        </div>
                    </Row>
                </div>
                {showSwipes[item.id] && <div className='mobile-wrapper'>
                    <div className='mobile-container'>
                        <div className='row-m0 title-line-mobile no-gutters'>
                            <div className='col arrow-back-wrapper' onClick={()=>{resetSwipe()}}>
                            </div>
                            <div className='col mobile-title'>{item.title}</div>
                        </div>
                        <div className='row-b mobile-swipe-line'>
                            <div className='col title'>Email</div>
                            <div className='col-auto swipe-block business-model-list-search'>
                                <Swipe
                                    onClick={() => {parentCallback(item, 'mail')}}
                                    active={item.mail}
                                />
                            </div>
                        </div>
                        <div className='row-b mobile-swipe-line'>
                            <div className='col title'>Telegram</div>
                            <div className='col-auto swipe-block business-model-list-search'>
                                <Swipe
                                    onClick={() => {parentCallback(item, 'telegram')}}
                                    active={item.telegram}
                                    disable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>}
                <div className='col arrow-wrapper'>
                    <i className="far fa-chevron-down"/>
                </div>
            </Row>

            // <div key={key} className={`row-m0 notification-line no-gutters w-100 ${!item.descr?'short-line':''}`}
            //             onClick={()=>{handleSwipes(item.id)}}
            //             style={item.withoutBorder?{borderBottom:'none'}:{}}
            // >
            //
            //
            // </div>
        });

    return (
        <div className={'col-12'}>
            <Box key={title} className='notification-main-wrapper'>
                {console.count('NotificationStartup ' + title )}
                <Row className={`notification-list-main-row ${title==='General preferences'?'without-border-line':''}`} >
                    <div className='col-8 notif-title'>{title}</div>
                    <div className={'col-4 column-container'}>
                        <Row>
                            <div className='col-6 notification-col-name text-align-center'>
                                Email
                            </div>
                            <div className='col-6 notification-col-name text-left'>
                                Telegram
                            </div>
                        </Row>
                    </div>
                </Row>
                {list}
            </Box>
        </div>

    )
};

export default NotificationStartup;
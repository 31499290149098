import {updateMenuAction, updateExpertAchMenuAction} from './menuActions';

export const updateMenu = (object) => {
    return dispatch => {
        dispatch(updateMenuAction(object));
    }
};

export const updateExpertAchMenu = (object) => {
    return dispatch => {
        dispatch(updateExpertAchMenuAction(object));
    }
};
import React, {useEffect, useState} from 'react';
import './style.scss'
import {Row} from "../Grid/Row";

const expertParams = {
    23: { //
        7: { //Team
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        4: { //Tokenomics
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    },
    69: { // Дробышевский
        10: { //id	10 title	Market
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        2: { //id	2 title	Marketing and PR
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    28: { // Koltun
        3: { //id	3 title	Technologies
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    25: { // Mikhailov
        8: { //id	8 title	Risk
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        30: { //id	8 title	fina
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    31: { // Kuryan
        9: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        1: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    216: { // Sanchuk
        6: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    30: { // Grablevski
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    34: { // Inga
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    221: { // Torri
        1: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    252: { // Skvorc
        9: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    327: { // Dominik Franek
        10: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    }
}

const ExpertParamBlock = ({ parameter={}, expert={}, onClick=()=>{} }) => {


    const getParamVal = (expertId, paramId, type, ach, line) => {
        try {
            if (!line)
                return expertParams[expertId][paramId][type][ach];
            else
                return expertParams[expertId][paramId].line;
        } catch (e) {
            return 0;
        }
    };

    return (
        <React.Fragment>
                <div className={`expert-param-block-wrapper 
                ${getParamVal(expert.id, parameter.RoleParameter.id, '', '', true)!==0?'rated-param':''}`}
                     onClick={onClick}
                     data-pId={parameter.parameterId}
                >
                    <Row className='align-items-center justify-content-between'>
                        <div className='col-10 col-sm-auto col-lg-6 title-wrapper'>
                            <Row className='m8 align-items-center left-side'>
                                <div className='col param-number-col'>
                                    <div className="param-number-wrapper">
                                        <span className="param-number">
                                            {getParamVal(expert.id, parameter.RoleParameter.id, '', '', true)/10}
                                        </span>
                                    </div>
                                </div>
                                <div className='col-auto title-col'>
                                    <h2 className="title">{parameter.RoleParameter.title}</h2>
                                    <span className='subtitle'>Since something</span>
                                </div>
                            </Row>
                        </div>
                        <div className='col-auto col-lg-6'>
                            <Row className='justify-content-end align-items-center'>
                                <div className='col-10 progress-col d-none d-lg-block'>
                                    <div className="progress-potential">
                                        <div className="progress-active"
                                             style={{width: `${getParamVal(expert.id, parameter.RoleParameter.id, '', '', true)}%`}}/>
                                    </div>
                                </div>
                                <div className='col-auto'>
                                    <i className="far fa-chevron-down"/>
                                </div>
                            </Row>
                        </div>

                    </Row>
                </div>

        </React.Fragment>
    )
};

export default ExpertParamBlock;
import React, {useEffect, useState} from 'react';
import './style.scss'
import Select from "../../controls3.0/select/Select";
import {COUNTRIES} from "../../../utils/countriesLanguages";


const  NameBlock = ({country, parentCallback}) => {
    const onChangeCountry = (data) => {
        if (data) {
            parentCallback(data);
        }
    };

    return (
        <React.Fragment>
            <div className='location-block-container'>
                <div className='row-b'>
                    <div className='col-12 col-sm-6 location-col'>
                        <div className='location-title'>Location</div>
                        <div className='location-descr'>Please define the
                            country where you actually live and work.
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 location-col location-right-col'>
                        <Select
                            placeholder={'Select'}
                            data={COUNTRIES}
                            value={country}
                            onChange={(e) => onChangeCountry(e)}
                            error={''}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};

export default NameBlock;
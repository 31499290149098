import React from 'react';
import "./style.css"


export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <div className={`document`}>
                <h1 className="heading-1">
                    Privacy Policy
                </h1>
                <p className="description">
                    OU Rocketdao (“Rocket DAO” "we", “us”) are committed to protecting and respecting your privacy.
                </p>
                <p className="description">
                    This privacy policy applies to the website www.rocketdao.io owned and operated by Rocket DAO (acting
                    as data controller). This privacy policy describes how Rocket DAO collects and uses the personal
                    data you provide on www.rocketdao.io. It also describes the choices available to you regarding our
                    use of your personal data and how you can access and update this information (see "Your rights"
                    section for more details).
                </p>
                <p className="description">
                    We may collect and process the following data about you provided at the time of requesting
                    information from us:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Information that you provide to us by filling in the forms on our website rocketdao.io and mobile
                    applications ("Our Site").
                </p>
                <p className="description">
                    For Expert: Name, Phone number, Email, Date of Birth, Сountry of residence, Passport number,
                    Address, Address validity proof photo, Language, Skills & Competencies, Work experience, Social
                    network links, Professional Identification, Startup evaluation parameters, Achievements, Metamask
                    wallet number.
                </p>
                <p className="description">
                    For Startup: Startup Name, Short description of the startup, Founding year, Logo, Industry, Country
                    of incorporation, Form of the legal entity, Headquarters, Geographic market focus, Fundraising
                    instruments, Investment round, Capital seeking, Valuation Cap, Equity offering, Token sale info
                    (Total tokens number, Tokens for sale, Token price, Hard cap), Business model type, Average monthly
                    turnover, Product status, Website, Google Play link, App Store link,Problem, Solution, Market,
                    Business model, Pitch deck & Demo, Team and founders, Achievements, Contacts.
                </p>
                <p className="description">
                    For Fund: Fund Name, Fund Logo, Location, Website, Founding year, Areas of Expertise, Skills, Fund
                    Description, Achievements, Industry, Geographic Focus, Business type, Startup turnover stage, The
                    size of fund, Average investment cheque of the Fund, Number of investments & Exits, Previous
                    investments, Investment focus, Current team, Proof of ownership document, Contacts.
                </p>
                <p className="description">
                    For Business angel: Name, Location, Skills, Work experience, Professional identification,
                    Achievements, Industry, Geographic market focus, Business type, Startup turnover stage, Investment
                    size range, Number of investments & Exits, Previous investments, Investment focus, Angel networks &
                    Venture funds membership, Contacts.
                </p>
                <p className="description">
                    For Angel network: Angel network Name, Angel network Logo, Number of current team members, Website,
                    Founding year, Areas of expertise, Skills, Angel network description, Achievements, Industry,
                    Geographic focus, Business type, Startup turnover stage, Investment size range, Number of
                    investments & Exits, Previous investments of the network, Investment focus, Current team, Proof of
                    ownership document, Contacts.
                </p>
                <p className="description">
                    For Accelerator: Type of accelerator, Accelerator name, Accelerator logo, Website, Location,
                    Founding year, Areas of expertise, Accelerator description, Achievements, Industry, Geographic
                    market focus, Business type, Investment size range: Funding up to, Equity taken in return, Startups
                    accelerated total, Number of batches, Startups in focus, Current team, Proof of ownership document,
                    Contacts.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Information that you provide to us when you write to us (including by email).
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Details of transactions you carry out through Our Site and of the fulfillment of your orders.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Information obtained by us from third parties in accordance with this Privacy Policy. For example,
                    if you chose to list your business with us, we may obtain additional information from credit
                    reference agencies as a supplementary source.
                </p>
                <p className="description">
                    As is true of most websites, we gather certain information automatically and store it in log files.
                    This information includes internet protocol (IP) addresses, browser type, internet service provider
                    (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this
                    information, to analyze trends, to administer Our Site, to track users’ movements around Our Site
                    and to gather demographic information about our user base as a whole.
                </p>
                <h2 className="heading-1">
                    Use made of the information
                </h2>
                <p className="description">
                    We use information held about you in the following ways:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    To ensure that content from Our Site is presented in the most effective manner for you and for your
                    computer. This is necessary for our legitimate business interests.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    To provide you with information that may be of interest to you, where you have consented to be
                    contacted for such purposes.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    To carry out our obligations between you and us to provide information or services that you have
                    requested and notify you about changes to our services.
                </p>
                <p className="description">
                    If you are an existing customer/member, we may contact you by electronic means (fax, email or SMS)
                    with information about services similar to those which were the subject of a previous sale to you.
                    We will not otherwise contact you by electronic means to provide you with information about services
                    which may be of interest to you, unless you have consented to this.
                </p>
                <p className="description">
                    If you do not want us to use your data in one or more of the ways mentioned above, please let us
                    know by contacting us at <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>.
                </p>
                <h2 className="heading-1">
                    Disclosure of your information
                </h2>
                <p className="description">
                    We will share your personal data with third parties only in the ways that are described in this
                    privacy policy. We do not sell your personal data to third parties.
                </p>
                <p className="description">
                    We may disclose your personal data to any member of our group (which means our subsidiaries).
                </p>
                <p className="description">
                    In addition to the above, we may disclose your personal data to third parties:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    If Rocket DAO or substantially all of its assets are acquired by a third party, in which case
                    personal data held by it about its customers will be one of the transferred assets. You will be
                    notified via email and/or prominent notice on Our Site of any change in ownership or uses of your
                    personal data, as well as any choices you may have regarding your personal data.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    If we are, or believe in good faith that we are, under a duty to disclose or share your personal
                    data in order to comply with any legal obligation; or permitted by law in order to enforce or apply
                    our Terms and Conditions of Website Use and other agreements; or to protect the rights, property, or
                    safety of us, our customers, or others. This includes exchanging information with other companies
                    and organizations for the purposes of fraud protection and credit risk reduction.
                </p>
                <p className="description">
                    If you are a Startup, the information that you provide during the registration process may be given
                    to the Experts that provide us with the evaluation services of your Startup. Experts are authorized
                    to use your personal data only as necessary while conducting an evaluation of your Startup in order
                    to provide us with the above-mentioned services. We cannot guarantee that Experts will not open and
                    study the information provided. If the information leakage from the Experts’ side is detected, the
                    latest bear full and direct responsibility to the Startup.
                </p>
                <p className="description">
                    If you are an Expert, the results of your evaluation work conducted is public, consequently this
                    information will be open along with the information about the evaluation authorship, about
                    quantitative values of your evaluations, about your expert rating, about the number of the projects
                    evaluated, about the percent of the tasks conducted for the platform, about your comments and
                    recommendations. Statistical information concerning your activities on Rocket DAO platform will also
                    be public. You cannot use information obtained about the Startup in the purposes not covered by
                    Rocket DAO platform in the context of Startups evaluation. In case of the information disclosure,
                    you assume full responsibility to the injured party.
                </p>
                <p className="description">
                    If you are an Investor (Venture fund, Angel network, Business angel), in the cases when you invest
                    or apply to invest in a Startup your personal data may be disclosed to the Startup that you have
                    invested or applied to invest in. You cannot use information obtained about the Startup to the
                    detriment of the project, comment it outside Rocket DAO platform or publish any confidential data
                    about the project.
                </p>
                <p className="description">
                    We do not disclose information about identifiable individuals to our advertisers, but we may provide
                    them with aggregate information about our users (for example, we may inform them that 500 men aged
                    under 30 have clicked on their advertisement on any given day). We may also use such aggregate
                    information to help advertisers reach the kind of audience they want to target. We may make use of
                    the personal data we have collected from you to enable us to comply with our advertisers' wishes by
                    displaying their advertisements to that target audience.
                </p>
                <h2 className="heading-1">
                    Your rights
                </h2>
                <p className="description">
                    In accordance with the Data Protection Act of 6 January 1978, reinforced and supple­mented by the
                    GDPR (General Data Protection regulation) entered into force on 25 May 2018, you have at any time a
                    right of access, recti­fic­ation, oppos­ition, erasure and portab­ility of all of your personal data
                    by writing, by mail and justi­fying your identity, to <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>
                </p>
                <p className="description">
                    You have the right to access information that is held about you. To protect your privacy and
                    security, we’ll take reasonable steps to verify your identity before providing copies of any
                    relevant materials.
                </p>
                <p className="description">
                    You may be entitled to ask us:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    for a copy of your information;
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    to correct or delete your information;
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    to restrict or stop the processing of the information;
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    to transfer certain information to other organisations; and
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    where we have asked for your consent to process your data, to withdraw this consent.
                </p>
                <p className="description">
                    These rights may be limited in some situations – for example, where we can demonstrate that we have
                    a legal requirement to process your data. In some instances, this may mean that we are able to
                    retain data even if you withdraw your consent.
                </p>
                <p className="description">
                    Where we require information to comply with legal or contractual obligations, then provision of such
                    data is mandatory: if such information is not provided, then we will not be able to meet obligations
                    placed on us or manage your transactions on Our Site. In all other cases, provision of requested
                    information is optional.
                </p>
                <p className="description">
                    We hope that we can satisfy queries you may have about the way we process your information and
                    resolve any complaints you may have. We encourage you to come to us in the first instance but you
                    are entitled to complain directly to the relevant data protection authority.
                </p>
                <h2 className="heading-1">
                    How to exercise your rights
                </h2>
                <p className="description">
                    You can correct, change or delete your information in your member account settings page. You can
                    stop receiving our newsletters or marketing emails by following the unsubscribe instructions
                    included in these emails or accessing the email preferences in your account settings page.
                    Alternatively, or to access any of the other above rights, you can email our Customer Support at
                    <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a> or by contacting us by postal mail at the contact information listed below.
                </p>
                <h2 className="heading-1">
                    How long we retain your information
                </h2>
                <p className="description">
                    We will retain your information for as long as your account is active or as needed to provide you
                    services. We will retain and use your information as necessary to comply with our legal obligations,
                    resolve disputes, and enforce our agreements, which in some cases involving the collection and
                    processing of financial data may require a retention period of 7 years (or longer if required by
                    law).
                </p>
                <h2 className="heading-1">
                    How we store, process and secure your information
                </h2>
                <h3 className="heading-2">
                    Security
                </h3>
                <p className="description">
                    All information you provide to us via email or Our Site is stored on our secure servers. <br/>
                    Where we have given you (or where you have chosen) a password which enables you to access certain
                    parts of Our Site, you are responsible for keeping this password confidential. We ask you not to
                    share a password with anyone.
                </p>
                <p className="description">
                    The security of your personal data is important to us. When you enter sensitive information (such as
                    bank account information) on our order forms, we encrypt the transmission of that information using
                    secure socket layer technology (SSL).
                </p>
                <p className="description">
                    We follow generally accepted standards to protect the personal data submitted to us, both during
                    transmission and once we receive it. No method of transmission over the Internet, or method of
                    electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security.
                    If you have any questions about security on Our Site, you can contact us at <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>
                </p>
                <h2 className="heading-1">
                    Additional privacy information
                </h2>
                <h3 className="heading-2">
                    Blog / Discussions
                </h3>
                <p className="description">
                    Our Site offers a publicly accessible blog and public discussions. You should be aware that any
                    information you provide in these areas may be read, collected, and used by others who access them.
                    To request removal of your personal data from our blog or community discussions, contact us at
                    <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>. In some cases, we may not be able to remove your personal data, in this case
                    we will let you know if we are unable to do so and why.
                </p>
                <h3 className="heading-2">
                    Links to 3rd Party Sites
                </h3>
                <p className="description">
                    Our Site may, from time to time, contain links to and from the websites of our partner networks,
                    advertisers, affiliates and other third parties. If you follow a link to any of these websites,
                    please note that these websites should have their own privacy policies and we do not accept any
                    responsibility or liability for these policies or the content or operation of these websites. Please
                    check these policies and the terms of the websites before you submit any personal data to these
                    websites.
                </p>
                <h3 className="heading-2">
                    Profiles
                </h3>
                <p className="description">
                    The profile you create on Our Site will be publicly accessible. <br/>
                    We have the right to remove non-venture projects from our platform.
                </p>
                <h3 className="heading-2">
                    Single Sign-On
                </h3>
                <p className="description">
                    You can log in to Our Site using the sign-in service such as LinkedIn, Facebook, Twitter, Google.
                    These services will authenticate your identity and provide you the option to share certain personal
                    data with us such as your name and email address to pre-populate our sign up form. Services like
                    LinkedIn, Facebook, Twitter and Google may give you the option to post information about your
                    activities on Our Site to your profile page to share with others within your network.
                </p>
                <h2 className="heading-1">
                    Changes to our privacy policy
                </h2>
                <p className="description">
                    We may update this privacy policy to reflect changes to our information practices. We encourage you
                    to periodically review this page for the latest information on our privacy practices.
                </p>
                <h2 className="heading-1">
                    Contact
                </h2>
                <p className="description">
                    Questions, comments and requests regarding this privacy policy are welcomed and should be addressed
                    to:
                </p>
                <h2 className="heading-1">
                    Address:
                </h2>
                <p className="description">
                    OU Rocketdao <br/>
                    Estonia <br/>
                    Tallinn, <br/>
                    Lasnamäe linnaosa, <br/>
                    Sepapaja tn 6, <br/>
                    15551. <br/><br/>

                    Office Opening Times: <br/>
                    Mon to Fri - 1000 to 1700 <br/>
                    Saturday - CLOSED <br/>
                    Sunday - CLOSED <br/><br/>

                    Email: <a href="mailto:support@rocketdao.io" target="_blank">support@rocketdao.io</a>
                </p>
            </div>
        );
    }
}

import { useEffect } from 'react';
import {newPayment} from "../actions/User";

export const Tap2Pay = ({product='wMCCMwHV'}) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://secure.tap2pay.me/checkout.v1.js';
        script.async = true;
        script.defer = true;
        script.onload = function(){
            window.T2PH = new window.T2P.Checkout({merchant_id: "oK8qgfUi"});

            window.T2PH.on('complete', function(res) {
                newPayment(res.detail.id, product);
            });
        }

        document.body.appendChild(script);

        return () => {
            script.onload = function(){
              return true;
            };
            document.body.removeChild(script);
        }
    }, []);

    return null
};

export const openProduct = (userId, product='wMCCMwHV') => {
    if(userId){
        if(window.T2PH){
            window.T2PH.openProduct('wMCCMwHV', {custom: `${userId}`});
        }

        return !!window.T2PH;
    } else {
        return 'no user'
    }
};


{/*<script src='https://secure.tap2pay.me/checkout.v1.js'></script>*/}
{/*< script >*/}
{/*var t2pHandler = new T2P.Checkout({merchant_id: "oK8qgfUi"});*/}
{/*</script>*/}
{/*<*/}
{/*button*/}
{/*className = "tap2pay-pay-btn"*/}
{/*type = "button"*/}
{/*onClick = "t2pHandler.openProduct('wMCCMwHV')" >*/}
    {/*Pay*/}
{/*using < i*/}
{/*className = "tap2pay-badge" > < /i><div className="tap2pay-safe-icons"></*/}
{/*div >*/}
{/*< /button>*/}

import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
// import Step0 from './Step0'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'
import Step10 from './Step10'
import Step11 from './Step11'
import Step12 from './Step12'
import Step13 from './Step13'
import Step14 from './Step14'
import Step15 from './Step15'
import Step16 from './Step16'
import Step17 from './Step17'
import Step18 from './Step18'
import Step19 from './Step19'
import Access from '../access';

const Routes = () => (
    <Switch>
        <Route exact path={'/create/access'} render={(props) => ( <Access/>)} />
        <Route exact path={'/create/accelerator'} render={(props) => ( <Step1/>)} />
        <Route path={'/create/accelerator/1'} render={(props) => ( <Step1/>)} />
        <Route path={'/create/accelerator/2'} render={(props) => ( <Step2/>)} />
        <Route path={'/create/accelerator/3'} render={(props) => ( <Step3/>)} />
        <Route path={'/create/accelerator/4'} render={(props) => ( <Step4/>)} />
        <Route path={'/create/accelerator/5'} render={(props) => ( <Step5/>)} />
        <Route path={'/create/accelerator/6'} render={(props) => ( <Step6/>)} />
        <Route path={'/create/accelerator/7'} render={(props) => ( <Step7/>)} />
        <Route path={'/create/accelerator/8'} render={(props) => ( <Step8/>)} />
        <Route path={'/create/accelerator/9'} render={(props) => ( <Step9/>)} />
        <Route path={'/create/accelerator/10'} render={(props) => ( <Step10/>)} />
        <Route path={'/create/accelerator/11'} render={(props) => ( <Step11/>)} />
        <Route path={'/create/accelerator/12'} render={(props) => ( <Step12/>)} />
        <Route path={'/create/accelerator/13'} render={(props) => ( <Step13/>)} />
        <Route path={'/create/accelerator/14'} render={(props) => ( <Step14/>)} />
        <Route path={'/create/accelerator/15'} render={(props) => ( <Step15/>)} />
        <Route path={'/create/accelerator/16'} render={(props) => ( <Step16/>)} />
        <Route path={'/create/accelerator/17'} render={(props) => ( <Step17/>)} />
        <Route path={'/create/accelerator/18'} render={(props) => ( <Step18/>)} />
        <Route path={'/create/accelerator/19'} render={(props) => ( <Step19/>)} />

    </Switch>
);

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes

import React from 'react';

import Select from '../../controls3.0/select/Select'

export default class ViewInput extends React.Component {
    constructor(props) {
        super();
        this.state = {
            selectValue:[],
            selectValue1:[]
        }
        this.select = this.select.bind(this);
    }

    select(key,e) {
        if (key == 1){
            this.setState({
                selectValue: e,
            });
        }else {
            this.setState({
                selectValue1: e,
            });
        }

    }

    render() {

        return (
            <div>
                <div style={{width: '300px', float: 'left', margin: '0 10px'}}>
                    <Select
                        placeholder={'Select'}
                        data={[
                            {Name: 'ItemSelect 1'},
                            {Name: 'Item1Select 2'},
                            {Name: 'Item2Select 3'},
                            {Name: 'Item3Select 4'},
                            {Name: 'Item2Select 5'},
                            {Name: 'Item3Select 6'},
                            {Name: 'Item3Select 7'},
                            {Name: 'Item3Select 8'},
                            {Name: 'Item1Select 9'},
                            {Name: 'ItemSelect 1'},
                            {Name: 'Item1Select 2'},
                            {Name: 'Item2Select 3'},
                            {Name: 'Item3Select 4'},
                            {Name: 'Item2Select 5'},
                            {Name: 'Item3Select 6'},
                            {Name: 'Item3Select 7'},
                            {Name: 'Item3Select 8'},
                            {Name: 'Item1Select 9'},

                        ]}
                        value={[]}
                        onChange={(e) => this.select(1,e)}
                        error={'error'}

                    />
                </div>
                <div style={{width: '300px', float: 'left', margin: '0 10px'}}>
                    <Select
                        placeholder={'Select'}
                        data={[
                            {Name: 'ItemSelect 1'},
                            {Name: 'Item1Select 2'},
                            {Name: 'Item2Select 3'},
                            {Name: 'Item3Select 4'},
                            {Name: 'Item2Select 5'},
                            {Name: 'Item3Select 6'},
                            {Name: 'Item3Select 7'},
                            {Name: 'Item3Select 8'},
                            {Name: 'Item1Select 9'},
                        ]}
                        value={[]}
                        onChange={(e) => console.log(2,e)}

                    />
                </div>

            </div>
        );
    }
}

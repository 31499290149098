import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {gql} from "apollo-boost/lib/index";
import ApolloClient from "apollo-boost/lib/index";
import moment from "moment/moment";
import config from "../../config";
import {isExist} from "../../utils/helper";
import {updateMenu} from "../../actions/menu";
import {createAvatar} from "../user/createAvatar";
import history from "../../history";
import {useQuery} from "@apollo/react-hooks/lib/index";
import { InMemoryCache } from "apollo-cache-inmemory";
import PropTypes from 'prop-types'
import {listSocialsUserForTeam} from "../v3/listSocialsUserForTeam/index";

import ButtonGreen from '../controls3.0/button/ButtonBlue'
import '../expert4.0/style.css'

import {Swiper, Slide} from 'react-dynamic-swiper';

import {getListIndustries, getListFocuses} from "../../actions/Project";

import Loader from '../test-controls/preloader/preloader'

import {NoInformation} from "../v4/NoInformation";
import {Verification} from "../v4/Verification";
import {VerificationIcon} from "../v4/VerificationIcon";
import ExternalAccess from "../v3/popup/externalAccess";

const GET_FUND = gql`
    query nonPublicAccelerator($id: Int!, $verify: String!){
        nonPublicAccelerator(id: $id, verify: $verify){
            id
            title
            shortDescription
            location
            website
            facebookLink
            linkedInLink
            twitterLink
            foundingYear
            industry
            marketFocus
            businessModel
            fundingUpTo
            equityTakenInReturn
            equityFree
            startupsTotal
            numberOfBatches
            startupsFocus
            currentTeamCount
            from
            contacts
            logoFileId
            skills{
                title
            }
            superstarStartups{
                title
            }
            access
            domain
        }
    }
`;

const cache = new InMemoryCache();

const client = new ApolloClient({
    cache,
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const ExternalAccelerator = (props) => {
    const [industries, setIndustries] = useState([]);
    const [gFocuses, setGFocuses] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);

    const outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2).replace(/\.00$/,'') + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2).replace(/\.00$/,'') + 'K';
            }
        }

        return `${r(min)}-${r(max)}`
    };

    useEffect(() => {

        getListIndustries()
            .then((res) => {
                setIndustries(res.data);
            });


        getListFocuses()
            .then((res) => {
                setGFocuses(res.data)
            })

        return () => false;
    }, []);

    const fundType = {
        'simple': 'Fund',
        'angels': 'Angel Network'
    };


    const { loading, error, data, refetch} = useQuery(GET_FUND, {
        client: client,
        fetchPolicy: 'cache-and-network',
        variables: { id: parseInt(props.match.params.id), verify: props.match.params.verify || 'v' },
    });

    const hIamOwner = () => {
        setShowPopUp(!showPopUp);
    };

    return <React.Fragment>
        {/*{!this.props.id && <MetaTags>*/}
            {/*<title>{'Accelerator ' + this.getImgAlt(accelerator.title) + ' - Rocket DAO - uniting startups, investors and experts'}</title>*/}
            {/*<meta property="og:title" content={accelerator.title + ' - Rocket DAO - uniting startups, investors and experts'}/>*/}
            {/*<meta name="description" content={accelerator.shortDescription} />*/}
            {/*<meta property="og:description" content={accelerator.shortDescription}/>*/}
        {/*</MetaTags>}*/}

        <div className='card-wrapper'>

            <div className="block-background-expert"/>
            <div className="block-container-expert">
                <div className="block-box-shadow-cart-startup"/>

                {(() => {
                    if(isExist(() => data.nonPublicAccelerator)){
                        const accelerator = data.nonPublicAccelerator;

                        accelerator.team = [{
                            User: {
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }, {
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }, {
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        },{
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        },{
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }];
                        accelerator.contacts = [];
                        accelerator.industry = [];
                        accelerator.marketFocus = [];

                        let leftPI = [], rightPI = [];

                        const _avatar = accelerator.logoFileId ?
                            <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${accelerator.logoFileId}/view?W=200`})`}}>
                                <img src={`${config.proxy}/file/${accelerator.logoFileId}/view`}/>
                            </div>
                            :
                            createAvatar('photo-cart-expert', accelerator.title);

                        return <React.Fragment>
                            <div className="block-cart-expert-mobile">
                                <div className="block-top-cart-expert-mobile">
                                    <div className="block-avatar-name-top-cart-expert-mobile">
                                        <div className="block-left-top-cart-expert-mobile">
                                            {_avatar}
                                        </div>
                                        <div className="block-right-top-cart-expert-mobile">
                                            <h1 className="title-top-cart-expert-mobile">
                                                {accelerator.title}
                                            </h1>
                                            {accelerator.isProof && <VerificationIcon type={'accelerator'}/>}
                                            <span className="round-prof investor">
                                               Accelerator
                                            </span>
                                        </div>
                                    </div>
                                    <p className="description-top-cart-expert-mobile">
                                        {accelerator.shortDescription}
                                    </p>
                                </div>
                                <div className="block-body-cart-expert-mobile">
                                    <div className="block-list-button-cart-expert-mobile accelerator-right-button-block">
                                        <ButtonGreen
                                            TitleButton={'I am owner'}
                                            EventOnClick={hIamOwner}
                                            paddingButton={'26'}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className="block-list-state-cart-expert-mobile">
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Location</p>
                                            <p className="description-l">{accelerator.location}</p>
                                        </div>

                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Type</p>
                                            <p className="description-l">{accelerator.type}</p>
                                        </div>

                                        < div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{accelerator.businessModelType}</p>
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Funding Up To</p>
                                            {/*<p className="description-l">$ {this.outputRange(accelerator.acceleratorSize.investmentSize.min, accelerator.acceleratorSize.investmentSize.max)}</p>*/}
                                            <p className="description-l">{accelerator.fundingUpTo ? accelerator.fundingUpTo.toMoneyFormat2() : '--'}</p>
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Equity in return,%</p>
                                            <p className="description-l">{
                                                accelerator.equityFree ? 'equity free' : accelerator.equityTakenInReturn
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="block-list-info-cart-expert-mobile">
                                        {accelerator.website && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website:
                                            </p>
                                            <a href={accelerator.website.toUrl()} target="_blank">{accelerator.website.toShowUrl()}</a>
                                        </div>
                                        }

                                        {
                                            gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {accelerator.marketFocus.map(id => {
                                                            return <span
                                                                className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                        }

                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Areas of expertise
                                            </p>
                                            <p className="list-skills-expert">
                                                {accelerator.skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>

                                        {industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Industries of interest
                                                </p>
                                                <p className="list-skills-expert">
                                                    {accelerator.industry.map(id => {
                                                        return <span
                                                            className="skill">{industries.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : ''
                                        }

                                        <div className="block-title-description-info">
                                            <p className="title-info-investor">
                                                Startups in focus
                                            </p>
                                            <p className="description-info-investor">
                                                {accelerator.startupsFocus}
                                            </p>
                                        </div>

                                        <div className="block-title-description-col">
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Startups accelerated total
                                                </p>
                                                <p className="description-info-investor">
                                                    {accelerator.startupsTotal}
                                                </p>
                                            </div>
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of batches
                                                </p>
                                                <p className="description-info-investor">
                                                    {accelerator.numberOfBatches}
                                                </p>
                                            </div>
                                        </div>

                                        {
                                            (() => {
                                                leftPI = [];
                                                rightPI = [];
                                                accelerator.superstarStartups.map((v, i) => {

                                                    let div = <div className="block-previous-investor">
                                                        <div className="block-top-previous-investor"
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                            <p className="title-previous-investor">
                                                                {v.title}
                                                            </p>
                                                            <p className="info-previous-investor">
                                                                Website:
                                                                <span>{v.site.toShowUrl()}</span>
                                                            </p>
                                                            <div className="block-open-close-previous-investor">
                                                                <i className="far fa-chevron-down"/>
                                                            </div>
                                                        </div>
                                                        <div className="block-body-previous-investor">
                                                            <p className="description-previous-investor">
                                                                {v.description}
                                                            </p>
                                                        </div>
                                                    </div>;

                                                    if (i % 2 === 0)
                                                        leftPI.push(div);
                                                    else
                                                        rightPI.push(div);
                                                })
                                            })()
                                        }

                                        <div className="block-list-previous-investor">
                                            <p className="title-block-expert">
                                                Accelerated startups
                                            </p>
                                            {(leftPI.length || rightPI.length) ?
                                                <React.Fragment>
                                                    <div className="block-left-previous-investor">
                                                        {leftPI}
                                                    </div>
                                                    <div className="block-right-previous-investor">
                                                        {rightPI}
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="block-left-previous-investor">
                                                    no information
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="block-cart-expert" >
                                <div className="block-left-cart-expert">
                                    <div className="block-photo-cart-expert">
                                        {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                        {_avatar}

                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Location</p>
                                        <p className="description-l">{accelerator.location || <NoInformation/>}</p>
                                    </div>

                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Type</p>
                                        <p className="description-l">{accelerator.type || <NoInformation/>}</p>
                                    </div>

                                    < div className="block-title-description-left-expert">
                                        <p className="title-l">Business type</p>
                                        <p className="description-l">{accelerator.businessModelType || <NoInformation/>}</p>
                                    </div>

                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Funding Up To</p>
                                        {/*<p className="description-l">$ {this.outputRange(accelerator.acceleratorSize.investmentSize.min, accelerator.acceleratorSize.investmentSize.max)}</p>*/}
                                        <p className="description-l">{accelerator.fundingUpTo ? accelerator.fundingUpTo.toMoneyFormat2() : <NoInformation/>}</p>
                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Equity in return,%</p>
                                        <p className="description-l">{
                                            // accelerator.equityFree ? 'equity free' : accelerator.equityTakenInReturn
                                            <NoInformation/>
                                        }</p>
                                    </div>
                                </div>
                                <div className="block-right-cart-expert">
                                    <div className="block-info-cart-expert block-info-cart-accelerator">
                                        <p className="name-expert" style={{maxWidth: '320px'}}>
                                            <h1>{accelerator.title}</h1>
                                            {accelerator.isProof && <VerificationIcon type={'accelerator'}/>}
                                        </p>
                                        <p className="title-prof">
                                            <span className="round-prof accelerator">
                                                <h2>Accelerator</h2>
                                            </span>
                                        </p>
                                        <p className="description-expert">
                                            {accelerator.shortDescription || <NoInformation/>}
                                        </p>
                                        <div className="accelerator-right-button-block">
                                            <ButtonGreen
                                                TitleButton={'I am owner'}
                                                EventOnClick={hIamOwner}
                                                paddingButton={'26'}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>

                                   <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Website:
                                        </p>
                                       {accelerator.website &&
                                        <a href={accelerator.website.toUrl()} target="_blank">{accelerator.website.toShowUrl()}</a>
                                       || <NoInformation/>
                                       }
                                    </div>

                                    <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Geographic focus
                                        </p>
                                        <p className="list-skills-expert">
                                            {gFocuses.length && accelerator.marketFocus.length && accelerator.marketFocus.map(id => {
                                                return <span
                                                    className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                            }) || <NoInformation lines={2}/>}
                                        </p>
                                    </div>

                                    <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Areas of expertise
                                        </p>
                                        <p className="list-skills-expert">
                                            {accelerator.skills.length && accelerator.skills.map(skill => {
                                                return <span className="skill">{skill.title}</span>
                                            }) || <NoInformation lines={2}/>}
                                        </p>
                                    </div>

                                    <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Industries of interest
                                        </p>
                                        <p className="list-skills-expert">
                                            {industries.length && accelerator.industry.length && accelerator.industry.map(id => {
                                                return <span
                                                    className="skill">{industries.find((a) => a.id === id).title}</span>
                                            }) || <NoInformation lines={2}/>}
                                        </p>
                                    </div>

                                    <div className="block-title-description-info">
                                        <p className="title-info-investor">
                                            Startups in focus
                                        </p>
                                        <p className="description-info-investor">
                                            {accelerator.startupsFocus || <NoInformation lines={2}/>}
                                        </p>
                                    </div>

                                    <div className="block-title-description-col">
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Startups accelerated total
                                            </p>
                                            <p className="description-info-investor">
                                                {accelerator.startupsTotal || <NoInformation/>}
                                            </p>
                                        </div>
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Number of batches
                                            </p>
                                            <p className="description-info-investor">
                                                {accelerator.numberOfBatches || <NoInformation/>}
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        (() => {
                                            leftPI = [];
                                            rightPI = [];
                                            accelerator.superstarStartups.map((v, i) => {

                                                let div = <div className="block-previous-investor">
                                                    <div className="block-top-previous-investor"
                                                         onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                        <p className="title-previous-investor">
                                                            {v.title}
                                                        </p>
                                                        <p className="info-previous-investor">
                                                            Website:
                                                            <span>{v.site.toShowUrl()}</span>
                                                        </p>
                                                        <div className="block-open-close-previous-investor">
                                                            <i className="far fa-chevron-down"/>
                                                        </div>
                                                    </div>
                                                    <div className="block-body-previous-investor">
                                                        <p className="description-previous-investor">
                                                            {v.description}
                                                        </p>
                                                    </div>
                                                </div>;

                                                if (i % 2 === 0)
                                                    leftPI.push(div);
                                                else
                                                    rightPI.push(div);
                                            })
                                        })()
                                    }

                                    <div className="block-list-previous-investor">
                                        <p className="title-block-expert">
                                            Accelerated startups
                                        </p>
                                        {(leftPI.length || rightPI.length) ?
                                            <React.Fragment>
                                                <div className="block-left-previous-investor">
                                                    {leftPI}
                                                </div>
                                                <div className="block-right-previous-investor">
                                                    {rightPI}
                                                </div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <div className="block-left-previous-investor">
                                                    <NoInformation/>
                                                </div>
                                                <div className="block-right-previous-investor">
                                                    <NoInformation/>
                                                </div>
                                            </React.Fragment>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="block-body-expert">

                                <div className='block-body-team'>
                                    {/*<div className='arrow-right'>*/}
                                    {/*    <div className="arrow">*/}
                                    {/*        <i className="far fa-chevron-down"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <p className="title-block-body-team">
                                        Team of <span>{accelerator.currentTeamCount}</span>
                                        {/*<div className="show-all">*/}
                                        {/*    Show all*/}
                                        {/*</div>*/}
                                    </p>

                                    <Swiper
                                        swiperOptions={{
                                            slidesPerView: 4,
                                            // loop: true

                                            freeMode: true,
                                            breakpoints: {
                                                // when window width is <= 320px
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10
                                                },
                                                // when window width is <= 480px
                                                480: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 20
                                                },
                                                // when window width is <= 640px
                                                640: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 30
                                                }
                                            }
                                        }}
                                        navigation={false}
                                        pagination={false}
                                    >
                                        {accelerator.team.map( member => {
                                            return  member.User && <Slide><div className='block-body-team-list' style={{opacity: '0.3', filter: 'grayscale(1)'}}>
                                                <div className='content-box'>
                                                    {/*<div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}

                                                    {
                                                        member.User.logoFileId
                                                        && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                                        || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>
                                                    }

                                                    {/*<p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>*/}
                                                    <p className='list-position'>{member.User.id === accelerator.userId ? 'Admin' : 'Member'}</p>
                                                    {listSocialsUserForTeam(member.User)}
                                                </div>
                                            </div></Slide>
                                        })}
                                    </Swiper>
                                </div>

                                {/*{!!listAchievements.length &&*/}

                                {/*<div className="block-achievements-investor">*/}
                                {/*<p className="title-achievements-investor">Achievements</p>*/}
                                {/*<div className="block-list-tab-achievements-investor">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {*/}
                                {/*return <TabsCounter*/}
                                {/*TitleTab={v.item.RoleParameter.title}*/}
                                {/*TypeTab={'big'}*/}
                                {/*EventOnClick={() => this.setActiveAch(v.item.parameterId)}*/}
                                {/*active={v.item.parameterId === this.state.activeAchivements}*/}
                                {/*count={v.data.length}*/}
                                {/*/>*/}
                                {/*})*/}
                                {/*}*/}

                                {/*</div>*/}

                                {/*<div className="block-list-achievements-investor">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(0,6).map(v => {*/}
                                {/*return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>*/}
                                {/*<p className="title-item-achievements-investor">*/}
                                {/*{v.description}*/}
                                {/*</p>*/}
                                {/*<div*/}
                                {/*className="block-right-achievements-cart-share">*/}
                                {/*<a href={v.link.toUrl()}*/}
                                {/*rel="noopener noreferrer"*/}
                                {/*target="_blank"> <i*/}
                                {/*className="fas fa-share-square"/>*/}
                                {/*</a>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*})*/}
                                {/*}*/}


                                {/*<div className="open-close-block">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {*/}
                                {/*return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>*/}
                                {/*<p className="title-item-achievements-investor">*/}
                                {/*{v.description}*/}
                                {/*</p>*/}
                                {/*<div*/}
                                {/*className="block-right-achievements-cart-share">*/}
                                {/*<a href={v.link.toUrl()}*/}
                                {/*rel="noopener noreferrer"*/}
                                {/*target="_blank"> <i*/}
                                {/*className="fas fa-share-square"/>*/}
                                {/*</a>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*})*/}
                                {/*}*/}
                                {/*</div>*/}


                                {/*{this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&*/}
                                {/*<div style={{float: 'none', clear: 'both'}} className="block-item-achievements-investor" onClick={(e) => {*/}
                                {/*this.setState({showMoreAch: !this.state.showMoreAch});*/}
                                {/*this.OpenCloseContentClick(e, '.open-close-block');*/}

                                {/*}}>*/}

                                {/*<p className="title-item-achievements-investor show">*/}
                                {/*{!this.state.showMoreAch && <span className="show-more">Show more <i className="far fa-chevron-down"/></span>}*/}
                                {/*{this.state.showMoreAch && <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}*/}
                                {/*</p>*/}
                                {/*</div>*/}
                                {/*}*/}

                                {/*</div>*/}


                                {/*</div>*/}
                                {/*}*/}
                            </div>

                            <div className="block-social-link-expert">

                                <p className="title-social-link">
                                    Follow us:
                                    {accelerator.twitterLink ? <a target="_blank" href={accelerator.twitterLink.toUrl()}><i className="fab fa-twitter"/></a> : <a><i className="fa fa-square"/></a>}
                                    {accelerator.facebookLink ? <a target="_blank" href={accelerator.facebookLink.toUrl()}><i className="fab fa-facebook"/></a> : <a><i className="fa fa-square"/></a>}
                                    {accelerator.linkedInLink ? <a target="_blank" href={accelerator.linkedInLink.toUrl()}><i className="fab fa-linkedin"/></a> : <a><i className="fa fa-square"/></a>}
                                    {accelerator.mediumLink ? <a target="_blank" href={accelerator.mediumLink.toUrl()}><i className="fab fa-medium"/></a> : <a><i className="fa fa-square"/></a>}
                                    {accelerator.telegramLink ? <a target="_blank" href={accelerator.telegramLink.toUrl()}><i className="fab fa-telegram"/></a> : <a><i className="fa fa-square"/></a>}

                                    {accelerator.contacts.map((contact) => {
                                        let classn = 'fa fa-link'
                                        if (/twitter/.test(contact)) {
                                            classn = 'fa-twitter'
                                        }
                                        if (/facebook/.test(contact)) {
                                            classn = 'fa-facebook'
                                        }
                                        if (/linked/.test(contact)) {
                                            classn = 'fa-linkedin'
                                        }
                                        if (/medium/.test(contact)) {
                                            classn = 'fa-medium'
                                        }
                                        if (/telegram/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }
                                        if (/t.me/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }

                                        return <a href={contact.toUrl()} target="_blank"><i
                                            className={`fab ${classn}`}/></a>
                                    })}
                                </p>

                            </div>
                            {showPopUp &&  <ExternalAccess
                                next={() => {history.push(`/create/accelerator/1?name=${accelerator.title}&ac=1`)}}
                                domain={accelerator.domain}
                                onClose={hIamOwner}/>}
                        </React.Fragment>
                    }
                    else {
                        return  <Loader loaded={false} isLocal={true}/>
                    }
                })()}


            </div>
        </div>

    </React.Fragment>
};

const mapStateToProps = (state) => ({
    ...state
});

ExternalAccelerator.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps, {updateMenu})(ExternalAccelerator);
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {regexUrl} from './../../../utils/RegExp'
import Input from "../../controls3.0/input/Input";


class Step9 extends React.Component {
    onChange = e => {
        console.log(e);
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors;
        value = value.trim();

        switch (name) {
            case 'linkedInLink':
            case 'twitterLink':
            case 'facebookLink':
            case 'mediumLink':
            case 'telegramLink':
                if (!value || regexUrl.test(value)) {
                    delete errors[name]
                } else {
                    errors[name] = 'Wrong link format'
                }
                break;
        }

        this.setState({
            user: {...this.state.user, [name]: value},
            errors: errors
        })

    }
    update = (nextStep, onlyStep) => {

        let user = this.state.user,
            data = {
                linkedInLink: user.linkedInLink,
                twitterLink: user.twitterLink,
                facebookLink: user.facebookLink,
                mediumLink: user.mediumLink,
                telegramLink: user.telegramLink
            };

        if (onlyStep) data = {}

        let errors = onlyStep ? {} : this.validate(data)
        this.setState({errors})

        let errorRequired = !onlyStep && (!user.linkedInLink && !user.twitterLink && !user.facebookLink && !user.mediumLink && !user.telegramLink)

        if (errorRequired) {
            this.setState({errorRequired: 'You must add at least one link'})
        }
        setTimeout(() => {
            this.setState({errorRequired: false})
        }, 2000)

        if (!Object.keys(errors).length) {
            let loading = nextStep == 8 ? 'loadingBack' : 'loading';
            this.setState({[loading]: true})
            this.props.updateExpert(data, onlyStep, nextStep)
                .catch(err => {
                    this.setState({[loading]: false})
                })
        }
    }


    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            errors: {}
        }
    }

    validate(data) {
        const errors = {}
        console.log(data)

        for (let key in data) {
            switch (key) {
                case 'linkedInLink':
                case 'twitterLink':
                case 'facebookLink':
                case 'mediumLink':
                    if (data[key] && !regexUrl.test(data[key])) {
                        errors[key] = 'Wrong format'
                    } else {
                        delete errors[key]
                    }
                    break;
                case 'telegramLink':
                    break;
            }
        }
        return errors;
    }


    render() {
        let {user, expert, errors} = this.state,
            hasOne = user.linkedInLink || user.twitterLink || user.facebookLink || user.mediumLink || user.telegramLink;

        return (
            <React.Fragment>
                <div className="container-full-reg">
                    <div className="block-9-top">
                        <div className="container-step-2-reg text-left">
                            <p className="text-h1 color-text-reg-black">Social networks links</p>
                            <p className="text-h2 color-text-reg-gray">
                                Make connections with other enthusiastic experts <br/>
                                easier! Add links to your social network profiles.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="block-gradient-gray-reg">
                    <div className="container-full-reg padding-reg">
                        <div className="block-9-bottom" style={{padding: '60px 0 0 0'}}>
                            <div className="container-step-2-reg">
                                <div className="float-left-49 no-float-620">
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'linkedInLink'}
                                            value={user.linkedInLink}
                                            onChange={this.onChange}
                                            error={errors.linkedInLink}
                                            placeholder={'LinkedIn Link'}
                                            iconClass={'fab fa-linkedin-in'}
                                        />
                                    </div>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'mediumLink'}
                                            value={user.mediumLink}
                                            onChange={this.onChange}
                                            error={errors.mediumLink}
                                            placeholder={'Medium Link'}
                                            iconClass={'fab fa-medium'}
                                        />
                                    </div>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'telegramLink'}
                                            value={user.telegramLink}
                                            onChange={this.onChange}
                                            error={errors.telegramLink}
                                            placeholder={'Telegram Link'}
                                            iconClass={'fab fa-telegram'}
                                        />
                                    </div>
                                </div>
                                <div className="float-right-49 no-float-620">
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'facebookLink'}
                                            value={user.facebookLink}
                                            onChange={this.onChange}
                                            error={errors.facebookLink}
                                            placeholder={'Facebook Link'}
                                            iconClass={'fab fa-facebook'}
                                        />
                                    </div>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'twitterLink'}
                                            value={user.twitterLink}
                                            onChange={this.onChange}
                                            error={errors.twitterLink}
                                            placeholder={'Twitter Link'}
                                            iconClass={'fab fa-twitter'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(25, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(10)}
                                paddingButton={'56'}
                                disabled={Object.keys(errors).length || !hasOne}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step9.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step9)

import React from 'react';
import axios from "axios";
import Input from "../../../controls3.0/input/Input";
import InputRange from "react-input-range";
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";



export default class StartupDashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        console.log(this.props.startup)
    }



    render() {
        const {startup} = this.props;
        return (
            <div className='block-fund-newsfeed'>
                <div className='profile-dashboard-main-title font-weight-500'>Dashboard</div>
                {startup && <div className='block-dashboard-buttons-line'>
                    <div className="dashboard-button likes">
                        <div className="db-title font-weight-500">Likes</div>
                        <div className="db-description">{startup.likes}</div>
                    </div>

                    <div className="dashboard-button views">
                        <div className="db-title font-weight-500">Views</div>
                        <div className="db-description"> {startup.views}</div>
                    </div>

                    <div className="dashboard-button wish">
                        <div className="db-title font-weight-500">Waitlist</div>
                        <div className="db-description">${startup.invest_sum ? startup.invest_sum.moneyFormat() : Number(0).moneyFormat()}</div>
                    </div>
                </div> }
                <div className='block-newsfeed-temp-text'>
                <div className='profile-dashboard-16-title font-weight-500'>The full version of the dashboard is now being developed. It will come very soon!</div>
                <br/>
                <div className='profile-dashboard-16-title'>Here you will:
                <br/>- see profile statistics: the number of visits and the geography of these users
                <br/>- see profiles of experts who 'liked' your project
                <br/>- see profiles of the investors who joined the waitlist
                </div>
                </div>
        <div className='block-dashboard-temp-content'/>
        {/*<div className='block-dashboard-shares'>*/}
        {/*<div className='shares-title'>Shares</div>*/}
        {/*<div className='social-group-line'>*/}
        {/*<div className='social-group'>*/}
        {/*<div className='social-facebook'/>*/}
        {/*<span className='count'>423</span>*/}
        {/*</div>*/}
        {/*<div className='social-group'>*/}
        {/*<div className='social-twitter'/>*/}
        {/*<span className='count'>2K</span>*/}
        {/*</div>*/}
        {/*<div className='social-group'>*/}
        {/*<div className='social-linkedin'/>*/}
        {/*<span className='count'>271</span>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*</div>*/}
        </div>
        )
    }
}
import React from 'react';
import WayPoint from '../wayPoint/WayPoint'
import BlackButtonProfile from '../blackButtonProfile/BlackButtonProfile'

import EditProfileList from '../editProfileList/EditProfileList'
import config from '../../../../config';
import history from '../../../../history';
import './style.css'
import Loader from './../../../test-controls/preloader/preloader'

export default class StartupMenuProfile extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
        openedMenu: 'edit'
    }

    menuOpen = (type, state = {active: true}) => {
        if(type !== 'edit'){
            history.push(`/edit/startup/${this.props.startup.id}/${type}`, state)
        } else {
            history.push(`/edit/startup/${this.props.startup.id}`, state)
        }
    }

    render() {
        let {
            startup,
        } = this.props;

        return (
            startup && <div className='fund-menu-profile' onClick={()=>{console.log(startup)}}>
                <div className='block-fund-sidebar'>
                    <div className='block-fund-info'>
                        <div className='avatar' style={{backgroundImage: `url(${startup ? `${config.proxy}/file/${startup.logoFileId}/view`: ''})`}}/>
                        <div className='name-title'>{startup ? startup.title : ''}</div>
                    </div>
                    <div className='block-fund-buttons-line'>
                        <BlackButtonProfile
                            onClick={() => startup ? window.open(`/startup/${startup.id}` ,'_blank') : null}
                            ButtonTitle='See profile'
                        />
                        <div className='button-edit-profile' onClick = {() => {this.menuOpen('edit')}}>
                            <BlackButtonProfile
                                ButtonTitle='Edit'
                            />
                        </div>
                    </div>


                    <div className='block-startup-way'>
                        <WayPoint
                            name='Profile'
                            descr={`${startup.views} views`}
                            NumberActive={'active'}
                            isDescrActive={true}
                            number=''
                            isLast={false}
                        />
                        <div  onClick = {() => {this.menuOpen('evaluation')}}>
                            <WayPoint
                                name='Preliminary evaluation'
                                descr={`${startup.id === 441?`${startup.views || 0} views`:'Not completed yet'}`}
                                NumberActive={`${startup.id === 441?'active':'default'}`}
                                isDescrActive={true}
                                number=''
                                isLast={false}
                            />
                        </div>
                        <WayPoint
                            name='Metrics'
                            descr='Not completed yet'
                            NumberActive={'default'}
                            isDescrActive={false}
                            number=''
                            isLast={false}
                        />
                        <WayPoint
                            name='Business Plan '
                            descr=''
                            NumberActive={'closed'}
                            isDescrActive={false}
                            number=''
                            isLast={false}
                        />
                        <WayPoint
                            name='Due Diligence'
                            descr=''
                            NumberActive={'closed'}
                            isDescrActive={false}
                            number=''
                            isLast={false}
                        />
                        <WayPoint
                            name='Fundraising'
                            descr=''
                            NumberActive={'closed'}
                            isDescrActive={false}
                            number=''
                            isLast={true}
                        />


                    </div>

                    <div className='block-main-menu'>
                        <div className='main-menu-title-block'>
                            <span className='main-menu-title' onClick = {() => {this.menuOpen('dashboard')}}>Dashboard</span>
                        </div>
                        <div className='main-menu-title-block'>
                            <span className='main-menu-title' onClick = {() => {this.menuOpen('community')}}>Community</span>
                        </div>
                        <div className='main-menu-title-block'>
                            <span className='main-menu-title' onClick = {() => {this.menuOpen('team')}} style={{float:'left'}}>Team</span>
                            <div className='team-number'><div>{startup.teamCount}/{startup.membersCount}</div></div>
                        </div>

                    </div>
                </div>
            </div> || <Loader isLocal={true}/>
        );
    }
}
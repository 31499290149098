import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonBlue'
import ButtonBorder from '../controls3.0/button/ButtonBorder'
import _bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import '../expert4.0/style.css'
import './style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import config from './../../config'

import axios from "axios";
import {getAchievementInvestor, tokenLogin} from "../../actions/Login";
import {getAchievement} from "../../actions/Accelerator";

import PropTypes from "prop-types";
import {getListIndustries, getListFocuses} from "../../actions/Project";
import {Swiper, Slide} from 'react-dynamic-swiper';
import {Comments} from '../v3/comments';
import {createAvatar} from "../user/createAvatar";
import Loader from '../test-controls/preloader/preloader'
import {listSocialsUserForTeam} from "../v3/listSocialsUserForTeam";
import EditMyPageButton from  '../v3/editMyPage';
import MetaTags from "react-meta-tags";
import history from '../../history';
import ButtonBlack from "../controls3.0/button/ButtonBlack";
import HeaderStickyRole from "../controls3.0/headerStickyRole";
import {viewCounter} from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";
import {VerificationIcon} from "../v4/VerificationIcon";
import ExternalAccelerator from "../external/accelerator";
import {isExist} from "../../utils/helper";


const TYPES = [];
TYPES[0] = {
    id: 0,
    title: 'Articles',
    description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
};
TYPES[1] = {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'};
TYPES[2] = {
    id: 2,
    title: 'Diploma',
    description: 'Documents and any documentary proof of your educational background.'
};
TYPES[3] = {
    id: 3,
    title: 'Projects',
    description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
};
TYPES[4] = {
    id: 4,
    title: 'Mass media',
    description: 'Links to the articles about you or about your professional experience.'
};
TYPES[5] = {
    id: 5,
    title: 'Recommendations',
    description: 'Reviews of your expert works and professional collaboration.'
};

Date.prototype.toShortFormat = function () {

    const month_names = ["Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
}

String.prototype.toUrl = function () {
    var re = new RegExp("^(http|https)://", "i");

    if(re.test(this)){
        console.log(this);
        return this;
    }
    if(re.test(this)){
        return this;
    }
    return 'http://'+this;
}

String.prototype.toShowUrl = function () {
    return this.replace('https://', '').replace('http://', '');
}


class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accelerator: {},
            user: props.user || {},
            logo: avatar,
            listAchievements: [],
            experience: [],
            industries: [],
            gFocuses: [],
            id: this.props.id || props.match.params.id,
            activeAchivements: -1,
            comments: [],
            currentComment: '',
            activeSticky:false,
            showLoginPopup: 'hide'
        }
        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.setActiveAch = this.setActiveAch.bind(this);
    }

    componentDidMount() {

        if( !viewCounter() ) {
            if(this.props.id){

                const commandProps = {
                    to: '/',
                    id: this.props.id,
                    type: 'accelerator',
                    f: 'tpc'
                };

                localStorage.removeItem("rFrom");
                localStorage.removeItem("referer");
                localStorage.removeItem("referer_startup");

                localStorage.setItem("commandProps", JSON.stringify(commandProps));

                this.setState({showLoginPopup: 'content'});
            } else {
                this.setState({showLoginPopup: 'popup'});
            }
        }

        getListIndustries()
            .then((res) => {
                this.setState({industries: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        getListFocuses()
            .then((res) => {
                this.setState({gFocuses: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        axios.get(`/v2/accelerator/${this.state.id}/public`)
            .then(r => {

                if(!r.data.superstarStartups){
                    r.data.superstarStartups = [];
                }
                this.setState({accelerator: r.data});
                const logo = r.data.logoFileId ? `${config.proxy}/file/${r.data.logoFileId}/view` : avatar;
                const bg = r.data.backgroundFileId ? `${config.proxy}/file/${r.data.backgroundFileId}/view` : _bg;
                this.setState({logo, bg});

                try {
                    let obj = [];
                    Promise.all(
                        r.data.parameters.map(async (item, key) => {
                            let achievement = await getAchievement(item.parameterId, this.state.id);
                            if(achievement.data.length){
                                obj[item.parameterId] = {item: item, data: achievement.data};
                            }
                            return (achievement.data)
                        })
                    ).then(results => {
                        this.setState({listAchievements: obj});
                        this.setState({activeAchivements: parseInt(Object.keys(obj)[0])});
                    })
                } catch (e) {
                    console.log(e);
                }

            }).catch(e => {
            if(e.response.status === 404  || e.response.internalMessage === 'accelerator_NOT_FOUND'){
                history.push('/404');
            }
        })
        // this.stickyHeaderCart();
        if(process.env.NODE_ENV !== 'production'){
            window.addEventListener('scroll',this.stickyBG);
        }
    }
    componentWillUnmount() {
        if(process.env.NODE_ENV !== 'production') {
            window.removeEventListener('scroll', this.stickyBG);
        }
    }

    // stickyHeaderCart = () => {
    //     let timer = setInterval(() => {
    //
    //         if (window.pageYOffset > 300) {
    //             this.setState({activeSticky:true})
    //         }else {
    //             this.setState({activeSticky:false})
    //         }
    //         // clearInterval(timer);
    //     }, 500);
    //
    // }
    stickyBG = () => {
        if (window.pageYOffset > 174) {
            $('.card-wrapper').addClass('on-scroll');
            this.setState({activeSticky:true})
        } else {
            $('.card-wrapper').removeClass('on-scroll');
            this.setState({activeSticky:false})
        }
    };

    componentWillReceiveProps(nextProps){
        if(this.props.id){
            if(nextProps.id != this.state.id) {
                this.setState({...nextProps}, () => {
                    this.componentDidMount();
                })
            }
        } else {
            if(nextProps.id != this.state.id) {
                this.setState({user: nextProps.user})
            }
        }
    }

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');
        if ($(e.currentTarget).parent().hasClass('active')) {
            $(e.currentTarget).parent().removeClass('active')
        }else {
            $(e.currentTarget).parent().addClass('active')
        }
    }

    setActiveAch = (id) => {
        this.setState({activeAchivements: id});
    }

    outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2).replace(/\.00$/,'') + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2).replace(/\.00$/,'') + 'K';
            }
        }

        return `${r(min)}-${r(max)}`
    }

    getImgAlt = (title) => {
        if(title){
            return  title.replace(/ /gi, '_');
        }
    };

    render() {
        const {accelerator, logo, listAchievements, industries, gFocuses, comments, user} = this.state;
        let leftPI = [];
        let rightPI = [];

        const _avatar = accelerator.logoFileId ?
            <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${accelerator.logoFileId}/view?W=200`})`}}>
                <img src={`${config.proxy}/file/${accelerator.logoFileId}/view`} alt={'accelerator ' + this.getImgAlt(accelerator.title)} />
            </div>
            :
            createAvatar('photo-cart-expert', accelerator.title);

        if (!accelerator.id) {
            return (<React.Fragment>
                <Loader loaded={!!accelerator.id} isLocal={!!this.props.id}/>
            </React.Fragment>);
        } else {

            if(accelerator.userId){
                return (
                    <React.Fragment>
                        {!this.props.id && <MetaTags>
                            <title>{'Accelerator ' + this.getImgAlt(accelerator.title) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                            <meta property="og:title" content={accelerator.title + ' - Rocket DAO - uniting startups, investors and experts'}/>
                            <meta name="description" content={accelerator.shortDescription} />
                            <meta property="og:description" content={accelerator.shortDescription}/>
                        </MetaTags>}

                        {this.state.showLoginPopup !== 'content' && <div className='card-wrapper'>


                            <div className={`block-sticky-role-cart ${this.state.activeSticky ? 'active' : ''}`}>
                                <HeaderStickyRole

                                    name={accelerator.title}
                                    activeCheck={accelerator.isProof}
                                    urlAvatar={accelerator.logoFileId ? `${config.proxy}/file/${accelerator.logoFileId}/view?W=80` : null}


                                    type={'accelerator'} // expert, fund, investor, startup
                                    titleType={null}
                                    description={null}
                                    location={accelerator.location}
                                    languageOrSize={accelerator.fundingUpTo ? accelerator.fundingUpTo.toMoneyFormat2() : '--'}
                                    eventButton={null} // only startup
                                    customButton={((user && user.id !== accelerator.userId) || !user) && <ButtonGreen
                                        TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                        EventOnClick={null}
                                        paddingButton={'26'}
                                        disabled={true}
                                        onMouseEnter={() => this.setState({mouseEnter: true}) }
                                        onMouseLeave={() => this.setState({mouseEnter: false}) }
                                    /> || <EditMyPageButton id={accelerator.id} type={'accelerator'}/>}


                                />

                            </div>


                            <div className="block-background-expert">
                                <img src={this.state.bg} alt={'load'}/>
                            </div>

                            <div className="block-container-expert">
                                <div className="block-box-shadow-cart-startup"/>



                                <div className="block-cart-expert-mobile">
                                    <div className="block-top-cart-expert-mobile">
                                        <div className="block-avatar-name-top-cart-expert-mobile">
                                            <div className="block-left-top-cart-expert-mobile">
                                                {_avatar}
                                            </div>
                                            <div className="block-right-top-cart-expert-mobile">
                                                <h1 className="title-top-cart-expert-mobile">
                                                    {accelerator.title}
                                                    {accelerator.isProof && <VerificationIcon
                                                        type={'accelerator'}
                                                        className={'sticky'}
                                                    />
                                                    }
                                                </h1>

                                                <span className="round-prof investor">
                                               Accelerator
                                            </span>
                                            </div>
                                        </div>
                                        <p className="description-top-cart-expert-mobile">
                                            {accelerator.shortDescription}
                                        </p>
                                    </div>
                                    <div className="block-body-cart-expert-mobile">
                                        <div className="block-list-button-cart-expert-mobile accelerator-right-button-block">
                                            {((user && user.id !== accelerator.userId) || !user) && <ButtonGreen
                                                TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                EventOnClick={null}
                                                paddingButton={'26'}
                                                disabled={true}
                                                onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                onMouseLeave={() => this.setState({mouseEnter: false}) }
                                            /> || <EditMyPageButton id={accelerator.id} type={'accelerator'}/>}
                                        </div>
                                        <div className="block-list-state-cart-expert-mobile">
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Location</p>
                                                <p className="description-l">{accelerator.location}</p>
                                            </div>

                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Type</p>
                                                <p className="description-l">{accelerator.type}</p>
                                            </div>

                                            < div className="block-title-description-left-expert">
                                                <p className="title-l">Business type</p>
                                                <p className="description-l">{accelerator.businessModelType}</p>
                                            </div>
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Funding Up To</p>
                                                {/*<p className="description-l">$ {this.outputRange(accelerator.acceleratorSize.investmentSize.min, accelerator.acceleratorSize.investmentSize.max)}</p>*/}
                                                <p className="description-l">{accelerator.fundingUpTo ? accelerator.fundingUpTo.toMoneyFormat2() : '--'}</p>
                                            </div>
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Equity in return,%</p>
                                                <p className="description-l">{
                                                    accelerator.equityFree ? 'equity free' : accelerator.equityTakenInReturn
                                                }</p>
                                            </div>
                                        </div>
                                        <div className="block-list-info-cart-expert-mobile">
                                            {accelerator.website && <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Website:
                                                </p>
                                                <a href={accelerator.website.toUrl()} target="_blank">{accelerator.website.toShowUrl()}</a>
                                            </div>
                                            }

                                            {
                                                gFocuses.length ?
                                                    <div className="block-skills-expert">
                                                        <p className="title-block-expert">
                                                            Geographic focus
                                                        </p>
                                                        <p className="list-skills-expert">
                                                            {accelerator.marketFocus.map(id => {
                                                                return <span
                                                                    className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                            })}
                                                        </p>
                                                    </div> : ''
                                            }

                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Areas of expertise
                                                </p>
                                                <p className="list-skills-expert">
                                                    {accelerator.skills.map(skill => {
                                                        return <span className="skill">{skill.title}</span>
                                                    })}
                                                </p>
                                            </div>

                                            {industries.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Industries of interest
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {accelerator.industry.map(id => {
                                                            return <span
                                                                className="skill">{industries.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                            }

                                            <div className="block-title-description-info">
                                                <p className="title-info-investor">
                                                    Startups in focus
                                                </p>
                                                <p className="description-info-investor">
                                                    {accelerator.startupsFocus}
                                                </p>
                                            </div>

                                            <div className="block-title-description-col">
                                                <div className="block-col-title-description">
                                                    <p className="title-info-investor">
                                                        Startups accelerated total
                                                    </p>
                                                    <p className="description-info-investor">
                                                        {accelerator.startupsTotal}
                                                    </p>
                                                </div>
                                                <div className="block-col-title-description">
                                                    <p className="title-info-investor">
                                                        Number of batches
                                                    </p>
                                                    <p className="description-info-investor">
                                                        {accelerator.numberOfBatches}
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                (() => {
                                                    leftPI = [];
                                                    rightPI = [];
                                                    accelerator.superstarStartups.map((v, i) => {

                                                        let div = <div className="block-previous-investor">
                                                            <div className="block-top-previous-investor"
                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                                <p className="title-previous-investor">
                                                                    {v.title}
                                                                </p>
                                                                <p className="info-previous-investor">
                                                                    Website:
                                                                    <span>{v.site.toShowUrl()}</span>
                                                                </p>
                                                                <div className="block-open-close-previous-investor">
                                                                    <i className="far fa-chevron-down"/>
                                                                </div>
                                                            </div>
                                                            <div className="block-body-previous-investor">
                                                                <p className="description-previous-investor">
                                                                    {v.description}
                                                                </p>
                                                            </div>
                                                        </div>;

                                                        if (i % 2 === 0)
                                                            leftPI.push(div);
                                                        else
                                                            rightPI.push(div);
                                                    })
                                                })()
                                            }

                                            <div className="block-list-previous-investor">
                                                <p className="title-block-expert">
                                                    Accelerated startups
                                                </p>
                                                {(leftPI.length || rightPI.length) ?
                                                    <React.Fragment>
                                                        <div className="block-left-previous-investor">
                                                            {leftPI}
                                                        </div>
                                                        <div className="block-right-previous-investor">
                                                            {rightPI}
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <div className="block-left-previous-investor">
                                                        no information
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div className="block-cart-expert">
                                    <div className="block-left-cart-expert">
                                        <div className="block-photo-cart-expert">
                                            {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                            {_avatar}

                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Location</p>
                                            <p className="description-l">{accelerator.location}</p>
                                        </div>

                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Type</p>
                                            <p className="description-l">{accelerator.type}</p>
                                        </div>

                                        < div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{accelerator.businessModelType}</p>
                                        </div>

                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Funding Up To</p>
                                            {/*<p className="description-l">$ {this.outputRange(accelerator.acceleratorSize.investmentSize.min, accelerator.acceleratorSize.investmentSize.max)}</p>*/}
                                            <p className="description-l">{accelerator.fundingUpTo ? accelerator.fundingUpTo.toMoneyFormat2() : '--'}</p>
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Equity in return,%</p>
                                            <p className="description-l">{
                                                accelerator.equityFree ? 'equity free' : accelerator.equityTakenInReturn
                                            }</p>
                                        </div>
                                    </div>
                                    <div className="block-right-cart-expert">
                                        <div className="block-info-cart-expert block-info-cart-accelerator">
                                            <p className="name-expert accelerator-title">
                                                <h1>{accelerator.title}</h1>
                                                {accelerator.isProof && <VerificationIcon type={'accelerator'} className='sticky'/>}
                                            </p>
                                            <p className="title-prof">
                                            <span className="round-prof accelerator">
                                                <h2>Accelerator</h2>
                                            </span>
                                            </p>
                                            <p className="description-expert">
                                                {accelerator.shortDescription}
                                            </p>
                                            <div className="accelerator-right-button-block">
                                                {((user && user.id !== accelerator.userId) || !user) && <ButtonGreen
                                                    TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                    EventOnClick={null}
                                                    paddingButton={'26'}
                                                    disabled={true}
                                                    onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                    onMouseLeave={() => this.setState({mouseEnter: false}) }
                                                /> || <EditMyPageButton id={accelerator.id} type={'accelerator'}/>}
                                            </div>
                                        </div>

                                        {accelerator.website && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website:
                                            </p>
                                            <a href={accelerator.website.toUrl()} target="_blank">{accelerator.website.toShowUrl()}</a>
                                        </div>
                                        }

                                        {
                                            gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {accelerator.marketFocus.map(id => {
                                                            return <span
                                                                className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                        }

                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Areas of expertise
                                            </p>
                                            <p className="list-skills-expert">
                                                {accelerator.skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>

                                        {industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Industries of interest
                                                </p>
                                                <p className="list-skills-expert">
                                                    {accelerator.industry.map(id => {
                                                        return <span
                                                            className="skill">{industries.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : ''
                                        }

                                        <div className="block-title-description-info">
                                            <p className="title-info-investor">
                                                Startups in focus
                                            </p>
                                            <p className="description-info-investor">
                                                {accelerator.startupsFocus}
                                            </p>
                                        </div>

                                        <div className="block-title-description-col">
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Startups accelerated total
                                                </p>
                                                <p className="description-info-investor">
                                                    {accelerator.startupsTotal || 'no information'}
                                                </p>
                                            </div>
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of batches
                                                </p>
                                                <p className="description-info-investor">
                                                    {accelerator.numberOfBatches || 'no information'}
                                                </p>
                                            </div>
                                        </div>

                                        {
                                            (() => {
                                                leftPI = [];
                                                rightPI = [];
                                                accelerator.superstarStartups.map((v, i) => {

                                                    let div = <div className="block-previous-investor">
                                                        <div className="block-top-previous-investor"
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                            <p className="title-previous-investor">
                                                                {v.title}
                                                            </p>
                                                            <p className="info-previous-investor">
                                                                Website:
                                                                <span>{v.site.toShowUrl()}</span>
                                                            </p>
                                                            <div className="block-open-close-previous-investor">
                                                                <i className="far fa-chevron-down"/>
                                                            </div>
                                                        </div>
                                                        <div className="block-body-previous-investor">
                                                            <p className="description-previous-investor">
                                                                {v.description}
                                                            </p>
                                                        </div>
                                                    </div>;

                                                    if (i % 2 === 0)
                                                        leftPI.push(div);
                                                    else
                                                        rightPI.push(div);
                                                })
                                            })()
                                        }

                                        <div className="block-list-previous-investor">
                                            <p className="title-block-expert">
                                                Accelerated startups
                                            </p>
                                            {(leftPI.length || rightPI.length) ?
                                                <React.Fragment>
                                                    <div className="block-left-previous-investor">
                                                        {leftPI}
                                                    </div>
                                                    <div className="block-right-previous-investor">
                                                        {rightPI}
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="block-left-previous-investor">
                                                    no information
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block-body-expert">

                                    <div className='block-body-team'>
                                        {/*<div className='arrow-right'>*/}
                                        {/*    <div className="arrow">*/}
                                        {/*        <i className="far fa-chevron-down"/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <p className="title-block-body-team">
                                            Team of <span>{accelerator.currentTeamCount}</span>
                                            {/*<div className="show-all">*/}
                                            {/*    Show all*/}
                                            {/*</div>*/}
                                        </p>

                                        <Swiper
                                            swiperOptions={{
                                                slidesPerView: 4,
                                                // loop: true

                                                freeMode: true,
                                                breakpoints: {
                                                    // when window width is <= 320px
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 10
                                                    },
                                                    // when window width is <= 480px
                                                    480: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20
                                                    },
                                                    // when window width is <= 640px
                                                    640: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 30
                                                    }
                                                }
                                            }}
                                            navigation={false}
                                            pagination={false}
                                        >
                                            {accelerator.team.map( member => {

                                                console.log(member.User.id , accelerator.userId);

                                                return  member.User && <Slide onClick={() => window.open(`/user/${member.User.id}`, '_blank')}><div className='block-body-team-list'>


                                                    <div className='content-box'>
                                                        {
                                                            member.User.logoFileId
                                                            && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                                            || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>
                                                        }

                                                        {
                                                            (member.User.id === accelerator.userId ||
                                                                member.isFounder) &&
                                                                <span className='label-is-founder'>{member.isFounder ? 'Co-Founder' : 'Admin'}</span>
                                                        }

                                                        <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>
                                                        <p className='list-position'>{member.position || 'Member'}</p>
                                                        {listSocialsUserForTeam(member.User)}
                                                    </div>
                                                </div></Slide>
                                            })}
                                        </Swiper>
                                    </div>

                                    {!!listAchievements.length &&

                                    <div className="block-achievements-investor">
                                        <p className="title-achievements-investor">Achievements</p>
                                        <div className="block-list-tab-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {
                                                    return <TabsCounter
                                                        TitleTab={v.item.RoleParameter.title}
                                                        TypeTab={'big'}
                                                        EventOnClick={() => this.setActiveAch(v.item.parameterId)}
                                                        active={v.item.parameterId === this.state.activeAchivements}
                                                        count={v.data.length}
                                                    />
                                                })
                                            }

                                        </div>

                                        <div className="block-list-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(0,6).map(v => {
                                                    return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>
                                                        <p className="title-item-achievements-investor">
                                                            {v.description}
                                                        </p>
                                                        <div
                                                            className="block-right-achievements-cart-share">
                                                            <a href={v.link.toUrl()}
                                                               rel="noopener noreferrer"
                                                               target="_blank"> <i
                                                                className="fas fa-share-square"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                })
                                            }


                                            <div className="open-close-block">
                                                {
                                                    this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {
                                                        return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>
                                                            <p className="title-item-achievements-investor">
                                                                {v.description}
                                                            </p>
                                                            <div
                                                                className="block-right-achievements-cart-share">
                                                                <a href={v.link.toUrl()}
                                                                   rel="noopener noreferrer"
                                                                   target="_blank"> <i
                                                                    className="fas fa-share-square"/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>


                                            {this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&
                                            <div style={{float: 'none', clear: 'both'}} className="block-item-achievements-investor" onClick={(e) => {
                                                this.setState({showMoreAch: !this.state.showMoreAch});
                                                this.OpenCloseContentClick(e, '.open-close-block');

                                            }}>

                                                <p className="title-item-achievements-investor show">
                                                    {!this.state.showMoreAch && <span className="show-more">Show more <i className="far fa-chevron-down"/></span>}
                                                    {this.state.showMoreAch && <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}
                                                </p>
                                            </div>
                                            }

                                        </div>


                                    </div>
                                    }
                                </div>

                                <div className="block-social-link-expert">

                                    <p className="title-social-link">
                                        Follow us:
                                        {accelerator.twitterLink ? <a target="_blank" href={accelerator.twitterLink.toUrl()}><i className="fab fa-twitter"/></a> : ''}
                                        {accelerator.facebookLink ? <a target="_blank" href={accelerator.facebookLink.toUrl()}><i className="fab fa-facebook"/></a> : ''}
                                        {accelerator.linkedInLink ? <a target="_blank" href={accelerator.linkedInLink.toUrl()}><i className="fab fa-linkedin"/></a> : ''}
                                        {accelerator.mediumLink ? <a target="_blank" href={accelerator.mediumLink.toUrl()}><i className="fab fa-medium"/></a> : ''}
                                        {accelerator.telegramLink ? <a target="_blank" href={accelerator.telegramLink.toUrl()}><i className="fab fa-telegram"/></a> : ''}

                                        {accelerator.contacts.map((contact) => {
                                            let classn = 'fa fa-link'
                                            if (/twitter/.test(contact)) {
                                                classn = 'fa-twitter'
                                            }
                                            if (/facebook/.test(contact)) {
                                                classn = 'fa-facebook'
                                            }
                                            if (/linked/.test(contact)) {
                                                classn = 'fa-linkedin'
                                            }
                                            if (/medium/.test(contact)) {
                                                classn = 'fa-medium'
                                            }
                                            if (/telegram/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }
                                            if (/t.me/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }

                                            return <a href={contact.toUrl()} target="_blank"><i
                                                className={`fab ${classn}`}/></a>
                                        })}
                                    </p>
                                    <p className="description-social-link">Registered {new Date(accelerator.createdAt).toShortFormat()}</p>
                                </div>
                                <Comments id={accelerator.id} user={user} type='accelerator'/>
                            </div>
                        </div>}

                        {this.state.showLoginPopup !== 'hide' && <LoginPopup
                            close={()=>{
                                this.setState({showLoginPopup:'hide'});
                                if(!localStorage.RocketToken){
                                    history.push('/');
                                }
                            }}
                            state={this.state.showLoginPopup}
                        />}
                    </React.Fragment>
                )
            } else {

                let newProps = this.props;

                if(!isExist(() => newProps.match.params.id)){
                    newProps = {...newProps, match: {params: {id: this.props.id, verify: 'v'}}};
                }

                return <ExternalAccelerator {...newProps}/>
            }

        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    accelerator: state,
})

Main.propTypes = {
    user: PropTypes.object,
    // getExpert: PropTypes.func.isRequired
    // updateaccelerator: PropTypes.func.isRequired,
    // getLastaccelerator: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(Main)
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import ButtonSocialReg from '../controls3.0/buttonSocialReg/ButtonSocialReg'
import ButtonBlue from '../controls3.0/button/ButtonBlue'
import Input from '../controls3.0/input/Input'
import iconMail from '../../images/svg/ic-mail.svg'
import RocketDAOBlue from '../../images/logo_beta.svg'
import FooterImg from '../../images/RocketDAO-Experts@1x.png'
import {login, logout, register, signUpFacebook, signUpGoogle, resendMail} from "../../actions/Login";
import Loader from '../test-controls/preloader/preloader'
import {showMessage} from "../../utils/showMessage";
import {regexEmail, regexPass} from '../../utils/RegExp'
import packageJSON from './../../../package.json'
import ModalForgotPassword from '../modal/ModalForgotPassword'
import Header from '../header';
import history from '../../history';
import axios from "axios/index";
import {newToken} from "../../actions/User";

export default class Start extends React.Component {
     constructor(props) {
        super(props);
        this.state = {
            data: {
                email: '',
                password: ''
            },
            errors: {},
            typeName: '',
            ref_b: '',
            showModalForgotPassword: false
        };

        window.scrollTo(0,0);
    }

    componentDidMount() {
        let url = new URL(window.location.href),
            type = url.searchParams.get("type") || '',
            ref_b = url.searchParams.get("ref_b") || '',
            ref_f = url.searchParams.get("ref_f") || '',
            team_r = url.searchParams.get("team_r") || '',
            team_f = url.searchParams.get("team_f") || '',
            team_a = url.searchParams.get("team_a") || '',
            event = url.searchParams.get("event") || '',
            typeName = '',
            rFrom = localStorage.getItem('rFrom') || '',
            referer = localStorage.getItem('referer') || '',
            referer_startup = localStorage.getItem('referer_startup') || '';

        if(type == 1) typeName = 'investor';
        if(type == 2) typeName = 'expert';
        if(type == 3) typeName = 'startup';
        if(type == 4) typeName = 'fund';
        if(type == 5) typeName = 'angel';

        this.setState({ typeName: typeName, referer, type: type, ref_b: ref_b, ref_f, rFrom: rFrom, team_r, team_f, team_a, referer_startup});

        localStorage.removeItem('tempType');
        // localStorage.removeItem('rFrom');
        //localStorage.removeItem('referer');

        // localStorage.setItem('rFrom', history.location.pathname + history.location.search || '');


        // if(localStorage.commandProps){
        //     this.setState({commandProps: localStorage.commandProps})
        // }

        if(this.props.location.state){

            localStorage.setItem("commandProps", JSON.stringify(this.props.location.state))
        }


        if(event){
            localStorage.setItem("event", event);
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({data: {...this.state.data, [name]: value }}, () => {
            let data = this.state.data,
                obj = {}
            for (let name in data) {
                if(data[name]) obj[name] = data[name]
            }
            obj[name] = value
            let errors = this.validate(obj)
            this.setState({ errors })
        })
    }

    validate(data) {
        const errors = this.state.errors;
        for (let name in data) {
            let value = data[name],
                required = false,
                limit = 300,
                minLength = 0;
            switch (name) {
                case 'email':
                    if(!regexEmail.test(value)) {
                        errors[name] = 'Empty or invalid e-mail address'
                    } else {
                        delete errors[name]
                    }
                    break;
                case 'password':
                    required = true;
            }

            if (required && !value) {
                errors[name] = 'Field is required'
            } else if (required && value) {
                delete errors[name]
            }
        }
        return errors;
    }

    login = () => {
        let data = this.state.data;
        let errors = this.validate(data);

        const {ref_b, ref_f, team_r, team_f, team_a} = this.state;

        this.setState({ errors })
        if(!Object.keys(errors).length){
            this.setState({ loading: true })
            login(data, ref_b, ref_f, team_r, team_f, team_a)
                .then((res)=>{
                    localStorage.setItem('RocketToken', res.data.token);
                    this.goToRegister(res.data)
                })
                .catch(err => {
                    this.setState({ loading: false })
                    showMessage({type: 'error', data: err})
                })
        } else {
            //alert('ERR');
        }
    }

    sendFunction = (signUpFunc, response) => {
        if(response.error) {
            NotificationManager.error(response.error)
            return
        }
        this.setState({loaded: false})
        signUpFunc(response.accessToken, this.state.typeName, this.state.ref_b, this.state.ref_f, this.state.team_r, this.state.team_f, this.state.team_a)
            .then((res)=>{
                localStorage.setItem('RocketToken', res.data.token);
                newToken(res.data.token);
                this.goToRegister(res.data)
            })
            .catch(err => {
                this.setState({loaded: true});
                console.log(err);
                showMessage({type: 'error', data: err})
            })
    }

    responseFacebook = (response) => {
        this.sendFunction (signUpFacebook, response)
    }

    responseGoogle = (response) => {
        this.sendFunction (signUpGoogle, response)
    }

    onSuccessTwitter = (response) => {
        const token = response.headers.get('x-auth-token');
        response.json().then(user => {
            localStorage.setItem('RocketToken', user.token);
            newToken(user.token);
            this.goToRegister(user)
        });
    };

    onFailedTwitter = (err) => {
        showMessage({type: 'error', data: err})
    };

    goToRegister = (user) => {


        let commandProps = JSON.parse(localStorage.commandProps || "{}");

        localStorage.removeItem("commandProps");
        localStorage.removeItem('referer');
        localStorage.removeItem('referer_startup');

        let event = localStorage.getItem('event');
        let type = this.state.type;

        switch (Number(type)) {
            case 1:
                if(user.investor && user.investor.id && user.investor.isFinished === false) {
                    history.replace(`/registration/investor/${user.investor.registrationStep}`)
                } else {
                    if(user.investor && user.investor.isFinished){
                        history.replace('/')
                    } else {
                        history.replace(`/registration/investor/1`);
                    }
                }
                break;
            case 2:
                if(user.expert && user.expert.id && user.expert.isFinished === false) {
                    history.replace(`/registration/expert/${user.expert.registrationStep}`);
                } else {
                    if(user.expert && user.expert.isFinished){
                        history.replace('/')
                    } else {
                        history.replace(`/registration/expert/1`);
                    }
                }
                break;
            case 3:
                if(event){
                    localStorage.removeItem('event');
                    if (this.state.ref_b){
                        history.replace(`/registration/startup/1?event=${event}&ref_b=${this.state.ref_b}`);
                    }
                    history.replace(`/registration/startup/1?event=${event}`);
                } else if (this.state.ref_b){
                    history.replace(`/registration/startup/1?ref_b=${this.state.ref_b}`);
                } else {
                    history.replace(`/registration/startup/1`);
                }

                break;
            case 4:
                history.replace(`/create/fund/1${history.location.search}`);
                break;
            case 5:
                history.replace(`/create/angel_network/1${history.location.search}`);
                break;
            case 6:
                history.replace(`/start_investor`);
                break;
            case 7:
                history.replace(`/start_accelerator${history.location.search}`);
                break;
            case 8:
                history.replace(`/join`);
                break;
            default:{

                try{
                    if( (this.state.referer_startup || this.state.rFrom || this.state.referer) && this.state.rFrom !== '/start_reg' && this.state.referer !== '/start_reg'){
                        history.replace(this.state.referer_startup || this.state.rFrom || this.state.referer || commandProps.to ||  '/', commandProps || {});
                    } else {
                        commandProps = commandProps || this.props.location.state;

                        if(commandProps && commandProps.prevLocation !== '/start_reg'){
                            history.replace(commandProps.prevLocation || commandProps.to ||  '/', commandProps || {})
                        } else {
                            history.replace('/', commandProps || {});
                        }
                    }
                } catch (e) {
                    console.error(e);
                }

                break;
            }
        }
    }

    handleModalForgotPassword = () => {
        this.setState({showModalForgotPassword: !this.state.showModalForgotPassword});
    }

    render () {
        let {data, errors} = this.state;

        return (
            <div className='reg-full-wrap' style={{background:'#fdfdfd'}}>
                <Header noShowCreate={true} user={this.props.user} project={this.props.project}/>

                <div className="container text-center">
                    <p className="text-h1">
                        Welcome back
                    </p>

                    <React.Fragment>
                        <div className='block-btns-soc block-email login-form'>
                            <Input
                                type={'email'}
                                name={'email'}
                                value={data.email}
                                onChange={this.onChange}
                                error={errors.email}
                                placeholder={'Email'}
                                iconClass={'fal fa-envelope'}
                            />
                            <Input
                                type={'password'}
                                name={'password'}
                                value={data.password}
                                onChange={this.onChange}
                                error={errors.password}
                                placeholder={'Password'}
                                iconClass={'fal fa-lock-alt'}
                            />
                            <div className="forgot_pass" onClick={this.handleModalForgotPassword}>Forgot password</div>
                            <ButtonBlue
                                TitleButton={'Login'}
                                EventOnClick={() => this.login()}
                                paddingButton={'55'}
                                disabled={Object.keys(errors).length}
                            />
                            <div className='block-login-sign-up sign-up-text text-align-center'>New to Rocket DAO?<a onClick={() => {
                                history.replace({...this.props.location, pathname: '/start_reg'})
                            }}>Sign up</a></div>
                        </div>


                        <div className='block-btns-soc' style={{marginTop: '30px', width: '369px'}}>
                            <div className="btn-google">
                                <GoogleLogin
                                    clientId="839740061712-mi7grbk5uvgst4qo55ml9kvk3ic8a9e2.apps.googleusercontent.com"
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    render={renderProps => (
                                        <ButtonSocialReg
                                            TitleButton={'Login with Google'}
                                            EventOnClick={() => renderProps.onClick()}
                                            disabled={false}
                                            color={'#DC4E41'}
                                            iconSVG={{
                                                d: 'M0.00463809195,7.44246163 C-0.0603414999,10.5438424 2.11043401,13.5035211 5.09655646,14.4687664 C7.90929115,15.3986666 11.3675769,14.7030087 13.2392503,12.332631 C14.6057809,10.6717278 14.9163116,8.45751156 14.7683932,6.38885329 C12.3595769,6.36764617 9.95076054,6.37471521 7.54553605,6.37824973 C7.54161768,7.22267874 7.54553605,8.06389454 7.54912789,8.90832355 C8.9914136,8.94752459 10.4340258,8.92953067 11.8763116,8.95812815 C11.5122299,10.1858919 10.6828422,11.3285059 9.42406666,11.7719275 C6.99369932,12.8258572 3.89263809,11.4490009 3.1279034,8.94013423 C2.20480136,6.53762446 3.71598503,3.63835394 6.21100544,2.93948291 C7.75810748,2.40384244 9.3519034,3.00696011 10.6756585,3.79837131 C11.3463524,3.18079424 11.9739442,2.52080292 12.590434,1.85374257 C11.086434,0.61537522 9.15369932,-0.143903981 7.16676054,0.022861108 C3.26831156,0.0684885504 -0.143280275,3.61007778 0.00463809195,7.44246163 Z',
                                                viewBox: '0 0 17 17'
                                            }}
                                            styleSVG={{
                                                width: '28px',
                                                height: '20px'
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="btn-twitter">
                                <TwitterLogin loginUrl={`${packageJSON.proxy}/auth/twitter`}
                                              requestTokenUrl={`${packageJSON.proxy}/auth/twitter/reverse`}
                                              onFailure={this.onFailedTwitter}
                                              onSuccess={this.onSuccessTwitter}
                                >
                                    <ButtonSocialReg
                                        TitleButton={'Login with Twitter'}
                                        EventOnClick={null}
                                        disabled={false}
                                        color={'#55ACEE'}
                                        iconSVG={{
                                            d: 'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
                                            viewBox: '0 0 512 512'
                                        }}
                                        styleSVG={{
                                            width: '28px',
                                            height: '20px'
                                        }}
                                    />
                                </TwitterLogin>
                            </div>

                            <FacebookLogin
                                appId="340838513264681"
                                autoLoad={false}
                                fields="name,email,picture"
                                disableMobileRedirect={true}
                                redirectUri={window.location.href}
                                render={renderProps => (
                                    <ButtonSocialReg
                                        TitleButton={'Login with Facebook'}
                                        EventOnClick={() => renderProps.onClick()}
                                        disabled={false}
                                        color={'#3B5998'}
                                        iconSVG={{
                                            d: 'M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229',
                                            viewBox: '0 0 264 512'
                                        }} // {"d":"", "viewBox": ""}
                                        styleSVG={{
                                            width: '28px',
                                            height: '20px'
                                        }} // {width:'',height:'',}
                                    />
                                    )}
                                callback={this.responseFacebook}>
                            </FacebookLogin>

                            <ButtonSocialReg
                                TitleButton={'Login with Linkedin'}
                                EventOnClick={() => window.location.href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86psnkb7tmaudy&redirect_uri=https://rocketdao.io/linkedin_auth&state=987654321&scope=r_liteprofile'}
                                disabled={false}
                                color={'#0E76A8'}
                                iconSVG={{
                                    d: 'M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z',
                                    viewBox: '0 0 448 512'
                                }} // {"d":"", "viewBox": ""}
                                styleSVG={{
                                    width: '28px',
                                    height: '20px'
                                }} // {width:'',height:'',}
                            />
                            <div className='terms-links-text' style={{marginTop:'-10px'}}>
                                By proceeding with using this website you agree to our <a href={'/document/general-terms'} target='_blank'>Terms</a> and <a href='/document/privacy-policy' target='_blank'>Privacy Policy</a>
                            </div>

                        </div>

                    </React.Fragment>


                </div>

                <div className={'block-img-footer'}>
                    <img src={FooterImg} alt="footer_img" style={{position: 'relative'}} />
                </div>
                <NotificationContainer/>

                <ModalForgotPassword
                    showModal={this.state.showModalForgotPassword}
                    handleCloseModal={this.handleModalForgotPassword}
                />

            </div>
        )
    }
}


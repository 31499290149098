import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import {getLastProject, updateProject, pitchProject} from "../../../actions/Project";
import Dropzone from 'react-dropzone'
import packageJSON from './../../../../package.json'
import YouTube from 'react-youtube';
import {youtubeParser} from '../../../utils/RegExp'
import moment from "moment/moment";
import { sortable } from 'react-sortable';
import config from "../../../config";

class Item extends React.Component {
    render() {
        return (
            <div {...this.props}>
                {this.props.children}
            </div>
        )
    }
}
var SortableItem = sortable(Item);

class SortableList extends React.Component {
    state = {
        items: this.props.items
    };

    componentDidUpdate (prevProps) {
        if(prevProps.items.length !== this.props.items.length){
            this.setState({items: this.props.items})
        }
    }

    onSortItems = (items) => {
        this.setState({
            items: items
        });
    }

    onRemove = (i) => {
        this.props.onRemove(i)
    }

    render() {
        const { items } = this.state;
        var listItems = items.map((item, i) => {
            return (
                <SortableItem
                    key={i}
                    onSortItems={this.onSortItems}
                    items={items}
                    sortId={i}
                >
                    <div className="one-item oi-media" key={i}>
                        {item.preview ?
                            <React.Fragment>
                                <div className="oiy-video oiy-image">
                                    {item.type === "application/pdf" ? <img src={'https://thumbs.gfycat.com/LameDifferentBalloonfish-small.gif'} alt={item.name}/> : <img src={item.preview} alt={item.name}/>}
                                </div>
                                <div className="oiy-title">
                                    <div className="y-title">
                                        {item.name}
                                    </div>
                                    <div className="oi-fileinfo">
                                        <div className="oif-1">
                                            {moment(item.lastModified).format('DD/MMM/YYYY')}
                                        </div>
                                        <div className="oif-2">
                                            {Math.round(item.size/1000)} kB
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="oiy-video oiy-image">
                                    <img src={config.proxy + '/file/' + (item.id) + '/view'} alt={item.name}/>
                                </div>
                                <div className="oiy-title">
                                    <div className="y-title">
                                        {item.name}
                                    </div>
                                    <div className="oi-fileinfo">
                                        <div className="oif-1">
                                            {moment(item.lastModified).format('DD/MMM/YYYY')}
                                        </div>
                                        <div className="oif-2">
                                            {Math.round(item.size/1000)} kB
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }

                        <div className="hover-back">
                            <div className='hb-text'>Grab and <br/> drag this</div>
                            <i className='fa fa-times hb-close' onClick={() => this.onRemove(i)} onTouchStart={() => this.onRemove(i)}/>
                        </div>
                    </div>
                </SortableItem>
            );
        });

        return (
            <React.Fragment>
                {listItems}
            </React.Fragment>
        )
    }
};

class Step14 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {pitch: {files: []}},
            errors: {}
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }

    componentDidUpdate (prevProps) {
        if(this.props.project && this.props.project.id) {

            if(!this.props.project.pitch) {
                this.props.project.pitch = {files: []}
            } else {
                if( JSON.stringify(this.state.project.pitch) !== JSON.stringify(this.props.project.pitch)){
                    this.setState({project: this.props.project}, this.props.getLastProject)
                    if(this.props.project.pitch.youtubeLink){
                        this.getYouTitle(this.props.project.pitch.youtubeLink)
                    }

                }
            }
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors;

            value = value.trim();

        if(value.length){
            this.getYouTitle(value);
        } else {
            delete errors['youtubeLink'];
            this.setState({errors: errors})
        }

        let projectPitch = this.state.project.pitch;
        projectPitch.youtubeLink = value;
        this.setState({ project: {...this.state.project, pitch: projectPitch }})
    }

    getYouTitle = url => {
        var videoId = youtubeParser(url);
        if(videoId) {
            var ytApiKey = "AIzaSyCLMtLI4stPVd_kPOVQsU0HZPlmNwFjyrc";
            fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${ytApiKey}`)
                .then((response) => response.json())
                .then((res) => {
                    this.setState({youtubeId: videoId, youtubeTitle: res.items[0].snippet.title});
                });
        } else {
            this.setState({errors: {...this.state.errors, ['youtubeLink']: 'Please insert valid link from youtube'}})
        }
    }

    onDrop(accepted, rejected) {

        this.setState({fileError: null});

        const THIS = this;
        const __sortedList = this.sortedList;

        if (accepted.length) {
            let projectPitch = this.state.project.pitch,
                filesCount = projectPitch.files.length,
                left = 20 - filesCount;

            for(let i = 0; i < left; i++) {
                if(accepted[i]){
                    if(accepted[i].type === 'application/pdf'){
                        const reader = new FileReader();
                        reader.readAsBinaryString(accepted[i]);
                        reader.onloadend = function(){
                            var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                            if(count > 40){
                                THIS.setState({fileError: 'Max 40 slides'})
                            } else {

                                let formData = new FormData();
                                if(projectPitch.youtubeLink) formData.append('youtubeLink', projectPitch.youtubeLink);
                                let _sortedList = __sortedList.state.items || [];
                                let arrayFiles = THIS.creatingArrayFiles(_sortedList)

                                if(arrayFiles)
                                    formData.append('arrayFiles', arrayFiles.join(','));
                                else
                                    formData.append('arrayFiles', '');

                                let newFiles = _sortedList.filter((item) => {
                                    return item.preview
                                });

                                newFiles.forEach((item, key) => {
                                    formData.append('files', item)
                                });

                                projectPitch.files.push(accepted[i])

                                THIS.setState({ project: {...THIS.state.project, pitch: projectPitch } }, async () => {
                                    let r = await THIS.update(14, false);
                                    THIS.setState({loading: false})
                                    THIS.componentDidMount();
                                })
                            }
                        }
                    } else {
                        projectPitch.files.push(accepted[i])
                    }
                }
            }
            this.setState({ project: {...this.state.project, pitch: projectPitch } })
        }
        if (rejected.length) {
            this.setState({fileError: 'The uploaded file seems to have incorrect format'})
        }
    }

    onRemove = (key) => {
        let projectPitch = this.state.project.pitch;
        projectPitch.files.splice(key, 1)

        this.setState({ project: {...this.state.project, pitch: projectPitch } })
    }

    onRemoveYoutube = () => {
        this.setState({youtubeId: '', youtubeTitle: ''});

        let projectPitch = this.state.project.pitch;
        projectPitch.youtubeLink = '';
        this.setState({ project: {...this.state.project, pitch: projectPitch }})
    }

    creatingArrayFiles = (sortedList) => {
        let arr = []
        sortedList.map((item, key) => {
            if(item.id) {
                arr.push(item.id)
            } else {
                arr.push(null)
            }
        })


        return arr;
    }

    update = async (nextStep, onlyStep) => {

        let project = this.state.project
        let obj = {
            id: project.id,
            market: project.market ? project.market.trim() : '',
            businessModel: project.businessModel ? project.businessModel.trim() : ''
        }

        // obj.event_type = 'startup_market_model_14';
        let sortedList = this.sortedList.state.items;

        let arrayFiles = this.creatingArrayFiles(sortedList)
        // console.log(arrayFiles)
        // return

        let data = new FormData();
        if(project.pitch.youtubeLink) data.append('youtubeLink', project.pitch.youtubeLink)

        if(arrayFiles) data.append('arrayFiles', arrayFiles.join(','))

        let newFiles = sortedList.filter((item) => {
            return item.preview
        })

        newFiles.forEach((item, key) => {
            data.append('files', item)
        })

        if(onlyStep){
            this.updateS(onlyStep, nextStep)
            return
        }

        this.setState({loading: true})

        return pitchProject(project.id, data)
            .then((res) => {
                this.updateS(onlyStep, nextStep)
                return 1;
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    updateS = (onlyStep, nextStep) => {
        let project = this.state.project;

        const obj = {
            id: project.id,
            media_type: `${project.pitch.youtubeLink.length ? 'video link, ' : ''}${this.sortedList.state.items.length ? 'slides upload' : ''}`,
            event_type: 'startup_pitchdeck_15'
        };

        if(nextStep === 15 && onlyStep){
            obj.event_data = {
                skip: true
            }
        }

        this.props.updateStartup(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {project, errors} = this.state;
        if(!project.pitch) project.pitch = {files: []};
        if(!project.pitch.youtubeLink) project.pitch.youtubeLink = ''

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        const dropStyle2 = {
            'width': '100%',
            'height': 'auto'
        }



        let opts = {
            height: '80px',
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 0
            }
        }

        var youtubeId = youtubeParser(project.pitch.youtubeLink);
        // youtubeId ? youtubeId = youtubeId : youtubeId = 'invalid'


        return (
            <React.Fragment>
                <div className="container-full-reg step-1 padding-reg">
                    <div className="wrap-white container-step-1-reg step-14" style={{padding:'0 0 10px 0'}}>
                        <p className="text-h1">
                            Pitch deck & demo
                        </p>
                        <p className="text-h7 color-text-reg-gray" style={{lineHeight: '22px'}}>
                            In storytelling the details matter. Visualization helps highlight the most important features. <br/>
                            <span className="text-bold-600">
                                Stand out! Upload your pitch deck slide by slide or screenshots of your product below. <br/>
                                Perhaps you have a quality demo-video? Provide the link to it as well then.
                            </span>
                        </p>
                    </div>

                    <div className="container-full-reg container-bottom container-step-1-reg">
                        <Dropzone
                            style={dropStyle2}
                            activeClassName='dropzone-component__active'
                            multiple={true}
                            onDrop={this.onDrop.bind(this)}
                            disableClick={true}
                            accept="image/jpeg, image/png, image/gif, application/pdf"
                        >

                            <div className="drop-container">
                                <div className="absolute-hint-2">
                                    Drop your slides right here. <br/>
                                    One by one or all together at a time.
                                </div>

                                <div className='opacity-wrapper'>
                                    {youtubeId ?
                                        <div className="one-item  oi-youtube">
                                            <div className="oiy-video oiy-circle">
                                                <YouTube
                                                    videoId={youtubeId}
                                                    opts={opts}
                                                    onReady={this._onReady}
                                                />
                                            </div>
                                            <div className="oiy-title">
                                                <div className="y-title">
                                                    {this.state.youtubeTitle}
                                                </div>
                                                <div className="y-type">
                                                    YouTube
                                                </div>
                                            </div>

                                            <div className="hover-back">
                                                {/*<div className='hb-text'>Grab and <br/> drag this</div>*/}
                                                <i className='fa fa-times hb-close' onClick={() => this.onRemoveYoutube()}></i>
                                            </div>
                                        </div>
                                        :
                                        <div className="one-item oi-empty">
                                            <div className="oie-title">
                                                Link YouTube
                                            </div>
                                            <div className="oie-description">
                                                Insert the link to video presentation of your project
                                            </div>
                                            <div className="oie-input">
                                                <Input
                                                    type={'text'}
                                                    name={'youtubeLink'}
                                                    value={project.pitch.youtubeLink}
                                                    onChange={this.onChange}
                                                    error={errors.youtubeLink}
                                                    placeholder={'Your Link'}
                                                />
                                            </div>
                                        </div>
                                    }



                                    <SortableList ref={(sortedList) => {this.sortedList = sortedList}} items={project.pitch.files} onRemove={this.onRemove}/>



                                    {project.pitch.files.length < 20 &&
                                    <div className="one-item oi-empty">
                                        <div className="oie-title">
                                            Slides upload
                                        </div>
                                        <div className="oie-description">
                                            High-resolution images (optimal parameters are 1440*810 px)
                                        </div>

                                        <Dropzone style={dropStyle}
                                                  multiple={true}
                                                  onDrop={this.onDrop.bind(this)}
                                                  accept="image/jpeg, image/png, image/gif, application/pdf"
                                        >
                                            <div className="oie-button">
                                                Upload
                                            </div>
                                        </Dropzone>

                                    </div>
                                    }
                                </div>



                                { project.pitch.files.length < 12 &&
                                <div className={`absolute-hint ${project.pitch.files.length >= 1 ? 'absolute-hint-mobile': ''}`} >
                                    Drop your slides right here. <br/>
                                    One by one or all together at a time.
                                </div>
                                }
                            </div>
                        </Dropzone>
                        {this.state.fileError}


                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(13, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 16px'}}>
                            <p className="title-block-button-reg-expert">
                                Note! You can upload not more than <br/>
                                14 perfect 16:9 images and only one video link.
                            </p>
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(15)}
                                paddingButton={'54'}
                                disabled={!this.state.project.pitch.files.length && !project.pitch.youtubeLink.length || Object.keys(errors).length || this.state.fileError || this.state.loader}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}



const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step14.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step14)
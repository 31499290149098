import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
// import async from "async";

import {updateFund} from "../../../actions/Login";
import {getListFunds} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import ButtonBorder from '../../controls3.0/button/ButtonBorder'


import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
// import packageJSON from './../../../../package.json'
// import {getTransaction} from "../../../actions/FundActions";
import {regexEmail} from "../../../utils/RegExp";
// import TabsWithRemove from "../../controls3.0/tabs/TabsWithRemove";
import plen18 from "../../../images/ilustaciya/Fund members@2x-min.png";

import ListTitlelMore from '../../controls3.0/listTitleMore/ListTitlelMore';

class Step16 extends React.Component {
    constructor(props) {
        super();
        let fund = props.fund,
            investor = fund.investor || {
                team: fund.team || []
            };

        this.state = {
            fund: fund,
            investor: investor,
            errors: {},
            contact: '',
            ref_f: `${window.location.origin}/start_reg?ref_f=${fund.id}`
        }
    }

    componentDidMount() {

    }


    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if (!regexEmail.test(value)) {
            this.setState({errors: {...this.state.errors, contact: 'Wrong link'}})
        } else {
            this.setState({errors: {...this.state.errors, contact: ''}});
        }
        this.setState({[name]: value}, () => {
            if (value) {
                this.getByString(value)
            } else {
                this.setState({foundFunds: []})
            }

        })
    }

    onChangeEmail = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({[name]: value})
    }

    getByString = str => {
        this.setState({loading2: true})
        getListFunds(str)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({foundFunds: res.data})
                this.setState({loading2: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading2: false})
            })
    }

    invite = () => {
        this.setState({invited: true})
    }

    onChange2 = (e, key) => {
        let team = this.state.investor.team;
        team[key] = e.target.value;
        this.setState({investor: {...this.state.investor, team: team}})
    }

    add = () => {
        let contact = {email: this.state.contact};

        let team = this.state.investor.team;

        if (team.includes({email: contact.email})) return;

        team.push(contact);

        let investor = this.state.investor;

        investor.team = team;

        if (regexEmail.test(contact.email)) {
            this.setState({errors: {...this.state.errors, contact: ''}})
            this.setState({investor: investor}, () => {
                this.setState({contact: ''})
            })
        } else {
            this.setState({errors: {...this.state.errors, contact: 'Wrong link'}})
        }
    }

    remove = (title, key) => {
        let team = this.state.investor.team
        team.splice(key, 1)
        this.setState({investor: {...this.state.investor, team: team}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        const fundId = this.props.fund.id;

        if (onlyStep) {
            obj = {}
            this.next(obj, onlyStep, nextStep)
        } else {
            this.setState({loading: true})
            axios
                .post(`/v2/funds/${fundId}/team/invite`, {members: this.state.investor.team || []})
                .then(res => {
                    this.next(obj, onlyStep, nextStep);
                })
        }
    }

    next = (obj, onlyStep, nextStep) => {
        const fundId = this.props.fund.id;
        this.props.updateFund(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {fund, investor, errors} = this.state;
        let team = investor.team ? investor.team.map((item, key) => {
            return (
                {title: item.email}
            )
        }) : null;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg">
                        <p className="title-top-page-reg-expert color-text-reg-gray text-h1 left-text shell-margin-bottom-15">
                            Current team
                        </p>

                        <p className="title-top-page-reg-expert color-text-reg-gray text-button-3 left-text shell-margin-bottom-30">
                            Here you can invite other members of the Fund to join the platform. <br/>
                            This will let you collaborate and invest jointly even more efficiently.
                        </p>


                        <div className='step-21-btm clearfix'>
                            <div className='s21-left new-width-100-down-900'>
                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                                    Invitation email list
                                </p>
                                {/*<p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">*/}
                                {/*You will earn <span>10%</span> from invited friends*/}
                                {/*</p>*/}

                                <div className="block-input-item-form-add-edit-modal-reg-expert input-item-form-add-adaptive">
                                    <p className="title">Enter emails of the members here</p>
                                    <Input
                                        type={'text'}
                                        name={'contact'}
                                        value={this.state.contact}
                                        onChange={this.onChange}
                                        error={errors.contact}
                                        placeholder={'Insert email'}
                                    />

                                    <ButtonBlue
                                        TitleButton={'Invite'}
                                        EventOnClick={() => this.add()}
                                        paddingButton={'26'}
                                        disabled={errors.contact || this.state.contact.length === 0}
                                    />
                                </div>

                                <div>
                                    <ListTitlelMore
                                        data={team}
                                        onChange={(e) => console.log(e)}
                                        viewCountItem={3}
                                        removeFromList={this.remove}

                                    />
                                </div>


                            </div>
                            <div className='s21-right new-width-100-down-900 new-no-padding-down-900-left'>
                                <img src={plen18} alt="" className=""/>
                                <p className="text-button-2">
                                    To ease the invitation process, you can share the link below with all your team
                                    members at once. This will enable them to create their own investor profiles and
                                    automatically link them to your Fund.
                                </p>
                                <div className="block-button-link">
                                    <ButtonBorder
                                        TitleButton={'Copy link'}
                                        EventOnClick={() => {
                                            navigator.clipboard.writeText(this.state.ref_f)
                                            showMessage({type: 'info', data: 'Copied!'});
                                        }}
                                        paddingButton={'20'}
                                    />
                                    <a href={this.state.ref_f} className="link new-width-100-down-900">{this.state.ref_f}</a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign: 'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={(this.state.investor.team.length>1)?'Send invitations':'Send invitation'}
                                EventOnClick={() => this.state.investor.team.length ? this.update(16) : this.update(17, true)}
                                paddingButton={'54'}
                                disabled={this.state.investor.team ? !this.state.investor.team.length : false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund
})

Step16.propTypes = {
    updateFund: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateFund})(Step16)
import React from 'react';
import CurrentStatistics from './CurrentStatistics';
import { Swiper, Slide } from 'react-dynamic-swiper'

import './style.css'
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import axios from "axios";
import packageJSON from "../../../../package.json";
import avatar from "../../expert4.0/pic/profilephoto.webp";
import {createAvatar} from "../../user/createAvatar";
import {getListIndustries} from "../../../actions/Project";
import $ from "jquery";
import {CartPopUp} from "../../v3/startupPopupPage";
import {StartupListPeItem} from "../../v4/startupListPeItem";
import {ContactsForm} from "../../../utils/ContactsForm";

import {Tap2Pay, openProduct} from "../../../utils/Tap2Pay";
import {isExist} from "../../../utils/helper";

import {tokenLogin} from "../../../actions/Login";

import PropTypes from "prop-types";
import LoginPopup from "../../v4/loginPopup";
import {connect} from "react-redux";
import ButtonWithTerms from "../../v4/Button/ButtonWithTerms";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";
import history from "../../../history";
import {Row} from "../../v4/Grid/Row";

let stopAnimate = false;

let listSwiper = [{
    Name:'Pierre Yurovski',
    Position: 'New York',
    descr: 'Being an investor, I get a lot of applications from startups. Selecting projects is be much easier with Rocket DAO, as it saves time and energy, letting me focus only on the best, evaluated startups.',
    logo: 'Angel investor',
    avatar:'Pierre'
},{
    Name:'Valentine Zavgorodnev',
    Position: 'California',
    descr: 'Rocket DAO evaluation standard helps startups and investors communicate on a single, understandable language.',
    logo: 'Angel investor',
    avatar:'/static/media/2@2x-min.a6f31a9d.png'
}];

class LandingInvestor extends React.Component {
    state={
        listIndustries: [],
        listProjectsPreliminaryEvaluation: [],
        showMoreStartupsPreliminaryEvaluation:true,
        showCartPopup: false,
        cartId: null,
        CartPopUpType: null,
        CartPopUpType2: null,
        showLoginPopup: 'hide'
    };


    componentDidMount() {
        window.scrollTo(0,0);
        getListIndustries()
            .then((res) => {
                this.setState({listIndustries: res.data})
            })
            .catch(error => {
                //alert('listIndustries e', error);
                this.setState({loading: false})
            })

        if (this.state.savedEmail) {
            this.checkSubscribe(this.state.savedEmail);
        }

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        let take = 3;
        axios
            .get('/v2/projects?take=3&preliminary_evaluation=true&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listProjectsPreliminaryEvaluation: r.data.data});
                // if(parseInt(r.data.totalCount) <= take){
                //     this.setState({showMoreProjectsScore: false});
                // }
                if(r.data.data.length <= 3) {
                    this.setState({showMoreStartupsPreliminaryEvaluation:false});
                }

            });
        // this.investorAnimate();
        stopAnimate = false;
    }

    componentWillUnmount() {
        stopAnimate = true;
    }

    investorAnimate () {
        setTimeout(()=>{
            let dotNum = $('.swiper-pagination-bullet-active').attr('aria-label');
            if (!stopAnimate) {
                // switch(dotNum){
                //     case('Go to slide 1'):
                //         $('[aria-label="Go to slide 2"]').click();
                //         break;
                //     case('Go to slide 2'):
                //         $('[aria-label="Go to slide 1"]').click();
                //         break;
                //
                //     case('Go to slide 3'):
                //         $('[aria-label="Go to slide 1"]').click();
                //         break;
                // }
            }

            if (!stopAnimate) this.investorAnimate();
        },8000);
    }

    togglePopupCart(id, type, e, type2) {
        if (e && e.preventDefault) e.preventDefault();
        this.setState({
            cartId: id,
            CartPopUpType: type,
            CartPopUpType2: type2,
            showCartPopup: !this.state.showCartPopup
        });
    }

    openProductHandle(){
        if(isExist(() => this.props.user.id)){
            openProduct(isExist(() => this.props.user.id));
        } else {

            localStorage.removeItem('rFrom');
            localStorage.removeItem('referer_startup');
            localStorage.removeItem("commandProps");

            localStorage.setItem("referer", window.location.pathname);
            this.setState({showLoginPopup: 'popup'});
        }
    }


    render() {
        const {listIndustries} = this.state;

        const listEvaluatedProjects = this.state.listProjectsPreliminaryEvaluation.map((item, key) => <StartupListPeItem key={key}
                                                                                                                         href={`/startup/${item.id}`}
                                                                                                                         item={item}
                                                                                                                         listIndustries={listIndustries}
                                                                                                                         onClick={(e) => this.goTo(`/startup/${item.id}`, e)}/>);


        // const listEvaluatedProjects = this.state.listProjectsPreliminaryEvaluation.map((item, key) => {
        //
        //     const _bg = item.backgroundImageId ? `${packageJSON.proxy}/file/${item.backgroundImageId}/view` : avatar;
        //     const _avatar = item.logoFileId ?
        //         <div className="img-cart-new-startup-marketplace"
        //              style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
        //         :
        //         createAvatar('img-cart-new-startup-marketplace', item.title);
        //
        //     return (
        //         <a key={key} href={`/startup/${item.id}`}>
        //             <div className="block-cart-new-startup-marketplace evaluated-card"
        //                  onClick={(e) => this.goTo(`/startup/${item.id}`, e)}>
        //                 <div className='block-startup-evaluation-view'>
        //                     <div className="row-0 review-score text-center">
        //                         <div className="new-col expert-main-score font-weight-500">{item.preliminary_evaluation}</div>
        //                         <div className="new-col expert-main-score-ten" style={{marginTop: '24px'}}>/10</div>
        //                         <div className='new-col review-score-text-block'>
        //                             <div className='row-0 expert-review-score-text text-left color-rgba-88'>Expert overall score</div>
        //                             <div className='row-0 expert-review-score-views text-left' style={{marginTop:'4px'}}>Defined round: <span className='color-rgba-88'>Seed</span></div>
        //                         </div>
        //                     </div>
        //
        //                 </div>
        //                 <div className="block-top-cart-new-startup-marketplace">
        //                     <div className="block-bg-top-cart-new-startup-marketplace">
        //                         <div className="bg-top-cart-new-startup-marketplace"
        //                              style={{backgroundImage: `url(${_bg})`}}/>
        //
        //
        //                         {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
        //                             <i className="fas fa-play"/>
        //                         </div>}
        //                     </div>
        //                     <div className="block-ing-cart-new-startup-marketplace">
        //                         {_avatar}
        //                     </div>
        //                 </div>
        //                 <div className="block-body-cart-new-startup-marketplace">
        //                     <p className="title-body-cart-new-startup-marketplace" title={item.title}>
        //                         {item.title}
        //                     </p>
        //                     <p className="description-body-cart-new-startup-marketplace">
        //                         {item.shortDescription.descriptionFormat()}
        //                     </p>
        //                     <div className="block-list-industries-body-cart-new-startup-marketplace">
        //                         <p className="title-industries-body-cart-new-startup-marketplace">
        //                             Industries
        //                         </p>
        //
        //                         {listIndustries && item.industry.slice(0, 4).map(id => {
        //                             return <div key={id}
        //                                         className="block-item-industries-body-cart-new-startup-marketplace">
        //                                 {listIndustries.find((a) => a.id === id) &&
        //                                 <p className="title-item-industries-body-cart-new-startup-marketplace">
        //                                     {listIndustries.find((a) => a.id === id).title}
        //                                 </p>
        //                                 }
        //                             </div>
        //                         })}
        //                         {item.industry.length > 4 &&
        //                         <div className="block-item-industries-body-cart-new-startup-marketplace">
        //                             <p className="title-item-industries-body-cart-new-startup-marketplace">
        //                                 {item.industry.length} more…
        //                             </p>
        //                         </div>
        //                         }
        //                     </div>
        //                     <div className="block-list-button-body-cart-new-startup-marketplace">
        //                         <div className="block-item-button-body-cart-new-startup-marketplace m">
        //                             <i className="fas fa-heart"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 {item.likes}
        //                             </p>
        //                         </div>
        //                         <div className="block-item-button-body-cart-new-startup-marketplace m">
        //                             <i className="far fa-clock"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
        //                             </p>
        //                         </div>
        //                         <div className="block-item-button-body-cart-new-startup-marketplace">
        //                             <i className="far fa-share-alt"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 {item.shared}
        //                             </p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="block-bottom-cart-startup-marketplace">
        //                     <p className="description-bottom-cart-startup-marketplace">
        //                         {item.views || 0} views
        //                     </p>
        //                 </div>
        //             </div>
        //         </a>
        //     )
        // });


        return (

            <div style={{background:'#FDFDFD'}}>
                <Tap2Pay/> {/*Tap2Pay Initial*/}

                        {/*HeaderBG*/}
                <div className='row-m0 block-header-landing-startup'>
                    <div className='block-investor-bg'>
                        <div className='header-landing-main-text text-align-center font-weight-500'>Need a quality overview of a startup?</div>
                        <div className='header-landing-main-text text-align-center font-weight-500 color-rgba-08'>Ask our experts for express evaluation!</div>
                        <div className='header-landing-descr-text text-align-center font-weight-300 color-rgba-08 margin-top-40'>Order Rocket DAO preliminary evaluation,<br/>mitigate your risks!</div>
                        <div className='row-m0 header-button-wrapper margin-top-55'>
                            <ButtonBlue
                                TitleButton={'Contact manager'}
                                EventOnClick={()=>{ContactsForm()}}
                                paddingButton={'1'}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>


                        {/*What our clients say*/}
                <div className='row-m0 no-gutters padding-top-70' style={{position:'relative'}}>
                    <div className='landing-startup-gradient'/>
                    <div className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08 shell-margin-bottom-60'>What our clients say</div>
                    <div className='w-100 investor-sliader'>
                        <Swiper
                            swiperOptions={{
                                slidesPerView: 1.5,
                                loop: false,
                                freeMode: false,
                                centeredSlides:true,
                                pagination:{
                                    el: '.swiper-pagination',
                                    clickable: true
                                },
                                autoplay:{
                                    delay: 8000,
                                },
                                breakpoints: {
                                    // when window width is <= 640px
                                    1200: {
                                        slidesPerView: 1
                                    },
                                    1300: {
                                        slidesPerView: 1.2
                                    },
                                    1400: {
                                        slidesPerView: 1.25
                                    },
                                    1500: {
                                        slidesPerView: 1.3
                                    },
                                    1600: {
                                        slidesPerView: 1.4
                                    }
                                }
                            }}
                            navigation={false}

                        >
                            {listSwiper.map( (member, k) => {
                                return  <Slide key={k}><div className='row-m0 block-slider-point no-gutters'>
                                    <div className='col block-slider-point-left'>
                                        <div className='row-m0 no-gutters'>
                                            <div className='col client-avatar' style={member.avatar==='Pierre' ?{}:{background: `url(${member.avatar})`}}/>
                                            <div className='col client-name-block shell-margin-top-20'>
                                                <div className='text-client-name font-weight-500 color-rgba-08'>{member.Name}</div>
                                                <div className='text-client-position'>{member.Position}</div>
                                            </div>
                                        </div>
                                        <div className='row-m0 margin-top-22 no-gutters'>
                                            <div className='col text-quotes quotes-block-left color-rgba-08'>“</div>
                                            <div className='col text-client-descr client-descr-block font-weight-300 color-rgba-08'>{member.descr}</div>
                                            <div className='col text-quotes quotes-block-right color-rgba-08'>”</div>
                                        </div>
                                        {/*<div className='row-m0 text-see-sample-pdf font-weight-500 no-gutters'><a href='#' target='_blank'>See a report sample PDF</a></div>*/}
                                    </div>
                                    <div className='col block-slider-point-right'>
                                        <div className='client-position-text color-rgba-08'>Angel<br/>investor</div>
                                    </div>
                                </div></Slide>

                            })}
                        </Swiper>
                    </div>



                </div>




                        {/*How it works*/}
                <div className='row-m0 no-gutters margin-top-75'>
                    <div className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08 margin-bottom-20'>How it works?</div>
                    <div className='row-m0 no-gutters w-100'>
                    <div className='block-algorithm-table no-gutters'>
                        <div className='row-b'>
                            <span className="line-dot line-4"></span>
                            <span className="line-dot line-5"></span>
                            <div className='col-12 col-sm-6 col-lg-4 block-advantage-point text-align-center'>
                                <div className='row-m0 no-gutters'>
                                    <div className='number-circle'>
                                        <div className='text-number-circle text-align-center'>1</div>
                                    </div>
                                </div>
                                <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Choose startup</div>
                                <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>Choose startups for evaluation. They can be either from your pipeline or from our marketplace.</div>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4 block-advantage-point text-align-center'>
                                <div className='row-m0 no-gutters'>
                                    <div className='number-circle'>
                                        <div className='text-number-circle text-align-center'>2</div>
                                    </div>
                                </div>
                                <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Provide data</div>
                                <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>Startup representative fills in the evaluation form and answers the questions about product, market, business model and project team prepared by Rocket DAO expert community.</div>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4 block-advantage-point text-align-center'>
                                <div className='row-m0 no-gutters'>
                                    <div className='number-circle'>
                                        <div className='text-number-circle text-align-center'>3</div>
                                    </div>
                                </div>
                                <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Get the result!</div>
                                <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>In the end you will receive a carefully structured pdf report with expert evaluation, feedback and recommendations on each parameter.</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='row-m0 algorithm-button-wrapper margin-top-35'>
                        <ButtonWithTerms EventOnClick={()=>{this.openProductHandle()}}>
                            De-risk your investments today
                        </ButtonWithTerms>
                    </div>
                </div>

                        {/*What are the advantages*/}
                <div className='row-m0 block-advantages-table-wrapper no-gutters margin-top-145 padding-top-70'>
                    <div className='landing-startup-gradient'/>
                    <div className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08'>What are the advantages?</div>
                    <div className='row-m0 block-advantages-table  margin-top-35 no-gutters'>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-expert'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Experts</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Independent expert community of recognized professionals</div>
                        </div>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-resourses'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Resourses</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Save your time and resourses by delegating evaluation of startups</div>
                        </div>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-startup'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Startups</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Bring your startup or choose one from our marketplace</div>
                        </div>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-uniform'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Uniformity</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Standartized reports assessing the most vital parameters</div>
                        </div>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-analisys'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Analisys</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Highlighted weak and strong points for further consideration</div>
                        </div>
                        <div className='col-12 col-md-4 block-advantage-point'>
                            <div className='row-m0 icon icon-universality'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Universality</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>Different evaluation criteria for different investment rounds</div>
                        </div>
                    </div>

                </div>


                        {/*Background*/}
                <div className='row-m0 expert-conducting-bg-wrapper margin-top-100'>
                    <div className='row-m0 block-expert-conducting-bg no-gutters'>
                        <div className='col-12 col-md-7'/>
                        <div className='col-12 col-md-5'>
                            <div className='expert-conducting-main-title expert-conducting-block color-rgba-08 font-weight-500'>Who are the experts conducting evaluation?</div>
                            <div className='expert-conducting-descr expert-conducting-block color-rgba-08 font-weight-300 margin-top-45'>
                                <strong>Experienced specialists</strong> in startup audit, business valuation, investment analysis,
                                pre-M&A asessment and in their narrow professional niches.
                                <br/><br/>
                                For today Rocket DAO experts <strong>come from USA, Germany, Great Britain,
                                Japan, Czech Republic, Croatia, Italy and many more.</strong>
                            </div>
                        </div>
                    </div>
                </div>

                        {/*Current Statistics*/}
                <CurrentStatistics
                    InvestorsNum='100+'
                    StartupsNum='50+'
                    ExpertsNum='100+'
                    PARTNERSNum={'4'}
                />


                        {/*Pay Banner*/}
                <div className='row-m0 no-gutters' style={{marginTop:'132px'}}>
                    <div className='row-m0 block-landing-pay-banner-wrapper block-investor-pay-banner-wrapper no-gutters'>
                        <div className='row-m0 pay-banner-columns-wrapper no-gutters'>
                            <div className='col pay-banner-column'>
                                <div className='row-b pay-banner-invetor-main-title font-weight-500 w-100 no-gutters'>1 - project <br/>express evaluation</div>
                                <div className='row-b pay-banner-cost-text color-rgba-88 font-weight-300 shell-margin-top-20 w-100 no-gutters'>$299</div>
                                <div className='row-b w-100 no-gutters' style={{marginTop: '14px'}}>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>Evaluation of the startup on the 4 key parameters approved by an expert community</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> Evaluation report prepared based on the analysis conducted by profesisonal experts</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> A pitch deck with expert comments</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> Expert feedback and individual recommendations for the startup</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>Project’s independent external SWOT analysis</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>A quality basis for your further investing decisions</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col investor-banner-right-col'>
                                <div className='row-b pay-banner-invetor-main-title font-weight-500 w-100 no-gutters'>10+ projects<br/>express evaluation</div>
                                <div className='row-b pay-banner-cost-text upon-text font-weight-300 w-100 no-gutters' style={{}}>upon request</div>
                                <div className='row-b shell-margin-top-20 w-100 no-gutters'>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>Evaluation of the startup on the 4 key parameters approved by an expert community</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> Evaluation report prepared based on the analysis conducted by profesisonal experts</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> A pitch deck with expert comments</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'> Expert feedback and individual recommendations for the startup</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>Project’s independent external SWOT analysis</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>A quality basis for your further investing decisions</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>A logo place on our website as of a partner</div>
                                    </div>
                                    <div className='row-0'>
                                        <div className='new-col pay-list-point'/>
                                        <div className='text-pay-list color-rgba-88 font-weight-300'>Commercial agreement for regular evaluations of the incoming startups</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-see-sample-pdf font-weight-500'><a href={`/Pre-Evaluation/Peeranha.pdf`} target='_blank'>See a report sample PDF</a></div>

                        <div className="row-b pay-banner-button-call investor w-100">
                            <div className='col-12 col-sm-6 col-md-5'>
                                <ButtonWithTerms className={'T2P-button'} color={'blue'} textColorClass={'_white'} EventOnClick={() => isExist(() => this.openProductHandle())}>
                                    Proceed with payment
                                </ButtonWithTerms>
                            </div>
                            <div className='col-12 col-sm-6 col-md-5'>
                                <ButtonGreen
                                    className={''}
                                    TitleButton={'Request a consultation'}
                                    EventOnClick={() => {window.open('https://docs.google.com/forms/d/e/1FAIpQLSd0WBm1Fk6rmhjBEW7o2XwYbQ6yK5zKdH8Y_HIfxrW9YCUvPw/viewform', '_blank');}}
                                    paddingButton={'0'}
                                    disabled={false}
                                />
                                </div>
                        </div>


                    </div>
                </div>

                        {/*Check the evaluated startups*/}
                <div className='row-w0 block-evaluated-startups no-gutters'>
                    <div className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08'>Here are the evaluated startups</div>
                    <div className='header-landing-descr-text text-align-center font-weight-300 color-rgba-08 margin-bottom-65'>
                        Study the reports, order evaluation of your projects and <br/>start a real startup unicorns hunting with us!
                    </div>
                    <div id='evaluation' className='block-startups-wrapper'>
                        <div className='row-b'>
                            {listEvaluatedProjects}
                        </div>

                    </div>
                    {this.state.showCartPopup ?
                        <CartPopUp id={this.state.cartId} user={this.props.user} type={this.state.CartPopUpType}
                                   type2={this.state.CartPopUpType2} close={() => this.togglePopupCart(null, null)}/>
                        : null
                    }
                </div>

                {this.state.showLoginPopup !== 'hide' && <LoginPopup
                    close={()=>{ this.setState({showLoginPopup:'hide'}); localStorage.removeItem("referer"); }}
                    state={this.state.showLoginPopup}
                />
                }


            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.auth
    }
}

LandingInvestor.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(LandingInvestor)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import human from "../../../images/ilustaciya/Ilustraciya_investor-exit-accelerator.svg";


class Step12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator,
            errors: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");

        if(Math.sign(value) === 1 || !value)
            this.setState({ accelerator: {...this.state.accelerator, [name]: value} })
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.accelerator;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        const acceleratorId = this.state.accelerator.id;
        this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    validate(){
        const {accelerator} = this.state;
        return accelerator.startupsTotal && accelerator.numberOfBatches;
    }


    render () {
        let {accelerator, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <img src={human} alt="" className="img-right-step" style={{top:'120px',right:'0'}}/>
                        <div className="container-step-1-reg">
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Accelerating programs
                            </p>
                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Now let's find out how extensive is your accelerating activity.
                            </p>


                            <div className="block-list-title-input">
                                <div className="block-item-title-input" style={{maxWidth:'420px'}}>
                                    <p className="title-item-title-input no-float-620">
                                        Startups total
                                    </p>
                                    <div className={`block-input-item-title-input ${false ? 'no-float-620':''}`}>
                                        <Input
                                            type={'text'}
                                            name={'startupsTotal'}
                                            value={accelerator.startupsTotal}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errors.startupsTotal}
                                        />
                                    </div>
                                </div>

                                <div className="block-item-title-input" style={{maxWidth:'420px'}}>
                                    <p className="title-item-title-input no-float-620">
                                        Number of batches
                                    </p>
                                    <div className={`block-input-item-title-input ${false ? 'no-float-620':''}`}>
                                        <Input
                                            type={'text'}
                                            name={'numberOfBatches'}
                                            value={accelerator.numberOfBatches}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errors.numberOfBatches}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(11, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(13, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(13)}
                                paddingButton={'54'}
                                disabled={!this.validate()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step12.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step12)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";
import history from "../../../history";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Input from "../../controls3.0/input/Input";
import AvatarImg from './../../controls3.0/AvatarImg'
import {getLastProject, updateProject} from "../../../actions/Project";
import {COUNTRIES, LANGUAGES} from "../../../utils/countriesLanguages";
import human from '../../../images/svg/Bitmap.svg'
import website from '../../../images/icon/website.svg'
import appStore from '../../../images/icon/app_store.svg'
import googlePlay from '../../../images/icon/google_play.svg'
import {regexUrl} from "../../../utils/RegExp";

class Step11 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            errors: {}
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace();

        this.setState({project: {...this.state.project, [name]: value}})
    }

    onChangeWebSite = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace();
        value = value.trim();
        if(value && !regexUrl.test(value)){
            this.setState({errors: {...this.state.errors, [name]: 'Invalid link'}})
        } else {
            delete this.state.errors[name];
        }

        this.setState({project: {...this.state.project, [name]: value}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            webSite: project.webSite        ? project.webSite.trim() : '',
            googlePlay: project.googlePlay  ? project.googlePlay.trim() : '',
            appStore: project.appStore      ? project.appStore.trim() : '',
            otherShow: project.otherShow    ? project.otherShow.trim() : ''
        }

        if (nextStep === 12 && onlyStep) {
            obj.event_data = {
                skip: true
            }
        }


        obj.event_type = 'startup_show_12';

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }


    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }



    render() {
        let {project, errors} = this.state;
        const disabledContinueBtn = !project.webSite || Object.keys(errors).length !== 0;

        return (
            <React.Fragment>

                <div className="container-full-reg" style={{position:'relative'}}>
                    <div className='container-step-11-reg padding-reg'>
                        <img src={human} alt="" className="img-right-step s11-irs"/>

                        <div className='s11-top-text'>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20">
                                Show your project to the world!
                            </p>
                            <p className="description-page-reg-expert text-button left-text shell-margin-bottom-40">
                                Your description is amazing, we think that your product has high potential for growth!
                            </p>
                        </div>


                        <div className="s11-btm-block">
                            <div className="shell-margin-bottom-40">
                                <div className='input-wrap'>
                                    <Input
                                        type={'text'}
                                        name={'webSite'}
                                        value={project.webSite}
                                        onChange={this.onChangeWebSite}
                                        error={errors.webSite}
                                        placeholder={'Your Website'}
                                        iconImg={website}
                                    />
                                </div>
                            </div>

                            <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20" style={{lineHeight:'30px'}}>
                                Now let the community see and test your innovative solution.
                            </p>

                            <div className="">
                                <div style={{marginTop: '10px'}}>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'googlePlay'}
                                            value={project.googlePlay}
                                            onChange={this.onChangeWebSite}
                                            error={errors.googlePlay}
                                            placeholder={'Your Google Play link'}
                                            iconImg={googlePlay}
                                        />
                                    </div>
                                </div>

                                <div style={{marginTop: '10px'}}>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'appStore'}
                                            value={project.appStore}
                                            onChange={this.onChangeWebSite}
                                            error={errors.appStore}
                                            placeholder={'Your App Store link'}
                                            iconImg={appStore}
                                        />
                                    </div>
                                </div>

                                <div style={{marginTop: '10px'}}>
                                    <div className='input-wrap'>
                                        <Input
                                            type={'text'}
                                            name={'otherShow'}
                                            value={project.otherShow}
                                            onChange={this.onChangeWebSite}
                                            error={errors.otherShow}
                                            placeholder={'Any other link?'}
                                            iconImg={website}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(10, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(12, true)}
                                paddingButton={'35'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(12)}
                                paddingButton={'54'}
                                disabled={disabledContinueBtn}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step11.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step11)


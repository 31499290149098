import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonBlue'
import _bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import '../expert4.0/style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import packageJSON from './../../../package.json'

import axios from "axios";
import {getAchievementInvestor, tokenLogin} from "../../actions/Login";
import {getListIndustries, getListFocuses} from "../../actions/Project";
import Loader from '../test-controls/preloader/preloader';
import {Comments} from '../v3/comments';
import {createAvatar} from "../user/createAvatar";
import EditMyPageButton from '../v3/editMyPage';
import MetaTags from "react-meta-tags";
import history from '../../history';
import {UserLang} from "../v3/userLang";
import HeaderStickyRole from "../controls3.0/headerStickyRole";
import config from "../../config";
import ButtonBlack from "../controls3.0/button/ButtonBlack";
import {getShortLang} from "../../utils/helper";
import {viewCounter} from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";

const TYPES = [];
TYPES[0] = {
    id: 0,
    title: 'Articles',
    description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
};
TYPES[1] = {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'};
TYPES[2] = {
    id: 2,
    title: 'Diploma',
    description: 'Documents and any documentary proof of your educational background.'
};
TYPES[3] = {
    id: 3,
    title: 'Projects',
    description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
};
TYPES[4] = {
    id: 4,
    title: 'Mass media',
    description: 'Links to the articles about you or about your professional experience.'
};
TYPES[5] = {
    id: 5,
    title: 'Recommendations',
    description: 'Reviews of your expert works and professional collaboration.'
};

Date.prototype.toShortFormat = function () {

    const month_names = ["Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
}

String.prototype.toUrl = function () {
    const re = new RegExp("^(http|https)://", "i");

    if (re.test(this)) {
        console.log(this);
        return this;
    }
    if (re.test(this)) {
        return this;
    }
    return 'http://' + this;
}

String.prototype.toShowUrl = function () {
    return this.replace('https://', '').replace('http://', '');
}

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            investor: {},
            user: props.user || {},
            logo: avatar,
            bg: _bg,
            listAchievements: [],
            experience: [],
            industries: [],
            gFocuses: [],
            id: this.props.id || props.match.params.id,
            activeAchivements: -1,
            comments: [],
            currentComment: '',
            showWork: false,
            activeSticky: false,
            showLoginPopup: 'hide'
        }
        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.setActiveAch = this.setActiveAch.bind(this);
    }

    componentDidMount() {

        if( !viewCounter() ) {
            if(this.props.id){

                const commandProps = {
                    to: '/',
                    id: this.props.id,
                    type: 'fund',
                    f: 'tpc'
                };

                localStorage.removeItem("rFrom");
                localStorage.removeItem("referer");
                localStorage.removeItem("referer_startup");

                localStorage.setItem("commandProps", JSON.stringify(commandProps));

                this.setState({showLoginPopup: 'content'});
            } else {
                this.setState({showLoginPopup: 'popup'});
            }
        }

        getListIndustries()
            .then((res) => {
                this.setState({industries: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        getListFocuses()
            .then((res) => {
                this.setState({gFocuses: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        axios.get(`/v2/investor/${this.state.id}/public`)
            .then(r => {

                if (r.data.expRole !== 'investor') {

                }

                if (!r.data.profile.previousInvestment) {
                    r.data.profile.previousInvestment = []
                }

                this.setState({investor: r.data});
                const logo = r.data.role.logoFileId ? `${packageJSON.proxy}/file/${r.data.role.logoFileId}/view` : avatar;
                const bg = r.data.role.backgroundImageId ? `${packageJSON.proxy}/file/${r.data.role.backgroundImageId}/view` : _bg;
                this.setState({logo, bg});

                try {
                    let obj = [];
                    Promise.all(
                        r.data.parameters.map(async (item, key) => {
                            let achievement = await getAchievementInvestor('investor', item.parameterId, this.state.investor.userId);
                            if (achievement.data.length) {
                                obj[item.parameterId] = {item: item, data: achievement.data};
                            }

                            return (achievement.data)
                        })
                    ).then(results => {
                        this.setState({listAchievements: obj});
                        this.setState({activeAchivements: parseInt(Object.keys(obj)[0])});
                    })
                } catch (e) {
                    console.log(e);
                }

                axios
                    .get(`/users/${this.state.investor.userId}/experience`)
                    .then(r => {
                        this.setState({experience: r.data})
                    })


            }).catch(e => {
            if (e.response.status === 404) {
                history.push('/404');
            }
        })
        // this.getComments();
        // this.stickyHeaderCart();
        if(process.env.NODE_ENV !== 'production'){
            window.addEventListener('scroll',this.stickyBG);
        }

    }
    componentWillUnmount() {
        if(process.env.NODE_ENV !== 'production') {
            window.removeEventListener('scroll', this.stickyBG);
        }
    }

    // stickyHeaderCart = () => {
    //     let timer = setInterval(() => {
    //
    //         if (window.pageYOffset > 200) {
    //             this.setState({activeSticky: true})
    //         } else {
    //             this.setState({activeSticky: false})
    //         }
    //         // clearInterval(timer);
    //     }, 250);
    //
    // }
    stickyBG = () => {
        if (window.pageYOffset > 174) {
            $('.card-wrapper').addClass('on-scroll');
            this.setState({activeSticky:true})
        } else {
            $('.card-wrapper').removeClass('on-scroll');
            this.setState({activeSticky:false})
        }
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.id) {
            if (nextProps.id != this.state.id) {
                const cleanState = {
                    investor: {},
                    listAchievements: [],
                    experience: [],
                    activeAchivements: '',
                    comments: [],
                    currentComment: '',
                    showWork: false
                }

                this.setState({...cleanState, ...nextProps}, () => {
                    this.componentDidMount();
                })
            }
        } else {
            if (nextProps.id != this.state.id) {
                this.setState({user: nextProps.user})
            }
        }
    }

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');
        if ($(e.currentTarget).parent().hasClass('active')) {
            $(e.currentTarget).parent().removeClass('active')
        } else {
            $(e.currentTarget).parent().addClass('active')
        }
        if (classBlock === '.open-close-block') {
            this.setState({showWork: !this.state.showWork})
        }

    }

    setActiveAch = (id) => {
        this.setState({activeAchivements: id});
    }

    outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
            }
        }

        return `${r(min)}-${r(max)}`
    }

    getImgAlt = (title) => {
        let replaced = title.replace(/ /gi, '_');
        return replaced;
    };


    render() {
        const {investor, logo, experience, listAchievements, industries, gFocuses, comments, user} = this.state;
        let leftPI = [];
        let rightPI = [];
        investor.contacts = [];

        const _avatar = (investor.role && investor.role.logoFileId) ?
            <div className="photo-cart-expert"
                 style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${investor.role.logoFileId}/view?W=200`})`}}>
                <img src={`${packageJSON.proxy}/file/${investor.role.logoFileId}/view`}
                     alt={'investor ' + this.getImgAlt(investor.role.firstName + ' ' + investor.role.lastName)}/>
            </div>
            :
            createAvatar('photo-cart-expert', investor.role ? investor.role.firstName : '');

        if (!investor.id) {
            return (<React.Fragment>
                <Loader loaded={!!investor.id} isLocal={!!this.props.id}/>
            </React.Fragment>);
        } else {
            return (
                <React.Fragment>
                    {!this.props.id && <MetaTags>
                        <title>{'Investor ' + this.getImgAlt(investor.role.firstName + ' ' + investor.role.lastName) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                        <meta property="og:title"
                              content={investor.role.firstName + ' ' + investor.role.lastName + ' - Rocket DAO - uniting startups, investors and experts'}/>
                        <meta name="description" content={investor.professionalIdentification}/>
                        <meta property="og:description" content={investor.professionalIdentification}/>
                    </MetaTags>}

                    {this.state.showLoginPopup !== 'content' && <div className='card-wrapper'>

                        <div className={`block-sticky-role-cart ${this.state.activeSticky ? 'active' : ''}`}>
                            <HeaderStickyRole

                                name={investor.role.firstName + ' ' + investor.role.lastName}
                                urlAvatar={(investor.role && investor.role.logoFileId) ? `${packageJSON.proxy}/file/${investor.role.logoFileId}/view?W=80` : null}


                                type={'investor'} // expert, fund, investor, startup
                                titleType={null}
                                description={investor.professionalIdentification ? investor.professionalIdentification : 'no information'}
                                location={investor.role.country ? investor.role.country : 'no information'}
                                languageOrSize={investor.role.lang ? investor.role.lang.map(lang => getShortLang(lang)).join(', ') : 'no information'}
                                eventButton={null} // only startup
                                customButton={((user && user.id !== investor.userId) || !user) && <ButtonGreen
                                    TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                    EventOnClick={null}
                                    paddingButton={'0'}
                                    disabled={true}
                                    onMouseEnter={() => this.setState({mouseEnter: true})}
                                    onMouseLeave={() => this.setState({mouseEnter: false})}
                                /> || <EditMyPageButton id={-1} type={'investor'}/>}


                            />

                        </div>


                        <div className="block-background-expert">
                            <img src={this.state.bg} alt={'load'}/>
                        </div>
                        <div className="block-container-expert">
                            <div className="block-box-shadow-cart-startup"/>
                            <div className="block-cart-expert-mobile">
                                <div className="block-top-cart-expert-mobile">
                                    <div className="block-avatar-name-top-cart-expert-mobile">
                                        <div className="block-left-top-cart-expert-mobile">
                                            {_avatar}
                                        </div>
                                        <div className="block-right-top-cart-expert-mobile">
                                            <h1 className="title-top-cart-expert-mobile">
                                                {investor.role.firstName} {investor.role.lastName}
                                                <span className="check-box-cart investor">
                                                    <i className="fal fa-check"/>
                                                </span>
                                            </h1>
                                            <span className="round-prof investor">
                                                Investor
                                            </span>
                                        </div>
                                    </div>
                                    <p className="description-top-cart-expert-mobile">
                                        {investor.professionalDescription ? investor.professionalDescription : 'no information'}
                                    </p>
                                </div>
                                <div className="block-body-cart-expert-mobile">
                                    <div className="block-list-button-cart-expert-mobile">
                                        {((user && user.id !== investor.userId) || !user) && <ButtonGreen
                                            TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                            EventOnClick={null}
                                            paddingButton={'26'}
                                            disabled={true}
                                            onMouseEnter={() => this.setState({mouseEnter: true})}
                                            onMouseLeave={() => this.setState({mouseEnter: false})}
                                        /> || <EditMyPageButton id={-1} type={'investor'}/>}
                                    </div>
                                    <div className="block-list-state-cart-expert-mobile">
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Location</p>
                                            <p className="description-l">{investor.role.country ? investor.role.country : 'no information'}</p>
                                        </div>

                                        <UserLang data={investor.role.lang || []}/>

                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Startup investment size range (USD)</p>
                                            {investor.profile.investmentSize && investor.profile.investmentSize.max ?
                                                <p className="description-l">$ {this.outputRange(investor.profile.investmentSize.min, investor.profile.investmentSize.max)}</p>
                                                :
                                                <p className="description-l">no information</p>
                                            }
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Startup turnover stage (USD)</p>
                                            {!investor.profile.noTurnover ? investor.profile.turnover && investor.profile.turnover.max &&
                                                <p className="description-l">$ {this.outputRange(investor.profile.turnover.min, investor.profile.turnover.max)}</p> ||
                                                <p className="description-l">no information</p>
                                                : <p className="description-l">no information</p>}
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{investor.profile.businessModelType ? investor.profile.businessModelType.replace(/,/g, ', ') : 'no information'}</p>
                                        </div>
                                    </div>
                                    <div className="block-list-info-cart-expert-mobile">
                                        {
                                            !!gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {investor.profile.marketFocus && investor.profile.marketFocus.map(id => {
                                                            return <span
                                                                className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : 'no information'
                                        }

                                        {!!investor.role.Skills.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Areas of expertise
                                                </p>
                                                <p className="list-skills-expert">
                                                    {investor.role.Skills.map(skill => {
                                                        return <span className="skill">{skill.title}</span>
                                                    })}
                                                </p>
                                            </div>
                                            : 'no information'
                                        }

                                        {!!industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Industries
                                                </p>
                                                <p className="list-skills-expert">
                                                    {investor.profile.industry && investor.profile.industry.map(id => {
                                                        return <span
                                                            className="skill">{industries.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : ''
                                        }


                                        {!!experience.length ?
                                            <div className="block-work-experience">
                                                <p className="title-block-expert">
                                                    Work experience
                                                </p>

                                                {
                                                    experience.slice(0, 3).map((exp => {
                                                        return <div className="block-item-work-experience">
                                                            <div style={{display: 'table', width: '100%'}}
                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                <div className="block-left-work-experience">
                                                                    <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                </div>
                                                                <div className="block-center-work-experience">
                                                                    <p className="description-work-experience">
                                                                        <h2>{exp.position}</h2>
                                                                        — <span
                                                                        className="gray">{exp.company}</span></p>
                                                                </div>
                                                                <div className="block-right-work-experience">
                                                                    <div
                                                                        className="block-button-open-close-work-experience">
                                                                        <i className="far fa-chevron-down"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="open-close-block-description-item-work-experience">
                                                                <div className="block-description-item-work-experience">
                                                                    <div className="block-left-work-experience">
                                                                        <p className="title-work-experience">
                                                                            {exp.city}
                                                                        </p>
                                                                    </div>
                                                                    <div className="block-center-work-experience">
                                                                        {!!exp.companyLocation &&
                                                                        <a href="" className="investor">
                                                                            {exp.companyLocation}
                                                                        </a>
                                                                        }

                                                                        <p className="description-work-experience">
                                                                            {exp.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }))
                                                }

                                                {experience.length > 3 ?
                                                    <div className="1block-item-work-experience">
                                                        <div className="open-close-block">
                                                            {
                                                                experience.slice(3, experience.length).map((exp => {
                                                                    return <div className="block-item-work-experience">
                                                                        <div style={{display: 'table', width: '100%'}}
                                                                             onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                            <div className="block-left-work-experience">
                                                                                <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                            </div>
                                                                            <div
                                                                                className="block-center-work-experience">
                                                                                <p className="description-work-experience">{exp.position} — <span
                                                                                    className="gray">{exp.company}</span>
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className="block-right-work-experience">
                                                                                <div
                                                                                    className="block-button-open-close-work-experience">
                                                                                    <i className="far fa-chevron-down"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="open-close-block-description-item-work-experience">
                                                                            <div
                                                                                className="block-description-item-work-experience">
                                                                                <div
                                                                                    className="block-left-work-experience">
                                                                                    <p className="title-work-experience">
                                                                                        {exp.city}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-center-work-experience">
                                                                                    {!!exp.companyLocation &&
                                                                                    <a href="" className="investor">
                                                                                        {exp.companyLocation}
                                                                                    </a>
                                                                                    }

                                                                                    <p className="description-work-experience">
                                                                                        {exp.description}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }))
                                                            }
                                                        </div>
                                                        <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')}
                                                           className="show-all-work-experience investor">
                                                            {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                            {!this.state.showWork ? `(+${experience.length - 3})` : ''}
                                                        </a>
                                                    </div>
                                                    : ''
                                                }
                                            </div> : ''
                                        }

                                        <div className="block-title-description-info">
                                            <p className="title-info-investor">
                                                I am looking for
                                            </p>
                                            <p className="description-info-investor">
                                                {investor.profile.investmentFocus ? investor.profile.investmentFocus : 'no information'}
                                            </p>
                                        </div>

                                        <div className="block-title-description-col">
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of investments
                                                </p>
                                                <p className="description-info-investor">
                                                    {investor.profile.investmentsCount ? investor.profile.investmentsCount : 'no information'}
                                                </p>
                                            </div>
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of Exits
                                                </p>
                                                <p className="description-info-investor">
                                                    {(investor.profile.exitsCount || investor.profile.exitsCount === 0) ? investor.profile.exitsCount : 'no information'}
                                                </p>
                                            </div>
                                        </div>

                                        {
                                            (() => {
                                                leftPI = [];
                                                rightPI = []
                                                investor.profile.previousInvestment.map((v, i) => {

                                                    let div = <div className="block-previous-investor">
                                                        <div className="block-top-previous-investor"
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                            <p className="title-previous-investor">
                                                                {v.title}
                                                            </p>
                                                            <p className="info-previous-investor">
                                                                Exit:
                                                                <span>{v.exite ? 'Yes' : 'No'}</span>
                                                                Investment size:
                                                                <span>${v.size}</span>
                                                            </p>
                                                            <div className="block-open-close-previous-investor">
                                                                <i className="far fa-chevron-down"/>
                                                            </div>
                                                        </div>
                                                        <div className="block-body-previous-investor">
                                                            <p className="description-previous-investor">
                                                                {v.position}
                                                            </p>
                                                        </div>
                                                    </div>;

                                                    if (i % 2 === 0)
                                                        leftPI.push(div);
                                                    else
                                                        rightPI.push(div);
                                                })
                                            })()
                                        }

                                        <div className="block-list-previous-investor">
                                            <p className="title-block-expert">
                                                Previous investments
                                            </p>

                                            {(leftPI.length || rightPI.length) ?
                                                <React.Fragment>
                                                    <div className="block-left-previous-investor">
                                                        {leftPI}
                                                    </div>
                                                    <div className="block-right-previous-investor">
                                                        {rightPI}
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="block-left-previous-investor">
                                                    no information
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="block-cart-expert">
                                <div className="block-left-cart-expert">
                                    <div className="block-photo-cart-expert">
                                        {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                        {_avatar}
                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Location</p>
                                        <p className="description-l">{investor.role.country ? investor.role.country : 'no information'}</p>
                                    </div>
                                    <UserLang data={investor.role.lang || []}/>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Startup investment size range (USD)</p>
                                        {investor.profile.investmentSize && investor.profile.investmentSize.max ?
                                            <p className="description-l">$ {this.outputRange(investor.profile.investmentSize.min, investor.profile.investmentSize.max)}</p>
                                            :
                                            <p className="description-l">no information</p>
                                        }
                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Startup turnover stage (USD)</p>
                                        {!investor.profile.noTurnover ? investor.profile.turnover && investor.profile.turnover.max &&
                                            <p className="description-l">$ {this.outputRange(investor.profile.turnover.min, investor.profile.turnover.max)}</p> ||
                                            <p className="description-l">no information</p>
                                            : <p className="description-l">no information</p>}
                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Business type</p>
                                        <p className="description-l">{investor.profile.businessModelType ? investor.profile.businessModelType.replace(/,/g, ', ') : 'no information'}</p>
                                    </div>
                                </div>
                                <div className="block-right-cart-expert">
                                    <div className="block-info-cart-expert">
                                        <p className="name-expert">
                                            <h1>{investor.role.firstName} {investor.role.lastName}</h1>
                                            {/*<span*/}
                                            {/*    className="check-ok-expert investor"><i*/}
                                            {/*    className="fal fa-check"/></span>*/}
                                        </p>
                                        <p className="title-prof">
                                        <span className="round-prof investor">
                                            Investor
                                        </span>
                                            {investor.professionalIdentification ? investor.professionalIdentification : 'no information'}
                                        </p>
                                        <h2 className="description-expert">
                                            {investor.professionalDescription ? investor.professionalDescription : 'no information'}
                                        </h2>

                                        {((user && user.id !== investor.userId) || !user) && <ButtonGreen
                                            TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                            EventOnClick={null}
                                            paddingButton={'26'}
                                            disabled={true}
                                            onMouseEnter={() => this.setState({mouseEnter: true})}
                                            onMouseLeave={() => this.setState({mouseEnter: false})}
                                        /> || <EditMyPageButton id={-1} type={'investor'}/>}
                                    </div>

                                    {
                                        !!gFocuses.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Geographic focus
                                                </p>
                                                <p className="list-skills-expert">
                                                    {investor.profile.marketFocus && investor.profile.marketFocus.map(id => {
                                                        return <span
                                                            className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : 'no information'
                                    }

                                    {!!investor.role.Skills.length ?
                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Areas of expertise
                                            </p>
                                            <p className="list-skills-expert">
                                                {investor.role.Skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>
                                        : 'no information'
                                    }

                                    {!!industries.length ?
                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Industries
                                            </p>
                                            <p className="list-skills-expert">
                                                {investor.profile.industry && investor.profile.industry.map(id => {
                                                    return <span
                                                        className="skill">{industries.find((a) => a.id === id).title}</span>
                                                })}
                                            </p>
                                        </div> : ''
                                    }


                                    {!!experience.length ?
                                        <div className="block-work-experience">
                                            <p className="title-block-expert">
                                                Work experience
                                            </p>

                                            {
                                                experience.slice(0, 3).map((exp => {
                                                    return <div className="block-item-work-experience">
                                                        <div style={{display: 'table', width: '100%'}}
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                            <div className="block-left-work-experience">
                                                                <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                            </div>
                                                            <div className="block-center-work-experience">
                                                                <p className="description-work-experience">
                                                                    <h2>{exp.position}</h2>
                                                                    — <span
                                                                    className="gray">{exp.company}</span></p>
                                                            </div>
                                                            <div className="block-right-work-experience">
                                                                <div
                                                                    className="block-button-open-close-work-experience">
                                                                    <i className="far fa-chevron-down"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="open-close-block-description-item-work-experience">
                                                            <div className="block-description-item-work-experience">
                                                                <div className="block-left-work-experience">
                                                                    <p className="title-work-experience">
                                                                        {exp.city}
                                                                    </p>
                                                                </div>
                                                                <div className="block-center-work-experience">
                                                                    {!!exp.companyLocation &&
                                                                    <a href="" className="investor">
                                                                        {exp.companyLocation}
                                                                    </a>
                                                                    }

                                                                    <p className="description-work-experience">
                                                                        {exp.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }))
                                            }

                                            {experience.length > 3 ?
                                                <div className="1block-item-work-experience">
                                                    <div className="open-close-block">
                                                        {
                                                            experience.slice(3, experience.length).map((exp => {
                                                                return <div className="block-item-work-experience">
                                                                    <div style={{display: 'table', width: '100%'}}
                                                                         onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                        <div className="block-left-work-experience">
                                                                            <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                        </div>
                                                                        <div className="block-center-work-experience">
                                                                            <p className="description-work-experience">{exp.position} — <span
                                                                                className="gray">{exp.company}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="block-right-work-experience">
                                                                            <div
                                                                                className="block-button-open-close-work-experience">
                                                                                <i className="far fa-chevron-down"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="open-close-block-description-item-work-experience">
                                                                        <div
                                                                            className="block-description-item-work-experience">
                                                                            <div className="block-left-work-experience">
                                                                                <p className="title-work-experience">
                                                                                    {exp.city}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className="block-center-work-experience">
                                                                                {!!exp.companyLocation &&
                                                                                <a href="" className="investor">
                                                                                    {exp.companyLocation}
                                                                                </a>
                                                                                }

                                                                                <p className="description-work-experience">
                                                                                    {exp.description}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }))
                                                        }
                                                    </div>
                                                    <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')}
                                                       className="show-all-work-experience investor">
                                                        {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                        {!this.state.showWork ? `(+${experience.length - 3})` : ''}
                                                    </a>
                                                </div>
                                                : ''
                                            }
                                        </div> : ''
                                    }


                                    <div className="block-title-description-info">
                                        <p className="title-info-investor">
                                            I am looking for
                                        </p>
                                        <p className="description-info-investor">
                                            {investor.profile.investmentFocus ? investor.profile.investmentFocus : 'no information'}
                                        </p>
                                    </div>

                                    <div className="block-title-description-col">
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Number of investments
                                            </p>
                                            <p className="description-info-investor">
                                                {investor.profile.investmentsCount ? investor.profile.investmentsCount : 'no information'}
                                            </p>
                                        </div>
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Number of Exits
                                            </p>
                                            <p className="description-info-investor">
                                                {(investor.profile.exitsCount || investor.profile.exitsCount === 0) ? investor.profile.exitsCount : 'no information'}
                                            </p>
                                        </div>
                                    </div>

                                    {
                                        (() => {
                                            leftPI = [];
                                            rightPI = [];
                                            investor.profile.previousInvestment.map((v, i) => {

                                                let div = <div className="block-previous-investor">
                                                    <div className="block-top-previous-investor"
                                                         onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                        <p className="title-previous-investor">
                                                            {v.title}
                                                        </p>
                                                        <p className="info-previous-investor">
                                                            Exit:
                                                            <span>{v.exite ? 'Yes' : 'No'}</span>
                                                            Investment size:
                                                            <span>${v.size}</span>
                                                        </p>
                                                        <div className="block-open-close-previous-investor">
                                                            <i className="far fa-chevron-down"/>
                                                        </div>
                                                    </div>
                                                    <div className="block-body-previous-investor">
                                                        <p className="description-previous-investor">
                                                            {v.position}
                                                        </p>
                                                    </div>
                                                </div>;

                                                if (i % 2 === 0)
                                                    leftPI.push(div);
                                                else
                                                    rightPI.push(div);
                                            })
                                        })()
                                    }

                                    <div className="block-list-previous-investor">
                                        <p className="title-block-expert">
                                            Previous investments
                                        </p>

                                        {(leftPI.length || rightPI.length) ?
                                            <React.Fragment>
                                                <div className="block-left-previous-investor">
                                                    {leftPI}
                                                </div>
                                                <div className="block-right-previous-investor">
                                                    {rightPI}
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div className="block-left-previous-investor">
                                                no information
                                            </div>
                                        }

                                    </div>

                                </div>


                            </div>
                            {!!listAchievements.length && <div className="block-body-expert">
                                <div className="block-achievements-investor">
                                    <p className="title-achievements-investor">Achievements</p>
                                    <div className="block-list-tab-achievements-investor">
                                        {
                                            listAchievements.length && this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {
                                                if (listAchievements[v.item.parameterId].data.length) {
                                                    return <TabsCounter
                                                        TitleTab={v.item.title}
                                                        TypeTab={'big'}
                                                        EventOnClick={() => this.setActiveAch(v.item.parameterId)}
                                                        active={v.item.parameterId === this.state.activeAchivements}
                                                        count={v.data.length}
                                                    />
                                                }
                                            })
                                        }
                                    </div>

                                    <div className="block-list-achievements-investor">
                                        {
                                            listAchievements[this.state.activeAchivements] && listAchievements.length && this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements].data.slice(0, 6).map(v => {
                                                return <div className="block-item-achievements-investor"
                                                            onClick={() => {
                                                                window.open(v.link.toUrl())
                                                            }}>
                                                    <p className="title-item-achievements-investor">
                                                        {v.description}
                                                    </p>

                                                    <div
                                                        className="block-right-achievements-cart-share">
                                                        <a href={v.link.toUrl()}
                                                           rel="noopener noreferrer"
                                                           target="_blank"> <i
                                                            className="fas fa-share-square"/>
                                                        </a>
                                                    </div>

                                                </div>
                                            })
                                        }

                                        <div className="open-close-block">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {
                                                    return <div className="block-item-achievements-investor"
                                                                onClick={() => {
                                                                    window.open(v.link.toUrl())
                                                                }}>
                                                        <p className="title-item-achievements-investor">
                                                            {v.description}
                                                        </p>
                                                        <div
                                                            className="block-right-achievements-cart-share">
                                                            <a href={v.link.toUrl()}
                                                               rel="noopener noreferrer"
                                                               target="_blank"> <i
                                                                className="fas fa-share-square"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>


                                        {this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&
                                        <div style={{float: 'none', clear: 'both'}}
                                             className="block-item-achievements-investor" onClick={(e) => {
                                            this.setState({showMoreAch: !this.state.showMoreAch});
                                            this.OpenCloseContentClick(e, '.open-close-block');

                                        }}>

                                            <p className="title-item-achievements-investor show">
                                                {!this.state.showMoreAch && <span className="show-more">Show more <i
                                                    className="far fa-chevron-down"/></span>}
                                                {this.state.showMoreAch &&
                                                <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}
                                            </p>
                                        </div>
                                        }
                                    </div>


                                </div>
                            </div>}


                            <div className="block-social-link-expert">

                                <p className="title-social-link">
                                    Follow me:
                                    {investor.role.twitterLink ? <a href={investor.role.twitterLink.toUrl()} target="_blank"><i
                                        className="fab fa-twitter"/></a> : ''}
                                    {investor.role.facebookLink ? <a href={investor.role.facebookLink.toUrl()} target="_blank"><i
                                        className="fab fa-facebook"/></a> : ''}
                                    {investor.role.linkedInLink ? <a href={investor.role.linkedInLink.toUrl()} target="_blank"><i
                                        className="fab fa-linkedin"/></a> : ''}
                                    {investor.role.mediumLink ? <a href={investor.role.mediumLink.toUrl()} target="_blank"><i
                                        className="fab fa-medium"/></a> : ''}
                                    {investor.role.telegramLink ? <a href={investor.role.telegramLink.toUrl()} target="_blank"><i
                                        className="fab fa-telegram"/></a> : ''}

                                    {investor.role.contacts && investor.role.contacts.map((contact) => {
                                        let classn = 'fa fa-link'
                                        if (/twitter/.test(contact)) {
                                            classn = 'fa-twitter'
                                        }
                                        if (/facebook/.test(contact)) {
                                            classn = 'fa-facebook'
                                        }
                                        if (/linked/.test(contact)) {
                                            classn = 'fa-linkedin'
                                        }
                                        if (/medium/.test(contact)) {
                                            classn = 'fa-medium'
                                        }
                                        if (/telegram/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }
                                        if (/t.me/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }

                                        return <a href={contact.toUrl()} target="_blank"><i
                                            className={`fab ${classn}`}/></a>
                                    })}
                                </p>
                                <p className="description-social-link">Registered {new Date(investor.createdAt).toShortFormat()}</p>
                            </div>

                            <Comments id={investor.id} user={user} type='investor'/>
                        </div>
                    </div>}
                    {this.state.showLoginPopup !== 'hide' && <LoginPopup
                        close={()=>{
                            this.setState({showLoginPopup:'hide'});
                            if(!localStorage.RocketToken){
                                history.push('/');
                            }
                        }}
                        state={this.state.showLoginPopup}
                    />}
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    investor: state,
})

Main.propTypes = {
    // getExpert: PropTypes.func.isRequired
    // updateFund: PropTypes.func.isRequired,
    // getLastFund: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(Main)
import React from 'react'
import PropTypes from 'prop-types'
import {Route, Switch, Redirect} from 'react-router-dom'
import GuestRoute from './components/routes/GuestRoute'


import UserRoute from './components/routes/UserRoute'
// import MultiFund3 from "./components/test-controls/MultiFund/MultiFund3";
import ResetPage from "./components/auth/ResetPage";
// import Passport from "./components/passport/Passport";
// import LandingPageStartUp from "./components/test-controls/LandingPage/StartUp";
// import LandingPageFund from "./components/test-controls/LandingPage/Fund";
// import Response from "./components/passport/Response";
// import PrivatMessages from "./components/home/PrivateMessages";
// import FundProject from "./components/fund/FundProject";
// import TableStartUp from "./components/test-controls/TableStartUp";
import Test from "./components/v3/popup/login";
import ViralTest from "./components/pages/ViralTest";
import Evaluation from "./components/startup4.0/preliminaryEvaluation/evaluation";
import EvaluationView from "./components/startup4.0/preliminaryEvaluation/evaluationViewContainer"
import LandingStartup from "./components/pages/EvaluationLandings/LandingStartupContainer";
import LandingInvestor from "./components/pages/EvaluationLandings/LandingInvestorContainer";
import SearchPage from "./components/pages/SearchPage/SearchStartup";
// import ProjectPage from "./components/home/Project";
// import InfoDaoPull from "./components/fund/InfoDaoPull";
// import MultiFund2 from "./components/test-controls/MultiFund/MultiFund2";
// import ListNominees from "./components/nominees/ListNominees";
// import ProjectNominee from "./components/nominees/ProjectNominee";
import ForgotPage from "./components/auth/ForgotPassPage";
// import KycPage from "./components/profile/KycPage";
// import ExpertCongratulations from "./components/expert/ExpertCongratulations";
// import StartUpPage from "./components/project/StartUpPage";
import Forbidden from "./components/403";
// import UserBase from "./components/user/UserBase";
// import MenuProject from "./components/test-controls/MenuProject/MenuProject";
// import backers from "./components/test-controls/fund/backers";
// import FundsPage from "./components/fund/FundsPage";
// import ProjectContainer from "./components/project/ProjectContainer";
// import MultiFund1 from "./components/test-controls/MultiFund/MultiFund1";
import LandingPageAbout from "./components/test-controls/LandingPage/about";
// import FundProjectAllStete from "./components/test-controls/FundProjectAllStete/FundProjectAllStete";
// import ProfileContainer from "./components/profile/ProfileContainer";

// import ProfileContainer2 from "./components/profile2/ProfileContainer";
// import SettingsContainer from "./components/profile2/SettingsContainer";


// import NewRoadMap from "./components/test-controls/NewRoadMap/NewRoadMap";
// import CreateSmart from "./components/project/CreateSmart";
import Logout from "./components/home/Logout";
// import LandingPageReview from "./components/test-controls/LandingPage/Review";
import JoinPage from "./components/auth/JoinPage";
// import ExpertRegister from "./components/expert/ExpertRegister";
// import InfoFromUser from "./components/InfoFromUser";
// import ProjectsBoard from "./components/project/ProjectsBoard";
// import LandingPageExpert from "./components/test-controls/LandingPage/Expert";
// import investorGuide from "./components/test-controls/LandingPage/investorGuide";
// import HomePage from "./components/home/HomePage";
import Marketplace from "./components/home/nmp";
import OldMarketplace from "./components/home/mp";
// import meta from "./components/test-controls/newStartPage/meta";
// import CreateFund from "./components/fund/CreateFund";
// import FundContainer from "./components/fund/FundContainer";
// import multiFund from "./components/project/multiFund";
import NotFound from "./components/NotFound";
// import profileFunds from "./components/test-controls/profileFunds/profileFunds";
import ExpertBase from "./components/expert/ExpertBase";
// import newMenuTop from "./components/test-controls/newMenuTop/new-menu-top";
// import Welcome from "./components/registration/Welcome";
import RegistrationContainer from "./components/registration/RegistrationContainer";
import RegistrationStart from "./components/registration/Start";
import InvestorStart from "./components/registration/InvestorStart";
import AcceleratorStart from "./components/registration/AcceleratorStart";
import Login from "./components/registration/Login";
import LinkedinAuth from "./components/registration/LinkedinAuth";
import JoinNow from "./components/registration/ChooseRole";

// import ProfilePreview from "./components/registration/ProfilePreview";

// import InvestorRocketProject from "./components/test-controls/InvestorRocketProject";
// import MarketingPR from "./components/test-controls/MarketingPR";
// import Marketing from "./components/test-controls/Page/Marketing";
// import Legal from "./components/test-controls/Page/Legal";
// import Tokenomics from "./components/test-controls/Page/Tokenomics";
// import Product from "./components/test-controls/Page/Product";
// import BusinessPlan from "./components/test-controls/Page/BusinessPlan";
// import ICOManagement from "./components/test-controls/Page/ICOManagement";
// import Team2_0 from "./components/test-controls/Page2.0/Team";
// import reg from "./components/test-controls/Page2.0/reg";
// import RoadMap from "./components/test-controls/Page2.0/RoadMap";
// import Campaign from "./components/test-controls/Page2.0/Campaign";
// import Tokenomics2_0 from "./components/test-controls/Page2.0/Tokenomics";
// import StartPage from "./components/test-controls/Page2.0/StartPage";
// import GabilFund from "./components/test-controls/Page2.0/gabil-fund-4";
// import inputControl from "./components/test-controls/Page2.0/inputControl";
import PageViewControls from './components/test-controls/newControlsView/PageViewControls'

import IIAVCreateMain from './components/IIAV/container';
import UserContainer from './components/user4.0/container';
import ExpertContainer from './components/expert4.0/container';
import InvestorContainer from './components/investor4.0/container';
import FundContainer4 from './components/fund4.0/container';
import AngelContainer from './components/angel_network4.0/container';
import StartupContainer from './components/startup4.0/container';
import AcceleratorContainer from './components/accelerator4.0/container';

import InvestClub from './components/investclub/InvestClub';

import './components/controls/ToolTip/ToolTip.css'

import Edit from './components/edit'
import Paddle from './components/Paddle'
import StartupProfile from './components/header/menu/startupProfile';
import MiniReg from './components/miniRegistration';
import slushAuth from './components/miniRegistration/auth';
import UnsubscribeNewsletter from './components/unsubscribe'
import config from "./config";
import {GridTest} from './components/grid_test';
import ExternalContainer from './components/external';

import Documents from "./components/documents";
import Payments from "./components/payments";
import {TeamMail} from "./components/TeamMail";

import {HellBaraban} from "./components/v3/hellBaraban";
import axios from "axios";
import qs from "qs";

function PDFViewRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                <React.Fragment>
                    {
                        (() => {
                            let data = config.proxy + `/file/pdf/${props.match.params.filename}/view`;
                            return <object data={data} type="application/pdf" style={{width: '100%', height: '100vh'}}/>
                        })()
                    }
                </React.Fragment>
            }
        />
    );
}

function GoRoute({...rest}) {
    const q = qs.parse(rest.location.search, {ignoreQueryPrefix: true});

    const href = q.link;
    const id = q.id;

    if(href){
        axios.post(`/v2/mail/stats/click/${id}`, {link: href});
    }
    window.location.href = href || 'https://rocketdao.io';
    return (
        <React.Fragment/>
    )
}


function ClearLocalStorage({...rest}) {

    localStorage.removeItem("ViralTest");
    localStorage.clear();

    return (
        <React.Fragment>
            <h1>Clear</h1>
        </React.Fragment>
    )
}

const Routes = () => (
    <Switch>

        <PDFViewRoute exact path='/DueD/:filename'/>
        <PDFViewRoute exact path='/Pre-Evaluation/:filename'/>

        <GuestRoute exact path="/" component={Marketplace}/>
        <GuestRoute exact path="/omp" component={OldMarketplace}/>
        <GuestRoute exact path="/PageViewControls" component={PageViewControls}/>

        <GuestRoute exact path='/startup_test' component={ViralTest}/>
        <Redirect exact from='/test/viral' to='/startup_test'/>

        <GuestRoute exact path='/evaluation' component={Evaluation}/>
        <Route exact path="/startup/:id/evaluation/view" component={EvaluationView}/>
        <GuestRoute exact path='/evaluation/landing/startup' component={LandingStartup}/>
        <GuestRoute exact path='/evaluation/landing/investor' component={LandingInvestor}/>
        <GuestRoute exact path='/search/:type' component={SearchPage}/>
        <GuestRoute exact path='/unsubscribe/:token/:emailId' component={UnsubscribeNewsletter}/>
        <GoRoute exact path='/go'/>

        <GuestRoute exact path="/join" component={JoinNow}/>
        <Route exact path='/clean' component={ClearLocalStorage}/>

        <Redirect exact from='/mp' to='/'/>

        {/*<GuestRoute path="/welcome" component={Welcome}/>*/}

        <Route path="/registration" component={RegistrationContainer}/>
        {/*<Route path="/profile_preview" component={ProfilePreview}/>*/}


        <UserRoute exact path="/edit/:type/:id/:page" component={props => <Edit {...props}/>}/>
        <UserRoute exact path="/edit/:type/:id" component={props => <Edit {...props}/>}/>
        <UserRoute exact path="/edit/:type" component={props => <Edit {...props}/>}/>

        <UserRoute path="/investclub" component={InvestClub}/>

        <Route path="/create" component={IIAVCreateMain}/>
        <Route path="/user/:id" component={UserContainer}/>
        <Route path="/expert/:id" component={ExpertContainer}/>
        <Route path="/investor/:id" component={InvestorContainer}/>

        <Route exact path="/fund/:id/nonpublic/:verify" component={ExternalContainer}/>
        <Route exact path="/angel_network/:id/nonpublic/:verify" component={ExternalContainer}/>
        <Route exact path="/accelerator/:id/nonpublic/:verify" component={ExternalContainer}/>

        <Route path="/fund/:id" component={FundContainer4}/>

        <Route path="/team/approve" component={TeamMail}/>
        <Route path="/team/reject" component={TeamMail}/>



        <Route path="/angel_network/:id" component={AngelContainer}/>
        <Route path="/startup/:id" component={StartupContainer}/>
        <Route path="/accelerator/:id" component={AcceleratorContainer}/>
        <Route path="/test/paddle" component={Paddle}/>
        <Route path="/test/grid" component={GridTest}/>
        <Route path="/test/baraban" component={HellBaraban}/>

        <GuestRoute path="/slush" component={MiniReg}/>
        <GuestRoute path="/start_reg" component={RegistrationStart}/>
        <GuestRoute path="/start_investor" component={InvestorStart}/>
        <GuestRoute path="/start_accelerator" component={AcceleratorStart}/>

        <GuestRoute path="/login" component={Login}/>
        <GuestRoute path="/linkedin_auth" component={LinkedinAuth}/>
        <GuestRoute path="/StartupProfile" component={StartupProfile}/>
        <GuestRoute path="/logout" component={Logout}/>
        <GuestRoute path='/join' component={JoinPage}/>
        <GuestRoute path='/forgot' component={ForgotPage}/>
        <GuestRoute path='/reset-password/:token' component={ResetPage}/>
        <GuestRoute path="/about" component={LandingPageAbout}/>
        <GuestRoute path="/knowledge_base" component={ExpertBase}/>

        <GuestRoute path="/document" component={Documents}/>

        <GuestRoute path="/payments/:productId" component={Payments}/>

        <Route path='/404' component={NotFound}/>
        <Route component={NotFound}/>


        {/*<GuestRoute path="/tg_passport" component={Passport}/>*/}
        {/*<GuestRoute path="/tg_passport/:response" component={Response}/>*/}

        {/*<GuestRoute exact path="/funds" component={FundsPage}/>*/}
        {/*<GuestRoute path="/experts" component={ExpertBase}/>*/}

        {/*<GuestRoute path="/congratulations" component={ExpertCongratulations}/>*/}

        {/*<GuestRoute path="/startups" component={StartUpPage}/>*/}
        {/*<GuestRoute path="/TableStartUp" component={TableStartUp}/>*/}
        {/*<GuestRoute path="/guide" component={investorGuide}/>*/}
        {/*<GuestRoute path="/expert_info" component={LandingPageExpert}/>*/}
        {/*<GuestRoute path="/startup_info" component={LandingPageStartUp}/>*/}
        {/*<GuestRoute path="/fund_info" component={LandingPageFund}/>*/}
        {/*<GuestRoute path="/review" component={LandingPageReview}/>*/}
        {/*<GuestRoute path="/loadMini" component={loadMini}/>*/}

        {/*<GuestRoute path="/multiFund" component={multiFund}/>*/}
        {/*<GuestRoute path="/NewRoadMap" component={NewRoadMap}/>*/}
        {/*<GuestRoute path="/FundProjectAllStete" component={FundProjectAllStete}/>*/}
        {/*<GuestRoute path="/project" component={ProjectPage}/>*/}
        {/*<GuestRoute path="/MultiFund1" component={MultiFund1}/>*/}
        {/*<GuestRoute path="/MultiFund2" component={MultiFund2}/>*/}
        {/*<GuestRoute path="/MultiFund3" component={MultiFund3}/>*/}
        {/*<GuestRoute path="/profileFunds" component={profileFunds}/>*/}
        {/*<GuestRoute path="/preloaderNew" component={preloaderNew}/>*/}
        {/*<GuestRoute path="/backers" component={backers}/>*/}
        {/*<GuestRoute path="/meta" component={meta}/>*/}

        {/*<GuestRoute exact path="/users/:id" component={UserBase}/>*/}
        {/*<GuestRoute path="/funds/:id" component={FundContainer}/>*/}
        {/*<GuestRoute path="/projects/:id" component={ProjectContainer}/>*/}
        {/*<GuestRoute path="/list_nominees" component={ListNominees}/>*/}
        {/*<GuestRoute path="/projects_nominees/:id" component={ProjectNominee}/>*/}

        {/*<UserRoute path='/profile' component={ProfileContainer}/>*/}

        {/*<UserRoute exact path='/profile2' component={ProfileContainer2}/>*/}
        {/*<UserRoute path='/profile2/settings' component={SettingsContainer}/>*/}


        {/*<UserRoute path='/private_messages' component={PrivatMessages}/>*/}
        {/*<UserRoute path='/projects_board' component={ProjectsBoard}/>*/}
        {/*<UserRoute path='/kyc' component={KycPage}/>*/}
        {/*<UserRoute path="/create_fund" component={CreateFund}/>*/}
        {/*<UserRoute path="/create_smart" component={CreateSmart}/>*/}
        {/*<UserRoute path="/fund_info" component={InfoDaoPull}/>*/}
        {/*<UserRoute path="/fund_projects" component={FundProject}/>*/}

        {/*<UserRoute path='/newMenuTop' component={newMenuTop}/>*/}
        {/*<UserRoute path='/MenuProject' component={MenuProject}/>*/}
        {/*<UserRoute path='/403' component={Forbidden}/>*/}

        {/*<UserRoute path='/rdtt' component={InfoFromUser}/>*/}
        {/*<UserRoute exact path="/expert_register" component={ExpertRegister}/>*/}
        {/*<UserRoute path='/InvestorRocketProject' component={InvestorRocketProject}/>*/}
        {/*<UserRoute path='/MarketingPR' component={MarketingPR}/>*/}
        {/*<UserRoute path='/Marketing' component={Marketing}/>*/}
        {/*<UserRoute path='/Legal' component={Legal}/>*/}
        {/*<UserRoute path='/Tokenomics' component={Tokenomics}/>*/}
        {/*<UserRoute path='/BusinessPlan' component={BusinessPlan}/>*/}
        {/*<UserRoute path='/ICOManagement' component={ICOManagement}/>*/}
        {/*<UserRoute path='/Product' component={Product}/>*/}
        {/*<UserRoute path='/Team2_0' component={Team2_0}/>*/}
        {/*<UserRoute path='/RoadMap' component={RoadMap}/>*/}
        {/*<UserRoute path='/Campaign' component={Campaign}/>*/}
        {/*<UserRoute path='/Tokenomics2_0' component={Tokenomics2_0}/>*/}
        {/*<UserRoute path='/StartPage' component={StartPage}/>*/}
        {/*<UserRoute path='/inputControl' component={inputControl}/>*/}
        {/*<UserRoute path='/reg' component={reg}*/}
        {/*openContent={false}/>*/}
        {/*<UserRoute path='/test22' component={GabilFund}/>*/}
    </Switch>
)

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes
import React, {useEffect, useState} from 'react';
import {Row} from "../Grid/Row";
import './style.scss';

export const NoInformation = ({lines = 1}) => {

    if(lines < 2){
        return <Row className={'no-information no-gutters'}/>
    } else {
        return Array(lines).fill(1).map(() => {
            return <Row className={'no-information no-gutters'}/>;
        })
    }


};
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Dropzone from 'react-dropzone'

import {updateExpert, getAchievement, createAchievement, editAchievement, deleteAchievement} from "../../../actions/Login";
import {showMessage} from "../../../utils/showMessage";

import packageJSON from './../../../../package.json';
import Categorie from "../../controls3.0/categorie/Categorie";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import TabsCounter from "../../controls3.0/tabs/TabsCounter";
import Input from "../../controls3.0/input/Input";
import {regexUrl} from "../../../utils/RegExp";
import TextArea from "../../v3/Textarea";

const TYPES = [
    {
        id: 0,
        title: 'Articles',
        description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
    },
    {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'},
    {id: 2, title: 'Diploma', description: 'Documents and any documentary proof of your educational background.'},
    {
        id: 3,
        title: 'Projects',
        description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
    },
    {id: 4, title: 'Mass media', description: 'Links to the articles about you or about your professional experience.'},
    {id: 5, title: 'Recommendations', description: 'Reviews of your expert works and professional collaboration.'},
]


class Step13 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        if (!Array.isArray(expert.parameters)) {
            expert.parameters = [];
        }
        this.state = {
            user: user,
            expert: expert,
            types: TYPES,
            listAchievements: {},
            activeParam: {},
            activeType: {},
            newA: {
                link: '',
                description: ''
            },
            editA: {},
            errors: {},
            errors2: {},
            isActiveForm: false
        }
    }

    componentDidMount() {
        let obj = {}
        try {
            Promise.all(
                this.props.user.expert.parameters.map(async (item, key) => {
                    let achievement = await getAchievement('expert', item.id)
                    obj[item.id] = achievement.data
                    return (achievement.data)
                })
            ).then(results => {
                console.log(obj)
                this.setState({listAchievements: obj})
            })
        } catch (e) {

        }
    }


    create = () => {
        let {activeParam, activeType, newA, errors} = this.state;
        let data = {
            parameterId: activeParam.id,
            achievementType: activeType.id,
            role: 'expert',
            link: newA.link,
            description: newA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors: {...this.state.errors, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})
        createAchievement(data, activeParam.id)
            .then(res => {
                this.addToList(res.data)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    edit = () => {
        let {activeParam, activeType, editA} = this.state;
        let data = {
            parameterId: activeParam.id,
            achievementType: activeType.id,
            role: 'expert',
            link: editA.link,
            description: editA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors2: {...this.state.errors2, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})
        editAchievement(data, activeParam.id, editA.id)
            .then(res => {
                this.addToList(res.data, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    delete = () => {
        deleteAchievement(this.state.activeParam.id, this.state.editA.id)
            .then(res => {
                this.addToList(this.state.editA, false, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(!value){
            this.setState({errors: {...this.state.errors, [name] : 'Field is required'}})
        } else {
            this.setState({errors: {...this.state.errors, [name] : ''}})
        }
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                this.setState({errors: {...this.state.errors, link : ''}})
            }
        }
        if (value.length > 100 && name == 'description') {
            this.setState({errors: {...this.state.errors, [name] : 'The text is too long, 100 symbols max'}});
            value = value.slice(0, 100);
        }
        this.setState({newA: {...this.state.newA, [name]: value}})
    }
    onChange2 = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(!value){
            this.setState({errors2: {...this.state.errors2, [name] : 'Field is required'}})
        } else {
            this.setState({errors2: {...this.state.errors2, [name] : ''}})
        }
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                this.setState({errors2: {...this.state.errors2, link : ''}})
            }
        }
        if (value.length > 100 && name == 'description') {
            this.setState({errors2: {...this.state.errors2, [name] : 'The text is too long, 100 symbols max'}});
            value = value.slice(0, 100);
        }
        this.setState({editA: {...this.state.editA, [name]: value}})
    }


    setActiveParam = (param) => {
        this.setState({activeParam: param})
    }
    setActiveType = (type) => {
        this.setState({activeType: type})
    }

    update = (nextStep, onlyStep) => {

        //alert(onlyStep);

        let expert = this.state.expert;

        if (!onlyStep) {

        }

        let loading = nextStep == 12 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert({expert, event_type: 'expert_achievements_10'}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }
    addToList = (item, edit, _delete) => {
        console.log(item)
        let arr = this.state.listAchievements[item.parameterId]

        if(edit) {
            let index;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) arr[index] = item;
            this.setState({editA: {}})
        } else if(_delete) {
            let index ;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) delete arr[index];
            this.setState({editA: {}})
        } else {
            arr.push(item)
        }

        this.setState({listAchievements: {...this.state.listAchievements, [item.parameterId]: arr}})
        this.handleClearNewA()
    }
    handleClearNewA = () => {
        this.setState({loadingCreate: false})
        this.setState({
            newA: {
                link: '',
                description: ''
            },
            isActiveForm: false
        })
    }


    render() {
        let {user, expert, types, newA, editA, activeParam, activeType, listAchievements, errors, errors2} = this.state;

        let isNoEmpty;
        for (let key in listAchievements) {
            if(listAchievements[key].length) isNoEmpty = true
        }

        const dropStyle = {
            'display': 'inline-block',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        return (
            <React.Fragment>

                {!activeParam.title &&
                <React.Fragment>
                    <div className="block-gradient" style={{top: '370px'}}/>
                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <div className="container-step-2-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>

                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                Your expert parameters
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20" style={{maxWidth:'680px'}}>
                                Providing proofs of your expertise will increase community's credibility to you as to a professional.
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-150"
                               style={{fontWeight: '600'}}>
                                Click at each parameter in order to add any kind of achievements or proofs
                                verifying <br/>
                                your expertise. The more details, the better.
                            </p>

                            <div className="block-list-category">
                                {expert.parameters.map((item, key) =>
                                    <Categorie
                                        key={key}
                                        TitleCategorie={item.title}
                                        EventOnClick={() => this.setActiveParam(item)}
                                        active={(listAchievements && listAchievements[item.id]) ? (listAchievements[item.id]).length : false}
                                        count={(listAchievements && listAchievements[item.id]) ? (listAchievements[item.id]).length : ''}
                                    />
                                )}
                            </div>
                        </div>
                    </div>


                    {!this.state.wantSkip &&

                    <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                        <div className="container-full-reg" style={{textAlign:'right'}}>
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(12, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    This is very important, but please <br/>
                                    let me fill it in later on my profile
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Skip this step'}
                                    EventOnClick={() => this.setState({wantSkip: true})}
                                    paddingButton={'35'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.update(14, true)}
                                    paddingButton={'56'}
                                    disabled={!isNoEmpty}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.wantSkip &&

                    <div className="block-fix-button-reg-bottom fix-button-double-details-double achieve-details">
                        <div className="container-full-reg" style={{textAlign:'right'}}>
                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    We will remind you to fill in the data later <br/>
                                    if you lack time right now.
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Continue with registration'}
                                    EventOnClick={() => this.update(14, true)}
                                    paddingButton={'20'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Provide my achievements'}
                                    EventOnClick={() => this.setState({wantSkip: false})}
                                    paddingButton={'22'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
                }

                {activeParam.title && !activeType.title &&
                <React.Fragment>
                    <div className="container-full-reg" style={{position: 'relative'}}>
                        <div className="block-button-circle-title">
                            <div className="block-button-circle" onClick={() => this.setState({activeParam: {}})}>
                                <i className="fal fa-long-arrow-left"/>
                            </div>
                            <p className="title">
                                Expert <br/>
                                Parameters
                            </p>
                        </div>

                        <div className="container-step-2-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>

                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15 new-mar-40-top">
                                {activeParam.title}
                            </p>
                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-40">
                                In what way would you like to provide information about your <br/>
                                professional experience?
                            </p>

                            <div className="block-cart-list-activity-reg-expert">
                                <div className="block-left-cart-list-activity-reg-expert">
                                    {
                                        types.map((item, key) => {
                                            if (key <= 2) {
                                                return (
                                                    <div key={key} className="block-item-cart-activity-reg-expert"
                                                         onClick={() => this.setActiveType(item)}>
                                                        <p className="title-top-page-reg-expert text-18-500 left-text margin-0 color-text-reg-black"
                                                           style={{margin: '0 0 3px 0'}}>
                                                            {item.title}
                                                        </p>
                                                        <p className="title-top-page-reg-expert text-sub-title left-text margin-0 color-text-reg-white-gray">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="block-right-cart-list-activity-reg-expert">
                                    {
                                        types.map((item, key) => {
                                            if (key >= 3) {
                                                return (
                                                    <div key={key} className="block-item-cart-activity-reg-expert"
                                                         onClick={() => this.setActiveType(item)}>
                                                        <p className="title-top-page-reg-expert text-18-500 left-text margin-0 color-text-reg-black"
                                                           style={{margin: '0 0 3px 0'}}>
                                                            {item.title}
                                                        </p>
                                                        <p className="title-top-page-reg-expert text-sub-title left-text margin-0 color-text-reg-white-gray">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                }

                {activeType.title &&
                <React.Fragment>
                    <div className="container-full-reg" style={{position: 'relative'}}>

                        <div className="block-button-circle-title">
                            <div className="block-button-circle" onClick={() => this.setState({activeParam: {}, activeType: {} })}>
                                <i className="fal fa-long-arrow-left"/>
                            </div>
                            <p className="title">
                                Expert <br/>
                                Parameters
                            </p>
                        </div>

                        <div className="container-step-2-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15 new-mar-40-top">
                                {activeParam.title}
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                                Please tell us about a bit more about your professional experience. <br/>
                                Choose the categories of achievements you can provide the information for.
                            </p>

                            <div className="block-list-tabs-counter-reg-expert">
                                {types.map((item, key) =>
                                    <TabsCounter
                                        key={key}
                                        TitleTab={item.title}
                                        TypeTab={'big'}
                                        EventOnClick={() => this.setState({activeType: item, isActiveForm: false})}
                                        active={activeType.id === item.id}
                                        count={ (listAchievements[activeParam.id].filter((ach) => item.id === ach.achievementType)).length }
                                    />
                                )}
                            </div>

                            {( !this.state.isActiveForm && !!(listAchievements[activeParam.id].filter((ach) => activeType.id === ach.achievementType)).length ) &&
                            <div className={'block-add-activity-reg-exp'}>
                                <ButtonBlue
                                    TitleButton={`Add ${activeType.title}`}
                                    EventOnClick={() => this.setState({isActiveForm: true})}
                                    paddingButton={'30'}
                                    disabled={false}
                                />
                            </div>
                            }

                            {( this.state.isActiveForm || (!this.state.isActiveForm && !(listAchievements[activeParam.id].filter((ach) => activeType.id === ach.achievementType)).length )) &&
                            <div className="block-form-add-edit-modal-reg-expert">
                                <div className="block-input-item-form-add-edit-modal-reg-expert">
                                    <p className="title">Insert the link</p>
                                    <Input
                                        type={'text'}
                                        name={'link'}
                                        value={newA.link}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errors.link}
                                    />
                                </div>
                                <div className="block-text-area-form-add-edit-modal-reg-expert">
                                    <p className="title">Leave comments</p>
                                    <TextArea name={'description'} val={newA.description}
                                              cols="30" rows="10"
                                              onChange={this.onChange}
                                              error={errors.description}
                                    />
                                </div>
                                <div className="block-button-form-add-edit-modal-reg-expert">
                                    <ButtonBlue
                                        TitleButton={`Add ${activeType.title}`}
                                        EventOnClick={() => this.create()}
                                        paddingButton={'54'}
                                        disabled={!newA.link || !newA.description}
                                        loader={this.state.loadingCreate}
                                    />
                                </div>
                            </div>
                            }

                            <div className="block-list-active-activity-reg-expert">
                                <div className="block-list-item-active-activity-reg-expert">
                                    {listAchievements[activeParam.id].map((ach, k) => {
                                        if (activeType.id === ach.achievementType) {
                                            return (
                                                <React.Fragment key={k}>
                                                    <div className="block-item-active-activity-reg-expert">
                                                        <div className="block-left-item-active-activity-reg-expert">
                                                            <p className="title">
                                                                {ach.link}
                                                            </p>
                                                            <p className="description">
                                                                {ach.description}
                                                            </p>
                                                        </div>
                                                        <div className="block-right-item-active-activity-reg-expert">
                                                            <p className="button-edit" onClick={() => this.setState({editA: ach})}>
                                                                Edit
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {editA.id === ach.id &&
                                                        <div className="block-form-add-edit-modal-reg-expert"
                                                             style={{marginBottom: '12px'}}>
                                                            <div
                                                                className="block-input-item-form-add-edit-modal-reg-expert">
                                                                <p className="title">Insert the link</p>
                                                                <Input
                                                                    type={'text'}
                                                                    name={'link'}
                                                                    value={editA.link}
                                                                    onChange={this.onChange2}
                                                                    placeholder={''}
                                                                    error={errors2.link}
                                                                />
                                                            </div>
                                                            <div
                                                                className="block-text-area-form-add-edit-modal-reg-expert">
                                                                <p className="title">Leave comments</p>
                                                                <TextArea name={'description'}
                                                                          val={editA.description}
                                                                          cols="30" rows="10"
                                                                          onChange={this.onChange2}
                                                                          error={errors2.description}
                                                                />
                                                            </div>
                                                            <div
                                                                className="block-button-form-add-edit-modal-reg-expert">
                                                                <ButtonBlue
                                                                    TitleButton={`Edit`}
                                                                    EventOnClick={() => this.edit()}
                                                                    paddingButton={'54'}
                                                                    disabled={!editA.link || !editA.description}
                                                                />

                                                                <p className="button-delete" onClick={() => this.delete()}>
                                                                    Delete
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }

                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                </div>
                            </div>


                            {( !this.state.isActiveForm && !!(listAchievements[activeParam.id].filter((ach) => activeType.id === ach.achievementType)).length ) &&
                            <div style={{ margin: '50px 0 0 0',textAlign:'right'}}>
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.setState({activeType: {}, activeParam: {}})}
                                    paddingButton={'40'}
                                    disabled={false}
                                />
                            </div>
                            }

                        </div>
                    </div>

                </React.Fragment>
                }


            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step13.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step13)


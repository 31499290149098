import React from 'react';

import axios from 'axios';
import qs from 'qs';




const isExist = (cb) => {
    try {
        return cb();
    } catch (e) {
        return false;
    }
}

export const TeamMail = () => {

    const q = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    const {verifyCode, object, user} = q;

    if(verifyCode && object && user){
        if(/approve/.test(window.location.pathname)){
            axios.post('/v2/funds/-1/team/mail/approve', {
                verifyCode, fundId: object, userId: user
            })
        }

        if(/reject/.test(window.location.pathname)){
            axios.post('/v2/funds/-1/team/mail/reject', {
                verifyCode, fundId: object, userId: user
            })
        }
        return <React.Fragment/>;
    } else {
        return <React.Fragment/>;
    }
}
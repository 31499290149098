import axios from "axios";
import {
  userLoggedIn,
  getUser,
  userLoggedOut,
  updateUserInfo,
    updateFundInfo, updateAngelInfo, updateExpertInfo
} from "./AuthActions";
import _ from "lodash";
import { setAuthToken } from "../utils/setAuthToken";
import {goToStep, getQuery} from "../utils/regNavigation";
import {showMessage} from "../utils/showMessage";
import {updateProjectInfo} from "./ProjectActions";
import history from "../history";

//send data for sign in of user
export const login = (data, ref_b, ref_f, team_r, team_f, team_a) => {
  const sessionId = localStorage.user_identifier;

  return axios.post(`/users/auth?ref_f=${ref_f}&ref_b=${ref_b}&team_r=${team_r}&team_f=${team_f}&team_a=${team_a}&session_id=${sessionId}`, data);
};
//send data for create account
export const register = (data, role, ref_b, ref_f, team_r, team_f, team_a, search) => {
    const sessionId = localStorage.user_identifier;
    data.search = search;
  return axios.post(`/users?role=${role}&ref_b=${ref_b}&ref_f=${ref_f}&team_r=${team_r}&team_f=${team_f}&team_a=${team_a}&session_id=${sessionId}`, data);
};
//send token for sign in via facebook
export const signUpFacebook = (token, role, ref_b, ref_f, team_r, team_f, team_a) => {
    const sessionId = localStorage.user_identifier;
  return axios.get(`/v1/auth/facebook/token?access_token=${token}&role=${role}&ref_f=${ref_f}&ref_b=${ref_b}&team_r=${team_r}&team_f=${team_f}&team_a=${team_a}&session_id=${sessionId}`);
};
//send token for sign in via google
export const signUpGoogle = (token, role, ref_b, ref_f, team_r, team_f, team_a) => {
    const sessionId = localStorage.user_identifier;
  return axios.get(`/v1/auth/google/token?access_token=${token}&role=${role}&ref_f=${ref_f}&ref_b=${ref_b}&team_r=${team_r}&team_f=${team_f}&team_a=${team_a}&session_id=${sessionId}`);
};
//send token for sign in via telegram
export const signUpTelegram = data => {
    return axios.post("/auth/telegram", data);
};
//send email for resetting of password
export const forgot = email => {
  return axios.post("/users/generate-reset-password-token", { email: email });
};

export const resendMail = (email, type) => {
    return axios.post(`/users/activation/resend?email=${email}&type=${type}`);
};

//send new password
export const setNewPassword = (password, token) => {
  return axios.put("/users/reset-password/" + token, { password: password });
};

export const tokenLogin = token => {
  //Add header 'Authorization' to every request to the server.
  setAuthToken(token);
  //get request
  return dispatch => {
    return axios
      .get("/users/auth")
      .then(res => {
        const user = res.data;
        // localStorage.setItem("userId", user.id);
        //   if(user.isAdmin) {
        //       localStorage.setItem('isAdmin', true)
        //   }
        if(user.isAdmin) {
            localStorage.setItem('mmod', true);
        } else {
            localStorage.removeItem('mmod');
        }
        if (_.isNil(res.data.error)) {
          dispatch(getUser(user));
        }
      })
      .catch(error => {
        localStorage.removeItem("RocketToken");
        setAuthToken(false);
        dispatch(userLoggedOut());


      });
  };
};

export const createRole = (data) => {
    return axios.post(`/v2/experts`, data);
};

export const updateInvestor = (investor = {}, onlyStep, nextStep, goToUrl = null) => {
    let obj = investor;
    obj.registrationStep = nextStep
    if(onlyStep) obj = {registrationStep: nextStep, event_type: investor.event_type, event_data: investor.event_data};
    obj.role = 'investor'

    return dispatch => {
        return axios
            .put("/v2/investor", obj)
            .then(res => {
                dispatch(updateUserInfo(res.data));

                if(goToUrl){
                    history.push(`/registration/investor/${nextStep}`)
                } else {
                    goToStep(investor.registrationStep || nextStep)
                }
                // if(onlyStep){
                //     goToStep(expert.registrationStep)
                // } else {
                //     return res
                // }
            })
            .catch(err => {
                goToStep(nextStep);
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};



export const updateFund = (fund = {}, id, onlyStep, nextStep, goToUrl = true) => {
    let obj = fund;
    obj.registrationStep = nextStep;
    if(onlyStep) obj = {registrationStep: nextStep, event_type: fund.event_type, event_data: fund.event_data};

    return dispatch => {
        return axios
            .put(`/v2/funds/${id}`, obj)
            .then(res => {
                dispatch(updateFundInfo(res.data));
                if(goToUrl){
                    history.push(`/create/fund/${nextStep}?id=${id}`)
                } else {
                    goToStep(fund.registrationStep || nextStep)
                }
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const updateAngel = (fund = {}, id, onlyStep, nextStep, goToUrl = true) => {
    let obj = fund;
    obj.registrationStep = nextStep;
    if(onlyStep) obj = {registrationStep: nextStep, event_type: fund.event_type, event_data: fund.event_data};

    return dispatch => {
        return axios
            .put(`/v2/angels/${id}`, obj)
            .then(res => {
                dispatch(updateAngelInfo(res.data));
                if(goToUrl){
                    history.push(`/create/angel_network/${nextStep}?id=${id}`)
                } else {
                    goToStep(fund.registrationStep || nextStep)
                }

            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const getLastFund = () => {
    return dispatch => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        let url = '/v2/funds/last';
        if (id) {
            url =  `/v2/funds/my/${id}`;

            return axios
                .get(url)
                .then(res => {
                    if(res.data.registrationStep){
                        dispatch(updateFundInfo(res.data));
                        //dispatch(updateAngelInfo(res.data));
                        goToStep(res.data.registrationStep)
                        //dispatch(updateFundInfo(res.data));
                    } else {
                        goToStep(1)
                    }
                    // dispatch(updateFundInfo(res.data));
                    // goToStep(res.data.registrationStep)
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                    throw err
                })
        }

    };
};

export const getLastAngel = () => {
    return dispatch => {

        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        let url = '/v2/angels/last';
        if (id) {
            url =  `/v2/angels/my/${id}`;

            return axios
                .get(url)
                .then(res => {
                    if(res.data.registrationStep){
                        dispatch(updateAngelInfo(res.data));
                        goToStep(res.data.registrationStep)
                    } else {
                        goToStep(1)
                    }
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                    throw err
                })
        }


    };
};

// export const getExpert = (id) => {
//     return dispatch => {
//         return axios
//             .get(`/v2/experts/${id}`)
//             .then(res => {
//                 dispatch(updateExpertInfo(res.data));
//             })
//             .catch(err => {
//                 showMessage({type: 'error', data: err})
//                 throw err
//             })
//     };
// };

export const createFund = (fund = {}, onlyStep, nextStep) => {
    let obj = fund;
    obj.registrationStep = nextStep;
    if(onlyStep) obj = {registrationStep: nextStep};
    obj.role = 'fund';

    return dispatch => {
        return axios
            .post("/v2/funds", obj)
            .then(res => {
                dispatch(updateFundInfo(res.data));
                goToStep(fund.registrationStep || nextStep)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const createAngel = (fund = {}, onlyStep, nextStep) => {
    let obj = fund;
    obj.registrationStep = nextStep;
    if(onlyStep) obj = {registrationStep: nextStep};
    obj.role = 'fund';

    return dispatch => {
        return axios
            .post("/v2/angels", obj)
            .then(res => {
                if(res.data){
                    dispatch(updateAngelInfo(res.data));
                    goToStep(fund.registrationStep || nextStep)
                }
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const createStartup = (startup = {}, onlyStep, nextStep) => {
    let obj = startup;
    obj.registrationStep = nextStep;
    if(onlyStep) obj = {registrationStep: nextStep};
    obj.role = 'startup';

    return dispatch => {
        return axios
            .post("/v2/projects", obj)
            .then(res => {
                dispatch(updateUserInfo(res.data));
                goToStep(startup.registrationStep || nextStep)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};


export const createFromEvent = (data, type) => {
    if(type === 'accelerator'){
        return axios
            .post("/v2/accelerator", data)
            .then(res => {
                if(res.data){
                    return res.data;
                }
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    }

    if(type === 'angel'){
        return axios
            .post("/v2/angels", data)
            .then(res => {
                if(res.data){
                    return res.data;
                }
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    }

    if(type === 'fund'){
        return axios
            .post("/v2/funds", data)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    }

    if(type === 'startup'){
        return axios
            .post("/v2/projects", data)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                throw err
            })
    }

    if(type === 'expert'){
        return axios
            .put("/v2/experts", data)
            .then(res => {
                return res.data;
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    }
};

export const updateExpert = (expert = {}, onlyStep, nextStep, goToUrl = null) => {
    // typeof (expert.append) === 'function'
    let obj = expert;
    obj.registrationStep = nextStep
    if(onlyStep) obj = {registrationStep: nextStep, isFinished: !!expert.isFinished, event_type: expert.event_type, event_data: expert.event_data};
    obj.role = 'expert'

    return dispatch => {
        return axios
            .put("/v2/experts", obj)
            .then(res => {
                dispatch(updateUserInfo(res.data));
                if(goToUrl){
                    history.push(`/registration/expert/${nextStep}`)
                } else {
                    goToStep(expert.registrationStep || nextStep)
                }
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const updateStartup = (startup = {}, onlyStep, nextStep, goToUrl = true) => {
    let obj = startup;
    const id = obj.id;

    obj.registrationStep = nextStep;

    if(onlyStep) obj = {registrationStep: nextStep, event_type: startup.event_type, event_data: startup.event_data};

    obj.role = 'startup';

    return dispatch => {
        return axios
            .put(`/v2/projects/${id}`, obj)
            .then(res => {
                dispatch(updateProjectInfo(res.data));
                if(goToUrl){
                    history.push({pathname:`/registration/startup/${nextStep}`, search: getQuery(id)})
                } else {
                    goToStep(obj.registrationStep);
                }

                return res
            })
            .catch(err => {
                //console.log(err)
                //showMessage({type: 'error', data: err})
                throw err
            })
    };
};

export const sendInvitation = (emails) => {
    return axios.post("/v2/experts/invite", { emails: emails });
};

export const createAchievementInvestor = (investorId, parameterId, data) => {
    return axios.post(`/v2/investor/${investorId}/achievements/${parameterId}`, data);
};
export const editAchievementInvestor = (investorId, parameterId, achId, data) => {
    return axios.put(`/v2/investor/${investorId}/achievements/${parameterId}/${achId}`, data);
}
export const deleteAchievementInvestor = (investorId, parameterId, achId) => {
    return axios.delete(`/v2/investor/${investorId}/achievements/${parameterId}/${achId}`);
}

export const createAchievementInvestorFund = (fundId, parameterId, data) => {
    return axios.post(`/v2/funds/${fundId}/achievements/${parameterId}`, data);
};

export const editAchievementInvestorFund = (investorId, parameterId, achId, data) => {
    return axios.put(`/v2/funds/${investorId}/achievements/${parameterId}/${achId}`, data);
}
export const deleteAchievementInvestorFund = (investorId, parameterId, achId) => {
    return axios.delete(`/v2/funds/${investorId}/achievements/${parameterId}/${achId}`);
}

export const createAchievementInvestorAngel = (fundId, parameterId, data) => {
    return axios.post(`/v2/angels/${fundId}/achievements/${parameterId}`, data);
};

export const editAchievementInvestorAngel = (investorId, parameterId, achId, data) => {
    return axios.put(`/v2/angels/${investorId}/achievements/${parameterId}/${achId}`, data);
}
export const deleteAchievementInvestorAngel = (investorId, parameterId, achId) => {
    return axios.delete(`/v2/angels/${investorId}/achievements/${parameterId}/${achId}`);
}




export const getAchievement = (role, id, fundId) => {
    switch (role){
        case 'fund':{
            return axios.get(`/v2/funds/${fundId}/achievements/${id}`);
        }
        case 'angel':{
            return axios.get(`/v2/angels/${fundId}/achievements/${id}`);
        }
        default: {
            return axios.get(`/v2/achievements/${role}/${id}`);
        }
    }
};


export const getAchievementExpert = (role, id, userId) => {
    return axios.get(`/v2/achievements/${role}/${id}/${userId}`);
};
export const getAchievementInvestor = (role, id, userId) => {
    return axios.get(`/v2/achievements/${role}/${id}/${userId}`);
};

export const createAchievement = (data, parameterId) => {
    return axios.post(`/v2/achievements/expert/${parameterId}`, data);
};

export const createExpertParameter = (data, parameterId) => {
    return axios.post(`/v2/achievements/expert/create/parameter/${parameterId}`, data);
};

export const editAchievement = (data, parameterId, achId) => {
    return axios.put(`/v2/achievements/expert/${parameterId}/${achId}`, data);
}
export const deleteAchievement = (parameterId, achId) => {
    return axios.delete(`/v2/achievements/expert/${parameterId}/${achId}`);
}

export const createAchievementStartup = (data, parameterId) => {
    return axios.post(`/v2/achievements/startup/${parameterId}`, data);
};
export const editAchievementStartup = (data, parameterId, achId) => {
    return axios.put(`/v2/achievements/startup/${parameterId}/${achId}`, data);
}
export const deleteAchievementStartup = (parameterId, achId) => {
    return axios.delete(`/v2/achievements/startup/${parameterId}/${achId}`);
}

export const updateUser = (user = {}, id = null) => dispatch => {
    return axios.put(`/users/${id ? id : user.id || 0}`, user)
        .then(res => {
                dispatch(updateUserInfo(res.data));
                return res
            }
        ).catch(err => {
            //console.log(err)
            //showMessage({type: 'error', data: err})
            return err
        })
}

export const updateUser2 = (user = {}) => {
    return axios.put(`/users/${user.id || 0}`, user)
}

export const getUserByToken = (token) => {
    return axios.get(`/users/unsubscribe/${token}`)
        .then(res => {
                return res.data
            }
        ).catch(err => {
            throw err;
        })
};

export const updateUserByToken = (token, data) => {
    return axios.put(`/users/unsubscribe/${token}`, data)
        .then(res => {
                return res.data
            }
        ).catch(err => {
            throw err;
        })
};


// export const updateUser = (user = {}) => {
//   //request
//   return dispatch => {
//     return axios.put("/users/" + user.id, user)
//       .then(res => {
//         dispatch(updateUserInfo(res.data));
//       })
//       .catch(error => {
//
//       });
//   };
// };


export const logout = () => {
  return dispatch => {
    localStorage.removeItem("RocketToken");
    setAuthToken(false);
    dispatch(userLoggedOut());
  };
};

export const sendEmailToSubscribe = email => {
    return axios.post("/v1/users/subscribe/email", {email: email});
};

export const share = type => {
    return axios.post('/v1/users/reward', {rewardType: type})
        .then(res => {
            return res
        })
        .catch(err => {
            showMessage({type: 'error', data: err})
        })
};

export const shareLinkedin = data => {
    return axios({
        method: 'post',
        url: 'https://api.linkedin.com/v1/people/~/shares?format=json',
        data: data,
        headers: {
            'content-type': 'application/json',
            'x-li-format': 'json'
        }
    });
};

export const twitterAuth = data => {
    return axios.post("/v1/data/twitter", data);
};


export const editInvestor = (investor = {}) => {
    let obj = investor;
    obj.role = 'investor'

    return dispatch => {
        return axios
            .put("/v2/investor", obj)
            .then(res => {
                dispatch(updateUserInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};

export const editExpert = (expert = {}) => {
    let obj = expert;
    obj.role = 'expert';

    return dispatch => {
        return axios
            .put("/v2/experts", obj)
            .then(res => {
                dispatch(updateUserInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
};


export const checkResetToken = (token) => {
    return axios.get(`/v1/users/check_reset/${token}`)
}
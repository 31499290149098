import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import RegAvatar from '../../controls3.0/regAvatar/regAvatar';
import ic_picture from "../../../images/svg/ic_picture.svg";
import ModalBackgroundImage from '../../modal/ModalBackgroundImage'
import config from '../../../config'
import {updateMenu} from "../../../actions/menu";


class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator || {},
            background: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        this.setState({ accelerator: {...this.state.accelerator, [name]: value} })
    }


    update = (nextStep, onlyStep) => {

        let data = new FormData(),
            accelerator = this.state.accelerator;

        if(onlyStep) {
            this.props.updateAccelerator({}, accelerator.id, onlyStep, nextStep)
                .catch(err => {
                    this.setState({loading: false})
                })
        }

        const THIS = this;
        if(accelerator.backgroundFileId) data.append('backgroundFileId', accelerator.backgroundFileId);
        if (this.state.background.name) data.append('bgImage', this.state.background)
        data.append("registrationStep", nextStep);
        data.append("role", 'accelerator');
        data.append("event_type", 'accelerator_logo_2');

        let logoState = this.logoEditor.state;
        if (logoState.image) {
            this.logoEditor.editor.getImageScaledToCanvas().toBlob(function (blob) {
                data.append("logo", blob, "imageFilename.png");
                THIS.sendToServer(data, accelerator.id, onlyStep, nextStep);
            }.bind(this), 'image/png', 1);
        } else {
            THIS.sendToServer(data, accelerator.id, onlyStep, nextStep)
        }
    }

    sendToServer = (data, id, onlyStep, nextStep) => {
        if(!onlyStep) this.setState({loading: true});
        this.props.updateAccelerator(data, id, onlyStep, nextStep)
            .then(r => {
                this.props.updateMenu({type: 'update'})
            })
            .catch(err => {
                this.setState({loading: false})
            })
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    setBG = (item) => {
        if (item.name) {
            this.setState({background: item})
        } else {
            this.setState({accelerator: {...this.state.accelerator, backgroundFileId: item}});
            this.setState({background: {}})
        }
        this.handleCloseModal()
    }

    validateNext = () => {
        const {accelerator, background, hasLogo} = this.state;
        const ifLogo = (this.logoEditor && this.logoEditor.state.image) || accelerator.logoFileId || hasLogo;
        const ifBg = accelerator.backgroundFileId ||  background.preview;
        return !!ifLogo && !!ifBg;
    }

    render () {
        let {accelerator, background} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Accelerator profile
                                </p>

                                <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Images help us tell stories.
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                    Make your profile notable by uploading some basic brand elements.
                                </p>

                                <div className="container-step-1-reg profile-reg-10-step-1"
                                     style={{position: 'relative', margin: '0 0 0 auto'}}>
                                    <div className="block-cart-load-img-reg-expert">
                                        <div className="block-left-cart-load-img-reg-expert">
                                            <p className="title-top-page-reg-expert text-h5 left-text shell-margin-bottom-10">
                                                Logo
                                            </p>
                                            <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-gray shell-margin-bottom-10">
                                                Logo is the image of your accelerator.<br/>
                                                Upload it right here!
                                            </p>
                                        </div>
                                        <div className="block-right-cart-load-img-reg-expert test-fix">
                                            <RegAvatar
                                                fileId={accelerator.logoFileId}
                                                title={accelerator.logoFileId ? 'Add new' : 'Add logo'}
                                                description='Not less than 180*180 pixels'
                                                onLoadSuccess={() => this.setState({hasLogo: true})}
                                                ref={(logoEditor) => {
                                                    this.logoEditor = logoEditor
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="block-cart-load-img-reg-expert">
                                        <div className="block-left-cart-load-img-reg-expert">
                                            <p className="title-top-page-reg-expert text-h5 left-text shell-margin-bottom-10">
                                                Background photo
                                            </p>
                                            <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-gray shell-margin-bottom-10">
                                                When it comes to stand out, the details matter.
                                                <br/>
                                                <br/>
                                                Background picture will help you create a more <br/>memorable profile.
                                            </p>
                                        </div>
                                        <div className="block-right-cart-load-img-reg-expert">
                                            <div className="shell-load-img-cart">
                                                <div className="block-load-img big">
                                                    {!background.preview && !accelerator.backgroundFileId &&
                                                    <img className="logo-load-img" src={ic_picture} alt=""/>}

                                                    {background.preview &&
                                                    <div className={'bgc-img-3'}
                                                         style={{
                                                             background: `url(${background.preview}) no-repeat`,
                                                             backgroundSize: 'cover'
                                                         }}>
                                                    </div>
                                                    }
                                                    {/*{background.preview && <img className="load-img" src={background.preview} alt=""/>}*/}

                                                    {!background.preview && accelerator.backgroundFileId && <img className='load-img'
                                                                                                              src={`${config.proxy}/file/${accelerator.backgroundFileId}/view`}
                                                                                                              alt={accelerator.backgroundFileId}/>}

                                                    <div className="block-button-load-img">
                                                        <p className="title-button-load-img"
                                                           onClick={() => this.handleShowModal()}>Add photo</p>
                                                    </div>
                                                </div>
                                                <p className="title-load-img">
                                                    Not less than <br/>
                                                    851 pixels width and 315 pixels height
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(1, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(3)}
                                paddingButton={'40'}
                                disabled={!this.validateNext()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <ModalBackgroundImage
                    showModal={this.state.showModal}
                    handleCloseModal={this.handleCloseModal}
                    setBG={(item) => this.setBG(item)}
                    bgId={accelerator.backgroundFileId}
                    type={3}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step2.propTypes = {
    updateAccelerator: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {updateAccelerator, updateMenu})(Step2)
import React from 'react';
import "./style.css"


export default class CookieNotice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <div className={`document`}>
                <h1 className="heading-1">
                    Cookie Notice and/or HTML5 Local Storage
                </h1>
                <h2 className="heading-1">
                    Definitions
                </h2>
                <p className="description">
                    Cookies are small text files that a website saves on your computer or mobile device when you visit
                    the site. Cookies enables the website to remember your actions and preferences over a period of
                    time, so you don’t have to keep re-entering them whenever you come back to the site or browse from
                    one page to another.
                </p>
                <p className="description">
                    HTML5 Local Storage is a component of the Web storage application programming interface. It is a
                    method by which Web pages locally store named key/value pairs inside a client's Web browser.
                </p>
                <h2 className="heading-1">
                    How do we use this?
                </h2>
                <p className="description">
                    We use cookies to facilitate your interaction with our website, enhance your user experience, and
                    analyze and improve our services.
                </p>
                <p className="description">
                    Our cookies can be categorized as:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Strictly necessary cookies. Some cookies will be strictly necessary for the functioning of our
                    website. This includes cookies that record whether or not you’ve consented to the use of cookies.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Strictly necessary cookies shall not be subject to your consent, and unless you restrict the use of
                    cookies by adjusting your web browser preferences, the use of our website will include the use of
                    strictly necessary cookies. You may restrict the use of strictly necessary cookies by adjusting your
                    web browser preferences, but this may affect the functionality of our website.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Performance cookies. They allow us to recognize and count the number of visitors and to see how
                    visitors move around our website when they are using it. This helps us to improve the way our
                    website works, for example, by ensuring that users are finding what they are looking for easily.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Targeting cookies. These cookies record your visit to our website, the pages you have visited and
                    the links you have followed. We will use this information to make our website and the advertising
                    displayed on it more relevant to your interests. We may also share this information with third
                    parties for this purpose.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Web beacons. Web beacons are objects embedded in a webpage or email. This object is typically
                    invisible to you, but it permits us to confirm whether you have viewed the web page and/or email (as
                    the case may be).
                </p>
                <p className="description">
                    We may use any or all of the below types of cookies:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Session cookies. Session cookies are stored in your device’s memory only for the length of time of
                    your browsing session. They are not accessible after your browser session may have been inactive for
                    a period of time and are deleted from your device when your browser is closed down.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Persistent cookies. Persistent cookies are stored in your devices’ memory and are not deleted when
                    your browser is closed. Persistent cookies can helpfully remember you and your preferences each time
                    you access our services.
                </p>
                <h2 className="heading-1">
                    How to control?
                </h2>
                <p className="description">
                    You may restrict the placement of Rocket DAO's cookies by adjusting your web browser preferences or
                    a "Do Not Track" setting in your web browser. If you do so, you may still use our website, but this
                    may interfere with some of its functionality.
                </p>
                <p className="description">
                    You may also clear cookies from your computer via your web browser settings. You may also wish to
                    use a Google Analytics opt-out web browser add-on.
                </p>
            </div>
        );
    }
}

import React from 'react';

import ButtonSocialReg from '../controls3.0/buttonSocialReg/ButtonSocialReg'
import ButtonBlack from '../controls3.0/button/ButtonBlack'
import Input from "../controls3.0/input/Input";
import GoogleLogin from 'react-google-login';
import {signUpGoogle} from "../../actions/Login";
import {NotificationManager} from "react-notifications";
import {showMessage} from "../../utils/showMessage";
import history from '../../history'
import {regexEmail, regexPass} from "../../utils/RegExp";
import axios from "axios";

export default class auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reaEmail:false
            , data: {
                firstName: '#',
                lastName: '#'
            }
            , errors: {}
            , loaded: false
        }
    }

    regMail = () =>{
        this.setState({reaEmail:!this.state.reaEmail})
    };

    createUser = () => {
        if(!Object.keys(this.state.errors).length){
            this.setState({loaded: true})
            axios.post('/v1/users/reg/event', {...this.state.data, passwordConfirm: this.state.data.password}).then(r => {
                this.setState({loaded: false})
                localStorage.setItem('RocketToken', r.data.token);
                // history.push('/slush/profile')
                history.push('/slush');
            }).catch((e) => {
                this.setState({loaded: false});
                this.setState({errors:{ ...this.state.errors, email: e.response.data.error}});
            })
        }
    };

    sendFunction = (signUpFunc, response) => {
        if(response.error) {
            NotificationManager.error(response.error)
            return
        }
        this.setState({loaded: false})
        signUpFunc(response.accessToken, this.state.typeName, this.state.ref_b)
            .then((res)=>{
                localStorage.setItem('RocketToken', res.data.token);
                history.push('/slush')
            })
            .catch(err => {
                this.setState({loaded: true})
                showMessage({type: 'error', data: err})
            })
    }

    responseGoogle = (response) => {
        this.sendFunction (signUpGoogle, response)
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        if(name === 'email'){
            value = value.trim();
        }

        this.setState({data: {...this.state.data, [name]: value }}, () => {
            let data = this.state.data,
                obj = {}
            for (let name in data) {
                if(data[name]) obj[name] = data[name]
            }
            obj[name] = value
            let errors = this.validate(obj)
            this.setState({ errors })
        })
    }

    validateB(){
        if(!this.state.data.email || !this.state.data.password){
            return false;
        }
        if(!this.state.data.email.length){
            return false;
        }

        return true;
    }

    validate(data) {
        const errors = this.state.errors;
        for (let name in data) {
            let value = data[name],
                required = false,
                limit = 300,
                minLength = 0;
            switch (name) {
                case 'firstName':
                case 'lastName':
                    required = true;
                    break;
                case 'email':
                    // required = true;
                    if(!regexEmail.test(value)) {
                        errors[name] = 'Empty or invalid e-mail address'
                    } else {
                        delete errors[name]
                    }
                    break;
                case 'password':
                    // required = true;
                    if(value.length < 6){
                        errors[name] = 'Minimum 6 characters';
                    } else {
                        delete errors[name]
                    }
                    break;
            }

            if (minLength && value.length < minLength) {
                errors[name] = 'min length ' + minLength + ' symbols';
            }

            if (required && !value) {
                errors[name] = 'Field is required'
            } else if (required && value) {
                delete errors[name]
            }

        }
        return errors;
    }

    render() {
        const {data, errors} = this.state;
        return (
            <div className="block-auth-mini-reg">
                <p className="title-auth-mini-reg">
                    Welcome!
                    In a few seconds you will join Rocket DAO!
                </p>
                <div className="block-list-button-auth-mini-reg">
                    {!this.state.reaEmail &&
                    <div className="block-button-auth-mini-reg">
                        <GoogleLogin
                            clientId="839740061712-mi7grbk5uvgst4qo55ml9kvk3ic8a9e2.apps.googleusercontent.com"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            render={renderProps => (
                                <ButtonSocialReg
                                    TitleButton={'Register with Google'}
                                    EventOnClick={() => renderProps.onClick()}
                                    disabled={false}
                                    color={'#DC4E41'}
                                    iconSVG={{
                                        d: 'M0.00463809195,7.44246163 C-0.0603414999,10.5438424 2.11043401,13.5035211 5.09655646,14.4687664 C7.90929115,15.3986666 11.3675769,14.7030087 13.2392503,12.332631 C14.6057809,10.6717278 14.9163116,8.45751156 14.7683932,6.38885329 C12.3595769,6.36764617 9.95076054,6.37471521 7.54553605,6.37824973 C7.54161768,7.22267874 7.54553605,8.06389454 7.54912789,8.90832355 C8.9914136,8.94752459 10.4340258,8.92953067 11.8763116,8.95812815 C11.5122299,10.1858919 10.6828422,11.3285059 9.42406666,11.7719275 C6.99369932,12.8258572 3.89263809,11.4490009 3.1279034,8.94013423 C2.20480136,6.53762446 3.71598503,3.63835394 6.21100544,2.93948291 C7.75810748,2.40384244 9.3519034,3.00696011 10.6756585,3.79837131 C11.3463524,3.18079424 11.9739442,2.52080292 12.590434,1.85374257 C11.086434,0.61537522 9.15369932,-0.143903981 7.16676054,0.022861108 C3.26831156,0.0684885504 -0.143280275,3.61007778 0.00463809195,7.44246163 Z',
                                        viewBox: '0 0 17 17'
                                    }}
                                    styleSVG={{
                                        width: '28px',
                                        height: '20px'
                                    }}
                                />
                            )}
                        />

                        <ButtonSocialReg
                            TitleButton={'Enter E-mail'}
                            EventOnClick={() => this.regMail()}
                            disabled={false}
                            color={'#979797'}
                            iconSVG={{
                                d: 'M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z',
                                viewBox: '0 0 512 512'
                            }}
                            styleSVG={{
                                width: '28px',
                                height: '20px'
                            }}
                        />
                    </div>
                    }
                    {this.state.reaEmail &&
                        <div className="block-auth-email-mini-reg">
                            <Input
                                type={'email'}
                                name={'email'}
                                value={data.email}
                                onChange={this.onChange}
                                error={errors.email}
                                placeholder={'Email'}
                                iconClass={'fal fa-envelope'}
                            />
                            <Input
                                type={'password'}
                                name={'password'}
                                value={data.password}
                                onChange={this.onChange}
                                error={errors.password}
                                placeholder={'Password'}
                                iconClass={'fal fa-lock-alt'}
                            />
                            {/*<Input*/}
                                {/*type={'password'}*/}
                                {/*name={'passwordConfirm'}*/}
                                {/*value={data.passwordConfirm}*/}
                                {/*onChange={this.onChange}*/}
                                {/*error={errors.passwordConfirm}*/}
                                {/*placeholder={'Repeat Password'}*/}
                                {/*iconClass={'fal fa-lock-alt'}*/}
                            {/*/>*/}
                            <ButtonBlack
                                TitleButton={'Register'}
                                EventOnClick={() => this.createUser()}
                                paddingButton={null}
                                disabled={Object.keys(errors).length || !this.validateB() }
                                loader={this.state.loaded}
                            />

                        </div>
                    }
                </div>
            </div>
        );
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";
import {getCountrySearch} from "../../../actions/Profile";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import Select from "../../controls3.0/select/Select";
import human from "../../../images/ilustaciya/language-rocketDAO-accelerator.svg";
// import {COUNTRIES, LANGUAGES} from "../../../utils/countriesLanguages";
import {getArrYears} from '../../../utils/staticData'
import {regexUrl} from "../../../utils/RegExp";


const FOUNDEDYEAR = getArrYears().reverse();

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listaccelerators: [],
            accelerator: props.accelerator,
            me: {},
            errors: {},
            listWE: [],
            listCities: [],
            experience: {
                position: '',
                description: '',
                company: '',
                city: [{Name: props.accelerator.location || ''}],
                periodFrom: '',
                periodTo: ''
            },
            website: props.accelerator.website || '',
            founding_year: [{Name: props.accelerator.foundingYear || ''}]
        }
    }

    componentDidMount () {
        this.searchCity('')
    }

    onChange = (e) => {
        this.setState({'name': e.target.value.cleanWhitespace()})
    }
    onChangeFY = (e) => {
        this.setState({'founding_year': e})
    }

    onChangeWebSite = (e) => {
        let {value} = e.target;
        value = value.trim();
        if(!regexUrl.test(value)){
            this.setState({errors: {...this.state.errors, website: 'Invalid link'}})
        } else {
            delete this.state.errors.website;
        }

        this.setState({'website': value})
    }
    searchCity = str => {
        //if(str.length < 3) return
        getCountrySearch(str)
            .then(res => {
                this.setState({listCities: res.data})
            })
    }

    update = (nextStep, onlyStep) => {
        const accelerator = this.state.accelerator;
        const data = {
            location:this.state.experience.city[0].Name,
            website: this.state.website,
            foundingYear: this.state.founding_year[0].Name
        }

        this.setState({loading: true})
        this.props.updateAccelerator(data, accelerator.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    onChangeCity = value => {
        this.setState({experience: {...this.state.experience, city: value}})
    }

    validate(){
        const {website, founding_year, experience} = this.state;
        return website.length && founding_year[0].Name.length && experience.city[0].Name.length;
    }

    render() {
        let {errors, experience} = this.state;

        return (
            <React.Fragment>
                <div className="container-full-reg" style={{position: 'relative', padding:'0 0 250px 0'}}>
                    <img src={human} alt="" className="img-right-step"
                         style={{top: '100px', right: '0', height: '460px'}}/>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20 color-rgba-88">
                        Let the community get to know you better{/*this.state.accelerator.title*/}
                    </p>
                    <p className="description-page-reg-expert text-button left-text shell-margin-bottom-40 accelerator-descr-text"
                       style={{maxWidth: '560px'}}>
                        Filling in some basic information will ease communication with other accelerators!<br/>
                        <span style={{fontWeight:'400'}}>Trust, honesty, and transparency are the basis of the community.</span>
                    </p>

                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20 color-rgba-88">
                        Website
                    </p>
                    <div className="block-input-reg" style={{maxWidth: "300px"}}>
                        <Input
                            type={'text'}
                            name={'website'}
                            value={this.state.website}
                            onChange={this.onChangeWebSite}
                            error={errors.website}
                            placeholder={'Link to the official Accelerator\'s website'}
                            required={true}
                        />
                    </div>


                    <p className="title-top-page-reg-expert text-h3 left-text margin-0 shell-margin-bottom-20 color-rgba-88">
                        Location
                    </p>
                    <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20" style={{opacity:'0.75'}}>
                        Please select the country where your <br/>
                        accelerator is situated.
                    </p>

                    <div className="shell-select-reg shell-margin-bottom-10 select-z-to-top" style={{maxWidth:'300px',width:'auto'}}>
                        <Select
                            placeholder={'Select'}
                            data={this.state.listCities}
                            value={experience.city}
                            onChange={(e) => this.onChangeCity(e)}
                            searchList={this.searchCity}
                            EventOnClick={() => this.searchCity('')}
                            error={errors.city}
                            required={true}
                        />
                    </div>

                    <p className="title-top-page-reg-expert text-h3 left-text margin-0 shell-margin-bottom-20 color-rgba-88">
                        Founding year
                    </p>

                    <div className="shell-select-reg">
                        <Select
                            placeholder={'Select'}
                            data={FOUNDEDYEAR}
                            value={this.state.founding_year}
                            onChange={(e) => this.onChangeFY(e)}
                            searchList={null}
                            error={''}
                            required={true}
                        />
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(4)}
                                paddingButton={'54'}
                                disabled={!this.validate() || Object.keys(this.state.errors).length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator,
});

Step3.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step3)
import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad'

class Step4 extends React.Component {
    constructor(props) {
        super();
        this.state = {

        }
    }

    update = (nextStep, onlyStep) => {
        let loading = nextStep == 1? 'loadingBack' : 'loading'
        this.setState({[loading]: true})
        this.props.updateExpert({} , onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    render () {
        return (
            <React.Fragment>
                <h2>Rocket DAO Expert</h2>
                <div>
                    The aim of Rocket DAO Expert is to create a mechanism for effective interaction of a multi-thousand expert community where shared expertise will not only help develop an optimal system for startups evaluation, but also will enable experts to grow their competences, generate passive income and enrich their potential.

                    Being a part of the community means:
                    - a new source of income;
                    - knowledge sharing and expertise growing;
                    - expert collaboration tools;
                    - mentorship of perspective startups.
                </div>
                <h3>So you will</h3>
                <div>1. Get interesting tasks</div>
                <div>2. Analyze and evaluate startups</div>
                <div>3. Receive tokens for these tasks</div>
                <ButtonLoad title={'Back to registration'} loading={this.state.loading} onClickFunc={() => this.update(2, true)} />

                <div style={{textAlign: 'right'}}>
                    <ButtonLoad title={'Back'} loading={this.state.loadingBack} onClickFunc={() => this.update(1, true)} />
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step4.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step4)
import React from 'react';
import $ from "jquery";
import CurrentStatistics from './CurrentStatistics';
import {connect} from "react-redux";
import './style.css'
import './_style.scss'
import PayBanner from "../../v4/payBanner";
import axios from "axios";
import {getListIndustries} from "../../../actions/Project";
import {CartPopUp} from "../../v3/startupPopupPage";
import UseDetailsPopup from "./useDetailsPopup";

import logo_69pixels from './img/logo_69pixels.png';
import logo_mind from './img/logo_slon.svg';
import logo_pixelplex from './img/logo_pixelplex.png';
import logo_sbh from './img/logo_sbh.png';
import {StartupListPeItem} from "../../v4/startupListPeItem";

import {Tap2Pay, openProduct} from "../../../utils/Tap2Pay";
import {isExist} from "../../../utils/helper";

import {getLastFund, tokenLogin} from "../../../actions/Login";

import PropTypes from "prop-types";
import LoginPopup from "../../v4/loginPopup";
import ButtonWithTerms from "../../v4/Button/ButtonWithTerms";

let stopStartupAnimate = false;

let listSwiper = [{
    Name: 'Vadim Makovsky',
    Position: 'CEO',
    descr: 'Having to answer a great number of questions for each evaluation parameter, the requirement to provide the most possible detailed information, eventually let generate quality and experts-approved business plan.',
    logo: 'https://api.rocketdao.io/file/3636/view',
    linkPDF: 'https://rocketdao.io//Multy%20express%20evaluation%20(by%20Rocket%20DAO).pdf',
    avatar: '/static/media/1@2x-min.250ab996.png',
    startupName: 'Multy',
    startupLink: '/startup/445'
},
    {
        Name: 'Eugene Hurynovich',
        Position: 'CEO',
        descr: 'The work was done efficiently and quickly. This is a great way to see the strengths and weaknesses of a startup. Evaluation made it possible to more clearly put the positioning. Thanks.',
        logo: '',
        linkPDF: 'https://rocketdao.io/Pre-Evaluation/Parsers.pdf',
        avatar: 'https://api.rocketdao.io/file/4126/view',
        startupName: 'Parsers',
        startupLink: '/startup/441'
    }];

class LandingStartup extends React.Component {
    state = {
        listIndustries: [],
        listProjectsPreliminaryEvaluation: [],
        showMoreStartupsPreliminaryEvaluation: true,
        showCartPopup: false,
        cartId: null,
        CartPopUpType: null,
        CartPopUpType2: null,
        activeOrder: false,
        showLoginPopup: 'hide',
        detailsCompany: 'none'
    };


    componentDidMount() {
        window.scrollTo(0,0);

        getListIndustries()
            .then((res) => {
                this.setState({listIndustries: res.data})
            })
            .catch(error => {
                //alert('listIndustries e', error);
                this.setState({loading: false})
            })

        if (this.state.savedEmail) {
            this.checkSubscribe(this.state.savedEmail);
        }

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        let take = 3;
        axios
            .get('/v2/projects?take=3&preliminary_evaluation=true&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listProjectsPreliminaryEvaluation: r.data.data});
                // if(parseInt(r.data.totalCount) <= take){
                //     this.setState({showMoreProjectsScore: false});
                // }
                if (r.data.data.length <= 3) {
                    this.setState({showMoreStartupsPreliminaryEvaluation: false});
                }

            });

        this.startupAnimate();
        stopStartupAnimate = false;
    }

    componentWillUnmount() {
        stopStartupAnimate = true;
    }


    onClickOrder() {
        this.setState({activeOrder: !this.state.activeOrder});
        if (!this.state.activeOrder && document.documentElement.scrollWidth < 560) {
            this.customScrollTo(605, 1000);
        }
    }


    startupAnimate() {

        setTimeout(() => {
            let dotNum = $('.swiper-pagination-bullet-active').attr('aria-label');
            if (!stopStartupAnimate) {
                // switch(dotNum){
                //     case('Go to slide 1'):
                //         $('[aria-label="Go to slide 2"]').click();
                //         break;
                //     case('Go to slide 2'):
                //         $('[aria-label="Go to slide 1"]').click();
                //         break;
                //
                //     case('Go to slide 3'):
                //         $('[aria-label="Go to slide 1"]').click();
                //         break;
                // }
            }

            if (!stopStartupAnimate) this.startupAnimate();
        }, 8000);
    }

    customScrollTo(to, duration) {
        window.scrollTo({top: to, behavior: 'smooth'})
    }


    togglePopupCart(id, type, e, type2) {
        if (e && e.preventDefault) e.preventDefault()
        this.setState({
            cartId: id,
            CartPopUpType: type,
            CartPopUpType2: type2,
            showCartPopup: !this.state.showCartPopup
        });
    }


    openProductHandle(){
        if(isExist(() => this.props.user.id)){
            openProduct(isExist(() => this.props.user.id));
        } else {
            localStorage.removeItem('rFrom');
            localStorage.removeItem('referer_startup');
            localStorage.removeItem("commandProps");
            localStorage.setItem("referer", window.location.pathname);
            this.setState({showLoginPopup: 'popup'});
        }
    }

    handleDetails (type) {
        this.setState({detailsCompany:type});
        $('body').css('overflow', 'hidden');
    }


    closeDetails () {
        this.setState({detailsCompany: 'none'});
        $('body').css('overflow', 'auto');
    }

    render() {
        const {listIndustries} = this.state;

        const listEvaluatedProjects = this.state.listProjectsPreliminaryEvaluation.map((item, key) => <StartupListPeItem key={key}
                                                                                                        href={`/startup/${item.id}`}
                                                                                                        item={item}
                                                                                                        listIndustries={listIndustries}
                                                                                                        onClick={(e) => this.goTo(`/startup/${item.id}`, e)}/>);

        // const listEvaluatedProjects = this.state.listProjectsPreliminaryEvaluation.map((item, key) => {
        //
        //     const _bg = item.backgroundImageId ? `${packageJSON.proxy}/file/${item.backgroundImageId}/view` : avatar;
        //     const _avatar = item.logoFileId ?
        //         <div className="img-cart-new-startup-marketplace"
        //              style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
        //         :
        //         createAvatar('img-cart-new-startup-marketplace', item.title);
        //
        //     return (
        //         <a key={key} href={`/startup/${item.id}`}>
        //             <div className="block-cart-new-startup-marketplace evaluated-card"
        //                  onClick={(e) => this.goTo(`/startup/${item.id}`, e)}>
        //                 <div className='block-startup-evaluation-view'>
        //                     <div className="row-0 review-score text-center">
        //                         <div
        //                             className="new-col expert-main-score font-weight-500">{item.preliminary_evaluation}</div>
        //                         <div className="new-col expert-main-score-ten" style={{marginTop: '24px'}}>/10</div>
        //                         <div className='new-col review-score-text-block'>
        //                             <div className='row-0 expert-review-score-text text-left color-rgba-88'>Expert
        //                                 overall score
        //                             </div>
        //                             <div className='row-0 expert-review-score-views text-left'
        //                                  style={{marginTop: '4px'}}>Defined round: <span
        //                                 className='color-rgba-88'>Seed</span></div>
        //                         </div>
        //                     </div>
        //
        //                 </div>
        //                 <div className="block-top-cart-new-startup-marketplace">
        //                     <div className="block-bg-top-cart-new-startup-marketplace">
        //                         <div className="bg-top-cart-new-startup-marketplace"
        //                              style={{backgroundImage: `url(${_bg})`}}/>
        //
        //
        //                         {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
        //                             <i className="fas fa-play"/>
        //                         </div>}
        //                     </div>
        //                     <div className="block-ing-cart-new-startup-marketplace">
        //                         {_avatar}
        //                     </div>
        //                 </div>
        //                 <div className="block-body-cart-new-startup-marketplace">
        //                     <p className="title-body-cart-new-startup-marketplace" title={item.title}>
        //                         {item.title}
        //                     </p>
        //                     <p className="description-body-cart-new-startup-marketplace">
        //                         {item.shortDescription.descriptionFormat()}
        //                     </p>
        //                     <div className="block-list-industries-body-cart-new-startup-marketplace">
        //                         <p className="title-industries-body-cart-new-startup-marketplace">
        //                             Industries
        //                         </p>
        //
        //                         {listIndustries && item.industry.slice(0, 4).map(id => {
        //                             return <div key={id}
        //                                         className="block-item-industries-body-cart-new-startup-marketplace">
        //                                 {listIndustries.find((a) => a.id === id) &&
        //                                 <p className="title-item-industries-body-cart-new-startup-marketplace">
        //                                     {listIndustries.find((a) => a.id === id).title}
        //                                 </p>
        //                                 }
        //                             </div>
        //                         })}
        //                         {item.industry.length > 4 &&
        //                         <div className="block-item-industries-body-cart-new-startup-marketplace">
        //                             <p className="title-item-industries-body-cart-new-startup-marketplace">
        //                                 {item.industry.length} more…
        //                             </p>
        //                         </div>
        //                         }
        //                     </div>
        //                     <div className="block-list-button-body-cart-new-startup-marketplace">
        //                         <div className="block-item-button-body-cart-new-startup-marketplace m">
        //                             <i className="fas fa-heart"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 {item.likes}
        //                             </p>
        //                         </div>
        //                         <div className="block-item-button-body-cart-new-startup-marketplace m">
        //                             <i className="far fa-clock"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
        //                             </p>
        //                         </div>
        //                         <div className="block-item-button-body-cart-new-startup-marketplace">
        //                             <i className="far fa-share-alt"/>
        //                             <p className="title-item-button-body-cart-new-startup-marketplace">
        //                                 {item.shared}
        //                             </p>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className="block-bottom-cart-startup-marketplace">
        //                     <p className="description-bottom-cart-startup-marketplace">
        //                         {item.views || 0} views
        //                     </p>
        //                 </div>
        //             </div>
        //         </a>
        //     )
        // });


        return (
            <div style={{background: '#FDFDFD'}}>
                <Tap2Pay/> {/*Tap2Pay Initial*/}

                {/*HeaderBG*/}
                <div className='row-m0 block-header-landing-startup'>
                    <div className='block-startup-bg'>
                        <div className='header-landing-main-text text-align-center font-weight-500'>Want to attract
                            investors?
                        </div>
                        <div className='header-landing-main-text text-align-center font-weight-500 color-rgba-08'>
                            Show them your startup’s expert evaluation!
                        </div>


                        <div className="container">
                            <div className="row-b justify-content-md-center">
                                <div className="col-lg-10">
                                    <div className="row-m0 block-order-landing-top">
                                        <div className="col-lg-12 p-0">
                                            <div className="row-m0 justify-content-between block-top-order-landing-top">

                                                <div className="col-lg-7 p-0 block-left-top-order-landing-top">
                                                    <p className="title-top-order-landing-top">
                                                        Order Rocket DAO preliminary evaluation of your startup and get
                                                        free
                                                        services from our partners as a bonus.
                                                    </p>
                                                </div>
                                                <div className="col-lg-5 p-0 block-right-top-order-landing-top">

                                                    <p className="price-title-top-order-landing-top">
                                                        <span className="price">
                                                           $299
                                                        </span>
                                                        <span className="info">
                                                            <br/>
                                                            Special offer!
                                                        </span>
                                                    </p>

                                                    <ButtonWithTerms color={'green'} EventOnClick={() => this.openProductHandle()}>
                                                        Order evaluation now
                                                    </ButtonWithTerms>

                                                </div>

                                            </div>
                                            <div
                                                className={`row-m0 block-anim-order-landing-top ${this.state.activeOrder && 'active'}`}>
                                                <div className="col-lg-12 p-0">
                                                    <div
                                                        className={`row-m0 block-body-order-landing-top ${this.state.activeOrder && 'active'}`}>
                                                        <div className="col-lg-12 p-0">

                                                            <div
                                                                className="row-m0 block-title-info-body-order-landing-top">
                                                                <div className="col-lg-12 p-0">
                                                                    <p className="title-info-body-order-landing-top">
                                                                        Evaluated projects can get free services worth
                                                                        <span className="text-green"> $6000</span> for:
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="row-m0 block-list-item-info-body-order-landing-top">

                                                                <div
                                                                    className="col-12 col-sm-6 col-lg-4 block-item-info-body-order-landing-top">
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Legal services
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        $2 000 <span>credit</span>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-12 col-sm-6 col-lg-4 block-item-info-body-order-landing-top"
                                                                    style={{padding: '0 0 0 11px'}}>
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Full-service design
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        $1 000 <span>credit</span>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-12 col-sm-6 col-lg-4 block-item-info-body-order-landing-top"
                                                                    style={{padding: '0 0 0 20px'}}>
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Software development
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        $1 000 <span>credit</span>
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    className="col-12 col-sm-6 col-lg-4 block-item-info-body-order-landing-top">
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Video production
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        up to $2000 <span>credit</span>
                                                                    </p>
                                                                </div>


                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`row-m0 block-bottom-order-landing-top ${this.state.activeOrder && 'active'}`}>
                                                        <div className="col-lg-12 p-0">

                                                            <div
                                                                className="row-m0 block-title-info-bottom-order-landing-top">
                                                                <div className="col-lg-12 p-0">
                                                                    <p className="title-info-body-order-landing-top">
                                                                        Evaluated projects can get free services worth
                                                                        <span className="text-green"> $6000</span> for:
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="row-m0 block-list-item-info-bottom-order-landing-top">

                                                                <div
                                                                    className="col-12 col-sm-6 col-md-4 block-item-info-bottom-order-landing-top">
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Legal services
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <img src={logo_sbh} alt=""
                                                                         className="img-item-info"/>
                                                                    <p className="price-item-info">
                                                                        $2000 <span>credit</span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        Preparation of: <br/>
                                                                        - Co-founders Agreement <br/>
                                                                        - Agreements on the creation of objects of
                                                                        intellectual
                                                                        property <br/>
                                                                        - Convertable loan template
                                                                    </p>
                                                                    <div className="button-details-item-info"
                                                                         onClick={() => { this.handleDetails('sbh'); }}>
                                                                        <p className="title-details-item-info">
                                                                            Details
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="col-12 col-sm-6 col-md-4 block-item-info-bottom-order-landing-top"
                                                                    style={{padding: '0 0 0 11px'}}>
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Full-service design
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <img src={logo_69pixels} alt=""
                                                                         className="img-item-info"/>
                                                                    <p className="price-item-info">
                                                                        $1000 <span>credit</span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        40 hours of designers’ work totally free for
                                                                        your
                                                                        project
                                                                        (whatever your needs are)
                                                                    </p>
                                                                    <div className="button-details-item-info"
                                                                         onClick={() =>{ this.handleDetails('69pixels'); }}>
                                                                        <p className="title-details-item-info">
                                                                            Details
                                                                        </p>
                                                                    </div>
                                                                </div>



                                                                <div
                                                                    className="col-12 col-sm-6 col-md-4 block-item-info-bottom-order-landing-top"
                                                                    style={{padding: '0 0 0 20px'}}>
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Software development
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <img src={logo_pixelplex} alt=""
                                                                         className="img-item-info"/>
                                                                    <p className="price-item-info">
                                                                        $1000 <span>credit</span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        20 hours for: <br/>
                                                                        - Technical audit <br/>
                                                                        - Architecture review <br/>
                                                                        - Front-end <br/>
                                                                        - Back-end
                                                                    </p>
                                                                    <div className="button-details-item-info"
                                                                         onClick={() => { this.handleDetails('pixelplex'); }}>
                                                                        <p className="title-details-item-info">
                                                                            Details
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="col-12 col-sm-6 col-md-4 block-item-info-bottom-order-landing-top"
                                                                    >
                                                                    <p className="title-check-item-info">
                                                                <span className="title-info">
                                                                    Video production
                                                                </span>
                                                                        <span className="check-info">
                                                                    <i className="far fa-check"/>
                                                                </span>
                                                                    </p>
                                                                    <img src={logo_mind} alt=""
                                                                         className="img-item-info"/>
                                                                    <p className="price-item-info">
                                                                        up to $2000 <span>credit</span>
                                                                    </p>
                                                                    <p className="description-item-info">
                                                                        Video marketing, inc.: <br/>
                                                                        - Validation of hypotheses <br/>
                                                                        - Video pitches <br/>
                                                                        - Product/promo videos <br/>
                                                                        - Video creatives for advertising
                                                                    </p>
                                                                    <div className="button-details-item-info"
                                                                         onClick={() => { this.handleDetails('slon'); }}>
                                                                        <p className="title-details-item-info">
                                                                            Details
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div
                                                                className="row-m0 block-description-info-bottom-order-landing-top">
                                                                <p className="description-info">
                                                                    One bonus certificate validity period is 1
                                                                    year. <br/>
                                                                    Bonuses are available to the evaluated startups with
                                                                    minimum
                                                                    5-points rate.
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row-m0 block-more-info-order-landing-top">
                                                <div className="col-lg-12 p-0">
                                                    <div
                                                        className={`button-more-info ${this.state.activeOrder && 'active'}`}
                                                        onClick={() => this.onClickOrder()}>
                                                        <p className="title-button-more-info">
                                                            {!this.state.activeOrder ? 'More info' : 'Hide info'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*<div*/}
                        {/*    className='header-landing-descr-text text-align-center font-weight-300 color-rgba-08 margin-top-40'>Order*/}
                        {/*    Rocket DAO preliminary evaluation,<br/>meet new opportunities*/}
                        {/*</div>*/}
                        {/*<div className='row-m0 header-button-wrapper margin-top-55'>*/}
                        {/*    <ButtonBlue*/}
                        {/*        TitleButton={'Contact manager'}*/}
                        {/*        EventOnClick={() => {*/}
                        {/*        }}*/}
                        {/*        paddingButton={'1'}*/}
                        {/*        disabled={false}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>


                {/*What our clients say*/}
                {/*<div className='row-m0 no-gutters padding-top-70' style={{position: 'relative'}}>*/}
                {/*<div className='landing-startup-gradient'/>*/}
                {/*<div*/}
                {/*    className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08 shell-margin-bottom-60'>What*/}
                {/*    our clients say*/}
                {/*</div>*/}
                {/*<div className='w-100'>*/}
                {/*    <Swiper*/}
                {/*        swiperOptions={{*/}
                {/*            slidesPerView: 1.5,*/}
                {/*            loop: false,*/}
                {/*            freeMode: false,*/}
                {/*            centeredSlides: true,*/}
                {/*            pagination: {*/}
                {/*                el: '.swiper-pagination',*/}
                {/*                clickable: true*/}
                {/*            },*/}
                {/*            autoplay: {*/}
                {/*                delay: 8000,*/}
                {/*            },*/}
                {/*            breakpoints: {*/}
                {/*                // when window width is <= 640px*/}
                {/*                1200: {*/}
                {/*                    slidesPerView: 1*/}
                {/*                },*/}
                {/*                1300: {*/}
                {/*                    slidesPerView: 1.2*/}
                {/*                },*/}
                {/*                1400: {*/}
                {/*                    slidesPerView: 1.25*/}
                {/*                },*/}
                {/*                1500: {*/}
                {/*                    slidesPerView: 1.3*/}
                {/*                },*/}
                {/*                1600: {*/}
                {/*                    slidesPerView: 1.4*/}
                {/*                }*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        navigation={false}*/}

                {/*    >*/}
                {/*        {listSwiper.map((member, k) => {*/}
                {/*            return <Slide key={k}>*/}
                {/*                <div className='row-m0 block-slider-point no-gutters'>*/}
                {/*                    <div className='col block-slider-point-left'>*/}
                {/*                        <div className='row-m0 no-gutters'>*/}
                {/*                            <div className='col client-avatar'*/}
                {/*                                 style={{background: `url(${member.avatar})`}}/>*/}
                {/*                            <div*/}
                {/*                                className='col client-name-block client-name-block-startup shell-margin-top-20'>*/}
                {/*                                <div*/}
                {/*                                    className='text-client-name font-weight-500 color-rgba-08'>{member.Name},*/}
                {/*                                </div>*/}
                {/*                                <div className='text-client-position'>{member.Position}<a*/}
                {/*                                    href={member.startupLink}*/}
                {/*                                    target='_blank'> {member.startupName}</a></div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className='row-m0 margin-top-22 no-gutters'>*/}
                {/*                            <div className='col text-quotes quotes-block-left color-rgba-08'>“</div>*/}
                {/*                            <div*/}
                {/*                                className='col text-client-descr client-descr-block font-weight-300 color-rgba-08'>{member.descr}</div>*/}
                {/*                            <div className='col text-quotes quotes-block-right color-rgba-08'>”*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                        <div className='row-m0 text-see-sample-pdf font-weight-500 no-gutters'><a*/}
                {/*                            href={member.linkPDF} target='_blank'>See a report sample PDF</a></div>*/}
                {/*                    </div>*/}
                {/*                    <div className='col block-slider-point-right'>*/}
                {/*                        <div className='client-logo' style={member.logo !== '' ? {*/}
                {/*                            background: `url(${member.logo})`,*/}
                {/*                            width: '150px',*/}
                {/*                            height: '150px',*/}
                {/*                            margin: '30px auto 0 auto'*/}
                {/*                        } : {}}/>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </Slide>*/}
                {/*        })}*/}
                {/*    </Swiper>*/}
                {/*</div>*/}
                {/*</div>*/}


                {/*How it works*/}
                <div className='row-m0 no-gutters margin-top-75'>
                    <div
                        className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08 margin-bottom-20'>How
                        it works?
                    </div>
                    <div className='row-m0 no-gutters w-100'>
                        <div className='block-algorithm-table'>
                            <div className='row-b'>
                                <span className="line-dot line-1"></span>
                                <span className="line-dot line-2"></span>
                                <span className="line-dot line-3"></span>

                                <div className='col-sm-6 col-md-6 col-lg-3 block-advantage-point text-align-center'>
                                    <div className='row-m0 no-gutters'>
                                        <div className='number-circle'>
                                            <div className='text-number-circle text-align-center'>1</div>
                                        </div>
                                    </div>
                                    <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Create
                                        startup profile
                                    </div>
                                    <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>Go public,
                                        create a beautiful profile of your project on Rocket DAO platform, become visible
                                        for experts, accelerators and investors.
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-3 block-advantage-point text-align-center'>
                                    <div className='row-m0 no-gutters'>
                                        <div className='number-circle'>
                                            <div className='text-number-circle text-align-center'>2</div>
                                        </div>
                                    </div>
                                    <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Fill in
                                        the evaluation form
                                    </div>
                                    <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>Answer the
                                        questions that Rocket DAO expert community has prepared. Describe your product,
                                        business model, market, industry, investment round, introduce your team.
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-3 block-advantage-point text-align-center'>
                                    <div className='row-m0 no-gutters'>
                                        <div className='number-circle'>
                                            <div className='text-number-circle text-align-center'>3</div>
                                        </div>
                                    </div>
                                    <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>Get the
                                        bright results!
                                    </div>
                                    <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>In the end
                                        you will receive a carefully structured pdf report with expert evaluation and
                                        feedback on each parameter. It can be spread among investors.
                                    </div>
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-3 block-advantage-point text-align-center'>
                                    <div className='row-m0 no-gutters'>
                                        <div className='number-circle' style={{backgroundColor: '#0ECFAA'}}>
                                            <div className='text-number-circle text-align-center'
                                                 style={{color: '#2F3849'}}>4
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-algorithm-name font-weight-500 color-rgba-08 margin-top-15'>
                                        Get free services bonus
                                    </div>
                                    <div className='row-m0 text-algorithm-descr font-weight-300 color-rgba-08'>
                                        For the startups with high evaluation rates (more than 6 points) we have great
                                        bonuses from our partners: for legal consultancy, full-service design, software
                                        development and video production.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row-m0 algorithm-button-wrapper margin-top-35'>
                        {/*<ButtonGreen*/}
                            {/*TitleButton={'Evaluate your startup today'}*/}
                            {/*EventOnClick={() => this.openProductHandle()}*/}
                            {/*paddingButton={'25'}*/}
                            {/*disabled={false}*/}
                        {/*/>*/}

                        <ButtonWithTerms color={'green'} EventOnClick={() => this.openProductHandle()}>
                            Evaluate your startup today
                        </ButtonWithTerms>
                    </div>
                </div>

                {/*What are the advantages*/}
                <div className='row-m0 block-advantages-table-wrapper no-gutters margin-top-145 padding-top-70'>
                    <div className='landing-startup-gradient'/>
                    <div
                        className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08'>What
                        are the advantages?
                    </div>
                    <div className='row-m0 block-advantages-table margin-top-35'>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-investor'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Investors</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                We spread evaluation reports amongst our investors database
                            </div>
                        </div>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-expert'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Experts</div>
                            <div
                                className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                With us you can find professional mentors for the project
                            </div>
                        </div>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-b-plan'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Partners pack
                            </div>
                            <div
                                className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                You will get free support from our parters for the needed services
                            </div>
                        </div>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-feedback'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Feedback</div>
                            <div className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                You will receive real objective opinion from the market players
                            </div>
                        </div>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-analisys'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Analisys</div>
                            <div
                                className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                Evaluation process will highlight your week and strong points
                            </div>
                        </div>
                        <div className='col-4 block-advantage-point old-point'>
                            <div className='row-m0 icon icon-universality'/>
                            <div className='row-m0 text-advantage-name font-weight-500 color-rgba-08'>Universality</div>
                            <div
                                className='row-m0 text-advantage-descr margin-top-10 font-weight-300 color-rgba-08'>
                                Evaluation criteria are different for different investment rounds
                            </div>
                        </div>
                    </div>

                </div>


                {/*Background*/}
                <div className='row-m0 expert-conducting-bg-wrapper margin-top-100'>
                    <div className='row-m0 block-expert-conducting-bg no-gutters'>
                        <div className='col-12 col-md-7'/>
                        <div className='col-12 col-md-5'>
                            <div
                                className='expert-conducting-main-title expert-conducting-block color-rgba-08 font-weight-500'>Who
                                are the experts conducting evaluation?
                            </div>
                            <div
                                className='expert-conducting-descr expert-conducting-block color-rgba-08 font-weight-300 margin-top-45'>
                                <strong>Experienced specialists</strong> in startup audit, business valuation,
                                investment analysis,
                                pre-M&A asessment and in their narrow professional niches.
                                <br/><br/>
                                For today Rocket DAO experts <strong>come from USA, Germany, Great Britain,
                                Japan, Czech Republic, Croatia, Italy and many more.</strong>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Current Statistics*/}
                <CurrentStatistics
                    InvestorsNum='100+'
                    StartupsNum='50+'
                    ExpertsNum='100+'
                    PARTNERSNum={'4'}
                />

                {/*Pay Banner*/}
                <div className='row-w0 no-gutters' style={{marginTop: '132px'}}>
                    <div className='row-w0 block-landing-pay-banner-wrapper no-gutters'>
                        <PayBanner openProductHandle={() => this.openProductHandle()} user={this.props.user} LandingBanner={true}/>
                    </div>
                </div>

                {/*Check the evaluated startups*/}
                <div className='row-w0 block-evaluated-startups no-gutters'>
                    <div
                        className='w-100 block-advantages-main-title font-weight-500 text-align-center color-rgba-08'>Here
                        are the evaluated startups
                    </div>
                    <div
                        className='header-landing-descr-text text-align-center font-weight-300 color-rgba-08 margin-bottom-65'>Study
                        their reports, get inspired to pass evaluation <br/>yourself and start fundraising!
                    </div>
                    <div id='evaluation' className='block-startups-wrapper'>
                        <div className='row-b'>
                            {listEvaluatedProjects}
                        </div>
                    </div>

                    {this.state.showCartPopup ?
                        <CartPopUp id={this.state.cartId} user={this.props.user} type={this.state.CartPopUpType}
                                   type2={this.state.CartPopUpType2} close={() => this.togglePopupCart(null, null)}/>
                        : null
                    }
                </div>

                {this.state.showLoginPopup !== 'hide' && <LoginPopup
                    close={()=>{ this.setState({showLoginPopup:'hide'}); localStorage.removeItem("referer"); }}
                    state={this.state.showLoginPopup}
                />
                }

                {this.state.detailsCompany !== 'none' && <UseDetailsPopup
                    company={this.state.detailsCompany}
                    parentCallback={()=>{ this.closeDetails() }}
                />}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        isAuthenticated: state.auth
    }
}

LandingStartup.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(LandingStartup)
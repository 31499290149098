import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import EvaluationView from './evaluationView'


import {logout, tokenLogin, updateUser, getLastFund, getLastAngel} from "../../../actions/Login";
import Loader from "../../test-controls/preloader/preloader";
import history from "../../../history";



class evaluationViewContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            type: ''
        }
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        } else {
            localStorage.setItem('referer', `/startup/${this.getStartupId()}/evaluation/view`);
            history.push('/login', this.props.location.state);
        }
    }

    getOpenedEval() {
        try {
            return this.props.location.state.openedEval || 0
        } catch {
            return 0;
        }
    }

    getStartupId() {
        try {
            return this.props.location.state.startupId
        } catch {
            return this.props.match.params.id;
        }
    }

    render() {

        return (
            <React.Fragment>
                {!this.props.user && <Loader loaded={this.props.user}/>}
                {this.props.user && <EvaluationView user={this.props.user} openedEval={this.getOpenedEval()} id={this.getStartupId()}/>}
                <NotificationContainer/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

evaluationViewContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps, {tokenLogin, getLastFund})(evaluationViewContainer)
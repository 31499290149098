import React from 'react';

import './stylesWayPoint.css'

export default class WayPoint extends React.Component {

    render() {
         let {
            name,
            descr,
            NumberActive,
            isDescrActive,
            noDescr,
            number,  //for active
            isLast
        } = this.props;

        let numberStyle = '';
        let numberTitle = number;
        if (NumberActive === 'active') {
            numberStyle = 'active-';
        }
        if (NumberActive === 'closed') {
            numberStyle = 'closed-';
            numberTitle = '';
        }

        let descrStyle = '';
        if (isLast) {
            descrStyle = '-last';
        } else if (!isDescrActive) {
            descrStyle = '-closed';
        }
        
        return (
            <div>
                <div className={`${numberStyle}block-way-name`}>
                     <div className='number-way-block'>{NumberActive === 'active' && <i className='fal fa-check'/>|| <div>{numberTitle}</div>} </div>

                     <div className='way-title'>{name}</div>

                </div>
                
                <div className={`block-way${descrStyle}-descr`}>
                    <div>{!noDescr ? descr : ''}</div><br/>
                </div>
            </div>
        );
    }
}
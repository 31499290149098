import React from 'react';
import './SocialButtonLink.css'

export default class InstagramSqr extends React.Component {
    render() {
        let {
            LinkSocial,
            ActiveSocial,
        } = this.props;
        /*let styleLink = {
            color: ActiveSocial? '#0000D3':'#F1F2F2',
        }*/
        let classActiveSocial = ActiveSocial? 'social-button active-social-button':' social-button no-active-social-button';
        return (
            <div className={classActiveSocial}>
                <a href={LinkSocial} target="_blank">
                    <svg width="19" height="18" viewBox="0 0 19 18">
                        <path d="M11.637 4.116c-.633-.028-.823-.034-2.425-.034-1.602 0-1.792.006-2.424.034-1.628.074-2.386.845-2.46 2.46-.029.632-.035.822-.035 2.424 0 1.602.006 1.792.035 2.425.074 1.61.83 2.385 2.46 2.459.631.028.822.035 2.424.035 1.603 0 1.792-.006 2.425-.034 1.627-.075 2.385-.847 2.459-2.46.028-.633.034-.822.034-2.424 0-1.602-.006-1.792-.034-2.424-.074-1.615-.833-2.387-2.46-2.461zm-2.425 7.965a3.08 3.08 0 110-6.162 3.08 3.08 0 010 6.162zm3.203-5.563a.72.72 0 110-1.44.72.72 0 010 1.44zM11.212 9a2 2 0 11-4 0 2 2 0 014 0zm3.25-9h-10.5a3.75 3.75 0 00-3.75 3.75v10.5A3.75 3.75 0 003.962 18h10.5a3.75 3.75 0 003.75-3.75V3.75A3.75 3.75 0 0014.462 0zm.714 11.473c-.1 2.182-1.313 3.391-3.49 3.491-.64.03-.844.036-2.474.036-1.63 0-1.833-.007-2.474-.036-2.18-.1-3.39-1.311-3.49-3.49-.03-.64-.036-.844-.036-2.474 0-1.629.007-1.834.036-2.473.1-2.181 1.31-3.391 3.49-3.49.64-.03.844-.037 2.474-.037 1.63 0 1.834.007 2.474.036 2.181.1 3.392 1.313 3.49 3.49.03.64.036.845.036 2.474 0 1.63-.007 1.834-.036 2.473z" fill="currentColor"/>
                    </svg>
                </a>
            </div>
        );
    }
}
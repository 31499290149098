import React from 'react';
import './style.css'

export default class ButtonBorder extends React.Component {

    render() {
        let {
            className,
            TitleButton,
            EventOnClick,
            paddingButton, //0, '' inline , >0 padding
            disabled,
        } = this.props;
        let classSize = disabled ? ' disabled' : ''
        let styleButton
        if (paddingButton) {
            if (paddingButton > 0) {
                styleButton = {
                    display: 'inline-block',
                    paddingLeft: paddingButton + 'px',
                    paddingRight: paddingButton + 'px'
                }
            }
            else {
                styleButton = {display: 'inline-block', width: '100%'}
            }
        } else {
            styleButton = {display: 'inline-block', width: '100%'}
        }
        return (
            <div className={"block-button-3-0 button-3-0-border " + className + classSize} style={styleButton}
                 onClick={!disabled ? EventOnClick : null}>
                <p className="block-button-3-0-title">{TitleButton}</p>
            </div>
        );
    }
}


import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {updateStartup} from "../../../actions/Login";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import {getLastProject, updateProject} from "../../../actions/Project";
import {COUNTRIES} from '../../../utils/countriesLanguages'
import Select from "../../controls3.0/select/Select";
import {LegalEntity} from '../../../utils/staticData'
import Bitmap from "../../../images/ilustaciya/language-rocketDAO-startup.svg";

class Step4 extends React.Component {
    constructor(props) {
        super(props);
        let project = props.project || {}
        project.countryOfIncorporation = "Company not registered"
        project.headquarters = "Decentralized"
        project.countryOfIncorporation ? project.countryOfIncorporation = [{Name: project.countryOfIncorporation}] : project.countryOfIncorporation = null
        project.legalEntity ? project.legalEntity = [{Name: project.legalEntity}] : project.legalEntity = null
        project.headquarters ? project.headquarters = [{Name: project.headquarters}] : project.headquarters = null
        this.state = {
            project: project,
            errors: {}
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id && !this.state.project.id) {
            let project = nextProps.project;
            project.countryOfIncorporation = project.countryOfIncorporation ? [{Name: project.countryOfIncorporation}] : null
            project.legalEntity = project.legalEntity ? [{Name: project.legalEntity}] : null
            project.headquarters = project.headquarters ? [{Name: project.headquarters}] : null


            this.setState({project: project})
        }
    }

    onChangeCountry = data => {
        if (data) {
            this.setState({project: {...this.state.project, countryOfIncorporation: data}})
            this.setState({errors: {...this.state.errors, countryOfIncorporation: ''}})
        }
    }

    onChangeLegalEntity = data => {
        if (data) {
            this.setState({project: {...this.state.project, legalEntity: data}})
            this.setState({errors: {...this.state.errors, legalEntity: ''}})
        }
    }

    onChangeHeadquarters = data => {
        if (data) {
            this.setState({project: {...this.state.project, headquarters: data}})
            this.setState({errors: {...this.state.errors, headquarters: ''}})
        }
    }

    validate(data) {
        const errors = {}
        for (let name in data) {
            let value = data[name],
                required = false
            switch (name) {
                case 'countryOfIncorporation':
                case 'legalEntity':
                case 'headquarters':
                    required = true;
                    break;
            }

            if(required && !value) {
                errors[name] = 'Field is required'
            }
        }
        return errors;
    }


    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            countryOfIncorporation: (project.countryOfIncorporation && project.countryOfIncorporation[0]) ? project.countryOfIncorporation[0].Name : '',
            // legalEntity: (project.legalEntity && project.legalEntity[0]) ? project.legalEntity[0].Name : '',
            headquarters: (project.headquarters && project.headquarters[0]) ? project.headquarters[0].Name : ''
        }

        let errors = this.validate(obj)
        if(Object.keys(errors).length && !onlyStep) {
            this.setState({ errors })
            return;
        }

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }


    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let {project, errors} = this.state;
        let incorporation = [{"code": "none", "Name": "Company not registered"}, ...COUNTRIES]
        let headquarters = [{"code": "none", "Name": "Decentralized"}, ...COUNTRIES]

        return (
            <React.Fragment>
                <div className="step-1 is-startup">


                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative', minHeight:'600px'}}>
                            <img src={Bitmap} alt="" className="img-16-step-reg-expert" style={{top:'70px', width:'450px',right:'0px'}}/>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Legal structure
                            </p>
                            <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                Have you registered your business already?
                            </p>
                            <p className="text-h3 color-text-reg-gray margin-0 shell-margin-bottom-5">
                                Country of incorporation
                            </p>
                            <div className="text-body color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Please define where the company is <br/>
                                legally incorporated.
                            </div>
                            <div className="shell-select-reg shell-margin-bottom-40">
                                <Select
                                    placeholder={'Select'}
                                    data={incorporation}
                                    value={project.countryOfIncorporation}
                                    onChange={(e) => this.onChangeCountry(e)}
                                    error={errors.countryOfIncorporation}
                                />
                            </div>
                            {/*<p className="text-h5 color-text-reg-gray margin-0 shell-margin-bottom-10">*/}
                                {/*Form of the legal entity*/}
                            {/*</p>*/}
                            {/*<div className="shell-select-reg shell-margin-bottom-40" style={{position:'relative',zIndex:'2'}}>*/}
                                {/*<Select*/}
                                    {/*placeholder={'select'}*/}
                                    {/*data={LegalEntity}*/}
                                    {/*value={project.legalEntity}*/}
                                    {/*onChange={(e) => this.onChangeLegalEntity(e)}*/}
                                    {/*error={errors.legalEntity}*/}
                                {/*/>*/}
                            {/*</div>*/}
                            <p className="text-h3 color-text-reg-gray margin-0 shell-margin-bottom-5">
                                Headquarters
                            </p>
                            <div className="text-body color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Please select the country where your team <br/>
                                actually lives, works and develops the product.
                            </div>
                            <div className="shell-select-reg shell-margin-bottom-40" style={{position:'relative',zIndex:'1'}}>
                                <Select
                                    placeholder={'Select'}
                                    data={headquarters}
                                    value={project.headquarters}
                                    onChange={(e) => this.onChangeHeadquarters(e)}
                                    error={errors.headquarters}
                                />
                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(3, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(5)}
                                paddingButton={'54'}
                                disabled={!project.countryOfIncorporation || !project.headquarters}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step4.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step4)
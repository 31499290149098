import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
// import { CardElement, Elements } from "@stripe/react-stripe-js";

import Header from "../header";
import Footer from "../footer/Footer";
import "./style.scss";

// const stripePromise = loadStripe("pk_test_9Eq1kDXWrFQ5zrHT1RLuO1Sz002tWfvPMy");
const stripePromise = loadStripe("pk_live_7yg5lqyuwrMbGQmAd9MvBUQX00moiKt3jg");

const Payments = () => {
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(async () => {
    const res = await axios
      .post(`/v2/stripe/create-checkout-session`, {
        productId,
        c: (new URLSearchParams(window.location.search)).get('c'),
        s: (new URLSearchParams(window.location.search)).get('s'),
      })
      .catch((e) => {
        setError("Error happens. Please contact support");
      });

    setIsLoading(false);
    if (!res) {
      return;
    }
    const stripe = await stripePromise;
    stripe.redirectToCheckout({
      sessionId: res.data.sessionId,
    });
  }, [productId]);

  return (
    <div>
      <Header />
      <div className="container payment-container">
        {isLoading && <div class="lds-hourglass"></div>}

        <span className="payment-error">{error}</span>
        {/* <form onSubmit={handleSubmit}>
          <Elements stripe={stripePromise}>
            <CardElement />
          </Elements>
        </form> */}
      </div>

      <Footer />
    </div>
  );
};

export default Payments;

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import Loader from "../../test-controls/preloader/preloader";

import Combined from '../../../images/svg/Combined-Shape-2.svg'
import ButtonGray from "../../controls3.0/button/ButtonGray";

import Human_KYC from '../../../images/svg/Human_KYC.svg'
import ButtonBlue from "../../controls3.0/button/ButtonBlue";

class Step22 extends React.Component {
    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if(!onlyStep) {

        }

        let loading = nextStep === 21? 'loadingBack' : 'loading';
        this.setState({[loading]: true});

        expert = {...expert, event_type: 'expert_KYC_start_21'};
        if(nextStep === 24){
            expert.event_data = {
                skip: true
            }
        }

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }

    render () {
        return (
            <React.Fragment>

                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>

                    <img src={Human_KYC} className="block-img-step-22 new-no-display-down-1199" alt=""/>

                    <div className="container-step-2-reg"
                         style={{position: 'relative'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Only one step keeps you <br/>
                            from receiving ROCK tokens ...
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-80">
                            We strive to build a transparent and honest community. <br/>
                            This is why we need to verify your identity before transferring tokens.
                        </p>

                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                            KYC
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            This is a necessary measure for <br/>
                            identifying and verifying customers <br/>
                            over the world.</p>


                        <div className="shell-button-reg-expert">
                            <div className="block-button-big-icon" onClick={() => this.update(23, true)}>
                                <p className="title"><img src={Combined} alt=""/>Pass KYC</p>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Skip'}
                                EventOnClick={() => this.update(24, true)}
                                paddingButton={'40'}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>


                {/*<h2>Only one step keeps you from receiving ROCK tokens...</h2>*/}
                {/*<div>We strive to build a transparent and honest community.*/}
                    {/*That is why we need to verify your identity before transferring tokens to your wallet.*/}
                {/*</div>*/}
                {/*<h3>KYC</h3>*/}
                {/*<div>KYC (Know your customer) is a necessary measure to ensure that we deal with legitimate entities.</div>*/}
                {/*<div className="row">*/}
                    {/*<div className="col-4">*/}
                        {/*<button className="btn" onClick={() => alert('Later!')}>Pass KYC</button>*/}
                    {/*</div>*/}
                    {/*<div className="col-8">*/}
                        {/*<ButtonLoad title={'Skip this step and explore platform functionality'} loading={this.state.loading} onClickFunc={() => this.update(23, true)} />*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<div style={{textAlign: 'right'}}>*/}
                    {/*<ButtonLoad title={'Back'} loading={this.state.loadingBack} onClickFunc={() => this.update(21, true)} />*/}
                {/*</div>*/}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step22.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step22)
import React from 'react';
import './style.scss';
import Header from "../../header";
import config from "../../../config";
import Footer from "../../footer/Footer";
import {getListIndustries} from "../../../actions/Project";
import history from "../../../history";
import ApolloClient from "apollo-boost"
import {gql} from 'apollo-boost';
import {Query} from 'react-apollo';
import Loader from '../../test-controls/preloader/preloader';
import qs from 'qs';
import InfiniteScroll from 'react-infinite-scroller';
import _ from "lodash";
import $ from "jquery";
import {connect} from "react-redux";
import {tokenLogin} from "../../../actions/Login";
import PropTypes from "prop-types";
import {generateEvent} from "../../../actions/amplitude";
import {StartupListCart} from "../../v4/startupListCart";
import {StartupListScoreItem} from "../../v4/startupListScoreItem";
import {StartupListPeItem} from "../../v4/startupListPeItem";
import {FundListItem} from "../../v4/fundListItem";
import {AngelNetworkListItem} from "../../v4/angelNetworkListItem";
import {InvestorListItem} from "../../v4/investorListItem";
import {ExpertListItem} from "../../v4/expertListItem";
import {AcceleratorListItem} from "../../v4/acceleratorListItem";
import {Row, Container} from '../../v4/Grid';
import {isExist} from "../../../utils/helper";
import {getViewStatus} from "../../../utils/viewCounter";
import LoginPopup from "../../v4/loginPopup";

const GET_SEARCH = gql`
    query search($type: String $params: JSON $limit: JSON $offset: Int){
        search(type: $type params: $params limit: $limit offset: $offset){
            startupList{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    likes
                    iLiked
                    hasVideo
                    invest_sum
                    shared
                    round
                }
            }
            startupListScore{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    likes
                    iLiked
                    hasVideo
                    invest_sum
                    shared
                    round
                    ddFile
                    views
                }
            }
            startupListPreliminaryEvaluation{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    views
                    likes
                    iLiked
                    hasVideo
                    invest_sum
                    shared
                    ddFile
                    preliminary_evaluation
                    round
                }
            }
            startupListPopular{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    views
                    likes
                    iLiked
                    hasVideo
                    invest_sum
                    shared
                    ddFile
                    preliminary_evaluation
                    round
                }
            }
            fundList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    industry
                    logoFileId
                    backgroundFileId
                    fundSize
                    isProof
                }
            }
            acceleratorList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    industry
                    logoFileId
                    backgroundFileId
                    fundingUpTo
                    type
                    startupsTotal
                }
            }
            angelNetworkList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    currentTeamCount
                    fundSize
                    industry
                    logoFileId
                    backgroundFileId
                }
            }
            investorList{
                count
                rows{
                    id
                    role
                    professionalDescription
                    InvestorProfile{
                        industry
                        investmentSize
                        investmentsCount
                    }
                    User{
                        firstName
                        lastName
                        logoFileId
                        backgroundImageId
                        country
                    }
                }
            }
            expertList{
                count
                rows{
                    id
                    role
                    professionalIdentification
                    professionalDescription
                    twitterLink
                    facebookLink
                    linkedInLink
                    mediumLink
                    expertRoles
                    User{
                        firstName
                        lastName
                        logoFileId
                        backgroundImageId
                        country
                    }
                    parameters
                }
            }
        }
    }
`;

const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const BlockTitle = ({h2, h3}) => {
    return <React.Fragment>
        <h2>{h2}</h2>
        <h3>{h3}</h3>
    </React.Fragment>
}

class SearchStartup extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchRole: props.match.params.type,
            params: this.normalizeQuery(qs.parse(props.location.search, {ignoreQueryPrefix: true}) || {}),
            isLoad: true,
            noResults: false,
            showLoginPopup: 'hide'
        };

        if(localStorage.RocketToken){
            this.props.tokenLogin(localStorage.RocketToken);
        }
    }

    componentDidMount() {
        this.setSearchRole(this.state.searchRole);
        if (document.documentElement.scrollWidth < 465) this.hideMenu();
        window.scrollTo(0,0);
        getListIndustries()
            .then((res) => {
                console.log(res.data)
                this.setState({listIndustries: res.data})
            })
            .catch(error => {
                console.log(error);
                this.setState({loading: false})
            })

        try {
            if(localStorage.RocketToken) {
                const com = this.props.location.state;
                if (com) {
                    if (com.id && com.type && com.f) {
                        localStorage.removeItem('commandProps');

                        if (com.f === 'tpc') {
                            this.togglePopupCart(com.id, com.type, null)
                        }
                    }
                }
            }
        } catch (e) {
            history.replace({...history.location, state: undefined});
        }
    }

    hideMenu = () => {
        setTimeout(()=>{
            if (this.props.user) $('.block-button-hamburger-new').css('display', 'none');
            else this.hideMenu();
        },200);
    };

    normalizeQuery = (q) => {

        if(q.investor_type){
            q.investor_type = q.investor_type.map(i => parseInt(i))
        }

        return q;
    }

    componentDidUpdate(prevProps){
        if(this.props.match.params.type !== this.state.searchRole){
            this.setState({searchRole: this.props.match.params.type, params: {}, noResults: false}, this.refetch)

            window.scrollTo(0,0);
        } else {

            const query = this.normalizeQuery(qs.parse(this.props.location.search, {ignoreQueryPrefix: true}) || {} );

            if(!this.state.emptySearch && !!query.title){
               // delete query.title;
               // if(!_.isEqual(this.state.params,  query)){
                //    this.setState({params: query})
               // }
            } else {
                if(!_.isEqual(this.state.params, query )){
                    this.setState({params: query, noResults: false}, this.refetch)
                    window.scrollTo(0,0);
                }
            }
        }

        if(!isExist(() => prevProps.user.id) && isExist(() => this.props.user.id)){
            if(localStorage.commandProps){
                const com = JSON.parse(localStorage.commandProps);
                if(com.id && com.type && com.f){
                    localStorage.removeItem('commandProps');

                    if(com.f === 'tpc'){
                        this.togglePopupCart(com.id, com.type, null)
                    }
                }
            }
        }
    }

    setSearchRole=(type)=>{
        if(type !== this.state.searchRole) {
            this.setState({searchRole: type, params: {}, noResults: false}, this.refetch)
            window.scrollTo(0,0)
        }
    };

    setNewParams = (newParams) => {

        const currentParams = {
            ...this.state.params,
            ...newParams
        }

        const query = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});

        if(!this.state.emptySearch && !!query.title){
            delete currentParams.title;

            generateEvent(`${this.getRealRole()}_search`, {
                filters_used: Object.keys(currentParams)
            })

            this.props.history.replace({...this.props.location, search: qs.stringify(currentParams)})
        } else {

            generateEvent(`${this.getRealRole()}_search`, {
                filters_used: Object.keys(currentParams)
            })

            this.setState({params: currentParams, noResults: false}, this.refetch);
            this.props.history.replace({...this.props.location, search: qs.stringify(currentParams)})
        }

    }

    handleScroll = async (e) => {
            const listTypes = {
                'expert':           'expertList',
                'all':              'expertList',
                'all_startups':     'startupList',
                'startup':          'startupList',
                'fund':             'fundList',
                'angel_network':    'angelNetworkList',
                'angel_investor':   'investorList',
                'startup_pe':       'startupListPreliminaryEvaluation',
                'startup_dd':       'startupListScore',
                'startup_popular':       'startupListPopular',
                'accelerator':      'acceleratorList',
            };

            if(this.state.params.investor_type){
                if(this.state.params.investor_type.includes(3)){
                    listTypes.investor = 'fundList';
                }
                if(this.state.params.investor_type.includes(2)){
                    listTypes.investor = 'angelNetworkList';
                }
                if(this.state.params.investor_type.includes(1)){
                    listTypes.investor = 'investorList';
                }
            }

            const listName = listTypes[this.state.searchRole] || listTypes[e];

            if(this.state.isLoad && listName){
                this.setState({isLoad: false}, () => {
                    this.fetchMore({
                        variables: {
                            type: this.state.searchRole,
                            offset: this.data.search[listName].rows.length,
                            limit: {
                                [this.state.searchRole]: 3
                            }
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;
                            if (!fetchMoreResult.search) return prev;
                            this.setState({isLoad: true});
                            return {
                                search: {
                                    ...prev.search,
                                    [listName]: {
                                        ...prev.search[listName],
                                        rows: [
                                            ...prev.search[listName].rows,
                                            ...fetchMoreResult.search[listName].rows
                                        ]
                                    }
                                }
                            }
                        }
                    })
                });
            }
        // }
    };

    togglePopupCart(id, type, e, type2) {
        if (e && e.preventDefault) e.preventDefault();

        if( !getViewStatus() ){
            const commandProps = {
                to: this.props.location.pathname + this.props.location.search,
                id: id,
                type: type,
                f: 'tpc'
            };

            localStorage.removeItem("rFrom");
            localStorage.removeItem("referer");
            localStorage.removeItem("referer_startup");

            localStorage.setItem("commandProps", JSON.stringify(commandProps));

            this.setState({showLoginPopup: 'popup'});
        } else {
            generateEvent(`${this.getRealRole()}_search`, {
                success: true
            });
            history.push(`/${type}/${id}`);
            window.scrollTo(0,0);
        }
    }

    getRealRole = () => {
        switch (this.state.searchRole){
            case 'all'      :
            case 'startup'    :
            case 'expert'    :
            case 'investor'  :
            case 'accelerator'  :
                return this.state.searchRole;
            case 'startup_dd':
            case 'startup_popular':
            case 'startup_pe':
                return 'startup';
            case 'fund':
            case 'angel_network':
                return 'investor';
            default:
                return this.state.searchRole;
        }
    }

    getLimit(){
        switch (this.state.searchRole){
            case 'all':{
                return {
                    startup: 6,
                    startup_pe: 3,
                    startup_dd: 3,
                    startup_popular: 6,
                    fund: 3,
                    angel_network: 6,
                    angel_investor: 6,
                    expert: 6,
                    accelerator: 6
                }
            }

            case 'startup':{
                return {
                    startup: 6,
                    startup_pe: 3,
                    startup_dd: 3,
                    startup_popular: 6,
                }
            }

            case 'investor':{
                const limit = {
                    fund: 6,
                    angel_network: 6,
                    angel_investor: 6,
                };

                if(this.state.params.investor_type){
                    if(!this.state.params.investor_type.includes(2) && !this.state.params.investor_type.includes(1)){
                        limit.fund = 6;
                    }
                }

                return limit;
            }

            case 'fund':
            case 'angel_network':
            case 'angel_investor':
            case 'expert':
            case 'startup_pe':
            case 'startup_dd':
            case 'accelerator':
            case 'startup_popular':
            case 'all_startups': {
                return {
                    [this.state.searchRole]: 6
                };
            }

            default: {
                return {
                    [this.state.searchRole]: 3
                };
            }
        }
    }

    checkSearch = (v) => {
        this.setState({emptySearch: v});
    }

    render() {
        const searchRole = this.state.searchRole;
        const {listIndustries, noResults} = this.state;
        let startupList = [],
            startupListScore  = [],
            startupListPreliminaryEvaluation = [],
            fundList = [],
            investorList = [],
            angelNetworkList = [],
            expertList = [],
            startupListPopular = [],
            acceleratorList = [];


        return (
            <React.Fragment>
            <div className='search-startup-page'>
                <Header checkSearch={this.checkSearch} location={this.props.location} user={this.props.user} openedRole={searchRole} setSearchRole={(type) => this.setSearchRole(type)} showLoginForm={this.state.showLoginForm} setNewParams={this.setNewParams}/>
                {noResults &&
                    <Container mod={'-fluid'} className={'not-found'}>
                        <Container>
                            <div className='text'>
                                <span>Sorry, we couldn't find any matches for your requests.</span><br/>
                                Try searching something related.
                            </div>
                        </Container>
                    </Container>
                }

                <Container mod={'-fluid'} className={'fullscreen marketplace search-page-mobile-margin'}>
                    <Query query={GET_SEARCH} variables={{type: searchRole, params: this.state.noResults?{}:this.state.params, limit: this.getLimit()}} client={client} fetchPolicy={'cache-first'} notifyOnNetworkStatusChange={true}>
                    { ({loading, error, data, refetch, fetchMore, networkStatus}) => {
                        this.refetch = refetch;
                        this.fetchMore = fetchMore;

                        this.data = data;

                        this.networkStatus = networkStatus;
                        if (error) return null;

                        if (data && data.search){

                            if(
                                !isExist(() => data.search.startupList.rows.length) &&
                                !isExist(() => data.search.startupListPopular.rows.length) &&
                                !isExist(() => data.search.startupListScore.rows.length) &&
                                !isExist(() => data.search.startupListPreliminaryEvaluation.rows.length) &&
                                !isExist(() => data.search.fundList.rows.length) &&
                                !isExist(() => data.search.angelNetworkList.rows.length) &&
                                !isExist(() => data.search.investorList.rows.length) &&
                                !isExist(() => data.search.expertList.rows.length) &&
                                !isExist(() => data.search.acceleratorList.rows.length)
                            ){
                                if(!this.state.noResults){
                                    this.setState({noResults: true});
                                }
                            }

                            if(data.search.startupList)
                                startupList = data.search.startupList.rows.map((item, key) => <StartupListCart key={key} href={`/startup/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'startup', e, 'New Startups')}/>);
                            if(data.search.startupListPopular)
                                startupListPopular = data.search.startupListPopular.rows.map((item, key) => <StartupListCart key={key} href={`/startup/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'startup', e, 'New Startups')}/>)
                            if(data.search.startupListScore)
                                startupListScore = data.search.startupListScore.rows.map((item, key) => <StartupListScoreItem key={key} href={`/startup/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'startup', e, 'New Startups')}/>);
                            if(data.search.startupListPreliminaryEvaluation)
                                startupListPreliminaryEvaluation = data.search.startupListPreliminaryEvaluation.rows.map((item, key) => <StartupListPeItem key={key} href={`/startup/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'startup', e)}/>);
                            if(data.search.fundList)
                                fundList =  data.search.fundList.rows.map((item, key) => <FundListItem key={key} href={`/fund/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'fund', e)}/>);
                            if(data.search.angelNetworkList)
                                angelNetworkList =  data.search.angelNetworkList.rows.map((item, key) => <AngelNetworkListItem key={key} href={`/angel_network/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'angel_network', e)}/>);
                            if(data.search.investorList)
                                investorList = data.search.investorList.rows.map((item, key) => <InvestorListItem key={key} href={`/investor/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'investor', e)}/>);
                            if(data.search.expertList)
                                expertList = data.search.expertList.rows.map((item, key) => <ExpertListItem key={key} href={`/expert/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'expert', e)}/>)
                            if(data.search.acceleratorList)
                                acceleratorList = data.search.acceleratorList.rows.map((item, key) => <AcceleratorListItem key={key} href={`/accelerator/${item.id}`} item={item} listIndustries={listIndustries} onClick={(e) => this.togglePopupCart(item.id, 'accelerator', e)}/>)
                        }
                        if (loading && networkStatus !== 3 && networkStatus !== 7) return <div><Loader isLocal={true} BGColor={'transparent'}/></div>
                        return (
                            <React.Fragment>
                                {/*PRELIMINARY EVALUATION*/}

                                {(searchRole==='startup' || searchRole==='all' || searchRole === 'startup_pe') && !!startupListPreliminaryEvaluation.length && <Container id="evaluation" className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Startups with preliminary evaluation'}
                                        h3={'Startups evaluated by Rocket DAO expert community on 4 evaluation parameters'}/>
                                    {
                                        searchRole === 'startup_pe' ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.handleScroll}
                                                hasMore={networkStatus === 7 && this.data.search.startupListPreliminaryEvaluation.rows.length < this.data.search.startupListPreliminaryEvaluation.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {startupListPreliminaryEvaluation}
                                            </InfiniteScroll></div>
                                            : <Row>{startupListPreliminaryEvaluation}</Row>
                                    }



                                    {data.search.startupListPreliminaryEvaluation.count > 3 && searchRole !== 'startup_pe' &&
                                    <p className="more-items" onClick={() => this.props.history.push({ pathname: '/search/startup_pe', search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        All startups with preliminary evaluation<i className="far fa-angle-right"/>
                                    </p>
                                    }
                                </Container>}

                                {/*DUE DILIGENCE*/}

                                {(searchRole==='startup' || searchRole==='all'  || searchRole === 'startup_dd') && !!startupListScore.length && <Container className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Startups with Due Diligence'}
                                        h3={'Startups which have undertaken public Due Diligence'}/>

                                    {
                                        searchRole === 'startup_dd' ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.handleScroll}
                                                hasMore={networkStatus === 7 && this.data.search.startupListScore.rows.length < this.data.search.startupListScore.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {startupListScore}
                                            </InfiniteScroll></div>
                                            : <Row>{startupListScore}</Row>
                                    }

                                    {data.search.startupListScore.count > 3 && searchRole !== 'startup_dd' &&
                                    <p className="more-items" onClick={() => this.props.history.push({pathname: '/search/startup_dd', search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        All startups with Due Diligence<i className="far fa-angle-right"/>
                                    </p>
                                    }
                                </Container>}
                                {/*DUE DILIGENCE*/}

                                {(searchRole==='startup' || searchRole==='all'  || searchRole === 'startup_popular') && !!startupListPopular.length && <Container className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Popular startups'}
                                        h3={'Startups with the greatest number of followers'}/>

                                    {
                                        searchRole === 'startup_popular' ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.handleScroll}
                                                hasMore={networkStatus === 7 && this.data.search.startupListPopular.rows.length < this.data.search.startupListPopular.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {startupListPopular}
                                            </InfiniteScroll></div>
                                            : <Row>{startupListPopular}</Row>
                                    }

                                    {data.search.startupListPopular.count > 6 && searchRole !== 'startup_popular' &&
                                    <p className="more-items" onClick={() => this.props.history.push({pathname: '/search/startup_popular', search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        More popular startups<i className="far fa-angle-right"/>
                                    </p>
                                    }
                                </Container>}

                                {(searchRole==='startup' || searchRole === 'all_startups' || searchRole==='all') && !!startupList.length &&

                                <Container id='startups' className={'mt-60'}>

                                    <BlockTitle
                                        h2={'All startups'}
                                        h3={'The list of all startups registered on Rocket DAO'}/>
                                    {
                                        searchRole === 'all_startups' || searchRole === 'startup' ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={this.handleScroll}
                                                hasMore={networkStatus === 7 && this.data.search.startupList.rows.length < this.data.search.startupList.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {startupList}
                                            </InfiniteScroll></div>

                                            :  <Row>{startupList}</Row>
                                    }


                                    {data.search.startupList.count > 3 && searchRole === 'all' &&
                                    <p className="more-items" onClick={() => this.props.history.push({ pathname: '/search/all_startups', search: qs.stringify(!this.state.noResults ? this.state.params : {}) })}>
                                        More startups<i className="far fa-angle-right"/>
                                        <span className="new-black-tool-tip"
                                              data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups at the idea stage (projects with an idea and literally nothing more), whereas 10 goes to startups at the B Series round and higher. ">
                                            </span>
                                    </p>
                                    }
                                </Container>}

                                {/*FUNDS*/}

                                {(searchRole==='investor' || searchRole==='all' || searchRole === 'fund') && !!this.data.search.fundList && !!fundList.length && <Container id='ventureFunds' className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Venture funds'}
                                        h3={'Investing funds you must be interested in'}/>
                                    {
                                        searchRole === 'fund' ||
                                        (searchRole === 'investor'
                                            && (!this.data.search.angelNetworkList || !this.data.search.angelNetworkList.count)
                                            && (!this.data.search.investorList || !this.data.search.investorList.count)
                                        ) ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={() => this.handleScroll('fund')}
                                                hasMore={networkStatus === 7 && this.data.search.fundList.rows.length < this.data.search.fundList.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {fundList}
                                            </InfiniteScroll></div>
                                            : <Row>{fundList.slice(0, 3)}</Row>
                                    }

                                    {data.search.fundList.count > 3 && ( (searchRole !== 'fund' || searchRole==='investor') && ((data.search.investorList && !!data.search.investorList.count) || (data.search.angelNetworkList && !!data.search.angelNetworkList.count))) &&
                                    <p className="more-items" onClick={() => this.props.history.push({pathname: '/search/fund', search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        More venture funds<i className="far fa-angle-right"/>
                                    </p>
                                    }

                                </Container>}

                                {/*ANGEL NETWORKS*/}

                                {(searchRole==='investor' || searchRole==='all' || searchRole === 'angel_network')  && !!this.data.search.angelNetworkList && !!angelNetworkList.length && <Container id='aFunds' className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Angel networks'}
                                        h3={'Top angel networks of the month'}/>

                                    {
                                        searchRole === 'angel_network' ||
                                        (searchRole==='investor' && !data.search.investorList) ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={() => this.handleScroll('angel_network')}
                                                hasMore={networkStatus === 7 && this.data.search.angelNetworkList.rows.length < this.data.search.angelNetworkList.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {angelNetworkList}
                                            </InfiniteScroll></div>
                                            : <Row>{angelNetworkList}</Row>
                                    }

                                    {data.search.angelNetworkList.count > 3 && ((searchRole !== 'angel_network' || searchRole==='investor') && data.search.investorList) &&
                                    <p className="more-items" onClick={() => this.props.history.push({pathname: '/search/angel_network', search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        More angel networks<i className="far fa-angle-right"/>
                                    </p>
                                    }
                                </Container>}

                                {/*ANGEL INVESTORS*/}

                                {(searchRole==='investor' || searchRole==='all' || searchRole === 'angel_investor') && !!data.search.investorList && !!investorList.length && <Container id='investors' className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Angel Investors'}
                                        h3={'Top business angels and public individual investors'}/>


                                    {
                                        searchRole === 'angel_investor' || searchRole==='investor' ?
                                            <div className="next-row-b"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={() => this.handleScroll('angel_investor')}
                                                hasMore={networkStatus === 7 && this.data.search.investorList.rows.length < this.data.search.investorList.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {investorList}
                                            </InfiniteScroll></div>
                                            : <Row>{investorList}</Row>
                                    }




                                    {data.search.investorList.count > 3 && searchRole === 'all' &&
                                    <p className="more-items" onClick={() => this.props.history.push({ pathname: '/search/angel_investor' , search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        More angel investors<i className="far fa-angle-right"/>
                                    </p>
                                    }
                                </Container>}

                                {/*ACCELERATORS*/}

                                {(searchRole==='accelerator' || searchRole==='all') && !!data.search.acceleratorList && !!acceleratorList.length &&  <Container id='accelerators' className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Accelerators'}
                                        h3={'Best supporters and educators of early-stage, growth-driven companies'}/>


                                    {
                                        searchRole === 'accelerator' ?
                                            <div className="next-row-b margin-top-15"><InfiniteScroll
                                                pageStart={0}
                                                loadMore={() => this.handleScroll('accelerator')}
                                                hasMore={networkStatus === 7 && this.data.search.acceleratorList.rows.length < this.data.search.acceleratorList.count}
                                                useWindow={true}
                                                threshold={250}
                                                initialLoad={false}
                                                loader={<div key={0}>Loading ...</div>}
                                            >
                                                {acceleratorList}
                                            </InfiniteScroll></div>
                                            : <Row className={'margin-top-15'}>{acceleratorList}</Row>
                                    }

                                    {data.search.acceleratorList.count > 3 && searchRole === 'all' &&
                                    <p className="more-items" onClick={() => this.props.history.push({ pathname: '/search/accelerator' , search: qs.stringify(!this.state.noResults ? this.state.params : {})})}>
                                        More accelerators<i className="far fa-angle-right"/>
                                    </p>
                                    }

                                </Container>}

                                {/*EXPERTS*/}

                                {(searchRole==='expert' || searchRole==='all') && !!expertList.length && <Container id='experts' className={'mt-60'}>
                                    <BlockTitle
                                        h2={'Experts'}
                                        h3={'Experts in startups evaluation and valuation'}/>

                                    <div className="next-row-b"><InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.handleScroll}
                                        hasMore={networkStatus === 7 && this.data.search.expertList.rows.length < this.data.search.expertList.count}
                                        useWindow={true}
                                        threshold={250}
                                        initialLoad={false}
                                        loader={<div key={0}>Loading ...</div>}
                                    >
                                        {expertList}
                                    </InfiniteScroll></div>
                                </Container>}

                            </React.Fragment>
                        )
                    }}
                </Query>
                </Container>

                {this.state.showLoginPopup !== 'hide' && <LoginPopup
                    close={()=>{
                        this.setState({showLoginPopup:'hide'});
                    }}
                    state={this.state.showLoginPopup}
                />}

                <Footer/>
            </div>

            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

SearchStartup.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    // getExpert: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(SearchStartup)
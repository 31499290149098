import React from 'react';

import './style.css'

export default class CurrentStatistics extends React.Component {


    render() {
        let {
            StartupsNum,
            InvestorsNum,
            ExpertsNum,
            PARTNERSNum,
        } = this.props;

        return (
            <div className='block-current-statistics w-100'>
                <div className='row-b main-text no-gutters color-rgba-08 font-weight-500 shell-margin-bottom-10'>Current statistics</div>
                <div className='row-b block-roles-statistic no-gutters '>
                    <div className='col role-statistic-wrapper'>
                        <div className='number-text number-block font-weight-300'>{InvestorsNum}</div>
                        <div className='role-name-text investor font-weight-500'>INVESTORS</div>
                        <div className='descr-text font-weight-300'>already work with us</div>
                    </div>
                    <div className='col role-statistic-wrapper'>
                        <div className='number-text number-block font-weight-300'>{StartupsNum}</div>
                        <div className='role-name-text startup font-weight-500'>STARTUPS</div>
                        <div className='descr-text font-weight-300'>passed our evaluation</div>
                    </div>
                    <div className='col role-statistic-wrapper'>
                        <div className='number-text number-block font-weight-300'>{ExpertsNum}</div>
                        <div className='role-name-text expert font-weight-500'>EXPERTS</div>
                        <div className='descr-text font-weight-300'>evaluate the projects</div>
                    </div>
                    <div className='col role-statistic-wrapper'>
                        <div className='number-text number-block font-weight-300'>{PARTNERSNum}</div>
                        <div className='role-name-text startup font-weight-500'>PARTNERS</div>
                        <div className='descr-text font-weight-300'>support projects</div>
                    </div>
                </div>
            </div>
        )
    }
}
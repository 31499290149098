import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import {getListParameters} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import Loader from '../../test-controls/preloader/preloader';
import Tabs from "../../controls3.0/tabs/Tabs";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";

class Step12 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        if (!Array.isArray(expert.parameters)) {
            expert.parameters = [];
        }
        this.state = {
            user: user,
            expert: expert,
            listParameters: [],
            loaded: false
        }
    }

    componentDidMount() {
        getListParameters()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({listParameters: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })
    }

    addToParameters = param => {
        let parameters = this.state.expert.parameters;
        let isFound = parameters.find(x => x.id === param.id)
        if (!isFound) {
            parameters.push(param)
        } else {
            parameters = parameters.filter(x => x.id !== param.id)
        }
        this.setState({expert: {...this.state.expert, parameters}, empty: false})
    }


    update = (nextStep, onlyStep) => {
        let expert = this.state.expert

        let loading = nextStep == 11 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})
        this.props.updateExpert({parameters: expert.parameters, event_type: 'expert_parameters_9'}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }




    render() {
        let {user, expert, listParameters} = this.state;

        let list = listParameters.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={() => this.addToParameters(item)}
                    active={expert.parameters.find((i) => i.id === item.id)}
                />
            )
        })

        return (
            <React.Fragment>

                <div className="block-gradient"/>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Startup evaluation parameters
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20">
                            In order to conduct expert evaluation of the startups registered on Rocket DAO platform,<br/>
                            we determined a set of the most important evaluation parameters you may see below.
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-150" style={{fontWeight:'600'}}>
                            Please choose the one(s) you are experienced in and ready to conduct startups' assessment on.
                        </p>

                        <div className="block-list-tabs" style={{position: 'relative', maxWidth:'620px'}}>
                            {list}
                            <Loader style={{backgroundColor: 'inherit'}} loaded={this.state.loaded} isLocal={true} ></Loader>
                        </div>

                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(11, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(13)}
                                paddingButton={'56'}
                                disabled={!expert.parameters.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step12.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step12)



import React from 'react';
import './Tag2.0.css'

export default class Tag2_0 extends React.Component {


    justFunc = () => {

    }

    render() {
        let {
            TitleTag,
            LinkTag,
            Color,
        } = this.props;
        let styleColor = Color? Color: '#00F082'
        let style = {color: styleColor}
        let getFilterByTag = this.props.getFilterByTag || this.justFunc


        return (
            <div className="block-tag-2-0 is-click-link" onClick={() => getFilterByTag(TitleTag)}>
                <a>

                    <p className='fix-long-text break-word is-click-link' style={style}><i className="fas fa-tag is-click-link" style={style}></i>{TitleTag}</p>
                </a>
            </div>
        );
    }
}
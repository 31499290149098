import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";
import {getListIndustries} from "../../../actions/Project";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import CheckBox from '../../controls3.0/checkBox/cubeCheckBox'


class Step8 extends React.Component {
    constructor(props) {
        super();

        let user = props.user,
            angel = props.angel || {};
        if(!angel.industry) angel.industry = []
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            listI: []
        }
    }

    componentDidMount () {
        getListIndustries()
            .then((res) => {
                this.setState({listI: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    onChange = item => {
        let industry = this.state.angel.industry;

        let isFound = industry.find(x => x === item.id);

        if (!isFound) {
            industry.push(item.id)
        } else {
            industry = industry.filter(x => x !== item.id)
        }
        this.setState({angel: {...this.state.angel, industry: industry}})
    }

    update = (nextStep, onlyStep) => {
        let obj = {
            industry: this.state.angel.industry
        }


        const fundId = this.state.angel.id;

        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})

        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, angel, errors} = this.state

        let list = this.state.listI.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup" onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CheckBox
                        onClick={null}
                        active={angel.industry.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup">{item.title}</div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Industry
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-10" style={{maxWidth:'540px'}}>
                                    Which industries and business areas is your angel network most interested and proficient in?
                                </p>
                                <div className="text-button color-text-reg-gray margin-0 shell-margin-bottom-25">
                                    Choose from the below.
                                </div>

                                <div className="block-list-check-box-reg-startup bl-fix-height">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(6, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(8)}
                                paddingButton={'54'}
                                disabled={!this.state.angel.industry.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step8.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step8)
import React from 'react';
import './Header.css';
import './style.scss';
import {Link, Switch} from 'react-router-dom'
import $ from 'jquery'
import packageJSON from './../../../package.json'
import ModalForgotPassword from '../modal/ModalForgotPassword'
import ModalChangePassword from '../modal/ModalChangePassword'
import RocketDAO from '../../images/header-rocket-logo.svg'
import '../test-controls/newMenuTop/new-menu-top.css'
import {getListParameters, getNotifications, putProfile} from "../../actions/Profile";
import {getListIndustries, getListProjects, getProjectInfoBalance, getProjectsPopular} from "../../actions/Project";
import {generateEvent} from "../../actions/amplitude";
import GodMenu from './newMenu'
import {createAvatar} from "../user/createAvatar";
import config from "../../config";
import history from '../../history';
import TabsCounter from "../controls3.0/tabs/TabsCounter";
import IndustryPopup from './Search/IndustryPopup'
import EvalParamPopup from './Search/EvalParamPopup'
import BModelPopup from './Search/BModelPopup'
import IModelPopup from './Search/IModelPopup'
import LocationPopup from './Search/LocationPopup'
import AccelTypePopup from './Search/AccelTypePopup'
import AccelInvSize from './Search/AccelInvSize'
import InvestorInvSize from './Search/InvestorInvSize'
import InvestorTypePopup from './Search/InvestorTypePopup'
import Input from "../controls3.0/input/Input";
import ResentSearchBlock from "./Search/ResentSearchBlock";
import axios from "axios";
import qs from "qs";
import ApolloClient from "apollo-boost"
import {gql} from 'apollo-boost';
import {Query} from 'react-apollo';
import {tokenLogin} from "../../actions/Login";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {updateMenu} from '../../actions/menu';
import _ from "lodash";
import {Container} from "../v4/Grid/Container";
import {Row} from '../v4/Grid/Row';
import {isExist, isMobile} from "../../utils/helper";

let openPopupCount = 1;
let searchFocus = false;

const GET_SEARCH = gql`
    query search($type: String $params: JSON $limit: JSON $offset: Int){
        search(type: $type params: $params limit: $limit offset: $offset){
            startupList{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    likes
                    invest_sum
                    shared

                }
            }
            startupListScore{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    likes
                    invest_sum
                    shared
                }
            }
            startupListPreliminaryEvaluation{
                count
                rows{
                    id
                    title
                    shortDescription
                    industry
                    logoFileId
                    backgroundImageId
                    views
                    likes
                    invest_sum
                    shared
                    ddFile
                    preliminary_evaluation

                }
            }
            fundList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    industry
                    logoFileId
                    backgroundFileId
                    fundSize
                }
            }
            acceleratorList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    industry
                    logoFileId
                    backgroundFileId
                    fundingUpTo
                    type
                    startupsTotal
                }
            }
            angelNetworkList{
                count
                rows{
                    id
                    title
                    shortDescription
                    location
                    currentTeamCount
                    fundSize
                    industry
                    logoFileId
                    backgroundFileId
                }
            }
            investorList{
                count
                rows{
                    id
                    role
                    professionalDescription
                    InvestorProfile{
                        industry
                        investmentSize
                        investmentsCount
                    }
                    User{
                        firstName
                        lastName
                        logoFileId
                        backgroundImageId
                        country
                    }
                }
            }
            expertList{
                count
                rows{
                    id
                    role
                    professionalIdentification
                    professionalDescription
                    twitterLink
                    facebookLink
                    linkedInLink
                    mediumLink
                    expertRoles
                    User{
                        firstName
                        lastName
                        logoFileId
                        backgroundImageId
                        country
                    }
                    parameters
                }
            }
            locationList
        }
    }
`;


const GET_HISTORY_SEARCH = gql`
    query historySearch($type: String){
        historySearch(type: $type){
            n
            type
            params
        }
    }
`;


const POST_HISTORY_SEARCH = gql`
    mutation saveHistorySearch($type: String $params: JSON){
        saveHistorySearch(type: $type params:$params){
            n
            type
            params
        }
    }
`;



const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

class Header extends React.Component {
    constructor(props) {
        super(props);

        let query = {};
        if(props.location){
            query = qs.parse(props.location.search, {ignoreQueryPrefix: true})
        }

        this.state = {
            mobileHeight:null,
            showModalLogin: false,
            showModalRegister: false,
            showModalForgotPassword: false,
            showModalChangePassword: false,
            showLeftMenu: false,
            path: 'funds',
            countUnreadMessages: 0,
            tempStatus: true,
            haveInfoMetamask: false,
            haveMetamask: false,
            haveAccount: false,
            metamaskSupported: false,
            networkSupported: false,
            haveBalance: false,
            network: 'checking network...',
            balanceEth: 0,
            balanceRock: 0,
            activeItemMenuMobile: '',
            openMenu: false,
            searchTabsArray:{
                0: {Name: 'location', show: false}
            },
            openedRole:'',
            searchValue: query.title || '',
            searchSettings:  {
                'industry':[],
                'location':[],
                'investment_round':[],
                'business_model':[],
                'evaluation_parameters':[],
                'type':[],
                'investor_type':[],
                'investment_size_range':[],
                'investment_size':[],
                ...query
            },
            iRange: null,
            aRange: null,
            listIndustries: [],
            evaluationParameters: [],
            progress: 4,
            step: 0,
            openSearch:false,
            hideRegLinks:false,
            isSearchPopupOpened: false
        };
    }

    componentDidMount() {
        this.searchFocus();
        this.lastScrollTop = window.scrollY;
        this.count = 0;

        window.addEventListener('scroll', e => this.handleScroll(e));


        if(/search/.test(history.location.pathname)){
            getListIndustries()
                .then((res) => {
                    // console.log(res.data)
                    this.setState({listIndustries: res.data})
                })
                .catch(error => {
                    console.log(error);
                    this.setState({loading: false})
                });

            getListParameters()
                .then((res) => {
                    if (Array.isArray(res.data)) this.setState({evaluationParameters: res.data})
                })
                .catch(err => {

                });

            if(this.props.openedRole !== undefined) {
                this.setOpenedRole(this.props.openedRole);
                this.searchOnFocus();
            }
            axios.get('/v2/default/investor_range').then(r => {
                this.setState({iRange: r.data});
            })

            axios.get('/v2/default/accelerator_range').then(r => {
                this.setState({aRange: r.data});
            })
        }

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(prevProps) {

        if (this.props.showLoginForm) {
            this.handleOpenModalLogin()
        }

        if (this.props.openedRole) {
            if (this.props.openedRole !== this.state.openedRole) {
                this.setOpenedRole(this.props.openedRole);
            }

            if(this.props.location && prevProps.location){
                let query = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
                let query2 = qs.parse(prevProps.location.search, {ignoreQueryPrefix: true});

                if( (query.title && query2.title && query.title !== query2.title) || query.title && !query2.title) {
                    this.setState({searchValue: query.title}, this.refetch);
                    if(this.props.checkSearch)
                        this.props.checkSearch(query.title);
                } else {
                    const newParams = {
                        'industry':[],
                        'location':[],
                        'investment_round':[],
                        'business_model':[],
                        'evaluation_parameters':[],
                        'type':[],
                        'investor_type':[],
                        'investment_size_range':[],
                        'investment_size':[],
                        ...query
                    };

                    if(!_.isEqual(this.state.searchSettings, newParams )){
                        this.setState({searchSettings:  newParams})
                    }
                }
            }
        }

        let pathname = window.location.pathname;
        if(/registration\/investor/.test(pathname)
            || /registration\/expert/.test(pathname)
            || /registration\/startup/.test(pathname)
            || /create\/accelerator/.test(pathname)
            || /start_accelerator/.test(pathname)
            || /investor_start/.test(pathname)
            || /create\/fund/.test(pathname)
            || /create\/angel_network/.test(pathname)

        ){
            if(this.props.project){
                if(/registration\/startup/.test(pathname)) {
                    if(!prevProps.project || ( this.fuckStep({}, this.props.project) !== this.state.step)){
                        this.beforeDraw(this.props.user, this.props.project)
                    }
                } else {

                    if(/create\/accelerator/.test(pathname)
                        || /start_accelerator/.test(pathname)
                        || /create\/fund/.test(pathname)
                        || /create\/angel_network/.test(pathname)){
                        if(!prevProps.project || (this.props.project.registrationStep !== this.state.step)){
                            this.beforeDraw(this.props.user, this.props.project)
                        }
                    }
                }
            }

            if(this.props.user){
                if( (( /registration\/expert/.test(pathname) && this.fuckStep(this.props.user)!== this.state.step))
                    || (( /registration\/investor/.test(pathname) && this.props.user.investor.registrationStep !== this.state.step))){
                    this.beforeDraw(this.props.user)
                }
            }
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return true;
    // }

    /*REGISTRATION*/
    beforeDraw = (user, project={}) => {
        let pathname = window.location.pathname,
            type = null,
            registrationStep,
            stepsCount = 24

        if (/investor/.test(pathname)) type = 1
        if (/expert/.test(pathname)) type = 2
        if (/startup/.test(pathname)) type = 3
        if (/accelerator/.test(pathname)) type = 6
        if (/start_accelerator/.test(pathname)) type = 6
        if (/fund/.test(pathname)) type = 4
        if (/angel_network/.test(pathname)) type = 5

        if(type){
            switch (type) {
                case 1:
                    registrationStep = user.investor.registrationStep;
                    stepsCount = 18
                    break;
                case 2:
                    registrationStep = user.expert.registrationStep;
                    stepsCount = 24
                    if(registrationStep === 3) registrationStep = 18
                    if(registrationStep === 6) registrationStep = 18
                    if(registrationStep === 25) registrationStep = 8
                    break;
                case 3:
                    registrationStep = project.registrationStep;
                    stepsCount = 22

                    if(registrationStep === 17) registrationStep = 3
                    if(registrationStep === 18) registrationStep = 6
                    if(registrationStep === 19) registrationStep = 7
                    if(registrationStep === 21) registrationStep = 16
                    if(registrationStep === 22) registrationStep = 17
                    if(registrationStep === 16) registrationStep = 21
                    if(registrationStep === 20) registrationStep = 22
                    break;
                case 4:
                case 5:
                case 6:
                    registrationStep = project.registrationStep;
                    stepsCount = 19;
                    break;
            }

            if(registrationStep) this.drawProgress(registrationStep, stepsCount+1)
        }
    }
    fuckStep = (user, project) => {
        let pathname = window.location.pathname,
            type = null,
            registrationStep

        if (/expert/.test(pathname)) type = 2
        if (/startup/.test(pathname)) type = 3

        if(type){
            switch (type) {
                case 2:
                    registrationStep = user.expert.registrationStep;
                    if(registrationStep === 3) registrationStep = 18
                    if(registrationStep === 6) registrationStep = 18
                    if(registrationStep === 25) registrationStep = 8
                    return registrationStep;
                case 3:
                    registrationStep = project.registrationStep;
                    if(registrationStep === 17) registrationStep = 3
                    if(registrationStep === 18) registrationStep = 6
                    if(registrationStep === 19) registrationStep = 7
                    if(registrationStep === 21) registrationStep = 16
                    if(registrationStep === 22) registrationStep = 17
                    if(registrationStep === 16) registrationStep = 21
                    if(registrationStep === 20) registrationStep = 22
                    return registrationStep;

                default: return null;
            }
        }
    }
    drawProgress = (step, stepsCount) => {
        if (!step) step = 1;
        let progress;
        progress = Math.floor((step+1) / stepsCount * 100)

        this.setState({progress, step})
    }
    /*/REGISTRATION*/

    /*AUTHORIZE*/
    logout = () => {
        localStorage.removeItem('RocketToken');
        localStorage.removeItem("commandProps");
        localStorage.removeItem("rFrom");
        localStorage.removeItem("referer");
        localStorage.removeItem("referer_startup");
        window.location.href = '/';
    };
    goToLogin = () => {
        localStorage.removeItem('referer');
        localStorage.removeItem('referer_startup');
        localStorage.setItem('rFrom', window.location.pathname)
        history.push('/login')
    };

    // handleOpenModalLogin = () => {
    //     this.setState({showModalLogin: true});
    // }
    // handleOpenModalForgotPassword = () => {
    //     this.handleCloseModal()
    //     this.setState({showModalForgotPassword: true});
    // }
    // handleCloseModal = () => {
    //     this.setState({
    //         showModalLogin: false,
    //         showModalRegister: false,
    //         showModalForgotPassword: false,
    //         showModalChangePassword: false
    //     });
    //     if (this.props.resetItem) this.props.resetItem()
    // }
    // handleChangeModal = () => {
    //     this.setState({showModalLogin: !this.state.showModalLogin, showModalRegister: !this.state.showModalRegister});
    // }

    /*/AUTHORIZE*/

    /*MENU BUTTON*/
    openMenuNew = () => {
        try {
           this.props.updateMenu({type: 'open'});
            document.querySelector('.god-menu').classList.remove('god-close');

            if (document.documentElement.clientWidth > 756) {
               document.querySelector(`.logo-img`).style.height = '0px';
               document.querySelector(`.block-search-header-input`).style.left = '-85px';
           }
        } catch {}
    };
    // closeMenuNew = () => {
    //     this.setState({openMenu: false})
    //     try {
    //         if (document.documentElement.scrollWidth > 500) {
    //             $('.block-logo-new-top-menu-rocket-dao').css('display', 'inline-block');
    //         }
    //         // document.getElementsByClassName('block-logo-new-top-menu-rocket-dao')[0].style.display = 'block';
    //     } catch {}
    // };
    /*/MENU BUTTON*/

    /*SLIDE ANIMATION*/
    OpenContent(e, classBlock) {
        if (document.documentElement.scrollWidth > 500)
            $(e.currentTarget).children(classBlock).stop().slideDown(200);
    }
    CloseContent(e, classBlock) {
        if (document.documentElement.scrollWidth > 500)
            $(e.currentTarget).children(classBlock).stop().slideUp(200);
    }
    OpenCloseContent(e, classBlock) {
        if (document.documentElement.scrollWidth < 500)
            $(e.currentTarget).children(classBlock).stop().slideToggle(200);
    }
    /*/SLIDE ANIMATION*/

    /*SEARCH TABS*/
    onTabClick(key) {
        this.setState({
            searchTabsArray: {
                ...this.state.searchTabsArray,
                [key]: {...this.state.searchTabsArray[key], show: !this.state.searchTabsArray[key].show}
            }
        });
        this.setState({
            isSearchPopupOpened: true
        });

        var scrollX = window.scrollX;
        var scrollY = window.scrollY;
        window.onscroll = function () { window.scrollTo(scrollX, scrollY); };
    }
    closePopup(key) {
        if (openPopupCount > 0) {
            this.onTabClick(key);
        }
        // $('body').removeClass('stop-scrolling');
        window.onscroll = function () { return true };
        openPopupCount = 1;
        this.setState({
            isSearchPopupOpened: false
        });
    }
    showTabsList () {
        return Object.keys(this.state.searchTabsArray).map((item, key) => {
                let thisItem = this.state.searchTabsArray[key];
                return (
                    <TabsCounter
                        key={key}
                        TitleTab={this.state.searchSettings[thisItem.Name].length === 0 ? thisItem.Name.replace(/_/g, ' '): thisItem.Name.replace(/_/g, ' ') +' - '+this.state.searchSettings[thisItem.Name].length}
                        TypeTab={'small'}
                        EventOnClick={() => {this.onTabClick(key)}}
                        active={this.state.searchSettings[thisItem.Name].length !== 0}
                        count={0}
                    />
                )
            }
        );
    }
    handleScroll(e) {
        if  (document.documentElement.scrollWidth < 465) {
            const window = e.currentTarget;

            let pathname = window.location.pathname;
            let pageYOffset = window.pageYOffset;

            if (/search/.test(pathname) && document.querySelector('.search-mobile-cancel-button').style.display === 'none') {
                if (pageYOffset >= 220 && this.lastScrollTop < window.scrollY) {
                    this.count++;
                    if (this.count >= 10) {
                        this.count = 0;
                        $('.search-line-wrapper').slideUp(150);
                    }

                }
                if (this.lastScrollTop > window.scrollY) {
                    this.count--;
                    if (this.count <= -15) {
                        this.count = 0;
                        $('.search-line-wrapper').slideDown(150, () => {
                            $('.search-line-wrapper').height("auto")
                        });
                    }
                }
                this.lastScrollTop = pageYOffset <= 0 ? 0 : pageYOffset;
            }
            this.lastScrollTop = window.scrollY;
        }
    }
    /*/SEARCH TABS*/

    /*SEARCH*/
        /*SEARCH CLOSE*/
    hideSearch(e, THIS){
        try{
            if (!$(e.target).closest(".block-search-header-input").length && $('.search-header-open-list').css('display') === 'block' && $(e.target)[0]['classList'][0] !== 'input-close-icon') {
                if(!$('.block-search-header-input .block-new-input input').is(':focus') || e.type === 'touchend'){
                    $('.search-header-open-list').stop().slideUp(200, ()=>{
                        $('.search-header-open-list').height('auto');
                    });

                    generateEvent(this.getEventName(), {
                        ...(() => {
                            if(this.getRealRole()){
                                return{
                                    [`${this.getRealRole()}_result`]: 'close'
                                }
                            }
                        })()

                    });

                    /*mobile*/
                    let clientWidth = document.documentElement.scrollWidth;
                    if (clientWidth < 465) {

                        let newWidth = $('.block-logo-new-top-menu-rocket-dao').width() - 60;
                        $('.block-logo-new-top-menu-rocket-dao').css('padding', '0 10px');
                        $('.block-search-header-input').animate({
                            width: newWidth,
                            paddingLeft: '0'
                        }, {
                            duration: 200, complete: () => {
                                $('.block-search-header-input').width("calc(100% - 60px)");
                            }
                        });
                        $('.block-button-hamburger-new').show(400);
                        $('.block-logo-new-top-menu-rocket-dao').show(400);
                        // $("body").removeClass("iosBugFixCaret");
                    }
                    $('.search-mobile-cancel-button').hide(100);

                    if (clientWidth > 465) {
                        setTimeout(()=>{
                            this.setState({
                                openSearch:false
                            });

                            setTimeout(()=>{
                                this.setState({
                                    hideRegLinks:false
                                });
                            }, 200)
                        }, 180);
                    }


                }

            }

            if (!$(e.target).closest(".block-button-hamburger-new").length &&
                !$(e.target).closest(".god-menu").length &&
                !$(e.target).closest(".sub-menu").length) {

                if( /search/.test(history.location.pathname) || history.location.pathname === '/' ){
                    try{
                        THIS.props.updateMenu({type: 'close'})
                    } catch (e) {

                    }
                }

            }
        } catch (e) {

        }
    }
        /*SEARCH OPEN*/
    searchFocus() {
        const THIS = this;

        $('.block-search-header-input .block-new-input input').on('click', (e) => {
            let clientWith = document.documentElement.scrollWidth;
            if (clientWith > 465) {
                this.setState({
                    openSearch:true,
                    hideRegLinks:true
                });
            }

            setTimeout(()=>{
                e.stopImmediatePropagation();

                if(this.refetchSearch){
                    this.refetchSearch();
                }

                $('.search-header-open-list').stop().slideDown(200);
            }, 200);

            /*mobile*/
            if (clientWith < 465) {
                $('.block-logo-new-top-menu-rocket-dao').css('padding', '0');
                $('.block-search-header-input').animate({
                    width: '100%',
                    paddingLeft: '10px'
                }, 400);
                $('.block-button-hamburger-new').hide(200);
                $('.block-logo-new-top-menu-rocket-dao').hide(200);
                $('.search-mobile-cancel-button').fadeIn(500).css('display', 'inline-block');
                // $("body").addClass("iosBugFixCaret");
            }

            return false;
        });


        if(this.props.location && this.props.location.state){

            if(!this.props.location.state.noOpen){
                if(THIS.state.openedRole || this.props.openedRole)
                    $('.block-search-header-input .block-new-input input').click();
            }

        } else {
            if(THIS.state.openedRole || this.props.openedRole)
                $('.block-search-header-input .block-new-input input').click();
        }




        let documentClick = true;
        $(document).on('touchstart', (e)=>{
            documentClick = true;
        });
        $(document).on('touchmove', (e)=>{
            documentClick = false;
        });
        $(document).on('click', (e)=>{
            this.hideSearch(e, THIS);
        });
        $('.search-mobile-cancel-button').on('touchend', (e)=>{
            if (documentClick) this.hideSearch(e, THIS);
        });

        $('.search-header-open-list').on('click', (e) => {
            $('.block-search-header-input .block-new-input input').focus();
        }).children().click(function(e) {
            if($(e.target).hasClass('search-among-button') || $(e.target).hasClass('resent-search-block')){
                return true;
            } else {
                $('.block-search-header-input .block-new-input input').focus();
            }

        });
    }

    searchOnFocus(e){

        if(!this.state.searchValue.length && this.props.checkSearch){
            this.props.checkSearch(false);
        }

        $('.block-search-header-input .block-new-input input').focus();
    }
    setOpenedRole(type){
        this.setState({
            openedRole:type
        }, this.refetchSearch);

        if(this.props.setSearchRole)
            this.props.setSearchRole(type);


        switch(type) {
            case 'all_startups':
            case 'startup_dd':
            case 'startup_pe':
            case 'startup_popular':
            case 'startup': {
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'industry', show: false},
                        1: {Name: 'location', show: false},
                        2: {Name: 'investment_round', show: false},
                        3: {Name: 'business_model', show: false}
                    }
                });
                break;
            }
            case 'expert': {
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'location', show: false},
                        1: {Name: 'evaluation_parameters', show: false}
                    }
                });
                break;
            }


            case 'investor': {
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'industry', show: false},
                        1: {Name: 'location', show: false},
                        2: {Name: 'investment_size', show: false},
                        3: {Name: 'business_model', show: false},
                        4: {Name: 'investor_type', show: false},
                    }
                });
                break;
            }

            case 'fund':
            case 'angel_investor':
            case 'angel_network': {
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'industry', show: false},
                        1: {Name: 'location', show: false},
                        2: {Name: 'investment_size', show: false},
                        3: {Name: 'business_model', show: false},
                    }
                });
                break;
            }
            case 'accelerator': {
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'industry', show: false},
                        1: {Name: 'location', show: false},
                        2: {Name: 'type', show: false},
                        3: {Name: 'investment_size_range', show: false}
                    }
                });
                break;
            }
            default :{
                this.setState({
                    searchTabsArray:{
                        0: {Name: 'location', show: false}
                    }
                });
            }
        }

    }
    startSearch(type){
        if (history.location.pathname !== `/search/${type}`) {
            history.push(`/search/${type}`);
            this.setOpenedRole(type);
        }
        else {
            //this.setOpenedRole(type);
        }

        this.setState({searchSettings:{
                'industry':[],
                'location':[],
                'investment_round':[],
                'business_model':[],
                'evaluation_parameters':[],
                'type':[],
                'investor_type':[],
                'investment_size_range':[],
                'investment_size':[]
            }
        });

        // if (document.documentElement.scrollWidth < 465) {
        //     let newWidth = $('.block-logo-new-top-menu-rocket-dao').width() - 60;
        //
        //     $('.block-search-header-input').animate({
        //         width: newWidth,
        //         paddingLeft: '0'
        //     }, {
        //         duration: 200, complete: () => {
        //             $('.block-search-header-input').width("calc(100% - 60px)");
        //         }
        //     });
        // }

        window.scrollTo(0,0);
    }
    onChangeSearchValue(e){
        const list = document.getElementsByClassName('search-header-open-list');
        if(list[0] && list[0].style.display === 'none') {
            $('.search-header-open-list').stop().slideDown(200);
        }


        this.setState({searchValue:e.target.value.cleanWhitespace()}, () => {
            if(this.state.searchValue.length > 1 && this.refetch){
                this.refetch();
            }
        });

        if(this.props.checkSearch)
            this.props.checkSearch(e.target.value.cleanWhitespace());

        if(!e.target.value.length){
        }
    }
    getRealRole = () => {
        switch (this.state.openedRole){
            case 'all'      :
            case 'startup'    :
            case 'expert'    :
            case 'investor'  :
            case 'accelerator'  :
                return this.state.openedRole;
            case 'startup_dd':
            case 'startup_popular':
            case 'startup_pe':
                return 'startup';
            case 'fund':
            case 'angel_network':
                return 'investor';
            default:
                return ''
        }
    };
    getEventName = () => {
        return this.state.openedRole ? `${this.getRealRole()}_search` : 'start_search';
    };
    setSearchSettings(Name, CheckedArray){
        this.setState({searchSettings:{...this.state.searchSettings, [Name]:CheckedArray}})
        if(this.props.setNewParams)
            this.props.setNewParams({[Name.toLowerCase()]:CheckedArray})
    }
    searchPlaceholder = () => {
        switch (this.state.openedRole){
            case 'all'      :
            case 'startup'    :
            case 'expert'    :
            case 'investor'  :
            case 'accelerator'  :
                return this.state.openedRole.toUpperCase();
            case 'startup_dd':
            case 'startup_popular':
            case 'startup_pe':
                return 'startup'.toUpperCase();
            case 'fund':
            case 'angel_network':
                return 'investor'.toUpperCase();
            default:
                return ''
        }
    }
    checkShowAll = (data) => {
        let count = 0;

        if(data.search.startupList){
            count += data.search.startupList.count;
        }
        if(data.search.expertList){
            count +=  data.search.expertList.count;
        }
        if(data.search.fundList){
            count +=  data.search.fundList.count;
        }
        if(data.search.angelNetworkList){
            count +=  data.search.angelNetworkList.count;
        }
        if(data.search.investorList){
            count +=  data.search.investorList.count;
        }
        if(data.search.acceleratorList){
            count +=  data.search.acceleratorList.count;
        }
        return count > 3;
    }
    getRoleForSearch = (currentRole) => {
        switch (currentRole){
            case 'fund':
            case 'angel_network':
            case 'angel_investor':
                return 'investor';
            case 'all_startups':
            case 'startup_pe':
            case 'startup_dd':
            case 'startup_popular':
                return 'startup';
            default:
                return currentRole;
        }
    }
    getLimit(currentRole){
        switch (currentRole){
            case 'all':{
                return {
                    startup: 2,
                    fund: 2,
                    angel_network: 2,
                    angel_investor: 2,
                    expert: 2,
                    accelerator: 2
                }
            }

            case 'startup':{
                return {
                    startup: 3,
                }
            }

            case 'investor':{
                return {
                    fund: 3,
                    angel_network: 3,
                    angel_investor: 3,
                };
            }

            case 'expert':{
                return {
                    expert: 3
                }
            }

            case 'accelerator':{
                return {
                    accelerator: 3
                }
            }
        }
    }
    showResultsClick = ()=> {
        this._handleKeyDown({key: 'Enter'});
        setTimeout(()=>{
            $('.block-search-header-input.changed-shadow-box .block-new-input input').blur();
            if (document.documentElement.scrollWidth < 465) this.hideSearch({type:'touchend'}, this)
        }, 100);
    };
    resizeMobile = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.setState({
            mobileHeight:window.innerHeight
        })
    };
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setTimeout(()=> {
                $('.block-search-header-input.changed-shadow-box .block-new-input input').blur();
                this.hideSearch({type: 'touchend'}, this);
            }, 100);

            if(this.state.searchValue.length > 1){
                client.mutate({
                    variables: {
                        type: this.state.openedRole || 'all',
                        params: {title: this.state.searchValue}
                    },
                    mutation: POST_HISTORY_SEARCH
                })

                generateEvent(this.getEventName(), {
                    search_result: 'manual_input', //menu || reset

                    ...(() => {
                        if(this.getRealRole()){
                            return{
                                [`${this.getRealRole()}_result`]: 'manual_input'
                            }
                        }
                    })()

                });

                if(!this.state.openedRole){
                    history.push({pathname: `/search/all`, search: qs.stringify({title: this.state.searchValue})})
                }
                if(this.props.setNewParams)
                    this.props.setNewParams({title: this.state.searchValue});
            } else {
                if(!this.state.searchValue.length && this.props.setNewParams)
                    this.props.setNewParams({title: null});
            }
        }
    }
    searchRecentOpen(){
        return this.state.searchValue.length <= 1;
    }
    getLocationTabNum(){
        switch(this.state.openedRole){
            case'all':
            case'expert':
                return 0;

            case'investor':
            case'fund':
            case'angel_network':
            case'angel_investor':
            case'startup':
            case'startup_dd':
            case'startup_popular':
            case'startup_pe':
            case'all_startups':
            case'accelerator':
                return 1;
        }
    }
    showLocationPopup(){
        return (
            (this.state.openedRole === 'expert' && this.state.searchTabsArray[0].show) ||
            (this.state.openedRole === 'all' && this.state.searchTabsArray[0].show) ||
            (['startup', 'all_startups', 'startup_pe', 'startup_dd', 'startup_popular'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[1].show) ||
            (['investor', 'fund', 'angel_network', 'angel_investor'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[1].show) ||
            (this.state.openedRole === 'accelerator' && this.state.searchTabsArray[1].show)
        )
    }
    showIndustryPopup(){
        return (
            (['startup', 'all_startups', 'startup_pe', 'startup_dd', 'startup_popular'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[0].show) ||
            (['investor', 'fund', 'angel_network', 'angel_investor'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[0].show) ||
            (this.state.openedRole === 'accelerator' && this.state.searchTabsArray[0].show)
        )
    }
    /*/SEARCH*/

    getEditTitle = (type) => {
        switch(type) {
            case 'expert':
                return 'Edit expert profile';
            case 'startup':
                return 'Edit startup profile';
            case 'fund':
                return 'Edit fund profile';
            case 'accelerator':
                return 'Edit accelerator profile';
            case 'investor':
                return 'Edit investor profile';
            case 'angel_network':
                return 'Edit angel network profile';
            default:
                return '';
        }
    };

    render() {

        console.count('header');

        /*registrationView - enable registr header*/
        let {bgcColor, registrationView, onlyMenu, offSticky, project, EditHeaderTitle} = this.props;
        let user = this.props.user || {};

        let {openSearch,hideRegLinks} = this.state;

        /*Hide registered*/
        let showExpertBtn = true,
            showInvestorBtn = true;
        if (user.expert && user.expert.isFinished) showExpertBtn = false;
        if (user.investor && user.investor.isFinished) showInvestorBtn = false;
        /*/Hide registered*/

        /*Search*/
        let startupList = [],
            startupListScore  = [],
            startupListPreliminaryEvaluation = [],
            fundList = [],
            investorList = [],
            angelNetworkList = [],
            historyList = [],
            acceleratorList = [],
            expertList = [],
            locationList = [];
        /*/Search*/

        let clientWidth = document.documentElement.clientWidth;

        return (
            <React.Fragment>
                <div className="top-header-row">
                    <p className="top-header-title">Rocket DAO ecosystem</p>
                    <button className="top-header-menu-open-btn" type="button" aria-label="Open top menu"></button>
                    <button className="top-header-menu-close-btn" type="button" aria-label="Close top menu"></button>
                    <ul className="top-header-menu">
                        <li className="top-header-menu-elem active">
                            <a href="http://rocketdao.io/" className="top-header-menu-link"
                                data-popover="An online database of startups, investors, experts and accelerators.">rocketdao.io</a>
                        </li>
                        <li className="top-header-menu-elem">
                            <a href="http://startupjedi.vc/" target="_blank" className="top-header-menu-link"
                               data-popover="Media about venture capital, startups and product development.">Startup Jedi</a>
                        </li>
                        <li className="top-header-menu-elem">
                            <a href="http://foundersclub.vc/" target="_blank" className="top-header-menu-link"
                               data-popover="Founders community, here we help Pre-seed and Seed startups to get prepared for fundraising.">Founders Club</a>
                        </li>
                        <li className="top-header-menu-elem">
                            <a href="http://investclub.vc/" target="_blank" className="top-header-menu-link"
                               data-popover="International investment club of business angels and early-stage VC funds.">investclub.vc</a>
                        </li>
                        <li className="top-header-menu-elem">
                            <a className="top-header-menu-link"
                               data-popover="An early-stage VC fund.">Fund (soon)</a>
                        </li>
                    </ul>
                </div>

                <div key={'header'}
                 className={`sticky-header search-header-adaptive ${offSticky ? 'off-sticky' : ''} ${onlyMenu ? 'hide' : ''} ${registrationView?'registration-view':''}`}
                style={bgcColor?{background:bgcColor}:{}}>

                {registrationView && <Row>
                    <div className="progress-bar col-12 p-0">
                        <div className='active-line' style={{width: `${this.state.progress}%`}}>
                            {/*<span>{this.state.progress}%</span>*/}
                        </div>
                    </div>
                </Row>}



                {!onlyMenu && <Row className='justify-content-between align-items-center'>

                    <div className='col-auto search-wrapper'>
                        <Row className='align-items-center'>
                            { (isMobile() ||  (!registrationView && !!localStorage.RocketToken)) &&
                                <div className="col-auto block-button-hamburger-new hide-highlight" onClick={() => this.openMenuNew()}>

                                </div>
                            }

                            <div className="col-auto logo-wrapper" onClick={this.ClickPersonMenu}>
                                <Row className='align-items-center logo-wrppaer-row'>
                                    <div className='col-auto block-logo-new-top-menu-rocket-dao'>
                                        <a href="/">
                                            <img src={RocketDAO} className='logo-img' alt=""/>
                                        </a>
                                    </div>

                                    {(!registrationView && !EditHeaderTitle) && <div className={`col block-search-header-input ${openSearch?'opened':''} ${this.state.searchValue.length <= 0?'':'changed-shadow-box'}`}>
                                        <Input
                                            type={'text'}
                                            name={'search'}
                                            value={this.state.searchValue}
                                            onChange={(e) => {this.onChangeSearchValue(e)}}
                                            onKeyUp={this._handleKeyDown}
                                            placeholder={this.searchPlaceholder()}
                                            iconClass={'fa-search'}
                                            error={''}
                                        />

                                        {this.state.searchValue.length >= 1 && <div className='input-close-icon' onClick={(e)=>{
                                            this.setState({searchValue:''});
                                            generateEvent(this.getEventName(), {
                                                search_result: 'reset', //menu
                                                ...(() => {
                                                    if(this.getRealRole()){
                                                        return{
                                                            [`${this.getRealRole()}_result`]: 'reset'
                                                        }
                                                    }
                                                })()
                                            });
                                        }}/>}
                                        {/*{!this.searchRecentOpen() && <div className='input-enter-icon' onClick={() => this._handleKeyDown({key: 'Enter'})}/>}*/}

                                        <div className='search-header-open-list row-m0 no-gutters' style={{borderBottom:'none', height: 'auto'}}>
                                            {this.searchRecentOpen() &&
                                            <div className='search-recent-list'>
                                                <div className={`search-among-button search-among-text search-all-padding
                                                        ${this.state.openedRole==='' || this.state.openedRole==='all'?'active':''}`} onClick={()=>{generateEvent(this.getEventName(), {switch_category: 'all'}); this.startSearch('all')}}>All</div>
                                                <div className={`search-among-button search-among-text search-startup-padding
                                                        ${['startup', 'all_startups', 'startup_pe', 'startup_dd', 'startup_popular'].indexOf(this.state.openedRole) !== -1 ? 'active':''}`} onClick={()=>{generateEvent(this.getEventName(), {switch_category: 'startup'}); this.startSearch('startup')}}>Startup</div>
                                                <div className={`search-among-button search-among-text search-investor-padding
                                                        ${['investor', 'fund', 'angel_network', 'angel_investor'].indexOf(this.state.openedRole) !== -1 ? 'active':''}`} onClick={()=>{generateEvent(this.getEventName(), {switch_category: 'investor'}); this.startSearch('investor')}}>Investor</div>
                                                <div className={`search-among-button search-among-text search-expert-padding
                                                        ${this.state.openedRole==='expert'?'active':''}`} onClick={()=>{generateEvent(this.getEventName(), {switch_category: 'expert'}); this.startSearch('expert')}}>Expert</div>
                                                <div className={`search-among-button search-among-text search-accelerator-padding
                                                        ${this.state.openedRole==='accelerator'?'active':''}`} onClick={()=>{generateEvent(this.getEventName(), {switch_category: 'accelerator'}); this.startSearch('accelerator')}}>Accelerator</div>
                                                <div key={'111'}>
                                                    <Query query={GET_HISTORY_SEARCH} variables={{type: this.state.openedRole || 'all'}} client={client} fetchPolicy={'cache-first'}>
                                                        { ({loading, error, data, refetch, fetchMore}) => {
                                                            this.refetchSearch = refetch;
                                                            if (error) return null;
                                                            if (data && data.historySearch){
                                                                if(data.historySearch)
                                                                    historyList = data.historySearch.map((item, key) => {
                                                                        return (
                                                                            <ResentSearchBlock
                                                                                key={item.n}
                                                                                requestType={item.type}
                                                                                industryList={this.state.listIndustries}
                                                                                evaluationParameters={this.state.evaluationParameters}
                                                                                requestNum={item.n}
                                                                                parameters={item.params}
                                                                            />
                                                                        )
                                                                    })
                                                            }
                                                            return (
                                                                !!historyList.length && <React.Fragment>
                                                                    <div className='row-m0 resent-search-title font-weight-500 margin-top-15 margin-bottom-15'>Your resent search request</div>
                                                                    {historyList}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    </Query>
                                                </div>
                                            </div>}
                                            {!this.searchRecentOpen() &&
                                            <Query query={GET_SEARCH} variables={{type: this.getRoleForSearch(this.state.openedRole || 'all'), params: {title: this.state.searchValue}, limit: this.getLimit(this.getRoleForSearch(this.state.openedRole  || 'all'))}} client={client} fetchPolicy={'cache-first'}>
                                                { ({loading, error, data, refetch, fetchMore}) => {
                                                    this.refetch = refetch;
                                                    this.data = data;
                                                    if (error) return null;
                                                    if (data && data.search){
                                                        if(data.search.startupList)
                                                            startupList = data.search.startupList.rows.map((item, key) => {

                                                                const _avatar = item.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.title);

                                                                return (
                                                                    <div key={key} className='row-m0 searched-profile-to-list w-100 no-gutters' onClick={() =>
                                                                    {
                                                                        generateEvent(this.getEventName(), {
                                                                            search_result: 'menu',
                                                                            ...(() => {
                                                                                if(this.getRealRole()){
                                                                                    return{
                                                                                        [`${this.getRealRole()}_result`]: 'menu'
                                                                                    }
                                                                                }
                                                                            })()
                                                                        });
                                                                        history.push({pathname: `/startup/${item.id}`})
                                                                    }}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div className='row-m0 searched-profile-role-text w-100'>STARTUP</div>
                                                                            <div className='searched-profile-name-text w-100'>{item.title}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        if(data.search.expertList)
                                                            expertList = data.search.expertList.rows.map((item, key) => {
                                                                const _avatar = item.User.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.User.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.User.firstName);

                                                                return (
                                                                    <div key={key} className='row-m0 searched-profile-to-list w-100 no-gutters' onClick={() => { generateEvent(this.getEventName(), {search_result: 'menu'}); history.push({pathname: `/expert/${item.id}`})}}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div className='row-m0 searched-profile-role-text w-100'>EXPERT</div>
                                                                            <div className='searched-profile-name-text w-100'>{item.User.firstName} {item.User.lastName}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            });
                                                        if(data.search.fundList)
                                                            fundList = data.search.fundList.rows.map((item, key) => {

                                                                const _avatar = item.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.title);

                                                                return (
                                                                    <div key={key}
                                                                         className='row-m0 searched-profile-to-list w-100 no-gutters'
                                                                         onClick={() => {generateEvent(this.getEventName(), {search_result: 'menu'}); history.push({pathname: `/fund/${item.id}`})}}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div
                                                                                className='row-m0 searched-profile-role-text w-100'>FUND
                                                                            </div>
                                                                            <div
                                                                                className='searched-profile-name-text w-100'>{item.title}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            });
                                                        if(data.search.angelNetworkList)
                                                            angelNetworkList =  data.search.angelNetworkList.rows.map((item, key) => {

                                                                const _avatar = item.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.title);

                                                                return (
                                                                    <div key={key} className='row-m0 searched-profile-to-list w-100 no-gutters' onClick={() => {generateEvent(this.getEventName(), {search_result: 'menu'}); history.push({pathname: `/angel_network/${item.id}`})}}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div className='row-m0 searched-profile-role-text w-100'>ANGEL NETWORK</div>
                                                                            <div className='searched-profile-name-text w-100'>{item.title}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            });
                                                        if(data.search.investorList)
                                                            investorList = data.search.investorList.rows.map((item, key) => {
                                                                const _avatar = item.User.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.User.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.User.firstName);

                                                                return (
                                                                    <div key={key} className='row-m0 searched-profile-to-list w-100 no-gutters' onClick={() => {generateEvent(this.getEventName(), {search_result: 'menu'}); history.push({pathname: `/investor/${item.id}`})}}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div className='row-m0 searched-profile-role-text w-100'>ANGEL INVESTOR</div>
                                                                            <div className='searched-profile-name-text w-100'>{item.User.firstName} {item.User.lastName}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            });
                                                        if(data.search.acceleratorList)
                                                            acceleratorList = data.search.acceleratorList.rows.map((item, key) => {

                                                                const _avatar = item.logoFileId ?
                                                                    <div className="col searched-profile-avatar"
                                                                         style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view`})`}}/>
                                                                    :
                                                                    createAvatar('col searched-profile-avatar', item.title);

                                                                return (
                                                                    <div key={key} className='row-m0 searched-profile-to-list w-100 no-gutters' onClick={() => {
                                                                        generateEvent(this.getEventName(), {search_result: 'menu'});
                                                                        history.push({pathname: `/accelerator/${item.id}`})
                                                                    }}>
                                                                        {_avatar}
                                                                        <div className='col'>
                                                                            <div className='row-m0 searched-profile-role-text w-100'>ACCELERATOR</div>
                                                                            <div className='searched-profile-name-text w-100'>{item.title}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            });
                                                        if(data.search.locationList)
                                                            locationList = [...new Set(data.search.locationList)].map((location, key) => {
                                                                return (
                                                                    !!location && !!location.length && <div className='row-m0 searched-location-to-list w-100 no-gutters' key={key} onClick={() => {
                                                                        generateEvent(this.getEventName(), {search_result: 'menu'});
                                                                        history.push({pathname: `/search/${this.state.openedRole || 'all'}`, search: qs.stringify({location: [location]})})

                                                                    }}>
                                                                        <div className='col location-icon'/>
                                                                        <div className='col location-name'>{location}</div>
                                                                    </div>
                                                                )
                                                            })
                                                    }
                                                    return (
                                                        <React.Fragment>
                                                            <div className='search-points-list'>
                                                                {startupList}
                                                                {expertList}
                                                                {investorList}
                                                                {fundList}
                                                                {angelNetworkList}
                                                                {acceleratorList}
                                                                {locationList}

                                                                {!startupList.length &&
                                                                !startupListScore.length &&
                                                                !startupListPreliminaryEvaluation.length &&
                                                                !fundList.length &&
                                                                !investorList.length &&
                                                                !angelNetworkList.length &&
                                                                !expertList.length &&
                                                                !acceleratorList.length &&
                                                                !locationList.length &&
                                                                <div className='error-not-found-text'>
                                                                    <span>Sorry, we couldn't find any matches for your requests.</span><br/>
                                                                    Try searching something related.
                                                                </div>
                                                                ||
                                                                this.checkShowAll(data) && <div className='row-m0 text-show-all margin-top-12 w-100' onClick={() => {this.showResultsClick()}}>Show all results</div>
                                                                }
                                                            </div>

                                                        </React.Fragment>
                                                    )
                                                }}
                                            </Query>
                                            }
                                        </div>

                                    </div>}

                                    {(!registrationView && EditHeaderTitle) &&
                                        <div className='col edit-profile-title'>
                                            {this.getEditTitle(EditHeaderTitle)}
                                        </div>
                                    }
                                </Row>

                            </div>
                            <div className='search-mobile-cancel-button' onTouchStart={()=>{ $('.block-search-header-input .block-new-input input').blur() }}>Сancel</div>

                        </Row>
                    </div>

                    <div className={`col temp-links-header ${hideRegLinks?'hide':''}`} style={registrationView ? {display: 'flex'} : {}}>
                        {!registrationView &&
                        <React.Fragment>
                            <div className={`header-reg-links ${hideRegLinks?'hide':''} ${showInvestorBtn?'investor':'no-investor'} ${showExpertBtn?'expert':'no-expert'}`}>
                                <Link to="/start_reg?type=3" className={'no-active col-auto'} onClick={() => {
                                    generateEvent('start_registration', {role: 'startup', source: 'header'})
                                }}>Create startup</Link>
                                {showInvestorBtn &&
                                <Link to="/start_reg?type=6" className={'no-active col-auto'} onClick={() => {
                                    generateEvent('start_registration', {role: 'investor', source: 'header'})
                                }}>Become investor</Link>
                                }
                                {showExpertBtn &&
                                <Link to="/start_reg?type=2" className={'no-active col-auto'} onClick={() => {
                                    generateEvent('start_registration', {role: 'expert', source: 'header'})
                                }}>Join experts</Link>
                                }
                                <Link to="/start_reg?type=7" className={'no-active col-auto'} onClick={() => {
                                    generateEvent('start_registration', {role: 'accelerator', source: 'header'})
                                }}>Add accelerator</Link>
                            </div>
                        </React.Fragment>
                        }

                        <Row className={`about-wrapper ${hideRegLinks?'hide':''} align-items-center`}>
                            {/*{!registrationView && <div className='col-auto'>*/}
                                {/*<Row className='align-items-center about-container'>*/}
                                    {/*<div className='col-auto header-title-about'*/}
                                         {/*onMouseEnter={(e) => this.OpenContent(e, '.open-close-header-block')}*/}
                                         {/*onMouseLeave={(e) => {*/}
                                             {/*this.CloseContent(e, '.open-close-header-block')*/}
                                         {/*}}*/}
                                         {/*onClick={(e) => {*/}
                                             {/*this.OpenCloseContent(e, '.open-close-header-block')*/}
                                         {/*}}*/}
                                    {/*>*/}
                                        {/*About*/}
                                        {/*<div className={`open-close-header-block ${(user && user.id)?'position-left':''}`}>*/}
                                            {/*<div className='header-subtitle-about'><Link to="/about"*/}
                                                                                         {/*className={(this.state.path == 'about' ? 'active' : 'no-active')}*/}
                                                                                         {/*onClick={() => {*/}
                                                                                             {/*window.scrollTo(0, 0)*/}
                                                                                         {/*}}>About the platform</Link></div>*/}
                                            {/*<div className='header-subtitle-about'><Link to="/evaluation/landing/startup"*/}
                                                                                         {/*onClick={() => {*/}
                                                                                             {/*window.scrollTo(0, 0)*/}
                                                                                         {/*}}>Evaluation of startups</Link></div>*/}

                                            {/*<div className='header-subtitle-about'><Link to="/evaluation/landing/investor"*/}
                                                                                         {/*onClick={() => {*/}
                                                                                             {/*window.scrollTo(0, 0)*/}
                                                                                         {/*}}>Evaluation for investors</Link>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}

                                    {/*<a className='col-auto blog-link' href="https://startupjedi.vc/" target="_blank">Blog</a>*/}
                                {/*</Row>*/}
                            {/*</div>*/}
                            {/*}*/}

                            {!registrationView && !user.id &&
                            <div className="col-auto shell-person-name-photo-new-top-menu-rocket-dao">
                                <div className="right-no-reg-user">
                                    <span className='btn-login button-new-login'
                                          onClick={() => {
                                              generateEvent('start_login', {source: 'header'});
                                              this.goToLogin()
                                          }}>Login</span>
                                </div>
                            </div>
                            }
                        </Row>

                        {user && user.id && registrationView &&
                        <div className="col-auto shell-person-name-photo-new-top-menu-rocket-dao">
                            <div className="right-no-reg-user" style={registrationView ? {margin: '5px 0 0 0'} : {}}>
                                {registrationView && <div className="block-person-photo-new-top-menu-rocket-dao"
                                                               style={registrationView ? {display: 'flex'} : {margin: '1px 0 1px 0'}}>
                                    <p style={{
                                        fontWeight: 400,
                                        padding: 0,
                                        margin: '0 0 0 0',
                                        textAlign: 'right',
                                        fontSize: '14px',
                                        color: 'rgba(0,0,0,0.8)'
                                    }}>{(user.firstName || '') + ' ' + (user.lastName || '')}</p>
                                    <p style={{
                                        fontWeight: 400,
                                        padding: 0,
                                        margin: '0 0 0 0',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        color: '#525252',
                                        textAlign: 'right',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        All changes saved, <span className={'continue-later'} onClick={() => history.push('/')}>you may continue later</span>
                                    </p>

                                </div>}
                                { !registrationView && <span className='btn-login button-new-login' onClick={this.logout}>Logout</span> }
                            </div>
                        </div>
                        }
                    </div>

                    {/*{this.state.showModalForgotPassword &&*/}
                    {/*<ModalForgotPassword showModal={this.state.showModalForgotPassword}*/}
                    {/*                     handleCloseModal={this.handleCloseModal} history={this.state.history}/>}*/}
                    {/*{this.state.showModalChangePassword &&*/}
                    {/*<ModalChangePassword showModal={this.state.showModalChangePassword}*/}
                    {/*                     handleCloseModal={this.handleCloseModal} history={this.state.history}*/}
                    {/*                     user={user}/>}*/}



                </Row>}

                <div className='search-line-wrapper'>
                    <Container className='search-line'>
                        {this.showTabsList()}
                    </Container>
                    {this.showIndustryPopup() &&
                    <IndustryPopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        onScroll={this.onScroll}
                        TabNum={0}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {this.showLocationPopup() &&
                    <LocationPopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={this.getLocationTabNum()}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {['startup', 'all_startups', 'startup_pe', 'startup_dd'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[2].show &&
                    <IModelPopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={2}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {['startup', 'all_startups', 'startup_pe', 'startup_dd', 'investor', 'fund', 'angel_network', 'angel_investor'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[3].show &&
                    <BModelPopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={3}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {this.state.openedRole === 'expert' && this.state.searchTabsArray[1].show &&
                    <EvalParamPopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={1}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {this.state.openedRole === 'investor' && this.state.searchTabsArray[4].show &&
                    <InvestorTypePopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={4}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {this.state.openedRole === 'accelerator' && this.state.searchTabsArray[2].show &&
                    <AccelTypePopup
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={2}
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }

                    {this.state.openedRole === 'accelerator' && this.state.searchTabsArray[3].show &&
                    <AccelInvSize
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={3}
                        NumberOfProfiles={this.state.aRange.between}  //кол-во карманов: Math.ceil((MaxSize-MinSize)/50000)
                        MinSize={this.state.aRange.min} //кратно 50000; 175k => 150k
                        MaxSize={this.state.aRange.max} //кратно 50000; 175k => 200k
                        pocketSize={this.state.aRange.step} //размер кармана
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                    {['investor', 'fund', 'angel_network', 'angel_investor'].indexOf(this.state.openedRole) !== -1 && this.state.searchTabsArray[2].show && this.state.iRange &&
                    <InvestorInvSize
                        handleClick={(key)=>{this.closePopup(key)}}
                        TabNum={2}
                        NumberOfProfiles={this.state.iRange.between}  //кол-во карманов: Math.ceil((MaxSize-MinSize)/50000)
                        MinSize={this.state.iRange.min} //кратно 50000; 175k => 150k
                        MaxSize={this.state.iRange.max} //кратно 50000; 175k => 200k
                        pocketSize={this.state.iRange.step} //размер кармана
                        searchSettings={this.state.searchSettings}
                        setSearchSettings={(Name, CheckedArray) => {this.setSearchSettings(Name, CheckedArray)}}
                        resizeMobile={()=>{this.resizeMobile()}}
                        mobileHeight={this.state.mobileHeight}
                    />
                    }
                </div>
            </div>
            <GodMenu/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

Header.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    updateMenu: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin, updateMenu})(Header)
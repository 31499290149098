import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";
import history from "../../../history";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Input from "../../controls3.0/input/Input";
import AvatarImg from './../../controls3.0/AvatarImg'
import {getLastProject, updateProject} from "../../../actions/Project";
import TextArea from "../../v3/Textarea";

class Step12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            errors: {}
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace();
        value = value.slice(0, 600)
        this.setState({project: {...this.state.project, [name]: value}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            problem: project.problem ? project.problem.trim() : '',
            solution: project.solution ? project.solution.trim() : ''
        }
        obj.event_type = 'startup_problem_solution_13';

        if(nextStep === 13 && onlyStep){
            obj.event_data = {
                skip: true
            }
        }

        if(onlyStep){
            obj = {
                id: project.id
            }
        }

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {project, errors} = this.state;

        return (
            <React.Fragment>
                <div className="container-full-reg step-12 is-startup">
                    <div className="wrap-white">
                        <p className="text-h1 color-text-reg-gray">
                            The problem, opportunity & solution
                        </p>
                        {/*<p className="text-h6">*/}
                            {/*Please describe the problem you are solving with your product.*/}
                            {/*Why do you think there is an opportunity to successfully solve it?*/}
                            {/*<br/><br/>*/}

                            {/*What is the solution to it? Why do you believe it is better than the alternatives that already exist on the market?*/}
                        {/*</p>*/}
                    </div>

                    <div className="container-bottom padding-reg" style={{position:'relative'}}>
                         <div className="step-16-row clearfix">
                             <div className="new-col c-2 new-pad-25-right new-width-100-down-900 new-no-padding-down-900">
                                 <div style={{marginTop: '30px'}}>
                                     <div className='text-h5 s16-subtitle color-text-reg-gray'>Problem</div>
                                     <div className='s16-subdescr color-text-reg-gray'>
                                         Please describe the problem you are solving with your product.
                                         Why do you think there is an opportunity to successfully solve it?
                                     </div>
                                     <TextArea name={'problem'} val={this.state.project.problem}
                                               onChange={this.onChange} placeholder='Not more than 600 symbols'
                                               defaultHeight={267}
                                               widthClass={'container-full-reg step-12 is-startup'}
                                               widthClass2={'new-col c-2 new-pad-25-right new-width-100-down-900 new-no-padding-down-900'}
                                               noPopUp={true}
                                               error={errors.problem}
                                     />
                                 </div>
                             </div>
                             <div className="new-col c-2 new-pad-25-left new-width-100-down-900 new-no-padding-down-900">
                                 <div style={{marginTop: '30px'}}>
                                     <div className='text-h5 s16-subtitle color-text-reg-gray'>Solution</div>
                                     <div className='s16-subdescr color-text-reg-gray'>
                                         What is the proposed solution to the problem? Why do you believe it is better than
                                         the alternatives that already exist on the market?
                                     </div>
                                     <TextArea name={'solution'} val={this.state.project.solution}
                                               onChange={this.onChange} placeholder='Not more than 600 symbols'
                                               defaultHeight={267}
                                               widthClass={'container-full-reg step-12 is-startup'}
                                               widthClass2={'new-col c-2 new-pad-25-right new-width-100-down-900 new-no-padding-down-900'}
                                               noPopUp={true}
                                               error={errors.solution}
                                     />

                                     <div className="block-text-area-form-add-edit-modal-reg-expert bta-10">


                                         {/*<textarea*/}
                                             {/*name="solution"*/}
                                             {/*value={project.solution}*/}
                                             {/*onChange={this.onChange}*/}
                                             {/*error={errors.solution}*/}
                                             {/*placeholder='Not more than 600 symbols'*/}
                                         {/*/>*/}
                                     </div>
                                 </div>
                             </div>
                         </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(11, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(13)}
                                paddingButton={'54'}
                                disabled={!project.problem || !project.solution}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step12.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step12)


import React from 'react';
import "./style.css"

import Routes from './routes'


import GuestRoute from "../routes/GuestRoute";
import {Link, Route, Switch} from 'react-router-dom'
import Header from "../header";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {logout, tokenLogin, updateUser} from "../../actions/Login";

class index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDoc: this.props.document ? this.props.document : 'privacy'
        };
    }

    handleClick(e) {
        this.setState({
            activeDoc: e,
        });
    }

    render() {
        let user = this.props.user || {};
        // let activeDoc = this.state;
        // let document = <Privacy/>;
        //
        // if (activeDoc === 'rules') {
        //     document = <Rules/>
        // }
        // if (activeDoc === 'cookie') {
        //     document = <Cookie/>
        // }
        // if (activeDoc === 'risk') {
        //     document = <Risk/>
        // }
        // if (activeDoc === 'general') {
        //     document = <General/>
        // }
        let url = this.props.location['pathname'];
        // console.log(url)
        return (
            <div className={`index-document`}>
                <Header user={user} showLoginForm={this.state.showLoginForm}
                        resetItem={this.resetItem}/>



                <div className="container documents-container">

                    <div className="row-m0">
                        <div className="col-md-4 block-left-index-document">


                            <div className="block-list-item-index-document">

                                <div className={`block-item-index-document ${url === '/document/privacy-policy' || url === '/document' || url === '/document/'?'active':null}`}>
                                    <Link to="/document/privacy-policy">
                                        <p className="title-item-index-document">
                                            Privacy Policy
                                        </p>
                                    </Link>
                                </div>
                                <div className={`block-item-index-document ${url === '/document/rules-of-conduct'?'active':null}`}>
                                    <Link to="/document/rules-of-conduct">
                                        <p className="title-item-index-document">
                                            Rules of Conduct
                                        </p>
                                    </Link>
                                </div>
                                <div className={`block-item-index-document ${url === '/document/cookie-notice'?'active':null}`}>
                                    <Link to="/document/cookie-notice">
                                        <p className="title-item-index-document">
                                            Cookie Notice and / <br/>
                                            or HTML5 Local Storage
                                        </p>
                                    </Link>
                                </div>
                                <div className={`block-item-index-document ${url === '/document/risk-warning'?'active':null}`}>
                                    <Link to="/document/risk-warning">
                                        <p className="title-item-index-document">
                                            Risk warning
                                        </p>
                                    </Link>
                                </div>
                                <div className={`block-item-index-document ${url === '/document/general-terms'?'active':null}`}>
                                    <Link to="/document/general-terms">
                                        <p className="title-item-index-document">
                                            General Terms and Conditions of Use
                                        </p>
                                    </Link>
                                </div>


                            </div>
                            <div className="block-shadow-gray-document">

                            </div>
                        </div>
                        <div className="col-md-8 block-right-index-document">
                            <Routes/>
                        </div>
                    </div>

                </div>



                {/*<div className="block-left-index-document">*/}

                {/*</div>*/}
                {/*<div className="block-right-index-document">*/}
                {/*    */}
                {/*    /!*{document}*!/*/}
                {/*</div>*/}
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.auth.user
})

index.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser, logout})(index)
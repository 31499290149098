import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonGreen'
import _bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import './style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import Loader from '../test-controls/preloader/preloader'
import axios from "axios";
import {getAchievementExpert, tokenLogin} from "../../actions/Login";
import EditMyPageButton from  '../v3/editMyPage';


import {Comments} from '../v3/comments';
import {createAvatar} from "../user/createAvatar";
import MetaTags from "react-meta-tags";
import history from '../../history';
import {gql} from "apollo-boost/lib/index";
import ApolloClient from "apollo-boost/lib/index";
import config from "../../config";
import {Query} from 'react-apollo';

import {getShortLang} from "../../utils/helper";
import {UserLang} from "../v3/userLang";

Date.prototype.toShortFormat = function() {

    const month_names =["Jan","Feb","Mar",
        "Apr","May","Jun",
        "Jul","Aug","Sep",
        "Oct","Nov","Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
}

const GET_USER = gql`
    query userProfile($ident: Int){
        userProfile(ident: $ident){
            lastName
            firstName
            logoFileId
            country
            lang
            Skills{
                title
            }
            experience
            createdAt
            twitterLink
            facebookLink
            facebookLink
            mediumLink
            telegramLink
            instagramLink
            link
            owner{
                startupList{
                    rows{
                        id
                        title
                        logoFileId
                    }
                }
                fundList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                    }
                }
                angelNetworkList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                    }
                }
                acceleratorList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                        team{
                            isFounder
                            position
                        }
                    }
                }
            }
            members{
                startupList{
                    rows{
                        id
                        title
                        logoFileId
                        team{
                            isFounder
                            position
                        }
                    }
                }
                fundList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                    }
                }
                angelNetworkList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                    }
                }
                acceleratorList{
                    rows{
                        id
                        title
                        logoFileId
                        location
                        team{
                            isFounder
                            position
                        }
                    }
                }
            }
            
            investor{
                id
            }
            expert{
                id
            }
        }
    }
`;

const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expert: {},
            user: props.user || {},
            logo: avatar,
            listAchievements: [],
            bg: _bg,
            id: this.props.id || props.match.params.id,
            activeAchivements: '',
            showWork: false
        }
        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.onActiveAchivementsu = this.onActiveAchivementsu.bind(this);
    }

    componentWillReceiveProps(nextProps){
        return false;
    }

    componentDidUpdate(prevProps){
        return false;
    }



    OpenCloseContentClick(e, classBlock) {

        if('.open-close-block' === classBlock){
            this.setState({showWork: !this.state.showWork});
        }
        if ($(e.currentTarget).parent().children('block-right-work-experience').hasClass('active')){
            $(e.currentTarget).parent().children('block-right-work-experience').removeClass('active')
        }else {
            $(e.currentTarget).parent().children('block-right-work-experience').addClass('active')
        }
        $(e.currentTarget).parent().children(classBlock).toggle('normal');
    }

    onActiveAchivementsu(e) {
        this.setState({
            activeAchivements: e
        });
    }

    getImgAlt = (title) => {
        let replaced = title.replace(/ /gi, '_');
        return replaced;
    };



    render() {
        const {user} = this.props;

        const MemberItem = ({key, logoFileId, title, position, isFounder, type, id}) => {


            const onClick = () => {
                window.open(`/${type}/${id}`);
            }

            const _avatar = logoFileId ?
                <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${logoFileId}/view?W=200`})`}}>
                    <img src={`${config.proxy}/file/${logoFileId}/view?W=200`} alt={'user ' + this.getImgAlt(title)} />
                </div>
                :
                createAvatar('photo-cart-expert', title ? title : 'R');

            return (
                <div className='col-12 col-md-6 col-lg-4 user-member-item-wrapper'>
                    <div className='user-member-item' key={key} onClick={onClick}>
                        <div className='avatar'>{_avatar}</div>
                        <div className='name'>{title}</div>
                        <div className='descr'>{position}{isFounder ? ', Founder' : ''}</div>
                    </div>
                </div>
            )

        };

        return (
            <React.Fragment>

                <Query query={GET_USER} variables={{ident: parseInt(this.props.match.params.id)}} client={client} fetchPolicy={'cache-first'}>
                    { ({loading, error, data}) => {
                        if (error) return null;
                        if(loading) return <Loader isLocal={!!this.props.id}/>

                        if (data && data.userProfile){
                            const _avatar = (data.userProfile && data.userProfile.logoFileId) ?
                                <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${data.userProfile.logoFileId}/view?W=200`})`}}>
                                    <img src={`${config.proxy}/file/${data.userProfile.logoFileId}/view`} alt={data.userProfile.firstName + ' ' + data.userProfile.lastName} />
                                </div>
                                :
                                createAvatar('photo-cart-expert', data.userProfile ? data.userProfile.firstName : 'R');

                            return (
                                <div>
                                    <MetaTags>
                                        <title>{'User ' + this.getImgAlt(data.userProfile.firstName + ' ' + data.userProfile.lastName) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                                        <meta property="og:title" content={data.userProfile.firstName + ' ' + data.userProfile.lastName + ' - Rocket DAO - uniting startups, investors and experts'}/>
                                        <meta name="description" content={data.userProfile.professionalDescription} />
                                        <meta property="og:description" content={data.userProfile.professionalDescription}/>
                                    </MetaTags>

                                    <div className="block-background-expert" style={{background: '#D8D8D8'}}/>

                                    <div className="block-container-expert">
                                        <div className="block-box-shadow-cart-startup"/>

                                        <div className="block-cart-expert-mobile">
                                            <div className="block-top-cart-expert-mobile">
                                                <div className="block-avatar-name-top-cart-expert-mobile">
                                                    <div className="block-left-top-cart-expert-mobile">
                                                        {_avatar}
                                                    </div>
                                                    <div className="block-right-top-cart-expert-mobile">
                                                        <h1 className="title-top-cart-expert-mobile">
                                                            {data.userProfile.firstName} {data.userProfile.lastName}
                                                            <span className="check-box-cart investor">
                                                                <i className="fal fa-check"/>
                                                            </span>
                                                        </h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-expert-mobile">
                                                <div className="block-list-button-cart-expert-mobile accelerator-right-button-block">
                                                    {!!user && user.id === parseInt(this.state.id) &&
                                                    <EditMyPageButton type={'user'}/> ||  <ButtonGreen
                                                        TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                        EventOnClick={null}
                                                        paddingButton={'26'}
                                                        disabled={true}
                                                        onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                        onMouseLeave={() => this.setState({mouseEnter: false}) }
                                                    /> }
                                                </div>

                                                { (data.userProfile.country || data.userProfile.lang) &&
                                                <div className="block-list-state-cart-expert-mobile">
                                                    { data.userProfile.country &&
                                                        <div className="block-title-description-left-expert">
                                                            <p className="title-l">Location</p>
                                                            <p className="description-l">{data.userProfile.country}</p>
                                                        </div>
                                                    }
                                                    { data.userProfile.lang && Array.isArray(data.userProfile.lang) &&
                                                        <UserLang data={data.userProfile.lang || []}/>
                                                    }
                                                </div>
                                                }

                                                <div className="block-list-info-cart-expert-mobile">
                                                    { data.userProfile && data.userProfile.Skills && !!data.userProfile.Skills.length &&
                                                        <div className="block-skills-expert">
                                                            <p className="title-block-expert">
                                                                Professional skills
                                                            </p>
                                                            <p className="list-skills-expert">
                                                                {data.userProfile && data.userProfile.Skills && data.userProfile.Skills.map(skill => {
                                                                    return <span className="skill">{skill.title}</span>
                                                                })}
                                                            </p>
                                                        </div>
                                                    }

                                                    {data.userProfile && data.userProfile.experience && <div className="block-work-experience">
                                                        {/*<p className="title-block-expert">*/}
                                                        {/*Work experience*/}
                                                        {/*</p>*/}

                                                        {!!data.userProfile.experience.length ?
                                                            <div className="block-work-experience">
                                                                <p className="title-block-expert">
                                                                    Work experience
                                                                </p>

                                                                {
                                                                    data.userProfile.experience.slice(0, 3).map((exp => {
                                                                        return <div className="block-item-work-experience hide-highlight">
                                                                            <div style={{display: 'table', width: '100%'}}
                                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                                <div className="block-left-work-experience">
                                                                                    <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-center-work-experience">
                                                                                    <p className="description-work-experience">
                                                                                        <h2>{exp.position}</h2>
                                                                                        — <span
                                                                                        className="gray">{exp.company}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-right-work-experience">
                                                                                    <div
                                                                                        className="block-button-open-close-work-experience">
                                                                                        <i className="far fa-chevron-down"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="open-close-block-description-item-work-experience">
                                                                                <div
                                                                                    className="block-description-item-work-experience">
                                                                                    <div
                                                                                        className="block-left-work-experience">
                                                                                        <p className="title-work-experience">
                                                                                            {exp.city}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="block-center-work-experience">
                                                                                        {!!exp.companyLocation &&
                                                                                        <a href="" className="investor">
                                                                                            {exp.companyLocation}
                                                                                        </a>
                                                                                        }

                                                                                        <p className="description-work-experience">
                                                                                            {exp.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }))
                                                                }

                                                                {data.userProfile.experience.length > 3 ?
                                                                    <div className="1block-item-work-experience hide-highlight">
                                                                        <div className="open-close-block">
                                                                            {
                                                                                data.userProfile.experience.slice(3, data.userProfile.experience.length).map((exp => {
                                                                                    return <div
                                                                                        className="block-item-work-experience hide-highlight">
                                                                                        <div style={{
                                                                                            display: 'table',
                                                                                            width: '100%'
                                                                                        }}
                                                                                             onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                                            <div
                                                                                                className="block-left-work-experience">
                                                                                                <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className="block-center-work-experience">
                                                                                                <p className="description-work-experience">{exp.position} — <span
                                                                                                    className="gray">{exp.company}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div
                                                                                                className="block-right-work-experience">
                                                                                                <div
                                                                                                    className="block-button-open-close-work-experience">
                                                                                                    <i className="far fa-chevron-down"/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="open-close-block-description-item-work-experience">
                                                                                            <div
                                                                                                className="block-description-item-work-experience">
                                                                                                <div
                                                                                                    className="block-left-work-experience">
                                                                                                    <p className="title-work-experience">
                                                                                                        {exp.city}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="block-center-work-experience">
                                                                                                    {!!exp.companyLocation &&
                                                                                                    <a href=""
                                                                                                       className="investor">
                                                                                                        {exp.companyLocation}
                                                                                                    </a>
                                                                                                    }

                                                                                                    <p className="description-work-experience">
                                                                                                        {exp.description}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }))
                                                                            }
                                                                        </div>
                                                                        <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')}
                                                                           className="show-all-work-experience investor">
                                                                            {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                                            {!this.state.showWork ? `(+${data.userProfile.experience.length - 3})` : ''}
                                                                        </a>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div> : ''
                                                        }

                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>



                                        <div className="block-cart-expert">
                                            <div className="block-left-cart-expert">
                                                <div className="block-photo-cart-expert">
                                                    {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                                    {_avatar}
                                                </div>

                                                {data.userProfile.country && <div className="block-title-description-left-expert">
                                                    <p className="title-l">Location</p>
                                                    <p className="description-l">{data.userProfile.country}</p>
                                                </div>}

                                                { data.userProfile.lang && Array.isArray(data.userProfile.lang) &&
                                                <div className="block-title-description-left-expert">
                                                    <p className="title-l">Language</p>
                                                    <p className="description-l">{data.userProfile.lang.map(lang => getShortLang(lang)).join(', ')}</p>
                                                </div>
                                                }

                                            </div>
                                            <div className="block-right-cart-expert">
                                                <div className="block-info-cart-expert">
                                                    <p className="name-expert">
                                                        <h1>{data.userProfile.firstName} {data.userProfile.lastName}</h1>
                                                        <span
                                                            className="check-ok-expert investor"><i
                                                            className="fal fa-check"/></span>
                                                    </p>
                                                    { (!!data.userProfile.investor || !!data.userProfile.expert) &&
                                                        <p className="title-prof">
                                                            {!!data.userProfile.expert && <span className="round-prof expert">Expert</span>}
                                                            {!!data.userProfile.investor && <span className="round-prof investor">Investor</span>}
                                                        </p>
                                                    }
                                                    {data.userProfile.professionalDescription && <p className="description-expert">
                                                        {data.userProfile.professionalDescription}
                                                    </p>}

                                                    <div className='accelerator-right-button-block'>
                                                        {!!user && (user.id === parseInt(this.state.id)) &&
                                                        <EditMyPageButton type={'user'}/> ||  <ButtonGreen
                                                            TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                            EventOnClick={null}
                                                            paddingButton={'26'}
                                                            disabled={true}
                                                            onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                            onMouseLeave={() => this.setState({mouseEnter: false}) }
                                                        /> }
                                                    </div>

                                                </div>

                                                {data.userProfile && data.userProfile.Skills && !!data.userProfile.Skills.length &&
                                                    <div className="block-skills-expert">
                                                        <p className="title-block-expert">
                                                            Professional skills
                                                        </p>
                                                        <p className="list-skills-expert">
                                                            {data.userProfile.Skills.map(skill => {
                                                                return <span className="skill">{skill.title}</span>
                                                            })}
                                                        </p>
                                                    </div>
                                                }

                                                { data.userProfile && data.userProfile.experience && <div className="block-work-experience">
                                                    {/*<p className="title-block-expert">*/}
                                                    {/*Work experience*/}
                                                    {/*</p>*/}

                                                    {!!data.userProfile.experience.length ?
                                                        <div className="block-work-experience">
                                                            <p className="title-block-expert">
                                                                Work experience
                                                            </p>

                                                            {
                                                                data.userProfile.experience.slice(0,3).map((exp => {
                                                                    return <div className="block-item-work-experience hide-highlight">
                                                                        <div style={{display: 'table', width: '100%'}}
                                                                             onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                            <div className="block-left-work-experience">
                                                                                <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                            </div>
                                                                            <div className="block-center-work-experience">
                                                                                <p className="description-work-experience">
                                                                                    <h2>{exp.position}</h2>
                                                                                    — <span
                                                                                    className="gray">{exp.company}</span></p>
                                                                            </div>
                                                                            <div className="block-right-work-experience">
                                                                                <div
                                                                                    className="block-button-open-close-work-experience">
                                                                                    <i className="far fa-chevron-down"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="open-close-block-description-item-work-experience">
                                                                            <div className="block-description-item-work-experience">
                                                                                <div className="block-left-work-experience">
                                                                                    <p className="title-work-experience">
                                                                                        {exp.city}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="block-center-work-experience">
                                                                                    {!!exp.companyLocation &&
                                                                                    <a href="" className="investor">
                                                                                        {exp.companyLocation}
                                                                                    </a>
                                                                                    }

                                                                                    <p className="description-work-experience">
                                                                                        {exp.description}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }))
                                                            }

                                                            {data.userProfile.experience.length > 3 ?
                                                                <div className="1block-item-work-experience hide-highlight">
                                                                    <div className="open-close-block">
                                                                        {
                                                                            data.userProfile.experience.slice(3, data.userProfile.experience.length).map((exp => {
                                                                                return <div className="block-item-work-experience hide-highlight">
                                                                                    <div style={{display: 'table', width: '100%'}}
                                                                                         onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                                        <div className="block-left-work-experience">
                                                                                            <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                                        </div>
                                                                                        <div className="block-center-work-experience">
                                                                                            <p className="description-work-experience">{exp.position} — <span
                                                                                                className="gray">{exp.company}</span></p>
                                                                                        </div>
                                                                                        <div className="block-right-work-experience">
                                                                                            <div
                                                                                                className="block-button-open-close-work-experience">
                                                                                                <i className="far fa-chevron-down"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="open-close-block-description-item-work-experience">
                                                                                        <div className="block-description-item-work-experience">
                                                                                            <div className="block-left-work-experience">
                                                                                                <p className="title-work-experience">
                                                                                                    {exp.city}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="block-center-work-experience">
                                                                                                {!!exp.companyLocation &&
                                                                                                <a href="" className="investor">
                                                                                                    {exp.companyLocation}
                                                                                                </a>
                                                                                                }

                                                                                                <p className="description-work-experience">
                                                                                                    {exp.description}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }))
                                                                        }
                                                                    </div>
                                                                    <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')} className="show-all-work-experience investor">
                                                                        {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                                        {!this.state.showWork ? `(+${data.userProfile.experience.length-3})` : ''}
                                                                    </a>
                                                                </div>
                                                                : ''
                                                            }
                                                        </div> : ''
                                                    }

                                                </div> }



                                            </div>
                                        </div>

                                        { !!data.userProfile.owner && (
                                            !!data.userProfile.owner.startupList.rows.length ||
                                            !!data.userProfile.owner.fundList.rows.length ||
                                            !!data.userProfile.owner.angelNetworkList.rows.length ||
                                            !!data.userProfile.owner.acceleratorList.rows.length

                                        ) &&
                                        <div className='row-m0 user-seaction-wrapper margin-top-100 w-100'>
                                            <div className='row-m0 user-section-title w-100'>Owner of</div>

                                            { !!data.userProfile.owner.startupList.rows.length &&
                                            <React.Fragment>
                                                <div className='row-m0 margin-top-22 user-section-subtitle w-100'>Startups</div>
                                                {/*<div className='row-m0 w-100'>*/}
                                                {/*    {data.userProfile.owner.startupList.rows.map((i, k) =>*/}
                                                {/*        <MemberItem key={k} logoFileId={i.logoFileId}*/}
                                                {/*                    title={i.title} position={i.team.position}*/}
                                                {/*                    isFounder={i.team.isFounder} type={'startup'} id={i.id}/>)}*/}
                                                {/*</div>*/}
                                            </React.Fragment>
                                            }

                                            { !!data.userProfile.owner.angelNetworkList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Business
                                                    angel network
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.owner.angelNetworkList.rows.map((i, k) => <MemberItem
                                                        key={k} logoFileId={i.logoFileId} title={i.title}
                                                        position={i.location} type={'angel_network'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }

                                            {!!data.userProfile.owner.fundList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Funds
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.owner.fundList.rows.map((i, k) =>
                                                        <MemberItem key={k} logoFileId={i.logoFileId}
                                                                    title={i.title}
                                                                    position={i.location} type={'fund'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }
                                            {!!data.userProfile.owner.acceleratorList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Accelerators
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.owner.acceleratorList.rows.map((i, k) =>
                                                        <MemberItem key={k} logoFileId={i.logoFileId}
                                                                    title={i.title}
                                                                    position={i.team.position}
                                                                    isFounder={i.team.isFounder} type={'accelerator'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }
                                        </div>
                                        }


                                        { !!data.userProfile.members && (
                                            !!data.userProfile.members.startupList.rows.length ||
                                            !!data.userProfile.members.fundList.rows.length ||
                                            !!data.userProfile.members.angelNetworkList.rows.length ||
                                            !!data.userProfile.members.acceleratorList.rows.length

                                        ) &&
                                        <div className='row-m0 user-seaction-wrapper margin-top-100 w-100'>
                                            <div className='row-m0 user-section-title w-100'>Member of</div>

                                            { !!data.userProfile.members.startupList.rows.length &&
                                            <React.Fragment>
                                                <div className='row-m0 margin-top-22 user-section-subtitle w-100'>Startups</div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.members.startupList.rows.map((i, k) =>
                                                        <MemberItem key={k} logoFileId={i.logoFileId}
                                                                    title={i.title} position={i.team.position}
                                                                    isFounder={i.team.isFounder} type={'startup'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }

                                            { !!data.userProfile.members.angelNetworkList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Business
                                                    angel network
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.members.angelNetworkList.rows.map((i, k) => <MemberItem
                                                        key={k} logoFileId={i.logoFileId} title={i.title}
                                                        position={i.location} type={'angel_network'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }

                                            {!!data.userProfile.members.fundList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Funds
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.members.fundList.rows.map((i, k) =>
                                                        <MemberItem key={k} logoFileId={i.logoFileId}
                                                                    title={i.title}
                                                                    position={i.location} type={'fund'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }
                                            {!!data.userProfile.members.acceleratorList.rows.length &&
                                            <React.Fragment>
                                                <div
                                                    className='row-m0 margin-top-22 user-section-subtitle w-100'>Accelerators
                                                </div>
                                                <div className='row-m0 w-100'>
                                                    {data.userProfile.members.acceleratorList.rows.map((i, k) =>
                                                        <MemberItem key={k} logoFileId={i.logoFileId}
                                                                    title={i.title}
                                                                    position={i.team.position}
                                                                    isFounder={i.team.isFounder} type={'accelerator'} id={i.id}/>)}
                                                </div>
                                            </React.Fragment>
                                            }
                                        </div>
                                        }


                                            <div className="block-social-link-expert" style={{marginTop:'75px'}}>

                                                { (
                                                    data.userProfile.twitterLink
                                                    || data.userProfile.facebookLink
                                                    || data.userProfile.linkedInLink
                                                    || data.userProfile.mediumLink
                                                    || data.userProfile.telegramLink
                                                    || data.userProfile.instagramLink
                                                    || data.userProfile.link
                                                ) && <p className="title-social-link">

                                                Follow me:

                                                {data.userProfile.twitterLink   ?  <a target="_blank" href={data.userProfile.twitterLink.toUrl()}><i className="fab fa-twitter"/></a> : ''}
                                                {data.userProfile.facebookLink  ?  <a target="_blank" href={data.userProfile.facebookLink.toUrl()}><i className="fab fa-facebook"/></a> : ''}
                                                {data.userProfile.linkedInLink  ?  <a target="_blank" href={data.userProfile.linkedInLink.toUrl()}><i className="fab fa-linkedin"/></a> : ''}
                                                {data.userProfile.mediumLink   ?  <a target="_blank" href={data.userProfile.mediumLink.toUrl()}><i className="fab fa-medium"/></a> : ''}
                                                {data.userProfile.telegramLink  ?  <a target="_blank" href={data.userProfile.telegramLink.toUrl()}><i className="fab fa-telegram"/></a> : ''}
                                                {data.userProfile.instagramLink ?  <a target="_blank" href={data.userProfile.instagramLink.toUrl()}><i className="fab fa-instagram"/></a> : ''}
                                                {data.userProfile.link      ?  <a target="_blank" href={data.userProfile.link.toUrl()}><i className="fab fa-link"/></a> : ''}

                                            </p>}

                                            <p className="description-social-link">Registered {new Date(data.userProfile.createdAt).toShortFormat()}</p>
                                        </div>


                                    </div>
                                </div>
                            )
                        }
                      
                    }}
                </Query>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

Main.propTypes = {

}

export default connect(mapStateToProps, {})(Main)
import React from 'react';
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";

import './styles.css'

export default class StartupNewDeletePopup extends React.Component {

    render() {
        return (
            <div className={`profile-edit-popup-block`}>
            <div className="block-members-popup block-delete-popup">
                <div className='close-title'>
                    <div className='close' onClick={() => this.props.close()}/>
                </div>
                <div className="members-text-title margin-top-25 font-weight-500 text-align-center">Delete team member</div>
                <div className='members-text-little-subtitle text-align-center' style={{marginTop: '60px'}}>
                    Are you sure? This invitation link that we gave sent to this person will be disabled.
                </div>
                <div className='members-text-subtitle margin-top-40 margin-bottom-20 text-align-center font-weight-500'>You are about to cancel an invitation to:</div>
                <div className='list-mail-inv-send'>
                    <div className='mail-block-border'>
                        <div className='text-mail'>{this.props.member.email}</div>
                        <div className='members-text-little-subtitle' style={{marginTop:'8px'}}>{this.props.member.position}</div>
                    </div>
                </div>

                <div className='send-button-line margin-top-45'>
                    <div style={{float: 'left'}}>
                        <ButtonBlue
                            TitleButton={'Cancel'}
                            EventOnClick={() => this.props.close()}
                            paddingButton={'65'}
                            disabled={false}
                        />
                    </div>
                    <div style={{float: 'right'}}>
                        <ButtonBlue
                            TitleButton={'Delete'}
                            EventOnClick={() => this.props.delete()}
                            paddingButton={'67'}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
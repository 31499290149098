import React from 'react';
import './EvalParamStyle.css'
import {Row} from "../../../v4/Grid/Row";

export default class EvalParamToList extends React.Component {
    render() {

        let {
            name,
            paramCount,
            position
        } = this.props;



        let evalParam = 'positive';
        let paramcount = '';

        if (paramCount === '' ||  paramCount === '0' || paramCount === 0) {
            evalParam = 'zero';
            paramcount = '';
        }   
        
        return (
        <Row className='rw m8 block-eval-param-to-list' {...this.props}>
            <div className={'col-auto'}>
                <div className={`block-eval-${evalParam}-param`}>
                    <div className='param-count'>{paramcount}</div>
                </div>
            </div>
            <div className={'col'}>
                {(position === '') && <div className='glue'/>}
                <div className='eval-param-name'> {name} </div>
                {(position !== '') && <div className='position-title'>{position}</div>}
            </div>
        </Row>
        );
    }
}
import React from 'react';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'

import config from './../../../config'
import uploadImg from '../../../images/photo-upload.png'
import defaultAvatar from '../../../images/default_avatar.png'
import ic_avatar from '../../../images/svg/ic_avatar.svg'
import ic_picture from '../../../images/svg/ic_picture.svg'

import './styles.css'

export default class regAvatar extends React.Component {
    getImage = async () => {
        return new Promise((resolve => {
            if(this.state.image) {
                 this.editor.getImageScaledToCanvas().toBlob(function (blob) {
                    resolve(blob);
                }.bind(this), 'image/png', 1);
            }
        }))

    }
    handleSave = data => {
        const img = this.editor.getImageScaledToCanvas().toDataURL()
        const rect = this.editor.getCroppingRect()

        this.setState({
            preview: {
                img,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height,
                borderRadius: this.state.borderRadius
            }
        })
    }
    handleScale = e => {
        const scale = parseFloat(e.target.value)
        this.setState({ scale })

        if(this.props.onChange){
            this.props.onChange(Math.random())
        }

    }
    handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
        this.setState({ allowZoomOut })
    }
    rotateLeft = e => {
        e.preventDefault()

        this.setState({
            rotate: this.state.rotate - 90
        })
    }
    rotateRight = e => {
        e.preventDefault()
        this.setState({
            rotate: this.state.rotate + 90
        })
    }
    handleBorderRadius = e => {
        const borderRadius = parseInt(e.target.value)
        this.setState({ borderRadius })
    }
    handleXPosition = e => {
        const x = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, x } })
    }
    handleYPosition = e => {
        const y = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, y } })
    }
    handleWidth = e => {
        const width = parseInt(e.target.value)
        this.setState({ width })
    }
    handleHeight = e => {
        const height = parseInt(e.target.value)
        this.setState({ height })
    }
    setEditorRef = editor => {
        if (editor) this.editor = editor
    }
    handlePositionChange = position => {
        this.setState({ position })

        if(this.props.onChange){
            this.props.onChange(Math.random())
        }
    }
    handleNewImage = e => {
        this.setState({ image: e.target.files[0] })
    }

    constructor(props) {
        super(props);
        this.state = {
            allowZoomOut: true,
            positiD: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 512,
            height: 512
        };

        this.onCrop = this.onCrop.bind(this)
        this.onCropDefault = this.onCropDefault.bind(this)
        this.onCloseDefault = this.onCloseDefault.bind(this)
    }

    onDrop(accepted, rejected) {
        if (accepted.length) {

            console.log('accepted')

            this.setState({image: accepted[0]}, () => {

            });
            this.handleScale({target:{value:'1.01'}})

            if(this.props.onChange){
                this.props.onChange(Math.random())
            }
        }
        if (rejected.length) {
            this.setState({fileError: 'The uploaded file seems to have incorrect format'})
        }
    }

    onCropDefault(preview) {
        this.setState({defaultPreview: preview, newAvatar: true})

        let binary = atob(preview.split(',')[1]);
        let array = [];
        for(let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        let img =  new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
        this.setState({avatar: img})
    }

    onCrop(preview) {
        this.setState({preview})
    }

    onCloseDefault() {
        this.setState({defaultPreview: null, newAvatar: false, preview: null})
    }

    logCallback (e) {
        if(e === 'onLoadSuccess' && this.props.onLoadSuccess) this.props.onLoadSuccess()

        if(this.props.onChange){
            this.props.onChange(Math.random())
        }
    }

    render() {
        let {
            fileId,
            title,
            description,
            editability
        } = this.props
        let image = this.state.image;

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none',
            'display':'table'
        }

        let styleCanvas = {
            'width': '111px',
            'height': '111px',
        }

        function ActionLink() {
            document.getElementById('input_load_file_2').click();
        }

        return (
            <React.Fragment>

                {/* default state. No photo */}
                {!image &&
                <div className="shell-load-img-cart">
                    <div className="block-load-img">
                        {
                            <Dropzone style={dropStyle}
                                      multiple={false}
                                      onDrop={this.onDrop.bind(this)}
                                      accept="image/jpeg, image/png, image/gif"
                                      maxSize={5000000}
                            >
                                {fileId && <img className='load-img' src={`${config.proxy}/file/${fileId}/view`} alt={fileId}/>}
                                {!fileId && <img className="logo-load-img" src={ic_avatar} alt=""/>}
                                <div className="block-button-load-img">
                                    <p className="title-button-load-img">{title}</p>
                                </div>
                            </Dropzone>
                        }
                    </div>
                    <p className="title-load-img">{description}</p>
                </div>
                }


                {/*Image is chosen*/}
                {image &&
                <React.Fragment>
                    <div>
                        <AvatarEditor
                            ref={this.setEditorRef}
                            scale={parseFloat(this.state.scale)}
                            width={this.state.width}
                            height={this.state.height}
                            position={this.state.position}
                            onPositionChange={this.handlePositionChange}
                            rotate={parseFloat(this.state.rotate)}
                            borderRadius={this.state.borderRadius}
                            onSave={this.handleSave}
                            onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                            onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                            onImageReady={this.logCallback.bind(this, 'onImageReady')}
                            onImageLoad={this.logCallback.bind(this, 'onImageLoad')}
                            onDropFile={this.logCallback.bind(this, 'onDropFile')}
                            color={[0, 0, 0, 0.8]}
                            style={styleCanvas}
                            image={this.state.image || uploadImg}
                        />
                    </div>

                    <div className={'zoom-inp'}>
                        <input
                            name='scale'
                            type='range'
                            onChange={this.handleScale.bind(this)}
                            min={this.state.allowZoomOut ? '0.1' : '1'}
                            max='4'
                            step='0.01'
                            defaultValue='1'
                            style={{display: 'none'}}
                        />
                    </div>

                    <div className="add-new-btn">
                        <Dropzone style={dropStyle}
                                  multiple={false}
                                  onDrop={this.onDrop.bind(this)}
                                  accept="image/jpeg, image/png, image/gif"
                                  maxSize={5000000}
                        >
                            <div className="block-button-load-img">
                                <p className="title-button-load-img">Add new</p>
                            </div>
                        </Dropzone>
                    </div>
                </React.Fragment>
                }
            </React.Fragment>

        );
    }
}

import React from 'react';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { logout } from '../../actions/Login'

class Logout extends React.Component {

    componentDidMount  () {
    this.props.logout()
  }

  render () {
    return(
      <div/>
    )
  }

}

Logout.propTypes = {
  logout:PropTypes.func.isRequired
}

export default connect(null, {logout})(Logout)













import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";


import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
// import ButtonGray from "../../controls3.0/button/ButtonGray";
import {regexUrl} from "../../../utils/RegExp";
// import Input from "../../controls3.0/input/Input";
import Group21 from "../../../images/svg/Group21.png";


class Step17 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        if(!angel.team) angel.team = angel.team || []
        this.state = {
            user: user,
            angel: angel,
            errors: {}
        }
    }

    componentDidMount () {
        this.checkExistLink()
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if (regexUrl.test(value)) {
            this.setState({errors: {...this.state.errors, contact : ''}})
        }

        this.setState({ [name]: value })
    }

    onChange2 = (e, key) => {
        let team = this.state.angel.team;
        team[key] = e.target.value;
        this.setState({ angel: {...this.state.angel, team: team}})
    }

    add = () => {
        let contact = this.state.contact;
        let team = this.state.angel.team;

        if(team.includes(contact)) return

        if (regexUrl.test(contact)) {
            this.setState({errors: {...this.state.errors, contact : ''}})
            this.setState({ angel: {...this.state.angel, team: [...this.state.angel.team, contact] }}, () => {
                this.setState ({contact: ''})
            })
        } else {
            this.setState({errors: {...this.state.errors, contact : 'Wrong link'}})
        }
    }

    checkExistLink = () => {
        let angel = this.props.angel,
            team = this.state.angel.team;
        if(angel.mediumLink && !team.includes(angel.mediumLink)) team.unshift(angel.mediumLink)
        if(angel.twitterLink && !team.includes(angel.twitterLink)) team.unshift(angel.twitterLink)
        if(angel.telegramLink && !team.includes(angel.telegramLink)) team.unshift(angel.telegramLink)
        if(angel.linkedInLink && !team.includes(angel.linkedInLink)) team.unshift(angel.linkedInLink)
        if(angel.facebookLink && !team.includes(angel.facebookLink)) team.unshift(angel.facebookLink)

        this.setState({ angel: {...this.state.angel, team: team}})
    }

    getSocialIcon = link => {
        let iconClass = 'far fa-link'
        if (/facebook/.test(link)) iconClass = 'fab fa-facebook'
        if (/linkedin/.test(link)) iconClass = 'fab fa-linkedin-in'
        if (/telegram/.test(link)) iconClass = 'fab fa-telegram'
        if (/twitter/.test(link)) iconClass = 'fab fa-twitter'
        return iconClass
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, angel, errors} = this.state


        let team = angel.team.map((item, key) => {
            if (item.email) {
                return (
                    <span className='item-email-sent'>{item.email}</span>
                )
            }
        })

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg">
                        <div className="container-step-1-reg">
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                {(angel.team.length>1)?'Invitations are successfully sent!':'Invitation is successfully sent!' }
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30" style={{maxWidth:'490px'}}>
                                You will be notified if your friends register on the platform via your invitation link.
                            </p>


                            <div className='step-21-btm clearfix'>
                                <div className='s21-left inv-succ no-float-620'>
                                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15 mb" style={{width:'110%'}}>
                                        {(angel.team.length>1)?'We have sent invitations to these emails:':'We have sent invitation to these email:' }
                                    </p>
                                    <div>
                                        {team}
                                    </div>


                                </div>
                                <div className='s21-right inv-succ no-float-620'>
                                    <img src={Group21} alt="" className=""/>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">

                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Continue'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'56'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step17.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step17)
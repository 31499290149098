import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonBlue'
import ButtonBorder from '../controls3.0/button/ButtonBorder'
import _bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import '../expert4.0/style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import packageJSON from './../../../package.json'
import Loader from '../test-controls/preloader/preloader'
import axios from "axios";
import {getAchievementInvestor, tokenLogin} from "../../actions/Login";
import {getListIndustries, getListFocuses} from "../../actions/Project";

import {Swiper, Slide} from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'
import {Comments} from '../v3/comments';
import {createAvatar} from "../user/createAvatar";
import {listSocialsUserForTeam} from  '../v3/listSocialsUserForTeam';
import EditMyPageButton from  '../v3/editMyPage';
import MetaTags from "react-meta-tags";
import history from '../../history';
import HeaderStickyRole from "../controls3.0/headerStickyRole";
import {getShortLang, isExist} from "../../utils/helper";
import {viewCounter} from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";
import {VerificationIcon} from "../v4/VerificationIcon";
import ExternalAngelNetwork from "../external/angelNetwork";
import config from "../../config";

const TYPES = [];
TYPES[0] = {
    id: 0,
    title: 'Articles',
    description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
};
TYPES[1] = {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'};
TYPES[2] = {
    id: 2,
    title: 'Diploma',
    description: 'Documents and any documentary proof of your educational background.'
};
TYPES[3] = {
    id: 3,
    title: 'Projects',
    description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
};
TYPES[4] = {
    id: 4,
    title: 'Mass media',
    description: 'Links to the articles about you or about your professional experience.'
};
TYPES[5] = {
    id: 5,
    title: 'Recommendations',
    description: 'Reviews of your expert works and professional collaboration.'
};


Date.prototype.toShortFormat = function () {

    const month_names = ["Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
}

String.prototype.toUrl = function () {
    const re = new RegExp("^(http|https)://", "i");

    if(re.test(this)){
        console.log(this);
        return this;
    }
    if(re.test(this)){
        return this;
    }
    return 'http://'+this;
}

String.prototype.toShowUrl = function () {
    return this.replace('https://', '').replace('http://', '');
}


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            angel: {},
            user: props.user || {},
            logo: avatar,
            bg: _bg,
            listAchievements: [],
            experience: [],
            industries: [],
            gFocuses: [],
            id: this.props.id || props.match.params.id,
            activeAchivements: -1,
            comments: [],
            currentComment: '',
            activeSticky: false,
            showLoginPopup: 'hide'
        }



        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.setActiveAch = this.setActiveAch.bind(this);


    }

    componentDidMount() {

        if( !viewCounter() ) {
            if(this.props.id){

                const commandProps = {
                    to: '/',
                    id: this.props.id,
                    type: 'angel_network',
                    f: 'tpc'
                };

                localStorage.removeItem("rFrom");
                localStorage.removeItem("referer");
                localStorage.removeItem("referer_startup");

                localStorage.setItem("commandProps", JSON.stringify(commandProps));

                this.setState({showLoginPopup: 'content'});
            } else {
                this.setState({showLoginPopup: 'popup'});
            }
        }

        getListIndustries()
            .then((res) => {
                this.setState({industries: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        getListFocuses()
            .then((res) => {
                this.setState({gFocuses: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        axios.get(`/v2/angels/${this.state.id}`)
            .then(r => {

                // console.log(r);
                //
                // r.data.team = [...[{User:r.data.User}, ...r.data.team]];

                if(!r.data.previousInvestment){
                    r.data.previousInvestment = [];
                }

                this.setState({angel: r.data});
                const logo = r.data.logoFileId ? `${config.proxy}/file/${r.data.logoFileId}/view` : avatar;
                const bg = r.data.backgroundFileId ? `${packageJSON.proxy}/file/${r.data.backgroundFileId}/view` : _bg;

                this.setState({logo, bg});

                try {
                    let obj = [];
                    Promise.all(
                        r.data.parameters.map(async (item, key) => {
                            let achievement = await getAchievementInvestor('angel', item.parameterId, this.state.id);
                            if(achievement.data.length){
                                obj[item.parameterId] = {item: item, data: achievement.data};
                            }
                            return (achievement.data)
                        })
                    ).then(results => {
                        this.setState({listAchievements: obj});
                        this.setState({activeAchivements: parseInt(Object.keys(obj)[0])});
                    })
                } catch (e) {
                    console.log(e);
                }

            }).catch(e => {
            if (e.response.status === 404) {
                history.push('/404');
            }
        })
        // this.stickyHeaderCart();
        if(process.env.NODE_ENV !== 'production'){
            window.addEventListener('scroll',this.stickyBG);
        }
    }
    componentWillUnmount() {
        if(process.env.NODE_ENV !== 'production') {
            window.removeEventListener('scroll', this.stickyBG);
        }
    }


    // stickyHeaderCart = () => {
    //     let timer = setInterval(() => {
    //
    //         if (window.pageYOffset > 300) {
    //             this.setState({activeSticky:true})
    //         }else {
    //             this.setState({activeSticky:false})
    //         }
    //         // clearInterval(timer);
    //     }, 500);
    //
    // }
    stickyBG = () => {
        if (window.pageYOffset > 174) {
            $('.card-wrapper').addClass('on-scroll');
            this.setState({activeSticky:true})
        } else {
            $('.card-wrapper').removeClass('on-scroll');
            this.setState({activeSticky:false})
        }
    };

    componentWillReceiveProps(nextProps){
        if(this.props.id){
            if(nextProps.id != this.state.id) {
                this.setState({...nextProps}, () => {
                    this.componentDidMount();
                })
            }
        } else {
            if(nextProps.id != this.state.id) {
                this.setState({user: nextProps.user})
            }
        }
    }

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');
    }

    setActiveAch = (id) => {
        this.setState({activeAchivements: id});
    }

    outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2).replace(/\.00$/,'') + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2).replace(/\.00$/,'') + 'K';
            }
        }

        return `${r(min)}-${r(max)}`
    }

    getImgAlt = (title) => {
        let replaced = title.replace(/ /gi, '_');
        return replaced;
    };

    render() {
        const {angel, logo, listAchievements, industries, gFocuses, comments, user} = this.state;
        let leftPI = [];
        let rightPI = [];

        const _avatar = angel.logoFileId ?
            <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${angel.logoFileId}/view?W=200`})`}}>
                <img src={`${config.proxy}/file/${angel.logoFileId}/view`} alt={'angel_network ' + this.getImgAlt(angel.title)} />
            </div>
            :
            createAvatar('photo-cart-expert', angel.title);

        if (!angel.id) {
            return (<React.Fragment>
                <Loader loaded={!!angel.id} isLocal={!!this.props.id}/>
            </React.Fragment>);
        } else {

            if(angel.userId){
                return (
                    <React.Fragment>
                        {!this.props.id && <MetaTags>
                            <title>{'Angel Network ' + this.getImgAlt(angel.title) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                            <meta property="og:title" content={angel.title + ' - Rocket DAO - uniting startups, investors and experts'}/>
                            <meta name="description" content={angel.shortDescription} />
                            <meta property="og:description" content={angel.shortDescription}/>
                        </MetaTags>}

                        {this.state.showLoginPopup !== 'content' && <div className='card-wrapper'>


                            <div className={`block-sticky-role-cart ${this.state.activeSticky ? 'active' : ''}`}>
                                <HeaderStickyRole

                                    name={angel.title}
                                    activeCheck={true || angel.proofSent}
                                    urlAvatar={angel.logoFileId ? `${config.proxy}/file/${angel.logoFileId}/view?W=80` : null}


                                    type={'network'} // expert, fund, investor, startup
                                    titleType={'Angel Network'}
                                    description={null}
                                    location={'$'+ angel.fundSize ? this.outputRange(angel.fundSize.investmentSize.min, angel.fundSize.investmentSize.max) : ''}
                                    languageOrSize={angel.currentTeamCount}
                                    eventButton={null} // only startup
                                    customButton={((user && user.id !== angel.userId) || !user) && <ButtonGreen
                                        TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                        EventOnClick={null}
                                        paddingButton={'0'}
                                        disabled={true}
                                        onMouseEnter={() => this.setState({mouseEnter: true}) }
                                        onMouseLeave={() => this.setState({mouseEnter: false}) }
                                    /> || <EditMyPageButton id={angel.id} type={'angel'}/>}


                                />

                            </div>


                            <div className="block-background-expert">
                                <img src={this.state.bg} alt={'load'}/>
                            </div>
                            <div className="block-container-expert">
                                <div className="block-box-shadow-cart-startup"/>



                                <div className="block-cart-expert-mobile">
                                    <div className="block-top-cart-expert-mobile">
                                        <div className="block-avatar-name-top-cart-expert-mobile">
                                            <div className="block-left-top-cart-expert-mobile">
                                                {_avatar}
                                            </div>
                                            <div className="block-right-top-cart-expert-mobile">
                                                <h1 className="title-top-cart-expert-mobile">
                                                    {angel.title}
                                                    {//angel.proofSent &&
                                                    <VerificationIcon type={'angel_network'} className='sticky'/>
                                                    }
                                                </h1>
                                                <span className="round-prof investor">
                                                Angel Network
                                            </span>
                                            </div>
                                        </div>
                                        <p className="description-top-cart-expert-mobile">
                                            {angel.shortDescription}
                                        </p>
                                    </div>
                                    <div className="block-body-cart-expert-mobile">
                                        <div className="block-list-button-cart-expert-mobile">
                                            <ButtonBorder
                                                TitleButton={'I am a member of this network'}
                                                EventOnClick={null}
                                                paddingButton={'0'}
                                                disabled={true}
                                            />


                                            { ((user && user.id !== angel.userId) || !user) && <ButtonGreen
                                                TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                EventOnClick={null}
                                                paddingButton={'26'}
                                                disabled={true}
                                                onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                onMouseLeave={() => this.setState({mouseEnter: false}) }
                                            /> || <EditMyPageButton id={angel.id} type={'angel'}/>}
                                        </div>
                                        <div className="block-list-state-cart-expert-mobile">
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Members</p>
                                                <p className="description-l">{angel.currentTeamCount}</p>
                                            </div>
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Startup investment size (USD)</p>
                                                <p className="description-l">$ {angel.fundSize ? this.outputRange(angel.fundSize.investmentSize.min, angel.fundSize.investmentSize.max) : ''}</p>
                                            </div>
                                            {
                                                !angel.noTurnover ?
                                                    <div className="block-title-description-left-expert">
                                                        <p className="title-l">Startup turnover stage (USD)</p>
                                                        <p className="description-l">$ {angel.turnover ? this.outputRange(angel.turnover.min, angel.turnover.max) : ''}</p>
                                                    </div> : ''
                                            }
                                            < div className="block-title-description-left-expert">
                                                <p className="title-l">Business type</p>
                                                <p className="description-l">{angel.businessModelType}</p>
                                            </div>
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Founding year</p>
                                                <p className="description-l">{angel.foundingYear}</p>
                                            </div>
                                        </div>
                                        <div className="block-list-info-cart-expert-mobile">
                                            {angel.website && <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Website:
                                                </p>
                                                <a href={angel.website.toUrl()}>{angel.website.toShowUrl()}</a>
                                            </div> }

                                            {
                                                gFocuses.length ?
                                                    <div className="block-skills-expert">
                                                        <p className="title-block-expert">
                                                            Geographic focus
                                                        </p>
                                                        <p className="list-skills-expert">
                                                            {angel.marketFocus.map(id => {
                                                                return <span
                                                                    className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                            })}
                                                        </p>
                                                    </div> : ''
                                            }

                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Areas of expertise
                                                </p>
                                                <p className="list-skills-expert">
                                                    {angel.skills.map(skill => {
                                                        return <span className="skill">{skill.title}</span>
                                                    })}
                                                </p>
                                            </div>

                                            {industries.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Industries
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {angel.industry.map(id => {
                                                            return <span
                                                                className="skill">{industries.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                            }

                                            <div className="block-title-description-info">
                                                <p className="title-info-investor">
                                                    Looking for
                                                </p>
                                                <p className="description-info-investor">
                                                    {angel.investmentFocus}
                                                </p>
                                            </div>

                                            <div className="block-title-description-col">
                                                <div className="block-col-title-description">
                                                    <p className="title-info-investor">
                                                        Number of investments
                                                    </p>
                                                    <p className="description-info-investor">
                                                        {angel.investmentsCount || 0}
                                                    </p>
                                                </div>
                                                <div className="block-col-title-description">
                                                    <p className="title-info-investor">
                                                        Number of Exits
                                                    </p>
                                                    <p className="description-info-investor">
                                                        {angel.exitsCount  || 0}
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                (() => {
                                                    leftPI = [];
                                                    rightPI = [];
                                                    angel.previousInvestment.map((v, i) => {

                                                        let div = <div className="block-previous-investor" key={i}>
                                                            <div className="block-top-previous-investor"
                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                                <p className="title-previous-investor">
                                                                    {v.title}
                                                                </p>
                                                                <p className="info-previous-investor">
                                                                    Exit:
                                                                    <span>{v.exite ? 'Yes' : 'No'}</span>
                                                                    Investment size:
                                                                    <span>${v.size}</span>
                                                                </p>
                                                                <div className="block-open-close-previous-investor">
                                                                    <i className="far fa-chevron-down"/>
                                                                </div>
                                                            </div>
                                                            <div className="block-body-previous-investor">
                                                                <p className="description-previous-investor">
                                                                    {v.position}
                                                                </p>
                                                            </div>
                                                        </div>;

                                                        if (i % 2 === 0)
                                                            leftPI.push(div);
                                                        else
                                                            rightPI.push(div);
                                                    })
                                                })()
                                            }

                                            <div className="block-list-previous-investor">
                                                <p className="title-block-expert">
                                                    Previous investments
                                                </p>
                                                {(leftPI.length || rightPI.length) ?
                                                    <React.Fragment>
                                                        <div className="block-left-previous-investor">
                                                            {leftPI}
                                                        </div>
                                                        <div className="block-right-previous-investor">
                                                            {rightPI}
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <div className="block-left-previous-investor">
                                                        no information
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="block-cart-expert">
                                    <div className="block-left-cart-expert">
                                        <div className="block-photo-cart-expert">
                                            {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                            {_avatar}

                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Members</p>
                                            <p className="description-l">{angel.currentTeamCount}</p>
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Startup investment size (USD)</p>
                                            <p className="description-l">$ {angel.fundSize ? this.outputRange(angel.fundSize.investmentSize.min, angel.fundSize.investmentSize.max) : ''}</p>
                                        </div>
                                        {
                                            !angel.noTurnover ?
                                                <div className="block-title-description-left-expert">
                                                    <p className="title-l">Startup turnover stage (USD)</p>
                                                    <p className="description-l">$ {angel.turnover ? this.outputRange(angel.turnover.min, angel.turnover.max) : ''}</p>
                                                </div> : ''
                                        }
                                        < div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{angel.businessModelType}</p>
                                        </div>
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Founding year</p>
                                            <p className="description-l">{angel.foundingYear}</p>
                                        </div>
                                    </div>
                                    <div className="block-right-cart-expert">
                                        <div className="block-info-cart-expert">
                                            <p className="name-expert" style={{maxWidth: '298px'}}>
                                                <h1>{angel.title} {//angel.proofSent &&
                                                    <VerificationIcon type={'angel_network'} className='sticky'/>
                                                }</h1>

                                            </p>
                                            <p className="title-prof">
                                        <span className="round-prof investor">
                                            <h2>Angel Network</h2>
                                        </span>

                                            </p>
                                            <h2 className="description-expert">
                                                {angel.shortDescription}
                                            </h2>
                                            <ButtonBorder
                                                TitleButton={'I am a member of this network'}
                                                EventOnClick={null}
                                                paddingButton={'12'}
                                                disabled={true}
                                            />


                                            { ((user && user.id !== angel.userId) || !user) && <ButtonGreen
                                                TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                EventOnClick={null}
                                                paddingButton={'26'}
                                                disabled={true}
                                                onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                onMouseLeave={() => this.setState({mouseEnter: false}) }
                                            /> || <EditMyPageButton id={angel.id} type={'angel'}/>}


                                        </div>

                                        {angel.website && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website:
                                            </p>
                                            <a href={angel.website.toUrl()}>{angel.website.toShowUrl()}</a>
                                        </div> }

                                        {
                                            gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {angel.marketFocus.map(id => {
                                                            return <span
                                                                className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                        }

                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Areas of expertise
                                            </p>
                                            <p className="list-skills-expert">
                                                {angel.skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>

                                        {industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Industries
                                                </p>
                                                <p className="list-skills-expert">
                                                    {angel.industry.map(id => {
                                                        return <span
                                                            className="skill">{industries.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : ''
                                        }

                                        <div className="block-title-description-info">
                                            <p className="title-info-investor">
                                                Looking for
                                            </p>
                                            <p className="description-info-investor">
                                                {angel.investmentFocus}
                                            </p>
                                        </div>

                                        <div className="block-title-description-col">
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of investments
                                                </p>
                                                <p className="description-info-investor">
                                                    {angel.investmentsCount || 0}
                                                </p>
                                            </div>
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of Exits
                                                </p>
                                                <p className="description-info-investor">
                                                    {angel.exitsCount || 0}
                                                </p>
                                            </div>
                                        </div>

                                        {
                                            (() => {
                                                leftPI = [];
                                                rightPI = [];
                                                angel.previousInvestment.map((v, i) => {

                                                    let div = <div className="block-previous-investor" key={i}>
                                                        <div className="block-top-previous-investor"
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                            <p className="title-previous-investor">
                                                                {v.title}
                                                            </p>
                                                            <p className="info-previous-investor">
                                                                Exit:
                                                                <span>{v.exite ? 'Yes' : 'No'}</span>
                                                                Investment size:
                                                                <span>${v.size}</span>
                                                            </p>
                                                            <div className="block-open-close-previous-investor">
                                                                <i className="far fa-chevron-down"/>
                                                            </div>
                                                        </div>
                                                        <div className="block-body-previous-investor">
                                                            <p className="description-previous-investor">
                                                                {v.position}
                                                            </p>
                                                        </div>
                                                    </div>;

                                                    if (i % 2 === 0)
                                                        leftPI.push(div);
                                                    else
                                                        rightPI.push(div);
                                                })
                                            })()
                                        }

                                        <div className="block-list-previous-investor">
                                            <p className="title-block-expert">
                                                Previous investments
                                            </p>
                                            {(leftPI.length || rightPI.length) ?
                                                <React.Fragment>
                                                    <div className="block-left-previous-investor">
                                                        {leftPI}
                                                    </div>
                                                    <div className="block-right-previous-investor">
                                                        {rightPI}
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="block-left-previous-investor">
                                                    no information
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="block-body-expert">


                                    <div className='block-body-team'>
                                        <p className="title-block-body-team">
                                            Members <span>{angel.currentTeamCount}</span>
                                        </p>

                                        <Swiper
                                            swiperOptions={{
                                                slidesPerView: 4,
                                                freeMode: true,
                                                breakpoints: {
                                                    // when window width is <= 320px
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 10
                                                    },
                                                    // when window width is <= 480px
                                                    480: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20
                                                    },
                                                    // when window width is <= 640px
                                                    640: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 30
                                                    }
                                                }
                                            }}
                                            navigation={false}
                                            pagination={false}
                                        >
                                            {angel.team.map( (member, i) => {
                                                return  member.User && <Slide key={i} onClick={() => window.open(`/user/${member.User.id}`, '_blank')}><div className='block-body-team-list'>
                                                    <div className='content-box'>
                                                        {/*<div className="list-avatar" style={{background: `url(${packageJSON.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}

                                                        {
                                                            member.User.logoFileId
                                                            && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                                            || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>
                                                        }

                                                        <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>
                                                        <p className='list-position'>{member.User.id === angel.userId ? 'Admin' : 'Member'}</p>
                                                        {listSocialsUserForTeam(member.User)}
                                                    </div>
                                                </div></Slide>
                                            })}
                                        </Swiper>
                                    </div>

                                    {!!listAchievements.length &&
                                    <div className="block-achievements-investor">
                                        <p className="title-achievements-investor">Achievements</p>
                                        <div className="block-list-tab-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {
                                                    return <TabsCounter
                                                        TitleTab={v.item.title}
                                                        TypeTab={'big'}
                                                        EventOnClick={() => this.setActiveAch(v.item.parameterId)}
                                                        active={v.item.parameterId === this.state.activeAchivements}
                                                        count={v.data.length}
                                                    />
                                                })
                                            }

                                        </div>

                                        <div className="block-list-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(0,6).map(v => {
                                                    return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>
                                                        <p className="title-item-achievements-investor">
                                                            {v.description}
                                                        </p>
                                                        <div
                                                            className="block-right-achievements-cart-share">
                                                            <a href={v.link.toUrl()}
                                                               rel="noopener noreferrer"
                                                               target="_blank"> <i
                                                                className="fas fa-share-square"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                })
                                            }

                                            <div className="open-close-block">
                                                {
                                                    this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {
                                                        return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>
                                                            <p className="title-item-achievements-investor">
                                                                {v.description}
                                                            </p>
                                                            <div
                                                                className="block-right-achievements-cart-share">
                                                                <a href={v.link.toUrl()}
                                                                   rel="noopener noreferrer"
                                                                   target="_blank"> <i
                                                                    className="fas fa-share-square"/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>


                                            {this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&
                                            <div style={{float: 'none', clear: 'both'}} className="block-item-achievements-investor" onClick={(e) => {
                                                this.setState({showMoreAch: !this.state.showMoreAch});
                                                this.OpenCloseContentClick(e, '.open-close-block');

                                            }}>

                                                <p className="title-item-achievements-investor show">
                                                    {!this.state.showMoreAch && <span className="show-more">Show more <i className="far fa-chevron-down"/></span>}
                                                    {this.state.showMoreAch && <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}
                                                </p>
                                            </div>
                                            }
                                        </div>


                                    </div>
                                    }
                                </div>

                                <div className="block-social-link-expert">

                                    <p className="title-social-link">
                                        Follow us:
                                        {angel.twitterLink ? <a target="_blank" href={angel.twitterLink.toUrl()}><i className="fab fa-twitter"/></a> : ''}
                                        {angel.facebookLink ? <a target="_blank" href={angel.facebookLink.toUrl()}><i className="fab fa-facebook"/></a> : ''}
                                        {angel.linkedInLink ? <a target="_blank" href={angel.linkedInLink.toUrl()}><i className="fab fa-linkedin"/></a> : ''}
                                        {angel.mediumLink ? <a target="_blank" href={angel.mediumLink.toUrl()}><i className="fab fa-medium"/></a> : ''}
                                        {angel.telegramLink ? <a target="_blank" href={angel.telegramLink.toUrl()}><i className="fab fa-telegram"/></a> : ''}

                                        {angel.contacts.map((contact) => {
                                            let classn = 'fa fa-link'
                                            if (/twitter/.test(contact)) {
                                                classn = 'fa-twitter'
                                            }
                                            if (/facebook/.test(contact)) {
                                                classn = 'fa-facebook'
                                            }
                                            if (/linked/.test(contact)) {
                                                classn = 'fa-linkedin'
                                            }
                                            if (/medium/.test(contact)) {
                                                classn = 'fa-medium'
                                            }
                                            if (/telegram/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }
                                            if (/t.me/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }

                                            return <a href={contact.toUrl()} target="_blank"><i
                                                className={`fab ${classn}`}/></a>
                                        })}

                                    </p>
                                    <p className="description-social-link">Registered {new Date(angel.createdAt).toShortFormat()}</p>
                                </div>

                                <Comments id={angel.id} user={user} type='angel'/>
                            </div>
                        </div>}
                        {this.state.showLoginPopup !== 'hide' && <LoginPopup
                            close={()=>{
                                this.setState({showLoginPopup:'hide'});
                                if(!localStorage.RocketToken){
                                    history.push('/');
                                }
                            }}
                            state={this.state.showLoginPopup}
                        />}
                    </React.Fragment>
                )
            } else {

                let newProps = this.props;

                if(!isExist(() => newProps.match.params.id)){
                    newProps = {...newProps, match: {params: {id: this.props.id, verify: 'v'}}};
                }

                return <ExternalAngelNetwork {...newProps}/>
            }


        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state,
})

Main.propTypes = {
    // getExpert: PropTypes.func.isRequired
    // updateFund: PropTypes.func.isRequired,
    // getLastFund: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(Main)
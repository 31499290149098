import React from 'react';
import ExpertTotalScore from './ExpertTotalScore';
import config from "../../../config";

import './style.css'
import "../../../libs/grid/grid.css";
import ExpertReviewScore from "./ExpertReviewScore";
import history from "../../../history";
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import packageJSON from "../../../../package.json";
import {createAvatar} from "../../user/createAvatar";

const PDFLinks = {
    441:'/file/pdf/Parsers.pdf/download',
    510:'/file/pdf/Peeranha.pdf/download',
    586:'/file/pdf/CaerSidi.pdf/download',
};

export default class PreliminaryEvaluationTable extends React.Component {
    constructor(props){
        super(props);
    }

    ParamClick=(clickedParam, ExpertsList)=>{
        if (localStorage.RocketToken) {
            history.push({pathname:`/startup/${this.props.startup.id}/evaluation/view`,state:{
                    openedEval:clickedParam,
                    startupId: this.props.startup.id
                }});
        } else {
            localStorage.setItem('referer', `/startup/${this.props.startup.id}/evaluation/view`);
            history.push('/login', {
                    openedEval: clickedParam,
                    startupId: this.props.startup.id
                }
            );
        }

        window.scrollTo(0,0);
    };

    AmountEvaluation = (evaluation)=>{
        let sum = 0;
        for (let i = 0; i < evaluation.length;i++) {
            sum += evaluation[i].ParamEvaluation;
        }
        return (sum/evaluation.length).toFixed(1);
    };

    getMyExpertList(){
        let expertList = [];

        for (let i = 0; i < this.props.Evaluation.length; i++) {
            if (this.props.Evaluation[i].ExpertId){
                expertList.push(this.getExpert(this.props.Evaluation[i].ExpertId));

            }else {
                expertList.push({
                    user:{
                        firstName:this.props.Evaluation[i].OuterNameExpert
                    },
                    professionalDescription:this.props.Evaluation[i].OuterDescriptionExpert
                })
            }
        }

        return expertList;
    }

    getExpert(id){
        let expert = this.props.listExperts.filter((item,key)=>{
            return item.id === id;
        })
        return expert[0];
    }

    getAvatar(item) {
        const {user} = item;

        const _avatar = user.logoFileId ?
            <div className="col block-avatar"
                 style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${user.logoFileId}/view`})`}}/>
            :
            createAvatar('col block-avatar', user.firstName);
        return _avatar;
    }
    drawAvatar(link) {
        const _avatar = <div className="col block-avatar"
                 style={{backgroundImage: `url(${link})`}}/>
        return _avatar;
    }

    downloadPDF (){
        document.getElementById('parsers-download-link').click();
    }


    render() {
        let {
            FullVersion,
            startup,
            Evaluation
        } = this.props;


        const MyExpertsList = this.getMyExpertList();

        return (
            <div className={`block-preliminary-evaluation-table cursor-pointer ${FullVersion?'preliminary-evaluation-table-full':''}`}  onClick={()=>{this.ParamClick(0, MyExpertsList)}}>
                <div className='row-b preliminary-evaluation-table-top no-gutters'>
                    <div className='col table-top-left-col'>
                        <div className='eval-table-main-title color-rgba-88 font-weight-500' onClick={()=>{console.log(PDFLinks[this.props.startup.id])}}>Preliminary evaluation</div>
                        {FullVersion && <div className='text-conducted color-rgba-88'>Conducted by Rocket DAO expert community</div>}
                        {!FullVersion && <div className='text-send-date margin-top-5'>Send evaluation: 11 Dec,2019 - 7:47 p.m.</div>}

                        <ButtonGreen
                            TitleButton={'Download PDF report'}
                            EventOnClick={() => {this.downloadPDF()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                    <div className='col table-top-right-col'>
                        <ExpertTotalScore
                            TotalScore={this.AmountEvaluation(Evaluation)}
                            FullVersion={true}
                        />
                    </div>
                </div>

                <div className='row-b evaluation-table-top-row no-gutters'>
                    <div className='col table-col-1 table-title'>Evaluation Parameters</div>
                    <div className={`col table-col-2 ${FullVersion?'table-col-2-full':''} table-title`}>Score</div>
                    <div className='col table-col-3 table-title'>Expert responsible for evaluation</div>
                </div>
                <div className='row-b evaluation-table-row no-gutters cursor-pointer'>
                    <div className='col table-col-1 name-col-text color-rgba-88 font-weight-500'>{Evaluation[0].ParamName}</div>
                    <div className={`col table-col-2 ${FullVersion?'table-col-2-full':''} name-col-text color-rgba-88 font-weight-500`}>
                        {Evaluation[0].ParamEvaluation}
                        {FullVersion && <div className='average-rating-line'>
                            <div className='active' style={
                                Evaluation[0].ParamEvaluation<5?{width:Evaluation[0].ParamEvaluation*10+'%', background:'#FF4133'}
                                :{width:Evaluation[0].ParamEvaluation*10+'%', background:'#0ECFAA'}}/>
                        </div>}
                    </div>
                    <div className='col table-col-3 info-col-text color-rgba-88'>
                        {MyExpertsList[0] && <div className='row-b no-gutters w-100 margin-top-12'>
                            {this.getAvatar(MyExpertsList[0])}
                            <div className='col text-block'>
                                <div className='row-b expert-name-text w-100 no-gutters color-rgba-88'>{MyExpertsList[0].user.firstName} {MyExpertsList[0].user.lastName}</div>
                                <div className='row-b text-40-symbols text-one-line w-100 no-gutters'>{MyExpertsList[0].professionalDescription}</div>
                            </div>
                        </div>}
                    </div>
                    <div className='col table-col-4 info-col-text text-right color-2f3849 font-weight-500'><i className="far fa-chevron-down"/></div>
                </div>
                <div className='row-b evaluation-table-row no-gutters cursor-pointer'>
                    <div className='col table-col-1 name-col-text color-rgba-88 font-weight-500'>{Evaluation[1].ParamName}</div>
                    <div className={`col table-col-2 ${FullVersion?'table-col-2-full':''} name-col-text color-rgba-88 font-weight-500`}>
                        {Evaluation[1].ParamEvaluation}
                        {FullVersion && <div className='average-rating-line'>
                            <div className='active' style={
                                Evaluation[1].ParamEvaluation<5?{width:Evaluation[1].ParamEvaluation*10+'%', background:'#FF4133'}
                                :{width:Evaluation[1].ParamEvaluation*10+'%', background:'#0ECFAA'}}/>
                        </div>}
                    </div>
                    <div className='col table-col-3 info-col-text color-rgba-88'>
                        {MyExpertsList[1] && <div className='row-b no-gutters w-100 margin-top-12'>
                            {this.getAvatar(MyExpertsList[1])}
                            <div className='col text-block'>
                                <div className='row-b expert-name-text w-100 no-gutters color-rgba-88'>{MyExpertsList[1].user.firstName} {MyExpertsList[1].user.lastName}</div>
                                <div className='row-b text-40-symbols text-one-line w-100 no-gutters'>{MyExpertsList[1].professionalDescription}</div>
                            </div>
                        </div>}
                    </div>
                    <div className='col table-col-4 info-col-text text-right color-2f3849 font-weight-500'><i className="far fa-chevron-down"/></div>
                </div>
                <div className='row-b evaluation-table-row no-gutters cursor-pointer'>
                    <div className='col table-col-1 name-col-text color-rgba-88 font-weight-500'>{Evaluation[2].ParamName}</div>
                    <div className={`col table-col-2 ${FullVersion?'table-col-2-full':''} name-col-text color-rgba-88 font-weight-500`}>
                        {Evaluation[2].ParamEvaluation}
                        {FullVersion && <div className='average-rating-line'>
                            <div className='active' style={
                                Evaluation[2].ParamEvaluation<5?{width:Evaluation[2].ParamEvaluation*10+'%', background:'#FF4133'}
                                :{width:Evaluation[2].ParamEvaluation*10+'%', background:'#0ECFAA'}}/>
                        </div>}
                    </div>
                    <div className='col table-col-3 info-col-text color-rgba-88'>
                        {MyExpertsList[2] && <div className='row-b no-gutters w-100 margin-top-12'>
                            {Evaluation[2].OuterAvatarExpert?this.drawAvatar(Evaluation[2].OuterAvatarExpert):this.getAvatar(MyExpertsList[2])}
                            <div className='col text-block'>
                                <div className='row-b expert-name-text w-100 no-gutters color-rgba-88'>{MyExpertsList[2].user.firstName} {MyExpertsList[2].user.lastName}</div>
                                <div className='row-b text-40-symbols text-one-line w-100 no-gutters'>{MyExpertsList[2].professionalDescription}</div>
                            </div>
                        </div>}
                    </div>
                    <div className='col table-col-4 info-col-text text-right color-2f3849 font-weight-500'><i className="far fa-chevron-down"/></div>
                </div>
                <div className='row-b evaluation-table-row no-gutters cursor-pointer'>
                    <div className='col table-col-1 name-col-text color-rgba-88 font-weight-500'>{Evaluation[3].ParamName}</div>
                    <div className={`col table-col-2 ${FullVersion?'table-col-2-full':''} name-col-text color-rgba-88 font-weight-500`}>
                        {Evaluation[3].ParamEvaluation}
                        {FullVersion && <div className='average-rating-line'>
                            <div className='active' style={
                                Evaluation[3].ParamEvaluation<5?{width:Evaluation[3].ParamEvaluation*10+'%', background:'#FF4133'}
                                :{width:Evaluation[3].ParamEvaluation*10+'%', background:'#0ECFAA'}}/>
                        </div>}
                    </div>
                    <div className='col table-col-3 info-col-text color-rgba-88'>
                        {MyExpertsList[3] && <div className='row-b no-gutters w-100 margin-top-12'>
                            {this.getAvatar(MyExpertsList[3])}
                            <div className='col text-block'>
                                <div className='row-b expert-name-text w-100 no-gutters color-rgba-88'>{MyExpertsList[3].user.firstName} {MyExpertsList[3].user.lastName}</div>
                                <div className='row-b text-40-symbols text-one-line w-100 no-gutters'>{MyExpertsList[3].professionalDescription}</div>
                            </div>
                        </div>}
                    </div>
                    <div className='col table-col-4 info-col-text text-right color-2f3849 font-weight-500'><i className="far fa-chevron-down"/></div>
                </div>
                <a id='parsers-download-link' href={`${config.proxy}${PDFLinks[this.props.startup.id]}`} target='_blank' style={{display:'none'}}/>
            </div>
        )
    }
}
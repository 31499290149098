import React from 'react';
import './styleMarcketplace.css'
import LustraExpert from '../../../images/ilustaciya/Lustra-Expert_rocket_DAO.svg'
import LustraInvestor from '../../../images/ilustaciya/Lustra-investor_rocket_DAO.svg'
import LustraStartup from '../../../images/ilustaciya/Lustra-Startup_rocket_DAO.svg'
import $ from 'jquery'

import Input from '../../controls3.0/input/Input'
import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import avatar from '../../expert4.0/pic/profilephoto.webp'
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import DD_1234 from "../../../images/RocketDAO-DD.png";
import Baner from '../../../images/ilustaciya/Baner-rocket_dao.png'

export default class ViewMarketplace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDot: '2',
            stateClickDots: false,
            openPopup: '0'
        }
        this.activeDot = this.activeDot.bind(this);
        this.StateClickDot = this.StateClickDot.bind(this);
        this.openCloseForm = this.openCloseForm.bind(this);
    }

    componentDidMount() {
        this.animateMarketplace('block-body-header-marketplace')
    }

    openCloseForm(key) {
        this.setState({openPopup: key})
    }

    animateMarketplace(classParent) {
        let settings = {
            timeSlide: 10000
        }


        let dot1 = $('.block-item-dot-header-marketplace.dot-1');
        let dot2 = $('.block-item-dot-header-marketplace.dot-2');
        let dot3 = $('.block-item-dot-header-marketplace.dot-3');

        let activeDot = '1';

        let timer = setInterval(() => {
            dot1.click();
            activeDot = '2';
            this.setState({activeDot: '2'})
            clearInterval(timer);
        }, 1000);

        let timeanim = setInterval(() => {
            switch (this.state.activeDot) {
                case '1' :
                    dot1.click();
                    activeDot = '2';
                    this.setState({activeDot: '2'})
                    break;
                case '2' :
                    dot2.click();
                    activeDot = '3';
                    this.setState({activeDot: '3'})
                    break;
                case '3' :
                    dot3.click();
                    activeDot = '1';
                    this.setState({activeDot: '1'})
                    break;
            }
        }, settings.timeSlide + 800);


        let timeGlobal = setInterval(() => {
            if (this.state.stateClickDots) {
                clearInterval(timeanim);
                this.setState({stateClickDots: false});
                timeanim = setInterval(() => {
                    switch (this.state.activeDot) {
                        case '1' :
                            dot1.click();
                            activeDot = '2';
                            this.setState({activeDot: '2'})
                            break;
                        case '2' :
                            dot2.click();
                            activeDot = '3';
                            this.setState({activeDot: '3'})
                            break;
                        case '3' :
                            dot3.click();
                            activeDot = '1';
                            this.setState({activeDot: '1'})
                            break;
                    }
                }, settings.timeSlide + 800);
            }
        }, 500)
    }


    activeDot(e) {
        this.setState({
            activeDot: e
        })
    }

    StateClickDot() {
        this.setState({
            stateClickDots: true
        })
    }

    removeDelDots(dot) {
        if (dot.hasClass('start') || dot.hasClass('active')) {
            dot.removeClass('start');
            dot.removeClass('active');
            dot.addClass('remove');
            let timer1 = setInterval(function () {
                dot.removeClass('remove');
                dot.addClass('delete');
                let timer2 = setInterval(function () {
                    dot.removeClass('delete');
                    clearInterval(timer2)
                }, 100);
                clearInterval(timer1)
            }, 600)
        }
    }

    dataAnim(dot) {
        let title = $('.block-info-header-marketplace p.title-header-marketplace');
        let description = $('.block-info-header-marketplace p.description-header-marketplace');
        let buttonColor = $('.block-info-header-marketplace .block-button-color-header-marketplace');
        let buttonColorText = $('.block-info-header-marketplace .block-button-color-header-marketplace.color p.title-button-color-header-marketplace');
        // let buttonWhite = $('.block-info-header-marketplace .block-button-color-header-marketplace.white');
        let buttonWhiteText = $('.block-info-header-marketplace .block-button-color-header-marketplace.white p.title-button-color-header-marketplace');
        // let buttonWhiteSpan = $('.block-info-header-marketplace .block-button-color-header-marketplace.white p.title-button-color-header-marketplace span');
        title.css({opacity: '0'});
        description.css({opacity: '0'});
        buttonColorText.css({opacity: '0'});
        buttonWhiteText.css({opacity: '0'});
        let timStat = setInterval(() => {
            if (dot === 1) {
                title.css({maxWidth: '480px'})
            }
            if (dot === 2) {
                title.css({maxWidth: '480px'})
            }
            if (dot === 3) {
                title.css({maxWidth: '590px'})
            }
            clearInterval(timStat)
        }, 200)

        let timer = setInterval(() => {
            if (dot === 1) {
                title.css({maxWidth: '480px'})
                title.text('For startups: Accelerate your startup and find investors');
                description.text('Get support from the expert community and present your hi-tech project to investors.');
                buttonColor.removeClass('blue')
                buttonColor.removeClass('green')
                buttonColor.addClass('black')
                buttonColorText.text('Register your startup')

                buttonWhiteText.html('Startups<span class="right-title-button">80</span>')
            }
            if (dot === 2) {
                title.css({maxWidth: '480px'})
                title.text('For experts: Support, evaluate and boost hi-tech startups');
                description.text('Get use of your unique knowledge to conduct independent startup Due Diligence.');
                buttonColor.removeClass('black')
                buttonColor.removeClass('blue')
                buttonColor.addClass('green')
                buttonColorText.text('Join expert community')

                buttonWhiteText.html('Experts<span class="right-title-button">140</span>')
            }
            if (dot === 3) {
                title.css({maxWidth: '590px'})
                title.text('For investors: Get access to a pool of evaluated projects fitting your interests');
                description.text('Study startups with our Due Diligence reports and track success of promising projects. ');
                buttonColor.removeClass('green')
                buttonColor.removeClass('black')
                buttonColor.addClass('blue')
                buttonColorText.text('Create investor profile')

                buttonWhiteText.html('Investors<span class="right-title-button">425</span>')
            }
            title.css({opacity: '1'});
            description.css({opacity: '1'});
            buttonColorText.css({opacity: '1'});
            buttonWhiteText.css({opacity: '1'});
            clearInterval(timer)
        }, 400)


    }

    clickItemDotMarketplace(e) {
        let dot1 = $('.block-item-dot-header-marketplace.dot-1');
        let dot2 = $('.block-item-dot-header-marketplace.dot-2');
        let dot3 = $('.block-item-dot-header-marketplace.dot-3');


        if ($(e.currentTarget).hasClass('dot-1')) {
            this.removeDelDots(dot2);
            this.removeDelDots(dot3);
            dot1.removeClass('start');
            let timet = setInterval(() => {
                dot1.removeClass('remove');
                dot1.removeClass('delete');
                dot1.addClass('active');
                dot2.addClass('start');
                this.dataAnim(1);
                clearInterval(timet)
            }, 100)

        }
        if ($(e.currentTarget).hasClass('dot-2')) {
            this.removeDelDots(dot1);
            this.removeDelDots(dot3);
            dot2.removeClass('start');
            let timet = setInterval(() => {
                dot2.removeClass('remove');
                dot2.removeClass('delete');
                dot2.addClass('active');
                dot3.addClass('start');
                this.dataAnim(2);
                clearInterval(timet)
            }, 100)
        }
        if ($(e.currentTarget).hasClass('dot-3')) {
            this.removeDelDots(dot1);
            this.removeDelDots(dot2);
            dot3.removeClass('start');
            let timet = setInterval(() => {
                dot3.removeClass('remove');
                dot3.removeClass('delete');
                dot3.addClass('active');
                dot1.addClass('start');
                this.dataAnim(3);
                clearInterval(timet)
            }, 100)
        }
    }


    render() {


        return (
            <div className="block-header-marketplace">
                <div className="block-top-header-marketplace">
                    <div className="block-logo">
                        <a href="">
                            <img src="" alt=""/>
                        </a>
                    </div>
                </div>
                <div className="block-body-header-marketplace">
                    <div className="block-dots-header-marketplace">
                        <div className="shell-dots-state" onClick={() => this.StateClickDot()}>
                            <div className="block-item-dot-header-marketplace dot-1"
                                 onClick={(e) => {
                                     this.clickItemDotMarketplace(e);
                                     this.activeDot('2')
                                 }}/>
                            <div className="block-item-dot-header-marketplace dot-2"
                                 onClick={(e) => {
                                     this.clickItemDotMarketplace(e);
                                     this.activeDot('3')
                                 }}/>
                            <div className="block-item-dot-header-marketplace dot-3"
                                 onClick={(e) => {
                                     this.clickItemDotMarketplace(e);
                                     this.activeDot('1')
                                 }}/>
                        </div>
                    </div>
                    <div className="block-info-header-marketplace">
                        <p className="title-header-marketplace">
                            For startups:
                            Accelerate your startup and find investors
                        </p>
                        <p className="description-header-marketplace">
                            Get support from the expert community and present your hi-tech project to investors.
                        </p>
                        <div className="block-button-color-header-marketplace color black"
                             onClick={() => this.openCloseForm(this.state.activeDot)}>
                            <p className="title-button-color-header-marketplace">
                                Register your startup
                            </p>
                        </div>
                        <div className="block-button-color-header-marketplace white">
                            <p className="title-button-color-header-marketplace">
                                Startups
                                <span className="right-title-button">56</span>
                            </p>
                        </div>
                    </div>
                    <img
                        src={this.state.activeDot === '1' ? LustraInvestor : this.state.activeDot === '2' ? LustraStartup : LustraExpert}
                        alt="" className="illustrate-header-marketplace"/>
                </div>

                <div className="block-form-email-marketplace">
                    <p className="title-form-email-marketplace">
                        Be the first to know the public release day and receive a lifetime discount on all subscription
                        types.
                    </p>
                    <div className="block-input-button-form-email-marketplace">
                        <Input
                            type={'email'}
                            name={'email'}
                            value={''}
                            placeholder={'Your email'}
                            onChange={(e) => console.log(e)}
                            error={'error'}
                        />
                        <ButtonBlue
                            TitleButton={'Request early access'}
                            EventOnClick={null}
                            paddingButton={'50'}
                            disabled={false}
                            loader={false}
                        />
                    </div>
                </div>


                <div className="block-popup-form-marketplace"
                     style={this.state.openPopup !== '0' ? {display: 'block'} : {display: 'none'}}>
                    <div className="block-form-marketplace">
                        <p className="title-form-marketplace">
                            Dear friend!
                        </p>
                        {this.state.openPopup === '1' &&
                        <p className="description-form-marketplace">
                            We will launch the platform within a month. Please, leave your email below to be in-time
                            notified about the release and get the priority right to create your Investor profile on
                            Rocket DAO.
                        </p>
                        }
                        {this.state.openPopup === '2' &&
                        <p className="description-form-marketplace">
                            We will launch the platform within a month. Please, leave your email below to be in-time
                            notified about the release and get the priority right to register your startup on Rocket
                            DAO.
                        </p>
                        }
                        {this.state.openPopup === '3' &&
                        <p className="description-form-marketplace">
                            We will launch the platform within a month. Please, leave your email below to be in-time
                            notified about the release and get the priority right to join Rocket DAO expert community.
                        </p>
                        }
                        <div className="block-input-button-form-email-marketplace">
                            <Input
                                type={'email'}
                                name={'email'}
                                value={''}
                                placeholder={'Your email'}
                                onChange={(e) => console.log(e)}
                                error={'error'}
                            />
                            <ButtonBlue
                                TitleButton={'Request early access'}
                                EventOnClick={null}
                                paddingButton={'50'}
                                disabled={false}
                                loader={false}
                            />
                        </div>
                        <div className="block-close-popup-form-marketplace" onClick={() => this.openCloseForm('0')}>
                            <i className="fal fa-times"/>
                        </div>
                    </div>
                </div>


                <div className="block-header-marketplace">
                    <div className="block-top-title-button-marketplace">
                        <p className="title-top-marketplace">
                            Explore, evaluate, invest.
                        </p>
                        <div className="block-list-button-top-marketplace">
                            <div className="block-item-button">
                                <p className="title-item-button">
                                    Startups
                                    <span className="right">
                                        6798
                                    </span>
                                </p>
                            </div>
                            <div className="block-item-button">
                                <p className="title-item-button">
                                    Experts
                                    <span className="right">
                                        175
                                    </span>
                                </p>
                            </div>
                            <div className="block-item-button">
                                <p className="title-item-button">
                                    Investors
                                    <span className="right">
                                        911
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            New Startups
                        </p>
                        <p className="description-list-cart-marketplace">
                            Be the first to discover rising stars!
                        </p>

                        <div className="list-cart-marketplace">
                            <div className="block-cart-new-startup-marketplace">
                                <div className="block-top-cart-new-startup-marketplace">
                                    <div className="block-bg-top-cart-new-startup-marketplace">
                                        <div className="bg-top-cart-new-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-icon-play-top-cart-new-startup-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                    </div>
                                    <div className="block-ing-cart-new-startup-marketplace">
                                        <div className="img-cart-new-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-startup-marketplace">
                                    <p className="title-body-cart-new-startup-marketplace">
                                        Skinive
                                    </p>
                                    <p className="description-body-cart-new-startup-marketplace">
                                        Skinive is there for you whenever and wherever you need make skin health
                                        self-examination
                                    </p>
                                    <div className="block-list-industries-body-cart-new-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-new-startup-marketplace">
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-cart-new-startup-marketplace">
                                <div className="block-top-cart-new-startup-marketplace">
                                    <div className="block-bg-top-cart-new-startup-marketplace">
                                        <div className="bg-top-cart-new-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-icon-play-top-cart-new-startup-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                    </div>
                                    <div className="block-ing-cart-new-startup-marketplace">
                                        <div className="img-cart-new-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-startup-marketplace">
                                    <p className="title-body-cart-new-startup-marketplace">
                                        Skinive
                                    </p>
                                    <p className="description-body-cart-new-startup-marketplace">
                                        Skinive is there for you whenever and wherever you need make skin health
                                        self-examination
                                    </p>
                                    <div className="block-list-industries-body-cart-new-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-new-startup-marketplace">
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-cart-new-startup-marketplace">
                                <div className="block-top-cart-new-startup-marketplace">
                                    <div className="block-bg-top-cart-new-startup-marketplace">
                                        <div className="bg-top-cart-new-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-icon-play-top-cart-new-startup-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                    </div>
                                    <div className="block-ing-cart-new-startup-marketplace">
                                        <div className="img-cart-new-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-startup-marketplace">
                                    <p className="title-body-cart-new-startup-marketplace">
                                        Skinive
                                    </p>
                                    <p className="description-body-cart-new-startup-marketplace">
                                        Skinive is there for you whenever and wherever you need make skin health
                                        self-examination
                                    </p>
                                    <div className="block-list-industries-body-cart-new-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-new-startup-marketplace">
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-new-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className="more-list-cart-marketplace">
                            More new startups <i className="far fa-angle-right"/>
                        </p>
                    </div>

                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            New startups with Video Pitches
                        </p>
                        <p className="description-list-cart-marketplace">
                            See the list of featured startups which published their video presentations
                        </p>

                        <div className="list-cart-marketplace">

                            <div className="block-cart-new-video-marketplace">
                                <div className="block-top-cart-new-video-marketplace">
                                    <div className="block-bg-top-cart-new-video-marketplace">
                                        <div className="bg-top-cart-new-video-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-button-play-top-cart-new-video-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                        <div className="block-button-path-top-cart-new-video-marketplace">
                                            <p className="title-button-path-top-cart-new-video-marketplace">
                                                Video Pitch
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-img-top-cart-new-video-marketplace">
                                        <div className="img-top-cart-new-video-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-video-marketplace">
                                    <p className="title-body-cart-new-video-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                </div>
                            </div>

                            <div className="block-cart-new-video-marketplace">
                                <div className="block-top-cart-new-video-marketplace">
                                    <div className="block-bg-top-cart-new-video-marketplace">
                                        <div className="bg-top-cart-new-video-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-button-play-top-cart-new-video-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                        <div className="block-button-path-top-cart-new-video-marketplace">
                                            <p className="title-button-path-top-cart-new-video-marketplace">
                                                Video Pitch
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-img-top-cart-new-video-marketplace">
                                        <div className="img-top-cart-new-video-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-video-marketplace">
                                    <p className="title-body-cart-new-video-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                </div>
                            </div>

                            <div className="block-cart-new-video-marketplace">
                                <div className="block-top-cart-new-video-marketplace">
                                    <div className="block-bg-top-cart-new-video-marketplace">
                                        <div className="bg-top-cart-new-video-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                        <div className="block-button-play-top-cart-new-video-marketplace">
                                            <i className="fas fa-play"/>
                                        </div>
                                        <div className="block-button-path-top-cart-new-video-marketplace">
                                            <p className="title-button-path-top-cart-new-video-marketplace">
                                                Video Pitch
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-img-top-cart-new-video-marketplace">
                                        <div className="img-top-cart-new-video-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-new-video-marketplace">
                                    <p className="title-body-cart-new-video-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                </div>
                            </div>

                        </div>

                        <p className="more-list-cart-marketplace">
                            More new startups with video pitches <i className="far fa-angle-right"/>
                        </p>
                    </div>

                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            Startups with business plans
                        </p>
                        <p className="description-list-cart-marketplace">
                            See the list of featured startups which published their business plans
                        </p>

                        <div className="list-cart-marketplace">

                            <div className="block-cart-startup-marketplace">
                                <div className="block-top-cart-startup-marketplace">
                                    <div className="block-bg-top-cart-startup-marketplace">
                                        <div className="bg-top-cart-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                    <div className="block-img-top-cart-startup-marketplace">
                                        <div className="img-top-cart-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-startup-marketplace">
                                    <p className="title-body-cart-startup-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                    <p className="description-body-cart-startup-marketplace">
                                        The Storj network uses client-side encryption so data owners can trust that only
                                        can access files.
                                    </p>
                                    <div className="block-list-industries-body-cart-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-startup-marketplace">
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-bottom-cart-startup-marketplace">
                                    <p className="title-bottom-cart-startup-marketplace">
                                        Due Diligence
                                    </p>
                                    <p className="description-bottom-cart-startup-marketplace">
                                        29 views
                                    </p>
                                </div>
                            </div>

                            <div className="block-cart-startup-marketplace">
                                <div className="block-top-cart-startup-marketplace">
                                    <div className="block-bg-top-cart-startup-marketplace">
                                        <div className="bg-top-cart-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                    <div className="block-img-top-cart-startup-marketplace">
                                        <div className="img-top-cart-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-startup-marketplace">
                                    <p className="title-body-cart-startup-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                    <p className="description-body-cart-startup-marketplace">
                                        The Storj network uses client-side encryption so data owners can trust that only
                                        can access files.
                                    </p>
                                    <div className="block-list-industries-body-cart-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-startup-marketplace">
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-bottom-cart-startup-marketplace">
                                    <p className="title-bottom-cart-startup-marketplace">
                                        Due Diligence
                                    </p>
                                    <p className="description-bottom-cart-startup-marketplace">
                                        29 views
                                    </p>
                                </div>
                            </div>

                            <div className="block-cart-startup-marketplace">
                                <div className="block-top-cart-startup-marketplace">
                                    <div className="block-bg-top-cart-startup-marketplace">
                                        <div className="bg-top-cart-startup-marketplace" style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                    <div className="block-img-top-cart-startup-marketplace">
                                        <div className="img-top-cart-startup-marketplace"
                                             style={{backgroundImage: `url(${avatar})`}}/>
                                    </div>
                                </div>
                                <div className="block-body-cart-startup-marketplace">
                                    <p className="title-body-cart-startup-marketplace">
                                        Panasonic HomeHawk FLOOR: Camera & Ambient Light
                                    </p>
                                    <p className="description-body-cart-startup-marketplace">
                                        The Storj network uses client-side encryption so data owners can trust that only
                                        can access files.
                                    </p>
                                    <div className="block-list-industries-body-cart-startup-marketplace">
                                        <p className="title-industries-body-cart-new-startup-marketplace">
                                            Industries
                                        </p>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                E-commerce & Online Marketplace
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Transpotation & Mobility
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                Travel & Leasure
                                            </p>
                                        </div>
                                        <div className="block-item-industries-body-cart-startup-marketplace">
                                            <p className="title-item-industries-body-cart-startup-marketplace">
                                                4 more…
                                            </p>
                                        </div>
                                    </div>
                                    <div className="block-list-button-body-cart-startup-marketplace">
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="fas fa-heart"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                125K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace m">
                                            <i className="far fa-clock"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                $110.5K
                                            </p>
                                        </div>
                                        <div className="block-item-button-body-cart-startup-marketplace">
                                            <i className="far fa-share-alt"/>
                                            <p className="title-item-button-body-cart-startup-marketplace">
                                                12304
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="block-bottom-cart-startup-marketplace">
                                    <p className="title-bottom-cart-startup-marketplace">
                                        Due Diligence
                                    </p>
                                    <p className="description-bottom-cart-startup-marketplace">
                                        29 views
                                    </p>
                                </div>
                            </div>

                        </div>

                        <p className="more-list-cart-marketplace">
                            More new startups with a business plan <i className="far fa-angle-right"/>
                        </p>
                    </div>


                    <div className="block-banner-marketplace" style={{margin:'80px 0 80px'}}>
                        <div className="block-left-banner-marketplace">
                            <p className="title-banner-marketplace">
                                Due Diligence report
                            </p>
                            <p className="description-banner-marketplace">
                                Rocket DAO experts conducted the first public Due Diligence.
                            </p>
                            <ButtonGreen
                                TitleButton={'Here is the full report'}
                                EventOnClick={() => window.open('https://rocketdao.io/DueD/Multy.pdf')}
                                paddingButton={'20'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-right-banner-marketplace">
                            <img src={DD_1234} alt=""/>
                        </div>
                    </div>


                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            Venture Funds and Angel Networks
                        </p>
                        <p className="description-list-cart-marketplace">
                            Top investment funds and networks of the month
                        </p>

                        <div className="list-cart-marketplace">

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <p className="more-list-cart-marketplace">
                            More venture funds and angel networks <i className="far fa-angle-right"/>
                        </p>
                    </div>

                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            Angel Investors
                        </p>
                        <p className="description-list-cart-marketplace">
                            Top investment angels of the month
                        </p>

                        <div className="list-cart-marketplace">

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>

                            <div className='all-card-block'>
                                <div className='all-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Fund</div>
                                        <div className='name'>Space Etherium Fund</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>
                                <div className='all-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className='industries title'>Industries</div>
                                    <div className="industries-list">
                                        <span>E-commerce & Online Marketplace</span>
                                        <span>Transpotation & Mobility</span>
                                        <span>Travel & Leasure</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>
                                <div className='all-card-bottom'>
                                    <div className='fund-size'>
                                        <div className='title'>Fund size</div>
                                        <div className='subtitle'>$20M</div>
                                    </div>
                                    <div className='investment-size'>
                                        <div className='title'>Investment size</div>
                                        <div className='subtitle'>$100-500K</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <p className="more-list-cart-marketplace">
                            More investors <i className="far fa-angle-right"/>
                        </p>
                    </div>



                    <div className="block-new-banner-get-email" style={{backgroundImage: `url(${Baner})`}}>
                        <p className="title-new-banner-get-email">
                            Get list of metrics that every startup and investor should know and track!
                        </p>
                        <div className="block-input-button-new-banner-get-email">
                            <Input
                                type={'email'}
                                name={'email'}
                                value={''}
                                placeholder={'Your email'}
                                onChange={(e) => console.log(e)}
                                error={'error'}
                            />
                            <ButtonBlue
                                TitleButton={'Get my copy'}
                                EventOnClick={null}
                                paddingButton={'30'}
                                disabled={false}
                                loader={false}
                            />
                        </div>
                    </div>



                    <div className="block-list-cart-marketplace">
                        <p className="title-list-cart-marketplace">
                            Top Experts
                        </p>
                        <p className="description-list-cart-marketplace">
                            Experts with highest RocketDAO verification rate
                        </p>

                        <div className="list-cart-marketplace">

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>

                            <div className='all-card-block expert-card-block'>
                                <div className='all-card-top expert-card-top'>
                                    <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                                    <div className='info-conteiner'>
                                        <div className='title'>Expert</div>
                                        <div className='name'>Andrey Miroshnichenko</div>
                                        <span className="check">
                                            <i className="fal fa-check"/>
                                        </span>
                                    </div>
                                </div>

                                <div className='all-card-content expert-card-content'>
                                    <div className='description subtitle'>The Storj network uses client-side encryption
                                        so data owners can trust that only can access files.
                                    </div>
                                    <div className='location subtitle'>New York, NY</div>
                                    <div className="industries-list expert-industries-list">
                                        <span>Design</span>
                                        <span>Design UI</span>
                                        <span>Design UX</span>
                                        <span>Calobration</span>
                                        <span>Desperados</span>
                                        <span>Design</span>
                                        <span>4 more…</span>
                                    </div>
                                </div>

                                <div className='all-card-bottom expert-card-bottom'>
                                    <div className='title'>Evaluation parameters</div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '40%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Resources and Assets</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '11%'}}/>
                                    </div>
                                    <div className='evaluation-parameters-title'>Marketing Strategy</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: '81%'}}/>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <p className="more-list-cart-marketplace">
                            More experts <i className="far fa-angle-right"/>
                        </p>
                    </div>


                    <div className="block-list-success-stories block-gradient-gray-reg">
                        <p className="title-latest-articles">Community feedback in Rocket DAO</p>


                        <div className="list-item-success-stories">
                            <div className="block-item-success-stories left-item-success-stories">
                                <div className="block-left-item-success-stories">
                                    <div className="img-item-success-stories" style={{backgroundImage: `url(${avatar})`}}/>
                                </div>
                                <div className="block-right-item-success-stories">
                                    <p className="title-item-success-stories">
                                        “Our clients receive their products faster and are more satisfied with our services”
                                    </p>
                                    <p className="description-item-success-stories">
                                        Kate Holmes, Freelance artist
                                    </p>
                                    <a href="" className="link-item-success-stories">
                                        kateholmes.com
                                    </a>
                                </div>
                            </div>
                            <div className="block-item-success-stories right-item-success-stories">
                                <div className="block-left-item-success-stories">
                                    <p className="title-item-success-stories">
                                        “Our clients receive their products faster and are more satisfied with our services”
                                    </p>
                                    <p className="description-item-success-stories">
                                        Kate Holmes, Freelance artist
                                    </p>
                                    <a href="" className="link-item-success-stories">
                                        kateholmes.com
                                    </a>
                                </div>
                                <div className="block-right-item-success-stories">
                                    <div className="img-item-success-stories" style={{backgroundImage: `url(${avatar})`}}/>
                                </div>
                            </div>
                            <div className="block-item-success-stories left-item-success-stories">
                                <div className="block-left-item-success-stories">
                                    <div className="img-item-success-stories" style={{backgroundImage: `url(${avatar})`}}/>
                                </div>
                                <div className="block-right-item-success-stories">
                                    <p className="title-item-success-stories">
                                        “Clients are more satisfied with our services”
                                    </p>
                                    <p className="description-item-success-stories">
                                        Kate Holmes, Freelance artist
                                    </p>
                                    <a href="" className="link-item-success-stories">
                                        kateholmes.com
                                    </a>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div className="block-list-articles">

                        <p className="title-latest-articles">The latest articles</p>
                        <div className='latest-articles-block'>
                            <div className='main-post-block'>
                                <div className='main-post-img' style={{backgroundImage: `url(${avatar})`}}/>
                                <div className='date-title'>12 Feb 2019</div>
                                <div className='main-post-title'>How to build a startup unicorn? Part 1</div>
                                <div className='main-post-descr'>High time to lift the veil of secrecy and explain how
                                    startups should get prepared for fundraising. Below we will tell you what investors
                                    pay special attention to while making their investment decisions
                                </div>
                                <div className='arrow'/>
                            </div>

                            <div className='left-post-block bottom-post'>
                                <div className='bottom-post-img' style={{backgroundImage: `url(${avatar})`}}/>
                                <div className='date-title'>24 Jan 2019</div>
                            </div>

                            <div className='center-post-block bottom-post'>
                                <div className='bottom-post-img' style={{backgroundImage: `url(${avatar})`}}/>
                                <div className='date-title'>24 Jan 2019</div>
                            </div>

                            <div className='right-post-block bottom-post'>
                                <div className='bottom-post-img' style={{backgroundImage: `url(${avatar})`}}/>
                                <div className='date-title'>24 Jan 2019</div>
                            </div>

                            <div className='latest-articles-block-button'>
                                <ButtonBlue
                                    TitleButton={'Explore Rocket DAO blog'}
                                    EventOnClick={null}
                                    paddingButton={'0'}
                                    disabled={false}
                                />
                            </div>

                        </div>


                    </div>


                    <div className="block-want-know-more">
                        <p className="title-want-know-more">
                            Want to know more?
                        </p>
                        <p className="description-want-know-more">
                            Rocket DAO is an ecosystem product for more effective interaction between startups and investors under the supervision of the expert community.
                        </p>
                        <ButtonBlue
                            TitleButton={'Learn more about Rocket DAO'}
                            EventOnClick={null}
                            paddingButton={'40'}
                            disabled={false}
                        />
                    </div>


                </div>


                <div className="block-list-cart-marketplace">


                </div>


            </div>
        );
    }
}
export const accelerator = (state = { accelerator: undefined }, action = {}) => {
  switch (action.type) {
    case "UPDATE_ACCELERATOR_INFO":
    case "GET_ACCELERATOR_INFO":
      return {
          ...action.accelerator
      };
    default:
      return state;
  }
};
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateFund} from "../../../actions/Login";
import {getMoneyView} from "../../../utils/transformation";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import InputRange from 'react-input-range';
import CheckBox from "../../controls3.0/checkBox/checkBox";


class Step11 extends React.Component {
    constructor(props) {
        super();
        let fund = props.fund,
            investor = fund.investor || {};
        investor.noTurnover = fund.noTurnover;

        this.state = {
            fund: fund,
            investor: investor,
            errors: {},
            value: fund.turnover || {min: 100000, max: 700000}
        }
    }

    componentDidMount() {
        if (this.state.investor.turnover && this.state.investor.turnover.min) {
            this.setState({
                value: {
                    ...this.state.value,
                    min: this.state.investor.turnover.min,
                    max: this.state.investor.turnover.max
                }
            })
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({investor: {...this.state.investor, [name]: value}})
    }

    onChangeRange = value => {
        this.setState({value: value})
    }

    onCheck = () => {
        this.setState({investor: {...this.state.investor, ['noTurnover']: !this.state.investor.noTurnover}})
    }

    calculate = () => {
        let investor = this.state.investor;
        if (investor.turnover) this.setState({value: investor.turnover})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) {
            if (!obj.noTurnover) obj.turnover = this.state.value;
        }

        obj.event_type = 'fund_turnover_stage_10';

        this.setState({loading: true});

        const fundId = this.props.fund.id;
        this.props.updateFund(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {fund, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-step-1-reg" style={{position: 'relative'}}>

                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                Startup turnover stage (in USD)
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20"
                               style={{maxWidth: '650px'}}>
                                When making your investment decisions do you take into account the current startups'
                                turnover?
                                If so, what is the range that satisfies you?
                            </p>
                            <p className="text-button color-text-reg-gray margin-0">
                                If you also work with project teams that have no cash flow yet, put a tick below.
                            </p>

                        </div>
                    </div>

                    <div className="container-step-1-reg container-bottom block-gradient-gray-reg padding-reg"
                         style={{position: 'relative'}}>

                        <div
                            className="block-input-range slider slider-green shell-margin-bottom-40 margin-top-50">
                            <p className="title-input-range" style={{minHeight: '45px'}}>Turnover
                                {!investor.noTurnover &&
                                <div className='data-slider-turnover'>
                                    <span className='subtext'>from</span>
                                    <span className="value">{getMoneyView(this.state.value.min)}</span>
                                    <span className='subtext'>to</span>
                                    <span className="value">{getMoneyView(this.state.value.max)}</span>
                                </div>
                                }
                            </p>

                            <div className={`container-input-range ${investor.noTurnover ? 'disable' : ''}`}>
                                <InputRange
                                    minValue={5000}
                                    maxValue={5000000}
                                    step={500}
                                    value={this.state.value}
                                    onChange={value => this.onChangeRange(value)}/>
                            </div>
                            <p className="title-left-right-input-range">
                                <span className="left">5k</span>
                                <span className="right">5m+</span>
                            </p>

                            <div className='container-inline-checkbox' onClick={() => this.onCheck()}>
                                <CheckBox
                                    onClick={() => {
                                    }}
                                    active={investor.noTurnover}
                                />
                                Turnover is not important
                            </div>



                            {/*<p className="title-left-right-input-range">*/}
                            {/*<span className="left">0</span>*/}
                            {/*<span className="right">{MAXCS}</span>*/}
                            {/*</p>*/}
                        </div>


                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(9, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(11)}
                                paddingButton={'54'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund
})

Step11.propTypes = {
    updateFund: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateFund})(Step11)
import React, {useState, useEffect, useMemo} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import moment from 'moment';
import './style.scss'
import MenuButton from '../../v4/MenuButtons/ProfileButton';
import ApolloClient from "apollo-boost"
import {gql} from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

import history from '../../../history';
import menuLogo from '../menu/profileMenuFrame/img/Rocket_DAO-Block.svg';

import config from "../../../config";
import {connect, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {updateMenu} from "../../../actions/menu";
import {createAvatar} from "../../user/createAvatar"
import {Row} from "../../v4/Grid/Row";
import UseCloseButton from "../../v4/closeButton";
import {Button} from "../../v4/Button";
import {isExist} from "../../../utils/helper";

import ServiceIcon from "../../v4/MenuButtons/ServiceButtons";
import NewLikeBlockToList from "../menu/profileMenuFrame/NewLikeBlockToList";
import {CartPopUp} from "../../v3/startupPopupPage";

const GET_USER = gql`
    query {
        user{
            id
            email
            lastName
            firstName
            logoFileId
            unsubscribe
            startupList{
                id
                title
                shortDescription
                logoFileId
                invest_sum
                team{
                    isFounder
                }
                isFinished
            }
            fundList{
                id
                title
                logoFileId
                shortDescription
                team{
                    isFounder
                }
                isFinished
                
            }
            acceleratorList{
                id
                title
                shortDescription
                logoFileId
                countTeam
                team{
                    isFounder
                }
                isFinished
            }
            angelNetworkList{
                id
                title
                shortDescription
                logoFileId
                team{
                    isFounder
                }
                isFinished
            }
            expert{
                id
                professionalIdentification
                parameters
                isFinished
            }
            investor{
                id
                professionalIdentification
                isFinished
            }
            likes
        }
    }
`;


const pathToObject = (template, path) => {
    const objectKeys = template.split('/:');
    const object = {};
    const pathValues = path.split('/');
    pathValues.shift();
    objectKeys.shift();

    for (let i = 0; i <objectKeys.length; i++) {
        object[ objectKeys[i] ] = pathValues[i];
    }

    return object;
}
// const cache = new InMemoryCache();

const client = new ApolloClient({
    // cache,
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const GodMenu = (props) => {
    const [cartPopupData, setCartPopupData] = useState({showCartPopup: false});
    const [showMenu, setShowMenu] = useState(false);

    let _refetch = () => {};

    // useEffect(() => {
    //     return () => {closeNeverHideMenu(); closeMenu()};
    // }, []);

    useEffect(() => {
        try {
            if (props.menu.object.type === 'update') {
                _refetch();
            }
            if (props.menu.object.type === 'close') {
                closeMenu();
            }
            if (props.menu.object.type === 'open') {
                document.querySelector('.god-menu').classList.remove('god-close');

                if(/edit/.test(history.location.pathname) && !/edit\/user/.test(history.location.pathname) ){
                    const subMenu = document.querySelector(`.never-hide`);
                    if(subMenu){
                        subMenu.classList.add(`active`);
                    }
                }
            }

            if(props.menu.object.type !== '-1'){
                if (props.updateMenu) {
                    props.updateMenu({type: '-1'});
                }
            }

        } catch (e) {
            if (props.updateMenu) {
                props.updateMenu({type: '-1'});
            }
        }
    }, [props.menu]);

    const SubHead = ({backHandle, closeHandle, children, theme=''}) => {
        return (
            <Row className={`m8 m-0 rw sub-head align-items-center ${theme}`}>
                <div className={`col-auto h_back ${ /edit/.test(history.location.pathname) && 'edit' } `}>
                    <UseCloseButton type={theme==='black'?'back-black':'back-menu'} onClick={backHandle}/>
                </div>
                <div className={'col title'}>
                    {children}
                </div>
                <div className={'col-auto h_close'}>
                    <UseCloseButton type={theme===''?'white':''} onClick={closeHandle}/>
                </div>
            </Row>
        )
    };

    const SubBlock = ({key, logo, title, description, id, type, isCompleted, object}) => {
        return (
            <Row k={key} className={'m8 m-0 sub-block'} onClick={() => clickHandle(id, type, isCompleted, object)}>
                {logo !== false && <div className={'col-auto'}>
                   <div className={'menu-logo'}>
                       { logo &&  <img src={logo}/> }
                   </div>
                </div>}
                <div className={'col'} style={{maxWidth:'calc(100% - 65px)'}}>
                    <div className={'title'}>{title}</div>
                    <div className={'type'}>
                        {(() => {
                            switch (type) {
                                case 'investor':{
                                    return '';
                                }
                                case 'fund':
                                case 'angel_network':{
                                    return `${type.replace('_', ' ').toUpperCase()} - Admin`
                                }
                                default:
                                    return 'Owner';
                            }
                        })()}
                    </div>
                    {/*{isCompleted && (type === 'fund' || type === 'angel_network' || type === 'accelerator') && <div className={'type'}>Members - {isExist(() => object.team.length)}</div>}*/}
                    {!isCompleted && <div className={'description'}>Continue registration</div> }
                </div>
            </Row>
        )
    };

    const CreateSubBlock = ({key, logo, title, description, id, type, isCompleted, object}) => {
        return (
            <Row k={key} className={'m8 m-0 sub-block'} onClick={() => clickHandle(id, type, isCompleted, object)}>
                <div className={'col-auto d-sm-none align-items-center'}>
                    <div className={'menu-logo flex align-items-center justify-content-center'}>
                        <MenuButton
                            type='plus'
                            active={true}
                        />
                    </div>
                </div>
                <div className={'col'}>
                    <div className={'title create-title'}>{title}</div>
                    <div className={'description'}>{isCompleted ? description : 'Continue registration'}</div>
                </div>
            </Row>
        )
    };

    const RoleMenuList = ({type, object}) => {
        const teamCount = useSelector((state) => state.fund.teamCount);

        switch(type){
            case 'startup': {
                return <React.Fragment>
                    <RoleMenuItem title={'Team'} value={ isExist(() => object.team.length)} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'team' )}/>
                    <RoleMenuItem title={'Evaluation'} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'evaluation' )}/>
                    <RoleMenuItem title={'Dashboard'} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'dashboard' )}/>
                    <RoleMenuItem title={'Waitlist'} value={ ( isExist(() => object.invest_sum.moneyFormat()) || 0) + '$' } disable={true}/>
                    <RoleMenuItem title={'Newsfeed'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Traction'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Metrics'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Community'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            case 'accelerator': {
                return <React.Fragment>
                    <RoleMenuItem title={'Team'} value={isExist(() => object.team.length)} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'team' )}/>
                    <RoleMenuItem title={'Newsfeed'} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'newsfeed' )}/>
                    <RoleMenuItem title={'Applications'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Demo day'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Startups'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            case 'investor': {
                return <React.Fragment>
                    <RoleMenuItem title={'Waitlist'}/>
                    <RoleMenuItem title={'Newsfeed'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Investing'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            case 'fund': {
                return <React.Fragment>
                    <RoleMenuItem title={'Members'} value={isExist(() => object.team.length)} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'members' )}/>
                    <RoleMenuItem title={'Waitlist'} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'waitlist' )}/>
                    <RoleMenuItem title={'Newsfeed'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Investing'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            case 'angel_network': {
                return <React.Fragment>
                    <RoleMenuItem title={'Members'} value={isExist(() => teamCount)} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'members' ) }/>
                    <RoleMenuItem title={'Waitlist'} onClick={() => clickHandle(object.id, type, object.isFinished, object, 'waitlist' )}/>
                    <RoleMenuItem title={'Newsfeed'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Investing'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            case 'expert': {
                return <React.Fragment>
                    <RoleMenuItem title={'Expert parameters'} value={isExist(() => object.expert.parameters.length)} onClick={() => clickHandle(false, type, isExist(() =>object.expert.isFinished), object.expert, 'parameters' ) }/>
                    <RoleMenuItem title={'Knowledge base'} value={''} onClick={() => window.open( '/knowledge_base', '_blank')}/>
                    <RoleMenuItem title={'Expert tasks'} value={'Coming soon'} disable={true}/>
                    <RoleMenuItem title={'Newsfeed'} value={'Coming soon'} disable={true}/>
                </React.Fragment>
            }
            default: {
                return <React.Fragment/>
            }
        }
    };

    const RoleMenuItem = ({key, title, value, disable, onClick}) => {
        return (
            <Row className={`m3 m-0 rw role-menu-item align-items-center justify-content-between ${disable && 'disable'}`} onClick={onClick}>
                <div className={'col-auto'}>{title}</div>
                {value && <div className={'col-auto'}>{value}</div> }
            </Row>
        )
    };

    const ProfileMenu = ({profile}) => {
        const {expert, investor} = profile;

        let findObject = profile;

        const pathVal = pathToObject('/:page/:type/:id', history.location.pathname);
        const type = pathVal.type;


        if(profile.id && pathVal.page === 'edit' &&  ! (/user|expert|investor/.test(pathVal.type)) && !Number.isNaN(pathVal.id) && pathVal.type){
            findObject = profile[`${pathVal.type === 'angel_network' ? 'angelNetwork' : pathVal.type}List`].find((item) => item.id == pathVal.id);
        }

        const {id, shortDescription, isFinished:isCompleted} = findObject;

        let _id = (() =>{
            if( type === 'expert' || type === 'investor' ){
                return isExist(() => profile[type].id)
            } else {
                return id;
            }
        })();

        let logoFileId = (() =>{
            if( type === 'expert' || type === 'investor' ){
                return isExist(() => profile.logoFileId)
            } else {
                return findObject.logoFileId;
            }
        })();

        const title = (()=>{
            switch (type){
                case 'expert':
                    return 'Expert';
                case 'investor':
                    return `Individual investor`;
                case 'startup':
                case 'fund':
                case 'angel_network':
                case 'accelerator':
                    return findObject.title;
            }
        })();

        return (
            <React.Fragment>

                <SubHead backHandle={backNeverHideMenu}  closeHandle={closeNeverHideMenu}>{title}</SubHead>

                <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
                    <div className={'wrapper'}>
                        <Row className={'m6 m-0 p13 rw profile-menu'}>
                            <div className={'col-auto'}>
                                <div className={'menu-logo'}>
                                    {logoFileId && <img src={`${config.proxy}/file/${logoFileId}/view?w=48`}/> }
                                </div>
                            </div>
                            <div className={'col description'}>
                                {(()=>{
                                    if(type === 'expert'){
                                        return isExist(() => expert.professionalIdentification);
                                    } else if(type === 'investor') {
                                        return isExist(() => investor.professionalIdentification);
                                    } else {
                                        return shortDescription;
                                    }
                                })()}
                            </div>
                        </Row>
                        <Row className={'m3 m-0 rw wb'}>
                            <div className={'col-6'}>
                                <Button onClick={() => clickHandle(id, type, (() =>{
                                    if( type === 'expert' || type === 'investor' ){
                                        return profile[type].isFinished
                                    } else {
                                        return isCompleted;
                                    }
                                })() , null, 'edit')}>Edit</Button>
                            </div>
                            <div className={'col-6'}>
                                <Button type={type === 'startup' ? 'black' : type} onClick={() => {setCartPopupData({
                                    showCartPopup: true,
                                    id: _id,
                                    type,
                                    profile,
                                    title
                                })}}>See profile</Button>
                            </div>
                        </Row>
                        <RoleMenuList type={type} object={findObject}/>
                    </div>
                </Scrollbars>
            </React.Fragment>
        )
    };

    const clickHandle = (id, type, isCompleted, object, page) => {
        const getDefaultPage = (type) => {
            switch (type) {
                case 'accelerator':{
                    return 'newsfeed'
                }
                case 'fund':
                case 'angel_network':{
                    return 'members'
                }
                case 'startup': {
                    return 'team'
                }
            }
        }
        switch (type) {
            case 'expert':
            case 'investor': {
                if(isCompleted){
                    if(page) {
                        history.push(`/edit/${type}${ page!=='/edit' ? `/${page}` : ''}`);
                    } else {
                        if(type === 'investor'){
                            history.push(`/edit/${type}/waitlist`);
                        } else {
                            history.push(`/edit/${type}/parameters`);
                        }
                    }
                } else {
                    window.open(`/registration/${type}/${object.registrationStep}`, '_blank')
                }
                break;
            }
            case 'accelerator':
            case 'angel_network':
            case 'fund' :
            case 'startup': {
                if (isCompleted) {
                    if(page){
                        history.push(`/edit/${type}/${id}${page!=='edit' ? `/${page}` : ''}`);
                    } else {
                        history.push(`/edit/${type}/${id}/${getDefaultPage(type)}`);
                    }
                } else {
                    if(type !== 'startup'){
                        window.open(`/create/${type}/1?id=${id}`, '_blank')
                    } else  {
                        window.open(`/registration/${type}/1?id=${id}`, '_blank')
                    }
                }
                break;
            }

            case 'create_startup': {
                window.open(`/registration/startup/1`, '_blank');
                break;
            }

            case 'create_expert': {
                window.open(`/start_reg?type=2`, '_blank');
                break;
            }
            case 'create_investor': {
                window.open(`/start_reg?type=1`, '_blank');
                break;
            }
            case 'become_investor': {
                window.open(`/start_investor`, '_blank');
                break;
            }
            case 'create_fund': {
                window.open(`/create/fund/1`, '_blank');
                break;
            }
            case 'create_angel_network': {
                window.open(`/create/angel_network/1`, '_blank');
                break;
            }
            case 'create_accelerator': {
                window.open(`/start_accelerator`, '_blank');
                break;
            }
        }



        window.scrollTo(0,0);
        if(isMobile()){
            if(page){
                closeNeverHideMenu();
            } else {
                props.updateMenu({type: 'open'});
            }
        } else {
            if(history.location.pathname === `/edit/${type}/${id}/${getDefaultPage(type)}`
                || history.location.pathname === `/edit/${type}/newsfeed`
                || history.location.pathname === `/edit/${type}/waitlist`
                || history.location.pathname === `/edit/${type}/parameters`
            ){
                closeMenu(false);
            }
        }
    }

    const activeMenu = (type) => {
        try {
            const sections = document.querySelectorAll(`.sub-menu:not(.${type})`);
            sections.forEach(i => {
                i.classList.remove('active');
            });

            const subMenu = document.querySelector(`.sub-menu.${type}`);
            if(subMenu)
                subMenu.classList.add('active');

            const allButtons = document.querySelectorAll(`.menu-icon:not(.current)`);
            allButtons.forEach((i) => {
                i.classList.remove('active');
            });

            const clickedButton = document.querySelector(`.menu-icon.${type}`);

            if(clickedButton)
                clickedButton.classList.add('active');
        } catch (e) {
            console.log('error');
        }
    };

    const closeMenu = () => {
        if( /edit/.test(history.location.pathname) && window.innerWidth > 756){
            activeMenu(false);
        } else {
            activeMenu(false);
            const subMenu = document.querySelector(`.god-menu`);
            subMenu.classList.add('god-close');
            try {
                document.querySelector(`.block-search-header-input`).style.left = '0';
                setTimeout(()=>{
                    try{
                        document.querySelector(`.logo-img`).style.height = '16px';
                    } catch (e) {

                    }

                }, 200)
            } catch {}
        }
    }

    const closeNeverHideMenu = () => {
        if( /edit/.test(history.location.pathname) && window.innerWidth <= 756){
            const subMenu = document.querySelector(`.god-menu`);
            const subMenu2 = document.querySelector(`.never-hide-menu`);
            if(subMenu2){
                subMenu2.classList.remove('active');
            }
            if(subMenu){
                subMenu.classList.add('god-close');
            }
        }
    };

    const backNeverHideMenu = () => {
        if( /edit/.test(history.location.pathname) && window.innerWidth <= 756){
            const subMenu2 = document.querySelector(`.never-hide-menu`);
            if(subMenu2){

                const pathVal = pathToObject('/:page/:type/:id', history.location.pathname);
                const type = pathVal.type;

                activeMenu((() => {
                    switch (type){
                        case 'expert':
                        case 'accelerator':
                        case 'investor':
                        case 'startup':{
                            return type;
                        }
                        case 'angel_network':
                        case 'fund':{
                            return 'investor'
                        }
                    }
                })());
                subMenu2.classList.remove('active');
            }
        }
    }

    const logout = () => {
        localStorage.removeItem('RocketToken');
        history.push({pathname: `/`});
        setTimeout(() => {
            window.location.reload();
        }, 200)
    };

    const goToLogin = () => {
        localStorage.removeItem('referer');
        localStorage.removeItem('referer_startup');
        localStorage.setItem('rFrom', window.location.pathname)
        history.push('/login')
    };

    const goToSettings = (page) => {
        history.push(`/edit/user/${page}`);
        window.scrollTo(0, 0,);
    };

    const isMobile = () => {
        return window.innerWidth < 756;
    };

    const { loading, error, data, refetch} = useQuery(GET_USER, {
        client: client,
        fetchPolicy: 'cache-and-network',
    });

    const profileMenu = useMemo(() => isExist(() => data.user) && <ProfileMenu profile={data.user}/>, [data, props]);

    const subStartupMenu = useMemo(() => isExist(() => data.user) && <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
        <div className={'wrapper'}>
            {(data.user.startupList.map((startup, i) => {
                return <SubBlock
                    key={i}
                    id={startup.id}
                    type={'startup'}
                    title={startup.title}
                    logo={startup.logoFileId ? `${config.proxy}/file/${startup.logoFileId}/view` : null}
                    description={startup.shortDescription}
                    isCompleted={startup.isFinished}
                    object={startup}
                />
            }))}
            <CreateSubBlock
                key={'SubBlockS'}
                type={'create_startup'}
                title={'Create startup'}
                logo={false}
                description={'Create your startup profile and become visible to experts and investors '}
                isCompleted={true}
            />
        </div>
    </Scrollbars>, [data]);

    const subLikesMenu = useMemo(() => isExist(() => data.user) && <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
        <div className={'wrapper'}>
            {data.user.likes.map((i, k) => {
                if (i.Project) {
                    return (
                        <NewLikeBlockToList key={k}
                                            idStartup={i.Project.id}
                                            avatar={i.Project.logoFileId ? `${config.proxy}/file/${i.Project.logoFileId}/view` : null}
                                            name={i.Project.title}
                                            descr={i.Project.shortDescription}
                                            startup={i.Project}
                                            time={moment(i.updatedAt).fromNow()}
                        />
                    )
                } else {

                }
            })}
        </div>
    </Scrollbars>, [data]);

    const subInvestorMenu = useMemo(() => isExist(() => data.user) && <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
        <div className={'wrapper'}>

            { isExist(() => data.user.investor.id) && <SubBlock
                key={'SubBlockI'}
                type={'investor'}
                title={'Individual investor'}
                logo={data.user.logoFileId ? `${config.proxy}/file/${data.user.logoFileId}/view` : null}
                description={isExist(() => data.user.investor.professionalIdentification) }
                isCompleted={data.user.investor?data.user.investor.isFinished:false}
                object={data.user}
            /> }

            {(data.user.fundList.map((fund, i) => {
                return <SubBlock
                    key={i}
                    id={fund.id}
                    type={'fund'}
                    title={fund.title}
                    logo={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                    description={fund.shortDescription}
                    isCompleted={fund.isFinished}
                    object={fund}
                />
            }))}
            {(data.user.angelNetworkList.map((angelNetwork, i) => {
                return <SubBlock
                    key={i}
                    id={angelNetwork.id}
                    type={'angel_network'}
                    title={angelNetwork.title}
                    logo={angelNetwork.logoFileId ? `${config.proxy}/file/${angelNetwork.logoFileId}/view` : null}
                    description={angelNetwork.shortDescription}
                    isCompleted={angelNetwork.isFinished}
                    object={angelNetwork}
                />
            }))}

            { !isExist(() => data.user.investor.id) && <CreateSubBlock
                key={'SubBlockAN1'}
                type={'create_investor'}
                title={'Become individual investor'}
                logo={false}
                description={'Create a personal profile of an individual investor or business angel'}
                isCompleted={true}
            />}

            <CreateSubBlock
                key={'SubBlockAN2'}
                type={'create_fund'}
                title={'Create Fund profile'}
                logo={false}
                description={'Create a profile of your Venture capital fund (with the proof of ownership)'}
                isCompleted={true}
            />
            <CreateSubBlock
                key={'SubBlockAN3'}
                type={'create_angel_network'}
                title={'Create Angel network profile'}
                logo={false}
                description={'Create a profile of your Business angels network (with the proof of ownership)'}
                isCompleted={true}
            />
        </div>
    </Scrollbars>, [data]);

    const subAcceleratorMenu = useMemo(() => isExist(() => data.user) && <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
        <div className={'wrapper'}>
            {(data.user.acceleratorList.map((accelerator, i) => {
                return <SubBlock
                    key={i}
                    id={accelerator.id}
                    type={'accelerator'}
                    title={accelerator.title}
                    logo={accelerator.logoFileId ? `${config.proxy}/file/${accelerator.logoFileId}/view` : null}
                    description={accelerator.shortDescription}
                    isCompleted={accelerator.isFinished}
                    object={accelerator}
                />
            }))}

            <CreateSubBlock
                key={'SubBlockA'}
                type={'create_accelerator'}
                title={'Create Accelerator profile'}
                logo={false}
                description={'Register private, corparate, or government accelerator'}
                isCompleted={true}
            />
        </div>
    </Scrollbars>, [data]);

    const subPlusMenu = useMemo(() => isExist(() => data.user) && <div className={'sub-menu plus'}>
        <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}/>
        <Scrollbars autoHeight autoHeightMax={window.innerHeight - 65}>
            <div className={'wrapper'}>
                <CreateSubBlock
                    key={'SubBlockS23'}
                    type={'create_startup'}
                    title={'Create startup'}
                    description={'Create your startup profile and become visible to experts and investors '}
                    isCompleted={true}
                />
                <CreateSubBlock
                    key={'SubBlockI'}
                    type={'become_investor'}
                    title={'Become investor'}
                    description={'Register as individual investor, venture fund or business angel network '}
                    isCompleted={true}
                />
                <CreateSubBlock
                    key={'CreateSubBlockExpert1'}
                    type={'create_expert'}
                    title={'Join experts'}
                    description={'Join expert community to conduct evaluation of startups and earn money on it'}
                    isCompleted={true}
                />
                <CreateSubBlock
                    key={'CreateSubBlockAccelerator1'}
                    type={'create_accelerator'}
                    title={'Add accelerator'}
                    description={'Register private, corporate, or government accelerator '}
                    isCompleted={true}
                />
            </div>
        </Scrollbars>
    </div>, [data]);

    const subMainMenu = isExist(() => data.user) && <div id='main-menu' className={'main-menu'}>
        <Scrollbars autoHeight autoHeightMin={window.innerHeight} autoHeightMax={window.innerHeight} className={'fullW'}>
            <div className='main-logo' onClick={()=>{ history.push('/'); closeMenu(); }}>
                <img src={menuLogo}/>
            </div>
            <div className='mobile-button-close hide-highlight'>
                <UseCloseButton type={'white'} onClick={closeMenu}/>
            </div>
            <div className='mobile-links'>
                <div className='title' onClick={()=>{ history.push('/'); closeMenu(); }}>Marketplace</div>
                <div className='title' onClick={()=>{ activeMenu('about') }}>About</div>
                <div className='title' onClick={()=>{ window.open('https://startupjedi.vc/') }}>Blog</div>
            </div>
            <div className='icons-block'>
                <ServiceIcon type={'like'} onClick={ () => activeMenu('likes')} className={'hide-highlight'}>Likes</ServiceIcon>
                <ServiceIcon type={'chat'} disable={true}>Messages</ServiceIcon>
                <ServiceIcon type={'bell'} disable={true}>Notifications</ServiceIcon>
            </div>
            <div className='profile-buttons-wrapper'>
                <MenuButton type='startup' active={false} onClick={ () => activeMenu('startup')} className={`hide-highlight ${/edit\/startup/.test(history.location.pathname) ? 'active current' : ''}`}>My startup</MenuButton>

                <MenuButton type='investor' active={false} onClick={ () => activeMenu('investor')} className={`hide-highlight ${/edit\/investor|edit\/fund|edit\/angel_network/.test(history.location.pathname) ? 'active current' : ''}`}>My investor profile</MenuButton>

                {isExist(() => data.user.expert.isFinished) && <MenuButton
                    type='expert'
                    active={false}
                    onClick={ () => {
                        clickHandle(-0, 'expert', data.user.expert.isFinished, data.user);
                        activeMenu(false);
                    }}
                    className={`hide-highlight ${/edit\/expert/.test(history.location.pathname) ? 'active current' : ''}`}>My expert profile</MenuButton>}

                <MenuButton type='accelerator' active={false} onClick={ () => activeMenu('accelerator')} className={`hide-highlight ${/edit\/accelerator/.test(history.location.pathname) ? 'active current' : ''}`}>My accelerator profile</MenuButton>

                <MenuButton type='plus' active={true} onClick={ () => activeMenu('plus')} className={`hide-highlight`}/>
            </div>

            {data.user.logoFileId && <div className='user-avatar'
                                          style={{backgroundImage: `url(${`${config.proxy}/file/${data.user.logoFileId}/view`})`}}
                                          onMouseEnter={() => {document.querySelector('.user-settings').classList.add('active')}}
                                          onMouseLeave={() => {document.querySelector('.user-settings').classList.remove('active')}}
            /> ||

            <div className='user-avatar'
                 onMouseEnter={() => {document.querySelector('.user-settings').classList.add('active')}}
                 onMouseLeave={() => {document.querySelector('.user-settings').classList.remove('active')}}>
                {createAvatar('min', data.user.firstName || 'R')}
            </div>
            }
            {/*desktop settings*/}
            <div className='user-settings'
                 onMouseEnter={() => {document.querySelector('.user-settings').classList.add('active')}}
                 onMouseLeave={() => {document.querySelector('.user-settings').classList.remove('active')}}
            >
                <div
                    className='settings-popup-line-name settings-popup-name'>{data.user.firstName} {data.user.lastName}
                    </div>
                {(props.auth.user.investClubPartner || props.auth.user.investClubAdmin || props.auth.user.investClubSuperAdmin) && (<div className='settings-popup-line settings-popup-title' onClick={()=>{ history.push("/investclub")}} >
                    investclub.vc
                </div>)}
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ goToSettings('profile') }}>
                    Profile
                </div>
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ goToSettings('account') }}>
                    Account
                </div>
                <div className='settings-popup-line settings-popup-title last-title' onClick={()=>{ logout() }}>
                    Logout
                </div>
            </div>
            {/*mobile settings*/}
            <div className='user-settings-mobile'>
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ goToSettings('profile');closeMenu(); }}>
                    Profile
                </div>
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ goToSettings('account');closeMenu(); }}>
                    Account settings
                </div>
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ logout() }}>
                    Logout
                </div>
            </div>
        </Scrollbars>
    </div>;

    _refetch = refetch;

    if(isExist(() => data.user.id)){

        return ( <div className={`god-menu ${ (!/(edit|investclub)/.test(history.location.pathname) || isMobile()) && 'god-close' }`}>
            {subMainMenu}

            <div className={`sub-menu startup`}>
                <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}>Startup</SubHead>
                {subStartupMenu}
            </div>

            <div className={`sub-menu likes`}>
                <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu} theme={'black'}>Likes</SubHead>
                {subLikesMenu}
            </div>

            <div className={'sub-menu investor'}>
                <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}>Investor</SubHead>
                {subInvestorMenu}
            </div>

            <div className={'sub-menu accelerator'}>
                <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}>Accelerator</SubHead>
                {subAcceleratorMenu}
            </div>

            {subPlusMenu}

            <div className={'sub-menu about'}>
                <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}>About</SubHead>
                <div className={'wrapper mobile-links'}>
                    <div className='title' onClick={()=>{ window.open('/about') }}>About the platform</div>
                    <div className='title' onClick={()=>{ window.open('/evaluation/landing/startup') }}>Evaluation for startups</div>
                    <div className='title' onClick={()=>{ window.open('/evaluation/landing/investor') }}>Evaluation for investors</div>
                </div>
            </div>

            <div className={`never-hide never-hide-menu ${ !isMobile() && /edit/.test(history.location.pathname) && !/edit\/user/.test(history.location.pathname) && 'active'}`}>
                {profileMenu}
            </div>

            {cartPopupData.showCartPopup && <CartPopUp id={cartPopupData.id} user={cartPopupData.profile} type={cartPopupData.type} type2={''} oneItem={true} close={() => setCartPopupData({
                showCartPopup: false
            })}/> }

        </div>)
    } else return <div className={`god-menu ${ (!/edit/.test(history.location.pathname) || isMobile()) && 'god-close'}`}>
        <div id='main-menu' className={'main-menu'}>
            <div className='main-logo' onClick={()=>{ closeMenu(); history.push('/'); }}>
                <img src={menuLogo}/>
            </div>
            <div className='mobile-button-close hide-highlight'>
                <UseCloseButton type={'white'} onClick={closeMenu}/>
            </div>
            <div className='mobile-links'>
                <div className='title' onClick={()=>{ history.push('/'); closeMenu(); }}>Marketplace</div>
                <div className='title' onClick={()=>{ clickHandle(0,'create_startup') }}>Create startup</div>
                <div className='title' onClick={()=>{ window.open('/start_reg?type=6') }}>Become investor</div>
                <div className='title' onClick={()=>{ clickHandle(0,'create_expert') }}>Join experts</div>
                <div className='title' onClick={()=>{ clickHandle(0,'create_accelerator') }}>Add accelerator</div>
                <div className='title' onClick={()=>{ activeMenu('about') }}>About</div>
                <div className='title' onClick={()=>{ window.open('https://startupjedi.vc/') }}>Blog</div>
            </div>

            <div className='user-settings-mobile login-wrapper'>
                <div className='settings-popup-line settings-popup-title' onClick={()=>{ goToLogin() }}>
                    Login
                </div>
            </div>
        </div>
        <div className={'sub-menu about'}>
            <SubHead backHandle={() => activeMenu(false)} closeHandle={closeMenu}>About</SubHead>
            <div className={'wrapper mobile-links'}>
                <div className='title' onClick={()=>{ window.open('/about') }}>About the platform</div>
                <div className='title' onClick={()=>{ window.open('/evaluation/landing/startup') }}>Evaluation for startups</div>
                <div className='title' onClick={()=>{ window.open('/evaluation/landing/investor') }}>Evaluation for investors</div>
            </div>
        </div>
    </div>
};

const mapStateToProps = (state) => ({
    ...state
});

GodMenu.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps, {updateMenu})(GodMenu);
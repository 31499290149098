import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {logout, tokenLogin, updateUser} from '../../actions/Login'
import Header from '../header'
import Footer from '../footer/Footer'
import '../test-controls/Page2.0/css/style.css'
import '../test-controls/Page2.0/css/StartPage.css'
import '../home/home.css'
import '../css/grid.css'
import '../css/container.css'
import '../css/Input.css'
import defaultAvatar from '../../images/default_avatar.png'
import ButtonTransBlue2_0 from '../controls2.0/Button2.0/ButtonTransBlue2.0'



import '../controls2.0/ProgressBar2.0/ProgressBar2.0.css';
import packageJSON from './../../../package.json'
import moment from 'moment';
import Loader from './../test-controls/preloader/preloader'
import {getListExperts, putExpert} from "../../actions/Profile";
import AvatarUser from '../controls2.0/avatarUser'
import {userLink} from "../../utils/getUserLink";

var skip = 0,
    take = 10


class ExpertPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            listExperts: [],
            search: '',
            loaded: false,
            currentPage: 1,
            lastPage: 1,
            arrPages: []

        };
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        this.getListExperts('', skip, take)
    }


    onChangeSearch = (e) => {
        let name = e.target.name,
            value = e.target.value;
        this.setState({[name]: value});
        this.getListExperts(value, 0, 100)
    }

    getListExperts = (string, skip, take) => {
        getListExperts(string, skip, take)
            .then((res)=>{
                // let sortedArr = res.data.sort ( (a, b) => {
                //     return new Date(b.createdAt) - new Date(a.createdAt);
                // });



                let countPages = Math.ceil(res.data.total/take);
                if(!countPages) countPages = 1;
                this.setState({listExperts: res.data.experts, countPosts: res.data.total, lastPage: countPages})
                let arrPages = Array.from({length: countPages}, (v, k) => k+1);
                this.setState({arrPages: arrPages, loaded: true})

                // this.setState({listExperts: sortedArr, loaded: true})

            })
            .catch(error => {
                console.error('error', error.response.data.error);
                this.setState({ errors: error.response.data.error, loaded: true })
            })
    }

    changeRole = (key, role) => {
        let obj = {
            role: role
        }
        let list = this.state.listExperts,
            id = list[key].id;

        this.setState({loaded: false})
        putExpert(id, obj)
            .then((res)=>{
                list[key] = res.data;
                // $('.block-button-drop-down')[0].css('height', 0);
                //
                // var buttons = document.getElementsByClassName('block-button-drop-down')
                // for (var i = 0; i < buttons.length; i++){
                //
                // }

                this.setState({listExperts: list, loaded: true})
            })
            .catch(error => {
                this.setState({loaded: true})
            })
    }

    openUser = (expert, e) => {
        if(e.target.className == 'no-click-link')return

        if(expert.user){
            if(this.props.user && this.props.user.id == expert.user.id){
                window.location.href='/profile/funds'
            } else {
                window.location.href='/users/' + expert.user.id
            }
        }
    }

    goToPage = (page) => {
        this.setState({currentPage: page})
        skip = page*take - take;
        this.getListExperts('', skip, take)
    }


    render() {
        let user = this.props.user || {};


        let listExpertsForAdmin = this.state.listExperts.map((item, key) => {
            return (
                <div className="block-item-grid-DAO" key={key} onClick={(e) => this.openUser(item, e)}>
                    <div className="block-column-grid-DAO block-column-person-grid-experts">
                        <div className="block-person-img-name-surname-grid-experts">
                            <div className="block-img-person-grid-experts add-indicator">
                                    <AvatarUser imgId={item.user.logoFileId}/>

                                {item.user.kycStatus == 'Approved' && <span className='notification-indicator'></span>}

                            </div>
                            <div className="block-name-surname-grid-experts">
                                <p className="name">{item.user.firstName}</p>
                                <p className="surname">{item.user.lastName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-skill-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.skills.map((item2, key2) => {
                                    return (
                                        <span className="inline-span">{item2.title}</span>
                                    )
                                })}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-country-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.countryOfResidence}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-language-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.lang}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-social-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <div className="block-social-grid-experts">
                                <div className="block-email">
                                    <a href={"mailto:" + item.expertEmail}
                                       className="email">{item.expertEmail}</a>
                                </div>
                                <div className="block-link-social">
                                    {item.facebookLink &&
                                    <a href={item.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a>
                                    }
                                    {item.linkedInLink &&
                                    <a href={item.linkedInLink} target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                    }
                                    {item.twitterLink &&
                                    <a href={item.twitterLink} target="_blank"><i className="fab fa-twitter"></i></a>
                                    }
                                    {item.telegramLink &&
                                    <a href={'http://t.me/' + item.telegramLink.replace(/@/, '')} target="_blank"><i className="fab fa-telegram"></i></a>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-date-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss')}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-role-grid-experts">

                        <div className="shell-button-drop-down close" id="button-drop-down-experts">
                            <div className="block-button-drop-down">
                                <div className={'block-header-button-drop-down ' + (item.role == 'block'? 'block': '')}>
                                    <p className={'title-button-drop-down-experts'}>
                                        {item.role.slice(0,1).toUpperCase() + item.role.slice(1, item.role.length)}
                                    </p>
                                </div>
                                <div className="block-body-button-drop-down" count-items="6">
                                    <div className="block-list-items-button-drop-down">

                                        <div className="block-item-button-drop-down " color-button="#00F082" color-title="#000000" onClick={() => this.changeRole(key, 'new')}>
                                            <p className={item.role == 'new'? 'active': 'no-click-link'}>NEW</p>
                                        </div>
                                        <div className="block-item-button-drop-down" color-button="#0028DC" color-title="#FFFFFF" onClick={() => this.changeRole(key, 'candidate')}>
                                            <p className={item.role == 'candidate'? 'active': 'no-click-link'}>Candidate</p>
                                        </div>
                                        <div className="block-item-button-drop-down" color-button="#0028DC" color-title="#FFFFFF" onClick={() => this.changeRole(key, 'analyst')}>
                                            <p className={item.role == 'analyst'? 'active': 'no-click-link'}>Analyst</p>
                                        </div>
                                        <div className="block-item-button-drop-down" color-button="#0028DC" color-title="#FFFFFF" onClick={() => this.changeRole(key, 'expert')}>
                                            <p className={item.role == 'expert'? 'active': 'no-click-link'}>Expert</p>
                                        </div>
                                        <div className="block-item-button-drop-down" color-button="#0028DC" color-title="#FFFFFF" onClick={() => this.changeRole(key, 'methodologist')}>
                                            <p className={item.role == 'methodologist'? 'active': 'no-click-link'}>Methodologist</p>
                                        </div>
                                        <div className="block-item-button-drop-down" color-button="#FA4600" color-title="#FFFFFF" onClick={() => this.changeRole(key, 'block')}>
                                            <p className={item.role == 'block'? 'active': 'no-click-link'}>Block</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            );
        });

        let listExperts = this.state.listExperts.map((item, key) => {
            return (
                <div>
                {(item.role != 'block') &&
                <div className="block-item-grid-DAO" onClick={(e) => this.openUser(item, e)}>
                    <div className="block-column-grid-DAO block-column-person-grid-experts">
                        <div className="block-person-img-name-surname-grid-experts">
                            <div className="block-img-person-grid-experts">
                                {item.user.logoFileId &&
                                <img src={packageJSON.proxy + '/file/' + (item.user.logoFileId) + '/view'}></img>
                                }
                                {!item.user.logoFileId && <img src={defaultAvatar} alt="default"></img>}
                            </div>
                            <div className="block-name-surname-grid-experts">
                                <p className="name">{item.user.firstName}</p>
                                <p className="surname">{item.user.lastName}</p>
                            </div>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-skill-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.skills.map((item2, key2) => {
                                    return (
                                        <span className="inline-span">{item2.title}</span>
                                    )
                                })}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-country-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.countryOfResidence}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-language-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">
                                {item.lang}
                            </p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-social-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <div className="block-social-grid-experts">
                                {item.facebookLink &&
                                <a href={item.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a>
                                }
                                {item.linkedInLink &&
                                <a href={item.linkedInLink} target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                }
                                {item.twitterLink &&
                                <a href={item.twitterLink} target="_blank"><i className="fab fa-twitter"></i></a>
                                }
                                {item.telegramLink &&
                                <a href={'http://t.me/' + item.telegramLink.replace(/@/, '')} target="_blank"><i
                                    className="fab fa-telegram"></i></a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-date-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="description">{moment(item.createdAt).format('YYYY.MM.DD HH:mm:ss')}</p>
                        </div>
                    </div>
                    <div className="block-column-grid-DAO block-column-role-grid-experts">
                        <div className="shell-center-top-bottom-flex">
                            <p className="title">{item.role}</p>
                        </div>
                    </div>
                </div>
                }
                </div>
            );
        });

        let pagination = this.state.arrPages.map((item, key) => {
            return (
                <span key={key} className={(this.state.currentPage == item)? 'active' : ''} onClick={() => this.goToPage(item)}>{item}</span>
            )
        }, this)

        return (

            <div className="">

                {user.isAdmin &&
                <div className="block-container-1140">
                    <div className="block-top-title-search-experts-comming">
                        {/*<div className="float-left-80">*/}
                            {/*<p className="super-big-title">Newcoming experts</p>*/}
                        {/*</div>*/}
                        {/*<div className="float-right-20">*/}
                            <div className="block-input-DAO-new-icon" style={{width:'320px', margin:'30px 0 '}}>
                                <p className="title"></p>
                                <div className="block-input-new-icon">
                                    <input type="text" name="search"  value={this.state.search}
                                           onChange={this.onChangeSearch} placeholder="Search "/>
                                    <i className="fal fa-search"></i>
                                </div>
                                {/*<div className="block-input-new-icon">*/}
                                    {/*<input type="text" name="search" value={this.state.search}*/}
                                           {/*onChange={this.onChangeSearch} placeholder="Search experts"/>*/}
                                    {/*<i className="fal fa-search"></i>*/}
                                {/*</div>*/}

                                <p className="error"></p>
                            </div>
                        {/*</div>*/}
                    </div>


                    <div className="shell-grid-DAO" id="grid-experts-comming" style={{'marginBottom': '0'}}>
                        <div className="block-grid-DAO">
                            <div className="block-header-grid-DAO">
                                <div className="block-column-grid-DAO block-column-person-grid-experts">
                                    <div className="block-img-person-grid-experts" style={{height: 'auto'}}>
                                        <p className="title-header">KYC</p>
                                    </div>
                                    <div className="block-name-surname-grid-experts" style={{height: 'auto'}}>
                                        <p className="title-header">Name and Surname</p>
                                    </div>
                                </div>
                                <div className="block-column-grid-DAO block-column-skill-grid-experts">
                                    <p className="title-header">Expert skills</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-country-grid-experts">
                                    <p className="title-header">Country</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-language-grid-experts">
                                    <p className="title-header">Languages</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-social-grid-experts">
                                    <p className="title-header">Social networks</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-date-grid-experts">
                                    <p className="title-header">Date</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-role-grid-experts">
                                    <p className="title-header">Role</p>
                                </div>
                            </div>
                            <div className="block-body-grid-DAO">
                                <div className="block-list-items-grid-DAO">

                                    {listExpertsForAdmin}

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                }

                {!user.isAdmin &&
                <div className="header-container-footer" style={{'paddingBottom': '0'}}>

                    <section className="advising experts state-person">
                        <div className="container">
                            {/*<div className="row">*/}
                            {/*<p className="super-big-title">Top Experts</p>*/}

                            {/*{listExperts}*/}

                            {/*</div>*/}


                            {!!this.state.listExperts.length &&
                            <div className="row">
                                <p className="super-big-title margin-top-100" style={{'marginTop': '50px'}}>Newcoming experts</p>
                                <div className="shell-padding-bottom-100" style={{'paddingBottom': '0'}}>


                                    <div className="shell-grid-DAO" style={{'marginBottom': '0'}}>
                                        <div className="block-grid-DAO">
                                            <div className="block-header-grid-DAO">
                                                <div className="block-column-grid-DAO block-column-person-grid-experts">
                                                    <p className="title-header">Name and Surname</p>
                                                </div>
                                                <div className="block-column-grid-DAO block-column-skill-grid-experts">
                                                    <p className="title-header">Expert skills</p>
                                                </div>
                                                <div
                                                    className="block-column-grid-DAO block-column-country-grid-experts">
                                                    <p className="title-header">Country</p>
                                                </div>
                                                <div
                                                    className="block-column-grid-DAO block-column-language-grid-experts">
                                                    <p className="title-header">Languages</p>
                                                </div>
                                                <div className="block-column-grid-DAO block-column-social-grid-experts">
                                                    <p className="title-header">Social networks</p>
                                                </div>
                                                <div className="block-column-grid-DAO block-column-date-grid-experts">
                                                    <p className="title-header">Date</p>
                                                </div>
                                                <div className="block-column-grid-DAO block-column-role-grid-experts">
                                                    <p className="title-header">Role</p>
                                                </div>
                                            </div>
                                            <div className="block-body-grid-DAO">
                                                <div className="block-list-items-grid-DAO">

                                                    {listExperts}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }


                        </div>
                    </section>


                </div>
                }


                <div className="block-pagin" style={{'border': 'none'}}>
                    <p>
                        To the page &nbsp;
                        {pagination}
                    </p>
                </div>

                <Loader loaded={this.state.loaded}></Loader>

            </div>

        )

    }

}

// HomePage.propTypes = {
//   logout:PropTypes.func.isRequired
// }
//
// export default connect(null, {logout})(HomePage)

const mapStateToProps = (state) => ({
    user: state.auth.user
})

ExpertPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser, logout})(ExpertPage)

import React from 'react';
import './style.css'

export default class TabsWithRemove extends React.Component {

    render() {
        let {
            TitleTab,
            TypeTab, // small or big
            EventOnClick,
            active,
        } = this.props;

        let classList = active? "block-button-tab-counter tab-remove active" : "block-button-tab-counter tab-remove";
        classList += TypeTab? TypeTab == 'big'? ' big':' small':' small';

        return (
            <div className={classList} onClick={EventOnClick} >
                {/*{removeTab && <span className='zh-icon-remove fa fa-times' onClick={() => removeTab()}></span>}*/}
                <p className="title">{TitleTab}</p>
                <span className="counter no-bgc fal fa-times"></span>
            </div>
        );
    }
}


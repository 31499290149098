import React from 'react';
import "./style.css"


export default class RulesOfConduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <div className={`document`}>
                <h1 className="heading-1">
                    Rules of Conduct
                </h1>
                <h3 className="heading-1">
                    1. Rules you need to take into account wishing to join the platform
                </h3>
                <p className="description-dots" style={{margin: '14px 0 0 0'}}>
                    <span className="dot"/>
                    All information is to be published in English, this is so because we are striving to build an
                    international community where everyone can understand each other perfectly well.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    No abuse, no discrimination, no adult content, no false or unexisting content or any actions
                    violating internationally accepted human rights.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Do your best providing as full information as possible, do not leave the fields filled-in partially
                    or poorly if Skip is not available.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Photos and pictures uploaded should correspond to reality and be of satisfactory quality (the
                    optimal size is specified in the registration).
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    We have the right to remove non-venture projects from our platform.
                </p>
                <p className="description">
                    If these rules are not fully met, you account will be hidden from the public (but still available
                    via the invitation link). For any clarification please contact our support team at
                    <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>.
                </p>
                <h3 className="heading-1" style={{margin:'32px 0 0 0'}}>
                    2. Rules you need to follow while communicating with other users on the platform
                </h3>
                <p className="description-dots" style={{margin: '14px 0 0 0'}}>
                    <span className="dot"/>
                    Be respectful of others at all times. The purpose of our chat channel is to provide a platform for
                    the exchange of information. Occasionally, there will be conflicts that arise when people voice
                    opinions. Be courteous when disagreeing with others. It is possible to disagree without being
                    insulting.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Personal attacks are prohibited. Commonly known as "flaming," personal attacks are statements which
                    are designed to personally berate or insult another.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Trolling is prohibited. "Trolling" is the phrase used to describe a remark which is deliberately
                    designed for the purpose of angering and insulting the members of the community. Comments of this
                    nature are disruptive and will not be tolerated.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    No sharing of personal information. For the safety of our users, we prohibit the exchange of
                    personal information such as phone numbers and addresses in the channels.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Immediate banning from a channel may result from any of the following: discussions regarding
                    pornography or inappropriately gruesome and/or hideous subjects; discriminatory remarks which are
                    sexually explicit, harmful, threatening, abusive, defamatory, obscene, hateful, racially or
                    ethnically offensive; excessive obscene or vulgar language; the discussion of illegal activity; or
                    providing links to sites that contain any of the aforementioned.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Spamming is prohibited. "Spam" is considered to be a repetitive display of the same text again and
                    again in an effort to aggravate other channel patrons. Using all caps is also considered spamming
                    and not permitted.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Off Topic discussions and casual conversation should be kept to a minimum. We also request that
                    certain topics (religion and politics, especially) be avoided. Inevitably, conversations of this
                    nature have a tendency to result in heated arguments and flaming. Please use common sense when
                    discussing off topic themes and be considerate of others.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    It is prohibited to post or transmit any information or software which contains a virus, trojan
                    horse, worm or other harmful component.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    The violation of intellectual property rights is prohibited.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    It is not permitted to violate intellectual property rights of others, including patents,
                    trademarks, trade secrets, copyrights or other proprietary rights; violate the intellectual property
                    or other rights of Rocket DAO, including, without limitation, using the word “Rocket DAO” or our
                    logos in any business name, email, or URL.
                </p>
                <p className="description">
                    To report a problem user or to register a complaint, please file a petition or e-mail
                    <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>.
                </p>
                <h3 className="heading-1">
                    3. Penalty / sanctions / punishment policy
                </h3>
                <p className="description">
                    You can't violate (or help or encourage others to violate) these rules or our policies:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    In the case of violation or intent to break the corresponding rules and policies you will receive a
                    warning or we can remove any content or information you share on the platform if we believe that it
                    violates these Rules of Conduct, our policies or we are permitted or required to do so by law.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    In the case of gross or repeated violation of the corresponding rules and policies or if you create
                    risk or legal exposure for us, we can block your public profile and account in the system without
                    the possibility of recovery to protect our community.
                </p>
                <p className="description">
                    If you believe your account has been terminated in error contact our support team at <a
                    href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>.
                </p>
            </div>
        );
    }
}

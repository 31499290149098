import axios from 'axios'
import {getProjectAction, updateProjectInfo} from "./ProjectActions";
import _ from "lodash";
import {goToStep} from "../utils/regNavigation";
import {updateUserInfo} from "./AuthActions";
import {showMessage} from "../utils/showMessage";

export const createProject = (data) => {
  return axios.post('/v2/projects', data)
}

export const getLastProject = (noStep = false) => {
    return dispatch => {

        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        let url = '/v2/projects/last';

        if (id) {
            url = `/v2/projects/my/${id}`;

            return axios
                .get(url)
                .then(res => {
                    let step = 0;
                    if (_.isNil(res.data.error)) {
                        if(res.data.error){
                            step =1;
                        }

                        dispatch(getProjectAction(res.data));

                        if(res.data && res.data.registrationStep === null){
                            step = 1;
                        }

                        if(res.data && res.data.registrationStep){
                            step = res.data.registrationStep
                        }

                        if(!noStep){
                            goToStep(step);
                        }
                    }
                })
                .catch(error => {
                    if(!noStep){
                        goToStep(1);
                    }
                });
        }
    };
};

export const getMyProject = (id) => {
    const url =  `/v2/projects/my/${id}`;

return axios
    .get(url)
    .then(res => {
        return res.data;
    })
    .catch(error => {
        return error;
    });
}

export const getMyProject2 = (id) => {
    console.log('getMyProject2')
    const url =  `/v2/projects/my/${id}`;
    return dispatch => {
        return axios
            .get(url)
            .then(res => {
                console.log(res.data);
                dispatch(getProjectAction(res.data));
                return res.data;
            })
            .catch(error => {
                return error;
            });
    }
}

export const getProject = (id) => {
  return axios.get('/v2/projects/' + id)
}

export const updateProject = (id, data) => {
  // return axios.put('/v2/projects/' + id, data)

    return dispatch => {
        return axios
            .put(`/v2/projects/${id}`, data)
            .then(res => {
                dispatch(updateProjectInfo(res.data));
                return res
            })
            .catch(err => {
                //console.log(err)
                //showMessage({type: 'error', data: err})
                return err
            })
    };
}

export const getProjectAchievements = (id, achId) => {
    return axios.get(`/v2/projects/${id}/achievements/${achId}`)
}

export const getProjectAchievementsPublic = (id, achId) => {
    return axios.get(`/v2/projects/${id}/achievements/${achId}/public`)
}


export const postProjectAchievements = (id, achId, data) => {
    return axios.post(`/v2/projects/${id}/achievements/${achId}`, data)
}

export const putProjectAchievements = (id, pId, achId, data) => {
    return axios.put(`/v2/projects/${id}/achievements/${pId}/${achId}`, data)
}

export const delProjectAchievements = (id, pId, achId) => {
    return axios.delete(`/v2/projects/${id}/achievements/${pId}/${achId}`)
}

export const sendInvitation = (id, data) => {
    return axios.post(`/v2/projects/${id}/team/invite/`, data)
}

export const pitchProject = (id, data) => {
    return axios.post(`/v2/projects/${id}/pitch/`, data)
}

export const getListIndustries = () => {
    return axios.get('/v2/default/industries/')
}
export const getListFocuses = () => {
    return axios.get('/v2/default/market-focus/')
}

export const getListProjects = (id) => {
   return axios.get('/projects')
}
export const getProjectsPopular = (id) => {
    return axios.get('/projects/popular')
}

export const getProjectInfoBalance = (id) => {
    return axios.get(`/projects/${id}/summary`)
}

export const getUsers = (string) => {
  return axios.get('/users')
}

export const getStartup = (id) => {
  return axios.get(`/v2/projects/${id}/public`)
}

export const likeDislikeTask = (id, like) => {
  return axios.post('/likes/task/' + id, {value: like})
}
export const sendComment = (id, obj) => {
  return axios.post('/comments/' + obj.type + '/' + id, obj)
}

export const postTeam = (projectId, obj) => {
    return axios.post('/projects/' + projectId +  '/team', obj)
}
export const editTeam = (projectId, user) => {
    return axios.put('/projects/' + projectId +  '/team/' + user.id, user)
}

export const sendETHToProject = (id, obj) => {
    return axios.post(`/projects/${id}/invest`, obj)
}

export const viewDD = (id) => {
    return axios.get(`/v2/projects/due`);
};

export const notifyFollowers = async  (id) => {
    return axios.post(`/v2/projects/${id}/notify/followers`)
};


import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {logout, tokenLogin} from "../../actions/Login";

import Header from '../header';
import Footer from '../footer/Footer'
import Routes from './routes'
import {Container} from "../v4/Grid/Container";
import '../header/menu/fundMenuProfile/style.css'

class RegistrationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            type: props.match.params.type,
            page: props.match.params.page,
        }
    }

    componentDidMount() {
        if(localStorage.RocketToken){
            this.props.tokenLogin(localStorage.RocketToken);
        }
    }

    // componentDidUpdate(prevProps) {
    //
    //     console.log(this.props.match);
    //
    //     if( this.props.match.params.id !== this.state.id
    //         || this.props.match.params.type !== this.state.type
    //         || this.props.match.params.page !== this.state.page) {
    //
    //         this.setState({
    //             id: this.props.match.params.id,
    //             type: this.props.match.params.type,
    //             page: this.props.match.params.page,
    //         })
    //     }
    // }

    render() {

        const {id, type, page} = this.props.match.params;

        const clientWidth = document.documentElement.clientWidth;
        return (
            <React.Fragment>
                {/*<div className={`${this.state.type === 'user'?'mobile-menu-hide':''}`}>*/}
                    <Header
                        user={this.props.user}
                        onlyMenu={clientWidth > 756}
                        offSticky={clientWidth > 756}
                        EditHeaderTitle={(!page && clientWidth < 576 && type!=='user')?type:null}
                    />
                    {/*<GodMenu/>*/}

                <Container mod={'-fluid'} className={'container-profile'}>
                    <Routes user={this.props.user} type={type} id={id} page={page}/>
                </Container>

                {/*<Footer/>*/}
                <NotificationContainer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

RegistrationContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(RegistrationContainer)
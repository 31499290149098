import React from 'react';
export default class StartupDashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className='block-community-temp-text'>
                <div className='profile-dashboard-main-title font-weight-500'>Community</div>

                    <div className='profile-dashboard-18-title font-weight-500'><br/>The full version of the community tab is now being developed. It will come very soon!</div>
                    <div className='profile-dashboard-18-title'>
                    <br/>Here you will be able to monitor and manage the community formed around to your startup: experts, individual investors, venture funds, angel networks, and other startups which 'liked' your starup or joined your waitlist.
                    <br/><br/>You will have the tools to message them directly from this tab as well
                    </div>
                <div className='block-community-temp-content'/>
            </div>

        )
    }
}
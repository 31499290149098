import React from 'react';
import Input from '../controls3.0/input/Input'
import './style.css'
import ButtonBlack from '../controls3.0/button/ButtonBlack'
import history from "../../history";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {tokenLogin} from "../../actions/Login";
import axios from "axios"

class eventRegEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                firstName: '',
                lastName: '',
            },
            loader: false
            , errors: {}
        };

        if(!localStorage.RocketToken){
            history.push('/slush/auth')
            window.scrollTo(0,0);
        } else {
            this.props.tokenLogin(localStorage.RocketToken)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.user){
            nextProps.user.lastName = nextProps.user.lastName.replace(/#/g,'');
            nextProps.user.firstName = nextProps.user.firstName.replace(/#/g,'');
            this.setState({user: nextProps.user})
        }
    }

    updateUser = () => {
        const {user} = this.state;
        const data = {
            firstName: user.firstName,
            lastName: user.lastName
        }
        axios.put(`/v1/users/${user.id}`, data).then(r => {
            history.push(`/slush/${this.props.match.params.type}/thanks`);
            window.scrollTo(0,0);
        })
    }

    validate(){
        const {user} = this.state;
        return user.lastName.length && user.firstName.length;
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if(value.length > 25){
            const error ='The text is too long, 25 symbols max';
            this.setState({errors:{ ...this.state.errors, [e.target.name]: error}});
        } else {
            const errors = this.state.errors;
            delete errors[e.target.name];
            this.setState({errors:errors});
        }

        value = value.leftTrim().slice(0, 25);


        this.setState({user: {...this.state.user, [name]: value.trim()}})
    }


    render() {
        const {user, errors} = this.state;
        return (
            <div className="block-reg-email-mini-reg">
                <p className="title-reg-email-mini-reg">
                    Well, now let’s get acquainted. Introduce yourself, please!
                </p>

                <div className="block-input-reg-email-mini-reg">
                    <p className="title-input-reg-email-mini-reg">
                        Name
                    </p>
                    <Input
                        type={'text'}
                        name={'firstName'}
                        value={user.firstName.trim().replace(/#/g,'')}
                        onChange={this.onChange}
                        placeholder={'Not more than 25 symbols'}
                        error={errors.firstName}
                    />
                </div>

                <div className="block-input-reg-email-mini-reg">
                    <p className="title-input-reg-email-mini-reg">
                        Surname
                    </p>
                    <Input
                        type={'text'}
                        name={'lastName'}
                        value={user.lastName.trim().replace(/#/g,'')}
                        onChange={this.onChange}
                        placeholder={'Not more than 25 symbols'}
                        error={errors.lastName}
                    />
                </div>
                <div className="block-button-bottom">
                    <ButtonBlack
                        TitleButton={'Save'}
                        EventOnClick={() => {this.updateUser()}}
                        paddingButton={null}
                        disabled={!this.validate() || Object.keys(errors).length}
                        loader={this.state.loader}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tokenLogin: PropTypes.func.isRequired,
    user: state.auth.user
})

export default connect(mapStateToProps, {tokenLogin})(eventRegEmail)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Input from "../../controls3.0/input/Input";
import Bitmap from '../../../images/Bitmap.png'


class Step17 extends React.Component {
    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors
        value = value.trim();
        value = value.slice(0, 42);
        if(!value.length) {
            errors[name] = 'Field is required';
        } else {
            if (value.length !== 42) {
                errors[name] = 'Field must contain 42 characters';
            } else {
                delete errors[name]
            }
        }

        this.setState({
            user: {...this.state.user, [name] : value},
            errors: errors
        })
    }
    validate = data => {
        const errors = {}
        for (let key in data) {
            switch (key) {
                case 'eth':
                    if(data[key] && data[key].length !== 42) {
                        errors[key] = 'must 42';
                    }
                    break
            }

            if(!data[key]) errors[key] = 'Field is required';
        }
        return errors
    }
    update = (nextStep, onlyStep) => {
        let user = this.state.user,
            data = {
                eth: user.eth,
                isFinished: true
            }

        let errors = onlyStep ? {} : this.validate(data)
        this.setState({ errors })

        if(!Object.keys(errors).length){
            let loading = nextStep === 16? 'loadingBack' : 'loading';
            this.setState({[loading]: true});

            if(onlyStep){
                data = {
                    isFinished: true
                }
            }

            this.props.updateExpert(data, onlyStep, nextStep)
                .catch(err => {
                    this.setState({[loading]: false})
                })
        }
    }

    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            errors: {}
        }
    }

    render () {
        let {user, expert, errors} = this.state;

        return (
            <React.Fragment>
                <div className="block-gradient block-grad-17"/>
                <div className="container-full-reg" style={{position: 'relative'}}>
                    <div className="block-button-circle-title fox-cont new-pos-relative-down-1199 new-no-padding-down-1199">
                        <img src={Bitmap} alt="fox"/>
                    </div>


                    <div className="container-step-2-reg step-17"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text color-text-reg-black shell-margin-bottom-15 shell-margin-top-0">
                            Metamask
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20">
                            Metamask is a bridge that allows you to visit the distributed web of tomorrow in your browser today.
                            It allows you to run Ethereum dApps (as Rocket DAO is) right in your browser.
                        </p>

                        <div>

                            <div className="new-row">
                                <div className="new-col c-3 new-pad-10-right new-no-float-down-620 new-width-100-down-620">
                                    <p className="text-h4">Why do you need it?</p>
                                    <p className="text-desc-2">
                                        Metamask extension lets you work easily with Ethereum blockchain based projects.
                                    </p>
                                </div>
                                <div className="new-col c-3 new-pad-10-hor new-no-float-down-620 new-width-100-down-620">
                                    <p className="text-h4">How will it benefit you?</p>
                                    <p className="text-desc-2">
                                        Metamask includes a secure identity vault and instruments to sign transactions.
                                    </p>
                                </div>
                                <div className="new-col c-3 new-pad-10-left new-no-float-down-620 new-width-100-down-620">
                                    <p className="text-h4">How to install?</p>
                                    <p className="text-desc-2">
                                        You can install the MetaMask add-on for Chrome, Firefox, Opera. Visit <a
                                        href="https://metamask.io" target="_blank">https://metamask.io</a> for details.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="container-full-reg block-metamask-adaptive" style={{position: 'relative', padding: '55px 0 150px 0'}}>
                    <div className="container-step-2-reg cont-metamask"
                         style={{position: 'relative', margin: '0 0 0 auto', paddingLeft: 0}}>
                        <p className="text-h2 cm-text-title color-text-reg-black">
                            Your metamask wallet number
                        </p>
                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-gray">
                            Please write down your ETH wallet public number so that we <br/>
                            can transfer tokens to you.
                        </p>

                        <div className="shell-input-reg new-width-100-down-620">
                            <Input
                                type={'text'}
                                name={'eth'}
                                value={user.eth}
                                onChange={this.onChange}
                                placeholder={''}
                                error={errors.eth}
                            />
                        </div>
                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign: 'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(16, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(24, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(18)}
                                paddingButton={'56'}
                                disabled={!user.eth || Object.keys(errors).length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step17.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step17)


import React from 'react';
import './style.css'
import ButtonBlack from '../controls3.0/button/ButtonBlack'
import Tabs from "../controls3.0/tabs/Tabs";
import Input from "../controls3.0/input/Input";
import CheckBox from "../controls3.0/checkBox/checkBox";
import {createFromEvent, createRole, updateExpert, tokenLogin} from "../../actions/Login";
import history from "../../history";
import {getListParameters} from "../../actions/Profile";
import {showMessage} from "../../utils/showMessage";
import {connect} from "react-redux";
import PropTypes from "prop-types";


const event = 'slush';

class eventReg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFounder: false,
            type: this.props.state,
            loader: false,
            investorLoader: false,
            fundLoader: false,
            angelLoader: false,
            listParameters: [],
            expertParameters: [],
            title: '',
            errors: {
                title: ''
            }
        }

        if(!localStorage.RocketToken){
            history.push('/slush/auth');
            window.scrollTo(0,0);
        } else {
            this.props.tokenLogin(localStorage.RocketToken)
        }

        getListParameters()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({listParameters: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.user){
            if(nextProps.user.expert.parameters){
                this.setState({expertParameters: nextProps.user.expert.parameters})
            }
        }
    }

    addToParameters = param => {
        let parameters = this.state.expertParameters;
        let isFound = parameters.find(x => x.id === param.id)
        if (!isFound) {
            parameters.push(param)
        } else {
            parameters = parameters.filter(x => x.id !== param.id)
        }
        this.setState({expertParameters: parameters, listParameters: [...this.state.listParameters]});
    }

    componentDidMount() {
        window.scrollTo(0,0);

        let ChangePadding = this.props.ChangePadding;
        if (this.props.state === 'startup') {
            ChangePadding('120px')
        }
        if (this.props.state === 'investor') {
            ChangePadding('300px')
        }
        if (this.props.state === 'expert') {
            ChangePadding('120px')
        }
    }

    onChange = (e) => {
        let value = e.target.value;

        if(value.length > 200){
            const error = 'The text is too long, 200 symbols max';
            this.setState({errors:{ ...this.state.errors, [e.target.name]: error}});
        } else {
            const errors = this.state.errors;
            delete errors[e.target.name];
            this.setState({errors:errors});
        }
        value = value.leftTrim().replace(/  +/g, ' ').replace(/\n\n|\r\n\r\n|\r\r+/g, ' ').slice(0, 200);
        this.setState({[e.target.name]: value})
    }

    onCheckbox = (e) => {
        this.setState({isFounder: !this.state.isFounder})
    }

    createStartup = () => {
        this.setState({loader: true})

        const data = {
            title: this.state.title,
            isFounder: this.state.isFounder,
            from: event,
            registrationStep: 2
        };

        createFromEvent(data, 'startup').then((r) => {
            this.setState({loader: false});
            history.push(`/slush/${this.state.type}/thanks`)
            window.scrollTo(0,0);
        }).catch(e => {
            this.setState({errors:{ ...this.state.errors, title: e.response.data.error}});
            this.setState({loader: false});
        })
    };

    createInvestor = () => {
        this.setState({investorLoader: true});
        let data = {
            role: 'investor',
            from: event
        }

        createRole(data).then(_=>{
            // history.push(`/slush/${this.state.type}/thanks`)
            history.push(`/slush/profile/investor`)
            window.scrollTo(0,0);
        }).catch(e => {

        })
    }

    createFund = () => {
        this.setState({fundLoader: true});

        const data = {
            title: this.state.title,
            isFounder: this.state.isFounder,
            from: event,
            registrationStep: 2
        };

        createFromEvent(data, 'fund')
            .then(_ => {
                history.push(`/slush/${this.state.type}/thanks`);
                window.scrollTo(0,0);
            })
    }

    createNetwork = () => {
        this.setState({angelLoader: true});
        const data = {
            title: this.state.title,
            isFounder: this.state.isFounder,
            from: event,
            registrationStep: 2
        };

        createFromEvent(data, 'angel')
            .then(_ => {
                history.push(`/slush/angel_network/thanks`)
                window.scrollTo(0,0);
            })
    }

    createAccelerator = () => {


        this.setState({angelLoader: true});
        const data = {
            title: this.state.title,
            isFounder: this.state.isFounder,
            type: this.props.type2.replace('?t=', ''),
            from: event,
            registrationStep: 2
        };

        createFromEvent(data, 'accelerator')
            .then(_ => {
                history.push(`/slush/accelerator/thanks`)
                window.scrollTo(0,0);
            })
    }

    createExpert = () => {
        this.setState({loader: true});
        let data = {
            role: 'expert',
            from: event
        }

        createRole(data).then(_=>{
            createFromEvent({parameters: this.state.expertParameters, role: 'expert'}, 'expert')
               .then(r => {
                   this.setState({loader: false});
                    // history.push(`/slush/${this.state.type}/thanks`)
                    history.push(`/slush/profile/expert`)
                   window.scrollTo(0,0);
                }).catch(e => {
                this.setState({loader: false});
                    console.log(e);
                })
        }).catch(e => {
            this.setState({loader: false});
            console.log(e);
        })
    }

    render() {
        let {
            state,
            type2
        } = this.props;

        const {errors} = this.state;

        let list = this.state.listParameters.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={() => this.addToParameters(item)}
                    active={this.state.expertParameters.find((i) => i.id === item.id)}
                />
            )
        });
        return (
            <div className="block-reg-state-mine-reg">
                {state === 'startup' ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            Let’s create your startup profile!
                        </p>

                        <div className="block-input-reg-email-mini-reg" style={{margin:'15px 0 0 0'}}>
                            <p className="title-input-reg-email-mini-reg">
                                Name of the startup
                            </p>
                            <Input
                                type={'text'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.onChange}
                                placeholder={'Not more than 200 symbols'}
                                error={errors.title}
                            />
                        </div>

                        <div className="block-check-box-mini-reg">
                            <div className="block-left-check-box-mini-reg">
                                <CheckBox
                                    onClick={this.onCheckbox}
                                    active={this.state.isFounder}
                                />
                            </div>
                            <div className="block-right-check-box-mini-reg">
                                <p className="title-check-box-mini-reg">
                                    I am founder
                                </p>
                            </div>

                        </div>


                        <div className="block-button-bottom">
                            <ButtonBlack
                                TitleButton={'Save'}
                                EventOnClick={() => this.createStartup()}
                                paddingButton={null}
                                disabled={(!this.state.title && !this.state.title.length) || Object.keys(errors).length}
                                loader={this.state.loader}
                            />
                        </div>

                    </div>
                    : null}
                {state === 'investor' ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            Please choose your investor type!
                        </p>

                        <div className="block-button-bottom-20">
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Business angel'}
                                    EventOnClick={() =>this.createInvestor()}
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.investorLoader}
                                />
                            </div>
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Angel network'}
                                    EventOnClick={() => {history.push('/slush/angel_network'); window.scrollTo(0,0);} }
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.angelLoader}
                                />
                            </div>
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Venture fund'}
                                    EventOnClick={() => {history.push('/slush/fund'); window.scrollTo(0,0);} }
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.fundLoader}
                                />
                            </div>
                        </div>

                    </div>
                    : null}
                {state === 'expert' ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            What evaluation parameters are you experienced in?
                        </p>

                        <div className="block-list-tabs-mini-reg">
                            <React.Fragment key={this.state.key}>
                                {list}
                            </React.Fragment>
                        </div>

                        <div className="block-button-bottom">
                            <ButtonBlack
                                TitleButton={'Save'}
                                EventOnClick={() => {this.createExpert()}}
                                paddingButton={null}
                                disabled={!this.state.expertParameters.length}
                                loader={this.state.loader}
                            />
                        </div>

                    </div>
                    : null}
                {state === 'fund' ?
                    <div className="block-reg-start-up-mini-reg">
                        <p className="block-title-reg-state-mine-reg">
                            Let’s create your VC fund’s profile!
                        </p>

                        <div className="block-input-reg-email-mini-reg" style={{margin:'15px 0 0 0'}}>
                            <p className="title-input-reg-email-mini-reg">
                                Name of the VC fund
                            </p>
                            <Input
                                type={'text'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.onChange}
                                placeholder={'Not more than 200 symbols'}
                                error={errors.title}
                            />
                        </div>

                        <div className="block-check-box-mini-reg">
                            <div className="block-left-check-box-mini-reg">
                                <CheckBox
                                    onClick={this.onCheckbox}
                                    active={this.state.isFounder}
                                />
                            </div>
                            <div className="block-right-check-box-mini-reg">
                                <p className="title-check-box-mini-reg">
                                    I am founder
                                </p>
                            </div>

                        </div>


                        <div className="block-button-bottom">
                            <ButtonBlack
                                TitleButton={'Save'}
                                EventOnClick={() => this.createFund()}
                                paddingButton={null}
                                disabled={(!this.state.title && !this.state.title.length) || Object.keys(errors).length}
                                loader={this.state.loader}
                            />
                        </div>

                    </div>
                    : null}
                {state === 'angel_network' ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            Let’s create your Network’s profile!
                        </p>

                        <div className="block-input-reg-email-mini-reg" style={{margin:'15px 0 0 0'}}>
                            <p className="title-input-reg-email-mini-reg">
                                Name of the Network
                            </p>
                            <Input
                                type={'text'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.onChange}
                                placeholder={'Not more than 200 symbols'}
                                error={errors.title}
                            />
                        </div>

                        <div className="block-check-box-mini-reg">
                            <div className="block-left-check-box-mini-reg">
                                <CheckBox
                                    onClick={this.onCheckbox}
                                    active={this.state.isFounder}
                                />
                            </div>
                            <div className="block-right-check-box-mini-reg">
                                <p className="title-check-box-mini-reg">
                                    I am founder
                                </p>
                            </div>
                        </div>


                        <div className="block-button-bottom">
                            <ButtonBlack
                                TitleButton={'Save'}
                                EventOnClick={() => this.createNetwork()}
                                paddingButton={null}
                                disabled={(!this.state.title && !this.state.title.length) || Object.keys(errors).length}
                                loader={this.state.loader}
                            />
                        </div>

                    </div>
                    : null}

                {(state === 'accelerator' && !type2) ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            Please choose your accelerator’s type!
                        </p>

                        <div className="block-button-bottom-20">
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Private'}
                                    EventOnClick={() => { history.push('/slush/accelerator?t=Private'); window.scrollTo(0,0);} }
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.investorLoader}
                                />
                            </div>
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Corporate'}
                                    EventOnClick={() => { history.push('/slush/accelerator?t=Corporate'); window.scrollTo(0,0);} }
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.angelLoader}
                                />
                            </div>
                            <div className="block-button-mini-reg">
                                <ButtonBlack
                                    TitleButton={'Government'}
                                    EventOnClick={() => { history.push('/slush/accelerator?t=Government'); window.scrollTo(0,0);} }
                                    paddingButton={null}
                                    disabled={false}
                                    loader={this.state.fundLoader}
                                />
                            </div>
                        </div>

                    </div>
                    : null}

                {(state === 'accelerator' && !!type2) ?
                    <div className="block-reg-start-up-mini-reg">

                        <p className="block-title-reg-state-mine-reg">
                            Let’s create your accelerator’s profile!
                        </p>

                        <div className="block-input-reg-email-mini-reg" style={{margin:'15px 0 0 0'}}>
                            <p className="title-input-reg-email-mini-reg">
                                Name of the accelerator
                            </p>
                            <Input
                                type={'text'}
                                name={'title'}
                                value={this.state.title}
                                onChange={this.onChange}
                                placeholder={'Not more than 200 symbols'}
                                error={errors.title}
                            />
                        </div>

                        <div className="block-check-box-mini-reg">
                            <div className="block-left-check-box-mini-reg">
                                <CheckBox
                                    onClick={this.onCheckbox}
                                    active={this.state.isFounder}
                                />
                            </div>
                            <div className="block-right-check-box-mini-reg">
                                <p className="title-check-box-mini-reg">
                                    I am founder
                                </p>
                            </div>
                        </div>


                        <div className="block-button-bottom">
                            <ButtonBlack
                                TitleButton={'Save'}
                                EventOnClick={() => this.createAccelerator()}
                                paddingButton={null}
                                disabled={(!this.state.title && !this.state.title.length) || Object.keys(errors).length}
                                loader={this.state.loader}
                            />
                        </div>

                    </div>
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tokenLogin: PropTypes.func.isRequired,
    user: state.auth.user
})

export default connect(mapStateToProps, {tokenLogin})(eventReg)
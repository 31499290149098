import React from 'react';
import './Folder.css'
import {connect} from "react-redux"
import Tag2 from '../Tag2.0/Tag2.0'
import $ from "jquery";
import ModalAddNew from '../../modal/ModalAddNew'
import ModalEditFFL from '../../modal/ModalEditFFL'
import moment from 'moment';
import {deleteKnowledgeOne, getKnowledgeOne, editKnowledgeOne} from "../../../actions/Profile";
import File from '../../controls2.0/File/File'
import Loader from '../../test-controls/preloader/preloader'

const ISADMIN = localStorage.getItem("isAdmin");

class Folder extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loaded: true,
            showModal: false,
            typeModal: null,
            parentId: props.id,
            editMode: false,
            level: 0,
            folder: {},
            editOne: {},
            parentDOM: []
        };
    }

    componentDidMount (){
        document.addEventListener('click', this.handleClock)
        this.setState({folder: this.props.folder})

    }
    componentWillUnmount (){
        document.removeEventListener('click', this.handleClock)
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
        if(nextProps.user && nextProps.user.isAdmin) this.setState({edit: true})
    }


    handleClock (event){
        let popup = document.getElementsByClassName('block-popup-menu-add-new-button')

        for (var i = 0; i< popup.length; i++){
            if(!event.target.parentElement) return;
            if(!event.target.classList.contains('button-add-folder-file-link') && !event.target.parentElement.classList.contains('button-add-folder-file-link')){
                $(popup[i]).slideUp();
            }
        }
    }

    handleShowModal = (type) => {
        this.setState({typeModal: type, showModal: true});
    }

    handleCloseModal = () => {
        this.setState({typeModal: null, showModal: false, showModal2: false, editOne: {}});
    }

    ClickTop = e => {
        if ($(e.target).hasClass('is-click-link')) return;


        let logo = e.currentTarget.getElementsByClassName('block-top-logo-folder')[0].getElementsByTagName('i')[0];
        $(e.currentTarget).parent().children('.block-body-folder').slideToggle('normal');

        if (e.currentTarget.classList.contains('active')) {
            e.currentTarget.classList.remove('active')
            logo.classList.remove('fa-folder-open')
            logo.classList.add('fa-folder')



        } else {
            e.currentTarget.classList.add('active')
            logo.classList.remove('fa-folder')
            logo.classList.add('fa-folder-open')

            if(this.props.folder && this.props.folder.id){
                this.setState({loaded: false})
                getKnowledgeOne(this.props.folder.id)
                    .then((res) => {
                        let items = res.data.items;

                        let arr = [];
                        items.forEach((item, key) => {
                            item.level = res.data.level + 1;
                            let thing = this.getThingByType(item);
                            arr.push(thing)
                        })
                        this.setState({level:res.data.level, parentDOM: arr, loaded: true})
                    })
                    .catch(error => {
                        this.setState({loaded: true})
                    })
            }
        }
    }

    fileLinkPrototype = item => {
        let file =
            <File
                key={item.id}
                file={item}
                nestingFile={this.state.level}
                delete={this.delete}
                getFilterByTag={this.props.getFilterByTag}
            />

        return file;
    }

    folderPrototype = item => {
        let folder =
            <Folder
                key={item.id}
                folder={item}
                addNewThing={this.addNewThingToDOM}
                nestingFolder={this.state.level}
                parent={false}
                delete={this.delete}
                getFilterByTag={this.props.getFilterByTag}
            >
            </Folder>

        return folder
    }

    getThingByType = item => {
        let newItem;
        switch (item.type) {
            case 'file':
            case 'href':
                newItem = this.fileLinkPrototype(item)
                break;
            case 'dir':
                newItem = this.folderPrototype(item)
                break;
            default:
        }
        return newItem
    }

    delete = item => {
        if(window.confirm ('DELETE. are you sure?')) {
            this.setState({loaded: false})
            deleteKnowledgeOne(item.id)
                .then((res) => {
                    let arr = this.state.parentDOM;
                    let newArr = arr.filter(thing => (item.id != thing.key))
                    this.setState({parentDOM: newArr, loaded: true})
                })
                .catch(error => {
                    this.setState({loaded: true})
                })
        }
    }

    onChange = e => {
        this.setState({folder:{...this.state.folder, [e.target.name]: e.target.value}});
    }

    edit = item => {
        this.setState({showModal2: true, editOne: item})
    }

    cancel = () => {
        this.setState({editMode: false})
        this.setState({folder:{...this.state.folder, title: this.props.folder.title}});
    }

    save = (item) => {
        this.setState({folder: item})
        this.handleCloseModal()
    }

    addNewThing = (item) => {
        this.setState({showModal: false})
        if(this.state.parentId == 'parent') {
            this.props.addNewThing(item)
        } else {
            let newItem = this.getThingByType(item);
            let arr = this.state.parentDOM;
            arr.push(newItem);
            this.setState({parentDOM: []})
            this.setState({parentDOM: arr})
        }
    }

    clickAddButton = e =>{
        $(e.currentTarget).parent().children('.block-popup-menu-add-new-button').slideToggle('normal');
    }


    render() {
        // let folder = this.props.folder;
        let folder = this.state.folder;


        if(!folder.tags) folder.tags = [];

        let user = this.props.user || {};


        let Tags = folder.tags.map((item, i) => {
            return (
                <Tag2
                    key={i}
                    TitleTag={item.title}
                    LinkTag={''}
                    Color="#0028DC"
                    getFilterByTag={this.props.getFilterByTag}
                />
            )
        })


        let nesting = {paddingLeft: (20 * (folder.level * 1)) + 'px'},
            nesting2 = {paddingLeft: (20 + 20 * (folder.level * 1)) + 'px'}


        return (
            <div className="shell-folder" data-id={this.props.id}>

                {!this.props.parent &&
                <div className="block-folder">
                    <div className="block-top-folder" onClick={this.ClickTop}>
                        <div className="block-logo-title-folder folder-edit" style={nesting} >
                            <div className="block-top-logo-folder"><i className="fal fa-folder"></i></div>
                            <div className="block-top-title-folder">
                                <div className="shell-flex-top-title-folder">

                                    {!this.state.editMode &&
                                    <div className="block-flex-top-title-folder">
                                        <p>{folder.title}</p>
                                    </div>
                                    }

                                    {this.state.editMode &&
                                    <div className="block-flex-top-title-folder" style={{'display': 'block', 'width': '100%'}}>
                                        <input type="text" name="title" className="is-click-link" value={this.state.folder.title} onChange={this.onChange} style={{'display': 'block', 'width': '100%'}} />
                                    </div>
                                    }


                                </div>

                            </div>
                        </div>
                        <div className="block-tags-date-folder folder-edit">
                            <div className="block-top-tags-folder folder-edit">
                                <div className="shell-flex-folder">
                                    <div className="block-flex-folder">
                                        {Tags}
                                    </div>
                                </div>
                            </div>
                            <div className="block-top-date-folder folder-edit">
                                <p>
                                    {moment(folder.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
                                </p>
                            </div>
                            <div className="block-top-link-folder folder-edit">

                            </div>


                            <div className="block-top-delete-folder folder-edit">
                                <div className="shell-flex-folder">
                                    <div className="block-flex-folder">
                                        {ISADMIN &&
                                            <span>
                                                {!this.state.editMode &&
                                                    <span>
                                                        <a className='is-click-link' style={{'color': '#2AA2E6'}} onClick={() => this.edit(folder)}>edit</a>
                                                        <a className='is-click-link' onClick={() => this.props.delete(folder)}>delete</a>
                                                    </span>
                                                }
                                                {/*{this.state.editMode &&*/}
                                                    {/*<span>*/}
                                                        {/*<a className='is-click-link' style={{'color': 'green'}} onClick={() => this.save()}>save</a>*/}
                                                        {/*<a className='is-click-link' style={{'color': 'black'}} onClick={() => this.cancel()}>cancel</a>*/}
                                                    {/*</span>*/}
                                                {/*}*/}

                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="block-body-folder" data-id={this.props.id}>
                        {/*{this.props.children}*/}

                        {this.state.parentDOM}

                        {ISADMIN &&
                        <div className="block-button-add-new" style={nesting2}>

                            <a onClick={this.clickAddButton} className="button-add-folder-file-link"><i
                                className="fal fa-plus"></i>Add new</a>

                            <div className="block-popup-menu-add-new-button">
                                <div className="block-list-popup-menu-add-new-button">
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('file')}>New file</p>
                                    </div>
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('link')}>New link</p>
                                    </div>
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('folder')}>New folder</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }

                {this.props.parent && ISADMIN &&
                <div className="block-folder">

                    <div className="block-body-folder" style={{'display': 'block'}}>
                        <div className="block-button-add-new" style={{'paddingLeft': '0'}}>
                            <a onClick={this.clickAddButton} className="button-add-folder-file-link">
                                <i className="fal fa-plus"></i>Add new
                            </a>
                            <div className="block-popup-menu-add-new-button">
                                <div className="block-list-popup-menu-add-new-button">
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('file')}>New file</p>
                                    </div>
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('link')}>New link</p>
                                    </div>
                                    <div className="block-item-popup-menu-add-new-button">
                                        <p onClick={() => this.handleShowModal('folder')}>New folder</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {this.state.showModal &&
                    <ModalAddNew
                        typeModal={this.state.typeModal}
                        parentId={folder.id}
                        showModal={this.state.showModal}
                        handleCloseModal={this.handleCloseModal}
                        addNewThing={this.addNewThing}
                    />
                }

                {this.state.showModal2 &&
                <ModalEditFFL
                    thing={this.state.editOne}
                    showModal={this.state.showModal2}
                    handleCloseModal={this.handleCloseModal}
                    save={this.save}
                />
                }
                <Loader loaded={this.state.loaded}/>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    user: state.auth.user
})


export default connect(mapStateToProps)(Folder)

import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {logout, tokenLogin, updateUser} from '../../../actions/Login'
import Header from '../../header'
import Footer from '../../footer/Footer'
import '../../test-controls/Page2.0/css/style.css'
import '../../test-controls/Page2.0/css/StartPage.css'
import '../../home/home.css'
import '../../controls2.0/ProgressBar2.0/ProgressBar2.0.css';

import Slider from "react-slick";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

import './startStyle.css';
import Tag2 from '../../controls2.0/Tag2.0/Tag2.0'
import ButtonGreen2 from '../../controls2.0/Button2.0/ButtonGreen2.0'
import ButtonBlue2 from '../../controls2.0/Button2.0/ButtonBlue2.0'
import ButtonTransWhite2 from '../../controls2.0/Button2.0/ButtonTransWhite2.0'
import ButtonTransBlue2 from '../../controls2.0/Button2.0/ButtonTransBlue2.0'
import ProgressBarMulti from '../../controls2.0/ProgressBarMulti/ProgressBarMulti'
import filtersvg from './filter.svg'
import $ from "jquery";
import videoCloud from './Sky-Rocket_DAO_2.mp4'



function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className="startup-slider-arrow startup-arrow-next"
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <i className="fal fa-angle-right"></i>
        </div>

    );
}


function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className="startup-slider-arrow startup-arrow-prev"
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <i className="fal fa-angle-left"></i>
        </div>
    );
}


class start extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loaded: false,
            showModal: false,
            parentDOM: [],
            listKnowlendgeBase: []
        };
    }

    componentDidMount() {
        this.circleAnimPuls()

    }
    ClickItemCircle = e => {
        if (!$(e.currentTarget).hasClass('circle-top')) {
            $('.block-item-circle').addClass('active');
            if ($(e.currentTarget).hasClass('circle-1')) {
                this.circleAnim('circle-1','circle-1','circle-2','circle-3')
            }
            if ($(e.currentTarget).hasClass('circle-2')) {
                this.circleAnim('circle-2','circle-2','circle-3','circle-1')
            }
            if ($(e.currentTarget).hasClass('circle-3')) {
                this.circleAnim('circle-3','circle-3','circle-2','circle-1')
            }
        } else {
            return;
        }
    }

    circleAnimPuls = e =>{
        var timer = setInterval(function () {

            $('.block-item-circle.circle-top').addClass('puls')
            var timer1 = setInterval(function () {
                $('.block-item-circle.circle-top').removeClass('puls')
                clearInterval(timer1)
            }, 400)
            var timer2 = setInterval(function () {
                $('.block-item-circle.circle-middle').addClass('puls')
                clearInterval(timer2)
            }, 1400)
            var timer3 = setInterval(function () {
                $('.block-item-circle.circle-middle').removeClass('puls')
                clearInterval(timer3)
            }, 1800)
            var timer4 = setInterval(function () {
                $('.block-item-circle.circle-bottom').addClass('puls')
                clearInterval(timer4)
            }, 2800)
            var timer5 = setInterval(function () {
                $('.block-item-circle.circle-bottom').removeClass('puls')
                clearInterval(timer5)
            }, 3200)


        }, 5000)
    }

    circleAnim = (e,c1,c2,c3) =>{
        $('#link-'+e)[0].click()
        var clearCirclePos = this.clearCirclePos
        var timer = setInterval(function () {
            clearCirclePos('.block-item-circle.circle-1')
            clearCirclePos('.block-item-circle.circle-2')
            clearCirclePos('.block-item-circle.circle-3')

            $('.block-item-circle.'+c1).addClass('circle-top');
            $('.block-item-circle.'+c2).addClass('circle-middle');
            $('.block-item-circle.'+c3).addClass('circle-bottom');
            clearInterval(timer)
        }, 500)
        var timer1 = setInterval(function () {
            $('.block-item-circle').removeClass('active');
            clearInterval(timer1)
        }, 1000)
    }

    clearCirclePos = e => {
        if ($(e).hasClass('circle-top')) {
            $(e).removeClass('circle-top')
        }
        if ($(e).hasClass('circle-middle')) {
            $(e).removeClass('circle-middle')
        }
        if ($(e).hasClass('circle-bottom')) {
            $(e).removeClass('circle-bottom')
        }
    }

    clickItemMenu = e =>{
        $('.block-left-body-header-start-page-dao .block-list-link a.active').removeClass('active')
        $(e.currentTarget).addClass('active')
        if($(e.currentTarget).hasClass('circle-1')){
            $('#title-fund-start-page').text('Fund')
            $('#description-fund-start-page').text('A product specially designed for investors. Using the full power of Ethereum based smart contracts we have achieved a new way to create and manage mutual funds.')
            $('.block-item-circle.circle-1').click()
            this.updateTitleSpanCircle('Create Fund','Read Expert\'s Review','Invest in Startups')
        }
        if($(e.currentTarget).hasClass('circle-2')){
            $('#title-fund-start-page').text('Expert')
            $('#description-fund-start-page').text('A product that was created for audit and consulting the projects wishing to subscribe for a fund creation on the platform.')
            $('.block-item-circle.circle-2').click()
            this.updateTitleSpanCircle('Make Money','Become an Expert','Review Startups')
        }
        if($(e.currentTarget).hasClass('circle-3')){
            $('#title-fund-start-page').text('Startup')
            $('#description-fund-start-page').text('A product for startups, the aim of which is to provide each of them with set of convenient tools to work with experts and funds, manage their token sales and attract community attention.')
            $('.block-item-circle.circle-3').click()
            this.updateTitleSpanCircle('Get Funding','Pass Expert Review','Add Your Company')
        }
    }

    updateTitleSpanCircle = (t1,t2,t3) =>{
        var timer = setInterval(function () {
            $('.block-item-circle.circle-1 span').text(t1)
            $('.block-item-circle.circle-2 span').text(t2)
            $('.block-item-circle.circle-3 span').text(t3)
            clearInterval(timer)
        }, 500)
    }

    render() {
        let user = this.props.user || {};
        var settings = {
            dots: false,
            paddingLeft: 20,
            infinite: true,
            speed: 500,
            variableWidth: true,
            width: 1155,
            nextArrow: <SampleNextArrow className="test"/>,
            prevArrow: <SamplePrevArrow/>
        };
        return (

            <div className="block-team-2-0 background-white">

                <Header user={user} bgcColor={'#0028DC'}></Header>
                <div className="back-white">

                    <div className="shell-header-start-page-dao">
                        <div className="block-header-start-page-dao">
                            <div className="block-background-video-start-page-dao">
                                <video autoPlay='autoplay' muted loop="loop" poster="../../../img/Video.jpg">
                                    <source src={videoCloud}
                                            >
                                    </source>
                                </video>
                            </div>
                            <div className="container-DAO">
                                <div className="block-body-header-start-page-dao">
                                    <div className="block-left-body-header-start-page-dao">
                                        <p className="title-mini">Decentralized Funding Platform</p>
                                        <p className="title-big" id='title-fund-start-page'>Fund</p>
                                        <p className="description" id='description-fund-start-page'>A product specially designed for investors. Using the
                                            full power of Ethereum based smart contracts we have achieved a new way to
                                            create and manage mutual funds.</p>
                                        <div className="block-list-link">
                                            <a id="link-circle-1" className="circle-1 active" onClick={this.clickItemMenu}>Fund</a>
                                            <a id="link-circle-2" className="circle-2" onClick={this.clickItemMenu}>Expert</a>
                                            <a id="link-circle-3" className="circle-3" onClick={this.clickItemMenu}>Startup</a>
                                        </div>
                                        <div className="block-button-start-page">
                                            <ButtonTransBlue2
                                                TitleButton="Learn more"
                                                EventOnClick={null}
                                                sizeButton="big"
                                                paddingButton="30"
                                                enabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="block-right-body-header-start-page-dao">

                                        <div className="block-list-circle">
                                            <div className="block-item-circle circle-1 circle-top"
                                                 onClick={this.ClickItemCircle}>
                                                <p className="title">Fund</p>
                                                <span>Create Fund</span>
                                            </div>
                                            <div className="block-item-circle circle-2 circle-middle"
                                                 onClick={this.ClickItemCircle}>
                                                <p className="title">Experts</p>
                                                <span>Read Expert's Review</span>
                                            </div>
                                            <div className="block-item-circle circle-3 circle-bottom"
                                                 onClick={this.ClickItemCircle}>
                                                <p className="title">Startup</p>
                                                <span>Invest in Startups</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="shell-body-start-new-fund back-white">
                        <div className="container-DAO">
                            <div className="block-body-start-new-fund">

                                <div className="shell-cart-list-start-page margin-top-30">
                                    <div className="block-cart-list-start-page">
                                        <div className="block-top-cart-list-start-page">
                                            <div className="block-left-top-cart-list-start-page">
                                                <p className="big-title-start-page">
                                                    Last Review
                                                    <a href="">learn more</a>
                                                </p>
                                                <p className="small-title-start-page">2 days ago</p>
                                            </div>
                                            <div className="block-right-top-cart-list-start-page">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="shell-header-start-page-new-startup padding-20-top">
                        <div className="block-header-start-page-new-startup">

                            <Slider {...settings}>
                                <div className="shell-item-slider">
                                    <div className="block-item-slider"
                                         style={{
                                        background: 'url(../img/page/Cosmecoin.jpg) no-repeat'
                                    }}>
                                        <div className="shell-item-startup-slider">
                                            <div className="block-item-startup-slider">
                                                <div className="block-left-item-startup-slider">

                                                    <div className="block-logo-title-startup-slider">
                                                        <div className="block-left-logo">
                                                            <img src="./img/page/Cosmecoin.png" alt=""/>
                                                        </div>
                                                        <div className="block-right-title">
                                                            <p className="title">NEW Startup</p>
                                                            <p className="description">Cosmecoin</p>
                                                        </div>
                                                    </div>

                                                    <div className="block-description-startup-slider">
                                                        <p className="description">Future Of Cosmetic Surgery And Medical Aesthetics</p>
                                                    </div>

                                                    <div className="block-tag-list-startup-slider">
                                                        <Tag2
                                                            TitleTag="Medicine"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="Medicine & Clinic"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="IT"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                    </div>

                                                    <div className="block-list-button-startup-slider">
                                                        <div className="block-button-blue-startup-slider">
                                                            <p>Review <span><span className="color-green">8.2</span>out of 10</span>
                                                            </p>
                                                        </div>
                                                        <ButtonTransWhite2
                                                            TitleButton="About startup"
                                                            EventOnClick={null}
                                                            sizeButton="small"
                                                            paddingButton="20"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="block-right-item-startup-slider">
                                                    <div className="shell-progress-bar-multi-fund">
                                                        <ProgressBarMulti
                                                            CountCollect="980"
                                                            NameVal="ETH"

                                                            CountContrebuted="11"

                                                            SoftCapPercent="40"
                                                            SoftCapCountStart="1000"
                                                            SoftCapCountCollect="2500"

                                                            HardCapPercent="60"
                                                            HardCapCount="3000"
                                                        />
                                                    </div>
                                                    <div className="block-info-list-startup-slider">
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Round</span>
                                                            <p className="description-info">Pre Seed</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Price</span>
                                                            <p className="description-info">1 COIN = 0.00043 ETH</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Platform</span>
                                                            <p className="description-info">Ethereum</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Site</span>
                                                            <p className="description-info">poolproject.io</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">End’s in</span>
                                                            <p className="description-info">5 days</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shell-item-slider ">
                                    <div className="block-item-slider"
                                         style={{
                                             background: 'url(../img/page/HiHealth.jpg) no-repeat'
                                         }}>
                                        <div className="shell-item-startup-slider">
                                            <div className="block-item-startup-slider">
                                                <div className="block-left-item-startup-slider">

                                                    <div className="block-logo-title-startup-slider">
                                                        <div className="block-left-logo">
                                                            <img src="./img/page/HiHealth.png" alt=""/>
                                                        </div>
                                                        <div className="block-right-title">
                                                            <p className="title">NEW Startup</p>
                                                            <p className="description">HiHealth</p>
                                                        </div>
                                                    </div>

                                                    <div className="block-description-startup-slider">
                                                        <p className="description">A Unique AI Based Medical App</p>
                                                    </div>

                                                    <div className="block-tag-list-startup-slider">
                                                        <Tag2
                                                            TitleTag="Medicine"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="Medicine & Clinic"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="IT"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                    </div>

                                                    <div className="block-list-button-startup-slider">
                                                        <div className="block-button-blue-startup-slider">
                                                            <p>Review <span><span className="color-green">8.9</span>out of 10</span>
                                                            </p>
                                                        </div>
                                                        <ButtonTransWhite2
                                                            TitleButton="About startup"
                                                            EventOnClick={null}
                                                            sizeButton="small"
                                                            paddingButton="20"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="block-right-item-startup-slider">
                                                    <div className="shell-progress-bar-multi-fund">
                                                        <ProgressBarMulti
                                                            CountCollect="980"
                                                            NameVal="ETH"

                                                            CountContrebuted="11"

                                                            SoftCapPercent="40"
                                                            SoftCapCountStart="1000"
                                                            SoftCapCountCollect="2500"

                                                            HardCapPercent="60"
                                                            HardCapCount="3000"
                                                        />
                                                    </div>
                                                    <div className="block-info-list-startup-slider">
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Round</span>
                                                            <p className="description-info">A</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Price</span>
                                                            <p className="description-info">1 COIN = 0.00043 ETH</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Platform</span>
                                                            <p className="description-info">Ethereum</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Site</span>
                                                            <p className="description-info">poolproject.io</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">End’s in</span>
                                                            <p className="description-info">5 days</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="shell-item-slider ">
                                    <div className="block-item-slider"
                                         style={{
                                             background: 'url(../img/page/Etheal.jpg) no-repeat'
                                         }}>
                                        <div className="shell-item-startup-slider">
                                            <div className="block-item-startup-slider">
                                                <div className="block-left-item-startup-slider">

                                                    <div className="block-logo-title-startup-slider">
                                                        <div className="block-left-logo">
                                                            <img src="./img/page/Etheal.png" alt=""/>
                                                        </div>
                                                        <div className="block-right-title">
                                                            <p className="title">NEW Startup</p>
                                                            <p className="description">Etheal</p>
                                                        </div>
                                                    </div>

                                                    <div className="block-description-startup-slider">
                                                        <p className="description">Bringing Transparency to $100bn+ Medical Tourism</p>
                                                    </div>

                                                    <div className="block-tag-list-startup-slider">
                                                        <Tag2
                                                            TitleTag="Medicine"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="Medicine & Clinic"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                        <Tag2
                                                            TitleTag="IT"
                                                            LinkTag=""
                                                            Color=""
                                                        />
                                                    </div>

                                                    <div className="block-list-button-startup-slider">
                                                        <div className="block-button-blue-startup-slider">
                                                            <p>Review <span><span className="color-green">89</span>out of 10</span>
                                                            </p>
                                                        </div>
                                                        <ButtonTransWhite2
                                                            TitleButton="About startup"
                                                            EventOnClick={null}
                                                            sizeButton="small"
                                                            paddingButton="20"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="block-right-item-startup-slider">
                                                    <div className="shell-progress-bar-multi-fund">
                                                        <ProgressBarMulti
                                                            CountCollect="980"
                                                            NameVal="ETH"

                                                            CountContrebuted="11"

                                                            SoftCapPercent="40"
                                                            SoftCapCountStart="1000"
                                                            SoftCapCountCollect="2500"

                                                            HardCapPercent="60"
                                                            HardCapCount="3000"
                                                        />
                                                    </div>
                                                    <div className="block-info-list-startup-slider">
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Round</span>
                                                            <p className="description-info">Seed</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Price</span>
                                                            <p className="description-info">1 COIN = 0.00043 ETH</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Platform</span>
                                                            <p className="description-info">Ethereum</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">Site</span>
                                                            <p className="description-info">poolproject.io</p>
                                                        </div>
                                                        <div className="block-info-item-startup-slider">
                                                            <span className="title-info">End’s in</span>
                                                            <p className="description-info">5 days</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Slider>

                        </div>
                    </div>
                </div>

                <div className="shell-body-start-new-fund back-white">
                    <div className="container-DAO">
                        <div className="block-body-start-new-fund">

                            <div className="shell-cart-list-start-page">
                                <div className="block-cart-list-start-page">
                                    <div className="block-top-cart-list-start-page">
                                        <div className="block-left-top-cart-list-start-page">
                                            <p className="big-title-start-page">
                                                Popular Funds
                                                <a href="">Show all</a>
                                            </p>
                                            <p className="small-title-start-page">More than 100 participants</p>
                                        </div>
                                        <div className="block-right-top-cart-list-start-page">
                                            <ButtonBlue2
                                                TitleButton="Create fund"
                                                EventOnClick={null}
                                                sizeButton="small"
                                                paddingButton="40"
                                            />
                                        </div>
                                    </div>
                                    <div className="block-body-cart-list-start-page">
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left">Funded</p>
                                                <p className="title-right"><i className="fal fa-clock"></i>3 days</p>
                                                <div className="block-line-logo back-color-gray">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Participants: <span>10 567</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left">Funded</p>
                                                <p className="title-right"><i className="fal fa-clock"></i>3 days</p>
                                                <div className="block-line-logo back-color-gray">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Participants: <span>10 567</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left">Funded</p>
                                                <p className="title-right"><i className="fal fa-clock"></i>3 days</p>
                                                <div className="block-line-logo back-color-gray">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Participants: <span>10 567</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left">Funded</p>
                                                <p className="title-right"><i className="fal fa-clock"></i>3 days</p>
                                                <div className="block-line-logo back-color-gray">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Participants: <span>10 567</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="shell-cart-list-start-page">
                                <div className="block-cart-list-start-page">
                                    <div className="block-top-cart-list-start-page">
                                        <div className="block-left-top-cart-list-start-page">
                                            <p className="big-title-start-page">
                                                Popular Startup
                                                <a href="">Show all</a>
                                            </p>
                                            <p className="small-title-start-page">8+ out of 10</p>
                                        </div>
                                        <div className="block-right-top-cart-list-start-page">
                                            <ButtonBlue2
                                                TitleButton="Create Startup"
                                                EventOnClick={null}
                                                sizeButton="small"
                                                paddingButton="40"
                                            />
                                        </div>
                                    </div>
                                    <div className="block-body-cart-list-start-page">
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left round">Round: <span>seed</span></p>
                                                <p className="title-right"><i className="fal fa-clock color-green"></i>3
                                                    days</p>
                                                <div className="block-line-logo back-color-blue">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Review <span
                                                        className="color-green">8.2</span> <span
                                                        className="font-size-12">out of 10</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left round">Round: <span>seed</span></p>
                                                <p className="title-right"><i className="fal fa-clock color-green"></i>3
                                                    days</p>
                                                <div className="block-line-logo back-color-blue">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Review <span
                                                        className="color-green">8.2</span> <span
                                                        className="font-size-12">out of 10</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left round">Round: <span>seed</span></p>
                                                <p className="title-right"><i className="fal fa-clock color-green"></i>3
                                                    days</p>
                                                <div className="block-line-logo back-color-blue">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Review <span
                                                        className="color-green">8.2</span> <span
                                                        className="font-size-12">out of 10</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-item-cart-start-page">
                                            <div className="block-top-cart-start-page">
                                                <img src="" alt=""/>
                                                <p className="title-left round">Round: <span>seed</span></p>
                                                <p className="title-right"><i className="fal fa-clock color-green"></i>3
                                                    days</p>
                                                <div className="block-line-logo back-color-blue">
                                                    <img src="" alt=""/>
                                                    <p className="title-line">Review <span
                                                        className="color-green">8.2</span> <span
                                                        className="font-size-12">out of 10</span></p>
                                                </div>
                                            </div>
                                            <div className="block-body-cart-start-page">
                                                <p className="title-cart-start-page">
                                                    Methodology of the method
                                                </p>
                                                <p className="description-cart-start-page">
                                                    100 Символов — startup accelerator platform where ambitious t form
                                                    where ambitious m where ambitious
                                                </p>
                                                <div className="block-tag-list-cart-start-page">
                                                    <Tag2
                                                        TitleTag="Medicine"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="IT-technology"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                    <Tag2
                                                        TitleTag="Clinic"
                                                        LinkTag=""
                                                        Color=""
                                                    />
                                                </div>
                                                <div className="block-progress-bar-cart-start-page">
                                                    <p className="title-progress"><span>256 ETH / 6%</span> Raised so
                                                        far</p>
                                                    <div className="block-progress-bar">
                                                        <div className="block-progress" style={{width: '20%'}}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="shell-cart-list-start-page">
                                <div className="block-cart-list-start-page">
                                    <div className="block-top-cart-list-start-page">
                                        <div className="block-left-top-cart-list-start-page">
                                            <p className="big-title-start-page">
                                                Popular Experts
                                                <a href="">Show all</a>
                                            </p>
                                            <p className="small-title-start-page">10+ reviews</p>
                                        </div>
                                        <div className="block-right-top-cart-list-start-page">
                                            <ButtonBlue2
                                                TitleButton="Become an Expert"
                                                EventOnClick={null}
                                                sizeButton="small"
                                                paddingButton="40"
                                            />
                                        </div>
                                    </div>
                                    <div className="block-body-cart-list-start-page advising">

                                        <div className="card-container">
                                            <div className="card">
                                                <div className="front">
                                                    <img src="assets/img/advising/zavgorodnev.png" alt=""
                                                         className="avatar"/>
                                                        <h3>Valentine Zavgorodnev</h3>
                                                        <p>Virginia, USA</p>
                                                </div>
                                                <div className="back">
                                                    <a href="https://www.linkedin.com/in/valentine/" target="_blank"><i
                                                        className="fa fa-linkedin"
                                                        aria-hidden="true"></i></a>
                                                    <p>
                                                        Co-founder of Steem - world's first decentralized social network
                                                        that rewards content
                                                        creators and curators. Decentralized self governing communities
                                                        enthusiast. UI/UX
                                                        expert. Helped to develop and launch BitShares - world's first
                                                        decentralized
                                                        exchange. </p>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p className="from">Virginia, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-container">
                                            <div className="card">
                                                <div className="front">
                                                    <img src="assets/img/advising/zavgorodnev.png" alt=""
                                                         className="avatar"/>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p>Virginia, USA</p>
                                                </div>
                                                <div className="back">
                                                    <a href="https://www.linkedin.com/in/valentine/" target="_blank"><i
                                                        className="fa fa-linkedin"
                                                        aria-hidden="true"></i></a>
                                                    <p>
                                                        Co-founder of Steem - world's first decentralized social network
                                                        that rewards content
                                                        creators and curators. Decentralized self governing communities
                                                        enthusiast. UI/UX
                                                        expert. Helped to develop and launch BitShares - world's first
                                                        decentralized
                                                        exchange. </p>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p className="from">Virginia, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-container">
                                            <div className="card">
                                                <div className="front">
                                                    <img src="assets/img/advising/zavgorodnev.png" alt=""
                                                         className="avatar"/>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p>Virginia, USA</p>
                                                </div>
                                                <div className="back">
                                                    <a href="https://www.linkedin.com/in/valentine/" target="_blank"><i
                                                        className="fa fa-linkedin"
                                                        aria-hidden="true"></i></a>
                                                    <p>
                                                        Co-founder of Steem - world's first decentralized social network
                                                        that rewards content
                                                        creators and curators. Decentralized self governing communities
                                                        enthusiast. UI/UX
                                                        expert. Helped to develop and launch BitShares - world's first
                                                        decentralized
                                                        exchange. </p>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p className="from">Virginia, USA</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-container">
                                            <div className="card">
                                                <div className="front">
                                                    <img src="assets/img/advising/zavgorodnev.png" alt=""
                                                         className="avatar"/>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p>Virginia, USA</p>
                                                </div>
                                                <div className="back">
                                                    <a href="https://www.linkedin.com/in/valentine/" target="_blank"><i
                                                        className="fa fa-linkedin"
                                                        aria-hidden="true"></i></a>
                                                    <p>
                                                        Co-founder of Steem - world's first decentralized social network
                                                        that rewards content
                                                        creators and curators. Decentralized self governing communities
                                                        enthusiast. UI/UX
                                                        expert. Helped to develop and launch BitShares - world's first
                                                        decentralized
                                                        exchange. </p>
                                                    <h3>Valentine Zavgorodnev</h3>
                                                    <p className="from">Virginia, USA</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>


        )

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

start.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(start)
module.exports = {
    23: { //
        7: { //Team
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        4: { //Tokenomics
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    },
    69: { // Дробышевский
        10: { //id	10 title	Market
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        2: { //id	2 title	Marketing and PR
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    28: { // Koltun
        3: { //id	3 title	Technologies
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    25: { // Mikhailov
        8: { //id	8 title	Risk
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        30: { //id	8 title	fina
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    31: { // Kuryan
        9: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        1: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    216: { // Sanchuk
        6: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    30: { // Grablevski
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    34: { // Inga
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    221: { // Torri
        1: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    252: { // Skvorc
        9: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    327: { // Dominik Franek
        10: { //id
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    }
}
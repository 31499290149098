import React from 'react';
import './SocialButtonLink.css'

export default class Bounty extends React.Component {
    render() {
        let {
            LinkSocial,
            ActiveSocial,
        } = this.props;
        /*let styleLink = {
            background: ActiveSocial? '#0000D3':'#F1F2F2',
        }*/
        let classActiveSocial = ActiveSocial? 'social-button bounty active-bounty':' social-button bounty no-active-bounty';
        return (
            <div className={classActiveSocial}>
                <a href={LinkSocial} target="_blank">
                    <i className="fa fa-btc" aria-hidden="true" ></i>
                    <p>BOUNTY</p>
                </a>
            </div>
        );
    }
}
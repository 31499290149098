exports.getMoneyView = (data, round) => {
    if(!data) return
    if(!isFinite(data)) return

    let str = `${data}`,
        value = ''
    if (str.length > 6) {
        value = 'm'

        let substr1 = str.slice(0, str.length - 6)
        let substr2 = str.slice(str.length - 6, 3)

        if(substr2.length == 0) substr2 = `00`
        if(substr2.length == 1) substr2 = `${substr2}0`

        str = `${substr1}.${substr2}`

        if(round) str = substr1

        // if(substr2 !== '00'){
        //     str = `${substr1}.${substr2}`
        // } else {
        //     str = substr1
        // }

    } else if (str.length > 3 && str.length <= 6) {
        value = 'k'
        let substr1 = str.slice(0, str.length - 3)
        let substr2 = str.slice(str.length - 3, 3)


        if(substr2.length == 0) substr2 = `00`
        if(substr2.length == 1) substr2 = `${substr2}0`

        str = `${substr1}.${substr2}`

        // if(substr2 !== '00'){
        //     str = `${substr1}.${substr2}`
        // } else {
        //     str = substr1
        // }

        if(round) str = substr1
    }

    str = `${str} ${value}`

    return str;
}

exports.getMoneyViewSpaces = (data) => {
    if(!data) return '--'
    let str = `${data}`

    str = str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    return str;
}
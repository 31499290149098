import React from 'react';
import './InputUserInfoProfile.css';


        export default class InputUserInfoProfile extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    myValue: this.props.valueInputUserInfoProfile,
                    ViewPassword: false,
                    type: 'password'
                };
                this.onClockEye = this.onClockEye.bind(this);
                this.onChangeHandler = this.onChangeHandler.bind(this);
                this.onFocus = this.onFocus.bind(this);
            }

            onChangeHandler(e) {
                this.setState({myValue: e.target.value})
                this.props.changeInputUserInfoProfile(e.target.value)
            }

              onFocus(e){
                if( this.props.focusInputUserInfoProfile)this.props.focusInputUserInfoProfile(e)
              }

            onClockEye(e) {
                let ty;
                if (!this.state.ViewPassword){
                    ty = 'text'
                    e.currentTarget.classList.add("fa-eye-slash");
                    e.currentTarget.classList.remove("fa-eye");
                }else {
                    ty = 'password'
                    e.currentTarget.classList.add("fa-eye");
                    e.currentTarget.classList.remove("fa-eye-slash");
                }
                this.setState({
                    ViewPassword: !this.state.ViewPassword,
                    type:ty
                })

            }
    render() {
        let {
            typeInputUserInfoProfile,
            placeholderInputUserInfoProfile,
            valueInputUserInfoProfile,
            nameInputUserInfoProfile
        } = this.props;
        let inputs
        if (typeInputUserInfoProfile == 'password'){
            inputs =
                <div className="block-input-password">
                    <input className="input-user-info-password" name={nameInputUserInfoProfile} value={this.state.myValue} onChange={this.onChangeHandler} onFocus={this.onFocus} type={this.state.type}
                           placeholder={placeholderInputUserInfoProfile}>

                    </input>
                    <i className="fa fa-eye" aria-hidden="true" onClick={this.onClockEye}></i>
                </div>
                ;
        }else {
            inputs =
                <div className="block-input-static">
                    <input className="input-user-info" value={this.state.myValue} onChange={this.onChangeHandler} type={typeInputUserInfoProfile}
                           placeholder={placeholderInputUserInfoProfile}/>
                </div>
            ;
        }
        return (
            <div className="shell-input-user">
                {inputs}
            </div>

        );
    }
}
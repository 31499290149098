import React, {useEffect, useState} from 'react';
import './style.scss'
import PopUp from "../../PopUp";
import {Scrollbars} from "react-custom-scrollbars";


const AddExpertParam = ( { onClose=()=>{}, listParameters={}, user={}, addParameter=()=>{}, addedParameters=[] } ) => {

    return (
        <div className='add-expert-param-wrapper'>
            <PopUp
                onClose={onClose}
            >
                <Scrollbars autoHeight autoHeightMin={100}
                            autoHeightMax={document.documentElement.clientWidth > 576?window.innerHeight - 140:window.innerHeight}>
                    <div className='add-expert-param-content'>
                        <div className='title'>Select parameter</div>
                        <div className='descr descr-wrapper'>Please choose the parameters in which you are highly experienced and interested in terms of evaluating startups.</div>
                        <div className='list-parameters-wrapper'>
                            {listParameters.map((p) => {
                                        if (!user.expert.parameters.find((item) => p.id === item.parameterId) && !addedParameters.find((item) => p.id === item.RoleParameter.id)) {
                                            return <div className="param-line" onClick={() => addParameter(p.id)}>
                                                <div className="param-title">
                                                    {p.title}
                                                </div>
                                                <div className='add-button'>
                                                    Add
                                                </div>
                                            </div>
                                        }
                                    })}
                        </div>
                    </div>
                </Scrollbars>
            </PopUp>
        </div>

    )
};

export default AddExpertParam;
import React from 'react';
import ProgressBar from "../../pages/ViralTest/components/ProgressBar";
import config from "../../../config";

import "../../../libs/grid/grid.css";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {Scrollbars} from "react-custom-scrollbars";

export default class PreliminaryEvaluation extends React.Component {
    constructor(props){
        super(props);
    }
    state = {
      textProblem : ''
    };

    onChange = (e) => {
        let value = e.target.value;

        this.setState({textProblem: value})
    };



    render() {
        let {
            startup,
            stepNum,
            stepAmount,
            descr,
            buttonBack,
            buttonSave
        } = this.props;


        return (
            <div>
                <ProgressBar
                    stepNum={stepNum}
                    stepAmount={stepAmount}
                />
                <div className='d-lg-block profile-name-block'>
                    <div className='row-b no-gutters name-text font-weight-500' style={{fontWeight: '900'}}>Name Surname</div>
                    <div className='row-b no-gutters save-text margin-top-3'>save 5 sec ago</div>
                </div>
                <div className='row-b no-gutters preliminary-evaluation-block'>
                    <div className='col-4 col-sm-3 left-sidebar'>
                        <div className='row-b no-gutters block-fund-info'>
                            <div className='col-25 avatar' style={false?{backgroundImage: `url(${startup ? `${config.proxy}/file/${startup.logoFileId}/view`: ''})`}:{background: `url(/static/media/3@2x-min.15f19dcb.png)`}}/>
                            <div className='col-75 name-title color-2f3849 text-one-line'>{/*startup ? startup.title : ''*/ 'Startup Name'}</div>
                        </div>
                        <div className='row-b no-gutters shell-margin-top-8 save-and-return-block'>
                            <div className='col save-arrow cursor-pointer'/>
                            <div className='col save-title margin-left-10 cursor-pointer'>Save and return</div>
                        </div>
                        <div className='row-b no-gutters margin-top-10 evaluation-points-block'>
                                <div className='row-b w-100 no-gutters point-text point-block margin-top-12 font-weight-500 color-rgba-08'>Product</div>
                                <div className={`row-b w-100 no-gutters point-text point-block margin-top-12 ${stepNum%3 >= 0 ? 'cursor-pointer':'color-rgba-03'}`}>Market</div>
                                <div className={`row-b w-100 no-gutters point-text point-block margin-top-12 ${stepNum%3 === 2 || stepNum%3 === 0 ? 'cursor-pointer':'color-rgba-03'}`}>Business model</div>
                                <div className={`row-b w-100 no-gutters point-text point-block margin-top-12 ${stepNum%3 === 0 ? 'cursor-pointer':'color-rgba-03'}`}>Team</div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-7 col-lg-9 evaluation-main-content'>
                        <div className='row-b no-gutters evaluation-title-h1 color-rgba-08 font-weight-500'>Problem</div>
                        <div className='row-b no-gutters evaluation-title-descr color-rgba-08 margin-top-15 margin-bottom-20'>{descr}</div>
                        <div className="row-b no-gutters" style={{/*minWidth:'700px'*/}}>
                            <div className='col-12 col-lg-7 block-text-area-form-add-edit-modal-reg-expert' style={{maxWidth:'460px'}}>
                                <textarea name="problem"
                                          value={this.state.textProblem}
                                          onChange={this.onChange}
                                          placeholder={'Not more than 100 symbols'}
                                          style={{height:'226px'}}
                                />
                                <div className='row-0 no-gutters margin-top-25 save-back-buttons-block' >
                                    <div className='new-col block-button-right'>
                                        <ButtonBlue
                                            TitleButton={'Save & Continue'}
                                            EventOnClick={() => {buttonSave()}}
                                            paddingButton={'40'}
                                            disabled={!this.state.textProblem}
                                        />
                                    </div>
                                    {(stepNum !== 1) && <div className='new-col block-button-left'>
                                        <ButtonGray
                                            TitleButton={'Back'}
                                            EventOnClick={() => {buttonBack()}}
                                            paddingButton={'56'}
                                            disabled={false}
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div className='col-11 col-md-6 col-lg-4 example-text-block'>
                                <div className='row-b no-gutters example-text-title text-block'>Example text</div>
                                <div className='row-b no-gutters example-wrapper'>
                                    <Scrollbars>
                                        <div className='example-text' style={{marginRight:'15px'}}>
                                            Of cryptocurrency users: evident lack ofsecure, convenient and multi-functionalwallets
                                            protecting users personal data andproviding the best experience transferringassets.Of vendors:
                                            complicated and expensiveaccess to cryptocurrency users. Of cryptocurrency users: evident
                                            lack ofsecure, convenient and. Of cryptocurrency users: evident lack ofsecure, convenient and multi-functionalwallets
                                            protecting users personal data andproviding the best experience transferringassets.Of vendors:
                                            complicated and expensiveaccess to cryptocurrency users. Of cryptocurrency users: evident
                                            lack ofsecure, convenient and
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
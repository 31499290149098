import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert, sendInvitation} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {regexEmail} from '../../../utils/RegExp'
import {showMessage} from "../../../utils/showMessage";
import plen18 from '../../../images/ilustaciya/Expert@2x-min.png'
import Group21 from '../../../images/svg/Group21.png'
import Input from "../../controls3.0/input/Input";
import TabsWithRemove from "../../controls3.0/tabs/TabsWithRemove";

class Step21 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            email: '',
            arrEmails: [],
            invitationsSent: false,
            errors: {}
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
            value = value.trim();

        if (regexEmail.test(value)) {
            this.setState({
                errors: {...this.state.errors, [name]: ''}
            })
        }
        this.setState({[name]: value})
    }
    add = () => {
        let arrEmails = this.state.arrEmails,
            email = this.state.email

        if (regexEmail.test(email)) {
            arrEmails.unshift(email)
            this.setState({arrEmails: arrEmails, email: ''})
        } else {
            this.setState({
                errors: {...this.state.errors, email: 'Invalid e-mail address'}
            })
        }

    }
    removeOne = index => {
        let arrEmails = this.state.arrEmails;
        arrEmails.splice(index, 1)
        this.setState({arrEmails})
    }


    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if (!onlyStep) {

        }

        let loading = nextStep === 20 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    sendInvitations = () => {
        // if(!this.state.arrEmails.length) {
        //     this.setState({
        //         errors: {...this.state.errors, friends: 'You need to add at least one friend’s address to the invitation list..'}
        //     })
        //     setTimeout(() => {
        //         this.setState({
        //             errors: {...this.state.errors, friends: null}
        //         })
        //     }, 2000)
        //     return
        // }
        this.setState({loading: true})
        sendInvitation(this.state.arrEmails.join(','))
            .then(res => {
                this.setState({invitationsSent: true, loading: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading: false})
            })
    }


    render() {
        let {user, expert, email, errors, arrEmails} = this.state;


        return (
            !this.state.invitationsSent ?
                <React.Fragment>
                    <div className="container-full-reg padding-reg">
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Invite a friend
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            You will receive % for all activities that a person you invited to the performs will perform.<br/>
                            We will track this using a referral link. All statistics will be available on your profile.
                        </p>


                        <div className='step-21-btm clearfix'>
                            <div className='s21-left no-float-620'>
                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                                    Invitation email list
                                </p>
                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                                    You will earn <span>10%</span> for the activities performed by your friends.
                                </p>

                                <div className="block-input-item-form-add-edit-modal-reg-expert input-item-form-add-adaptive">
                                    <p className="title">Enter your friends’s email here</p>
                                    <Input
                                        type={'text'}
                                        name={'email'}
                                        value={email}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errors.email}
                                    />

                                    <ButtonBlue
                                        TitleButton={'Invite'}
                                        EventOnClick={() => this.add()}
                                        paddingButton={'26'}
                                        disabled={false}
                                    />
                                </div>

                                <div>
                                    {arrEmails.map((item, key) =>
                                        <React.Fragment>
                                            {(key < 3) &&
                                            <TabsWithRemove
                                                key={key}
                                                TitleTab={item}
                                                TypeTab={'big'}
                                                EventOnClick={() => this.removeOne(key)}
                                            />
                                            }
                                            {(key >= 3 && this.state.showAll) &&
                                            <TabsWithRemove
                                                key={key}
                                                TitleTab={item}
                                                TypeTab={'big'}
                                                EventOnClick={() => this.removeOne(key)}
                                            />
                                            }
                                        </React.Fragment>
                                    )}

                                    {arrEmails.length > 3 && !this.state.showAll &&
                                    <React.Fragment>
                                        <div className='block-button-tab-counter tab-remove tab-down big'
                                             onClick={() => this.setState({showAll: true})}>
                                            <p className="title">{arrEmails.length - 3} more</p>
                                            <span className="counter no-bgc fal fa-chevron-down"></span>
                                        </div>
                                        <div className='block-button-tab-counter tab-remove tab-down big under'></div>
                                        <div className='block-button-tab-counter tab-remove tab-down big under-2'></div>
                                    </React.Fragment>
                                    }

                                </div>


                            </div>
                            <div className='s21-right no-float-620 new-no-padding-down-620'>
                                <img src={plen18} alt="" className=""/>
                            </div>
                        </div>
                    </div>


                    <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                        <div className="container-full-reg" style={{textAlign:'right'}}>
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(19, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>

                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Skip this step'}
                                    EventOnClick={() => this.update(22, true)}
                                    paddingButton={'24'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={(arrEmails.length>1)?'Send invitations':'Send invitation'}
                                    EventOnClick={() => this.sendInvitations()}
                                    paddingButton={'56'}
                                    disabled={!arrEmails.length}
                                />
                            </div>
                        </div>
                    </div>

                </React.Fragment>

                :

                <React.Fragment>
                    <div className="container-full-reg">
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            {(arrEmails.length>1)?'Invitations are successfully sent!':'Invitation is successfully sent!' }
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            You will be notified if your friends register on the platform <br/>
                            via your invitation link.
                        </p>


                        <div className='step-21-btm clearfix' style={{padding:'0 0 250px 0'}}>
                            <div className='s21-left inv-succ no-float-620'>
                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15 mb">
                                    {(arrEmails.length>1)?'We have sent invitations to these emails:':'We have sent invitation to these email:' }
                                </p>
                                <div>
                                    {arrEmails.map((item, key) =>
                                        <span className='item-email-sent'>{item}</span>
                                    )}
                                </div>


                            </div>
                            <div className='s21-right inv-succ no-float-620'>
                                <img src={Group21} alt="" className=""/>
                            </div>
                        </div>

                    </div>
                    <div className="block-fix-button-reg-bottom fix-button-one">
                        <div className="container-full-reg">
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Continue'}
                                    EventOnClick={() => this.update(22, true)}
                                    paddingButton={'56'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>

        )

    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step21.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step21)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import moment from "moment/moment";

import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
// import {showMessage} from "../../../utils/showMessage";
// import Input from "../../controls3.0/input/Input";
// import ListTitleDescriptionMore from '../../controls3.0/listTitleDescriptionMore/ListTitleDescriptionMore'
// import Select from "../../controls3.0/select/Select";
// import RocketDatePicker from '../../controls3.0/datePicker/DatePicker';
// import CheckBox from "../../controls3.0/checkBox/checkBox";
import {getListWorkExp} from '../../../actions/Profile'
import human from "../../../images/ilustaciya/Ilustraciya_investor.svg";
import TextArea from "../../v3/Textarea";

class Step5 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        this.state = {
            user: user,
            angel: angel,
            errors: {
                description: {msg: ''},
            },
            experience: {
                description: angel.shortDescription || '',
            },
        }
    }

    componentDidMount() {
        getListWorkExp(this.state.user.id)
            .then(res => {
                let sorArr = this.sortWE(res.data)
                this.setState({listWE: sorArr})
            })
    }

    sortWE = arr => {
        let sortArr = arr.sort(function (a, b) {
            return new Date(b.periodFrom) - new Date(a.periodFrom);
        });
        return sortArr
    }
    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        switch (name) {
            case 'description':
                if(value.length > 200){
                    const error ='The text is too long, 200 symbols max';
                    this.setState({errors:{ ...this.state.errors, description: {msg: error}}});
                } else {
                    this.setState({errors:{ ...this.state.errors, description: {msg: ''}}});
                }
                value = value.slice(0, 200);
                break;
            case 'company':
                value = value.slice(0, 100);
                break;
        }

        this.setState({experience: {...this.state.experience, [name]: value}})
    }

    onChangeCity = value => {
        this.setState({experience: {...this.state.experience, city: value}})
    }

    onCheck = () => {
        this.setState({experience: {...this.state.experience, currentJob: !this.state.experience.currentJob}})
    }

    validate(descLength){
        const {experience} = this.state;
        return experience.description.length && experience.description.length <= descLength
    }


    update = (nextStep, onlyStep) => {
        const angel = this.state.angel;

        let obj = {
            shortDescription: this.state.experience.description || ''
        }

        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) this.setState({loading: true})


        this.props.updateAngel(obj, angel.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, angel, errors, experience} = this.state

        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position:'relative'}}>
                    <img src={human} alt="" className="img-right-step"
                         style={{top: '160px', right: '80px',}}/>
                    <div className="container-step-1-reg">
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                            Identification
                        </p>

                        <p className="title-top-page-reg-expert text-h4 left-text shell-margin-bottom-80 shell-margin-top-0">
                            Let the community know your angel network better.
                        </p>


                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10 shell-margin-top-0">
                            Angel network description
                        </p>
                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0"
                           style={{maxWidth: '380px'}}>
                            Provide a short summary about the network, highlighting the most important facts here.
                        </p>



                        <div className="shell-text-area-kyc text-left mw380">

                                        <TextArea name={'description'} val={experience.description}
                                                  onChange={this.onChange} placeholder='200 symbols'
                                                  defaultHeight={105}
                                                  widthClass={'container-full-reg'}
                                                  widthClass2={'shell-text-area-kyc text-left mw380'}
                                                  noPopUp={true}
                                                  error={errors.description.msg}
                                        />

                            {/*<textarea name="description"*/}
                                      {/*value={experience.description}*/}
                                      {/*onChange={this.onChange}*/}
                                      {/*placeholder={'200 symbols'}*/}
                                      {/*style={{maxWidth: '380px', height: '105px'}}/>*/}
                            {/*<div className="block-new-input error">*/}
                                {/*<p className="title-error">{errors.description.msg || ''}</p>*/}
                            {/*</div>*/}

                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(4, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(6)}
                                paddingButton={'54'}
                                disabled={!this.validate(200)}
                                loader={false}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step5.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step5)
import React from 'react';
import StartupButton from '../index';

const StartupWaitButton = ({active, allInvest, myInvest, onClick}) => {

    console.log(onClick);

    return (
        <StartupButton className={`wait ${myInvest ? '' : 'disable_behavior'}`} onClick={onClick}>
            <div className={'wrap'}>
                <div className={'one'}>
                    <div>
                        <div className={`blockWaitImg blockImg ${active ? 'active' : ''}`}/>
                        <p className={'blockLeft'}>Waitlist</p>
                    </div>
                    <p className={'blockRight'}>{allInvest}</p>
                </div>
                <div className={'two'}>
                    <div>
                        <div className={`blockWaitImg blockImg ${active ? 'active' : ''}`}/>
                        <p className={'blockLeft'}>My sum</p>
                    </div>
                    <p className={'blockRight'}>{myInvest}</p>
                </div>
            </div>


        </StartupButton>
    )
};

export default StartupWaitButton;
import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {logout, tokenLogin, updateUser, getLastFund, getLastAngel} from "../../actions/Login";
import {checkStep, goToStep} from './../../utils/regNavigation'
import Header from '../header'
import Footer from '../footer/Footer'
import Routes from './routes'

// import './style.css'
// import './investor/style.css'


class RegistrationContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            type: ''
        }
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='reg-wrap'>
                    <Header user={this.props.user} offSticky={true}/>
                    <div className="container-100-reg">
                        <Routes user={this.props.user}/>
                    </div>
                    <Footer/>
                </div>
                <NotificationContainer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    message: state.auth.message,
    fund: state.auth.fund,
})

RegistrationContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
    fund: PropTypes.object,
    message: PropTypes.string,
    getLastFund: PropTypes.func.isRequired,
    //getLastAngel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin, getLastFund})(RegistrationContainer)
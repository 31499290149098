import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import CheckBox from "../../controls3.0/checkBox/checkBox";


class Step10 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        if(!angel.businessModelType) {
            angel.businessModelType = []
        } else {
            angel.businessModelType = angel.businessModelType.split(',')
        }
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            types: [
                {
                    title: 'B2B',
                    name: 'Business-to-Business',
                    description: 'These are the companies that do business with each other (i.e. a manufacturer and a wholesaler, or a wholesaler and a retailer).'

                },
                {
                    title: 'B2C',
                    name: 'Business-to-Consumer',
                    description: 'Such a company provides services directly to the general public and individual customers who are the end-users of the product (for example, Amazon and Priceline).'

                },
                {
                    title: 'B2G',
                    name: 'Business-to-Government',
                    description: 'This business category concerns private sector firms dealing with the government (like IT consulting to a local government agency).'

                },
                {
                    title: 'C2B',
                    name: 'Consumer-to-Business',
                    description: 'In this case customers create a specific value for businesses: i.e. reverse auctions, service provision (like UpWork).'

                },
                {
                    title: 'C2C',
                    name: 'Consumer-to-Consumer',
                    description: 'This is usually an online environment where customers can trade with each other (for example, eBay and Craigslist).'

                },
                {
                    title: 'C2G',
                    name: 'Consumer-to-Government',
                    description: 'This solution implies electronic transactions between individuals and public administration (distance learning, tax payments, etc.).'

                }
            ]
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({ angel: {...this.state.angel, [name]: value} })
    }

    set = type => {
        let businessModelType = this.state.angel.businessModelType;


        let isFound = businessModelType.find(item => item === type)
        if (!isFound) {
            businessModelType.push(type)
        } else {
            businessModelType = businessModelType.filter(item => item !== type)
        }
        this.setState({angel: {...this.state.angel, businessModelType: businessModelType}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        if(onlyStep){
            obj = {}
        }
        const fundId = this.state.angel.id;
        if(!onlyStep) {
            this.setState({loading: true})
            obj = {businessModelType: this.state.angel.businessModelType.join(',')}
        }
        this.props.updateAngel(obj, fundId , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, angel, errors} = this.state

        let list = this.state.types.map((item, key) =>
            <div className={`block-item-cart-round-reg-startup bic-bm ${angel.businessModelType.includes(item.title) ?' active' : ''}`} onClick={() => this.set(item.title)}>
                <div className='block-checkbox-front-cart'>
                    <CheckBox
                        active={angel.businessModelType.includes(item.title)}
                    />
                </div>
                <p className="description-back-cart">
                    {item.description}
                </p>
                <p className="title-front-cart">
                    {item.title}
                </p>
                <p className="description-front-cart">
                    {item.name}
                </p>
                <div className="block-button-cart">
                    <p className="title-button-cart">
                        {angel.businessModelType.includes(item.title)?'Cancel':'Choose'}
                    </p>
                </div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-25">
                                Business type
                            </p>
                            <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Startups build their businesses for different target audiences depending on the business models they choose.
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Which business models are of a greater interest to your angel network? (you may choose a few options if you'd like)
                            </p>

                            <div
                                className="block-list-cart-round-reg-startup padding-top-35 margin-top-50">
                                {list}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(8, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(10)}
                                paddingButton={'54'}
                                disabled={!this.state.angel.businessModelType.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step10.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step10)
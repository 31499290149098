import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Swipe from '../../controls3.0/swipe/Swipe'
import NumberFormat from "react-number-format";
import CheckBox from "../../controls3.0/checkBox/checkBox";
import InputRange from "react-input-range";
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";
import $ from "jquery";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();
        this.state = {
            InvSizeMin:0,
            InvSizeMax:10000000,
            MaxNumber:0,
            TempInputMin:0,
            TempInputMax:10000000,
            realInputMin: null,
            realInputMax: null,
        };
    }

    componentDidMount(){
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);

        this.InputMaxFocusOut();
        this.InputMinFocusOut();

        if (this.props.MinSize) {
            this.setState({
                InvSizeMin:this.props.MinSize,
                TempInputMin:this.props.MinSize,
            })
        }

        if (this.props.MaxSize) {
            this.setState({
                InvSizeMax:this.props.MaxSize,
                TempInputMax:this.props.MaxSize
            })
        }

        if (this.props.searchSettings && this.props.searchSettings['investment_size'].length) {
            let InvSizeObj = this.props.searchSettings['investment_size'];

            if(InvSizeObj[0].min && InvSizeObj[0].max){
                this.setState({
                    realInputMin: InvSizeObj[0].minReal,
                    realInputMax: InvSizeObj[0].maxReal,
                    InvSizeMin: parseInt(InvSizeObj[0].min),
                    InvSizeMax: parseInt(InvSizeObj[0].max)
                });
            }

        }

        this.setState({MaxNumber:Math.max.apply(null, this.props.NumberOfProfiles)});

    }


    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    ResetPopup(){
        this.setState({
            InvSizeMin:this.props.MinSize,
            InvSizeMax:this.props.MaxSize
        })
    }

    InputMinFocusOut = () => {
        $('.accel-inv-input-wrapper .input-min input').focusout(()=>{
            let value = Math.ceil(this.state.TempInputMin/this.props.pocketSize)*this.props.pocketSize;
            if (this.state.TempInputMin%this.props.pocketSize !== 0) value -= this.props.pocketSize;

            if (this.state.TempInputMin < this.props.MinSize) {
                this.setState({
                    InvSizeMin:this.props.MinSize,
                    TempInputMin:this.props.MinSize
                });
            }

            else if (value >= this.state.InvSizeMax) {
                this.setState({
                    InvSizeMin:this.state.InvSizeMax-this.props.pocketSize,
                    TempInputMin:this.state.InvSizeMax-this.props.pocketSize
                });
            }

            else this.setState({InvSizeMin:value,TempInputMin:value});
        });


    };

    InputMaxFocusOut = () => {
        $('.accel-inv-input-wrapper .input-max input').focusout(()=>{
            let value = Math.floor(this.state.TempInputMax/this.props.pocketSize)*this.props.pocketSize;
            if (this.state.TempInputMax%this.props.pocketSize !== 0) value += this.props.pocketSize;

            if (value > this.props.MaxSize) {
                this.setState({
                    InvSizeMax:this.props.MaxSize,
                    TempInputMax:this.props.MaxSize,
                });
            }

            else if (value <= this.state.InvSizeMin) {
                this.setState({
                    InvSizeMax:this.state.InvSizeMin+this.props.pocketSize,
                    TempInputMax:this.state.InvSizeMin+this.props.pocketSize,
                });
            }
            else this.setState({InvSizeMax:value, TempInputMax:value});
        });
    };

    onChangeMin = (obj) => {
        this.setState({
            realInputMin: obj.floatValue,
            TempInputMin: obj.floatValue
        })
    };
    onChangeMax = (obj) => {
        this.setState({
            realInputMax: obj.floatValue,
            TempInputMax:obj.floatValue
        })
    };

    onChangeRange = (value) => {
        this.setState({
            realInputMin: value.min,
            realInputMax: value.max,
            InvSizeMin: value.min,
            InvSizeMax: value.max,
            TempInputMin: value.min,
            TempInputMax: value.max
        })
    };

    showGistogramm(){
        let i = -1;
        const gistogramm = this.props.NumberOfProfiles.map((item)=>{
            i++;
            return  <div className='col'
                         style={(this.state.InvSizeMin <= this.props.MinSize+(this.props.pocketSize*i) && this.state.InvSizeMax >= this.props.MinSize+(this.props.pocketSize*(i))) ?
                             {background:'#0ecfaa', height:`${item*100/this.state.MaxNumber}px`, marginTop:`${100-(item*100/this.state.MaxNumber)}px`,
                                 maxWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)}px`,  minWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)}px`} :

                             {background:'#c4c4c4', height:`${item*100/this.state.MaxNumber}px`, marginTop:`${100-(item*100/this.state.MaxNumber)}px`,
                                 maxWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)}px`,  minWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)}px`}}/>
        })

        return gistogramm;
    }

    SetSettings(){
        if(this.state.InvSizeMin !== this.props.MinSize || this.state.InvSizeMax !== this.props.MaxSize) {
            this.props.setSearchSettings(
                'investment_size',
                [
                    {
                        minReal: this.state.realInputMin,
                        maxReal: this.state.realInputMax,
                        min: this.state.InvSizeMin,
                        max:this.state.InvSizeMax
                    }
                ]
            );
        } else {
            this.props.setSearchSettings('investment_size', []);
        }
        this.ClosePopup()
    }

    render() {

        let {mobileHeight} = this.props;

        const {InvSizeMax, InvSizeMin} = this.state;

        return (
            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                <Scrollbars>
                <div className='search-popup search-popup-bmodel row-m0 no-gutters' id='inv-size' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>



                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this scroll-this-display'}>

                            <div style={{maxHeight: '90%', padding: '13px 25px 20px 25px'}}>
                                <div className='row-m0 accel-gistogramm-block no-gutters'>
                                    {this.showGistogramm()}
                                    <div className='col-12 container-input-range' style={{maxWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)*this.props.NumberOfProfiles.length}px`}}>
                                        <InputRange
                                            minValue={this.props.MinSize}
                                            maxValue={this.props.MaxSize}
                                            step={this.props.pocketSize}
                                            value={{min:this.state.InvSizeMin, max:this.state.InvSizeMax}}
                                            onChange={value => this.onChangeRange(value)} />
                                    </div>
                                </div>
                                <div className='row-m0 accel-inv-input-wrapper w-100 no-gutters'>
                                    <div className='col' style={{maxWidth: '125px'}}>
                                        <div className="block-new-input input-min">
                                            <NumberFormat
                                                prefix={' $'}
                                                thousandSeparator={' '}
                                                value={this.state.realInputMin || this.state.TempInputMin}
                                                onValueChange={this.onChangeMin}
                                            />
                                        </div>
                                    </div>
                                    <div className='col' style={{maxWidth: '125px'}}>
                                        <div className="block-new-input input-max">
                                            <NumberFormat
                                                prefix={' $'}
                                                thousandSeparator={' '}
                                                value={this.state.realInputMax || this.state.TempInputMax}
                                                onValueChange={this.onChangeMax}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>

                    <div className='not-scroll-this row-m0 no-gutters'>
                        <div className='row-m0 accel-gistogramm-block no-gutters'>
                            {this.showGistogramm()}
                            <div className='col-12 container-input-range' style={{maxWidth:`${Math.floor(270/this.props.NumberOfProfiles.length)*this.props.NumberOfProfiles.length}px`}}>
                                <InputRange
                                    minValue={this.props.MinSize}
                                    maxValue={this.props.MaxSize}
                                    step={this.props.pocketSize}
                                    value={{min:this.state.InvSizeMin, max:this.state.InvSizeMax}}
                                    onChange={value => this.onChangeRange(value)} />
                            </div>
                        </div>
                        <div className='row-m0 accel-inv-input-wrapper w-100 no-gutters'>
                            <div className='col' style={{maxWidth: '125px'}}>
                                <div className="block-new-input input-min">
                                    <NumberFormat
                                        prefix={' $'}
                                        thousandSeparator={' '}
                                        value={this.state.realInputMin || this.state.TempInputMin}
                                        onValueChange={this.onChangeMin}
                                    />
                                </div>
                            </div>
                            <div className='col' style={{maxWidth: '125px'}}>
                                <div className="block-new-input input-max">
                                    <NumberFormat
                                        prefix={' $'}
                                        thousandSeparator={' '}
                                        value={this.state.realInputMax || this.state.TempInputMax}
                                        onValueChange={this.onChangeMax}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className='row-m0 buttons-line margin-top-15 no-gutters w-100'>
                        <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.SetSettings()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>
                    <div className='mobile-popup-footer'>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.SetSettings()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>

                </Scrollbars>
            </div>
        )


    }
}




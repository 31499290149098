import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import async from "async";

import {updateAngel} from "../../../actions/Login";
import {getListFunds} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import ButtonBorder from '../../controls3.0/button/ButtonBorder'


import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
// import packageJSON from './../../../../package.json'
// import {getTransaction} from "../../../actions/FundActions";
import {regexEmail} from "../../../utils/RegExp";
// import TabsWithRemove from "../../controls3.0/tabs/TabsWithRemove";
import plen18 from "../../../images/ilustaciya/Angel members@2x-min.png";

import ListTitlelMore from '../../controls3.0/listTitleMore/ListTitlelMore';

class Step16 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {
                team:  []
            };
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            contact: '',
            ref_f:  `${window.location.origin}/start_reg?ref_f=${angel.id}`,
            event_data: {
                copy_invite_link: false,
                skip: false
            }
        }
    }

    componentDidMount() {

    }


    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.trim();
        this.setState({[name]: value}, () => {
            if (value) {
                this.getByString(value)
            } else {
                this.setState({foundFunds: []})
            }

        })
    }

    onChangeEmail = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.trim();
        this.setState({[name]: value})
    }

    getByString = str => {
        this.setState({loading2: true})
        getListFunds(str)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({foundFunds: res.data})
                this.setState({loading2: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading2: false})
            })
    }

    invite = () => {
        this.setState({invited: true})
    }

    onChange2 = (e, key) => {
        let team = this.state.angel.team;
        team[key] = e.target.value;
        this.setState({angel: {...this.state.angel, team: team}})
    }

    add = () => {
        let contact = {email : this.state.contact};

        let team = this.state.angel.team || [];
        let angel = this.state.angel;

        if (regexEmail.test(contact.email)) {
            this.setState({errors: {...this.state.errors, contact: ''}})
            this.setState({angel: angel}, () => {
                this.setState({contact: ''})
            })
        } else {
            this.setState({errors: {...this.state.errors, contact: 'Wrong link'}})
            return
        }

        try{
            if(contact.email.toLowerCase() == this.props.user.email.toLowerCase()){
                return
            }
        }catch (e) {

        }

        if (team.includes({email: contact.email})) return;
        team.push(contact);
        angel.team = team;


        this.setState({angel})

    }

    remove = (title, key) => {
        let team = this.state.angel.team
        team.splice(key, 1)
        this.setState({angel: {...this.state.angel, team: team} })
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        const fundId = this.state.angel.id;

        if (onlyStep) {
            obj = {}
            this.next(obj, onlyStep, nextStep)
        } else {
            this.setState({loading: true})

            axios
                .post(`/v2/funds/${fundId}/team/invite`, {members: this.state.angel.team || []})
                .then(res => {
                    this.next(obj, onlyStep, nextStep);
                })
        }

    }

    next = (obj, onlyStep, nextStep) => {
        const fundId = this.state.angel.id;
        obj.event_type = 'network_team_invite_15';
        obj.event_data = this.state.event_data;

        if(nextStep === 17 && onlyStep){
            obj.event_data.skip = true;
        }

        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, angel, errors} = this.state;

        let team = angel.team || [];


        team = team.map((item, key) => {
            return (
                {title: item.email}
            )
        })

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg">
                        <p className="title-top-page-reg-expert color-text-reg-gray text-h1 left-text shell-margin-bottom-15">
                            Current team
                        </p>

                        <p className="title-top-page-reg-expert color-text-reg-gray text-button-3 left-text shell-margin-bottom-30">
                            Here you can invite other members of the angel network to join the Platform. <br/>
                            This will let you collaborate and invest jointly even more efficiently.
                        </p>


                        <div className='step-21-btm clearfix'>
                            <div className='s21-left no-float-620'>
                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                                    Invitation email list
                                </p>
                                {/*<p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">*/}
                                {/*You will earn <span>10%</span> from invited friends*/}
                                {/*</p>*/}

                                <div className="block-input-item-form-add-edit-modal-reg-expert input-item-form-add-adaptive">
                                    <p className="title">Enter emails of the members here</p>
                                    <Input
                                        type={'text'}
                                        name={'contact'}
                                        value={this.state.contact}
                                        onChange={this.onChange}
                                        error={errors.contact}
                                        placeholder={'Insert email'}
                                    />
                                    <ButtonBlue
                                        TitleButton={'Invite'}
                                        EventOnClick={() => this.add()}
                                        paddingButton={'26'}
                                        disabled={false}
                                    />
                                </div>

                                <div>
                                    <ListTitlelMore
                                        data={team}
                                        onChange={(e) => console.log(e)}
                                        viewCountItem={3}
                                        removeFromList={this.remove}

                                    />
                                </div>


                            </div>
                            <div className='s21-right no-float-620 new-no-padding-down-620'>
                                <img src={plen18} alt="" className=""/>
                                <p className="text-button-2">
                                    To ease the invitation process, you can share the link below with all your team members at once. This will enable them to create their own angel profiles and  automatically link them to your angel network.
                                </p>
                                <div className="block-button-link">
                                    <ButtonBorder
                                        TitleButton={'Copy link'}
                                        EventOnClick={() => {
                                            navigator.clipboard.writeText(this.state.ref_f)
                                            showMessage({type: 'info', data: 'Copied!'});
                                            this.setState({event_data: {...this.state.event_data, copy_invite_link: true}})
                                        }}
                                        paddingButton={'20'}
                                    />
                                    <a href={this.state.ref_f} className="link" target="_blank">{this.state.ref_f}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={(team.length>1)?'Send invitations':'Send invitation'}
                                EventOnClick={() => team.length ? this.update(16) : this.update(17, true)}
                                paddingButton={'54'}
                                disabled={!team.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step16.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step16)
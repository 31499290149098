import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";
import {getMoneyView} from "../../../utils/transformation";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import InputRange from 'react-input-range';


class Step12 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {},
            value: {min: 5000, max: 1000000},
            step: 5000
        }
    }

    componentDidMount() {
        if (this.state.investor.investmentSize && this.state.investor.investmentSize.min) {
            this.setState({
                value: {
                    ...this.state.value,
                    min: this.state.investor.investmentSize.min,
                    max: this.state.investor.investmentSize.max
                }
            })
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({investor: {...this.state.investor, [name]: value}})
    }

    onChangeRange = value => {
        let min = this.state.value.min,
            max = this.state.value.max,
            param,
            step

        if (value.min <= 5000) value.min = 5000

        if(max !== value.max) {
            if(value.min !== min) {
                value.min = min
            }
            param = 'max'
        } else {
            param = 'min'
        }

        let param2 = value[param]

        if(param2 <= 100000 ){
            step = 5000
        } else if (param2 > 100000 && param2 <= 1000000 ) {
            step = 10000
        } else if(param2 > 1000000){
            if(param2 < 1100000)
            value[param] = 1100000
            step = 100000
        }
        this.setState({step: step, value: value})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) {
            obj.investmentSize = this.state.value;
        }

        if (!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className={'container-step-1-reg'}>
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Investment size range (in USD)
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0">
                                    How much money are you ready to invest? What is your average investment cheque?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg container-bottom block-gradient-gray-reg"
                         style={{position: 'relative'}}>
                        <div className="container-step-1-reg padding-reg">
                            <div className="shell-margin-bottom-40 margin-top-30 block-input-range" style={{maxWidth:'100%'}}>
                                <div className="title-input-range" style={{minHeight: '45px'}}>
                                    <p className="title-input-range">Average investment cheque:
                                    <div className='data-slider-turnover'>
                                        <span className='subtext'>from</span>
                                        <span className="value">{getMoneyView(this.state.value.min)}</span>
                                        <span className='subtext'>to</span>
                                        <span className="value">{getMoneyView(this.state.value.max)}</span>
                                    </div>
                                    </p>
                                </div>
                                <div className="block-input-range slider slider-green ">


                                    <div className={`container-input-range`}>
                                        <InputRange
                                            minValue={5000}
                                            maxValue={10000000}
                                            step={this.state.step}
                                            value={this.state.value}
                                            onChange={value => this.onChangeRange(value)}/>
                                    </div>
                                    <p className="title-left-right-input-range">
                                        <span className="left">5k</span>
                                        <span className="right">10m+</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(11, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(13)}
                                paddingButton={'54'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step12.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step12)
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import './forgot-pass.css'
import {reset} from '../../actions/UserSignup'
import Loader  from './../test-controls/preloader/preloader'
import {setNewPassword, checkResetToken} from "../../actions/Login";
import Input from "../controls3.0/input/Input";
import ButtonGreen from "../controls3.0/button/ButtonGreen";
import history from "../../history";

class ResetPage extends React.Component {

  constructor(props){
    super();
    this.state = {
      password: '',
      passwordConfirm: '',
      token: props.match.params.token,
      errors:{},
      serverError:'',
      loaded: false,
      isSuccess: false,
      regexPass: new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})/),

    };
  }

  componentDidMount(){
      if(this.state.token){
          checkResetToken(this.state.token).then( r => {
              if(r.data){
                  this.setState({loaded: true});
              } else {
                  this.setState({serverError: 'TOKEN_NOT_FOUND'})
              }

          }).catch(e => {
              this.setState({serverError: 'TOKEN_NOT_FOUND'})
          })
      } else {
          this.setState({serverError: 'TOKEN_NOT_FOUND'})
      }
  }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        this.setState({[name]: value})

        if(!value){
            this.setState({errors:{...this.state.errors, [name]: 'Field is required'}})
        } else {
            this.setState({errors:{...this.state.errors, [name]: null}})
        }
        if(name == 'password') this.validatePass(value)
        if(name == 'passwordConfirm') this.confirmPass(value)
    }

    validatePass = value => {
        if(!this.state.regexPass.test(value)) {
            this.setState({errors:{...this.state.errors, ['password']: 'Minimum 8 characters - lower and upper case symbols and digit(s)'}})
        }else {
            let errors = this.state.errors;
            delete errors.password;
            this.setState({errors})
        }
    }

    confirmPass = value => {
        if(this.state.password){
            if(this.state.password != value) {
                this.setState({errors:{...this.state.errors, ['passwordConfirm']: 'Passwords don\'t match'}})
            }else {
                let errors = this.state.errors;
                delete errors.passwordConfirm;
                this.setState({errors})
            }
        }
    }

    validate = data => {
        const errors = {}
        for (let key in data) {
            if(!data[key]) {
                switch (key) {
                    case 'password':
                        errors[key] = 'Minimum 8 characters - lower and upper case symbols and digit(s)';
                        break;
                }
            } else {
                if(data[key].length > 100) errors[key] = 'max length 100 characters';
            }
        }

        if(data.password != data.passwordConfirm) {
            errors ['passwordConfirm'] = 'Passwords don\'t match'
        }
        return errors;
    }

    send = () =>{
        let data = {
            password: this.state.password,
            passwordConfirm: this.state.passwordConfirm
        }
        let errors = this.validate(data)
        this.setState({ errors })
        if(!Object.keys(errors).length){
            this.setState({loaded: false})
            setNewPassword(data.password, this.state.token)
                .then(()=>{
                  this.setState({isSuccess: true, loaded: true})
                  setTimeout(function() {
                      this.props.history.push('/')
                  }.bind(this), 5000);

              })
              .catch(error => {
                  this.setState({serverError:error.response.data.error, loaded: true})
              })
        }
  }



  render () {

      return (
          <div>
              {(this.state.serverError !== 'TOKEN_NOT_FOUND') &&
              <div className="modal-login new-pass-page">


                  {!this.state.isSuccess &&
                      <div className="modal-login-block modal-register-block">
                          <div className="block-top fix">
                              <div className='sr-title'>New password</div>

                              {/*<img src="../../img/x.svg" alt="" className="close-icon" onClick={() => window.location.href='/'}/>*/}
                              <div className='close-title' onClick={() => window.location.href='/'}>
                                  <div className='close' style={{right:'0', left:'auto', opacity:'1'}}/>
                              </div>

                          </div>

                          <div className="block-form">

                            <div className="input-reg-dao" style={{paddingBottom:'5px'}}>
                                <Input
                                    type={'password'}
                                    name={'password'}
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    placeholder={'Type Password'}
                                    error={this.state.errors.password}
                                />
                            </div>
                            <div className="input-reg-dao" style={{paddingBottom:'5px'}}>
                                <Input
                                    type={'password'}
                                    name={'passwordConfirm'}
                                    value={this.state.passwordConfirm}
                                    onChange={this.onChange}
                                    placeholder={'Retype Password'}
                                    error={this.state.errors.passwordConfirm}
                                />
                            </div>


                            <div>
                                  <button className="login-form__btn login-form__btn_join btn-change-pass"  onClick={this.send}>Change password</button>
                                  <div className="reg_error_field err_center fix-error">{this.state.serverError}</div>
                              </div>


                          </div>

                      </div>
                      }

                      {this.state.isSuccess &&
                      <div className="modal-login-block modal-register-block">
                          <div className="block-top">
                              <div className='sr-title'>New password</div>
                              {/*<img src="../../img/x.svg" alt="" className="close-icon" onClick={() => window.location.href='/'}/>*/}
                              <div className='close-title' onClick={() => window.location.href='/'}>
                                  <div className='close' style={{right:'0', left:'auto', opacity:'1'}}/>
                              </div>
                          </div>
                          <div className="sr-description">
                              Your password has been changed successfully!
                          </div>
                      </div>
                      }


                      <Loader loaded={this.state.loaded}></Loader>

              </div>}
              {(this.state.serverError === 'TOKEN_NOT_FOUND') &&
              <div className='modal-error-404-wrapper'>
                  <div className='modal-error-404-block text-center font-weight-500'>
                      <div className='text-error-404'>
                          Error 404
                      </div>
                      <div className='text-error-404-descr error-404-descr-padding'>
                          The link does not exist or has expired.
                      </div>

                      <div className='modal-error-404-button'>
                          <ButtonGreen
                              TitleButton={'Go to the main page'}
                              EventOnClick={() => {window.location.href='/'}}
                              paddingButton={'29'}
                              disabled={false}
                          />
                      </div>
                      <div className='modal-error-404-bg-person'/>
                  </div>

                  <div className='modal-error-404-bg'>

                  </div>
              </div>


              }
          </div>


      )

  }
}

ResetPage.propTypes = {
  history:PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default connect (null, {})(ResetPage)






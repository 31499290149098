import React, {useRef} from 'react';
import Input from "../../../controls3.0/input/Input";
import Select from "../../../controls3.0/select/Select";
import {COUNTRIES, LANGUAGES} from "../../../../utils/countriesLanguages";
import {SocialContacts} from "../../../v3/socialContacts";
import RegAvatar from "../../../controls3.0/regAvatar/regAvatar";
import {regexEmail, regexPhone} from "../../../../utils/RegExp";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import Tabs from "../../../controls3.0/tabs/Tabs";
import {
    createWorkExp,
    deleteWorkExp,
    getListSkillsByStringInvestor,
    getListSkillsInvestor, getListWorkExp, updateWorkExp
} from "../../../../actions/Profile";
import {updateUser2} from "../../../../actions/Login";

import ApolloClient from "apollo-boost"
import {gql} from 'apollo-boost';
import {Query} from 'react-apollo';
import config from '../../../../config';
import axios from "axios";
import {showMessage} from "../../../../utils/showMessage";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import NameBlock from "../../../v4/nameBlock";
import AddLang from "../../../v4/addLang";
import LocationBlock from "../../../v4/locationBlock";
import PhotoBlock from "../../../v4/photoBlock";
import SkillTags from "../../../v4/skillTags";


import {updateMenu} from "../../../../actions/menu";

import ListTitleDescriptionMore from '../../../controls3.0/listTitleDescriptionMore/ListTitleDescriptionMore'
import {WorkExperience} from '../../../v3/workExperience';
import moment from "moment";


const GET_USER = gql`
    query {
        user{
            id
            email
            lastName
            firstName
            logoFileId
            country
            email
            phone
            lang
            skills{
                id
                title
            }
            facebookLink
            twitterLink
            instagramLink
            linkedInLink
            mediumLink
            telegramLink
            contacts
            experience
        }
    }
`;


const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const FlyButton = ({title='Apply changes', onlyApply=false, apply, reset, errors}) => {
    return (
        <div className={'container-fluid fixed fly-button-container'}>
            <div className={'container no-gutters'}>
                <div className={'row-b'}>
                    <div className={'col-12 col-sm-4'}>
                    </div>
                    <div className={'col-12 col-sm-8 pr-asm-0'}>
                        <div className='row-b button-wrapper no-gutters'>
                            <div className={'col-12 col-sm-auto'}>
                                <ButtonBlue
                                    TitleButton={title}
                                    EventOnClick={() => {apply()} }
                                    paddingButton={'16'}
                                    disabled={!!Object.keys(errors).length}
                                />
                            </div>
                            <div className={'col-12 reset-button-column col-sm-auto'}>
                                {!onlyApply && <div className='button-reset-wrapper'>
                                    <ButtonBlue
                                        TitleButton={'Reset'}
                                        EventOnClick={() => {reset()} }
                                        paddingButton={'16'}
                                        disabled={false}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            country: '',
            email: '',
            phone: '',
            errorsIn: {},
            validate: true,
            suggestedArrSkills: [],
            searchArrSkills: [],
            skills: [],
            inputSearch: '',
            contacts: {
                contacts: [],
            },
            myLangs: [],
            listWE: [],
            imgBlob: null,
            FixButton: false,
            workExperienceData: null
        }
    }

    componentDidMount() {
        this.setState({
            FixButton: Boolean(document.documentElement.scrollHeight-document.documentElement.clientHeight)
        });

        client.query({ query: GET_USER, fetchPolicy: "network-only" }).then(r => {
            this.setState({
                firstName: r.data.user.firstName,
                lastName: r.data.user.lastName,
                email: r.data.user.email,
                logoFileId: r.data.user.logoFileId,
                contacts: {
                    contacts:       r.data.user.contacts || [],
                    facebookLink:   r.data.user.facebookLink,
                    twitterLink:    r.data.user.twitterLink,
                    instagramLink:  r.data.user.instagramLink,
                    linkedInLink:   r.data.user.linkedInLink,
                    mediumLink:     r.data.user.mediumLink,
                    telegramLink:   r.data.user.telegramLink,
                },
                country: [{Name:r.data.user.country}],
                myLangs: r.data.user.lang || [],
                skills: [...r.data.user.skills] || [],
                phone: r.data.user.phone,
                listWE: r.data.user.experience || [],
                errorsIn: {},
                imgBlob: null

            }, () => {console.log('CURRENT_STATE', this.state)})
        })

        getListSkillsInvestor(1)
            .then((res) => {
                if (Array.isArray(res.data)) {
                    this.setState({suggestedArrSkills: res.data})
                }
            })
            .catch(err => {})

        // getListWorkExp(this.state.user.id)
        //     .then(res => {
        //         let sorArr = this.sortWE(res.data)
        //         this.setState({listWE: sorArr})
        //     })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.user && this.state.firstName === '' && this.state.lastName === '') {
        //     console.log(this.props.user);
        //
        //     this.setState({
        //         firstName:this.props.user.firstName,
        //         lastName:this.props.user.lastName,
        //         country:[{Name:this.props.user.country}],
        //         email:this.props.user.email,
        //     })
        // }

        if (this.props.openedSection !== prevProps.openedSection) {
            this.setState({
                FixButton: Boolean(document.documentElement.scrollHeight-document.documentElement.clientHeight)
            });
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace(),
            _errors = this.state.errorsIn,
            required = false,
            limit = 200;

        switch (name) {
            case 'lastName':
            case 'firstName':
                limit = 20;
                required = true;
                break;
            case 'phone':
                if(!regexPhone.test(value)){
                    value = value.substring(0, value.length - 1)
                }
                break;
            case 'inputSearch':
                if(value){
                    this.getByString(value)
                } else {
                    this.setState({
                        searchArrSkills:[]
                    });
                }
        }

        if (value.length) {
            if (value.length > limit) {
                value = value.slice(0, limit);
                _errors[name] = 'max length ' + limit + ' symbols';
            } else {
                delete _errors[name];
            }
        }

        if (required && !value) {
            _errors[name] = 'Field is required'
        }

        this.setState({
            [name]:value,
            errorsIn:{..._errors}
        })

    };

    onChangeEmail = event => {
        let {value} = event.target,
            _errors = this.state.errorsIn;

        value = value.trim();


        if(!regexEmail.test(value) && value.length){
            _errors.email = 'Invalid e-mail address';
        } else {
            delete _errors.email;
        }
        this.setState({
            email:value,
            errorsIn:{..._errors}
        });

    };

    onChangecountry = (data) => {
        if (data) {
            this.setState({
                country: data
            });
        }
    };

    handleSocial = (_teas, _errors, _validate) => {
        this.setState({
            contacts: _teas,
            validate:_validate,
            errorsIn:{..._errors}
        });
    };

    //SKILLS FUNCTIONS

    getByString = str => {
        // this.setState({loading: true})
        getListSkillsByStringInvestor(str)
            .then((res) => {
                if (Array.isArray(res.data)) {
                    this.setState({searchArrSkills:res.data})
                }
                //this.setState({loading: false})
            })
            .catch(err => {
                //showMessage({type: 'error', data: err})
                //this.setState({loading: false})
            })
    };

    addToSkills = skill => {
        let _skills = this.state.skills;
        let isFound = _skills.find(x => x.title === skill.title)
        if (!isFound) _skills.push(skill)
        this.setState({
            skills:[..._skills]
        })
    };

    removeSkill = skill => {
        if(skill) {
            let filtered = this.state.skills.filter(x => x.title !== skill.title);
            this.setState({
                skills:filtered
            })
        }
    };

    addNew = () => {
        let s = this.state.inputSearch;
        let obj = {title: s};
        this.addToSkills(obj);
        this.setState({
            inputSearch:''
        });
    };

    updateProfile = () => {
        const {contacts, firstName, lastName, country, skills, phone, myLangs, listWE} = this.state;

        let data = {};

        if(contacts){data = {...contacts};}
        if(firstName){data.firstName = firstName;}
        if(lastName){data.lastName = lastName;}
        if(country){data.country = country[0].Name;}
        if(skills){data.skills = skills;}
        if(phone){data.phone = phone;}

        if(myLangs){data.lang = myLangs;}

        if(listWE){data.workExperience = listWE;}

        updateUser2(data).then(r=>{
            showMessage({type: 'info', data: 'Saved!'})
        }).catch(e => {
            console.error(e);
        })
    };

    updatePhoto = () => {
        if(this.state.imgBlob){
            let data = new FormData();
            data.append("avatar", this.state.imgBlob, "imageFilename.png");
            updateUser2(data).then(r=>{
                showMessage({type: 'info', data: 'Saved!'});
                this.props.updateMenu({type: 'update'});
            })
        }
    };

    onChangeLogo = (imgBlob) => {
        this.setState({imgBlob})
    }

    onChangeCountry(e){
        let _myLangs = this.state.myLangs;
        _myLangs.push(e[0].Name);
        this.setState({myLangs:_myLangs});
    };

    removeCountry(item){
        if(item) {
            let filtered = this.state.myLangs.filter(x => x !== item);
            this.setState({myLangs:filtered});
        }
    }

    onSave = (experience) => {
        let userId = this.props.user ? this.props.user.id : null,
            createUpdateFunc = experience.id ? updateWorkExp : createWorkExp

        // let errors = this.validate(experience)
        // this.setState({errors})
        // if (Object.keys(errors).length) return;

        const copy = Object.assign({}, experience);
        copy.city = experience.city[0].Name
        if (copy.currentJob) delete copy['periodTo']

        createUpdateFunc(userId, copy)
            .then(res => {
                this.addWE(res.data)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    }

    addWE = data => {
        let listWE = this.state.listWE;

        const index = listWE.findIndex(item => item.id === data.id);

        if (index > -1) {
            listWE[index] = data;
            this.setState({listWE,
                showAdd: false,
                editWP: false})
        } else {
            let arr = [...listWE, data];
            let arrSort = this.sortWE(arr);
            this.setState({
                listWE: arrSort,
                showAdd: false,
                editWP: false
            })
        }

        this.setState({
            experience: {
                position: '',
                description: '',
                company: '',
                city: '',
                periodFrom: '',
                periodTo: ''

            }
        })
    }

    deleteWE = key => {
        let listWE = this.state.listWE,
            one = listWE[key]
        deleteWorkExp(this.props.user.id, one.id)
            .then(res => {
                listWE.splice(key, 1)
                this.setState({listWE})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    }

    sortWE = arr => {
        let sortArr = arr.sort(function (a, b) {
            return new Date(b.periodFrom) - new Date(a.periodFrom);
        });
        return sortArr
    }

    setWorkExperienceData = id => {
        const current = this.state.listWE.find((i) => i.id === id);
        this.setState({workExperienceData: {...current, city : [{Name: current.city}]}, editWP: true});
    };

    render() {
        let {searchArrSkills, inputSearch, errorsIn} = this.state;
        let {user, openedSection} = this.props;
        const {firstName, lastName, country, logoFileId, email, phone, skills, showAdd, FixButton} = this.state;

        let listWE = this.state.listWE.map((item, key) => {
            let obj = {};
            obj.id = item.id;
            obj.title = `${moment(item.periodFrom).format('MMM YYYY')} - ${ item.currentJob ? 'Present' : moment(item.periodTo).format('MMM YYYY')}`;
            obj.description = `${item.position} — ${item.company}`;
            return obj
        });


        return (
            <div className={`block-user-right block-page ${openedSection === 5?'big-user-right':''}`}>
                <React.Fragment>
                    {openedSection === 0 && <div>
                        {/*USER NAME*/}
                        <div className='profile-user-name-block profile-user-shadow name-block-wrapper'>
                            <NameBlock
                                firstName = {this.state.firstName}
                                lastName={this.state.lastName}
                                parentCallback={  (data) => {this.setState({...data})} }
                            />
                        </div>

                        {/*USER LANGUAGES*/}
                        <div className='profile-user-name-block profile-user-shadow lang-block-wrapper'>
                            <AddLang langs={[...this.state.myLangs]}
                                     parentCallback={ (langs) => {this.setState({myLangs:langs})}}
                            />
                        </div>

                        {/*LOCATION*/}
                        <div className='profile-user-name-block profile-user-shadow location-block-wrapper'>
                            <LocationBlock
                                country={this.state.country}
                                parentCallback={ (country) => {this.setState({country:country})}}
                            />
                        </div>

                        {/*SOCIAL*/}
                        <div className='row-m0 profile-user-name-block block-social profile-user-shadow no-gutters'>
                            <div className='row-m0'>
                                <div className='block-location-title w-100'>Social media accounts</div>
                                <div className='block-location-descr margin-top-10 color-rgba-88 w-100'>How can
                                    the community contact you?<br/>Share the links to your social media
                                    networks here.
                                </div>
                            </div>
                            <div className='row-m0 social-links-block w-100'>
                                <SocialContacts object={this.state.contacts} errors={errorsIn}
                                                handle={this.handleSocial}/>
                            </div>
                        </div>

                        {!FixButton && <div className='row-b button-wrapper buttons-personal-info no-gutters'>
                            <div className={'col-auto'}>
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {this.updateProfile()} }
                                    paddingButton={'16'}
                                    disabled={false}
                                />
                            </div>
                            <div className={'col-auto'}>
                                <div className='button-reset-wrapper'>
                                    <ButtonBlue
                                        TitleButton={'Reset'}
                                        EventOnClick={() => {this.componentDidMount()} }
                                        paddingButton={'16'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>}

                        {FixButton && <FlyButton apply={() => {this.updateProfile()}} reset={ () => {this.componentDidMount()}} errors={{}}/>}



                </div>}

                    {openedSection === 1 && <div>
                        {/*USER PHOTO*/}
                        <div className='profile-user-name-block profile-user-shadow photo-block-wrapper'>
                            <PhotoBlock logoFileId = {this.state.logoFileId} parentCallback={this.onChangeLogo} parentBlob={this.state.imgBlob}/>
                        </div>
                        <div className='block-photo-descr-text photo-descr-text-wrapper'>
                            Adding your photo will help investors, startups and experts find you faster
                            so that to discuss any matter or establish business relations.
                        </div>

                        {!FixButton && <div className='row-b button-wrapper buttons-photo no-gutters'>
                            <div className={'col-auto'}>
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {this.updatePhoto()} }
                                    paddingButton={'16'}
                                    disabled={false}
                                />
                            </div>
                        </div>}
                        {FixButton && <FlyButton title='Add photo' apply={this.updatePhoto} onlyApply={true} reset={()=>{this.componentDidMount()}} errors={{}}/>}
                </div>}

                    {openedSection === 2 && <div>
                        {/*USER SKILLS*/}
                        <div className='row-m0 no-gutters skill-tags-wrapper' style={{maxWidth: '620px'}}>

                            <div className='block-photo-descr-text font-weight-500'
                                 style={{lineHeight: '24px'}}>Let people know your background and scope
                                of interest.
                            </div>
                            <div className='block-photo-descr-text second-text'>
                                This ill enable you to
                                find users with similar experience to share it and expand together.
                            </div>

                            <SkillTags
                                skills={this.state.skills}
                                suggestedArrSkills={this.state.suggestedArrSkills}
                                parentCallback={ (data) => {this.setState({...data})} }
                            />
                        </div>

                        {!FixButton && <div className='row-b button-wrapper buttons-skills no-gutters'>
                            <div className={'col-auto'}>
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {this.updateProfile()} }
                                    paddingButton={'16'}
                                    disabled={false}
                                />
                            </div>
                            <div className={'col-auto'}>
                                <div className='button-reset-wrapper'>
                                    <ButtonBlue
                                        TitleButton={'Reset'}
                                        EventOnClick={() => {this.componentDidMount()} }
                                        paddingButton={'16'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>}

                        {FixButton && <FlyButton apply={() => {this.updateProfile()}} reset={() => {this.componentDidMount()}} errors={{}}/>}
                </div>}

                    {openedSection === 5 && <div className='prof-exp-wrapper'>
                        {!showAdd && !this.state.editWP &&
                        <React.Fragment>
                            <div className={'row-b'}>
                                <div className={'col-12'}>
                                    <div className="prof-exp-title margin-top-5">
                                        Let the community know you are a true specialist. <br/>
                                        Demonstrate your professional background, competencies and skills.
                                    </div>
                                </div>
                                <div className={'col-12 prof-exp-button-wrapper'}>
                                    <ButtonBlue
                                        TitleButton={'Add experience'}
                                        EventOnClick={() => this.setState({showAdd: true})}
                                        paddingButton={'22'}
                                        disabled={false}
                                        loader={false}
                                    />
                                </div>
                                <div className={'col-12'}>
                                    <div className="block-list-experience">
                                        {console.log('listWE', listWE)}
                                        <ListTitleDescriptionMore
                                            data={listWE}
                                            onChange={null}
                                            delete={this.deleteWE}
                                            onClick={this.setWorkExperienceData}
                                            titleMore={'test'}
                                            viewCountItem={document.documentElement.scrollWidth > 700? 4 : 2}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                            ||
                        <React.Fragment>
                            <div className="row-b title-top-page-reg-expert text-h1 left-text margin-0 shell-margin-bottom-10">
                                <div className={'col-12'}>
                                    <div className="block-button-circle" onClick={() => this.setState({showAdd: false, editWP: false})}>
                                        <i className="fal fa-long-arrow-left"/>
                                    </div>
                                    Add experience
                                </div>
                            </div>
                            <div className={'row-b'}>
                                <div className={'col-12'}>
                                    <WorkExperience onSave={this.onSave} data={this.state.workExperienceData}/>
                                </div>
                            </div>
                        </React.Fragment>
                        }
                </div>}

                    {openedSection === 3 && <div>
                        {/*USER CONTACTS*/}
                        <div className='row-m0 profile-user-name-block profile-user-shadow block-contacts no-gutters'>
                            <div className='row-m0 text-h5 color-rgba-88'>Contacts</div>
                            <div className='row-m0 block-photo-descr-text margin-bottom-15 margin-top-12'
                                 style={{lineHeight: '20px'}}>
                                This information will not be public. It will be used only in case of the
                                financial deals being performed.
                            </div>
                            <div className='row-b w-100-20'>
                                <div className='col-12 col-sm-6'>
                                    <p className="block-user-basic-input-title font-weight-500 left-text shell-margin-top-0">
                                        Email
                                    </p>

                                    <div className="shell-input-height-40">
                                        <Input
                                            type={'text'}
                                            name={'email'}
                                            value={email}
                                            onChange={this.onChangeEmail}
                                            autocomplete={false}
                                            placeholder={''}
                                            error={errorsIn.email}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <p className="block-user-basic-input-title font-weight-500 left-text shell-margin-top-0">
                                        Telephone number
                                    </p>

                                    <div className="shell-input-height-40">
                                        <Input
                                            type={'tel'}
                                            name={'phone'}
                                            autocomplete={false}
                                            value={phone}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errorsIn.phone}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!FixButton && <div className='row-b button-wrapper buttons-personal-info no-gutters'>
                            <div className={'col-auto'}>
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {this.updateProfile()} }
                                    paddingButton={'16'}
                                    disabled={false}
                                />
                            </div>
                        </div>}
                        {FixButton && <FlyButton apply={() => {this.updateProfile()}} reset={() => {this.componentDidMount()} } errors={{}}/>}

                    </div>}
                </React.Fragment>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    accelerator: state.accelerator
})

UserProfile.propTypes = {
    user: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, {updateMenu})(UserProfile));
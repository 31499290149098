import React, {useState, useEffect} from 'react';
import Select from "../../controls3.0/select/Select";
import {LANGUAGES} from "../../../utils/countriesLanguages";
import Tabs from "../../controls3.0/tabs/Tabs";


export const FormLang = ({data, onChange, errors = {}}) => {
    const removeLanguage = item => {
        if(item) {
            let filtered = data.filter(x => x !== item);
            onChange(filtered);
        }
    };

    const onChangeLanguage = l => {
        if (l) {
            data.push(l[0].Name);
            onChange(data);
        }
    };

    useEffect(() => {
    }, [data])

    let checkedLangs = data.map((item, key) => {
        return (
            <div className='col-auto'>
                <Tabs
                    key={key}
                    TitleTab={item}
                    TypeTab={'small'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => removeLanguage(item)}
                />
            </div>
        )
    });

    return (
        <React.Fragment>
            <div className={'row-b'}>
                <div className="shell-input-height-40">
                    {data.length !== 0 && <div className='row-m0 w-100 checked-countries-list-wrapper no-gutters margin-bottom-15'>
                        <div className='row-m0 checked-countries-block w-100'>{checkedLangs}</div>
                    </div>}
                </div>
            </div>
            <div className={'row-b'}>
                <div className={'col-12'}>
                    <div className="shell-select-reg">
                        <Select
                            placeholder={'Select'}
                            data={LANGUAGES.filter(i => {
                                return data.indexOf(i.Name) === -1
                            })}
                            onChange={(e) => onChangeLanguage(e)}
                            error={errors.lang}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
import React from 'react';
import WayPoint from '../wayPoint/WayPoint'
import TabsCounter from '../../../controls3.0/tabs/TabsCounter'
import './stylesEvalParamRoadMap.css'
import history from '../../../../history';
import i18n from '../../../../i18n';
import {getAchievement} from "../../../../actions/Login";
import {showMessage} from "../../../../utils/showMessage";
import {getListParameters} from "../../../../actions/Profile";
import {connect} from "react-redux";
import {updateExpertAchMenu} from "../../../../actions/menu";
import PropTypes from "prop-types";


class EvalParamRoadMap extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            listAchievements: [],
            title: '',
            currentAch: []
        }
    }

    async componentDidMount() {
        let obj = []
        try {
            if(this.props.match.params.id){
                let achievement = await getAchievement('expert', this.props.match.params.id);

                getListParameters()
                    .then((res) => {
                        if (Array.isArray(res.data))
                            this.setState({title: res.data[parseInt(this.props.match.params.id) - 1 ].title});
                        this.setState({loaded: true})
                    })
                    .catch(err => {
                        showMessage({type: 'error', data: err})
                        this.setState({loaded: true})
                    })

                obj[this.props.match.params.id] = achievement.data;

                this.setState({currentAch: achievement.data});
                this.setState({listAchievements: obj})
            }
        } catch (e) {

        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.expertAchMenu.object){
            try {
                if (nextProps.expertAchMenu.object.type === 'update') {
                    this.componentDidMount();
                    if (this.props.updateExpertAchMenu) {
                        this.props.updateExpertAchMenu({type: '-1'});
                    }
                }
            } catch (e) {
                console.error(e);
                if (this.props.updateExpertAchMenu) {
                    this.props.updateExpertAchMenu({type: '-1'});
                }
            }
        }
    }


    getExpertRole = (id) => {
        switch (id){
            case 244: //IAM
            case 23: //Лавриненко
            case 69: //Дробышевского
            case 28: //Колтуна
            case 25: //Михайлова
            case 31: //Курьяна
            case 30: //Граблевского
            case 34: //Гичевой
            {
                return 'Methodologist'
            }

            default: {
                return 'Candidate for Expert'
            }
        }
    }

    getActiveEval = (id) => {
        switch (id){
            case 244: //IAM
            case 23: //Лавриненко
            case 69: //Дробышевского
            case 28: //Колтуна
            case 25: //Михайлова
            case 31: //Курьяна
            case 30: //Граблевского
            case 34: //Гичевой
            {
                return true;
            }

            default: {
                return false;
            }
        }
    }

    render() {
        const {currentAch, title} = this.state;
        return (
            <div>
                <div className='block-eval-param-roadmap' 
                    style={{width: '265px', paddingLeft: '25px'}}>
                    <div className='block-eval-roadmap-header'>
                        <div className='eval-param-title'>Evaluation parameters</div>
                        <div className='eval-param-name'>{title}</div>
                        <div className='eval-param-position'>{this.getExpertRole(this.props.user.expert.id)}</div>
                    </div>
                    <div className='block-eval-roadmap-map'>
                        <div>
                            {currentAch && <WayPoint
                                name='Achievements'
                                descr={ currentAch ? currentAch.length ? i18n.t('item', {count: currentAch.length}) : 'Proof your expertize' : 'Proof your expertize'}
                                NumberActive={true}
                                isDescrActive={this.getActiveEval(this.props.user.expert.id)}
                                noDescr={false}
                                number='1'
                                isLast={false}
                            />}
                        </div>
                        <WayPoint
                            name='Expert'
                            descr='Unavailable yet'
                            NumberActive={`${this.getActiveEval(this.props.user.expert.id)?'default':'closed'}`}
                            isDescrActive={this.getActiveEval(this.props.user.expert.id)}
                            noDescr={this.getActiveEval(this.props.user.expert.id)}
                            number='2'
                            isLast={false}
                        />
                        <WayPoint
                            name='Supervisor'
                            descr='Unavailable yet'
                            NumberActive={`${this.getActiveEval(this.props.user.expert.id)?'default':'closed'}`}
                            isDescrActive={this.getActiveEval(this.props.user.expert.id)}
                            noDescr={this.getActiveEval(this.props.user.expert.id)}
                            number='3'
                            isLast={false}
                        />
                        <WayPoint
                            name='Methodologist'
                            descr='Unavailable yet'
                            NumberActive={`${this.getActiveEval(this.props.user.expert.id)?'default':'closed'}`}
                            isDescrActive={this.getActiveEval(this.props.user.expert.id)}
                            noDescr={this.getActiveEval(this.props.user.expert.id)}
                            number='4'
                            isLast={true}
                        />
                    </div>
                </div>
                {(this.state.openedMenu === 'dashboard') && <div className='block-eval-param-dashboard'>
                    <div className='dashboard-title'>Dashboard</div>
                    <div className='temp-text'><br/>The full version of the dashboard is now being developed. It will
                        come very soon!
                    </div>
                </div>}
                {(this.state.openedMenu === 'achievements') && <div className='block-eval-param-achieve'>
                    <div className='achieve-title'>Achievements in business model parameter</div>
                    <div className='toggle-buttons-line'>
                        <TabsCounter
                            TitleTab={'Articles'}
                            TypeTab={'big'}
                            EventOnClick={null}
                            active={false}
                            count={3}
                        />
                        <TabsCounter
                            TitleTab={'Diplomas'}
                            TypeTab={'big'}
                            EventOnClick={null}
                            active={true}
                            count={15}
                        />
                    </div>
                    <div className='block-eval-param-diplomas'>
                        <div className='diplom'/>
                        <div className='diplom'/>
                        <div className='diplom'/>
                        <div className='diplom'/>
                        <div className='button-show-more'/>
                    </div>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state
});

EvalParamRoadMap.propTypes = {
    user: PropTypes.object,
    updateExpertAchMenu: PropTypes.func
};

export default connect(mapStateToProps, {updateExpertAchMenu})(EvalParamRoadMap);
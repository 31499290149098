export const updateMenuAction = object => {
    return {
        type: "REFRESH_MENU",
        object
    };
};

export const updateExpertAchMenuAction = object => {
    return {
        type: "REFRESH_EXPERT_MENU",
        object
    };
};

export const updateMarketAction = object => {
    return {
        type: "REFRESH_MARKET",
        object
    };
};
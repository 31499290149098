import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";
import {validate} from '../utils/validation'

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {COUNTRIES, LANGUAGES} from "../../../utils/countriesLanguages";
import Select from "../../controls3.0/select/Select";
import human from '../../../images/ilustaciya/language-rocketDAO.svg'
import {FormLang} from "../../v3/formLang";


class Step3 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
            user.country ? investor.countryOfResidence = [{Name: user.country}] : investor.countryOfResidence = []
            investor.lang = user.lang || [];
        this.state = {
            user: user,
            investor: investor,
            errors: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({ investor: {...this.state.investor, [name]: value} })
    }

    onChangeCountry = data => {
        if (data) {
            this.setState({investor: {...this.state.investor, countryOfResidence: data}})
        }
    }

    onChangeLang = data => {
        this.setState({investor: {...this.state.investor, lang: data}});
    }

    update = (nextStep, onlyStep) => {
        let investor = this.state.investor;
        let obj = {
            countryOfResidence: investor.countryOfResidence.length? investor.countryOfResidence[0].Name : '',
            lang: investor.lang
        }
        if(onlyStep) obj = {}

        let errors = onlyStep ? {} : validate(obj)
        this.setState({errors})
        if (Object.keys(errors).length) return

        if(!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, investor, errors} = this.state


        return (
            <React.Fragment>
                <div className="container-full-reg" style={{position:'relative', padding:'0 0 250px 0'}}>
                    <img src={human} alt="" className="img-right-step" style={{top:'100px',right:'0',height:'460px'}}/>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20">
                        Let community get to know you
                    </p>
                    <p className="description-page-reg-expert text-button left-text shell-margin-bottom-40" style={{maxWidth:'530px'}}>
                        Filling in some basic information about yourself will ease communication with other users! <br/>
                        Trust, honesty, and transparency are the basis of the community.
                    </p>
                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20">
                        Location
                    </p>
                    <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20">
                        Please choose the language you are fluent in.
                    </p>

                    <div className="shell-select-reg shell-margin-bottom-40">
                        <Select
                            placeholder={'Select'}
                            data={COUNTRIES}
                            value={investor.countryOfResidence}
                            onChange={(e) => this.onChangeCountry(e)}
                            error={errors.countryOfResidence}
                        />
                    </div>

                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20">
                        Language
                    </p>
                    <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20">
                        Please choose languages you can <br/>
                        freely speak on, communicate and <br/>
                        work.
                    </p>

                    <FormLang data={investor.lang} onChange={this.onChangeLang}/>

                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(4)}
                                paddingButton={'56'}
                                disabled={!investor.lang.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step3.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step3)
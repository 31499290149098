import React from 'react';

import spin from './../../images/spin.gif'


export default class ButtonLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }



    render() {
        let {
            title,
            loading,
            onClickFunc
        } = this.props



        return (
            <button className={`btn ${title === 'Back'? 'btn-white' : ''}`} onClick={onClickFunc} disabled={loading}>
                {title}
                <span className={`spinner ${loading ? 'loading' : ''} `}>
                    <img src={spin} alt=""/>
                </span>
            </button>
        );
    }
}

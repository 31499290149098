import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import {COUNTRIES, LANGUAGES} from '../../../utils/countriesLanguages'
import Select from "../../controls3.0/select/Select";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";

import human from '../../../images/svg/language-rocketDAO.svg'
import {FormLang} from "../../v3/formLang";

class Step7 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
            user.country ? expert.countryOfResidence = [{Name: user.country}] : expert.countryOfResidence = []
            expert.lang = user.lang || [];
        this.state = {
            user: user,
            expert: expert,
            errors: {}
        }
    }

    onChangeCountry = data => {
        if (data) {
            this.setState({expert: {...this.state.expert, countryOfResidence: data}})
            // if (e.target.value) this.setState({errors: {...this.state.errors, countryOfResidence: null}})
        }
    }

    validate = data => {
        const errors = {}
        for (let key in data) {
            if (!data[key]) errors[key] = 'Field is required';
        }
        return errors
    }
    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        let data = {
                countryOfResidence: expert.countryOfResidence.length? expert.countryOfResidence[0].Name : '',
                lang: expert.lang
            }

        let errors = onlyStep ? {} : this.validate(data)
        this.setState({errors})

        if (!Object.keys(errors).length) {
            let loading = nextStep == 5 ? 'loadingBack' : 'loading';
            this.setState({[loading]: true})
            this.props.updateExpert(data, onlyStep, nextStep)
                .catch(err => {
                    this.setState({[loading]: false})
                })
        }
    }

    onChangeLang = data => {
        this.setState({expert:{...this.state.expert, lang: data}});
    }

    render() {
        let {user, expert, errors} = this.state;

        return (
            <React.Fragment>

                <div className="container-full-reg" style={{position:'relative',padding:'0 0 250px 0'}}>
                    <img src={human} alt="" className="img-right-step"/>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20">
                        Let the community get to know you
                    </p>
                    <p className="description-page-reg-expert text-button left-text shell-margin-bottom-40">
                        Filling in information about yourself will will ease communication with <br/>
                        other experts! Trust, honesty, and transparency are the basis of the <br/>
                        community.
                    </p>
                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20">
                        Residency
                    </p>
                    <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20">
                        Please select the country where you <br/>
                        actually live and work.
                    </p>

                    <div className="shell-select-reg shell-margin-bottom-40">
                        <Select
                            placeholder={'Select'}
                            data={COUNTRIES}
                            value={expert.countryOfResidence}
                            onChange={(e) => this.onChangeCountry(e)}
                            error={errors.countryOfResidence}
                        />
                        {/*<select onChange={this.onChangeCountry} defaultValue={expert.countryOfResidence}>*/}
                            {/*<option value="">-- Select --</option>*/}
                            {/*{COUNTRIES.map((item, key) =>*/}
                                {/*<option key={key} value={item.Name}>{item.Name}</option>*/}
                            {/*)};*/}
                        {/*</select>*/}
                    </div>

                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20">
                        Language
                    </p>
                    <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20">
                        Please choose the language you are fluent in.
                    </p>

                    <FormLang data={expert.lang} onChange={this.onChangeLang}/>

                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(5, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(8)}
                                paddingButton={'56'}
                                disabled={!expert.countryOfResidence.length || !expert.lang.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step7.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step7)
import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import avatar from "../../expert4.0/pic/profilephoto.webp";

export const StartupListCart = ({key, href, item, onClick, listIndustries}) => {
    const _bg = item.backgroundImageId ? `${config.proxy}/file/${item.backgroundImageId}/view?w=${config.image.bg.sm}` : '';
    const _avatar = item.logoFileId ?
        <div className="img-cart-new-startup-marketplace"
             style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('img-cart-new-startup-marketplace', item.title);
    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className="block-cart-new-startup-marketplace slide-up"
                 onClick={onClick}>
                <div className="block-top-cart-new-startup-marketplace">
                    <div className="block-bg-top-cart-new-startup-marketplace">
                        <div className="bg-top-cart-new-startup-marketplace"
                             style={{backgroundImage: `url(${_bg})`}}/>

                        {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
                            <i className="fas fa-play"/>
                        </div>}
                    </div>
                    <div className="block-ing-cart-new-startup-marketplace">
                        {_avatar}
                    </div>
                    {item.round && <span className="title-round">Round: <span className="round">{item.round?item.round:'NaN'}</span></span>}
                </div>
                <div className="block-body-cart-new-startup-marketplace">
                    <p className="title-body-cart-new-startup-marketplace" title={item.title}>
                        {item.title}
                    </p>
                    <p className="description-body-cart-new-startup-marketplace">
                        {item.shortDescription.descriptionFormat()}
                    </p>
                    <div className="block-list-industries-body-cart-new-startup-marketplace">
                        <p className="title-industries-body-cart-new-startup-marketplace">
                            Industries
                        </p>

                        {listIndustries && item.industry.slice(0, 4).map(id => {
                            return <div key={id}
                                        className="block-item-industries-body-cart-new-startup-marketplace">
                                {listIndustries.find((a) => a.id === id) &&
                                <p className="title-item-industries-body-cart-new-startup-marketplace">
                                    {listIndustries.find((a) => a.id === id).title}
                                </p>
                                }
                            </div>
                        })}
                        {item.industry.length > 4 &&
                        <div className="block-item-industries-body-cart-new-startup-marketplace">
                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                {item.industry.length} more…
                            </p>
                        </div>
                        }
                    </div>
                    <div className="block-list-button-body-cart-new-startup-marketplace">
                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                            <i className={`fas fa-heart${item.iLiked ? ' liked': ''}`}/>
                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                {item.likes}
                            </p>
                        </div>
                        <div className="block-item-button-body-cart-new-startup-marketplace m">
                            <i className="far fa-clock"/>
                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
                            </p>
                        </div>
                        <div className="block-item-button-body-cart-new-startup-marketplace">
                            <i className="far fa-share-alt"/>
                            <p className="title-item-button-body-cart-new-startup-marketplace">
                                {item.shared || 0}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </a>
        </div>
    )
};
exports.LegalEntity = [
    {Name: "Publicly Held Corporation"},
    {Name: "Closely Held Corporation"},
    {Name: "Limited Liability Company"},
    {Name: "C Corporation"},
    {Name: "S Corporation"},
    {Name: "Professional Corporation"},
    {Name: "Non Profit Corporation"}
]



exports.getArrYears = () => {
    let currentYear = new Date().getFullYear();
    let founded = []
    for (let i = 1970; i <= currentYear; i++) {
        founded.push({ Name: `${i}` })
    }
    return founded;
}


exports.TYPES = [
    {
        id: 0,
        title: 'Articles',
        description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
    },
    {
        id: 1,
        title: 'Blog',
        description: 'Links to your professional blogs (including video blogs)'},
    {
        id: 2,
        title: 'Diploma',
        description: 'Documents and any documentary proof of your educational background.'},
    {
        id: 3,
        title: 'Projects',
        description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
    },
    {
        id: 4,
        title: 'Mass media',
        description: 'Links to the articles about you or about your professional experience.'},
    {
        id: 5,
        title: 'Recommendations',
        description: 'Reviews of your expert works and professional collaboration.'}
]

exports.ACHIEVEMENTS_INVESTOR = [
    {
        id: 0,
        title: 'Professional blog',
        description: 'Do you share your knowledge with others and publish professional articles on a regular basis in your personal blog?'
    },
    {id: 1, title: 'Mass Media', description: 'Have you ever published your materials in popular and professional media? Have you given an interview to editorials?'},
    {id: 2, title: 'Certificates and diplomas', description: 'Have you taken any specific courses which are directly connected with your investment activities?'},
    {
        id: 3,
        title: 'Awards',
        description: 'Do you have any awards or diplomas as a top investor or entrepreneur, for example?'
    },
    {id: 4, title: 'Mentoring & advising', description: 'Have you contributed to any startups as a mentor? Have you been in the advisors board? In which projects then?'},
    {id: 5, title: 'Other', description: 'Do you have any other important achievements you think do matter to prove your expertise?'},
]

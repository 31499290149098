import React from 'react';
import Button from './button';
import CheckBox from './checkBox'
import './style.scss'

export default class index extends React.Component {

    render() {
        let {
            typeCart,//small, big
            bigTitle,
            title,
            description,
            onCheck,
            check,
            viewCheckbox,
            titleButtonNoCheck,
            titleButtonCheck,

        } = this.props;

        return (
            <div className={`block-cart-anim-info ${typeCart.toLowerCase()} ${check ? 'checked' : 'unchecked'}`}
                 onClick={onCheck}>
                {viewCheckbox &&
                <CheckBox
                    check={check}
                />
                }
                <p className="big-title-cart-anim-info">
                    {bigTitle}
                </p>
                <p className="title-cart-anim-info">
                    {title}
                </p>
                <p className="description-cart-anim-info">
                    {description}
                </p>

                <Button
                    check={check}
                    titleButtonNoCheck={titleButtonNoCheck}
                    titleButtonCheck={titleButtonCheck}
                />
            </div>
        );
    }
}


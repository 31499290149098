import React from 'react';
import './style.scss'

export default class button extends React.Component {

    render() {
        let {
            check,
            titleButtonNoCheck,
            titleButtonCheck,

        } = this.props;

        return (
            <div className={`button-cart-anim-info ${check ? 'checked' : 'unchecked'}`}>
                <p className="title-button-cart-anim-info">
                    {check &&
                    titleButtonCheck
                    }
                    {!check &&
                    titleButtonNoCheck
                    }
                </p>
            </div>
        );
    }
}


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {getLastProject, updateProject} from "../../../actions/Project";
import CheckBox from '../../controls3.0/checkBox/checkBox'
import TextArea from "../../v3/Textarea";


class Step10 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            list: [
                {id: 1, title: 'Working on a Product', description: 'Defining mission, problem, solution, technology stack of the product, active development of an MVP.'},
                {id: 2, title: 'Going to Market', description: 'The product is released on the relevant platforms, A/B tests conducted, marketing and communication channels are tested.'},
                {id: 3, title: 'Growth & Scale', description: 'Proof of the scalability potential; high revenue / customer / user / partner growth; team expands.'},
                {id: 4, title: 'Mature Business', description: 'Proof of the business model efficiency. The company has steady incomes, predictable marketing metrics, a well-established sales system.'},
            ],
            active: null
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.leftTrim().replace(/  +/g, ' ').replace(/\n\n|\r\n\r\n|\r\r+/g, ' ');

        value = value.slice(0, 600);

        this.setState({project: {...this.state.project, [name]: value}})
    }

    onChangeCheck = title => {
        let currentStatus = this.state. project.currentStatus;
        if(currentStatus === title) title = ''
        this.setState({ project: {...this.state.project, ['currentStatus']: title}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project;

        if(onlyStep){
            this.updateS(onlyStep, nextStep);
            return
        }

        let obj = {
            id: project.id,
            description: project.description.trim(),
            currentStatus: project.currentStatus
        }
        obj.event_type = 'startup_product_status_11';



        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        let obj = {
            id: this.state.project.id,
        }
        this.props.updateStartup(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let {project} = this.state;

        let list = this.state.list.map((item, key) =>
            <div key={key} className={`block-item-cart-round-reg-startup bit-10 ${project.currentStatus == item.title?'active':''}`} onClick={() => this.onChangeCheck(item.title)}>
                <div className='block-checkbox-front-cart'>
                    <CheckBox
                        active={project.currentStatus == item.title?true:false}
                    />
                </div>

                <p className="title-front-cart">
                    {item.title}
                </p>

                <p className="description-back-cart">
                    {item.description}
                </p>
                <div className="block-button-cart">
                    <p className="title-button-cart">
                        {project.currentStatus == item.title?'Cancel':'Choose'}
                    </p>
                </div>
            </div>
        )


        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                Product status
                            </p>

                            <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-10" style={{fontSize: '18px'}}>
                                Please tell us more about what you are doing. What are the concept and <br/>
                                mission of the project?
                                What are the key killer features?
                            </p>

                            <div className=" bta-10">
                                <p className={'color-text-reg-gray'}>Product detailed description</p>

                                <TextArea name={'description'} val={this.state.project.description}
                                          onChange={this.onChange} placeholder='600 symbols'
                                          widthClass={'container-full-reg'}
                                          defaultHeight={120}
                                          noPopUp={true}
                                />

                            </div>

                            <div className="block-gradient-gray-reg padding-top-25 margin-top-30">
                                <p className='bta-10-p'>What is the product's current development stage? </p>
                                <div className="block-list-cart-round-reg-startup">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(9, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.update(11)}
                                    paddingButton={'54'}
                                    disabled={!project.description || !project.currentStatus}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>



                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step10.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step10)

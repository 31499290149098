import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Fund from './fund';
import AngelNetwork from './angelNetwork';
import Accelerator from './accelerator';

const Routes = (user) => (
    <Switch>
        <Route path={'/fund/:id/nonpublic/:verify'} render={(props) => ( <Fund user={user} {...props}/>)} />
        <Route path={'/angel_network/:id/nonpublic/:verify'} render={(props) => ( <AngelNetwork user={user} {...props}/>)} />
        <Route path={'/accelerator/:id/nonpublic/:verify'} render={(props) => ( <Accelerator user={user} {...props}/>)} />
    </Switch>
);

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
};

export default Routes
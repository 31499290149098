import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Main from './main'

const Routes = (user) => (
    <Switch>
        <Route path={'/expert/:id'} render={ (props) => ( <Main user={user} {...props}/>)} />
    </Switch>
);

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes

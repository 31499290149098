import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import ChooseAvatar from './../../controls3.0/ChooseAvatar';
import RegAvatar from './../../controls3.0/regAvatar/regAvatar';
import history from './../../../history'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";

import ic_avatar from '../../../images/svg/ic_avatar.svg'
import ic_picture from '../../../images/svg/ic_picture.svg'
import ModalBackgroundImage from '../../modal/ModalBackgroundImage'
import _avatar from '../../../images/ava.jpg'
import _picture from '../../../images/big.jpg'

import ProfilePreview from './../ProfilePreview'
import config from '../../../config';


class Step10 extends React.Component {
    constructor(props) {
        super();
        let user = props.user;
        this.state = {
            user: user,
            background: {},
            errors: {}
        }
    }

    update = (nextStep, onlyStep) => {
        let data = new FormData(),
            user = this.state.user;

        try {
            if(user.backgroundImageId) data.append('backgroundImageId', this.state.user.backgroundImageId);
            if (this.state.background.name) data.append('background', this.state.background)
            data.append("registrationStep", nextStep);
            data.append("role", 'expert');

            if (!onlyStep) {
                let avatarState = this.avatarEditor.state;
                if (avatarState.image) {
                    this.avatarEditor.editor.getImageScaledToCanvas().toBlob(function (blob) {
                        data.append("avatar", blob, "imageFilename.png");
                        this.sendToServer(data, onlyStep, nextStep)
                    }.bind(this), 'image/jpeg', 1);
                } else {
                    this.sendToServer(data, onlyStep, nextStep)
                }
            } else {
                this.sendToServer(data, onlyStep, nextStep)
            }
        } catch (e) {
            this.sendToServer({}, onlyStep, nextStep)
        }
    }


    sendToServer = (data, onlyStep, nextStep) => {
        let loading = nextStep == 9 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})
        this.props.updateExpert(data, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    setBG = (item) => {
        if (item.name) {
            this.setState({background: item})
        } else {
            this.setState({user: {...this.state.user, backgroundImageId: item}});
            this.setState({background: {}})
        }
        this.handleCloseModal()
    };

    validateNext = () => {
        const {user, background, hasLogo} = this.state;
        const ifLogo = (this.logoEditor && this.logoEditor.state.image) || user.logoFileId || hasLogo;
        const ifBg = user.backgroundImageId || background.preview;
        return !!ifLogo && !!ifBg;
    }

    render() {
        let {user, background, preview} = this.state;


        if(!preview) {
            return (
                <React.Fragment>
                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <div className="container-step-2-reg profile-reg-10-step-2"
                             style={{position: 'relative', margin: '0 0 0 auto', padding: '0'}}>

                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                                Profile
                            </p>
                            <p className="description-page-reg-expert text-button left-text margin-0">
                                Make your profile notable.
                            </p>
                            <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-20 margin-top-0">
                                Adding your photo will help investors, startups and other experts find you <br/>
                                faster so that you can discuss any matter or establish business relations.
                            </p>
                        </div>
                        <div className="container-step-1-reg profile-reg-10-step-1"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <div className="block-cart-load-img-reg-expert">
                                <div className="block-left-cart-load-img-reg-expert">
                                    <p className="title-top-page-reg-expert text-h5 left-text shell-margin-bottom-10">
                                        Your photo
                                    </p>
                                    <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-gray shell-margin-bottom-10">
                                        Are there any requirements? Actually no. <br/>
                                        Just a photo of you.
                                    </p>
                                </div>
                                <div className="block-right-cart-load-img-reg-expert test-fix">

                                    <RegAvatar
                                        fileId={user.logoFileId}
                                        title={user.logoFileId ? 'Add new' : 'Add photo'}
                                        onLoadSuccess={() => this.setState({hasLogo: true})}
                                        description='Not less than 180*180 pixels'
                                        ref={(avatarEditor) => {
                                            this.avatarEditor = avatarEditor
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="block-cart-load-img-reg-expert">
                                <div className="block-left-cart-load-img-reg-expert">
                                    <p className="title-top-page-reg-expert text-h5 left-text shell-margin-bottom-10">
                                        Background photo
                                    </p>
                                    <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-gray shell-margin-bottom-10">
                                        When it comes to standing out, the details matter. <br/>
                                        Сhoose an image that represents what you do and <br/>
                                        who you are.
                                    </p>
                                </div>
                                <div className="block-right-cart-load-img-reg-expert">
                                    <div className="shell-load-img-cart">
                                        <div className="block-load-img big">
                                            {!background.preview && !user.backgroundImageId &&
                                            <img className="logo-load-img" src={ic_picture} alt=""/>}

                                            {background.preview &&
                                            <div className={'bgc-img-3'}
                                                 style={{
                                                     background: `url(${background.preview}) no-repeat`,
                                                     backgroundSize: 'cover'
                                                 }}>
                                            </div>
                                            }
                                            {/*{background.preview && <img className="load-img" src={background.preview} alt=""/>}*/}

                                            {!background.preview && user.backgroundImageId && <img className='load-img'
                                                                                                   src={`${config.proxy}/file/${user.backgroundImageId}/view`}
                                                                                                   alt={user.backgroundImageId}></img>}

                                            <div className="block-button-load-img">
                                                <p className="title-button-load-img"
                                                   onClick={() => this.handleShowModal()}>Add photo</p>
                                            </div>
                                        </div>
                                        <p className="title-load-img">
                                            Not less than <br/>
                                            851 pixels width and 315 pixels height
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>


                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(9, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.update(11)}
                                    paddingButton={'56'}
                                    disabled={!this.validateNext()}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>


                    <ModalBackgroundImage
                        showModal={this.state.showModal}
                        handleCloseModal={this.handleCloseModal}
                        setBG={(item) => this.setBG(item)}
                        bgId={user.backgroundImageId}
                        type={2}
                    />
                </React.Fragment>
            )
        }

        else {
            return (
               <ProfilePreview
                   goBack = {() => this.setState({preview: false})}
                   background = {(background && background.preview)? background.preview : null}
                   avatar = {(this.avatarEditor && this.avatarEditor.state && this.avatarEditor.state.image) ? this.avatarEditor.state.image : null}
               />
            )
        }



    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step10.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step10)

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
// import {regexUrl} from "../../../utils/RegExp";
// import Input from "../../controls3.0/input/Input";
import {SocialContacts} from "../../v3/socialContacts/index";

class Step18 extends React.Component {
    constructor(props) {
        super();
        let accelerator = props.accelerator;
            // accelerator = accelerator.accelerator || {
            //     twitterLink: accelerator.twitterLink || '',
            //     facebookLink: accelerator.facebookLink || '',
            //     linkedInLink: accelerator.linkedInLink || '',
            //     contacts: accelerator.contacts
            // };
        if(!accelerator.contacts) accelerator.contacts = []
        this.state = {
            accelerator: accelerator,
            errors: {},
            contact: '',
            validate: false
        }
    }

    handle = (data, errors, validate) => {
        this.setState({accelerator: data, errors, validate})
    }

    componentDidMount () {

    }



    update = (nextStep, onlyStep) => {
        let obj = this.state.accelerator;
        obj.isFinished = true;

        if(onlyStep){
            obj = {}
        }

        obj.event_type = 'accelerator_contacts_17'

        if(!onlyStep) this.setState({loading: true})
        const acceleratorId = this.state.accelerator.id;
        this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {accelerator, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup padding-reg">

                    <div className="block-reg-startup-step-2">
                        <div className="container-full-reg container-step-16" style={{position: 'relative'}}>
                            <div className="container-step-2-reg">
                                <p className="text-h1 color-text-reg-gray margin-0">
                                    Contacts
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 text-middle" style={{fontSize:'20px'}}>
                                    Almost done!
                                </p>
                                <p className="text-button color-text-reg-gray margin-0 font-weight-400" style={{maxWidth: '660px'}}>
                                    How can the community contact you to establish new business relations?<br/>
                                    Share the links to the branded social media profiles of your accelerator here.
                                </p>
                            </div>

                            <div
                                className="block-list-cart-reg-startup">
                                <div className="block-gradient-gray-reg new-mar-30-top new-pad-35-top"></div>
                                <div className="container-step-2-reg">
                                    <SocialContacts object={accelerator} errors={this.state.errors} handle={this.handle}/>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.state.accelerator.proofSent ? this.update(15, true) : this.update(17, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(19)}
                                paddingButton={'54'}
                                disabled={!(this.state.accelerator.twitterLink ||
                                    this.state.accelerator.facebookLink ||
                                    this.state.accelerator.linkedInLink ||
                                    this.state.accelerator.contacts.length) ||
                                    Object.keys(errors).length
                                }
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step18.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step18)
import React from 'react';
import {regexUrl} from "../../../utils/RegExp";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";

export class SocialContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , object: props.object
            , contact: ''
            , validate: false
            , errors: {}
        };

        this.handle  = this.props.handle;
    }


    componentDidMount() {
        //this.checkExistLink()
        this.handle(this.state.object, this.state.errors, this.validate());
    }

    componentWillReceiveProps(nextProps) {
        this.setState({...nextProps}, () => {
            //this.setState({object: nextProps.object}, () => this.checkExistLink())
        });
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.trim().cleanWhitespace();
        let errors = this.state.errors;

        if(!value.length){
            delete errors[name];
        } else if (value && regexUrl.test(value)) {
            delete errors[name]
            this.setState({errors: errors}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        } else {
            this.setState({errors: {...this.state.errors, [name]: 'Wrong format'}}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        }

        this.setState({ [name]: value }, () => {
            this.handle(this.state.object, this.state.errors ,this.validate());
        })
    }

    add = () => {
        let contact = this.state.contact;
        let contacts = this.state.object.contacts;

        if(contacts.includes(contact)) return

        if (regexUrl.test(contact)) {

            let newStateError = this.state.errors;
            delete newStateError.contact;


            this.setState({errors: {...newStateError}}, () => {
                this.handle(this.state.object, this.state.errors, this.validate());
            });

            this.setState({ object: {...this.state.object, contacts: [...this.state.object.contacts, contact] }}, () => {
                this.setState ({contact: ''})
            })

        } else {
            this.setState({errors: {...this.state.errors, contact : 'Wrong link'}}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        }
    }

    checkExistLink = () => {
        let object = this.state.object,
            contacts = this.state.object.contacts;
        if(object.mediumLink && !contacts.includes(object.mediumLink)) contacts.unshift(object.mediumLink)
        if(object.twitterLink && !contacts.includes(object.twitterLink)) contacts.unshift(object.twitterLink)
        if(object.telegramLink && !contacts.includes(object.telegramLink)) contacts.unshift(object.telegramLink)
        if(object.linkedInLink && !contacts.includes(object.linkedInLink)) contacts.unshift(object.linkedInLink)
        if(object.facebookLink && !contacts.includes(object.facebookLink)) contacts.unshift(object.facebookLink)
        // this.setState({ object: {...this.state.object, contacts: contacts}})
    }

    getSocialIcon = link => {
        let iconClass = 'far fa-link'
        if (/facebook/.test(link)) iconClass = 'fab fa-facebook'
        if (/linkedin/.test(link)) iconClass = 'fab fa-linkedin-in'
        if (/telegram/.test(link)) iconClass = 'fab fa-telegram'
        if (/twitter/.test(link)) iconClass = 'fab fa-twitter'
        return iconClass
    }

    onChange2 = (e, key) => {

        let name = e.target.name,
            value = e.target.value.trim().cleanWhitespace(),
            errors = this.state.errors;

        let contacts = this.state.object.contacts;
        contacts[key] = value;

        if(!value.length){
            contacts.splice(key, 1);
        } else if(!regexUrl.test(value)){
            this.setState({errors: {...errors, [name]: 'Wrong format'}}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        } else {
            delete errors[name];
            this.setState({errors: errors}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        }


        this.setState({ object: {...this.state.object, contacts: contacts}}, () => {
            this.handle(this.state.object, this.state.errors ,this.validate());
        })
    }

    onChange3 = e => {
        let name = e.target.name,
            value = e.target.value.trim().cleanWhitespace();
        let errors = this.state.errors;

        if (value && !regexUrl.test(value)) {
            this.setState({errors: {...this.state.errors, [name]: 'Wrong format'}}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        } else {
            delete errors[name]
            this.setState({errors: errors}, () => {
                this.handle(this.state.object, this.state.errors ,this.validate());
            })
        }
        
        this.setState({object: {...this.state.object, [name]: value}}, () => {
            this.handle(this.state.object, this.state.errors ,this.validate());
        })
    }

    validate = () => {
        const {facebookLink,linkedInLink, twitterLink, contacts} = this.state.object;
        const errors = this.state.errors;
        return (facebookLink || linkedInLink || twitterLink || contacts.length) && !Object.keys(errors).length;
    }

    render() {
        const {object, errors} = this.state;
        let contacts = object.contacts.map((item, key) => {
            return (
                <div className='input-wrap blue-icon' key={key} style={{width: '100%'}}>
                    <Input
                        type={'text'}
                        name={`contact${key}`}
                        value={item}
                        onChange={(e) => this.onChange2 (e, key)}
                        placeholder={'Insert link'}
                        iconClass={this.getSocialIcon(item)}
                        error={errors[`contact${key}`]}
                    />
                </div>
            )
        })

        return <div className="block-cont-540" style={{maxWidth: '540px'}}>
           <div className='input-wrap blue-icon color-icon-black-blue block-input-min' style={{width: '100%'}}>
               <Input
                   type={'text'}
                   name={'facebookLink'}
                   value={object.facebookLink}
                   onChange={(e) => this.onChange3(e)}
                   placeholder={'Insert link'}
                   iconClass={'fab fa-facebook-f'}
                   error={errors.facebookLink}
               />
           </div>
           <div className='input-wrap blue-icon color-icon-blue block-input-min' style={{width: '100%'}}>
               <Input
                   type={'text'}
                   name={'linkedInLink'}
                   value={object.linkedInLink}
                   onChange={(e) => this.onChange3(e)}
                   placeholder={'Insert link'}
                   iconClass={'fab fa-linkedin-in'}
                   error={errors.linkedInLink}
               />
           </div>
           <div className='input-wrap blue-icon color-icon-white-blue block-input-min' style={{width: '100%'}}>
               <Input
                   type={'text'}
                   name={'twitterLink'}
                   value={object.twitterLink}
                   onChange={(e) => this.onChange3(e)}
                   placeholder={'Insert link'}
                   iconClass={'fab fa-twitter'}
                   error={errors.twitterLink}
               />
           </div>

           {contacts}

           <div className='input-with-btn-right button-min'>
               <div className='input-wrap block-input-min'>
                   <Input
                       type={'text'}
                       name={'contact'}
                       value={this.state.contact}
                       onChange={this.onChange}
                       error={errors.contact}
                       placeholder={'Insert link'}
                   />
               </div>
               <ButtonGray
                   TitleButton={'Add'}
                   EventOnClick={this.add}
                   paddingButton={'10'}
                   disabled={!this.state.contact || errors.contact}
               />
           </div>
       </div>
    }
}
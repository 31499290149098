export const userLoggedIn = (token) => {
  return {
    type: 'USER_LOGGED_IN',
    token
  }
}

export const getUser = (user) => {
  return {
    type: 'GET_USER_INFO',
    user
  }
}

export const updateUserInfo = (user) => {
  return {
    type: 'UPDATE_USER_INFO',
    user
  }
}

export const updateFundInfo = (fund) => {
  return {
    type: 'UPDATE_FUND_INFO',
    fund: {...fund}
  }
}

export const updateAngelInfo = (angel) => {
  return {
    type: 'UPDATE_ANGEL_INFO',
    angel: {...angel}
  }
}

export const updateExpertInfo = (expert) => {
  return {
    type: 'UPDATE_EXPERT_INFO',
      expert: {...expert}
  }
}

export const userLoggedOut = () => {
  return {
    type: 'USER_LOGGED_OUT'
  }
}
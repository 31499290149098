import React from 'react';

import Tabs from '../../controls3.0/tabs/Tabs'
import TabsCounter from '../../controls3.0/tabs/TabsCounter'
import Swipe from "../../controls3.0/swipe/Swipe";
import CubeCheckBox from '../../controls3.0/checkBox/cubeCheckBox'

export default class ViewInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _S1:false,
            _S2:false,
            _S3:false,
            _S4:false,
        }
        this._click_1 = this._click_1.bind(this);
        this._click_2 = this._click_2.bind(this);
        this._click_3 = this._click_3.bind(this);
        this._click_4 = this._click_4.bind(this);
    }


    _click_1(){
        this.setState({
            _S1: !this.state._S1,
            _S4: !this.state._S2,
        });
    }
    _click_2(){
        this.setState({
            _S2: !this.state._S2,
            _S1: false,
        });
    }
    _click_3(){
        this.setState({
            _S3: !this.state._S3,
            _S2: !this.state._S4,
        });
    }
    _click_4(){
        this.setState({
            _S4: !this.state._S4,
            _S1: false,
            _S3: false,
        });
    }

    render() {

        return (
            <div>
                <div style={{margin: '10px'}}>
                    <div onClick={this._click_1}>
                        bug 1
                        <Swipe
                            active={this.state._S1}
                        />
                    </div>
                    <br/>
                    <div onClick={this._click_2}>
                        bug 2
                        <Swipe
                            active={this.state._S2}
                        />
                    </div>
                    <br/>
                    <div onClick={this._click_3}>
                        bug 3
                        <Swipe
                            active={this.state._S3}
                        />
                    </div>
                    <br/>
                    <div onClick={this._click_4}>
                        bug 4
                        <Swipe
                            active={this.state._S4}
                        />
                    </div>
                    <br/>
                    <div onClick={this._click_4}>
                        bug 4
                        <CubeCheckBox
                            active={this.state._S4}
                        />
                    </div>

                </div>


            </div>
        );
    }
}

import React from 'react';

import Tabs from '../../controls3.0/tabs/Tabs'
import TabsCounter from '../../controls3.0/tabs/TabsCounter'
import TabsWithRemove from '../../controls3.0/tabs/TabsWithRemove'

export default class ViewInput extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <div style={{margin: '10px'}}>
                    <Tabs
                        TitleTab={'Business'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={false}
                    />
                    <Tabs
                        TitleTab={'Business'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={true}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <Tabs
                        TitleTab={'BusinessBusiness'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={false}
                    />
                    <Tabs
                        TitleTab={'BusinessBusiness'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={true}
                    />
                </div>


                <br/>
                <br/>
                <p>tabs counter</p>
                <div style={{margin: '10px'}}>
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={false}
                        count={null}
                    />
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={true}
                        count={null}
                    />
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={false}
                        count={100}
                    />
                    <TabsCounter
                        TitleTab={'Recommendations'}
                        TypeTab={'big'}
                        EventOnClick={null}
                        active={true}
                        count={1}
                    />
                </div>

                <div style={{margin: '10px'}}>
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={false}
                        count={null}
                    />
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={true}
                        count={null}
                    />
                    <TabsCounter
                        TitleTab={'Recommendations'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={false}
                        count={100}
                    />
                    <TabsCounter
                        TitleTab={'Articles'}
                        TypeTab={'small'}
                        EventOnClick={null}
                        active={true}
                        count={1}
                    />
                </div>

                <div style={{margin: '10px', width: '400px'}}>
                    <TabsWithRemove
                        TitleTab={'test'}
                        TypeTab={'big'}
                        EventOnClick={() => {}}
                    />
                </div>

            </div>
        );
    }
}

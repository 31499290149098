const initialState = {
    message: ''
}

export const message = (state = initialState, action={}) => {
    switch (action.type){
        case 'NEW_MESSAGE':
            return {
                message: action.message
            }
        case 'SEND_MESSAGE':
            return {
                message: action.message
            }

        default: return state
    }
};
import React, {useEffect, useState} from 'react';
import './style.scss'
import Input from "../../controls3.0/input/Input";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Tabs from "../../controls3.0/tabs/Tabs";
import {regexPhone} from "../../../utils/RegExp";
import {getListSkillsByStringInvestor} from "../../../actions/Profile";
import {Row} from "../Grid/Row";


const SkillTags = ({skills, suggestedArrSkills, parentCallback}) => {
    const [searchArrSkills, setSearchArrSkills] = useState([]);

    const [inputSearch, setInputSearch] = useState('');
    const [errorsIn, setErrorsIn] = useState({});


    const onChange = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace(),
            limit = 100,
            _errors = errorsIn;

        if(value && value.length !== 0){
            getByString(value)
        } else {
            setSearchArrSkills([]);
        }

        if (value.length) {
            if (value.length > limit) {
                value = value.slice(0, limit);
                _errors[name] = 'max length ' + limit + ' symbols';
            } else {
                delete _errors[name];
            }
        }

        setErrorsIn(_errors);
        setInputSearch(value);


    };
    const getByString = str => {
        getListSkillsByStringInvestor(str)
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setSearchArrSkills(res.data);
                }
            })
            .catch(err => {
            })
    };
    const addToSkills = (skill) => {
        let _skills = skills;
        let isFound = _skills.find(x => x.title === skill.title);
        if (!isFound) _skills.push(skill);
        parentCallback({
            skills:[..._skills]
        });
    };

    const removeSkill = (skill) => {
        if (skill) {
            let filtered = skills.filter(x => x.title !== skill.title);
            parentCallback({
                skills:[...filtered]
            });
        }
    };

    const addNew = () => {
        let s = inputSearch;
        let obj = {title: s};
        addToSkills(obj);
        setInputSearch('');
        setSearchArrSkills([]);
    };

    let mySkills = skills.map((item, key) => {
        return (
            <div className='col-auto-p6' style={{paddingBottom:'12px'}}>
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => removeSkill(item)}
                />
            </div>
        )
    });
    let searchSkills = searchArrSkills.map((item, key) => {
        if (!skills.find((i) => i.id === item.id)) {
            return (
                <div className='col-auto-p3' style={{paddingBottom:'6px'}}>
                    <Tabs
                        key={key}
                        TitleTab={item.title}
                        TypeTab={'small'}
                        EventOnClick={() => addToSkills(item)}
                        active={false}
                    />
                </div>
            )
        }
    });
    let suggestedSkills = suggestedArrSkills.map((item, key) => {
        return (
            <div className='col-auto-p3' style={{paddingBottom:'6px'}}>
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'small'}
                    EventOnClick={() => addToSkills(item)}
                    active={skills.find((i) => i.id === item.id)}
                />
            </div>
        )
    });



    return (
        <React.Fragment>
            <Row className='skill-tags-container'>
                <div className='col-12'>
                    <div className='row-m6 my-skills-block'>
                        {mySkills}
                    </div>

                    <div className="title row-m0 w-100">
                        Input skill tags here
                    </div>

                    <Row>
                        <div className='col-12 col-sm-8 input-wrapper'>
                            <Input
                                type={'text'}
                                name={'inputSearch'}
                                value={inputSearch}
                                onChange={onChange}
                                placeholder={''}
                                iconClass={null}
                            />
                        </div>
                        <div className='col-12 col-sm skills-button-wrapper'>
                            <ButtonBlue
                                TitleButton={'Add new'}
                                EventOnClick={() => addNew()}
                                paddingButton={'30'}
                                disabled={searchArrSkills.find((item) => item.title === inputSearch) || !inputSearch}
                                loader={false}
                            />
                        </div>
                    </Row>

                    <div className='row-m3 search-skills-wrapper'>
                        {searchSkills}
                    </div>

                    <div className="title row-m0 w-100">
                        Suggested skills tags
                    </div>

                    <div className='row-m3'>
                        {suggestedSkills}
                    </div>

                </div>
            </Row>


        </React.Fragment>
    )
};

export default SkillTags;
import React from 'react';
import {createAvatar} from "../../../user/createAvatar";
import config from '../../../../config';
import moment from 'moment';

import AngelNetworkInvitePopup from  '../popupMembers/AngelNetworkInvitePopup';
import AngelNetworkDeletePopup    from  '../popupMembers/AngelNetworkDeletePopup';
import AngelNetworkRejectPopup    from  '../popupMembers/AngelNetworkRejectPopup';
import AngelNetworkSendPopup   from  '../popupMembers/AngelNetworkSendPopup';
import SetTotalMembersPopup   from  '../popupMembers/SetTotalMembersPopup';
import AngelNetworkNewDeletePopup   from  '../popupMembers/AngelNetworkNewDeletePopup';
import TabsCounter from '../../../controls3.0/tabs/TabsCounter'
import Loader from '../../../test-controls/preloader/preloader';

import {connect} from "react-redux";
import {getMyAngelNetwork} from "../../../../actions/Fund";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import axios from "axios";
import {Row} from "../../../v4/Grid/Row";
import TeamCard from "../../../v4/TeamCard";
import {isExist} from "../../../../utils/helper";


class AngelNetworkMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFundInvitePopup: false,
            showNewDelete: false,
            showDelete: false,
            showSend: false,
            buttonTitle:'Invite new members',
            isMembersOpened: props.fund.team2 ? !props.fund.team2.length: true,
            showSetMembers: false
        }
    }

    close(){
        this.get();
        this.setState({showFundInvitePopup: false});
        this.setState({showNewDelete: false});
        this.setState({showDelete: false});
        this.setState({showSend: false});
        this.setState({showReject: false});
        this.setState({showSetMembers: false});
        this.setState({currentMember: {}})
    }

    componentWillReceiveProps(nextProps){
        if(isExist(() => nextProps.fund.team2)) {
            this.setState({isMembersOpened: nextProps.fund ? !nextProps.fund.team2.length : true})
        }
    }

    get(){
        this.props.getMyAngelNetwork(this.props.fund.id)
    }

    showNewDelete(member){
        this.setState({currentMember: member}, () => {
            this.setState({showNewDelete: true});
        })
    }

    showDelete(member){
        this.setState({currentMember: member}, () => {
            this.setState({showDelete: true});
        })
    }

    showReject(member){
        this.setState({currentMember: member}, () => {
            this.setState({showReject: true});
        })
    }

    showSend(member){
        this.setState({currentMember: member}, () => {
            this.setState({showSend: true});
        })
    }
    showSetTotal(){
        this.setState({showSetMembers: true});
    }

    delete(){
        axios
            .delete(`/v2/funds/${this.props.fund.id}/team/${this.state.currentMember.id}`)
            .then(() => {
                this.get();
                this.close();
            }).catch(e => {
            console.log(e.response);
        })
    }

    reInvite(member){
        axios
            .post(`/v2/funds/${this.props.fund.id}/team/${member.id}/reinvite`)
            .then(() => {
                this.get();
                this.showSend(member);
            }).catch(e => {
            console.log(e.response);
        })
    }

    addToFund({id, userId, fundId}){
        axios
            .put(`/v2/funds/${this.props.fund.id}/team/${id}`, {userId, fundId, request: false})
            .then(() => {
                this.get();
            }).catch(e => {
            console.log(e.response);
        })
    }

    toggleMembers = (state) => {
        this.setState({isMembersOpened:state})

    };


    setTotalTeam = (number) => {
        axios
            .put(`/v2/funds/${this.props.fund.id}`, {currentTeamCount: number})
            .then(() => {
                this.get();
            }).catch(e => {
            console.log(e.response);
        })
    }

    render() {
        const {fund} = this.props;

        return (
            fund && fund.User && <div className='block-startup-team block-fund-members' id="startup-team">

                <div className=''>
                    <Row className='block-fund-members-title justify-content-between'>
                        <div className='col-12 col-md margin-top-12'>
                            <Row className='m6'>
                                <div className='main-title members-title col-auto'>Members: {fund.teamCount}/{fund.currentTeamCount}</div>
                                <div className='edit-total-members-text col-auto' onClick={()=>{this.showSetTotal()}}>Edit total number</div>
                            </Row>
                        </div>
                        <div className='col-auto margin-top-12'>
                            <div className='button-add-members' onClick={() => this.setState({showFundInvitePopup: true})}><div className='button-add-members-title'>{this.state.buttonTitle}</div></div>
                        </div>
                    </Row>
                    <div className='toggle-buttons-line'>
                        <div onClick = {() => this.toggleMembers(true)}>
                            <TabsCounter
                                TitleTab={'Members'}
                                TypeTab={'big'}
                                EventOnClick={null}
                                active={this.state.isMembersOpened}
                                count={fund.teamCount}
                            />
                        </div>
                        <div onClick = {() => this.toggleMembers(false)}>
                            <TabsCounter
                                TitleTab={'Requests'}
                                TypeTab={'big'}
                                EventOnClick={null}
                                active={!this.state.isMembersOpened}
                                count={fund.team2.length}
                            />
                        </div>
                    </div>
                    <div className='descr-text' style={{maxWidth:'488px'}}>Invite your colleagues to join the platform, collaborate and search for new startups even more effectively!</div>

                    {/*{!this.state.isMembersOpened && <div className='block-fund-members-list'>*/}
                    {/*    {fund.team2.map( (member, k) => {*/}
                    {/*        return  member.User && <div className='block-body-team-list block-member-card' key={k}>*/}
                    {/*                <div className='content-box'>*/}
                    {/*                    {member.isFounder && <span className='label-is-founder'>Founder</span>}*/}

                    {/*                    {*/}
                    {/*                        member.User.logoFileId*/}
                    {/*                        && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}
                    {/*                        || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>*/}
                    {/*                    }*/}

                    {/*                    <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>*/}
                    {/*                    <p className='list-position list-descr'>Request has been sent on<br/>*/}
                    {/*                        <span>{moment(member.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</span>*/}
                    {/*                    </p>*/}
                    {/*                    <div className='buttons-line-wrapper'>*/}
                    {/*                        <div className='button-delete-member color-text-button mem-btn' style={{float:'left'}} onClick={() => {this.addToFund(member)}}>Add to Fund</div>*/}
                    {/*                        <div className='button-delete-member color-text-button mem-btn' onClick={() => this.showReject(member)}>Reject</div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*    })}*/}
                    {/*</div>}*/}
                    {/*{this.state.isMembersOpened && <div className='block-fund-members-list'>*/}

                    {/*    <div className='block-body-team-list block-member-card' key={'block-body-team-list-owner'}>*/}
                    {/*        <div className='content-box'>*/}
                    {/*            {fund.User.isFounder && <span className='label-is-founder text-admin-block text-center'>Founder</span>}*/}
                    {/*            <span className='label-is-founder text-admin-block text-center'>Admin</span>*/}

                    {/*            {*/}
                    {/*                fund.User.logoFileId*/}
                    {/*                && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${fund.User.logoFileId}/view)`}}/>*/}
                    {/*                || <div className="list-avatar">{ createAvatar('avatar', fund.User.firstName) }</div>*/}
                    {/*            }*/}

                    {/*            <p className='list-user-name'>{fund.User.firstName} {fund.User.lastName}</p>*/}
                    {/*            /!*<p className='list-position'>{startup.owner.position}</p>*!/*/}

                    {/*            <div className='row'>*/}
                    {/*                <div className='col-9'>*/}

                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    {fund.team.map( (member, k) => {*/}
                    {/*        return  member.User && <div className='block-body-team-list block-member-card' key={k}>*/}
                    {/*                <div className='content-box'>*/}
                    {/*                    {member.isFounder && <span className='label-is-founder'>Founder</span>}*/}

                    {/*                    {*/}
                    {/*                        member.User.logoFileId*/}
                    {/*                        && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}
                    {/*                        || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>*/}
                    {/*                    }*/}

                    {/*                    <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>*/}
                    {/*                    <p className='list-position'>{member.position}</p>*/}

                    {/*                    <div className='row-b' style={{margin: '40px 10px 0 0'}}>*/}
                    {/*                        <div className='col-9'>*/}
                    {/*                            <div className='list-social'>*/}
                    {/*                                {member.User && member.User.facebook &&*/}
                    {/*                                <a href={member.User.facebook} target="_blank"><i className="fab fa-facebook"/></a>*/}
                    {/*                                }*/}
                    {/*                                {member.User && member.User.linkedin &&*/}
                    {/*                                <a href={member.User.linkedin} target="_blank"><i className="fab fa-linkedin"/></a>*/}
                    {/*                                }*/}

                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='col-3 delete'>*/}
                    {/*                            <div className='mem-btn' onClick={() => this.showDelete(member)}>Delete</div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            ||*/}
                    {/*            <div className='block-body-team-list block-member-card' key={k}>*/}
                    {/*                <div className='content-box'>*/}
                    {/*                    {*/}
                    {/*                        <div className="list-avatar">{ createAvatar('avatar', member.email) }</div>*/}
                    {/*                    }*/}

                    {/*                    <p className='list-position list-mail'>{member.email}</p>*/}
                    {/*                    <p className='list-position' style={{minHeight: '25px'}}>{member.position}</p>*/}
                    {/*                    <p className='list-position list-descr'>Invitation was sent on<br/>*/}
                    {/*                        <span>{moment(member.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</span>*/}
                    {/*                    </p>*/}
                    {/*                    <div className='buttons-line-wrapper'>*/}
                    {/*                        <div className='button-delete-member color-text-button mem-btn' style={{float:'left'}} onClick={() => {this.reInvite(member)}}>Resend invitation</div>*/}
                    {/*                        <div className='button-delete-member color-text-button mem-btn' onClick={() => this.showNewDelete(member)}>Delete</div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*    })}*/}
                    {/*</div>}*/}

                    <Row className='margin-top-30'>
                        {this.state.isMembersOpened &&
                            fund.team.map((member, k) => {

                                return <div className='col-12 col-sm-6 col-md-4 my2' key={k}>
                                    { member.User && <TeamCard
                                        user={member}
                                        project={fund}
                                        onDelete={(user) => {
                                            member.User ? this.showDelete(user) : this.showNewDelete(user)
                                        }}
                                        reInvite={(user) => {
                                            this.reInvite(user)
                                        }}
                                        showAdmin={fund.User.id === member.User.id}
                                    /> }
                                </div>

                            })
                        }
                        {!this.state.isMembersOpened &&

                            fund.team2.map( (member, k) => {

                                return <div className='col-12 col-sm-6 col-md-4 my3' key={k}>
                                    <TeamCard
                                        user={member}
                                        project={fund}
                                        onDelete={ (user) => {this.showReject(user)} }
                                        reInvite={ (user) => {this.addToFund(user)} }
                                        type={'fund'}
                                    />
                                </div>
                            })
                        }
                    </Row>
                </div>


                {this.state.showFundInvitePopup && <AngelNetworkInvitePopup fund={fund} close={this.close.bind(this)}/> }
                {this.state.showNewDelete && this.state.currentMember &&    <AngelNetworkNewDeletePopup member={this.state.currentMember} close={this.close.bind(this)} delete={this.delete.bind(this)}/> }
                {this.state.showDelete && this.state.currentMember &&       <AngelNetworkDeletePopup member={this.state.currentMember} close={this.close.bind(this)} delete={this.delete.bind(this)}/> }
                {this.state.showReject && this.state.currentMember &&       <AngelNetworkRejectPopup member={this.state.currentMember} close={this.close.bind(this)} delete={this.delete.bind(this)}/> }
                {this.state.showSend && this.state.currentMember &&         <AngelNetworkSendPopup member={this.state.currentMember} close={this.close.bind(this)}/> }
                {this.state.showSetMembers &&                               <SetTotalMembersPopup applySet={(number)=>{this.setTotalTeam(number)}} close={this.close.bind(this)}/> }
            </div> || <Loader isLocal={true}/>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

AngelNetworkMembers.propTypes = {
    user: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, {getMyAngelNetwork})(AngelNetworkMembers));
export const fund = (state = {  }, action = {}) => {
  switch (action.type) {
    case "UPDATE_FUND_INFO":
      case "GET_FUND_INFO":
      return {
          ...action.fund
      };
    default:
      return state;
  }
};
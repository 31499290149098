import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import CheckBox from '../../controls3.0/checkBox/cubeCheckBox'

import {updateStartup} from "../../../actions/Login";
import {getLastProject, updateProject, getListIndustries} from "../../../actions/Project";
import RegAvatar from "../../controls3.0/regAvatar/regAvatar";
import ic_picture from "../../../images/svg/ic_picture.svg";
import Bitmap from "../../../images/svg/Bitmap.svg";

class Step3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {industry: []},
            listI: []
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject();

        getListIndustries()
            .then((res) => {
                this.setState({listI: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = item => {
        let industry = this.state.project.industry

        let isFound = industry.find(x => x === item.id)
        if (!isFound) {
            industry.push(item.id)
        } else {
            industry = industry.filter(x => x !== item.id)
        }
        this.setState({project: {...this.state.project, industry: industry}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project;

        if (!onlyStep) this.setState({loading: true})

        const obj = {
            id: project.id
            , industry: project.industry
        }

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }
    //
    // updateS = (onlyStep, nextStep) => {
    //     let project = this.state.project;
    //
    //     this.props.updateStartup(project, onlyStep, nextStep)
    //         .catch(err => {
    //             this.setState({loading: false})
    //         })
    // }


    render() {
        let project = this.state.project;

        let list = this.state.listI.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup" onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CheckBox
                        onClick={null}
                        active={this.state.project.industry.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup">{item.title}</div>
            </div>
        )


        return (
            project.id ?
            <React.Fragment>
                <div className="step-1 is-startup">


                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-2-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Industry
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    What business area are you working at? <br/>
                                    What industry is the project in?
                                </p>
                                <div className="text-button-3 color-text-reg-gray margin-0">
                                    Choose from the below
                                </div>

                                <div className="block-list-check-box-reg-startup bl-fix-height">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(4)}
                                paddingButton={'54'}
                                disabled={!project.industry.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
                :
                <div>load</div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step3.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject})(Step3)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import Human_KYC from "../../../images/svg/Human_KYC.svg";

import ava from '../../../images/ava.jpg'
import moment from "moment/moment";
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone'
import {COUNTRIES, LANGUAGES} from '../../../utils/countriesLanguages'
import Input from '../../controls3.0/input/Input'
import Select from '../../controls3.0/select/Select'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {sendKyc} from "../../../actions/Profile";
import {regexEmail, regexNumber, regexPhone} from "../../../utils/RegExp";
import {showMessage} from "../../../utils/showMessage";
import RocketDatePicker from "../../controls3.0/datePicker/DatePicker";

class Step23 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            data: {
                firstName: user.firstName,
                lastName: user.lastName,
                phone:'',
                email: user.email || user.email2,
                birthdayFull:'',
                birthdayYear: '',
                birthdayMonth: '',
                birthdayDay: '',
                passport: '',
                expirationFull:'',
                expirationYear: '',
                expirationMonth: '',
                expirationDay: '',
                issueFull:'',
                issueYear: '',
                issueMonth: '',
                issueDay: '',
                address: '',
                zip: '',
                city: '',
                files: [],
                files2: []
            },
            // data: {
            //     firstName: 'asdasd',
            //     lastName: 'zxczxczxc',
            //     phone:'123213',
            //     email: 'sadsadasd',
            //     birthdayYear: '2018',
            //     birthdayMonth: '12',
            //     birthdayDay: '12',
            //     passport: 'as1233',
            //     expirationYear: '2018',
            //     expirationMonth: '12',
            //     expirationDay: '12',
            //     issueYear: '2018',
            //     issueMonth: '12',
            //     issueDay: '12',
            //     address: 'qwewqe',
            //     zip: 'qwe123',
            //     city: 'sdfsdf',
            //     files: [],
            //     files2: []
            // },
            errors: {}
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors =  this.state.errors,
            limit = 256;

        value = value.cleanWhitespace();

        switch (name) {
            case 'firstName':
            case 'lastName':
                break;
            case 'email':
                if(!regexEmail.test(value)){
                    errors[name] = 'Empty or invalid e-mail address'
                } else {
                    delete errors[name]
                }
                break;
            case 'birthdayYear':
            case 'birthdayMonth':
            case 'birthdayDay':
            case 'expirationYear':
            case 'expirationMonth':
            case 'expirationDay':
            case 'issueYear':
            case 'issueMonth':
            case 'issueDay':
                if(!regexNumber.test(value)){
                    value = value.substring(0, value.length - 1)
                }
                if(name === 'birthdayYear' || name === 'issueYear') {
                    value = value.slice(0, 4);
                    if(Number(value) > new Date().getFullYear()) value = new Date().getFullYear()
                }
                if(name === 'expirationYear') {
                    value = value.slice(0, 4);
                }
                if(name === 'birthdayMonth' || name === 'expirationMonth' || name === 'issueMonth') {
                    value = value.slice(0, 2);
                    if(Number(value) > 12) value = 12
                }
                if(name === 'birthdayDay' || name === 'expirationDay' || name === 'issueDay') {
                    value = value.slice(0, 2);
                    if(Number(value) > 31) value = 31
                }
                value = `${value}`
                break;
            case 'phone':
                if(!regexPhone.test(value)){
                    value = value.substring(0, value.length - 1)
                }
                break;
            case 'passport':
            case 'address':
            case 'city':
            case 'zip':
                // limit = 20;
                break;
            case 'birthday':
            case 'expiration':
            case 'issue':
                // limit = 256;
                break;
        }

        if(!value.length) {
            errors[name] = 'Field is required'
        } else {
            delete errors[name]
        }

        this.setState({data:{...this.state.data, [name]: value}})
        this.setState({ errors })
    }

    changeErrors = name => {
        let errors = this.state.errors;
        delete errors[name];
        this.setState({errors})
    }

    updateCountry = newValue => {
        this.setState({data:{...this.state.data, countryOfResidence: newValue}})
    }
    updateCountry2 = newValue => {
        this.setState({data:{...this.state.data, countryDocument: newValue}})
    }
    handleChangePicker = date => {
        this.setState({data:{...this.state.data, ['birthdayFull']: date, ['birthdayYear']: date.getFullYear(), ['birthdayMonth']: date.getMonth() + 1, ['birthdayDay']: date.getDate()}});

        /*this.setState({data:{...this.state.data, birthday: date}})*/
        if(!date){
            this.setState({errors:{...this.state.errors, birthday: 'Field is required'}})
        }else {
            this.changeErrors('birthday')
        }
    }
    handleChangePicker2 = date => {
        this.setState({data:{...this.state.data, ['expirationFull']: date, ['expirationYear']: date.getFullYear(), ['expirationMonth']: date.getMonth() + 1, ['expirationDay']: date.getDate()}});

        /*this.setState({data:{...this.state.data, expiration: date}})*/
        if(!date){
            this.setState({errors:{...this.state.errors, expiration: 'Field is required'}})
        }else {
            this.changeErrors('expiration')
        }
    }
    handleChangePicker3 = date => {
        this.setState({data:{...this.state.data, ['issueFull']: date, ['issueYear']: date.getFullYear(), ['issueMonth']: date.getMonth() + 1, ['issueDay']: date.getDate()}});

        /*this.setState({data:{...this.state.data, issue: date}})*/
        if(!date){
            this.setState({errors:{...this.state.errors, issue: 'Field is required'}})
        }else {
            this.changeErrors('isssue')
        }
    }

    addFile (accepted, rejected) {
        if(accepted.length){
            let files = this.state.data.files;
            accepted.forEach((item, key) => {
                if(files.length < 5) files.push(item);
            })

            this.setState({data:{...this.state.data, files: files}})
        }
        if(rejected.length){
            this.setState({ errorFile: 'The uploaded file seems to have incorrect format' })
        }
    }
    addFile2 (accepted, rejected) {
        if(accepted.length){
            // let files = this.state.data.files2;
            // accepted.forEach((item, key) => {
            //     if(files.length < 5) files.push(item);
            // })

            this.setState({data:{...this.state.data, files2: accepted}})
        }
        if(rejected.length){
            this.setState({ errorFile2: 'The uploaded file seems to have incorrect format' })
        }
    }

    deleteFile  = key => {
        let files = this.state.data.files;
        files.splice(key, 1)
        this.setState({data:{...this.state.data, files: files}})
    }
    deleteFile2  = key => {
        let files = this.state.data.files2;
        files.splice(key, 1)
        this.setState({data:{...this.state.data, files2: files}})
    }

    validate = data => {
        const errors = {}
        for (let key in data) {
            let value = data[key];
            switch (key) {
                case 'firstName':
                case 'lastName':
                    if(!value.trim())errors[key] = 'Field is required'
                    break;
                case 'countryOfResidence':

                    break;
                case 'phone':
                case 'passport':
                    if(!value.trim())errors[key] = 'Field is required'
                    break;
                case 'birthday':
                case 'expiration':
                case 'issue':
                    break;
            }
        }

        return errors;
    }

    send = () => {
        console.log(this.state.data)

        const errors = this.validate(this.state.data)
        this.setState({ errors })
        if(!Object.keys(errors).length){
            let stateData = this.state.data;
            let data =  new FormData();
            data.append('firstName', stateData.firstName);
            data.append('lastName', stateData.lastName);
            data.append('phone', stateData.phone);
            data.append('email', stateData.email);
            data.append('dob', `${stateData.birthdayDay}.${stateData.birthdayMonth}.${stateData.birthdayYear}`);
            data.append('countryOfResidence', stateData.countryOfResidence[0].Name);

            data.append('docId', stateData.passport);
            data.append('docExpires', `${stateData.expirationDay}.${stateData.expirationMonth}.${stateData.expirationYear}`);
            data.append('docIssue', `${stateData.issueDay}.${stateData.issueMonth}.${stateData.issueYear}`);
            data.append('countryDocument', stateData.countryDocument[0].Name);
            stateData.files.forEach((item, key) => {
                data.append('files', item)
            })

            data.append('address', stateData.address);
            // data.append('docId', stateData.passport);
            data.append('zip', stateData.zip);
            data.append('city', stateData.city);
            stateData.files2.forEach((item, key) => {
                data.append('files2', item)
            })

            this.setState({loading: true})
            sendKyc(this.props.user.id, data)
                .then((res)=>{
                    this.props.updateExpert({}, true, 24)
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                    this.setState({loading: false})
                })
        }

    }

    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if(!onlyStep) {

        }

        let loading = nextStep === 22? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    render() {
        let errors = this.state.errors,
            data = this.state.data,
            accept="image/jpeg, image/png, image/gif",
            dropStyle = {
                'width': '100%',
                'height': 'auto',
                'margin': '0 auto',
                'border': 'none'
            };

        let disabled = !data.firstName || !data.lastName || !data.phone || !data.email || !data.birthdayYear || !data.birthdayMonth || !data.birthdayDay || !data.countryOfResidence ||
            !data.passport || !data.expirationYear || !data.expirationMonth || !data.expirationDay || !data.issueYear || !data.issueMonth || !data.issueDay || !data.countryDocument || !data.files.length ||
            !data.address ||  !data.city||  !data.zip || !data.files2.length  || Object.keys(errors).length;

        let listFiles = data.files.map((item, key) => {
            return (
                <div key={key} className="block-img-load-kyc" style={{background: `url(${item.preview})`}} onClick={() => this.deleteFile(key)} >
                    <i className="fal fa-times"></i>
                </div>
            )
        })

        let listFiles2 = data.files2.map((item, key) => {
            return (
                <div key={key} className="block-img-load-kyc" style={{background: `url(${item.preview})`}} onClick={() => this.deleteFile2(key)} >
                    <i className="fal fa-times"></i>
                </div>
            )
        })


        return (
            <React.Fragment>


                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>


                    <div className="container-step-1-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            KYC procedure
                        </p>

                        <p className="title-top-page-reg-expert text-button-2 left-text shell-margin-bottom-80">
                            KYC procedure is to be completed for secure tokens distribution on the platform.
                            <span style={{fontWeight: '500'}}> All fields are required.</span>
                        </p>
                    </div>

                    <div className="container-full-reg new-no-padding-down-1199"
                         style={{position: 'relative', margin: '0 0 0 auto', padding:'0 0 0 30px'}}>

                        <div className="block-cart-kyc-reg-expert">

                            <p className="title-cart-kyc-reg-expert">
                                Personal information
                            </p>

                            <div className="block-left-cart-kyc-reg-expert new-width-100-down-1199">

                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-left-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">Name</p>
                                            <Input
                                                type={'text'}
                                                name={'firstName'}
                                                value={data.firstName}
                                                onChange={this.onChange}
                                                placeholder={''}
                                                error={errors.firstName}
                                            />
                                        </div>
                                    </div>
                                    <div className="block-right-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">Surname</p>
                                            <Input
                                                type={'text'}
                                                name={'lastName'}
                                                value={data.lastName}
                                                onChange={this.onChange}
                                                placeholder={''}
                                                error={errors.lastName}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-left-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">Phone number</p>
                                            <Input
                                                type={'text'}
                                                name={'phone'}
                                                value={data.phone}
                                                onChange={this.onChange}
                                                placeholder={'e.g. +9234 425 456 001'}
                                                error={errors.phone}
                                            />
                                        </div>
                                    </div>
                                    <div className="block-right-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">Your email</p>
                                            <Input
                                                type={'text'}
                                                name={'email'}
                                                value={data.email}
                                                onChange={this.onChange}
                                                placeholder={''}
                                                error={errors.email}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="block-right-cart-kyc-reg-expert new-width-100-down-1199">
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-date-input">
                                        <p className="title-input-kyc">Date of Birth</p>
                                        <RocketDatePicker
                                            name="startDate"
                                            value={data.birthdayFull}
                                            onChange={this.handleChangePicker}
                                            maxDate={moment()['_d']}
                                            peekNextMonth={true}
                                            showMonthDropdown={true}
                                            showYearDropdown={true}
                                            yearDropdownItemNumber={8}
                                            placeholderText=""
                                        />
                                    </div>
                                </div>
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="shell-select-kyc">
                                        <p className="title-input-kyc">Your country of residence</p>
                                        <Select
                                            placeholder={'Country'}
                                            data={COUNTRIES}
                                            value={data.countryOfResidence}
                                            onChange={(e) => this.updateCountry(e)}
                                            error={errors.countryOfResidence}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div className="block-cart-kyc-reg-expert">

                            <p className="title-cart-kyc-reg-expert">
                                Passport
                            </p>

                            <div className="block-left-cart-kyc-reg-expert new-width-100-down-1199">

                                <div className="block-line-left-cart-kyc-reg-expert">

                                    <div className="shell-input-kyc">
                                        <p className="title-input-kyc">Passport Number</p>
                                        <Input
                                            type={'text'}
                                            name={'passport'}
                                            value={data.passport}
                                            onChange={this.onChange}
                                            placeholder={'XXXXXXXXXXXXXXXX'}
                                        />
                                    </div>

                                </div>
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-left-left-cart-kyc-reg-expert">
                                        <div className="block-date-input">
                                            <p className="title-input-kyc">Document Expiration date</p>
                                            <RocketDatePicker
                                                name="startDate"
                                                value={data.expirationFull}
                                                onChange={this.handleChangePicker2}
                                                minDate={moment()['_d']}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                yearDropdownItemNumber={8}
                                                placeholderText=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-left-left-cart-kyc-reg-expert">
                                        <div className="block-date-input">
                                            <p className="title-input-kyc">Document Issue date</p>
                                            <RocketDatePicker
                                                name="startDate"
                                                value={data.issueFull}
                                                onChange={this.handleChangePicker3}
                                                maxDate={moment()['_d']}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                yearDropdownItemNumber={8}
                                                placeholderText=""
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="block-right-cart-kyc-reg-expert new-width-100-down-1199">
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="shell-select-kyc">
                                        <p className="title-input-kyc">Your country of residence</p>
                                        <Select
                                            placeholder={'Country'}
                                            data={COUNTRIES}
                                            value={data.countryDocument}
                                            onChange={(e) => this.updateCountry2(e)}
                                            error={errors.countryDocument}
                                        />
                                    </div>
                                </div>


                                <div className="block-load-img-kyc">
                                    <p className="title-input-kyc">Passport upload</p>
                                    <p className="description-input-kyc">
                                        Should be a clear color scan with all <br/>
                                        text and photo cleary visible
                                    </p>

                                    <div className="block-list-img-load-kyc" >
                                        {listFiles}
                                    </div>

                                    <Dropzone
                                        style={dropStyle}
                                        multiple={true}
                                        onDrop={(accepted, rejected) => {this.addFile(accepted, rejected)}}
                                        accept={accept}
                                        maxfiles={5}
                                        maxSize={15000000}
                                    >
                                        <ButtonGray
                                            TitleButton={'Upload'}
                                            EventOnClick={() => console.log('Upload')}
                                            paddingButton={'0'}
                                            disabled={false}
                                        />

                                    </Dropzone>

                                </div>
                            </div>
                        </div>


                        <div className="block-cart-kyc-reg-expert">

                            <p className="title-cart-kyc-reg-expert">
                                Verification address
                            </p>

                            <div className="block-left-cart-kyc-reg-expert new-width-100-down-1199">


                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="shell-text-area-kyc">
                                        <p className="title-input-kyc">Address</p>
                                        <textarea name="address" value={data.address} onChange={this.onChange} placeholder={'Complete home address'}></textarea>
                                    </div>
                                </div>
                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="block-left-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">Postal Code</p>
                                            <Input
                                                type={'text'}
                                                name={'zip'}
                                                value={data.zip}
                                                onChange={this.onChange}
                                                placeholder={''}
                                            />
                                        </div>
                                    </div>
                                    <div className="block-right-left-cart-kyc-reg-expert new-width-100-down-520">
                                        <div className="shell-input-kyc">
                                            <p className="title-input-kyc">City</p>
                                            <Input
                                                type={'text'}
                                                name={'city'}
                                                value={data.city}
                                                onChange={this.onChange}
                                                placeholder={''}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="block-right-cart-kyc-reg-expert new-width-100-down-1199">


                                <div className="block-load-img-kyc">
                                    <p className="title-input-kyc">Address upload (one photo)</p>
                                    <p className="description-input-kyc">
                                        Documents should be free of objects, <br/>
                                        or inscriptions, which do not belong to <br/>
                                        the original
                                    </p>

                                    <div className="block-list-img-load-kyc" >
                                        {listFiles2}
                                    </div>

                                    <Dropzone
                                        style={dropStyle}
                                        multiple={false}
                                        onDrop={(accepted, rejected) => {this.addFile2(accepted, rejected)}}
                                        accept={accept}
                                        maxfiles={1}
                                        maxSize={15000000}
                                    >
                                        <ButtonGray
                                            TitleButton={'Upload'}
                                            EventOnClick={() => console.log('Upload')}
                                            paddingButton={'0'}
                                            disabled={false}
                                        />

                                    </Dropzone>


                                </div>


                            </div>

                        </div>


                    </div>
                </div>



                <div className="block-fix-button-reg-bottom fix-button-one-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip'}
                                EventOnClick={() => this.update(24, true)}
                                paddingButton={'40'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Done, what’s next?'}
                                EventOnClick={() => this.send()}
                                paddingButton={'24'}
                                disabled={disabled}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>



            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step23.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step23)
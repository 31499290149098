import React from 'react';
import './preloader.css'


export default class preloader extends React.Component {
    render() {
        let lStyle = {
            height: '100vh',
            position: 'fixed'
        }

        if(this.props.isLocal) {
            lStyle = {
                minHeight: '300px',
                position: 'absolute'
            }
        }

        if(this.props.style) {
            lStyle = this.props.style
        }

        if (this.props.BGColor) {
            lStyle.background = this.props.BGColor;
        }


        return (
            <div className={`shell-pre-loader ${this.props.isBlack? '' : 'white-load'} ${this.props.loaded? 'display-none' : ''}`} style={lStyle}>
                <div className="block-pre-loader">
                    <div className="shell-pre-loader-circle">
                        <div className="pre-loader-circle-1">
                            <div className="pre-loader-circle-2"></div>
                        </div>
                    </div>
                    <p className="title-loader">
                        {this.props.text}
                        {/*Scaning Ethereum Blockchain…*/}
                    </p>
                </div>

            </div>
        );
    }
}
import React from "react";

import { NavLink } from "react-router-dom";

const LeftBar = ({ user }) => {
  if (!(user.is_investClubSuperAdmin || user.is_investClubAdmin)) {
    return "";
  }


  return (
    <div className="col-300">
      <div className="fund-menu-profile">
        <div
          className={`row-m0 block-fund-sidebar block-user-sidebar no-gutters`}
        >
          <div className="col-12 block-main-menu order-1 order-sm-2">
            <div className="main-menu-title-block margin-15">
              <NavLink exact to="/investclub" className={`main-menu-title`}>
                Partners and Startups
              </NavLink>
            </div>
            <div className="main-menu-title-block margin-15">
              <NavLink
                exact
                to="/investclub/admin"
                className={`main-menu-title`}
              >
                Admins
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;

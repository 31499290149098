import React from 'react';
import '../Header.css';
import '../../test-controls/newMenuTop/new-menu-top.css'
import history from '../../../history';
import qs from 'qs';

const investorTypes = {
    1: 'Individual investor',
    2: 'Angel network',
    3: 'Venture capital fund'
};



export default class ResentSearchBlock extends React.Component {
    constructor(props) {
        super();
        this.state = {

        };
    }

    showParameters(parameters){
        return Object.keys(parameters).map((key) => {
            if(key === 'industry'){
                if(this.props.industryList.length){
                    return parameters[key].map(item => {return this.props.industryList[item-1].title});
                }
            }
            if(key === 'investor_type'){
                return parameters[key].map(i => {return investorTypes[i]});
            }

            if(key === 'evaluation_parameters'){
                if(this.props.evaluationParameters.length){
                    return parameters[key].map(item => {return this.props.evaluationParameters[item-1].title});
                }
            }

            return parameters[key]
        }).join(', ')
    }

     getType(t){
        switch (t){
            case'expert':
                return  'expert';
            case 'all':
                return '';
            case 'investor':
            case 'fund':
            case 'angel_network':
            case 'angel_investor':
                return 'investor';
            case 'all_startups':
            case 'startup':
            case 'startup_pe':
            case 'startup_dd':
                return 'startup';
            case 'accelerator':
                return 'accelerator';
        }
    }


    render() {

        let {
            requestType,
            requestNum,
            parameters,
        } = this.props;



        return (
            <div key={requestNum} className='row-m0 resent-search-block no-gutters' onClick={() => history.push({pathname: `/search/${requestType}`, search: qs.stringify(parameters)}) }>
                <div className='col time-icon-block'/>
                <div className={`col main-${requestType}-block main-block`}>
                    <div className='row-m0 resent-search-text'>Search {this.getType(requestType)} request {requestNum}</div>
                    <div className='row-m0 resent-search-text font-weight-300'>{this.showParameters(parameters)}</div>
                </div>
            </div>
        )

    }
}




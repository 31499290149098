import React from 'react';
import './style.scss'

const MenuIcon = ({children, className, active, type, onClick=()=>console.error()}) => {

    const get = (type) =>{
        switch (type) {
            case 'investor': return 'I';
            case 'expert': return 'E';
            case 'startup': return 'S';
            case 'accelerator': return 'A';
            case 'plus': return '';
            default: return '0';
        }
    };

    return (
        <div className={`menu-icon ${type} ${active ? 'active' : ''} ${className && className}`} onClick={onClick}>
            <div className={`icon`}>{get(type)}</div>
            {children && <div className="title">{children}</div>}
        </div>
    )
};

export default MenuIcon;
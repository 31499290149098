import React from 'react';
import './style.scss'

const StartupButton = ({children, className, onClick}) => {
    return (
        <div className={`startupButton ${className}`} onClick={onClick}>
            {children}
        </div>
    )
};

export default StartupButton;
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import FooterImg from "../../../images/svg/RocketDAO-finish.svg";

class Step23 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }


    render () {
        let user = this.state.user;

        return (
            <React.Fragment>

                <div className="container-full-reg" style={{position: 'relative'}}>

                    {user.kycSent ?
                    <React.Fragment>
                        <div className="container-step-1-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                You are welcome to Rocket DAO!
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-60">
                                We have been waiting for you, {user.firstName}. <br/>
                                Thanks for joining Rocket DAO Expert!
                                <br/><br/>
                                KYC verification usually takes 72 hours. After that, you will receive a confirmation email giving you access to the full platform’s functionality.
                                <br/><br/>
                                We are always there to support and consult you if necessary.
                                <br/><br/>
                                Here your journey begins!<br/>
                                Best regards, Rocket DAO team.
                            </p>

                            <div className="shell-button-reg-expert">
                                <div className="block-button-big-reg-expert" style={{width: '240px'}}
                                     onClick={() => window.location.href = '/'}>
                                    <p className="title">Go to rocketdao.io</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="container-step-1-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                You are welcome to Rocket DAO!
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-60">
                                We have been waiting for you, {user.firstName}. <br/>
                                And here your journey begins!
                                <br/><br/>
                                Access to the full platform’s functionality will be available as soon as you pass KYC. You can do it in right in your profile.
                                <br/><br/>
                                We will always be there to support and consult you if necessary.
                                <br/><br/>
                                Here your journey begins! <br/>
                                Best regards, Rocket DAO team.
                            </p>

                            <div className="shell-button-reg-expert">
                                <div className="block-button-big-reg-expert" style={{width: '240px'}}
                                     onClick={() => window.location.href = '/'}>
                                    <p className="title">Go to rocketdao.io</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    }

                </div>

                <div className={'block-img-footer'} >
                    <img src={FooterImg} style={{position:'relative'}} alt="footer_img"/>
                </div>


                {/*<h2>You are welcome to Rocket DAO! </h2>*/}
                {/*<div>*/}
                    {/*To be honest, we have been waiting for you... <br/>*/}
                    {/*But now you are finally a part of an international expert community! Sounds great, right? <br/>*/}
                    {/*Start your journey right now! <br/>*/}
                {/*</div>*/}
                {/*<button className="btn" onClick={() => window.location.href='/'}>Go to rocketdao.io</button>*/}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step23.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step23)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import TextArea from "../../v3/Textarea";

class Step15 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        this.state = {
            user: user,
            angel: angel,
            errors: {}
        }
    }

    componentDidMount() {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        this.setState({angel: {...this.state.angel, [name]: value}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) this.setState({loading: true});

        obj.event_type = 'network_focus_14';

        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, angel, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Investment focus
                                </p>
                                <p className="text-h5 color-text-reg-gray margin-0 shell-margin-bottom-25"
                                   style={{fontSize: '20px', maxWidth: '620px'}}>
                                    Please describe the type of projects you are currently looking for.
                                </p>
                                <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-40"
                                   style={{fontSize: '20px', maxWidth: '620px'}}>
                                    This will help you to match up with the startup teams that have similar expertise and can potentially be of great value for you.
                                </p>

                                <div className="block-text-area-form-add-edit-modal-reg-expert"
                                     style={{maxWidth: '620px'}}>
                                    {/*<p className="title shell-margin-bottom-5">*/}
                                        {/*Terms of the deal*/}
                                    {/*</p>*/}

                                    <TextArea name={'investmentFocus'} val={angel.investmentFocus}
                                              onChange={this.onChange} placeholder='Ex.: My investment criteria include the following parameters ...'
                                              defaultHeight={240}
                                              widthClass={'container-full-reg'}
                                              widthClass2={'block-text-area-form-add-edit-modal-reg-expert mw620'}
                                              noPopUp={true}
                                              error={errors.description}
                                    />

                                    {/*<textarea name="investmentFocus"*/}
                                              {/*value={angel.investmentFocus}*/}
                                              {/*onChange={this.onChange}*/}
                                              {/*placeholder={'Ex.: My investment criteria include the following parameters ...'}*/}
                                              {/*style={{height: '240px'}}*/}
                                    {/*>*/}

                                    {/*</textarea>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(15)}
                                paddingButton={'54'}
                                disabled={!this.state.angel.investmentFocus}
                                loader={this.state.loading}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(13, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step15.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step15)
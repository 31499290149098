import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {gql} from "apollo-boost/lib/index";
import ApolloClient from "apollo-boost/lib/index";
import moment from "moment/moment";
import config from "../../config";
import {isExist} from "../../utils/helper";
import {updateMenu} from "../../actions/menu";
import {createAvatar} from "../user/createAvatar";
import history from "../../history";
import {useQuery} from "@apollo/react-hooks/lib/index";
import { InMemoryCache } from "apollo-cache-inmemory";
import PropTypes from 'prop-types'
import {listSocialsUserForTeam} from "../v3/listSocialsUserForTeam/index";

import ButtonGreen from '../controls3.0/button/ButtonBlue'
import '../expert4.0/style.css'

import {Swiper, Slide} from 'react-dynamic-swiper';

import {getListIndustries, getListFocuses} from "../../actions/Project";

import Loader from '../test-controls/preloader/preloader'

import {NoInformation} from "../v4/NoInformation";
import ExternalAccess from "../v3/popup/externalAccess";

const GET_FUND = gql`
    query fund($id: Int!, $verify: String!){
        nonPublicAngelNetwork(id: $id, verify: $verify){
            id
            title
            logoFileId
            backgroundFileId
            isFinished
            shortDescription
            location
            fundSize
            industry
            fundType
            currentTeamCount
            website
            facebookLink
            linkedInLink
            twitterLink
            foundingYear
            marketFocus
            businessModelType
            turnover
            previousInvestment
            investmentsCount
            exitsCount
            investmentFocus
            registrationStep
            skills{
                title
            }
            access
            domain
        }
    }
`;

const cache = new InMemoryCache();

const client = new ApolloClient({
    cache,
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const ExternalAngelNetwork = (props) => {
    const [industries, setIndustries] = useState([]);
    const [gFocuses, setGFocuses] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);

    const outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2).replace(/\.00$/,'') + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2).replace(/\.00$/,'') + 'K';
            }
        }

        return `${r(min)}-${r(max)}`
    };

    useEffect(() => {

        getListIndustries()
            .then((res) => {
                setIndustries(res.data);
            });


        getListFocuses()
            .then((res) => {
                setGFocuses(res.data)
            })

        return () => false;
    }, []);

    const fundType = {
        'simple': 'Fund',
        'angels': 'Angel Network'
    };


    const { loading, error, data, refetch} = useQuery(GET_FUND, {
        client: client,
        fetchPolicy: 'cache-and-network',
        variables: { id: parseInt(props.match.params.id), verify: props.match.params.verify || 'v' },
    });

    const hIamOwner = () => {
        setShowPopUp(!showPopUp);
    };

    return <React.Fragment>
        {/*{!this.props.id && <MetaTags>*/}
            {/*<title>{'Fund ' + this.getImgAlt(fund.title) + ' - Rocket DAO - uniting startups, investors and experts'}</title>*/}
            {/*<meta property="og:title" content={fund.title + ' - Rocket DAO - uniting startups, investors and experts'}/>*/}
            {/*<meta name="description" content={fund.shortDescription} />*/}
            {/*<meta property="og:description" content={fund.shortDescription}/>*/}
        {/*</MetaTags>}*/}

        <div className='card-wrapper'>
            <div className="block-background-expert" style={{background: `url(${''})`}}/>
            <div className="block-container-expert">
                {(() => {
                    if(isExist(() => data.nonPublicAngelNetwork)){
                        const fund = data.nonPublicAngelNetwork;

                        fund.team = [{
                            User: {
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }, {
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }, {
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        },{
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        },{
                            User: {
                                id: -1,
                                lastName: 'lastName',
                                firstName: 'firstName',
                                logoFileId: null
                            }
                        }];

                        const _avatar = fund.logoFileId ?
                            <div className="photo-cart-expert" style={{backgroundImage: `url(${`${config.proxy}/file/${fund.logoFileId}/view`})`}}>
                                <img src={`${config.proxy}/file/${fund.logoFileId}/view?w=200`}/>
                            </div>
                            :
                            createAvatar('photo-cart-expert', fund.title);

                        let leftPI = [];
                        let rightPI = [];
                        return <React.Fragment>
                            <div className="block-box-shadow-cart-startup"/>

                            <div className="block-cart-expert-mobile">
                                <div className="block-top-cart-expert-mobile">
                                    <div className="block-avatar-name-top-cart-expert-mobile">
                                        <div className="block-left-top-cart-expert-mobile">
                                            {_avatar}
                                        </div>
                                        <div className="block-right-top-cart-expert-mobile">
                                            <h1 className="title-top-cart-expert-mobile">
                                                {fund.title}
                                                {fund.proofSent &&
                                                <span className="check-box-cart investor">
                                                    <i className="fal fa-check"/>
                                                    </span>
                                                }
                                            </h1>
                                            <span className="round-prof investor">
                                               {fundType[fund.fundType]}
                                            </span>
                                        </div>
                                    </div>
                                    <p className="description-top-cart-expert-mobile">
                                        {fund.shortDescription}
                                    </p>
                                </div>
                                <div className="block-body-cart-expert-mobile">
                                    <div className="block-list-button-cart-expert-mobile">
                                        <ButtonGreen
                                            TitleButton={'I am owner'}
                                            EventOnClick={null}
                                            paddingButton={'26'}
                                        />
                                    </div>
                                    <div className="block-list-state-cart-expert-mobile">
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Location</p>
                                            <p className="description-l">{fund.location}</p>
                                        </div>

                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Fund size</p>
                                            <p className="description-l">{isExist(() => fund.fundSize) ? `$ ${fund.fundSize}` :  <NoInformation/>}</p>
                                        </div>

                                        < div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{fund.businessModelType || <NoInformation/>}</p>
                                        </div>

                                        {fund.investmentSize &&
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Startup investment size range (USD)</p>
                                                <p className="description-l">$ {outputRange(fund.investmentSize.min, fund.investmentSize.max)}</p>
                                            </div> || <NoInformation/>
                                        }
                                        {
                                            !fund.noTurnover && fund.turnover ?
                                                <div className="block-title-description-left-expert">
                                                    <p className="title-l">Startup turnover stage (USD)</p>
                                                    <p className="description-l">$ {outputRange(fund.turnover.min, fund.turnover.max)}</p>
                                                </div> : <NoInformation/>
                                        }
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Founding year</p>
                                            <p className="description-l">{fund.foundingYear}</p>
                                        </div>
                                    </div>
                                    <div className="block-list-info-cart-expert-mobile">
                                        {fund.website && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website:
                                            </p>
                                            <a href={fund.website.toUrl()} target="_blank">{fund.website.toShowUrl()}</a>
                                        </div>
                                        }

                                        {
                                            gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {isExist(() => fund.marketFocus) && fund.marketFocus.map(id => {
                                                            return <span
                                                                className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                        }) || <NoInformation/>}
                                                    </p>
                                                </div> : ''
                                        }

                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Areas of expertise
                                            </p>
                                            <p className="list-skills-expert">
                                                {isExist(() => fund.skills) && fund.skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>

                                        {industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Industries
                                                </p>
                                                <p className="list-skills-expert">
                                                    { isExist(() => fund.industry) && fund.industry.map(id => {
                                                        return <span
                                                            className="skill">{industries.find((a) => a.id === id).title}</span>
                                                    }) || <NoInformation/>}
                                                </p>
                                            </div> : ''
                                        }

                                        <div className="block-title-description-info">
                                            <p className="title-info-investor">
                                                Looking for
                                            </p>
                                            <p className="description-info-investor">
                                                {fund.investmentFocus}
                                            </p>
                                        </div>

                                        <div className="block-title-description-col">
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of investments
                                                </p>
                                                <p className="description-info-investor">
                                                    {fund.investmentsCount}
                                                </p>
                                            </div>
                                            <div className="block-col-title-description">
                                                <p className="title-info-investor">
                                                    Number of Exits
                                                </p>
                                                <p className="description-info-investor">
                                                    {fund.exitsCount}
                                                </p>
                                            </div>
                                        </div>

                                        { isExist(() => fund.previousInvestment) &&
                                            (() => {
                                                leftPI = [];
                                                rightPI = [];
                                                fund.previousInvestment.map((v, i) => {

                                                    let div = <div className="block-previous-investor">
                                                        <div className="block-top-previous-investor"
                                                             onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                            <p className="title-previous-investor">
                                                                {v.title}
                                                            </p>
                                                            <p className="info-previous-investor">
                                                                Exit:
                                                                <span>{v.exite ? 'Yes' : 'No'}</span>
                                                                Investment size:
                                                                <span>${v.size}</span>
                                                            </p>
                                                            <div className="block-open-close-previous-investor">
                                                                <i className="far fa-chevron-down"/>
                                                            </div>
                                                        </div>
                                                        <div className="block-body-previous-investor">
                                                            <p className="description-previous-investor">
                                                                {v.position}
                                                            </p>
                                                        </div>
                                                    </div>;

                                                    if (i % 2 === 0)
                                                        leftPI.push(div);
                                                    else
                                                        rightPI.push(div);
                                                })
                                            })()
                                        }

                                        <div className="block-list-previous-investor">
                                            <p className="title-block-expert">
                                                Previous investments
                                            </p>
                                            {(leftPI.length || rightPI.length) ?
                                                <React.Fragment>
                                                    <div className="block-left-previous-investor">
                                                        {leftPI}
                                                    </div>
                                                    <div className="block-right-previous-investor">
                                                        {rightPI}
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <div className="block-left-previous-investor">
                                                    no information
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="block-cart-expert">
                                <div className="block-left-cart-expert">
                                    <div className="block-photo-cart-expert">
                                        {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                        {_avatar}

                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Location</p>
                                        <p className="description-l">{fund.location}</p>
                                    </div>

                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Fund size</p>
                                        <p className="description-l">{isExist(() => fund.fundSize) ? `$ ${fund.fundSize}` :  <NoInformation/>}</p>
                                    </div>

                                    < div className="block-title-description-left-expert">
                                        <p className="title-l">Business type</p>
                                        <p className="description-l">{fund.businessModelType || <NoInformation/>}</p>
                                    </div>

                                    {fund.investmentSize &&
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Startup investment size range (USD)</p>
                                            <p className="description-l">$ {outputRange(fund.investmentSize.min, fund.investmentSize.max)}</p>
                                        </div> ||  <div className="block-title-description-left-expert">
                                                        <p className="title-l">Startup investment size range (USD)</p>
                                                        <p className="description-l"><NoInformation/></p>
                                                    </div>
                                    }

                                    {
                                        !fund.noTurnover && fund.turnover ?
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Startup turnover stage (USD)</p>
                                                <p className="description-l">$ {outputRange(fund.turnover.min, fund.turnover.max)}</p>
                                            </div> : <div className="block-title-description-left-expert">
                                                <p className="title-l">Startup turnover stage (USD)</p>
                                                <p className="description-l"><NoInformation/></p>
                                            </div>
                                    }
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Founding year</p>
                                        <p className="description-l">{fund.foundingYear}</p>
                                    </div>
                                </div>
                                <div className="block-right-cart-expert">
                                    <div className="block-info-cart-expert">
                                        <p className="name-expert">
                                            <h1>{fund.title}</h1>
                                        </p>
                                        <p className="title-prof">
                                            <div className={'round-prof'}>{fundType[fund.fundType]}</div>
                                        </p>
                                        <p className="description-expert">
                                            {fund.shortDescription || <NoInformation/>}
                                        </p>
                                        <ButtonGreen
                                            TitleButton={'I am owner'}
                                            EventOnClick={() => history.push(`/create/angel_network/1?name=${fund.title}&ac=1`)}
                                            paddingButton={'26'}
                                        />
                                    </div>

                                    {fund.website && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website:
                                            </p>
                                            <a href={fund.website.toUrl()} target="_blank">{fund.website.toShowUrl()}</a>
                                        </div>
                                    }

                                    {
                                        gFocuses.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Geographic focus
                                                </p>
                                                <p className="list-skills-expert">
                                                    {isExist(() => fund.marketFocus) && fund.marketFocus.map(id => {
                                                        return <span
                                                            className="skill">{gFocuses.find((a) => a.id === id).title}</span>
                                                    }) || <NoInformation/>}
                                                </p>
                                            </div> : <NoInformation/>
                                    }

                                    <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Areas of expertise
                                        </p>
                                        <p className="list-skills-expert">
                                            { isExist(() => fund.skills) && fund.skills.map(skill => {
                                                return <span className="skill">{skill.title}</span>
                                            }) || <NoInformation/>}
                                        </p>
                                    </div>

                                    {industries.length ?
                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Industries
                                            </p>
                                            <p className="list-skills-expert">
                                                { isExist(() => fund.industry) && fund.industry.map(id => {
                                                    return <span
                                                        className="skill">{industries.find((a) => a.id === id).title}</span>
                                                }) || <NoInformation/>}
                                            </p>
                                        </div> : <NoInformation/>
                                    }

                                    <div className="block-title-description-info">
                                        <p className="title-info-investor">
                                            Looking for
                                        </p>
                                        <p className="description-info-investor">
                                            {fund.investmentFocus || <NoInformation/>}
                                        </p>
                                    </div>

                                    <div className="block-title-description-col">
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Number of investments
                                            </p>
                                            <p className="description-info-investor">
                                                {fund.investmentsCount || <NoInformation/>}
                                            </p>
                                        </div>
                                        <div className="block-col-title-description">
                                            <p className="title-info-investor">
                                                Number of Exits
                                            </p>
                                            <p className="description-info-investor">
                                                {fund.exitsCount || <NoInformation/>}
                                            </p>
                                        </div>
                                    </div>

                                    { isExist(() => fund.previousInvestment) &&
                                        (() => {
                                            leftPI = [];
                                            rightPI = [];
                                            fund.previousInvestment.map((v, i) => {

                                                let div = <div className="block-previous-investor">
                                                    <div className="block-top-previous-investor"
                                                         onClick={(e) => this.OpenCloseContentClick(e, '.block-body-previous-investor')}>
                                                        <p className="title-previous-investor">
                                                            {v.title}
                                                        </p>
                                                        <p className="info-previous-investor">
                                                            Exit:
                                                            <span>{v.exite ? 'Yes' : 'No'}</span>
                                                            Investment size:
                                                            <span>${v.size}</span>
                                                        </p>
                                                        <div className="block-open-close-previous-investor">
                                                            <i className="far fa-chevron-down"/>
                                                        </div>
                                                    </div>
                                                    <div className="block-body-previous-investor">
                                                        <p className="description-previous-investor">
                                                            {v.position}
                                                        </p>
                                                    </div>
                                                </div>;

                                                if (i % 2 === 0)
                                                    leftPI.push(div);
                                                else
                                                    rightPI.push(div);
                                            })
                                        })()
                                    }

                                    <div className="block-list-previous-investor">
                                        <p className="title-block-expert">
                                            Previous investments
                                        </p>
                                        {(leftPI.length || rightPI.length) ?
                                            <React.Fragment>
                                                <div className="block-left-previous-investor">
                                                    {leftPI}
                                                </div>
                                                <div className="block-right-previous-investor">
                                                    {rightPI}
                                                </div>
                                            </React.Fragment>
                                            :
                                            <div className="block-left-previous-investor">
                                                <NoInformation/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="block-body-expert">

                                <div className='block-body-team'>
                                    {/*<div className='arrow-right'>*/}
                                    {/*    <div className="arrow">*/}
                                    {/*        <i className="far fa-chevron-down"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <p className="title-block-body-team">
                                        Team of <span>{ isExist(() => fund.team.length)}</span>
                                        {/*<div className="show-all">*/}
                                        {/*    Show all*/}
                                        {/*</div>*/}
                                    </p>
                                    {
                                        isExist(() => fund.team.length) && <Swiper
                                            swiperOptions={{
                                                slidesPerView: 4,
                                                // loop: true

                                                freeMode: true,
                                                breakpoints: {
                                                    // when window width is <= 320px
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 10
                                                    },
                                                    // when window width is <= 480px
                                                    480: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20
                                                    },
                                                    // when window width is <= 640px
                                                    640: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 30
                                                    }
                                                }
                                            }}
                                            navigation={false}
                                            pagination={false}
                                        >
                                            {fund.team.map( member => {
                                                return  member.User && <Slide><div className='block-body-team-list' style={{opacity: '0.3', filter: 'grayscale(1)'}}>
                                                    <div className='content-box'>
                                                        {/*<div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}

                                                        {
                                                            member.User.logoFileId
                                                            && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                                            || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>
                                                        }

                                                        <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>
                                                        <p className='list-position'>{member.User.id === fund.userId ? 'Admin' : 'Member'}</p>
                                                        {listSocialsUserForTeam(member.User)}
                                                    </div>
                                                </div></Slide>
                                            })}
                                        </Swiper> ||
                                            <div><NoInformation/>
                                            <NoInformation/>
                                            <NoInformation/>
                                            <NoInformation/>
                                            <NoInformation/>
                                            <NoInformation/>
                                            <NoInformation/>
                                                <NoInformation/></div>
                                    }
                                </div>

                                {/*{!!listAchievements.length &&*/}

                                {/*<div className="block-achievements-investor">*/}
                                {/*<p className="title-achievements-investor">Achievements</p>*/}
                                {/*<div className="block-list-tab-achievements-investor">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {*/}
                                {/*return <TabsCounter*/}
                                {/*TitleTab={v.item.title}*/}
                                {/*TypeTab={'big'}*/}
                                {/*EventOnClick={() => this.setActiveAch(v.item.parameterId)}*/}
                                {/*active={v.item.parameterId === this.state.activeAchivements}*/}
                                {/*count={v.data.length}*/}
                                {/*/>*/}
                                {/*})*/}
                                {/*}*/}

                                {/*</div>*/}

                                {/*<div className="block-list-achievements-investor">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(0,6).map(v => {*/}
                                {/*return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>*/}
                                {/*<p className="title-item-achievements-investor">*/}
                                {/*{v.description}*/}
                                {/*</p>*/}
                                {/*<div*/}
                                {/*className="block-right-achievements-cart-share">*/}
                                {/*<a href={v.link.toUrl()}*/}
                                {/*rel="noopener noreferrer"*/}
                                {/*target="_blank"> <i*/}
                                {/*className="fas fa-share-square"/>*/}
                                {/*</a>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*})*/}
                                {/*}*/}


                                {/*<div className="open-close-block">*/}
                                {/*{*/}
                                {/*this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {*/}
                                {/*return <div className="block-item-achievements-investor" onClick={()=>{window.open(v.link.toUrl())}}>*/}
                                {/*<p className="title-item-achievements-investor">*/}
                                {/*{v.description}*/}
                                {/*</p>*/}
                                {/*<div*/}
                                {/*className="block-right-achievements-cart-share">*/}
                                {/*<a href={v.link.toUrl()}*/}
                                {/*rel="noopener noreferrer"*/}
                                {/*target="_blank"> <i*/}
                                {/*className="fas fa-share-square"/>*/}
                                {/*</a>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*})*/}
                                {/*}*/}
                                {/*</div>*/}


                                {/*{this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&*/}
                                {/*<div style={{float: 'none', clear: 'both'}} className="block-item-achievements-investor" onClick={(e) => {*/}
                                {/*this.setState({showMoreAch: !this.state.showMoreAch});*/}
                                {/*this.OpenCloseContentClick(e, '.open-close-block');*/}

                                {/*}}>*/}

                                {/*<p className="title-item-achievements-investor show">*/}
                                {/*{!this.state.showMoreAch && <span className="show-more">Show more <i className="far fa-chevron-down"/></span>}*/}
                                {/*{this.state.showMoreAch && <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}*/}
                                {/*</p>*/}
                                {/*</div>*/}
                                {/*}*/}

                                {/*</div>*/}


                                {/*</div>*/}
                                {/*}*/}
                            </div>

                            <div className="block-social-link-expert">

                                <p className="title-social-link">
                                    Follow us:
                                    {fund.twitterLink ? <a target="_blank" href={fund.twitterLink.toUrl()}><i className="fab fa-twitter"/></a> :        <a><i className="fa fa-square"/></a>}
                                    {fund.facebookLink ? <a target="_blank" href={fund.facebookLink.toUrl()}><i className="fab fa-facebook"/></a> :     <a><i className="fa fa-square"/></a>}
                                    {fund.linkedInLink ? <a target="_blank" href={fund.linkedInLink.toUrl()}><i className="fab fa-linkedin"/></a> :     <a><i className="fa fa-square"/></a>}
                                    {fund.mediumLink ? <a target="_blank" href={fund.mediumLink.toUrl()}><i className="fab fa-medium"/></a> :           <a><i className="fa fa-square"/></a>}
                                    {fund.telegramLink ? <a target="_blank" href={fund.telegramLink.toUrl()}><i className="fab fa-telegram"/></a> :     <a><i className="fa fa-square"/></a>}

                                    { isExist(() => fund.contacts) && fund.contacts.map((contact) => {
                                        let classn = 'fa fa-link'
                                        if (/twitter/.test(contact)) {
                                            classn = 'fa-twitter'
                                        }
                                        if (/facebook/.test(contact)) {
                                            classn = 'fa-facebook'
                                        }
                                        if (/linked/.test(contact)) {
                                            classn = 'fa-linkedin'
                                        }
                                        if (/medium/.test(contact)) {
                                            classn = 'fa-medium'
                                        }
                                        if (/telegram/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }
                                        if (/t.me/.test(contact)) {
                                            classn = 'fa-telegram'
                                        }

                                        return <a href={contact.toUrl()} target="_blank"><i
                                            className={`fab ${classn}`}/></a>
                                    })}
                                </p>
                            </div>
                            {showPopUp &&  <ExternalAccess
                                next={() => {history.push(`/create/angel_network/1?name=${fund.title}&ac=1`)}}
                                domain={fund.domain}
                                onClose={hIamOwner}/>}
                        </React.Fragment>
                    }
                    else {
                        return  <Loader loaded={false} isLocal={true}/>
                    }
                })()}
            </div>
        </div>
    </React.Fragment>
}

const mapStateToProps = (state) => ({
    ...state
});

ExternalAngelNetwork.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps, {updateMenu})(ExternalAngelNetwork);
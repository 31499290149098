import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import icon1 from '../../../images/svg/Group6.svg'
import icon2 from '../../../images/svg/Group7.svg'
import icon3 from '../../../images/svg/Group.svg'
import {getLastProject, updateProject} from "../../../actions/Project";
import CheckBox from "../../controls3.0/checkBox/checkBox";


class Step6 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {}
        }
    }

    componentDidMount() {
        if (!this.state.project.id) this.props.getLastProject()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    set = round => {
        let currentRound = this.state.project.round;
        if (currentRound === round) round = '';
        this.setState({project: {...this.state.project, round: round}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            round: project.round,
        }

        obj.event_type = 'startup_round_7';

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let {project} = this.state;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Investment round
                            </p>
                            <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Please indicate the stage your project is now at.
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0">
                                But make sure this is the round that your current development and sales stages and <br/>
                                the amount of capital seeking correlate with.
                            </p>


                            <div
                                className="block-list-cart-round-reg-startup block-gradient-gray-reg padding-top-35 margin-top-50">
                                <div
                                    className={project.round == 'Pre-Seed' ? 'block-item-cart-round-reg-startup active' : 'block-item-cart-round-reg-startup'}
                                    onClick={() => this.set('Pre-Seed')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.round == 'Pre-Seed' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        Pre-Seed
                                    </p>
                                    <p className="description-front-cart">
                                        Up to $500 k
                                    </p>
                                    <p className="description-back-cart">
                                        Founders have a clear understanding of the project's mission, market structure
                                        and its needs, of the technologies most suitable for their case.
                                        <br/><br/>
                                        The team has a coherent development plan.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.round == 'Pre-Seed' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={project.round == 'Seed' ? 'block-item-cart-round-reg-startup active' : 'block-item-cart-round-reg-startup'}
                                    onClick={() => this.set('Seed')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.round == 'Seed' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        Seed
                                    </p>

                                    <p className="description-front-cart">
                                        Up to $5 m
                                    </p>
                                    <p className="description-back-cart" style={{top:'-5px'}}>
                                        Business model hypothese have alreay proved themselves.
                                        <br/><br/>
                                        The MVP is further enhanced  and turned into a serial product.
                                        <br/><br/>
                                        Financing is needed for cost recovery.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.round == 'Seed' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={project.round == 'Series A' ? 'block-item-cart-round-reg-startup active' : 'block-item-cart-round-reg-startup'}
                                    onClick={() => this.set('Series A')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.round == 'Series A' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        Series A
                                    </p>
                                    <p className="description-front-cart">
                                        Up to $10 m
                                    </p>
                                    <p className="description-back-cart">
                                        Investments are needed for advertising, attracting the target audience.
                                        <br/><br/>
                                        This is the period when the project starts global scaling of their mass market product.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.round == 'Series A' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(5, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(7)}
                                paddingButton={'56'}
                                disabled={!project.round}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step6.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step6)
import React from "react";
import "./ProgressBarMulti.css";
import $ from "jquery";
import {fund} from "../../../reducers/fund";


export default class ProgressBarMulti extends React.Component {

    render() {
        let {
            CountCollect,
            NameVal,

            CountContrebuted,

            SoftCapPercent,
            SoftCapCountStart,
            SoftCapCountCollect,

            HardCapPercent,
            HardCapCount,
        } = this.props;

        let softCount = CountCollect * 1 >= SoftCapCountStart * 1 ? SoftCapCountCollect : SoftCapCountStart;
        let rightSoft = CountCollect * 1 >= SoftCapCountStart * 1 ?
            {width: 'calc(100% - ' + (100 - (SoftCapCountCollect / (HardCapCount / 100))) + "%)"} :
            {width: 'calc(100% - ' + (100 - (SoftCapCountStart / (HardCapCount / 100))) + "%)"}
            // console.log(rightSoft)
        let softCap = <div className="block-soft-cap-multi-fund" id="soft_cap_multi_fund" style={rightSoft}>
            <p>{softCount} {NameVal} +{SoftCapPercent} %</p>
        </div>
        let hardcap = ""
        if (100 - (SoftCapCountCollect / (HardCapCount / 100)) > 30){
            hardcap = <div className="block-hard-cap-multi-fund" id="hard_cap_multi_fund">
                <p>{HardCapCount} {NameVal} +{HardCapPercent} %</p>
            </div>
        }

        let color = CountCollect * 1 >= SoftCapCountStart * 1 ? "#00F082" : "#A4E3C6";

        let fixWidth = CountCollect / (HardCapCount / 100);
        if(fixWidth > 100) fixWidth = 100

        let style = {width: fixWidth + "%", background: color};

        return (
            <div className="block-progress-bar-multi-fund">
                <div className="block-top-progress-bar-multi-fund">
                    <p className="count-raised-multi-fund">
                        <span className="count">{CountCollect} {NameVal}</span>
                        <span className="title">Raised</span>
                    </p>
                    {/*<p className="count-contrebuted-multi-fund">*/}
                        {/*<span className="count">{CountContrebuted}</span>*/}
                        {/*<span className="title">Funds<br/>contributed</span>*/}
                    {/*</p>*/}
                </div>
                <div className="block-body-progress-bar-multi-fund">
                    <div className="progress-multi-fund" style={style}/>
                    {softCap}
                    {hardcap}
                </div>
            </div>
        );
    }
}



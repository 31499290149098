import React from 'react';
import './Button.css';


export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ColorTitle: this.props.ColorTitle,
            ColorTitleHover: this.props.ColorTitleHover,
            ColorBackground: this.props.ColorBackground,
            ColorBackgroundHover: this.props.ColorBackgroundHover,
            ColorBorder: this.props.ColorBorder,
            ColorBorderHover: this.props.ColorBorderHover,
        };
        this.onHoverButton = this.onHoverButton.bind(this);
        this.offHoverButton = this.offHoverButton.bind(this);
    }

    onHoverButton(e){
        e.currentTarget.style.background = this.state.ColorBackground;
        if (this.state.ColorBorder != null){
            e.currentTarget.style.borderColor = this.state.ColorBorder;
        }else {
            e.currentTarget.style.borderColor = this.state.ColorBackground;
        }
        e.currentTarget.getElementsByTagName('p')[0].style.color = this.state.ColorTitle;

    }
    offHoverButton(e){
        if (this.state.ColorBorderHover != null){
            e.currentTarget.style.borderColor = this.state.ColorBorderHover;
        }else {
            e.currentTarget.style.borderColor = this.state.ColorBackgroundHover;
        }

        e.currentTarget.style.background = this.state.ColorBackgroundHover;
        e.currentTarget.getElementsByTagName('p')[0].style.color = this.state.ColorTitleHover;
    }

    render() {
        let {
            TitleButton,
            ColorTitle,
            ColorTitleHover,
            ColorBackground,
            ColorBackgroundHover,
            PaddingLeftRight,
            IventOnClickButton,
            ColorBorder,
            ColorBorderHover,
        } = this.props;

        if (PaddingLeftRight == ''){
            PaddingLeftRight = '20px';
        }

        let styleButton = {background: ColorBackground, paddingLeft: PaddingLeftRight, paddingRight: PaddingLeftRight, borderColor: ColorBorder!=null? ColorBorder:ColorBackground};
        let styleTitle = {color: ColorTitle}
        return (
            <div className="block-button" onClick={IventOnClickButton} style={styleButton} onMouseOver={this.offHoverButton} onMouseOut={this.onHoverButton}>
                <p style={styleTitle}>{TitleButton}</p>
            </div>
        );
    }
}

import React from 'react';
import './style.css'

export default class Categorie extends React.Component {

    render() {
        let {
            TitleTab,
            TypeTab, // small or big
            EventOnClick,
            active,
            removeTab
        } = this.props;

        let classList = active?"block-button-tab active":"block-button-tab";
        classList += TypeTab? TypeTab == 'big'? ' big':' small':' small';
        classList += removeTab? ' remove' :'';
        return (
            <div className={classList} onClick={EventOnClick} >
                {removeTab && <span className='zh-icon-remove fa fa-times' onClick={() => removeTab()}></span>}
                <p className="title">{TitleTab} </p>
            </div>
        );
    }
}


import React from 'react';
import defaultAvatar from '../../images/default_avatar.png'
import config from "../../config";

const AvatarImg = ({id}) => {
    console.log('defaultAvatar', defaultAvatar)
    return (
            <img src={id ? `${config.proxy}/file/${id}/view` : defaultAvatar} alt={id? id : 'avatar'} style={{width: '100%'}}></img>
    )

};

export default AvatarImg;
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {regexUrl} from "../../../utils/RegExp";
import Input from "../../controls3.0/input/Input";
import {SocialContacts} from "../../v3/socialContacts";

class Step17 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        if (!user.contacts) user.contacts = []
        this.state = {
            user: user,
            investor: investor,
            validate: false,
            errors: {}
        }
    }

    componentDidMount() {

    }

    handle = (data, errors, validate) => {
        this.setState({user: data, errors, validate})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.user;
        obj.isFinished = true;

        if (onlyStep) {
            obj = {}
        }

        obj.event_type = 'angel_contacts_16';

        if (!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, investor, errors} = this.state;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg container-step-16" style={{position: 'relative'}}>
                            <div className="container-step-2-reg">
                                <p className="text-h1 color-text-reg-gray margin-0">
                                    Contacts
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 text-middle">
                                    Almost done!
                                </p>
                                <p className="text-button color-text-reg-gray margin-0" style={{maxWidth: '660px'}}>
                                    How can the community contact you and learn some exciting details about the project?
                                    Share the links to your social media networks here.
                                </p>
                            </div>


                            <div
                                className="block-list-cart-reg-startup block-gradient-gray-reg padding-top-35 margin-top-30">
                                <div className="container-step-2-reg">
                                    <SocialContacts object={user} errors={this.state.errors} handle={this.handle}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(16, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(18)}
                                paddingButton={'54'}
                                disabled={!this.state.validate}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step17.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step17)
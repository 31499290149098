export const menu = (state = {}, action={}) => {
    switch (action.type){
        case 'REFRESH_MENU':{
            return {
                ...action
            }
        }
        default: return state
    }
};

export const expertAchMenu = (state = {}, action={}) => {
    switch (action.type){
        case 'REFRESH_EXPERT_MENU':{
            return {
                ...action
            }
        }

        default: return state
    }
};


export const Market = (state = {}, action={}) => {
    switch (action.type){
        case 'REFRESH_MARKET':{
            return {
                ...action
            }
        }

        default: return state
    }
};
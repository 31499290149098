import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {updateExpert, getAchievement, createAchievement, editAchievement, deleteAchievement} from "../../../../actions/Login";
import {showMessage} from "../../../../utils/showMessage";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import TabsCounter from "../../../controls3.0/tabs/TabsCounter";
import Input from "../../../controls3.0/input/Input";
import {regexUrl} from "../../../../utils/RegExp";
import {getListParameters} from "../../../../actions/Profile";
import {updateExpertAchMenu, updateMenu} from "../../../../actions/menu";

const TYPES = [
    {
        id: 0,
        title: 'Articles',
        description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
    },
    {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'},
    {id: 2, title: 'Diploma', description: 'Documents and any documentary proof of your educational background.'},
    {
        id: 3,
        title: 'Projects',
        description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
    },
    {id: 4, title: 'Mass media', description: 'Links to the articles about you or about your professional experience.'},
    {id: 5, title: 'Recommendations', description: 'Reviews of your expert works and professional collaboration.'},
]


class ExpertAchievements extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        if (!Array.isArray(expert.parameters)) {
            expert.parameters = [];
        }

        this.state = {
            user: user,
            expert: expert,
            types: TYPES,
            listAchievements: [],
            title: '',
            activeParam: {id:props.match.params.id},
            activeType: TYPES[0],
            newA: {
                link: '',
                description: ''
            },
            editA: {},
            errors: {},
            errors2: {},
            isActiveForm: false
        }
    }

    async componentDidMount() {
        let obj = []
        try {
            if(this.props.match.params.id){
                let achievement = await getAchievement('expert', this.props.match.params.id);

                getListParameters()
                    .then((res) => {
                        if (Array.isArray(res.data))
                            this.setState({title: res.data.find((i) => i.id == this.props.match.params.id).title});
                        this.setState({loaded: true})
                    })
                    .catch(err => {
                        showMessage({type: 'error', data: err})
                        this.setState({loaded: true})
                    })

                obj[this.props.match.params.id] = achievement.data;
                this.setState({listAchievements: obj})
            }
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.id !== this.props.match.params.id){
            // this.props.updateMenu({type: 'close'});
            this.props.updateExpertAchMenu({type: 'update'})
            this.setState({activeParam: {id:this.props.match.params.id}},  this.componentDidMount)
        }
    }


    create = () => {
        let {activeParam, activeType, newA, errors} = this.state;
        let data = {
            parameterId: activeParam.id,
            achievementType: activeType.id,
            role: 'expert',
            link: newA.link,
            description: newA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors: {...this.state.errors, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})
        createAchievement(data, activeParam.id)
            .then(res => {
                this.addToList(res.data)
                this.props.updateExpertAchMenu({type: 'update'});
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    edit = () => {
        let {activeParam, activeType, editA} = this.state;
        let data = {
            parameterId: activeParam.id,
            achievementType: activeType.id,
            role: 'expert',
            link: editA.link,
            description: editA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors2: {...this.state.errors2, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})
        editAchievement(data, activeParam.id, editA.id)
            .then(res => {
                this.addToList(res.data, true)
                this.props.updateExpertAchMenu({type: 'update'})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    delete = () => {
        deleteAchievement(this.state.activeParam.id, this.state.editA.id)
            .then(res => {
                this.addToList(this.state.editA, false, true)
                this.props.updateExpertAchMenu({type: 'update'})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        value = value.cleanWhitespace();
        if(!value){
            this.setState({errors: {...this.state.errors, [name] : 'Field is required'}})
        } else {
            let errors = this.state.errors;
            delete errors[name];
            this.setState({errors});
        }
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                let errors = this.state.errors;
                delete errors[name];
                this.setState({errors});
            } else {
                this.setState({errors: {...this.state.errors, [name] : 'Wrong link format'}})
            }
        }
        if(name === 'description'){
            if(value.length > 100){
                value = value.slice(0, 100);
                this.setState({errors: {...this.state.errors, [name] : 'The text is too long, 100 symbols max'}})
            } else {
                let errors = this.state.errors;
                delete errors[name];
                this.setState({errors});
            }
        }

        this.setState({newA: {...this.state.newA, [name]: value}})
    }

    onChange2 = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(!value){
            this.setState({errors2: {...this.state.errors2, [name] : 'Field is required'}})
        } else {
            let errors2 = this.state.errors2;
            delete errors2[name];
            this.setState({errors2});
        }
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                let errors2 = this.state.errors2;
                delete errors2[name];
                this.setState({errors2});
            } else {
                this.setState({errors2: {...this.state.errors2, [name] : 'Wrong link format'}})
            }
        }
        if(name === 'description'){
            if(value.length > 100){
                value = value.slice(0, 100);
                this.setState({errors2: {...this.state.errors2, [name] : 'The text is too long, 100 symbols max'}})
            } else {
                let errors2 = this.state.errors2;
                delete errors2[name];
                this.setState({errors2});
            }
        }

        this.setState({editA: {...this.state.editA, [name]: value}})
    }


    setActiveParam = (param) => {
        this.setState({activeParam: param})
    }
    setActiveType = (type) => {
        this.setState({activeType: type})
    }

    addToList = (item, edit, _delete) => {
        let arr = this.state.listAchievements[item.parameterId]

        if(edit) {
            let index;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) arr[index] = item;
            this.setState({editA: {}})
        } else if(_delete) {
            let index ;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) delete arr[index];
            this.setState({editA: {}})
        } else {
            arr.push(item)
        }

        const newList = [...this.state.listAchievements];
        newList[item.parameterId] = arr;
        this.setState({listAchievements: newList});
        this.handleClearNewA();
    };

    handleClearNewA = () => {
        this.setState({loadingCreate: false})
        this.setState({
            newA: {
                link: '',
                description: ''
            },
            isActiveForm: false
        })
    }

    render() {
        let {title, types, newA, editA, activeParam, activeType, errors, errors2} = this.state;
        let {listAchievements} = this.props;


        console.log(listAchievements);
        return (
            <React.Fragment>
                { listAchievements &&
                <React.Fragment>

                    <div className='block-expert-ach block-page' style={{paddingTop: '35px'}}>
                        {/*<div className='main-title'>{title}</div>*/}

                        {/*<div>*/}
                        {/*    <div className='title'><br/>*/}
                        {/*        Please tell us about a bit more about your professional experience. <br/>*/}
                        {/*        Choose the categories of achievements you can provide the information for.</div>*/}
                        {/*</div>*/}

                        {/*<div className="block-list-tabs-counter-reg-expert" style={{marginTop: '30px'}}>*/}
                        {/*    {types.map((item, key) =>*/}
                        {/*        <TabsCounter*/}
                        {/*            key={key}*/}
                        {/*            TitleTab={item.title}*/}
                        {/*            TypeTab={'big'}*/}
                        {/*            EventOnClick={() => this.setState({activeType: item, isActiveForm: false})}*/}
                        {/*            active={activeType.id === item.id}*/}
                        {/*            count={ (listAchievements[activeParam.id].filter((ach) => item.id === ach.achievementType)).length }*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</div>*/}

                        {/*{( !this.state.isActiveForm && !!(listAchievements[activeParam.id].filter((ach) => activeType.id === ach.achievementType)).length ) &&*/}
                        {/*<div className={'block-add-activity-reg-exp'}>*/}
                        {/*    <ButtonBlue*/}
                        {/*        TitleButton={`Add ${activeType.title}`}*/}
                        {/*        EventOnClick={() => this.setState({isActiveForm: true})}*/}
                        {/*        paddingButton={'30'}*/}
                        {/*        disabled={false}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*}*/}

                        <div className="block-form-add-edit-modal-reg-expert" 
                                          style={{  backgroundColor: '#FAFAFA',
                                                    boxShadow: 'none',
                                                    maxWidth: '688px',
                                                    padding: '0 0 24px 0'}}>
                            <div className="block-input-item-form-add-edit-modal-reg-expert">
                                <p className="title">Insert the link</p>
                                <Input
                                    type={'text'}
                                    name={'link'}
                                    value={newA.link}
                                    onChange={this.onChange}
                                    placeholder={''}
                                    error={errors.link}
                                />
                            </div>
                            <div className="block-text-area-form-add-edit-modal-reg-expert">
                                <p className="title">Leave comments</p>
                                <textarea name="description" cols="30" rows="10" value={newA.description}
                                          onChange={this.onChange}/>
                                <div className="block-new-input error" style={{height:'20px'}}>
                                    <p className="title-error">{errors.description}</p>
                                </div>
                            </div>
                            <div className="block-button-form-add-edit-modal-reg-expert">
                                <ButtonBlue
                                    TitleButton={`Add ${activeType.title}`}
                                    EventOnClick={() => this.create()}
                                    paddingButton={'54'}
                                    disabled={!newA.link || !newA.description || Object.keys(errors).length}
                                    loader={this.state.loadingCreate}
                                />
                            </div>
                        </div>


                        <div className="block-list-active-activity-reg-expert">
                            <div className="block-list-item-active-activity-reg-expert">
                                {listAchievements.map((ach, k) => {
                                    if (activeType.id === ach.achievementType) {
                                        return (
                                            <React.Fragment key={k}>
                                                <div className="block-item-active-activity-reg-expert">
                                                    <div className="block-left-item-active-activity-reg-expert">
                                                        <p className="title">
                                                            {ach.link}
                                                        </p>
                                                        <p className="description">
                                                            {ach.description}
                                                        </p>
                                                    </div>
                                                    <div className="block-right-item-active-activity-reg-expert">
                                                        <p className="button-edit" onClick={() => this.setState({editA: ach})}>
                                                            Edit
                                                        </p>
                                                    </div>
                                                </div>

                                                {editA.id === ach.id &&
                                                <div className="block-form-add-edit-modal-reg-expert"
                                                     style={{marginBottom: '12px', 
                                                     backgroundColor: '#FAFAFA',
                                                     boxShadow: 'none',
                                                     maxWidth: '688px',
                                                     padding: '0 0 24px 0'}}>
                                                    <div
                                                        className="block-input-item-form-add-edit-modal-reg-expert">
                                                        <p className="title">Insert the link</p>
                                                        <Input
                                                            type={'text'}
                                                            name={'link'}
                                                            value={editA.link}
                                                            onChange={this.onChange2}
                                                            placeholder={''}
                                                            error={errors2.link}
                                                        />
                                                    </div>
                                                    <div
                                                        className="block-text-area-form-add-edit-modal-reg-expert">
                                                        <p className="title">Leave comments</p>
                                                        <textarea name="description" cols="30" rows="10"
                                                                  value={editA.description}
                                                                  onChange={this.onChange2}/>
                                                        <div className="block-new-input error" style={{height:'20px'}}>
                                                            <p className="title-error">{errors2.description}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="block-button-form-add-edit-modal-reg-expert">
                                                        <ButtonBlue
                                                            TitleButton={`Edit`}
                                                            EventOnClick={() => this.edit()}
                                                            paddingButton={'54'}
                                                            disabled={!editA.link || !editA.description || Object.keys(errors2).length}
                                                        />

                                                        <p className="button-delete" onClick={() => this.delete()}>
                                                            Delete
                                                        </p>
                                                    </div>
                                                </div>
                                                }

                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </div>
                        </div>

                    </div>
                </React.Fragment> || 'ffd'
                }
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

ExpertAchievements.propTypes = {
    updateExpert: PropTypes.func.isRequired,
    updateExpertAchMenu: PropTypes.func,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {updateExpert, updateExpertAchMenu, updateMenu})(ExpertAchievements)
import React from 'react';
import {Row} from '../Grid/Row';
import Human_ic from "../../startup4.0/preliminaryEvaluation/img/PayBannerBG.svg";
import Human_ic_white from "../../pages/EvaluationLandings/img/HumanWhite.svg";
import history from "../../../history";

import './style.scss'

import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";
import {Tap2Pay, openProduct} from "../../../utils/Tap2Pay";
import {isExist} from "../../../utils/helper";
import {ContactsForm} from "../../../utils/ContactsForm";


export default class PayBanner extends React.Component {

    openProductHandle(){
        if (this.props.openProductHandle) {
            this.props.openProductHandle();
        } else {
            if(isExist(() => this.props.user.id)){
                openProduct(isExist(() => this.props.user.id));
            } else {
                localStorage.removeItem('rFrom');
                localStorage.removeItem('referer_startup');
                localStorage.setItem("referer", window.location.pathname);
                this.setState({showLoginPopup: 'popup'});
            }
        }

    }

    render() {

        let {
            LandingBanner
        } = this.props;

        return (
            <Row className={`row-b pay-banner-wrapper ${LandingBanner?'landing-banner':''}`}>
                <Tap2Pay/>
                <div className='col-12 col-sm-7 left-side order-sm-1 order-2'>
                    <h5 className='pay-banner-title main-title-block'>
                        Order Rocket DAO preliminary evaluation of your startup and get free services from our partners as a bonus.
                    </h5>
                    <ul>
                        <li className='text-list'>Project assessment on 4 evaluation parameters: team, product, business model, market</li>
                        <li className='text-list'>Expert conclusion about each project’s parameter</li>
                        <li className='text-list'>Personal recommendations for improvements</li>
                        <li className='text-list'>External quality startup’s SWOT analysis</li>
                        <li className='text-list'>Rating of compliance with the investment round</li>
                        <li className='text-list'>Direct access to Rocket DAO investors</li>
                        <li className='text-list'>Public presentation of the project on the platform</li>
                        <li className='text-list'>Open expert discussions in your startup’s profile</li>
                        <li className='text-list'>Pdf report with evaluation</li>
                        <li className='text-list'>A pitch deck for investors</li>
                        <li className='text-list'>Report’s mailing to our investing partners</li>
                        <li className='text-list'>Basis for business plan creation</li>
                        <li className='text-list'>Basis for Due Diligence conduction</li>
                    </ul>
                    <div className='list-descr list-descr-block'>
                        In addition to these, you will receive a partners pack: free of charge legal support, software development, full-service design and video production.
                    </div>
                    <Row className="buttons-line">
                        <div className='col-12 col-sm request-button-wrapper'>
                            <ButtonBlue
                                className={''}
                                TitleButton={'Request a consultation'}
                                EventOnClick={() => {ContactsForm()}}
                                paddingButton={'0'}
                                disabled={false}
                            />
                        </div>
                        <div className='col-12 col-sm details-button-wrapper'>
                            <ButtonBorder
                                className={'details-button'}
                                TitleButton={'More details'}
                                EventOnClick={ ()=>{history.push('/evaluation/landing/startup')} }
                                paddingButton={'0'}
                                disabled={false}
                            />
                        </div>
                    </Row>
                </div>
                <div className='col-12 col-sm-5 right-side order-sm-2 order-1'>
                    <h5 className='pay-banner-title main-title-block'>
                        Order Rocket DAO preliminary evaluation of your startup and get free services from our partners as a bonus.
                    </h5>
                    <div className='text-299'>$299 <span>Special offer!</span></div>
                    <Row>
                        <div className='col-12'>
                            <ButtonGreen
                                className={'order-button'}
                                TitleButton={'Order evaluation now'}
                                EventOnClick={() => isExist(() => this.openProductHandle())}
                                paddingButton={'1'}
                                disabled={false}
                            />
                        </div>
                    </Row>
                    <div className='agree-text'>
                        By clicking this button, you agree to our<br/>
                        <span onClick={()=>{window.open('/document/general-terms')}}>Terms</span> and <span onClick={()=>{window.open('/document/privacy-policy')}}>Privacy Policy</span>
                    </div>
                    <img src={LandingBanner?Human_ic_white:Human_ic}/>
                    <div className='see-sample-text' onClick={ ()=>{window.open('/Pre-Evaluation/Peeranha.pdf')} }>
                        See a report sample PDF
                    </div>
                </div>

            </Row>
        )
    }
}
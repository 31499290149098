import React from 'react';
import Input from '../../controls3.0/input/Input'
import ButtonStartup from '../../controls3.0/button/ButtonBlue';
import './style.css'
import {Scrollbars} from 'react-custom-scrollbars';
import axios from "axios";
import {getStartup} from '../../../actions/Project'
import Loader from '../../test-controls/preloader/preloader'
import {getQueryVariable, getYoutubePreview, getYoutubeEmbedURL} from '../../../utils/youtube';
import {goToCart} from '../../../utils/innerLinks';
import {Comments} from '../comments';
import {LinkedinShareButton, TwitterShareButton, FacebookShareButton} from "react-share";
import {youtubeParser} from '../../../utils/RegExp';
import ExpertReviewScore from "../../startup4.0/preliminaryEvaluation/ExpertReviewScore";
import App_Store from "../../../images/icon/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import Google_Store from "../../../images/icon/Download_on_the_google_Store_Badge_US-UK_RGB_blk_092917.svg";
import handleDD from "../../../utils/handleDD";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";
import EditMyPageButton from "../editMyPage";
import packageJSON from "../../../../package.json";
import {createAvatar} from "../../user/createAvatar";
import history from "../../../history";
import PopupLogin from "../popup/login";
import InputRange from "react-input-range";
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import {isExist} from "../../../utils/helper";
import StartupWaitButton from "../../v4/Button/StartupButton/Wait";
import {Row} from "../../v4/Grid/Row";

export class VideoPopUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props
            , id: props.id
            , startup: {}
            , iWish: false
            , loading: true
            , currentVideo: ''
            , currentPreview: ''
            , play: false
            , iLiked: false
            , currentIndex: 0
            , totalCount: 0
            , allProjects: []
            , openClosePopupInvest: false
            , showLogin: false
            , popupLoginProps: {}
            , valueInputRange: 300
        };
        this.onChangeRange = this.onChangeRange.bind(this);
        this.OpenClosePopupInvest = this.OpenClosePopupInvest.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount(){
        this.curStartup();
        this.allProject();
    }

    curStartup = () => {
        this.setState({loading: true});
        getStartup(this.state.id)
            .then(r => {
                const startup = r.data;
                let youtubePreview = '';
                let youtubeVideo = '';

                if(startup.pitch){
                    const youtubeId = youtubeParser(startup.pitch.youtubeLink);
                    youtubePreview = getYoutubePreview(youtubeId);
                    youtubeVideo = getYoutubeEmbedURL(youtubeId);
                }

                this.setState({
                    startup: startup
                    , currentPreview: youtubePreview
                    , currentVideo: youtubeVideo
                    , loading: false
                    , iLiked: startup.iLiked
                    , iWish: startup.iWish
                    , valueInputRange: isExist(() => Number(startup.iWish.invest)) || 300
                })
            })
    }

    allProject = () => {
        axios
            .get('/v2/projects?pitch=true&needCount=true&youtube=true')
            .then(r => {
                const {data} = r.data;
                const id = this.state.id;
                this.setState({allProjects: data, totalCount: data.length});
                const currentIndex = data.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    }

    startupLike = () => {
        const {startup} = this.state;
        axios
            .post(`/v2/projects/${startup.id}/like`)
            .then(r => {
                this.setState({iLiked: !this.state.iLiked});
                this.setState({startup: {...this.state.startup, likes: r.data.likes}})
            })
    }

    play = () => {
        this.setState({play: !this.state.play})
    }

    prev = () => {
        const prevP = this.state.allProjects[this.state.currentIndex-1];
        if(prevP){
            this.setState({id: prevP.id}, () => {
                this.curStartup();
                this.allProject();
            });
        }
    }

    next = () => {
        const nextP = this.state.allProjects[this.state.currentIndex+1];
        if(nextP){
            this.setState({id: nextP.id}, () => {
                this.curStartup();
                this.allProject();
            });
        }
    }

    countShare = () => {
        axios
            .put(`/v2/projects/${this.state.id}/share`)
            .then(r => {

            })
    }

    closePopup() {
        localStorage.removeItem('referer_startup');
        this.setState({showLogin: false, popupLoginProps: {}});
    }

    OpenClosePopupInvest() {
        let user = this.props.user;

        if (user && user.investor && user.investor.isFinished) {

            this.setState({
                openClosePopupInvest: !this.state.openClosePopupInvest,
                showLogin: false,
                popupLoginProps: {}
            })
        } else {
            localStorage.setItem('referer_startup', `${history.location.pathname}${history.location.search}`);

            this.setState({
                popupLoginProps: {
                    title: "Only registered investors can join waitlists of the startups.",
                    text: "Please, login or create your investor profile (or continue registration).",
                    register: "/start_investor",
                    regBtn: "Create investor profile",
                    commandState: {
                        to: '/',
                        startup: this.props.id,
                        prevLocation: this.props.location ? this.props.location.pathname : null
                    }
                }
            }, () => {
                this.setState({showLogin: true});
            });
        }
    }

    onChangeRange = value => {
        this.setState({valueInputRange: value})
    }

    onChangeRange2 = (e) => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");

        if (value > 200000) value = 200000;

        if (value > 0 || !value) {
            this.setState({valueInputRange: Number(value)})
        }

    }

    addToWishlist = () => {
        const {startup} = this.state;
        axios
            .post(`/v2/projects/${startup.id}/waitlist`, {sum: this.state.valueInputRange})
            .then(r => {

                console.log(r.data);

                this.setState({iWish: r.data});
                this.setState({valueInputRange: isExist(() => Number(r.data.invest)) || 300});
                this.OpenClosePopupInvest();

                axios.get(`/v2/projects/${this.state.id}/public`)
                    .then(r => {
                        r.data.valuationCap = Math.round((r.data.capitalSeeking / r.data.entityOffering) * 100);

                        this.setState({startup: r.data});
                    })
            })
    }

    render() {
        const {currentPreview, currentVideo, play, id, loading, user, startup, currentIndex, totalCount} = this.state;

        const sharedUrl = `http://startup.rocketdao.io?id=${startup.id}`;

        const _avatar = startup.logoFileId ?
            <div className="photo-cart-expert" style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${startup.logoFileId}/view?W=200`})`}}>
                <img src={`${packageJSON.proxy}/file/${startup.logoFileId}/view`} alt={startup.title} />
            </div>
            :
            createAvatar('photo-cart-expert', startup.title);

        return (
            <div className='video-pitch-popup-block'>
                <div className='video-pitch-popup-inner-block'>
                    <div className='video-pitch-popup-header-block'>
                        <div className='title'>
                            Video pitches
                        </div>
                        <div className='open-in-new-tab pc' onClick={() => goToCart(id, 'startup', true)}>
                            Open startup in new tab
                            <div className="share-icon">
                                <i className="fas fa-share-square"/>
                            </div>
                        </div>
                        <div className='open-in-new-tab mob' onClick={() => goToCart(id, 'startup', true)}>
                            Open
                            <div className="share-icon">
                                <i className="fas fa-share-square"/>
                            </div>
                        </div>

                        <div className='close-title' onClick={ () => this.props.close('')}>
                            <p>Close</p>
                            <div className='close'/>
                        </div>
                        <div className='page-number'>
                            <div className='prev-arrow-button' onClick={this.prev}>
                                <div className="arrow">
                                    <i className="far fa-chevron-down"/>
                                </div>
                            </div>
                            <div className='title'>{currentIndex+1}/{totalCount}</div>
                            <div className='next-arrow-button' onClick={this.next}>
                                <div className="arrow">
                                    <i className="far fa-chevron-down"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.state.loading &&
                    <Scrollbars style={{height: 'calc(100% - 80px)'}}>
                        <div className='popup-wrap'>


                            <div className="block-cart-expert">
                                <div className="block-left-cart-expert startup">
                                    <div className="block-photo-cart-expert">
                                        {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                        {_avatar}


                                    </div>
                                </div>
                                <div className="block-right-cart-expert startup">
                                    <div className="block-info-cart-expert">
                                        <div className="block-left-info-cart-expert">
                                            <p className="name-expert" title={startup.title}>
                                                <h1>{startup.title}</h1>
                                                <span
                                                    className="check-ok-expert startup"><i
                                                    className="fal fa-check"/>
                                                </span>
                                            </p>
                                            <p className="title-prof">
                                        <span className="round-prof startup">
                                            Startup
                                        </span>
                                            </p>

                                            <p className="description-expert">
                                                {startup.shortDescription}
                                            </p>
                                        </div>
                                        <div className="block-right-info-cart-expert">


                                            { ((user && user.id !== startup.userId) || !user) && <ButtonBlack
                                                TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                EventOnClick={null}
                                                paddingButton={'26'}
                                                disabled={true}
                                                onMouseEnter={() => this.setState({mouseEnter: true}) }
                                                onMouseLeave={() => this.setState({mouseEnter: false}) }
                                            /> || <EditMyPageButton id={startup.id} type={'startup'}/>}
                                            <div className="shell-margin-top-20"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='video-pitch-popup-player-block' style={{backgroundImage: `url(${currentPreview})`}}>

                                {play
                                && <iframe width="100%" height="100%" src={`${currentVideo}?autoplay=1`} frameBorder="0"
                                           allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                           allowFullScreen="1"/>

                                || <div className='button-play' onClick={this.play}>
                                    <div className='play'/>
                                </div>
                                }
                            </div>

                            <Row className={'margin-top-25'}>
                                <div className={'col-12 col-sm-3'} style={{maxWidth: '180px'}}>
                                    <div className="block-button-icon-title-description"
                                         onClick={this.startupLike}>
                                        <div className="block-button-icon">
                                            {this.state.iLiked ?
                                                <i className="fas fa-heart"/> : <i className="far fa-heart"/>}
                                        </div>
                                        <div className="block-button-title">
                                            <p className="title-button-title">
                                                {this.state.iLiked ? 'Liked' : 'Like'}
                                            </p>
                                        </div>
                                        <div className="block-button-description">
                                            <p className="description-button-description">
                                                {startup.likes}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-12 col-sm-3'} style={{maxWidth: '180px'}}>
                                    <div className="block-button-icon-title-description social fix-width-btn-share">
                                        <div className="block-button-icon">
                                            <i className="far fa-share-alt"/>
                                        </div>
                                        <div className="block-button-title">
                                            <p className="title-button-title">
                                                Share
                                            </p>
                                        </div>
                                        <div className="block-button-description">
                                            <p className="description-button-description">
                                                {startup.shared}
                                            </p>
                                        </div>
                                        <div className="block-button-social-icon-title-description">

                                            <LinkedinShareButton
                                                url={sharedUrl}
                                            >
                                                <div className="block-item-button-social" onClick={() => this.countShare()} >
                                                    <i className="fab fa-linkedin-in"/>
                                                </div>
                                            </LinkedinShareButton>
                                            <FacebookShareButton
                                                url={sharedUrl}
                                            >
                                                <div className="block-item-button-social" onClick={() => this.countShare()} >
                                                    <i className="fab fa-facebook"/>
                                                </div>
                                            </FacebookShareButton>
                                            <TwitterShareButton
                                                url={sharedUrl}
                                            >
                                                <div className="block-item-button-social" onClick={() => this.countShare()} >
                                                    <i className="fab fa-twitter"/>
                                                </div>
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-12 col-sm-3'} style={{maxWidth: '180px'}}>
                                    <StartupWaitButton onClick={this.OpenClosePopupInvest} active={!!startup.iWish} allInvest={ isExist(() => `$ ${startup.invest_sum.moneyFormat()}`) || ''} myInvest={ isExist(() => `$ ${this.state.iWish.invest.moneyFormat()}`)}/>
                                </div>
                            </Row>
                        </div>
                        <div className='comments-wrap '>
                            <Comments id={id} user={user} type='startup'/>
                        </div>
                    </Scrollbars>
                    || <Loader loaded={!loading}/>}

                    {this.state.showLogin &&
                    <PopupLogin
                        {...this.state.popupLoginProps}
                        user={this.props.user}
                        onClose={this.closePopup}/>
                    }

                    <div className="shell-popup-expert-marketplace"
                         style={this.state.openClosePopupInvest ? {display: 'block'} : {display: 'none'}}>
                        <div className="block-popup-expert-marketplace">
                            <p className="title-popup-expert-marketplace">
                                How much would you like to invest*?
                            </p>
                            <div className="block-title-input-invest-popup-expert-marketplace">
                                <p className="title-invest-popup-expert-marketplace">
                                    You would like to invest
                                </p>
                                <Input
                                    type={'text'}
                                    name={'valueInputRange'}
                                    value={this.state.valueInputRange}
                                    placeholder={''}
                                    onChange={this.onChangeRange2}
                                    error={''}
                                />
                            </div>

                            <div className="block-input-range-popup-expert-marketplace">
                                <InputRange
                                    minValue={100}
                                    maxValue={200000}
                                    step={100}
                                    value={this.state.valueInputRange}
                                    onChange={value => this.onChangeRange(value)}/>
                                <p className="title-input-range min">$100</p>
                                <p className="title-input-range max">$200 000</p>

                            </div>

                            <p className="description-popup-expert-marketplace">
                                * This is not real invetment. It’s whitelist and the main aim is a showing how
                                many investors would like to invest in this startup
                            </p>
                            <div className="block-button-popup-expert-marketplace">
                                <ButtonGreen
                                    TitleButton={ this.state.iWish ? 'Update the sum' : 'Join the waitlist'}
                                    EventOnClick={() => this.addToWishlist()}
                                    paddingButton={'40'}
                                    disabled={false}
                                    loader={false}
                                />
                            </div>
                            <div className="block-button-close-popup"
                                 onClick={() => this.OpenClosePopupInvest()}>
                                <i className="fal fa-times"/>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );

    }
}
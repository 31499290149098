import React from 'react';

import ButtonSocialReg from '../../controls3.0/buttonSocialReg/ButtonSocialReg'
import iconMail from '../../../images/svg/ic-mail.svg'
export default class ViewSocialButton extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <div style={{margin: '40px 10px'}}>
                    <ButtonSocialReg
                        TitleButton={'Register with Gmail'}
                        EventOnClick={null}
                        disabled={false}
                        color={'#DC4E41'}
                        iconSVG={{
                            d:'M386.061 228.496c1.834 9.692 3.143 19.384 3.143 31.956C389.204 370.205 315.599 448 204.8 448c-106.084 0-192-85.915-192-192s85.916-192 192-192c51.864 0 95.083 18.859 128.611 50.292l-52.126 50.03c-14.145-13.621-39.028-29.599-76.485-29.599-65.484 0-118.92 54.221-118.92 121.277 0 67.056 53.436 121.277 118.92 121.277 75.961 0 104.513-54.745 108.965-82.773H204.8v-66.009h181.261zm185.406 6.437V179.2h-56.001v55.733h-55.733v56.001h55.733v55.733h56.001v-55.733H627.2v-56.001h-55.733z',
                            viewBox:'0 0 640 512'
                        }} // {"d":"", "viewBox": ""}
                        styleSVG={{
                            width:'28px',
                            height:'20px'
                        }} // {width:'',height:'',}
                    />
                </div>
                <div style={{margin: '40px 10px'}}>
                    <ButtonSocialReg
                        TitleButton={'Register with Twitter'}
                        EventOnClick={null}
                        disabled={false}
                        color={'#55ACEE'}
                        iconSVG={{
                            d:'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
                            viewBox:'0 0 512 512'
                        }} // {"d":"", "viewBox": ""}
                        styleSVG={{
                            width:'28px',
                            height:'20px'
                        }} // {width:'',height:'',}
                    />
                </div>
                <div style={{margin: '40px 10px'}}>
                    <ButtonSocialReg
                        TitleButton={'Register with Facebook'}
                        EventOnClick={null}
                        disabled={false}
                        color={'#3B5998'}
                        iconSVG={{
                            d:'M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229',
                            viewBox:'0 0 264 512'
                        }} // {"d":"", "viewBox": ""}
                        styleSVG={{
                            width:'28px',
                            height:'20px'
                        }} // {width:'',height:'',}
                    />
                </div>
                <div style={{margin: '40px 10px'}}>
                    <ButtonSocialReg
                        TitleButton={'Register with E-mail'}
                        EventOnClick={null}
                        disabled={false}
                        color={'#C5B399'}
                        iconSVG={{
                            d:'M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z',
                            viewBox:'0 0 512 512'
                        }} // {"d":"", "viewBox": ""}
                        styleSVG={{
                            width:'28px',
                            height:'20px'
                        }} // {width:'',height:'',}
                    />
                </div>

                <div style={{margin: '40px 10px'}}>

                    <div className="block-button-social-reg-custom">
                        <img src={iconMail} alt=""/>
                        <p className="title-button-social-reg-custom">Register with E-mail</p>
                    </div>

                </div>
            </div>
        );
    }
}

import React from 'react';

import './stylesExpertTaskList.css'

export default class ExpertTaskList extends React.Component {

    render() {
        let {
            paramName,
            positionTitle
        } = this.props;

        

        
        return (
            <div className='block-expert-task-list'>
                <div className='block-expert-task-list-header'>
                    <div className='task-list-title'>Expert tasks</div>
                </div>
                <div className='block-task-list-categories'>
                    <div className='task-list-category'>
                        <div className='title'>New</div>
                        <div className='count'>14</div>
                    </div>
                    <div className='task-list-subtitle'>New objectives are waiting for you</div>

                    <div className='task-list-category'>
                        <div className='title'>In work</div>
                        <div className='count'>3</div>
                    </div>
                    <div className='task-list-subtitle'>Don't forget to meet the deadlines</div>

                    <div className='task-list-category'>
                        <div className='title'>Completed</div>
                        <div className='count'>34</div>
                    </div>
                    <div className='task-list-subtitle'>An archive of the finished tasks</div>
                </div>

            </div>
        );
    }
}
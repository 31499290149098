export default {
    step1: {
        title: 'Basic information',
        active: true
    },
    // step2: {
    //     title: 'Areas of expertise',
    //     active: true
    // },
    // step3: {
    //     title: 'Work experience',
    //     active: true,
    //     noReset: true,
    //     applyText: 'Ok'
    // },
    step4: {
        title: 'Achievements',
        active: true,
        class: 'col-m60',
        next: 'step4_1'
    },
    step4_1: {
        title: 'Achievements',
        active: true,
        class: 'col-m60',
        noShow: true,
        noReset: true,
        applyText: 'Ok',
        prev: 'step4'
    },
    step5: {
        title: 'Industry',
        active: true
    },
    step6: {
        title: 'Geographic market focus',
        active: true
    },
    step7: {
        title: 'Business type',
        active: true,
        class: 'col-m60'
    },
    step8: {
        title: 'Preferred startup\'s turnover stage' ,
        active: true
    },
    step9: {
        title: 'Investment size range',
        active: true
    },
    step10: {
        title: 'Number of investments and exits',
        active: true
    },
    step11: {
        title: 'Previous investments',
        active: true,
        class: 'col-m60',
    },
    step12: {
        title: 'Investment focus',
        active: true
    },
    // step13: {
    //     title: 'My Angel Networks',
    //     active: true
    // },
    // step14: {
    //     title: 'My Venture Funds',
    //     active: true
    // },
    // step15: {
    //     title: 'Social contacts',
    //     active: true
    // }
}
import React from 'react';

import Categorie from '../../controls3.0/categorie/Categorie'

export default class ViewButton extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <div style={{margin: '10px'}}>
                    <Categorie
                        TitleCategorie={'Technologies'}
                        EventOnClick={null}
                        active={false}
                        count={null}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <Categorie
                        TitleCategorie={'Technologies'}
                        EventOnClick={null}
                        active={false}
                        count={'0'}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <Categorie
                        TitleCategorie={'Technologies'}
                        EventOnClick={null}
                        active={true}
                        count={'1'}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <Categorie
                        TitleCategorie={'Technologies'}
                        EventOnClick={null}
                        active={true}
                        count={'999'}
                    />
                </div>



                <br/><br/>

                <p>нету ховеров на актив сделал на своё усмотрение (рамка изменяет цвет)</p>

            </div>


        );
    }
}

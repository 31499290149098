import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker';
import moment from "moment";

import {getLastProject} from "../../../actions/Project";
import RegAvatar from './../../controls3.0/regAvatar/regAvatar';
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import Input from "../../controls3.0/input/Input";

import Dropzone from 'react-dropzone'

import {tokenLogin, updateStartup} from "../../../actions/Login";
import {createProject, updateProject} from "../../../actions/Project";
import {checkStep, goToStep} from "../../../utils/regNavigation";
import {NotificationManager} from "react-notifications";
import ic_picture from "../../../images/svg/ic_picture.svg";
import ModalBackgroundImage from '../../modal/ModalBackgroundImage'
import packageJSON from './../../../../package.json'
import {updateMenu} from "../../../actions/menu";
import config from "../../../config";

class Step17 extends React.Component {
    constructor(props) {
        super(props);
        let user = props.user;
        this.state = {
            user: user,
            project: props.project || {},
            background: {},
            errors: {}
        }
    }

    componentDidMount() {
        if (!this.state.project.id) this.props.getLastProject()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors;


        switch (name) {
            case 'title':

                break;
        }

        this.setState({
            project: {...this.state.project, [name]: value},
            errors: errors
        })

    }

    create = (nextStep, onlyStep) => {
        let data = new FormData(),
            project = this.state.project;

        if (onlyStep) this.update(nextStep, onlyStep)


        if (project.backgroundImageId) data.append('backgroundImageId', this.state.project.backgroundImageId);
        if (this.state.background.name) data.append('background', this.state.background)

        data.append('event_type', 'startup_logo_2');
        let avatarState = this.avatarEditor.state;
        if (avatarState.image) {
            this.avatarEditor.editor.getImageScaledToCanvas().toBlob(function (blob) {
                data.append("avatar", blob, "imageFilename.png");
                this.sendToServer(data, onlyStep, nextStep)
            }.bind(this), 'image/png', 1);
        } else {
            this.sendToServer(data, onlyStep, nextStep)
        }

    }

    sendToServer = (data, onlyStep, nextStep) => {
        if (!onlyStep) this.setState({loading: true})
        this.props.updateProject(this.state.project.id, data)
            .then((res) => {
                this.update(nextStep, onlyStep)
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project;
        if (!onlyStep) this.setState({loading: true})
        this.props.updateStartup({id: project.id}, onlyStep, nextStep).then(r => {
            this.props.updateMenu({type: 'update'})
        })
            .catch(err => {
                this.setState({loading: false})
            })
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    setBG = (item) => {
        if (item.name) {
            this.setState({background: item})
        } else {
            this.setState({project: {...this.state.project, backgroundImageId: item}});
            this.setState({background: {}})
        }
        this.handleCloseModal()
    }


    render() {
        let {user, project, background} = this.state;

        let propsProject = this.props.project || {}

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        console.log(this.avatarEditor)

        return (
            project.id ?
                <React.Fragment>
                    <div className="step-1 is-startup">
                        <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                            <div className="container-step-2-reg profile-reg-10-step-2"
                                 style={{position: 'relative', margin: '0 0 0 auto', padding: '0'}}>

                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Your startup profile
                                </p>
                                <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Images help us tell stories.
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Make your profile notable and memorable for investors by uploading some <br/>
                                    basic important brand elements.
                                </p>
                            </div>
                            <div className="container-step-2-reg"
                                 style={{position: 'relative', margin: '0 0 0 auto'}}>
                                <div className="block-cart-load-img-reg-expert">
                                    <div className="block-left-cart-load-img-reg-expert">
                                        <p className="title-top-page-reg-expert text-h5 left-text color-text-reg-black shell-margin-bottom-10">
                                            Startup logo
                                        </p>
                                        <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-black shell-margin-bottom-10">
                                            Logo is the center of your brand. <br/>
                                            Upload it right here!
                                        </p>
                                    </div>
                                    <div className="block-right-cart-load-img-reg-expert test-fix">
                                        <RegAvatar
                                            fileId={project.logoFileId}
                                            title={project.logoFileId ? 'Add new' : 'Add logo'}
                                            description='Not less than 180*180 pixels'
                                            onLoadSuccess={() => this.setState({hasLogo: true})}
                                            ref={(avatarEditor) => {
                                                this.avatarEditor = avatarEditor
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="block-cart-load-img-reg-expert">
                                    <div className="block-left-cart-load-img-reg-expert">
                                        <p className="title-top-page-reg-expert text-h5 left-text color-text-reg-black shell-margin-bottom-10">
                                            Background photo
                                        </p>
                                        <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-black shell-margin-bottom-10">
                                            When it comes to standing out, the details matter. <br/>
                                            Background picture will help you to create a more <br/>
                                            memorable profile.
                                        </p>
                                    </div>
                                    <div className="block-right-cart-load-img-reg-expert">
                                        <div className="shell-load-img-cart">
                                            <div className="block-load-img big">
                                                {!background.preview && !project.backgroundImageId &&
                                                <img className="logo-load-img" src={ic_picture} alt=""/>}

                                                {background.preview &&
                                                <div className={'bgc-img-3'}
                                                     style={{
                                                         background: `url(${background.preview}) no-repeat`,
                                                         backgroundSize: 'cover'
                                                     }}>
                                                </div>
                                                }
                                                {/*{background.preview && <img className="load-img" src={background.preview} alt=""/>}*/}

                                                {!background.preview && project.backgroundImageId &&
                                                <img className='load-img'
                                                     src={`${config.proxy}/file/${project.backgroundImageId}/view`}
                                                     alt={project.backgroundImageId}></img>}

                                                <div className="block-button-load-img">
                                                    <p className="title-button-load-img"
                                                       onClick={() => this.handleShowModal()}>Add photo</p>
                                                </div>
                                            </div>
                                            <p className="title-load-img">
                                                Not less than <br/>
                                                851 pixels width and 315 pixels height
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>
                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(2, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Done!'}
                                    EventOnClick={() => this.create(3)}
                                    paddingButton={'94'}
                                    disabled={!(project.logoFileId || this.state.hasLogo)}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalBackgroundImage
                        showModal={this.state.showModal}
                        handleCloseModal={this.handleCloseModal}
                        setBG={(item) => this.setBG(item)}
                        bgId={project.backgroundImageId}
                        type={4}
                    />
                </React.Fragment>
                :
                <div></div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step17.propTypes = {
    updateStartup: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject, updateMenu})(Step17)


import React, {useState} from 'react';
import './style.scss';
import load from "../../controls3.0/button/button-loader.svg";
import Profile from "../../../images/icon/Profile.svg";
import Defender from "../../../images/icon/Defender.svg";
export const Button = ({children, onClick = () => {console.error()}, height = 36, type = '', active, loader, profiler, defender}) => {
        return (
            <div className={`flex-row button${height && ` h${height}`} ${type} ${active ? 'active' : ''}`} onClick={onClick}>
                {defender && <div className={'col-auto'}><img className={'defender-img'} src={Defender} alt=""/></div>}
                {profiler && <div className={'col-auto'}><img className={'profile-img'} src={Profile} alt=""/></div>}
                <div className={'col-auto'}>{children}</div>
                {loader && <div className="col-auto"><img className={'loader-button'} src={load} alt=""/></div>}
            </div>
        );
};
import moment from "moment";

const init = () => {
    const currentStatus = {
        t: moment(),
        v: 0
    };
    localStorage.setItem('currentStatus', JSON.stringify(currentStatus));
    return true;
};

export const viewCounter = () => {
    if(localStorage.RocketToken){
        localStorage.removeItem('currentStatus');
        return true;
    }

    const currentStatus = localStorage.currentStatus ? JSON.parse(localStorage.currentStatus) : null;

    if(currentStatus){
        if(moment(currentStatus.t).format("DDD") !== moment().format("DDD")){
            return init();
        } else {
            if(currentStatus.v < 5){
                currentStatus.v++;
                localStorage.setItem('currentStatus', JSON.stringify(currentStatus));
                return true;
            } else {
                return false;
            }
        }
    } else {
        return init();
    }
};

export const getViewStatus = () => {

    if(localStorage.RocketToken){
        localStorage.removeItem('currentStatus');
        return true;
    }

    const currentStatus = localStorage.currentStatus ? JSON.parse(localStorage.currentStatus) : null;

    if(currentStatus){

        if(moment(currentStatus.t).format("DDD") !== moment().format("DDD")){
            return init();
        } else {
            return currentStatus.v < 5;
        }
    } else {
        return init();
    }
};
import React from 'react'
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import './loginRegister.css'
import Loader  from './../test-controls/preloader/preloader'
import {forgot} from '../../actions/Login'
import Input from "../controls3.0/input/Input";



class ModalForgotPassword extends React.Component {

    constructor(props){
        super();
        this.state = {
            loaded: true,
            successReset: false,
            email: '',
            errorEmail: ''
        };
    }



    componentWillReceiveProps () {
        this.setState({errorEmail: null, serverError: null})
    }

    onChange = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    onFocus = e => {
        this.setState({errorEmail: null})
    }


    send = () => {
        let email = this.state.email;
        if(email) {
            this.setState({loaded: false})
            forgot(email)
                .then((res)=>{
                    this.setState({loaded: true, successReset: true})
                    // localStorage.setItem('RocketToken', res.data.token);
                    // this.props.handleCloseModal();
                    // window.location.reload()
                })
                .catch(error => {
                    this.setState({loaded: true})
                    this.setState({ serverError: error.response.data.error })
                    setTimeout(function() {
                        this.setState({serverError: null})
                    }.bind(this), 3000);
                })
        } else {
            this.setState({errorEmail: 'Empty or invalid e-mail address'})
        }


    }



    render() {
        return (

            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login"
                    overlayClassName="Overlay"
                >

                    <div className="modal-login-block ">
                        <div className="block-top fix">
                            <div className='sr-title'>Reset password</div>
                            {/*<img src="../../img/x.svg" alt="" className="close-icon" onClick={this.props.handleCloseModal}/>*/}
                            <div className='close-title' onClick={this.props.handleCloseModal}>
                                <div className='close' style={{right:'0', left:'auto', opacity:'1'}}/>
                            </div>
                        </div>

                        {!this.state.successReset &&
                            <div>
                                <div className="sr-description">
                                    Please enter your email address below.
                                    Further instructions on how to reset your password will be sent to you.
                                </div>
                                <div className="block-form">
                                    <div className="input-reg-dao" style={{paddingBottom: '20px'}}>
                                        <Input
                                            type={'email'}
                                            name={'email'}
                                            value={this.state.email}
                                            onChange={this.onChange}
                                            placeholder={'E-mail'}
                                        />
                                        <div className="reg_error_field">{this.state.errorEmail}</div>
                                    </div>
                                    <div>
                                        <button className="login-form__btn login-form__btn_join new-button-green-modal" onClick={this.send}>Submit</button>
                                        <div className="reg_error_field  fix-error-2">{this.state.serverError}</div>
                                    </div>
                                </div>
                            </div>

                        }

                        {this.state.successReset &&
                            <div className="sr-description">
                                Instructions on how to reset your password have been sent to your email.
                            </div>

                        }











                    </div>

                    <Loader loaded={this.state.loaded}></Loader>
                </ReactModal>
            </div>


        )
    }
}



ModalForgotPassword.propTypes = {
    history:PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
}

export default connect(null, {})(ModalForgotPassword)
import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import expertParams from './expertParams';
import {Row} from "../Grid/Row";
import {VerificationIcon} from "../VerificationIcon";

function getParamVal(expertId, paramId, type, ach, line){
    try {
        if(!line)
            return expertParams[expertId][paramId][type][ach];
        else
            return expertParams[expertId][paramId].line;
    } catch (e) {
        return 0;
    }
}

export const ExpertListItem = ({key, href, item, onClick}) => {
    const {User} = item;

    const _avatar = User.logoFileId ?
        <div className="avatar"
             style={{backgroundImage: `url(${`${config.proxy}/file/${User.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('avatar', User.firstName);

    if (!item.parameters) item.parameters = []

    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className='all-card-block expert-card-block'
                 onClick={onClick}>
                <div className='all-card-top expert-card-top'>
                    {_avatar}
                    <div className='info-conteiner'>
                        <div className='title'>Expert</div>
                        <div className='name'>{User.firstName} {User.lastName}</div>
                        <VerificationIcon type={'expert'} className={'fly'}/>
                        {/*<span className="check">*/}
                        {/*<i className="fal fa-check"/>*/}
                        {/*</span>*/}
                    </div>
                </div>
                <div className='all-card-content expert-card-content'>
                    <div
                        className='description subtitle'>{item.professionalDescription && item.professionalDescription.length > 120 ? `${item.professionalDescription.slice(0, 120)}...` : item.professionalDescription || ''}</div>
                    <div className='location subtitle'>{User.country}</div>
                </div>
                <div className='all-card-bottom expert-card-bottom'>
                    <Row>
                    <div className='col-12 title'>Evaluation parameters</div>
                    {item.parameters.map((param, key) => {
                        if (key <= 1) {
                            return (
                                <div className={'col-12'}>
                                    <div className='evaluation-parameters-title' title={key}
                                         key={key}>{param.RoleParameter.title}</div>
                                    <div className='evaluation-parameters-line'>
                                        <div className='line' style={{width: `${getParamVal(item.id, param.RoleParameter.id, '', '', true)}%`}}/>
                                    </div>
                                </div>
                            )
                        }
                    })}
                    </Row>
                </div>
                <div className="all-card-bottom border-top shell-margin-top-20 bottom-0-cart-expert">
                    <Row>
                        <div className="col-6 fund-size">
                            <div className="title">Projects evaluated</div>
                            <div className="subtitle">0</div>
                        </div>
                        <div className="col-6 investment-size">
                            <div className="title">Experts evaluated</div>
                            <div className="subtitle">0</div>
                        </div>
                    </Row>
                </div>
            </div>
            </a>
        </div>
    )
};
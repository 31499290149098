import React from 'react';
import history from '../../history'
import { Route, Switch } from 'react-router-dom'

import Header from './header'
import './style.css'
import Auth from './auth'
import RegEmail from './regEmail'
import SelectState from './selectState'
import RegState from './regStete'
import EndReg from './endReg'
import {NotificationContainer} from 'react-notifications';

export default class index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottomPadding: '120px'
        }
    }

    componentDidMount(){
        if(!localStorage.RocketToken){
            history.push('/slush/auth');
            window.scrollTo(0,0);
        }
        window.scrollTo(0,0);
    }

    setBottomPadding = (e) => {
        this.setState({bottomPadding:e})
    }

    render() {
        return (
            <div className="shell-container-mini-reg">
                <div className="block-container-mini-reg">
                    <Header/>
                    <Switch>
                        <Route exact path={'/slush/auth'} render={(props) => ( <Auth/>)} />
                        <Route exact path={'/slush/profile/:type'} render={(props) => ( <RegEmail {...props}/>)} />
                        <Route exact path={'/slush/:type'} render={(props) => (
                            <RegState
                                state={props.match.params.type}
                                type2={history.location.search}
                                ChangePadding={(e) => this.setBottomPadding(e)}
                            />
                        )} />
                        <Route exact path={'/slush/:type/thanks'} render={(props) => (
                            <EndReg
                                state={props.match.params.type}
                            />
                        )
                        }/>
                        <Route exact path={'/slush'} render={(props) => ( <SelectState ChangePadding={(e) => this.setBottomPadding(e)}/>)} />
                    </Switch>
                </div>
                <NotificationContainer/>
            </div>
        );
    }
}
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'
import Step10 from './Step10'
import Step11 from './Step11'
import Step12 from './Step12'
import Step13 from './Step13'
import Step14 from './Step14'
import Step15 from './Step15'
import Step16 from './Step16'
import Step17 from './Step17'
import Step18 from './Step18'
import Step19 from './Step19'
import Step20 from './Step20'
import Step21 from './Step21'
import Step22 from './Step22'
import Step23 from './Step23'
import Step24 from './Step24'
import Step25 from './Step25'

const Routes = () => (
    <Switch>
        <Route path={'/registration/expert/1'} render={(props) => ( <Step1/>)} />
        <Route path={'/registration/expert/2'} render={(props) => ( <Step2/>)} />
        <Route path={'/registration/expert/3'} render={(props) => ( <Step3/>)} />
        <Route path={'/registration/expert/4'} render={(props) => ( <Step4/>)} />
        <Route path={'/registration/expert/5'} render={(props) => ( <Step5/>)} />
        <Route path={'/registration/expert/6'} render={(props) => ( <Step6/>)} />
        <Route path={'/registration/expert/7'} render={(props) => ( <Step7/>)} />
        <Route path={'/registration/expert/8'} render={(props) => ( <Step8/>)} />
        <Route path={'/registration/expert/9'} render={(props) => ( <Step9/>)} />
        <Route path={'/registration/expert/10'} render={(props) => ( <Step10/>)} />
        <Route path={'/registration/expert/11'} render={(props) => ( <Step11/>)} />
        <Route path={'/registration/expert/12'} render={(props) => ( <Step12/>)} />
        <Route path={'/registration/expert/13'} render={(props) => ( <Step13/>)} />
        <Route path={'/registration/expert/14'} render={(props) => ( <Step14/>)} />
        <Route path={'/registration/expert/15'} render={(props) => ( <Step15/>)} />
        <Route path={'/registration/expert/16'} render={(props) => ( <Step16/>)} />
        <Route path={'/registration/expert/17'} render={(props) => ( <Step17/>)} />
        <Route path={'/registration/expert/18'} render={(props) => ( <Step18/>)} />
        <Route path={'/registration/expert/19'} render={(props) => ( <Step19/>)} />
        <Route path={'/registration/expert/20'} render={(props) => ( <Step20/>)} />
        <Route path={'/registration/expert/21'} render={(props) => ( <Step21/>)} />
        <Route path={'/registration/expert/22'} render={(props) => ( <Step22/>)} />
        <Route path={'/registration/expert/23'} render={(props) => ( <Step23/>)} />
        <Route path={'/registration/expert/24'} render={(props) => ( <Step24/>)} />
        <Route path={'/registration/expert/25'} render={(props) => ( <Step25/>)} />
    </Switch>

)

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes

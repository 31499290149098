import React from 'react';

import ButtonBlue from '../controls3.0/button/ButtonBlue'
import ButtonGray from "../controls3.0/button/ButtonGray";
import history from '../../history';

export default class Access extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        if(this.props.access){
            return (
                <React.Fragment>
                    <div className="step-1 is-startup">
                        <div className="block-reg-startup-step-2 padding-reg">
                            <div className="container-full-reg" style={{position: 'relative'}}>
                                <div className="container-step-1-reg">
                                    <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                        Great!
                                    </p>
                                    <p className="text-h6 margin-0 shell-margin-bottom-15">
                                        Your domain is alright, now you can continue registration. As soon as finished, you will be nominated as an admin of the account.
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={this.props.back}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Continue'}
                                    EventOnClick={this.props.next}
                                    paddingButton={'54'}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="step-1 is-startup">
                        <div className="block-reg-startup-step-2 padding-reg">
                            <div className="container-full-reg" style={{position: 'relative'}}>
                                <div className="container-step-1-reg">
                                    <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                        Oops
                                    </p>
                                    <p className="text-h6 margin-0 shell-margin-bottom-15">
                                        There is a problem, the domain of your email is not the same as of the organization's. Please try again.
                                    </p>
                                    <p className="margin-top-50">
                                        <a href={'mailto:support@rocketdao.io'} style={{color: '#0ECFAA', fontSize: '16px',
                                            lineHeight: '24px',
                                            textDecoration: 'underline'}}>Contact support</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block-fix-button-reg-bottom fix-button-one-details-double">
                        <div className="container-full-reg" style={{textAlign: 'right'}}>

                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    Are you the owner of this Fund?<br/>
                                    You will have to provide relevant documents to <br/>
                                    prove ownership.
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Invite the owner.'}
                                    EventOnClick={this.props.invite}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Authorize with a different email'}
                                    EventOnClick={this.props.next}
                                    paddingButton={'25'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>


                </React.Fragment>
            )
        }

    }
}
const initialState = {

}

export const project = (state = initialState, action={}) => {
    switch (action.type){
        case 'UPDATE_PROJECT_INFO':
        case 'GET_PROJECT_INFO':
            return {
                project: action.project
            }

        default: return state
    }
};
import React, {useState, useEffect} from 'react';
import Input from "../../controls3.0/input/Input";
import Select from "../../controls3.0/select/Select";
import RocketDatePicker from '../../controls3.0/datePicker/DatePicker';
import CheckBox from "../../controls3.0/checkBox/checkBox";
import moment from "moment/moment";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import './style.scss';
import {getCitySearch} from "../../../actions/Profile";

export const WorkExperience = ({onSave, data}) => {

    const [experience, setExperience] =  useState(data || {position: '',
        description: '',
        company: '',
        city: '',
        periodFrom: '',
        periodTo: ''});

    const [errors, setErrors] = useState({});
    const [listCities, setListCities] = useState([]);


    const onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        value = value.cleanWhitespace();

        switch (name) {
            case 'description':
                value = value.slice(0, 1000);
                break;
            case 'company':
                value = value.slice(0, 100);
                break;
        }

        if (value.length) {
           delete errors[name];
        }

        setExperience({
            ...experience,
            [name]: value
        })
    }

    const onChangeCity = value => {
        setExperience({
            ...experience,
            city: value
        });

        delete errors['city'];
    }

    const onCheck = () => {
        setExperience({
            ...experience,
            currentJob: !experience.currentJob
        });
        delete errors['periodTo'];
    }

    const handleChangeStartDate = date => {
        setExperience({
            ...experience,
            periodFrom: date
        });
        delete errors['periodFrom'];
    };

    const handleChangeEndDate = date => {
        setExperience({
            ...experience,
            periodTo: date
        });
        delete errors['periodTo'];
    };

    const searchCity = str => {
        if (str.length < 2) return
        getCitySearch(str)
            .then(res => {
                setListCities(res.data);
            })
    }

    const validate = () => {
        const _errors = {}
        for (let name in experience) {
            let value = experience[name],
                required = false,
                limit = 1000,
                minLength = 0;
            switch (name) {
                case 'position':
                case 'description':
                case 'company':
                case 'city':
                    required = true;
                    break;
                case 'periodFrom':
                case 'periodTo':
                    required = true;
                    break;
            }

            if (required && !value) _errors[name] = 'Field is required'


            if (experience.currentJob) delete _errors['periodTo']
        }

        return Object.keys(_errors).length === 0;
    }

    return (
        <React.Fragment>
            <div className="work-experience">
                <div className={'row-b'}>
                    <div className={'col-12'}>
                        <div className="block-line-add-experience-cart">
                            <div className="block-left-add-experience-cart new-width-100-down-1199">
                                <div className="shell-input-kyc">
                                    <p className="title-input-kyc">Position</p>
                                    <Input
                                        type={'text'}
                                        name={'position'}
                                        value={experience.position}
                                        onChange={onChange}
                                        placeholder={'Ex.: Chief Marketing Officer'}
                                        error={errors.position}
                                    />
                                </div>
                                <div className="shell-text-area-kyc">
                                    <p className="title-input-kyc">Description</p>
                                    <textarea name="description" value={experience.description} style={!errors.description?{}:{border: '1px solid #E43A70'}}
                                              onChange={onChange}
                                              placeholder={'What are your duties? Write down a short summary - not more than 1 000 symbols.'}/>
                                    <p className="title-error-select" style={{position:'static'}}>{errors.description}</p>
                                </div>
                            </div>
                            <div className="block-right-add-experience-cart top-cart new-width-100-down-1199">
                                <div className="shell-input-kyc">
                                    <p className="title-input-kyc">Company</p>
                                    <Input
                                        type={'text'}
                                        name={'company'}
                                        value={experience.company}
                                        onChange={onChange}
                                        placeholder={'Ex.: EPAM'}
                                        error={errors.company}
                                    />
                                </div>
                                <div className="shell-select-kyc">
                                    <p className="title-input-kyc">Location of the company</p>
                                    <Select
                                        placeholder={'City'}
                                        data={listCities}
                                        value={experience.city}
                                        onChange={(e) => onChangeCity(e)}
                                        searchList={searchCity}
                                        error={errors.city}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <div
                            className="shell-text-area-kyc bottom-calendars-block new-display-block-down-1199 text-left">
                            <div className="block-left-add-experience-cart new-width-100-down-1199 shell-margin-bottom-20">
                                <div className="new-col c-2 new-pad-15-right new-width-100-down-1199">
                                    <p className="title-input-kyc">Start date</p>
                                    <RocketDatePicker
                                        name="startDate"
                                        value={ experience.periodFrom ? new Date(experience.periodFrom) : ''}
                                        onChange={handleChangeStartDate}
                                        maxDate={new Date()}
                                        peekNextMonth={true}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        yearDropdownItemNumber={8}
                                        placeholderText=""
                                        error={errors.periodFrom}
                                    />
                                    <p className="error textarea-error">{errors.periodFrom}</p>
                                </div>


                                {!experience.currentJob &&
                                <div className="new-col c-2 new-pad-15-right new-width-100-down-1199">
                                    <p className="title-input-kyc">End date</p>
                                    <RocketDatePicker
                                        name="startDate"
                                        value={ experience.periodTo ? new Date(experience.periodTo) : ''}
                                        onChange={handleChangeEndDate}
                                        minDate={ new Date(experience.periodFrom) || new Date()}
                                        maxDate={new Date()}
                                        peekNextMonth={true}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        yearDropdownItemNumber={8}
                                        placeholderText=""
                                        error={errors.periodTo}
                                    />
                                    <p className="error textarea-error">{errors.periodTo}</p>
                                </div>
                                }
                            </div>
                            <div className="block-right-add-experience-cart new-width-100-down-1199">
                                <div
                                    className='container-inline-checkbox new-display-block-down-1199 new-no-margin-down-1199-left margin-top-35'
                                    onClick={() => onCheck()}>
                                    <CheckBox
                                        onClick={() => onCheck()}
                                        active={experience.currentJob}
                                    />
                                    I work here now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'row-b'}>
                    <div className={'col-12'}>
                        <div className="block-line-add-experience-cart">
                            <div className="block-left-add-experience-cart">
                                <ButtonBlue
                                    TitleButton={'Save'}
                                    EventOnClick={() => onSave(experience)}
                                    paddingButton={'54'}
                                    disabled={!validate()}
                                    loader={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
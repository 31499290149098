import axios from 'axios'
import { updateUserInfo } from './AuthActions'

export const signup = (userData) => {
    return axios.post('/users', userData)
}

//send email for resetting of password
export const forgot = (email) => {
  return axios.post('/users/generate-reset-password-token', {email: email})
}



//get list of questions
export const getQuestions = () => {
  return axios.get('/users/questions')
}

//get list of interests
export const getInterests = () => {
  return axios.get('/users/interests')
}

//get list of interests
export const validateField = (data) => {
  return axios.post('/users/validate', {email: data.email, password: data.password})
}

//login width facebook
// export const signUpFacebook = (token) => {
//   return axios.get('/auth/facebook/token?access_token=' + token)
// }



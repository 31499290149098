import React, {useState, useEffect} from 'react';

export const GridTest = ({}) => {
    return (
        <div className={'container'}>
            <div className={'row-b'}>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'red'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'blue'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'green'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'red'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'blue'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'green'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'red'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'blue'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'green'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'red'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'blue'}}/>
                </div>
                <div className={'col-1'} style={{height: '250px'}}>
                    <div style={{width: '100%', height: '100%', background: 'green'}}/>
                </div>
            </div>
        </div>
    )
};
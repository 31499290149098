import React from "react";
import Header from "../header";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NotificationContainer } from "react-notifications";
import { Route, Switch } from "react-router-dom";


import { tokenLogin } from "../../actions/Login";

import { Container } from "../v4/Grid/Container";
import { ProfileContainer } from "../v4/Grid/ProfileContainer";
import { Row } from "../v4/Grid/Row";

import NotFound from "../NotFound";

import LeftBar from "./components/LeftBar";

import InvestClubAdmin from "./components/InvestClubAdmin/InvestClubAdmin";
import InvestClubMember from "./components/InvestClubMember/InvestClubMember";
import InvestClubList from "./components/InvestClubList/InvestClubList";

import "./investClub.scss";

class InvestClub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      type: props.match.params.type,
      page: props.match.params.page,
    };
  }

  componentDidMount() {
    if (localStorage.RocketToken) {
      this.props.tokenLogin(localStorage.RocketToken);
    }
  }

  render() {
    const { user } = this.props;
    if (!user) {
      return "";
    }

    //   "investClubSuperAdmin", "investClubAdmin", "investClubPartner"

    if (
      !(
        user.is_investClubSuperAdmin ||
        user.is_investClubAdmin ||
        user.is_investClubPartner
      )
    ) {
      return <NotFound />;
    }

    const clientWidth = document.documentElement.clientWidth;

    const isAdmin = user.is_investClubSuperAdmin || user.is_investClubAdmin;

    return (
      <React.Fragment>
        <Header
          user={this.props.user}
          onlyMenu={clientWidth > 756}
          offSticky={clientWidth > 756}
        />

        <Container
          mod={"-fluid"}
          className={"container-profile invest-club-container"}
        >
          <ProfileContainer style={{ maxWidth: "100%" }}>
            <Row className="min-height-100 m16">
              <LeftBar user={this.props.user} />

              <div
                className={`col-12 col-sm`}
                style={{ padding: "20px 25px", textAlign: "center" }}
              >
                <Switch>
                  <Route exact path="/investclub">
                    {isAdmin ? <InvestClubList /> : <InvestClubMember />}
                  </Route>
                  <Route exact path="/investclub/partner/:userId">
                    <InvestClubMember/>
                  </Route>
                  <Route exact path="/investclub/admin">
                    <InvestClubAdmin />
                  </Route>

                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </div>
            </Row>

            {/* <Routes user={this.props.user} type={type} id={id} page={page}/> */}
          </ProfileContainer>
        </Container>

        <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

InvestClub.propTypes = {
  tokenLogin: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default connect(mapStateToProps, { tokenLogin })(InvestClub);

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import AvatarImg from './../../controls3.0/AvatarImg'
import {showMessage} from "../../../utils/showMessage";

class Step extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {
                professionalIdentification: {msg: ''},
                professionalDescription: {msg: ''}
            },
            error: ''
        }
    }

    componentDidMount () {

    }

    onChange = e => {
        this.setState({error:''});

        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();

        switch (name) {
            case 'professionalIdentification':
                if(value.length > 40){
                    const error = 'The text is too long, 40 symbols max';
                    this.setState({errors:{ ...this.state.errors, professionalIdentification: {msg: error}}});
                } else {
                    this.setState({errors:{...this.state.errors, professionalIdentification: {msg: ''}}});
                }
                value = value.slice(0, 40);
                break;
            case 'professionalDescription':
                if(value.length > 120){
                    const error = 'The text is too long, 120 symbols max';
                    this.setState({errors:{...this.state.errors, professionalDescription: {msg: error}}});
                } else {
                    this.setState({errors:{...this.state.errors, professionalDescription: {msg: ''}}});
                }
                value = value.slice(0, 120);
                break;
        }

        this.setState({investor: {...this.state.investor, [name]: value}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, investor, errors, error} = this.state

        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-1-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <div className="block-prof-ide">
                            <div className="block-left-prof-ide new-width-100-down-1199">

                                <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                                    Professional identification
                                </p>

                                <p className="title-top-page-reg-expert text-h4 left-text shell-margin-bottom-40 shell-margin-top-0">
                                    Tell your story to the community.
                                </p>

                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10 shell-margin-top-0">
                                    Professional status
                                </p>

                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0">
                                    For example: business consultant; Senior risks analyst.
                                </p>

                                <div className="shell-input-height-40" style={{width:'282px'}}>
                                    <Input
                                        type={'text'}
                                        name={'professionalIdentification'}
                                        value={investor.professionalIdentification}
                                        onChange={this.onChange}
                                        placeholder={'40 symbols'}
                                        error={errors.professionalIdentification.msg}
                                    />
                                </div>

                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10">
                                    Who are you as an investor?
                                </p>

                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0">
                                    Provide a short summary of your professional experience and scope of interests.
                                </p>

                                <div className="block-text-area-form-add-edit-modal-reg-expert">
                                    <textarea
                                        name={'professionalDescription'}
                                        value={investor.professionalDescription}
                                        onChange={this.onChange}
                                        placeholder={'120 symbols'}>
                                    </textarea>
                                    <div className="block-new-input error" style={{height:'20px'}}>
                                        <p className="title-error">{errors.professionalDescription.msg || ''}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="block-right-prof-ide new-width-100-down-1199 new-no-padding-down-1199">
                                <p className="title-top-page-reg-expert text-button left-text color-text-reg-white-gray shell-margin-bottom-20 new-no-padding-down-1199" style={{padding:'70px 0 0 0'}}>
                                    Here is the way other Rocket DAO users will see your profile.
                                </p>

                                <div className="block-person-reg-expert">
                                    <div className="block-left-person-reg-expert">
                                        <AvatarImg id={user.logoFileId}/>
                                    </div>
                                    <div className="block-right-person-reg-expert">

                                        <p className="title-top-page-reg-expert text-h6 left-text margin-0">
                                            {user.firstName} {user.lastName}
                                        </p>
                                        <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-white-gray margin-0">
                                            {investor.professionalIdentification}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>



                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(5, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(7)}
                                paddingButton={'56'}
                                disabled={!investor.professionalIdentification || !investor.professionalDescription}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step)
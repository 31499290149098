import React from 'react';
import './style.scss'

export default class checkBox extends React.Component {

    render() {
        let {
            check,
        } = this.props;

        return (
            <div className={`check-box-cart-anim-info ${check ? 'checked' : 'unchecked'}`}>
                <i className="fal fa-check"/>
            </div>
        );
    }
}


import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import './style.css';
import icon_arrows from '../../../images/svg/arrows_top_bottom.svg';
import {createPosition} from "../../../actions/Profile";
import load from '../../controls3.0/button/button-loader.svg';

export default class ButtonLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _value: this.props.value || [],
            activeSelect: false,
            openCloseList: false,
            clickItem: false,
            _dataView: this.props.data || [],
            valueSearch: '',
            _onChange: this.props.onChange,
            loader: false
        }
        this.myRef = React.createRef();
        this.handleClock = this.handleClock.bind(this);
        this.SearchItem = this.SearchItem.bind(this);
        this.onClickItem = this.onClickItem.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClock)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.value && nextProps.value != this.state._value) {
            this.setState({_value : nextProps.value})
        }

        if (nextProps.data) {
            this.setState({_dataView : nextProps.data})
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClock)
    }

    handleClock(event) {
        if (!this.myRef.current.contains(event.target)) {
            this.setState({
                activeSelect: false,
                openCloseList: false,
            });
        } else {
            if (this.state.clickItem) {
                this.setState({
                    activeSelect: false,
                    openCloseList: true,
                    clickItem: false
                });
            } else {
                this.setState({
                    activeSelect: true,
                    openCloseList: true,
                });
            }
        }
    }

    SearchItem(e) {
        if(this.props.searchList) this.props.searchList(e.target.value)

        this.setState({
            valueSearch: e.target.value,
        });
    }

    onClickItem(item) {
        let dataValue = [];
        dataValue.push(item);
        this.setState({
            _value: dataValue,
            activeSelect: false,
            openCloseList: false,
            valueSearch: '',
            clickItem: true,
        });
        this.props.onChange(dataValue)
    }

    AddNewItem(item){
        let newData = this.state._dataView;
        newData.push({Name:item});
        this.setState({_dataView:newData});
        this.onClickItem({Name:item});

        this.setState({loader: true});
        createPosition(item).then(r => {
            this.setState({loader: false});
        }).catch(e => {
            this.setState({loader: false});
        })
    }

    render() {
        let {
            placeholder,
            data, //[{Name:''}, {Name:''}]
            value, // {Name:''}
            onChange,
            error
        } = this.props;

        const {loader} = this.state;

        let countItem = 0;
        let items =  this.state._dataView.map((item, key) => {
            let active = false
            if (this.state._value.length > 0) {
                if (this.state._value[0]['Name'] == item.Name) {
                    active = true
                }
            }
            if (!active) {
                if (this.state.valueSearch.length > 1) {
                    // console.log(item.name.toUpperCase() + ' >>> ' + this.state.valueSearch.toUpperCase() + ' === ' + item.name.toUpperCase().indexOf(this.state.valueSearch.toUpperCase()));

                    if (item.Name.toUpperCase().indexOf(this.state.valueSearch.toUpperCase()) == 0) {
                        countItem++;
                        return (
                            <div key={key} className="block-item-select"
                                 onClick={() => this.onClickItem(item)}>
                                <p className="title-item-select">{item.Name}</p>
                            </div>
                        )
                    } else {
                        console.log('here')
                        return (
                            null
                        )
                    }
                } else {
                    countItem++;
                    return (
                        <div key={key} className="block-item-select" onClick={() => this.onClickItem(this.state._dataView[key])}>
                            <p className="title-item-select">{item.Name}</p>
                        </div>
                    )
                }
            }
        });

        let title;

        if (this.state._value.length > 0) {
            title = this.state._value.map((item, key) => {
                return (item.Name)
            })
        }
        else {
            title = placeholder;
        }
        let err = error ? <p className="title-error-select">{error}</p> : null;

        const find = this.state._dataView.findIndex( (element, index) => {
            return element.Name === this.state.valueSearch;
        }) > -1;

        return (
            <div className="block-select block-select-with-add" ref={this.myRef}>
                <div className={this.state.activeSelect ? "block-top-select active" : (error?"block-top-select error":"block-top-select")}>
                    <input type="text" onChange={this.SearchItem} onKeyDown={this.props.onKeyDown} onKeyUp={this.props.onKeyUp} value={this.state.valueSearch} placeholder={'Search'}/>
                    <p className="title-select placeholder">
                        {title}
                    </p>

                    {!find && this.state.valueSearch &&
                        <div className={`select-add-new-btn text-add-new`} onClick={()=>{this.AddNewItem(this.state.valueSearch)}}>Add new</div>
                    }

                    {loader && <div className={``}>
                        <span className="loader-button"><img src={load} alt=""/></span>
                    </div>}

                    <img src={icon_arrows} alt=""/>
                </div>
                <div className={this.state.openCloseList?"block-body-select active":"block-body-select"}
                     style={this.state.openCloseList ? {height: countItem > 5 ? 'calc(32px * 5)' : 'calc(34px * ' + countItem + ')', marginTop:'3px'} : {height: '0', marginTop:'3px'}}>
                    <Scrollbars>
                        <div className="block-list-item-select">
                            {items}
                        </div>
                    </Scrollbars>
                    <span className="gradient"/>
                </div>
                {err}
            </div>
        );
    }
}





import React from 'react';
import ButtonBlack from '../controls3.0/button/ButtonBlack'
import ButtonBlue from '../controls3.0/button/ButtonBlue'
import ButtonGreen from '../controls3.0/button/ButtonGreen'
import './style.css'
import history from  '../../history';
import {tokenLogin} from "../../actions/Login";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Loader  from './../test-controls/preloader/preloader';
import {Button} from "../v4/Button";

class selectState extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        if(!localStorage.RocketToken){
            history.push('/slush/auth');
            window.scrollTo(0,0);
        } else {
            this.props.tokenLogin(localStorage.RocketToken)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.user){
            this.setState({user: nextProps.user})
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        // let ChangePadding = this.props.ChangePadding;
        // ChangePadding('325px')
    }

    render() {
        return (
            this.state.user && <div className="block-select-state-mini-reg">
                <p className="title-select-state-mini-reg">
                    Hello!
                </p>
                <p className="description-select-state-mini-reg">
                    Please choose the role in which you would like to join the community.
                </p>

                <div className="block-button-bottom-state">
                    <div className="block-button-description">
                        <ButtonBlack
                            TitleButton={'Startup'}
                            EventOnClick={() => history.push('/slush/startup')}
                            paddingButton={null}
                            disabled={false}
                            loader={false}
                        />
                        <p className="description-button">
                            High-tech, digital startups seeking investments
                        </p>
                    </div>
                    <div className="block-button-description">
                        <ButtonBlue
                            TitleButton={'Investor'}
                            EventOnClick={() => history.push('/slush/investor')}
                            paddingButton={null}
                            disabled={false}
                            loader={false}
                        />
                        <p className="description-button">
                            Business angels, VC funds and angel networks
                        </p>
                    </div>
                    <div className="block-button-description">
                        <ButtonGreen
                            TitleButton={'Expert'}
                            EventOnClick={() => history.push('/slush/expert')}
                            paddingButton={null}
                            disabled={false}
                            loader={false}
                        />
                        <p className="description-button">
                            Specialists in startup evaluation
                        </p>
                    </div>
                    <div className="block-button-description">
                        <Button onClick={() => history.push('/slush/accelerator')} height={48} type={'accelerator'}>Accelerator</Button>
                        <p className="description-button">
                            Private, corparate, government accelerators
                        </p>
                    </div>
                </div>
            </div> || <Loader loaded={true}/>
        );
    }
}

const mapStateToProps = (state) => ({
    tokenLogin: PropTypes.func.isRequired,
    user: state.auth.user
})

export default connect(mapStateToProps, {tokenLogin})(selectState)
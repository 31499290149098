import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import CheckBox from "../../controls3.0/checkBox/checkBox";
import ListTitlelMore from "../../controls3.0/listTitleMore/ListTitlelMore";
import {regexUrl} from "../../../utils/RegExp";


class Step13 extends React.Component {
    constructor(props) {
        super(props);

        const accelerator = props.accelerator;
        if(!accelerator.superstarStartups)
            accelerator.superstarStartups = [];

        this.state = {
            accelerator: props.accelerator,
            errors: {},
            website: '',
            newPI: {
                title: '',
                description: '',
                site: ''
            }
        }
    }


    componentDidMount() {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();

        if (name === 'description' && value.length > 200) {
            value = value.slice(0, 200);
            this.state.errors[name] = 'The text is too long. 200 symbols max'
        }
        else if(value.length > 100 && name !== 'description'){
            value = value.slice(0, 100);
            this.state.errors[name] = 'The text is too long. 100 symbols max'
        } else {
            delete this.state.errors[name];
        }

        if (name === 'size') {
            value = value.replace(/ /g, '').replace(/\D/, "");
        }
        this.setState({newPI: {...this.state.newPI, [name]: value}})
    }

    onChangeWebSite = (e) => {
        let {value, name} = e.target;
        value = value.trim();
        if(!regexUrl.test(value)){
            this.setState({errors: {...this.state.errors, site: 'Invalid link'}})
        } else {
            delete this.state.errors.site;
        }

        this.setState({newPI: {...this.state.newPI, site: value}})
    }

    add = () => {
        let newPI = this.state.newPI,
            superstarStartups = this.state.accelerator.superstarStartups;
        if (newPI.title && newPI.description && newPI.site && !this.state.errors.site) {
            superstarStartups.push(newPI)
            this.setState({accelerator: {...this.state.accelerator, superstarStartups: superstarStartups}}, () => {
                this.setState({
                    newPI: {
                        title: '',
                        description: '',
                        site: ''
                    }
                })
            })
        }
    }


    remove = (title, key) => {
        let superstarStartups = this.state.accelerator.superstarStartups
        superstarStartups.splice(key, 1)
        this.setState({accelerator: {...this.state.accelerator, superstarStartups: superstarStartups}})
    }


    update = (nextStep, onlyStep) => {
        let superstarStartups = this.state.accelerator.superstarStartups,
            obj = {superstarStartups: superstarStartups}
        if (onlyStep) {
            obj = {}
        }

        obj.event_type = 'accelerator_success_13';
        if(nextStep === 14 && onlyStep){
            obj.event_data = {
                skip: true
            }
        }

        if (!onlyStep) this.setState({loading: true})
        const acceleratorId = this.state.accelerator.id;
        this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {accelerator, newPI, errors} = this.state;

        let list = accelerator.superstarStartups.map((item, key) => {
            return (
                {title: item.title}
            )
        });

        return (
            <React.Fragment>
                <div className="step-1 is-startup">


                    <div className="wrap-white">
                        <div className="container-full-reg padding-reg" style={{position: 'relative'}}>

                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                               Your superstar startups
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-50">
                                What of the startups you have ever accelerated you are very proud of?
                            </p>

                            <div className="block-previosu-add-startup">
                                <div className="block-left-previosu-add-startup">
                                    <div className="shell-input-kyc">
                                        <p className="title-input-kyc">Startup name</p>
                                        <Input
                                            type={'text'}
                                            name={'title'}
                                            value={newPI.title}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errors.title}
                                        />
                                    </div>

                                    <div className="shell-text-area-kyc">
                                        <p className="title-input-kyc">Startup description</p>
                                        <textarea
                                            name={'description'}
                                            value={newPI.description}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            style={{height: '103px'}}
                                        />
                                        <div className="block-new-input error" style={{height:'20px'}}>
                                            <p className="title-error">{errors.description}</p>
                                        </div>
                                    </div>

                                    <div className="block-size-previosu-add-startup">
                                        <div className="block-left-size-previosu-add-startup" style={{width:'100%'}}>
                                            <div className="shell-input-kyc">
                                                <p className="title-input-kyc">Site</p>
                                                <Input
                                                    type={'text'}
                                                    name={'site'}
                                                    value={newPI.site}
                                                    onChange={this.onChangeWebSite}
                                                    placeholder={''}
                                                    error={errors.site}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <ButtonBlue
                                        TitleButton={'Add'}
                                        EventOnClick={() => this.add()}
                                        paddingButton={'33'}
                                        disabled={!newPI.title || !newPI.description || !newPI.site || Object.keys(this.state.errors).length}
                                        loader={false}
                                    />
                                </div>
                                <div className="block-right-previosu-add-startup">
                                    <ListTitlelMore
                                        data={list}
                                        onChange={(e) => console.log(e)}
                                        viewCountItem={6}
                                        removeFromList={this.remove}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign: 'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(12, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(14)}
                                paddingButton={'54'}
                                disabled={!this.state.accelerator.superstarStartups.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step13.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step13)
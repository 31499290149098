import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
// import async from "async";

import {updateAccelerator, sendInvitation} from "../../../actions/Accelerator";
import {getListFunds} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import ButtonBorder from '../../controls3.0/button/ButtonBorder'


import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
// import packageJSON from './../../../../package.json'
// import {getTransaction} from "../../../actions/FundActions";
import {regexEmail} from "../../../utils/RegExp";
// import TabsWithRemove from "../../controls3.0/tabs/TabsWithRemove";
import plen18 from "../../../images/ilustaciya/Fund members@2x-min.png";

import ListTitlelMore from '../../controls3.0/listTitleMore/ListTitlelMore';
import AvatarImg from "../../controls3.0/AvatarImg";
import CheckBox from "../../controls3.0/checkBox/checkBox";
import Select from "../../controls3.0/select/Select";
import SelectWithAdd from "../../controls3.0/select/SelectWithAdd";
import ListMailMore from "../../controls3.0/listMailMore/ListMailMore";
import Group21 from "../../../images/svg/Group21.png";

class Step16 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator,
            errors: {},
            iAmFounder: false,
            myPosition: [{Name: ''}],
            email: '',
            invitationsSent: false,
            positionList: [],
            myPositionList: []
        }
    }

    componentDidMount() {
        this.getPositionList('A');
        if(this.state.accelerator){
            this.setRightState();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accelerator && nextProps.accelerator.id) {
            this.setState({accelerator: nextProps.accelerator}, () => this.setRightState())
        }
    }

    setRightState = () => {
        let team = this.state.accelerator.team
        if (team.length) this.setState({invitationsSent: team.length > 1});

        let iAm = team.find((item) => item.userId === this.props.user.id);

        if (iAm) {
            const filtered = team.filter((item) => item.userId !== this.props.user.id);
            this.setState({ accelerator: {...this.state.accelerator, team: filtered} });

            let myPosition = this.state.myPosition
            myPosition[0] = {Name: iAm.position}

            this.setState({myPosition: myPosition, iAmFounder: iAm.isFounder})
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.trim();

        if (regexEmail.test(value)) {
            const errors = this.state.errors;
            delete errors.email;
            this.setState({errors})
        } else {
            this.setState({errors: {...this.state.errors, [name]: 'Invalid e-mail address'}})
        }

        if(this.state.accelerator.team.find((item) => item.email === value) || this.props.user.email === value){
            this.setState({errors: {...this.state.errors, [name]: 'E-mail address exist'}})
        }

        this.setState({[name]: value})
    }

    onChangecurrentTeamCount = e => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");
        value = parseInt(value);
        if(value <= 0 || value > 99999){
            this.setState({errors: {...this.state.errors, [name]: 'Min 1 and max 99999'}})
        } else {
            const errors = this.state.errors;
            delete errors[name];
            this.setState({errors: errors});

            this.setState({accelerator: {...this.state.accelerator, [name]: value}})
        }
    }

    onChangeIAmFounder = () => {
        let iAmFounder = this.state.iAmFounder;
        this.setState({iAmFounder: !iAmFounder})
    }

    onChangeFounder = () => {
        let isFounder = this.state.isFounder;
        this.setState({isFounder: !isFounder})
    }

    onKeyDown1 = (e) => {
        this.getPositionList(e.target.value);
    }

    onKeyDown2 = (e) => {
        this.getMyPositionList(e.target.value);
    }

    getPositionList(filter){
        if(filter){
            axios
                .get('/v2/positions/'+filter)
                .then(r => {
                    this.setState({positionList: r.data})
                    this.setState({myPositionList: r.data})
                })
        }
    }

    getMyPositionList(filter){
        if(filter){
            axios
                .get('/v2/positions/'+filter)
                .then(r => {
                    this.setState({myPositionList: r.data})
                })
        }
    }

    onChangeMyPosition = myPosition => {
        this.setState({myPosition})
    }

    onChangePosition = position => {
        this.setState({position})
    }

    addToTeam = () => {
        let {email, position, isFounder} = this.state;
        if (!regexEmail.test(email)) {
            this.setState({errors: {...this.state.errors, email: 'Invalid e-mail address'}})
            return;
        }
        let obj = {
            email: email,
            position: position[0].Name,
            isFounder: isFounder
        }
        this.setState({accelerator: {...this.state.accelerator, team: [...this.state.accelerator.team, obj]}}, () => {
            this.setState({email: '', isFounder: false})
            let position = this.state.position;
            position[0] = {Name: ''}
            this.setState({position})
        })
    }

    removeFromList = email => {
        let team = this.state.accelerator.team,
            filtered = team.filter((item) => {
                return item.email !== email
            })
        this.setState({accelerator: {...this.state.accelerator, team: filtered}})
    }

    send = () => {
        let {accelerator} = this.state;
        let team = [...accelerator.team];
        team.push({
            position: this.state.myPosition[0].Name,
            isFounder: this.state.iAmFounder,
            userId: this.props.user.id,
            email: this.props.user.email
        });
        this.setState({loading2: true});

        return sendInvitation(accelerator.id, {members: team})
            .then((res) => {
                this.setState({loading2: false})
                return true;
            })
            .catch(error => {
                this.setState({loading2: false})
                return false;
            })

    }

    update = async (nextStep, onlyStep) => {
        let accelerator = this.state.accelerator
        let obj = {
            currentTeamCount: accelerator.currentTeamCount,
            invitations: accelerator.team.length,
            event_type: 'accelerator_team_16'
        }

        await this.send();

        this.props.updateAccelerator(obj, accelerator.id, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        let accelerator = this.state.accelerator
        let obj = {
            currentTeamCount: accelerator.currentTeamCount
        }

        this.props.updateAccelerator(obj, accelerator.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    disableInvite = () => {
        try {
            return !this.state.email || !this.state.position.length || this.state.position[0].Name.length === 0 || Object.keys(this.state.errors).length;
        } catch (e) {
            return true;
        }
    }

    render() {
        let {accelerator, errors} = this.state;
        let user = this.props.user;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{padding: '0 0 30px 0'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0">
                                    Team
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                    Here you can invite other colleagues to join Rocket DAO. This will let you collaborate and search for new startups even more effectively.
                                </p>

                                <div className="block-user-profile-reg-startup">
                                    <div className="block-left-user-profile-reg-startup new-width-100-down-1199">
                                        <div className="block-user-cart-user-profile-reg-startup">
                                            <div className="block-left-user-cart-user-profile-reg-startup">
                                                <div className="block-img-user-profile">
                                                    <AvatarImg id={user.logoFileId}/>
                                                </div>
                                            </div>
                                            <div className="block-right-user-cart-user-profile-reg-startup">
                                                <p className="title-user-profile-reg-startup">
                                                    What is your role in the accelerator?
                                                </p>
                                                <p className="check-box-user-profile-reg-startup">
                                                    <CheckBox
                                                        active={this.state.iAmFounder}
                                                        onClick={this.onChangeIAmFounder}
                                                    />
                                                    (Co)Founder
                                                </p>
                                                <div className="block-select-user-profile-reg-startup accelerator-select-user-profile">
                                                    <SelectWithAdd
                                                        placeholder={'Select your position'}
                                                        data={this.state.myPositionList}
                                                        value={this.state.myPosition}
                                                        onKeyUp={(e) => this.onKeyDown2(e)}
                                                        onChange={(e) => this.onChangeMyPosition(e)}
                                                        error={''}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-right-user-profile-reg-startup new-width-100-down-1199">
                                        <p className="title-input-user">
                                            Now please specify how many members <br/>
                                            are there in the current team.
                                        </p>
                                        <div className="block-small-input bold-input">
                                            <Input
                                                type={'text'}
                                                name={'currentTeamCount'}
                                                value={accelerator.currentTeamCount}
                                                onChange={this.onChangecurrentTeamCount}
                                                error={errors.currentTeamCount}
                                                placeholder={''}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className="block-section-gradient">
                                    <div className="block-gradient new-top-auto-down-1199"
                                         style={{ padding: '35px 0 0 0'}}></div>
                                    <p className="text-h5 color-text-reg-gray margin-0 shell-margin-top-60 ">
                                        Add team members to the accelerator’s profile
                                    </p>
                                    <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-25">
                                        Send them invitations to join the platform.
                                    </p>
                                    <div className="block-list-input-email">
                                        <div className="block-left-list-input-email new-width-100-down-900">
                                            <div className="block-input-reg">
                                                <p className="title-input-reg">Enter email here</p>
                                                <Input
                                                    type={'text'}
                                                    name={'email'}
                                                    value={this.state.email}
                                                    onChange={this.onChange}
                                                    error={errors.email}
                                                    placeholder={'Enter email'}
                                                />
                                            </div>
                                            <div className="block-select-reg">
                                                <p className="title-input-reg">Select position</p>
                                                <SelectWithAdd
                                                    placeholder={'Select'}
                                                    data={this.state.positionList}
                                                    value={this.state.position}
                                                    onKeyUp={(e) => this.onKeyDown1(e)}
                                                    onChange={(e) => this.onChangePosition(e)}
                                                    error={''}
                                                />
                                            </div>
                                            <div className="block-button-list-input-email">

                                                <ButtonBlue
                                                    TitleButton={'Invite'}
                                                    EventOnClick={() => this.addToTeam()}
                                                    paddingButton={'48'}
                                                    disabled={this.disableInvite()}
                                                    loader={this.state.sending}
                                                />

                                                <p className="check-box-user-profile-reg-startup">
                                                    <CheckBox
                                                        active={this.state.isFounder}
                                                        onClick={this.onChangeFounder}
                                                    />
                                                    (Co)Founder
                                                </p>
                                            </div>

                                        </div>
                                        {/*Mail:'alexarch@69pixels.com',*/}
                                        {/*Pos:'Founder, Positioning Strategy for Startups',*/}
                                        <div className="block-right-list-input-email new-width-100-down-900">
                                            <ListMailMore
                                                data={accelerator.team}
                                                onChange={(e) => console.log(e)}
                                                viewCountItem={3}
                                                removeFromList={this.removeFromList}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            {!accelerator.team.length ?
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.update(17)}
                                    paddingButton={'54'}
                                    disabled={!this.state.myPosition[0].Name || !accelerator.currentTeamCount}
                                    loader={this.state.loading}
                                />

                                :
                                <ButtonBlue
                                    TitleButton={(accelerator.team.length>1)?'Send invitations':'Send invitation'}
                                    EventOnClick={() => this.update(16)}
                                    paddingButton={'54'}
                                    disabled={!this.state.myPosition[0].Name || !accelerator.currentTeamCount}
                                    loader={this.state.loading2}
                                />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator,
    user: state.auth.user
})

Step16.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step16)
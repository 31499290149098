import axios from 'axios'
import {updateUserInfo} from "./AuthActions";
import {showMessage} from "../utils/showMessage";

export const getUserProfile = (id) => {
    return axios.get('/users/' + id)
}

export const updateProfile = (id, data) => dispatch => {
    return axios.put(`/users/${id}`, data)
        .then(res => {
                dispatch(updateUserInfo(res.data));
                return res
            }
        )
}

export const putProfile = (id, data) => {
    return axios.put('/users/' + id, data)
}

export const changePassword = (id, data) => {
    return axios.put('/users/' + id + '/change-password', data)
}

export const getMyPools = (id) => {
    return axios.get('/funds/?userId=' + id)
}
export const sendKyc = (id, data) => {
    return axios.post('/users/' + id + '/kyc', data)
}

export const getWalletList = (id) => {
    return axios.get('/users/balances')
}

export const getTokens = (contractAddress) => {
    return axios.get('/users/get-tokens?contractAddress=' + contractAddress)
}
export const getRefund = (contractAddress) => {
    return axios.get('/users/refund?contractAddress=' + contractAddress)
}
export const getTransaction = (contractAddress) => {
    return axios.get(`/tx?contractAddress=${contractAddress}`)
}

export const checkCountConfirmation = (hash) => {
    return axios.get(`/tx/check/${hash}`)
}

export const getMyProjects = (id) => {
    return axios.get('/projects?my=1')
}

export const sendExpertInfo = (id, data) => {
    return axios.post('/experts', data)
}

export const getListSkills = (isRecommended) => {
    return axios.get(`/v2/experts/skills?isRecommended=${isRecommended}`)
}
export const getListSkillsByString = (str) => {
    return axios.get(`/v2/experts/skills?search=${str}`)
}

export const getListSkillsInvestor = (isRecommended) => {
    return axios.get(`/v2/investor/skills?isRecommended=${isRecommended}`)
}

export const getListSkillsByStringInvestor = (str) => {
    return axios.get(`/v2/investor/skills?search=${str}`)
}
export const getListFunds = (str) => {
    return axios.get(`/v2/funds?search=${str}&type=simple,angels&isFinished=true`)
}


export const getListParametersInvestor = () => {
    return axios.get('/v2/investor/parameters')
}

export const getListParametersFunds = () => {
    return axios.get('/v2/funds/parameters')
}
export const getListParametersAngels = () => {
    return axios.get('/v2/angels/parameters')
}

export const getListParameters = () => {
    return axios.get('/v2/experts/parameters')
}
export const getListParametersStartup = () => {
    return axios.get('/v2/startup/parameters')
}

export const getListExperts = (string, skip, take) => {
    if(!string) string ='';
    if(!skip) skip = 0;
    if(!take) take = '';
    return axios.get('/experts?skip=' + skip + '&take=' + take + '&search=' + string)

}

export const putExpert = (id, obj) => {
    return axios.put('/experts/' + id, obj )
}

export const getKnowledge = () => {
    return axios.get('/knowledge')
}
export const getKnowledgeFilter = (obj) => {
    let tags = obj.tags || '',
        search = obj.search || '';
    return axios.get(`/knowledge/?search=${search}&tag=${tags}&newApi=true`)
}

export const createThing = (obj) => {
    let data = new FormData();
    for (let key in obj) {
        if(obj[key]){
            data.append(key, obj[key]);
        }

    }
    return axios.post('/knowledge', data )
}

export const getKnowledgeOne = (id) => {
    return axios.get(`/knowledge/${id}`)
}



export const deleteKnowledgeOne = (id) => {
    return axios.delete(`/knowledge/${id}`)
}
export const editKnowledgeOne = (data) => {
    // let obj = {
    //     title: data.title
    // }
    return axios.put(`/knowledge/${data.id}`, data)
}



export const senMessage = (data) => {
    return axios.post('/messages', data)
}

export const getPrivateChats = (skip, take) => {
    if(!skip) skip = 0;
    if(!take) take = 20;
    return axios.get(`/messages/chats?skip=${skip}&take=${take}`)
}
export const getPrivateMessages = (id, skip, take) => {
    if(!skip) skip = 0;
    if(!take) take = 20;
    return axios.get(`/messages/user/${id}`)
}

export const getNotifications = () => {
    return axios.get(`/users/notifications`)
}
export const getBGI = (type) => {
    return axios.get(`/v2/default/background/${type || 1}`)
}

export const getTagsSearch = (input) => {
    return axios.get(`/users/search_tags?search=${input}`)
}

export const sendInfoToServer = (data) => {
    return axios.post('/default/front-logs', data)
}


export const tokensale = (data) => {
    return axios.post('/icos/emails', data)
}

export const createWorkExp = (userId, data) => {
    return axios.post(`/users/${userId}/experience`, data)
}
export const updateWorkExp = (userId, data) => {
    return axios.put(`/users/${userId}/experience/${data.id}`, data)
}
export const deleteWorkExp = (userId, id) => {
    return axios.delete(`/users/${userId}/experience/${id}`)
}
export const getListWorkExp = (userId) => {
    return axios.get(`/users/${userId}/experience`)
        .catch(err => {
            showMessage({type: 'error', data: err})
        })
}
export const getProfileSettings = (userId) => {
    return axios.get(`/v1/users/settings`)
        .catch(err => {
            showMessage({type: 'error', data: err})
        })
}
export const putProfileSettings = (data) => {
    return axios.put(`/v1/users/settings`, data)
}

export const getCitySearch = (str) => {
    return axios.get(`/v2/default/cities?search=${str}`)
}

export const getCapitals = () => {
    return axios.get(`/v2/default/capitals`)
}

export const getCountrySearch = (str) => {
    return axios.get(`/v2/default/countries?search=${str}`)
}

export const createPosition = (title) => {
    return axios.post(`/v2/positions`, {
        title
    })
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";
import {getMoneyView} from "../../../utils/transformation";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import InputRange from 'react-input-range';
// import Input from "../../controls3.0/input/Input";



class Step12 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        if(!angel.fundSize){
            angel.fundSize = {};
        }
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            value: angel.fundSize.investmentSize || {min: 5000, max: 1000000},
            size_of_fund: angel.fundSize.size_of_fund ? '$ ' + parseInt(angel.fundSize.size_of_fund).moneyFormat() : ''
        }
    }

    componentDidMount() {
        if (this.state.angel.investmentSize && this.state.angel.investmentSize.min) {
            this.setState({
                value: {
                    ...this.state.value,
                    min: this.state.angel.investmentSize.min,
                    max: this.state.angel.investmentSize.max
                }
            })
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({angel: {...this.state.angel, [name]: value}})
    }

    onChangeRange = value => {
        this.setState({value: value})
    }
    onChangeSizeOfFund = (e) => {
        let name = e.target.name,
            //value = value.replace(/\D/, "");
            value = parseInt(e.target.value.replace(/ /g, '').replace('$', '')).moneyFormat();
        this.setState({size_of_fund: `$ ${value}`})
    }

    update = (nextStep, onlyStep) => {
        let obj = {
            fundSize: {
                size_of_fund: this.state.size_of_fund.replace(/ /g, '').replace('$', ''),
                investmentSize :this.state.value
            }
        };

        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) this.setState({loading: true})
        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, angel, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Investment size range (in USD)
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    What is the average investment cheque of the network?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg  container-bottom block-gradient-gray-reg padding-reg"
                         style={{position: 'relative'}}>
                        <div className="container-step-1-reg">
                            {/*<div className="block-list-title-input margin-0 shell-margin-top-30"*/}
                                 {/*style={{width: '560px', maxWidth: '560px'}}>*/}
                                {/*<div className="block-item-title-input" style={{width: '560px'}}>*/}
                                    {/*<p className="title-item-title-input" style={{width: 'calc(100% - 290px)'}}>*/}
                                        {/*The size of the Angel Network*/}
                                    {/*</p>*/}
                                    {/*<div className="block-input-item-title-input" style={{width: '290px'}}>*/}
                                        {/*<Input*/}
                                            {/*type={'text'}*/}
                                            {/*name={'fundSize'}*/}
                                            {/*value={this.state.size_of_fund}*/}
                                            {/*onChange={this.onChangeSizeOfFund}*/}
                                            {/*placeholder={''}*/}
                                            {/*error={''}*/}
                                        {/*/>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="block-input-range slider slider-green shell-margin-bottom-40 margin-top-50">
                                <p className="title-input-range" style={{minHeight: '45px',width:'110%',fontSize:'22px'}}>
                                    Average investment cheque:
                                    <div className='data-slider-turnover'>
                                        <span className='subtext'>from</span>
                                        <span className="value">{getMoneyView(this.state.value.min)}</span>
                                        <span className='subtext'>to</span>
                                        <span className="value">{getMoneyView(this.state.value.max)}</span>
                                    </div>
                                </p>

                                <div className={`container-input-range`}>
                                    <InputRange
                                        minValue={5000}
                                        maxValue={10000000}
                                        step={500}
                                        value={this.state.value}
                                        onChange={value => this.onChangeRange(value)}/>
                                </div>
                                <p className="title-left-right-input-range">
                                    <span className="left">5k</span>
                                    <span className="right">10m+</span>
                                </p>

                            </div>
                        </div>



                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(10, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(12)}
                                paddingButton={'54'}
                                // disabled={!this.state.size_of_fund.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step12.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step12)
import React from 'react'
import {getInterests} from '../../actions/UserSignup'
import bgImg from '../../images/main-bg.jpg'
import packageJSON from './../../../package.json'


export default class SignupInterests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interests: [],
      btnDisable: true,
      selectInterests: []
    }

    this.handleBoxChange = this.handleBoxChange.bind(this)
    this.nextStep = this.nextStep.bind(this)
  }

  componentDidMount() {
    getInterests().then((res)=>{
      this.setState({ interests: res.data })
    })
      .catch(error => {
        console.error('error', error)
      })
  }

  handleBoxChange(e) {
    const value = e.target.checked;
    const name = e.target.name;
      this.setState({interests: { ...this.state.interests, [name]: value }})
    }

  nextStep(){
    this.state.interests.forEach(function (interest) {
      if(interest.active) this.state.selectInterests.push(interest.id);
    }, this)

    // console.log(this.state.selectInterests)

    this.props.get('interests', this.state.selectInterests)
  }

  checkBtnDisable () {
    let check = true;
    this.state.interests.forEach(function (interest) {
      if(interest.active)check = false
    });
    this.setState({btnDisable: check})
  }

  selectInterest (item) {
    let index = this.state.interests.indexOf(item);
    this.state.interests[index].active = !this.state.interests[index].active;
    this.setState({interests:[...this.state.interests]});
    this.checkBtnDisable();
  }

  render() {
    //const {interests} = this.state

    var listInterests = this.state.interests.map(function(item) {
      return (
      <div key={item.id} className="col-4 one-card" onClick={()=>this.selectInterest(item)}>
        <div className={"border-card " + (item.active ? 'active' : '')}>
          <img src={packageJSON.proxy + '/media' + item.imageUrl} alt=""/>
          <div className="text-1">
            {item.title}
          </div>
          <div className="text-2">
            {item.description}
          </div>
        </div>

      </div>
      );
    }, this);



    return (


      <div className="interest-page">
        {/*<div className="header-title">*/}
          {/*The main idea of the project is to make the world a better place...*/}
        {/*</div>*/}
        <div className="card-interests">
          <div className="ci-title">Your interests</div>

          <div className="ci-list">
            <div className="row">
              {listInterests}
            </div>
          </div>

          <div className="block-btn">
            <button className="login-form__btn login-form__btn_join" disabled={this.state.btnDisable} onClick={this.nextStep}>Next</button>
          </div>

        </div>

      </div>

    )
  }
}
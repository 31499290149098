import React from 'react';
import './checkBox.css'

export default class CheckBox extends React.Component {
    render() {
        let {
            active
        } = this.props;

        let classList = active?'block-check-box-blue active':'block-check-box-blue';
        return (
            <div className={classList} onClick={this.props.onClick}>

            </div>
        );
    }
}


import React from 'react';
import './ProfileStyle.css'
import history from '../../../../history';

export default class ProfileToList extends React.Component {
    goTo = () => {
        let {
            id,
            type,
            isCompleted
        } = this.props;

        switch (type) {
            case 'investor': {
                if(isCompleted){
                    history.push(`/edit/investor/newsfeed`, {activeLeft:true,active:false});
                    window.scrollTo(0,0,);
                }else{
                    window.open(`/registration/investor/1?id=${id}`, '_blank')
                }
                break;
            }
            case 'startup': {
                if (isCompleted) {
                    history.push(`/edit/startup/${id}/dashboard`, {activeLeft: true, active: false});
                    window.scrollTo(0,0,);
                } else {
                    window.open(`/registration/startup/1?id=${id}`, '_blank')
                }
                break;
            }
            case 'fund' : {
                if (isCompleted) {
                    history.push(`/edit/fund/${id}/newsfeed`, {activeLeft: true, active: false});
                    window.scrollTo(0,0,);
                } else {
                    window.open(`/create/fund/1?id=${id}`, '_blank')
                }
                break;
            }
            case 'angel_network': {
                if (isCompleted) {
                    history.push(`/edit/angel_network/${id}/newsfeed`, {activeLeft: true, active: false});
                    window.scrollTo(0,0,);
                } else {
                    window.open(`/create/angel_network/1?id=${id}`, '_blank')
                }
                break;
            }
            case 'accelerator': {
                if (isCompleted) {
                    history.push(`/edit/accelerator/${id}/newsfeed`, {activeLeft: true, active: false});
                    window.scrollTo(0,0,);
                } else {
                    window.open(`/create/accelerator/1?id=${id}`, '_blank')
                }
                break;
            }
        }


    };

    render() {
        let {
            id,
            name,
            avatar,
            isCompleted
        } = this.props;


        return (
            <div className='block-profile-to-list' onClick={this.goTo}>
                {avatar &&
                <div className='profile-avatar' style={{backgroundImage: `url(${avatar})`, backgroundColor: '#fff'}}/>
                || <div className='profile-avatar' style={{backgroundColor: '#fff'}}/>
                }
                <div className='wrapper'>
                    {isCompleted && <div className='glue'/>}
                    <div id='name' className='profile-name'> {name} </div>
                    {!isCompleted && <div className='edit-title'>continue registration</div>}
                </div>
            </div>
        );
    }
}
import React from 'react'
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import './loginRegister.css'



export default class NeedRegister extends React.Component {

    constructor(props){
        super();
        this.state = {
            link: '/start_reg',
            text: 'register'
        };
    }

    componentDidMount () {
        let user = this.props.user || {}
        let link = this.state.link
        let text = this.state.text


        switch(this.props.roleNR){
            case 'investor':
                text = 'Create investor profile or continue registration'
                link = '/registration/investor/1'
                break;
        }

        this.setState({link: link, text: text})
    }


    render() {
        return (
            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login modal-register"
                    overlayClassName="Overlay"
                >
                    <div className="modal-login-block modal-register-block">
                        <div className="block-top">
                            <div className="sr-title">
                                Only registered investors can add startups to waitlists.
                            </div>

                            <img src="../../img/x.svg" alt="" className="close-icon" onClick={this.props.handleCloseModal}/>
                        </div>
                        <div className="sr-description">
                            <a href={this.state.link}>{this.state.text}</a>
                        </div>
                    </div>



                </ReactModal>
            </div>


        )
    }
}


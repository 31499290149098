import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'


class Step1 extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }

    update = (nextStep, onlyStep) => {
        this.setState({loading: true})
        this.props.updateExpert({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && nextProps.user.id) {

        }
    }

    componentWillUpdate(nextProps) {

    }

    componentDidMount() {

    }

    render() {
        let user = this.props.user || {}

        return (
            <React.Fragment>
                <div className="step-1">
                    <div className="wrap-white" style={{paddingBottom:'16px'}}>
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1">
                                &#8212; Welcome, {user.firstName}
                            </p>
                            <div className={'pg-left'}>
                                <p className="text-h5">
                                    There are a few steps to be taken before joining Rocket DAO - an international venture community and a blockchain platform where startups get expert support and connect with investors.
                                </p>
                                <p className="text-h6">
                                    Please note that registration takes about 10 minutes, you will be able to continue registration or edit your profile at any convenient time.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg container-bottom padding-reg block-gradient-gray-reg" style={{position: 'relative'}}>
                        <div className="block-list-cart-start-reg" style={{padding:'70px 0 20px 0'}}>
                            <div className="block-item-cart-start-reg" onClick={() => this.update(2, true)} style={{width:'auto'}}>
                                <div className='numb active'>01</div>
                                <p className='text-h4 test'>Register</p>
                                <div className={'btm-text'}>
                                    Explore our project and <br/>
                                    tell us your story
                                </div>
                            </div>
                            <div className={'div-flex'}>
                                <span></span>
                            </div>
                            <div className="block-item-cart-start-reg" style={{width:'auto'}}>
                                <div className='numb'>02</div>
                                <p className='text-h4 no-active'>Get tasks</p>
                                <div className={'btm-text no-active'}>
                                    As soon as you are registered we <br/>
                                    will suggest you fulfilling several <br/>
                                    tasks for a smooth start in the <br/>
                                    blockchain world
                                </div>
                            </div>
                            <div className={'div-flex'}>
                                <span></span>
                            </div>
                            <div className="block-item-cart-start-reg" style={{width:'auto'}}>
                                <div className='numb '>03</div>
                                <p className='text-h4 no-active'>Receive tokens</p>
                                <div className={'btm-text no-active'}>
                                    Receive tokens for the <br/>
                                    tasks completed, enjoy <br/>
                                    the journey!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Ok, got it!'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'80'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step1.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step1)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import icon1 from '../../../images/svg/Group6.svg'
import icon2 from '../../../images/svg/Group7.svg'
import icon3 from '../../../images/svg/Group.svg'




class Step6 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }

    update = (nextStep, onlyStep) => {
        let loading = nextStep == 3? 'loadingBack' : 'loading'
        this.setState({[loading]: true})
        this.props.updateExpert({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    render () {
        let {expert}  = this.state;

        return (
            <React.Fragment>

                <div className="block-gradient" style={{top: '280px'}}/>
                <div className="container-full-reg container-step-6 padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-1-reg"
                         style={{position: 'relative'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Promotional tasks
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20">
                            Here is the list of the activities you can perform. <br/>
                            For a quick start, we suggest you share a social media post right now and earn you first ROCK tokens!
                        </p>

                        <div className='bottom-content'>

                            <div className={'clearfix'}>
                                <div className="bc-1 new-no-display-down-620">
                                    <img src={icon1} alt="icon"/>
                                </div>
                                <div className="bc-2 new-width-100-down-620">
                                    <div className="bc21-title">
                                        <div className='bc-2-1'>
                                            Write an article
                                        </div>
                                        <div className='bc-2-2'>
                                            ROCKS
                                        </div>
                                    </div>
                                    <div className="bc21-description">
                                        <div className='bc-2-1'>
                                            Analysis of pros and cons of a specific methodology
                                        </div>
                                        <div className='bc-2-2'>
                                            100
                                        </div>
                                    </div>
                                    <div className="bc21-description">
                                        <div className='bc-2-1'>
                                            Comparison of Rocket DAO methodologies with alternative methods
                                        </div>
                                        <div className='bc-2-2'>
                                            150
                                        </div>
                                    </div>
                                    <div className="bc21-description">
                                        <div className='bc-2-1'>
                                            Descritption of a practical use-case of a specific methodology
                                        </div>
                                        <div className='bc-2-2'>
                                            200
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'clearfix'}>
                                <div className="bc-1 new-no-display-down-620">
                                    <img src={icon2} alt="icon"/>
                                </div>
                                <div className="bc-2 new-width-100-down-620">
                                    <div className="bc21-title">
                                        <div className='bc-2-1'>
                                            Social media post
                                        </div>
                                        <div className='bc-2-2'>

                                        </div>
                                    </div>
                                    <div className="bc21-description">
                                        <div className='bc-2-1'>
                                            Sharing information on you social media account will benefit you as well!
                                        </div>
                                        <div className='bc-2-2'>
                                            2
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'clearfix'}>
                                <div className="bc-1 new-no-display-down-620">
                                    <img src={icon3} alt="icon"/>
                                </div>
                                <div className="bc-2 new-width-100-down-620">
                                    <div className="bc21-title">
                                        <div className='bc-2-1'>
                                            Video
                                        </div>
                                        <div className='bc-2-2'>

                                        </div>
                                    </div>
                                    <div className="bc21-description">
                                        <div className='bc-2-1'>
                                            Video description of all advantages of using a specific Rocket DAO <br/>
                                            methodology in comparison with other approaches available.
                                        </div>
                                        <div className='bc-2-2'>
                                            250
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-one-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Nice, thank you. Skip for now.'}
                                EventOnClick={() => this.update(22)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={`It\'s great! Let\'s start with a post!`}
                                EventOnClick={() => this.update(19, true)}
                                paddingButton={'56'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step6.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step6)
import React from 'react';
import './style.css'
import CheckBox from '../checkBox/checkBox'

export default class ButtonIconImgCheckBox extends React.Component {

    render() {
        let {
            TitleButton,
            EventOnClick,
            icon,
            img,
            colorText,
            activeCheckBox,
            CheckBoxDisabled
        } = this.props;

        let classList =
            icon ?
                img ? 'block-button-icon-img-check-box icon img' : 'block-button-icon-img-check-box icon' :
                img ? 'block-button-icon-img-check-box img' : 'block-button-icon-img-check-box';

        return (
            <div className={classList} onClick={EventOnClick}>
                <div className="block-left-button-icon-img-check-box">
                    {icon && <img className="icon-button" src={icon} alt=""/>}
                    {img && <img className="img-button" src={img} alt=""/>}
                    <p className="title-button-icon-img-check-box" style={{color: colorText}}>{TitleButton}</p>
                </div>
                <div className="block-right-button-icon-img-check-box">
                    {CheckBoxDisabled != true &&
                    <CheckBox
                        active={activeCheckBox}
                    />
                    }

                </div>
            </div>
        );
    }
}


import React, {useEffect, useState} from 'react';
import Input from "../../controls3.0/input/Input";
import './style.scss'


const  NameBlock = ({firstName, lastName, parentCallback}) => {
    const [errorsIn, setErrorsIn] = useState({});
    // const [_firstName, setFirstName] = useState(firstName);
    // const [_lastName, setLastName] = useState(lastName);

    // useEffect(() => {
    //     parentCallback({
    //         firstName,
    //         lastName
    //     })
    // }, [firstName, lastName]);

    useEffect(() => {
        setErrorsIn({});
    }, [firstName, lastName]);

    const onChange = (e) => {
        console.log(e);
            let name = e.target.name,
                value = e.target.value.cleanWhitespace(),
                _errors = errorsIn,
                required = true,
                limit = 20;

            if (value.length) {
                if (value.length > limit) {
                    value = value.slice(0, limit);
                    _errors[name] = 'max length ' + limit + ' symbols';
                } else {
                    delete _errors[name];
                }
            }
            if (required && !value) {
                _errors[name] = 'Field is required'
            }

            setErrorsIn({..._errors});
            parentCallback({
                [name]: value
            })
        };


    return (
        <React.Fragment>
                <div className='row-b name-block-container'>
                    <div className='col-12 col-sm-6 user-block-col'>
                        <p className="name-block-title">
                            Name
                        </p>
                        <Input
                            type={'text'}
                            name={'firstName'}
                            value={firstName}
                            onChange={(e) => onChange(e)}
                            autocomplete={false}
                            placeholder={'Not more than 20 symbols'}
                            error={errorsIn.firstName}
                        />
                    </div>
                    <div className='col-12 col-sm-6 user-block-col'>
                        <p className="name-block-title">
                            Surname
                        </p>
                        <Input
                            type={'text'}
                            name={'lastName'}
                            autocomplete={false}
                            value={lastName}
                            onChange={onChange}
                            placeholder={'Not more than 20 symbols'}
                            error={errorsIn.lastName}
                        />
                    </div>
                </div>

        </React.Fragment>
    )
};

export default NameBlock;
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Main from './main'

const Routes = (props1) => (
    <Switch>
        <Route exact path={'/edit/investor/newsfeed'} render={ (props) => ( <Main {...props} {...props1} page='newsfeed'/>)} />
        <Route exact path={'/edit/investor/waitlist'} render={ (props) => ( <Main {...props} {...props1} page='waitlist'/>)} />
        {/*<Route exact path={'/edit/expert/achievement/:id'} render={ (props) => ( <Main {...props} {...props1} page='achievements'/>)} />*/}
        <Route exact path={'/edit/expert/parameters'} render={ (props) => ( <Main {...props} {...props1} page='parameters'/>)} />

        <Route exact path={'/edit/user/profile'} render={ (props) => ( <Main {...props} {...props1} type='user' page='profile'/>)} />
        <Route exact path={'/edit/user/account'} render={ (props) => ( <Main {...props} {...props1} type='user' page='account'/>)} />

        <Route exact path={'/edit/:type/:id/:page'} render={ (props) => ( <Main {...props} {...props1}/>)} />
        <Route exact path={'/edit/:type/:id'} render={ (props) => ( <Main {...props} {...props1}/>)} />
        <Route exact path={'/edit/:type'} render={ (props) => ( <Main {...props} {...props1}/>)} />
    </Switch>
);

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";


import {updateFund} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
// import ButtonGray from "../../controls3.0/button/ButtonGray";
import {regexUrl} from "../../../utils/RegExp";
// import Input from "../../controls3.0/input/Input";
import Group21 from "../../../images/svg/Group21.png";


class Step17 extends React.Component {
    constructor(props) {
        super();
        let fund = props.fund,
            investor = fund.investor || {};
        if(!investor.team) investor.team = fund.team || []
        this.state = {
            fund: fund,
            investor: investor,
            errors: {}
        }
    }

    componentDidMount () {
        this.checkExistLink()
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if (regexUrl.test(value)) {
            this.setState({errors: {...this.state.errors, contact : ''}})
        }

        this.setState({ [name]: value })
    }

    onChange2 = (e, key) => {
        let team = this.state.investor.team;
        team[key] = e.target.value;
        this.setState({ investor: {...this.state.investor, team: team}})
    }

    add = () => {
        let contact = this.state.contact;
        let team = this.state.investor.team;

        if(team.includes(contact)) return

        if (regexUrl.test(contact)) {
            this.setState({errors: {...this.state.errors, contact : ''}})
            this.setState({ investor: {...this.state.investor, team: [...this.state.investor.team, contact] }}, () => {
                this.setState ({contact: ''})
            })
        } else {
            this.setState({errors: {...this.state.errors, contact : 'Wrong link'}})
        }
    }

    checkExistLink = () => {
        let fund = this.props.fund,
            team = this.state.investor.team;
        if(fund.mediumLink && !team.includes(fund.mediumLink)) team.unshift(fund.mediumLink)
        if(fund.twitterLink && !team.includes(fund.twitterLink)) team.unshift(fund.twitterLink)
        if(fund.telegramLink && !team.includes(fund.telegramLink)) team.unshift(fund.telegramLink)
        if(fund.linkedInLink && !team.includes(fund.linkedInLink)) team.unshift(fund.linkedInLink)
        if(fund.facebookLink && !team.includes(fund.facebookLink)) team.unshift(fund.facebookLink)

        this.setState({ investor: {...this.state.investor, team: team}})
    }

    getSocialIcon = link => {
        let iconClass = 'far fa-link'
        if (/facebook/.test(link)) iconClass = 'fab fa-facebook'
        if (/linkedin/.test(link)) iconClass = 'fab fa-linkedin-in'
        if (/telegram/.test(link)) iconClass = 'fab fa-telegram'
        if (/twitter/.test(link)) iconClass = 'fab fa-twitter'
        return iconClass
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        const fundId = this.props.fund.id;
        this.props.updateFund(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {fund, investor, errors} = this.state


        let team = investor.team.map((item, key) => {
            return (
                <span className='item-email-sent'>{item.email}</span>
            )
        })

        return (
            <React.Fragment>
                <div className="step-1 is-startup padding-reg">

                    <div className="container-full-reg">
                        <div className="container-step-1-reg">
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                {(this.state.investor.team.length>1)?'Invitations are successfully sent!':'Invitation is successfully sent!' }
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30" style={{maxWidth:'490px'}}>
                                You will be notified if your friends register on the platform via your invitation link.
                            </p>


                            <div className='step-21-btm clearfix' style={{padding:'0 0 250px 0'}}>
                                <div className='s21-left inv-succ no-float-620'>
                                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15 mb" style={{width:'110%'}}>
                                        {(this.state.investor.team.length>1)?'We have sent invitations to these emails:':'We have sent invitation to these email:' }
                                    </p>
                                    <div>
                                        {team}
                                    </div>


                                </div>
                                <div className='s21-right inv-succ no-float-620'>
                                    <img src={Group21} alt="" className=""/>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">

                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Continue'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'56'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund
})

Step17.propTypes = {
    updateFund: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateFund})(Step17)
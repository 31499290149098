import React from 'react';

import '../../controls3.0/text/style.css'

export default class ViewButton extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <p className="text-h1">H1 -- Project evaluation</p>
                <p className="text-h2">H2 -- Let the community know your interestes. Add a short professional status to your profie.</p>
                <p className="text-h3">H3 -- Language</p>
                <p className="text-h4">H4 -- Experts</p>
                <p className="text-h5">H5 -- A few steps are to be taken before joining Rocket DAO Expert — a decentralized expert community boosting tokenized innovations all around the world.</p>
                <p className="text-h6">H6 -- Become its active participant and pass the registration procedure right now!</p>
                <p className="text-body">Body -- Note that every user starts his/her way on the platfrom as a candidate, only after having passed through some tutorials and having completed a test task he/she will be nominated an expert role.</p>
                <p className="text-body-2">Body 2 -- Give me some details</p>
                <p className="text-button">Button -- Register with Twitter</p>
                <p className="text-button-2">Button 2 -- Data verification <br/>
                    Project evaluation</p>
                <p className="text-button-3">Button 3 -- Data verification <br/>
                    Project evaluation</p>
                <p className="text-sub-title">SubTitle -- An expert may either:<br/>
                    - verify the accuracy of projects’ data provided; <br/>
                    - or evaluate projects using in-house methodologies and give relevant recommendations.
                </p>
                <p className="text-caption">Caption -- Not less than 180*180 pixels</p>
                <p className="text-caption-2">Caption 2 -- Links to your articles, as well as to documents containing your articles, scientific researches and other papers.</p>
            </div>


        );
    }
}

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import CheckBox from "../../controls3.0/checkBox/checkBox";
import ListTitlelMore from "../../controls3.0/listTitleMore/ListTitlelMore";
import TextArea from "../../v3/Textarea";


class Step14 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        if (!angel.previousInvestment) angel.previousInvestment = []
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            newPI: {
                title: '',
                position: '',
                size: '',
                exite: false
            }
        }
    }


    componentDidMount() {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();

        if (name === 'size') {
            value = value.replace(/ /g, '').replace(/\D/, "");
            if(value >= 0 && value <= 9999999999)
                this.setState({newPI: {...this.state.newPI, [name]: value}})
        } else {
            this.setState({newPI: {...this.state.newPI, [name]: value}})
        }

        if (value.length > 200 && name == 'position') {
            this.setState({errors: {...this.state.errors, [name] : 'The text is too long, 200 symbols max'}});
            value = value.slice(0, 200);
        } else {
            this.setState({errors: {...this.state.errors, [name] : ''}});
        }


    }

    onCheck = () => {
        this.setState({newPI: {...this.state.newPI, ['exite']: !this.state.newPI.exite}})
    }

    add = () => {
        let newPI = this.state.newPI,
            previousInvestment = this.state.angel.previousInvestment;
        if (newPI.title && newPI.position && newPI.size) {
            previousInvestment.push(newPI)
            this.setState({angel: {...this.state.angel, previousInvestment: previousInvestment}}, () => {
                this.setState({
                    newPI: {
                        title: '',
                        position: '',
                        size: '',
                        exite: false
                    }
                })
            })
        }
    }

    remove = (title, key) => {
        let previousInvestment = this.state.angel.previousInvestment
        previousInvestment.splice(key, 1)
        this.setState({angel: {...this.state.angel, previousInvestment: previousInvestment}})
    }


    update = (nextStep, onlyStep) => {
        let previousInvestment = this.state.angel.previousInvestment,
            obj = {previousInvestment: previousInvestment}
        if (onlyStep) {
            obj = {}
        }

        obj.event_type = 'network_success_13';
        if(nextStep === 14 && onlyStep){
            obj.event_data = {
                skip: true
            }
        }

        if (!onlyStep) this.setState({loading: true})
        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {angel, newPI, errors} = this.state;

        let list = angel.previousInvestment.map((item, key) => {
            return (
                {title: item.title}
            )
        });

        return (
            <React.Fragment>
                <div className="step-1 is-startup">


                    <div className="wrap-white" style={{padding:'0 0 200px 0'}}>
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                Previous investments of the network
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-50">
                                Highlight the most significant investments you have already made.
                            </p>

                            <div className="block-previosu-add-startup">
                                <div className="block-left-previosu-add-startup">
                                    <div className="shell-input-kyc">
                                        <p className="title-input-kyc">Startup name</p>
                                        <Input
                                            type={'text'}
                                            name={'title'}
                                            value={newPI.title}
                                            onChange={this.onChange}
                                            placeholder={'Ex.: Wix'}
                                            error={errors.title}
                                        />
                                    </div>


                                    <div className="shell-text-area-kyc previous-investments-textarea">
                                        <p className="title-input-kyc">Terms of the deal</p>
                                        <TextArea name={'position'} val={newPI.position}
                                                  Style={{height: '103px'}}
                                                  placeholder={'Lead angel, Funding Round, etc. 200 symbols'}
                                                  onChange={this.onChange}
                                                  error={errors.position}
                                        />

                                    </div>


                                    <div className="block-size-previosu-add-startup">
                                        <div className="block-left-size-previosu-add-startup">
                                            <div className="shell-input-kyc">
                                                <p className="title-input-kyc">Investments size (USD)</p>
                                                {/*<div className="block-new-input">*/}
                                                {/*<NumberFormat suffix={' USD'}*/}
                                                {/*name={'size'}*/}
                                                {/*value={newPI.size}*/}
                                                {/*onValueChange={this.onChangeSize}*/}
                                                {/*placeholder={'Ex.: Wix'}*/}
                                                {/*/>*/}
                                                {/*</div>*/}

                                                <Input
                                                    type={'text'}
                                                    name={'size'}
                                                    value={Number(newPI.size).moneyFormat()}
                                                    onChange={this.onChange}
                                                    placeholder={''}
                                                    error={errors.size}
                                                />
                                            </div>


                                        </div>
                                        <div className="block-right-size-previosu-add-startup shell-input-kyc">
                                            <p className="title-input-kyc margin-0">Exit</p>
                                            <div className="block-yes-no-exit-size-previosu-add-startup"
                                                 style={{margin: '15px 0 0 0'}}>
                                                <div className="block-yes-exit-size-previosu-add-startup"
                                                     onClick={() => this.onCheck()}>
                                                    <CheckBox
                                                        onClick={() => {
                                                        }}
                                                        active={newPI.exite}
                                                    />
                                                    <p className="title-previosu-add-startup">Yes</p>
                                                </div>
                                                <div className="block-no-exit-size-previosu-add-startup"
                                                     onClick={() => this.onCheck()}>
                                                    <CheckBox
                                                        onClick={() => {
                                                        }}
                                                        active={!newPI.exite}
                                                    />
                                                    <p className="title-previosu-add-startup">No</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <ButtonBlue
                                        TitleButton={'Add'}
                                        EventOnClick={() => this.add()}
                                        paddingButton={'33'}
                                        disabled={!this.state.newPI.title || !this.state.newPI.position || !this.state.newPI.size}
                                        loader={false}
                                    />
                                </div>
                                <div className="block-right-previosu-add-startup">
                                    <ListTitlelMore
                                        data={list}
                                        onChange={(e) => console.log(e)}
                                        viewCountItem={6}
                                        removeFromList={this.remove}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign: 'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(12, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(14)}
                                paddingButton={'54'}
                                disabled={!this.state.angel.previousInvestment.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step14.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step14)
import axios from 'axios'

export const getComments = (type, id) => {
    return axios.get(`/v2/comments/${type}/${id}`);
}

export const likeUp = (id, data) => {
    return axios.post(`/v2/comments/${id}/like`, data)
}

export const sendComment = (type, id, data) => {
    return axios.post(`/v2/comments/${type}/${id}`, data)
}

export const deleteComment = (id) => {
    return axios.delete(`/v2/comments/${id}`)
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import FooterImg from "../../../images/svg/RocketDAO-finish.svg";



class Step18 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({ investor: {...this.state.investor, [name]: value} })
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="container-step-1-reg"
                     style={{position: 'relative', margin: '0 0 0 auto'}}>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                        We welcome you to Rocket DAO!
                    </p>
                    <p className="title-top-page-reg-expert text-h6 left-text shell-margin-bottom-15">
                        We have been waiting for you, {user.firstName} <br/>
                        And here your journey begins!
                    </p>
                    <p className="title-top-page-reg-expert text-body left-text shell-margin-bottom-60">
                        It is a pleasure for us to welcome you on Rocket DAO.
                        In case of any queries regarding the platform's tools please feel
                        free to contact us and we will gladly assist you.
                        <br/><br/>
                        Best regards, Rocket DAO team.
                    </p>

                    <div className="shell-button-reg-expert">
                        <div className="block-button-big-reg-expert" style={{width: '240px'}}
                             onClick={() => window.location.href = localStorage.referer_startup || '/'}>
                            <p className="title">Go to rocketdao.io</p>
                        </div>
                    </div>
                </div>
                <div className={'block-img-footer'} >
                    <img src={FooterImg} style={{position:'relative'}} alt="footer_img"/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step18.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step18)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";
import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {getLastProject, updateProject} from "../../../actions/Project";
import CheckBox from "../../controls3.0/checkBox/checkBox";


class Step10 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        investor.noTurnover = user.noTurnover;

        this.state = {
            user: user,
            investor: investor,
            errors: {},
            project: props.project || {},
            round:''
        }
    }


    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }


    set = round => {
        let currentRound = this.state.round;
        if (currentRound === round) round = '';
        this.setState( {round: round})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) {
            if (!obj.noTurnover) obj.turnover = this.state.value;
        }

        obj.event_type = 'fund_turnover_stage_10';

        this.setState({loading: true});

        const fundId = this.state.user.id;
        this.props.updateAccelerator(obj, fundId , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {


        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Startup investment round
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0">
                                How mature should the projects br prior applying to your programs?
                            </p>


                            <div
                                className="block-list-cart-round-reg-startup block-gradient-gray-reg padding-top-35 margin-top-50">
                                <div
                                    className={this.state.round === 'Pre-Seed' ? 'block-item-cart-round-reg-startup active block-item-cart-round-reg-accelerator' : 'block-item-cart-round-reg-startup block-item-cart-round-reg-accelerator'}
                                    onClick={() => this.set('Pre-Seed')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={this.state.round === 'Pre-Seed' ? true : false}
                                        />
                                    </div>
                                    <div className="accelerator-main-title color-rgba-08">
                                        Pre-Seed
                                    </div>
                                    <div className="description-accelerator-cart color-rgba-08">
                                        Founders have a clear understanding of the project's mission, market structure
                                        and its needs, of the technologies most suitable for their case.
                                        <br/><br/>
                                        The team has a coherent development plan.
                                    </div>

                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {this.state.round === 'Pre-Seed' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={this.state.round === 'Seed' ? 'block-item-cart-round-reg-startup active block-item-cart-round-reg-accelerator' : 'block-item-cart-round-reg-startup block-item-cart-round-reg-accelerator'}
                                    onClick={() => this.set('Seed')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={this.state.round === 'Seed' ? true : false}
                                        />
                                    </div>
                                    <div className="accelerator-main-title color-rgba-08">
                                        Seed
                                    </div>
                                    <div className="description-accelerator-cart color-rgba-08">
                                        Business model hypotheses have been proved already
                                        <br/><br/>
                                        The MVP is further enhanced  and turned into a serial product.
                                        <br/><br/>
                                        Financing is needed for cost recovery.
                                    </div>

                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {this.state.round === 'Seed' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={this.state.round === 'Series A' ? 'block-item-cart-round-reg-startup active block-item-cart-round-reg-accelerator' : 'block-item-cart-round-reg-startup block-item-cart-round-reg-accelerator'}
                                    onClick={() => this.set('Series A')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={this.state.round === 'Series A' ? true : false}
                                        />
                                    </div>

                                    <div className="accelerator-main-title color-rgba-08">
                                        Series A
                                    </div>
                                    <div className="description-accelerator-cart color-rgba-08">
                                        Investments are needed for advertising, attracting the target audience.
                                        <br/><br/>
                                        This is the period when the project starts global scaling of their mass market product.
                                    </div>

                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {this.state.round === 'Series A' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={this.state.round === 'Series B' ? 'block-item-cart-round-reg-startup active block-item-cart-round-reg-accelerator' : 'block-item-cart-round-reg-startup block-item-cart-round-reg-accelerator'}
                                    onClick={() => this.set('Series B')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={this.state.round === 'Series B' ? true : false}
                                        />
                                    </div>

                                    <div className="accelerator-main-title color-rgba-08">
                                        Series B
                                    </div>
                                    <div className="description-accelerator-cart color-rgba-08">
                                        At this step, the team needs extra financing for global expansion.
                                        <br/><br/>
                                        Series B supposes the business model of the project to be adapted to different geographical regions.
                                    </div>

                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {this.state.round === 'Series B' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className='container-inline-checkbox margin-top-30 color-rgba-08' onClick={() => this.set('NoRound')}>
                                    <CheckBox
                                        onClick={() => {}}
                                        active={this.state.round === 'NoRound'}
                                    />
                                    Having traction isn't necessary
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(9, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(11)}
                                paddingButton={'56'}
                                disabled={!this.state.round}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step10.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step10)
import React from 'react';
import packageJSON from './../../../package.json'
import defaultAvatar from '../../images/default_avatar.png'

export default class AvatarUser extends React.Component {
    render() {
        let {
            imgId,
        } = this.props;

        return (
            imgId ?
            <img src={ `${packageJSON.proxy}/file/${imgId}/view`} className={this.props.className}></img>
            :
            <img src={defaultAvatar} className={this.props.className}></img>
        );
    }
}
import React from 'react';
import Menu from './menu'

import StartupMenuProfile from './startupMenuProfile/StartupMenuProfile'
import InvestorMenuProfile from './investorMenuProfile/InvestorMenuProfile'
import FundMenuProfile from './fundMenuProfile/FundMenuProfile'
import EvalParamRoadMap from './expertMenuProfile/EvalParamRoadMap'
import ExpertTaskList from './expertMenuProfile/ExpertTaskList'
import EditProfileList from './editProfileList/EditProfileList'
export default class startupProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        return (
            <div>

                <StartupMenuProfile
                    name={'test'}
                    avatar={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd2eJVSAd0BO0iOipuHg1wY0gtcf828hnD1d4_CFEucv17YLs'}
                />
            </div>
        );
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
// import {regexUrl} from "../../../utils/RegExp";
import Input from "../../controls3.0/input/Input";
// import Dropzone from "react-dropzone";
import axios from "axios";

class Step17 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator,
            errors: {},
            name_of_the_document: '',
            comments_text: '',
            selectedFile: [],
            proofSent: props.accelerator.proofSent,
            proof: {},
            previews: [],
        }
    }

    componentDidMount() {
        const acceleratorId = this.state.accelerator.id;
        if (this.state.proofSent) {
            this.update(18, true)
        }
        // axios
        //     .get(`/v2/accelerators/${acceleratorId}/proof`)
        //     .then(res => {
        //         this.setState({proof: res.data})
        //     })
        //     .catch(e => {
        //         console.log(e);
        //     })
    }

    onChangeTextField = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(name === 'name_of_the_document'){
            if(value.length > 100){
                value = value.slice(0, 100);
                this.state.errors[name] = 'The text is too long. 100 symbols max'
            }  else {
                delete this.state.errors[name];
            }
        }
        if(name === 'comments_text'){
            if(value.length > 1000){
                value = value.slice(0, 1000);
                this.state.errors[name] = 'The text is too long. 1000 symbols max'
            }  else {
                delete this.state.errors[name];
            }
        }
        this.setState({[name]: value})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.accelerator;
        obj.event_type = 'accelerator_ownership_16';

        const acceleratorId = this.state.accelerator.id;
        if (onlyStep) {
            obj = {}
            this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
                .catch(err => {
                    this.setState({loading: false})
                })
        }

        if (!onlyStep) {
            this.setState({loading: true})
            /*
                @File file
                @String comments
                @String docName
            */
            let data = new FormData();
            data.append("docName", this.state.name_of_the_document);
            data.append("comments", this.state.comments_text);
            data.append("event_type", 'accelerator_ownership_16');

            this.state.selectedFile.forEach(file => data.append("file", file, file.name));

            axios
                .post(`/v2/accelerator/${acceleratorId}/proof`, data)
                .then(res => {
                    this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
                        .catch(err => {
                            this.setState({loading: false})
                        })
                })
                .catch(e => {
                    this.props.updateAccelerator(obj, acceleratorId, true, nextStep)
                        .catch(err => {
                            this.setState({loading: false})
                        })
                })
        }
    }

    handleselectedFile = event => {

        const files = [...this.state.selectedFile, ...Array.from(event.target.files)].slice(0, 5);

        const THIS = this;
        let previews = [...this.state.previews];

        for (let i = 0; i < files.length && i < 5; i++) {
            let file = files[i];
            //Only pics
            if (!file.type.match('image') && !file.type === 'application/pdf') continue;

            let picReader = new FileReader();
            picReader.addEventListener("load", function (event) {
                const picFile = event.target;
                if (file.type === 'application/pdf') {
                    previews[i] = 'https://png.pngtree.com/svg/20170511/pdf_355930.png'
                } else {
                    previews[i] = picFile.result;
                }

                THIS.setState({previews: previews});
                THIS.setState({
                    selectedFile: files,
                })
            });
            picReader.readAsDataURL(file);
        }
    }

    handleClick = (e) => {
        this.inputElement.click();
    }

    removeFile = (index) => {
        let n = this.state.selectedFile;
        let p = this.state.previews;
        n.splice(index, 1);
        p.splice(index, 1);
        this.setState({selectedFile: n, previews: p})
    };

    validate = () => {
        return this.state.name_of_the_document.length
            && this.state.comments_text.length
            && this.state.selectedFile.length
    }

    render() {
        let {accelerator, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg container-step-16" style={{position: 'relative'}}>
                            <div className="container-step-1-reg" style={{maxWidth: '670px', margin: '0 auto'}}>
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-25">
                                    Proof of ownership
                                </p>
                                <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-25">
                                    <span className='font-weight-400'>Please provide here any document which would be a proof that you are the owner of
                                    the accelerator: for example, statement from the local authorities about registration of
                                        the legal entity, Ownership agreement, etc.</span>
                                    <br/><br/>
                                    <b>Note! This information will never be public.</b>
                                    <br/><br/>
                                    <span className='font-weight-400'>Verification of the documents will take up to 5 business days.
                                        As soon as the proofs provided are verified you will be notified.</span>
                                </p>


                                {!this.state.proofSent && <div className="block-form-proof-owner">
                                    <div className="block-left-cart-kyc-reg-expert no-float-620"
                                         style={{width: 'calc(100% - 240px)'}}>
                                        <div className="block-line-left-cart-kyc-reg-expert">
                                            <div className="shell-input-kyc">
                                                <p className="title-input-kyc">Name of the document</p>
                                                <Input
                                                    type={'text'}
                                                    name={'name_of_the_document'}
                                                    value={this.state.name_of_the_document}
                                                    onChange={this.onChangeTextField}
                                                    error={this.state.errors.name_of_the_document}
                                                    placeholder={'Text'}
                                                />
                                            </div>
                                            <div className="shell-input-kyc">
                                                <p className="title-input-kyc">Comments</p>
                                                <Input
                                                    type={'text'}
                                                    name={'comments_text'}
                                                    value={this.state.comments_text}
                                                    onChange={this.onChangeTextField}
                                                    placeholder={'Text'}
                                                    error={this.state.errors.comments_text}
                                                />
                                            </div>
                                        </div>
                                        <div className='proof-descr-text color-rgba-88'>
                                            * In case you skip this step now, your accelerator will be marked as 'Not verified yet'.
                                            You will be able to upload the documents in your personal profile ar any convenient time.
                                        </div>
                                    </div>
                                    <div className="block-right-cart-kyc-reg-expert no-float-620">
                                        <div className="block-load-img-kyc">
                                            <p className="title-input-kyc">Document upload</p>
                                            <p className="description-input-kyc">
                                                Should be a clear color scan with all <br/>
                                                text and photo cleary visible
                                            </p>

                                            <div className="block-list-img-load-kyc">
                                                {
                                                    this.state.selectedFile.map((item, index) => {
                                                        return (
                                                            <div className="block-img-load-kyc" key={index}
                                                                 style={{background: '#dfdfdf'}}
                                                                 onClick={() => this.removeFile(index)}>
                                                                <i className="fal fa-times"></i>
                                                                {
                                                                    this.state.previews[index] &&
                                                                    <img src={this.state.previews[index]} style={
                                                                        {
                                                                            'position': 'absolute',
                                                                            'zIndex': 222,
                                                                            'left': '0',
                                                                            'width':
                                                                                '100%',
                                                                            'height':
                                                                                '100%'
                                                                        }
                                                                    }/>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }</div>
                                            <input
                                                ref={input => this.inputElement = input}
                                                style={{display: "none"}}
                                                type="file" multiple={true} name="files"
                                                onChange={this.handleselectedFile}/>

                                            <ButtonGray
                                                TitleButton={'Upload'}
                                                EventOnClick={this.handleClick}
                                                paddingButton={'0'}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                </div>}

                                {this.state.proofSent &&
                                <div>

                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {!this.state.proofSent &&
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-button-right">
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Skip this step'}
                                    EventOnClick={() => this.update(18, true)}
                                    paddingButton={'24'}
                                    disabled={false}
                                />
                            </div>
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(18)}
                                paddingButton={'54'}
                                loader={this.state.loading}
                                disabled={!this.validate() || Object.keys(this.state.errors).length}
                            />
                        </div>
                    </div>
                </div>

                }

                {this.state.proofSent &&
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(18, true)}
                                paddingButton={'54'}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

                }


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step17.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step17)
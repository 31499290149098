import React, { useEffect, useState } from "react";
import axios from "axios";

import { useHistory } from "react-router";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import ReactModal from "react-modal";

import Loader from "../../../test-controls/preloader/preloader";
import config from "../../../../config";

import Input from "../../../controls3.0/input/Input";
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";

const InvestClubList = () => {
  const [partners, setPartners] = useState([]);
  const [lastUserActivity, setLastUserActivity] = useState(Date.now());
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [totalPartners, setTotalPartners] = useState(0);
  const [totalRegisterStartups, setTotalRegisterStartups] = useState(0);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    axios.get("/v2/ivestclub/partners").then((data) => {
      setIsLoading(false);
      setPartners(data.data.users || []);
      setLastUserActivity(new Date(data.data.lastActivity).getTime());
    });
  }, []);

  useEffect(() => {
    let p = 0;
    let s = 0;
    partners.forEach((val) => {
      if (!val.isRoleFinished) {
        p++;
      }

      val.investClubProjects.forEach((project) => {
        if (project.project.isFinished) {
          s++;
        }
      });
    });
    setTotalPartners(p);
    setTotalRegisterStartups(s);
  }, [partners]);

  const onPartnerAdd = async () => {
    const regex = /user\/(.+)/;
    const found = value.match(regex);
    if (!found) {
      return NotificationManager.error(
        "URL not valid. Please provide link as https://rocketdao.io/user/12345"
      );
    }
    setIsLoading(true);

    try {
      const data = await axios.put(`/v2/ivestclub/partners/${found[1]}`);
      NotificationManager.info("Partner added");
      setPartners(data.data.users || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onPartnerAdd();
    }
  };

  const copyLink = (user) => {
    const link = `https://rocketdao.io/registration/startup/1?ref_b=${user.referralHash}`;

    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    NotificationManager.info(`Link copied to clipboard ${link}`);
  };

  const onModalClick = async () => {
    setIsLoading(true);
    const id = userToDelete.id;
    const disable = userToDelete.isRoleFinished;
    setUserToDelete(null);

    try {
      const data = await axios.post(
        `/v2/ivestclub/partners/${id}?disable=${!disable}`
      );
      NotificationManager.info("Partner updated");
      setPartners(data.data.users || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPartnerAvatarClasses = (partner) => {
    let isHaveActivity = false;

    for (let p of partner.investClubProjects) {
      console.log(p.project);

      if (
        new Date(p.project.createdAt) > lastUserActivity ||
        (p.project.isFinished &&
          new Date(p.project.finishedAt) > lastUserActivity)
      ) {
        isHaveActivity = true;
        break;
      }
    }

    if (isHaveActivity) {
      return "with-green-dot";
    }
    return "";
  };

  return (
    <div>
      <div
        className="row-m0 invest-club-paper"
        style={{ marginBottom: "20px" }}
      >
        <span>Total partners - {totalPartners}</span> &nbsp; &nbsp;&nbsp;&nbsp;
        <span>Total startups registered - {totalRegisterStartups}</span>
      </div>
      <div className="investClubInput">
        <Input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={onEnterPress}
          placeholder={"Enter a link to add new partner"}
        />
        <ButtonGreen
          TitleButton={"Add partner"}
          onMouseEnter={null}
          onMouseLeave={null}
          EventOnClick={onPartnerAdd}
        />
      </div>

      {!partners.length ? (
        <h1 className="margin-20">
          No partners found. Start by adding new partner
        </h1>
      ) : (
        <article className="row-m0 invest-club-paper invest-club-paper-header">
          <div className="col-lg-3 p-0  offset-lg-1">Name</div>
          <div className="col-lg-3 p-0 ">Region</div>
          <div className="col-lg-2 p-0">Startups invited</div>
          <div className="col-lg-2 p-0">Startups registered</div>
        </article>
      )}

      {partners.map((user, key) => (
        <article
          className="row-m0 shell-header-sticky-role invest-club-paper like-a-link"
          key={key}
          onClick={() => {
            history.push(`/investclub/partner/${user.id}`);
          }}
        >
          <div className="col-lg-1 p-0">
            <div
              className={`block-avatar url ${getPartnerAvatarClasses(user)}`}
              style={{
                backgroundImage: `url(${config.proxy}/file/${user.logoFileId}/view`,
              }}
            ></div>
          </div>
          <div className="col-lg-3 p-0">
            {user.firstName} {user.lastName}
            {!user.isRoleFinished && (
              <div
                className="invest-club-copy-link"
                onClick={(e) => {
                  e.stopPropagation();
                  copyLink(user);
                }}
              >
                Copy link
              </div>
            )}
          </div>
          <div className="col-lg-3 p-0">{user.country || "Not defined"}</div>
          <div className="col-lg-2 p-0">
            {+user.startupsInvited + user.startupsRegistered}
          </div>
          <div className="col-lg-2 p-0">{user.startupsRegistered}</div>
          <div className="col-lg-1 p-0" style={{ textAlign: "end" }}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                setUserToDelete(user);
              }}
            >
              {user.isRoleFinished ? (
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.8"
                    d="M10.2733 6.91211L1.06833 12.2266L1.06833 1.59761L10.2733 6.91211Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.8"
                    d="M1.06833 0L1.06833 12.2266Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    opacity="0.8"
                    d="M6.91211 0L6.91211 12.2266Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              )}
            </span>
          </div>
        </article>
      ))}
      <NotificationContainer />
      <Loader loaded={!isLoading} />
      <ReactModal
        ariaHideApp={false}
        isOpen={Boolean(userToDelete)}
        onRequestClose={() => setUserToDelete(null)}
        className="Modal  modal-invest-club"
        overlayClassName="Overlay"
      >
        {userToDelete && (
          <React.Fragment>
            <div className="close-title" onClick={() => setUserToDelete(null)}>
              <div
                className="close"
                style={{
                  opacity: "1",
                  left: "auto",
                  right: "35px",
                  top: "35px",
                }}
              />
            </div>

            <div
              className="invest-club-block-avatar"
              style={{
                backgroundImage: `url(${config.proxy}/file/${userToDelete.logoFileId}/view`,
              }}
            ></div>
            <h5 className="margin-15">
              {userToDelete.firstName} {userToDelete.lastName}
            </h5>
            <h3 className="margin-15">
              {userToDelete.isRoleFinished
                ? "Make this partner active again?"
                : "Pause the activity of this partner?"}
            </h3>
            <p className="invest-club-modal-description">
              {userToDelete.isRoleFinished
                ? "Referral link will again be active and ready for new startups. All stats will be saved."
                : "Referral link will no longer be active. All stats will be saved."}
            </p>
            <div className="row-m0">
              <div className="col-lg-6">
                <ButtonBlue
                  className="margin-15"
                  TitleButton={"Cancel"}
                  onMouseEnter={null}
                  onMouseLeave={null}
                  EventOnClick={() => setUserToDelete(null)}
                />
              </div>
              <div className="col-lg-6">
                <ButtonGreen
                  className={`margin-15 ${
                    !userToDelete.isRoleFinished ? "red-background" : ""
                  }`}
                  TitleButton={`${
                    userToDelete.isRoleFinished ? "Re-start" : "Pause"
                  }`}
                  onMouseEnter={null}
                  onMouseLeave={null}
                  EventOnClick={onModalClick}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </ReactModal>
    </div>
  );
};

export default InvestClubList;

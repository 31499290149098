import React from 'react';
import SOC from '../controls/SocialButtonLink/Slack'
import SOCB from '../controls/SocialButtonLink/BitCoin'
import SOCT from '../controls/SocialButtonLink/Telegram'
import SOCTW from '../controls/SocialButtonLink/Twitter'
import SOCTWS from '../controls/SocialButtonLink/TwitterSqr'
import SOCI from '../controls/SocialButtonLink/Instagram'
import SOCIS from '../controls/SocialButtonLink/InstagramSqr'
import SOCFA from '../controls/SocialButtonLink/Facebook'
import SOCBOU from '../controls/SocialButtonLink/Bounty'
import SOCG from '../controls/SocialButtonLink/GitHub'
import SOCL from '../controls/SocialButtonLink/Linkedin'
import SOCP from '../controls/SocialButtonLink/Product'
import SOCST from '../controls/SocialButtonLink/Steemit'
import SOCR from '../controls/SocialButtonLink/Reddit'
import SOCM from '../controls/SocialButtonLink/Medium'
import SOCTT from '../controls/SocialButtonLink/Tiktok'
import SOCDIS from '../controls/SocialButtonLink/discord'
import Discord from "../controls/SocialButtonLink/discord";
import {Row} from '../v4/Grid/Row';
import {regexEmail} from '../../utils/RegExp'
import {sendEmailToSubscribe} from '../../actions/Login'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios/index";

import LoginPopup from '../v4/loginPopup';


import './style.scss'
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
            emailError: '',
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            email = e.target.value,
            emailError = this.state.emailError;


        (regexEmail.test(email) || !email) ? emailError = '' : emailError = 'Invalid e-mail address'
        // regexEmail.test(email)? emailError = '' : emailError = 'Invalid e-mail address'

        this.setState({email, emailError})
    }

    validateEmail = (email) => {
        const regexp = /\S+@\S+\.\S+/;
        if (regexp.test(email)) {
            this.setState({error: ''})
            return true;
        } else {
            this.setState({error: 'Not valid email'})
            return false;
        }
    }

    subscribe = (type) => {
        const email = this.state.email;

        if (this.validateEmail(email)) {
            axios
                .post(`/v1/users/subscribe/${type}`, {email: email})
                .then(res => {
                        if (res.data.status === 400) {
                            NotificationManager.info('Thank you for subscription to Rocket DAO newsletter!')
                            this.setState({email: ''});
                        } else {
                            this.setState({email: ''});
                            NotificationManager.info('Thank you for subscription to Rocket DAO newsletter!')
                        }
                    }
                )
        }
    }

    render() {

        return (
            <div className="block-footer new">

                <div className="container">

                    <div className="footer-new-top">
                        <div className="logo-footer">
                            <a href="/">
                                <img src="/img/LOGO-RocketDAO.svg" alt=""/>
                            </a>
                        </div>
                        <div className="footer-new-subscribe">
                            <input className="footer-new-subscribe-elem" type="text" placeholder={'Enter your email'} onChange={this.onChange} value={this.state.email}/>
                            <div className="footer-new-subscribe-submit"
                                 onClick={() => this.subscribe('newsletter')}>
                                Subscribe to newsletter
                            </div>
                        </div>
                    </div>

                    <Row className="footer-menu-row">

                        <div className="footer-menu-col col">
                            <div className="block-col-link-list">
                                <Link className="title-link-list" to={'/'} onClick={()=>{window.scrollTo(0,0)}}>
                                    rocketdao.io
                                </Link>
                                <Link to={'/start_reg?type=8'} onClick={()=>{window.scrollTo(0,0)}}>
                                    Register
                                </Link>
                                <Link to={'/startup_test'} onClick={()=>{window.scrollTo(0,0)}}>
                                    Pass a startup test
                                </Link>
                                <a className="link-list-email" href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </div>
                            <div className="block-list-social-link">
                                <SOCFA
                                    LinkSocial="https://web.facebook.com/RocketDAO/?_rdc=1&_rdr"
                                    ActiveSocial={true}
                                />
                                <SOCL
                                    LinkSocial="https://www.linkedin.com/company/rocket-dao"
                                    ActiveSocial={true}
                                />
                                <SOCM
                                    LinkSocial="https://medium.com/@rocketdao"
                                    ActiveSocial={true}
                                />
                                <SOCTWS
                                    LinkSocial="https://twitter.com/rocketdao_io"
                                    ActiveSocial={true}
                                />
                                <SOCIS
                                    LinkSocial="https://www.instagram.com/rocketdao.io/"
                                    ActiveSocial={true}
                                />
                            </div>
                        </div>
                        <div className='footer-menu-col col'>
                            <div className="block-col-link-list">
                                <a className="title-link-list" href="https://startupjedi.vc/ru" target="_blank">
                                    startupjedi.vc
                                </a>
                                <a href="https://startupjedi.vc/content/about-startup-jedi" target="_blank">
                                    Media’s mission
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLScNt0oStPaDydqPQAqSXvHIttav393B8gGv1LWD93PwFDsLyQ/viewform" target="_blank">
                                    Become an author
                                </a>
                                <a className="link-list-email" href="mailto:yo@startupjedi.vc">yo@startupjedi.vc</a>
                            </div>
                            <div className="block-list-social-link">
                                <SOCFA
                                    LinkSocial="https://www.facebook.com/StartupJedi"
                                    ActiveSocial={true}
                                />
                                <SOCM
                                    LinkSocial="https://medium.com/startup-jedi"
                                    ActiveSocial={true}
                                />
                                <SOCTWS
                                    LinkSocial="https://twitter.com/startup_jedi"
                                    ActiveSocial={true}
                                />
                                <SOCT
                                    LinkSocial="https://t.me/Startup_Jedi"
                                    ActiveSocial={true}
                                />
                                <SOCTT
                                    LinkSocial="https://www.tiktok.com/@startupjedi.vc"
                                    ActiveSocial={true}
                                />
                                <SOCIS
                                    LinkSocial="https://www.instagram.com/startupjedi.vc/"
                                    ActiveSocial={true}
                                />
                            </div>
                        </div>
                        <div className='footer-menu-col col'>
                            <div className="block-col-link-list">
                                <a className="title-link-list" href="https://foundersclub.vc/" target="_blank">
                                    founderslub.vc
                                </a>
                                <a href="https://t.me/rocketdaobot/?start=am" target="_blank">
                                    Go to onboarding bot
                                </a>
                                <a href="https://t.me/founders_club" target="_blank">
                                    Subscribe to channel
                                </a>
                                <a className="link-list-email" href="mailto:yo@foundersclub.vc">yo@foundersclub.vc</a>
                            </div>
                            <div className="block-list-social-link">
                                <SOCT
                                    LinkSocial="https://t.me/founders_club"
                                    ActiveSocial={true}
                                />
                            </div>
                        </div>



                        <div className='footer-menu-col col'>
                            <div className="block-col-link-list">
                                <a className="title-link-list" href="https://investclub.vc/" target="_blank">
                                    investclub.vc
                                </a>
                                <a href="https://t.me/rocketdaobot/?start=am" target="_blank">
                                    Go to onboarding bot
                                </a>
                                <a href="mailto:yo@investclub.vc?subject=Partnership request" target="_blank">
                                    Become a partner
                                </a>
                                <a className="link-list-email" href="mailto:yo@investclub.vc">yo@investclub.vc</a>
                            </div>
                        </div>
                        <div className='footer-menu-col col col-md-4 footer-menu-col-soon'>
                            <div className="block-col-link-list block-col-link-list-soon">
                                <p className="title-link-list">
                                    XYZ Fund (soon)
                                </p>
                                <a>
                                    Investment criteria
                                </a>
                                <a>
                                    Send application
                                </a>
                            </div>
                        </div>

                    </Row>

                    <div className="footer-bottom">
                        <a href="https://tap2pay.me" className="footer-tap2pay-link"
                           alt="Tap2Pay payments gateway for social networks, messengers and websites"
                           title="Tap2Pay accept payments online"
                           target="_blank">
                            <img className="footer-tap2pay-img" src="https://tap2pay.me/wp-content/uploads/2016/09/Tap2Pay-accept-payments-online.png"/>
                        </a>

                        <div className="footer-copyright">
                            <p>© 2021 All Rights Reserved.</p>
                            <Link to={'/document'} onClick={()=>{window.scrollTo(0,0)}} className='first-link'>
                                Agreements
                            </Link>
                        </div>
                    </div>

                </div>


                {/*<div className="container">*/}
                {/*    <div className='row-m0 no-gutters'>*/}
                {/*        <div className="col-sm-9 col-12 block-left-footer">*/}


                {/*            <div className="block-link-grid-footer">*/}
                {/*            <div className="block-col-link-grid">*/}
                {/*            <a className="top-link">About</a>*/}
                {/*            <a href="https://rocketdao.io/about">About the platform</a>*/}
                {/*            <a href="https://rocketdao.io/about#tokenSale">Token sale</a>*/}
                {/*            <a href="https://rocketdao.io/about#bounty">Bounties</a>*/}
                {/*            <a href="https://rocketdao.io/about#rocketWallet">Rocket wallet</a>*/}
                {/*            <a href="https://blog.rocketdao.io/">Blog</a>*/}
                {/*            /!*<a href="https://rocketdao.io/about#roadmap">Roadmap</a>*!/*/}
                {/*            </div>*/}
                {/*            <div className="block-col-link-grid">*/}
                {/*            <a className="top-link">Investors</a>*/}
                {/*            <a href="https://rocketdao.io/fund_info">How it works</a>*/}
                {/*            <a href="https://rocketdao.io/funds">Decentralized funds</a>*/}
                {/*            </div>*/}
                {/*            <div className="block-col-link-grid">*/}
                {/*            <a className="top-link">Experts</a>*/}
                {/*            <a href="https://rocketdao.io/expert_info">How it works</a>*/}
                {/*            <a href="https://rocketdao.io/experts">Expert list</a>*/}
                {/*            <a href="https://rocketdao.io/forum">Forum</a>*/}
                {/*            <a href="https://rocketdao.io/knowledge_base">Knowlege base</a>*/}
                {/*            </div>*/}
                {/*            <div className="block-col-link-grid">*/}
                {/*            <a className="top-link">Startups</a>*/}
                {/*            <a href="https://rocketdao.io/startup_info">How it works</a>*/}
                {/*            <a href="https://rocketdao.io/startups">Startups list</a>*/}
                {/*            </div>*/}
                {/*            </div>*/}

                {/*            <div className="block-input-button-footer-new">*/}
                {/*                <p className="title-input-button-footer-new">*/}
                {/*                    Subscribe to our newsletter*/}
                {/*                </p>*/}
                {/*                <div className="row-m0 no-gutters input-button-footer-new">*/}
                {/*                    <div className='col-8'><input type="text" placeholder={'Enter your email'}*/}
                {/*                                                  onChange={this.onChange} value={this.state.email}/>*/}
                {/*                    </div>*/}
                {/*                    <div className="col-4 button-footer-new"*/}
                {/*                         onClick={() => this.subscribe('newsletter')}>*/}
                {/*                        <p className="title-button-footer-new">Subscribe</p>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <div className="block-social-group shell-margin-top-30" style={{textAlign: "left"}}>*/}
                {/*                <SOCFA*/}
                {/*                    LinkSocial="https://web.facebook.com/RocketDAO/?_rdc=1&_rdr"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}
                {/*                <SOCL*/}
                {/*                    LinkSocial="https://www.linkedin.com/company/rocket-dao"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}
                {/*                <SOCM*/}
                {/*                    LinkSocial="https://medium.com/@rocketdao"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}
                {/*                <SOCTW*/}
                {/*                    LinkSocial="https://twitter.com/rocketdao_io"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}
                {/*                <SOCT*/}
                {/*                    LinkSocial="https://t.me/RocketDAO_Community"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}
                {/*                <SOCDIS*/}
                {/*                    LinkSocial="https://discord.gg/g3vF59V"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}

                {/*                <SOCR*/}
                {/*                    LinkSocial="https://www.reddit.com/r/RocketDAO/"*/}
                {/*                    ActiveSocial={true}*/}
                {/*                />*/}


                {/*                <SOCST*/}
                {/*                LinkSocial="https://steemit.com/@rocket.dao"*/}
                {/*                ActiveSocial={true}*/}
                {/*                />*/}

                {/*                <SOCG*/}
                {/*                LinkSocial="https://github.com/RocketDAO"*/}
                {/*                ActiveSocial={true}*/}
                {/*                />*/}


                {/*                <SOCI*/}
                {/*                LinkSocial="https://www.instagram.com/rocketdao.io/"*/}
                {/*                ActiveSocial={true}*/}
                {/*                />*/}


                {/*                <SOCB*/}
                {/*                LinkSocial="https://bitcointalk.org/index.php?topic=3505413.msg36192457#msg36192457"*/}
                {/*                ActiveSocial={true}*/}
                {/*                />*/}


                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-sm-3 col-12 block-right-footer" style={{padding: '30px 0 0 0'}}>*/}
                {/*            <div className="block-input-button-footer">*/}
                {/*            <input*/}
                {/*            type="email"*/}
                {/*            name="email"*/}
                {/*            value={this.state.email}*/}
                {/*            className="block-input-footer-input"*/}
                {/*            placeholder="Enter your email"*/}
                {/*            onChange={this.onChange}*/}
                {/*            autoComplete="off"*/}
                {/*            />*/}
                {/*            { !this.state.emailError ?*/}
                {/*            <div className="block-button-footer-input" onClick={() => this.sendEmail()}>*/}
                {/*            <p className="title">Subscribe to our newsletter</p>*/}
                {/*            </div>*/}
                {/*            :*/}
                {/*            <div className="block-button-footer-input error" >*/}
                {/*            <p className="title">{this.state.emailError}</p>*/}
                {/*            </div>*/}
                {/*            }*/}
                {/*            </div>*/}
                {/*            <img src="/img/LOGO-RocketDAO.svg" alt=""/>*/}

                {/*            /!*<a href="https://blog.rocketdao.io" target="_blank">blog.rocketdao.io</a>*!/*/}
                {/*            <a href="mailto:support@rocketdao.io" target="_blank">support@rocketdao.io</a>*/}
                {/*            <p>Copyright © {new Date().getFullYear()} Rocketdao.io</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <NotificationContainer/>
            </div>

        );
    }
}
import React from 'react';
import ButtonBlack from '../../controls3.0/button/ButtonBlack';
import ButtonGreen from '../../controls3.0/button/ButtonGreen';
import ButtonBlue from '../../controls3.0/button/ButtonBlue';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {updateAngel, updateFund, updateStartup, updateInvestor, updateExpert} from "../../../actions/Login";
import history from '../../../history';

class EditMyPageButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , error: {}
        }
    }


    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({...nextProps}, () => {

        });
    }

    action = () => {
        const {type, id} = this.state;

        switch (type){
            case 'angel': {
                history.push(`/edit/angel_network/${id}`)
                return;
            }

            case 'fund': {
                history.push(`/edit/fund/${id}`)
                return;
            }

            case 'accelerator': {
                history.push(`/edit/accelerator/${id}`)
                return;
            }

            case 'startup': {
                history.push(`/edit/startup/${id}`)
                return;
            }

            case 'investor': {
                history.push(`/edit/investor`)
                return;
            }

            case 'expert': {
                history.push(`/edit/expert`)
                return;
            }

            case 'user': {
                history.push(`/edit/user/profile`)
                return;
            }
        }
    }


    render() {
        const {type} = this.state;
        const props = {
            TitleButton : 'Edit',
            EventOnClick: () => this.action(),
            paddingButton: '54',
            disabled:false,
            loader:false,
            onMouseEnter:null,
            onMouseLeave:null
        }

        let button = <ButtonBlack {...props}/>;

        if(['investor', 'expert'].indexOf(type) !== -1){
            button = <ButtonGreen {...props}/>;
        }

        if(['angel', 'fund', 'accelerator'].indexOf(type) !== -1){
            button = <ButtonBlue {...props}/>;
        }

        if(['user'].indexOf(type) !== -1){
            button = <ButtonBlue {...props}/>;
        }

        return (
            button
        );
    }
}

const mapStateToProps = (state) => ({

})

EditMyPageButton.propTypes = {
    updateAngel: PropTypes.func.isRequired,
    updateFund: PropTypes.func.isRequired,
    updateStartup: PropTypes.func.isRequired,
    updateInvestor: PropTypes.func.isRequired,
    updateExpert: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {updateAngel, updateFund, updateStartup, updateInvestor, updateExpert})(EditMyPageButton)
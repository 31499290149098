import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";
import {getCitySearch} from "../../../actions/Profile";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";



class Step1 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            listUsers: [],
            me: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        this.setState({ 'name': e.target.value }, () => this.searchUser())
    }

    searchUser = () => {
        let str = this.state.name
        if(str.length < 3) return

        getCitySearch(str)
            .then(res => {
                this.setState({listUsers: res.data })
            })
    }

    setMe = user => {
        let me = this.state.me
        if(me.Name === user.Name) user = {}
        this.setState({me: user})
    }


    update = (nextStep, onlyStep) => {
        if(onlyStep){
            window.location.href = '/start_investor'
            return
        }


        this.setState({loading: true})
        this.props.updateInvestor({} , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let user = this.props.user || {}

        let list = this.state.listUsers.map((item, key) =>
            <div key={key} className={`item-user-investor ${this.state.me.Name === item.Name ? 'active' : ''}`} onClick={() => this.setMe(item)}>
                {item.Name}
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    What is your name?
                                </p>
                                <p className="text-h2 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Introduce yourself to the community! Please enter your name and surname as shown in your passport.
                                </p>

                                <div className="block-list-check-box-reg-startup bl-min-height">
                                    <div>
                                        <Input
                                            type={'text'}
                                            name={'name'}
                                            value={this.state.name}
                                            onChange={this.onChange}
                                            error={''}
                                            placeholder={'Name'}
                                        />
                                    </div>

                                    <div>
                                        <p>Hmm, we found a match already registered on the platform. Is it you?</p>
                                        <div>{list}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(1, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(2)}
                                paddingButton={'40'}
                                disabled={!this.state.me.Name}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step1.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step1)
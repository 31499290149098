import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import './style.css'
import icon_arrows from '../../../images/svg/arrows_top_bottom.svg'

export default class ButtonLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _value: this.props.value ? this.props.value : [],
            activeSelect: false,
            openCloseList: false,
            clickItem: false,
            _dataView: this.props.data === [] ? [] : this.props.data,
            valueSearch: '',
            _onChange: this.props.onChange,
        }
        this.myRef = React.createRef();
        this.handleClock = this.handleClock.bind(this);
        this.SearchItem = this.SearchItem.bind(this);
        this.onClickItem = this.onClickItem.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClock)
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.value && nextProps.value != this.state._value) {
            this.setState({_value : nextProps.value})
        }
        if (nextProps.data) {
            this.setState({_dataView : nextProps.data})
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClock)
    }

    handleClock(event) {
        if (!this.myRef.current.contains(event.target)) {
            this.setState({
                activeSelect: false,
                openCloseList: false,
            });
        } else {
            if (this.state.clickItem) {
                this.setState({
                    activeSelect: false,
                    openCloseList: true,
                    clickItem: false
                });
            } else {
                this.setState({
                    activeSelect: true,
                    openCloseList: true,
                });
            }

        }
    }

    SearchItem(e) {
        if(this.props.searchList) this.props.searchList(e.target.value)

        this.setState({
            valueSearch: e.target.value,
        });
    }

    onClickItem(item) {
        let dataValue = [];
        dataValue.push(item);
        this.setState({
            _value: dataValue,
            activeSelect: false,
            openCloseList: false,
            valueSearch: '',
            clickItem: true,
        });
        this.props.onChange(dataValue)
    }

    render() {
        let {
            placeholder,
            data, //[{Name:''}, {Name:''}]
            value, // {Name:''}
            onChange,
            inputPlaceholder,
            error
        } = this.props;

        let countItem = 0;

        let items = this.state._dataView ? this.state._dataView.map((item, key) => {
            let active = false
            if (this.state._value.length > 0) {
                if (this.state._value[0]['Name'] == item.Name) {
                    active = true
                }
            }
            if (!active) {
                if (this.state.valueSearch.length > 1) {
                    if (item.Name.toUpperCase().indexOf(this.state.valueSearch.toUpperCase()) == 0) {
                        countItem++;
                        return (
                            <div key={key} className="block-item-select"
                                 onClick={() => this.onClickItem(this.state._dataView[key])}>
                                <p className="title-item-select">{item.Name}</p>
                            </div>
                        )
                    } else {
                        return (
                            null
                        )
                    }
                } else {
                    countItem++;
                    return (
                        <div key={key} className="block-item-select" onClick={() => this.onClickItem(this.state._dataView[key])}>
                            <p className="title-item-select">{item.Name}</p>
                        </div>
                    )
                }
            } else {
                if (this.state.valueSearch.length > 1) {
                    if (item.Name.toUpperCase().indexOf(this.state.valueSearch.toUpperCase()) == 0) {
                        countItem++;
                        return (
                            <div key={key} className="block-item-select"
                                 onClick={() => this.onClickItem(this.state._dataView[key])}>
                                <p className="title-item-select">{item.Name}</p>
                            </div>
                        )
                    } else {
                        return (
                            null
                        )
                    }
                }
            }
        }) : null;

        let title;

        if (this.state._value.length > 0) {
            title = this.state._value.map((item, key) => {
                return (item.Name)
            })
        }
        else {
            title = placeholder;
        }
        let err = error ? <p className="title-error-select">{error}</p> : null;
        console.log('this.state.openCloseList', this.state.openCloseList);
        return (
            <div className="block-select" ref={this.myRef}>
                <div className={this.state.activeSelect ? "block-top-select active" : (error?"block-top-select error":"block-top-select")}>
                    <input name={'RAND_STRING'} autoComplete="off" type="text" onChange={this.SearchItem} onKeyDown={this.props.onKeyDown} onKeyUp={this.props.onKeyUp} value={this.state.valueSearch} placeholder={inputPlaceholder?inputPlaceholder:'Search'}/>
                    <p className="title-select placeholder">{title}</p>
                    <img src={icon_arrows} alt=""/>
                </div>

                {!!items.length &&
                <div className={this.state.openCloseList ? "block-body-select active" : "block-body-select"}
                     style={this.state.openCloseList ? {height: countItem > 5 ? 'calc(32px * 5)' : 'calc(34px * ' + countItem + ')'} : {height: '0'}}>
                    <Scrollbars>
                        <div className="block-list-item-select">
                            {items}
                        </div>
                    </Scrollbars>
                    <span className="gradient-select"/>
                </div>
                }
                {err}
            </div>
        );
    }
}





import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {getListSkills, getListSkillsByString} from "../../../actions/Profile";
import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import {showMessage} from "../../../utils/showMessage";
import Loader from '../../test-controls/preloader/preloader'
import human from "../../../images/svg/human.svg";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Tabs from "../../controls3.0/tabs/Tabs";
import Input from "../../controls3.0/input/Input";
import Button from "../../controls/Button/Button";

class Step8 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        if (!Array.isArray(user.skills)) {
            user.skills = [];
        }
        this.state = {
            user: user,
            expert: expert,
            inputSearch: '',
            suggestedArrSkills: [],
            searchArrSkills: [],
            skills_input: []
        }
    }

    componentDidMount() {
        getListSkills(1)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({suggestedArrSkills: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })
    }

    addToSkills = (skill, inputType = null) => {
        let skills = this.state.user.skills;
        let isFound = skills.find(x => x.title === skill.title)
        if (!isFound) skills.push(skill);

        if(inputType){
            if(this.state.skills_input.indexOf(inputType) === -1){
                this.setState({skills_input: [...this.state.skills_input, inputType] })
            }

            if(inputType === 'manual'){
                this.setState({inputSearch: ''});
            }
        }

        this.setState({user: {...this.state.user, skills}, empty: false})
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if (value[value.length-1] === ' ' && (this.state.inputSearch[this.state.inputSearch.length-1] === ' ' || this.state.inputSearch.length===0)) return;

        this.setState({[name]: value}, () => {
            if(value) {
                this.getByString(value)
            } else {
                console.log('test')
                this.setState({searchArrSkills: []})
            }

        })
    }

    getByString = str => {
        // this.setState({loading: true})
        getListSkillsByString(str)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({searchArrSkills: res.data})
                this.setState({loading: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading: false})
            })
    }

    removeSkill = skill => {
        if(skill) {
            let skills = this.state.user.skills;
            let filtered = skills.filter(x => x.id !== skill.id)
            this.setState({user: {...this.state.user, skills: filtered}})
        }
    }

    update = (nextStep, onlyStep) => {
        let user = this.state.user,
            skills = [];
        if (!onlyStep) {
            if (!user.skills.length) {
                this.setState({empty: true})
                return
            } else {
                // user.skills.forEach((item, key) => {
                //     skills.push(item.id)
                // })
            }
        }

        let loading = nextStep == 7 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})
        this.props.updateExpert({skills: user.skills, event_type: 'expert_skills_4', event_data: {skills_input: this.state.skills_input}}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    render() {
        let {user, expert, suggestedArrSkills, searchArrSkills, loading} = this.state;

        let mySkills = user.skills.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => this.removeSkill(item)}
                />
            )
        })

        let searchSkills = searchArrSkills.map((item, key) => {
            if (!user.skills.find((i) => i.id === item.id)) {
                return (
                    <Tabs
                        key={key}
                        TitleTab={item.title}
                        TypeTab={'small'}
                        EventOnClick={() => this.addToSkills(item, 'database')}
                        active={false}
                    />
                )
            }
        })

        let suggestedSkills = suggestedArrSkills.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'small'}
                    EventOnClick={() => this.addToSkills(item, 'suggested')}
                    active={user.skills.find((i) => i.id === item.id)}
                />
            )
        })

        return (
            <React.Fragment>


                <div className="container-full-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg padding-reg" style={{position: 'relative'}}>
                        <p className="description-page-reg-expert text-h1 left-text shell-margin-bottom-20 color-text-reg-black">
                            Skills and competenciens
                        </p>
                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-20 color-text-reg-black">
                            Let people know your background and scope of interests.
                        </p>
                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20 color-text-reg-gray">
                            This step will help you find experts with similar experience. <br/>
                            You can then share, collaborate and grow together.
                        </p>
                        <div className="shell-tabs-reg shell-margin-bottom-30">
                            {mySkills}
                        </div>


                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-black">
                            Input skill tags here
                        </p>

                        <div className="block-list-button-input">
                            <div className="shell-input-reg">
                                <Input
                                    type={'text'}
                                    name={'inputSearch'}
                                    value={this.state.inputSearch}
                                    onChange={this.onChange}
                                    placeholder={''}
                                    iconClass={null}
                                />
                            </div>
                            <div className="shell-input-button-add">
                                <ButtonBlue
                                    TitleButton={'Add'}
                                    EventOnClick={() => this.addToSkills({title: this.state.inputSearch}, 'manual') }
                                    paddingButton={'30'}
                                    disabled={(this.state.inputSearch === '') || searchArrSkills.find((item) => item.title === this.state.inputSearch)}
                                    loader={false}
                                />
                            </div>
                        </div>

                        <div className="shell-tabs-reg shell-margin-bottom-30">
                            {loading ?
                                'loading...'
                                :
                                searchSkills
                            }
                        </div>

                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-black">
                            Suggested skills tags
                        </p>
                        <div className="shell-tabs-reg">
                            {suggestedSkills}
                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(7, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(25)}
                                paddingButton={'56'}
                                disabled={!user.skills.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step8.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step8)
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ReactModal from "react-modal";
import moment from 'moment';


import config from "../../../../config";
import Input from "../../../controls3.0/input/Input";
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import Loader from "../../../test-controls/preloader/preloader";
import ButtonBlack from "../../../controls3.0/button/ButtonBlack";

const InvestClubMember = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [value, setValue] = useState();
  const [startupToDelete, setStartupToDelete] = useState(null);

  const user = useSelector((state) => state.auth.user);
  let { userId } = useParams();
  if (!userId) {
    userId = user.id;
  }

  useEffect(() => {
    setIsLoading(true);
    axios.get(`/v2/ivestclub/partners/${userId}`).then((data) => {
      setIsLoading(false);
      setUserData(data.data.user || []);
    });
  }, []);

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onStartupAdd();
    }
  };

  const onStartupAdd = async () => {
    const regex = /startup\/(.+)/;
    const found = value.match(regex);
    if (!found) {
      return NotificationManager.error(
        "URL not valid. Please provide link as https://rocketdao.io/startup/12345"
      );
    }
    setIsLoading(true);

    try {
      const data = await axios.put(
        `/v2/ivestclub/partners/${userId}/${found[1]}`
      );
      NotificationManager.info("Startup added");
      setUserData(data.data.user || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const isAdmin = user.is_investClubSuperAdmin || user.is_investClubAdmin;

  const setAgreement = async (project) => {
    if (!isAdmin) {
      return;
    }
    if (!project.isFinished){
      return NotificationManager.error("You can set agreement to startup that finished registration. This startup still on registration step");
    }
    if (project.isAgreementSigned && !user.is_investClubSuperAdmin){
      return;
    }
    setIsLoading(true);
    const startupId = project.id;

    try {
      const data = await axios.post(
        `/v2/ivestclub/partners/${userId}/${startupId}?agreement=${!project.isAgreementSigned}`
      );
      NotificationManager.info("Agreement updated");
      setUserData(data.data.user || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onModalClick = async () => {
    setIsLoading(true);
    const startupId = startupToDelete.project.id;

    setStartupToDelete(null);

    try {
      const data = await axios.delete(
        `/v2/ivestclub/partners/${userData.id}/${startupId}`
      );
      NotificationManager.info("Statup deleted");
      setUserData(data.data.user || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!userData) {
    return "";
  }

  const link = `https://rocketdao.io/registration/startup/1?ref_b=${userData.referralHash}`;

  const copyLink = () => {
    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    NotificationManager.info(`Link copied to clipboard ${link}`);
  };

  return (
    <div>
      <div className="row-m0">
        <div
          className="col-lg-12 invest-club-avatar-block"
          style={{ textAlign: "left" }}
        >
          <img src={`${config.proxy}/file/${userData.logoFileId}/view`} />
          <h4>
            {userData.firstName} {userData.lastName}
          </h4>
          <p>
            {" "}
            Referral link used:{" "}
            <strong> {userData.referralLinkUsedTimes} times</strong>
          </p>
          <p> Startups: <strong>{userData.investClubProjects.length}</strong></p>
          <p style={{color: "#979797"}}> Joined  {moment(userData.createdAt).format('DD MMMM, YYYY')}</p>
        </div>
      </div>
      {isAdmin ? (
        <div className="investClubInput">
          <Input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={onEnterPress}
            placeholder={"Enter a link to add statup profile"}
          />
          <ButtonGreen
            TitleButton={"Add startup"}
            onMouseEnter={null}
            onMouseLeave={null}
            EventOnClick={onStartupAdd}
          />
        </div>
      ) : !userData.isRoleFinished && (
        <div className="investClubInput">
          <Input type="text" disabled value={link} />
          <ButtonGreen
            TitleButton={"Copy link"}
            onMouseEnter={null}
            onMouseLeave={null}
            EventOnClick={copyLink}
          />
        </div>
      )}
      {!userData.investClubProjects.length ? (
        <h1 className="margin-20">No startups found</h1>
      ) : (
        <article className="row-m0 invest-club-paper invest-club-paper-header">
          <div className={`col-lg-${isAdmin?3:4} p-0  offset-lg-1`}>Name</div>
          <div className="col-lg-2 p-0 ">Registration</div>
          <div className="col-lg-3 p-0">Region</div>
          <div className="col-lg-2 p-0">Agreement</div>
        </article>
      )}
      {userData.investClubProjects.map((startup, key) => (
        <article
          className="row-m0 shell-header-sticky-role invest-club-paper"
          key={key}
        >
          <div className="col-lg-1 p-0">
            <div
              className="block-avatar url"
              style={{
                backgroundImage: `url(${config.proxy}/file/${startup.project.logoFileId}/view`,
              }}
            ></div>
          </div>
          <div className={`col-lg-${isAdmin?3:4} p-0`}>{startup.project.title}</div>
          <div className="col-lg-2 p-0">
            <strong>
              {startup.project.isFinished ? (
                <Link to={`/startup/${startup.project.id}`}>View Profile</Link>
              ) : (
                "Not Finished"
              )}
            </strong>
          </div>
          <div className="col-lg-3 p-0">
            {startup.project.countryOfIncorporation}
          </div>
          <div className="col-lg-2" style={{ paddingLeft: "30px" }}>
            {startup.project.isAgreementSigned ? (
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setAgreement(startup.project)}

              >
                <path
                  d="M17 9.42578C17 13.8441 13.4183 17.4258 9 17.4258C4.58172 17.4258 1 13.8441 1 9.42578C1 5.0075 4.58172 1.42578 9 1.42578C13.4183 1.42578 17 5.0075 17 9.42578Z"
                  fill="#2CA6EB"
                  stroke="#2CA6EB"
                  stroke-width="2"
                />
                <path
                  d="M5.08696 9.81708L8.21739 12.5562L12.913 6.68665"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setAgreement(startup.project)}
              >
                <path
                  d="M17.5 9.5C17.5 14.1944 13.6944 18 9 18C4.30558 18 0.5 14.1944 0.5 9.5C0.5 4.80558 4.30558 1 9 1C13.6944 1 17.5 4.80558 17.5 9.5Z"
                  fill="white"
                  stroke="#2CA6EB"
                />
              </svg>
            )}
          </div>
          {isAdmin && (<div className="col-lg-1 p-0">
            {startup.status === "addedByAdmin" && (
              <ButtonBlack
                TitleButton={"Delete"}
                onMouseEnter={null}
                onMouseLeave={null}
                EventOnClick={() => setStartupToDelete(startup)}
              />
            )}
          </div>)}
        </article>
      ))}
      <NotificationContainer />
      <Loader loaded={!isLoading} />
      <ReactModal
        ariaHideApp={false}
        isOpen={Boolean(startupToDelete)}
        onRequestClose={() => setStartupToDelete(null)}
        className="Modal  modal-invest-club"
        overlayClassName="Overlay"
      >
        {startupToDelete && (
          <React.Fragment>
            <div
              className="close-title"
              onClick={() => setStartupToDelete(null)}
            >
              <div
                className="close"
                style={{
                  opacity: "1",
                  left: "auto",
                  right: "35px",
                  top: "35px",
                }}
              />
            </div>
            <h3 className="margin-15">
              Delete the startup from partner's pipeline?
            </h3>
            <div className="row-m0">
              <div className="col-lg-6">
                <div
                  className="invest-club-block-avatar"
                  style={{
                    backgroundImage: `url(${config.proxy}/file/${startupToDelete.project.logoFileId}/view`,
                  }}
                ></div>
                <span className="margin-15">
                  {startupToDelete.project.title}
                </span>
              </div>
              <div className="col-lg-6">
                <div
                  className="invest-club-block-avatar"
                  style={{
                    backgroundImage: `url(${config.proxy}/file/${userData.logoFileId}/view`,
                  }}
                ></div>
                <span className="margin-15">
                  {userData.firstName} {userData.lastName}
                </span>
              </div>
            </div>

            <p className="invest-club-modal-description margin-20">
              This startup will still be public on rocketdao.io, but removed
              from the list of partner’s startups.
            </p>
            <div className="row-m0">
              <ButtonGreen
                className={`margin-15 red-background`}
                TitleButton={"Delete"}
                onMouseEnter={null}
                onMouseLeave={null}
                EventOnClick={onModalClick}
              />
            </div>
          </React.Fragment>
        )}
      </ReactModal>
    </div>
  );
};

export default InvestClubMember;

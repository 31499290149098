import React from 'react';
import './SocialButtonLink.css'

export default class Tiktok extends React.Component {
    render() {
        let {
            LinkSocial,
            ActiveSocial,
        } = this.props;
        /*let styleLink = {
            color: ActiveSocial? '#0000D3':'#F1F2F2',
        }*/
        let classActiveSocial = ActiveSocial? 'social-button active-social-button':' social-button no-active-social-button';
        return (
            <div className={classActiveSocial}>
                <a href={LinkSocial} target="_blank">
                    <svg width="19" height="18" viewBox="0 0 19 18">
                        <path d="M16.07 0H2.354A2.145 2.145 0 00.212 2.143v13.714C.212 17.04 1.173 18 2.355 18h13.714a2.145 2.145 0 002.143-2.143V2.143A2.145 2.145 0 0016.069 0zm-1.713 7.853a3.21 3.21 0 01-2.982-1.437v4.944A3.654 3.654 0 117.72 7.706c.077 0 .151.007.226.011v1.801c-.075-.009-.148-.023-.226-.023a1.865 1.865 0 100 3.73c1.03 0 1.94-.811 1.94-1.841l.019-8.398h1.723a3.209 3.209 0 002.957 2.865v2.002" fill="currentColor"/>
                    </svg>
                </a>
            </div>
        );
    }
}
import React from 'react';
import './style.css'

export default class TabsCounter extends React.Component {

    render() {
        let {
            TitleTab,
            TypeTab, // small or big
            EventOnClick,
            active,
            // removeTab,
            count,
        } = this.props;

        let classList = active?"block-button-tab-counter active":"block-button-tab-counter";
        classList += TypeTab? TypeTab == 'big'? ' big':' small':' small';
        classList += count? ' counter' :'';

        let counter = count?<span className="counter">{count}</span>:null;

        return (
            <div className={classList} onClick={EventOnClick} >
                <p className="title">{TitleTab}</p>
                {counter}
            </div>
        );
    }
}


import React from 'react'
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import './loginRegister.css'
import Loader  from './../test-controls/preloader/preloader'
import {changePassword} from '../../actions/Profile'



class ModalChangePassword extends React.Component {

    constructor(props){
        super();
        this.state = {
            loaded: true,
            changed: false,
            currentPass: '',
            newPass: '',
            newPassConfirm: '',
            regexPass: new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})/),
            errors: {},
            serverError: ''
        };
    }



    componentWillReceiveProps () {
        this.setState({errors: {}})
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        this.setState({[name]: value})

        if(name == 'currentPass') this.validatePass(name, value)
        if(name == 'newPass') this.validatePass(name, value)
        if(name == 'newPassConfirm') this.confirmPass(this.state.newPass, value)
    }

    validatePass = (name, value) => {
        let hasError;
        if(!this.state.regexPass.test(value)) {
            this.setState({errors:{...this.state.errors, [name]: 'Minimum 8 characters - lower and upper case symbols and digit(s)'}})
            hasError = true;
        }else {
            // this.setState({errors:{...this.state.errors, [name]: null}})
            this.deleteError(name);
        }

        if(name == 'newPass' && !hasError && this.state.newPassConfirm) {
            this.confirmPass(value, this.state.newPassConfirm)
        }

    }

    confirmPass = (newPass, confirmPass) => {
        if(newPass){
            if(newPass != confirmPass) {
                this.setState({errors:{...this.state.errors, ['newPassConfirm']: 'Passwords don\'t match'}})
            }else {
                this.deleteError('newPassConfirm');
            }
        }
    }

    deleteError = name => {
        let errors = this.state.errors;
        delete errors[name];
        this.setState({errors: errors})
    }


    // onFocus = e => {
    //     this.setState({errorEmail: null})
    // }


    send = () => {
        let obj ={
            cur_password: this.state.currentPass,
            password: this.state.newPass
        }

        this.setState({loaded: false})
        changePassword(this.props.user.id, obj)
            .then((res)=>{
                this.setState({loaded: true, changed: true})
            })
            .catch(error => {
                this.setState({loaded: true})
                if(error.response.data.error == 'INVALID_CURRENT_PASSWORD') {
                    this.setState({errors:{...this.state.errors, currentPass: 'The current password specified is incorrect.'}})
                } else {
                    this.setState({ serverError: error.response.data.error })
                    setTimeout(function() {
                        this.setState({serverError: null})
                    }.bind(this), 3000);
                }

            })


    }


    render() {
        return (

            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login"
                    overlayClassName="Overlay"
                >

                    <div className="modal-login-block ">
                        <div className="block-top fix">
                            <div className='sr-title'>Change password</div>

                            <img src="../../img/x.svg" alt="" className="close-icon" onClick={this.props.handleCloseModal}/>
                        </div>

                        {!this.state.changed &&
                        <div>
                            <div className="block-form">
                                <div className="input-reg-dao">
                                    <input type="password" name="currentPass" onChange={this.onChange} value={this.state.currentPass} onFocus={this.onFocus} placeholder="Current password"/>
                                    <div className="reg_error_field">{this.state.errors.currentPass}</div>
                                </div>
                                <div className="input-reg-dao">
                                    <input type="password" name="newPass" onChange={this.onChange} value={this.state.newPass} onFocus={this.onFocus} placeholder="New password"/>
                                    <div className="reg_error_field">{this.state.errors.newPass}</div>
                                </div>
                                <div className="input-reg-dao">
                                    <input type="password" name="newPassConfirm" onChange={this.onChange} value={this.state.newPassConfirm} onFocus={this.onFocus} placeholder="Retype new password"/>
                                    <div className="reg_error_field">{this.state.errors.newPassConfirm}</div>
                                </div>
                                <div>
                                    <button className="login-form__btn login-form__btn_join new-button-green-modal" disabled={!this.state.currentPass || !this.state.newPass || !this.state.newPassConfirm || Object.keys(this.state.errors).length} onClick={this.send}>Submit</button>
                                    <div className="reg_error_field fix-error-2 text-center">{this.state.serverError}</div>
                                </div>
                            </div>
                        </div>

                        }

                        {this.state.changed &&
                        <div className="sr-description">
                            Your password has been changed successfully!
                        </div>

                        }

                    </div>

                    <Loader loaded={this.state.loaded}></Loader>
                </ReactModal>
            </div>


        )
    }
}



ModalChangePassword.propTypes = {
    history:PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
}

export default connect(null, {})(ModalChangePassword)
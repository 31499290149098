import React from 'react';

import '../style.css'

export default class AnswerPoint extends React.Component {

    render() {

        let {
            AnswerText,
            answerCheck,
            answerNumber,
            handleClick
        } = this.props;


        return (
            <div className={`${answerCheck} block-viral-answer-point ${this.props.canUserAnswer?'cursor-pointer':''}`} onClick={() =>  handleClick(answerNumber)}>

                <div className='choise-point'/>
                <div className='block-answer-text'>
                    <div className='answer-text'>{AnswerText}</div>
                </div>

            </div>
        );
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";
import {getMoneyView} from "../../../utils/transformation";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import InputRange from 'react-input-range';
import CheckBox from "../../controls3.0/checkBox/checkBox";


class Step11 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {},
            value: {min: 100000, max: 700000},
            step: 5000
        }
    }

    componentDidMount() {
        if (this.state.investor.turnover && this.state.investor.turnover.min) {
            this.setState({
                value: {
                    ...this.state.value,
                    min: this.state.investor.turnover.min,
                    max: this.state.investor.turnover.max
                }
            })
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({investor: {...this.state.investor, [name]: value}})
    }

    onChangeRange = value => {
        let min = this.state.value.min,
            max = this.state.value.max,
            param,
            step

        if (value.min <= 5000) value.min = 5000

        if(max !== value.max) {
            if(value.min !== min) {
                value.min = min
            }
            param = 'max'
        } else {
            param = 'min'
        }

        let param2 = value[param]

        if(param2 <= 100000 ){
            step = 5000
        } else if (param2 > 100000 && param2 <= 1000000 ) {
            step = 10000
        } else if(param2 > 1000000){
            if(param2 < 1100000)
            value[param] = 1100000
            step = 100000
        }
        this.setState({step: step, value: value})
    }

    onCheck = () => {
        this.setState({investor: {...this.state.investor, ['noTurnover']: !this.state.investor.noTurnover}})
    }

    calculate = () => {
        let investor = this.state.investor;
        if (investor.turnover) this.setState({value: investor.turnover})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if (onlyStep) {
            obj = {}
        }

        obj.event_type = 'angel_turnover_stage_10';

        if (!onlyStep) {
            if (!obj.noTurnover) obj.turnover = this.state.value;
        }
        this.setState({loading: true})
        this.props.updateInvestor(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className={'container-step-1-reg'}>
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Startup turnover stage (in USD)
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20"
                                   style={{maxWidth: '660px'}}>
                                    When making your investment decisions do you take into account the current startups'
                                    turnover?
                                    If so, what is the range that satisfies you?
                                </p>
                                <p className="text-button color-text-reg-gray margin-0">
                                    If you also work with project teams that have no cash flow yet, put a tick below.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg container-bottom block-gradient-gray-reg"
                         style={{position: 'relative'}}>
                        <div className="container-step-1-reg padding-reg">
                            <div
                                className="block-input-range slider slider-green shell-margin-bottom-40 margin-top-30">
                                <p className="title-input-range" style={{minHeight: '45px'}}>Turnover
                                    {!investor.noTurnover &&
                                    <div className='data-slider-turnover'>
                                        <span className='subtext'>from</span>
                                        <span className="value">{getMoneyView(this.state.value.min)}</span>
                                        <span className='subtext'>to</span>
                                        <span className="value">{getMoneyView(this.state.value.max)}</span>
                                    </div>
                                    }
                                </p>

                                <div className={`container-input-range ${investor.noTurnover ? 'disable' : ''}`}>
                                    <InputRange
                                        minValue={5000}
                                        maxValue={5000000}
                                        step={this.state.step}
                                        value={this.state.value}
                                        onChange={value => this.onChangeRange(value)}/>
                                </div>
                                <p className="title-left-right-input-range">
                                    <span className="left">5k</span>
                                    <span className="right">5m+</span>
                                </p>
                                <div className='container-inline-checkbox' onClick={() => this.onCheck()}>
                                    <CheckBox
                                        onClick={() => {
                                        }}
                                        active={investor.noTurnover}
                                    />
                                    Turnover is not important
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(10, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(12)}
                                paddingButton={'54'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step11.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step11)
import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'

import CheckBox from "../../controls3.0/checkBox/cubeCheckBox";
import {getListIndustries} from "../../../actions/Project";
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";

let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        let industry = [];

        if(props.searchSettings.industry){
            industry = props.searchSettings.industry.map(i => parseInt(i))
        }

        this.state = {
            industryList:[],
            checkedIndustry: industry
        };
    }

    componentDidMount() {
        getListIndustries()
            .then((res) => {
                this.setState({industryList: res.data});
            })
            .catch(error => {

            });

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }

    ResetPopup() {
        this.setState({checkedIndustry:[]})
    }

    onChange = item => {
        let _checkedIndustry = this.state.checkedIndustry;

        let isFound = _checkedIndustry.find(x => x === item.id);
        if (!isFound) {
            _checkedIndustry.push(item.id)
        } else {
            _checkedIndustry = _checkedIndustry.filter(x => x !== item.id)
        }
        this.setState({checkedIndustry:_checkedIndustry});
    };

    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    render() {

        let {mobileHeight} = this.props;

        const industryI = this.state.industryList.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup" onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CheckBox
                        onClick={null}
                        active={this.state.checkedIndustry.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup search-popup-right">{item.title}</div>
            </div>
        );

        return (

            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                    <div className='search-popup search-popup-industry row-m0 no-gutters' id='industry' onClick={()=>{isPopupShouldClose = false;}}>
                        <div className='mobile-popup-header'>
                            <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                            <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        </div>

                        {/*<div className='scroll-this-display'>*/}
                            {/*<Scrollbars style={{height:'100vh'}}>*/}
                                {/*<div className='row-m0 buttons-line no-gutters w-100' style={{padding:'100px 30px 93px 30px'}}>*/}
                                    {/*{industryI && <div className="col-12 block-industry-check-box-reg-startup bl-fix-height text-left scroll-this">*/}
                                        {/*{industryI}*/}
                                    {/*</div>}*/}
                                    {/*<div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>*/}
                                    {/*<div className='col font-button'*/}
                                         {/*onClick={()=>{this.props.setSearchSettings('industry',this.state.checkedIndustry); this.ClosePopup()}}*/}
                                         {/*style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>*/}
                                {/*</div>*/}
                            {/*</Scrollbars>*/}
                        {/*</div>*/}

                        {industryI && <div className="col-12 block-industry-check-box-reg-startup bl-fix-height text-left">
                            <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this'} onScroll={this.props.onScroll}>
                                <div className='industry-mobile-padding'>
                                    {industryI}
                                </div>
                            </Scrollbars>
                        </div>}
                        <div className='row-m0 buttons-line no-gutters w-100'>

                            <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                            <div className='col font-button'
                                 onClick={()=>{this.props.setSearchSettings('industry',this.state.checkedIndustry); this.ClosePopup()}}
                                 style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                        </div>

                        <div className='mobile-popup-footer'>
                            <ButtonBlack
                                TitleButton={'Show results'}
                                EventOnClick={() => {this.props.setSearchSettings('industry',this.state.checkedIndustry); this.ClosePopup()}}
                                paddingButton={'1'}
                                disabled={false}
                            />
                        </div>
                    </div>

            </div>
        )


    }
}




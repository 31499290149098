import React, {Component} from "react";
import ApolloClient from "apollo-boost"
import { ApolloProvider} from "react-apollo"

import "./App.scss";
import "./components/css/css.css";

import "./libs/grid/grid.css";
import "./PayFont/style.css";
import "./FontAwesome5/css/fontawesome-all.min.css";

import './components/test-controls/test.css';
import './components/nominees__to__delete/style.css';
import './components/test-controls/Page2.0/css/Team.css';
import './components/css/adaptive.css'
import './components/css/notifications.css';

import Routes from './Routes'
import ErrorBoundary from './components/ErrorBoundary'
import axios from 'axios';
import config from './config';
import {generateEvent} from './actions/amplitude';
// import { I18nextProvider } from 'react-i18next';
import './i18n';
import './components/test-controls/newControlsView/styleMarcketplace.css';
import {Quality} from './components/v3/Quality';

import GodMenu from './components/header/newMenu';
axios.defaults.baseURL = config.proxy;

if (localStorage.RocketToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.RocketToken}`;
}

if(process.env.NODE_ENV === 'production'){
    console.log = function () {}
}

if(!localStorage.user_identifier || isNaN(localStorage.user_identifier)){
    axios.get('/v2/amplitude/session').then( ({data}) => {
        localStorage.setItem("user_identifier", data.nextval);
        generateEvent('launch_first_time', {});
    })
}

function reverseString(str) {
    const splitString = str.split("");
    const reverseArray = splitString.reverse();
    return reverseArray.join("");
}

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

Number.prototype.moneyFormat = function () {
    if(this == 0){
        return 0;
    }
    if(!this){
        return '';
    }

    const r = reverseString(this.toString()).split("").map((c, i) => {
        if ( (i % 3 === 0) && i > 0) {
            return ' ' + c;
        } else {
            return c;
        }
    }).join("");

    return reverseString(r);
};

String.prototype.leftTrim = function() {
    return this.replace(/^\s+/,"");
}

String.prototype.cleanWhitespace = function () {
    return this.leftTrim().replace(/  +/g, ' ').replace(/\n\n|\r\n\r\n|\r\r+/g, ' ')
}

class App extends Component {
    constructor(props) {
        super();
        this.state = {
            timestamp: 'no timestamp yet'
        };
        // subscribeToTimer((err, timestamp) => this.setState({
        //     timestamp
        // }));
    }

    componentDidMount() {
        // $('.block-team-2-0').css('padding-top', '25px')
        // $('.block-new-top-menu-rocket-dao').css('top', '25px')
        //
        // {/*<ApolloProvider client={client}>*/}
        //
        // {/*/!*<I18nextProvider i18n={ i18n }>*!/*/}
        // {/*/!**!/*/}
        // {/*/!*</I18nextProvider>*!/*/}
        // {/*</ApolloProvider>*/}
    }

    render() {
        return (
            <div className="App">
                <ErrorBoundary>
                    <Routes />
                    {/*{process.env.NODE_ENV !== 'production' && <Quality/>}*/}
                </ErrorBoundary>
                {/*<GodMenu/>*/}
            </div>
        );
    }
}

export default App;



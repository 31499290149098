import React from 'react';

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from '../../controls3.0/button/ButtonGray'
import ButtonBorder from '../../controls3.0/button/ButtonBorder'

import Mod from '../../modal/ModalForgotPassword'
import HeaderStickyRole from '../../controls3.0/headerStickyRole'

export default class ViewButton extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <div style={{margin: '10px'}}>
                    <ButtonBlue
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'55'}
                        disabled={false}
                    />
                    <ButtonBlue
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'55'}
                        disabled={true}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <ButtonBorder
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'53'}
                        disabled={false}
                    />
                    <ButtonBorder
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'53'}
                        disabled={true}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <ButtonGray
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'55'}
                        disabled={false}
                    />
                    <ButtonGray
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'55'}
                        disabled={true}
                    />
                </div>


                <div style={{margin: '10px'}}>
                    <ButtonBlue
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'70'}
                        disabled={false}
                        loader={true}
                    />
                    <ButtonBlue
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'25'}
                        disabled={false}
                        loader={true}
                    />
                </div>
                <div style={{margin: '10px'}}>
                    <ButtonBlue
                        TitleButton={'Save & Continue'}
                        EventOnClick={null}
                        paddingButton={'0'}
                        disabled={false}
                        loader={true}
                    />
                </div>


                <div style={{margin: '20px 0'}}>
                    <HeaderStickyRole

                        name={'Space Ethereum'}
                        urlAvatar={'https://apitest.rocketdao.io/file/4868/view?W=200'}


                        type={'fund'} // expert, fund, investor, startup
                        description={null}
                        location={'Saint Petersburg'}
                        languageOrSize={'$ 20M'}
                        eventButton={null} // only startup
                        customButton={null}
                        eventLike={null} // only startup
                        eventWhiteList={null} // only startup
                        eventShareLN={null} // only startup
                        eventShareFB={null} // only startup
                        eventShareTW={null} // only startup

                    />
                </div>
                <div style={{margin: '20px 0'}}>
                    <HeaderStickyRole

                        name={'Andrey Miroshnichenko'}
                        urlAvatar={null}


                        type={'expert'} // expert, fund, investor, startup
                        description={'40 simbol is developing medical'}
                        location={'Saint Petersburg'}
                        languageOrSize={'RU, EN, SP, IT, BY'}
                        eventButton={() => console.log('test')} // only startup
                        customButton={null}
                        eventLike={null} // only startup
                        eventWhiteList={null} // only startup
                        eventShareLN={null} // only startup
                        eventShareFB={null} // only startup
                        eventShareTW={null} // only startup

                    />
                </div>

                <div style={{margin: '20px 0'}}>
                    <HeaderStickyRole

                        name={'STORJ'}
                        urlAvatar={'https://apitest.rocketdao.io/file/4638/view?w=80'}


                        type={'startup'} // expert, fund, investor, startup
                        description={null}
                        location={null}
                        languageOrSize={null}
                        eventButton={() => console.log('test')} // only startup
                        customButton={null}
                        eventLike={null} // only startup
                        eventWhiteList={null} // only startup
                        eventShareLN={null} // only startup
                        eventShareFB={null} // only startup
                        eventShareTW={null} // only startup

                    />
                </div>

                <div style={{margin: '20px 0'}}>
                    <HeaderStickyRole

                        name={'Angelina Dmitruk'}
                        urlAvatar={'https://apitest.rocketdao.io/file/4940/view?w=80'}


                        type={'investor'} // expert, fund, investor, startup
                        description={'40 simbol is developing medical 40 simbol is developing medical'}
                        location={'Saint Petersburg'}
                        languageOrSize={'RU, EN, SP, IT, BY'}
                        eventButton={() => console.log('test')} // only startup
                        customButton={null}
                        eventLike={null} // only startup
                        eventWhiteList={null} // only startup
                        eventShareLN={null} // only startup
                        eventShareFB={null} // only startup
                        eventShareTW={null} // only startup
                    />
                </div>


            </div>
        );
    }
}


import { combineReducers } from "redux";
import { auth } from "./auth";
import { project } from "./project";
import { angel } from "./angel";
import { expert } from "./expert";
import { message } from "./messages";
import { loader } from "./common";
import { fund } from "./fund";
import { menu, expertAchMenu, Market } from "./menu";
import { accelerator } from "./accelerator";

export default combineReducers({
    auth,
    message,
    loader,
    fund,
    project,
    angel,
    menu,
    accelerator,
    expertAchMenu, Market
    // expert
});
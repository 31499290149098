import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";

// import ButtonBlue from './../../controls3.0/button/ButtonBlue'
// import ButtonGray from "../../controls3.0/button/ButtonGray";
import FooterImg from "../../../images/svg/RocketDAO-finish-accelerator.svg";



class Step18 extends React.Component {
    constructor(props) {
        super();
        let accelerator = props.accelerator;

        console.log('Step18', accelerator);

        this.state = {
            accelerator: accelerator,
            errors: {},
        }
    }

    render () {
        let {accelerator} = this.state;

        return (
            <React.Fragment>
                <div className="container-step-1-reg"
                     style={{position: 'relative', margin: '0 0 0 auto'}}>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15 color-rgba-88">
                        We welcome you to Rocket DAO!
                    </p>

                    <p className="title-top-page-reg-expert text-h6 left-text shell-margin-bottom-15 color-rgba-88">
                        Your accelerator {accelerator.title} <br/>
                        has been successfully registered!
                    </p>
                    <p className="title-top-page-reg-expert text-body left-text shell-margin-bottom-60 color-rgba-88">
                        It is a pleasure for us to welcome you on Rocket DAO.<br/>
                        In case of any queries regarding the platform's tools please feel free to contact us<br/> and we will gladly assist you.
                        <br/><br/>
                        Best regards, Rocket DAO team.
                    </p>


                    <div className="shell-button-reg-expert">
                        <div className="block-button-big-reg-expert" style={{width: '240px'}}
                             onClick={() =>  window.location.href='/'}>
                            <p className="title">Go to rocketdao.io</p>
                        </div>
                    </div>
                </div>
                <div className={'block-img-footer'} >
                    <img src={FooterImg} style={{position:'relative', bottom: '180px', left: '85px'}} alt="footer_img"/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step18.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step18)
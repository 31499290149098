import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TelegramLoginButton from 'react-telegram-login';

import {signUpTelegram, updateStartup} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import illustration from '../../../images/svg/reminder_notify.svg'
import {regexEmail, regexUrl} from "../../../utils/RegExp";
import Swipe from "../../controls3.0/swipe/Swipe";
import {getProfileSettings, putProfileSettings, putProfile} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import {webNotification} from "../../../utils/webNotification";
import packageJSON from './../../../../package.json'
import ButtonSocialReg from '../../controls3.0/buttonSocialReg/ButtonSocialReg'
import {getLastProject, updateProject, sendInvitation} from "../../../actions/Project";
import {COUNTRIES} from "../../../utils/countriesLanguages";
import Select from "../../controls3.0/select/Select";
import SelectWithAdd from "../../controls3.0/select/SelectWithAdd";
import CheckBox from "../../controls3.0/checkBox/checkBox";
import ListMailMore from '../../controls3.0/listMailMore/ListMailMore'
import AvatarImg from './../../controls3.0/AvatarImg'
import plen18 from '../../../images/svg/Group 17.svg'
import Group21 from '../../../images/svg/Group21.png'
import axios from "axios";

class Step15 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {team: []},
            errors: {},
            iAmFounder: false,
            myPosition: [
                {
                    Name: '',
                },
            ],
            email: '',
            telegram: null,
            whatsapp: null,
            facebook: null,
            invitationsSent: false,
            positionList: [],
            myPositionList: []
        }
    }

    componentDidMount() {

        this.getPositionList('A');
        this.getMyPositionList('A');

        if (!this.state.project.id) {
            this.props.getLastProject()
            this.setRightState();
        } else {
            this.setRightState()
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project}, () => this.setRightState())
        }
    }

    setRightState = () => {
        let team = this.state.project.team
        if (team.length) this.setState({invitationsSent: team.length > 1});

        let iAm = team.find((item) => item.userId === this.props.user.id);

        if (iAm) {
            const filtered = team.filter((item) => item.userId !== this.props.user.id);
            this.setState({ project: {...this.state.project, team: filtered} });

            let myPosition = this.state.myPosition
            myPosition[0] = {Name: iAm.position}

            this.setState({
                myPosition: myPosition,
                iAmFounder: iAm.isFounder,
                telegram: iAm.telegram,
                whatsapp: iAm.whatsapp,
                facebook: iAm.facebook,
            });
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value.trim();


        if (regexEmail.test(value)) {
            const errors = this.state.errors;
            delete errors.email;

            this.setState({errors})
        } else {
            this.setState({errors: {...this.state.errors, [name]: 'Invalid e-mail address'}})
        }

        if(this.state.project.team.find((item) => item.email === value) || this.props.user.email === value){
            this.setState({errors: {...this.state.errors, [name]: 'E-mail address exist'}})
        }

        this.setState({[name]: value})
    }

    onChangeMembersCount = e => {
        debugger;
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");
        if(value) value = parseInt(value);
        if(value <= 0 || value > 99999){
            this.setState({errors: {...this.state.errors, [name]: 'Min 1 and max 99999'}})
        } else {
            const errors = this.state.errors;
            delete errors[name];
            this.setState({errors: errors});

            this.setState({project: {...this.state.project, [name]: value}})
        }
    }

    onChangeMyLinks = (e, name2) => {
        let { name, value } = e.target;
        value = value.trim().cleanWhitespace();
        let errors = this.state.errors;

        if(!value.length){
            delete errors[name];
        } /*else if (value && regexUrl.test(value)) {
            delete errors[name]
            this.setState({ errors: errors });
        } else {
            this.setState({ errors: { ...this.state.errors, [name]: 'Wrong format' }});
        }*/

        this.setState({ [name]: value }, () => {
            console.log(this.state[name]);
        });
    }

    onChangeIAmFounder = () => {
        let iAmFounder = this.state.iAmFounder;
        this.setState({iAmFounder: !iAmFounder})
    }

    onChangeFounder = () => {
        let isFounder = this.state.isFounder;
        this.setState({isFounder: !isFounder})
    }

    onKeyDown1 = (e) => {
        this.getPositionList(e.target.value);
    }

    onKeyDown2 = (e) => {
        this.getMyPositionList(e.target.value);
    }


    getPositionList(filter){
        if(filter){
            axios
                .get('/v2/projects/positions/'+filter)
                .then(r => {
                    this.setState({positionList: r.data})
                })
        }
    }

    getMyPositionList(filter){
        if(filter){
            axios
                .get('/v2/projects/positions/'+filter)
                .then(r => {
                    this.setState({myPositionList: r.data})
                })
        }
    }

    onChangeMyPosition = myPosition => {
        this.setState({myPosition})
    }

    onChangePosition = position => {
        this.setState({position})
    }

    addToTeam = () => {
        let {email, position, isFounder} = this.state;
        if (!regexEmail.test(email)) {
            this.setState({errors: {...this.state.errors, email: 'Invalid e-mail address'}})
            return;
        }
        let obj = {
            email: email,
            position: position[0].Name,
            isFounder: isFounder
        }
        this.setState({project: {...this.state.project, team: [...this.state.project.team, obj]}}, () => {
            this.setState({email: '', isFounder: false})
            let position = this.state.position;
            position[0] = {Name: ''}
            this.setState({position})
        })
    }

    removeFromList = email => {
        let team = this.state.project.team,
            filtered = team.filter((item) => {
                return item.email !== email
            })
        this.setState({project: {...this.state.project, team: filtered}})
    }

    send = () => {
        let { project, telegram, whatsapp, facebook} = this.state;
        let team = [...project.team];
        const member = {
            position: this.state.myPosition[0].Name,
            isFounder: this.state.iAmFounder,
            memberId: this.props.user.id,
            email: this.props.user.email
        };
        if (telegram) {
            member.telegram = telegram
        }
        if (whatsapp) {
            member.whatsapp = whatsapp
        }
        if (facebook) {
            member.facebook = facebook
        }
        team.push(member);
        this.setState({loading2: true})
        sendInvitation(project.id, {members: team})
            .then((res) => {
                this.setState({invitationsSent: team.length > 1})
                this.setState({loading2: false})
            })
            .catch(error => {
                this.setState({loading2: false})
            })

    }

    update = async (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            membersCount: project.membersCount,
            invitations: project.team.length,
            event_type: 'startup_team_16'
        }

        await this.send();

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            membersCount: project.membersCount
        }

        this.props.updateStartup(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    disableInvite = () => {
        try {
            return !this.state.email || !this.state.position.length || this.state.position[0].Name.length === 0 || Object.keys(this.state.errors).length;
        } catch (e) {
            return true;
        }
    }


    render() {
        let {project, errors, telegram, whatsapp, facebook} = this.state;
        let user = this.props.user;

        let team = project.team.map((item, key) => {
            return (
                <div key={key} className={'row'}
                     style={{padding: '15px', border: '1px solid grey', marginBottom: '10px'}}>
                    <div className="col-8">
                        <div>{item.email}</div>
                        <div>{item.position}</div>
                    </div>
                    <div className="col-4" style={{color: '#b3b2b2'}}>
                        invitation sent
                    </div>
                </div>
            )
        })

        return (
            <React.Fragment>
                {!this.state.invitationsSent ?
                    <React.Fragment>
                        <div className="step-1 is-startup">
                            <div className="block-reg-startup-step-2 padding-reg">
                                <div className="container-full-reg" style={{padding: '0 0 30px 0'}}>
                                    <div className="container-step-1-reg">
                                        <p className="text-h1 color-text-reg-gray margin-0">
                                            Team and founders
                                        </p>
                                        <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                            A strong team is the crucial success factor of any startup, isn’t it?
                                        </p>

                                        <div className="block-user-profile-reg-startup">
                                            <div className="block-left-user-profile-reg-startup new-width-100-down-1199">
                                                <div className="block-user-cart-user-profile-reg-startup">
                                                    <div className="block-left-user-cart-user-profile-reg-startup">
                                                        <div className="block-img-user-profile">
                                                            <AvatarImg id={user.logoFileId}/>
                                                        </div>
                                                    </div>
                                                    <div className="block-right-user-cart-user-profile-reg-startup">
                                                        <p className="title-user-profile-reg-startup">
                                                            What is your role in the project?
                                                        </p>
                                                        <p className="check-box-user-profile-reg-startup">
                                                            <CheckBox
                                                                active={this.state.iAmFounder}
                                                                onClick={this.onChangeIAmFounder}
                                                            />
                                                            Founder
                                                        </p>
                                                        <div className="block-select-user-profile-reg-startup">
                                                            <SelectWithAdd
                                                                placeholder={'Enter your position'}
                                                                data={this.state.myPositionList}
                                                                value={this.state.myPosition}
                                                                onKeyUp={(e) => this.onKeyDown2(e)}
                                                                onChange={(e) => this.onChangeMyPosition(e)}
                                                                error={''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-right-user-profile-reg-startup new-width-100-down-1199">
                                                <p className="title-input-user">
                                                    Now please tell us how many <br/>
                                                    members are there in your team.
                                                </p>
                                                <div className="block-small-input bold-input">
                                                    <Input
                                                        type={'text'}
                                                        name={'membersCount'}
                                                        value={project.membersCount}
                                                        onChange={this.onChangeMembersCount}
                                                        error={errors.membersCount}
                                                        placeholder={''}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='member-links'>
                                                <div className='input-wrap blue-icon color-icon-black-blue block-input-min' style={{width: '100%'}}>
                                                    <Input
                                                        type={'text'}
                                                        name={'telegram'}
                                                        value={telegram}
                                                        onChange={this.onChangeMyLinks}
                                                        placeholder={'t.me/NICK_NAME'}
                                                        iconClass={'fab fa-telegram'}
                                                        error={errors.telegram}
                                                    />
                                                </div>
                                                <div className='input-wrap blue-icon color-icon-black-blue block-input-min' style={{width: '100%'}}>
                                                    <Input
                                                        type={'text'}
                                                        name={'whatsapp'}
                                                        value={whatsapp}
                                                        onChange={this.onChangeMyLinks}
                                                        placeholder={'wa.me/+12343223'}
                                                        iconClass={'fab fa-whatsapp'}
                                                        error={errors.whatsapp}
                                                    />
                                                </div>
                                                <div className='input-wrap blue-icon color-icon-black-blue block-input-min' style={{width: '100%'}}>
                                                    <Input
                                                        type={'text'}
                                                        name={'facebook'}
                                                        value={facebook}
                                                        onChange={this.onChangeMyLinks}
                                                        placeholder={'facebook.com/nickname'}
                                                        iconClass={'fab fa-facebook-f'}
                                                        error={errors.facebook}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="block-section-gradient">
                                            <div className="block-gradient new-top-auto-down-1199"
                                                 style={{ padding: '35px 0 0 0'}}></div>
                                            <p className="text-h5 color-text-reg-gray margin-0 shell-margin-top-60 ">
                                                Add team members to your project’s profile
                                            </p>
                                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-25">
                                                Send them invitations to join the platform.
                                            </p>
                                            <div className="block-list-input-email">
                                                <div className="block-left-list-input-email new-width-100-down-900">
                                                    <div className="block-input-reg">
                                                        <p className="title-input-reg">Enter email here</p>
                                                        <Input
                                                            type={'text'}
                                                            name={'email'}
                                                            value={this.state.email}
                                                            onChange={this.onChange}
                                                            error={errors.email}
                                                            placeholder={'Enter email'}
                                                        />
                                                    </div>
                                                    <div className="block-select-reg">
                                                        <p className="title-input-reg">Select position</p>
                                                        <SelectWithAdd
                                                            placeholder={'Select'}
                                                            data={this.state.positionList}
                                                            value={this.state.position}
                                                            onKeyUp={(e) => this.onKeyDown1(e)}
                                                            onChange={(e) => this.onChangePosition(e)}
                                                            error={''}
                                                        />
                                                    </div>
                                                    <div className="block-button-list-input-email">

                                                        <ButtonBlue
                                                            TitleButton={'Invite'}
                                                            EventOnClick={() => this.addToTeam()}
                                                            paddingButton={'48'}
                                                            disabled={this.disableInvite()}
                                                            loader={this.state.sending}
                                                        />

                                                        <p className="check-box-user-profile-reg-startup">
                                                            <CheckBox
                                                                active={this.state.isFounder}
                                                                onClick={this.onChangeFounder}
                                                            />
                                                            Founder
                                                        </p>
                                                    </div>

                                                </div>
                                                {/*Mail:'alexarch@69pixels.com',*/}
                                                {/*Pos:'Founder, Positioning Strategy for Startups',*/}
                                                <div className="block-right-list-input-email new-width-100-down-900">
                                                    <ListMailMore
                                                        data={project.team}
                                                        onChange={(e) => console.log(e)}
                                                        viewCountItem={3}
                                                        removeFromList={this.removeFromList}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="block-fix-button-reg-bottom fix-button-double">
                            <div className="container-full-reg">
                                <div className="block-button-left">
                                    <ButtonGray
                                        TitleButton={'Back'}
                                        EventOnClick={() => this.update(14, true)}
                                        paddingButton={'55'}
                                        disabled={false}
                                    />
                                </div>
                                <div className="block-button-right">
                                    {!project.team.length ?
                                        <ButtonBlue
                                            TitleButton={'Save & Continue'}
                                            EventOnClick={() => this.update(21)}
                                            paddingButton={'54'}
                                            disabled={!this.state.myPosition[0].Name || !project.membersCount || !(telegram || whatsapp)}
                                            loader={this.state.loading}
                                        />

                                        :
                                        <ButtonBlue
                                            TitleButton={(project.team.length>1)?'Send invitations':'Send invitation'}
                                            EventOnClick={() => this.send()}
                                            paddingButton={'54'}
                                            disabled={!this.state.myPosition[0].Name || !project.membersCount}
                                            loader={this.state.loading2}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="container-full-reg">
                            <div className="container-step-1-reg">
                                <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                    {(project.team.length>1)?'Invitations are successfully sent!':'Invitation is successfully sent!' }
                                </p>

                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                                    You will be notified when other team memebrs of your startup register on the
                                    platform <br/>
                                    via your invitation link.
                                </p>


                                <div className='step-21-btm clearfix' style={{padding:'0 0 250px 0'}}>
                                    <div className='s21-left inv-succ no-float-620'>
                                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15 mb">
                                            {(project.team.length>1)?'We have sent invitations to these emails:':'We have sent invitation to these email:' }
                                        </p>
                                        <div>
                                            {project.team.map((item, key) =>
                                                <span className='item-email-sent'>{item.email}</span>
                                            )}
                                        </div>


                                    </div>
                                    <div className='s21-right inv-succ no-float-620'>
                                        <img src={Group21} alt="" className=""/>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="block-fix-button-reg-bottom fix-button-one">
                            <div className="container-full-reg">

                                <div className="block-button-right">
                                    <ButtonBlue
                                        TitleButton={'Continue'}
                                        EventOnClick={() => this.update(21)}
                                        paddingButton={'56'}
                                        disabled={false}
                                        loader={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                }
            </React.Fragment>

        )
    }
};


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step15.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step15)


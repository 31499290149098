import React from 'react';
import './LikeStyle.css'

export default class LikeBlockToList extends React.Component {

    render() {
    
        let {
            avatar,
            name,
            descr,
            time
        } = this.props;

        
        return (
        <div className='like-block-to-list'>  
            <div className='main-block'>
                <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                <div className='subtitle'>
                    <div className='name-line'>
                        {name}
                    </div>
                    <div className='descr-line'>
                        {descr}
                    </div>
                </div>
            </div>
            <div className='block-info'>

                <div className='time-title'>{time}</div>
                <div className='icon-like'/>
            </div>
            <div className='stripe'/>  
        </div>    
        );
    }
}
import React from 'react';

import './style.css'
import $ from "jquery";


export default class QuestionReview extends React.Component {

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');

        if(classBlock === '.open-close-block'){
            this.setState({showWork: !this.state.showWork})
        }

    }

    QAList(QAList) {
        const showAnswer = (item)=> {
            let i = -1;
            let listAnswer = item.answer.map((answerItem, key)=>{
                i++;
                return <span>
                    {i%2===0 && <span>{answerItem}</span>}
                    {i%2!==0 && <a href={answerItem} target='_blank'>{answerItem}</a>}
                </span>

            })

            return listAnswer;
        };

        let isLink = (QAList.answer === '');
        let isArray = false;
        let list = QAList.map((item, key) => {
            isArray = Array.isArray(item.answer);


            return <div key={key} className='w-100'>
                <div className='row-b sides-text font-weight-500 color-rgba-08 no-gutters'>{item.question}</div>
                {!isLink && !isArray && <div className='row-b sides-text font-weight-300 color-rgba-08 no-gutters margin-top-10 shell-margin-bottom-15 answer-block'>
                    {item.answer}
                </div>}
                {!isLink && isArray && <div className='row-b sides-text font-weight-300 color-rgba-08 no-gutters margin-top-10 shell-margin-bottom-15 answer-block'>
                    {showAnswer(item)}


                </div>}
                {isLink && <div className='row-b sides-text font-weight-300 color-rgba-08 no-gutters margin-top-10 shell-margin-bottom-15 answer-block'>
                    Product is bought (small number of customers).
                    Website <a href='https://parsers.me/' target='_blank'>https://parsers.me/</a>The number of users is growing every month.
                    Now we have a growing number of active users in the browser extension Chrome <a href='https://chrome.google.com/webstore/detail/scraper-parsers-free-web/mhfjedhbggbodliofccpefegbmaoohin' target='_blank'>https://chrome.google.com/webstore/det...</a>
                    Pages for displaying and analyzing scraped data. For example <a href='https://parsers.me/pars/list/55d95988-6161-40d0-a5c7-bac042799085' target='_blank'>https://parsers.me/pars/list/55d95988-6161-40d0...</a>
                    In closed beta testing, search by structured data <a href='https://parsers.me/pars/data/' target='_blank'>https://parsers.me/pars/data/</a>
                </div>}
            </div>
        });
        return list;
    }


    render() {

        let {
            ValueName,
            ValueEval,
            QAList,
            expertRewiew
        } = this.props;

        return (
                        <div className="row-b no-gutters block-question-review">
                            <div className="row-b no-gutters block-question cursor-pointer" style={{display: 'table', width: '100%'}}
                                 onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>

                                <div className={`left-block-value-name value-name-text font-weight-500 color-rgba-08 ${ValueName.length > 26?'long-value-name':''}`}>
                                    {ValueName}
                                </div>
                                <div className="block-button-open-close float-right">
                                    <i className="far fa-chevron-down"/>
                                </div>
                                <div className='block-value-eval-number float-right'>
                                    <div className='eval-num-text'>{ValueEval}<span>/10</span></div>
                                </div>
                            </div>
                            <div className="open-close-block-description-item-work-experience padding-bottom-15 padding-top-5">
                                {this.QAList(QAList)}
                                { expertRewiew && <div className='row-b expert-review-block no-gutters margin-top-25'>
                                    <div className='w-100 expert-review-text'>Expert review</div>
                                    <div className='sides-text color-rgba-08 margin-top-10'>{expertRewiew}</div>
                                </div>
                                }
                            </div>
                        </div>
        )
    }
}
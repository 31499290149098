import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {NotificationContainer, NotificationManager} from 'react-notifications';

import {logout, tokenLogin, updateUser} from "../../actions/Login";

import {createAccelerator} from "../../actions/Accelerator";

import ButtonBlue from './../controls3.0/button/ButtonBlue'
import ButtonGray from "../controls3.0/button/ButtonGray";
import Header from '../header'

import iconPrivate from '../../images/private_accelerator.svg'
import iconCorporate from '../../images/corporate_accelerator.svg'
import iconGovernment from '../../images/government_accelerator.svg'
import CheckBox from "../controls3.0/checkBox/checkBox";

const types = {
    1: {
        id: 1,
        title: 'Private',
        descr1: 'non-corporate accelerator',
        descr2: 'Usually, entrepreneurs who have enough wealth to help others both with financial assistance and mentor support. This will be your own public profile. If you belong to any Angel Network or VC Fund, you will be able to indicate them as well.'
    },
    2: {
        id: 2,
        title: 'Corporate',
        descr1: 'run accelerator / incubator',
        descr2: 'Group of angel investors organized to invest jointly, operate more effectively and provide mutual support. Also known as angel groups. To register a Network you have to be the owner of it. So you will be the only one to have access to editing and managing public profile, to inviting other investors to join the platform and your Network.'
    },
    3: {
        id: 3,
        title: 'Government',
        descr1: 'sponsored accelerator',
        descr2: 'Funds that manage the money of investors seeking private equity stakes in startups with strong growth potential. These are typically high-risk and high-return investments. To register a Fund you have to be the owner of it. So you will be the only one to have access to editing and managing oublic profile, to inviting other investors to join the platform and your Fund.'
    }
};

class InvestorStart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            view2: false
        }

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        } else {
            localStorage.setItem('referer', this.props.history.location.pathname);
            this.props.history.push(`/start_reg?type=7`);
        }
    }

    componentDidMount() {

    }

    setActive = active => {
        this.setState({active})
    }

    go = () => {
        const {active} = this.state;
        this.props.createAccelerator({
            type: types[active].title
        })
        // this.props.history.replace('/create/accelerator/1');
    }

    render() {
        let user = this.props.user || {}

        const list = Object.keys(types).map((item, key) =>
            <div key={key}
                 className={this.state.active == item.id ? 'block-item-cart-reg-invest active' : 'block-item-cart-reg-invest'}>

                <div className='block-checkbox-front-cart'>
                    <CheckBox
                        active={this.state.active == item.id?true:false}
                    />
                </div>

                <div className="block-icon-cart-reg-invest block-icon-accelerator">
                    <img src={key == 0 ? iconPrivate : iconPrivate} alt=""/>
                </div>
                <div className="block-title-cart-reg-invest block-title-cart-accelerator">
                    <p className="title-cart-reg-invest">
                        {item.title} 1
                    </p>
                </div>
                <div className="block-description-cart-reg-invest">
                    <p className="description-cart-reg-invest">
                        {item.descr1}
                    </p>
                </div>
                <div className="block-big-description-cart-reg-invest">
                    <p className="big-description-cart-reg-invest">
                        {item.descr2}
                    </p>
                </div>
                <div className="shell-button-cart">
                    <div className="block-button-cart" onClick={() => this.setActive(item.id)}>
                        <p className="title-button-cart">
                            {this.state.active.id == item.id ? 'Cancel' : 'Choose'}
                        </p>
                    </div>
                </div>
            </div>
        )


        if (!user.id) return <div><NotificationContainer/></div>

        return (
            <div className='reg-wrap'>
                <Header user={this.props.user} project={this.props.project || {}} registrationView={true}/>
                <div className="container-100-reg">
                    <div className="container-global-reg">
                        {!this.state.view2 ?
                            <React.Fragment>
                                <div className="step-1">
                                    <div className="wrap-white" style={{paddingBottom:'16px'}}>
                                        <div className="container-full-reg" style={{position: 'relative'}}>
                                            <p className="text-h1">
                                                &#8212; Welcome, {user.firstName}
                                            </p>
                                            <div className={'pg-left'}>
                                                <p className="text-h5">
                                                    It’s time to start a unicorns’ hunting!
                                                </p>
                                                <p className="text-h6" style={{maxWidth: '800px'}}>
                                                    In just a few steps you will become a part of Rocket DAO
                                                    - an international venture community and a platform where startups
                                                    get expert support and connect with investors.
                                                    <br/><br/>
                                                    Please note that registration takes about 10 minutes,
                                                    you will be able to continue registration or edit your profile at any convenient time.

                                                </p>
                                            </div>
                                        </div>
                                    </div>




                                    <div className="container-full-reg container-bottom block-gradient-gray-reg"
                                         style={{position: 'relative'}}>
                                        {/*<div className="block-gradient padding-top-70 margin-top-40"></div>*/}
                                        <div className="block-list-cart-start-reg block-cont-investor" style={{padding:'73px 0 250px 0'}}>
                                            <div className="block-item-cart-start-reg"
                                                 onClick={() => this.update(2, true)}>
                                                <div className='numb active'>01</div>
                                                <p className='text-h4 test'>Create profile</p>
                                                <div className={'btm-text'}>
                                                    Fill in some basic, still very important <br/>
                                                    information about: <br/>
                                                    - specifics of your <b>accelerator</b>, <br/>
                                                    - your <b>expertise</b> and <b>experience</b>; <br/>
                                                    - your <b>investing interests</b>. <br/>
                                                </div>
                                            </div>
                                            <div className={'div-flex'}>
                                                <span></span>
                                            </div>
                                            <div className="block-item-cart-start-reg">
                                                <div className='numb'>02</div>
                                                <p className='text-h4 no-active'>Get data</p>
                                                <div className={'btm-text no-active'}>
                                                    You will get access to: <br/>
                                                    - startups and experts <b>database</b>, <br/>
                                                    - professional <b>business plans</b>; <br/>
                                                    - regularly updated <b>knowledge base</b>; <br/>
                                                    - crowd startup <b>Due Diligence</b> reports.
                                                </div>
                                            </div>
                                            <div className={'div-flex'}>
                                                <span></span>
                                            </div>
                                            <div className="block-item-cart-start-reg">
                                                <div className='numb '>03</div>
                                                <p className='text-h4 no-active'>Invest smartly</p>
                                                <div className={'btm-text no-active'}>
                                                    You will be provided with: <br/>
                                                    - tools for <b>joint investments</b> based on smart contracts; <br/>
                                                    - unbiased <b>expert reviews</b> of promising startups; <br/>
                                                    - <b>search filters</b> fitting your interests. <br/>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="block-fix-button-reg-bottom fix-button-one">
                                            <div className="container-full-reg">

                                                <div className="block-button-right">
                                                    <ButtonBlue
                                                        TitleButton={'Ok, got it!'}
                                                        EventOnClick={() => this.setState({view2: true})}
                                                        paddingButton={'80'}
                                                        disabled={false}
                                                        loader={this.state.loading}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="step-1">
                                    <div className="wrap-white">
                                        <div className="container-full-reg" style={{position: 'relative'}}>


                                            <p className="text-h1">
                                                Type of accelerator
                                            </p>

                                            {/*<p className="text-body"><b>“The most meaningful way to succeed is to help others succeed.”</b><br/>— Adam Grant, organizational psychologist.</p>*/}

                                            <p className="text-body margin-0 shell-margin-top-20 color-rgba-88 font-weight-300" style={{maxWidth: '680px'}}>
                                                There are different types of accelerators. It is helpful to identify which of the below classification corresponds with yours.
                                                <br/><br/>
                                                Please note that registering an accelerator (which is a legal entity) you will have to provide a legal document to prove ownership rights.

                                            </p>

                                        </div>
                                    </div>

                                    <div className="container-full-reg container-bottom"
                                         style={{position: 'relative'}}>
                                        <div className="block-list-cart-start-reg" style={{padding:'0 0 250px 0'}}>
                                            <div
                                                className="block-list-cart-reg-startup block-gradient-gray-reg padding-top-50 test">

                                                <div
                                                    className={this.state.active == 1 ? 'block-item-cart-reg-invest active' : 'block-item-cart-reg-invest'}>
                                                    <div className='block-checkbox-front-cart'>
                                                        <CheckBox
                                                            active={this.state.active == 1?true:false}
                                                        />
                                                    </div>
                                                    <div className="block-icon-cart-reg-invest block-icon-accelerator">
                                                        <img src={iconPrivate} alt=""/>
                                                    </div>
                                                    <div className="block-title-cart-reg-invest block-title-cart-accelerator">
                                                        <p className="title-cart-reg-invest description-cart-reg-accelerator font-weight-500">
                                                            Private
                                                        </p>
                                                    </div>
                                                    <div className="block-description-cart-reg-invest">
                                                        <p className="description-cart-reg-invest description-cart-reg-accelerator font-weight-300">
                                                            non-corporate accelerator
                                                        </p>
                                                    </div>
                                                    <div className="block-big-description-cart-reg-invest">
                                                        <p className="big-description-cart-reg-invest">
                                                            These accelerators offer startups access to seed money mostly and
                                                            potential follow-up investments (typically from $20k - $120k),
                                                            mentorship, connections. <br/><br/>
                                                            Private accelerators focus on helping startups reach their
                                                            product market fit, initiate business network ans start their first sales.
                                                        </p>
                                                    </div>
                                                    <div className="shell-button-cart">
                                                        <div className="block-button-cart"
                                                             onClick={() => this.setActive(1)}>
                                                            <p className="title-button-cart">
                                                                {this.state.active == 1 ? 'Cancel' : 'Choose'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={this.state.active == 2 ? 'block-item-cart-reg-invest active' : 'block-item-cart-reg-invest'}>
                                                    <div className='block-checkbox-front-cart'>
                                                        <CheckBox
                                                            active={this.state.active == 2?true:false}
                                                        />
                                                    </div>
                                                    <div className="block-icon-cart-reg-invest block-icon-accelerator">
                                                        <img src={iconCorporate} alt=""/>
                                                    </div>
                                                    <div className="block-title-cart-reg-invest block-title-cart-accelerator">
                                                        <p className="title-cart-reg-invest description-cart-reg-accelerator font-weight-500">
                                                            Corporate
                                                        </p>
                                                    </div>
                                                    <div className="block-description-cart-reg-invest">
                                                        <p className="description-cart-reg-invest description-cart-reg-accelerator font-weight-300">
                                                            run accelerator / incubator
                                                        </p>
                                                    </div>
                                                    <div className="block-big-description-cart-reg-invest">
                                                        <p className="big-description-cart-reg-invest">
                                                            These accelerators provide established startups with a nurturing
                                                            environment to grow. <br/><br/>
                                                            Some of them are industry-agnostic, other focus on specific verticals of the corporation.
                                                            <br/><br/>
                                                            The backing corporation gains by being close innovative ideas and many times does not charge equity.
                                                        </p>
                                                    </div>
                                                    <div className="shell-button-cart">
                                                        <div className="block-button-cart"
                                                             onClick={() => this.setActive(2)}>
                                                            <p className="title-button-cart">
                                                                {this.state.active == 2 ? 'Cancel' : 'Choose'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={this.state.active == 3 ? 'block-item-cart-reg-invest active' : 'block-item-cart-reg-invest'}>
                                                    <div className='block-checkbox-front-cart'>
                                                        <CheckBox
                                                            active={this.state.active == 3?true:false}
                                                        />
                                                    </div>
                                                    <div className="block-icon-cart-reg-invest block-icon-government">
                                                        <img src={iconGovernment} alt=""/>
                                                    </div>
                                                    <div className="block-title-cart-reg-invest block-title-cart-accelerator">
                                                        <p className="title-cart-reg-invest description-cart-reg-accelerator font-weight-500">
                                                            Government
                                                        </p>
                                                    </div>
                                                    <div className="block-description-cart-reg-invest">
                                                        <p className="description-cart-reg-invest description-cart-reg-accelerator font-weight-300">
                                                            sponsored accelerator
                                                        </p>
                                                    </div>
                                                    <div className="block-big-description-cart-reg-invest">
                                                        <p className="big-description-cart-reg-invest">
                                                           These are non-profit accelerators, either funded by the governments or for-profit organizations.
                                                            <br/><br/>
                                                            Government supported accelerators emphasize integration between government entities,
                                                            private sector, academic institutions, and NGOs with the aim of driving innovation.
                                                        </p>
                                                    </div>
                                                    <div className="shell-button-cart">
                                                        <div className="block-button-cart"
                                                             onClick={() => this.setActive(3)}>
                                                            <p className="title-button-cart">
                                                                {this.state.active == 3 ? 'Cancel' : 'Choose'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                        <div className="block-fix-button-reg-bottom fix-button-double">
                                            <div className="container-full-reg">
                                                <div className="block-button-left">
                                                    <ButtonGray
                                                        TitleButton={'Back'}
                                                        EventOnClick={() => this.setState({view2: false})}
                                                        paddingButton={'55'}
                                                        disabled={false}
                                                    />
                                                </div>
                                                <div className="block-button-right">
                                                    <ButtonBlue
                                                        TitleButton={'Save & Continue'}
                                                        EventOnClick={() => this.go()}
                                                        paddingButton={'40'}
                                                        disabled={!this.state.active}
                                                        loader={this.state.loading}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>

                            </React.Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    tokenLogin: PropTypes.func.isRequired,
    user: state.auth.user,
    accelerator: state.acceleration
})

export default connect(mapStateToProps, {tokenLogin, createAccelerator})(InvestorStart)
import React from 'react'
import uploadImg from '../../images/join-upload.png'
import {signup} from '../../actions/UserSignup'
import Dropzone from 'react-dropzone'


import AvatarEditor from 'react-avatar-editor'

export default class SignupPhoto extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      file: null,
      registered: false,
      error: '',
      errorFile: '',
      defaultPreview: null,
      newAvatar: false,
      answer: {},

      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 100,
      preview: null,
      width: 200,
      height: 200
    };

    this.onChange = this.onChange.bind(this)
    this.createAccount = this.createAccount.bind(this)
    this.sendToServer = this.sendToServer.bind(this)
    // this.onCrop = this.onCrop.bind(this)
    // this.onCropDefault = this.onCropDefault.bind(this)
    // this.onCloseDefault = this.onCloseDefault.bind(this)
  }

  // onCropDefault(preview) {
  //   this.setState({defaultPreview: preview, newAvatar: true})
  //
  //   var binary = atob(preview.split(',')[1]);
  //   var array = [];
  //   for(var i = 0; i < binary.length; i++) {
  //     array.push(binary.charCodeAt(i));
  //   }
  //   var img =  new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  //   this.setState({file: img})
  // }
  //
  // onCrop(preview) {
  //   this.setState({preview})
  // }
  // onCloseDefault() {
  //   this.setState({defaultPreview: null, newAvatar: false, preview: null})
  // }

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  onDrop(accepted, rejected) {
    if (accepted.length) {
      this.setState({image: accepted[0]});
    }
    if (rejected.length) {
      this.setState({fileError: 'The uploaded file seems to have incorrect format'})
    }
  }

  handleSave = data => {
    const img = this.editor.getImageScaledToCanvas().toDataURL()
    const rect = this.editor.getCroppingRect()

    this.setState({
      preview: {
        img,
        rect,
        scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius
      }
    })
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
    this.setState({ allowZoomOut })
  }

  rotateLeft = e => {
    e.preventDefault()

    this.setState({
      rotate: this.state.rotate - 90
    })
  }

  rotateRight = e => {
    e.preventDefault()
    this.setState({
      rotate: this.state.rotate + 90
    })
  }

  handleBorderRadius = e => {
    const borderRadius = parseInt(e.target.value)
    this.setState({ borderRadius })
  }

  handleXPosition = e => {
    const x = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, x } })
  }

  handleYPosition = e => {
    const y = parseFloat(e.target.value)
    this.setState({ position: { ...this.state.position, y } })
  }

  handleWidth = e => {
    const width = parseInt(e.target.value)
    this.setState({ width })
  }

  handleHeight = e => {
    const height = parseInt(e.target.value)
    this.setState({ height })
  }

  logCallback (e) {
    console.log('callback', e)
  }

  setEditorRef = editor => {
    if (editor) this.editor = editor
  }

  handlePositionChange = position => {
    this.setState({ position })
  }
  createAccount(e) {
      e.preventDefault()
      let credentials = {...this.props.credentials, file: this.state.file}
      for (let key in credentials.data) {
        credentials[key] = credentials.data[key]
      }
      delete credentials ['data'];

      let data = new FormData();
      for (let key in credentials) {
        data.append(key, credentials[key]);
      }



     if(this.state.image) {
         this.editor.getImageScaledToCanvas().toBlob(function (blob) {
           data.append('file', blob);
           this.sendToServer(data)
            }.bind(this), 'image/jpeg', 1);
      } else {
       this.sendToServer(data)
     }
   }

  sendToServer(data) {
    signup(data)
      .then((res)=>{
        // this.props.history.push('/login')

        var obj = {
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email
        }
        this.setState({registered: true, answer: obj})
      })
      .catch(error => {
        // this.setState({ error: error.response.data.error })
      })
  }









    // nextStep = (name, data) => this.setState({credentials:{...this.state.credentials, [name]:data}, step:this.state.step+1})




  onChange(e){
    this.setState({[e.target.name]:e.target.value})
  }

  render() {
    if(this.state.registered) {
      return (
        <div className="interest-page photo-page">
          <div className="card-interests">
            <div>
              Thank you, {this.state.answer.firstName + ' ' + this.state.answer.lastName}. Your account has been created
              We sent you a confirmation {this.state.answer.email} - please click the
              link in the email to activate your account. If you didn't receive
              the confirmation email please check your spam folder or click
              here to resend
            </div>
          </div>
        </div>
      )
    }

    // let styleCanvas = {
    //   background: 'red',
    //   width: '300px',
    //   height: '300px'
    // }
    let styleCanvas = {

    }
    let dropStyle = {

    }

    function ActionLink() {
      document.getElementById('input_load_file_2').click();
    }

    return (
        <div className="interest-page photo-page">
          <div className="card-interests">


            <div className="ci-title">Your photo</div>
            <div className="upload-photo">

              {!this.state.image &&
              <Dropzone style={dropStyle} multiple={false} onDrop={this.onDrop.bind(this)}
                        accept="image/jpeg, image/png, image/gif">

                <div className="block-drop-zone fix2">
                  <div className="block-title-description-drop-zone">
                    <p className="title-drop-zone">Photo <br/> Upload</p>
                    <p className="description-drop-zone">Drag&drop <br/> it here</p>
                  </div>
                </div>

              </Dropzone>
              }

              {this.state.image &&
                <div>
                <AvatarEditor
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={this.handlePositionChange}
                  rotate={parseFloat(this.state.rotate)}
                  borderRadius={this.state.borderRadius}
                  onSave={this.handleSave}
                  onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                  onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                  onImageReady={this.logCallback.bind(this, 'onImageReady')}
                  onImageLoad={this.logCallback.bind(this, 'onImageLoad')}
                  onDropFile={this.logCallback.bind(this, 'onDropFile')}
                  color={[0,0,0, 0.8]}
                  style={styleCanvas}
                  image={this.state.image || uploadImg}
                />
              </div>}





              <br />
              <br />

              {this.state.image &&
              <div>
                <div>
                  Zoom:
                  <input
                    name='scale'
                    type='range'
                    onChange={this.handleScale}
                    min={this.state.allowZoomOut ? '0.1' : '1'}
                    max='4'
                    step='0.01'
                    defaultValue='1'
                  />
                </div>
                <br />
                <br />

                <div className="block-button ">
                  <input type="file" className="button-upload" id="input_load_file_2" style={{'display': 'none'}} onChange={this.handleNewImage}/>
                  <div className="button-upload auth-upload"  onClick={ActionLink}><p>New File</p></div>
                </div>


                {/*<input name='newImage' type='file' onChange={this.handleNewImage} />*/}


              </div> }

            </div>

            <div>
              {this.state.errorFile && <div className="reg_error_photo">The uploaded file seems to have incorrect format</div>}
            </div>

            <div className="block-btn">
              <button className="login-form__btn login-form__btn_join" disabled={this.state.checkDisable} onClick={this.createAccount}>Join now</button>
              {this.state.error && <div className="reg_error_field err_center">{this.state.error}</div>}
            </div>


          </div>
        </div>


    )
  }
}


import axios from 'axios'
import {showMessage} from "../utils/showMessage";
import {updateAcceleratorInfo} from "./AcceleratorAction";
import {acceleratorRoute, goToStep} from "../utils/regNavigation";
import {updateAngelInfo, updateFundInfo} from "./AuthActions";
import history from "../history";
import {getFundInfo} from "./FundActions";

export const createAccelerator = (data, nextStep = 1) => {
    return dispatch => {
        return axios.post(`/v2/accelerator`, data)
            .then(res => {
                console.log(res.data);
                    dispatch(updateAcceleratorInfo(res.data));
                    acceleratorRoute(res.data.registrationStep || nextStep, res.data.id)
                    return res.data
                }
            ).catch(e => {
                console.log(e);
                throw e;
            })
    }
};

export const updateAccelerator = (data, id, onlyStep, nextStep) => {
    data.registrationStep = nextStep;
    if(onlyStep) data = {registrationStep: nextStep, event_type: data.event_type, event_data: data.event_data};

    return dispatch => {
        return axios.put(`/v2/accelerator/${id}`, data)
            .then(res => {
                    dispatch(updateAcceleratorInfo(res.data));
                    acceleratorRoute(res.data.registrationStep || nextStep, res.data.id)
                    return res
                }
            ).catch(e => {
                console.log(e);
                throw e;
            })
    };
}

export const updateAccelerator2 = (data, id) => {
    return dispatch => {
        return axios.put(`/v2/accelerator/${id}`, data)
            .then(res => {
                    dispatch(updateAcceleratorInfo(res.data));
                    return res
                }
            ).catch(e => {
                console.log(e);
                throw e;
            })
    };
}

export const getAccelerator = () => {
    return dispatch => {

        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        let url = '/v2/accelerator/last';
        if (id) {
            url =  `/v2/accelerator/${id}/private`;

            return axios
                .get(url)
                .then(res => {
                    if(res.data.registrationStep){
                        dispatch(updateAcceleratorInfo(res.data));
                        acceleratorRoute(res.data.registrationStep, res.data.id)
                    } else {
                        acceleratorRoute(1)
                    }
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                    throw err
                })
        }
    };
};

export const getMyAccelerator = (id) => {
    return dispatch => {
        const url =  `/v2/accelerator/${id}/private`;
        return axios
            .get(url)
            .then(res => {
                dispatch(updateAcceleratorInfo(res.data));
                return res;
            })
            .catch(err => {
                throw err
            })
    };
}

export const getAchievement = (id, acceleratorId) => {
    return axios.get(`/v2/accelerator/${acceleratorId}/achievements/${id}`);
};

export const getListParametersAccelerator = () => {
    return axios.get('/v2/accelerator/parameters')
};

export const createAchievementAccelerator = (acceleratorId, parameterId, data) => {
    return axios.post(`/v2/accelerator/${acceleratorId}/achievements/${parameterId}`, data);
};

export const deleteAchievementAccelerator = (acceleratorId, parameterId, achId) => {
    return axios.delete(`/v2/accelerator/${acceleratorId}/achievements/${parameterId}/${achId}`);
}

export const editAchievementAccelerator = (acceleratorId, parameterId, achId, data) => {
    return axios.put(`/v2/accelerator/${acceleratorId}/achievements/${parameterId}/${achId}`, data);
}

export const getAcceleratorList = (limit, offset) => {
    return axios
        .get(`/v2/accelerator?limit=${limit}&offset=${offset}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            showMessage({type: 'error', data: err})
            throw err
        })
};

export const sendInvitation = (id, data) => {
    return axios.post(`/v2/accelerator/${id}/team/invite/`, data)
}
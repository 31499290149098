import React, {useEffect, useState} from 'react';
import './style.scss'
import TabsCounter from "../../../../controls3.0/tabs/TabsCounter";
import {Row} from "../../../Grid/Row";
import ButtonBlue from "../../../../controls3.0/button/ButtonBlue";
import Input from "../../../../controls3.0/input/Input";
import {regexUrl} from "../../../../../utils/RegExp";
import {createAchievement, deleteAchievement, editAchievement} from "../../../../../actions/Login";
import {showMessage} from "../../../../../utils/showMessage";

const SINGLES = {
    'Articles':'article',
    'Blog':'blog',
    'Diploma':'diploma',
    'Projects':'project',
    'Mass media':'mass media',
    'Recommendations':'recommendation'

};
const AchieveBlock = ({ AchieveList=[], parameterDetails={}, backClick=()=>{}, ...props }) => {
    const [activeAchieve, setActiveAchieve] = useState(0);
    const [types, setTypes] = useState([
        {
            id: 0,
            title: 'Articles',
            description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.',
            achieve:[],
        },
        {
            id: 1,
            title: 'Blog',
            description: 'Links to your professional blogs (including video blogs)',
            achieve:[],
        },
        {
            id: 2,
            title: 'Diploma',
            description: 'Documents and any documentary proof of your educational background.',
            achieve:[],
        },
        {
            id: 3,
            title: 'Projects',
            description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.',
            achieve:[],
        },
        {
            id: 4,
            title: 'Mass media',
            description: 'Links to the articles about you or about your professional experience.',
            achieve:[],
        },
        {
            id: 5,
            title: 'Recommendations',
            description: 'Reviews of your expert works and professional collaboration.',
            achieve:[],
        }
    ]);
    const [changeAchieve, setChangeAchieve] = useState('default');
    const [errors, setErrors] = useState({});
    const [newAchieve, setNewAchieve] = useState({
        link: '',
        description: ''
    });
    const [textUpdate, setTextUpdate] = useState('');

    useEffect( ()=>{
        if (AchieveList && AchieveList !== []) {

            AchieveList.forEach((item, key) => {
                let temp = types;
                temp[item.achievementType].achieve.push(item);
                setTypes(temp);

            });
            setTextUpdate('update');
        }
    }, [AchieveList] );

    const TabList = types.map( (item, key) => {
            return <div className='col-auto achieve-tab-block'>
                <TabsCounter
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={() => { setActiveAchieve(item.id); setChangeAchieve('default'); }}
                    active={activeAchieve === item.id}
                    count={item.achieve.length}
                />
            </div>
        }
    );

    const TabFooter = (id)=> {
        try {
            let list = types[id].achieve.map( (item, key) => {
                return <div className='col-12' key={key}>
                    <div className="achieve-line shell-margin-top-8" onClick={ ()=>{ edit(item) } }>
                        <div className='text-wrapper'>
                            <div className="title">
                                {item.link}
                            </div>
                            <div className="description">
                                {item.description}
                            </div>
                        </div>

                        <div className="edit-button">
                            Edit
                        </div>
                    </div>

                </div>
            });
            return list;
        } catch {}
    };

    const onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        value = value.cleanWhitespace();
        if(!value){
            let _errors = errors;
            _errors[name] = 'Field is required';
            setErrors(_errors);
        } else {
            let _errors = errors;
            delete _errors[name];
            setErrors(_errors);
        }
        if(name === 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                let _errors = errors;
                delete _errors[name];
                setErrors(_errors);
            } else {
                let _errors = errors;
                _errors[name] = 'Wrong link format';
                setErrors(_errors);
            }
        }
        if(name === 'description'){
            if(value.length > 100){
                value = value.slice(0, 100);
                let _errors = errors;
                _errors[name] = 'The text is too long, 100 symbols max';
                setErrors(_errors);
            } else {
                let _errors = errors;
                delete _errors[name];
                setErrors(_errors);
            }
        }

        let _newAchieve = newAchieve;
        _newAchieve[name] = value;
        setNewAchieve(_newAchieve);

        setTextUpdate(value);
    };

    const add = () => {
        setChangeAchieve('add');
        setNewAchieve({link:'', description:''});
        setTextUpdate('upd');
    };
    const edit = (item) => {
        setChangeAchieve('edit');
        setNewAchieve({link:item.link, description:item.description, id:item.id});
        setTextUpdate('upd');
    };

    const createAchieve = () => {
        let data = {
            parameterId: parameterDetails.parameterId,
            achievementType: activeAchieve,
            role: 'expert',
            link: newAchieve.link,
            description: newAchieve.description
        };

        createAchievement(data, parameterDetails.parameterId)
            .then(res => {
                addToList(res.data);

                // try {this.props.updateExpertAchMenu({type: 'update'});}
                // catch {}
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    };

    const DeleteAchieve = () => {
        deleteAchievement(parameterDetails.parameterId, newAchieve.id)
            .then(res => {
                addToList(parameterDetails, false, true);
                // this.props.updateExpertAchMenu({type: 'update'})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    };

    const EditAchieve = () => {
        let data = {
            parameterId: parameterDetails.parameterId,
            achievementType: activeAchieve,
            role: 'expert',
            link: newAchieve.link,
            description: newAchieve.description
        };
        editAchievement(data, parameterDetails.parameterId, newAchieve.id)
            .then(res => {
                addToList(res.data, true);
            })
            .catch(err => {
                showMessage({type: 'error', data: err});
            })
    };

    const addToList = (item, edit, _delete) => {
        let arr = types[activeAchieve].achieve;
        if(edit) {
            let index;
            arr.map((x, key) => {
                if(x.id === newAchieve.id) index = key
            });
            if(index >= 0) arr[index] = item;
        } else if(_delete) {
            let index;
            arr.map((x, key) => {
                if(x.id === newAchieve.id) index = key;
            });
            if(index >= 0) delete arr[index];
            let clean = arr.filter((el) => { return el; });
            arr = clean;
        } else {
            arr.push(item);
        }

        handleClearNewA(item, arr);
    };

    const handleClearNewA = (item={}, arr=[]) => {
        let _types = types;
        _types[activeAchieve].achieve = arr;
        setTypes(_types);

        setNewAchieve({link:'', description:''});
        setChangeAchieve('default');
    };

    return (
        <React.Fragment>
            <div className='achieve-container'>
                <Row className='m4'>
                    {TabList}
                </Row>

                {changeAchieve !== 'default' && <Row className='edit-achieve-wrapper'>
                    <div className="block-form-add-edit-modal-reg-expert">
                        <div className="block-input-item-form-add-edit-modal-reg-expert">
                            <p className="title">Insert the link</p>
                            <Input
                                type={'text'}
                                name={'link'}
                                value={newAchieve.link}
                                onChange={onChange}
                                placeholder={''}
                                error={errors.link}
                            />
                        </div>
                        <div className="block-text-area-form-add-edit-modal-reg-expert">
                            <p className="title">Leave comments</p>
                            <textarea name="description" cols="30" rows="10" value={newAchieve.description}
                                      onChange={onChange}/>
                            <div className="block-new-input error" style={{height:'20px'}}>
                                <p className="title-error">{errors.description}</p>
                            </div>
                        </div>
                        {changeAchieve === 'add' && <div className="block-button-form-add-edit-modal-reg-expert">
                            <ButtonBlue
                                TitleButton={`Add ${SINGLES[types[activeAchieve].title]}`}
                                EventOnClick={() => {createAchieve()}}
                                paddingButton={'30'}
                                disabled={!newAchieve.link || !newAchieve.description || Object.keys(errors).length}
                                loader={false}
                            />
                        </div>}
                        {changeAchieve === 'edit' && <Row className='justify-content-between align-items-center'>
                            <div className='col-sm-auto col-12'>
                                <ButtonBlue
                                    TitleButton={'Save'}
                                    EventOnClick={() => { EditAchieve() }}
                                    paddingButton={'40'}
                                    disabled={!newAchieve.link || !newAchieve.description || Object.keys(errors).length}
                                />
                            </div>
                            <div className='col-sm-auto col-12'>
                                <ButtonBlue
                                    TitleButton={'Delete'}
                                    EventOnClick={() => { DeleteAchieve() }}
                                    paddingButton={'34'}
                                    className={'delete-button'}
                                    disabled={false}
                                />
                            </div>
                        </Row>}
                    </div>
                </Row>}

                {changeAchieve === 'default' && <ButtonBlue
                    className={'add-article-button'}
                    TitleButton={`Add ${SINGLES[types[activeAchieve].title]}`}
                    EventOnClick={ ()=>{ add() } }
                    paddingButton={'30'}
                />}

                {activeAchieve !== null && changeAchieve === 'default' && <Row className='m3 achieve-descr-wrapper'>
                    {TabFooter(activeAchieve)}
                </Row>}
            </div>

        </React.Fragment>
    )
};

export default AchieveBlock;
import axios from 'axios'

export const getListSections = (skip, take) => {
    if(!skip) skip = 0;
    if(!take) take = 20;
    return axios.get(`/_forum/sections?skip=${skip}&take=${take}`)
}

export const createSection = (obj) => {
    return axios.post('/_forum/sections', obj )
}
export const deleteSection = (id) => {
    return axios.delete(`/_forum/sections/${id}`)
}
export const editSection = (id, obj) => {
    return axios.put(`/_forum/sections/${id}`, obj)
}

export const createThread = (id, obj) => {
    return axios.post(`/_forum/sections/${id}/threads`, obj )
}
export const deleteThread = (sectionId, threadId) => {
    return axios.delete(`/_forum/sections/${sectionId}/threads/${threadId}`)
}
export const editThread = (sectionId, threadId, obj) => {
    return axios.put(`/_forum/sections/${sectionId}/threads/${threadId}`, obj)
}

export const getListThread = (sectionId, threadId) => {
    return axios.get(`/_forum/sections/${sectionId}/threads/${threadId}`)
}

export const createTopic = (sectionId, threadId, obj) => {
    let data = new FormData();
    for (let key in obj) {
        if(obj[key]){
            data.append(key, obj[key]);
        }
    }
    return axios.post(`/_forum/sections/${sectionId}/threads/${threadId}/topics`, data )
}

export const deleteTopic = (sectionId, threadId, topicId) => {
    return axios.delete(`/_forum/sections/${sectionId}/threads/${threadId}/topics/${topicId}`)
}
export const editTopic = (sectionId, threadId, topicId, obj) => {
    return axios.put(`/_forum/sections/${sectionId}/threads/${threadId}/topics/${topicId}`, obj)
}
export const getListTopic = (sectionId, threadId, topicId) => {
    return axios.get(`/_forum/sections/${sectionId}/threads/${threadId}/topics/${topicId}`)
}


export const createPost = (sectionId, threadId, topicId, obj) => {
    let data = new FormData();
    for (let key in obj) {
        if(obj[key]){
            data.append(key, obj[key]);
        }
    }
    return axios.post(`/_forum/sections/${sectionId}/threads/${threadId}/topics/${topicId}/comment`, data )
}

export const editPost = (sectionId, threadId, topicId, obj) => {
    let data = new FormData();
    for (let key in obj) {
        if(obj[key]){
            data.append(key, obj[key]);
        }
    }
    return axios.put(`/comments/${obj.id}`, data )
}

export const getListPosts = (sectionId, threadId, topicId, skip, take) => {
    if(!skip) skip = 0;
    if(!take) take = 20;
    return axios.get(`/_forum/sections/${sectionId}/threads/${threadId}/topics/${topicId}/comment?skip=${skip}&take=${take}`)
}
export const deletePost = (id) => {
    return axios.delete(`/comments/${id}`)
}



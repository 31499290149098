import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {updateExpert} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Group_4 from '../../../images/svg/Group_4.svg'


class Step3 extends React.Component {
    constructor(props) {
        super();
        this.state = {

        }
    }

    update = (nextStep, onlyStep) => {
        let loading = nextStep == 18? 'loadingBack' : 'loading';

        this.setState({[loading]: true});


        const expert = {event_type: 'expert_promotion_16'};

        if(nextStep === 19){
            expert.event_data = {
                skip: true
            }
        }

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    render () {
        let {user, expert} = this.state;
        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg step-3"
                         style={{position: 'relative'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Promotion
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                            In order to make an expert community a truly strong and independent one, we need as
                            more people as possible to know about the project and the opportunities it provides
                            for the expertise monetization.
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-60" style={{fontWeight:'600'}}>
                            That is why we are glad to share ROCK tokens for the promotional activities you perform.
                        </p>
                    </div>
                    <div className='step-3-img'>
                        <img src={Group_4} alt=""/>
                    </div>
                </div>





                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(18, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(19)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Sounds good. What will I have to do?'}
                                EventOnClick={() => this.update(6)}
                                paddingButton={'56'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>




            </React.Fragment>

        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step3.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step3)
const initialState = {
  token: false
}

export const auth = (state = initialState, action={}) => {
  switch (action.type){
    case 'USER_LOGGED_IN':
      return {
        token: !!action.token
      }
    case 'UPDATE_USER_INFO':
    case 'GET_USER_INFO':
      return {
        token: !!localStorage.RocketToken,
        user: action.user
      }
    case 'USER_LOGGED_OUT':
      return {
        token:false
      }

      // case 'UPDATE_FUND_INFO': {
      //
      //
      //   return {
      //       token: !!localStorage.RocketToken,
      //       user: action.user,
      //       fund: action.fund
      //   } }
    default: return state
  }
};
import React from 'react'
import ReactModal from 'react-modal';
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import './loginRegister.css'
import {signUpFacebook} from '../../actions/Login'
import {signUpGoogle} from '../../actions/Login'
import ButtonGreen2 from '../controls2.0/Button2.0/ButtonGreen2.0'

import ButtonBlue2 from '../controls2.0/Button2.0/ButtonBlue2.0'
//import Select from 'react-select';
import {createThing} from "../../actions/Profile";
import Dropzone from 'react-dropzone'
import Loader from './../test-controls/preloader/preloader'



export default class ModalAddNew extends React.Component {

    constructor(props) {
        super();
        this.state = {
            type: '',
            data: {
                name: '',
                description: '',
                link: ''
            },
            file: {},
            tags:[],
            errors: {},
            regexUrl: new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/),
            loaded: true

        };
    }

    componentDidMount () {
        this.setState({type: this.props.typeModal});

    }

    changeRadio = (e) => {
        this.setState({type: e.target.name})
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors


        switch (name) {
            case 'name':
                if (!value.length) {
                    errors[name] = 'Field is required'
                } else {
                    delete errors[name]
                }
                if(value.length > 50) {
                    errors[name] = 'max length 50 characters';
                    value = value.slice(0,50);
                    setTimeout(function() {
                        delete errors[name]
                        this.setState({errors})
                    }.bind(this), 3000);
                } else {
                    delete errors[name]
                }
                break;
            case 'description':
                if(value.length > 150) {
                    errors[name] = 'max length 150 characters';
                } else {
                    delete errors[name]
                }
                break;
            case 'tags':

                break;
            case 'link':
                if (value && !this.state.regexUrl.test(value)) {
                    errors[name] = 'Wrong format'
                } else {
                    delete errors[name]
                }
                break;
        }

        this.setState({data: {...this.state.data, [name]: value}})
        this.setState({errors})
    }

    addFile (accepted, rejected) {
        if (accepted.length) {
            this.setState({file: accepted[0]})
        }
        if (rejected.length) {
            // this.setState({['fileError' + key]: 'Incorrect format or big size'})
            // setTimeout(function () {
            //     this.setState({['fileError' + key]: ''})
            // }.bind(this), 5000);
        }
    }

    getTags = input => {
        if (!input) {
            return Promise.resolve({ options: [] });
        }
        return fetch(`/users/search_tags?search=${input}`)
            .then((response) => response.json())
            .then((res) => {
                return { options: res };
            });
    }

    onChangeTags = value => {
        if(value.length < 4){
            this.setState({tags: value})
        } else {
            this.setState({errors:{...this.state.errors, ['tags']: 'max 3'}})
            setTimeout(function() {
                this.setState({errors:{...this.state.errors, ['tags']: null}})
            }.bind(this), 3000);
        }

    }



    validate = data => {
        const errors = {}
        for (let key in data) {
            let value = data[key];
            switch (key) {
                case 'name':
                    if(value.length > 50) {
                         errors[key] = 'max length 50 characters';
                    } else {
                        delete errors[key]
                    }
                    if (!value.trim()) errors[key] = 'Field is required'
                    break;
                case 'description':
                    if(value.length > 150) {
                        errors[key] = 'max length 150 characters';
                    } else {
                        delete errors[key]
                    }
                    // if (!value.trim()) errors[key] = 'Field is required'

                    break;
                case 'type':

                    if(data.type == 'link'){
                        if(!data.link) {
                            errors['link'] = 'Field is required'
                        } else {
                            if (data.link && !this.state.regexUrl.test(data.link)) {
                                errors['link'] = 'Wrong format'
                            } else {
                                delete errors['link']
                            }
                        }
                    }

                    if(data.type == 'file'){
                        if(!data.file) {
                            errors['file'] = 'Field is required'
                        } else {
                            delete errors['file']
                        }
                    }

                    break
            }



        }

        return errors;
    }

    send = () => {
        let checkdata = this.state.data;
        checkdata.type = this.state.type;
        checkdata.file = this.state.file;
        const errors = this.validate(checkdata);
        this.setState({errors})

        if (!Object.keys(errors).length) {
            let data = this.state.data,
                type;

            switch (this.state.type) {
                case 'file':
                   type = 'file'
                    break;
                case 'link':
                    type = 'href'
                    break;
                case 'folder':
                    type = 'dir'
                    break;
                default:

            }

            let tags = this.state.tags.map(function (item, key) {
                return item.title;
            });
            // data.append('tags', tags);

            let obj = {
                type: type,
                title: data.name,
                description: data.description,
                tags: tags,
                parentId: this.props.parentId || ''
            }

            //NEED TO ADD TAGS LATER

            if(obj.type == 'file') obj.file = this.state.file;
            if(obj.type == 'href') obj.href = data.link;


            this.setState({loaded: false})

            createThing(obj)
                .then((res) => {
                    this.props.addNewThing(res.data)
                    this.setState({loaded: true})
                })
                .catch(error => {
                    this.setState({serverError: error.response.data.message, loaded: true})
                    setTimeout(function () {
                        this.setState({serverError: null})
                    }.bind(this), 5000);

                })
        }
    }


    render() {
            let data = this.state.data,
                errors = this.state.errors;

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        return (
            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login"
                    overlayClassName="Overlay"
                >
                    <div className="modal-login-block modal-register-block">
                        <div className="block-top">
                            <div className="sr-title">
                                Add new
                            </div>

                            <img src="../../img/x.svg" alt="" className="close-icon"
                                 onClick={this.props.handleCloseModal}/>
                        </div>
                        <div className="sr-description">
                            <div className="block-radio-button-modal">
                                <div className="block-input-radio-button" htmlFor="File">
                                    <input type="radio" name="file" id="File"
                                           value={this.state.type}
                                           checked={this.state.type === 'file'}
                                           onChange={this.changeRadio}
                                    />
                                    <label htmlFor="File">File</label>
                                </div>
                                <div className="block-input-radio-button" htmlFor="Link">
                                    <input type="radio" name="link" id="Link"
                                           value={this.state.type}
                                           checked={this.state.type === 'link'}
                                           onChange={this.changeRadio}
                                    />
                                    <label htmlFor="Link">Link</label>
                                </div>
                                <div className="block-input-radio-button" htmlFor="Folder">
                                    <input type="radio" name="folder" id="Folder"
                                           value={this.state.type}
                                           checked={this.state.type === 'folder'}
                                           onChange={this.changeRadio}
                                    />
                                    <label htmlFor="Folder">Folder</label>
                                </div>
                            </div>

                            <div className="block-inputs-add-modal block-inputs-file">
                                <div className="block-input-DAO-new">
                                    <p className="title">File Name</p>
                                    <input type="text" name="name" value={data.name} onChange={this.onChange} placeholder=""/>
                                    <p className="error">{errors.name}</p>
                                </div>
                                <div className="block-input-DAO-new">
                                    <p className="title">Description</p>
                                    <input type="text" name="description" value={data.description} onChange={this.onChange} placeholder=""/>
                                    <p className="error">{errors.description}</p>
                                </div>

                                {/*<div className="block-input-DAO-new">*/}
                                    {/*<div style={{'paddingBottom': '20px', 'position': 'relative'}}>*/}
                                        {/*<p className="title">Tags</p>*/}
                                        {/*<div className="section">*/}
                                            {/*<Select.AsyncCreatable name='tags' multi={true}  value={this.state.tags} onChange={this.onChangeTags} clearRenderer={this.onClose} onValueClick={this.gotoUser2} valueKey="id" labelKey="title" loadOptions={this.getTags} backspaceRemoves={false} onFocus={this.onFocus} placeholder={'Select tags'}/>*/}
                                        {/*</div>*/}
                                        {/*<p className="response-server-error">{errors.tags}</p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}

                                <div className="hp-tags">
                                    <p className="title">Tags</p>
                                    <div className="section new-design-section-tags">
                                        {/*<Select.AsyncCreatable name='tags'
                                                               multi={true}
                                                               value={this.state.tags}
                                                               onChange={this.onChangeTags}
                                                               clearRenderer={this.onClose}
                                                               onValueClick={this.gotoUser2}
                                                               valueKey="id"
                                                               labelKey="title"
                                                               loadOptions={this.getTags}
                                                               backspaceRemoves={false}
                                                               onFocus={this.onFocus}
                                                               placeholder=''
                                        />*/}
                                    </div>
                                    <p className="response-server-error">{errors.tags}</p>
                                </div>




                                {this.state.type == 'link' &&
                                <div className="block-input-DAO-new">
                                    <p className="title">Link</p>
                                    <input type="text" name="link" value={data.link} onChange={this.onChange} placeholder=""/>
                                    <p className="error">{errors.link}</p>
                                </div>
                                }

                                {this.state.type == 'file' &&
                                <div className="block-button-add-file">
                                    <p className="title">Upload your file</p>
                                    <Dropzone
                                        style={dropStyle}
                                        multiple={false}
                                        onDrop={this.addFile.bind(this)}
                                    >
                                        <ButtonBlue2
                                            TitleButton="Upload"
                                            EventOnClick={() => {}}
                                            sizeButton="small"
                                            paddingButton="20"
                                        />
                                    </Dropzone>



                                    <p className="response-server-error">{errors.file}</p>
                                    <div className="p-file-name fix-long-text-2">{this.state.file.name}</div>
                                </div>
                                }


                            </div>

                            <ButtonGreen2
                                TitleButton="Submit"
                                EventOnClick={this.send}
                                sizeButton="big"
                                paddingButton=""
                            />
                            <p className="response-server-error">{this.state.serverError}</p>
                        </div>
                    </div>
                    <Loader loaded={this.state.loaded}></Loader>

                </ReactModal>
            </div>


        )
    }
}



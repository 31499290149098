import history from './../history'
import qs from "qs";

export const goToStep = (step, user) => {
    let url = window.location.pathname,
        arr = url.split('/')
    arr[arr.length-1] = step;
    let path = arr.join('/');

    const urlParams = new URLSearchParams(window.location.search);

    const name = urlParams.get('name');
    const id = urlParams.get('id');
    const event = urlParams.get('event');
    const ref_b = urlParams.get('ref_b');

    const params = {};

    if(name && step===1){
        params.name = name;
    }

    if (ref_b && step ===2){
        params.ref_b = ref_b;

    }

    if(id){
        params.id = id;
    }

    if(event){
        params.event = event;
    }

    history.push({pathname: path, search: qs.stringify(params)})
};

export const getQuery = (id) => {
    const urlParams = new URLSearchParams(window.location.search);

    const event = urlParams.get('event');

    const params = {};

    if(id){
        params.id = id;
    }

    if(event){
        params.event = event;
    }

    return qs.stringify(params);
}

export const acceleratorRoute = (step, _id = null) => {
    let path = '/create/accelerator';


    const urlParams = new URLSearchParams(window.location.search);
    const name = urlParams.get('name');
    const id = urlParams.get('id') || _id;
    const ac = urlParams.get('ac');

    if(step){
        path += `/${step}`;
    }

    if(name && step===1){
        path += `?name=${name}`
    }

    if(id){
        path += `${name && step===1 ? '&' : '?'}id=${id}`
    }

    if(ac){
        path += `&ac=${ac}`
    }

    history.replace(path)
};

export const checkStep = () => {
    let url = window.location.pathname,
        step = url.substr(url.lastIndexOf('/') + 1);
    return step
}
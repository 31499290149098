import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import {getLastProject, updateProject} from "../../../actions/Project";
import FooterImg from "../../../images/svg/footer_welcome_startup.svg";


class Step20 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            project: props.project || {}
        }
    }

    componentDidMount () {
        if(!this.state.project.id) this.props.getLastProject()
    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    render () {
        let user = this.props.user || {}
        let project = this.state.project;

        return (
            <React.Fragment>

                <div className="container-full-reg" style={{position: 'relative'}}>

                    <React.Fragment>
                        <div className="container-step-1-reg"
                             style={{position: 'relative'}}>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                We welcome you to Rocket DAO!
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-60 step-20-btm-text">
                                <div className='step-20-text'>
                                    Your amazing project {project.title} has just been registered on Rocket DAO platform!
                                </div>
                                <br/>
                                While your startup is being reviewed by community manager,
                                fill your time surfing Startup Jedi media for great startup stories and useful info about product launching and fundraising.
                                <br/>
                                <br/>
                                Good luck with your project!
                                <br/>
                                <br/>
                                Rocket DAO team.
                            </p>

                            <div className="shell-button-reg-expert wrap-button">
                                <a href="https://startupjedi.vc/" target="_blank">
                                    <div className="block-button-big-reg-expert" style={{width: '240px'}}>
                                        <p className="title">Go to Startup Jedi</p>
                                    </div>
                                </a>
                                <a href="https://rocketdao.io/" target="_blank" style={{marginLeft: '20px'}}>
                                    <div className="block-button-big-reg-expert"
                                         style={{width: '240px', background: "#ffffff", border: "1px solid #2CA6EB"}}>
                                        <p className="title" style={{color: "#2CA6EB"}}>Back to Rocket DAO</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </React.Fragment>

                </div>

                <div style={{marginTop: '40px'}}>
                    <img src={FooterImg} alt="footer_img" style={{width:"100%"}}/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step20.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, updateProject, getLastProject})(Step20)
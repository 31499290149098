import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {createFund, updateFund, getLastFund} from "../../../actions/Login";
import {searchExternalFund} from "../../../actions/Fund";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import config from "./../../../config";
import plen18 from "../../../images/ilustaciya/Fund God@2x-min.png";
import Group21 from "../../../images/svg/Group21.png";
import {updateMenu} from "../../../actions/menu";
import axios from "axios/index";
import history from "../../../history";
import qs from "qs";
import Access from "../access";

class Step1 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            listFunds: [],
            fund: props.fund, //tODO FIXPLS ID
            me: {},
            name: props.fund.title || '',
            foundFunds: [],
            chosenFunds: [],
            invited: false,
            invitedPush: false,
            active_item: -1,
            owner_email: '',
            owner_name: '',
            errors: {},
            showAccess: false,
            access: false,
            next: '',
        };
        this.checkAccess.bind(this);
    }

    async componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('name');
        if (name) {
            this.setState({name: name}, () => {
                this.searchFund()
            })
        } else {
            this.searchFund();
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace(),
            errors = this.state.errors,
            limit = 200;

        if (name === 'inputSearch') limit = 40;

        if (value.length) {
            if (value.length > limit) {
                value = value.slice(0, limit)
                errors['name'] = 'max length ' + limit + ' symbols';
            } else {
                delete errors['name'];
            }
        } else {
            this.setState({active_item: -1, listFunds: []})
        }

        this.setState({
            'name': value,
            errors: errors
        }, () => value.length >= 3 ? this.searchFund() : null);
    }

    onSetOE = (e) => {
        this.setState({'owner_email': e.target.value});
    }

    onSetON = (e) => {
        this.setState({'owner_name': e.target.value});
    }

    add = fund => {
        this.setState({'name': fund.title, active_item: fund.id});
    }

    searchFund = () => {
        let str = this.state.name
        if (str.length < 1) {
            this.setState({active_item: -1, listFunds: []})
        } else {
            searchExternalFund(str)
                .then(res => {
                    let query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
                    if(query.ac === '1'){
                        this.setState({active_item: res.data[0].id, listFunds: res.data})
                    } else {
                        this.setState({active_item: -1, listFunds: res.data})
                    }
                })
        }
    }


    // setMe = fund => {
    //     this.setState({'name': fund.title})
    // }

    invite = () => {
        this.setState({invited: !this.state.invited, showAccess: false})
    }

    invitePush = () => {
        axios
            .get(`/v2/funds/invite-owner?email=${this.state.owner_email}&name=${this.state.owner_name}&fund=${this.state.name}`)
            .then(r => {
                this.setState({invitedPush: !this.state.invitedPush})
            })
    }
    onChangeEmail = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({[name]: value})
    }

    update = (nextStep, onlyStep, copy) => {
        if (onlyStep) {
            window.location.href = '/start_investor'
            return
        }

        this.setState({loading: true})

        if (this.state.fund.fundId) {
            this.props.updateFund({title: this.state.name}, this.state.fund.id, onlyStep, nextStep)
                .then(r => {
                    this.props.updateMenu({type: 'update'})
                })
                .catch(err => {
                    this.setState({loading: false})
                })
        } else {
            this.props.createFund(copy ? {copyId: this.state.active_item} : {title: this.state.name}, onlyStep, nextStep)
                .then(r => {
                    this.props.updateMenu({type: 'update'})
                })
                .catch(err => {
                    this.setState({loading: false})
                })
        }
    }

    checkAccess = () => {
        if(this.state.active_item) {
            axios.post('/v1/users/check_access_object', {
                id: this.state.active_item,
                type: 'fund'
            }).then(r => {
                if (r.data.access) {
                    this.setState({showAccess: true, access: true, next: () => this.update(2, false, true)})
                } else {
                    this.setState({showAccess: true, access: false, next: () => {localStorage.removeItem('RocketToken'); history.push(`/start_reg?type=4&name=${this.state.title}&ac=1`)}});
                }
            })
        }
    };

    render() {
        let foundFunds = this.state.listFunds.map((item, key) => {

            return (
                <div className={`block-item-net-work-fund ${this.state.active_item === item.id ? 'active' : ''}`}
                     key={item.id}
                     onClick={() => this.add(item)}>
                    <div className="block-left-item-net-work-fund">
                        <p className="title-item-net-work-fund">{item.title}</p>
                        <p className="description-item-net-work-fund">{item.location}</p>
                    </div>
                    <div className="block-right-item-net-work-fund">

                        {!!item.logoFileId && <div className="block-img-item-net-work-fund"
                             style={{background: 'url(' + config.proxy + '/file/' + item.logoFileId + '/view' + ')'}}/>
                        }
                    </div>
                    <div className="block-active-net-work-fund">
                        <p className="title-active-net-work-fund">This is it!</p>
                    </div>
                </div>
            )
        })
        if(this.state.showAccess){
            return <Access access={this.state.access} next={this.state.next} back={() => this.setState({showAccess: false})} invite={this.invite}/>
        } else
            return (
                <React.Fragment>
                    <div className="step-1 is-startup padding-reg">


                        <div className="block-reg-startup-step-2">
                            <div className="container-full-reg" style={{position: 'relative'}}>

                                {!this.state.invited && !this.state.invitedPush &&

                                <div className="block-net-work-fund">
                                    <div className="block-top-net-work-fund">
                                        <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                            Venture fund name
                                        </p>
                                        <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                            Introduce your Fund to the community! Put down the correct name in the field
                                            below.
                                        </p>
                                    </div>
                                    <div className="block-body-net-work-fund">

                                        <div className="block-input-net-work-fund shell-margin-bottom-20">
                                            <div className="shell-input-reg">
                                                <Input
                                                    type={'text'}
                                                    name={'inputSearch'}
                                                    value={this.state.name}
                                                    onChange={this.onChange}
                                                    placeholder={''}
                                                    iconClass={null}
                                                    error={this.state.errors.name}
                                                />
                                            </div>
                                        </div>
                                        {!!foundFunds.length && !!this.state.name.length &&
                                        <div>
                                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                                Hmm, we found a match already registered on the platform. Is it yours?
                                            </p>
                                            <p className="text-body color-text-reg-gray margin-0 shell-margin-bottom-15">
                                                Do not worry, this is just an profile in our database, not verified yet.
                                                Fill it in with relevant information about the Fund.
                                            </p>

                                            <div className="block-list-net-work-fund">
                                                {foundFunds}
                                            </div>
                                        </div>
                                        }

                                        {!foundFunds.length && !!this.state.name.length &&
                                        <div>
                                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15"
                                               style={{maxWidth: '470px'}}>
                                                Note that later you will have to provide documents which would <b>prove your
                                                ownership</b> of this Fund. Only official founder of the Fund can register
                                                it on our platform.
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }

                                {this.state.invited && !this.state.invitedPush &&
                                <div>
                                    <div className="block-net-work-fund">
                                        <div className="block-top-net-work-fund">
                                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                                Invite the owner of Venture Fund Name
                                            </p>
                                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                                This person will be notified that you invited him/her to create an official
                                                profile of your Fund on our platform.
                                            </p>
                                        </div>

                                        <div className='step-21-btm clearfix'>
                                            <div className='s21-left no-float-620'>
                                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                                                    Invitation email list
                                                </p>
                                                {/*<p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">*/}
                                                {/*You will earn <span>10%</span> from invited friends*/}
                                                {/*</p>*/}

                                                <div className="block-input-item-form-add-edit-modal-reg-expert">
                                                    <p className="title">Enter email of the owner</p>
                                                    <Input
                                                        type={'text'}
                                                        name={'contact'}
                                                        value={this.state.owner_email}
                                                        onChange={this.onSetOE}
                                                        error={''}
                                                        placeholder={'Insert email'}
                                                    />
                                                </div>
                                                <div className="block-input-item-form-add-edit-modal-reg-expert">
                                                    <p className="title">Enter Name of the owner</p>
                                                    <Input
                                                        type={'text'}
                                                        name={'owner_name'}
                                                        value={this.state.owner_name}
                                                        onChange={this.onSetON}
                                                        error={''}
                                                        placeholder={'Insert Name'}
                                                    />
                                                </div>
                                            </div>
                                            <div className='s21-right no-float-620 new-no-padding-down-620'>
                                                <img src={plen18} alt="" className=""/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                }
                                {this.state.invitedPush &&
                                <div>
                                    <div className="block-net-work-fund">
                                        <div className="block-top-net-work-fund">
                                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                                Invitation was successfully sent!
                                            </p>
                                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                                We will inform you as soon as the Fund is registered so you.
                                            </p>
                                        </div>

                                        <img src={Group21} alt="" className=""/>

                                    </div>
                                </div>
                                }


                            </div>
                        </div>


                    </div>

                    {this.state.invited && !this.state.invitedPush &&
                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.invite()}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Send invitation'}
                                    EventOnClick={() => this.invitePush()}
                                    paddingButton={'56'}
                                    disabled={false}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.invitedPush &&
                    <div className="block-fix-button-reg-bottom fix-button-one">
                        <div className="container-full-reg">
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Close and go to Rocket DAO'}
                                    EventOnClick={() => window.location.href = '/'}
                                    paddingButton={'56'}
                                    disabled={false}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    }

                    {this.state.active_item < 0 && !this.state.invited && !this.state.invitedPush && !!foundFunds.length &&
                    <div className="block-fix-button-reg-bottom fix-button-one">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(1, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    {!this.state.invited && !this.state.invitedPush && this.state.active_item < 0 && this.state.name.length >= 3 &&
                    <div className="block-fix-button-reg-bottom fix-button-double">
                        <div className="container-full-reg">
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(1, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Create a new profile & Continue'}
                                    EventOnClick={() => this.update(2)}
                                    paddingButton={'56'}
                                    disabled={!this.state.name.length}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>
                    }
                    {this.state.active_item >= 0 && !this.state.invited && !this.state.invitedPush && !!foundFunds.length &&
                    <div className="block-fix-button-reg-bottom fix-button-one-details-double">
                        <div className="container-full-reg" style={{textAlign: 'right'}}>

                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    Are you the owner of this Fund?<br/>
                                    You will have to provide relevant documents to <br/>
                                    prove ownership.
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Invite the owner.'}
                                    EventOnClick={() => this.invite()}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={' I am the owner. Continue.'}
                                    EventOnClick={() => this.checkAccess()}
                                    paddingButton={'25'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>

                    }
                    {/*{(!!this.state.name.length || !!foundFunds.length  || (this.state.active_item && !this.state.invited && !this.state.invitedPush))&& this.state.name &&*/}
                    {/*<div>*/}
                    {/*<div className="block-list-button-right">*/}
                    {/*{!!foundFunds.length &&*/}
                    {/*<div className="block-left-list-button-right">*/}
                    {/*<p className="title" style={{fontSize: '14px', margin: '-7px'}}>*/}
                    {/*Are you the owner of this Fund?<br/>*/}
                    {/*You will have to provide relevant documents to <br/>*/}
                    {/*prove ownership.*/}
                    {/*</p>*/}
                    {/*</div> || ''}*/}
                    {/*<div className="block-right-list-button-right">*/}
                    {/*{!!foundFunds.length && this.state.active_item >= 0 &&*/}
                    {/*<ButtonGray*/}
                    {/*TitleButton={'Invite the owner.'}*/}
                    {/*EventOnClick={() => this.invite()}*/}
                    {/*paddingButton={'55'}*/}
                    {/*disabled={false}*/}
                    {/*/> || ''}*/}
                    {/*<ButtonBlue*/}
                    {/*TitleButton={' I am the owner. Continue.'}*/}
                    {/*EventOnClick={() => this.update(2)}*/}
                    {/*paddingButton={'25'}*/}
                    {/*disabled={false}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*}*/}


                </React.Fragment>
            )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund,
})

Step1.propTypes = {
    createFund: PropTypes.func.isRequired,
    updateFund: PropTypes.func.isRequired,
    getLastFund: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {createFund, updateFund, getLastFund, updateMenu})(Step1)
import React from 'react';
import './style.scss'
import UseCloseButton from "../closeButton";

const PopUp = ({children, className='', onClose=()=>{} }) => {
    return (
        <div className={`popup ${className}`} >
            <UseCloseButton onClick={onClose}/>
            <div className={'content'}>
                {children}
            </div>
        </div>
    )
};

export default PopUp;
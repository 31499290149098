import React from 'react';

import BlackButtonProfile from '../../../header/menu/blackButtonProfile/BlackButtonProfile'

import './style.css'
import Header from "../../../header";
import {CartPopUp} from "../../../v3/startupPopupPage";

export default class FundMenuProfile extends React.Component {
    state = {
        openedMenu: 'edit',
        isMembersOpened: true,
        buttonTitle: 'Add members',
        cartPopupData: {showCartPopup: false},
    };




    render() {
        let {
            user,
            openedSection,
            handleClick,
            page
        } = this.props;

        let {cartPopupData} = this.state;

        if (!user){
            return "";
        }

        return (
            <div className='fund-menu-profile'>
                <div className={`row-m0 block-fund-sidebar block-user-sidebar no-gutters ${page==='account'?'account-sidebar':''}`}>
                    <div className='col-12 block-user-buttons-line order-2 order-sm-1'>
                        <div className='button-wrapper' onClick={()=>{if(user) this.setState({cartPopupData: {
                                showCartPopup: true,
                                id: user.id,
                                type: 'user',
                                profile:user,
                                title:'User'
                            }
                        }) }}>
                            See Profile
                        </div>
                    </div>
                    <div className='col-12 block-main-menu order-1 order-sm-2'>
                        <div className='main-menu-title-block'>
                            <div className={`main-menu-title ${openedSection===0?'active':''}`} onClick = {() => {handleClick(0)}}>{page==='profile'?'Personal info':'Notifications'}</div>
                        </div>
                        <div className='main-menu-title-block'>
                            <div className={`main-menu-title ${openedSection===1?'active':''}`} onClick = {() => {handleClick(1)}}>{page==='profile'?'Photo':'Security'}</div>
                        </div>
                        <div className='main-menu-title-block'>
                            <div className={`main-menu-title ${openedSection===2?'active':''}`} onClick = {() => {handleClick(2)}}>{page==='profile'?'Professional skills':'Verification'}</div>
                        </div>
                        {page==='profile' && <div className='main-menu-title-block'>
                            <div className={`main-menu-title ${openedSection===5?'active':''}`} onClick = {() => {handleClick(5)}}>{'Professional experience'}</div>
                        </div>}
                        <div className='main-menu-title-block'>
                            <div className={`main-menu-title ${openedSection===3?'active':''}`} onClick = {() => {handleClick(3)}}>{page==='profile'?'Contact information':'Public agreements'}</div>
                        </div>

                    </div>
                </div>
                {cartPopupData.showCartPopup && <CartPopUp id={cartPopupData.id} user={cartPopupData.profile} type={cartPopupData.type} type2={cartPopupData.title} oneItem={true} close={() => this.setState({
                    cartPopupData: {showCartPopup: false}
                })}/> }
            </div>
        );
    }
}
import React from 'react';
import './SocialButtonLink.css'

export default class Steemit extends React.Component {
    render() {
        let {
            LinkSocial,
            ActiveSocial,
        } = this.props;
        /*let styleLink = {
            color: ActiveSocial? '#0000D3':'#F1F2F2',
        }*/
        let classActiveSocial = ActiveSocial? 'social-button active-social-button':' social-button no-active-social-button';
        return (
            <div className={classActiveSocial}>
                <a href={LinkSocial} target="_blank">
                    <img className="img-1" src="../../../img/soc/steemit-01.svg" alt=""/>
                    <img className="img-2" src="../../../img/soc/steemit-01-green.svg" alt=""/>
                </a>
            </div>
        );
    }
}
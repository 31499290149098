import React from 'react';
import './loginPopupStyle.css'

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import history from '../../../history';
import {disableScroll, enableScroll} from "../../../utils/scroll";
import {isExist} from "../../../utils/helper";

export default class ExternalAccess extends React.Component {
    componentDidMount(){
        disableScroll();
    }
    componentWillUnmount(){
        enableScroll();
    }

    render() {
        return (
            <div className='popup-overlay'>
                <div className='block-login-popup-container popup-fc' style={{padding: '32px 20px 36px 32px', maxWidth: '620px'}}>
                    <div className='close-title' onClick={this.props.onClose}>
                        <div className='close'/>
                    </div>
                    <div className='row-m0 w-100 login-popup-main-title font-weight-500' style={{fontSize: '30px'}}>Let's start!</div>
                    <p className='login-popup-descr margin-top-25' style={{fontSize: '20px', lineHeight: '26px', textAlign: 'left'}}>Make sure that the domain of your email correlates with the domain of the <span><b>{this.props.domain}</b></span>.</p>

                    <div className="margin-top-25">
                        <ButtonBlue
                            TitleButton={'Authorize now'}
                            EventOnClick={this.props.next}
                            paddingButton={'75'}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
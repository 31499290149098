import {LANGUAGES} from './countriesLanguages';

export const getShortLang = (lang) => {
    const find = LANGUAGES.find((i) => i.Name === lang);
    if(find){
        return find.code.toUpperCase();
    }
    return lang.toUpperCase();
};

export const isExist = (cb) => {
    try {
        return cb();
    } catch (e) {
        return false;
    }
}

export const isNone = (v) => {

    console.log({v});

    try {
       if(v === undefined || isNaN(v) || v === 'undefined' || v === 'NaN' || v === null || v == 'null'){
           return true
       } else {
           return false;
       }
    } catch (e) {
        return true;
    }
}

export const isMobile = () => {
    return window.innerWidth < 756;
}
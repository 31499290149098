import React from 'react'
import Logo from '../../images/svg/Logo.svg'
import './style.css'
import UseCloseButton from "../v4/closeButton";
import history from '../../history';

const Header = (props) => {
    const backHandle = () => {
        history.go(-1);
    };

    return (
        <div className="block-header-mini-reg">
            <a href="/slush">
                <img src={Logo} alt=""/>
            </a>
            {history.location.pathname !== '/slush' &&
                history.location.pathname !== '/slush/auth' &&
                !/thanks/.test(history.location.pathname) &&
                <div className={'round-b'}><UseCloseButton type={'back-black'} onClick={backHandle}/></div>
            }
        </div>
    );
}

export default Header
const initialState = {

}

export const angel = (state = initialState, action={}) => {
    switch (action.type){
        case 'UPDATE_ANGEL_INFO':{
            return {
                ...action.angel
            }
        }

        default: return {...state}
    }
};
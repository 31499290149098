import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {logout, tokenLogin, updateUser} from '../../../actions/Login'
import Header from '../../header'
import Footer from '../../footer/Footer'
import './LandingPageStyle.css'
import ButtonTransBlue2 from '../../controls2.0/Button2.0/ButtonTransBlue2.0'
import ButtonBlue2_0 from '../../controls2.0/Button2.0/ButtonBlue2.0'
import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGreen from '../../controls3.0/button/ButtonGreen'
import ButtonBorder from '../../controls3.0/button/ButtonBorder'
import {getFunds} from "../../../actions/Fund";
// import ModalTokenSale from '../../modal/ModalTokenSale'
import packageJSON from './../../../../package.json'
import MetaTags from 'react-meta-tags';


import Human from '../../../images/ilustaciya/Verified.svg'
import Ilustraciya_investor from '../../../images/ilustaciya/Ilustraciya_investor_new.svg'
import RocketDAO_Congratulations from '../../../images/ilustaciya/RocketDAO-Congratulations_new.svg'
import RocketDAO_great_rock from '../../../images/ilustaciya/RocketDAO-great-rock_new.svg'

import browser from '../../../images/ilustaciya/browser.svg'
import Group_29 from '../../../images/ilustaciya/Group 29.svg'
import Group_43 from '../../../images/ilustaciya/Group 43.svg'
import ic_methodologist_active from '../../../images/ilustaciya/ic_methodologist_active.svg'
import Rocket_DAO_DD from '../../../images/ilustaciya/Rocket_DAO_DD.png'
import img_banner from './img/Group_29.png'
import Input from "../../controls3.0/input/Input";
import axios from "axios/index";



const subscribeTypes = {
    '1': 'startup',
    '2': 'investor',
    '3': 'expert'
}

class about extends React.Component {
    constructor(props) {
        super();
        this.state = {
            amount: '',
            count: 0,
            arrowData: [],
            tag: '',
            openPopup: '0',
            savedEmail: localStorage.savedEmail,
            isSubscribe: false,
            currentEmail: '',
            error: '',
            currentDot: '1'
        }
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        if (this.state.savedEmail) {
            this.checkSubscribe(this.state.savedEmail);
        }
        window.scrollTo(0,0);
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value

        value = value.replace(/\D/, "");
        this.setState({[name]: value}, function () {

        })
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    openCloseForm(key) {
        this.setState({currentDot: key})
        this.setState({openPopup: key})
    }

    closeForm(key) {
        this.setState({openPopup: key})
    }

    setCurrentEmail = (e) => {
        const currentEmail = e.target.value.toLowerCase();
        this.setState({'currentEmail': currentEmail});
        this.validateEmail(currentEmail)
    }

    validateEmail = (email) => {
        const regexp = /\S+@\S+\.\S+/;
        if (regexp.test(email)) {
            this.setState({error: ''})
            return true;
        } else {
            this.setState({error: 'Not valid email'})
            return false;
        }
    }

    checkSubscribe = (email) => {
        axios
            .post('/v1/users/subscribe/check', {email: email})
            .then(res => {
                this.setState({isSubscribe: res.data.isSubscribe})
            })
    }

    subscribe = (type) => {
        const email = this.state.currentEmail;

        if(this.validateEmail(email)){
            axios
                .post(`/v1/users/subscribe/${type}`, {email: email})
                .then(res => {
                    if (res.data.status === 400) {
                        localStorage.setItem("savedEmail", email)
                        this.setState({isSubscribe: true, savedEmail: email, currentEmail: ''});
                    } else {
                        localStorage.setItem("savedEmail", email)
                        this.setState({isSubscribe: true, savedEmail: email, currentEmail: ''});
                    }
                })
        }
    }


    render() {
        let user = this.props.user || {};
        return (

            <div className="block-team-2-0" style={{background: '#FDFDFD'}}>
                <Header user={user} offSticky={true}/>

                <div className="shell-header-back-title-form shell-header-back-title-form-after">
                    <div className="block-header-back-title-form block-header-about">
                        <div className="container-lending">
                            <div className="block-left-header-back-title-form">
                                <p className="title">
                                    Rocket DAO
                                </p>
                                <p className="description">
                                    An international venture community and a platform where digital and hi-tech startups get expert support, grow and meet investors.
                                </p>
                                {/*<div style={{paddingTop: '20px'}}>*/}
                                    {/*<ButtonBlue2_0*/}
                                        {/*TitleButton="WHITEPAPER"*/}
                                        {/*EventOnClick={() => {window.open(`/Rocket_DAO_WP_2018.pdf`, '_blank')} }*/}
                                        {/*sizeButton="small"*/}
                                        {/*paddingButton="20"*/}
                                    {/*/>*/}
                                {/*</div>*/}
                            </div>
                            <div className="block-right-header-back-title-form">
                                <img src={Group_43} alt=""/>
                                {/*<div className="block-checked-about">*/}
                                    {/*<p className="title">Powered by: </p>*/}
                                    {/*<p className="item"><img src="./img/svg/check.svg" alt=""/>Blockchain technology</p>*/}
                                    {/*<p className="item"><img src="./img/svg/check.svg" alt=""/>Smart contracts</p>*/}
                                    {/*<p className="item"><img src="./img/svg/check.svg" alt=""/>Tokenization</p>*/}
                                    {/*<p className="item"><img src="./img/svg/check.svg" alt=""/>DAO</p>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="shell-column-title-gradient">
                        <div className="container-lending">
                            <div className="block-column-title-gradient block-column-title-gradient-about">
                                <div className="container-lending">
                                    <div className="block-group-3-column">
                                        <div className="block-item-3-column">
                                            <p className="title">Startups</p>
                                            <p className="description">
                                                Register your startup, share statistics, create business plan, pass independent Due Diligence, announce your fundraising campaign.
                                            </p>
                                            <a href="/start_reg?type=3">Register your startup<i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                        <div className="block-item-3-column">
                                            <p className="title">Investors</p>
                                            <p className="description">
                                                Use comprehensive search filters, track startups’ development progress, add the most promising ones to waitlist or contact directly.
                                            </p>
                                            <a href="/start_reg?type=1">Create investor profile<i
                                                className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                        <div className="block-item-3-column">
                                            <p className="title">Experts</p>
                                            <p className="description">
                                                Join the decentralized expert community, perform an audit of startups, develop startup evaluation standards.
                                            </p>
                                            <a href="/start_reg?type=2">Become an expert
                                                <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="block-expert-bounty block-evaluation-about"*/}
                {/*     style={{position: 'relative', top: '-4px', boxShadow: '0 7px 9px 0 rgba(0,0,0,0.16)', background:'#2F3849'}}>*/}

                {/*    <div className="">*/}
                {/*        <div className="block-right-expert-bounty">*/}
                {/*            <div className='block-evaluation-img'/>*/}
                {/*            <div>*/}
                {/*                <div className='see-sample-arrow' onClick={()=>{window.open('/Pre-Evaluation/Parsers.pdf', '_blank')}}/>*/}
                {/*                <div className='see-sample-text' onClick={()=>{window.open('/Pre-Evaluation/Parsers.pdf', '_blank')}}>See the sample</div>*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*        <div className="block-left-expert-bounty">*/}
                {/*            <p className="title" style={{color:'#FFFFFF'}}>Rocket DAO <br/>preliminary evaluation*/}
                {/*            </p>*/}
                {/*            <p className="description"  style={{color:'#FFFFFF', marginBottom: '23px'}}>Express assessment of startups on theur market, product, team and business model, equal to external SWOT analysis supplemented by expert recommendations.</p>*/}
                {/*            <div className=''>*/}
                {/*                <ButtonGreen*/}
                {/*                    TitleButton={'Evaluation for startups'}*/}
                {/*                    EventOnClick={() => window.open('/evaluation/landing/startup', '_blank')}*/}
                {/*                    sizeButton={'small'}*/}
                {/*                    paddingButton={'13'}*/}
                {/*                />*/}
                {/*                <ButtonBlue*/}
                {/*                    TitleButton={'Evaluation for investors'}*/}
                {/*                    EventOnClick={() => window.open('/evaluation/landing/investor', '_blank')}*/}
                {/*                    sizeButton={'small'}*/}
                {/*                    paddingButton={'13'}*/}
                {/*                />*/}

                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}




                <div className="container" style={{marginTop:'60px'}}>
                    <div className="row-m0 block-banner-peeranha" style={{backgroundImage:`url(${img_banner})`}}>
                        <div className="col-12">

                            <div className="row-m0 block-title-description-banner-peeranha">
                                <div className="col-12 p-0">
                                    <p className="title-banner">
                                        Preliminary evaluation
                                    </p>
                                    <p className="description-banner">
                                        Express assessment of startups on their market, product, team and business model, supplemented with expert feedback and recommendations.
                                    </p>
                                </div>
                            </div>
                            <div className="row-m0 block-button-banner-peeranha">
                                <div className="col-12 p-0">
                                    <ButtonGreen
                                        TitleButton={'Evaluation of startups'}
                                        EventOnClick={() => window.open('/evaluation/landing/startup', '_blank')}
                                        sizeButton={'small'}
                                        paddingButton={'26'}
                                    />
                                    <ButtonBlue
                                        TitleButton={'Evaluation for investors'}
                                        EventOnClick={() => window.open('/evaluation/landing/investor', '_blank')}
                                        sizeButton={'small'}
                                        paddingButton={'23'}
                                    />
                                    <ButtonBorder
                                        TitleButton={'See the sample'}
                                        EventOnClick={() => window.open('/Pre-Evaluation/Peeranha.pdf', '_blank')}
                                        sizeButton={'small'}
                                        paddingButton={'25'}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




                <div className="block-lending-about-container"
                     style={{padding: '80px 0 50px 0', background: '#fdfdfd'}}>
                    <div className="container-DAO">
                        <div className="container-lending">
                            <p className="title-program-bounty">Our mission</p>


                            <div className="shell-group-3-column" style={{padding:'0'}}>
                                <div className="block-group-3-column" style={{padding:'0'}}>
                                    <div className="block-item-3-column">
                                        <div className="block-img-title">
                                            <img src={browser} alt=""/>
                                            {/*<p className="title">Blockchain adoption</p>*/}
                                        </div>
                                        <p className="description-img-title">
                                            To reduce the time and costs of investors on search, tracking and evaluation of startups.
                                        </p>
                                    </div>
                                    <div className="block-item-3-column">
                                        <div className="block-img-title">
                                            <img src={Group_29} alt=""/>
                                            {/*<p className="title">Access to global capital</p>*/}
                                        </div>
                                        <p className="description-img-title">
                                            To help startups with the preparation of all necessary documents and data for further successful investments attraction.
                                        </p>
                                    </div>
                                    <div className="block-item-3-column">
                                        <div className="block-img-title">
                                            <img src={ic_methodologist_active} alt=""/>
                                            {/*<p className="title">Building expert community</p>*/}
                                        </div>
                                        <p className="description-img-title">
                                            To create a single ever-improving startup evaluation system approved by the expert community and adopted by investors.
                                        </p>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div id='tokenSale' className="block-expert-bounty"
                     style={{position: 'relative', top: '-4px', boxShadow: '0 7px 9px 0 rgba(0,0,0,0.16)', background:'#2F3849'}}>
                    <div className="container-lending">
                        <div className="block-left-expert-bounty">
                            <p className="title" style={{color:'#FFFFFF'}}>Rocket DAO Due Diligence
                            </p>
                            <p className="description"  style={{color:'#FFFFFF', marginBottom: '30px'}}>A hard Due Diligence of startups conducted by Rocket DAO expert community on 10 evaluation parameters based on the in-house approved methodologies.</p>
                            <ButtonGreen
                                TitleButton={'See the sample DueD report'}
                                EventOnClick={() => window.open('https://rocketdao.io/DueD/Multy.pdf', '_blank')}
                                sizeButton={'small'}
                                paddingButton={'40'}
                            />
                        </div>
                        <div className="block-right-expert-bounty">
                            <img src={Rocket_DAO_DD} alt=""/>
                            {/*<div className="block-button-expert-bounty">*/}
                            {/*<div className="shell-button-expert" onClick={() => this.handleShowModal()}>*/}
                            {/*<div className="block-button-expert"/>*/}
                            {/*</div>*/}
                            {/*<p className="title"  style={{color:'#FFFFFF'}}>Contact us</p>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                </div>


                <div className="block-lending-about-container" style={{padding: '80px 0 0 0'}}>
                    <div className="container-DAO">
                        <div className="container-lending">
                            <p className="title-program-bounty">How it works</p>


                            <div className="shell-list-title-img">
                                <div className="block-list-title-img">

                                    <div className="block-item-title-img title-left-img-right">
                                    <div className="block-left-item-title-img">
                                        <div className="block-number-title-description">
                                            <div className="block-left-number-title-description">
                                                <p className="number">1</p>
                                            </div>
                                            <div className="block-right-number-title-description">
                                                {/*<p className="title">Web 3.0</p>*/}
                                                <p className="description">
                                                    A startup registers its public profile on the platform and provides basic information about the project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="block-right-item-title-img">
                                        <img src={RocketDAO_Congratulations} alt=""/>
                                    </div>
                                </div>

                                    <div className="block-item-title-img img-left-title-right">
                                        <div className="block-left-item-title-img">
                                            <img src={RocketDAO_great_rock} alt=""/>
                                        </div>
                                        <div className="block-right-item-title-img">
                                            <div className="block-number-title-description">
                                                <div className="block-left-number-title-description">
                                                    <p className="number">2</p>
                                                </div>
                                                <div className="block-right-number-title-description">
                                                    {/*<p className="title">DAO</p>*/}
                                                    <p className="description">
                                                        Startup shares statistics, creates a business plan and collects investors’ "likes" in the waitlist.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-right-item-title-img img">
                                            <img src={RocketDAO_great_rock} alt=""/>
                                        </div>
                                    </div>

                                    <div className="block-item-title-img title-left-img-right">
                                        <div className="block-left-item-title-img">
                                            <div className="block-number-title-description">
                                                <div className="block-left-number-title-description">
                                                    <p className="number">3</p>
                                                </div>
                                                <div className="block-right-number-title-description">
                                                    {/*<p className="title">Web 3.0</p>*/}
                                                    <p className="description">
                                                        Startup requests a Due Diligence procedure to be conducted by the expert community to highlight strong and weak points of the project.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-right-item-title-img">
                                            <img src={Ilustraciya_investor} alt=""/>
                                        </div>
                                    </div>

                                    <div className="block-item-title-img img-left-title-right">
                                        <div className="block-left-item-title-img">
                                            <img src={Human} alt=""/>
                                        </div>
                                        <div className="block-right-item-title-img">
                                            <div className="block-number-title-description">
                                                <div className="block-left-number-title-description">
                                                    <p className="number">4</p>
                                                </div>
                                                <div className="block-right-number-title-description">
                                                    {/*<p className="title">DAO</p>*/}
                                                    <p className="description">
                                                        Verified investors study Due Diligence reports and make their investment decisions based on the results.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-right-item-title-img img">
                                            <img src={Human} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


                <div id="bounty" className="block-lending-about-container">
                    <div className="container-DAO">
                        <div className="block-program-bounty">
                            <div className="container-lending">
                                <p className="title-program-bounty">Work with us</p>
                                <div className="block-left-program-bounty">
                                    <p className="title">Order a Due Diligence</p>
                                    <p className="description">
                                        Would you like to present your startup to Rocket DAO expert community and get an independent Due Diligence report? This document contains all necessary indicators to show investors and increase your chances for raising funds.
                                    </p>
                                    <ButtonBlue
                                        TitleButton={'Apply'}
                                        EventOnClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSey8Dd9-ndzZBGB7P2r6vejraG52zQ3WiRRwJcbZV2U580I9g/viewform', '_blank')}
                                        sizeButton={'small'}
                                        paddingButton={'40'}
                                    />
                                </div>

                                <div className="block-right-program-bounty">
                                    <p className="title">Become a partner</p>
                                    <p className="description">
                                        Are you interested in cooperation? There are many options how we could benefit each other - whether you represent a startup accelerator, an investment company or want to send an individual proposition.
                                    </p>
                                    <ButtonBlue
                                        TitleButton={'Send request'}
                                        EventOnClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScNrlIvuQeKJtLlmWbdV7K1mdwZlrA5ZxGWr4BV0QyhoeaAkQ/viewform', '_blank')}
                                        sizeButton={'small'}
                                        paddingButton={'40'}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>



                {/*<div id='rocketWallet' className="block-lending-about-rocket-wallet" style={{background: 'url(./img/svg/bg.png)'}}>*/}
                    {/*<div className="container-lending">*/}
                        {/*<div className="block-left-rocket-wallet">*/}
                            {/*<img src="./img/svg/phone.png" alt=""/>*/}
                        {/*</div>*/}
                        {/*<div className="block-right-rocket-wallet">*/}
                            {/*<p className="title">Rocket DAO Wallet</p>*/}
                            {/*<p className="description">*/}
                                {/*A mobile application bringing functionality of all Rocket DAO products*/}
                            {/*</p>*/}
                            {/*<div className="block-img">*/}
                                {/*<a onClick={() => alert('Coming soon!')}><img src="./img/svg/apple.png" alt=""/></a>*/}
                                {/*<a href="https://play.google.com/store/apps/details?id=io.rocketico.mapp" target="_blank"><img src="./img/svg/google.png" alt=""/></a>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}


                {/*<div className="block-lending-about-container" style={{padding: '80px 0 110px 0'}}>*/}
                    {/*<div className="container-DAO">*/}
                        {/*<div className="container-lending">*/}
                            {/*<p className="title-program-bounty">Join the Rocket DAO community</p>*/}


                            {/*<div className="block-join-community">*/}
                                {/*<div className="block-left-join-community">*/}
                                    {/*<div className="block-cart-join-community">*/}
                                        {/*<div className="block-top-right-icon"><i className="fab fa-telegram-plane"/>*/}
                                        {/*</div>*/}
                                        {/*<p className="title">Join the conversation</p>*/}
                                        {/*<p className="description">*/}
                                            {/*Our Rocket DAO community is always welcome to discuss recent updates, news,*/}
                                            {/*ask questions or share their feedback as well as make suggestions concerning*/}
                                            {/*further development of the platform.*/}
                                        {/*</p>*/}
                                        {/*<a href="https://t.me/RocketICOen">Join us on Telegram<i className="fal fa-long-arrow-right"/></a>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="block-right-join-community">*/}
                                    {/*<div className="block-top-right-join-community">*/}
                                        {/*<div className="block-left-top-right-join-community">*/}
                                            {/*<div className="block-cart-join-community">*/}
                                                {/*<div className="block-top-right-icon"><i className="fab fa-discord"/>*/}
                                                {/*</div>*/}
                                                {/*<p className="title">Any technical questions?</p>*/}
                                                {/*<a href="https://discord.gg/g3vF59V">Join us on Discord<i*/}
                                                    {/*className="fal fa-long-arrow-right"/></a>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="block-right-top-right-join-community">*/}
                                            {/*<div className="block-cart-join-community">*/}
                                                {/*<div className="block-top-right-icon"><i*/}
                                                    {/*className="fas fa-envelope"/></div>*/}
                                                {/*<p className="title">General questions? </p>*/}
                                                {/*<a href="mailto:support@rocketdao.io" target="_blank">Email us <i*/}
                                                    {/*className="fal fa-long-arrow-right"/></a>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="block-bottom-right-join-community">*/}
                                        {/*<div className="block-cart-join-community">*/}
                                            {/*<p className="title">Find Rocket DAO around the web</p>*/}
                                            {/*<div className="block-social-link-join-community">*/}
                                                {/*<a href="https://steemit.com/@rocket.dao" target="_blank">*/}
                                                    {/*<span className="block-svg-icon">*/}
                                                        {/*<img src="./img/svg/steemit-01.svg" alt=""/>*/}
                                                        {/*<img src="./img/svg/steemit-01-green.svg" alt=""/>*/}
                                                    {/*</span>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://www.reddit.com/r/RocketDAO" target="_blank">*/}
                                                    {/*<i className="fab fa-reddit"></i>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://www.facebook.com/RocketDAO" target="_blank">*/}
                                                    {/*<i className="fab fa-facebook"></i>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://twitter.com/rocketdao_io" target="_blank">*/}
                                                    {/*<i className="fab fa-twitter"></i>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://www.linkedin.com/company/rocket-dao" target="_blank">*/}
                                                    {/*<i className="fab fa-linkedin-in"></i>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://bitcointalk.org/index.php?topic=5033016.0" target="_blank">*/}
                                                    {/*<span className="block-svg-icon">*/}
                                                        {/*<img src="./img/svg/bit.svg" alt=""/>*/}
                                                        {/*<img src="./img/svg/bit-green.svg" alt=""/>*/}
                                                    {/*</span>*/}
                                                {/*</a>*/}
                                                {/*<a href="https://medium.com/@rocketdao" target="_blank">*/}
                                                    {/*<span className="block-svg-icon">*/}
                                                        {/*<img src="./img/svg/Medium.svg" alt=""/>*/}
                                                        {/*<img src="./img/svg/Medium-green.svg" alt=""/>*/}
                                                    {/*</span>*/}
                                                {/*</a>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}


                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}

                {/*<div id='roadmap' className="block-lending-about-container"*/}
                     {/*style={{padding: '80px 0 50px 0', background: '#FFFFFF'}}>*/}
                    {/*<div className="container-DAO">*/}
                        {/*<div className="container-lending">*/}

                            {/*<div className="shell-new-road-map">*/}
                                {/*<div className="block-new-road-map">*/}
                                    {/*<div className="block-top-new-road-map">*/}
                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div className="block-left-item-new-road-map">*/}
                                                {/*<div className="block-big-title-new-road-map">*/}
                                                    {/*<p className="title">Roadmap</p>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}

                                            {/*<div className="block-right-item-new-road-map">*/}
                                                {/*<div className="block-list-dots-new-road-map">*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Sep</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Oct</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Nov</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Dec</p></div>*/}
                                                    {/*<span className="years"*/}
                                                          {/*title-year='2018'></span>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Jan</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Feb</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Mar</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Apr</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">May</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Jun</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Jul</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Aug</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Sep</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Oct</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Nov</p></div>*/}
                                                    {/*<div className="block-item-dots-new-road-map"><p*/}
                                                        {/*className="title">Dec</p></div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}

                                        {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="block-body-new-road-map">*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">System architecture drafts</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">UI prototyping and design</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}

                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Web API</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">User account and profile management</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Project's profile management</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">KYC module</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}


                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Rocket wallet Core (ethereum blockchain support)</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Rocket wallet Android version</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Rocket wallet iOS version</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Backer functionality</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">The constitution of DAO regulating platform's participants based on smart contracts</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Tools for creation DAO funds between backers</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Tools for verification and validation startups</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="block-item-new-road-map">*/}
                                            {/*<div>*/}
                                                {/*<div className="block-left-item-new-road-map">*/}
                                                    {/*<div className="block-title-item-new-road-map">*/}
                                                        {/*<p className="title">Tools for tracking startups activities	</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-right-item-new-road-map">*/}
                                                    {/*<div className="block-list-dots-new-road-map">*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="block-item-dots-new-road-map">*/}
                                                            {/*<div className='block-dots dot-green'/>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}



                                    {/*</div>*/}

                                    {/*<div className="block-roadmap-clarification">*/}
                                        {/*<span className='circle circle-blue'></span>*/}
                                        {/*<span className='brc-text'>To do</span>*/}
                                        {/*<span className='circle circle-green'></span>*/}
                                        {/*<span className='brc-text'>Done</span>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}


                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}







                <div className="block-popup-form-marketplace"
                     style={this.state.openPopup !== '0' ? {display: 'block'} : {display: 'none'}}>

                    {this.state.isSubscribe ?
                        <div className="block-form-marketplace">
                            <p className="title-form-marketplace">
                                You have successfully subscribed to Rocket DAO Launch newsletter!
                            </p>
                            <p className="description-form-marketplace">
                                Note that your lifetime discount will be available via
                                <br/><b>{this.state.savedEmail}</b> only.
                            </p>

                            <div className="block-close-popup-form-marketplace"
                                 onClick={() => this.closeForm('0')}>
                                <i className="fal fa-times"/>
                            </div>
                        </div>
                        :
                        <div className="block-form-marketplace">
                            <p className="title-form-marketplace">
                                Dear friend!
                            </p>
                            {this.state.currentDot === '1' &&
                            <p className="description-form-marketplace">
                                We will launch the platform within a month. Please, leave your email below
                                to be in-time notified about the release and get the priority right to
                                register your startup on Rocket DAO.
                            </p>
                            }
                            {this.state.currentDot === '2' &&
                            <p className="description-form-marketplace">
                                We will launch the platform within a month. Please, leave your email below
                                to be in-time notified about the release and get the priority right to
                                create your investor profile on Rocket DAO.
                            </p>
                            }
                            {this.state.currentDot === '3' &&
                            <p className="description-form-marketplace">
                                We will launch the platform within a month. Please, leave your email below
                                to be in-time notified about the release and get the priority right to join
                                Rocket DAO expert community.
                            </p>
                            }
                            <div className="block-input-button-form-email-marketplace">
                                <Input
                                    type={'email'}
                                    name={'email'}
                                    placeholder={'Your email'}
                                    value={this.state.currentEmail}
                                    onChange={this.setCurrentEmail}
                                    error={this.state.error}
                                />
                                <ButtonBlue
                                    TitleButton={'Request early access'}
                                    EventOnClick={() => this.subscribe(subscribeTypes[this.state.currentDot])}
                                    paddingButton={'50'}
                                    disabled={this.state.isSubscribe}
                                    loader={false}
                                />
                            </div>
                            <div className="block-close-popup-form-marketplace"
                                 onClick={() => this.openCloseForm('0')}>
                                <i className="fal fa-times"/>
                            </div>
                        </div>
                    }
                </div>









                <Footer></Footer>


                {/*<ModalTokenSale user={user} forProject={true}  showModal={this.state.showModal} handleCloseModal={this.handleCloseModal} />*/}

                <MetaTags>
                    <title>About - rocketdao.io</title>
                    <meta property="og:title" content={'About - rocketdao.io'}/>
                </MetaTags>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

about.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser, logout})(about)


import React from 'react';
import './swipe.scss'

export default class Swipe extends React.Component {

    render() {
        let {
            active,
            disable
        } = this.props;

        let classList = `swipe-control hide-highlight block-swipe-blue ${active && !disable? 'active' : '' } ${disable? 'disable' : '' }`;
        return (
            <div className={classList} onClick={disable ? null : this.props.onClick}>

            </div>
        );
    }
}


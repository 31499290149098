import React, {useState, useEffect, useRef} from 'react';
import './style.scss';
import Slider from "react-slick";
import AnswerPoint from '../../pages/ViralTest/components/AnswerPoint';

function eventFire(el, etype){
    if (el.fireEvent) {
        el.fireEvent('on' + etype);
    } else {
        var evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
    }
}

export const HellBaraban = (props) => {
    const [check, setCheck]   = useState([]);
    const [answerArray, setAnswerArray]   = useState([]);
    const [thisAnswersCheck, setThisAnswersCheck]   = useState([]);
    const Slick = useRef(null);
    let Y = 0;
    let slickPermit = false;
    let content = null;
    let overlay = null;

    const settings = {
        speed: 300,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: false,
        vertical: true,
        verticalSwiping: true,
        draggable: false,
        touchMove: true,
        waitForAnimate: true,
        arrows: false
    };



    function overlayTouchmove(e){
        e.preventDefault();
        e.stopPropagation();
        if(slickPermit){
            if(Y <= e.targetTouches[0].clientY){
                Slick.current.slickPrev();
                // window.navigator.vibrate(100);
            } else {
                Slick.current.slickNext();
                // window.navigator.vibrate(100);
            }
            slickPermit = false;
        }
    }

    function overlayTouchstart(e){
        Y = e.targetTouches[0].clientY;
        slickPermit = true;
    }

    function overlayCLick(e){
        const rect = overlay.getBoundingClientRect();
        const diff = e.clientY - rect.top;

        if(diff < 70){
            Slick.current.slickPrev();
        }

        if(diff >= 70 && diff <= 170){
            const element = document.querySelector('.slick-current + .slick-active .block-viral-answer-point');
            if(element){
                eventFire(element, 'click');
            }
        } else {
            Slick.current.slickNext();
        }
    }

    function contentTouchstart(e){
        this.allowUp = (this.scrollTop > 0);
        this.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
        this.slideBeginY = e.pageY;
    }

    function contentTouchmove(e){
        var up = (e.pageY > this.slideBeginY);
        var down = (e.pageY < this.slideBeginY);
        this.slideBeginY = e.pageY;
        if ((up && this.allowUp) || (down && this.allowDown)) {
            e.stopPropagation();
        }
        else {
            e.preventDefault();
        }
    }

    useEffect(() => {
        console.log('next');
        try {Slick.current.slickGoTo(0);} catch {}
    }, [props.updateBaraban]);

    useEffect(() => {
        setAnswerArray(props.randAnswerArray);
        setThisAnswersCheck(props.answersCheck);
    }, [props]);


    useEffect(() => {



        // document.ontouchmove = function(e) {e.preventDefault()};

        content = document.getElementById('scrollport');
        overlay = document.getElementById('overlay');

        overlay.addEventListener('touchmove', overlayTouchmove, false);

        overlay.addEventListener('touchstart', overlayTouchstart, false);

        overlay.addEventListener('click', overlayCLick, false)

        content.addEventListener('touchstart', contentTouchstart);

        content.addEventListener('touchmove', contentTouchmove);

        return () => {
            // document.ontouchmove = function(){};
            console.log('remove');

            overlay.removeEventListener('touchmove', overlayTouchmove);
            overlay.removeEventListener('touchstart', overlayTouchstart);
            overlay.removeEventListener('click', overlayCLick);
            content.removeEventListener('touchstart', contentTouchstart);
            content.removeEventListener('touchmove', contentTouchmove);
        }

    }, []);

    const renderList = () => {
        if ( Array.isArray(answerArray) &&  Array.isArray(thisAnswersCheck)) {
            let i = -1;
            let list = answerArray.map((item, key) => {
                i++;
                return <div key={key} className='default-answer-point'>
                    <AnswerPoint
                        AnswerText={item.title}
                        answerCheck={thisAnswersCheck[i] || false}
                        answerNumber={i}
                        handleClick={(click) => props.handleClick(click)}
                        // canUserAnswer={this.props.canUserAnswer}
                    />
                </div>
            });
            return list;
        }
    };

    return (
        <React.Fragment>
            <div className={'wrapper'}>
                <div id={'scrollport'} className={'drum'}>
                    <div id={'overlay'}/>
                    <Slider ref={Slick} {...settings}>
                        <div className={'cell white'}/>
                        {renderList()}
                        <div className={'cell white'}/>
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
};
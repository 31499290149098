import React from "react";
import RocketDAOBlue from '../images/LOGO-RocketDAO.svg'
import Child from '../images/child.jpg'
import ButtonTransWhite2 from './controls2.0/Button2.0/ButtonTransWhite2.0'
import ButtonTransBlue2_0 from './controls2.0/Button2.0/ButtonTransBlue2.0'
import {sendInfoToServer} from "../actions/Profile";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    let obj = {
        userId: localStorage.getItem('userId'),
        error: `${error}`,
        pathname: window.location.pathname,
        info: info
    };

    sendInfoToServer(obj)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='global-container-error'>
            <div className='gce-logo'>
                <img src={RocketDAOBlue} alt=""/>
            </div>
            <div className='gce-text-top'>Sorry, something went wrong.</div>
            <div className='gce-text-1'>We're working on getting this fixed as soon as we can.</div>

            {/*<div className='gce-temporary'>*/}
                {/*<img src={Child} alt=""/>*/}
            {/*</div>*/}

            <div className='gce-back'>
                <ButtonTransWhite2
                    TitleButton="Go back"
                    EventOnClick={() => window.history.back()}
                    sizeButton="small"
                    paddingButton="20"
                />
                <ButtonTransWhite2
                    TitleButton="Try again"
                    EventOnClick={() => window.location.reload()}
                    sizeButton="small"
                    paddingButton="20"
                />
            </div>
            <div className='gce-link'><a href="mailto:support@rocketdao.io" target="_blank">support@rocketdao.io</a></div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import React from 'react'
import {getQuestions} from '../../actions/UserSignup'

export default class SignupPurpose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purpose:'',
      questions: [],
      selectQuestions: [],
      btnDisable: true
    };
    this.nextStep = this.nextStep.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
    this.checkBtnDisable = this.checkBtnDisable.bind(this);
  };

  componentDidMount() {
    getQuestions().then((res)=>{
      this.setState({ questions: res.data })
    })
      .catch(error => {
        console.error('error', error)
      })
  }

  nextStep(){
    this.state.questions.forEach(function (question) {
      if(question.active) this.state.selectQuestions.push(question.id);
    }, this)
    this.props.get('questions', this.state.selectQuestions);
    // console.log(this.state.selectQuestions)
  }

  checkBtnDisable () {
    let check = true;
    this.state.questions.forEach(function (question) {
      if(question.active)check = false
    })
    this.setState({btnDisable: check})
  }

  selectQuestion (item) {
    console.log(item)
    let index = this.state.questions.indexOf(item);
    this.state.questions[index].active = !this.state.questions[index].active;
    this.setState({questions:[...this.state.questions]});
    this.checkBtnDisable()
  }


  render() {
    var listQuestions = this.state.questions.map(function(item) {
      return (
        <div key={item.id} className={"single-question " + (item.active ? 'active' : '')}  onClick={()=>this.selectQuestion(item)}>
          <div className="ch-checkbox">
            <span className="icon_checkbox"></span>
          </div>
          <div className="ch-text">{item.text}</div>
        </div>
      );
    }, this);

    return (
      <div className="card-help col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11">
        <div className="ch-title">How can we help you?</div>
        <div className="list-questions">
          {listQuestions}
        </div>
        <div className="block-btn">
          <button className="login-form__btn login-form__btn_join" disabled={this.state.btnDisable} onClick={this.nextStep}>Next</button>
        </div>

      </div>
    )
  }
}



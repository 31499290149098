import React from 'react';
import {createAvatar} from "../../../user/createAvatar";
import config from '../../../../config';
import moment from 'moment';

import StartupInvitePopup from  '../popupMembers/StartupInvitePopup';
import StartupNewDeletePopup from  '../popupMembers/StartupNewDeletePopup';
import StartupDeletePopup from  '../popupMembers/StartupDeletePopup';
import StartupSendPopup from  '../popupMembers/StartupSendPopup';

import {getMyProject2} from "../../../../actions/Project";
import {connect} from "react-redux";
import {getMyFund} from "../../../../actions/Fund";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import axios from "axios";
import SetTotalMembersPopup from "../popupMembers/SetTotalMembersPopup";
import {Row} from "../../../v4/Grid/Row";
import TeamCard from "../../../v4/TeamCard";

class StartupTeam extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showStartupInvitePopup: false,
            showNewDelete: false,
            showDelete: false,
            showSend: false,
            showSetMembers: false
        }
    }

    close(){
        this.get();
        this.setState({showStartupInvitePopup: false});
        this.setState({showNewDelete: false});
        this.setState({showDelete: false});
        this.setState({showSend: false});
        this.setState({showSetMembers: false});
        this.setState({currentMember: {}})
    }

    get(){
        this.props.getMyProject2(this.props.startup.id)
    }

    showNewDelete(member){
        this.setState({currentMember: member}, () => {
            this.setState({showNewDelete: true});
        })
    }

    showDelete(member){
        this.setState({currentMember: member}, () => {
            this.setState({showDelete: true});
        })
    }

    showSend(member){
        this.setState({currentMember: member}, () => {
            this.setState({showSend: true});
        })
    }
    showSetTotal(){
        this.setState({showSetMembers: true});
    }

    delete(){
        axios
            .delete(`/v2/projects/${this.props.startup.id}/team/${this.state.currentMember.id}`)
            .then(() => {
                this.get();
                this.close();
            }).catch(e => {
                console.log(e.response);
            })
    }

    reInvite(member){
        axios
            .post(`/v2/projects/${this.props.startup.id}/team/${member.id}/reinvite`)
            .then(() => {
                this.get();
                this.showSend(member);
            }).catch(e => {
                console.log(e.response);
            })
    }

    setTotalTeam = (number) => {
        axios
            .put(`/v2/projects/${this.props.startup.id}`, {membersCount: number})
            .then(() => {
                this.get();
            }).catch(e => {
            console.log(e.response);
        })
    }

    render() {
        const {startup} = this.props;

        return (
            startup && <div className='block-startup-team' id="startup-team">
                <Row className='block-startup-team-title justify-content-between'>
                    <div className='col-12 col-md margin-top-12'>
                        <Row className='m6'>
                            <div className='main-title team-title col-auto'>Team members: {startup.teamCount}/{startup.membersCount}</div>
                            <div className='edit-total-members-text col-auto' onClick={()=>{this.showSetTotal()}}>Edit total number</div>
                        </Row>
                    </div>
                    <div className='col-auto margin-top-12'>
                        <div className='button-add-members' onClick={() => this.setState({showStartupInvitePopup: true})}><span>Invite other members</span></div>
                    </div>
                </Row>
                <div className='block-team-instruct-text' style={{maxWidth:'488px'}}>
                    <div className='descr-text'><br/>Please invite all your team members to join the platform in order to make your profile more complete and memorable.</div>
                </div>
                {/*<div className='block-startup-team-list'>*/}
                {/*    {startup.team.map( (member, k) => {*/}
                {/*        console.log(member);*/}
                {/*        return member.User && <div className='block-body-team-list block-member-card admin' key={k}>*/}
                {/*            <div className='content-box'>*/}
                {/*                {member.isFounder && <span className='label-is-founder'>Founder</span>}*/}

                {/*                {*/}
                {/*                    member.User.logoFileId*/}
                {/*                    && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>*/}
                {/*                    || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>*/}
                {/*                }*/}

                {/*                <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>*/}
                {/*                <p className='list-position'>{member.position}</p>*/}

                {/*                <div className='row-b' style={{marginRight:'10px'}}>*/}
                {/*                    <div className='col-9'>*/}
                {/*                        <div className='list-social'>*/}
                {/*                            {member.User && member.User.facebook &&*/}
                {/*                            <a href={member.User.facebook} target="_blank"><i className="fab fa-facebook"/></a>*/}
                {/*                            }*/}
                {/*                            {member.User && member.User.linkedin &&*/}
                {/*                            <a href={member.User.linkedin} target="_blank"><i className="fab fa-linkedin"/></a>*/}
                {/*                            }*/}

                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className='col-3 delete'>*/}
                {/*                        { member.userId !== startup.userId && <div className='mem-btn' onClick={() => this.showDelete(member)}>Delete</div>}*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        ||*/}
                {/*        <div className='block-body-team-list block-member-card member' key={k}>*/}
                {/*                <div className='content-box'>*/}
                {/*                    {*/}
                {/*                        <div className="list-avatar">{ createAvatar('avatar', member.email) }</div>*/}
                {/*                    }*/}

                {/*                    <p className='list-position list-mail'>{member.email}</p>*/}
                {/*                    <p className='list-position' style={{minHeight: '25px'}}>{member.position}</p>*/}
                {/*                    <p className='list-position list-descr'>Invitation was sent on<br/>*/}
                {/*                        <span>{moment(member.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</span>*/}
                {/*                    </p>*/}
                {/*                    <div className='buttons-line-wrapper'>*/}
                {/*                        <div className='button-delete-member color-text-button mem-btn' style={{float:'left'}} onClick={() => {this.reInvite(member)}}>Resend invitation</div>*/}
                {/*                        <div className='button-delete-member color-text-button mem-btn' onClick={() => this.showNewDelete(member)}>Delete</div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*    })}*/}
                {/*</div>*/}

                <Row className='margin-top-30 team-wrapper'>
                    {startup.team.map( (member, k) => {

                        return <div className='col-12 col-sm-6 col-lg-4 margin-bottom-20' key={k}>
                            <TeamCard
                                user={member}
                                project={startup}
                                onDelete={ (user) => {member.User?this.showDelete(user):this.showNewDelete(user)} }
                                reInvite={ (user) => {this.reInvite(user)} }
                            />
                        </div>

                    })
                    }
                </Row>

                {this.state.showStartupInvitePopup && <StartupInvitePopup startup={startup} close={this.close.bind(this)}/> }
                {this.state.showNewDelete && this.state.currentMember && <StartupNewDeletePopup member={this.state.currentMember} close={this.close.bind(this)} delete={this.delete.bind(this)}/> }
                {this.state.showDelete && this.state.currentMember && <StartupDeletePopup member={this.state.currentMember} close={this.close.bind(this)} delete={this.delete.bind(this)}/> }
                {this.state.showSend && this.state.currentMember && <StartupSendPopup member={this.state.currentMember} close={this.close.bind(this)}/> }
                {this.state.showSetMembers &&                       <SetTotalMembersPopup applySet={(number)=>{this.setTotalTeam(number)}} close={this.close.bind(this)}/> }
            </div> || ''
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project,
})

StartupTeam.propTypes = {
    getMyProject2: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, {getMyProject2})(StartupTeam));
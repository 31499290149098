import React from 'react';
import './style.scss'
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";

const terms = '/document/general-terms';
const privacy = '/document/privacy-policy'

const ButtonWithTerms = ({className = '', children, color = 'green', textColorClass = '_black', EventOnClick}) => {

    const Button = {
        green: <ButtonGreen TitleButton={children} EventOnClick={EventOnClick}/>,
        blue: <ButtonBlue TitleButton={children} EventOnClick={EventOnClick}/>,
    }[color];

    return (
        <div className={`buttonWithTerms ${className}`}>
            {Button}
            <div className={`text ${textColorClass}`}>
                By clicking this button, you agree to our
                <br/><a href={terms} target={'_blank'}>Terms</a> and <a href={privacy} target={'_blank'}>Privacy Policy</a>
            </div>
        </div>
    )
};

export default ButtonWithTerms;
import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Swipe from '../../controls3.0/swipe/Swipe'
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();
        this.state = {
            InvestorTypes:[
                {id:1,Name:'Individual investor', descr:'Personal profile of an individual investor / business angel.'},
                {id:2,Name:'Angel network', descr:'A group of angel investors who have organized to invest collectively, operate more effectively and provide mutual support'},
                {id:3,Name:'Venture capital fund', descr:'Investment funds that manage the money of investors who seek private equity stakes in startups with high growth potential'}
            ],
            CheckedTypes:[]
        };
    }

    componentDidMount() {
        if (this.props.searchSettings) {
            this.setState({CheckedTypes: this.props.searchSettings['investor_type'].map(i => parseInt(i))});
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }

    ResetPopup(){
        this.setState({ CheckedTypes:[]})
    }

    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    bmodelCheck(item) {
        let _CheckedTypes = this.state.CheckedTypes;

        let isFound = _CheckedTypes.find(x => x === item.id);
        if (!isFound) {
            _CheckedTypes.push(item.id)
        } else {
            _CheckedTypes = _CheckedTypes.filter(x => x !== item.id)
        }
        this.setState({CheckedTypes:_CheckedTypes});
    }

    render() {
        let {mobileHeight} = this.props;
        const list = this.state.InvestorTypes.map((item, key) =>{
                return (
                    <div className='row-m0 business-model-list-search no-gutters w-100' key={key}>
                        <div className='row-m0 w-100 no-gutters'>
                            <div className='col-8 bmodel-list-name'>{item.Name}</div>
                            <div className='col-4 text-right'>
                                <Swipe
                                    onClick={() => {this.bmodelCheck(item)}}
                                    active={this.state.CheckedTypes.includes(item.id)}
                                />
                            </div>
                        </div>

                        <div className='row-m0 imodel-list-descr investor-list-descr w-100 no-gutters'>{item.descr}</div>

                    </div>
                )

            }
        );

        return (
            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                <Scrollbars>
                <div className='search-popup search-popup-bmodel row-m0 no-gutters' id='inv-type' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>

                    {/*<div style={{minHeight: 'calc(100% - 135px)', height: 'calc(100% - 135px)', width: '100%'}} >*/}
                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this scroll-this-display'}>
                            <div style={{maxHeight: '90%', padding: '13px 25px 20px 25px'}}>
                                {list}
                            </div>
                        </Scrollbars>
                    {/*</div>*/}

                    <div className='not-scroll-this'>
                        {list}
                    </div>

                    <div className='row-m0 buttons-line margin-top-12 no-gutters w-100'>
                        <div className='col-3 font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('investor_type',this.state.CheckedTypes); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>

                    <div className='mobile-popup-footer' style={{marginTop:'-17px'}}>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('investor_type',this.state.CheckedTypes); this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
                </Scrollbars>
            </div>
        )


    }
}




import React from 'react';
import './style.css'

export default class ButtonBlue extends React.Component {
    componentDidMount() {
        [].map.call(document.getElementsByClassName('anim-button-2-0'), el => {
            el.addEventListener('click', e => {
                console.log('test')
                e = e.touches ? e.touches[0] : e;
                const r = el.getBoundingClientRect(), d = Math.sqrt(Math.pow(r.width, 2) + Math.pow(r.height, 2)) * 2;
                el.style.cssText = `--s: 0; --o: 1;`;
                //el.offsetTop;
                el.style.cssText = `--t: 1; --o: 0; --d: ${d}; --x:${e.clientX - r.left}; --y:${e.clientY - r.top};`
            })
        })
    }

    render() {
        let {
            TitleButton,
            EventOnClick,
            sizeButton, // sizeButton = big,small
            paddingButton, //0, '' inline , >0 padding
            disabled,
        } = this.props;
        let classSize = sizeButton === 'big' ? 'button-2-0-big' : 'button-2-0-small'
        classSize += disabled ? ' disabled-button' : ''
        let styleButton
        if (paddingButton) {
            if (paddingButton > 0) {
                styleButton = {
                    display: 'inline-block',
                    paddingLeft: paddingButton + 'px',
                    paddingRight: paddingButton + 'px'
                }
            }
            else {
                styleButton = {display: 'inline-block', width: '100%'}
            }
        } else {
            styleButton = {display: 'inline-block', width: '100%'}
        }
        return (
            <div className={"block-button-2-0 button-2-0-blue " + classSize} style={styleButton}
                 onClick={!disabled ? EventOnClick : null} anim="ripple">
                <p className="block-button-2-0-title">{TitleButton}</p>
                <span className="anim-button-2-0 anim-button-2-0-blue"></span>
            </div>
        );
    }
}


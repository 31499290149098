import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {tokenLogin, updateUser} from "../../actions/Login";
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import Footer from '../footer/Footer'
import Header from '../header'
import $ from "jquery";
import ExpertPage from './ExpertPage'
import KnowledgeBase from './KnowledgeBase'
import ForumPage from '../forum/ForumPage'
import ModalFundCreate from '../modal/ModalCreateFund'
import MetaTags from 'react-meta-tags';
import {checkTitleOfButton, checkRegistration} from "../../utils/checkRegistration";

class ExpertBase extends React.Component {
    constructor() {
        super();
        this.state = {
            path: 'Expert',
            metaTitle: 'Experts',
            pathname: ''

        }
    }

    componentDidMount() {
        this.checkPath(window.location.pathname)
    }

    componentWillUpdate(nextProps, nextState) {
        if(this.state.pathname != nextProps.match.path) {
            this.checkPath(nextProps.match.path)
        }
    }

    checkPath = (pathname) => {
        let path,
        metaTitle = this.state.metaTitle;

        switch (pathname) {
            case '/experts':
                path = 'Expert';
                metaTitle = 'Experts'
                break;
            case '/forum':
                path = 'Forum';
                metaTitle = 'Experts - Forum'
                break;
            case '/knowledge_base':
                path = 'Knowledge base';
                metaTitle = 'Experts - Knowledge base'
                break;
        }

        this.setState({'path': path, pathname: pathname, metaTitle: metaTitle})
    }

    handleOpenModal = () => {
        this.setState({showLoginForm: true});
    }

    handleCloseModal = () => {
        this.setState({showLoginForm: false});
    }

    checkLogIn = () => {
        if(this.props.user && this.props.user.id){
            this.setState({forExpert: true, showModalFundCreate: true})
        } else {
            this.handleOpenModal();
        }
    }

    handleCloseModalFundCreate = () => {
        this.setState({showModalFundCreate: false, joinFund: null, forProject: false, forExpert: false})
    }


    render() {
        let user = this.props.user || {}

        return (
            <div className="block-team-2-0 background-white">

                <Header user = {user} showLoginForm={this.state.showLoginForm} offSticky={true}></Header>


                <div className="shell-menu-down-sticky">
                    <div className="block-menu-down-sticky">
                        <div className="block-left-menu-down-sticky">
                            <p className="title">{this.state.path}</p>
                        </div>

                        <span className='span-divider-expert'>|</span>

                        <div className="block-right-menu-down-sticky">
                            <div className="block-list-link-menu-down">

                                {this.state.path != 'Expert' &&
                                    <span>
                                        <Route path={'/experts'} exact={false} children={({match}) => (
                                            <Link to={'/experts'} className='link-style'>
                                                <a className={'link-item ' + (match ? 'active' : '')}>Experts</a>

                                            </Link>
                                        )}/>
                                    </span>

                                }

                                {this.state.path != 'Forum' &&
                                <Route path={'/forum'} exact={false} children={({match}) => (
                                    <Link to={'/forum'} className='link-style'>
                                        {this.state.path == 'Knowledge base' &&
                                        <span className='span-divider-expert'>|</span>
                                        }
                                        <a className={'link-item ' + (match ? 'active' : '')}>Forum</a>
                                    </Link>
                                )}/>
                                }
                                {this.state.path != 'Knowledge base' &&
                                    <span>
                                        <Route path={'/knowledge_base'} exact={false} children={({match}) => (
                                            <Link to={'/knowledge_base'} className='link-style'>
                                                <span className='span-divider-expert'>|</span>
                                                <a className={'link-item ' + (match? 'active' : '')}>Knowledge base</a>
                                            </Link>
                                        )}/>
                                    </span>

                                }

                                {!user.isExpert &&
                                <a className="link-item button" style={{'float': 'right'}} onClick={() => checkRegistration(user, 'expert')}>Join expert community</a>
                                }

                            </div>
                        </div>
                    </div>
                </div>

                {/*<ExpertPage/>*/}

                {/*<KnowledgeBase/>*/}

                {/*<ForumPage/>*/}



                <Switch>
                    <Route path={'/experts'} render={(props) => (
                        <ExpertPage/>
                        )}
                    />
                    <Route path={'/knowledge_base'} render={(props) => (
                        <KnowledgeBase/>
                        )}
                    />
                    <Route path={'/forum'} render={(props) => (
                        <ForumPage/>
                    )}
                    />
                </Switch>



                <Footer></Footer>


                {this.state.showModalFundCreate &&
                <ModalFundCreate user={user} forExpert={true} showModal={this.state.showModalFundCreate} handleCloseModal={this.handleCloseModalFundCreate} />
                }

                <MetaTags>
                    <title>{this.state.metaTitle} - rocketdao.io</title>
                    <meta property="og:title" content={`${this.state.metaTitle} - rocketdao.io`}/>
                </MetaTags>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

ExpertBase.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser})(ExpertBase)

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import history from './history'
import rootReducer from './reducers/Root'
import App from './App'
import { userLoggedIn } from './actions/AuthActions'


const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

if(localStorage.RocketToken){
  store.dispatch(userLoggedIn(localStorage.RocketToken))
}

ReactDOM.render(
    <Router history={history}>
      <Provider  store={store}>
          <App />
      </Provider>
    </Router>,
document.getElementById('root'));
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import moment from "moment/moment";

import {updateAccelerator} from "../../../actions/Accelerator";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
// import {showMessage} from "../../../utils/showMessage";
// import Input from "../../controls3.0/input/Input";
// import ListTitleDescriptionMore from '../../controls3.0/listTitleDescriptionMore/ListTitleDescriptionMore'
// import Select from "../../controls3.0/select/Select";
// import RocketDatePicker from '../../controls3.0/datePicker/DatePicker';
// import CheckBox from "../../controls3.0/checkBox/checkBox";
import {getListWorkExp} from '../../../actions/Profile'
import human from "../../../images/ilustaciya/Ilustraciya_investor-accelerator.svg";
import TextArea from "../../v3/Textarea/index";


class Step5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator,
            errors: {shortDescription: ''},
            shortDescription: props.accelerator.shortDescription || '',
        }
    }

    componentDidMount() {

    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        switch (name) {
            case 'shortDescription':
                if(value.length > 200){
                    this.state.errors[name] = 'The text is too long, 200 symbols max';
                } else {
                    delete this.state.errors[name];
                }
                value = value.slice(0, 200);
                break;
        }

        this.setState({[name]: value});

    }
    validate(descLength){
        const {shortDescription} = this.state;
        return shortDescription.length && shortDescription.length <= descLength
    }


    update = (nextStep, onlyStep) => {
        const accelerator = this.state.accelerator;
        let obj = {
            shortDescription: this.state.shortDescription || ''
        }

        if (!onlyStep) this.setState({loading: true})


        this.props.updateAccelerator(obj, accelerator.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {accelerator, errors, shortDescription} = this.state;

        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position:'relative'}}>
                    <img src={human} alt="" className="img-right-step"
                         style={{top: '160px', right: '80px',}}/>
                    <div className="container-step-1-reg">
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10 color-rgba-88">
                            Identification
                        </p>

                        <p className="title-top-page-reg-expert text-h4 left-text shell-margin-bottom-80 shell-margin-top-0 color-rgba-88">
                            Let the community know your {/*this.state.accelerator.title*/}accelerator better.
                        </p>


                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10 shell-margin-top-0 color-rgba-88">
                            Accelerator description
                        </p>
                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0 color-rgba-88"
                           style={{maxWidth: '380px'}}>
                            Provide a short summary about or a slogan of your accelerator.
                        </p>

                        <div className="shell-text-area-kyc text-left mw380">

                            <TextArea name={'shortDescription'} val={shortDescription}
                                      onChange={this.onChange} placeholder='200 symbols'
                                      defaultHeight={72}
                                      widthClass={'container-full-reg'}
                                      widthClass2={'shell-text-area-kyc text-left mw380'}
                                      noPopUp={true}
                                      error={errors.shortDescription}
                            />
                        </div>
                    </div>
                </div>

                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(4, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(6)}
                                paddingButton={'54'}
                                disabled={!this.validate(200) || Object.keys(this.state.errors).length}
                                loader={false}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step5.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step5)
import React from 'react';

import Header from "../header";
import Footer from "../footer/Footer";
import $ from "jquery";
import {connect} from "react-redux";
import {tokenLogin} from "../../actions/Login";

import PropTypes from "prop-types";

class PaddlePage extends React.Component {
    isS = true;

    constructor(props){
        super(props);
        if(localStorage.RocketToken){
            this.props.tokenLogin(localStorage.RocketToken);
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://cdn.paddle.com/paddle/paddle.js";
        script.async = true;
        script.onload = () => this.scriptLoaded();

        document.body.appendChild(script);
    }

    scriptLoaded(){
        window.Paddle.Setup({ vendor: 53608 })
    }

    Buy(){
        window.Paddle.Checkout.open({ product: 568737, allowQuantity: false });
    }

    render() {

        return (
            <React.Fragment>
                <div className=''>
                    <Header location={this.props.location} user={this.props.user} bgcColor={'#0028DC'} />
                    <div className='block-header-marketplace' style={{position: 'relative'}}>
                        <button onClick={this.Buy}>Buy now!</button>
                    </div>
                    <Footer/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

PaddlePage.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    // getExpert: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(PaddlePage)
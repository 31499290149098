import React from 'react';
import './style.scss';
import config from "../../../config";
import {createAvatar} from "../../user/createAvatar";
import {Row} from "../Grid/Row";
import moment from "moment";

const TeamCard = ( { user = {}, project = {}, onDelete=()=>{}, reInvite=()=>{}, type='default', addClick=()=>{}, showAdmin=false  } ) => {

    return (
        <div className={'team-card-block'}>
            {user.isFounder && <span className='label-is-founder'>Founder</span>}
            {showAdmin && <span className='label-is-founder'>Admin</span>}

        {/*Avatar*/}
            {user.User && <div>
                {user.User.logoFileId &&
                <div className="avatar" style={{background: `url(${config.proxy}/file/${user.User.logoFileId}/view)`}}/>
                || <div className="avatar">{ createAvatar('avatar', user.User.firstName) }</div>
                }
            </div> ||
                <div className="avatar">{ createAvatar('avatar', user.email) }</div>
            }

        {/*Text*/}
            {user.User &&   <p className='name-title name-title-wrapper'>{user.User.firstName} {user.User.lastName}</p>
            ||              <p className='name-title email-title name-title-wrapper'>{user.email}</p> }

            <p className='position-title position-title-wrapper'>{user.position}</p>

            {!user.User &&
                <p className='data-descr data-descr-wrapper'>Invitation was sent on<br/>
                    <span>{moment(user.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</span>
                </p>}

            {type!=='default' &&
            <p className='data-descr data-descr-wrapper'>Request has been sent on<br/>
                <span>{moment(user.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</span>
            </p>}

        {/*Buttons + social*/}
            <Row className='justify-content-between buttons-line m3'>
                {/*social*/}
                {user.User && type==='default' && <div className='col-auto'>
                    <div className='list-social'>
                        {user.User && user.User.facebook &&
                        <a href={user.User.facebook} target="_blank"><i className="fab fa-facebook"/></a>
                        }
                        {user.User && user.User.linkedin &&
                        <a href={user.User.linkedin} target="_blank"><i className="fab fa-linkedin"/></a>
                        }

                    </div>
                </div>}

                {/*Buttons*/}
                {!user.User && <div className='col-auto'>
                    <div className='delete-title' onClick={() => {reInvite(user)}}>Resend invitation</div>
                </div>}

                {type!=='default' && <div className='col-auto'>
                    <div className='delete-title' onClick={() => {reInvite(user)}}>Add to {type.charAt(0).toUpperCase() + type.slice(1)}</div>
                </div>}


                <div className='col-auto'>
                    { user.userId !== project.userId && <div className={`delete-title ${type!=='default'?'red-color':''}`} onClick={() => onDelete(user)}>{type==='default'?'Delete':'Reject'}</div>}
                </div>
            </Row>
        </div>
    );
};

export default TeamCard;
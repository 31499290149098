import React from 'react';
import './LikeStyle.css'
import {Link} from "react-router-dom";

export default class LikeBlockToList extends React.Component {

    render() {

        let {
            avatar,
            name,
            descr,
            time,
            idStartup
        } = this.props;


        return (
            <div className='new-like-block-to-list row-0'>
                <Link to={'/startup/' + idStartup} target={'_blank'}>
                    <div className='main-block'>
                        <div className='avatar' style={{backgroundImage: `url(${avatar})`}}/>
                        <div className='subtitle'>
                            <div className='name-line'>
                                {name}
                            </div>
                            <div className='descr-line'>
                                {descr}
                            </div>
                        </div>
                    </div>
                    <div className='block-info'>

                        <div className='time-title'>{time}</div>
                        <div className='icon-like'/>
                    </div>
                    <div className='stripe'/>
                </Link>
            </div>
        );
    }
}
import React from 'react';
import './loginPopupStyle.css'

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import history from '../../../history';
import {disableScroll, enableScroll} from "../../../utils/scroll";
import {isExist} from "../../../utils/helper";


export default class PopupLogin extends React.Component {
    componentDidMount(){
        disableScroll();
    }
    componentWillUnmount(){
        enableScroll();
    }

    render() {
        return (
            <div className='popup-overlay'>
                <div className='block-login-popup-container text-align-center popup-fc'>
                    <div className='close-title' onClick={this.props.onClose}>
                        <div className='close'/>
                    </div>
                    <div className='row-m0 w-100 login-popup-main-title font-weight-500'>{this.props.title || 'Oops, only logged-in users can do this.'}</div>
                    <div className='row-m0 w-100 login-popup-descr margin-top-25'>{this.props.text || 'Register now to have full access to the platform\'s functionality. It will take 15 sec.'}</div>


                    { !isExist(() => this.props.user.id) &&
                        <div className="row-m0 w-100 block-button-popup-login login-popup-button margin-top-45">
                            <ButtonGray
                                TitleButton={'Log in'}
                                EventOnClick={() => history.push('/login', this.props.commandState || {})}
                                paddingButton={'1'}
                                disabled={false}
                            />
                        </div>
                    }

                    <div className="row-m0 w-100 block-button-popup-register login-popup-button margin-top-25">
                        <ButtonBlue
                            TitleButton={this.props.regBtn || 'Register'}
                            EventOnClick={() => {
                                console.log(this.props.commandState);
                                history.push(this.props.register || '/start_reg', this.props.commandState || {})
                            }}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
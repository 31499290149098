import React from 'react';

import './styleViewEval.css'
import "../../../libs/grid/grid.css";
import Swipe from "../../controls3.0/swipe/Swipe";

export default class ExpertTotalScore  extends React.Component {
    state={
        EnableVisability: false
    };

    onChangeSwipe=()=>{
        this.setState({EnableVisability:!this.state.EnableVisability})
    };

    render() {
        let {
            TotalScore,
            FullVersion
        } = this.props;


        return (
            <div className={`expert-review-score expert-total-score text-center ${FullVersion?'expert-total-score-full':''}`}>
                <div className='block-info-icon-absolute block-info-icon cursor-pointer'>
                     <span className="new-black-tool-tip"
                           data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups at the idea stage (projects with an idea and literally nothing more), whereas 10 goes to startups at the B Series round and higher.">
                    <div className='info-icon'>i</div>
                    </span>
                </div>
                <div className='row-0 expert-main-score-block'>
                    <div className='row-0 review-score text-center'>
                        <div className='new-col expert-main-score font-weight-500'>{TotalScore}</div>
                        <div className='new-col expert-main-score-ten' style={{marginTop:'19px'}}>/10</div>
                    </div>
                    <div className='row-0 main-score-title color-rgba-88'>Defined round: Seed</div>
                </div>
                <div className='row-0 total-score-swipe block-for-swipe' style={FullVersion?{display:'none'}:{display:'inline-block'}}>
                    <Swipe
                        onClick={() => this.onChangeSwipe()}
                        active={this.state.EnableVisability}
                    />
                    <div className='text-swipe-descr'>{!this.state.EnableVisability?'Enable':'Disable'} rating visability</div>
                </div>
            </div>
        )
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {getLastProject, updateProject} from "../../../actions/Project";
import {SocialContacts} from "../../v3/socialContacts";

class Step16 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {contacts: []}
            , errors: {}
            , validate: false
        }
    }

    componentDidMount() {
        if(!this.state.project.id){
            this.props.getLastProject()
        }
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {

        }
    }

    handle = (data, errors, validate) => {
        this.setState({project: data, errors, validate})
    }



    update = (nextStep, onlyStep) => {
        let project = {...this.state.project, isFinished:true, event_type: 'startup_contacts_18'}

        if(!onlyStep) this.setState({loading: true})

        this.props.updateStartup(project, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }


    render() {
        let {project, errors} = this.state;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg container-step-16" style={{position: 'relative'}}>
                            <div className="container-width-600">
                                <p className="text-h1 color-text-reg-gray margin-0">
                                    Startup's pages
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 text-middle">
                                    Almost done!
                                </p>
                                <p className="text-button color-text-reg-gray margin-0">
                                    How can the community contact you and learn some more exciting details about the project? Here you can share the links to social media profiles of your startup.
                                </p>
                            </div>



                            <div
                                className="block-list-cart-reg-startup block-gradient-gray-reg padding-top-35 margin-top-30">
                                <div className="container-step-2-reg">
                                    <SocialContacts object={project} errors={this.state.errors} handle={this.handle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(21, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(20)}
                                paddingButton={'56'}
                                disabled={!this.state.validate}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step16.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step16)


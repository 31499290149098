import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import ReactModal from "react-modal";

import Input from "../../../controls3.0/input/Input";
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import Loader from "../../../test-controls/preloader/preloader";

import config from "../../../../config";

import "./investClubAdmin.css";

const InvestClubAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios.get("/v2/ivestclub/admins").then((data) => {
      setIsLoading(false);
      setAdmins(data.data.users || []);
    });
  }, []);

  const onAdminAdd = async () => {
    const regex = /user\/(.+)/;
    const found = value.match(regex);
    if (!found) {
      return NotificationManager.error(
        "URL not valid. Please provide link as https://rocketdao.io/user/12345"
      );
    }
    setIsLoading(true);

    try {
      const data = await axios.put(`/v2/ivestclub/admins/${found[1]}`);
      NotificationManager.info("User added");
      setAdmins(data.data.users || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onAdminAdd();
    }
  };

  const onAdminDelete =  async () => {
    setIsLoading(true);
    const id = userToDelete.id;
    setUserToDelete(null);


    try {
      const data = await axios.delete(`/v2/ivestclub/admins/${id}`);
      NotificationManager.info("User deleted");
      setAdmins(data.data.users || []);
    } catch (e) {
      return NotificationManager.error(e.response.data.error);
    } finally {
      setIsLoading(false);
    }  };

  return (
    <div>
      <div className="investClubInput">
        <Input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={onEnterPress}
          placeholder={"Enter a link to add new admin"}
        />
        <ButtonGreen
          TitleButton={"Add new admin"}
          onMouseEnter={null}
          onMouseLeave={null}
          EventOnClick={onAdminAdd}
        />
      </div>
      {admins.map((user, key) => (
        <article
          className="row-m0 shell-header-sticky-role invest-club-paper"
          key={key}
        >
          <div className="col-lg-1 p-0">
            <div
              className="block-avatar url"
              style={{
                backgroundImage: `url(${config.proxy}/file/${user.logoFileId}/view`,
              }}
            ></div>
          </div>
          <div className="col-lg-10 p-0">
            {user.firstName} {user.lastName}
          </div>
          <div className="col-lg-1 p-0">
            {user.roles.indexOf("investClubSuperAdmin") === -1 ? (
              <div
                className="close-title"
                onClick={() => setUserToDelete(user)}
              >
                <div
                  className="close"
                  style={{ opacity: "1", left: "auto", right: 0, top: "-8px" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </article>
      ))}
      <NotificationContainer />
      <Loader loaded={!isLoading} />
      <ReactModal
        ariaHideApp={false}
        isOpen={Boolean(userToDelete)}
        onRequestClose={() => setUserToDelete(null)}
        className="Modal  modal-invest-club"
        overlayClassName="Overlay"
      >
        {userToDelete && (
          <React.Fragment>
            <div className="close-title" onClick={() => setUserToDelete(null)}>
              <div
                className="close"
                style={{
                  opacity: "1",
                  left: "auto",
                  right: "35px",
                  top: "35px",
                }}
              />
            </div>

            <div
              className="invest-club-block-avatar"
              style={{
                backgroundImage: `url(${config.proxy}/file/${userToDelete.logoFileId}/view`,
              }}
            ></div>
            <h5 className="margin-15">
              {userToDelete.firstName} {userToDelete.lastName}
            </h5>
            <h3 className="margin-15"> Delete admin?</h3>
            <ButtonGreen
              className="margin-15 red-background"
              TitleButton={"Delete"}
              onMouseEnter={null}
              onMouseLeave={null}
              EventOnClick={onAdminDelete}
            />
          </React.Fragment>
        )}
      </ReactModal>
    </div>
  );
};

export default InvestClubAdmin;

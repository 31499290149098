import React from 'react';
import "./style.css"
import GuestRoute from "../routes/GuestRoute";
import Privacy from "./privacy-policy";
import Rules from "./rules-of-conduct";
import Cookie from "./cookie-notice";
import Risk from "./risk-warning";
import General from "./general-terms";
import {Switch} from "react-router-dom";


export default class routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <Switch>
                <GuestRoute exact path="/document" component={Privacy}/>
                <GuestRoute exact path="/document/privacy-policy" component={Privacy}/>
                <GuestRoute path="/document/rules-of-conduct" component={Rules}/>
                <GuestRoute path="/document/cookie-notice" component={Cookie}/>
                <GuestRoute path="/document/risk-warning" component={Risk}/>
                <GuestRoute path="/document/general-terms" component={General}/>
            </Switch>
        );
    }
}

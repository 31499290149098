import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {Link} from 'react-router-dom'
import moment from "moment/moment";
import $ from "jquery";

import packageJSON from './../../../package.json'
import Loader from './../test-controls/preloader/preloader'
import Header from '../header'
import Footer from '../footer/Footer'
// import Tag from '../controls2.0/Tag2.0/Tag2.0'
// import ModalProjectCreate from '../modal/ModalProjectCreate'
// import ModalFundCreate from '../modal/ModalCreateFund'
// import ModalTokenSale from '../modal/ModalTokenSale'
// import Tag2 from '../controls2.0/Tag2.0/Tag2.0'
// import ButtonBlue2 from '../controls2.0/Button2.0/ButtonBlue2.0'
import ButtonGreen from '../controls3.0/button/ButtonGreen'
// import ButtonTransWhite2 from '../controls2.0/Button2.0/ButtonTransWhite2.0'
// import ButtonTransBlue2 from '../controls2.0/Button2.0/ButtonTransBlue2.0'
// import AvatarUser from '../controls2.0/avatarUser'

import {generateEvent} from '../../actions/amplitude';
import history from "../../history";

import '../test-controls/Page2.0/css/style.css'
import '../test-controls/Page2.0/css/StartPage.css'
import './home.css'
// import './test2.scss'
import '../controls2.0/ProgressBar2.0/ProgressBar2.0.css';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import '../test-controls/newStartPage/startStyle.css';

import {getFundsPopular} from "../../actions/Fund"
import {getUserProfile} from "../../actions/Profile"
import {getProjectInfoBalance, getProjectsPopular, getStartup} from '../../actions/Project'
import {logout, tokenLogin, updateUser} from '../../actions/Login'
import {checkTitleOfButton, checkRegistration} from "../../utils/checkRegistration";

import img_Group_mucap from '../../images/Group_mucap.svg'
import DD_1234 from '../../images/RocketDAO-DD.png'
import LustraInvestor from "../../images/ilustaciya/Lustra-investor_rocket_DAO.svg";
import LustraStartup from "../../images/ilustaciya/Lustra-Startup_rocket_DAO.svg";
import LustraExpert from "../../images/ilustaciya/Lustra-Expert_rocket_DAO.svg";
import Input from "../controls3.0/input/Input";
import ButtonBlue from "../controls3.0/button/ButtonBlue";
import axios from "axios";

import './styleMarcketplace.css'
import avatar from "../expert4.0/pic/profilephoto.webp";
import Baner from "../../images/ilustaciya/Baner-rocket_dao.png";
import avatar_1 from "../../images/icon/1@2x-min.png";
import avatar_2 from "../../images/icon/2@2x-min.png";
import avatar_3 from "../../images/icon/3@2x-min.png";
import {getListIndustries} from "../../actions/Project";

import {VideoPopUp} from "../v3/videoPopupPage";
import {CartPopUp} from "../v3/startupPopupPage";
import ButtonBlack from "../controls3.0/button/ButtonBlack";
import handleDD from '../../utils/handleDD'
import {getNotification} from '../../actions/api'
import Slider from "react-slick";
import {createAvatar} from "../../components/user/createAvatar"
import {youtubeParser} from '../../utils/RegExp';
import MetaTags from "react-meta-tags";
import config from "../../config";

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className="startup-slider-arrow startup-arrow-next"
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <i className="fal fa-angle-right"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className="startup-slider-arrow startup-arrow-prev"
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <i className="fal fa-angle-left"></i>
        </div>
    );
}

Number.prototype.toMoneyFormat2 = function () {
    if (this >= 1000000) {
        return (this / 1000000).toFixed(2).replace(/\.00$/, '') + 'M';
    }

    if (this >= 1000) {
        return (this / 1000).toFixed(2).replace(/\.00$/, '') + 'K';
    }

    return this || 0;
}

String.prototype.descriptionFormat = function (len = 125) {
    if (this.length > len) {
        return this.substring(0, len) + '...';
    } else {
        return this;
    }
}

const subscribeTypes = {
    '1': 'startup',
    '2': 'investor',
    '3': 'expert',
    '4': 'other',
    '5': 'metrics',
    '6': 'newsletter'
}

const expertParams = {
    23: { //
        7: { //Team
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        4: { //Tokenomics
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    },
    69: { // Дробышевский
        10: { //id	10 title	Market
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        2: { //id	2 title	Marketing and PR
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    28: { // Koltun
        3: { //id	3 title	Technologies
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    25: { // Mikhailov
        8: { //id	8 title	Risk
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        30: { //id	8 title	fina
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    31: { // Kuryan
        9: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        1: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    216: { // Sanchuk
        6: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    30: { // Grablevski
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    34: { // Inga
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    221: { // Torri
        1: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    252: { // Skvorc
        9: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    327: { // Dominik Franek
        10: { //id
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    }
}

class Mp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            showModal: false,
            listIndustries: [],
            listFunds: [],
            listAccelerators: [],
            listProjects: [],
            listExperts: [],
            listInvestors: [],
            listProjectsWithVideo: [],
            listProjectsScore: [],
            listProjectsPreliminaryEvaluation: [],
            expertsId: [618, 700, 131, 625],
            parentDOM: [],
            listKnowlendgeBase: [],
            showMProjectCreate: false,
            showModalFundCreate: false,
            showModalTokenSale: false,
            landingType: 'Fund',
            activeDot: '2',
            stateClickDots: false,
            openPopup: '0',
            savedEmail: localStorage.savedEmail || null,
            savedEmail2: localStorage.savedEmail2 || null,
            isSubscribe: false,
            isSubscribe2: !!localStorage.savedEmail2,
            currentEmail: '',
            error: '',
            currentDot: '1',
            statistic: {}, //{ experts: 14, projects: 5, investors: 12 }
            showVideoPopup: false,
            showCartPopup: false,
            showVideoId: null,
            cartId: null,
            CartPopUpType: null,
            expertPage: 0,
            startupPage: 0,
            angelPage: 0,
            fundPage: 0,
            acceleratorPage: 0,
            startupVideoPage: 0,
            startupPreliminaryEvaluationPage: 0,
            showMoreExperts: true,
            showMoreStartups: true,
            showMoreStartupsVideo: true,
            showMoreInvestors: true,
            showMoreFunds: true,
            showMoreAccelerators: true,
            showMoreStartupsPreliminaryEvaluation: true,
            notificationsLog: [],
            blogList: [],
        };
        this.activeDot = this.activeDot.bind(this);
        this.StateClickDot = this.StateClickDot.bind(this);
        this.openCloseForm = this.openCloseForm.bind(this);

        console.log(this.props)
    }

    getParamVal(expertId, paramId, type, ach, line) {
        try {
            if (!line)
                return expertParams[expertId][paramId][type][ach];
            else
                return expertParams[expertId][paramId].line;
        } catch (e) {
            return 0;
        }
    }

    componentDidMount() {
        this.getNotification(9986400000)

        getListIndustries()
            .then((res) => {
                console.log(res.data)
                this.setState({listIndustries: res.data})
            })
            .catch(error => {
                //alert('listIndustries e', error);
                console.log(error);
                this.setState({loading: false})
            })

        if (this.state.savedEmail) {
            this.checkSubscribe(this.state.savedEmail);
        }

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }

        axios
            .get('/v2/default/statistic_total')
            .then(r => {
                this.setState({statistic: r.data});
            });

        axios
            .get('/v1/blog/last')
            .then(r => {
                this.setState({blogList: r.data});
            });

        let take = 3

        axios
            .get('/v2/projects?take=3&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listProjects: r.data.data});
                //
                // let multy = r.data.data.find((project) => project.id == 403)
                //
                // if(multy && multy.id) {
                //     let arr = []
                //     arr.push(multy)
                //     this.setState({listProjectsScore: arr});
                // }


                if (parseInt(r.data.totalCount) <= take) {
                    this.setState({showMoreStartups: false});
                }
            })

        axios
            .get('/v2/projects?take=3&pitch=true&youtube=true&isFinished=true&needCount=true')
            .then(r => {
                console.log(r.data);
                this.setState({listProjectsWithVideo: r.data.data});
                if (parseInt(r.data.totalCount) <= take) {
                    this.setState({showMoreStartupsVideo: false, onePageSV: true});
                }
            })

        axios
            .get('/v2/projects?take=3&expert_score=true&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listProjectsScore: r.data.data});
                // if(parseInt(r.data.totalCount) <= take){
                //     this.setState({showMoreProjectsScore: false});
                // }
            })

        axios
            .get('/v2/projects?take=3&preliminary_evaluation=true&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listProjectsPreliminaryEvaluation: r.data.data});
                // if(parseInt(r.data.totalCount) <= take){
                //     this.setState({showMoreProjectsScore: false});
                // }
                if (r.data.data.length <= 3) {
                    this.setState({showMoreStartupsPreliminaryEvaluation: false});
                }

            });

        axios
            .get('/v2/funds?take=3&type=simple,angels&isFinished=true&needCount=true')
            .then(r => {
                this.setState({listFunds: r.data.data});

                if (parseInt(r.data.totalCount) <= take) {
                    this.setState({showMoreFunds: false});
                }
            })

        axios
            .get('/v2/accelerator?limit=3')
            .then(r => {
                this.setState({listAccelerators: r.data.rows});

                if (parseInt(r.data.count) <= take) {
                    this.setState({showMoreAccelerators: false});
                }
            })

        axios
            .get('/v2/experts?take=3&role=expert&isFinished=true')
            .then(r => {
                this.setState({listExperts: r.data.experts});
            })

        axios
            .get(`/v2/investor?skip=0&take=${take}&isFinished=true&needCount=true`)
            .then(r => {
                this.setState({listInvestors: r.data.experts});
                if (parseInt(r.data.total) <= take) {
                    this.setState({showMoreInvestors: false});
                }
            });

        // this.animateMarketplace('block-body-header-marketplace')
        this.newAnimateImage()
        try {
            if (this.props.location.state && this.props.location.state.startup) {
                this.togglePopupCart(this.props.location.state.startup, 'startup', {}, 'New Startups');
                history.replace({...history.location, state: undefined});
            }
        } catch (e) {
            history.replace({...history.location, state: undefined});
        }

    }

    getNotification = (period) => {
        getNotification(period)
            .then(res => {
                function compare(a, b) {
                    if (moment(a.createdAt).unix() < moment(b.createdAt).unix()) {
                        return 1;
                    }
                    if (moment(a.createdAt).unix() > moment(b.createdAt).unix()) {
                        return -1;
                    }
                    return 0;
                }

                const notificationsLog = res.data.sort(compare);
                this.setState({notificationsLog: res.data})
            })
            .catch(error => {
                console.log(error)
            })


    }

    openCloseForm(key) {


        switch (key) {
            case '1': {
                generateEvent('start_registration', {role: 'startup', source: 'animation'});
                //history.push('/start_reg?type=3');
                history.push('/start_reg?type=8');
                break;
            }
            case '2': {
                generateEvent('start_registration', {role: 'investor', source: 'animation'});
                //history.push('/start_investor');
                history.push('/start_reg?type=8');
                break;
            }
            case '3': {
                generateEvent('start_registration', {role: 'expert', source: 'animation'});
                //history.push('/start_reg?type=2');
                history.push('/start_reg?type=8');
                break;
            }
        }
    }

    closeForm(key) {
        this.setState({openPopup: key})
    }

    togglePopup(id, e, type2) {
        if (e && e.preventDefault) e.preventDefault()
        this.setState({
            showVideoId: id,
            CartPopUpType2: type2,
            showVideoPopup: !this.state.showVideoPopup
        });
    }

    togglePopupCart(id, type, e, type2) {
        if (e && e.preventDefault) e.preventDefault()
        this.setState({
            cartId: id,
            CartPopUpType: type,
            CartPopUpType2: type2,
            showCartPopup: !this.state.showCartPopup
        });
    }

    goTo(url, e) {
        e.preventDefault()
        window.open(url, '_blank')
    }

    newAnimateImage() {
        let active = 1
        let timer = setInterval(() => {
            this.setState({activeDot: active.toString()})
            active++;
            if (active === 4){
                active = 1
            }
            // clearInterval(timer);
        }, 8000);
    }


    animateMarketplace(classParent) {
        let settings = {
            timeSlide: 10000
        }


        let dot1 = $('.block-item-dot-header-marketplace.dot-1');
        let dot2 = $('.block-item-dot-header-marketplace.dot-2');
        let dot3 = $('.block-item-dot-header-marketplace.dot-3');

        let activeDot = '1';

        let timer = setInterval(() => {
            if (this.state.openPopup === '0' || this.state.openPopup === 0) {
                dot1.click();
                activeDot = '2';
                this.setState({activeDot: '2'})
                clearInterval(timer);
            }
        }, 1000);

        let timeanim = setInterval(() => {
            if (this.state.openPopup === '0' || this.state.openPopup === 0) {
                switch (this.state.activeDot) {
                    case '1' :
                        dot1.click();
                        activeDot = '2';
                        this.setState({activeDot: '2'})
                        this.setState({currentDot: '1'})
                        break;
                    case '2' :
                        dot2.click();
                        activeDot = '3';
                        this.setState({activeDot: '3'})
                        this.setState({currentDot: '2'})
                        break;
                    case '3' :
                        dot3.click();
                        activeDot = '1';
                        this.setState({activeDot: '1'})
                        this.setState({currentDot: '3'})
                        break;
                }
            }
        }, settings.timeSlide + 800);

        //
        let timeGlobal = setInterval(() => {    //NODEL
            if (this.state.stateClickDots) {
                clearInterval(timeanim);
                this.setState({stateClickDots: false});
                timeanim = setInterval(() => {
                    if (this.state.openPopup === '0' || this.state.openPopup === 0) {
                        switch (this.state.activeDot) {
                            case '1' :
                                dot1.click();
                                activeDot = '2';
                                this.setState({activeDot: '2'})
                                break;
                            case '2' :
                                dot2.click();
                                activeDot = '3';
                                this.setState({activeDot: '3'})
                                break;
                            case '3' :
                                dot3.click();
                                activeDot = '1';
                                this.setState({activeDot: '1'})
                                break;
                        }
                    }
                }, settings.timeSlide + 800);
            }
        }, 500)
    }

    activeDot(e, v2) {
        this.setState({
            activeDot: e
        })

        if (v2)
            this.setState({currentDot: v2})
    }

    StateClickDot() {
        this.setState({
            stateClickDots: true
        })
    }

    removeDelDots(dot) {
        if (dot.hasClass('start') || dot.hasClass('active')) {
            dot.removeClass('start');
            dot.removeClass('active');
            dot.addClass('remove');
            let timer1 = setInterval(function () {
                dot.removeClass('remove');
                dot.addClass('delete');
                let timer2 = setInterval(function () {
                    dot.removeClass('delete');
                    clearInterval(timer2)
                }, 100);
                clearInterval(timer1)
            }, 600)
        }
    }

    dataAnim(dot) {
        let title = $('.block-info-header-marketplace p.title-header-marketplace');
        let description = $('.block-info-header-marketplace p.description-header-marketplace');
        let buttonColor = $('.block-info-header-marketplace .block-button-color-header-marketplace');
        let buttonColorText = $('.block-info-header-marketplace .block-button-color-header-marketplace.color p.title-button-color-header-marketplace');
        // let buttonWhite = $('.block-info-header-marketplace .block-button-color-header-marketplace.white');
        //let buttonWhiteText = $('.block-info-header-marketplace .block-button-color-header-marketplace.white p.title-button-color-header-marketplace');
        // let buttonWhiteSpan = $('.block-info-header-marketplace .block-button-color-header-marketplace.white p.title-button-color-header-marketplace span');
        title.css({opacity: '0'});
        description.css({opacity: '0'});
        buttonColorText.css({opacity: '0'});
        //buttonWhiteText.css({opacity: '0'});
        let timStat = setInterval(() => {
            if (dot === 1) {
                title.css({maxWidth: '480px'})
            }
            if (dot === 2) {
                title.css({maxWidth: '480px'})
            }
            if (dot === 3) {
                title.css({maxWidth: '590px'})
            }
            clearInterval(timStat)
        }, 200)

        let timer = setInterval(() => {
            if (this.state.openPopup === '0' || this.state.openPopup === 0) {
                if (dot === 1) {
                    title.css({maxWidth: '480px'})
                    title.html('A VC investment marketplace <br/> and a startup evaluation\n' +
                        'platform with an independent \n' +
                        'expert community.\n');
                    description.text('Get support from the expert community and present your hi-tech project to investors.');
                    buttonColor.removeClass('blue')
                    buttonColor.removeClass('green')
                    buttonColor.addClass('black')
                    buttonColorText.text('Join now!')
                }
                if (dot === 2) {
                    title.css({maxWidth: '590px'})
                    title.html('For investors:<br/> Get access to a pool of evaluated projects fitting your interests');
                    description.text('Study startups with our Due Diligence reports and track success of promising projects. ');
                    buttonColor.removeClass('green')
                    buttonColor.removeClass('black')
                    buttonColor.addClass('blue')
                    buttonColorText.text('Join now!')
                }
                if (dot === 3) {
                    title.css({maxWidth: '480px'})
                    title.html('For experts:<br/> Support, evaluate and boost hi-tech startups');
                    description.text('Get use of your unique knowledge to conduct independent startup Due Diligence.');
                    buttonColor.removeClass('black')
                    buttonColor.removeClass('blue')
                    buttonColor.addClass('green')
                    buttonColorText.text('Join now!')
                }
                title.css({opacity: '1'});
                description.css({opacity: '1'});
                buttonColorText.css({opacity: '1'});
                //buttonWhiteText.css({opacity: '1'});
            }
            clearInterval(timer)
        }, 400)


    }

    clickItemDotMarketplace(e) {
        let dot1 = $('.block-item-dot-header-marketplace.dot-1');
        let dot2 = $('.block-item-dot-header-marketplace.dot-2');
        let dot3 = $('.block-item-dot-header-marketplace.dot-3');


        if ($(e.currentTarget).hasClass('dot-1')) {
            this.removeDelDots(dot2);
            this.removeDelDots(dot3);
            dot1.removeClass('start');
            let timet = setInterval(() => {
                dot1.removeClass('remove');
                dot1.removeClass('delete');
                dot1.addClass('active');
                dot2.addClass('start');
                this.dataAnim(1);
                clearInterval(timet)
            }, 100)

        }
        if ($(e.currentTarget).hasClass('dot-2')) {
            this.removeDelDots(dot1);
            this.removeDelDots(dot3);
            dot2.removeClass('start');
            let timet = setInterval(() => {
                dot2.removeClass('remove');
                dot2.removeClass('delete');
                dot2.addClass('active');
                dot3.addClass('start');
                this.dataAnim(2);
                clearInterval(timet)
            }, 100)
        }
        if ($(e.currentTarget).hasClass('dot-3')) {
            this.removeDelDots(dot1);
            this.removeDelDots(dot2);
            dot3.removeClass('start');
            let timet = setInterval(() => {
                dot3.removeClass('remove');
                dot3.removeClass('delete');
                dot3.addClass('active');
                dot1.addClass('start');
                this.dataAnim(3);
                clearInterval(timet)
            }, 100)
        }
    }

    moreExpert = () => {
        let {expertPage} = this.state;
        expertPage++;
        const take = 3;
        const skip = expertPage * take;
        axios
            .get(`/v2/experts?take=${take}&isFinished=true&skip=${skip}&role=expert`)
            .then(r => {
                if (r.data.experts.length) {
                    this.setState({listExperts: [...this.state.listExperts, ...r.data.experts]});
                    this.setState({expertPage: expertPage});

                    if (parseInt(r.data.total) <= skip + take) {
                        this.setState({showMoreExperts: false});
                    }
                }

            })
    }


    closeExpert = () => {
        let expertPage = 0,
            showMoreExperts = true,
            listExperts = this.state.listExperts.slice(0, 3)

        this.setState({expertPage, showMoreExperts, listExperts}, () => {
            this.scrollTo('experts')
        })
    }

    moreStartups = () => {
        let {startupPage} = this.state;
        startupPage++;
        const take = 3;
        const skip = startupPage * take;
        axios
            .get(`/v2/projects?take=${take}&skip=${skip}&isFinished=true&needCount=true`)
            .then(r => {
                if (r.data.data.length) {
                    this.setState({listProjects: [...this.state.listProjects, ...r.data.data]});
                    this.setState({startupPage: startupPage});
                    if (parseInt(r.data.totalCount) <= skip + take) {
                        this.setState({showMoreStartups: false});
                    }
                }
            })
    }

    closeStartups = () => {
        let startupPage = 0,
            showMoreStartups = true,
            listProjects = this.state.listProjects.slice(0, 3)

        this.setState({startupPage, showMoreStartups, listProjects}, () => {
            this.scrollTo('startups')
        })

    }

    moreStartupsPreliminaryEvaluation = () => {
        let {startupPreliminaryEvaluationPage} = this.state;
        startupPreliminaryEvaluationPage++;
        const take = 3;
        const skip = startupPreliminaryEvaluationPage * take;
        axios
            .get(`/v2/projects?take=${take}&preliminary_evaluation=true&skip=${skip}&isFinished=true&needCount=true`)
            .then(r => {
                if (r.data.data.length) {
                    this.setState({listProjectsPreliminaryEvaluation: [...this.state.listProjectsPreliminaryEvaluation, ...r.data.data]});
                    this.setState({startupPreliminaryEvaluationPage: startupPreliminaryEvaluationPage});
                    if (parseInt(r.data.totalCount) <= skip + take) {
                        this.setState({showMoreStartupsPreliminaryEvaluation: false});
                    }
                }
            });

    }

    closeStartupsPreliminaryEvaluation = () => {
        let startupPreliminaryEvaluationPage = 0,
            showMoreStartupsPreliminaryEvaluation = true,
            listProjectsPreliminaryEvaluation = this.state.listProjectsPreliminaryEvaluation.slice(0, 3)

        this.setState({
            startupPreliminaryEvaluationPage,
            showMoreStartupsPreliminaryEvaluation,
            listProjectsPreliminaryEvaluation
        }, () => {
            this.scrollTo('evaluation')
        })

    }

    moreStartupsVideo = () => {
        let {startupVideoPage} = this.state;
        startupVideoPage++;
        const take = 3;
        const skip = startupVideoPage * take;
        axios
            .get(`/v2/projects?take=${take}&isFinished=true&skip=${skip}&needCount=true&pitch=true&youtube=true`)
            .then(r => {
                if (r.data.data.length) {
                    this.setState({listProjectsWithVideo: [...this.state.listProjectsWithVideo, ...r.data.data]});
                    this.setState({startupVideoPage: startupVideoPage});
                    if (parseInt(r.data.totalCount) <= skip + take) {
                        this.setState({showMoreStartupsVideo: false});
                    }
                } else {
                    this.setState({showMoreStartupsVideo: false});
                }
            })
    }

    closeStartupsVideo = () => {
        let startupVideoPage = 0,
            showMoreStartupsVideo = true,
            listProjectsWithVideo = this.state.listProjectsWithVideo.slice(0, 3)

        this.setState({startupVideoPage, showMoreStartupsVideo, listProjectsWithVideo}, () => {
            this.scrollTo('startupsVideo')
        })
    }

    moreInvestors = () => {
        let {angelPage} = this.state;
        angelPage++;
        const take = 3;
        const skip = angelPage * take;
        axios
            .get(`/v2/investor?take=${take}&skip=${skip}&isFinished=true&needCount=true`)
            .then(r => {
                if (r.data.experts.length) {
                    this.setState({listInvestors: [...this.state.listInvestors, ...r.data.experts]});
                    this.setState({angelPage: angelPage});
                    if (parseInt(r.data.total) <= skip + take) {
                        this.setState({showMoreInvestors: false});
                    }
                } else {
                    this.setState({showMoreInvestors: false});
                }
            })
    }

    closeInvestors = () => {
        let angelPage = 0,
            showMoreInvestors = true,
            listInvestors = this.state.listInvestors.slice(0, 3)

        this.setState({angelPage, showMoreInvestors, listInvestors}, () => {
            this.scrollTo('investors')
        })
    }

    moreFunds = () => {
        let {fundPage} = this.state;
        fundPage++;
        const take = 3;
        const skip = fundPage * take;
        axios
            .get(`/v2/funds?take=${take}&skip=${skip}&type=simple,angels&isFinished=true&needCount=true`)
            .then(r => {
                if (r.data.data.length) {
                    this.setState({listFunds: [...this.state.listFunds, ...r.data.data]});
                    this.setState({fundPage: fundPage});
                    if (parseInt(r.data.totalCount) <= skip + take) {
                        this.setState({showMoreFunds: false});
                    }
                } else {
                    this.setState({showMoreFunds: false});
                }
            })
    }

    closeFunds = () => {
        let fundPage = 0,
            showMoreFunds = true,
            listFunds = this.state.listFunds.slice(0, 3)

        this.setState({fundPage, showMoreFunds, listFunds}, () => {
            this.scrollTo('ventureFunds')
        })
    }

    moreAccelerator = () => {
        let {acceleratorPage} = this.state;
        acceleratorPage++;
        const take = 3;
        const skip = acceleratorPage * take;
        axios
            .get(`/v2/accelerator?limit=${take}&offset=${skip}`)
            .then(r => {
                if (r.data.rows.length) {
                    this.setState({listAccelerators: [...this.state.listAccelerators, ...r.data.rows]});
                    this.setState({acceleratorPage: acceleratorPage});
                    if (parseInt(r.data.count) <= skip + take) {
                        this.setState({showMoreAccelerators: false});
                    }
                } else {
                    this.setState({showMoreAccelerators: false});
                }
            })
    }

    closeAccelerator = () => {
        let acceleratorPage = 0,
            showMoreAccelerators = true,
            listAccelerators = this.state.listAccelerators.slice(0, 3)

        this.setState({acceleratorPage, showMoreAccelerators, listAccelerators}, () => {
            this.scrollTo('accelerators')
        })
    }

    handleCloseModal = () => {
        this.setState({
            showMProjectCreate: false,
            showLoginForm: false,
            showModalFundCreate: false,
            showModalTokenSale: false,
            forProject: false,
            forExpert: false
        })
    }

    handleOpenModalFundCreate = (fund) => {
        this.setState({joinFund: fund, showModalFundCreate: true, showLoginForm: false})
    }
    handleOpenModalFundCreate2 = () => {
        this.setState({forProject: true, showModalFundCreate: true, showLoginForm: false})
    }
    handleOpenModalFundCreate3 = () => {
        this.setState({forExpert: true, showModalFundCreate: true, showLoginForm: false})
    }

    handleOpenModalProjectCreate = () => {
        this.setState({showMProjectCreate: true})
    }

    handleOpenModalTokenSale = () => {
        this.setState({showModalTokenSale: true})
    }

    handleCloseModalFundCreate = () => {
        this.setState({
            showModalFundCreate: false,
            joinFund: null,
            forProject: false,
            forExpert: false,
            showLoginForm: false
        })
    }

    handleModalTEamWelcome = () => {
        this.setState({showModalTEamWelcome: !this.state.showModalTEamWelcome});
    }

    checkLogIn = (type) => {
        if (this.props.user && this.props.user.id) {
            if (type == 'fund') {
                this.handleOpenModalFundCreate()
            } else if (type == 'startup') {
                this.handleOpenModalFundCreate2()
            } else if ('expert') {
                this.handleOpenModalFundCreate3()
            }

        } else {
            this.setState({showLoginForm: true});
        }
    }

    resetItem = () => {
        this.setState({showLoginForm: false});
    }

    goToLanding = () => {
        let landingType = this.state.landingType,
            path;
        switch (landingType) {
            case 'Fund':
                path = '/fund_info'
                break
            case 'Expert':
                path = '/expert_info'
                break
            case 'Startup':
                path = '/startup_info'
                break
        }
        // window.open(path, '_blank')
        window.location.href = path
    }

    setCurrentEmail = (e) => {
        const currentEmail = e.target.value.toLowerCase();
        this.setState({'currentEmail': currentEmail});
        this.validateEmail(currentEmail)
    }

    validateEmail = (email) => {
        const regexp = /\S+@\S+\.\S+/;
        if (regexp.test(email)) {
            this.setState({error: ''})
            return true;
        } else {
            this.setState({error: 'Not valid email'})
            return false;
        }
    }

    checkSubscribe = (email) => {
        axios
            .post('/v1/users/subscribe/check', {email: email})
            .then(res => {
                this.setState({isSubscribe: res.data.isSubscribe})
            })
    }

    subscribe = (type) => {
        const email = this.state.currentEmail;

        if (this.validateEmail(email)) {
            axios
                .post(`/v1/users/subscribe/${type}`, {email: email})
                .then(res => {
                    if (res.data.status === 400) {
                        if (type === 'metrics') {
                            localStorage.setItem("savedEmail2", email);
                            this.setState({isSubscribe2: true, savedEmail2: email, currentEmail: ''});
                        } else {
                            localStorage.setItem("savedEmail", email);
                            this.setState({isSubscribe: true, savedEmail: email, currentEmail: ''});
                        }
                    } else {
                        if (type === 'metrics') {
                            localStorage.setItem("savedEmail2", email);
                            this.setState({isSubscribe2: true, savedEmail2: email, currentEmail: ''});
                        } else {
                            localStorage.setItem("savedEmail", email);
                            this.setState({isSubscribe: true, savedEmail: email, currentEmail: ''});
                        }
                    }
                })
        }
    }

    scrollTo = (id) => {
        var element = document.getElementById(id);
        element.scrollIntoView(false);
    }


    render() {
        let user = this.props.user || {};
        var settings = {
            dots: false,
            paddingLeft: 20,
            infinite: true,
            speed: 500,
            variableWidth: true,
            width: 1155,
            nextArrow: <SampleNextArrow className="test"/>,
            prevArrow: <SamplePrevArrow/>
        };
        const {statistic, listIndustries, showMoreExperts, showMoreStartups, showMoreStartupsVideo} = this.state;

        if (!listIndustries) {
            listIndustries.push([]);
        }

        const fundType = {
            'simple': 'Fund',
            'angels': 'Angel Network'
        }

        const fundSpType = {
            'simple': 'fund',
            'angels': 'angel'
        }

        let setting = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            width: 154,
            swipe: false,
            autoplay: true,
            autoplaySpeed: 3000,
        }

        const mainBlog = this.state.blogList[0] || {};
        const blogList = this.state.blogList.slice(1, 4) || [];
// console.log(mainBlog)
        const listFunds = this.state.listFunds && this.state.listFunds.map((item, key) => {
            // const _avatar = item.logoFileId ? `${packageJSON.proxy}/file/${item.logoFileId}/view` : avatar;

            const _avatar = item.logoFileId ?
                <div className="avatar"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('avatar', item.title);

            return (
                <a key={key} href={`/fund/${item.id}`}>
                    <div className='all-card-block'
                         onClick={(e) => this.togglePopupCart(item.id, (item.fundType === 'simple' ? 'fund' : 'angel'), e, 'Venture Funds and Angel Networks')}>
                        <div className='all-card-top'>

                            {/*<div className='avatar' style={{backgroundImage: `url(${_avatar})`}}/>*/}
                            {_avatar}

                            <div className='info-conteiner'>
                                <div className='title'>{fundType[item.fundType]}</div>
                                <div className='name'>{item.title}</div>
                                {item.proofSent && <span className="check">
                                <i className="fal fa-check"/>
                                </span>}
                            </div>
                        </div>
                        <div className='all-card-content'>
                            <div className='description subtitle'>{item.shortDescription.descriptionFormat()}</div>
                            <div className='location subtitle'>{item.location}</div>
                            <div className='industries title'>Industries</div>

                            {listIndustries && item.industry &&
                            <div className="industries-list">
                                {item.industry.slice(0, 4).map(id => {
                                    return listIndustries.find((a) => a.id === id) ?
                                        <span>{listIndustries.find((a) => a.id === id).title}</span> : ''
                                })}
                                {item.industry.length > 4 && <span> {item.industry.length} more… </span>}
                            </div>
                            }
                        </div>
                        <div className='all-card-bottom'>

                            {item.fundType === 'simple' && item.fundSize && <div className='fund-size'>
                                <div className='title'>Fund size</div>
                                {item.fundSize &&
                                <div className='subtitle'>${parseInt(item.fundSize.size_of_fund).toMoneyFormat2()}</div>
                                }
                            </div>
                            }

                            {item.fundType === 'angels' && item.fundSize && <div className='fund-size'>
                                <div className='title'>Members</div>
                                <div className='subtitle'>{item.currentTeamCount}</div>
                            </div>
                            }

                            {item.fundSize &&
                            <div className='investment-size'>
                                <div className='title'>Investment size</div>
                                <div
                                    className='subtitle'>${item.fundSize.investmentSize.min.toMoneyFormat2()}-{item.fundSize.investmentSize.max.toMoneyFormat2()}</div>
                            </div>
                            }
                        </div>
                    </div>
                </a>
            )
        })

        const listInvestors = this.state.listInvestors.map((item, key) => {
            // const _avatar = item.role && item.role.logoFileId ? `${packageJSON.proxy}/file/${item.role.logoFileId}/view` : avatar;

            const _avatar = item.role && item.role.logoFileId ?
                <div className="avatar"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.role.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('avatar', item.role.firstName);

            return (
                <a key={key} href={`/investor/${item.id}`}>
                    <div className='all-card-block'
                         onClick={(e) => this.togglePopupCart(item.id, 'investor', e, 'Angel Investors')}>
                        <div className='all-card-top'>
                            {_avatar}
                            <div className='info-conteiner'>
                                <div className='title'>{item.expRole}</div>
                                <div className='name'>{item.role.firstName} {item.role.lastName}</div>
                                {/*<span className="check">*/}
                                {/*<i className="fal fa-check"/>*/}
                                {/*</span>*/}
                            </div>
                        </div>
                        <div className='all-card-content'>
                            <div className='description subtitle'>{item.professionalDescription}</div>
                            <div className='location subtitle'>{item.role.country}</div>
                            <div className='industries title'>Industries</div>
                            {listIndustries && item.profile && item.profile.industry && !!item.profile.industry.length &&
                            <div className="industries-list">
                                {item.profile.industry.slice(0, 4).map(id => {
                                    return listIndustries.find((a) => a.id === id) ?
                                        <span>{listIndustries.find((a) => a.id === id).title}</span> : ''
                                })}
                                {item.profile.industry.length > 4 &&
                                <span> {item.profile.industry.length} more… </span>}
                            </div>
                            }

                        </div>
                        <div className='all-card-bottom'>

                            <div className='fund-size'>
                                <div className='title'>Investments</div>
                                <div className='subtitle'>{item.profile.investmentsCount || 0}</div>
                            </div>

                            {item.profile && item.profile.investmentSize &&
                            <div className='investment-size'>
                                <div className='title'>Investment size</div>
                                <div
                                    className='subtitle'>${item.profile.investmentSize.min.toMoneyFormat2()}-{item.profile.investmentSize.max.toMoneyFormat2()}</div>
                            </div>
                            }
                        </div>
                    </div>
                </a>
            )
        })

        const listProjects = this.state.listProjects.map((item, key) => {

            const _bg = item.backgroundImageId ? `${packageJSON.proxy}/file/${item.backgroundImageId}/view?w=300` : avatar;
            const _avatar = item.logoFileId ?
                <div className="img-cart-new-startup-marketplace"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view?w=80`})`}}/>
                :
                createAvatar('img-cart-new-startup-marketplace', item.title);

            return (
                <a key={key} href={`/startup/${item.id}`}>
                    <div className="block-cart-new-startup-marketplace"
                         onClick={(e) => this.togglePopupCart(item.id, 'startup', e, 'New Startups')}>
                        <div className="block-top-cart-new-startup-marketplace">
                            <div className="block-bg-top-cart-new-startup-marketplace">
                                <div className="bg-top-cart-new-startup-marketplace"
                                     style={{backgroundImage: `url(${_bg})`}}/>


                                {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
                                    <i className="fas fa-play"/>
                                </div>}
                            </div>
                            <div className="block-ing-cart-new-startup-marketplace">
                                {_avatar}
                            </div>
                        </div>
                        <div className="block-body-cart-new-startup-marketplace">
                            <p className="title-body-cart-new-startup-marketplace" title={item.title}>
                                {item.title}
                            </p>
                            <p className="description-body-cart-new-startup-marketplace">
                                {item.shortDescription.descriptionFormat()}
                            </p>
                            <div className="block-list-industries-body-cart-new-startup-marketplace">
                                <p className="title-industries-body-cart-new-startup-marketplace">
                                    Industries
                                </p>

                                {listIndustries && item.industry.slice(0, 4).map(id => {
                                    return <div key={id}
                                                className="block-item-industries-body-cart-new-startup-marketplace">
                                        {listIndustries.find((a) => a.id === id) &&
                                        <p className="title-item-industries-body-cart-new-startup-marketplace">
                                            {listIndustries.find((a) => a.id === id).title}
                                        </p>
                                        }
                                    </div>
                                })}
                                {item.industry.length > 4 &&
                                <div className="block-item-industries-body-cart-new-startup-marketplace">
                                    <p className="title-item-industries-body-cart-new-startup-marketplace">
                                        {item.industry.length} more…
                                    </p>
                                </div>
                                }
                            </div>
                            <div className="block-list-button-body-cart-new-startup-marketplace">
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="fas fa-heart"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.likes}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="far fa-clock"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace">
                                    <i className="far fa-share-alt"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.shared && item.shared !== '' ? item.shared : '0'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            )
        });

        const listProjectsScore = this.state.listProjectsScore.map((item, key) => {

            const _bg = item.backgroundImageId ? `${packageJSON.proxy}/file/${item.backgroundImageId}/view?w=200` : avatar;
            const _avatar = item.logoFileId ?
                <div className="img-cart-new-startup-marketplace"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('img-cart-new-startup-marketplace', item.title);

            return (
                <a key={key} href={`/startup/${item.id}`}>
                    <div className="block-cart-new-startup-marketplace evaluated-card"
                         onClick={(e) => this.goTo(`/startup/${item.id}`, e)}>
                        <div className='block-startup-evaluation-view'>
                            <div className="row-0 review-score text-center">
                                <div className="new-col expert-main-score font-weight-500">6.3</div>
                                <div className="new-col expert-main-score-ten" style={{marginTop: '24px'}}>/10</div>
                                <div className='new-col review-score-text-block'>
                                    <div
                                        className='row-0 expert-review-score-text text-left color-rgba-88 font-weight-500'
                                        style={{fontSize: '16px'}}>Due Diligence
                                    </div>
                                    <div className='row-0 expert-review-score-views text-left'
                                         style={{marginTop: '4px'}}>Defined round: <span
                                        className='color-rgba-88'>{item.round}</span></div>
                                </div>
                            </div>

                        </div>
                        <div className="block-top-cart-new-startup-marketplace">
                            <div className="block-bg-top-cart-new-startup-marketplace">
                                <div className="bg-top-cart-new-startup-marketplace"
                                     style={{backgroundImage: `url(${_bg})`}}/>


                                {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
                                    <i className="fas fa-play"/>
                                </div>}
                            </div>
                            <div className="block-ing-cart-new-startup-marketplace">
                                {_avatar}
                            </div>
                        </div>
                        <div className="block-body-cart-new-startup-marketplace">
                            <p className="title-body-cart-new-startup-marketplace" title={item.title}>
                                {item.title}
                            </p>
                            <p className="description-body-cart-new-startup-marketplace">
                                {item.shortDescription.descriptionFormat()}
                            </p>
                            <div className="block-list-industries-body-cart-new-startup-marketplace">
                                <p className="title-industries-body-cart-new-startup-marketplace">
                                    Industries
                                </p>

                                {listIndustries && item.industry.slice(0, 4).map(id => {
                                    return <div key={id}
                                                className="block-item-industries-body-cart-new-startup-marketplace">
                                        {listIndustries.find((a) => a.id === id) &&
                                        <p className="title-item-industries-body-cart-new-startup-marketplace">
                                            {listIndustries.find((a) => a.id === id).title}
                                        </p>
                                        }
                                    </div>
                                })}
                                {item.industry.length > 4 &&
                                <div className="block-item-industries-body-cart-new-startup-marketplace">
                                    <p className="title-item-industries-body-cart-new-startup-marketplace">
                                        {item.industry.length} more…
                                    </p>
                                </div>
                                }
                            </div>
                            <div className="block-list-button-body-cart-new-startup-marketplace">
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="fas fa-heart"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.likes}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="far fa-clock"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace">
                                    <i className="far fa-share-alt"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.shared}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block-bottom-cart-startup-marketplace">
                            <p className="description-bottom-cart-startup-marketplace">
                                {item.ddFile ? item.ddFile.views : 0} views
                            </p>
                        </div>
                    </div>
                </a>
            )
        });

        const listProjectsPreliminaryEvaluation = this.state.listProjectsPreliminaryEvaluation.map((item, key) => {

            const _bg = item.backgroundImageId ? `${packageJSON.proxy}/file/${item.backgroundImageId}/view?w=200` : avatar;
            const _avatar = item.logoFileId ?
                <div className="img-cart-new-startup-marketplace"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('img-cart-new-startup-marketplace', item.title);

            return (
                <a key={key} href={`/startup/${item.id}`}>
                    <div className="block-cart-new-startup-marketplace evaluated-card"
                         onClick={(e) => this.goTo(`/startup/${item.id}`, e)}>
                        <div className='block-startup-evaluation-view'>
                            <div className="row-0 review-score text-center">
                                <div
                                    className="new-col expert-main-score font-weight-500">{item.preliminary_evaluation}</div>
                                <div className="new-col expert-main-score-ten" style={{marginTop: '24px'}}>/10</div>
                                <div className='new-col review-score-text-block'>
                                    <div className='row-0 expert-review-score-text text-left color-rgba-88'>Expert
                                        overall score
                                    </div>
                                    <div className='row-0 expert-review-score-views text-left'
                                         style={{marginTop: '4px'}}>Expert-defined round: <span
                                        className='color-rgba-88'>{item.id === 441 ? 'Seed' : item.id === 510 ? 'Pre-Seed' : item.round}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="block-top-cart-new-startup-marketplace">
                            <div className="block-bg-top-cart-new-startup-marketplace">
                                <div className="bg-top-cart-new-startup-marketplace"
                                     style={{backgroundImage: `url(${_bg})`}}/>


                                {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
                                    <i className="fas fa-play"/>
                                </div>}
                            </div>
                            <div className="block-ing-cart-new-startup-marketplace">
                                {_avatar}
                            </div>
                        </div>
                        <div className="block-body-cart-new-startup-marketplace">
                            <p className="title-body-cart-new-startup-marketplace" title={item.title}>
                                {item.title}
                            </p>
                            <p className="description-body-cart-new-startup-marketplace">
                                {item.shortDescription.descriptionFormat()}
                            </p>
                            <div className="block-list-industries-body-cart-new-startup-marketplace">
                                <p className="title-industries-body-cart-new-startup-marketplace">
                                    Industries
                                </p>

                                {listIndustries && item.industry.slice(0, 4).map(id => {
                                    return <div key={id}
                                                className="block-item-industries-body-cart-new-startup-marketplace">
                                        {listIndustries.find((a) => a.id === id) &&
                                        <p className="title-item-industries-body-cart-new-startup-marketplace">
                                            {listIndustries.find((a) => a.id === id).title}
                                        </p>
                                        }
                                    </div>
                                })}
                                {item.industry.length > 4 &&
                                <div className="block-item-industries-body-cart-new-startup-marketplace">
                                    <p className="title-item-industries-body-cart-new-startup-marketplace">
                                        {item.industry.length} more…
                                    </p>
                                </div>
                                }
                            </div>
                            <div className="block-list-button-body-cart-new-startup-marketplace">
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="fas fa-heart"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.likes}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace m">
                                    <i className="far fa-clock"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
                                    </p>
                                </div>
                                <div className="block-item-button-body-cart-new-startup-marketplace">
                                    <i className="far fa-share-alt"/>
                                    <p className="title-item-button-body-cart-new-startup-marketplace">
                                        {item.shared}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="block-bottom-cart-startup-marketplace">
                            <p className="description-bottom-cart-startup-marketplace">
                                {item.views || 0} views
                            </p>
                        </div>
                    </div>
                </a>
            )
        });

        const listProjectsWithVideo = this.state.listProjectsWithVideo.map((item, key) => {

            function getYoutubePreview(id) {
                return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
            }

            function getQueryVariable(url, variable) {
                const query = url.replace('?', '&');
                const vars = query.split('&');

                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split('=');
                    if (decodeURIComponent(pair[0]) == variable) {
                        return decodeURIComponent(pair[1]);
                    }
                }
            }

            let youtubePreview = '';

            if (item.pitch) {
                const youtubeId = youtubeParser(item.pitch.youtubeLink);
                youtubePreview = getYoutubePreview(youtubeId);
            }

            const _bg = youtubePreview;

            const _avatar = item.logoFileId ?
                <div className="img-top-cart-new-video-marketplace"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('img-top-cart-new-video-marketplace', item.title);

            return (
                <a href={`/startup/${item.id}`} key={key}>
                    <div className="block-cart-new-video-marketplace"
                         onClick={(e) => this.togglePopup(item.id, e, 'Video pitches')}>
                        <div className="block-top-cart-new-video-marketplace">
                            <div className="block-bg-top-cart-new-video-marketplace">
                                <div className="bg-top-cart-new-video-marketplace"
                                     style={{backgroundImage: `url(${_bg})`}}/>
                                <div className="block-button-play-top-cart-new-video-marketplace">
                                    <i className="fas fa-play"/>
                                </div>
                                <div className="block-button-path-top-cart-new-video-marketplace">
                                    <p className="title-button-path-top-cart-new-video-marketplace">
                                        Video Pitch
                                    </p>
                                </div>
                            </div>
                            <div className="block-img-top-cart-new-video-marketplace">
                                {_avatar}
                            </div>
                        </div>
                        <div className="block-body-cart-new-video-marketplace">
                            <p className="title-body-cart-new-video-marketplace">
                                {item.title}
                            </p>
                        </div>
                    </div>
                </a>
            )
        })

        const listExperts = this.state.listExperts.map((item, key) => {
            const {user} = item;

            const _avatar = user.logoFileId ?
                <div className="avatar"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${user.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('avatar', user.firstName);

            if (!item.parameters) item.parameters = []
            return (
                <a key={key} href={`/expert/${item.id}`}>
                    <div className='all-card-block expert-card-block'
                         onClick={(e) => this.togglePopupCart(item.id, 'expert', e, 'Experts')}>
                        <div className='all-card-top expert-card-top'>
                            {_avatar}
                            <div className='info-conteiner'>
                                <div className='title'>Expert</div>
                                <div className='name'>{user.firstName} {user.lastName}</div>
                                {/*<span className="check">*/}
                                {/*<i className="fal fa-check"/>*/}
                                {/*</span>*/}
                            </div>
                        </div>
                        <div className='all-card-content expert-card-content'>
                            <div
                                className='description subtitle'>{item.professionalDescription && item.professionalDescription.length > 120 ? `${item.professionalDescription.slice(0, 120)}...` : item.professionalDescription || ''}</div>
                            <div className='location subtitle'>{user.country}</div>
                            {/*<div className="industries-list expert-industries-list">*/}

                            {/*{item.skills.slice(0,6).map(skill => {*/}
                            {/*return <span>{skill.title}</span>*/}
                            {/*})}*/}
                            {/*{item.skills.length > 6 && <span>{item.skills.length} more…</span>}*/}
                            {/*</div>*/}
                        </div>
                        <div className='all-card-bottom expert-card-bottom'>


                            <div className='title'>Evaluation parameters</div>

                            {item.parameters.map((param, key) => {
                                if (key <= 1) {
                                    return (
                                        <React.Fragment>
                                            <div className='evaluation-parameters-title' title={key}
                                                 key={key}>{param.RoleParameter.title}</div>
                                            <div className='evaluation-parameters-line'>
                                                <div className='line'
                                                     style={{width: `${this.getParamVal(item.id, param.RoleParameter.id, '', '', true)}%`}}/>
                                            </div>
                                        </React.Fragment>
                                    )
                                }

                            })}
                        </div>
                        <div className="all-card-bottom border-top shell-margin-top-20 bottom-0-cart-expert">
                            <div className="fund-size">
                                <div className="title">Projects evaluated</div>
                                <div className="subtitle">0</div>
                            </div>
                            <div className="investment-size">
                                <div className="title">Experts evaluated</div>
                                <div className="subtitle">0</div>
                            </div>
                        </div>
                    </div>
                </a>
            )
        })

        const listAccelerators = this.state.listAccelerators && this.state.listAccelerators.map((item, key) => {
            // const _avatar = item.logoFileId ? `${packageJSON.proxy}/file/${item.logoFileId}/view` : avatar;

            const _avatar = item.logoFileId ?
                <div className="avatar"
                     style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
                :
                createAvatar('avatar', item.title);

            return (
                <a key={key} href={`/accelerator/${item.id}`}>
                    <div className='all-card-block'
                         onClick={(e) => this.togglePopupCart(item.id, 'accelerator', e, 'Accelerators')}>
                        <div className='all-card-top'>

                            {/*<div className='avatar' style={{backgroundImage: `url(${_avatar})`}}/>*/}
                            {_avatar}

                            <div className='info-conteiner'>
                                <div className='accelerator-name-title font-weight-300'>Accelerator</div>
                                <div className='name'>{item.title}</div>

                                {item.isProof &&
                                <span className="check">
                                        <i className="fal fa-check"/>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className='all-card-content'>
                            <div className='description subtitle'
                                 style={{fontSize: '16px'}}>{item.shortDescription.descriptionFormat()}</div>
                            <div className='location subtitle'>{item.location}</div>
                            <div className='accelerator-name-title' style={{margin: '16px 0 8px 0'}}>Type</div>
                            <span className="accelerator-type-block">{item.type}</span>
                        </div>
                        <div className='all-card-bottom'>
                            <div className='investment-size'>
                                <div className='title'>Total startups</div>
                                <div className='subtitle'>{item.startupsTotal || '--'}</div>
                            </div>
                            <div className='fund-size'>
                                <div className='title'>Funding up to</div>
                                <div className='subtitle'>${item.fundingUpTo.toMoneyFormat2()}</div>
                            </div>
                        </div>
                    </div>
                </a>
            )
        })

        const listNotifications = this.state.notificationsLog.map((item, key) => {
            let type = item.type,
                text = '',
                text2 = ''


            if (type == 'startup') {
                text = 'New startup created its profile!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'fund') {
                text = 'New VC Fund created its profile!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'accelerator') {
                text = 'New accelerator created its profile!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'expert') {
                text = 'New expert joined us!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'investor') {
                text = 'New investor joined us!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'angel') {
                text = 'New Angel Network created its profile!'
                text2 = `Welcome, ${item.title}`
            }
            if (type == 'user') {
                text = 'New user has just registered on the platform!'
                text2 = `Welcome, ${item.title}`
            }

            return (
                <div key={key} className="block-item-log-marketplace"
                     style={{borderLeft: '1px solid rgba(0,0,0,0.15)'}}>
                    <p className="title-item-log-marketplace">
                        {text}
                    </p>
                    <p className="description-item-log-marketplace">
                        {text2}
                    </p>
                    <p className='date-item-log-marketplace'>{item.finishedAt}</p>
                </div>
            )
        })

        return (
            <div>
                <MetaTags>
                    <title>Rocket DAO - uniting startups, investors and experts</title>
                    <meta property="og:title" content="Rocket DAO - uniting startups, investors and experts"/>
                    <meta name="description"
                          content="Rocket DAO connects digital startups and investors under the supervision of an independent expert community."/>
                    <meta property="og:description"
                          content="Rocket DAO connects digital startups and investors under the supervision of an independent expert community."/>
                </MetaTags>

                <Header user={user} bgcColor={'#0028DC'} showLoginForm={this.state.showLoginForm}
                        resetItem={this.resetItem}/>
                <div className="block-team-2-0">


                    <div className="back-white">
                        <div className="shell-header-start-page-dao">
                            <div className="block-header-marketplace">
                                <div className="block-body-header-marketplace">
                                    {/*<div className="block-dots-header-marketplace">*/}
                                    {/*    <div className="shell-dots-state" onClick={() => this.StateClickDot()}>*/}
                                    {/*        <div className="block-item-dot-header-marketplace dot-1"*/}
                                    {/*             onClick={(e) => {*/}
                                    {/*                 this.clickItemDotMarketplace(e);*/}
                                    {/*                 this.activeDot('2', '1')*/}
                                    {/*             }}/>*/}
                                    {/*        <div className="block-item-dot-header-marketplace dot-2"*/}
                                    {/*             onClick={(e) => {*/}
                                    {/*                 this.clickItemDotMarketplace(e);*/}
                                    {/*                 this.activeDot('3', '2')*/}
                                    {/*             }}/>*/}
                                    {/*        <div className="block-item-dot-header-marketplace dot-3"*/}
                                    {/*             onClick={(e) => {*/}
                                    {/*                 this.clickItemDotMarketplace(e);*/}
                                    {/*                 this.activeDot('1', '3')*/}
                                    {/*             }}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="block-info-header-marketplace">
                                        <p className="title-header-marketplace title-header-adaptive"
                                           style={{margin: '0 0 50px 0'}}>
                                            A VC investment marketplace <br/>
                                            and a startup evaluation <br/>
                                            platform with an independent <br/>
                                            expert community.
                                        </p>
                                        <p className="description-header-marketplace" style={{display: 'none'}}>
                                            Get support from the expert community and present your hi-tech project to
                                            investors.
                                        </p>
                                        <div
                                            className={`block-button-color-header-marketplace color black ${this.state.isSubscribe ? 'disabled disable disabled-button' : ''}`}
                                            onClick={() => this.openCloseForm(this.state.currentDot)}
                                            style={{
                                                margin: '0 30px 20px 0',
                                                display: 'inline-block',
                                                verticalAlign: 'top'
                                            }}
                                        >


                                            <p className="title-button-color-header-marketplace">
                                                Join now!
                                            </p>
                                        </div>
                                        <div className="block-button-white-border" onClick={() => window.open('/about', "_blank")}>
                                            <p className="title-button-white-border">
                                                How it works
                                            </p>
                                        </div>
                                        {/*<div className="block-button-color-header-marketplace white">*/}
                                        {/*<p className="title-button-color-header-marketplace">*/}
                                        {/*Startups*/}
                                        {/*<span className="right-title-button">56</span>*/}
                                        {/*</p>*/}
                                        {/*</div>*/}
                                    </div>
                                    <img
                                        src={this.state.activeDot === '1' ? LustraExpert : this.state.activeDot === '2' ? LustraStartup : LustraInvestor}
                                        alt="" className="illustrate-header-marketplace"/>
                                </div>

                                {/*{!this.state.isSubscribe ? <div className="block-form-email-marketplace">*/}
                                {/*<p className="title-form-email-marketplace">*/}
                                {/*Be the first to know the public release day and receive a lifetime discount on all*/}
                                {/*subscription*/}
                                {/*types.*/}
                                {/*<br/><br/>*/}
                                {/*</p>*/}
                                {/*<div className="block-input-button-form-email-marketplace">*/}
                                {/*<Input*/}
                                {/*type={'email'}*/}
                                {/*name={'email'}*/}
                                {/*value={this.state.currentEmail}*/}
                                {/*placeholder={'Your email'}*/}
                                {/*onChange={this.setCurrentEmail}*/}
                                {/*error={this.state.error}*/}
                                {/*/>*/}
                                {/*<ButtonBlue*/}
                                {/*TitleButton={'Request early access'}*/}
                                {/*EventOnClick={() => this.subscribe('other')}*/}
                                {/*paddingButton={'50'}*/}
                                {/*disabled={false}*/}
                                {/*loader={false}*/}
                                {/*/>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*:*/}
                                {/*<div className="block-form-email-marketplace">*/}
                                {/*<p className="title-form-email-marketplace" style={{maxWidth: '1024px'}}>*/}
                                {/*You have successfully subscribed to Rocket DAO Launch newsletter!*/}
                                {/*</p>*/}
                                {/*<p className="description-form-marketplace">*/}
                                {/*Note that your lifetime discount will be available via*/}
                                {/*<br/><b>{this.state.savedEmail}</b> only.*/}
                                {/*</p>*/}
                                {/*</div>*/}
                                {/*}*/}

                                <div className="block-popup-form-marketplace"
                                     style={this.state.openPopup !== '0' ? {display: 'block'} : {display: 'none'}}>

                                    {this.state.isSubscribe ?
                                        <div className="block-form-marketplace">
                                            <p className="title-form-marketplace">
                                                You have successfully subscribed to Rocket DAO Launch newsletter!
                                            </p>
                                            <p className="description-form-marketplace">
                                                Note that your lifetime discount will be available via
                                                <br/><b>{this.state.savedEmail}</b> only.
                                            </p>

                                            <div className="block-close-popup-form-marketplace"
                                                 onClick={() => this.closeForm('0')}>
                                                <i className="fal fa-times"/>
                                            </div>
                                        </div>
                                        :
                                        <div className="block-form-marketplace">
                                            <p className="title-form-marketplace">
                                                Dear friend!
                                            </p>
                                            {this.state.currentDot === '1' &&
                                            <p className="description-form-marketplace">
                                                We will launch the platform within a month. Please, leave your email
                                                below
                                                to be in-time notified about the release and get the priority right to
                                                register your startup on Rocket DAO.
                                            </p>
                                            }
                                            {this.state.currentDot === '2' &&
                                            <p className="description-form-marketplace">
                                                We will launch the platform within a month. Please, leave your email
                                                below
                                                to be in-time notified about the release and get the priority right to
                                                create your investor profile on Rocket DAO.
                                            </p>
                                            }
                                            {this.state.currentDot === '3' &&
                                            <p className="description-form-marketplace">
                                                We will launch the platform within a month. Please, leave your email
                                                below
                                                to be in-time notified about the release and get the priority right to
                                                join
                                                Rocket DAO expert community.
                                            </p>
                                            }
                                            <div className="block-input-button-form-email-marketplace">
                                                <Input
                                                    type={'email'}
                                                    name={'email'}
                                                    placeholder={'Your email'}
                                                    value={this.state.currentEmail}
                                                    onChange={this.setCurrentEmail}
                                                    error={this.state.error}
                                                />
                                                <ButtonBlue
                                                    TitleButton={'Request early access'}
                                                    EventOnClick={() => this.subscribe(subscribeTypes[this.state.currentDot])}
                                                    paddingButton={'50'}
                                                    disabled={this.state.isSubscribe}
                                                    loader={false}
                                                />
                                            </div>
                                            <div className="block-close-popup-form-marketplace"
                                                 onClick={() => this.openCloseForm('0')}>
                                                <i className="fal fa-times"/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="new-mp-rocket">What’s new on Rocket DAO</div>
                    <div className="block-list-log-marketplace">
                        <Slider{...setting}>
                            {listNotifications}
                        </Slider>
                        <div className="shell-margin-top-30"></div>
                    </div>

                    <div className="block-header-marketplace">
                        <div className="block-gradient-new"/>
                        <div className="block-top-title-button-marketplace" style={{position: 'relative', zIndex: '2'}}>
                            <p className="title-top-marketplace">
                                Explore, evaluate, invest.
                            </p>
                            <div className="block-list-button-top-marketplace">
                                <a href="/#startups">
                                    <div className="block-item-button">
                                        <p className="title-item-button">
                                            Startups
                                            {statistic && <span className="right">
                                        {statistic.projects}
                                    </span>}
                                        </p>
                                    </div>
                                </a>
                                <a href="/#experts">
                                    <div className="block-item-button">
                                        <p className="title-item-button">
                                            Experts
                                            {statistic && <span className="right">
                                        {statistic.experts}
                                    </span>}
                                        </p>
                                    </div>
                                </a>
                                <a href="/#investors">
                                    <div className="block-item-button">
                                        <p className="title-item-button">
                                            Investors
                                            {statistic && <span className="right">
                                        {statistic.investors}
                                    </span>}
                                        </p>
                                    </div>
                                </a>
                                <a href="/#accelerators">
                                    <div className="block-item-button">
                                        <p className="title-item-button">
                                            Accelerators
                                            {statistic && <span className="right">
                                        {statistic.accelerators}
                                    </span>}
                                        </p>
                                    </div>
                                </a>

                            </div>
                        </div>


                        <div id='startups' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Startups
                            </p>
                            <p className="description-list-cart-marketplace">
                                Be the first to discover rising stars!
                            </p>

                            <div className="list-cart-marketplace">
                                {listProjects}
                            </div>

                            {showMoreStartups ?
                                <p className="more-list-cart-marketplace" onClick={this.moreStartups}>
                                    More startups
                                    <i className="far fa-angle-right"/>
                                    <span className="new-black-tool-tip"
                                          data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups at the idea stage (projects with an idea and literally nothing more), whereas 10 goes to startups at the B Series round and higher. ">

                                      </span>

                                </p>
                                :
                                listProjects.length > 3 &&
                                <p className="more-list-cart-marketplace" onClick={this.closeStartups}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }


                        </div>

                        <div id='startupsVideo' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Startups with Video Pitches
                            </p>
                            <p className="description-list-cart-marketplace">
                                See the list of featured startups which published their video presentations
                            </p>

                            <div className="list-cart-marketplace">

                                {listProjectsWithVideo}

                            </div>

                            {!this.state.onePageSV &&
                            <React.Fragment>
                                {showMoreStartupsVideo ?
                                    <p className="more-list-cart-marketplace" onClick={this.moreStartupsVideo}>
                                        More startups with video pitches <i className="far fa-angle-right"/>
                                    </p>
                                    :
                                    listProjectsWithVideo.length > 3 &&
                                    <p className="more-list-cart-marketplace" onClick={this.closeStartupsVideo}>
                                        <i className="far fa-angle-left"/> Close
                                    </p>
                                }
                            </React.Fragment>
                            }

                        </div>


                        <div id="evaluation" className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Startups with preliminary evaluation
                            </p>
                            <p className="description-list-cart-marketplace">
                                Preliminary evaluation of startups conducted by Rocket DAO expert community
                            </p>

                            <div className="list-cart-marketplace">
                                {listProjectsPreliminaryEvaluation}
                            </div>

                            {/*{this.state.showMoreProjectsScore ?*/}
                            {/*<p className="more-list-cart-marketplace" onClick={this.moreProjectsScore}>*/}
                            {/*More startups with Due Diligence <i className="far fa-angle-right"/>*/}
                            {/*</p>*/}
                            {/*:*/}
                            {/*<p className="more-list-cart-marketplace" onClick={this.closeProjectsScore}>*/}
                            {/*<i className="far fa-angle-left"/> Close*/}
                            {/*</p>*/}
                            {/*}*/}

                            {this.state.showMoreStartupsPreliminaryEvaluation ?
                                <p className="more-list-cart-marketplace"
                                   onClick={this.moreStartupsPreliminaryEvaluation}>
                                    More new startups with preliminary evaluation<i className="far fa-angle-right"/>
                                </p>
                                :
                                listProjectsPreliminaryEvaluation.length > 3 &&
                                <p className="more-list-cart-marketplace"
                                   onClick={this.closeStartupsPreliminaryEvaluation}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }

                        </div>

                        <div className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Startups with Due Diligence
                            </p>
                            <p className="description-list-cart-marketplace">
                                See the list of featured startups which published their business plans
                            </p>

                            <div className="list-cart-marketplace">
                                {listProjectsScore}
                            </div>

                            {/*{this.state.showMoreProjectsScore ?*/}
                            {/*<p className="more-list-cart-marketplace" onClick={this.moreProjectsScore}>*/}
                            {/*More startups with Due Diligence <i className="far fa-angle-right"/>*/}
                            {/*</p>*/}
                            {/*:*/}
                            {/*<p className="more-list-cart-marketplace" onClick={this.closeProjectsScore}>*/}
                            {/*<i className="far fa-angle-left"/> Close*/}
                            {/*</p>*/}
                            {/*}*/}


                        </div>


                        <div className="block-banner-marketplace cursor-pointer" onClick={() => {
                            handleDD(403)
                        }} style={{margin: '80px 0 80px'}}>
                            <div className="block-left-banner-marketplace">
                                <p className="title-banner-marketplace">
                                    Due Diligence report
                                </p>
                                <p className="description-banner-marketplace">
                                    Rocket DAO experts conducted the first public Due Diligence.
                                </p>
                                <ButtonGreen
                                    TitleButton={'Here is the full report'}
                                    EventOnClick={() => handleDD(403)}
                                    paddingButton={'20'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-right-banner-marketplace">
                                <img src={DD_1234} alt=""/>
                            </div>
                        </div>


                        <div id='ventureFunds' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Venture Funds and Angel Networks
                            </p>
                            <p className="description-list-cart-marketplace">
                                Top investment funds and networks of the month
                            </p>

                            <div className="list-cart-marketplace">
                                {listFunds}
                            </div>

                            {this.state.showMoreFunds ?
                                <p className="more-list-cart-marketplace" onClick={this.moreFunds}>
                                    More venture funds and angel networks <i className="far fa-angle-right"/>
                                </p>
                                :
                                listFunds.length > 3 &&
                                <p className="more-list-cart-marketplace" onClick={this.closeFunds}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }

                        </div>

                        <div id='investors' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Angel Investors
                            </p>
                            <p className="description-list-cart-marketplace">
                                Top investment angels of the month
                            </p>

                            <div className="list-cart-marketplace">
                                {listInvestors}
                            </div>

                            {this.state.showMoreInvestors ?
                                <p className="more-list-cart-marketplace" onClick={this.moreInvestors}>
                                    More investors <i className="far fa-angle-right"/>
                                </p>
                                :
                                listInvestors.length > 3 &&
                                <p className="more-list-cart-marketplace" onClick={this.closeInvestors}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }
                        </div>


                        {!this.state.isSubscribe2 &&
                        <div className="block-new-banner-get-email" style={{backgroundImage: `url(${Baner})`}}>
                            <p className="title-new-banner-get-email">
                                Get list of metrics that every startup and investor should know and track!
                            </p>
                            <div className="block-input-button-new-banner-get-email">
                                <Input
                                    type={'email'}
                                    name={'email'}
                                    value={this.state.currentEmail}
                                    onChange={this.setCurrentEmail}
                                    placeholder={'Your email'}
                                    error={this.state.error}
                                />
                                <ButtonBlue
                                    TitleButton={'Get my copy'}
                                    EventOnClick={() => this.subscribe(subscribeTypes['5'])}
                                    paddingButton={'30'}
                                    disabled={false}
                                    loader={false}
                                />
                            </div>
                        </div>

                        ||

                        <div className="block-new-banner-get-email" style={{backgroundImage: `url(${Baner})`}}>
                            <p className="title-new-banner-get-email">
                                Your copy of our 'Top startups metrics for you to track' is on its way right now.
                            </p>
                            <div className="block-input-button-new-banner-get-email">
                                Check out your email. You are cool!
                            </div>
                        </div>
                        }

                        <div id='experts' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Top Experts
                            </p>
                            <p className="description-list-cart-marketplace">
                                Experts with highest Rocket DAO verification rate
                            </p>

                            <div className="list-cart-marketplace">
                                {listExperts}
                            </div>
                            {/*<div className="row">*/}
                            {/*<div style={{height: '50px', background: '#000', color: '#fff'}}>*/}
                            {/*Show all*/}
                            {/*</div>*/}
                            {/*</div>*/}

                            {showMoreExperts ?
                                <p className="more-list-cart-marketplace" onClick={this.moreExpert}>
                                    More experts <i className="far fa-angle-right"/>
                                </p>
                                :
                                listExperts.length > 6 &&
                                <p className="more-list-cart-marketplace" onClick={this.closeExpert}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }

                        </div>

                        {listAccelerators.length > 0 && <div id='accelerators' className="block-list-cart-marketplace">
                            <p className="title-list-cart-marketplace">
                                Accelerators
                            </p>

                            <div className="list-cart-marketplace margin-top-15">
                                {listAccelerators}
                            </div>

                            {this.state.showMoreAccelerators ?
                                <p className="more-list-cart-marketplace" onClick={this.moreAccelerator}>
                                    More accelerators <i className="far fa-angle-right"/>
                                </p>
                                :
                                listAccelerators.length > 6 &&
                                <p className="more-list-cart-marketplace" onClick={this.closeAccelerator}>
                                    <i className="far fa-angle-left"/> Close
                                </p>
                            }

                        </div>}


                        <div className="block-list-success-stories block-gradient-gray-reg">
                            <p className="title-latest-articles">Community feedback</p>

                            <div className="list-item-success-stories">
                                <div className="block-item-success-stories left-item-success-stories">
                                    <div className="block-left-item-success-stories">
                                        <div className="img-item-success-stories"
                                             style={{backgroundImage: `url(${avatar_1})`}}/>
                                    </div>
                                    <div className="block-right-item-success-stories">
                                        <p className="title-item-success-stories">
                                            "I was really impressed by the level of competencies of the experts who
                                            conducted Due Diligence for our project."
                                        </p>
                                        <p className="description-item-success-stories">
                                            <a href="https://www.linkedin.com/in/vadimicus/" className="no-undeground">Vadim
                                                Makovsky</a>, <span className="gray">CEO</span> <a
                                            className="no-undeground" href="https://rocketdao.io/startup/445"><span
                                            className="blue">Multy</span></a>
                                        </p>
                                        {/*<a href="" className="link-item-success-stories">*/}
                                        {/*kateholmes.com*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                                <div className="block-item-success-stories right-item-success-stories">
                                    <div className="block-left-item-img">
                                        <div className="img-item-success-stories"
                                             style={{backgroundImage: `url(${avatar_2})`}}/>
                                    </div>
                                    <div className="block-left-item-success-stories">
                                        <p className="title-item-success-stories">
                                            "Rocket DAO evaluation standard helps startups and investors communicate on
                                            a single, understandable language."
                                        </p>
                                        <p className="description-item-success-stories">
                                            <a href="https://www.linkedin.com/in/valentine/" className="no-undeground">Valentine
                                                Zavgorodnev</a>, <span className="gray">Business angel</span>
                                        </p>
                                        {/*<a href="" className="link-item-success-stories">*/}
                                        {/*kateholmes.com*/}
                                        {/*</a>*/}
                                    </div>
                                    <div className="block-right-item-success-stories">
                                        <div className="img-item-success-stories"
                                             style={{backgroundImage: `url(${avatar_2})`}}/>
                                    </div>
                                </div>
                                <div className="block-item-success-stories left-item-success-stories">
                                    <div className="block-left-item-success-stories">
                                        <div className="img-item-success-stories"
                                             style={{backgroundImage: `url(${avatar_3})`}}/>
                                    </div>
                                    <div className="block-right-item-success-stories">
                                        <p className="title-item-success-stories">
                                            "I am honored to be a contributor to the first ever viable decentralized
                                            expert community."
                                        </p>
                                        <p className="description-item-success-stories">
                                            <a href="https://rocketdao.io/expert/31" className="no-undeground">Andrew
                                                Kuryan</a>, <span className="gray">TRIZ trainer in EPAM Systems</span>
                                        </p>
                                        {/*<a href="" className="link-item-success-stories">*/}
                                        {/*kateholmes.com*/}
                                        {/*</a>*/}
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div className="block-list-articles">

                            <p className="title-latest-articles">The latest articles</p>
                            <div className='latest-articles-block'>

                                <div className="block-big-post-blog">
                                    <a href={'https://startupjedi.vc'+mainBlog.href}
                                       target={'_blank'}>
                                        <div className="block-left-big-post-blog">
                                            <div className="bg-big-post-blog"
                                                 style={{backgroundImage: `url(${mainBlog.img})`}}/>
                                        </div>
                                        <div className="block-right-big-post-blog">
                                            <p className="date-big-post-blog">
                                                {moment(mainBlog.date).format('DD MMM, YYYY')}
                                            </p>
                                            <p className="title-big-post-blog">
                                                {mainBlog.title}
                                            </p>
                                            <p className="description-big-post-blog">
                                                {mainBlog.shortDescription}
                                            </p>
                                        </div>
                                    </a>

                                </div>

                                <div className="block-col-post-block">

                                    {
                                        blogList.map((o, i) => <div
                                            className={`${['left-post-block', 'center-post-block', 'right-post-block'][i]} bottom-post`}>
                                            <a href={'https://startupjedi.vc/'+o.href}
                                               target={'_blank'}>
                                                <div className='bottom-post-img'
                                                     style={{backgroundImage: `url(${o.img})`}}/>
                                                <div className='date-title'>
                                                    {moment(o.date).format('DD MMM, YYYY')}
                                                </div>
                                            </a>
                                        </div>)
                                    }
                                </div>

                                <div className='latest-articles-block-button'>
                                    <ButtonBlue
                                        TitleButton={'Explore Rocket DAO blog'}
                                        EventOnClick={() => window.open('https://startupjedi.vc/', "_blank")}
                                        paddingButton={'0'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="block-want-know-more">
                            <p className="title-want-know-more">
                                Want to know more?
                            </p>
                            <p className="description-want-know-more">
                                Rocket DAO is an ecosystem product for more effective interaction between startups and
                                investors under the supervision of the expert community.
                            </p>
                            <ButtonBlue
                                TitleButton={'Learn more about Rocket DAO'}
                                EventOnClick={() => window.open('/about', "_blank")}
                                paddingButton={'40'}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <Footer/>

                    {this.state.showVideoPopup ?
                        <VideoPopUp id={this.state.showVideoId} user={user} close={() => this.togglePopup(null)}/>
                        : null
                    }

                    {this.state.showCartPopup ?
                        <CartPopUp id={this.state.cartId} user={user} type={this.state.CartPopUpType}
                                   type2={this.state.CartPopUpType2} close={() => this.togglePopupCart(null, null)}/>
                        : null
                    }

                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Mp.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser, logout})(Mp)

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
// import {regexUrl} from "../../../utils/RegExp";
// import Input from "../../controls3.0/input/Input";
import {SocialContacts} from "../../v3/socialContacts";

class Step17 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {
                twitterLink: props.angel.twitterLink || '',
                facebookLink: props.angel.facebookLink || '',
                linkedInLink: props.angel.linkedInLink || '',
                contacts: props.angel.contacts
            };
        if(!angel.contacts) angel.contacts = []
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            contact: '',
            validate: false
        }
    }

    componentDidMount () {

    }

    handle = (data, errors, validate) => {
        this.setState({angel: data, errors, validate})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        obj.isFinished = true;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true});

        obj.event_type = 'network_contacts_17';

        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, angel, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg container-step-16" style={{position: 'relative'}}>
                            <div className="container-width-600">
                                <p className="text-h1 color-text-reg-gray margin-0">
                                    Contacts
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 text-middle">
                                    Almost done!
                                </p>
                                <p className="text-button color-text-reg-gray margin-0" style={{maxWidth: '660px'}}>
                                    How can the community contact you to establish new business relations?
                                    Share the links to the branded social media profiles of your angel network here.
                                </p>
                            </div>



                            <div
                                className="block-list-cart-reg-startup">
                                <div className="block-gradient-gray-reg new-pad-35-top new-mar-30-top"></div>
                                <div className="container-step-2-reg">
                                    <SocialContacts object={angel} errors={this.state.errors} handle={this.handle}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.state.angel.proofSent ? this.update(15, true) : this.update(17, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(19)}
                                paddingButton={'54'}
                                disabled={!this.state.validate}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step17.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step17)
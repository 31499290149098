import React, {useEffect, useState, useRef} from 'react';
import './style.scss'
import RegAvatar from "../../controls3.0/regAvatar/regAvatar";

const PhotoBlock = ({logoFileId, parentCallback, parentBlob}) => {
    const [changeImage, setChangeImage] = useState(false);
    const [blob, setBlob] = useState(false);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        parentCallback(blob)
    }, [blob])

    useEffect(() => {
        if(avatar){
            let avatarState = avatar ? avatar.state : {};
            if (avatarState.image) {
                avatar.editor.getImageScaledToCanvas().toBlob(function (blob) {
                    setBlob(blob);
                }, 'image/png', 1);
            }
        }
    }, [changeImage, avatar]);

    useEffect(() => {
        if(avatar && !parentBlob){
            avatar.setState({image: null})
        }
    }, [parentBlob]);

    return (
        <React.Fragment>
            <div className='row-b photo-block-container'>
                <div className="col-12 col-sm-8">
                    <div className="photo-block-title mt-sm-25">
                        Your photo
                    </div>
                    <div className="photo-block-title-descr">
                        Are there any requirements? Actually no.<br/>
                        Just a photo of you.
                    </div>
                </div>
                <div className="col-12 col-sm-4 mt-sm-0 mt-32">
                    <RegAvatar
                        fileId={logoFileId}
                        title={logoFileId ? 'Add new' : 'Add photo'}
                        description='Not less than 180*180 pixels'
                        onLoadSuccess={() => {
                            console.log('avatar load success')
                        }}
                        ref={(_avatarEditor) => {
                            setAvatar(_avatarEditor)
                        }}
                        onChange={(n) => setChangeImage(n)}
                    />
                </div>
            </div>

        </React.Fragment>
    )
};

export default PhotoBlock;
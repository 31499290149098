import React from 'react';
import './File.css'
import Tag2 from '../Tag2.0/Tag2.0'
import moment from 'moment';
import copy from 'copy-to-clipboard';
import packageJSON from './../../../../package.json'
import ModalEditFFL from '../../modal/ModalEditFFL'
import {checkTitleOfButton, checkRegistration, checkAccess} from "../../../utils/checkRegistration";

const ISADMIN = localStorage.getItem("isAdmin");


export default class File extends React.Component {
    constructor(props) {
        super();
        this.state = {
            file: {
                tags: []
            },
            logoClass: 'fal fa-file-alt',
            isLink: false,
            copied: false,
            editMode: false,
            editOne: {}
        };
    }

    componentDidMount () {
        this.setState({file: this.props.file})
        let file = this.props.file;
        let logoClass;

        if(file.type == 'file'){
            if (file.file) {

                if(/application/.test(file.file.mimetype)){
                    logoClass="fal fa-file"
                    if(file.file.mimetype == 'application/pdf') logoClass="fal fa-file-pdf"
                    if(file.file.mimetype == 'application/zip') logoClass="fal fa-file-archive"

                } else if(/audio/.test(file.file.mimetype)) {
                    logoClass="fal fa-file-audio"
                    // logoClass=""

                } else if(/image/.test(file.file.mimetype)) {
                    logoClass="fal fa-file-image"

                } else if(/text/.test(file.file.mimetype)) {
                    logoClass="fal fa-file-word"

                } else if(/video/.test(file.file.mimetype)) {
                    logoClass="fal fa-file-video"

                } else {
                    logoClass="fal fa-file-alt"
                }
            } else {
                logoClass="fal fa-file-alt"
            }

        } else if(file.type == 'href') {
            logoClass = "fal fa-link";
            this.setState({isLink: true})
        }

        this.setState({logoClass: logoClass})
    }

    copyLink = () => {
        let hasAccess = checkAccess(this.props.user, 'expert')

        if(hasAccess) {
            if(this.state.isLink) {
                if(this.state.file && this.state.file.href) {
                    this.setState({copied: true})
                    copy(this.state.file.href);
                }
            } else {
                this.setState({copied: true})
                let link = packageJSON.proxy + "/file/" + this.state.file.fileId + "/view"
                copy(link);
            }

            setTimeout(() => {
                this.setState({copied: false})
            }, 2000)
        } else {
            checkRegistration(this.props.user, 'expert')
        }

    }

    onChange = e => {
        this.setState({file:{...this.state.file, [e.target.name]: e.target.value}});
    }

    edit = item => {
        this.setState({showModal2: true, editOne: item})
    }

    handleCloseModal = () => {
        this.setState({typeModal: null, showModal: false, showModal2: false, editOne: {}});
    }

    cancel = () => {
        this.setState({editMode: false})
        this.setState({file:{...this.state.file, title: this.props.file.title}});

    }

    save = (item) => {
        this.setState({file: item})
        this.handleCloseModal()
    }

    openFile = id => {
        let hasAccess = checkAccess(this.props.user, 'expert')

        if(hasAccess) {
            let url = `${packageJSON.proxy}/file/${id}/view`
            window.open(url, '_blank')
        } else {
            checkRegistration(this.props.user, 'expert')
        }
    }

    followLink = link => {
        let hasAccess = checkAccess(this.props.user, 'expert')
        if(hasAccess) {
            window.open(link, '_blank')
        } else {
            checkRegistration(this.props.user, 'expert')
        }
    }


    render() {
        // let file = this.props.file;
        let file = this.state.file;


        if(!file.tags) file.tags = [];

        let Tags = file.tags.map((item, i) => {
            return (
                <Tag2
                    key={i}
                    TitleTag={item.title}
                    LinkTag={''}
                    Color="#0028DC"
                    getFilterByTag={this.props.getFilterByTag}
                />
            )
        })

        let nesting = {paddingLeft: (20 * (file.level * 1)) + 'px'}


        let rightColumn;

        return (
            <div className="shell-file">
                <div className="block-file">
                    <div className="block-top-file">
                        <div className="block-logo-title-file file-edit" style={nesting} >
                            <div className="block-top-logo-fiel">
                                <i className={this.state.logoClass}></i>
                            </div>
                            <div className="block-top-title-file">
                                <div className="shell-flex-file">


                                    {!this.state.editMode &&
                                    <div className="block-flex-file">
                                        <p className="title">{file.title}</p>
                                        <p className="description">{file.description}</p>
                                    </div>
                                    }

                                    {this.state.editMode &&
                                    <div>
                                        <div className="block-flex-file block-flex-top-title-folder" style={{'display': 'block', 'width': '100%'}} >
                                            <input type="text" name="title" className="is-click-link" value={this.state.file.title} onChange={this.onChange} style={{'display': 'block', 'width': '100%', 'height': '30px'}} />
                                        </div>
                                        <div className="block-flex-file block-flex-top-title-folder" style={{'display': 'block', 'width': '100%'}} >
                                            <input type="text" name="description" className="is-click-link" value={this.state.file.description} onChange={this.onChange} style={{'display': 'block', 'width': '100%', 'height': '26px', 'fontSize': '12px'}} />
                                        </div>
                                    </div>

                                    }


                                </div>
                            </div>
                        </div>
                        <div className="block-tags-date-file file-edit">
                            <div className="block-top-tags-file file-edit">
                                <div className="shell-flex-file">
                                    <div className="block-flex-file">
                                        {Tags}
                                    </div>
                                </div>
                            </div>
                            <div className="block-top-date-file file-edit"><p>
                                {moment(file.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
                            </p></div>
                            <div className="block-top-link-file file-edit">
                                <div className="shell-flex-file">
                                    <div className="block-flex-file">

                                        {!this.state.isLink &&
                                        <span onClick={() => this.openFile(file.fileId)}>Open File</span>
                                        }
                                        {this.state.isLink &&
                                        <span onClick={() => this.followLink(file.href)}>Follow the link</span>
                                        }

                                        <a onClick={this.copyLink}>
                                            Copy link
                                            {this.state.copied &&
                                            <span className="link-copied">Copied</span>
                                            }
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="block-top-delete-file file-edit">
                                <div className="shell-flex-file">
                                    <div className="block-flex-file">
                                        {ISADMIN &&
                                             <span>
                                                 {!this.state.editMode &&
                                                 <span>
                                                        <a className='is-click-link' style={{'color': 'blue'}} onClick={() => this.edit(file)}>edit</a>
                                                        <a className='is-click-link' onClick={() => this.props.delete(file)}>delete</a>
                                                    </span>
                                                 }
                                                 {/*{this.state.editMode &&*/}
                                                 {/*<span>*/}
                                                        {/*<a className='is-click-link' style={{'color': 'green'}} onClick={() => this.save()}>save</a>*/}
                                                        {/*<a className='is-click-link' style={{'color': 'black'}} onClick={() => this.cancel()}>cancel</a>*/}
                                                    {/*</span>*/}
                                                 {/*}*/}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showModal2 &&
                <ModalEditFFL
                    thing={this.state.editOne}
                    showModal={this.state.showModal2}
                    handleCloseModal={this.handleCloseModal}
                    save={this.save}
                />
                }

            </div>
        );
    }
}


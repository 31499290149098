
export const getProjectAction = (project) => {
    return {
        type: 'GET_PROJECT_INFO',
        project
    }
}

export const updateProjectInfo = (project) => {
    return {
        type: 'UPDATE_PROJECT_INFO',
        project
    }
}


import React, {useEffect, useState} from 'react';
import Input from "../../controls3.0/input/Input";
import './style.scss'
import Select from "../../controls3.0/select/Select";
import {LANGUAGES} from "../../../utils/countriesLanguages";
import Tabs from "../../controls3.0/tabs/Tabs";

const  Index = ({langs, parentCallback}) => {

    const onChangeCountry = (e) => {
        let _myLangs = langs;
        _myLangs.push(e[0].Name);

       parentCallback(_myLangs)
    };
    const removeCountry = (item) => {
        if(item) {
            let filtered = langs.filter(x => x !== item);
            parentCallback(filtered)
        }
    };

    let checkedLangs = langs.map((item, key) => {
        return (
            <div className='col-auto'>
                <Tabs
                    key={key}
                    TitleTab={item}
                    TypeTab={'small'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => removeCountry(item)}
                />
            </div>
        )
    });

    return (
        <React.Fragment>
            <div className='lang-block-container'>
                <div className='row-b'>
                    <div className='col-12 col-sm-5 col-lg-6 order-2 order-sm-1 lang-left-col pt-sm-0'>
                        <p className="lang-block-title">
                            Add spoken languages
                        </p>
                        <Select
                            placeholder={'Language'}
                            data={LANGUAGES.filter(i => {
                                return langs.indexOf(i.Name) === -1
                            })}
                            onChange={(e) => onChangeCountry(e)}
                            error={''}
                            inputPlaceholder={'Language'}
                        />
                    </div>
                    <div className='col-12 col-sm-7 col-lg-6 order-1 order-sm-2'>
                        <p className="lang-block-title">
                            Spoken languages:
                        </p>
                        {langs.length !== 0 && <div className='row-m0 checked-countries-wrapper no-gutters'>
                            <div className='row-m0 checked-countries-block w-100'>{checkedLangs}</div>
                        </div>}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};

export default Index;
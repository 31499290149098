import React from 'react';

import '../style.css'

export default class ProgressBar extends React.Component {

    render() {

        let {
            stepNum,
            stepAmount
        } = this.props;

        return (
            <div className="block-viral-test-progress-bar progress-bar-text">
                <div className='block-viral-test-step' style={{width: stepNum*100/stepAmount+'%'}}>
                    {stepNum}
                </div>
                {(stepNum < stepAmount)?stepAmount:''}
            </div>

        );
    }
}
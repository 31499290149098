import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment/moment";

import {updateInvestor} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import {showMessage} from "../../../utils/showMessage";
import ListTitleDescriptionMore from '../../controls3.0/listTitleDescriptionMore/ListTitleDescriptionMore'

import {createWorkExp, getListWorkExp, deleteWorkExp, updateWorkExp, getCitySearch} from '../../../actions/Profile'
import {WorkExperience} from "../../v3/workExperience";


class Step5 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {},
            listWE: [],
            listCities: [],
            experience: {
                position: '',
                description: '',
                company: '',
                city: '',
                periodFrom: '',
                periodTo: ''
            }
        }
    }

    componentDidMount() {
        getListWorkExp(this.state.user.id)
            .then(res => {
                let sorArr = this.sortWE(res.data)
                this.setState({listWE: sorArr})
            })
    }

    onSave = (experience) => {
        let userId = this.props.user ? this.props.user.id : null,
            createUpdateFunc = experience.id ? updateWorkExp : createWorkExp;


        const copy = Object.assign({}, experience);
        copy.city = experience.city[0].Name
        if (copy.currentJob) delete copy['periodTo']

        createUpdateFunc(userId, copy)
            .then(res => {
                this.addWE(res.data)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    }

    addWE = data => {
        let listWE = this.state.listWE;


        const index = listWE.findIndex(item => item.id === data.id);

        if (index >= 0) {
            listWE[index] = data;
            this.setState({listWE,
                showAdd: false,
                editWP: false})
        } else {
            let arr = [...listWE, data];
            let arrSort = this.sortWE(arr);
            this.setState({
                listWE: arrSort,
                showAdd: false,
                editWP: false
            })
        }

        this.setState({
            experience: {
                position: '',
                description: '',
                company: '',
                city: '',
                periodFrom: '',
                periodTo: ''

            }
        })
    }

    deleteWE = key => {
        let listWE = this.state.listWE,
            one = listWE[key]
        deleteWorkExp(this.props.user.id, one.id)
            .then(res => {
                listWE.splice(key, 1)
                this.setState({listWE})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    }

    sortWE = arr => {
        let sortArr = arr.sort(function (a, b) {
            return new Date(b.periodFrom) - new Date(a.periodFrom);
        });
        return sortArr
    }

    update = (nextStep, onlyStep) => {
        let obj = this.props.user.investor;
        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    setWorkExperienceData = id => {
        const current = this.state.listWE.find((i) => i.id === id);
        this.setState({workExperienceData: {...current, city : [{Name: current.city}]}, editWP: true});
    };

    render() {
        let {user, investor, errors, experience} = this.state

        let listWE = this.state.listWE.map((item, key) => {
            let obj = {}
            obj.id = item.id;
            obj.title = `${moment(item.periodFrom).format('MMM YYYY')} - ${ item.currentJob ? 'Present' : moment(item.periodTo).format('MMM YYYY')}`
            obj.description = `${item.position} - ${item.company}`
            return obj
        })

        return (
            <React.Fragment>
                {!this.state.showAdd && !this.state.editWP ?
                    <React.Fragment>
                        <div className="container-full-reg padding-reg">
                            <div className="container-step-2-reg">


                                <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                                    Work experience
                                </p>

                                <p className="title-top-page-reg-expert text-h4 left-text shell-margin-bottom-40 shell-margin-top-0">
                                    Let the community know you are a true specialist. <br/>
                                    Demonstrate your professional background, competencies and skills.
                                </p>


                                <div className="block-gradient-25">
                                    <div className="block-gradient-gray-reg" style={{top: '-40px'}}/>
                                    <ButtonBlue
                                        TitleButton={'Add experience'}
                                        EventOnClick={() => this.setState({showAdd: true,  workExperienceData: false})}
                                        paddingButton={'20'}
                                        disabled={false}
                                        loader={false}
                                    />
                                    <div className="block-list-experience">

                                        <ListTitleDescriptionMore
                                            data={listWE}
                                            onChange={null}
                                            delete={this.deleteWE}
                                            onClick={this.setWorkExperienceData}
                                            titleMore={'test'}
                                            viewCountItem={4}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="block-fix-button-reg-bottom fix-button-double">
                            <div className="container-full-reg">
                                <div className="block-button-left">
                                    <ButtonGray
                                        TitleButton={'Back'}
                                        EventOnClick={() => this.update(4, true)}
                                        paddingButton={'55'}
                                        disabled={false}
                                    />
                                </div>
                                <div className="block-button-right">
                                    <ButtonBlue
                                        TitleButton={'Save & Continue'}
                                        EventOnClick={() => this.update(6)}
                                        paddingButton={'54'}
                                        disabled={false}
                                        loader={false}
                                    />
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                    :
                    <div className="container-full-reg" style={{position: 'relative', padding: '0 0 350px'}}>
                        <div className="block-button-circle-title">
                            <div className="block-button-circle" onClick={() => this.setState({showAdd: false, editWP: false})}>
                                <i className="fal fa-long-arrow-left"/>
                            </div>
                            <p className="title">
                                Work <br/>
                                Experience
                            </p>
                        </div>

                        <div className="container-step-2-reg">
                            <p className="title-top-page-reg-expert text-h1 left-text margin-0 shell-margin-bottom-10 new-mar-40-top">
                                Add experience
                            </p>
                            <WorkExperience onSave={this.onSave} data={this.state.workExperienceData || null}/>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step5.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step5)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {getLastProject, updateProject} from "../../../actions/Project";
import CheckBox from "../../controls3.0/checkBox/checkBox";

class Step18 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {}
        }
    }

    componentDidMount() {
        if (!this.state.project.id) this.props.getLastProject()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project})
        }
    }

    set = type => {
        let currentType = this.state.project.fundraisingInstruments;
        if (currentType === type) type = '';
        this.setState({project: {...this.state.project, fundraisingInstruments: type}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project;
        let obj = {
            id: project.id,
            fundraisingInstruments: project.fundraisingInstruments,
            event_type: 'startup_fundraising_6'
        }

        if (!onlyStep) {
            this.setState({loading: true})
            if (project.fundraisingInstruments == 'ICO') {
                nextStep = 19
            }
        }

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let {project} = this.state;


        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Fundraising instruments
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Currently there are three most popular instruments through which a startup can <br/>
                                attract financing to meet its development and expansion needs.
                            </p>
                            <p className="text-button color-text-reg-gray margin-0">
                                Which one are you going to use for your project? Click on the relevant variant.
                            </p>


                            <div
                                className="block-list-cart-reg-startup block-gradient-gray-reg padding-top-35 margin-top-50">
                                <div
                                    className={project.fundraisingInstruments == 'VC' ? 'block-item-cart-reg-startup active' : 'block-item-cart-reg-startup'}
                                    onClick={() => this.set('VC')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.fundraisingInstruments == 'VC' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        VC
                                    </p>
                                    <p className="description-front-cart">
                                        Venture Capital
                                    </p>
                                    <p className="description-back-cart">
                                        VC is a risky form of financing of emerging startups and small companies that
                                        potentially will generate above-average returns.
                                        <br/><br/>
                                        In return for financing investors get equity in the company and can influence th
                                        decision-making processes then.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.fundraisingInstruments == 'VC' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={project.fundraisingInstruments == 'STO' ? 'block-item-cart-reg-startup active' : 'block-item-cart-reg-startup'}
                                    onClick={() => this.set('STO')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.fundraisingInstruments == 'STO' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        STO
                                    </p>
                                    <p className="description-front-cart">
                                        Security Token Offering
                                    </p>
                                    <p className="description-back-cart">
                                        STO is a form of crowdfunding which enables companies to create token value from
                                        an external tradable asset.
                                        <br/><br/>
                                        Security token means creating an investment offer where stock or bonds are
                                        tokenised and put on a blockchain.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.fundraisingInstruments == 'STO' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={project.fundraisingInstruments == 'ICO' ? 'block-item-cart-reg-startup active' : 'block-item-cart-reg-startup'}
                                    onClick={() => this.set('ICO')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.fundraisingInstruments == 'ICO' ? true : false}
                                        />
                                    </div>
                                    <p className="title-front-cart">
                                        ICO
                                    </p>
                                    <p className="description-front-cart">
                                        Initial Coin Offering
                                    </p>
                                    <p className="description-back-cart">
                                        ICO is a fundraising mechanism letting projects sell their underlying crypto
                                        tokens in exchange for Bitcoin, Ethereum or other cryptocurrencies.
                                        <br/><br/>
                                        It’s popular among blockchain companies, nut not as regulated as traditional
                                        fundraising methodes.
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.fundraisingInstruments == 'ICO' ? 'Cancel' : 'Choose'}
                                        </p>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(5, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(6)}
                                paddingButton={'54'}
                                disabled={!project.fundraisingInstruments}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step18.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step18)
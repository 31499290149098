import React from 'react';
import config from "../../../config";
import ExpertReviewScore from './ExpertReviewScore'
import {createAvatar} from "../../../components/user/createAvatar"

import './styleViewEval.css'
import "../../../libs/grid/grid.css";

export default class ViewEvalLeftBar extends React.Component {



    AmountEvaluation = (evaluation)=>{
        let sum = 0;
        for (let i = 0; i < evaluation.length;i++) {
            sum += evaluation[i].ParamEvaluation;
        }
        return (sum/evaluation.length).toFixed(1);
    };


    render() {
        let {
            startup,
            openedEval,
            evalClick,
            Evaluation
        } = this.props;


        return (
                <div className='view-eval-left-bar'>
                        <div className='row-b w-100 no-gutters startup-profile'>
                            <div className='row-b no-gutters avatar' style={startup.logoFileId ?{backgroundImage: `url(${config.proxy}/file/${startup.logoFileId}/view?W=200`}:createAvatar('photo-cart-expert', startup.title)}/>
                            <div className='row-b w-100 no-gutters startup-name-block color-rgba-08 margin-top-12'>
                                <div className='evaluation-title-descr'>{startup.title}</div>
                            </div>
                        </div>
                        <div className='row-b w-100 no-gutters margin-top-15'>
                            <ExpertReviewScore
                                rewiewScore={[
                                    this.AmountEvaluation(Evaluation),
                                    Evaluation[0].ParamEvaluation,
                                    Evaluation[1].ParamEvaluation,
                                    Evaluation[2].ParamEvaluation,
                                    Evaluation[3].ParamEvaluation,
                                ]}
                                evalClick={(evalParam)=>{evalClick(evalParam)}}
                            />
                        </div>
                        <div className='row-b w-100 no-gutters evaluation-points-block margin-top-15'>
                                <div className={`row-b w-100 no-gutters margin-top-12 ${openedEval === 0 ? 'font-weight-500 color-rgba-08' : ''}`}>
                                    <div className='point-text cursor-pointer' onClick={()=>{evalClick(0)}}>
                                        {Evaluation[0].ParamName}
                                    </div>
                                </div>
                                <div className={`row-b w-100 no-gutters margin-top-12 ${openedEval === 1 ? 'font-weight-500 color-rgba-08':''}`}>
                                    <div className='point-text cursor-pointer' onClick={()=>{evalClick(1)}}>
                                        {Evaluation[1].ParamName}
                                    </div>
                                </div>
                                <div className={`row-b w-100 no-gutters margin-top-12 ${openedEval === 2 ? 'font-weight-500 color-rgba-08':''}`}>
                                    <div className='point-text cursor-pointer' onClick={()=>{evalClick(2)}}>
                                        {Evaluation[2].ParamName}
                                    </div>
                                </div>
                                <div className={`row-b w-100 no-gutters margin-top-12 ${openedEval === 3 ? 'font-weight-500 color-rgba-08' : ''}`}>
                                    <div className='point-text cursor-pointer' onClick={()=>{evalClick(3)}}>
                                        {Evaluation[3].ParamName}
                                    </div>
                                </div>
                        </div>
                </div>
        )
    }
}
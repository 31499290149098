import React from 'react';

import Input from '../../controls3.0/input/Input'

export default class ViewInput extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {

        return (
            <div>
                <div style={{width:'300px', float:'left', margin:'0 10px'}}>
                    <Input
                        type={'text'}
                        name={'name'}
                        value={''}
                        onChangeInput={(e) => console.log(e)}
                        error={'Error Message'}
                        placeholder={'Name'}
                        iconClass={'fal fa-user'}
                    />
                    <br/><br/>
                    <Input
                        type={'text'}
                        name={'name'}
                        value={''}
                        onChangeInput={(e) => console.log(e)}
                        error={'Error Message'}
                        placeholder={'Name'}
                        iconClass={'fal fa-lock-alt'}
                    />
                </div>
                <div style={{width:'300px', float:'left', margin:'0 10px'}}>
                    <Input
                        type={'text'}
                        name={'name'}
                        value={''}
                        onChangeInput={(e) => console.log(e)}
                        error={'Error Message'}
                        placeholder={'Name'}
                        iconClass={'fal fa-lock-alt'}
                    />
                    <br/><br/>
                    <Input
                        type={'text'}
                        name={'name'}
                        value={''}
                        onChangeInput={(e) => console.log(e)}
                        error={'Error Message'}
                        placeholder={'Name'}
                        iconClass={'fal fa-lock-alt'}
                    />
                </div>

            </div>
        );
    }
}

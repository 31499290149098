import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import {youtubeParser} from "../../../utils/RegExp";

function getYoutubePreview(id) {
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
}


export const StartupListVideoPitchItem = ({key, item, href, onClick}) => {
    let youtubePreview = '';

    if (item.pitch) {
        const youtubeId = youtubeParser(item.pitch.youtubeLink);
        youtubePreview = getYoutubePreview(youtubeId);
    }

    const _bg = youtubePreview;

    const _avatar = item.logoFileId ?
        <div className="img-top-cart-new-video-marketplace"
             style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=200`})`}}/>
        :
        createAvatar('img-top-cart-new-video-marketplace', item.title);

    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className="block-cart-new-video-marketplace slide-up"
                     onClick={onClick}>
                    <div className="block-top-cart-new-video-marketplace">
                        <div className="block-bg-top-cart-new-video-marketplace">
                            <div className="bg-top-cart-new-video-marketplace"
                                 style={{backgroundImage: `url(${_bg})`}}/>
                            <div className="block-button-play-top-cart-new-video-marketplace">
                                <i className="fas fa-play"/>
                            </div>
                            <div className="block-button-path-top-cart-new-video-marketplace">
                                <p className="title-button-path-top-cart-new-video-marketplace">
                                    Video Pitch
                                </p>
                            </div>
                        </div>
                        <div className="block-img-top-cart-new-video-marketplace">
                            {_avatar}
                        </div>
                    </div>
                    <div className="block-body-cart-new-video-marketplace">
                        <p className="title-body-cart-new-video-marketplace">
                            {item.title}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    )
}
import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonGreen'
import ButtonBlack from '../controls3.0/button/ButtonBlack'

import bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import '../expert4.0/style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import config from './../../config';

import axios from "axios";

import {Swiper, Slide} from 'react-dynamic-swiper'
import 'react-dynamic-swiper/lib/styles.css'

import {Comments} from '../v3/comments';

import Input from "../controls3.0/input/Input";
import {getListIndustries, getListFocuses, getProjectAchievementsPublic} from "../../actions/Project";
import InputRange from 'react-input-range';

import ImageGallery from 'react-image-gallery';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css"

import Loader from '../test-controls/preloader/preloader'
import {getQueryVariable, getYoutubeEmbedURL, getYoutubePreview} from "../../utils/youtube";
import ModalNeedRegister from '../modal/NeedRegister'
import handleDD from '../../utils/handleDD'
import {LinkedinShareButton, TwitterShareButton, FacebookShareButton} from "react-share";
import {createAvatar} from "../../components/user/createAvatar"
import EditMyPageButton from '../v3/editMyPage';

import App_Store from '../../images/icon/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import Google_Store from '../../images/icon/Download_on_the_google_Store_Badge_US-UK_RGB_blk_092917.svg'
import {youtubeParser} from '../../utils/RegExp';
import MetaTags from "react-meta-tags";
import {updateMenu} from "../../actions/menu";
import PreliminaryEvaluationTable from "./preliminaryEvaluation/PreliminaryEvaluationTable";
import ExpertReviewScore from "./preliminaryEvaluation/ExpertReviewScore";
import history from '../../history';
import PopupLogin from '../v3/popup/login';
import avatarExpert510Erez from '../../images/newExperts/Erez.jpg'
import avatarExpert510Nick from '../../images/newExperts/Nick.jpg'
import qs from "qs";
import HeaderStickyRole from "../controls3.0/headerStickyRole";
import {isExist} from "../../utils/helper";
import {viewCounter} from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";
import {tokenLogin} from "../../actions/Login";
import StartupWaitButton from "../v4/Button/StartupButton/Wait";
import {VerificationIcon} from "../v4/VerificationIcon";
import {updateMarket} from '../../actions/Market';
import start from "../test-controls/newStartPage/start";

function reverseString(str) {
    const splitString = str.split("");
    const reverseArray = splitString.reverse();
    return reverseArray.join("");
}


Number.prototype.moneyFormat = function () {
    const r = reverseString(this.toString()).split("").map((c, i) => {
        if (i % 3 === 0 && i > 0) {
            return ' ' + c;
        } else {
            return c;
        }
    }).join("");

    return reverseString(r);
};

const TYPES = [];

TYPES[0] = {
    id: 0,
    title: 'Articles',
    description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
};
TYPES[1] = {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'};
TYPES[2] = {
    id: 2,
    title: 'Diploma',
    description: 'Documents and any documentary proof of your educational background.'
};
TYPES[3] = {
    id: 3,
    title: 'Projects',
    description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
};
TYPES[4] = {
    id: 4,
    title: 'Mass media',
    description: 'Links to the articles about you or about your professional experience.'
};
TYPES[5] = {
    id: 5,
    title: 'Recommendations',
    description: 'Reviews of your expert works and professional collaboration.'
};
const ISADMIN = 'true' === localStorage.getItem("mmod");

Date.prototype.toShortFormat = function () {

    const month_names = ["Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
};

String.prototype.toUrl = function () {
    const re = new RegExp("^(http|https)://", "i");

    if (re.test(this)) {
        return this;
    }
    if (re.test(this)) {
        return this;
    }
    return 'http://' + this;
};

String.prototype.toShowUrl = function () {
    return this.replace('https://', '').replace('http://', '');
};

const ParsersEvaluation = {
    441: [
        {
            ParamName: 'Product',
            ExpertId: 252,
            ParamEvaluation: 6.5,
        },
        {
            ParamName: 'Market',
            ExpertId: 401,
            ParamEvaluation: 6.3,
        },
        {
            ParamName: 'Business model',
            ExpertId: 221,
            ParamEvaluation: 7,
        },
        {
            ParamName: 'Team',
            ExpertId: 421,
            ParamEvaluation: 6.3,
        },
    ],
    510: [
        {
            ParamName: 'Product',
            ExpertId: 467,
            OuterLinkExpert: 'https://www.linkedin.com/in/erezcohen/',
            OuterNameExpert: 'Erez Cohen',
            OuterDescriptionExpert: 'I am an agile coach and a software professional, fulfilling a variety of roles such as developer, project manager, R&D team leader and product manager. I have a strive to learning and constant improvement.\n' +
                'I am passionate about working with individuals, teams and organizations helping them achieve their goals while focusing on happy clients, product quality and organization productivity. ',
            OuterAvatarExpert: avatarExpert510Erez,
            ParamEvaluation: 6,
        },
        {
            ParamName: 'Market',
            ExpertId: 327,
            ParamEvaluation: 4.5,
        },
        {
            ParamName: 'Business model',
            ExpertId: null,
            OuterLinkExpert: 'https://www.linkedin.com/in/nickgraham-frsa/',
            OuterNameExpert: 'Nick Graham',
            OuterDescriptionExpert: 'A senior business change specialist with deep experience of innovating and delivering scalable, sustainable systems and culture change in highly complex environments. \n' +
                '\n' +
                'Accomplished programme leader with an integrated philosophy and skills application across strategy, technology, learning,development and business change. \n' +
                '\n' +
                'Proven track record of leading significant programmes/portfolios of projects managing complex stakeholder relationships across virtual and physical environments at all levels and settings.',
            OuterAvatarExpert: avatarExpert510Nick,
            ParamEvaluation: 6,
        },
        {
            ParamName: 'Team',
            ExpertId: 421,
            ParamEvaluation: 8.3,
        },
    ],
    586: [
        {
            ParamName: 'Product',
            ExpertId: 625,
            OuterLinkExpert: 'https://www.linkedin.com/in/shlahanau/',
            OuterNameExpert: 'Yury Shlahanau',
            OuterDescriptionExpert: 'In constant search for the best startups in the world',
            OuterAvatarExpert: 'https://api.rocketdao.io/file/4710/view?w=53',
            ParamEvaluation: 8.3,
        },
        {
            ParamName: 'Market',
            ExpertId: 625,
            OuterLinkExpert: 'https://www.linkedin.com/in/shlahanau/',
            OuterNameExpert: 'Yury Shlahanau',
            OuterDescriptionExpert: 'In constant search for the best startups in the world',
            OuterAvatarExpert: 'https://api.rocketdao.io/file/4710/view?w=53',
            ParamEvaluation: 8.25,
        },
        {
            ParamName: 'Business model',
            ExpertId: 625,
            OuterLinkExpert: 'https://www.linkedin.com/in/shlahanau/',
            OuterNameExpert: 'Yury Shlahanau',
            OuterDescriptionExpert: 'In constant search for the best startups in the world',
            OuterAvatarExpert: 'https://api.rocketdao.io/file/4710/view?w=53',
            ParamEvaluation: 7.5,
        },
        {
            ParamName: 'Team',
            ExpertId: 625,
            OuterLinkExpert: 'https://www.linkedin.com/in/shlahanau/',
            OuterNameExpert: 'Yury Shlahanau',
            OuterDescriptionExpert: 'In constant search for the best startups in the world',
            OuterAvatarExpert: 'https://api.rocketdao.io/file/4710/view?w=53',
            ParamEvaluation: 7.6,
        },
    ]
};

export class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startup: {},
            logo: avatar,
            user: props.user || {},
            bg: bg,
            listAchievements: [],
            experience: [],
            industries: [],
            gFocuses: [],
            id: props.id || props.match.params.id,
            activeAchivements: -1,
            activeImgSwiper: 0,
            valueInputRange: 300,
            openClosePopupInvest: false,
            pitch: [],
            showVideo: {},
            iLiked: false,
            iWish: false,
            comments: [],
            currentComment: '',
            showMoreAch: false,
            listExperts: [],
            showLogin: false,
            popupLoginProps: {},
            activeSticky: false,
            showLoginPopup: 'hide'
        };

        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.setActiveAch = this.setActiveAch.bind(this);
        this.ImgSwiper = this.ImgSwiper.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);
        this.OpenClosePopupInvest = this.OpenClosePopupInvest.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        localStorage.removeItem('referer_startup');
        this.setState({showLogin: false, popupLoginProps: {}});
    }

    OpenClosePopupInvest() {
        let user = this.props.user;

        if (user && user.investor && user.investor.isFinished) {

            this.setState({
                openClosePopupInvest: !this.state.openClosePopupInvest,
                showLogin: false,
                popupLoginProps: {}
            })
        } else {
            localStorage.setItem('referer_startup', `${history.location.pathname}${history.location.search}`);

            this.setState({
                popupLoginProps: {
                    title: "Only registered investors can join waitlists of the startups.",
                    text: "Please, login or create your investor profile (or continue registration).",
                    register: "/start_investor",
                    regBtn: "Create investor profile",
                    commandState: {
                        to: '/',
                        startup: this.props.id,
                        prevLocation: this.props.location ? this.props.location.pathname : null
                    }
                }
            }, () => {
                this.setState({showLogin: true});
            });
        }
    }

    onChangeRange = value => {
        this.setState({valueInputRange: value})
    }

    onChangeRange2 = (e) => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");

        if (value > 200000) value = 200000;

        if (value > 0 || !value) {
            this.setState({valueInputRange: Number(value)})
        }

    }

    ImgSwiper(e) {
        this.setState({activeImgSwiper: e})
    }

    scrollTo = (id) => {
        let element = document.getElementById(id);
        element.scrollIntoView({block: "center", inline: "nearest"});
    }

    _renderVideo(item) {
        return (
            <div className='image-gallery-image' style={{height: '550x', width: '100%', maxWidth: '5500px'}}>
                {
                    <div className='video-wrapper'>
                        <iframe
                            width='100%'
                            height='100%'
                            src={item.embedUrl}
                            frameBorder='0'
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                }
            </div>
        );
    }

    _renderImage(item) {
        return (
            <div className='image-gallery-image'>
                <div className='inner'>
                    <img src={item.original}/>
                </div>
            </div>
        );
    }

    componentDidMount() {
        let query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
        if (query.c === 'join_waitlist') {
            localStorage.removeItem('referer_startup');
            this.OpenClosePopupInvest();
        }

        if( !viewCounter() ) {
            if(this.props.id){

                const commandProps = {
                    to: '/',
                    id: this.props.id,
                    type: 'startup',
                    f: 'tpc'
                };

                localStorage.removeItem("rFrom");
                localStorage.removeItem("referer");
                localStorage.removeItem("referer_startup");

                localStorage.setItem("commandProps", JSON.stringify(commandProps));

                this.setState({showLoginPopup: 'content'});
            } else {
                this.setState({showLoginPopup: 'popup'});
            }
        }

        getListIndustries()
            .then((res) => {
                this.setState({industries: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })

        getListFocuses()
            .then((res) => {
                let arr = res.data.filter((item) => item.title != 'All');
                this.setState({gFocuses: arr})
            })
            .catch(error => {
                this.setState({loading: false})
            })


        axios.get(`/v2/projects/${this.state.id}/public`)
            .then(r => {
                r.data.valuationCap = Math.round((r.data.capitalSeeking / r.data.entityOffering) * 100);

                try {
                    let isFound = r.data.team.find((user) => {
                        if (user.User && user.User.id) {
                            return user.User.id == r.data.owner.id
                        }
                    })
                    if (!isFound) {
                        r.data.team = [...[{User: r.data.owner}, ...r.data.team]];
                    }
                } catch (e) {

                }

                const logo = r.data.logoFileId ? `${config.proxy}/file/${r.data.logoFileId}/view` : avatar;
                const bg = r.data.backgroundImageId ? `${config.proxy}/file/${r.data.backgroundImageId}/view` : bg;

                this.setState({startup: r.data});
                this.setState({logo: logo});
                this.setState({bg: bg});
                this.setState({iLiked: r.data.iLiked});
                this.setState({iWish: r.data.iWish});
                this.setState({valueInputRange: isExist(() => Number(r.data.iWish.invest)) || 300});


                let youtubePreview = '';
                let youtubeVideo = '';


                if (r.data.pitch) {
                    //const youtubeId = getQueryVariable(r.data.pitch.youtubeLink, 'v');
                    const youtubeId = youtubeParser(r.data.pitch.youtubeLink);
                    youtubePreview = getYoutubePreview(youtubeId);
                    youtubeVideo = getYoutubeEmbedURL(youtubeId);

                    let video = [{
                        original: youtubePreview,
                        thumbnail: youtubePreview,
                        embedUrl: youtubeVideo, //r.data.pitch.youtubeLink,
                        renderItem: this._renderVideo.bind(this)
                    }]

                    let files = [];

                    if (!r.data.pitch.youtubeLink) video = []

                    if (!r.data.pitch.files) files = []

                    if (r.data.pitch.files.length) {
                        files = r.data.pitch.files.map(file => {
                            return {
                                original: `${config.proxy}/file/${file.id}/view`,
                                thumbnail: `${config.proxy}/file/${file.id}/view`,
                                renderItem: this._renderImage.bind(this)
                            }
                        });
                    }

                    this.setState({pitch: [...video, ...files]});
                }

                try {

                    let obj = [];
                    Promise.all(
                        r.data.parameters.map(async (item, key) => {
                            let achievement = await getProjectAchievementsPublic(this.state.id, item.parameterId);
                            if (achievement.data.length) {
                                obj[item.parameterId] = {item: item, data: achievement.data};
                            }
                            return (achievement.data)
                        })
                    ).then(results => {
                        this.setState({listAchievements: obj});
                        this.setState({activeAchivements: parseInt(Object.keys(obj)[0])});
                    })
                } catch (e) {
                    console.log(e);
                }

            }).catch(e => {
            if ( isExist(() => e.response.status) === 404) {
                history.push('/404');
            }

            console.log({...e});
        })

        axios
            .get(`/v2/experts?isFinished=true&role=expert&take=200`)
            .then(r => {
                if (r.data.experts.length) {
                    this.setState({listExperts: [...this.state.listExperts, ...r.data.experts]});
                }

            })


        // this.stickyHeaderCart();
        if(process.env.NODE_ENV !== 'production'){
            window.addEventListener('scroll',this.stickyBG);
        }
    }
    componentWillUnmount() {
        if(process.env.NODE_ENV !== 'production') {
            window.removeEventListener('scroll', this.stickyBG);
        }
    }


    // stickyHeaderCart = () => {
    //     let timer = setInterval(() => {
    //
    //         if (window.pageYOffset > 300) {
    //             this.setState({activeSticky: true})
    //         } else {
    //             this.setState({activeSticky: false})
    //         }
    //         // clearInterval(timer);
    //     }, 500);
    //
    // };
    stickyBG = () => {
        if (window.pageYOffset > 174) {
            $('.card-wrapper').addClass('on-scroll');
            this.setState({activeSticky: true})
        } else {
            $('.card-wrapper').removeClass('on-scroll');
            this.setState({activeSticky: false})
        }
    };


    componentDidUpdate(prevProps) {
        if (this.props.id || isExist(() => this.props.match.params.id)) {
            if ((this.props.id && this.props.id !== this.state.id) || (isExist(() => this.props.match.params.id) && this.props.match.params.id !== this.state.id)) {
                this.setState({
                    startup: {},
                    listAchievements: [],
                    experience: [],
                    industries: [],
                    gFocuses: [],
                    activeAchivements: -1,
                    activeImgSwiper: 0,
                    valueInputRange: 300,
                    openClosePopupInvest: false,
                    pitch: [],
                    showVideo: {},
                    iLiked: false,
                    iWish: false,
                    comments: [],
                    showMoreAch: false,
                    currentComment: '',
                    ...this.props,
                    id: this.props.id || isExist(() => this.props.match.params.id)
                }, () => {
                    this.componentDidMount();
                })
            }
        }


        if (!this.state.user.id && this.props.user.id) {
            this.setState({user: this.props.user}, () => {
                let query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
                if (query.c === 'join_waitlist') {
                    localStorage.removeItem('referer_startup');
                    this.OpenClosePopupInvest();
                }
            })
        }
        // this.resizeImgGalery()
    }

    resizeImgGalery() {
        const galery = $('.image-gallery-slides');
        const item_img = $('.image-gallery-image');
        const img = $('.image-gallery-image').children('.inner');

        let oldWidth = 0;

        let width = galery.innerWidth() ? galery.innerWidth() : oldWidth;
        oldWidth = galery.innerWidth() ? galery.innerWidth() : oldWidth;
        let height = width / 1.6786;
        if (height / 1.6786 !== 0) {
            item_img.css({height: height + 'px'})
            img.css({height: height + 'px'})
        }
        $(window).resize(function () {
            width = galery.innerWidth() ? galery.innerWidth() : oldWidth;
            oldWidth = galery.innerWidth() ? galery.innerWidth() : oldWidth;
            height = width / 1.6786;
            if (height / 1.6786 !== 0) {
                item_img.css({height: height + 'px'})
                img.css({height: height + 'px'})
            }
        })
    }

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');
        if ($(e.currentTarget).parent().hasClass('active')) {
            $(e.currentTarget).parent().removeClass('active')
        } else {
            $(e.currentTarget).parent().addClass('active')
        }
    }

    setActiveAch = (id) => {
        this.setState({activeAchivements: id});
    }

    outputRange = (min, max) => {
        function r(n) {
            if (n >= 1000000) {
                return (n / 1000000).toFixed(2) + 'M';
            }

            if (n >= 1000) {
                return (n / 1000).toFixed(2) + 'K';
            }
        }

        return `${r(min)} - ${r(max)}`
    }

    startupLike = () => {
        const {startup} = this.state;
        if (this.props.user && this.props.user.id) {
            axios
                .post(`/v2/projects/${startup.id}/like`)
                .then(r => {
                    this.setState({iLiked: !this.state.iLiked});
                    this.setState({startup: {...this.state.startup, likes: r.data.likes}})
                    this.props.updateMenu({type: 'update'});
                    this.props.updateMarket({type: 'update'});
                }).catch(e => {
                this.props.updateMenu({type: 'update'});
            })
        } else {
            this.setState({
                popupLoginProps: {
                    commandState: {
                        to: window.location.pathname,
                        startup: this.props.id,
                        prevLocation: window.location.pathname
                    }
                }
            }, () => {
                this.setState({showLogin: true});
            });
        }
    }


    addToWishlist = () => {
        const {startup} = this.state;
        axios
            .post(`/v2/projects/${startup.id}/waitlist`, {sum: this.state.valueInputRange})
            .then(r => {

                console.log(r.data);

                this.setState({iWish: r.data});
                this.setState({valueInputRange: isExist(() => Number(r.data.invest)) || 300});
                this.OpenClosePopupInvest();

                axios.get(`/v2/projects/${this.state.id}/public`)
                    .then(r => {
                        r.data.valuationCap = Math.round((r.data.capitalSeeking / r.data.entityOffering) * 100);

                        this.setState({startup: r.data});
                    })
            })
    }

    countShare = () => {
        axios
            .put(`/v2/projects/${this.state.id}/share`)
            .then(r => {

            })
    }

    AmountEvaluation = (evaluation) => {
        let sum = 0;
        for (let i = 0; i < evaluation.length; i++) {
            sum += evaluation[i].ParamEvaluation;
        }
        return (sum / evaluation.length).toFixed(1);
    };

    getImgAlt = (title) => {
        let replaced = title.replace(/ /gi, '_');
        return replaced;
    };

    render() {
        const {startup, logo, listAchievements, industries, gFocuses, comments, user} = this.state;
        const paramsSwiper = {
            spaceBetween: 30
        }

        const sharedUrl = `http://startup.rocketdao.io?id=${startup.id}`


        const _avatar = startup.logoFileId ?
            <div className="photo-cart-expert"
                 style={{backgroundImage: `url(${`${config.proxy}/file/${startup.logoFileId}/view?W=200`})`}}>
                <img src={`${config.proxy}/file/${startup.logoFileId}/view?W=200`} alt={'startup ' + this.getImgAlt(startup.title)}/>
            </div>
            :
            createAvatar('photo-cart-expert', startup.title);


        if (!startup.id) {
            return (<React.Fragment>
                <Loader loaded={!!startup.id} isLocal={!!this.props.id}/>
            </React.Fragment>);
        } else {
            return (
                <React.Fragment>
                    <div className='card-wrapper'>
                        {!this.props.id && <MetaTags>
                            <title>{'Startup ' + this.getImgAlt(startup.title) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                            <meta property="og:title"
                                  content={startup.title + ' - Rocket DAO - uniting startups, investors and experts'}/>
                            <meta name="description" content={startup.description}/>
                            <meta property="og:description" content={startup.description}/>
                        </MetaTags>}

                        {this.state.showLoginPopup !== 'content' && <div>

                            <div className={`block-sticky-role-cart ${this.state.activeSticky ? 'active' : ''}`}>
                                <HeaderStickyRole

                                    name={startup.title}
                                    urlAvatar={startup.logoFileId ? `${config.proxy}/file/${startup.logoFileId}/view?w=80` : null}


                                    type={'startup'} // expert, fund, investor, startup
                                    titleType={null}
                                    description={null}
                                    location={null}
                                    languageOrSize={null}
                                    eventButton={null} // only startup
                                    customButton={((user && user.id !== startup.userId) || !user) && <ButtonBlack
                                        TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                        EventOnClick={null}
                                        paddingButton={'0'}
                                        disabled={true}
                                        onMouseEnter={() => this.setState({mouseEnter: true})}
                                        onMouseLeave={() => this.setState({mouseEnter: false})}
                                    /> || <EditMyPageButton id={startup.id} type={'startup'}/>}
                                    eventLike={this.startupLike} // only startup
                                    liked={this.state.iLiked}
                                    countLike={startup.likes}
                                    eventWaitList={() => this.OpenClosePopupInvest()} // only startup
                                    waited={this.state.iWish}
                                    CountWaitList={this.state.iWish ? this.state.iWish.invest.moneyFormat() : startup.invest_sum ? startup.invest_sum.moneyFormat() : 0}
                                    eventShareLN={() => this.countShare()} // only startupэ
                                    sharedUrlLN={sharedUrl}
                                    eventShareFB={() => this.countShare()} // only startup
                                    sharedUrlFB={sharedUrl}
                                    eventShareTW={() => this.countShare()} // only startup
                                    sharedUrlTW={sharedUrl}
                                />

                            </div>


                            <div className="block-background-expert">
                                <img src={this.state.bg} alt={'load'}/>
                            </div>
                            <div className="block-container-expert">
                                <div className="block-box-shadow-cart-startup"/>
                                <div className="block-cart-expert-mobile">
                                    <div className="block-top-cart-expert-mobile">
                                        <div className="block-avatar-name-top-cart-expert-mobile">
                                            <div className="block-left-top-cart-expert-mobile">
                                                {_avatar}
                                            </div>
                                            <div className="block-right-top-cart-expert-mobile">
                                                <h1 className="title-top-cart-expert-mobile">
                                                    {startup.title}
                                                    <VerificationIcon type={'startup'} className='sticky'/>
                                                </h1>
                                                <span className="round-prof startup">
                                                    Startup
                                                </span>
                                            </div>
                                        </div>
                                        <p className="description-top-cart-expert-mobile">
                                            {startup.shortDescription}
                                        </p>
                                    </div>
                                    <div className="block-body-cart-expert-mobile">
                                        <div className="block-list-button-cart-expert-mobile">
                                            {((user && user.id !== startup.userId) || !user) && <ButtonBlack
                                                TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                EventOnClick={null}
                                                paddingButton={'26'}
                                                disabled={true}
                                                onMouseEnter={() => this.setState({mouseEnter: true})}
                                                onMouseLeave={() => this.setState({mouseEnter: false})}
                                            /> || <EditMyPageButton id={startup.id} type={'startup'}/>}
                                            <div className="shell-margin-top-20"/>

                                            <div className="block-button-icon-title-description"
                                                 onClick={this.startupLike}>
                                                <div className="block-button-icon">
                                                    {this.state.iLiked ?
                                                        <i className="fas fa-heart"/> : <i className="far fa-heart"/>}
                                                </div>
                                                <div className="block-button-title">
                                                    <p className="title-button-title">
                                                        {this.state.iLiked ? 'Liked' : 'Like'}
                                                    </p>
                                                </div>
                                                <div className="block-button-description">
                                                    <p className="description-button-description">
                                                        {startup.likes}
                                                    </p>
                                                </div>
                                            </div>

                                            <StartupWaitButton onClick={this.OpenClosePopupInvest} active={!!startup.iWish} allInvest={ isExist(() => `$ ${startup.invest_sum.moneyFormat()}`) || ''} myInvest={ isExist(() => `$ ${this.state.iWish.invest.moneyFormat()}`)}/>


                                            {/*<div className="block-button-icon-title-description">*/}
                                                {/*<div className="block-button-title"*/}
                                                     {/*style={{padding: '0 0 0 16px', width: '87px'}}>*/}
                                                    {/*<p className="title-button-title">*/}
                                                        {/*You wish*/}
                                                    {/*</p>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-button-description">*/}
                                                    {/*<p className="description-button-description">*/}
                                                        {/*$ {this.state.iWish.invest.moneyFormat()}*/}
                                                    {/*</p>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}


                                            <div className="block-button-icon-title-description social">
                                                <div className="block-button-icon">
                                                    <i className="far fa-share-alt"/>
                                                </div>
                                                <div className="block-button-title">
                                                    <p className="title-button-title">
                                                        Share
                                                    </p>
                                                </div>
                                                <div className="block-button-description">
                                                    <p className="description-button-description">
                                                        {startup.shared}
                                                    </p>
                                                </div>
                                                <div className="block-button-social-icon-title-description">
                                                    {/*<a href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fstartup.rocketdao.io%3Fid%3D${startup.id}`} rel="noopener" target="_blank">*/}
                                                    {/*<div className="block-item-button-social">*/}
                                                    {/*<i className="fab fa-linkedin-in"/>*/}
                                                    {/*</div>*/}
                                                    {/*</a>*/}


                                                    <LinkedinShareButton
                                                        url={sharedUrl}
                                                    >
                                                        <div className="block-item-button-social"
                                                             onClick={() => this.countShare()}>
                                                            <i className="fab fa-linkedin-in"/>
                                                        </div>
                                                    </LinkedinShareButton>


                                                    <FacebookShareButton
                                                        url={sharedUrl}
                                                    >
                                                        <div className="block-item-button-social"
                                                             onClick={() => this.countShare()}>
                                                            <i className="fab fa-facebook"/>
                                                        </div>
                                                    </FacebookShareButton>
                                                    <TwitterShareButton
                                                        url={sharedUrl}
                                                    >
                                                        <div className="block-item-button-social"
                                                             onClick={() => this.countShare()}>
                                                            <i className="fab fa-twitter"/>
                                                        </div>
                                                    </TwitterShareButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-list-state-cart-expert-mobile">
                                            {startup.expert_score &&
                                            <div className="block-expert-evaluation">
                                                <p className="title-expert-evaluation">
                                                    {startup.expert_score}
                                                </p>
                                                <p className="description-expert-evaluation">
                                                    Expert evaluation score
                                                </p>
                                            </div>
                                            }

                                            {(startup.id === 441 || startup.id === 510) &&
                                            <div style={{marginTop: '16px', cursor: 'pointer'}} onClick={() => {
                                                this.scrollTo('evaluation-table')
                                            }}>
                                                <ExpertReviewScore
                                                    rewiewScore={[
                                                        this.AmountEvaluation(ParsersEvaluation[startup.id]),
                                                        ParsersEvaluation[startup.id][0].ParamEvaluation,
                                                        ParsersEvaluation[startup.id][1].ParamEvaluation,
                                                        ParsersEvaluation[startup.id][2].ParamEvaluation,
                                                        ParsersEvaluation[startup.id][3].ParamEvaluation,
                                                    ]}
                                                />
                                            </div>
                                            }

                                            {startup.fundraisingInstruments !== 'ICO' &&
                                            <div className="block-title-description-left-expert">
                                                {(startup.id === 441) &&
                                                <div>
                                                    <p className="title-l">Expert-defined round</p>
                                                    <p className="description-l ico">Seed</p>
                                                </div>
                                                }
                                                {(startup.id === 510) &&
                                                <div>
                                                    <p className="title-l">Expert-defined round</p>
                                                    <p className="description-l ico">Pre-Seed</p>
                                                </div>
                                                }
                                                {(startup.id !== 441 && startup.id !== 510) &&
                                                <div>
                                                    <p className="title-l">Investment round </p>
                                                    <p className="description-l ico">{startup.round}</p>
                                                </div>
                                                }
                                            </div>
                                            }

                                            {startup.fundraisingInstruments === 'ICO' &&
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Type of Fundraising </p>
                                                <p className="description-l ico">{startup.fundraisingInstruments}</p>
                                            </div>}


                                            {
                                                !startup.noTurnover ?
                                                    <div className="block-title-description-left-expert">
                                                        <p className="title-l">Startup turnover stage (USD)</p>
                                                        {startup.turnover && startup.turnover.max &&
                                                        <p className="description-l">$ {this.outputRange(startup.turnover.min, startup.turnover.max)}</p>
                                                        }

                                                    </div> : ''
                                            }
                                            <div className="block-title-description-left-expert">
                                                <p className="title-l">Business type</p>
                                                <p className="description-l">{startup.businessModelType.replace(/,/g, ', ')}</p>
                                            </div>

                                            {(startup.googlePlay || startup.appStore) &&
                                            <div className="block-list-button-install-app">
                                                {startup.appStore && <div className="block-button-install-app">
                                                    <a href={startup.appStore} target={'_blank'}>
                                                        <img src={App_Store} alt=""/>
                                                    </a>
                                                </div>}
                                                {startup.googlePlay && <div className="block-button-install-app">
                                                    <a href={startup.googlePlay} target={'_blank'}>
                                                        <img src={Google_Store} alt=""/>
                                                    </a>
                                                </div>}
                                            </div>}
                                        </div>
                                        <div className="block-list-info-cart-expert-mobile">
                                            {startup.webSite && <div className="block-skills-expert">
                                                <p className="title-block-expert">
                                                    Website: <a href={startup.webSite.toUrl()}
                                                                target="_blank">{startup.webSite.toShowUrl()}</a>
                                                </p>
                                            </div>}

                                            {startup.fundraisingInstruments === 'ICO' &&
                                            <div className="block-skills-expert">
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Total tokens number
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.tokensCount}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Tokens for sale
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.tokensForSale}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Hard Cap
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {Math.round(Number(startup.tokensCount) * Number(startup.tokensForSale) * Number(startup.tokensPrice) / 100)}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Token price
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.tokensPrice}
                                                    </p>
                                                </div>
                                            </div>
                                            ||
                                            <div className="block-skills-expert">
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Valuation cap:
                                                    </p>
                                                    <p className="description-block-expert">
                                                         {isExist(() => startup.valuationCap.moneyFormat()) ? `$ ${startup.valuationCap.moneyFormat()}` : '-'}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Capital seeking:
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {isExist(() => startup.capitalSeeking.moneyFormat()) ? `$ ${startup.capitalSeeking.moneyFormat()}` : '-'}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Equity offering:
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {isExist(() => startup.entityOffering) ? `${startup.entityOffering}%` : '-'}
                                                    </p>
                                                </div>
                                            </div>
                                            }

                                            {
                                                gFocuses.length ?
                                                    <div className="block-skills-expert">
                                                        <p className="title-block-expert">
                                                            Geographic focus
                                                        </p>
                                                        <p className="list-skills-expert">
                                                            {startup.marketFocus.map((id, k) => {
                                                                let found = gFocuses.find((a) => a.id === id);

                                                                return <span className="skill"
                                                                             key={k}>{found ? found.title : null}</span>
                                                            })}
                                                        </p>
                                                    </div> : ''
                                            }

                                            {industries.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Industries
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {startup.industry.map((id, k) => {
                                                            return <span
                                                                className="skill"
                                                                key={k}>{industries.find((a) => a.id === id).title}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                            }


                                            <div className="block-skills-expert">
                                                <div className="block-col-3-skills-expert" style={{width: '185px'}}>
                                                    <p className="title-block-expert">
                                                        Country of incorporation
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.countryOfIncorporation}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Team location
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.headquarters}
                                                    </p>
                                                </div>
                                                <div className="block-col-3-skills-expert">
                                                    <p className="title-block-expert">
                                                        Founding year
                                                    </p>
                                                    <p className="description-block-expert">
                                                        {startup.founded}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="block-cart-expert">
                                    <div className="block-left-cart-expert startup">
                                        <div className="block-photo-cart-expert">
                                            {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                            {_avatar}


                                        </div>
                                        {startup.expert_score &&
                                        <div className="block-expert-evaluation">
                                            <p className="title-expert-evaluation">
                                                <span>{startup.expert_score}</span>/10
                                            </p>
                                            <p className="description-expert-evaluation">
                                                Expert evaluation score
                                            </p>
                                            <div className="block-back-expert-evaluation">
                                                <p className="title-back-expert-evaluation">
                                                    This is the evaluation of the expert community.
                                                </p>
                                                <a href="https://rocketdao.io/DueD/Multy.pdf" target="_blank">See the Due
                                                    Diligence</a>
                                            </div>
                                        </div>
                                        }

                                        {(startup.id === 441 || startup.id === 510) &&
                                        <div style={{marginTop: '16px', cursor: 'pointer'}} onClick={() => {
                                            this.scrollTo('evaluation-table')
                                        }}>
                                            <ExpertReviewScore
                                                rewiewScore={[
                                                    this.AmountEvaluation(ParsersEvaluation[startup.id]),
                                                    ParsersEvaluation[startup.id][0].ParamEvaluation,
                                                    ParsersEvaluation[startup.id][1].ParamEvaluation,
                                                    ParsersEvaluation[startup.id][2].ParamEvaluation,
                                                    ParsersEvaluation[startup.id][3].ParamEvaluation,
                                                ]}
                                            />
                                        </div>
                                        }

                                        {startup.fundraisingInstruments !== 'ICO' &&
                                        <div className="block-title-description-left-expert">
                                            {(startup.id === 441) &&
                                            <div>
                                                <p className="title-l">Expert-defined round</p>
                                                <p className="description-l ico">Seed</p>
                                            </div>
                                            }
                                            {(startup.id === 510) &&
                                            <div>
                                                <p className="title-l">Expert-defined round</p>
                                                <p className="description-l ico">Pre-Seed</p>
                                            </div>
                                            }
                                            {(startup.id !== 441 && startup.id !== 510) &&
                                            <div>
                                                <p className="title-l">Investment round </p>
                                                <p className="description-l ico">{startup.round}</p>
                                            </div>
                                            }
                                        </div>}


                                        {startup.fundraisingInstruments === 'ICO' &&
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Type of Fundraising </p>
                                            <p className="description-l ico">{startup.fundraisingInstruments}</p>
                                        </div>}
                                        {
                                            !startup.noTurnover ?
                                                <div className="block-title-description-left-expert">
                                                    <p className="title-l">Startup turnover stage (USD)</p>
                                                    {startup.turnover && startup.turnover.max &&
                                                    <p className="description-l">$ {this.outputRange(startup.turnover.min, startup.turnover.max)}</p>
                                                    }

                                                </div> : ''
                                        }
                                        < div className="block-title-description-left-expert">
                                            <p className="title-l">Business type</p>
                                            <p className="description-l">{startup.businessModelType.replace(/,/g, ', ')}</p>
                                        </div>

                                        {(startup.googlePlay || startup.appStore) &&
                                        <div className="block-list-button-install-app">
                                            {startup.appStore && <div className="block-button-install-app">
                                                <a href={startup.appStore} target={'_blank'}>
                                                    <img src={App_Store} alt=""/>
                                                </a>
                                            </div>}
                                            {startup.googlePlay && <div className="block-button-install-app">
                                                <a href={startup.googlePlay} target={'_blank'}>
                                                    <img src={Google_Store} alt=""/>
                                                </a>
                                            </div>}
                                        </div>}

                                    </div>
                                    <div className="block-right-cart-expert startup">
                                        <div className="block-info-cart-expert">
                                            <div className="block-left-info-cart-expert">
                                                <p className="name-expert" title={startup.title}>
                                                    <h1>{startup.title}</h1>
                                                    <VerificationIcon type={'startup'} className='sticky'/>
                                                </p>
                                                <p className="title-prof">
                                            <span className="round-prof startup">
                                                Startup
                                            </span>
                                                </p>

                                                <div className="block-progress-block-startup">
                                                    <div className="block-item-progress-block-startup">
                                                        <p className="title-progress-block-startup">
                                                            Business Plan
                                                        </p>
                                                        <p className="description-progress-block-startup">
                                                            Available upon request
                                                        </p>

                                                        {/*<div className="block-button-progress-block-startup blue">*/}
                                                        {/*<p className="title-button-progress-block-startup">*/}
                                                        {/*See business plan*/}
                                                        {/*<i className="far fa-external-link-alt"/>*/}
                                                        {/*</p>*/}
                                                        {/*</div>*/}


                                                    </div>
                                                    <div className="block-line-progress-block-startup"/>
                                                    <div className="block-item-progress-block-startup">
                                                        <p className="title-progress-block-startup">
                                                            Due Diligence
                                                        </p>

                                                        {startup.id !== 445 ?
                                                            <p className="description-progress-block-startup">
                                                                Not completed yet
                                                            </p>
                                                            :
                                                            <div className="block-button-progress-block-startup green"
                                                                 onClick={() => handleDD(445)}>
                                                                <p className="title-button-progress-block-startup">
                                                                    Expert evaluation
                                                                    <i className="far fa-external-link-alt"/>
                                                                </p>
                                                            </div>
                                                        }


                                                    </div>
                                                    <div className="block-line-progress-block-startup"/>
                                                    <div className="block-item-progress-block-startup">
                                                        <p className="title-progress-block-startup">
                                                            Fundraising
                                                        </p>

                                                        {startup.id !== 403 ?
                                                            <p className="description-progress-block-startup">
                                                                Expert evaluation
                                                                is not conducted
                                                            </p>
                                                            :
                                                            <p className="description-progress-block-startup">
                                                                Haven't started yet
                                                            </p>
                                                        }

                                                    </div>
                                                </div>

                                                <p className="description-expert">
                                                    {startup.shortDescription}
                                                </p>
                                            </div>
                                            <div className="block-right-info-cart-expert">


                                                {((user && user.id !== startup.userId) || !user) && <ButtonBlack
                                                    TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                                    EventOnClick={null}
                                                    paddingButton={'26'}
                                                    disabled={true}
                                                    onMouseEnter={() => this.setState({mouseEnter: true})}
                                                    onMouseLeave={() => this.setState({mouseEnter: false})}
                                                /> || <EditMyPageButton id={startup.id} type={'startup'}/>}
                                                <div className="shell-margin-top-20"/>

                                                <div className="block-button-icon-title-description"
                                                     onClick={this.startupLike}>
                                                    <div className="block-button-icon">
                                                        {this.state.iLiked ?
                                                            <i className="fas fa-heart"/> : <i className="far fa-heart"/>}
                                                    </div>
                                                    <div className="block-button-title">
                                                        <p className="title-button-title">
                                                            {this.state.iLiked ? 'Liked' : 'Like'}
                                                        </p>
                                                    </div>
                                                    <div className="block-button-description">
                                                        <p className="description-button-description">
                                                            {startup.likes}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/*<div className="block-button-icon-title-description">*/}
                                                    {/*<div className="block-button-title"*/}
                                                         {/*style={{padding: '0 0 0 16px', width: '87px'}}>*/}
                                                        {/*<p className="title-button-title">*/}
                                                            {/*You wish*/}
                                                        {/*</p>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="block-button-description">*/}
                                                        {/*<p className="description-button-description">*/}
                                                            {/*$ {this.state.iWish.invest.moneyFormat()}*/}
                                                        {/*</p>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}

                                                <StartupWaitButton onClick={this.OpenClosePopupInvest} active={!!startup.iWish} allInvest={ isExist(() => `$ ${startup.invest_sum.moneyFormat()}`) || ''} myInvest={ isExist(() => `$ ${this.state.iWish.invest.moneyFormat()}`)}/>


                                                <div className="block-button-icon-title-description social">
                                                    <div className="block-button-icon">
                                                        <i className="far fa-share-alt"/>
                                                    </div>
                                                    <div className="block-button-title">
                                                        <p className="title-button-title">
                                                            Share
                                                        </p>
                                                    </div>
                                                    <div className="block-button-description">
                                                        <p className="description-button-description">
                                                            {startup.shared}
                                                        </p>
                                                    </div>
                                                    <div className="block-button-social-icon-title-description">
                                                        {/*<a href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fstartup.rocketdao.io%3Fid%3D${startup.id}`} rel="noopener" target="_blank">*/}
                                                        {/*<div className="block-item-button-social">*/}
                                                        {/*<i className="fab fa-linkedin-in"/>*/}
                                                        {/*</div>*/}
                                                        {/*</a>*/}


                                                        <LinkedinShareButton
                                                            url={sharedUrl}
                                                        >
                                                            <div className="block-item-button-social"
                                                                 onClick={() => this.countShare()}>
                                                                <i className="fab fa-linkedin-in"/>
                                                            </div>
                                                        </LinkedinShareButton>


                                                        <FacebookShareButton
                                                            url={sharedUrl}
                                                        >
                                                            <div className="block-item-button-social"
                                                                 onClick={() => this.countShare()}>
                                                                <i className="fab fa-facebook"/>
                                                            </div>
                                                        </FacebookShareButton>
                                                        <TwitterShareButton
                                                            url={sharedUrl}
                                                        >
                                                            <div className="block-item-button-social"
                                                                 onClick={() => this.countShare()}>
                                                                <i className="fab fa-twitter"/>
                                                            </div>
                                                        </TwitterShareButton>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        {startup.webSite && <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Website: <a href={startup.webSite.toUrl()}
                                                            target="_blank">{startup.webSite.toShowUrl()}</a>
                                            </p>
                                        </div>}

                                        {startup.fundraisingInstruments === 'ICO' &&
                                        <div className="block-skills-expert">
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Total tokens number
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.tokensCount}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Tokens for sale
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.tokensForSale}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Hard Cap
                                                </p>
                                                <p className="description-block-expert">
                                                    {Math.round(Number(startup.tokensCount) * Number(startup.tokensForSale) * Number(startup.tokensPrice) / 100)}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Token price
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.tokensPrice}
                                                </p>
                                            </div>
                                        </div>
                                        ||
                                        <div className="block-skills-expert">
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Valuation cap:
                                                </p>
                                                <p className="description-block-expert">
                                                   {isExist(() => startup.valuationCap.moneyFormat()) ? `$ ${startup.valuationCap.moneyFormat()}` : '-'}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Capital seeking:
                                                </p>
                                                <p className="description-block-expert">
                                                   {isExist(() => startup.capitalSeeking.moneyFormat()) ? `$ ${startup.capitalSeeking.moneyFormat()}` : '-'}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Equity offering:
                                                </p>
                                                <p className="description-block-expert">
                                                    {isExist(() => startup.entityOffering) ? `${startup.entityOffering}%` : '-'}
                                                </p>
                                            </div>
                                        </div>
                                        }

                                        {
                                            gFocuses.length ?
                                                <div className="block-skills-expert">
                                                    <p className="title-block-expert">
                                                        Geographic focus
                                                    </p>
                                                    <p className="list-skills-expert">
                                                        {startup.marketFocus.map((id, k) => {
                                                            let found = gFocuses.find((a) => a.id === id);

                                                            return <span className="skill"
                                                                         key={k}>{found ? found.title : null}</span>
                                                        })}
                                                    </p>
                                                </div> : ''
                                        }

                                        {industries.length ?
                                            <div className="block-skills-expert">
                                                <p className="title-block-expert" key={'title-block-expert-100'}>
                                                    Industries
                                                </p>
                                                <p className="list-skills-expert">
                                                    {startup.industry.map((id, k) => {
                                                        return <span
                                                            className="skill"
                                                            key={k}>{industries.find((a) => a.id === id).title}</span>
                                                    })}
                                                </p>
                                            </div> : ''
                                        }


                                        <div className="block-skills-expert">
                                            <div className="block-col-3-skills-expert" style={{width: '185px'}}>
                                                <p className="title-block-expert">
                                                    Country of incorporation
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.countryOfIncorporation}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Team location
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.headquarters}
                                                </p>
                                            </div>
                                            <div className="block-col-3-skills-expert">
                                                <p className="title-block-expert">
                                                    Founding year
                                                </p>
                                                <p className="description-block-expert">
                                                    {startup.founded}
                                                </p>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                                <div className="block-body-expert">
                                    {!!this.state.pitch.length &&
                                    <div className="block-swiper-startup">
                                        <ImageGallery
                                            items={this.state.pitch}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            lazyLoad={true}
                                        />
                                    </div>
                                    }


                                    <div className="block-super-description-startup">
                                        <div className="block-top-super-description-startup">
                                            <h2 className="title-top-super-description-startup">
                                                Product
                                                <span className='productStatus'>
                                                    {startup.currentStatus}
                                                </span>
                                            </h2>
                                            <p className="description-top-super-description-startup">
                                                {startup.description}
                                            </p>
                                        </div>
                                        <div className="block-body-super-description-startup">
                                            <div className="block-row-super-description-startup">
                                                <div className="block-left-row-super-description-startup">
                                                    <h2 className="title-super-description-startup">
                                                        Problem
                                                    </h2>
                                                    <p className="description-super-description-startup">
                                                        {startup.problem}
                                                    </p>
                                                </div>
                                                <div className="block-right-row-super-description-startup">
                                                    <h2 className="title-super-description-startup">
                                                        Solution
                                                    </h2>
                                                    <p className="description-super-description-startup">
                                                        {startup.solution}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="block-row-super-description-startup">
                                                <div className="block-left-row-super-description-startup">
                                                    <h2 className="title-super-description-startup">
                                                        Business model
                                                    </h2>
                                                    <p className="description-super-description-startup">
                                                        {startup.businessModel}
                                                    </p>
                                                </div>
                                                <div className="block-right-row-super-description-startup">
                                                    <h2 className="title-super-description-startup">
                                                        Market
                                                    </h2>
                                                    <p className="description-super-description-startup">
                                                        {startup.market}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Evaluation*/}


                                    {( (startup.id === 441 || startup.id === 510 || startup.id === 586) && isExist(() => this.state.listExperts.length) ) &&
                                    <div id='evaluation-table' className='margin-top-75 shell-margin-bottom-80'>
                                        <PreliminaryEvaluationTable
                                            FullVersion={true}
                                            startup={startup}
                                            Evaluation={ParsersEvaluation[startup.id]}
                                            user={user}
                                            listExperts={this.state.listExperts}
                                        />
                                    </div> ||
                                    <div
                                        className='row-m0 w-100 no-gutters block-get-preliminary-evaluation margin-top-75 shell-margin-bottom-80'>
                                        <div className='col'>
                                            <p className='title-block-body-team'>Preliminary evaluation</p>
                                            <div className='get-evaluation-desr color-rgba-88'>is conducted by Rocket DAO
                                                expert community
                                            </div>
                                        </div>
                                        <div className='col block-button-wrapper shell-margin-top-20'>
                                            <ButtonGreen
                                                TitleButton='Order preliminary evaluation'
                                                EventOnClick={() => {
                                                    window.open('https://docs.google.com/forms/d/e/1FAIpQLSeNUW9t-HJT4zWw3nRf87Yon1j0iPfqSdmIIahxLbQAu41ZVQ/viewform');
                                                }}
                                                paddingButton={'1'}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    }

                                    <div className='block-body-team'>
                                        {/*<div className='arrow-right'>*/}
                                        {/*<div className="arrow">*/}
                                        {/*<i className="far fa-chevron-down"/>*/}
                                        {/*</div>*/}
                                        {/*</div>*/}
                                        <p className="title-block-body-team">
                                            Total number of team members {startup.membersCount}
                                        </p>
                                        <p className="description-block-body-team" style={{marginBottom: '3px'}}>
                                            Registered on the platform: {startup.team.length}
                                            {/*<div className="show-all">*/}
                                            {/*Show all*/}
                                            {/*</div>*/}
                                        </p>
                                        <Swiper
                                            swiperOptions={{
                                                slidesPerView: 4,
                                                freeMode: true,
                                                breakpoints: {
                                                    // when window width is <= 320px
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 10
                                                    },
                                                    // when window width is <= 480px
                                                    480: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 20
                                                    },
                                                    // when window width is <= 640px
                                                    640: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 30
                                                    }
                                                }
                                            }}
                                            navigation={false}
                                            pagination={false}
                                        >
                                            {startup.team.map((member, k) => {
                                                return member.User && <Slide key={k}>
                                                    <div className='block-body-team-list no-link'>
                                                        <div className='content-box'>
                                                            {member.isFounder &&
                                                            <span className='label-is-founder'>founder</span>}

                                                            {
                                                                member.User.logoFileId
                                                                && <div className="list-avatar"
                                                                        style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                                                || <div
                                                                    className="list-avatar">{createAvatar('avatar', member.User.firstName)}</div>
                                                            }

                                                            <p className='list-user-name'>{member.User.firstName} {member.User.lastName}</p>
                                                            <p className='list-position'>{member.position}</p>
                                                            <div className='list-social'>
                                                                {member.User && member.User.facebook &&
                                                                <a href={member.User.facebook} target="_blank"><i
                                                                    className="fab fa-facebook"></i></a>
                                                                }
                                                                {member.User && member.User.linkedin &&
                                                                <a href={member.User.linkedin} target="_blank"><i
                                                                    className="fab fa-linkedin"></i></a>
                                                                }
                                                                {ISADMIN && member.telegram &&
                                                                <span><i
                                                                    className="fab fa-telegram"></i>{member.telegram}</span>
                                                                }
                                                                {ISADMIN && member.whatsapp &&
                                                                <span><i
                                                                    className="fab fa-whatsapp"></i>{member.whatsapp}</span>
                                                                }
                                                                {ISADMIN && member.facebook &&
                                                                <span><i
                                                                    className="fab fa-facebook"></i>{member.facebook}</span>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slide>
                                            })}
                                        </Swiper>
                                    </div>


                                    {!!listAchievements.length &&
                                    <div className="block-achievements-investor">
                                        <p className="title-achievements-investor">Achievements</p>
                                        <div className="block-list-tab-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements.map((v, i) => {
                                                    if (listAchievements[v.item.parameterId].data.length) {
                                                        return <TabsCounter
                                                            TitleTab={v.item.title}
                                                            TypeTab={'big'}
                                                            EventOnClick={() => this.setActiveAch(v.item.parameterId)}
                                                            active={v.item.parameterId === this.state.activeAchivements}
                                                            count={v.data.length}
                                                        />
                                                    }
                                                })
                                            }

                                        </div>

                                        <div className="block-list-achievements-investor">
                                            {
                                                this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(0, 6).map(v => {
                                                    return <div className="block-item-achievements-investor"
                                                                onClick={() => {
                                                                    window.open(v.link.toUrl())
                                                                }}>
                                                        <p className="title-item-achievements-investor">
                                                            {v.description}
                                                        </p>
                                                        <div
                                                            className="block-right-achievements-cart-share">
                                                            <a href={v.link.toUrl()}
                                                               rel="noopener noreferrer"
                                                               target="_blank"> <i
                                                                className="fas fa-share-square"/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                })
                                            }


                                            <div className="open-close-block">
                                                {
                                                    this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.slice(6, listAchievements[this.state.activeAchivements].data.length).map(v => {
                                                        return <div className="block-item-achievements-investor"
                                                                    onClick={() => {
                                                                        window.open(v.link.toUrl())
                                                                    }}>
                                                            <p className="title-item-achievements-investor">
                                                                {v.description}
                                                            </p>
                                                            <div
                                                                className="block-right-achievements-cart-share">
                                                                <a href={v.link.toUrl()}
                                                                   rel="noopener noreferrer"
                                                                   target="_blank"> <i
                                                                    className="fas fa-share-square"/>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>


                                            {this.state.activeAchivements >= 0 && listAchievements[this.state.activeAchivements] && listAchievements[this.state.activeAchivements].data.length > 6 &&
                                            <div style={{float: 'none', clear: 'both'}}
                                                 className="block-item-achievements-investor" onClick={(e) => {
                                                this.setState({showMoreAch: !this.state.showMoreAch});
                                                this.OpenCloseContentClick(e, '.open-close-block');

                                            }}>

                                                <p className="title-item-achievements-investor show">
                                                    {!this.state.showMoreAch && <span className="show-more">Show more <i
                                                        className="far fa-chevron-down"/></span>}
                                                    {this.state.showMoreAch &&
                                                    <span className="show-more">Show less <i className="far fa-chevron-up"/></span>}
                                                </p>
                                            </div>
                                            }
                                        </div>


                                    </div>
                                    }
                                </div>

                                <div className="block-social-link-expert block-background-gray111">


                                    {(startup.twitterLink || startup.facebookLink || startup.linkedInLink || startup.mediumLink || startup.telegramLink || !!startup.contacts.length) &&
                                    <p className="title-social-link">
                                        Follow us:

                                        {startup.twitterLink ? <a href={startup.twitterLink.toUrl()} target="_blank"><i
                                            className="fab fa-twitter"/></a> : ''}
                                        {startup.facebookLink ?
                                            <a href={startup.facebookLink.toUrl()} target="_blank"><i
                                                className="fab fa-facebook"/></a> : ''}
                                        {startup.linkedInLink ?
                                            <a href={startup.linkedInLink.toUrl()} target="_blank"><i
                                                className="fab fa-linkedin"/></a> : ''}
                                        {startup.mediumLink ? <a href={startup.mediumLink.toUrl()} target="_blank"><i
                                            className="fab fa-medium"/></a> : ''}
                                        {startup.telegramLink ?
                                            <a href={startup.telegramLink.toUrl()} target="_blank"><i
                                                className="fab fa-telegram"/></a> : ''}

                                        {startup.contacts.map((contact) => {
                                            let classn = 'fa fa-link';
                                            if (/twitter/.test(contact)) {
                                                classn = 'fa-twitter'
                                            }
                                            if (/facebook/.test(contact)) {
                                                classn = 'fa-facebook'
                                            }
                                            if (/linked/.test(contact)) {
                                                classn = 'fa-linkedin'
                                            }
                                            if (/medium/.test(contact)) {
                                                classn = 'fa-medium'
                                            }
                                            if (/telegram/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }
                                            if (/t.me/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }

                                            return <a href={contact.toUrl()} target="_blank"><i
                                                className={`fab ${classn}`}/></a>
                                        })}

                                        {/*{startup.twitterLink ? <a href={startup.twitterLink.toUrl()} target="_blank"><i*/}
                                        {/*className="fab fa-twitter"/></a> : ''}*/}
                                        {/*{startup.facebookLink ? <a href={startup.facebookLink.toUrl()} target="_blank"><i*/}
                                        {/*className="fab fa-facebook"/></a> : ''}*/}
                                        {/*{startup.linkedInLink ? <a href={startup.linkedInLink.toUrl()} target="_blank"><i*/}
                                        {/*className="fab fa-linkedin"/></a> : ''}*/}
                                        {/*{startup.mediumLink ? <a href={startup.mediumLink.toUrl()} target="_blank"><i*/}
                                        {/*className="fab fa-medium"/></a> : ''}*/}
                                        {/*{startup.telegramLink ? <a href={startup.telegramLink.toUrl()} target="_blank"><i*/}
                                        {/*className="fab fa-telegram"/></a> : ''}*/}
                                    </p>
                                    }

                                    <div className="block-list-investors">

                                        {!!startup.ProjectWaitlists.length &&
                                        <p className="title-investors">
                                            {startup.ProjectWaitlists.length} investors willing to invest
                                            ${startup.invest_sum ? startup.invest_sum.moneyFormat() : ''}
                                        </p>
                                        || <p className="title-investors"> Hm, there is nobody in the waitlist yet. Be the
                                            first to join! </p>
                                        }
                                        <div className="block-list-item-investors">

                                            {startup.ProjectWaitlists.map(item => {
                                                return !!item.User && <div className="block-item-investors" onClick={() => {
                                                    console.log(item);
                                                    window.open(`/investor/${item.User.roles[0].id}`);
                                                }}>
                                                    <div className="block-left-item-investors">
                                                        <div className="block-logo-item-investors"
                                                             style={{background: `url(${config.proxy}/file/${item.User.logoFileId}/view)`}}/>
                                                    </div>
                                                    <div className="block-right-item-investors">
                                                        <p className="title-item-investors">
                                                            {item.User.firstName} {item.User.lastName}
                                                        </p>
                                                        <p className="description-item-investors">
                                                            $ {item.invest.moneyFormat()}
                                                        </p>
                                                    </div>
                                                </div>
                                            })}

                                            {
                                                startup.ProjectWaitlists.length > 7 &&
                                                <div className="block-item-investors show">
                                                    <p className="title-show-more">Show more<i
                                                        className="far fa-chevron-down"/>
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                        {startup.iWish ?
                                            <ButtonBlack
                                                TitleButton={'You wish $ ' + startup.iWish.invest.moneyFormat()}
                                                paddingButton={'44'}
                                                EventOnClick={() => this.OpenClosePopupInvest()}
                                                disabled={false}
                                                loader={false}
                                            />
                                            :
                                            <ButtonBlack
                                                TitleButton={'Join the waitlist'}
                                                EventOnClick={() => this.OpenClosePopupInvest()}
                                                paddingButton={'44'}
                                                disabled={false}
                                                loader={false}
                                            />
                                        }
                                    </div>


                                    <p className="description-social-link">Registered {new Date(startup.createdAt).toShortFormat()}</p>
                                </div>

                                <Comments id={startup.id} user={user} type='startup' location={this.props.location}/>

                                <div className="shell-popup-expert-marketplace"
                                     style={this.state.openClosePopupInvest ? {display: 'block'} : {display: 'none'}}>
                                    <div className="block-popup-expert-marketplace">
                                        <p className="title-popup-expert-marketplace">
                                            How much would you like to invest*?
                                        </p>
                                        <div className="block-title-input-invest-popup-expert-marketplace">
                                            <p className="title-invest-popup-expert-marketplace">
                                                You would like to invest
                                            </p>
                                            <Input
                                                type={'text'}
                                                name={'valueInputRange'}
                                                value={this.state.valueInputRange}
                                                placeholder={''}
                                                onChange={this.onChangeRange2}
                                                error={''}
                                            />
                                        </div>

                                        <div className="block-input-range-popup-expert-marketplace">
                                            <InputRange
                                                minValue={100}
                                                maxValue={200000}
                                                step={100}
                                                value={this.state.valueInputRange}
                                                onChange={value => this.onChangeRange(value)}/>
                                            <p className="title-input-range min">$100</p>
                                            <p className="title-input-range max">$200 000</p>

                                        </div>

                                        <p className="description-popup-expert-marketplace">
                                            * This is not real invetment. It’s whitelist and the main aim is a showing how
                                            many investors would like to invest in this startup
                                        </p>
                                        <div className="block-button-popup-expert-marketplace">
                                            <ButtonGreen
                                                TitleButton={ this.state.iWish ? 'Update the sum' : 'Join the waitlist'}
                                                EventOnClick={() => this.addToWishlist()}
                                                paddingButton={'40'}
                                                disabled={false}
                                                loader={false}
                                            />
                                        </div>
                                        <div className="block-button-close-popup"
                                             onClick={() => this.OpenClosePopupInvest()}>
                                            <i className="fal fa-times"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>}

                        {this.state.showModalNR &&
                        <ModalNeedRegister
                            user={this.props.user}
                            roleNR={this.state.roleNR}
                            showModal={this.state.showModalNR}
                            handleCloseModal={() => this.setState({showModalNR: false})}
                        />
                        }

                        {this.state.showLoginPopup !== 'hide' && <LoginPopup
                            close={()=>{
                                this.setState({showLoginPopup:'hide'});
                                if(!localStorage.RocketToken){
                                    history.push('/');
                                }
                            }}
                            state={this.state.showLoginPopup}
                        />}

                        {this.state.showLogin &&
                        <PopupLogin
                            {...this.state.popupLoginProps}
                            user={this.props.user}
                            onClose={this.closePopup}/>
                        }
                    </div>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user || {},
    startup: state,
})

Main.propTypes = {
    // getExpert: PropTypes.func.isRequired
    // updateFund: PropTypes.func.isRequired,
    // getLastFund: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {updateMenu, tokenLogin, updateMarket})(Main)
const translation = {
  translation : {
      test_message: 'WeloCEM',
      item: "{{count}} item",
      item_plural: "{{count}} items",
      member: "{{count}} member",
      member_plural: "{{count}} members",
      results:'Результат',
      share:'Поделиться',

      viral_test:{
          okayButton:'Приятно познакомиться!',
          result_share_h3: 'Потрясающий результат!',
          result_share_h5: 'Поделитесь достижением с друзьями!',
          lang:'ru',
          questions: [
              'В каком из вариантов ниже вы узнаете свою команду?',
              'А кто ваш CEO, в таком случае?',
              'Вы их бережете?',
              'Как бы вы описали свою команду?',
              '',
              'Давайте, похвастайтесь, кого вам удалось собрать в своей команде?',
              'У вас уже есть клиенты? Их много?',
              'А вот то, над чем вы работаете - инновация?',
              '',
              'Поэтому вопрос: ваша проблема реальна?',
              'Где вы сейчас?',
              'Выберите индустрию(и), к которой относится ваш продукт:',
              'Соотнесите термин и определение. Только давайте без Google Search…',
              'Сколько лет рынку, на котором вы функционируете / скоро появитесь?',
              'Сколько "стоят" ваши клиенты?',
              'Что напоминает ваша техническая документация?',
              'Какие же меры вы предпринимаете?',
          ],
          answers:[
              [
                  {point:2, title:'Кучка энтузиастов, которая объединилась, чтобы изменить мир.'},
                  {point:4, title:'Один лидер, пару друзей, несколько родственников и один хороший парень.'},
                  {point:6, title:'Лидер-гик, лидер-предприниматель и несколько наемных профессионалов.'},
                  {point:8, title:'Есть тот, кто всем управляет, кто контролирует создание продукта, кто договаривается с партнерами, и наемные специалисты.'},
                  {point:10, title:'Мы крутые, нас много, у нас есть даже финансовый директор и HR.'}],
              [
                  {point:0, title:'Он просто хороший парень'},
                  {point:2, title:'У него конечно нет опыта, зато очень богатый папа'},
                  {point:4, title:'Он опытный - он уже сделал несколько не совсем удачных релизов.'},
                  {point:6, title:'Он опытный - он уже сделал минимум один успешный релиз'},
                  {point:10, title:'Он опытный - как специалист участвовал в создании всемирно известного продукта'},
                  {point:8, title:'Он опытный - он был линейным руководителем в крупной и успешной компании'}],
              [
                  {point:0, title:'У нас есть целый гараж, мы все туда помещаемся, а когда тепло у нас даже появляется солнечный свет'},
                  {point:2, title:'Ну мы самостоятельные ребята и работаем мы удаленно, иногда пересекаемся в коворкинге'},
                  {point:4, title:'У нас есть офис на 2-3 человека, работаем там по очереди или встречаемся у кого-нибудь дома'},
                  {point:6, title:'Мы снимаем неплохой офис в довольно экологичном и не сильно криминогенном районе города'},
                  {point:8, title:'У нас есть приличный офис в приличном квартале'},
                  {point:10, title:'У нас отличный офис в отличном квартале, все меры обеспечения безопасности приняты и задокументированы'}],
              [
                  {point:4, title:'У нас дружная команда технарей. У нас всегда тихо, но на праздники мы надеваем колпачки и обсыпаемся блестками.'},
                  {point:6, title:'У нас все по плану: как сказал CEO, так и будет'},
                  {point:8, title:'У нас разношерстная команда - есть гуманитарии,  есть технари, есть ребята \'все умею, все могу\'. '},
                  {point:8, title:'У нас в офисе поощряется креативность, периоды кропотливой работы перемежаются креативным шумом и гамом'},
                  {point:8, title:'Наша команда не моно и даже не би-гендерна, у нас работают представители всех рас и различных гендеров'}],
              [],
              [
                  {point:0, title:'Наша команда - это амбициозные гики из глубинки, которые хотят покорить весь мир'},
                  {point:2, title:'В нашей команде есть участники различных отраслевых конкурсов по их основному виду деятельности'},
                  {point:4, title:'В нашей команде есть победители различных отраслевых конкурсов по их основному виду деятельности'},
                  {point:6, title:'В нашей команде есть признанные отраслевые эксперты, обладатели различных отраслевых наград'},
                  {point:8, title:'В нашей команде есть отраслевые десижен-мейкеры'},
                  {point:10, title:'В нашей команде есть эксперты, чьи статьи публикуются в признанных отраслевых журналах'}],
              [
                  {point:2, title:'Я - главный и единственный пользователь своего продукта'},
                  {point:4, title:'Все мои 2 друга с удовольствием пользуются моим продуктом'},
                  {point:6, title:'Мы тестируем свой продукт и им бесплатно пользуется несколько десятков человек'},
                  {point:8, title:'У нас пошли продажи. Продукт купило почти сотня человек'},
                  {point:10, title:'У нас пошли продажи. Количество клиентов, которые покупают продукт растет в разы каждый месяц.'}],
              [
                  {point:2, title:'Мы создаем продукт, который взорвет этот мир, но пока про него никто не слышал'},
                  {point:4, title:'Мы делаем аналог того, что есть на рынке, только более удобный и качественный'},
                  {point:6, title:'Мы создаем продукт, который лучше аналогов, у нас уже есть последователи и пользователи'},
                  {point:8, title:'Мы создаем продукт, которого нет и который подорвет целую отрасль (как uber и отрасль такси), это доказывают первые продажи.'},
                  {point:10, title:'У нас абсолютно новый продукт, продажи которого только растут.'}],
              [],
              [
                  {point:0, title:'У нашего инвестора избыток денег, какая разница, что разрабатывать?'},
                  {point:4, title:'Был бы хороший продукт, а потребитель всегда найдется'},
                  {point:2, title:'Мы у рынка не спрашивали, мы сами часть рынка, и раз интересно нам, то будет интересно и рынку'},
                  {point:6, title:'Рынок еще даже не задумывается о необходимости нашего продукта, но мы завоюем этот мир'},
                  {point:8, title:'Мы показали прототип нашего продукта нескольким потенциальным пользователям и они остались в восторге'},
                  {point:10, title:'Мы отлично знаем проблему рынка, регулярно тестируем востребованность продукта, продажи растут'}],
              [
                  {point:0, title:'Есть идея, определена проблема и то, как мы ее будем решать'},
                  {point:2, title:'Мы описали концепцию продукта: ценностное предложение, функциональная и компонентная модели, архитектура решения.'},
                  {point:4, title:'Провели анализ готовности ЦА купить наш продукт и начать им пользоваться. Выпустили MVP, которое находится на стадии тестирования.'},
                  {point:6, title:'Мы уже опубликовали готовую версию продукт (опубликовали на сервере / блокчейне)'},
                  {point:8, title:'Наш продукт уже покупают (небольшое количество юзеров)'},
                  {point:10, title:'Наш продукт уже покупают (и юзеров очень много!)'}],
              [],
              ['EBITDA', 'OPEX', 'CAC','ARPPU', 'LTV', 'ARR'],
              [
                  {point:0, title:'У рынка есть возраст?!?!?! Хахаха, рынок же не человек!'},
                  {point:2, title:'Наш рынок еще не появился, мы своим продуктом сформируем НОВЫЙ рынок!'},
                  {point:4, title:'Наш рынок, конечно, потихоньку умирает, но на нем еще есть много незанятых ниш'},
                  {point:6, title:'У нас очень молодой и стремительно растущий рынок'},
                  {point:8, title:'Наш рынок созрел, нам придется драться за свое место под солнцем, и нас возбуждает эта мысль'}],
              [
                  {point:0, title:'В каком смысле "стоит"? Клиент не товар, чтобы им торговать.'},
                  {point:2, title:'К нам клиенты приходят сами, через органику. Откуда я знаю, кто, сколько и когда?'},
                  {point:4, title:'У нас есть бюджет на привлечение клиентов, вот столько и стоит. А как посчитать стоимость одного клиента мы не знаем.'},
                  {point:6, title:'Мы считаем среднюю стоимость клиента и даже можем ее назвать. Насколько она низкая или высокая относительно средней на рынке мы не знаем.'},
                  {point:8, title:'Мы знаем нашу стоимость "покупки" клиента. И она выше среднерыночной. Но мы над этим работаем.'},
                  {point:10, title:'У нас отстроена мощная система привлечения клиентов и каждый клиент обходится нам дешевле среднерыночной в том числе и за счет "органики".'}],
              [
                  {point:0, title:'Что такое техническая документация?'},
                  {point:2, title:'У нас нет никакой технической документации, мы же стартап! Мы и без этого знаем, что должны делать.'},
                  {point:6, title:'У нас есть техническая документация и напоминает она хаотичные заметки художника, но я не знаю, кто ее пишет и зачем.'},
                  {point:8, title:'Каждый специалист пишет свою документацию как ему нравится. У кого-то серьезные документы, а кто-то пишет понятные только им схемы.'},
                  {point:10, title:'Техническая документация - важный элемент проекта. Все документы разрабатываются и используются в рамках определенного стандарта.'}],
              [
                  {point:0, title:'Мы еще ничего не сделали, какая собственность?'},
                  {point:4, title:'У нас все на открытом коде, пусть воруют себе, мы все равно сделаем лучше.'},
                  {point:6, title:'У нас есть, что защищать, но команда надежная и мы пока не предпринимаем никаких специальных мер для защиты интеллектуальной собственности.'},
                  {point:8, title:'Мы знаем, что могут у нас украсть, и мы знаем, что делать, если это случится. Ничего более мы не делаем.'},
                  {point:10, title:'У нас зарегистрированы патент, товарный знак, копирайт и прочая интеллектуальная собственность.'}],
          ],
          jokes: [
              [
                  'А вы очень амбициозны!',
                  'Ну что я могу сказать, зато вы всегда находитесь в кругу родных и близких, разве не сказка?',
                  'Надо признать, это отличное сочетание.',
                  'А вы мне нравитесь.  Интересно, что вы ответите на следующие вопросы...',
                  'А вы точно стартап?!'],
              [
                  'Это как сын маминой подруги?',
                  'Надеюсь, папа вовлечен в процесс создания проекта!',
                  'Ну ничего-ничего. Опыт ведь приходит через осознание ошибок, все - впереди!',
                  'Вот это команде повезло!',
                  'Ой, не может быть, это Илон Маск?',
                  'А теперь он вот-вот станет создателем своего первого единорога! Классно же!'],
              [
                  'Друзья, у меня для вас плохие новости...',
                  'Фрилансеры, что с них взять...',
                  'Ну, как говорится, в тесноте, да не в обиде',
                  'Берегите друг друга! Надеюсь у вас переезд не за горами.',
                  'Да с вами можно иметь дело, приятно, люблю, когда у людей такой ответственный подход!',
                  'Всем бы стартапам так жить!'],
              [
                  'Вы знаете толк в развлечениях!',
                  'По-моему, авторитарный режим - это прошлый век. Вам так не кажется?',
                  'Горжусь вами!',
                  'А я тоже за творчество - так держать!',
                  'Подниму в вашу честь многоцветный флаг!'],
              'Ну что ж, наша служба тех поддержки свяжется с вами ровно через 7,5 млн лет!',
              [
                  'Ну что ж, ребята, только вперед!',
                  'Да вы видать эксперт в поиске талантов!',
                  'Собрали вокруг себя звезд, похвально!',
                  'И как вам удалось собрать столь великие разумы?',
                  'Наверное весь ваш Burn Rate приходится на их зарплаты? Надеюсь конечно, что это не так...',
                  'Генерация нового знания - вот основа развития! Уж я то знаю...'],
              [
                  'Тоже неплохо, видимо спрос есть',
                  'Вам очень повезло иметь таких преданных товарищей!',
                  'Похоже на crowd QA. Это так?',
                  'Растете на глазах!',
                  'Скоро переплюнете Facebook с такой динамикой!'],
              [
                  'Ничего-ничего, залог хорошего маркетинга - хороший продукт. Еще завоюете!',
                  'Что ж, ведь нет предела совершенству.',
                  'Да с вами опасно конкурировать!',
                  'Остается пожелать вам только успехов!',
                  'Уже не терпится с вами лично познакомиться, знаете ли!'],
              [],
              [
                  'Ой, а может поделитесь контактами инвестора?',
                  'Ну, оптимизм продлевает жизнь :)',
                  'Поосторожней там с субъективизмом.',
                  'Это называется латентные потребности, отличное направление, между прочим.',
                  'Lean Startup в действии! Супер!',
                  'Ребята, это просто 10/10!'],
              [
                  'Начало положено - это главное',
                  'Шагаете семимильными шагами!',
                  'Приятно осознавать, что с нами работают такие серьезные команды!',
                  'Держитесь! Начинается самое интересное!',
                  'А трэкшн свой можете показать?',
                  'Только не останавливайтесь, я вас прошу!'],
              'Отличный выбор, думаю мы ещё не раз о вас услышим!',
              [
                  'Ну вот теперь вы знаете, как должно быть, больше точно не ошибетесь!',
                  'Наверное в школе вы были отличником!'],
              [
                  'Ну, тут ошибочка конечно вышла в вашем мировоззрении.',
                  'Да прибудет с вами сила, друзья мои!',
                  'Будем надеяться на лучшее.','Тогда - все в ваших руках.',
                  'Ууух, вот это запал, если что, вы же знаете, к кому обращаться за помощью?'],
              [
                  'Вполне себе человеколюбивая позиция :)',
                  'Про аналитику вы, значит, не слышали?..',
                  'Ну бюджет выделяете на маркетинг, уже неплохо конечно...',
                  'А ведь это может стать вашим конкурентным преимуществом, задумайтесь...',
                  'Не сдавайтесь только!',
                  'Никакой вы уже не стартап, признавайтесь!'],
              [
                  'Ну ребята, ну вы чего...',
                  'Обсудим это через год-два?',
                  'Зря вы так, знаете ли!',
                  'Задатки системности - это очень хорошо!',
                  'Да к вам просто не возможно придраться!'],
              [
                  'Видимо никакая...',
                  'Есть что-то чарующее в этом open source...',
                  'Хотите сказать, никаких  NDA? Ну это ваше право конечно ...',
                  'Понимать риски - первый шаг на пути к их минимизации!',
                  'За вас я совершенно спокоен!'],
          ],
          representation_text:`Приветствую! Я - Думатель, второй по производительности за всё существование времени и Вселенной суперкомпьютер, созданный сверхразумной командой Rocket DAO специально, чтобы находить ответы на вопросы стартаперов.`,
          thinker_text: [
              ['Когда будете знать вопрос, то поймете и ответ… \nНачнем мы конечно же с простого.', 'Все мы знаем, что успех стартапа примерно на 30% зависит от людей, которые к нему присоединились.'],
              'СЕО - он ведь душа и главная движущая сила любого проекта, согласны?',
              'Ваши люди - ваше все.',
              'In varietate concordia (с лат. — «единство в многообразии»), да?',
              'Быть может вы хотите задать мне какой-то вопрос? (Кряхтит)',
              'Когда-то слышал миф, якобы гении притягивают гениев. Поэтому вопрос!',
              'А сейчас вопрос будет совсем не шуточный.',
              'Не мне вам рассказывать про важность уникальности продукта и все такое. Но!',
              'Так, время отдыха! Хотите получить от меня какую-нибудь мудрость?',
              'Есть такая концепция - Jobs-to-be-done. Она говорит о том, что пользователи "нанимают" продукт для решения какой-то своей конкретной проблемы.',
              'Каждый продукт в своем развитии проходит ряд последовательных этапов.',
              'Цена стартапа и его успешность зачастую зависят от рыночных трендов. А вы знаете, в какой отрасли можно попасть на волну хайпа?',
              'Так-так, а теперь проверим ваши знания финансовых и маркетинговых метрик :) Только без Google Search, договорились?',
              'Мы же тут с вами серьезные люди, верно? Сейчас вопрос будет еще сложнее предыдущих...',
              'А теперь поговорим про Customer Acquisition Cost. ',
              'Осталось всего парочку вопросов, обещаю!',
              'Вы же знаете, насколько важно защищать свою интеллектуальную собственность?',
          ],
          choicelist_questions:[
              {answer:2, title:'Итоговая сумма всех маркетинговых усилий, которые потребовались для привлечения одного нового клиента.'},
              {answer:0, title:'Прибыль до вычета расходов по выплате процентов, налогов, износа и начисленной амортизации.'},
              {answer:4, title:'Метрика, которая показывает, сколько денег средний новый пользователь заплатит за все время использования продукта.'},
              {answer:3, title:'Средняя выручка с 1 платящего пользователя.'},
              {answer:5, title:'Нормализованные годовые поступления в течение срока подписки (действия лицензии). Такие платежи не включают единовременные/ разовые выплаты.'},
              {answer:1, title:'Повседневные затраты компании на ведение бизнеса, производства товаров и услуг, состоит из расходов на поддержку офиса, аренды оборудования, аренды офиса, затрат HR менеджеров на тимбилдинговые активности, постоянных офисных нужд, затрат на soft и на реализацию маркетинговых кампаний.'},
          ],
          choicelist_title:'Вот вам тест: прочитайте определение, выберите описанную ниже метрику.',
          choicelist_timeout:['Не верно', 'Верно!', 'Следующий вопрос через'],
          results:[
              {
                  name:'Растущий',
                  type:'growing',
                  stage:'Идея',
                  cost:'до $500 тыс',
                  investment:'до $20 тыс',
                  descr:'В народе ваш этап инвестирования называется просто и понятно “Идея”. Инвестиции вам лучше искать у FFF, ведь кто, если не друзья, родственники и ярые фанаты вашей концепции вложатся на такой рискованной стадии. Как вариант - краудфандинговые площадки. А может повезет убедить какого-нибудь бизнес-ангела. Но сейчас вы сможете привлечь не более 20 тыс долларов на свое развитие. В любом случае, вы молодцы, раз решились на создание собственного проекта, так держать.',
              },
              {
                  name:'Окрыленный',
                  type:'winged',
                  stage:'Pre-Seed',
                  cost:'$500 тыс - 1 млн',
                  investment:'$20 - 100 тыс',
                  descr:'Step by step и вы уже на Pre-Seed стадии! Класс! Средний чек на этом этапе составляет порядка 20-100 тыс долларов. Можно смело искать поддержки у бизнес ангелов и активно работать на mvp, участвовать в конференциях, заявлять о себе миру и получать обратную связь от рынка. Уделите особое внимание изучению рынка и формированию крепкой, мотивированной команды.',
              },
              {
                  name:'Мудрый',
                  type:'wise',
                  stage:'Seed',
                  cost:'$1 - 2,5 млн',
                  investment:'$100 - 500 тыс',
                  descr:'Вероятнее всего вы уже на Seed стадии. Сейчас средний чек инвестиций, на который вы можете рассчитывать - от 100 тыс до 500 тыс долларов. Соответственно, ваша рыночная цена равна произведению инвестиций, которые вы хотите привлечь, на долю, которую в вашем проекте получит инвестор. Ваши инвесторы сейчас: бизнес ангелы, фонды ранних стадий. На самом деле это один из сложнейших этапов, потому что это период валидации бизнес модели и первого трекшена. Поэтому очень актуальна поддержка акселераторов, коворкингов, инкубаторов и других элементов венчурной экосистемы.',
              },
              {
                  name:'Решительный',
                  type:'certain',
                  stage:'Раунд А',
                  cost:'$5-15 млн',
                  investment:'$1-5 млн',
                  descr:'Проверив и утвердив бизнес-модель, продемонстрировав уверенный трекшн и опыт работы с клиентами, вы постепенно превращаетесь в зрелую компанию и уже выходите на раунд А. У вас есть все для привлечения инвестиций от венчурных фондов и полной упаковки проекта, клиенты, технологии, команда, продажи. Средний чек инвестиций, на который вы сейчас можете претендовать - от 1 до 5 млн долларов. Уделите особое внимание рациональной организации маркетинговой кампании и оптимизации менеджмента внутри команды.',
              },
              {
                  name:'Уверенный',
                  type:'resolute',
                  stage:'Раунд А',
                  cost:'$15+ млн',
                  investment:'$15+ млн',
                  descr: 'Классические венчурные, private equity фонды - в вашем распоряжении, пора привлекать международный капитал и глобально масштабироваться. Теперь вы можете рассчитывать на инвестиции в размере от 10 млн долларов. Поработайте над топ-менеджментом, улучшением конкурентных позиций и стимулировании спроса на ваш продукт. Что тут еще сказать. Вы уже в бизнесе, в большом бизнесе. Проделан большой и сложный путь. И вы с ним справились. Вы уже наш герой.',
              },
          ],
          results_title:['Мой стартап', 'Стадия', 'Стоимость', 'Инвестиции', 'Пройти тест ещё раз', 'Хочешь развиваться дальше?', 'Покажи свой стартап ивесторам и экспертам!'],
          user_question:['Задавайте!','Ваш вопрос','Мой вопрос готов','Спасибо, воздержусь','Буду ждать ответа'],
          break_time:['Да!','Общеизвестно, что на самом деле часто всё бывает не так, как кажется.','Интересно...'],
          choose_character:['Кто вы сегодня?', 'Начало впечатляет'],
          number_of_answer_title:'Столько человек прошли тест:',
          start_test_title:['Сколько стоит','ваш стартап?','Готовы узнать ответ?','Да!'],
          test_loader:['Анализ ответов стартапа', 'Непростая задача', 'Группа (18)', 'Ах да, думаю, сейчас самое время нам наконец познакомиться, прежде, чем я раскрою все карты.', 'Ух, не терпится уже показать результаты!'],
          next:'Продолжаем',
          getBucks:['Забери $6 000 ', 'на развитие проекта'],
          getBucksPopup:{
              title:'Что дальше?',
              descr:'Выполните пару несложных действий:',
              button:'Приступить',
              steps:[
                  {position: 1, title:'Создайте профиль своего стартапа', descr:'Зарегистрируйте проект на Rocket DAO, представьтесь сообществу.'},
                  {position: 2, title:'Заполните оценочную форму', descr:'Ответьте на вопросы от экспертов, опишите продукт, бизнес модель, рынок и команду.'},
                  {position: 3, title:'Наслаждайтесь результатом!', descr:'Получите отчет с оценкой, отзывами и рекомендациями экспертов.'},
              ],
              finalStep:{title:'Получите пакет услуг на $6 000', descr:' - Консультации юристов, \n - Дизайн полного цикла, \n - Разработка MVP,\n - Разработка ПО,\n - Видео-производство.'}
          },
          NextButton:['Хорошо.', 'Спасибо!', 'Ок.', 'Окей.', 'Все в порядке.', 'Славно.', 'Чудесно!', 'Приятно!', 'Прекрасно!', 'Вау!', 'Спа-си-бо!']
      }
  }
};

export default translation;
import React, {Suspense, useContext} from 'react';
import ButtonBlue from "../../../../controls3.0/button/ButtonBlue";
import "./style.css"
import {getMyProject2, updateProject} from "../../../../../actions/Project";
import {editInvestor, tokenLogin, editExpert, updateUser} from "../../../../../actions/Login";
import {updateFund, updateAngelNetwork} from "../../../../../actions/Fund";

import {showMessage} from "../../../../../utils/showMessage";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {pitchProject} from '../../../../../actions/Project';
import {Scrollbars} from 'react-custom-scrollbars';
// import CustomScroll from 'react-custom-scroll';

import axios from "axios";
import {getMyFund} from '../../../../../actions/Fund';
import Loader from '../../../../test-controls/preloader/preloader';
import {putProfileSettings, putProfile} from "../../../../../actions/Profile";
import {updateMenu} from "../../../../../actions/menu";
import {getMyAccelerator, updateAccelerator2} from "../../../../../actions/Accelerator";

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , data: {}
            , errors: {}
            , DynamicComponent: null
        };

        this.changeDynamicComponent(this.state.step, this.state.object)
    }

    async changeDynamicComponent(step, object = {}){
        const DynamicComponent = await import(`../${this.state.type}/${step}`);

        this.setState({
            DynamicComponent: DynamicComponent.default,
            step,
            object: object
        });
    }

    save = async () => {
        if(this.state.type === 'startup'){
            if(this.state.data.pitch){
                await pitchProject(this.props.data.id, this.state.data.pitch).then(r => {
                    this.props.close();
                    //showMessage({type: 'info', data: 'saved'})
                });
                //return 1;
            }

            const response = await this.props.updateProject(this.props.data.id, this.state.data.pitch ? {} : this.state.data);
            if(response.status === 200){
                if(this.state.object.next){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();
                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }
        }

        if(this.state.type === 'investor'){
            const response = await this.props.editInvestor(this.state.data);

            if(response.status === 200){
                if(this.state.object.next){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();

                    if(localStorage.RocketToken){
                        this.props.tokenLogin(localStorage.RocketToken);
                    }

                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }
        }

        if(this.state.type === 'expert'){

            if(this.state.data.checks){
                await putProfile(this.props.user.id ,{email2: this.state.data.checks.email2})
                await putProfileSettings(this.state.data.checks)
                    .then(res => {
                        this.props.close();

                        if(localStorage.RocketToken){
                            this.props.tokenLogin(localStorage.RocketToken);
                        }

                        return res;
                    })
                    .catch(err => {

                    })

                this.setState({data: {}})
            }

            const response = await this.props.editExpert(this.state.data);

            if(response.status === 200){
                if(this.state.object.next){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();

                    if(localStorage.RocketToken){
                        this.props.tokenLogin(localStorage.RocketToken);
                    }

                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }


        }

        if(this.state.type === 'fund'){
            if(this.state.data.proof){
                await axios
                    .post(`/v2/funds/${this.props.data.id}/proof`, this.state.data.proof)
                    .then(res => {
                        return res;
                    })
                    .catch(e => {
                        return e;
                    })
            }

            const response = await this.props.updateFund(this.state.data, this.props.data.id);
            if(response.status === 200){
                if(this.state.object.next && this.state.data.parameters.length){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();
                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }
        }

        if(this.state.type === 'accelerator'){
            if(this.state.data.proof){
                await axios
                    .post(`/v2/accelerator/${this.props.data.id}/proof`, this.state.data.proof)
                    .then(res => {
                        return res;
                    })
                    .catch(e => {
                        return e;
                    })
            }

            const response = await this.props.updateAccelerator2(this.state.data, this.props.data.id);
            if(response.status === 200){
                if(this.state.object.next && this.state.data.parameters.length){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();
                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }
        }

        if(this.state.type === 'angel_network'){
            if(this.state.data.proof){
                await axios
                    .post(`/v2/angels/${this.props.data.id}/proof`, this.state.data.proof)
                    .then(res => {
                        return res;
                    })
                    .catch(e => {
                        return e;
                    })
            }

            const response = await this.props.updateAngelNetwork(this.state.data, this.props.data.id);
            if(response.status === 200){
                if(this.state.object.next && this.state.data.parameters.length){
                    const steps = await import(`../../../steps/${this.state.type}`);
                    this.changeDynamicComponent(this.state.object.next, steps.default[this.state.object.next])
                } else {
                    this.props.close();
                    showMessage({type: 'info', data: 'saved'})
                }
            } else {
                this.setState({errors: response.response})
            }
        }

        if(this.state.type === 'user'){
            const response = await this.props.updateUser(this.state.data, this.props.data.id);
            if(response.status === 200){
                this.props.updateMenu({type: 'update'});
                this.props.close();
                showMessage({type: 'info', data: 'saved'})

            } else {
                this.setState({errors: response.response})
            }


        }
    }

    prev = async () => {
        if(this.state.object.prev){
            const steps = await import(`../../../steps/${this.state.type}`);
            this.changeDynamicComponent(this.state.object.prev, steps.default[this.state.object.prev])
        }
    };

    handler = (data, errors = {}, validate = true) => {
        if(data.notifyType === 'pitch_pdf'){

            if(this.state.type === 'startup') {
                if (this.state.data.pitch) {
                     pitchProject(this.props.data.id, data.pitch).then(r => {
                        this.reset();
                    });
                }
            }

        } else {
            this.setState({data, errors, validate})
        }
    }

    reset = () => {
        if(this.state.type === 'investor' || this.state.type === 'expert'){
            this.props.tokenLogin(localStorage.RocketToken)
        }
        if(this.state.type === 'startup'){
            this.props.getMyProject2(this.props.data.id)
        }
        if(this.state.type === 'fund' || this.state.type === 'angel_network'){
            this.props.getMyFund(this.props.data.id);
        }

        if(this.state.type === 'accelerator'){
            this.props.getMyAccelerator(this.props.data.id);
        }

        if(this.state.type === 'user'){
            this.props.updateMenu({type: 'update'});
        }
    }

    resize = () => this.forceUpdate();

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    isMobile = () => {
        return window.innerWidth < 756;
    };

    render() {
        const { DynamicComponent } = this.state;

        let autoHeightMaxR = 90;

        try{
            if(this.isMobile()){
                autoHeightMaxR += 50;
            } else {
                autoHeightMaxR += 173;
            }

        } catch (e) {

        }

        return (
            <div className={`profile-edit-popup-block`}>
                <div className={`profile-edit-popup-inner-block ${this.props.object.class}`}>
                    <div className='profile-edit-popup-header-block shell-margin-bottom-15'>
                        {this.state.object.prev && <div className='title' onClick={this.prev}>
                            <div className="block-button-circle" style={{/*cursor: 'unset'*/}}><i className="fal fa-long-arrow-left"/></div>
                            {this.state.object.title}
                        </div>
                        || <div className='title'>
                            {this.state.object.title}
                        </div>
                        }

                        <div className='close-title' onClick={ () => {this.reset(); this.props.close();}}>
                            <div className='close'/>
                        </div>
                    </div>

                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={parseInt(window.innerHeight - autoHeightMaxR)}>
                            {
                                DynamicComponent
                                && <div className="shell-scroll-component"><DynamicComponent data={this.props.data} errors={this.state.errors} parentCallback={this.handler}/></div>
                                || <Loader isLocal={true}/>
                            }
                        </Scrollbars>

                    <div className='profile-edit-popup-footer new-mar-20-bottom new-pad-35-right'>
                        <div className='button-reset'>
                            { !this.state.object.noReset && <ButtonBlue TitleButton={'Reset'}
                                                                      EventOnClick={() => this.reset()}
                                                                      paddingButton={'20'}
                                                                      disabled={false}
                                                                      loader={false}/>
                            }
                        </div>
                        <ButtonBlue TitleButton={this.state.object.applyText || 'Apply changes'}
                                    EventOnClick={() => {this.save()} }
                                    paddingButton={'20'}
                                    disabled={Object.keys(this.state.errors).length || this.state.validate === false}
                                    loader={false}/>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    //project: state.project
})

Popup.propTypes = {
    getMyProject2: PropTypes.func.isRequired,
    tokenLogin: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {updateProject, editInvestor, editExpert, getMyProject2, tokenLogin, updateFund, getMyFund, updateMenu, updateUser, updateAngelNetwork, getMyAccelerator, updateAccelerator2})(Popup)
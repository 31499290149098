import React from 'react';

import './style.css'
import QuestionReview from "./QuestionReview";
import $ from "jquery";
import packageJSON from "../../../../package.json";
import {createAvatar} from "../../user/createAvatar";
import {isExist} from "../../../utils/helper";


export default class ExpertReview extends React.Component {
    state = {
        openedEval: 'Product',
        showWork: false
    };

    evalClick = (evalParam) => {
        this.setState({openedEval: evalParam});
    };

    OpenCloseContentClick(e, classBlock) {
        $(e.currentTarget).parent().children(classBlock).toggle('normal');

        if (classBlock === '.open-close-block') {
            this.setState({showWork: !this.state.showWork})
        }

    }

    QuestionReviewList(ValueProps) {
        let list = ValueProps.map((item, key) => {
            let QAFilteredList =  item.QAList;
            if (item.ValueName === 'Product lifecycle' && this.props.StartupId == 441) QAFilteredList.answer = '';
            return <div key={key} className='w-100'>
                <QuestionReview
                    ValueName={item.ValueName}
                    ValueEval={item.ValueEval}
                    QAList={QAFilteredList}
                    expertRewiew={item.expertRewiew}
                />
            </div>
        });
        return list;
    }

    ParamScore = (ValueProps) => {
        let sum = 0;
        for (let i = 0; i < ValueProps.length; i++) {
            sum += ValueProps[i].ValueEval;
        }
        return (sum / ValueProps.length).toFixed(1);
    };

    getAvatar(item) {
        if (this.props.ExpertAvatar) {
            return <div className="col block-avatar"
                        style={{background: `url(${this.props.ExpertAvatar}) no-repeat`, height: '100%'}}/>
        }
        if (item) {
            const {user} = item;
            const _avatar = isExist(() => user.logoFileId) ?
                <div className="avatar cursor-pointer"
                     style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${user.logoFileId}/view`})`}}/>
                :
                createAvatar('avatar', isExist(() => user.firstName));
            return _avatar;
        }
    }

    openExpert() {
        window.open(`/expert/${this.props.Expert.id}`, '_blank')
    }

    render() {
        let {
            Expert,
            PosSides,
            NegSides,
            Recommend,
            ValueProps,
            OpenedParam,
            TextReview,
            ExpertAvatar
        } = this.props;

        let user = {};

        if(Expert.user){
            return (
                <div className='block-preliminary-evaluation-view-inner'>

                    <div className='row-b block-parameter-review no-gutters w-100 margin-top-25'>
                        <div className='col-3 w-100 rewiew-text color-rgba-88 font-weight-300'>Review</div>
                        <div className='col-9 block-param-total-score'>
                            <div className='float-right block-info-icon cursor-pointer'>
                                        <span className="new-black-tool-tip"
                                              data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups with super poor characteristics in a parameter, consequently, 10 means a startup is brilliant in a certain evaluation criterion.">


                                        <div className='info-icon'>i</div>
                                          </span>
                            </div>
                            <div className='total-score float-right'>
                                <div className='total-score-text color-2f3849'>{this.ParamScore(ValueProps)}<span>/10</span>
                                </div>
                            </div>
                            <div className='float-right block-param-total-score-title color-rgba-08'>
                                <div className='param-total-score-title'>Total score</div>
                                <div className='param-total-score-title'>{OpenedParam}</div>
                            </div>
                        </div>
                        <div className='row-b w-100 no-gutters shell-margin-top-20'>
                            <div className='col-3' style={{maxWidth: '62px'}} onClick={!Expert.OuterLinkExpert?() => {
                                this.openExpert()
                            }:null}>
                                {!Expert.OuterLinkExpert &&
                                this.getAvatar(Expert)
                                || <a href={`${Expert.OuterLinkExpert}`} target="_blank" style={{textDecoration:'none', cursor:'pointer'}}>{this.getAvatar(Expert)}</a>
                                }
                            </div>
                            <div className='col-9'>
                                <div className='row-b w-100 no-gutters expert-text'>Expert</div>
                                <div className='row-b w-100 no-gutters preliminary-evaluation-title color-rgba-88'
                                     style={{margin: '0'}}>{Expert.user.firstName} {Expert.user.lastName}</div>
                                <div
                                    className='row-b w-100 no-gutters text-40-symbols'>{Expert.professionalDescription}</div>
                            </div>
                        </div>
                        { TextReview ? <div>{TextReview}</div> : <React.Fragment>
                        <div className='row-b positive-sides-block w-100 no-gutters margin-top-35'>
                            <div className='sides-text color-rgba-08 font-weight-500 w-100'>Positive sides:</div>
                            <div className='sides-text color-rgba-08'>
                                {PosSides}
                            </div>
                        </div>
                        <div className='row-b negative-sides-block shell-margin-top-20 w-100 no-gutters'>
                            <div className='sides-text color-rgba-08 font-weight-500 w-100'>Negative sides:</div>
                            <div className='sides-text color-rgba-08'>
                                {NegSides}
                            </div>
                        </div>
                        <div className='row-b margin-top-22 w-100 no-gutters'>
                            <div className='recommendations-text font-weight-500 color-rgba-08'>Recommendations:</div>
                            <div className='recommendations-text color-rgba-08'>
                                {Recommend}
                            </div>
                        </div></React.Fragment>
                            }

                        <div className='row-b block-value-reviews margin-top-30 w-100 no-gutters'>
                            {this.QuestionReviewList(ValueProps)}

                        </div>
                    </div>
                </div>
            )
        } else {
            return <div/>
        }
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import plen18 from "../../../images/svg/plen18.svg";
import ButtonGreen from "../../controls3.0/button/ButtonGreen";


class Step20 extends React.Component {
    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if(!onlyStep) {

        }

        let loading = nextStep === 19? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }

    render () {
        return (
            <React.Fragment>
                <div className="container-full-reg" style={{position: 'relative'}}>
                    <img src={plen18} alt="" className="img-18-step-reg-expert" style={{right: '-80px'}}/>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Great! <br/>
                            You have just earned <span style={{color: '#0ECFAA'}}>4 ROCK</span> tokens!
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            Here your Rocket DAO Expert journey successfully began!
                        </p>

                        <div className="shell-button-reg-expert shell-margin-bottom-80">
                            <ButtonBlue
                                TitleButton={'That is enohugh for a start. Receive tokens now'}
                                EventOnClick={() => this.update(22, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                            Want to earn more ROCK tokens?
                        </p>
                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            We have prepared so many interesting tasks for you, ooh... <br/>
                            Excited?
                        </p>

                        <div className="shell-button-reg-expert shell-margin-bottom-20">
                            <ButtonGreen
                                TitleButton={'How can I earn more ROCK tokens?'}
                                EventOnClick={() => this.update(21, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="shell-button-reg-expert">
                            <ButtonGray
                                TitleButton={'Skip and explore the platform'}
                                EventOnClick={() => window.location.href = '/'}
                                paddingButton={'40'}
                                disabled={false}
                            />
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}



const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step20.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step20)
import React from 'react';
import CartAnimInfo from '../../controls3.0/cartAnimInfo'


export default class ViewCartAnim extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_1:false,
        }
    }



    render() {

        return (
            <div>
                <br/><br/>
                <div className="container">
                    <div className="row-b justify-content-around">
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={() => this.setState({active_1:!this.state.active_1})}
                                check={this.state.active_1}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={false}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                    </div>
                    <br/><br/><br/>


                    <div className="row-b justify-content-around">
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={() => this.setState({active_1:!this.state.active_1})}
                                check={this.state.active_1}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={false}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'big'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    ICO is a fundraising mechanism
                                    letting projects sell their
                                    underlying crypto tokens in
                                    exchange for Bitcoin, Ethereum or
                                    other cryptocurrencies. <br/><br/>

                                    It’s popular among blockchain
                                    companies, nut not as regulated as
                                    traditional fundraising methodes.
                                </span>}
                                onCheck={console.log('клик на карту')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                    </div>
                    <br/><br/><br/>

                    <div className="row-b justify-content-around">
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={() => this.setState({active_1:!this.state.active_1})}
                                check={this.state.active_1}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={false}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                    <br/><br/>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                    </div>



                    <br/><br/><br/>

                    <div className="row-b justify-content-around">
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={() => this.setState({active_1:!this.state.active_1})}
                                check={this.state.active_1}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={false}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                        <div className="col-3">
                            <CartAnimInfo
                                typeCart={'small'}
                                bigTitle={'Seed'}
                                title={'Up to $5 m'}
                                description={<span>
                                    These are the companies doing
                                    doing transactions with each other
                                    (involving a manufacturer and
                                    wholesaler, or a wholesaler and a
                                    retailer).
                                </span>}
                                onCheck={console.log('rkbr')}
                                check={true}
                                viewCheckbox={true}
                                titleButtonNoCheck={'Choose'}
                                titleButtonCheck={'Cancel'}
                            />
                        </div>
                    </div>

                </div>

                <br/><br/><br/>


            </div>
        );
    }
}


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBorder from "../../controls3.0/button/ButtonBorder";
import history from "../../../history";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Input from "../../controls3.0/input/Input";
import AvatarImg from './../../controls3.0/AvatarImg'
import {showMessage} from "../../../utils/showMessage";

class Step11 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            error: '',
            errors: {
                professionalIdentification: {msg: ''},
                professionalDescription: {msg: ''}
            },
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();

        switch (name) {
            case 'professionalIdentification':
                if(value.length > 40){
                    const error = 'The text is too long, 40 symbols max';
                    this.setState({errors:{ ...this.state.errors, professionalIdentification: {msg: error}}});
                } else {
                    this.setState({errors:{ ...this.state.errors, professionalIdentification: {msg: ''}}});
                }
                value = value.slice(0, 40);
                break;
            case 'professionalDescription':
                if(value.length > 120){
                    const error ='The text is too long, 120 symbols max';
                    this.setState({errors:{ ...this.state.errors, professionalDescription: {msg: error}}});
                } else {
                    this.setState({errors:{ ...this.state.errors, professionalDescription: {msg: ''}}});
                }
                value = value.slice(0, 120);
                break;
        }

        this.setState({expert: {...this.state.expert, [name]: value}})
    }


    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        let loading = nextStep == 10 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})
        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }




    render() {
        let {user, expert, errors} = this.state;

        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <div className="block-prof-ide">
                            <div className="block-left-prof-ide new-no-float-down-1199 new-width-100-down-1199">

                                <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-10">
                                    Professional identification
                                </p>

                                <p className="title-top-page-reg-expert text-h4 left-text shell-margin-bottom-40 shell-margin-top-0">
                                    professional interests and expertise.
                                </p>

                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10 shell-margin-top-0">
                                    Professional status
                                </p>

                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0">
                                    For example: business consultant; Senior risks analyst.
                                </p>

                                <div className="shell-input-height-40" style={{width:'282px'}}>
                                    <Input
                                        type={'text'}
                                        name={'professionalIdentification'}
                                        value={expert.professionalIdentification}
                                        onChange={this.onChange}
                                        placeholder={'40 symbols'}
                                        error={errors.professionalIdentification.msg}
                                    />
                                </div>

                                <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-10">
                                    Professional description
                                </p>

                                <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-15 shell-margin-top-0">
                                    Summarize your professional experience and relevant expertise below.
                                </p>

                                <div className="block-text-area-form-add-edit-modal-reg-expert">
                                    <textarea
                                        name={'professionalDescription'}
                                        value={expert.professionalDescription}
                                        onChange={this.onChange}
                                        placeholder={'120 symbols'}>
                                    </textarea>
                                    <div className="block-new-input error" style={{height:'auto'}}>
                                        <p className="title-error">{errors.professionalDescription.msg || ''}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="block-right-prof-ide new-no-float-down-1199 new-width-100-down-1199 new-no-padding-down-1199">
                                <p className="title-top-page-reg-expert text-button left-text color-text-reg-white-gray shell-margin-bottom-20">
                                    Here is the way other Rocket DAO users will see your profile.
                                </p>

                                <div className="block-person-reg-expert">
                                    <div className="block-left-person-reg-expert">
                                        <AvatarImg id={user.logoFileId}/>
                                    </div>
                                    <div className="block-right-person-reg-expert">

                                        <p className="title-top-page-reg-expert text-h6 left-text margin-0">
                                            {user.firstName} {user.lastName}
                                        </p>
                                        <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-white-gray margin-0">
                                            {expert.professionalIdentification}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(10, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(12)}
                                paddingButton={'56'}
                                disabled={!expert.professionalIdentification || !expert.professionalDescription}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step11.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step11)


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import DatePicker from 'react-datepicker';
import moment from "moment";

import {getLastProject} from "../../../actions/Project";
import RegAvatar from './../../controls3.0/regAvatar/regAvatar';
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import Input from "../../controls3.0/input/Input";


import {tokenLogin, updateStartup} from "../../../actions/Login";
import {createProject, updateProject} from "../../../actions/Project";
import {checkStep, goToStep} from "../../../utils/regNavigation";
import {NotificationManager} from "react-notifications";
import Select from "../../controls3.0/select/Select";
import {COUNTRIES} from "../../../utils/countriesLanguages";

import Bitmap from '../../../images/svg/Bitmap.svg'
import {regexEmail} from "../../../utils/RegExp";
import {getArrYears} from '../../../utils/staticData'
import {updateMenu} from '../../../actions/menu';

const FOUNDEDYEAR = getArrYears().reverse()

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        let user = props.user;
        let project = props.project || {title: '', shortDescription: '', founded: ''}
        project.founded ? project.founded = [{Name: project.founded}] : project.founded = [];
        if(!project.shortDescription){
            project.shortDescription = '';
        }
        this.state = {
            user: user,
            project: project,
            background: {},
            errors: {
                title: ''
            }
        }
    }

    componentDidMount() {
        //if (!this.state.project.id) this.props.getLastProject(true)
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.project.id && nextProps.project && nextProps.project.id) {
            nextProps.project.founded ? nextProps.project.founded = [{Name: nextProps.project.founded}] : nextProps.project.founded = [];
            this.setState({project: nextProps.project})
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace(),
            errors = this.state.errors,
            required = false,
            limit = 200,
            minLength = 0;

        switch (name) {
            case 'title':
                limit = 50;
                required = true;
                break;
            case 'shortDescription':
                limit = 200;
                required = true;
                break;
            case 'founded':
                required = true;
                break;
        }

        if (value.length) {
            if (value.length > limit) {
                value = value.slice(0, limit)
                errors[name] = 'max length ' + limit + ' symbols';
                // setTimeout(() => {
                //     this.setState({errors: {...this.state.errors, [name]: null}})
                // }, 2000)
            } else if (minLength && value.length < minLength) {
                errors[name] = 'min length ' + minLength + ' symbols';
            } else {
                delete errors[name];
            }
        }

        if (required && !value) {
            errors[name] = 'Field is required'
        }

        this.setState({
            project: {...this.state.project, [name]: value},
            errors: errors
        })
    }

    onChangeYear = data => {
        if (data) {
            this.setState({project: {...this.state.project, founded: data}})
        }
    }

    validate(data) {
        const errors = {}
        for (let name in data) {
            let value = data[name],
                required = false,
                limit = 200,
                minLength = 0;
            switch (name) {
                case 'title':
                    required = true;
                    break;
                case 'shortDescription':
                    required = true;
                    limit = 200
                    break;
                case 'founded':
                    required = true;
                    break;
            }
            if (minLength && value.length < minLength) {
                errors[name] = 'min length ' + minLength + ' symbols';
            }
            if (required && !value) {
                errors[name] = 'Field is required'
            }
        }
        return errors;
    }

    create = (nextStep, onlyStep) => {
        let data = new FormData(),
            project = this.state.project;

        let errors = onlyStep ? {} : this.validate(project)
        this.setState({errors})
        if (Object.keys(errors).length) return;

        data.append('title', project.title.trim());
        data.append('registrationStep', nextStep);
        data.append('shortDescription', project.shortDescription.trim());
        let founded = project.founded[0] ? project.founded[0].Name : '';
        data.append('founded', founded);
        data.append('event_type', 'startup_name_1');

        const urlParams = new URLSearchParams(window.location.search);
        const event = urlParams.get('event');
        const ref_b = urlParams.get('ref_b');

        if(event){
            data.append('from', event);
        }

        if(ref_b){
            data.append('ref_b', ref_b);
        }

        this.sendToServer(data, onlyStep, nextStep)
    }

    sendToServer = async (data, onlyStep, nextStep) => {
        if (!onlyStep) this.setState({loading: true})


        if (!this.state.project.id) {
            createProject(data)
                .then((res) => {
                    this.props.updateStartup(res.data, onlyStep, nextStep)
                    this.props.updateMenu({type: 'update'});
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            title: 'Startup with this name is already on the platform'
                        }
                    })
                    this.setState({loading: false})
                })
        } else {
            const project = this.state.project;
            let founded = project.founded[0] ? project.founded[0].Name : '';
            this.props.updateStartup({
                id: project.id
                , title: project.title.trim()
                , shortDescription: project.shortDescription.trim()
                , founded: founded
                , event_type: 'startup_name_1'
            }, onlyStep, nextStep).then(r => {

                this.props.updateMenu({type: 'update'});
            })
                .catch(err => {
                    this.setState({loading: false});
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            title: 'Startup with this name is already on the platform'
                        }
                    })
                    console.log(err);
                })
        }
    }

    update = (nextStep, onlyStep) => {
        this.setState({loading: true})

        if (!this.state.project.id) {
            goToStep(1);
        } else {
            this.props.updateStartup(this.state.project, onlyStep, nextStep).then(r => {
                this.props.updateMenu({type: 'update'});
            })
                .catch(err => {
                    this.setState({loading: false})
                    console.log(err);
                })
        }
    }

    render() {
        let {user, project, errors} = this.state;

        let propsProject = this.props.project || {}

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <img src={Bitmap} alt="" className="img-16-step-reg-expert"/>
                            <div className="container-step-2-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    And here we start!
                                </p>
                                <p className="text-h5 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Let’s create your startup’s profile.
                                </p>
                                <p className="text-button color-text-reg-gray margin-0">
                                    We will begin with some fundamental steps.
                                </p>
                                <div className="text-button-2 color-text-reg-gray margin-0">
                                    Tell us the story of your project, highlighting the most significant features. <br/>
                                    Make yourself notable for potential investors and advisors.
                                </div>

                                <div className="block-list-items-input margin-top-50" style={{marginTop: '50px'}}>
                                    <div className="block-item-input-reg-startup">
                                        <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-10">
                                            Startup Name
                                        </p>
                                        <Input
                                            type={'text'}
                                            name={'title'}
                                            value={project.title}
                                            onChange={this.onChange}
                                            error={errors.title}
                                            placeholder={'Title'}
                                        />
                                    </div>
                                    <div className="block-item-input-reg-startup">
                                        <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-10">
                                            Two-sentence description of the project
                                        </p>
                                        <Input
                                            type={'text'}
                                            name={'shortDescription'}
                                            value={project.shortDescription}
                                            onChange={this.onChange}
                                            error={errors.shortDescription}
                                            placeholder={'Not more than 200 symbols'}
                                        />
                                    </div>
                                    <div className="block-item-input-reg-startup">
                                        <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-10">
                                            Founding year
                                        </p>
                                        <Select
                                            placeholder={'Select'}
                                            data={FOUNDEDYEAR}
                                            value={project.founded}
                                            onChange={(e) => this.onChangeYear(e)}
                                            error={errors.founded}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Done!'}
                                EventOnClick={() => this.create(17)}
                                paddingButton={'94'}
                                disabled={!project.title || (!project.shortDescription) || !project.founded.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step2.propTypes = {
    updateStartup: PropTypes.func.isRequired,
    getLastProject: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject, updateMenu})(Step2)


import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import './UnsubscribeNewsletterStyle.css'

import {tokenLogin, getUserByToken, updateUserByToken} from "../../actions/Login";

import Header from '../header'
import Footer from '../footer/Footer'
import MetaTags from "react-meta-tags";
import history from '../../history';
import Loader from '../test-controls/preloader/preloader'
import {showMessage} from "../../utils/showMessage";
import ButtonGreen from "../controls3.0/button/ButtonGreen";

class UnsubscribeNewsletter extends React.Component {
    constructor(props) {
        super();
        this.state = {
            ...props,
            token: props.match.params.token,
            emailId: props.match.params.emailId,
            userByToken: {},
            unsubscribe: false,
        }
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }

        getUserByToken(this.state.token).then(r => {
            this.setState({userByToken: r});
            this.setState({unsubscribe: r.unsubscribe});
        }).catch(e => {
            history.push('/404');
        })
    }

    onCheck = (val) => {
        updateUserByToken(this.state.token, {unsubscribe: val, emailId: this.state.emailId}).then(r => {
            this.setState({unsubscribe: r.unsubscribe});
            if(r.unsubscribe){
                showMessage({type: 'info', data: 'You unsubscribed successfully'})
            } else {
                showMessage({type: 'info', data: 'Welcome back comrade!!!'})
            }

        }).catch(e => {
            showMessage({type: 'error', data: ''})
        })
    }

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{'Unsubscribe newsletters - Rocket DAO - uniting startups, investors and experts'}</title>
                    <meta property="og:title" content={'Unsubscribe newsletters - Rocket DAO - uniting startups, investors and experts'}/>
                </MetaTags>

                <div className='reg-wrap'>
                    <Header user={this.props.user} noShowCreate={true}/>
                    <div className="container-100-reg">
                        <div className="container-unsub-news">
                            {this.state.userByToken &&
                            <div className='row-m0 no-gutters block-unsub-news text-align-center '>
                                <div className='row-m0 main-title-insub font-weight-500'>Is this goodbye?</div>
                                <div className='row-m0 text-insub-descr font-weight-500 margin-top-80'>
                                    We just received your request to unsubscribe
                                    to our platform emails, so you won't receive them anymore.
                                </div>
                                <div className='row-m0 text-insub-descr font-weight-300'>
                                    <br/>To be honest, no matter what our email relationship is,
                                    Rocket DAO will always be here if you need us.
                                </div>
                                <div className='row-m0 no-gutters buttons-line-wrapper margin-top-40 w-100'>
                                    <div className='col-12 col-sm left-button-wrapper'>
                                        <ButtonGreen
                                            TitleButton={'Renew subscription'}
                                            EventOnClick={() => {this.onCheck(false)}}
                                            paddingButton={'1'}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='col right-button-wrapper'>
                                        <ButtonGreen
                                            TitleButton={'Still unsubscribe :('}
                                            EventOnClick={() => {this.onCheck(true)}}
                                            paddingButton={'1'}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                                <div className='person-icon'/>
                                <div className='paper-planes-icon'/>
                            </div>
                            || <Loader loaded={false} isLocal={true}/>
                            }


                        </div>
                        <div className='row-m0 block-unsub-bg w-100'/>
                    </div>
                    <Footer/>
                </div>
                <NotificationContainer/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

UnsubscribeNewsletter.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object
};

export default connect(mapStateToProps, {tokenLogin})(UnsubscribeNewsletter)
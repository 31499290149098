const config = {
    proxy: process.env.REACT_APP_API_ENDPOINT || 'https://apitest.rocketdao.io',
    image:{
        logo:{
            sm: 80,
            md: 200,
        },
        bg:{
            sm: 300,
            md: 600,
            lg: 0
        }
    }
};

export default config;
import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import qs from 'qs';
import axios from 'axios';


import {logout, tokenLogin, updateUser} from "../../actions/Login";
import {getLastProject} from "../../actions/Project";
import {checkStep, goToStep} from './../../utils/regNavigation'
// import Header from './controls/Header'

import Header from '../header';

import InvestorRoutes from './investor/routes'
import ExpertRoutes from './expert/routes'
import StartupRoutes from './startup/routes'
import history from '../../history';

import './style.css'
import './investor/style.css'


class RegistrationContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            type: ''
        }
    }

    componentDidMount() {
        let pathname = window.location.pathname,
            type = '';

        if (/investor/.test(pathname)) type = 1;
        if (/expert/.test(pathname)) type = 2;
        if (/startup/.test(pathname)) type = 3;
        if (/fund/.test(pathname)) type = 4;
        if (/angel/.test(pathname)) type = 5;
        if (/start_investor/.test(pathname)) type = 6;
        if (/accelerator/.test(pathname)) type = 7;

        this.setState({type: type});
        const q = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if ((type === 3 && q.ref_b)){ //track refferal used
            axios.post(`/v1/users/trackReferral/${q.ref_b}`)
        }


        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        } else {
            if (q.ref_b){
                return history.push(`/login?type=${type}&ref_b=${q.ref_b}`)
            }
            return  history.push(`/login?type=${type}`)

        }
        if (type === 3) {
            this.props.getLastProject()
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.message) NotificationManager.error(nextProps.message)
        let registrationStep,
            type = this.state.type;

        if (nextProps.user && nextProps.user.id) {
            switch (type) {
                case 1:
                    registrationStep = nextProps.user.investor.registrationStep;
                    if(!nextProps.user.investor.registrationStep) registrationStep = 2;
                    if(nextProps.user.investor.registrationStep === 0) registrationStep = 2;
                    if(nextProps.user.investor.registrationStep === 1) registrationStep = 2;
                    break;
                case 2:
                    registrationStep = nextProps.user.expert.registrationStep;
                    if(nextProps.user.expert.registrationStep === 0) registrationStep = 1;
                    break;
                // case 3:
                //     if(nextProps.project){
                //         registrationStep = nextProps.project.registrationStep;
                //     } else {
                //         registrationStep = 1;
                //     }
                //     break;
            }
        }

        //check if browser router equals registration step
        if (nextProps.user && nextProps.user.id && type !== 3) {
            if(!registrationStep) {
                window.location.href = `/start_reg?type=${this.state.type}`
                return
            }
            if (checkStep() != registrationStep) {
                goToStep(registrationStep)
            }
        }
    }


    render() {
        return (
            <React.Fragment>
                {this.props.user && this.props.user.id ?
                    <div className='reg-wrap'>
                        {/*<Header user={this.props.user} project={this.props.project} />*/}

                        <Header user={this.props.user} project={this.props.project || {registrationStep: 0}} registrationView={true}/>

                        <div className="container-100-reg">
                            <div className="container-global-reg">
                                <InvestorRoutes/>
                                <ExpertRoutes/>
                                <StartupRoutes project={this.props.project}/>
                            </div>
                        </div>
                    </div>
                    :
                    <div>Loading...</div>
                }
                <NotificationContainer/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    message: state.auth.message,
    project: state.project.project
})

RegistrationContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    getLastProject: PropTypes.func.isRequired,
    user: PropTypes.object,
    message: PropTypes.string
}

export default connect(mapStateToProps, {tokenLogin, getLastProject})(RegistrationContainer)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";
import {getMoneyView} from "../../../utils/transformation";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import InputRange from 'react-input-range';
// import Input from "../../controls3.0/input/Input";
import NumberFormat from 'react-number-format';
import CheckBox from "../../controls3.0/checkBox/checkBox";

const isDefined = function (variable) {
    return typeof variable !== 'undefined' && variable !== null;
};

class Step12 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator,
            errors: {},
            fundingUpTo: props.accelerator.fundingUpTo || null,
            equityTakenInReturn: props.accelerator.equityTakenInReturn || null,
            equityFree: props.accelerator.equityFree || false
        }
    }

    componentDidMount() {

    }

    onChangeFundingUpTo = (obj) => {
        let value = obj.floatValue;
        this.setState({fundingUpTo: value})
    }
    onChangeEquityTakenInReturn = (obj) => {
        let value = obj.floatValue;
        this.setState({equityTakenInReturn: value})
        if (!value) {
            this.setState({equityFree: true})
        } else {
            this.setState({equityFree: false})
        }
    }
    onChangeEquityFree = () => {
        const value = !this.state.equityFree;
        this.setState({ equityFree: value });
        if (value) {
            this.setState({equityTakenInReturn: 0})
        }
    }

    update = (nextStep, onlyStep) => {
        let obj = {
            fundingUpTo :this.state.fundingUpTo,
            equityTakenInReturn: this.state.equityTakenInReturn,
            equityFree: this.state.equityFree,
        };

        if (!onlyStep) this.setState({loading: true})
        const acceleratorId = this.state.accelerator.id;
        this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    disabled = () => {
        const {fundingUpTo, equityTakenInReturn, equityFree} = this.state;
        return isDefined(fundingUpTo) && isDefined(equityTakenInReturn) || isDefined(fundingUpTo) && equityFree;
    }

    render() {
        let {accelerator, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup padding-reg">
                    <div className="wrap-white">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    Investment size range
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                    How much money does your accelerator usually invest in each startup?<br/>
                                    What part of equity do you take in return?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg  container-bottom block-gradient-gray-reg"
                         style={{position: 'relative'}}>
                        <div className="container-step-1-reg">
                            <div className="block-list-title-input margin-0 shell-margin-top-30" style={{margin:'30px 0'}}>
                                <div className="block-item-title-input" style={{maxWidth:'500px'}}>
                                    <p className="title-item-title-input no-float-620" style={{width: 'calc(100% - 130px)'}}>
                                        Funding up to, $
                                    </p>
                                    <div className="block-input-item-title-input no-float-620" style={{width: '130px'}}>
                                        <div className="block-new-input block-new-input-black">
                                            <NumberFormat
                                                isAllowed={(values) => {
                                                    const {floatValue} = values;
                                                    return (floatValue >= 0 && floatValue <= 9999999999) || (typeof floatValue === 'undefined' || typeof floatValue === 'NaN')}}
                                                allowNegative={false}
                                                prefix={' $'}
                                                thousandSeparator={' '}
                                                value={this.state.fundingUpTo}
                                                onValueChange={this.onChangeFundingUpTo}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="block-item-title-input" style={{maxWidth:'500px'}}>
                                    <p className="title-item-title-input no-float-620" style={{width: 'calc(100% - 130px)'}}>
                                        Equity taken in return, %
                                    </p>
                                    <div className="block-input-item-title-input no-float-620" style={{width: '130px'}}>
                                        <div className="block-new-input block-new-input-black">
                                            <NumberFormat
                                                isAllowed={(values) => {
                                                    const {floatValue} = values;
                                                    return (floatValue >= 0 && floatValue <= 100) || (typeof floatValue === 'undefined' || typeof floatValue === 'NaN')}}
                                                allowNegative={false}
                                                sufix={'%'}
                                                thousandSeparator={' '}
                                                value={this.state.equityTakenInReturn}
                                                onValueChange={this.onChangeEquityTakenInReturn}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className='container-inline-checkbox color-rgba-08'
                                    onClick={this.onChangeEquityFree}
                                >
                                    <CheckBox
                                        onClick={() => {}}
                                        active={this.state.equityFree}
                                    />
                                   Our accelerator is equity-free
                                </div>
                            </div>



                        </div>




                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(9, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(12)}
                                paddingButton={'54'}
                                disabled={!this.disabled()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step12.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step12)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateFund} from "../../../actions/Login";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import human from "../../../images/ilustaciya/Ilustraciya_investor-exit.svg";


class Step13 extends React.Component {
    constructor(props) {
        super();
        let fund = props.fund,
            investor = fund || {};
        this.state = {
            fund: fund,
            investor: investor,
            errors: {}
        }
    }


    // investmentsCount	123
    // exitsCount	123
    //

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");

        if(value >= 0 && value <= 9999999999)
            this.setState({ investor: {...this.state.investor, [name]: value} })
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        const fundId = this.props.fund.id;
        this.props.updateFund(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    validate(){
        const {investor} = this.state;
        return investor.investmentsCount && investor.exitsCount;
    }


    render () {
        let {fund, investor, errors} = this.state

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <img src={human} alt="" className="img-right-step" style={{top:'120px',right:'0'}}/>
                        <div className="container-step-1-reg">
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                Number of investments and exits
                            </p>
                            <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                How extensive is your investing experience?
                            </p>


                            <div className="block-list-title-input">
                                <div className="block-item-title-input" style={{maxWidth:'420px'}}>
                                    <p className="title-item-title-input no-float-620">
                                        Number of investments
                                    </p>
                                    <div className={`block-input-item-title-input ${false ? 'no-float-620':''}`}>
                                        <Input
                                            type={'text'}
                                            name={'investmentsCount'}
                                            value={investor.investmentsCount}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errors.investmentsCount}
                                        />
                                    </div>
                                </div>

                                <div className="block-item-title-input" style={{maxWidth:'420px'}}>
                                    <p className="title-item-title-input no-float-620">
                                        Number of exits
                                    </p>
                                    <div className={`block-input-item-title-input ${false ? 'no-float-620':''}`}>
                                        <Input
                                            type={'text'}
                                            name={'exitsCount'}
                                            value={investor.exitsCount}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            error={errors.exitsCount}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(11, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(13, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(13)}
                                paddingButton={'54'}
                                disabled={!this.validate()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund
})

Step13.propTypes = {
    updateFund: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateFund})(Step13)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {getListSkills, getListSkillsByString} from "../../../actions/Profile";
import {updateStartup} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import {showMessage} from "../../../utils/showMessage";
import Loader from '../../test-controls/preloader/preloader'
import human from "../../../images/svg/human.svg";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Tabs from "../../controls3.0/tabs/Tabs";
import Input from "../../controls3.0/input/Input";
import {getLastProject, updateProject} from "../../../actions/Project";
import CheckBox from "../../controls3.0/checkBox/checkBox";

class Step8 extends React.Component {
    constructor(props) {
        super(props);
        let project = props.project || {}


        if(!project.businessModelType || typeof project.businessModelType === 'object') {
            if(typeof project.businessModelType === 'object' && project.businessModelType) {

            } else {
                project.businessModelType = []
            }
        } else {
            project.businessModelType = project.businessModelType.split(',')
        }
        this.state = {
            project: project
        }
    }

    componentDidMount() {
        if(!this.state.project.id) this.props.getLastProject()
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id && !this.state.project.id) {
            let project = nextProps.project
            if(project && !project.businessModelType) {
                project.businessModelType = []
            } else {
                project.businessModelType = project.businessModelType.split(',')
            }
            this.setState({project: project})
        }
    }

    set = type => {
        let businessModelType = this.state.project.businessModelType;


        let isFound = businessModelType.find(item => item === type)
        if (!isFound) {
            businessModelType.push(type)
        } else {
            businessModelType = businessModelType.filter(item => item !== type)
        }
        this.setState({project: {...this.state.project, businessModelType: businessModelType}})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            businessModelType: project.businessModelType.join(',')
        }
        obj.event_type = 'startup_business_model_9';

        if(onlyStep){
            if (project.fundraisingInstruments == 'ICO') {
                nextStep = 19
            }
        }

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    // updateS = (onlyStep, nextStep) => {
    //     this.props.updateStartup({}, onlyStep, nextStep)
    //         .catch(err => {
    //             this.setState({loading: false})
    //         })
    // }



    render() {
        let {project} = this.state;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                Business model type
                            </p>
                            <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-30">
                                What is the current target group of users/customers? <br/>
                                How have you decided to develop your business model?
                                <br/><br/>
                                There might be multiple variations depending on the monetization hypotheses you have. <br/>
                                Select those which suit you the best.
                            </p>
                            {/*<p className="text-button-2 color-text-reg-gray margin-0">*/}
                                {/**/}

                            {/*</p>*/}


                            <div className="block-list-cart-round-reg-startup block-gradient-gray-reg padding-top-35 margin-top-50">
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('B2B') ?' active' : ''}`} onClick={() => this.set('B2B')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('B2B')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        These are the companies doing doing transactions with each other (involving a manufacturer and wholesaler, or a wholesaler and a retailer).
                                    </p>
                                    <p className="title-front-cart">
                                        B2B
                                    </p>
                                    <p className="description-front-cart">
                                        Business-to-Business
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('B2B')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('B2C') ?' active' : ''}`} onClick={() => this.set('B2C')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('B2C')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        Such a company provides delivers services directly to the general public and individual customers who are the end-users of the product (for example, Amazon and Priceline).
                                    </p>
                                    <p className="title-front-cart">
                                        B2C
                                    </p>
                                    <p className="description-front-cart">
                                        Business-to-Consumer
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('B2C')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('B2G')?' active' : ''}`} onClick={() => this.set('B2G')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('B2G')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        This business category concerns private sector firms dealing with the government (like IT consulting to a local government agency).
                                    </p>
                                    <p className="title-front-cart">
                                        B2G
                                    </p>
                                    <p className="description-front-cart">
                                        Business-to-Government
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('B2G')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('C2B')?' active' : ''}`} onClick={() => this.set('C2B')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('C2B')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        In this case customers create a specific value for businesses: reverse auctions, service provision (like UpWork).
                                    </p>
                                    <p className="title-front-cart">
                                        C2B
                                    </p>
                                    <p className="description-front-cart">
                                        Consumer-to-Business
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('C2B')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('C2C')?' active' : ''}`} onClick={() => this.set('C2C')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('C2C')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        This is usually an online environment where customers can trade with each other (for example, eBay and Craigslist).
                                    </p>
                                    <p className="title-front-cart">
                                        C2C
                                    </p>
                                    <p className="description-front-cart">
                                        Consumer-to-Consumer
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('C2C')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>
                                <div className={`block-item-cart-round-reg-startup bic-bm ${project.businessModelType.includes('C2G')?' active' : ''}`} onClick={() => this.set('C2G')}>
                                    <div className='block-checkbox-front-cart'>
                                        <CheckBox
                                            active={project.businessModelType.includes('C2G')}
                                        />
                                    </div>
                                    <p className="description-back-cart">
                                        This solution implies electronic transactions between individuals and public administration (distance learning, tax payments, etc.).
                                    </p>
                                    <p className="title-front-cart">
                                        C2G
                                    </p>
                                    <p className="description-front-cart">
                                        Consumer-to-Government
                                    </p>
                                    <div className="block-button-cart">
                                        <p className="title-button-cart">
                                            {project.businessModelType.includes('C2G')?'Cancel':'Choose'}
                                        </p>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(7, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(9)}
                                paddingButton={'54'}
                                disabled={!project.businessModelType.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>


        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step8.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step8)
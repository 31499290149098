import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'

import CheckBox from "../../controls3.0/checkBox/cubeCheckBox";
import {getListParameters} from "../../../actions/Profile";
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";

let isPopupShouldClose = true;

export default class EvalParamPopup extends React.Component {
    constructor(props) {
        super();

        let evaluation_parameters = [];
        if(props.searchSettings.evaluation_parameters){
            evaluation_parameters = props.searchSettings.evaluation_parameters.map(i => parseInt(i))
        }


        this.state = {
            listParameters:[],
            checkedParameters: evaluation_parameters
        };
    }

    componentDidMount() {
        // if (this.props.searchSettings) {
        //     this.setState({checkedParameters:this.props.searchSettings['evaluation_parameters']})
        // }
        getListParameters()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({listParameters: res.data})
            })
            .catch(err => {

            })


        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);

    }

    ResetPopup() {
        this.setState({checkedParameters:[]})
    }

    onChange = item => {
        let _checkedParameters = this.state.checkedParameters;

        let isFound = _checkedParameters.find(x => x === item.id);
        if (!isFound) {
            _checkedParameters.push(item.id)
        } else {
            _checkedParameters = _checkedParameters.filter(x => x !== item.id)
        }
        this.setState({checkedParameters:_checkedParameters});
    };

    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    render() {
        let {mobileHeight} = this.props;


        const parameters = this.state.listParameters.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup" onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CheckBox
                        onClick={null}
                        active={this.state.checkedParameters.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup search-popup-right">{item.title}</div>
            </div>
        );

        return (

            <div className='profile-edit-popup-block'onClick={()=>{this.ClosePopup()}}>
                <Scrollbars>
                <div className='search-popup search-popup-eval-param row-m0 no-gutters' id='eval-param' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>

                    {/*<div style={{minHeight: 'calc(100% - 135px)', height: 'calc(100% - 135px)', width: '100%'}} className={'scroll-this scroll-this-display'}>*/}
                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this scroll-this-display'}>
                            <div style={{maxHeight: '90%', padding: '27px 12px 17px 30px'}}>
                                <div className='row-m0 buttons-line no-gutters w-100'>
                                    {parameters && <div className="col-12 block-industry-check-box-reg-startup bl-fix-height text-left">
                                        {parameters}
                                    </div>}
                                    <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                                    <div className='col font-button'
                                         onClick={()=>{this.props.setSearchSettings('evaluation_parameters',this.state.checkedParameters); this.ClosePopup()}}
                                         style={{color:'#1BC8A7', marginRight:'60px'}}>Apply</div>
                                </div>
                            </div>
                        </Scrollbars>
                    {/*</div>*/}


                    <div className='row-m0 buttons-line no-gutters w-100 not-scroll-this'>
                        {parameters && <div className="col-12 block-industry-check-box-reg-startup bl-fix-height text-left">
                            {parameters}
                        </div>}
                        <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('evaluation_parameters',this.state.checkedParameters); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'60px'}}>Apply</div>
                    </div>


                    <div className='mobile-popup-footer' style={{marginTop:'-5px'}}>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('evaluation_parameters',this.state.checkedParameters); this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
                </Scrollbars>
            </div>
        )


    }
}




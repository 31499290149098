import React from 'react';

import './stylesEditProfileList.css'

export default class EditProfileList extends React.Component {

    render() {
        let {
            title, //investor, fund...
            lastEdited,
            posted
        } = this.props;

        let options = [];
        if (title === 'startup') {
          options = ['Basic information', 'Industry', 'Legal structure', 'Geographic market focus', 'Fundraising instruments', 'Investment round + Capital & Equity / Token sale info', 'Business model type', 'Average monthly turnover', 'Problem, Opportunity & Solution', 'Business model & Target audience', 'Pitch deck & Demo', 'Team & Founders', 'Startup achievements', 'Social contacts'];
        }
        if (title === 'expert') {
          options = ['Basic information', 'Expert roles', 'Skills & Competenciens', 'Work experience', 'Social networks', 'Work calendar', 'Reminders & Notifications', 'Metamask wallet number'];
        }
        if (title === 'investor') {
          options = ['Basic information', 'Areas of expertise', 'Work experience', 'Achievements', 'Industry', 'Geographic market focus', 'Business type', 'Preferred startup’s turnover stage', 'Investment size range', 'Number of investments and exits', 'Previous investments', 'Investment focus', 'My Angel Networks', 'My Venture Funds', 'Social contacts'];
        }
        if (title === 'fund') {
          options = ['Basic information', 'Areas of expertise', 'Work experience', 'Achievements', 'Industry', 'Geographic market focus', 'Business type', 'Preferred startup’s turnover stage', 'Investment size range', 'Number of investments and exits', 'Previous investments', 'Investment focus', 'Proof of ownership', 'Social contacts'];
        }
        if (posted === undefined) posted = '';
        if (lastEdited === undefined) lastEdited = '';

        
        return (
            <div className='edit-profile-list'>
                <div className='edit-profile-main-title'>
	                <div className='main-title'>
	                	<div>Edit {title} profile</div>
	                </div>
	                <div className='last-edited'>
	                	{(lastEdited !== '') && <div>Last edited: {lastEdited}</div>}
                        {(posted !== '') && <div>Posted: {posted}</div>}
	                </div>
                </div>

                <div className='edit-profile-table'>
                	{options.map(o => <div className='edit-profile-table-line'>
                		<span>{o}</span>
                		<div className='table-edit-button'><span>Edit</span></div>
                	</div>)}
                	
                </div>
            </div>
        );
    }
}
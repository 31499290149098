import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Swipe from '../../controls3.0/swipe/Swipe'
import {getListIndustries} from "../../../actions/Project";
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();
        this.state = {
            IModelList:[
                {id:'Pre-Seed',Name:'Pre-Seed', descr:'Founders have a clear understanding of the project\'s mission, market structure and its needs, of the technologies most suitable for their case.  The team has a coherent development plan.'},
                {id:'Seed',Name:'Seed', descr:'Business model hypothese have alreay proved themselves.  The MVP is further enhanced  and turned into a serial product. Financing is needed for cost recovery.'},
                {id:'Series A',Name:'Series A', descr:'Investments are needed for advertising, attracting the target audience. This is the period when the project starts global scaling of their mass market product.'},
                {id:'Series B',Name:'Series B', descr:'At this step, the team needs extra financing for global expansion. Series B supposes the business model of the project to be adapted to different geographical regions.'}
            ],
            IModelChecked:[]

        };
    }

    componentDidMount() {
        if (this.props.searchSettings) {
            this.setState({IModelChecked:this.props.searchSettings['investment_round']})
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }

    ResetPopup(){
        this.setState({ IModelChecked:[]})
    }

    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    bmodelCheck(item) {
        let _IModelChecked = this.state.IModelChecked;

        let isFound = _IModelChecked.find(x => x === item.id);
        if (!isFound) {
            _IModelChecked.push(item.id)
        } else {
            _IModelChecked = _IModelChecked.filter(x => x !== item.id)
        }
        this.setState({IModelChecked:_IModelChecked});

    }

    render() {

        let {mobileHeight} = this.props;

        const list = this.state.IModelList.map((item, key) =>{
                return (
                    <div className='row-m0 business-model-list-search no-gutters w-100'>
                        <div className='row-m0 w-100 no-gutters'>
                            <div className='col-8 bmodel-list-name'>{item.Name}</div>
                            <div className='col-4 text-right'>
                                <Swipe
                                    onClick={() => {this.bmodelCheck(item)}}
                                    active={this.state.IModelChecked.includes(item.id)}
                                />
                            </div>
                        </div>

                        <div className='row-m0 imodel-list-descr w-100 no-gutters'>{item.descr}</div>

                    </div>
                )

            }
        );

        return (
            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                {/*<Scrollbars>*/}
                <div className='search-popup search-popup-bmodel new-search-popup-bmodel row-m0 no-gutters' id='inv-round' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>

                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this'}>
                        <div style={{maxHeight: '90%', padding: '13px 25px 20px 25px'}}>
                            {list}
                        </div>
                    </Scrollbars>

                    {/*<div style={{minHeight: '100%', height: '100%', width: '100%'}}>*/}

                    {/*</div>*/}

                    {/*<div className='not-scroll-this'>*/}
                        {/*{list}*/}
                    {/*</div>*/}

                    <div className='row-m0 buttons-line no-gutters w-100 not-scroll-this' style={{marginTop:'32px', padding:'0 25px'}}>
                        <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('investment_round',this.state.IModelChecked); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>

                    <div className='mobile-popup-footer'>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('investment_round',this.state.IModelChecked); this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
                {/*</Scrollbars>*/}
            </div>
        )


    }
}




export function getYoutubePreview(id) {
    // return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
}

export function getYoutubeEmbedURL(id) {
    return `https://www.youtube.com/embed/${id}`
}

export function getQueryVariable(url, variable) {
    const query = url.replace('?', '&');
    const vars = query.split('&');

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}
import React from 'react';
import './style.css'
export default class ButtonTransBlack extends React.Component {
    render() {
        let {
            TitleButton,
            spanTitle,
            EventOnClick,
            sizeButton, // sizeButton = big,small
            paddingButton, //0, '' inline , >0 padding
        } = this.props;
        let classSize = sizeButton == 'big'? 'button-2-0-big':'button-2-0-small'
        let styleButton
        if (paddingButton){
            if (paddingButton>0) {
                styleButton = {
                    display: 'inline-block',
                    paddingLeft: paddingButton + 'px',
                    paddingRight: paddingButton + 'px'
                }
            }
            else {
                styleButton = {display:'inline-block', width: '100%'}
            }
        }else {
            styleButton = {display:'inline-block', width: '100%'}
        }
        return (
            <div className={"block-button-2-0 button-2-0-trans-blue " + classSize} style={styleButton} onClick={EventOnClick} anim="ripple">
                <p>{TitleButton} <span>{spanTitle}</span></p>
            </div>
        );
    }
}
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";
// import {getCountrySearch} from "../../../actions/Profile";
import {regexUrl} from "../../../utils/RegExp";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import Select from "../../controls3.0/select/Select";
import human from "../../../images/ilustaciya/language-rocketDAO.svg";
// import {COUNTRIES, LANGUAGES} from "../../../utils/countriesLanguages";
import {getArrYears} from '../../../utils/staticData'


const FOUNDEDYEAR = getArrYears().reverse();


class Step1 extends React.Component {
    constructor(props) {
        super();
        let user = props.user;
        const angel = props.angel;

        this.state = {
            listUsers: [],
            user: user,
            angel:angel,
            me: {},
            errors: {},
            listWE: [],
            listCities: [],
            experience: {
                position: '',
                description: '',
                company: '',
                city: [{Name: angel.location || ''}],
                periodFrom: '',
                periodTo: ''
            },
            website: angel.website || '',
            founding_year: [{Name: angel.foundingYear || ''}],
            currentTeamCount: angel.currentTeamCount || ''
        }
    }

    componentDidMount () {
        //this.searchCity('')
    }

    // onChange = (e) => {
    //     this.setState({'name': e.target.value})
    // }
    //
    onChangeFY = (e) => {
        this.setState({'founding_year': e})
    }
    onChangeNTM = (e) => {

        const value = e.target.value.trim();

        if(value >= 1 && value <= 9999999999){
            this.setState({'currentTeamCount': value})
        }

    }

    onChangeWebSite = (e) => {
        let {value} = e.target;
        value = value.trim();
        if(!regexUrl.test(value)){
            this.setState({errors: {...this.state.errors, website: 'Invalid link'}})
        } else {
            delete this.state.errors.website;
        }

        this.setState({'website': value})
    }

    update = (nextStep, onlyStep) => {
        const angel = this.state.angel;

        if(onlyStep) {
            this.props.updateAngel({}, angel.id, onlyStep, nextStep)
                .catch(err => {
                    this.setState({loading: false})
                })
        }


        const fund = {
            currentTeamCount:this.state.currentTeamCount,
            website: this.state.website,
            foundingYear: this.state.founding_year[0].Name
        }

        this.setState({loading: true})
        this.props.updateAngel(fund, angel.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    validate(){
        const {website, founding_year, currentTeamCount} = this.state;
        const errors = Object.keys(this.state.errors);
        return website.length && errors.length === 0 && founding_year[0].Name.length && currentTeamCount;
    }

    render() {
        let {errors, experience} = this.state;

        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <img src={human} alt="" className="img-right-step"
                         style={{top: '100px', right: '0', height: '460px'}}/>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20">
                        Let the community get to know
                    </p>
                    <p className="description-page-reg-expert text-button left-text shell-margin-bottom-40"
                       style={{maxWidth: '530px'}}>
                        Filling in some basic information about yourself will ease communication with other users!
                        Trust, honesty, and transparency are the basis of the community.
                    </p>


                    <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-20">
                        Number of current
                        team members
                    </p>
                    <div className="block-input-reg" style={{maxWidth: "300px"}}>
                        <Input
                            type={'number'}
                            name={'website'}
                            value={this.state.currentTeamCount}
                            min="1"
                            onChange={this.onChangeNTM}
                            error={''}
                            placeholder={'Enter the current team members'}
                            required={true}
                        />
                    </div>

                    <p className="title-top-page-reg-expert text-h3 left-text margin-0 shell-margin-bottom-20">
                        Website
                    </p>
                    <div className="block-input-reg" style={{maxWidth: "300px"}}>
                        <Input
                            type={'text'}
                            name={'website'}
                            value={this.state.website}
                            onChange={this.onChangeWebSite}
                            error={errors.website}
                            placeholder={'Enter the link'}
                            required={true}
                        />
                    </div>

                    <p className="title-top-page-reg-expert text-h3 left-text margin-0 shell-margin-bottom-20">
                        Founding year
                    </p>

                    <div className="shell-select-reg">
                        <Select
                            placeholder={'Select'}
                            data={FOUNDEDYEAR}
                            value={this.state.founding_year}
                            onChange={(e) => this.onChangeFY(e)}
                            searchList={null}
                            error={''}
                            required={true}
                        />
                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(4)}
                                paddingButton={'54'}
                                disabled={!this.validate()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step1.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step1)
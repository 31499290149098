import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import history from '../../history';
import {tokenLogin, getLastFund, getLastAngel} from "../../actions/Login";
import {getAccelerator} from "../../actions/Accelerator";
import Header from '../header';
import RoutesF from './Fund/routes'
import RoutesA from './Angel/routes'
import RoutesAC from './Accelerator/routes'


class RegistrationContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            type: ''
        }
    }

    componentDidMount() {

        let pathname = history.location.pathname,
            type = '';
        if (/fund/.test(pathname)) type = 4;
        if (/angel_network/.test(pathname)) type = 5;
        if (/accelerator/.test(pathname)) type = 6;
        this.setState({type: type})

        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        } else {
            NotificationManager.error('You are not logged in')
            setTimeout(() => {
                let search = '';
                if(history.location.search){
                    search = history.location.search + `&type=${type}`
                } else {
                    search = `?type=${type}`;
                }

                history.push(`/start_reg${search}`)
            }, 2000)
        }

        switch (type){
            case 1:{
                break;
            }
            case 5:{
                this.props.getLastAngel();
                break
            }
            case 4:{
                this.props.getLastFund();
                break
            }
            case 6:{
                this.props.getAccelerator();
                break
            }
        }

    }

    componentWillReceiveProps(nextProps) {

        //console.log('componentWillReceiveProps')

        // if (nextProps.message) NotificationManager.error(nextProps.message)
        // let registrationStep,
        //     type = this.state.type;
        //
        // if (nextProps.user && nextProps.user.id) {
        //     switch (type) {
        //         case 1:
        //             registrationStep = nextProps.user.investor.registrationStep;
        //             if(nextProps.user.investor.registrationStep === 1) registrationStep = 2;
        //             break;
        //         case 2:
        //             registrationStep = nextProps.user.expert.registrationStep;
        //             break;
        //         case 3:
        //             registrationStep = nextProps.user.startup.registrationStep;
        //             break;
        //     }
        // }
        //
        // // check if browser router equals registration step
        // if (nextProps.user && nextProps.user.id) {
        //     // if(!registrationStep) {
        //     //     window.location.href = `/start_reg?type=${this.state.type}`
        //     //     return
        //     // }
        //
        //     if (checkStep() != registrationStep) {
        //         goToStep(registrationStep)
        //     }
        // }
    }

    getProps(){
        if(this.props.fund && this.props.fund.id){
            return  this.props.fund;
        }
        if(this.props.angel && this.props.angel.id){
            return  this.props.angel;
        }
        if(this.props.accelerator && this.props.accelerator.id){
            return  this.props.accelerator;
        }

        return null
    }

    render() {
        return (
            <React.Fragment>
                {this.props.user && this.props.user.id ?
                    <div className='reg-wrap'>

                        <Header user={this.props.user}  project={ this.getProps() || {registrationStep: 0}} registrationView={true}/>
                        <div className="container-100-reg">
                            <div className="container-global-reg">
                                <RoutesF/>
                                <RoutesA/>
                                <RoutesAC/>
                            </div>
                        </div>
                    </div>
                    :
                    <div>Loading...</div>
                }
                <NotificationContainer/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    message: state.auth.message,
    fund: state.fund,
    angel: state.angel,
    accelerator: state.accelerator
})

RegistrationContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
    fund: PropTypes.object,
    angel: PropTypes.object,
    accelerator: PropTypes.object,
    message: PropTypes.string,
    getLastFund: PropTypes.func.isRequired,
    getLastAngel: PropTypes.func.isRequired,
    getAccelerator: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin, getLastFund, getLastAngel, getAccelerator})(RegistrationContainer)
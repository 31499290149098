import React, {useState} from 'react';
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";

import './styles.css'
import NumberFormat from "react-number-format";
import EditStep2 from "./FundInvitePopup";


const SetTotalMembersPopup = ({applySet, close, parentCallback}) => {
    const [TeamCount, setTeamCount] = useState('');


    const onChangeNTM = (obj) => {
        let value = parseInt(obj.floatValue);
        if(value){
            setTeamCount(value)
        }
    };

        return (
            <React.Fragment>
                <div className={`profile-edit-popup-block`}>
                    <div className="block-members-popup block-set-total-team text-align-center">
                        <div className='close-title'>
                            <div className='close' onClick={close}/>
                        </div>
                        <div className='main-title'>Team members</div>
                        <div className='descr-text margin-top-35'>
                            Has the number of your team members changed recently?<br/><br/>
                            No problem, you can specify the current team size below.
                        </div>
                        <div className='input-wrapper margin-top-30'>
                            <NumberFormat
                                isAllowed={(values) => {
                                    const {floatValue} = values;
                                    return (floatValue >= 1 && floatValue <= 999) || typeof floatValue === 'undefined' || typeof floatValue === 'NaN';
                                }}
                                thousandSeparator={''}
                                allowNegative={false}
                                value={TeamCount}
                                onValueChange={onChangeNTM}
                                decimalPrecision={0}
                                placeholder=""
                                format="###"
                            />
                        </div>



                        <div className='send-button-line margin-top-45 margin-bottom-20'>
                            <div>
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {
                                        applySet(TeamCount);
                                        close();
                                    }}
                                    paddingButton={'17'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
};
export default SetTotalMembersPopup;
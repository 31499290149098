export const validate = data => {
    const errors = {}
    for (let name in data) {
        let value = data[name],
            required = false,
            limit = 200,
            minLength = 0;
        switch (name) {
            case 'countryOfResidence':
            case 'lang':
                required = true;
                break;
            case 'shortDescription':
                required = true;
                limit = 200
                break;
            case 'founded':
                required = true;
                break;
        }
        if (minLength && value.length < minLength){
            errors[name] = 'min length ' + minLength + ' symbols';
        }
        if(required && !value) {
            errors[name] = 'Field is required'
        }
    }
    return errors;
}
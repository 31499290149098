import React, {useEffect, useState} from 'react';
import {getListParameters} from "../../../../actions/Profile";
import EvalParamToList from "../../../header/menu/profileMenuFrame/EvalParamToList";
import {createExpertParameter} from "../../../../actions/Login";
import {isExist} from "../../../../utils/helper";
import MenuButton from '../../../v4/MenuButtons/ProfileButton';
import {gql} from "apollo-boost/lib/index";
import ApolloClient from "apollo-boost"
import { useQuery } from '@apollo/react-hooks';
import { InMemoryCache } from "apollo-cache-inmemory";

import config from "../../../../config";
import AchieveBlock from "../../../v4/ExpertComponents/ParameterDetailsBlock/AchieveBlock";
import EditAchieve from "./acheivements";
import ExpertParamBlock from "../../../v4/ExpetParamBlock";
import '../../style.scss';
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import AddExpertParamPopup from "../../../v4/Popups/AddExpertParam";
import ParameterDetailsBlock from '../../../v4/ExpertComponents/ParameterDetailsBlock'
const cache = new InMemoryCache();

const client = new ApolloClient({
    cache,
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

const GET_USER = gql`
    query {
        user{
            id
            email
            lastName
            firstName
            logoFileId
            expert{
                id
                professionalIdentification
                parameters
                isFinished
            }
        }
    }
`;

export const Parameters = ({...props}) => {
    const [listParameters, setListParameters] = useState([]);
    const [addedParameters, setAddedParameters] = useState([]);
    const [addParameter, setAddParameter] = useState(false);
    const [parameterDetails, setParameterDetails] = useState(null);

    useEffect(() => {
        getListParameters()
            .then((res) => {
                if (Array.isArray(res.data)) setListParameters(res.data);
            })
            .catch(err => {

            });

        return () => false;

    }, []);



    const AddParameter = () => {
        setAddParameter(!addParameter);
    };

    const toggleDetails = (parameter) => {
        setParameterDetails(parameter);
    };

    const createParameter = (parameterId) => {

        createExpertParameter({}, parameterId).then(r => {
            listParameters.forEach((i, k)=>{
                if (i.id === parameterId) {
                    let defaultParameter = {
                        RoleParameter:{id:i.id, title:i.title, type:'expert'},
                        achievements:[],
                        parameterId:parameterId,
                        roleId:data.user.expert.id,
                        id:1
                    };
                    let _arr = addedParameters;
                    _arr.push(defaultParameter);
                    setAddedParameters(_arr);
                    AddParameter();
                }
            });
        }).catch(e => {
            console.log(e);
        })
    };

    const { loading, error, data, refetch} = useQuery(GET_USER, {
        client: client,
        fetchPolicy: 'cache-and-network',
    });

    if(isExist(() => data.user.id)) {
        return (
            <div className='parameters-dashboard-container'>
                {!parameterDetails && <div className='parameters-dashboard-title'>Evaluation parameters dashboard</div>}
                {!parameterDetails &&<div className='parameters-dashboard-descr'>
                    Here you are available to manage your evaluation parameters:<br/>
                    - Check general activity on your evaluations inside each parameter<br/>
                    - Track your rating<br/>
                    - Add and change evaluation parameters
                </div>}
                {/*PARAMETERS*/}
                {!parameterDetails && <div className='expert-parameters-container'>
                    {(data.user.expert.parameters.map((p, i) =>
                        <div className='expert-param-wrapper'>
                            {console.log(p)}
                            <ExpertParamBlock
                                key={i}
                                parameter={p}
                                expert={data.user.expert}
                                onClick={ ()=>{toggleDetails(p)} }
                            />
                        </div>
                    ))}
                    {(addedParameters.map((p, i) =>
                        <div className='expert-param-wrapper'>
                            <ExpertParamBlock
                                key={i}
                                parameter={p}
                                expert={data.user.expert}
                                onClick={ ()=>{toggleDetails(p)} }
                            />
                        </div>
                    ))}

                    { (data.user.expert.parameters.length + addedParameters.length < 10) && <div className='expert-param-wrapper button-wrapper'>
                        <ButtonGreen
                            TitleButton={'Add new parameter'}
                            EventOnClick={AddParameter}
                            className={'add-parameter-button'}
                        />
                    </div>}

                    {addParameter && <AddExpertParamPopup
                        onClose={AddParameter}
                        listParameters={listParameters}
                        addedParameters={addedParameters}
                        user={data.user}
                        addParameter={createParameter}
                    />}

                </div>}

                {/*DETAILS*/}
                {parameterDetails && <div className='expert-parameters-container' style={{paddingTop:'0'}}>
                    <ParameterDetailsBlock
                        parameterDetails={parameterDetails}
                        backClick={()=>{ toggleDetails(null)} }
                        expert={data.user.expert}
                        {...props}
                    />

                </div>}
            </div>
        )
    } else {
        return 'Load'
    }
};
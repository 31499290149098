import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import user_ico from '../../../images/svg/user-solid.svg'
import plen18 from '../../../images/svg/RocketDAO-Congratulations.svg'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import history from "../../../history";


class Step18 extends React.Component {
    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if (!onlyStep) {

        }

        let loading = nextStep === 17 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true});

        this.props.updateExpert({...expert, event_type: 'expert_metamask_14'}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }

    render() {
        let {user, expert, errors} = this.state;
        return (
            <React.Fragment>
                <div className="container-full-reg " style={{position: 'relative', padding:'0 0 200px 0'}}>
                    <img src={plen18} alt="" className="img-18-step-reg-expert new-no-display-down-1199"/>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Congratulations!<br/>
                            Your expert profile has been registered!
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            Now you are an important member of a decentralized expert community!
                        </p>

                        <div className="shell-button-reg-expert shell-margin-bottom-80">
                            <div className="block-button-user-img-blue" onClick={() => history.push('/profile_preview')}>
                                <img src={user_ico} alt=""/>
                                <p className="title-button">
                                    I want to view my profile
                                </p>
                            </div>
                        </div>


                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                            Earn ROCK tokens right now!
                        </p>
                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                            ROCK is a ERC20 utility token that provides <br/>
                            access to platform’s functionality.
                        </p>

                        <div className="shell-button-reg-expert shell-margin-bottom-20">
                            <ButtonGreen
                                TitleButton={'Earn ROCK tokens in a few steps'}
                                EventOnClick={() => this.update(3, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="shell-button-reg-expert">
                            <ButtonGray
                                TitleButton={'Skip and explore the platform'}
                                EventOnClick={() => window.location.href = '/'}
                                paddingButton={'30'}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step18.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step18)
import React from 'react';

import './stylesBlackButtonProfile.css'

export default class BlackButtonProfile extends React.Component {

    render() {
        let {
        	ButtonTitle,
            onClick
        } = this.props;

        
        return (
            <div>
                <div className='black-button-profile' onClick={onClick}>
                	<div className='black-button-title'>{ButtonTitle}</div>
                	<div className='black-button-arrow'/>
                </div>
            </div>
        );
    }
}
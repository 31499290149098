import i18next from 'i18next';
import en from './locale/en.js';
import ru from './locale/ru.js';
import { initReactI18next } from 'react-i18next';

const resources = {
    en,
    ru
};

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: ['en', 'ru'],
        lng: 'en',
        resources
    })

export default i18next
import React from 'react';
import './style.scss'

const IconHearth = ({children, className, type, disable=false, onClick=()=>{}}) => {

    return (
        <div className={`service-button icon ${type} ${className} ${disable?'disable':''}`} onClick={()=>{onClick()}}>
            {children && <div className="title">{children}</div>}
        </div>
    )
};

export default IconHearth;
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import $ from "jquery";
import {updateStartup} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {regexUrl} from './../../../utils/RegExp'
import Input from "../../controls3.0/input/Input";
import {getLastProject, updateProject} from "../../../actions/Project";
import Slider from 'react-rangeslider'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import CheckBox from "../../controls3.0/checkBox/checkBox";
import {getMoneyView} from '../../../utils/transformation'

const MIN = 5000
const MAX = 5000000

class Step9 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            value: {min: 100000, max: 700000}
        }
    }

    componentDidMount() {
        let project = this.state.project;
        if (project.id) this.calculate()
        if (!this.state.project.id) this.props.getLastProject()

        let valuesSlider = $('.input-range__label-container')
        valuesSlider.each((key, item) => {
            let value = item.innerText
            item.innerText = getMoneyView(value, true)
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id && !this.state.project.id) {
            this.setState({project: nextProps.project}, () => this.calculate())
        }
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value

        this.setState({value: {...this.state.value, [name]: value}})
    }

    onChangeRange = value => {
        this.setState({value: value})
    }

    onBlurInput = (e) => {

        let name = e.target.name,
            value = e.target.value;
        value = value.replace(/\s+/g, '');


        switch (name) {
            case 'min':
                if (Number(value) < MIN) value = MIN;
                if (Number(value) >= this.state.value.max) value = this.state.value.max;
                break;

            case 'max':
                if (Number(value) < this.state.value.min) value = this.state.value.min;
                if (Number(value) > MAX) value = MAX;
                break;
        }
        this.setState({value: {...this.state.value, [name]: value}});
    }

    onChangeInput = (e) => {
        let name = e.currentTarget.name,
            value = e.currentTarget.value;

        value = value.replace(/\D/, "");

        if (typeof value == 'string') value = value.replace(/\s+/g, '');

        this.setState({value: {...this.state.value, [name]: value}});
    }

    onCheck = () => {
        this.setState({project: {...this.state.project, ['noTurnover']: !this.state.project.noTurnover}})
    }

    calculate = () => {
        let project = this.state.project;
        if (project.turnover) this.setState({value: project.turnover})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            noTurnover: project.noTurnover,
            event_type: 'startup_turnover_10'
        };

        if(nextStep === 10 && onlyStep){
            obj.event_data = {
                skip: true
            }
        }

        if (!obj.noTurnover) obj.turnover = this.state.value;


        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    // updateS = (onlyStep, nextStep) => {
    //     this.props.updateStartup({}, onlyStep, nextStep)
    //         .catch(err => {
    //             this.setState({loading: false})
    //         })
    // }


    render() {
        let {project} = this.state;


        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-step-1-reg" style={{position: 'relative'}}>
                            <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                Average monthly turnover (in USD)
                            </p>
                            <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-20">
                                If you are already a profitable startup what is your monthly turnover or revenue?
                            </p>
                            <p className="text-button color-text-reg-gray margin-0">
                                If you are non-profitable yet, then put a tick below.
                            </p>
                        </div>
                    </div>

                    <div className="container-step-1-reg container-bottom block-gradient-gray-reg padding-reg"
                         style={{position: 'relative'}}>

                        {/*<div>*/}
                        {/*<div>Turnover: {project.turnover}</div>*/}
                        {/*<input type="range" name="turnover" value={project.turnover} onChange={this.onChange} min="5000" max="5000000" />*/}
                        {/*<br/>*/}
                        {/*</div>*/}

                        <div
                            className="block-input-range slider slider-green shell-margin-bottom-40 margin-top-50">
                            <p className="title-input-range" style={{minHeight: '45px'}}>Turnover, $

                                {!project.noTurnover &&
                                <div className='data-slider-turnover'>
                                    <span className='subtext'>from</span>
                                    <input type="text" name="min" value={parseInt(this.state.value.min).moneyFormat()}
                                           onChange={this.onChangeInput} onBlur={this.onBlurInput}/>
                                    {/*<span className="value">{getMoneyView(this.state.value.min)}</span>*/}
                                    <span className='subtext'>to</span>
                                    <input type="text" name="max" value={parseInt(this.state.value.max).moneyFormat()}
                                           onChange={this.onChangeInput} onBlur={this.onBlurInput}/>
                                    {/*<span className="value">{getMoneyView(this.state.value.max)}</span>*/}
                                </div>
                                }
                            </p>

                            <div className={`container-input-range ${project.noTurnover ? 'disable' : ''}`}>
                                <InputRange
                                    minValue={5000}
                                    maxValue={5000000}
                                    step={500}
                                    value={this.state.value}
                                    onChange={value => this.onChangeRange(value)}/>
                            </div>
                            <p className="title-left-right-input-range">
                                <span className="left">5k</span>
                                <span className="right">5m+</span>
                            </p>

                            <div className='container-inline-checkbox' onClick={() => this.onCheck()}>
                                <CheckBox
                                    onClick={() => {
                                    }}
                                    active={project.noTurnover}
                                />
                                No turnover yet
                            </div>


                            {/*<p className="title-left-right-input-range">*/}
                            {/*<span className="left">0</span>*/}
                            {/*<span className="right">{MAXCS}</span>*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign: 'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(8, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(10, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(10)}
                                paddingButton={'54'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step9.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step9)

import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import {Row} from "../Grid/Row";

export const InvestorListItem = ({key, href, item, onClick, listIndustries}) => {
    const {User} = item;

    const _avatar = User.logoFileId ?
        <div className="avatar"
             style={{backgroundImage: `url(${`${config.proxy}/file/${User.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('avatar', User.firstName);

    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className='all-card-block slide-up'
                 onClick={onClick}>
                <div className='all-card-top'>
                    {_avatar}
                    <div className='info-conteiner'>
                        <div className='title'>{item.role}</div>
                        <div className='name'>{item.User.firstName} {item.User.lastName}</div>
                        {/*<span className="check">*/}
                        {/*    <i className="fal fa-check"/>*/}
                        {/*</span>*/}
                    </div>
                </div>
                <div className='all-card-content'>
                    <div className='description subtitle'>{item.professionalDescription}</div>
                    <div className='location subtitle'>{item.User.country}</div>
                    <div className='industries title'>Industries</div>

                    {listIndustries && item.InvestorProfile && item.InvestorProfile.industry && !!item.InvestorProfile.industry.length &&
                        <div className="industries-list">
                            {item.InvestorProfile.industry.slice(0, 4).map(id => {
                                return listIndustries.find((a) => a.id === id) ?
                                    <span>{listIndustries.find((a) => a.id === id).title}</span> : ''
                            })}
                            {item.InvestorProfile.industry.length > 4 &&
                            <span> {item.InvestorProfile.industry.length} more… </span>}
                        </div>
                    }

                </div>
                <Row className='all-card-bottom'>

                    <div className='col-6 fund-size'>
                        <div className='title'>Investments</div>
                        <div className='subtitle'>{item.InvestorProfile.investmentsCount || 0}</div>
                    </div>

                    {item.InvestorProfile && item.InvestorProfile.investmentSize &&
                    <div className='col-6 investment-size'>
                        <div className='title'>Investment size</div>
                        <div
                            className='subtitle'>${item.InvestorProfile.investmentSize.min.toMoneyFormat2()}-{item.InvestorProfile.investmentSize.max.toMoneyFormat2()}</div>
                    </div>
                    }
                </Row>
            </div>
            </a>
        </div>
    )
};
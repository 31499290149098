import React, {useEffect, useState} from 'react';
import './style.scss'
import logo_69pixels from '../img/logo_69pixels.png';
import logo_slon from '../img/logo_slon.svg';
import logo_pixelplex from '../img/logo_pixelplex.png';
import logo_sbh from '../img/logo_sbh.png';
import {Scrollbars} from "react-custom-scrollbars";
import ButtonGreen from "../../../controls3.0/button/ButtonGreen";
import CloseButton from '../../../v4/closeButton'
import ButtonBorder from "../../../controls3.0/button/ButtonBorder";

const data = {
    'sbh': {
        descr:'SBH Law Office provides legal assistance in the field of dispute resolution to individuals, including individual entrepreneurs, legal entities and state both in the courts of the Republic of Belarus, as well as in international arbitration courts.',
        location:'Belarus',
        siteLink:'https://sbh-partners.com/en/',
        siteTitle:'sbh-partners.com',
        contacts: {
            name:'Elena Tomchuk',
            position:'Head of Marketing'
        },
        clutch:null,
        email:'elena.tomchuk@sbh-partners.com',
        logo:logo_sbh
    },
    '69pixels': {
        descr:'Service design and UI/UX design agency. We bring UX design and innovation to the startups, software companies and enterprise from AR and mobile apps to promo campaigns and marketing websites.',
        location:'Belarus',
        siteLink:'https://69pixels.com/',
        siteTitle:'69pixels.com',
        contacts: {
            name:'Ilya Gets',
            position:'CEO'
        },
        clutch:'https://clutch.co/profile/69pixels',
        email:'i.gets@69pixels.com',
        logo:logo_69pixels
    },
    'pixelplex': {
        descr:'An award-winning software development company, combining backgrounds in blockchain, artificial intelligence, big data, cybersecurity, and cloud development with cutting-edge web and mobile technologies.',
        location:'Minsk, New York, Geneva, Seoul, Amsterdam ',
        siteLink:'https://pixelplex.io/',
        siteTitle:'pixelplex.io',
        contacts: {
            name:'Yulia Gushchina',
            position:'Head of Business Development'
        },
        clutch:'https://clutch.co/profile/pixelplex',
        email:'y.gushchina@pixelplex.io',
        logo:logo_pixelplex
    },
    'slon': {
        descr:'A video marketing company that creates, promotes and distributes visual content on social, web and mobile platform',
        location:'Belarus',
        siteLink:'https://slonmedia.com/',
        siteTitle:'slonmedia.com',
        contacts: {
            name:'Vadim Kheifets',
            position:'Commercial Director and Partner'
        },
        clutch:'https://clutch.co/profile/slon-media',
        email:'vadim@slonmedia.com',
        logo:logo_slon
    }
};

const useDetailsPopup = ({company, parentCallback}) => {

    return (
        <div className='popup-wrapper'>
            <div className='details-popup-container'>
                <CloseButton
                    onClick={parentCallback}
                />

                <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={window.innerHeight}>
                    <div className={`top-block ${company==='slon'?'slon-paddings':''}`}>
                        <img src={data[company].logo} alt="" className="cursor-pointer" onClick={()=>{window.open(data[company].siteLink);}}/>
                        <div className='description-title description-block'>
                            {data[company].descr}
                        </div>
                        <div className='details-title location-block'>
                            Location: {data[company].location}
                        </div>
                        <div className='details-title site-block'>
                            Site: <span onClick={()=>{window.open(data[company].siteLink);}}>{data[company].siteTitle}</span>
                        </div>
                        <div className='details-title margin-top-12'>
                            Contact person: <div style={{display:'inline-block'}}>
                                {data[company].contacts.name},
                                <span className='position-text'>
                                    {' ' + data[company].contacts.position}
                                </span>
                            </div>
                        </div>
                        {data[company].clutch && <div className='details-title shell-margin-top-8'>
                            <span onClick={()=>{window.open(data[company].clutch);}}>Clutch</span> review:
                            <div className='clutch-stars-wrapper'>
                                <div className='clutch-stars'/>
                            </div>
                        </div>}
                    </div>
                    <div className='bottom-block'>
                        <ButtonGreen
                            TitleButton={'Send email'}
                            EventOnClick={() => { window.open(`mailto:${data[company].email}`, '_self') }}
                            paddingButton={'32'}
                            disabled={false}
                        />
                        {company === 'slon' && <div className='slon-bottom-container'>
                            <div className='description-title descr-block'>
                                Details about this offer: “Video marketing” product is actually a growth strategy which includes:
                            </div>
                            <div className='format-block'>
                                <div className='format-title'>
                                    Format 1
                                </div>
                                <div className='format-descr'>
                                    Validation of the hypotheses with the help of videos.
                                </div>
                                <div className='format-descr font-weight-300'>
                                    In a video you can present your product in the way you see it and distribute among different channels: PR, digital media, social media targeting, emails - show it and receive feedback, improve your vision accordingly.
                                </div>
                            </div>
                            <div className='format-block'>
                                <div className='format-title'>
                                    Format 2
                                </div>
                                <div className='format-descr'>
                                    Video presentations for investors and crowdfunding campaigns.
                                </div>
                                <div className='format-descr font-weight-300'>
                                    You want to convince investors that your team is worth being invested in, or you are planning to launch a fundraising campaign on Kickstarter - just let potential investors ‘see it on their own’.
                                </div>
                            </div>
                            <div className='format-block'>
                                <div className='format-title'>
                                    Format3
                                </div>
                                <div className='format-descr'>
                                    Product/promo video (for Product Hunt, for example).
                                </div>
                                <div className='format-descr font-weight-300'>
                                    The aim of such type of video content is to clearly explain the features, advantages and functionality of your product or service, which can be done in 30 seconds.
                                </div>
                            </div>
                            <div className='row-b prices-wrapper'>
                                <div className='col-12 description-title padding-bottom-15'>
                                    For formats 1-3, we suggest partner prices:
                                </div>
                                <div className='col-12 col-sm-4 price-col'>
                                    <div className='row-b'>
                                        <div className='col-8 col-sm-12'>
                                            <div className='details-title'>
                                                Standard
                                            </div>
                                            <div className='descr-text descr-text-wrapper'>
                                                If based on already existing graphic materials with simple design
                                                <br/>
                                                From $850
                                            </div>
                                        </div>
                                        <div className='col-4 col-sm-12'>
                                            <div onClick={()=>{window.open('https://vimeo.com/295760763')}} className='example-button example-button-text'>Example</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4 price-col'>
                                    <div className='row-b'>
                                        <div className='col-8 col-sm-12'>
                                            <div className='details-title'>
                                                Premium
                                            </div>
                                            <div className='descr-text descr-text-wrapper'>
                                                Complicated design and graphics, several shooting scenes
                                                <br/>
                                                From $2500
                                            </div>
                                        </div>
                                        <div className='col-4 col-sm-12'>
                                            <div onClick={()=>{window.open('https://vimeo.com/353807195')}} className='example-button example-button-text'>Example</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-4 price-col'>
                                    <div className='row-b'>
                                        <div className='col-8 col-sm-12'>
                                            <div className='details-title'>
                                                Animation
                                            </div>
                                            <div className='descr-text descr-text-wrapper'>
                                                From $2500
                                            </div>
                                        </div>
                                        <div className='col-4 col-sm-12'>
                                            <div onClick={()=>{window.open('https://vimeo.com/329089446')}} className='example-button example-button-text'>Example</div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className='format-block last-format'>
                                <div className='format-title'>
                                    Format 4
                                </div>
                                <div className='format-descr'>
                                    Video advertising for targeting/retargeting in social media, display ads; video creatives for mobile stores.
                                    <br/>
                                    From $1200 for 10 creatives.
                                </div>
                            </div>
                        </div>}
                    </div>
                </Scrollbars>
            </div>

        </div>
    )
};

export default useDetailsPopup;
import React from 'react';
import Dropzone from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'

import packageJSON from './../../../package.json'
import uploadImg from '../../images/photo-upload.png'
import defaultAvatar from '../../images/default_avatar.png'
import './chooseAvatar.css'


export default class ChooseAvatar extends React.Component {
    getImage = () => {
        if(this.state.image) {
            this.editor.getImageScaledToCanvas().toBlob(function (blob) {
                return blob;
            }.bind(this), 'image/jpeg', 1);
        }
    }
    handleSave = data => {
        const img = this.editor.getImageScaledToCanvas().toDataURL()
        const rect = this.editor.getCroppingRect()

        this.setState({
            preview: {
                img,
                rect,
                scale: this.state.scale,
                width: this.state.width,
                height: this.state.height,
                borderRadius: this.state.borderRadius
            }
        })
    }
    handleScale = e => {
        const scale = parseFloat(e.target.value)
        this.setState({ scale })
    }
    handleAllowZoomOut = ({ target: { checked: allowZoomOut } }) => {
        this.setState({ allowZoomOut })
    }
    rotateLeft = e => {
        e.preventDefault()

        this.setState({
            rotate: this.state.rotate - 90
        })
    }
    rotateRight = e => {
        e.preventDefault()
        this.setState({
            rotate: this.state.rotate + 90
        })
    }
    handleBorderRadius = e => {
        const borderRadius = parseInt(e.target.value)
        this.setState({ borderRadius })
    }
    handleXPosition = e => {
        const x = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, x } })
    }
    handleYPosition = e => {
        const y = parseFloat(e.target.value)
        this.setState({ position: { ...this.state.position, y } })
    }
    handleWidth = e => {
        const width = parseInt(e.target.value)
        this.setState({ width })
    }
    handleHeight = e => {
        const height = parseInt(e.target.value)
        this.setState({ height })
    }
    setEditorRef = editor => {
        if (editor) this.editor = editor
    }
    handlePositionChange = position => {
        this.setState({ position })
    }
    handleNewImage = e => {
        this.setState({ image: e.target.files[0] })
    }

    constructor(props) {
        super(props);
        this.state = {
            allowZoomOut: false,
            positiD: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 0,
            preview: null,
            width: 512,
            height: 512
        };

        this.onCrop = this.onCrop.bind(this)
        this.onCropDefault = this.onCropDefault.bind(this)
        this.onCloseDefault = this.onCloseDefault.bind(this)
    }

    onDrop(accepted, rejected) {
        if (accepted.length) {
            this.setState({image: accepted[0]});
            this.handleScale({target:{value:'1.01'}})
        }
        if (rejected.length) {
            this.setState({fileError: 'The uploaded file seems to have incorrect format'})
        }
    }

    onCropDefault(preview) {
        this.setState({defaultPreview: preview, newAvatar: true})

        let binary = atob(preview.split(',')[1]);
        let array = [];
        for(let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        let img =  new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
        this.setState({avatar: img})
    }

    onCrop(preview) {
        this.setState({preview})
    }

    onCloseDefault() {
        this.setState({defaultPreview: null, newAvatar: false, preview: null})
    }

    logCallback (e) {
        console.log('callback', e)
    }

    render() {
        let {
            fileId,
            editability
        } = this.props

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        let styleCanvas = {
            'width': '200px',
            'height': '200px',
        }

        function ActionLink() {
            document.getElementById('input_load_file_2').click();
        }

        return (
            <div className="upload-photo reg-exp">

                <div className="person-avatar-block">
                    {fileId && <img src={`${packageJSON.proxy}/file/${fileId}/view`} alt={fileId}></img>}
                    {!fileId && <img src={defaultAvatar} alt="default"></img>}
                    {editability &&
                    <div className="button-circle-white">
                        <input
                            type="file"
                            className="button-upload"
                            id="input_load_file_2"
                            style={{'display': 'none'}}
                            onChange={this.handleNewImage}
                        />

                        {/*<div className="button-upload auth-upload" onClick={ActionLink}><p>New*/}
                            {/*File</p></div>*/}


                        <i className="fa fa-pencil" aria-hidden="true" onClick={ActionLink}></i>
                    </div>
                    }
                </div>


                {!this.state.image &&
                <Dropzone style={dropStyle} multiple={false} onDrop={this.onDrop.bind(this)}
                          accept="image/jpeg, image/png, image/gif" maxSize={5000000}>

                    <div className="block-drop-zone fix3">
                        <div className="block-title-description-drop-zone">
                            <p className="title-drop-zone">logo<br/>Upload</p>
                            <p className="description-drop-zone">Drag&Drop <br/>here</p>
                        </div>
                    </div>

                </Dropzone>
                }

                {this.state.image &&
                <React.Fragment>
                    <div>
                        <AvatarEditor
                            ref={this.setEditorRef}
                            scale={parseFloat(this.state.scale)}
                            width={this.state.width}
                            height={this.state.height}
                            position={this.state.position}
                            onPositionChange={this.handlePositionChange}
                            rotate={parseFloat(this.state.rotate)}
                            borderRadius={this.state.borderRadius}
                            onSave={this.handleSave}
                            onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
                            onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
                            onImageReady={this.logCallback.bind(this, 'onImageReady')}
                            onImageLoad={this.logCallback.bind(this, 'onImageLoad')}
                            onDropFile={this.logCallback.bind(this, 'onDropFile')}
                            color={[0, 0, 0, 0.8]}
                            style={styleCanvas}
                            image={this.state.image || uploadImg}
                        />
                    </div>
                    <br/>
                    <div>
                        <div>
                            Zoom:
                            <input
                                name='scale'
                                type='range'
                                onChange={this.handleScale}
                                min={this.state.allowZoomOut ? '0.1' : '1'}
                                max='4'
                                step='0.01'
                                defaultValue='1'
                            />
                        </div>

                        <br/>
                        <br/>


                        {/*<div className="block-button ">*/}
                            {/*<input type="file" className="button-upload" id="input_load_file_2"*/}
                                   {/*style={{'display': 'none'}} onChange={this.handleNewImage}/>*/}
                            {/*<div className="button-upload auth-upload" onClick={ActionLink}><p>New*/}
                                {/*File</p></div>*/}
                        {/*</div>*/}


                    </div>
                </React.Fragment>
                }

            </div>
        );
    }
}

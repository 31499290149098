import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import TelegramLoginButton from 'react-telegram-login';

import {signUpTelegram, updateExpert} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import illustration from '../../../images/svg/reminder_notify.svg'
import {regexEmail} from "../../../utils/RegExp";
import Swipe from "../../controls3.0/swipe/Swipe";
import {getProfileSettings, putProfileSettings, putProfile} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import {webNotification} from "../../../utils/webNotification";
import packageJSON from './../../../../package.json'
import ButtonSocialReg from '../../controls3.0/buttonSocialReg/ButtonSocialReg'



class Step15 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        user.email2 = user.email2 || user.email;
        this.state = {
            user: user,
            expert: expert,
            errors: {},
            data: {
                notifyEmail: false,
                notifyTelegram: false,
                notifyWeb: false
            },
            ignore: true,
            title: ''
        }
    }

    componentDidMount () {
        getProfileSettings(this.state.user.id)
            .then(res => {
                if(res) {
                    this.setState({data: res.data })
                }
            })
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        let errors = this.state.errors;
        value = value.trim();
        this.setState({
            user: {...this.state.user, [name]: value}
        })
        if(!value || regexEmail.test(value)) {
            delete errors [name]
        } else {
           errors [name] = 'Invalid e-mail address'
        }

        this.setState({errors})
    }
    onChangeSwipe = name => {
        let value = this.state.data[name]

        this.setState({
            data: {...this.state.data, [name]: !value}
        }, () => {
            if(name === 'notifyTelegram' && !value && this.state.user.telegramId) {
                window.open(packageJSON.tBot, "_blank");
            }
        })

    }


    update = (nextStep, onlyStep) => {
        let expert = this.state.expert,
            data = this.state.data,
            user = this.state.user,
            errors = this.state.errors;

        if(!onlyStep) {
            if(user.email2 && !Object.keys(errors).length) data.notifyEmail = true
            this.setState({loading: true})
            putProfile(user.id ,{email2: user.email2})
            putProfileSettings(data)
                .then(res => {
                    this.props.updateExpert(expert, onlyStep, nextStep)
                        .catch(err => {
                            showMessage({type: 'error', data: err})
                            this.setState({loading: false})
                        })
                })
                .catch(err => {
                    showMessage({type: 'error', data: err})
                    this.setState({loading: false})
                })
        } else {
            this.props.updateExpert(expert, onlyStep, nextStep)
                .catch(err => {
                    showMessage({type: 'error', data: err})
                })
        }
    }


    responseTelegram = response => {
        putProfile(this.state.user.id ,{telegramId: response.id})
            .then((res) => {
               this.setState({user: {...this.state.user, telegramId: response.id }})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
            })
    };



    testWebNotification = () => {
        webNotification({type: 'info', data: 'INFO'})
    }



    handlePermissionGranted(){
        console.log('Permission Granted');
        this.setState({
            ignore: false
        });
    }
    handlePermissionDenied(){
        console.log('Permission Denied');
        this.setState({
            ignore: true
        });
    }
    handleNotSupported(){
        console.log('Web Notification not Supported');
        this.setState({
            ignore: true
        });
    }

    handleNotificationOnClick(e, tag){
        console.log(e, 'Notification clicked tag:' + tag);
    }

    handleNotificationOnError(e, tag){
        console.log(e, 'Notification error tag:' + tag);
    }

    handleNotificationOnClose(e, tag){
        console.log(e, 'Notification closed tag:' + tag);
    }

    handleNotificationOnShow(e, tag){
        // this.playSound();
        console.log(e, 'Notification shown tag:' + tag);
    }

    playSound(filename){
        document.getElementById('sound').play();
    }

    handleButtonClick() {

        if(this.state.ignore) {
            return;
        }

        const now = Date.now();

        const title = 'Rocket DAO notification';
        const body = 'Hey man from the best platform ever';
        const tag = now;
        const icon = 'https://rocketdao.io/img/person/Tagiev.png';
        // const icon = 'https://rocketdao.io/static/media/logo_beta.84fa0a98.svg';
        // const icon = 'http://localhost:3000/Notifications_button_24.png';

        // Available options
        // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
        const options = {
            tag: tag,
            body: body,
            icon: icon,
            lang: 'en',
            dir: 'ltr',
            sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
        }
        this.setState({
            title: title,
            options: options
        });
    }


    render () {
        let {user, data, errors} = this.state;
        return (
            <React.Fragment>
                <div className="container-full-reg step-15 padding-reg" style={{position: 'relative'}}>

                    <div className="block-img-right new-no-display-down-1199">
                        <img src={illustration} alt=""/>
                    </div>

                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Reminders and notifications
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                            We will send you notifications about the new tasks and deadlines so that you <br/>
                            always know of the available opportunities and possible renumeration.
                        </p>


                        <div className="block-list-check-box-reg-expert" style={{width:'320px'}}>
                            <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-black">
                                We will send reminders to your email
                            </p>

                            <div className="shell-input-reg">
                                <Input
                                    type={'text'}
                                    name={'email2'}
                                    value={user.email2}
                                    onChange={this.onChange}
                                    placeholder={''}
                                    error={errors.email2}
                                />
                            </div>

                            <div className='block-for-swipe' style={{marginTop: '65px'}}>
                                <Swipe
                                    onClick={() => this.onChangeSwipe('notifyTelegram')}
                                    active={user.telegramId && data.notifyTelegram}
                                />
                                <div className="swipe-text">
                                    Telegram notifications
                                </div>
                            </div>

                            <TelegramLoginButton
                                dataOnauth={this.responseTelegram}
                                dataUserpic="false"
                                dataRadius="0"
                                botName="RocketDAOexpert_bot"
                                usePic='true'>
                            </TelegramLoginButton>


                            <div className='block-for-swipe' style={{marginTop: '65px'}}>
                                <Swipe
                                    onClick={() => this.onChangeSwipe('notifyWeb')}
                                    active={data.notifyWeb}
                                />
                                <div className="swipe-text">
                                    Browser push notifications
                                </div>
                            </div>

                            {/*<div style={{marginTop: '20px'}}>*/}
                                {/*<ButtonBlue*/}
                                    {/*TitleButton={'example browser push'}*/}
                                    {/*EventOnClick={() => this.handleButtonClick()}*/}
                                    {/*paddingButton={'40'}*/}
                                    {/*disabled={false}*/}
                                {/*/>*/}
                                {/*<Notification*/}
                                    {/*ignore={this.state.ignore && this.state.title !== ''}*/}
                                    {/*notSupported={this.handleNotSupported.bind(this)}*/}
                                    {/*onPermissionGranted={this.handlePermissionGranted.bind(this)}*/}
                                    {/*onPermissionDenied={this.handlePermissionDenied.bind(this)}*/}
                                    {/*onShow={this.handleNotificationOnShow.bind(this)}*/}
                                    {/*onClick={this.handleNotificationOnClick.bind(this)}*/}
                                    {/*onClose={this.handleNotificationOnClose.bind(this)}*/}
                                    {/*onError={this.handleNotificationOnError.bind(this)}*/}
                                    {/*timeout={5000}*/}
                                    {/*title={this.state.title}*/}
                                    {/*options={this.state.options}*/}
                                {/*/>*/}
                            {/*</div>*/}


                        </div>


                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(14, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(16)}
                                paddingButton={'56'}
                                disabled={ Object.keys(errors).length || !(user.email2 || data.notifyTelegram || data.notifyWeb)}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


            </React.Fragment>

        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step15.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step15)



import React from 'react';
import './style.css'
import {Scrollbars} from 'react-custom-scrollbars';
import {goToCart} from '../../../utils/innerLinks';

import Startup from  '../../startup4.0/main';
import Fund from  '../../fund4.0/main';
import Accelerator from  '../../accelerator4.0/main';
import Angel from  '../../angel_network4.0/main';
import Expert from  '../../expert4.0/main';
import Investor from  '../../investor4.0/main';
import User from  '../../user4.0/main';
import {getQueryVariable, getYoutubeEmbedURL, getYoutubePreview} from "../../../utils/youtube";
import {getStartup} from "../../../actions/Project";
import axios from "axios/index";
import {getViewStatus} from "../../../utils/viewCounter";

const typeNames = {
    'startup': 'startup',
    'startup_score': 'startup',
    'expert': 'expert',
    'angel': 'angel network',
    'fund': 'fund',
    'investor': 'investor',
    'accelerator': 'accelerator'
}

const fundSpType = {
    'simple': 'fund',
    'angels': 'angel'
}

export class CartPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , currentIndex: 0
            , totalCount: 0
            , allItems: []
        }
    }

    componentDidMount(){
        const {type} = this.state;
        if(type === 'startup') this.allProject();
        if(type === 'startup_score') this.allProjectScore();
        if(type === 'startup_evaluation') this.allProjectEvaluation();
        if(type === 'expert') this.allExpert();
        if(type === 'angel' || type === 'angel_network') this.allFund();
        if(type === 'fund') this.allFund();
        if(type === 'accelerator') this.allAccelerator();
        if(type === 'investor') this.allInvestor();
    }

    prev = () => {
        const prevItem = this.state.allItems[this.state.currentIndex-1];

        if(prevItem){
            this.setState({id: prevItem.id, currentIndex: this.state.currentIndex-1});
            if(prevItem.fundType){
                this.setState({type: fundSpType[prevItem.fundType]});
            }
        } else {
            this.setState({currentIndex: this.state.allItems.length}, this.prev);
        }
    }

    next = () => {
        const nextIndex = this.state.currentIndex + 1;
        const nextItem = this.state.allItems[nextIndex];
        if(nextItem){
            this.setState({id: nextItem.id});
            if(nextItem.fundType){
                this.setState({type: fundSpType[nextItem.fundType]});
            }

            this.setState({currentIndex: nextIndex})
        } else {
            this.setState({currentIndex: -1}, this.next);
        }
    }

    allProject = () => {
        axios
            .get('/v2/projects?needCount=true&isFinished=true&take=10000')
            .then(r => {
                const {data} = r.data;
                const id = this.state.id;
                this.setState({allItems: data, totalCount: data.length});
                const currentIndex = data.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    }

    allProjectEvaluation = () => {
        axios
            .get('/v2/projects?take=3&preliminary_evaluation=true&isFinished=true&needCount=true')
            .then(r => {
                const {data} = r.data;
                const id = this.state.id;
                this.setState({allItems: data, totalCount: data.length});
                const currentIndex = data.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })

    };

    allProjectScore = () => {
        axios
            .get('/v2/projects?expert_score=true&needCount=true&isFinished=true&take=10000')
            .then(r => {
                const {data, totalCount} = r.data;
                const id = this.state.id;
                this.setState({allItems: data, totalCount: totalCount});
                const currentIndex = data.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    }

    allExpert = () => {
        axios
            .get('/v2/experts?&role=expert&isFinished=true&take=10000')
            .then(r => {
                const {experts} = r.data;
                const id = this.state.id;
                this.setState({allItems: experts, totalCount: experts.length});
                const currentIndex = experts.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    }

    allAngel = () => {
        axios
            .get(`/v2/funds?needCount=true&type=angels&isFinished=true&take=10000`)
            .then(r => {
                const {experts} = r.data;
                const id = this.state.id;
                this.setState({allItems: experts, totalCount: experts.length});
                const currentIndex = experts.findIndex((item) => item.id === id);
                if(experts[currentIndex].fundType){
                    this.setState({type: fundSpType[experts[currentIndex].fundType]});
                }
                this.setState({currentIndex: currentIndex});
            })
    }

    allFund = () => {
        axios
            .get('/v2/funds?type=simple,angels&isFinished=true&needCount=true&take=10000')
            .then(r => {
                const funds = r.data.data;
                const id = this.state.id;
                this.setState({allItems: funds, totalCount: r.data.totalCount});
                const currentIndex = funds.findIndex((item) => item.id === id);

                if(funds[currentIndex].fundType){
                    this.setState({type: fundSpType[funds[currentIndex].fundType]});
                }

                this.setState({currentIndex: currentIndex});
            })
    }

    allAccelerator = () => {
        axios
            .get('/v2/accelerator?limit=10000')
            .then(r => {
                const rows = r.data.rows;
                const id = this.state.id;
                this.setState({allItems: rows, totalCount: r.data.count});
                const currentIndex = rows.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    };

    allInvestor = () => {
        axios
            .get('/v2/investor?&isFinished=true&take=10000')
            .then(r => {
                const {experts, total} = r.data;
                const id = this.state.id;
                this.setState({allItems: experts, totalCount: total});
                const currentIndex = experts.findIndex((item) => item.id === id);
                this.setState({currentIndex: currentIndex});
            })
    }


    render() {
        const  {id, type, totalCount, currentIndex, oneItem} = this.state;
        return (
            <div className='video-pitch-popup-block'>
                <div className='video-pitch-popup-inner-block startup'>
                    <div className='video-pitch-popup-header-block'>
                        <div className='title' onClick={() => console.log(typeNames[type])}>
                            {this.props.type2}
                        </div>
                        <div className='open-in-new-tab pc' onClick={() => goToCart(id, type, true)} style={(typeNames[type] === 'angel network')?{left:'38%'}:{}}>
                            Open {typeNames[type]} in new tab
                            <div className="share-icon">
                                <i className="fas fa-share-square"/>
                            </div>
                        </div>
                        <div className='open-in-new-tab mob' onClick={() => goToCart(id, type, true)}>
                            Open
                            <div className="share-icon">
                                <i className="fas fa-share-square"/>
                            </div>
                        </div>

                        <div className='close-title' onClick={ () => this.props.close('')}>
                            <p>Close</p>
                            <div className='close'/>
                        </div>
                        {!oneItem && <div className='page-number'>
                            <div className='prev-arrow-button' onClick={this.prev}>
                                <div className="arrow">
                                    <i className="far fa-chevron-down"/>
                                </div>
                            </div>
                            <div className='title'>{currentIndex+1}/{totalCount}</div>

                            <div className='next-arrow-button' onClick={this.next}>
                                <div className="arrow">
                                    <i className="far fa-chevron-down"/>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <Scrollbars style={{height: 'calc(100% - 80px)', width:'100%'}}>
                        <div className={`popup-wrap ${!getViewStatus()?'popup-wrap-adaptive':''}`}>
                            {this.state.type ==='startup' && this.state.id && <Startup id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='startup_score' && this.state.id && <Startup id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='startup_evaluation' && this.state.id && <Startup id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='angel' && this.state.id && <Angel id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='fund' && this.state.id && <Fund id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='investor' && this.state.id && <Investor id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='expert' && this.state.id && <Expert id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='accelerator' && this.state.id && <Accelerator id={this.state.id} user={this.props.user}/> }
                            {this.state.type ==='user' && this.state.id && <User id={this.state.id} user={this.props.user} match={ {params: {id: this.state.id}} }/> }
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }
}
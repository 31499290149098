import React from 'react';
import './style.css'
import {regexCyrillic} from "../../../utils/RegExp";
import {isExist} from "../../../utils/helper";


export const isExistV = (cb) => {
    try {
        return cb();
    } catch (e) {
        return '';
    }
}

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerError: '',
            innerClass: ''
        };
    }

    render() {
        let {
            type,
            name,
            value,
            placeholder,
            iconClass,
            onChange,
            onFocus,
            onBlur,
            error,
            autocomplete,
            iconImg,
            rightIcon,
            min,
            onKeyPress,
            onKeyUp,
            disabled,
        } = this.props
        let iClass = value ? iconClass + ' active' : iconClass;
        let icon = iconClass ? <i className={iClass}/> : null;
        let iImg = iconImg ? <i className='icon-img'><img className='img-icon' src={iconImg} alt="ic"/></i> : null;

        let err = error ? <p className="title-error">{error}</p> : null;
        let err2 = this.state.innerError ? <p className="title-error">{this.state.innerError}</p> : null;
        let classInput = value ? 'block-new-input active' : 'block-new-input'
        classInput += (iconClass || iImg) ? ' icon' : '';
        classInput += error ? ' error' : '';
        let rightIconView = rightIcon ? <span className="right-icon">{rightIcon}</span> : null;
        return (
            <div className={classInput + this.state.innerClass}>
                {icon || iImg}
                <input
                    type={type}
                    min={min}
                    name={name}
                    value={ isExistV(() => value)}
                    //must understand any language, not only English
                    onChange={ /*onChange ? (e) => {
                        if(!regexCyrillic.test(e.target.value)){
                            this.setState({innerError: false, innerClass: ''})
                            onChange(e);
                        } else {
                            this.setState({innerError: 'Only latin characters are allowed', innerClass: ' error'});
                            e.preventDefault();
                        }
                    }:*/ onChange }
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyPress={onKeyPress ? (e) => {
                            if (onKeyPress){
                                if(!regexCyrillic.test(e.target.key) || e.target.key === 8){
                                    this.setState({innerError: false, innerClass: ''})
                                    onKeyPress(e);
                                } else {
                                    this.setState({innerError: 'Only latin characters are allowed', innerClass: ' error'});

                                    e.preventDefault();
                                }
                            }
                        } : onKeyPress
                    }
                    onKeyUp={onKeyUp ? (e) => {
                        if(onKeyUp){
                            if(!regexCyrillic.test(e.target.key) || e.target.key === 8){
                                this.setState({innerError: false, innerClass: ''})
                                onKeyUp(e);
                            } else {
                                this.setState({innerError: 'Only latin characters are allowed', innerClass: ' error'});
                                e.preventDefault();
                            }
                        }

                        } : onKeyUp
                    }
                    placeholder={placeholder}
                    autoComplete={!autocomplete? 'off' : true}
                    disabled={disabled}
                />
                {rightIconView}
                {err || err2}
            </div>

        );
    }
}

const errors = {
    'INVALID_CURRENT_PASSWORD': 'Incorrect password'
}

export const getErrorMessage = (error) => {
    try{
        return errors[error] || error;
    }
    catch (e) {
        return error;
    }
}
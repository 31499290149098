import React from 'react';
import ButtonBlue from "../controls3.0/button/ButtonBlue";
import {Button} from "../v4/Button";
import Expert from './img/Expert.svg'
import Investor from './img/Investor.svg'
import StartUp from './img/StartUp.svg'
import Accelerator from './img/Accelerator.svg'
import './style.css'
import avatar_1 from "../../images/icon/1@2x-min.png";
import history from '../../history';

export default class  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(){
        if(!localStorage.RocketToken){
            history.push('/slush/auth')
        }
    }


    render() {
        let {
            state, type2
        } = this.props;
        return (
            <div className="block-reg-state-mine-reg">
                <div className="block-reg-start-up-mini-reg">
                {state === 'startup' ?
                        <React.Fragment>
                            <p className="block-title-reg-state-mine-reg">
                                Thanks for joining!
                                You are great!
                            </p>
                            <p className="block-description-reg-state-mine-reg">
                                We have already sent you an email with further instructions.
                            </p>

                            <div className="bg-end-reg-mini-reg" style={{background:`url(${StartUp})`}}/>
                        </React.Fragment>
                    : null}
                    {(state === 'investor' || state === 'fund' || state === 'angel_network') ?
                        <React.Fragment>
                            <p className="block-title-reg-state-mine-reg">
                                Thanks for joining!
                                You are great!
                            </p>
                            <p className="block-description-reg-state-mine-reg">
                                We have already sent you an email with further instructions.
                            </p>

                            <div className="bg-end-reg-mini-reg" style={{background:`url(${Investor})`}}/>
                        </React.Fragment>
                        : null}
                    {state === 'expert' ?
                        <React.Fragment>
                            <p className="block-title-reg-state-mine-reg">
                                Thanks for joining!
                                You are great!
                            </p>
                            <p className="block-description-reg-state-mine-reg">
                                We have already sent you an email with further instructions.
                            </p>

                            <div className="bg-end-reg-mini-reg" style={{background:`url(${Expert})`}}/>
                        </React.Fragment>
                        : null}
                    {state === 'accelerator' ?
                        <React.Fragment>
                            <p className="block-title-reg-state-mine-reg">
                                Thanks for joining!
                                You are great!
                            </p>
                            <p className="block-description-reg-state-mine-reg">
                                We will send further instructions very soon!
                            </p>

                            <div className="bg-end-reg-mini-reg" style={{background:`url(${Accelerator})`}}/>
                        </React.Fragment>
                        : null}

                    <div className="block-button-bottom">
                        <Button type={ (() => {
                            switch (state){
                                case 'startup':
                                    return 'pinok_pod_zad';

                                case 'fund':
                                case 'angel_investor':{
                                    return 'investor';
                                }
                                default: return state;
                            }
                        })() } height={48} onClick={() => history.push('/')}>Go to rocketdao.io</Button>

                    </div>
                        </div>

            </div>
        );
    }
}
import React, {useState, useEffect, useRef} from 'react';

import {Scrollbars} from 'react-custom-scrollbars';
import './style.css';
import {regexCyrillic} from "../../../utils/RegExp";

const TextArea = ({val, error, onChange, name, defaultHeight, placeholder, cols, rows, noPopUp = false, widthClass = 'profile-edit-popup-inner-block col-m60', widthClass2='' }) => {
    const [height, setHeight] = useState(0);
    const [height2, setHeight2] = useState(0);
    const [inputError, setInputError] = useState(null);
    const inputRef = useRef();
    const inputRef2 = useRef();
    const scrollRef = useRef();
    const scrollRef2 = useRef();

    useEffect(() => {
        setHeight2(0);
    }, [val]);

    useEffect(() => {
        if(inputRef){
            adjustHeight({
                target: inputRef2.current
            });
        }
    }, []);

    useEffect(() => {
        adjustHeight({
            target: inputRef2.current
        });
    }, [height2]);


    function adjustHeight(e){
        let height = e.target.scrollHeight ? (e.target.scrollHeight)+"px" : `${defaultHeight}px`;


        if(inputRef.current.selectionStart === inputRef.current.value.length){
            scrollRef.current.view.scrollTop = scrollRef.current.view.scrollTop + 18;
        }

        setHeight(height);
        setHeight2(height);
        if(parseInt(height) < defaultHeight){
            height = defaultHeight + 'px';
        }
        setHeight(height);
        setHeight2(height);
    }

    const onChaneFilter = (e) => {
        /*if( !regexCyrillic.test(e.target.value) ){
            setInputError(null);
            onChange(e);
        } else {
            setInputError('Only latin characters are allowed');
            e.preventDefault();
        }*/
        //fix for client, no longer need a restriction on writing text only in Latin
        setInputError(null);
        onChange(e);
    };

    return (
        <React.Fragment>
            <Scrollbars
                renderView={props => (
                    <div {...props} style={{ ...props.style, overflowX: 'hidden', maxHeight:`${defaultHeight+2}px`, minHeight:`${defaultHeight+2}px`  }} />
                )}
                renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                ref={scrollRef} autoHeight autoHide={true} autoHeightMin={defaultHeight+2} autoHeightMax={defaultHeight+2} className={'textarea'}>
                <div style={{height: !noPopUp ? 12 + parseInt(height) + 'px': parseInt(height) + 'px'}} className={'wrap'}>
                    <textarea cols={cols} rows={rows}
                        placeholder={placeholder} ref={inputRef} value={val} name={name} onChange={onChaneFilter}  style={{overflow: 'hidden', height: '100%', minHeight: `${defaultHeight}px`}}/>
                </div>
            </Scrollbars>
            <div className="block-new-input error" style={{height:'20px'}}>
                <p className="title-error">{error || inputError}</p>
            </div>

            <div className={`noShow ${widthClass}`}>
                <div className={`${widthClass2}`}>
                    <Scrollbars
                        renderView={props => (
                            <div {...props} style={{ ...props.style, overflowX: 'hidden', maxHeight:`${defaultHeight+2}px`, minHeight:`${defaultHeight+2}px`  }} />
                        )}
                        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                        ref={scrollRef2} autoHeight autoHide={false} autoHeightMin={defaultHeight+2} autoHeightMax={defaultHeight+2} className={'textarea'}>
                        <div style={{height: height2}} className={'wrap'}>
                            <textarea cols={cols} rows={rows} placeholder={placeholder} ref={inputRef2} value={val} name={name} onChange={onChaneFilter} onKeyUp={adjustHeight} style={{overflow: 'hidden', height: '100%', minHeight: `${defaultHeight}px`}}/>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </React.Fragment>
    )
};
//
export default TextArea;
import React from 'react';
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";

import './styles.css'
import config from "../../../../config";
import {createAvatar} from "../../../user/createAvatar";
import moment from 'moment';

export default class AcceleratorDeletePopup extends React.Component {

    render() {
        const member = this.props.member;

        return (
            <div className={`profile-edit-popup-block`}>
                <div className="block-members-popup block-delete-popup">
                <div className='close-title'>
                    <div className='close' onClick={() => this.props.close()}/>
                </div>
                <div className='block-member-card'>
                    <div className='block-body-team-list'>
                        <div className='content-box'>
                            {
                                member.User.logoFileId
                                && <div className="list-avatar" style={{background: `url(${config.proxy}/file/${member.User.logoFileId}/view)`}}/>
                                || <div className="list-avatar">{ createAvatar('avatar', member.User.firstName) }</div>
                            }
                            <p className='list-user-name' style={{minHeight: '48px'}}>{member.User.firstName} {member.User.lastName}</p>
                            <div className='list-position' style={{minHeight: '18px', marginTop:'7px'}}>{member.position}</div>
                            <p className='list-position list-descr' style={{minHeight: '36px', marginTop:'7px'}}>Has been registered as team member since {moment(member.updatedAt).format("DD MMM, YYYY")}</p>
                        </div>
                    </div>
                </div>
                <div className="members-text-title margin-top-25 font-weight-500">You are about to delete a person from accelerator</div>
                <div className='members-text-little-subtitle text-align-left' style={{marginTop: '8px'}}>
                Are you sure? This person will never be displayed in the project's profile anymore and will
                not be able to send a request to join you as well.</div>
                <div className='send-button-line margin-top-30'>
                    <div style={{float: 'left'}}>
                        <ButtonBlue
                            TitleButton={'Cancel'}
                            EventOnClick={() => this.props.close()}
                            paddingButton={'65'}
                            disabled={false}
                        />
                    </div>
                    <div style={{float: 'right'}}>
                        <ButtonBlue
                            TitleButton={'Delete'}
                            EventOnClick={() => this.props.delete()}
                            paddingButton={'67'}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
            </div>
        );
    }
}
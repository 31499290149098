import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";
import {showMessage} from "../../../utils/showMessage";
import {getListSkillsInvestor, getListSkillsByStringInvestor} from "../../../actions/Profile";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Tabs from "../../controls3.0/tabs/Tabs";
import Input from "../../controls3.0/input/Input";


class Step4 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel  || {
                skills: [],
            };
        
        this.state = {
            user: user,
            angel: angel,
            errors: {},
            inputSearch: '',
            suggestedArrSkills: [],
            searchArrSkills: [],
            skills_input: []
        }
    }

    componentDidMount () {
        getListSkillsInvestor(1)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({suggestedArrSkills: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })
    }

    addToSkills = (skill, inputType = null) => {
        let skills = this.state.angel.skills;
        let isFound = skills.find(x => x.title === skill.title)
        if (!isFound) skills.push(skill)

        if(inputType){
            if(this.state.skills_input.indexOf(inputType) === -1){
                this.setState({skills_input: [...this.state.skills_input, inputType] })
            }

            if(inputType === 'manual'){
                this.setState({inputSearch: ''});
            }
        }

        this.setState({angel: {...this.state.angel, skills}, empty: false})
    }

    addNew = () => {
        let skill = this.state.inputSearch;
        let obj = {title: skill}
        this.addToSkills(obj, 'manual')
        this.setState({inputSearch: ''})
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if (value[value.length-1] === ' ' && (this.state.inputSearch[this.state.inputSearch.length-1] === ' ' || this.state.inputSearch.length===0)) return;

        this.setState({[name]: value}, () => {
            if(value) {
                this.getByString(value)
            } else {
                console.log('test')
                this.setState({searchArrSkills: []})
            }

        })
    }

    getByString = str => {
        // this.setState({loading: true})
        getListSkillsByStringInvestor(str)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({searchArrSkills: res.data})
                this.setState({loading: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading: false})
            })
    }

    removeSkill = skill => {
        if(skill) {
            let skills = this.state.angel.skills;
            let filtered = skills.filter(x => x.title !== skill.title)
            this.setState({angel: {...this.state.angel, skills: filtered}})
        }
    }

    update = (nextStep, onlyStep) => {
        let angel = this.state.angel,
            obj = {skills: angel.skills};

        if(onlyStep){
            obj = {}
        }
        obj.event_type = 'network_skills_4';
        obj.event_data = {skills_input: this.state.skills_input};

        if(!onlyStep) this.setState({loading: true})
        this.props.updateAngel(obj, angel.id, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {user, angel, errors, suggestedArrSkills, searchArrSkills, loading} = this.state
        if(!angel.skills){
            angel.skills = [];
        }
        let mySkills = angel.skills.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'big'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => this.removeSkill(item)}
                />
            )
        })

        let searchSkills = searchArrSkills.map((item, key) => {
            if (!angel.skills.find((i) => i.id === item.id)) {
                return (
                    <Tabs
                        key={key}
                        TitleTab={item.title}
                        TypeTab={'small'}
                        EventOnClick={() => this.addToSkills(item, 'database')}
                        active={false}
                    />
                )
            }
        })

        let suggestedSkills = suggestedArrSkills.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item.title}
                    TypeTab={'small'}
                    EventOnClick={() => this.addToSkills(item, 'suggested')}
                    active={angel.skills.find((i) => i.id === item.id)}
                />
            )
        })

        return (
            <React.Fragment>


                <div className="container-full-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg padding-reg" style={{position: 'relative'}}>
                        <p className="description-page-reg-expert text-h1 left-text shell-margin-bottom-20 color-text-reg-black">
                            Areas of expertise
                        </p>
                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-20 color-text-reg-black">
                            Which specific competencies is your angel network proficient in? In which spheres could you help startups with?
                        </p>
                        {/*<p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20 color-text-reg-gray">*/}
                        {/*This step will enable you to find experts with similar experience to <br/>*/}
                        {/*share it and expand together.*/}
                        {/*</p>*/}

                        <div className="shell-tabs-reg shell-margin-bottom-30">
                            {mySkills}
                        </div>


                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-black">
                            Input skill tags here
                        </p>

                        <div className="block-list-button-input shell-margin-bottom-10">
                            <div className="shell-input-reg">
                                <Input
                                    type={'text'}
                                    name={'inputSearch'}
                                    value={this.state.inputSearch}
                                    onChange={this.onChange}
                                    placeholder={''}
                                    iconClass={null}
                                />
                            </div>
                            <div className="shell-input-button-add">
                                <ButtonBlue
                                    TitleButton={'Add new'}
                                    EventOnClick={() => this.addToSkills({title: this.state.inputSearch}, 'manual') }
                                    paddingButton={'30'}
                                    disabled={(this.state.inputSearch === '') || searchArrSkills.find((item) => item.title.toLowerCase() === this.state.inputSearch.toLowerCase())}
                                    loader={false}
                                />
                            </div>

                        </div>

                        <div className="shell-tabs-reg shell-margin-bottom-30">
                            {loading ?
                                'loading...'
                                :
                                searchSkills
                            }
                        </div>


                        <p className="description-page-reg-expert text-button left-text shell-margin-bottom-15 color-text-reg-black">
                            Suggested skills tags
                        </p>
                        <div className="shell-tabs-reg">
                            {suggestedSkills}
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(3, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(5)}
                                paddingButton={'56'}
                                disabled={!angel.skills.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step4.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step4)
import React from 'react';
import PreliminaryEvaluation from "./PreliminaryEvaluation"

import './style.css'

let descrs = ['Problem of the potential customers the product solves.', 'Releveance of the solution to the customers\' problems.'];

export default class evaluation extends React.Component {
    state = {
        stepNum:1
    };

    handleBack = () => {
        if (this.state.stepNum <= 1) return;
        this.setState({stepNum:--this.state.stepNum});
    };

    handleSave = () => {
        this.setState({stepNum:++this.state.stepNum});
    };


    render() {


        return (
            <div>
                <PreliminaryEvaluation
                    stepNum={this.state.stepNum}
                    stepAmount={16}
                    descr={descrs[this.state.stepNum-1]}
                    buttonBack={()=>{this.handleBack()}}
                    buttonSave={()=>{this.handleSave()}}
                />
            </div>
        )
    }
}
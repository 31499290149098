import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import CheckBox from '../../controls3.0/checkBox/checkBox'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ModalDocuments from '../../modal/ModalDocuments'

import Bitmap from '../../../images/svg/Bitmap.svg'

class Step16 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert
        }
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    update = (nextStep, onlyStep) => {
        let data = {
                term: this.state.user.term,
                privacy: this.state.user.privacy
            }

        let loading = nextStep == 15 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert(data, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }



    handleShowModal = type => {
        this.setState({typeModal:type}, () => {
            this.setState({showModal: true})
        })
    }

    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    addAgree = type => {
        if(type == 1) {
            this.setState({user: {...this.state.user, term : true}})
        } else if(type === 2) {
            this.setState({user: {...this.state.user, privacy : true}})
        }
        this.handleCloseModal()
    }

    render() {
        let user = this.state.user;
        return (
            <React.Fragment>
                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <img src={Bitmap} alt="" className="img-16-step-reg-expert new-no-display-down-1199"/>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>

                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                            Blockchain and web 3.0
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20">
                            Within Rocket DAO Expert the DAO principle implies an independent and honest review of
                            startups. <br/>
                            Random selection of experts will guarantee only unbiased assessments as no party will
                            know <br/>
                            in advance his/her evaluator/contractor.

                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-50"
                           style={{fontWeight: '600'}}>
                            All expert activities will be securely stored and recorded on blockchain.
                        </p>

                        <p className="title-top-page-reg-expert text-sub-title left-text shell-margin-bottom-30 color-text-reg-white-gray"
                           style={{fontWeight: '400'}}>
                            Before continuing with the registration please carefully <br/>
                            review the documents below and check the relevant boxes, <br/>
                            this way confirming that you agree with the terms and conditions.
                        </p>


                        <div className="block-list-button-check-box-reg-expert" style={{width:'270px'}}>
                            <div className="block-item-button-check-box-reg-expert" onClick={() => this.handleShowModal(1)}>
                                <div className="block-left-item-button-check-box-reg-expert">
                                    <CheckBox
                                        active={user.term}
                                    />
                                </div>
                                <div className="block-right-item-button-check-box-reg-expert">
                                    <p className="title">Terms of Service</p>
                                </div>
                                <a>
                                    <i className="fas fa-external-link-alt"/>
                                </a>
                            </div>
                            <div className="block-item-button-check-box-reg-expert" onClick={() => this.handleShowModal(2)}>
                                <div className="block-left-item-button-check-box-reg-expert">
                                    <CheckBox
                                        active={user.privacy}
                                    />
                                </div>
                                <div className="block-right-item-button-check-box-reg-expert">
                                    <p className="title">Privacy Policy</p>
                                </div>
                                <a>
                                    <i className="fas fa-external-link-alt"/>
                                </a>
                            </div>
                        </div>



                    </div>
                </div>


                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(17)}
                                paddingButton={'56'}
                                disabled={!user.term || !user.privacy}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>


                {this.state.showModal &&
                < ModalDocuments
                    type={this.state.typeModal}
                    addAgree={() => this.addAgree(this.state.typeModal)}
                    isAgree={this.state.typeModal === 1 ? user.term : user.privacy}
                    showModal={this.state.showModal}
                    handleCloseModal={this.handleCloseModal}
                    />
                }
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step16.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step16)
import React, {useEffect, useState} from 'react';
import LoginButtons from '../loginButtons';

import './style.scss'
import Input from "../../controls3.0/input/Input";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import history from "../../../history";
import {regexEmail} from "../../../utils/RegExp";
import {showMessage} from "../../../utils/showMessage";
import {login, tokenLogin} from "../../../actions/Login";
import {connect} from "react-redux";
import {updateMenu} from "../../../actions/menu";
import PropTypes from "prop-types";
import {Scrollbars} from "react-custom-scrollbars";

const  Index = ({close, state, tokenLogin}) => {
    let [errors, setErrors] = useState({});
    let [data, setData] = useState({email:'', password:''});

    const onChange = e => {
        let name = e.target.name,
            value = e.target.value;


        if (name === 'email') {
            setData({email: value, password:data.password})
        } else {
            setData({email: data.email, password:value})
        }

        let _data = data;

        let obj = {};
        for (let name in _data) {
            if(_data[name]) obj[name] = _data[name]
        }
        obj[name] = value;
        let _errors = validate(obj);
        setErrors(_errors);
    };

    const validate = (data) => {
        const _errors = errors;
        for (let name in data) {
            let value = data[name],
                required = false,
                limit = 300,
                minLength = 0;
            switch (name) {
                case 'email':
                    if(!regexEmail.test(value)) {
                        _errors[name] = 'Empty or invalid e-mail address'
                    } else {
                        delete _errors[name]
                    }
                    break;
                case 'password':
                    required = true;
            }

            if (required && !value) {
                _errors[name] = 'Field is required'
            } else if (required && value) {
                delete _errors[name]
            }
        }
        return _errors;
    };

    const l1ogin = () => {
        let _data = data;
        let _errors = validate(_data);
        setErrors(_errors);
        if(!Object.keys(_errors).length){
        //     this.setState({ loading: true })
            login(_data)
                .then((res)=>{
                    localStorage.setItem('RocketToken', res.data.token);
                    tokenLogin(res.data.token);
                    closePopup();
                })
                .catch(err => {
                    // this.setState({ loading: false })
                    showMessage({type: 'error', data: err})
                })
        } else {
            //alert('ERR');
        }
    }

    const closePopup = ()=> {
        close();
    };
    return (
        <React.Fragment>
            <div className={`white-bg-wrapper fixed ${state === 'content'?'login-content':''}`}>

                <div className='inner-popup' style={state === 'content'?{width:'100%'}:{}}>
                    {state !== 'content' && <div className='close-title'>
                        <div className='close' onClick={() => closePopup()}/>
                    </div>}

                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={window.innerHeight - 140}>
                        <div className='content-wrapper'>
                            <div className='title'>Want to see more?</div>
                            <div className='descr'>Authorize in any convenient way to continue exploring the platform:</div>
                            <div className='login-popup-buttons-wrapper'>
                                <LoginButtons
                                    ButtonsTitle='Authorize'
                                    closePopUp={closePopup}
                                />
                            </div>
                            {/*<div className='block-btns-soc block-email login-form'>*/}
                                {/*<Input*/}
                                    {/*type={'email'}*/}
                                    {/*name={'email'}*/}
                                    {/*value={data.email}*/}
                                    {/*onChange={onChange}*/}
                                    {/*error={errors.email}*/}
                                    {/*placeholder={'Email'}*/}
                                    {/*iconClass={'fal fa-envelope'}*/}
                                {/*/>*/}
                                {/*<Input*/}
                                    {/*type={'password'}*/}
                                    {/*name={'password'}*/}
                                    {/*value={data.password}*/}
                                    {/*onChange={onChange}*/}
                                    {/*error={errors.password}*/}
                                    {/*placeholder={'Password'}*/}
                                    {/*iconClass={'fal fa-lock-alt'}*/}
                                {/*/>*/}
                                {/*/!*<div className="forgot_pass" onClick={this.handleModalForgotPassword}>Forgot password</div>*!/*/}
                                {/*<ButtonBlue*/}
                                    {/*TitleButton={'Login'}*/}
                                    {/*EventOnClick={() => l1ogin()}*/}
                                    {/*paddingButton={'55'}*/}
                                    {/*disabled={Object.keys(errors).length}*/}
                                {/*/>*/}
                                {/*<div className='block-login-sign-up sign-up-text text-align-center'>New to Rocket DAO?<a onClick={() => {*/}
                                    {/*history.replace({...this.props.location, pathname: '/start_reg'})*/}
                                {/*}}>Sign up</a></div>*/}
                            {/*</div>*/}
                        </div>
                    </Scrollbars>
                </div>

            </div>

        </React.Fragment>
    )
};

// export default Index;

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

Index.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(Index)
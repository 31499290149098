import { LOADER_SPIN } from "../actions/Common";

const initialState = {
  status: false
};

export const loader = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case LOADER_SPIN:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
};

const translation = {
  translation : {
      test_message: 'WeloCEM',
      item: "{{count}} item",
      item_plural: "{{count}} items",
      member: "{{count}} member",
      member_plural: "{{count}} members",
      results:'Results',
      share:'Share',

      viral_test: {
          okayButton:'Glad to meet you!',
          result_share_h3: 'Amazing results!',
          result_share_h5: 'Share them with your friends!',
          lang:'en',
          questions: [
              'Which option describes best your team?',
              'Who is your CEO then?',
              'Do you appreciate them?',
              'How would you describe your team?',
              '',
              'Come on, show off, who you managed to gather in your team?',
              'Do you have clients? How many?',
              'The thing you are working on - is it an innovation?',
              '',
              'That is why we have a question: is your problem real?',
              'Where are you now?',
              'Choose the industry(s) which your product belongs to:',
              'Match the term and its definition. Let’s not cheat and forget about Google Search...',
              'How old is the market in which you operate / will appear soon?',
              'How much do your clients “cost”?',
              'What does your technical documentation look like?',
              'So which measures do you take?',
          ],
          answers:[
              [
                  {point:2, title:'A bunch of enthusiasts, assembled to change this world.'},
                  {point:4, title:'One leader, a few friends, relatives and one good guy.'},
                  {point:6, title:'Leader-geek, business-leader and a few hired professionals.'},
                  {point:8, title:'There is someone who rules, who controls the development of the product, who communicates with partners and several hired employees.'},
                  {point:10, title:'We are dope, there are many of us, we do even have a Chief Financial Officer and HR.'}],
              [
                  {point:0, title:'He is just a good guy.'},
                  {point:2, title:'Well, he doesn’t have that much experience, but he has a rich daddy.'},
                  {point:4, title:'He’s experienced - he’ve done some not successful releases.'},
                  {point:6, title:'He’s experienced - he has done at least one successful release.'},
                  {point:10, title:'He’s experienced - as a specialist, he participated in the creation of a world famous product.'},
                  {point:8, title:'He’s experienced - he was a supervisor in a large and successful company.'}],
              [
                  {point:0, title:'We have the whole garage, we can fit there, and when it’s hot outside we do even have sunlight!'},
                  {point:2, title:'Well, we are independent guys and we work remotely, sometimes we see each other in coworking space.'},
                  {point:4, title:'We have an office for 2-3 people, we work there in turns or we gather at someone\'s house.'},
                  {point:6, title:'We rent a good office in a rather environmentally friendly and not very criminal area of the city.'},
                  {point:8, title:'We have a decent office in a decent quarter.'},
                  {point:10, title:'We have a great office in a great quarter, all security measures have been taken and documented.'}],
              [
                  {point:4, title:'We have a friendly team of techies. We are always quiet, but on holidays we put on caps and sprinkle with sparkles.'},
                  {point:6, title:'We have everything according to plan: as the CEO said, it will be so.'},
                  {point:8, title:'Our team is motley: there are humanities, techies and guys \"I can do whatever you need\".'},
                  {point:8, title:'In our office, creativity is encouraged, periods of painstaking work are interspersed with creative noise and din.'},
                  {point:8, title:'Our team is not mono or even bi-gender, we have representatives from all races and different genders.'}],
              [],
              [
                  {point:0, title:'Our team is ambitious geeks from the province who want to conquer the whole world.'},
                  {point:2, title:'Our team has participants of various industry competitions in their professional fields.'},
                  {point:4, title:'Our team has winners of various industry competitions in their professional fields.'},
                  {point:6, title:'Our team has recognized industry experts, holders of various industry awards.'},
                  {point:8, title:'Our team has industry decision makers.'},
                  {point:10, title:'Our team has experts whose articles are published in recognized industry journals.'}],
              [
                  {point:2, title:'I am one and only user of my product!'},
                  {point:4, title:'All of my 2 friends are happy to use my product.'},
                  {point:6, title:'We test our product and dozen people use it for free.'},
                  {point:8, title:'We have started selling. Almost a hundred people bought the product.'},
                  {point:10, title:'We are already selling our product. The number of customers grows at times monthly.'}],
              [
                  {point:2, title:'We create a product that will blow up this world, but so far no one has heard about it.'},
                  {point:4, title:'We are making the analogue of the items which are already on the market, but more convenient and high-quality.'},
                  {point:6, title:'We create a product that is better than analogues, we already have followers and users.'},
                  {point:8, title:'We create a totally new product which will blow up the industry (like uber), it is also proven by first sales.'},
                  {point:10, title:'We have a completely new product, sales of which are only growing.'}],
              [],
              [
                  {point:0, title:'Our investor has an excess of money, is there any difference, what to develop?'},
                  {point:4, title:'Would there be a good product, there will always be a consumer for it.'},
                  {point:2, title:'We didn’t ask the market, we are the part of the market. What is interesting to us, will be interesting to market, too.'},
                  {point:6, title:'The market has not even thought about the need of our product, but we will conquer this world.'},
                  {point:8, title:'We showed the prototype of our product to several potential users and they were satisfied.'},
                  {point:10, title:'We are well aware of the market problem and regularly test the demand for the product, sales are growing.'}],
              [
                  {point:0, title:'There is an idea, we have defined a problem and the way how we will solve it.'},
                  {point:2, title:'We have described a concept of the product: value proposition, functional and component models, architecture solution.'},
                  {point:4, title:'We have conducted target audience analysis to validate their readiness to buy and use the product. MVP is ready, it is being tested.'},
                  {point:6, title:'We have already published the final version of the product (released it on the server / blockchain.'},
                  {point:8, title:'Our product is already being bought (a small number of users).'},
                  {point:10, title:'Our product is already bought (and there are a lot of users!).'}],
              [],
              ['EBITDA', 'OPEX', 'CAC','ARPPU', 'LTV', 'ARR'],
              [
                  {point:0, title:'Does market have an age?!?!? It’s not even human!'},
                  {point:2, title:'Our market has not yet appeared, we will form a BRAND NEW market with our product!'},
                  {point:4, title:'Of course, our market is slowly dying, but there are still many unoccupied niches on it.'},
                  {point:6, title:'We have a very young and rapidly growing market.'},
                  {point:8, title:'Our market is mature, we will have to fight for our place under the sun, and this idea excites us.'}],
              [
                  {point:0, title:'What do you mean how much do they “cost”? The customer is not a product to trade with.'},
                  {point:2, title:'Clients come to us themselves, organically. How do I know who, how many and when?'},
                  {point:4, title:'We have a separate budget for attracting customers, that\'s their cost. But we don\'t know how to calculate the cost of one client.'},
                  {point:6, title:'We do consider and know our average customer acquisition cost, but we don\'t know the market-average.'},
                  {point:8, title:'We know our customer acquisition cost, it is above market-average and we are working on it.'},
                  {point:10, title:'We have built a powerful system for attracting customers and each client costs us less than the average on the market, including the "organic" effect.'}],
              [
                  {point:0, title:'What is technical documentation?'},
                  {point:2, title:'We do not have any technical documentation, we are a startup! We know what we must do, even without it.'},
                  {point:6, title:'We have technical documentation and it resembles the artist’s chaotic notes, but I don’t know who writes it and why.'},
                  {point:8, title:'All specialists have their own documents written in the way they prefer. Some have serious documents, others - hardly understandable schemes.'},
                  {point:10, title:'Technical documentation is an important element of our project. All documents are used and developed on one standard.'}],
              [
                  {point:0, title:'We haven’t done anything yet, what kind of property?'},
                  {point:4, title:'We are all open source, let them steal, we will still do better.'},
                  {point:6, title:'We have what to protect, but the team is reliable and we do not take any special measures to protect intellectual property.'},
                  {point:8, title:'We know that they can steal from us, and we know what to do if this happens. We do nothing more.'},
                  {point:10, title:'We have registered a patent, trademark, copyright and other intellectual property.'}],
          ],
          jokes: [
              [
                  'So ambitious!',
                  'Well, what I can say? At least, you are always with your near and dear, isn’t it wonderful?',
                  'I have to admit this is a good combination.',
                  'I do like you. Let’s see how you are going to answer next questions.',
                  'Are you even a startup?!'],
              [
                  'Is he like a son of mom’s friend?',
                  'I hope daddy is involved in the project creation!',
                  'Oh, it’s fine. After all, experience comes through the recognition of errors, everything is ahead!',
                  'Oh wow! Team is lucky to have him.',
                  'ОOh come on, is it Elon Musk?',
                  'And now he’s about to become the creator of his first unicorn! Cool!'],
              [
                  'Guys, I have bad news for ya...',
                  'Freelancers, nothing more, nothing less...',
                  'Well, as they say, in cramped conditions, but not in insult.',
                  'Take care of each other! I hope you have a move just around the corner.',
                  'Well, yeah it’s good to do business with you. Love it when people are so committed.',
                  'I wish all startups would live like that!'],
              [
                  'You know how to go wild!',
                  'I think, the authoritarian regime is the last century. Don\'t you think so?',
                  'Proud of you!',
                  'I’m for creativity too! Keep it up!',
                  'I will raise a multicolor flag in your honor!'],
                  'Thanks! Our technical support team will contact you in 7,5 million years. Good luck!',
              [
                  'Oh wow, go for it, guys!',
                  'No way, you must be an expert in talent recruiting!',
                  'You gathered the top-stars around, that’s the spirit!',
                  'And how did you manage to gather such great minds?',
                  'Probably, all your Burn Rate goes on their salaries? Of course, I hope that this is not so...',
                  'The generation of new knowledge is the basis of development! I know that right...'],
              [
                  'Not bad, I guess there is a demand!',
                  'You are so lucky to have such devoted friends!',
                  'Looks like Crowd QA. Am I right?',
                  'You grow by leaps and bounds!',
                  'Soon you will surpass Facebook with such dynamics!'],
              [
                  'No worries, the key to good marketing is a good product. You will win all hearts!',
                  'Well, there is always room for improvement!',
                  'It’s dangerous to compete with you!',
                  'I can only wish you a good luck!',
                  'You know, I can’t wait to meet you personally!'],
              [],
              [
                  'Oh my, maybe you can share the investor’s contacts?',
                  'Well, optimism prolongs life :)',
                  'Be careful there with subjectivity.',
                  'This is called latent needs, an excellent direction, by the way.',
                  'Lean Startup in action! Brilliant!',
                  'Guys, it is 10/10!'],
              [
                  'You’ve started - it’s the main.',
                  'You march with seven-mile steps!',
                  'It is a pleasure to know that we are dealing with serious people here!',
                  'Hold on! The fun begins!',
                  'Can you show your traction?',
                  'PLEASE, DON’T STOP!'],
              'Nice choice, for sure, we will hear about you not even once!',
              [
                  'Now you know how it should be, you won’t make mistake anymore.',
                  'Seems like you used to be among excellent pupils!'],
              [
                  'Well, you are quite mistaken. ',
                  'May the force be with you, my friends!',
                  'Let’s hope for the best.',
		          'Well, it’s all in your hands.',
                  'Wow-wow, let the battle begin, I really like your spirit!'],
              [
                  'Quite a philanthropic position:)',
                  'Oh well, have you ever heard about analytics?',
                  'Well, you have a separate budget for marketing, of course not bad ...',
                  'This can become your competitive advantage, think about it ...',
                  'Just don’t give up!',
                  'You are not even a startup, reveal the truth! Am I right?'],
              [
                  'Guys, come on, are you serious?',
                  'Let’s discuss it in a year or two?',
                  'You shouldn’t be so in vain, you know!',
                  'The makings of consistency are very good!',
                  'Well, it’s just impossible to find fault with you!'],
              [
                  'I guess, none...',
                  'There is something charming in this open source....',
                  'You want to say you have no NDA? Well it’s your right...',
                  'Understanding risks is the first step towards minimizing them!',
                  'Now we can be sure for you!'],
          ],
          representation_text:`Hello! I am Deep Thought - the second greatest computer in the Universe of Time and Space, created specially by superintelligent Rocket DAO team to find the answers to the questions of startuppers.`,
          thinker_text:[
              ['When you will know the question - you will understand the answer… \nWell, my friend, let’s start easy.', 'It is a well-known fact that a startup’s success is about 30% dependent on the people who join it.'],
              'CEO - he is the soul and the main driving force of any project, agree?',
              'Your people are your everything.',
              'In varietate concordia (from latin. — «Unity in diversity»), right?',
              'Hm, maybe you want to ask me anything? (Grunting...)',
              'I once heard the myth that geniuses attract geniuses. So here is a question!',
              'Okay, jokes aside, serious stuff ahead.',
              'I am not the one who should tell you about the importance of the uniqueness of the solution of the product. But!',
              'Time to rest! You want me to share wisdom with you, don\'t you?',
              'There is such concept as - Jobs-to-be-done. It says that users “hire” a product to solve their own particular problem.',
              'Each product in its development goes through a series of successive stages.',
              'Startup’s value and its success are usually depend on the market trends. Do you know in which industry you can get on the wave of hype?',
              'Now let\'s test your knowledge of financial and marketing metrics:) Let’s not cheat and forget about Google Search...',
              'We all are serious people here, aren\'t we? The next question will be even more complicated than the previous ones...',
              'Now let’s talk about Customer Acquisition Cost.',
              'A few questions are left! Hang tight!',
              'You know how important it is to protect your intellectual property, don\'t you?',
          ],
          choicelist_questions:[
              {answer:2, title:'The total amount of all marketing efforts that were required to attract one new customer'},
              {answer:0, title:'Profit before interest, taxes, depreciation and accrued depreciation'},
              {answer:4, title:'A metric that shows how much money the average new user will pay for the entire time the product is used.'},
              {answer:3, title:'Average revenue per 1 paying user.'},
              {answer:5, title:'Normalized annual receipts during the subscription term (license validity). Such payments do not include simultaneous/one-time payments.'},
              {answer:1, title:'The company\'s daily expenses for conducting business, manufacturing goods and services, consists of expenses for office support, equipment rental, office rental, HR managers\' expenses for team building activities, constant office needs, expenses for soft and implementation of marketing campaigns.'},
          ],
          choicelist_title:'It\'s a test: read definitions one by one and match them with the correct metrics below.',
          choicelist_timeout:['Wrong', 'Right!', 'Next question in'],
          results:[
              {
                  name:'Growing',
                  type:'growing',
                  stage:'Idea',
                  cost:'up to $700 K',
                  investment:'up to $30 K',
                  descr:'In common terms, we can call your investment stage simply and clearly the “Idea”. You should seek investments from the FFF, because who, if not friends, relatives and ardent fans of your concept, will invest at such a risky stage. Alternatively, crowdfunding platforms. Maybe you will be lucky to convince some business angel. However, now you can attract no more than 30 thousand dollars for your development. In any case, you are the brave ones, once you decided to create your own project, keep it up.',
              },
              {
                  name:'Inspired',
                  type:'winged',
                  stage:'Pre-Seed',
                  cost:'$700 K - 1,5 M',
                  investment:'$30-150 K',
                  descr:'Step by step and you have reached the Pre-Seed Stage! Great! The average paycheck at this stage is about 30-150 thousand dollars. You can safely seek for support from business angels and actively work on MVP, participate in conferences, make yourself a name and receive feedback from the market. Pay particular attention to market research and building a strong, motivated team.',
              },
              {
                  name:'Wise',
                  type:'wise',
                  stage:'Seed',
                  cost:'$1,5 - 3,5 M',
                  investment:'$150-700 K',
                  descr:'Most likely you are already at the Seed stage. Now the average investment paycheck you can count on is from 150 thousand to 700 thousand dollars. Accordingly, your market price is equal to making the investments that you want to attract for the share that the investor will receive in your project. Your investors now are  business angels, funds of the early stages. In fact, this is one of the most difficult stages, because this is the period of validation of the business model and the first traction. Therefore, support for accelerators, coworking, incubators and other elements of the venture ecosystem is very relevant.',
              },
              {
                  name:'Determined',
                  type:'certain',
                  stage:'А Series',
                  cost:'$3,5 - 20 M',
                  investment:'$1 - 7 M',
                  descr:'After testing and approving the business model, demonstrating confident traction and customer experience, you gradually turn into a mature company and already go to Round A. You have everything to attract investments from venture funds and complete project packaging, clients, technologies, team and sales. The average investment paycheck that you can now claim is starting from 1 to 7 million dollars. Pay particular attention to the rational organization of the marketing campaign and the optimisation of management within the team.',
              },
              {
                  name:'Confident',
                  type:'resolute',
                  stage:'B Series',
                  cost:'$20+ M',
                  investment:'$7+ M',
                  descr: 'Classic venture capital funds, private equity funds - at your service, it\'s time to attract international capital and scale globally. Now you can count on an investment of $ 7 million and more. Work on top management, improving your competitive position and stimulating demand for your product. What else can I say. You are already in business, in big business. A great and difficult way has been done. You coped with it. You are already our hero.',
              },
          ],
          results_title:['My startup', 'Round', 'Valuation', 'Investments', 'Take the test again', 'Do you want to grow and develop faster?', 'Show your startup to investors and experts!'],
          user_question:['Go for it!','Your question','Send my question','No, thanks','I\'ll be waiting for the answer'],
          break_time:['Sure thing!','It is an important and popular fact that things are not always what they seem.','Nice'],
          choose_character:['Who are you today?', 'What a beginning!'],
          number_of_answer_title:'Already completed the test',
          start_test_title:['How much','is your startup worth?','Are you ready to know the answer?','Yes!'],
          test_loader:['Analyzing the answers', 'Difficult task...', 'Group (18)', 'Oh yes, I think it\'s high time for you to introduce yourself, before I show all the aces.', 'I\'m so excited to show you the results! Just a second...'],
          next:'Next',
          getBucks:['Get $6 000 ', 'for project\'s development'],
          getBucksPopup:{
              title:'What\'s next?',
              descr:'Complete some simple steps:',
              button:'Start with step 1',
              steps:[
                      {position: 1, title:'Create startup profile', descr:'Register your project on Rocket DAO platform, introduce it to our investing community.'},
                      {position: 2, title:`Fill in the \nevaluation form`, descr:'Answer the questions from experts, describe your product, business model, market, industry and team.'},
                      {position: 3, title:'Get the bright results!', descr:'Receive a detailed report with evaluation, feedback and recommendations from experts.'},
                  ],
              finalStep:{title:'Get free services  worth $6 000:', descr:' - Legal consultancy, \n- Full-service design, \n - MVP development,\n - Software development,\n - Video production.'}
          },
          NextButton:['Thanks!', 'Nice!', 'Hmm!', 'Wow!', 'I\'m pleased!', 'So caring!', 'Okay!', 'Ok.', 'Yeh.', 'Huh.', 'Right.']
      }
  }
};

export default translation;
import React from 'react';
import WayPoint from '../wayPoint/WayPoint'
import BlackButtonProfile from '../blackButtonProfile/BlackButtonProfile'

import EditProfileList from '../editProfileList/EditProfileList'
import config from "../../../../config";
import history from "../../../../history";


export default class InvestorMenuProfile extends React.Component {
    state = {
        openedMenu: 'edit'
    }

    menuOpen = (type) => {
        this.setState({openedMenu:type})
    }

    render() {
        let {
            investor
        } = this.props;

        return (
            <div className='fund-menu-profile'>
                <div className='block-fund-sidebar'>
                    <div className='block-fund-info'>
                        <div className='avatar' style={{backgroundImage: `url(${investor.logoFileId ? `${config.proxy}/file/${investor.logoFileId}/view`: ''})`}}/>
                        <div className='name-title'>{investor.firstName} {investor.lastName}</div>
                    </div>
                    <div className='block-fund-buttons-line'>
                        <BlackButtonProfile onClick={() => investor.investor ? window.open(`/investor/${investor.investor.id}`, '_blank') : null}
                            ButtonTitle='See profile'
                        />
                        <div className='button-edit-profile' onClick = {() => history.push('/edit/investor',{active:true})}>
                            <BlackButtonProfile 
                                ButtonTitle='Edit'
                            />
                        </div>
                    </div>

                    <div className='block-fund-way'>
                        <WayPoint
                            name='Profile'
                            descr={`${investor.investor.views} views`}
                            NumberActive={'active'}
                            isDescrActive={true}
                            number='1'
                            isLast={false}
                        />
                        <WayPoint
                            name='Investing'
                            descr='Will be available soon'
                            NumberActive={'closed'}
                            isDescrActive={false}
                            number=''
                            isLast={true}
                        />
                    </div>

                    <div className='block-main-menu'>
                        <div className='main-menu-title-block'>
                            <span className='main-menu-title' onClick = {() => history.push('/edit/investor/newsfeed',{active:true})}>Newsfeed</span>
                        </div>
                        <div className='main-menu-title-block'>
                            <span className='main-menu-title' onClick = {() => history.push('/edit/investor/waitlist',{active:true})}>Waitlist</span>
                        </div>
                    </div>
                </div>




                
                {/*<div className='block-fund-content'>*/}
                    {/*{(this.state.openedMenu === 'edit') && */}
                    {/*<div className='block-edit-profile'>*/}
                        {/*<EditProfileList*/}
                            {/*title='investor'*/}
                            {/*lastEdited='11.12.2019 - 19:47'*/}
                            {/*posted='11.12.2019 - 19:47'*/}
                        {/*/>*/}
                    {/*</div>}*/}

                    {/*{(this.state.openedMenu === 'newsfeed') && */}
                    {/*<div className='block-fund-newsfeed'>*/}
                        {/*<div className='main-title'>Investor newsfeed</div>*/}
                        {/*<div className='block-newsfeed-temp-text'>*/}
                            {/*<div className='title'><br/>News tab is now being developed. Here we will accumulate the latest and most accurate updates about the startups that you are following.</div>*/}
                        {/*</div>*/}
                        {/**/}
                    {/*</div>}*/}

                    {/*{(this.state.openedMenu === 'waitlist') && */}
                    {/*<div className='block-fund-waitlist'>*/}
                        {/*<div className='main-title'>Waitlist</div>*/}
                        {/**/}
                        {/*<div className='block-waitlist-temp-text'>*/}
                            {/*<div className='title'><br/>News tab is now being developed. Here we will accumulate the latest and most accurate updates about the startups that you are following.</div>*/}
                            {/**/}
                        {/*</div>*/}
                        {/*<div className='block-waitlist-temp-content'/>*/}
                    {/*</div>}*/}


                {/*</div>*/}

               

            </div>
        );
    }
}
import React from 'react';
export default class FundNewsfeed extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className='block-fund-waitlist block-page'>
                <div className='profile-dashboard-main-title font-weight-500'>Angel network newsfeed</div>
                <div className='block-newsfeed-temp-text'>
                    <div className='profile-dashboard-18-title'><br/>News tab is now being developed. Here we will accumulate the latest and most accurate updates about the startups that you are following.</div>
                </div>
                <div className='block-newsfeed-temp-content'/>
            </div>
        )
    }
}
import React from 'react';
import MetaTags from "react-meta-tags";
import Steps from './steps/components/step';
import Popup from './steps/components/popup';
import UserLeftBar from './pages/user/userLeftBar';
import UserProfile from './pages/user/profile';
import UserAccount from './pages/user/account';
import StartupDashboard from './pages/startup/dashboard';
import StartupCommunity from './pages/startup/community';
import StartupTeam from './pages/startup/team';

import AcceleratorNewsfeed from './pages/accelerator/newsfeed';
import AcceleratorStartups from './pages/accelerator/startups';
import AcceleratorTeam from './pages/accelerator/team';

import InvestorNewsfeed from './pages/investor/newsfeed';
import InvestorWaitlist from './pages/investor/waitlist';
import FundNewsfeed from './pages/fund/newsfeed';
import FundWaitlist from './pages/fund/waitlist';
import FundMembers from './pages/fund/members';

import AngelNetworkNewsfeed from './pages/angel_network/newsfeed';
import AngelNetworkWaitlist from './pages/angel_network/waitlist';
import AngelNetworkMembers from './pages/angel_network/members';

import {Parameters} from './pages/expert/parameters';
import EvalParamRoadMap from '../header/menu/expertMenuProfile/EvalParamRoadMap';

import {getLastProject, getMyProject, getMyProject2} from '../../actions/Project';
import {getMyFund, getMyAngelNetwork} from '../../actions/Fund';
import {getMyAccelerator} from '../../actions/Accelerator';

import {connect} from "react-redux";
import {createStartup, tokenLogin, updateStartup} from "../../actions/Login";
import PropTypes from "prop-types";
import {withRouter} from 'react-router';
import {updateMenu} from "../../actions/menu";
import history from "../../history";
import config from "../../config";
import PreliminaryEvaluationTable from "../startup4.0/preliminaryEvaluation/PreliminaryEvaluationTable";
import PayBanner from "../v4/payBanner";
import axios from "axios";
import Loader from "../test-controls/preloader/preloader";
import {notifyFollowers} from '../../actions/Project'
import avatarExpert510Erez from "../../images/newExperts/Erez.jpg";
import avatarExpert510Nick from "../../images/newExperts/Nick.jpg";
import {Container} from "../v4/Grid/Container";
import {ProfileContainer} from "../v4/Grid/ProfileContainer";
import {Row} from "../v4/Grid/Row";
import {isExist} from "../../utils/helper";
import './style.scss';

const ParsersEvaluation = {
    441:[
        {
            ParamName:'Product',
            ExpertId:252,
            ParamEvaluation:6.5,
        },
        {
            ParamName:'Market',
            ExpertId:401,
            ParamEvaluation:6.3,
        },
        {
            ParamName:'Business model',
            ExpertId:221,
            ParamEvaluation:7,
        },
        {
            ParamName:'Team',
            ExpertId:421,
            ParamEvaluation:6.3,
        },
    ],
    510:[
        {
            ParamName:'Product',
            ExpertId: 467,
            OuterLinkExpert: 'https://www.linkedin.com/in/erezcohen/',
            OuterNameExpert: 'Erez Cohen',
            OuterDescriptionExpert: 'I am an agile coach and a software professional, fulfilling a variety of roles such as developer, project manager, S&D team leader and product manager. I have a strive to learning and constant improvement.\n' +
                'I am passionate about working with individuals, teams and organizations helping them achieve their goals while focusing on happy clients, product quality and organization productivity. ',
            OuterAvatarExpert: avatarExpert510Erez,
            ParamEvaluation:6,
        },
        {
            ParamName:'Market',
            ExpertId:327,
            ParamEvaluation:4.5,
        },
        {
            ParamName:'Business model',
            ExpertId: null,
            OuterLinkExpert: 'https://www.linkedin.com/in/nickgraham-frsa/',
            OuterNameExpert: 'Nick Graham',
            OuterDescriptionExpert: 'A senior business change specialist with deep experience of innovating and delivering scalable, sustainable systems and culture change in highly complex environments. \n' +
                '\n' +
                'Accomplished programme leader with an integrated philosophy and skills application across strategy, technology, learning,development and business change. \n' +
                '\n' +
                'Proven track record of leading significant programmes/portfolios of projects managing complex stakeholder relationships across virtual and physical environments at all levels and settings.',
            OuterAvatarExpert: avatarExpert510Nick,
            ParamEvaluation:6,
        },
        {
            ParamName:'Team',
            ExpertId:421,
            ParamEvaluation:8.3,
        },
    ]
};

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , type: props.type
            , id: props.id
            , page: props.page
            , openedUserSetting:0
            , show: false
            , data: null
            , object: null
            , trueOrFalse: false
            , listExperts:[]
        };
    }


    async componentDidMount() {
        const {type, id} = this.props;
        if (type === 'startup' && id) {
            this.props.getMyProject2(id)
        }
        if (type === 'fund' && id) {
            this.props.getMyFund(id)
        }
        if (type === 'accelerator' && id) {
            this.props.getMyAccelerator(id)
        }

        if (type === 'angel_network' && id) {
            this.props.getMyAngelNetwork(id)
        }

        if (type === 'investor' || type === 'expert') {
            if (localStorage.RocketToken) {
                this.props.tokenLogin(localStorage.RocketToken);
            }
        }

        if(!this.state.listExperts.length){
            axios
                .get(`/v2/experts?isFinished=true&role=expert&take=200`)
                .then(r => {
                    if (r.data.experts.length) {
                        this.setState({listExperts: [...this.state.listExperts, ...r.data.experts]});
                    }
                })
        }
    }

    componentDidUpdate(prevProps) {
        if( this.props.id !== prevProps.id || this.props.type !== prevProps.type || this.props.page !== prevProps.page) {
            this.componentDidMount();
            // this.props.updateMenu({type: 'close'});
        }
        console.log(this.props.page, this.props.type);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.page === 'account' || nextProps.page === 'profile') {
            this.setState({openedUserSetting:0});
        }
    }

    showPopup(e, key, type, object) {
        this.setState({step: key, show: true, object: object});
        document.getElementsByTagName('BODY')[0].style.overflow = 'hidden';
    }

    closePopup() {
        this.setState({show: false});
        this.props.updateMenu({type: 'update'});
        document.getElementsByTagName('BODY')[0].style.overflow = 'inherit';
    }

    // activeRight() {
    //     if (this.props.type === 'expert') {
    //         let state1 = {active: false, activeLeft: false}
    //         history.push(state1)
    //     } else {
    //         let state1 = {active: false, activeLeft: true}
    //         history.push(state1)
    //     }
    // };

    getPropByType = () => {
        if (this.props.type === 'fund') {
            return this.props.fund;
        }

        if (this.props.type === 'accelerator') {
            return this.props.accelerator;
        }

        if (this.props.type === 'angel_network') {
            return this.props.fund;
        }

        if (this.props.type === 'startup') {
            return this.props.project.project;
        }

        if (this.props.type === 'investor') {
            if (this.props.user) {
                return {...this.props.user, ...this.props.user.investor};
            } else {
                return null;
            }
        }
        if (this.props.type === 'user') {
            if (this.props.user) {
                return {...this.props.user};
            } else {
                return null;
            }
        }

        if (this.props.type === 'expert') {
            if (this.props.user) {
                return {...this.props.user, ...this.props.user.expert};
            } else {
                return null;
            }
        }
    }

    getIsMobile = () => {
        let isMobile = false;

        try {
            isMobile = !!(window.navigator && window.navigator.standalone || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i));
        } catch (ex) {
            // continue regardless of error
        }
        return isMobile;
    };

    isEvaluated = () => {
        try {
            // return (this.props.project.project.id === 441 || this.props.project.project.id === 510)

            return (ParsersEvaluation[this.props.project.project.id])
        } catch {
            setTimeout(()=>{return this.isEvaluated()}, 100)
        }
    };

    setOpenedUserSetting = (section) => {
        if (section === 3 && this.props.page==='account') {
            window.open('/document');
        } else {
            this.setState({openedUserSetting:section})
        }
    };

    render() {
        const {show, step, object} = this.state;
        const propData = this.getPropByType();

        console.count('main');

        return (
            <React.Fragment>
                <ProfileContainer className={this.props.type}>
                    <Row className='min-height-100 m16'>
                        <div className={`col-300 ${this.props.type !== 'user' && 'd-xs-none'}`}>

                            {this.props.type === 'user' &&
                            <UserLeftBar
                                user={this.props.user}
                                openedSection={this.state.openedUserSetting}
                                handleClick={(section)=>{this.setOpenedUserSetting(section)}}
                                page={this.props.page}
                            />}
                            </div>
                        <div className={`col-12 col-sm`} style={{background:'#FDFDFD'}}>
                            <div className='profile-content-wrapper'>
                                {!this.props.page && propData &&
                                <Steps type={this.props.type} onClick={this.showPopup.bind(this)} data={propData}/>}

                                {this.props.page === 'dashboard' && this.props.type === 'startup' &&
                                <StartupDashboard startup={this.props.project.project}/>}
                                {this.props.page === 'community' && this.props.type === 'startup' && <StartupCommunity/>}
                                {this.props.page === 'team' && this.props.type === 'startup' &&
                                <StartupTeam startup={this.props.project.project}/>}
                                {this.props.page === 'evaluation' && this.props.type === 'startup' &&
                                <div className='margin-top-22'>
                                    {this.isEvaluated() &&
                                    <div>{(this.state.listExperts.length !== 0) && <PreliminaryEvaluationTable
                                        FullVersion={false}
                                        startup={this.props.project.project}
                                        Evaluation={ParsersEvaluation[this.props.project.project.id]}
                                        user={this.props.user}
                                        listExperts={this.state.listExperts || []}
                                    />|| <Loader loaded={this.state.listExperts.length !== 0}/>}
                                    </div> ||
                                        <div className='pay-banner-container'>
                                            <PayBanner
                                                user={this.props.user}
                                            />
                                        </div>}
                                </div>}

                                {this.props.page === 'newsfeed' && this.props.type === 'investor' && <InvestorNewsfeed/>}
                                {this.props.page === 'waitlist' && this.props.type === 'investor' && <InvestorWaitlist/>}

                                {this.props.page === 'newsfeed' && this.props.type === 'fund' && <FundNewsfeed/>}
                                {this.props.page === 'waitlist' && this.props.type === 'fund' && <FundWaitlist/>}
                                {this.props.page === 'members' && this.props.type === 'fund' &&
                                <FundMembers fund={this.props.fund}/>}

                                {this.props.page === 'newsfeed' && this.props.type === 'accelerator' && <AcceleratorNewsfeed/>}
                                {this.props.page === 'startups' && this.props.type === 'accelerator' && <AcceleratorStartups/>}
                                {this.props.page === 'team' && this.props.type === 'accelerator' && this.props.accelerator.id &&
                                <AcceleratorTeam accelerator={this.props.accelerator}/>
                                }

                                {this.props.page === 'newsfeed' && this.props.type === 'angel_network' &&
                                <AngelNetworkNewsfeed/>}
                                {this.props.page === 'waitlist' && this.props.type === 'angel_network' &&
                                <AngelNetworkWaitlist/>}
                                {this.props.page === 'members' && this.props.type === 'angel_network' &&
                                <AngelNetworkMembers fund={this.props.fund}/>}

                                {this.props.page === 'profile' && this.props.type === 'user' &&
                                <UserProfile user={this.props.user} openedSection={this.state.openedUserSetting}/>}

                                {this.props.page === 'account' && this.props.type === 'user' &&
                                <UserAccount fund={this.props.user} openedSection={this.state.openedUserSetting}/>}

                                {this.props.page === 'parameters' && this.props.type === 'expert' && this.props.user &&
                                <Parameters user={this.props.user} {...this.props}/>}
                            </div>
                        </div>
                    </Row>
                </ProfileContainer>

                {show && object && <Popup type={this.props.type} step={step} object={object} data={propData}
                                          close={this.closePopup.bind(this)}/>}

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project,
    fund: state.fund,
    accelerator: state.accelerator
})

Main.propTypes = {
    getMyProject2: PropTypes.func.isRequired,
    user: PropTypes.object,
    updateMenu: PropTypes.func
}

export default withRouter(connect(mapStateToProps, {
    getMyProject2,
    getMyFund,
    updateMenu,
    tokenLogin,
    getMyAngelNetwork,
    getMyAccelerator,
})(Main))


//export default connect(mapStateToProps, {getMyProject2})(Main)
export const getFundInfo = fund => {
  return {
    type: "GET_FUND_INFO",
    fund
  };
};
//
export const getProjectInfo = project => {
  return {
    type: "GET_PROJECT_INFO",
    project
  };
};

export const updateFundInfo = (fund) => {
    return {
        type: 'UPDATE_FUND_INFO',
        fund
    }
}
//
// export const getBonus = bonus => {
//   return {
//     type: "GET_PROJECT_BONUS",
//     bonus
//   };
// };
//
// export const getTransaction = transaction => {
//   return {
//     type: "GET_TRANSACTION_STATUS",
//     transaction
//   };
// };

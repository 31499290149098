import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import {Row} from "../Grid/Row";
import {VerificationIcon} from "../VerificationIcon";
import {isExist} from "../../../utils/helper";

export const AcceleratorListItem = ({key, href, item, onClick}) => {
    const _avatar = item.logoFileId ?
        <div className="avatar"
             style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('avatar', item.title);

    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className='all-card-block slide-up'
                 onClick={onClick}>
                <div className='all-card-top'>
                    {_avatar}

                    <div className='info-conteiner'>
                        <div className='accelerator-name-title font-weight-300'>Accelerator</div>
                        <div className='name'>{item.title}</div>
                        {item.isProof && <VerificationIcon type={'accelerator'} className={'fly'}/>}
                    </div>
                </div>
                <div className='all-card-content'>
                    <div className='description subtitle' style={{fontSize:'16px'}}>{item.shortDescription.descriptionFormat()}</div>
                    <div className='location subtitle'>{item.location}</div>
                    <div className='accelerator-name-title' style={{margin:'16px 0 8px 0'}}>Type</div>
                    <span className="accelerator-type-block">{item.type}</span>
                </div>
                <Row className='all-card-bottom'>
                    <div className='col-6 investment-size'>
                        <div className='title'>Total startups</div>
                        <div className='subtitle'>{item.startupsTotal}</div>
                    </div>
                    <div className='col-6 fund-size'>
                        <div className='title'>Funding up to</div>
                        <div
                            className='subtitle'>${isExist(() => item.fundingUpTo.toMoneyFormat2())}</div>
                    </div>
                </Row>
            </div>
            </a>
        </div>
    )
};
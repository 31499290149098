import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {LinkedinShareButton, TwitterShareButton, FacebookShareButton} from "react-share";
import MetaTags from "react-meta-tags";
import { FacebookProvider, Share, ShareButton } from 'react-facebook';

import {updateExpert, shareLinkedin, share} from "../../../actions/Login";
import ava from '../../../images/ava.jpg'

import linkedin from '../../../images/svg/linkedin-in-brands.svg'
import facebook from '../../../images/svg/facebook-f-brands (1).svg'
import twitter from '../../../images/svg/twitter-brands (1).svg'
import plen18 from "../../../images/svg/RocketDAO-great-rock.svg";
import ButtonGreen from "../../controls3.0/button/ButtonGreen";
import ButtonIconImgCheckBox from '../../controls3.0/buttonIconImgCheckBox/ButtonIconImgCheckBox'
import Group19 from "../../../images/Post-RocketDAO-mini.png";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
// import Share from '../../controls3.0/Share'
import $ from "jquery";

class Step19 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        this.state = {
            user: user,
            expert: expert,
            made: false,
            data: {
                url: 'https://expert.rocketdao.io',
                title: 'It looks really great. Join!'
            },
            ln: false,
            fb: false,
            tw: false
        }
    }

    update = (nextStep, onlyStep) => {
        let expert = this.state.expert;
        if (!onlyStep) {

        }

        let loading = nextStep === 6 ? 'loadingBack' : 'loading';
        this.setState({[loading]: true})

        this.props.updateExpert(expert, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }

    share = (type) => {

        // let url;
        // url  = 'http://www.facebook.com/sharer.php?s=100';
        // url += '&p[title]='     + encodeURIComponent(ptitle);
        // url += '&p[summary]='   + encodeURIComponent(text);
        // url += '&p[url]='       + encodeURIComponent(purl);
        // url += '&p[images][0]=' + encodeURIComponent(pimg);
        //
        // window.open(url,'','toolbar=0,status=0,width=626,height=436');

        share(type)
            .then(res => {
                this.setState({[type]: true})
            })

        let u = 'http://test.rocketico.io',
            t = 'test'

        // if (type == 'fb') {

            // window.open(`https://www.facebook.com/dialog/share?app_id=5303202981&display=popup&href=${'http://test.rocketico.io'}&redirect_uri=https%3a%2f%2fstatic.addtoany.com%2fmenu%2fthanks.html%23url%3dhttps%3A%2F%2Fblog.rocketdao.io%2Fcontent%2Fwhat-disruptive-business-model-consists&quote=`)




            // var url = "http://www.facebook.com/sharer.php?s=100";
            // url += "&p[url]=" + encodeURIComponent(this.state.data.url);
            // url +=
            //     "&p[images][0]=" +
            //     encodeURIComponent($('meta[property="og:image"]').attr("content"));
            // window.open(url, 600, 500);
            // return false

            // window.open('http://www.facebook.com/sharer.php?u='+ encodeURIComponent(u)+'&t='+encodeURIComponent(t)+`&quote=${this.state.data.description}`,'sharer','toolbar=0,status=0,width=626,height=436');return false;

        // }



        if (type == 'ln') {
            // window.open('https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Frocketico.io&title=testTItle&summary=&source=AddToAny')


            // window.open('https://linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(u) + '&title= '+ t +'&summary=', 'twitterwindow', 'height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
            // window.open('https://www.linkedin.com/shareArticle?mini=true&url=http://developer.linkedin.com&title=LinkedIn%20Developer%20Network\n' +
            //     '&summary=My%20favorite%20developer%20program&source=LinkedIn')
            // return false
        }
    }

    onShareWindowClose = e => {
        console.log(e)
    }



    render() {
        let {data} = this.state;
        let countRocks = 0;
        if(this.state.fb) countRocks += 2;
        if(this.state.ln) countRocks += 2;
        if(this.state.tw) countRocks += 2;


        if (!this.state.made) {
            return (
                <React.Fragment>
                    {/*<MetaTags>*/}
                        {/*<meta*/}
                            {/*property="og:description"*/}
                            {/*content={data.description}*/}
                        {/*/>*/}
                        {/*<meta property="og:title" content={data.title}/>*/}
                        {/*<meta*/}
                            {/*property="og:text-twitter"*/}
                            {/*content={data.description}*/}
                        {/*/>*/}
                        {/*<meta property="og:image" content={data.image}/>*/}
                    {/*</MetaTags>*/}


                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>


                        <p className="title-top-page-reg-expert text-h1 left-text margin-0">
                            ROCK tokens are almost yours!
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-60">
                            Share information about our platform on your social media account <br/>
                            and receive 2 ROCKs for each post.
                        </p>

                        <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                            Choose social networks
                        </p>

                        <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-40">
                            You will earn <span style={{color: '#0ECFAA', fontWeight: '500'}}>2 ROCKS</span> for 1 share
                        </p>



                        <div className="shell-margin-bottom-30">
                            {/*<ButtonIconImgCheckBox*/}
                                {/*TitleButton={'Share on LinkedIn'}*/}
                                {/*EventOnClick={() => this.share('ln')}*/}
                                {/*icon={linkedin}*/}
                                {/*img={null}*/}
                                {/*colorText={'#0E76A8'}*/}
                                {/*activeCheckBox={this.state.ln}*/}
                            {/*/>*/}

                            <LinkedinShareButton
                                url={data.url}
                                // title={data.title}
                                // description={data.description}
                                // source={'https://bipbap.ru/wp-content/uploads/2017/07/love-29.jpg'}
                                // onShareWindowClose = {this.onShareWindowClose}
                            >
                                <ButtonIconImgCheckBox
                                    TitleButton={'Share on LinkedIn'}
                                    EventOnClick={() => this.share('ln')}
                                    icon={linkedin}
                                    img={null}
                                    colorText={'#0E76A8'}
                                    // CheckBoxDisabled={true}
                                    activeCheckBox={this.state.ln}
                                />
                            </LinkedinShareButton>
                        </div>
                        <div className="shell-margin-bottom-30 block-for-social-btn">
                            {/*<FacebookProvider appId="415695435674008">*/}
                                {/*<ShareButton href={window.location.origin}>*/}
                                    {/*<ButtonIconImgCheckBox*/}
                                        {/*TitleButton={'Share on Facebook'}*/}
                                        {/*EventOnClick={() => this.share('fb')}*/}
                                        {/*icon={facebook}*/}
                                        {/*img={null}*/}
                                        {/*colorText={'#3B5998'}*/}
                                        {/*activeCheckBox={this.state.fb}*/}
                                    {/*/>*/}
                                {/*</ShareButton>*/}
                            {/*</FacebookProvider>*/}




                            <FacebookShareButton
                                url={data.url}
                                // hashtag={data.hashtag}
                                // quote={data.description}
                            >
                                <ButtonIconImgCheckBox
                                    TitleButton={'Share on Facebook'}
                                    EventOnClick={() => this.share('fb')}
                                    icon={facebook}
                                    img={null}
                                    colorText={'#3B5998'}
                                    // CheckBoxDisabled={true}
                                    activeCheckBox={this.state.fb}
                                />
                            </FacebookShareButton>
                        </div>




                        {/*<div className="shell-margin-bottom-30">*/}
                        {/*<ButtonIconImgCheckBox*/}
                        {/*TitleButton={'Michael Rocket'}*/}
                        {/*EventOnClick={null}*/}
                        {/*icon={ic_medium}*/}
                        {/*img={ava}*/}
                        {/*colorText={'#00AD6C'}*/}
                        {/*activeCheckBox={true}*/}
                        {/*/>*/}
                        {/*</div>*/}
                        <div className="shell-margin-bottom-30">
                            <TwitterShareButton
                                url={data.url}
                                title={data.title}
                                // hashtags={data.hashtags}
                            >
                                <ButtonIconImgCheckBox
                                    TitleButton={'Share on Twitter'}
                                    EventOnClick={() => this.share('tw')}
                                    icon={twitter}
                                    img={null}
                                    colorText={'#55ACEE'}
                                    // CheckBoxDisabled={true}
                                    activeCheckBox={this.state.tw}
                                />
                            </TwitterShareButton>
                        </div>

                        <img src={Group19} alt="" className="img-19-step-reg-expert new-pos-relative-down-1199 new-width-100-down-520 new-top-0-down-1199"/>

                    </div>



                    <div className="block-fix-button-reg-bottom fix-button-one-details-one">
                        <div className="container-full-reg" style={{textAlign:'right'}}>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Skip this step'}
                                    EventOnClick={() => this.update(21, true)}
                                    paddingButton={'24'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Next'}
                                    EventOnClick={() => this.setState({made: true})}
                                    paddingButton={'56'}
                                    disabled={!countRocks}
                                    loader={this.state.loading}
                                />
                            </div>
                        </div>
                    </div>


                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="container-full-reg" style={{position: 'relative'}}>
                        <img src={plen18} alt="" className="img-18-step-reg-expert new-no-display-down-1199" style={{right: '-80px'}}/>
                        <div className="container-step-1-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>

                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                Great! <br/>
                                You have just earned <span style={{color: '#0ECFAA'}}>{countRocks} ROCK</span> tokens!
                            </p>

                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                                Here your Rocket DAO Expert journey successfully began!
                            </p>

                            <div className="shell-button-reg-expert shell-margin-bottom-80">
                                <ButtonBlue
                                    TitleButton={'That is enohugh for a start. Receive tokens now'}
                                    EventOnClick={() => this.update(22, true)}
                                    paddingButton={'24'}
                                    disabled={false}
                                />
                            </div>
                            <p className="title-top-page-reg-expert text-h3 left-text shell-margin-bottom-15">
                                Want to earn more ROCK tokens?
                            </p>
                            <p className="title-top-page-reg-expert text-button-3 left-text shell-margin-bottom-30">
                                We have prepared so many interesting tasks for you, ooh... <br/>
                                Excited?
                            </p>

                            <div className="shell-button-reg-expert shell-margin-bottom-20">
                                <ButtonGreen
                                    TitleButton={'How can I earn more ROCK tokens?'}
                                    EventOnClick={() => this.update(21, true)}
                                    paddingButton={'24'}
                                    disabled={false}
                                />
                            </div>
                            <div className="shell-button-reg-expert">
                                <ButtonGray
                                    TitleButton={'Skip and explore the platform'}
                                    EventOnClick={() => window.location.href = '/'}
                                    paddingButton={'40'}
                                    disabled={false}
                                />
                            </div>

                        </div>
                    </div>

                </React.Fragment>
            )
        }

    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step19.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step19)


import React, {useState, useEffect} from 'react';
import './styles.css'
import Input from "../../../controls3.0/input/Input";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import ListMailMore from "../../../controls3.0/listMailMore/ListMailMore";
import ButtonBorder from "../../../controls3.0/button/ButtonBorder";
import {showMessage} from "../../../../utils/showMessage";
import {sendInvitation} from "../../../../actions/Fund";
import {regexEmail} from "../../../../utils/RegExp";
import {Scrollbars} from "react-custom-scrollbars";


const EditStep2 = ({fund, close, parentCallback}) => {
    const [email, setEmail] = useState('');
    const [team, setTeam] = useState([]);
    const [sended, setSended] = useState(false);
    const [errors, setErrors] = useState({});

    const [ref_f] = useState(`${window.location.origin}/start_reg?ref_f=${fund.id}`);

    const onChange = event => {
        let {value} = event.target;
        value = value.trim();

        if (regexEmail.test(value)) {
            delete errors.email
        } else {
            errors.email = 'Invalid e-mail address'
        }

        if (fund.team.find((item) => item.email === value) || fund.User.email === value || team.find((item) => item.email === value)) {
            errors.email = 'Exist address'
        }

        setEmail(value);
    };

    const addToList = () => {
        const obj = {email};

        if (fund.team.find((item) => item.email === email) || fund.User.email === email || team.find((item) => item.email === email)) {
            errors.email = 'Exist address'
        } else {
            delete errors.email;
            setTeam([...team, obj]);
            setEmail('');
        }


    };

    const send = () => {
        sendInvitation(fund.id, {members: team})
            .then((res) => {
                setSended(true);
            })
            .catch(error => {

            })
    };

    const removeFromList = email => {
        const filtered = team.filter((item) => {
            return item.email !== email
        });

        setTeam([...filtered]);
    }

    return (
        <React.Fragment>
            <div className={`profile-edit-popup-block`}>
                <div className="block-members-popup block-invite-popup">
                    <Scrollbars style={{height: '80vh'}}>
                        <div style={{padding: '0 40px 0 0'}}>
                            <div className='close-title'>
                                <div className='close' onClick={close}/>
                            </div>
                            <div className="block-add-more-team block-invite-fund-members">
                                {!sended && <div className="block-section-gradient">
                                    <div className='text-members-invite font-weight-500 shell-margin-bottom-15'>Invite
                                        the
                                        members of your Fund!
                                    </div>
                                    <div className="block-list-input-email">
                                        <div className="block-left-list-input-email" style={{width: '100%'}}>
                                            <div className="block-input-reg">
                                                <p className="title-input-reg">Enter emails</p>
                                                <Input
                                                    type={'text'}
                                                    name={'email'}
                                                    value={email}
                                                    onChange={onChange}
                                                    error={errors.email}
                                                    placeholder={''}
                                                />
                                            </div>
                                            <div className="block-button-list-input-email">
                                                <ButtonBlue
                                                    TitleButton={'Invite'}
                                                    EventOnClick={() => addToList()}
                                                    paddingButton={'26'}
                                                    disabled={errors.email || !email.length}
                                                    loader={null}
                                                />
                                            </div>
                                        </div>

                                        {team.length &&
                                        <div className="block-right-list-input-email"
                                             style={{float: 'none', width: '100%'}}>
                                            <p className="right-list-title">Here is the list of emails we will send
                                                invitations
                                                to:</p>
                                            <div style={{height: '210px'}}>
                                                <ListMailMore
                                                    data={team}
                                                    onChange={(e) => console.log(e)}
                                                    viewCountItem={3}
                                                    removeFromList={removeFromList}
                                                />
                                            </div>
                                        </div> || ''}

                                        <div className='clearfix'/>
                                        <p className="text-button-2" style={{opacity: '0.8', textAlign: 'justify'}}>
                                            To ease the invitation process, you can share the link below with all your
                                            team
                                            members at once. This will enable them to create their own investor profiles
                                            and
                                            automatically link them to your Fund.
                                        </p>
                                        <div className="block-button-link">
                                            <ButtonBorder
                                                TitleButton={'Copy link'}
                                                EventOnClick={() => {
                                                    navigator.clipboard.writeText(ref_f);
                                                    showMessage({type: 'info', data: 'Copied!'});
                                                }}
                                                paddingButton={'20'}
                                            />
                                            <a href={ref_f} className="link">{ref_f}</a>
                                        </div>
                                        <div className='send-button-line' style={{paddingTop: '60px'}}>
                                            <div style={{float: 'left'}}>
                                                <ButtonBlue
                                                    TitleButton={'Cancel'}
                                                    EventOnClick={() => close()}
                                                    paddingButton={'65'}
                                                    disabled={false}
                                                />
                                            </div>
                                            <div style={{float: 'right'}}>
                                                <ButtonBlue
                                                    TitleButton={'Send invitations'}
                                                    EventOnClick={() => send()}
                                                    paddingButton={'31'}
                                                    disabled={!team.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>}


                                {sended && <React.Fragment>
                                    <div className="block-send-popup-bg"/>
                                    <div
                                        className='members-text-title text-align-center font-weight-500'>{(team.length > 1) ? 'Invitations have' : 'Invitation has'} been
                                        successfully sent!
                                    </div>
                                    <div className='members-text-little-subtitle text-align-center margin-top-15'>
                                        You will be notified when your
                                        team {(team.length > 1) ? 'members register ' : 'member registers '}
                                        on the platform via your inviation link.
                                    </div>
                                    <div
                                        className="members-text-subtitle margin-top-65 margin-bottom-20 text-align-center">We
                                        have
                                        sent {(team.length > 1) ? 'invitations to these emails:' : 'invitation to this email:'}</div>
                                    <div className='list-mail-inv-send'>
                                        <div className='mail-block-border'>
                                            {team.map((i, k) =>
                                                <div className='text-mail' key={k}>{i.email}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='send-button-line margin-top-40'>
                                        <ButtonBlue
                                            TitleButton={'Continue'}
                                            EventOnClick={() => close()}
                                            paddingButton={'58'}
                                            disabled={false}
                                        />
                                    </div>
                                </React.Fragment>}

                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </React.Fragment>
    )
};

export default EditStep2;

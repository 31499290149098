import React, {useEffect, useState} from 'react';
import close_icon from './img/ic_close.svg';
import close_icon_w from './img/cross.svg';
import arrow_back from './img/arrow-back.svg';
import './style.scss'


const UseCloseButton = ({onClick = () => {console.error('onClick not defined')}, type}) => {

    switch (type){
        case 'white': return <img src={close_icon_w} alt="" className="cursor-pointer" onClick={onClick}/>
        case 'back-menu': return <img src={arrow_back} alt="" className="cursor-pointer" onClick={onClick}/>
        case 'back-black':return <i className="fal fa-long-arrow-left cursor-pointer" onClick={onClick}/>
        default: return <img src={close_icon} alt="" className="close-block cursor-pointer" onClick={onClick}/>
    }
};

export default UseCloseButton;
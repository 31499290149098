import axios from "axios";
import Platform from 'react-platform-js'

const nextSession = async () => {
    return await axios.get('/v2/amplitude/session').then( ({data}) => {
        localStorage.setItem("user_identifier", data.nextval);
        return data.nextval;
    });
};

export const generateEvent = async (event_type, event_properties, session_id = localStorage.user_identifier) => {
    let resolution = null;

    if(!session_id || isNaN(session_id)){
        session_id = await nextSession()
    }

    if(window.screen){
        let width=window.screen.width;
        let height=window.screen.height;
        resolution = width +"x"+ height;
    }

    const user_properties = {
        platform: Platform.Browser,
        platform_version: Platform.BrowserVersion,
        device_model: Platform.DeviceModel,
        device_brand: Platform.DeviceVendor,
        engine: Platform.Engine,
        engine_version: Platform.EngineVersion,
        os_name: Platform.OS,
        os_version: Platform.OSVersion,
        screen_resolution: resolution || "undefined"
    };

    Object.keys(user_properties).forEach(key => user_properties[key] === undefined && delete user_properties[key])

    return axios.post('/v2/amplitude/event', {
        event_type, event_properties, user_properties, session_id
    })
}
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import Step8 from './Step8'
import Step9 from './Step9'
import Step10 from './Step10'
import Step11 from './Step11'
import Step12 from './Step12'
import Step13 from './Step13'
import Step14 from './Step14'
import Step15 from './Step15'
import Step16 from './Step16'
import Step17 from './Step17'
import Step18 from './Step18'
import Step19 from './Step19'
import Step20 from './Step20'
import Step21 from './Step21'
import Step22 from './Step22'
// import Step23 from './Step23'
// import Step24 from './Step24'

const Routes = (project) => (
    <Switch>
        <Route path={'/registration/startup/1'} render={(props) => ( <Step1/>)} />
        <Route path={'/registration/startup/2'} render={(props) => ( <Step2/>)} />
        <Route path={'/registration/startup/3'} render={(props) => ( <Step3/>)} />
        <Route path={'/registration/startup/4'} render={(props) => ( <Step4/>)} />
        <Route path={'/registration/startup/5'} render={(props) => ( <Step5/>)} />
        <Route path={'/registration/startup/6'} render={(props) => ( <Step6/>)} />
        <Route path={'/registration/startup/7'} render={(props) => ( <Step7/>)} />
        <Route path={'/registration/startup/8'} render={(props) => ( <Step8/>)} />
        <Route path={'/registration/startup/9'} render={(props) => ( <Step9/>)} />
        <Route path={'/registration/startup/10'} render={(props) => ( <Step10/>)} />
        <Route path={'/registration/startup/11'} render={(props) => ( <Step11/>)} />
        <Route path={'/registration/startup/12'} render={(props) => ( <Step12/>)} />
        <Route path={'/registration/startup/13'} render={(props) => ( <Step13/>)} />
        <Route path={'/registration/startup/14'} render={(props) => ( <Step14/>)} />
        <Route path={'/registration/startup/15'} render={(props) => ( <Step15/>)} />
        <Route path={'/registration/startup/16'} render={(props) => ( <Step16/>)} />
        <Route path={'/registration/startup/17'} render={(props) => ( <Step17/>)} />
        <Route path={'/registration/startup/18'} render={(props) => ( <Step18/>)} />
        <Route path={'/registration/startup/19'} render={(props) => ( <Step19/>)} />
        <Route path={'/registration/startup/20'} render={(props) => ( <Step20/>)} />
        <Route path={'/registration/startup/21'} render={(props) => ( <Step21/>)} />
        <Route path={'/registration/startup/22'} render={(props) => ( <Step22/>)} />
    </Switch>

)

Routes.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}

export default Routes

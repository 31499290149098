import React from 'react';
import './style.css'

export default class ButtonSocialReg extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            colorBack: '#FFFFFF',
            colorTitle: this.props.color,
            hoverActive: false,
        };
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    }

    onMouseEnterHandler() {
        this.setState({
            hoverActive: true,
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            hoverActive: false,
        });
    }

    ColorHexToRGB(hex) {
        return parseInt(hex[1] + hex[2], 16).toString() + ', ' + parseInt(hex[3] + hex[4], 16).toString() + ', ' + parseInt(hex[5] + hex[6], 16).toString();
    }

    render() {
        let {
            TitleButton,
            EventOnClick,
            disabled,
            color,
            iconSVG, // {"d":"", "viewBox": ""}
            styleSVG, // {width:'',height:'',}
        } = this.props;
        let Shadow = this.state.hoverActive ? '0px 2px 25px 0px rgba(' + this.ColorHexToRGB(this.state.colorTitle) + ', 0.28)' : '0px 2px 40px 6px rgba(0, 0, 0, 0.07)';
        return (

            <div
                className="block-button-reg-social"
                onClick={disabled ? null : EventOnClick}
                onMouseEnter={this.onMouseEnterHandler}
                onMouseLeave={this.onMouseLeaveHandler}
                style={{
                    boxShadow: Shadow,
                    background: this.state.hoverActive ? this.state.colorTitle : this.state.colorBack,
                }}
            >
                <svg
                    width={styleSVG.width}
                    height={styleSVG.height}
                    viewBox={iconSVG.viewBox}
                    onMouseEnter={this.onMouseEnterHandler}
                    onMouseLeave={this.onMouseLeaveHandler}
                    style={{
                        position: 'absolute',
                        transition: '.3s',
                        top: 'calc((48px - ' + styleSVG.height + ') / 2)',
                        left: 'calc((48px - ' + styleSVG.width + ') / 2)'
                    }}
                >
                    <path
                        fill={this.state.hoverActive ? this.state.colorBack : this.state.colorTitle}
                        d={iconSVG.d}
                        style={{transition: '.3s'}}
                    />
                </svg>
                <p className="title-button-reg-social"
                   style={{color: this.state.hoverActive ? this.state.colorBack : this.state.colorTitle}}>{TitleButton}</p>
            </div>
        );
    }
}


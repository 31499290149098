import React from 'react';
import Input from "../../../controls3.0/input/Input";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import {regexPass} from "../../../../utils/RegExp";
import Swipe from "../../../controls3.0/swipe/Swipe";
import RocketDatePicker from "../../../controls3.0/datePicker/DatePicker";
import moment from "moment/moment";
import Select from "../../../controls3.0/select/Select";
import {COUNTRIES} from "../../../../utils/countriesLanguages";
import Dropzone from "react-dropzone";
import ButtonGray from "../../../controls3.0/button/ButtonGray";
import {updateUser2} from "../../../../actions/Login";
import {showMessage} from "../../../../utils/showMessage";
import {getErrorMessage} from "../../../../utils/api_errors";

import NotificationBlock from '../../../v4/notificationsBlock'
import axios from "axios";

import ApolloClient, {gql} from "apollo-boost"
import {Query} from 'react-apollo';
import config from "../../../../config";
import {Row} from "../../../v4/Grid/Row";
import {Box} from "../../../v4/Grid/Box";

const GET_USER = gql`
    query {
        user{
            id
            notifyRules{
                roles
                rules
            }
        }
    }
`;

const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});


// const NOTIFICATIONS_LIST = ;


const FlyButton = ({title='Apply changes', paddingButton='16', apply, reset, errors, applyDisabled}) => {
    return (
        <div className={'container-fluid fixed fly-button-container'}>
            <div className={'container no-gutters'}>
                <div className={'row-b'}>
                    <div className={'col-12 col-sm-4'}>
                    </div>
                    <div className={'col-12 col-sm-8 pr-asm-0'}>
                        <div className='row-b button-wrapper account-button-wrapper no-gutters'>
                            <div className={'col-12 col-sm-auto'}>
                                <ButtonBlue
                                    TitleButton={title}
                                    EventOnClick={() => {apply()} }
                                    paddingButton={paddingButton}
                                    disabled={applyDisabled || Object.keys(errors).length}
                                />
                            </div>
                            <div className={'col-12 col-sm-auto reset-button-column'}>
                                {reset && <div className='button-reset-wrapper'>
                                    <ButtonBlue
                                        TitleButton={'Reset'}
                                        EventOnClick={() => {
                                            reset()
                                        }}
                                        paddingButton={'16'}
                                        disabled={false}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default class AccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword:'',
            newPassword:'',
            reNewPassword:'',
            errorsIn: {},
            checkedNotif:[],
            passport: '',
            adress:'',
            zip: '',
            city: '',
            data: {
                expirationFull:'',
                expirationYear: '',
                expirationMonth: '',
                expirationDay: '',
                issueFull:'',
                issueYear: '',
                issueMonth: '',
                issueDay: '',
                files: [],
                files2: [],
                countryDocument:''
            },

            roles: {},

            FixButton: Boolean(document.documentElement.scrollHeight-document.documentElement.clientHeight),

            notify_rules_general: [
                {id: 'general_new_message',                                 title:'New message (coming soon)',                  descr:'Get an alert when someone sends you a private message ',                                                         mail: false, telegram: false},
                {id: 'general_info_updates_of_liked_startups',              title:'Info updates of liked startups',             descr:'Get an alert when a startup you like (follow) updates anything in its profile',                                  mail: false, telegram: false},
                {id: 'general_new_evaluated_startups',                      title:'New evaluated startups',                     descr:'Get an alert when a new startup undertakes expert evaluation',                                                   mail: false, telegram: false},
                {id: 'general_blog_updates',                                title:'Blog updates',                               descr:'Get an alert abour the new articles published in our blog',                                                      mail: false, telegram: false},
                {id: 'general_platform_regular_updates_including',          title:'Platform regular updates, including:',       descr:'Two times per month we will send in an overview of the recent achievements on the platform, it may include:',    mail: false, telegram: false, withoutBorder:true},
                {id: 'general_new_startups_registered_on_the_platform',     title:'New startups registered on the platform',        descr:null,                                               mail: false, telegram: false},
                {id: 'general_new_investors_registered_on_the_platform',    title:'New investors registered on the platform',       descr:null,                                               mail: false, telegram: false},
                {id: 'general_new_experts_registered_on_the_platform',      title:'New experts registered on the platform',         descr:null,                                               mail: false, telegram: false},
                {id: 'general_new_accelerators_registered_on_the_platform', title:'New accelerators registered on the platform',    descr:null,                                               mail: false, telegram: false},
            ],

            notify_rules_startup:[
                {id: 'startup_new_comment',                title:'New comment', descr:'Get an alert when someone leaves a comment on the profile of your startup (or replies to yours)',           mail: false, telegram: false},
                {id: 'startup_new_like',                title:'New like', descr:'Get an alert when someone like your startup',                                                                  mail: false, telegram: false},
                {id: 'startup_new_investor_in_the_waitlist',       title:'New investor in the waitlist', descr:'Get an alert when a new investor joins your waitlist',                                     mail: false, telegram: false},
                {id: 'startup_registration_of_the_team_members',      title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                 mail: false, telegram: false},
                {id: 'startup_new_message_to_startup',        title:'New message to startup (coming soon)', descr:'Get an alert when someone sends a private message directly to your startup',       mail: false, telegram: false},
            ],
            notify_rules_investor:[
                {id: 'investor_new_comment',                            title:'New comment', descr:'Get an alert when someone leaves a comment on the profile of your startup (or replies to yours)',                                      mail: false, telegram: false},
                {id: 'investor_another_investor_in_the_waitlist',      title:'Another investor in the waitlist', descr:'Get an alert when someone joins the waitlist of the startup which you have already joined before',                mail: false, telegram: false},
                {id: 'investor_registration_of_the_team_members',      title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                                            mail: false, telegram: false},
                {id: 'investor_new_message_to_fund',                    title:'New message to fund (coming soon)', descr:'Get an alert when someone sends a private message directly to the fund which you have registered',               mail: false, telegram: false},
                {id: 'investor_new_message_to_network',                 title:'New message to network (coming soon)', descr:'Get an alert when someone sends a private message directly to the angel network which you have registered',   mail: false, telegram: false},
            ],
            notify_rules_expert:[
                {id: 'expert_new_comment',      title:'New comment', descr:'Get an alert when someone leaves a comment on your expert profile (or replies to yours)',                                              mail: false, telegram: false},
                {id: 'expert_new_task',      title:'New task (coming soon)', descr:'Get an alert every time you have a new task assigned',                                                                      mail: false, telegram: false},
                {id: 'expert_new_startup_for_evaluation',      title:'New startup for evaluation', descr:'Get an alert when there is a startup to be evaluated (either preliminary evaluation or Due Diligence)',                 mail: false, telegram: false},
                {id: 'expert_new_comment_to_evaluation',      title:'New comment to evaluation (coming soon)', descr:'Get an alert when someone leaves a comment on the results of the evaluation of the startup you assessed',  mail: false, telegram: false},
            ],
            notify_rules_accelerator: [
                {id: 'accelerator_new_comment',       title:'New comment', descr:'Get an alert when someone leaves a comment on your accelerator profile (or replies to yours)',                                            mail: false, telegram: false},
                {id: 'accelerator_new_message_to_accelerator',       title:'New message to accelerator (coming soon)', descr:'Get an alert when someone sends a private message directly to the accekerator which you have registered',    mail: false, telegram: false},
                {id: 'accelerator_registration_of_the_team_members',       title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                                               mail: false, telegram: false},
            ],

        };
    }

    componentDidMount(){

        client.query({ query: GET_USER }).then(r => {
            const {notifyRules} = r.data.user;
            const {roles, rules} = notifyRules;

            const notify_rules_general = [
                {id: 'general_new_message',                                 title:'New message (coming soon)',                  descr:'Get an alert when someone sends you a private message ',                                                         mail: rules.mail_general_new_message,                                   telegram: rules.telegram_general_new_message},
                {id: 'general_info_updates_of_liked_startups',              title:'Info updates of liked startups',             descr:'Get an alert when a startup you like (follow) updates anything in its profile',                                  mail: rules.mail_general_info_updates_of_liked_startups,                telegram: rules.telegram_general_info_updates_of_liked_startups},
                {id: 'general_new_evaluated_startups',                      title:'New evaluated startups',                     descr:'Get an alert when a new startup undertakes expert evaluation',                                                   mail: rules.mail_general_new_evaluated_startups,                        telegram: rules.telegram_general_new_evaluated_startups},
                {id: 'general_blog_updates',                                title:'Blog updates',                               descr:'Get an alert abour the new articles published in our blog',                                                      mail: rules.mail_general_blog_updates,                                  telegram: rules.telegram_general_blog_updates},

                {id: 'general_platform_regular_updates_including',          title:'Platform regular updates, including:',       descr:'Two times per month we will send in an overview of the recent achievements on the platform, it may include:',    mail: rules.mail_general_platform_regular_updates_including,            telegram: rules.telegram_general_platform_regular_updates_including, withoutBorder:true},
                {id: 'general_new_startups_registered_on_the_platform',     title:'New startups registered on the platform',        descr:null,                                                                                                         mail: rules.mail_general_new_startups_registered_on_the_platform,       telegram: rules.telegram_general_new_startups_registered_on_the_platform},
                {id: 'general_new_investors_registered_on_the_platform',    title:'New investors registered on the platform',       descr:null,                                                                                                         mail: rules.mail_general_new_investors_registered_on_the_platform,      telegram: rules.telegram_general_new_investors_registered_on_the_platform},
                {id: 'general_new_experts_registered_on_the_platform',      title:'New experts registered on the platform',         descr:null,                                                                                                         mail: rules.mail_general_new_experts_registered_on_the_platform,        telegram: rules.telegram_general_new_experts_registered_on_the_platform},
                {id: 'general_new_accelerators_registered_on_the_platform', title:'New accelerators registered on the platform',    descr:null,                                                                                                         mail: rules.mail_general_new_accelerators_registered_on_the_platform,   telegram: rules.telegram_general_new_accelerators_registered_on_the_platform},
            ];

            const notify_rules_investor = [
                {id: 'investor_new_comment',                            title:'New comment', descr:'Get an alert when someone leaves a comment on the profile of your startup (or replies to yours)',                                      mail: rules.mail_investor_new_comment       ,               telegram: rules.telegram_investor_new_comment},
                {id: 'investor_another_investor_in_the_waitlist',      title:'Another investor in the waitlist', descr:'Get an alert when someone joins the waitlist of the startup which you have already joined before',                 mail: rules.mail_investor_another_investor_in_the_waitlist, telegram: rules.telegram_investor_another_investor_in_the_waitlist},
                {id: 'investor_registration_of_the_team_members',      title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                                             mail: rules.mail_investor_registration_of_the_team_members, telegram: rules.telegram_investor_registration_of_the_team_members},
            ];

            if(roles.fund){
                notify_rules_investor.push({id: 'investor_new_message_to_fund', title:'New message to fund (coming soon)', descr:'Get an alert when someone sends a private message directly to the fund which you have registered',               mail: rules.mail_investor_new_message_to_fund             , telegram: rules.telegram_investor_new_message_to_fund})
            }

            if(roles.angel_network){
                notify_rules_investor.push({id: 'investor_new_message_to_network',                 title:'New message to network (coming soon)', descr:'Get an alert when someone sends a private message directly to the angel network which you have registered',   mail: rules.mail_investor_new_message_to_network , telegram: rules.telegram_investor_new_message_to_network});
            }

            const notify_rules_startup = [
                {id: 'startup_new_comment',                title:'New comment', descr:'Get an alert when someone leaves a comment on the profile of your startup (or replies to yours)',        mail: rules.mail_startup_new_comment,                      telegram: rules.telegram_startup_new_comment},
                {id: 'startup_new_like',                    title:'New like', descr:'Get an alert when someone like your startup',                                                              mail: rules.mail_startup_new_like,                         telegram: rules.telegram_startup_new_like},
                {id: 'startup_new_investor_in_the_waitlist',       title:'New investor in the waitlist', descr:'Get an alert when a new investor joins your waitlist',                           mail: rules.mail_startup_new_investor_in_the_waitlist,      telegram: rules.telegram_startup_new_investor_in_the_waitlist},
                {id: 'startup_registration_of_the_team_members',      title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',   mail: rules.mail_startup_registration_of_the_team_members, telegram: rules.telegram_startup_registration_of_the_team_members},
                {id: 'startup_new_message_to_startup',        title:'New message to startup (coming soon)', descr:'Get an alert when someone sends a private message directly to your startup', mail: rules.mail_startup_new_message_to_startup,           telegram: rules.telegram_startup_new_message_to_startup},
            ];

            const notify_rules_expert = [
                {id: 'expert_new_comment',                      title:'New comment', descr:'Get an alert when someone leaves a comment on your expert profile (or replies to yours)',                                              mail: rules.mail_expert_new_comment               , telegram: rules.telegram_expert_new_comment                    },
                {id: 'expert_new_task',                         title:'New task (coming soon)', descr:'Get an alert every time you have a new task assigned',                                                                      mail: rules.mail_expert_new_task                  , telegram: rules.telegram_expert_new_task                       },
                {id: 'expert_new_startup_for_evaluation',       title:'New startup for evaluation', descr:'Get an alert when there is a startup to be evaluated (either preliminary evaluation or Due Diligence)',                 mail: rules.mail_expert_new_startup_for_evaluation, telegram: rules.telegram_expert_new_startup_for_evaluation     },
                {id: 'expert_new_comment_to_evaluation',        title:'New comment to evaluation (coming soon)', descr:'Get an alert when someone leaves a comment on the results of the evaluation of the startup you assessed',  mail: rules.mail_expert_new_comment_to_evaluation , telegram: rules.telegram_expert_new_comment_to_evaluation      },
            ];

            const notify_rules_accelerator = [
                 {id: 'accelerator_new_comment',                        title:'New comment', descr:'Get an alert when someone leaves a comment on your accelerator profile (or replies to yours)',                                            mail: rules.mail_accelerator_new_comment,                      telegram: rules.telegram_accelerator_new_comment,                     },
                 {id: 'accelerator_new_message_to_accelerator',         title:'New message to accelerator (coming soon)', descr:'Get an alert when someone sends a private message directly to the accekerator which you have registered',    mail: rules.mail_accelerator_new_message_to_accelerator,       telegram: rules.telegram_accelerator_new_message_to_accelerator,      },
                 {id: 'accelerator_registration_of_the_team_members',       title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                                           mail: rules.mail_accelerator_registration_of_the_team_members, telegram: rules.telegram_accelerator_registration_of_the_team_members },
            ];

            this.setState({roles, notify_rules_startup, notify_rules_general, notify_rules_investor, notify_rules_expert, notify_rules_accelerator});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const state = {};
        const c1 = this.props.user && this.state.firstName === '' && this.state.lastName === '';
        const c2 = this.props.openedSection !== prevProps.openedSection;
        if (c1) {
                state.firstName = this.props.user.firstName;
                state.lastName = this.props.user.lastName;
                state.countryOfResidence = [{Name: this.props.user.country}];
                state.mail = this.props.user.mail;
        }

        if (c2) {
            state.FixButton = Boolean(document.documentElement.scrollHeight-document.documentElement.clientHeight)
        }

        if(c1 || c2)
            this.setState({...state});
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value.cleanWhitespace(),
            _errors = this.state.errorsIn,
            required = false,
            limit = 200;

        const {newPassword, reNewPassword} = this.state;

        switch (name) {
            case 'oldPassword':
            case 'newPassword':
            case 'reNewPassword':
                limit = 36;
                break;
        }

        if (value.length) {
            if (value.length > limit) {
                value = value.slice(0, limit);
                _errors[name] = 'max length ' + limit + ' symbols';
            }
        }

        if(name === 'newPassword' && !!value){
            if(!regexPass.test(value)){
                _errors.newPassword = 'Minimum 8 characters - lower and upper case symbols and digit(s)'
            } else {
                delete _errors[name];
            }
        }

        if(name === 'reNewPassword' && !!value){
            if(value !== newPassword){
                _errors.reNewPassword = 'Passwords don\'t match'
            } else {
                delete _errors[name];
            }
        }

        if (required && !value) {
            _errors[name] = 'Field is required'
        } else {
            if(!value){
                delete _errors[name];
            }
        }


        this.setState({
            [name]:value,
            errorsIn:{..._errors}
        })
    };

    onCheck = (item, itemType, type) => {

        const rulesName = `notify_rules_${type}`;

        let rules = this.state[rulesName];

        const rule = `${itemType}_${item.id}`;
      
        const notifyRules = rules.map((thisItem, key)=>{
            if (thisItem === item) {
                thisItem[itemType] = !thisItem[itemType]
            }
            return thisItem;
        });

        console.log(notifyRules);

        this.setState({
            [rulesName]: notifyRules
        });
        
        axios.put('/v1/users/notify/rules', {
            rules: {[rule]: item[itemType]},
        }).then( ({data}) => {

        })
    };

    SetAllNotifications = () => {
        axios.put('/v1/users/notify/rules', {
            rules: {
                'mail_new_comments': !this.state.allChecked,
                'mail_new_messages': !this.state.allChecked,
                'mail_new_startups_platform': !this.state.allChecked,
                'mail_new_investors_platform': !this.state.allChecked,
                'mail_new_experts_platform': !this.state.allChecked,
                'mail_new_accelerators_platform': !this.state.allChecked,
                'mail_weekly_platform_updates': !this.state.allChecked,
                'mail_registration_colleagues': !this.state.allChecked,
            }
        }).then( ({data}) => {
            const notifyRules = data[0];

            const allChecked = Object.keys(notifyRules).find(i => {
                return notifyRules[i] === false;
            });
            this.setState({allChecked: !allChecked});

            const newNotifyRulesStartup = [
                {id: 'new_comments',                title:'New comment', descr:'Get an alert when someone leaves a comment on the profile of your startup (or replies to yours)',          mail: notifyRules.mail_new_comments , platform: notifyRules.platform_new_comments || false, telegram: notifyRules.telegram_new_comments || false},
                {id: 'new_messages',                title:'New like', descr:'Get an alert when someone like your startup',                                                                  mail: notifyRules.mail_new_messages, platform: notifyRules.platform_new_messages || false, telegram: notifyRules.telegram_new_messages || false},
                {id: 'new_startups_platform',       title:'New investor in the waitlist', descr:'Get an alert when a new investor joins your waitlist',                                     mail: notifyRules.mail_new_startups_platform , platform: notifyRules.platform_new_startups_platform || false, telegram: notifyRules.telegram_new_startups_platform || false},
                {id: 'new_investors_platform',      title:'Registration of the team members', descr:'Get an alert when any team member registers via your invitation link',                 mail: notifyRules.mail_new_investors_platform , platform: notifyRules.platform_new_investors_platform || false, telegram: notifyRules.telegram_new_investors_platform || false},
                {id: 'new_experts_platform',        title:'New message to startup (coming soon)', descr:'Get an alert when someone sends a private message directly to your startup',       mail: notifyRules.mail_new_experts_platform , platform: notifyRules.platform_new_experts_platform || false, telegram: notifyRules.telegram_new_experts_platform || false},
            ];

            this.setState({
                notifyRulesStartup: newNotifyRulesStartup
            })
        })
    };


    //VERIFICATION

    handleChangePicker2 = date => {
        this.setState({data:{...this.state.data, ['expirationFull']: date, ['expirationYear']: date.getFullYear(), ['expirationMonth']: date.getMonth() + 1, ['expirationDay']: date.getDate()}});

        if(!date){
            this.setState({errorsIn:{...this.state.errorsIn, expiration: 'Field is required'}})
        }else {
            this.changeErrors('expiration')
        }
    }

    handleChangePicker3 = date => {
        this.setState({data:{...this.state.data, ['issueFull']: date, ['issueYear']: date.getFullYear(), ['issueMonth']: date.getMonth() + 1, ['issueDay']: date.getDate()}});

        if(!date){
            this.setState({errorsIn:{...this.state.errorsIn, issue: 'Field is required'}})
        }else {
            this.changeErrors('isssue')
        }
    }

    changeErrors = (name) => {
        let errorsIn = this.state.errorsIn;
        delete errorsIn[name];
        this.setState({errorsIn})
    };

    updateCountry2 = newValue => {
        this.setState({data:{...this.state.data, countryDocument: newValue}})
    };

    deleteFile  = (key) => {
        let files = this.state.data.files;
        files.splice(key, 1)
        this.setState({data:{...this.state.data, files: files}})
    };

    deleteFile2  = (key) => {
        let files = this.state.data.files2;
        files.splice(key, 1)
        this.setState({data:{...this.state.data, files2: files}})
    };

    addFile (accepted, rejected) {
        if(accepted.length){
            let files = this.state.data.files;
            accepted.forEach((item, key) => {
                if(files.length < 5) files.push(item);
            })

            this.setState({data:{...this.state.data, files: files}})
        }
        if(rejected.length){
            this.setState({ errorFile: 'The uploaded file seems to have incorrect format' })
        }
    }

    addFile2 (accepted, rejected) {
        if(accepted.length){
            // let files = this.state.data.files2;
            // accepted.forEach((item, key) => {
            //     if(files.length < 5) files.push(item);
            // })

            this.setState({data:{...this.state.data, files2: accepted}})
        }
        if(rejected.length){
            this.setState({ errorFile2: 'The uploaded file seems to have incorrect format' })
        }
    }

    updateProfile = () => {
        const {newPassword, oldPassword, errorsIn} = this.state;

        updateUser2({
            newPassword,
            oldPassword
        }).then(r=>{
            showMessage({type: 'info', data: 'Saved!'});
            delete errorsIn.oldPassword;
            this.setState({errorsIn});
        }).catch(e => {
            if(e.response.data.error === 'INVALID_CURRENT_PASSWORD'){
                errorsIn.oldPassword = getErrorMessage(e.response.data.error);
                this.setState({errorsIn})
            }
        })
    };

    render() {

        console.count('account render')

        let {oldPassword, newPassword, reNewPassword, data, errorsIn, passport, FixButton} = this.state;
        let {user, openedSection} = this.props,
            accept="image/jpeg, image/png, image/gif",
            dropStyle = {
                'width': '100%',
                'height': 'auto',
                'margin': '0 auto',
                'border': 'none'
            };


        let listFiles = data.files.map((item, key) => {
            return (
                <div key={key} className="block-img-load-kyc" style={{background: `url(${item.preview})`}} onClick={() => this.deleteFile(key)} >
                    <i className="fal fa-times"></i>
                </div>
            )
        });

        let listFiles2 = data.files2.map((item, key) => {
            return (
                <div key={key} className="block-img-load-kyc" style={{background: `url(${item.preview})`}} onClick={() => this.deleteFile2(key)} >
                    <i className="fal fa-times"></i>
                </div>
            )
        })

        return (
            <Row className='block-user-right block-page'>
                {openedSection===0 && <div className={'col-12'}>
                    {/*USER NOTIFICATIONS*/}
                    <Row className='notifications-descr-text shell-margin-bottom-40'>
                        <div className={'col-12'}>
                            As the platfrom is developing fast, we would like to share our most interesting updates with you.  Specify what kind of information you would like to receive.
                        </div>
                    </Row>
                    <Row className='block-notification first-notification-block no-gutters'>
                        <NotificationBlock
                            title={'General preferences'}
                            notifyRules={this.state.notify_rules_general}
                            parentCallback={ (item, type) => {this.onCheck(item, type, 'general')} }
                        />
                    </Row>
                    {this.state.roles.startup && <Row className='profile-user-shadow block-notification no-gutters'>
                        <NotificationBlock
                            title={'Startup'}
                            notifyRules={this.state.notify_rules_startup}
                            parentCallback={ (item, type)=>{this.onCheck(item, type, 'startup')} }
                        />
                    </Row>}
                    {this.state.roles.investor && <Row className='profile-user-shadow block-notification no-gutters'>
                        <NotificationBlock
                            title={'Investor'}
                            notifyRules={this.state.notify_rules_investor}
                            parentCallback={ (item, type)=>{this.onCheck(item, type, 'investor')} }
                        />
                    </Row>}
                    {this.state.roles.expert && <Row className='profile-user-shadow block-notification no-gutters'>
                        <NotificationBlock
                            title={'Expert'}
                            notifyRules={this.state.notify_rules_expert}
                            parentCallback={ (item, type)=>{this.onCheck(item, type, 'expert')} }
                        />
                    </Row>}
                    {this.state.roles.accelerator && <Row className='profile-user-shadow block-notification no-gutters'>
                        <NotificationBlock
                            title={'Accelerator'}
                            notifyRules={this.state.notify_rules_accelerator}
                            parentCallback={ (item, type)=>{this.onCheck(item, type, 'accelerator')} }
                        />
                    </Row>}
                </div>}

                {openedSection===1 && <div className='col-12 pr-asm-0'>
                    {/*USER PASSWORD*/}
                    <div className='row-m0 profile-user-name-block profile-user-shadow block-password no-gutters'>
                        <p className="row-m0 text-h5 color-rgba-88">
                            Reset password
                        </p>
                        <div className='row-b w-100-20'>
                            <div className='col-sm-6 col-12'>
                                <p className="block-user-password-title font-weight-500 left-text margin-bottom-12" style={{marginTop:'0'}}>
                                    New password
                                </p>

                                <div className="shell-input-height-40">
                                    <Input
                                        type={'password'}
                                        name={'newPassword'}
                                        value={newPassword}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errorsIn.newPassword}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6 col-12 order-sm-2 order-3'>
                                <p className="row-m0 w-100 block-user-password-title font-weight-500 left-text margin-bottom-12 shell-margin-top-0">
                                    Old password
                                </p>

                                <div className="row-m0 old-pass-block shell-input-height-40">
                                    <Input
                                        type={'password'}
                                        name={'oldPassword'}
                                        autocomplete={false}
                                        value={oldPassword}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errorsIn.oldPassword}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-6 col-12 order-sm-3 order-2' style={{marginTop:'-3px'}} >
                                <p className="block-user-password-title last-title font-weight-500 left-text margin-bottom-12 shell-margin-top-0">
                                    Re-type new password
                                </p>

                                <div className="shell-input-height-40">
                                    <Input
                                        type={'password'}
                                        name={'reNewPassword'}
                                        value={reNewPassword}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errorsIn.reNewPassword}
                                    />
                                </div>
                            </div>

                            {!FixButton && <div className='col-sm-6 col-12 order-4 password-apply-wrapper' style={{marginTop:'-3px'}} >
                                <ButtonBlue
                                    TitleButton={'Apply changes'}
                                    EventOnClick={() => {this.updateProfile()} }
                                    paddingButton={'22'}
                                    disabled={(newPassword != reNewPassword) || !reNewPassword || !oldPassword || Object.keys(errorsIn).length}
                                />
                            </div>}
                        </div>

                        <div className='row-b bottom-password-line shell-margin-bottom-15 w-100-20' >



                            {FixButton && <FlyButton apply={() => {this.updateProfile()}} errors={errorsIn} applyDisabled={ (newPassword != reNewPassword) || !reNewPassword || !oldPassword}/>}
                        </div>
                    </div>
                </div>}

                {openedSection===2 && <div>

                    {/*<div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, background: 'rgba(255,255,255,0.5)'}}>*/}

                    {/*</div>*/}

                    {/*USER PASSPORT*/}

                        <div className="block-cart-kyc-reg-expert">

                            <p className="title-cart-kyc-reg-expert">
                                Passport
                            </p>

                            <div className="block-left-cart-kyc-reg-expert new-width-100-down-1199">

                                <div className="block-line-left-cart-kyc-reg-expert">
                                    <div className="shell-input-kyc">
                                        <p className="title-input-kyc">Passport number</p>
                                        <Input
                                            type={'text'}
                                            name={'passport'}
                                            value={passport}
                                            onChange={this.onChange}
                                            placeholder={'XXXXXXXXXXXXXXXX'}
                                            disabled={true}
                                        />
                                    </div>

                                </div>
                                <div className="w-100"  style={{height:'85px'}}>
                                    <div className="block-left-left-cart-kyc-reg-expert">
                                        <div className="block-date-input">
                                            <p className="title-input-kyc">Document expiration date</p>
                                            <RocketDatePicker
                                                name="startDate"
                                                value={data.expirationFull}
                                                onChange={this.handleChangePicker2}
                                                minDate={moment()['_d']}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                yearDropdownItemNumber={8}
                                                placeholderText=""
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="block-left-left-cart-kyc-reg-expert">
                                        <div className="block-date-input">
                                            <p className="title-input-kyc">Document issue date</p>
                                            <RocketDatePicker
                                                name="startDate"
                                                value={data.issueFull}
                                                onChange={this.handleChangePicker3}
                                                maxDate={moment()['_d']}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                yearDropdownItemNumber={8}
                                                placeholderText=""
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        <div className="block-right-cart-kyc-reg-expert new-width-100-down-1199">
                            <div className="block-line-left-cart-kyc-reg-expert">
                                <div className="shell-select-kyc">
                                    <p className="title-input-kyc">Your country of residence</p>
                                    <Select
                                        placeholder={'Country'}
                                        data={COUNTRIES}
                                        value={data.countryDocument}
                                        onChange={(e) => this.updateCountry2(e)}
                                        error={errorsIn.countryDocument}
                                        disabled={true}
                                    />
                                </div>
                            </div>


                            <div className="block-load-img-kyc">
                                <p className="title-input-kyc" style={{margin:'0'}}>Passport upload</p>
                                <p className="description-input-kyc" style={{margin:'0'}}>
                                    Should be a clear color scan with all
                                    text and photo cleary visible
                                </p>

                                <div className="block-list-img-load-kyc" >
                                    {listFiles}
                                </div>

                                <Dropzone
                                    style={dropStyle}
                                    multiple={true}
                                    onDrop={(accepted, rejected) => {this.addFile(accepted, rejected)}}
                                    accept={accept}
                                    maxfiles={5}
                                    maxSize={15000000}
                                >
                                    <ButtonGray
                                        TitleButton={'Upload'}
                                        EventOnClick={() => console.log('Upload')}
                                        paddingButton={'0'}
                                        disabled={true}
                                    />

                                </Dropzone>

                            </div>
                        </div>
                        </div>


                    {/*USER Verification*/}

                    <div className="block-cart-kyc-reg-expert">



                        <div className="block-left-cart-kyc-reg-expert new-width-100-down-1199">

                            <p className="title-cart-kyc-reg-expert">
                                Verification address
                            </p>
                            <div className="block-line-left-cart-kyc-reg-expert">
                                <div className="shell-text-area-kyc">
                                    <p className="title-input-kyc">Address</p>
                                    <textarea name="address" value={this.state.address} onChange={this.onChange} placeholder={'Complete home address'} disabled={true}/>
                                </div>
                            </div>
                            <div className="row-b row-m5" style={{marginTop:'13px'}}>
                                <div className="col-12 col-sm-6 col-p5">
                                    <div className="shell-input-kyc input-41">
                                        <p className="title-input-kyc">Postal code</p>
                                        <Input
                                            type={'text'}
                                            name={'zip'}
                                            value={this.state.zip}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-p5">
                                    <div className="shell-input-kyc input-41">
                                        <p className="title-input-kyc">City</p>
                                        <Input
                                            type={'text'}
                                            name={'city'}
                                            value={this.state.city}
                                            onChange={this.onChange}
                                            placeholder={''}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="block-right-cart-kyc-reg-expert new-width-100-down-1199">


                            <div className="block-load-img-kyc" style={{paddingTop: '11px'}}>
                                <p className="title-input-kyc" style={{margin:'0'}}>Address upload (one photo)</p>
                                <p className="description-input-kyc" style={{margin:'1px 0 0 0'}}>
                                    Documents should be free of objects,
                                    or inscriptions, which do not belong to
                                    the original
                                </p>

                                <div className="block-list-img-load-kyc" >
                                    {listFiles2}
                                </div>

                                <Dropzone
                                    style={dropStyle}
                                    multiple={false}
                                    onDrop={(accepted, rejected) => {this.addFile2(accepted, rejected)}}
                                    accept={accept}
                                    maxfiles={1}
                                    maxSize={15000000}
                                >
                                    <ButtonGray
                                        TitleButton={'Upload'}
                                        EventOnClick={() => console.log('Upload')}
                                        paddingButton={'0'}
                                        disabled={true}
                                    />

                                </Dropzone>


                            </div>
                        </div>

                    </div>

                    {!FixButton && <div className='w-100 validation-button-wrapper'>
                        <ButtonBlue
                            TitleButton={'Send for validation'}
                            EventOnClick={() => {this.updateProfile()} }
                            paddingButton={'30'}
                            disabled={Object.keys(errorsIn).length}
                        />
                    </div>}

                    {FixButton && <FlyButton title='Send for validation' paddingButton={'30'} apply={() => {this.updateProfile()}} errors={errorsIn}/>}
                </div>}
            </Row>

        )
    }
}
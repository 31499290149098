import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import './forgot-pass.css'
import './auth.css'
import {forgot} from '../../actions/UserSignup'

class ForgotPage extends React.Component {

  constructor(){
    super();
    this.state = {
      data:{
        email:'',
        sent: ''
      },
      errors:{},
      serverError:'',
      isLoading:false,
      isSuccess: ''
    };
    this.onChange = this.onChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  onChange(e){
    this.setState({data:{...this.state.data, [e.target.name]:e.target.value}});
  }

  sendEmail(e){
    e.preventDefault();
    this.setState({ isLoading: true });

    forgot(this.state.data.email).then(()=>{
      this.setState({sent: true, isLoading:false})
    })
      .catch(error => {
        console.log('my test error')
        this.setState({serverError:error.response.data.error, isLoading:false})
      })
  }

  render () {
    const {data, errors} = this.state;

    return (
      <div className="forgot-page">

        <div className="main-bg">
          <div className="container">
            <div className="row justify-content-center justify-content-column">

              {!this.state.sent &&
                <div>
                <div className="text-top">
                    Please enter your email address below. <br/>Further instructions on how to reset your <br/> password will be sent to you.
                  </div>
                  <div className="forgot-block">
                    <form className="login-form" onSubmit={this.sendEmail}>
                      <input type="email"
                             className="login-form__field"
                             id="email"
                             name="email"
                             placeholder="Enter email..."
                             value={data.email}
                             onChange={this.onChange}
                      />
                      <button className="login-form__btn login-form__btn_join" disabled={!this.state.data.email}>Submit</button>
                      {this.state.isSuccess && <div className="test-message">{this.state.isSuccess}</div>}
                      {this.state.serverError && <div className="test-message-err">{this.state.serverError}</div>}
                    </form>
                  </div>
                </div>
              }

              {this.state.sent &&
              <div>
                <div className="text-top">
                  Instructions on how to reset your password have sent to your email.
                </div>
              </div>
              }



            </div>
          </div>
        </div>



      </div>
    )
  }
}

ForgotPage.propTypes = {
  history:PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default connect (null, {})(ForgotPage)

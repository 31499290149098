import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import './style.scss';

const Evaluation = {
        441: {
            Product: 6.5,
            Market: 6.3,
            Team: 6.3,
            B_Model: 7,
        },
        510: {
            Product: 6,
            Market: 4.5,
            Team: 8.3,
            B_Model: 6,
        },
        586: {
            Product: 6.3,
            Market: 8.25,
            Team: 7.6,
            B_Model: 7.5,
        }
    }

export const StartupListPeItem = ({key, item, href, onClick, listIndustries}) => {
    const _bg = item.backgroundImageId ? `${config.proxy}/file/${item.backgroundImageId}/view?w=${config.image.bg.sm}` : '';
    const _avatar = item.logoFileId ?
        <div className="img-cart-new-startup-marketplace"
             style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('img-cart-new-startup-marketplace', item.title);

        try {
            return (
                <div key={key} className={'col-12 col-sm-6 col-md-4'}>
                    <a href={href}>
                        <div className="block-cart-new-startup-marketplace startup-cart evaluated-card slide-up"
                             onClick={onClick}>
                            <div className='block-startup-evaluation-view'>
                                <div className="row-0 review-score text-center">
                                    <div
                                        className="new-col expert-main-score font-weight-500">{item.preliminary_evaluation}</div>
                                    <div className="new-col expert-main-score-ten" style={{marginTop: '24px'}}>/10</div>
                                    <div className='new-col review-score-text-block'>

                                        <div className="row-m0 block-front-review-score-text">
                                            <div className="col-12 p-0">
                                                <div className='row-0 expert-review-score-text text-left color-rgba-88'>Expert
                                                    overall score
                                                </div>
                                                <div className='row-0 expert-review-score-views text-left'
                                                     style={{marginTop: '4px'}}>Defined round: <span
                                                    className='color-rgba-88'>{item.round}</span></div>
                                            </div>
                                        </div>
                                        <div className="row-m0 block-back-review-score-text">
                                            <div className="col-3 p-0" style={{borderRight: '1px solid rgba(0, 0, 0, 0.3)'}}>
                                                <p className="count-review-score">
                                                    {Evaluation[item.id].Product}
                                                </p>
                                                <p className="title-review-score">
                                                    Product
                                                </p>
                                            </div>
                                            <div className="col-3 p-0" style={{borderRight: '1px solid rgba(0, 0, 0, 0.3)'}}>
                                                <p className="count-review-score">
                                                    {Evaluation[item.id].Market}
                                                </p>
                                                <p className="title-review-score">
                                                    Market
                                                </p>
                                            </div>
                                            <div className="col-3 p-0" style={{borderRight: '1px solid rgba(0, 0, 0, 0.3)'}}>
                                                <p className="count-review-score">
                                                    {Evaluation[item.id].Team}
                                                </p>
                                                <p className="title-review-score">
                                                    Team
                                                </p>
                                            </div>
                                            <div className="col-3 p-0">
                                                <p className="count-review-score">
                                                    {Evaluation[item.id].B_Model}
                                                </p>
                                                <p className="title-review-score">
                                                    B.Model
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="block-top-cart-new-startup-marketplace">
                                <div className="block-bg-top-cart-new-startup-marketplace">
                                    <div className="bg-top-cart-new-startup-marketplace"
                                         style={{backgroundImage: `url(${_bg})`}}/>


                                    {item.hasVideo && <div className="block-icon-play-top-cart-new-startup-marketplace">
                                        <i className="fas fa-play"/>
                                    </div>}
                                </div>
                                <div className="block-ing-cart-new-startup-marketplace">
                                    {_avatar}
                                </div>
                            </div>
                            <div className="block-body-cart-new-startup-marketplace">
                                <p className="title-body-cart-new-startup-marketplace" title={item.title}>
                                    {item.title}
                                </p>
                                <p className="description-body-cart-new-startup-marketplace">
                                    {item.shortDescription.descriptionFormat()}
                                </p>
                                <div className="block-list-industries-body-cart-new-startup-marketplace">
                                    <p className="title-industries-body-cart-new-startup-marketplace">
                                        Industries
                                    </p>

                                    {listIndustries && item.industry.slice(0, 4).map(id => {
                                        return <div key={id}
                                                    className="block-item-industries-body-cart-new-startup-marketplace">
                                            {listIndustries.find((a) => a.id === id) &&
                                            <p className="title-item-industries-body-cart-new-startup-marketplace">
                                                {listIndustries.find((a) => a.id === id).title}
                                            </p>
                                            }
                                        </div>
                                    })}
                                    {item.industry.length > 4 &&
                                    <div className="block-item-industries-body-cart-new-startup-marketplace">
                                        <p className="title-item-industries-body-cart-new-startup-marketplace">
                                            {item.industry.length} more…
                                        </p>
                                    </div>
                                    }
                                </div>
                                <div className="block-list-button-body-cart-new-startup-marketplace">
                                    <div className="block-item-button-body-cart-new-startup-marketplace m">
                                        <i className={`fas fa-heart${item.iLiked ? ' liked' : ''}`}/>
                                        <p className="title-item-button-body-cart-new-startup-marketplace">
                                            {item.likes}
                                        </p>
                                    </div>
                                    <div className="block-item-button-body-cart-new-startup-marketplace m">
                                        <i className="far fa-clock"/>
                                        <p className="title-item-button-body-cart-new-startup-marketplace">
                                            ${item.invest_sum ? item.invest_sum.toMoneyFormat2() : '0'}
                                        </p>
                                    </div>
                                    <div className="block-item-button-body-cart-new-startup-marketplace">
                                        <i className="far fa-share-alt"/>
                                        <p className="title-item-button-body-cart-new-startup-marketplace">
                                            {item.shared || 0}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="block-bottom-cart-startup-marketplace">
                                <p className="description-bottom-cart-startup-marketplace">
                                    {item.views || 0} views
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            )
        } catch (e) {
            return (
                <React.Fragment/>
            )
        }

};
import React, {Component} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import ProfileToList from './profileMenuFrame/ProfileToList';
import LikeBlockToList from './profileMenuFrame/LikeBlockToList';
import NewLikeBlockToList from './profileMenuFrame/NewLikeBlockToList';
import EvalParamToList from './profileMenuFrame/EvalParamToList';
import BlackButtonProfile from './blackButtonProfile/BlackButtonProfile';

import $ from 'jquery'
import moment from 'moment';
import './profileMenuFrame/style.css'
import './style.css'
import ApolloClient from "apollo-boost"
import {gql} from 'apollo-boost';
import {Query} from 'react-apollo';
import history from '../../../history';

import config from "../../../config";
import {connect} from "react-redux";
import {getMyProject2} from "../../../actions/Project";
import {withRouter} from "react-router";
import {getMyFund} from "../../../actions/Fund";
import PropTypes from "prop-types";
import axios from "axios/index";
import {getProjectAction} from "../../../actions/ProjectActions";
import {updateMenu} from "../../../actions/menu";
import {getListParameters} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";
import {createExpertParameter, createRole} from "../../../actions/Login";
import Popup from '../../edit/steps/components/popup';
import {createAvatar} from "../../user/createAvatar"
import {generateEvent} from "../../../actions/amplitude";
import {Link} from "react-router-dom";
import RocketDAOBlue from "../../../images/logo_beta.svg";

const GET_USER = gql`
    query {
        user{
            id
            email
            lastName
            firstName
            logoFileId
            unsubscribe
            startupList{
                id
                title
                logoFileId
                isFinished
            }
            fundList{
                id
                title
                logoFileId
                isFinished
            }
            acceleratorList{
                id
                title
                logoFileId
                isFinished
            }
            angelNetworkList{
                id
                title
                logoFileId
                isFinished
            }
            expert{
                id
                professionalIdentification
                parameters
                isFinished
            }
            investor{
                id
                isFinished     
            }
            likes
        }
    }
`;

const client = new ApolloClient({
    uri: `${config.proxy}/api`,
    request: async operation => {
        const token = await localStorage.getItem('RocketToken');
        if (token) {
            operation.setContext({
                headers: {
                    authorization: token ? `Bearer ${token}` : ''
                }
            });
        }
    }
});

class ProfileMenuFrame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            openedMenu: 'none',
            openedRole: 'none',
            currentParam: {},
            openParameters: false,
            listParameters: []
        }
        this.refetch = null;
    }

    menuClose = () => {
        $('.' + this.state.openedMenu + '-menu-block').animate({
            left: '-500px'
        }, 400).removeClass('active');

        // $('.' + this.state.openedMenu + '-menu-block').animate({
        //     left: '-500px'
        // }, 400).removeClass('active');
        this.setState({openedMenu: 'none'})
    };

    exitMenuArrow = () => {

        $('.profile-menu-frame.active').animate({
            left: '-500px'
        }, 400).removeClass('active');


        if (this.state.openedMenu === 'none') {

            let pathname = window.location.pathname;
            if (/edit/.test(pathname)) {
                // window.location.href = '/'
            }
        }
    }

    componentDidMount() {
        getListParameters()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({listParameters: res.data})
            })
            .catch(err => {

            })
    }

    CloseRole = () => {
        this.setState({openedRole: 'null'})
    }

    OpenRole = (type) => {
        this.CloseRole();
        if (this.state.openedRole !== type) {
            this.setState({openedRole: type})
        } else {
            this.CloseRole();
        }
    }

    menuOpen = (type) => {
        this.menuClose();

        console.log(this.state.openedMenu, type);

        if (this.state.openedMenu !== type) {
            this.setState({openedMenu: type})
            $('.' + type + '-menu-block').animate({
                left: '56px'
            }, 500);
            $('.' + type + '-menu-block').addClass('active')
        }
    }

    openParameters = (e) => {
        const {openParameters} = this.state;
        this.setState({openParameters: !openParameters})
    }

    setCurrentParam = (param) => {
        this.setState({currentParam: param})
    }

    componentDidUpdate(nextProps) {
        try {
            if (nextProps.menu.object.type === 'update') {
                if (this.props.updateMenu) {
                    this.props.updateMenu({type: '-1'});
                }
                this.refetch();
            }
            if (nextProps.menu.object.type === 'close') {
                if (this.props.updateMenu) {
                    this.props.updateMenu({type: '-1'});
                }
                this.exitMenuArrow();
                this.menuClose();

                if (this.props.changeClose)
                    this.props.changeClose('notNone')
            }
        } catch (e) {
            if (this.props.updateMenu) {
                this.props.updateMenu({type: '-1'});
            }
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     try {
    //         if (nextProps.menu.object.type === 'update') {
    //             this.refetch();
    //             if (this.props.updateMenu) {
    //                 this.props.updateMenu({type: '-1'});
    //             }
    //         }
    //
    //         if (nextProps.menu.object.type === 'close') {
    //             if (this.props.updateMenu) {
    //                 this.props.updateMenu({type: '-1'});
    //             }
    //             // this.exitMenuArrow();
    //             this.menuClose();
    //
    //             if (this.props.changeClose)
    //                 this.props.changeClose('notNone')
    //         }
    //     } catch (e) {
    //         if (this.props.updateMenu) {
    //             this.props.updateMenu({type: '-1'});
    //         }
    //     }
    // }

    createParameter = (parameterId) => {
        createExpertParameter({}, parameterId).then(r => {
            this.refetch();
        }).catch(e => {
            console.log(e);
        })
    };

    showPopup(e) {
        this.setState({show: true});
        document.getElementsByTagName('BODY')[0].style.overflow = 'hidden';
    }

    closePopup() {
        this.setState({show: false});
        this.props.updateMenu({type: 'update'});
        document.getElementsByTagName('BODY')[0].style.overflow = 'inherit';
    }

    logout = () => {
        localStorage.removeItem('RocketToken');
        history.push({pathname: `/`});
        setTimeout(() => {
            window.location.reload();
        }, 200)
    };

    login = () => {

        localStorage.removeItem('referer');
        localStorage.removeItem('referer_startup');

        localStorage.setItem('rFrom', window.location.pathname);
        history.push('/login')
    };

    goToSettings = (page) => {
        history.push(`/edit/user/${page}`, {activeLeft: true, active: false});
        window.scrollTo(0, 0,);
    };

    render() {

        let {
            open,
            changeClose,
        } = this.props;


        return (
            <Query query={GET_USER} client={client} fetchPolicy={'no-cache'}>
                {({loading, error, data, refetch}) => {
                    this.refetch = refetch;
                    // if(loading) return <div>Loading</div>
                    if (error) return null;
                    if (!data || !data.user) {
                        return <div>
                            <div className={`profile-menu-frame ${open && 'active'}`}>
                                <div className='main-menu-block'>
                                    <div className='menu-block-dao'>
                                        <div className='icon'/>
                                    </div>
                                    <div className='menu-block-arrow-back' onClick={() => {
                                        this.menuClose();
                                        changeClose(this.state.openedMenu)
                                    }}/>

                                    <div className="block-list-link-menu-mobile">
                                        {/*<div className="link-home-menu-mobile">*/}
                                        {/*    <a href="">Главная</a>*/}
                                        {/*</div>*/}

                                        <div className='block-blog-mobile-menu'>
                                            <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                history.push('/start_reg?type=3');
                                                generateEvent('start_registration', {role: 'startup', source: 'menu'})
                                            }}>
                                                Create startup
                                            </p>
                                        </div>
                                        <div className='block-blog-mobile-menu'>
                                            <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                history.push('/start_reg?type=6');
                                                generateEvent('start_registration', {role: 'investor', source: 'menu'})
                                            }}>
                                                Become investor
                                            </p>
                                        </div>
                                        <div className='block-blog-mobile-menu'>
                                            <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                history.push('/start_reg?type=2');
                                                generateEvent('start_registration', {role: 'expert', source: 'menu'})
                                            }}>
                                                Join experts
                                            </p>
                                        </div>
                                        <div className='block-blog-mobile-menu'>
                                            <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                history.push('/start_reg?type=7');
                                                generateEvent('start_registration', {
                                                    role: 'accelerator',
                                                    source: 'menu'
                                                })
                                            }}>
                                                Add accelerator
                                            </p>
                                        </div>

                                        <div
                                            className={`block-role-list-user-menu-mobile ${this.state.openedRole === 'about' && 'active'}`}
                                            style={{marginTop: '16px'}}>
                                            <div className="block-top-role-list-user-menu-mobile"
                                                 onClick={() => this.OpenRole('about')}>
                                                <p className="title-top-role-list-user-menu-mobile">
                                                    About
                                                </p>
                                            </div>
                                            <div className="block-body-role-list-user-menu-mobile about-button"
                                                 style={this.state.openedRole !== 'about' ? {height: '0px'} : {height: 'auto'}}>
                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        history.push('/about');
                                                        window.scrollTo(0, 0);
                                                    }}>
                                                        About the platform
                                                    </p>
                                                </div>

                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        history.push('/evaluation/landing/startup');
                                                        window.scrollTo(0, 0);
                                                    }}>
                                                        Evaluation for startups
                                                    </p>
                                                </div>
                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        history.push('/evaluation/landing/investor');
                                                        window.scrollTo(0, 0);
                                                    }}>
                                                        Evaluation for investors
                                                    </p>
                                                </div>
                                            </div>

                                            <div className='block-blog-mobile-menu'
                                                 style={{margin: '16px 20px 20px 20px'}}>
                                                <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                    window.open("https://startupjedi.vc/");

                                                }}>
                                                    Blog
                                                </p>
                                            </div>

                                            <div className="link-settings-menu-mobile">
                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        this.login()
                                                    }}>
                                                        Login
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } else {
                        return (
                            <div>
                                <div
                                    className={`profile-menu-frame ${open && 'active'}`}>
                                    <div className='main-menu-block'>
                                        <div className='menu-block-dao' style={{cursor: 'pointer'}}>
                                            <a href="/">
                                                <div className='icon'/>
                                            </a>
                                        </div>
                                        <div className='menu-block-arrow-back menu' onClick={() => {
                                            this.exitMenuArrow();
                                            this.menuClose();
                                            changeClose(this.state.openedMenu)
                                        }}/>

                                        <div className='menu-block-icon-like' onClick={() => this.menuOpen('like')}/>
                                        <div className='menu-block-roles'>
                                            <div className='button-block' onClick={() => this.menuOpen('startup')}>
                                                <div className='title'>Startup</div>
                                                <div className='block-startup block-role'/>
                                            </div>


                                            <div className='button-block' onClick={() => this.menuOpen('investor')}>
                                                <div className='title'>Investor</div>
                                                <div className='block-investor block-role'/>
                                            </div>


                                            {data.user.expert && data.user.expert.isFinished &&
                                            <div className='button-block' onClick={() => this.menuOpen('expert')}>
                                                <div className='title'>Expert</div>
                                                <div className='block-expert block-role'/>
                                            </div>}

                                            <div className='button-block' onClick={() => this.menuOpen('accelerator')}>
                                                <div className='title'>Accelerator</div>
                                                <div className='block-accelerator block-role'/>
                                            </div>
                                        </div>

                                        <div className="block-list-link-menu-mobile">
                                            {/*<div className="link-home-menu-mobile">*/}
                                            {/*    <a href="">Главная</a>*/}
                                            {/*</div>*/}

                                            <div
                                                className={`block-role-list-user-menu-mobile ${this.state.openedRole === 'about' && 'active'}`}>
                                                <div className="block-top-role-list-user-menu-mobile"
                                                     onClick={() => this.OpenRole('about')}>
                                                    <p className="title-top-role-list-user-menu-mobile">
                                                        About
                                                    </p>
                                                </div>
                                                <div className="block-body-role-list-user-menu-mobile about-button"
                                                     style={this.state.openedRole !== 'about' ? {height: '0px'} : {height: 'auto'}}>
                                                    <div className='block-settings-menu-mobile'>
                                                        <p className="title-top-role-list-user-menu-mobile"
                                                           onClick={() => {
                                                               history.push('/about');
                                                               window.scrollTo(0, 0);
                                                           }}>
                                                            About the platform
                                                        </p>
                                                    </div>

                                                    <div className='block-settings-menu-mobile'>
                                                        <p className="title-top-role-list-user-menu-mobile"
                                                           onClick={() => {
                                                               history.push('/evaluation/landing/startup');
                                                               window.scrollTo(0, 0);
                                                           }}>
                                                            Evaluation for startups
                                                        </p>
                                                    </div>
                                                    <div className='block-settings-menu-mobile'>
                                                        <p className="title-top-role-list-user-menu-mobile"
                                                           onClick={() => {
                                                               history.push('/evaluation/landing/investor');
                                                               window.scrollTo(0, 0);
                                                           }}>
                                                            Evaluation for investors
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className='block-blog-mobile-menu'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        window.open("https://startupjedi.vc/");
                                                    }}>
                                                        Blog
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="links-user-menu-mobile">
                                                {/*<div className="block-search-menu-mobile"></div>*/}
                                                <div className="block-link-icon-user-menu-mobile"
                                                     onClick={() => this.menuOpen('like')}>
                                                    <p className="title-link-user-menu-mobile">Likes</p>
                                                    <i className="far fa-heart"/>
                                                </div>
                                                {/*<div className="block-link-icon-user-menu-mobile">*/}
                                                {/*    <p className="title-link-user-menu-mobile">Сообщения</p>*/}
                                                {/*    <i className="far fa-comment"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="block-link-icon-user-menu-mobile active">*/}
                                                {/*    <p className="title-link-user-menu-mobile">Нотификации</p>*/}
                                                {/*    <i className="far fa-bell"/>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className="list-role-user-menu-mobile">
                                                <div
                                                    className={`block-role-list-user-menu-mobile ${this.state.openedRole === 'startup' && 'active'}`}>
                                                    <div className="block-top-role-list-user-menu-mobile"
                                                         onClick={() => this.OpenRole('startup')}>
                                                        <p className="title-top-role-list-user-menu-mobile">
                                                            Your startup
                                                        </p>
                                                    </div>
                                                    <div className="block-body-role-list-user-menu-mobile"
                                                         style={this.state.openedRole !== 'startup' ? {height: '0px'} : {height: 'auto'}}>
                                                        {(data.user.startupList.map((startup, i) => {
                                                            return <ProfileToList
                                                                key={i}
                                                                id={startup.id}
                                                                type={'startup'}
                                                                name={startup.title}
                                                                avatar={startup.logoFileId ? `${config.proxy}/file/${startup.logoFileId}/view` : null}
                                                                isCompleted={startup.isFinished}
                                                            />
                                                        }))}
                                                        <div className='icon-add'
                                                             onClick={() => window.open('/registration/startup/1', '_blank')}/>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`block-role-list-user-menu-mobile ${this.state.openedRole === 'Investments' && 'active'}`}>
                                                    <div className="block-top-role-list-user-menu-mobile"
                                                         onClick={() => this.OpenRole('Investments')}>
                                                        <p className="title-top-role-list-user-menu-mobile">
                                                            Investor
                                                        </p>
                                                    </div>
                                                    <div className="block-body-role-list-user-menu-mobile"
                                                         style={this.state.openedRole !== 'Investments' ? {height: '0px'} : {height: 'auto'}}>
                                                        {data.user.investor &&
                                                        <div className='role-container'>
                                                            <div className='title first-title'>Investor:</div>

                                                            {data.user.investor ? <ProfileToList
                                                                id={data.user.investor.id}
                                                                type={'investor'}
                                                                name={`${data.user.firstName} ${data.user.lastName}`}
                                                                avatar={data.user.logoFileId ? `${config.proxy}/file/${data.user.logoFileId}/view` : null}
                                                                isCompleted={data.user.investor.isFinished}
                                                            /> : <div className='icon-add'
                                                                      onClick={() => history.push('/registration/investor/1')}/>
                                                            }
                                                        </div>
                                                        }
                                                        {data.user.fundList &&
                                                        <div className='role-container'>
                                                            <div className='title'>Fund:</div>
                                                            {(data.user.fundList.map((fund, i) => {
                                                                return <ProfileToList
                                                                    key={i}
                                                                    id={fund.id}
                                                                    type={'fund'}
                                                                    name={fund.title}
                                                                    avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                                    isCompleted={fund.isFinished}
                                                                />
                                                            }))}
                                                            <div className='icon-add'
                                                                 onClick={() => window.open('/create/fund/1', '_blank')}/>

                                                        </div>
                                                        }
                                                        {data.user.angelNetworkList &&
                                                        <div className='role-container'>
                                                            <div className='title'>Angel Network:</div>
                                                            {(data.user.angelNetworkList.map((fund, i) => {
                                                                return <ProfileToList
                                                                    key={i}
                                                                    id={fund.id}
                                                                    type={'angel_network'}
                                                                    name={fund.title}
                                                                    avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                                    isCompleted={fund.isFinished}
                                                                />
                                                            }))}
                                                            <div className='icon-add'/>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>

                                                {data.user.expert && data.user.expert.isFinished &&
                                                <div className="block-role-list-user-menu-mobile">
                                                    <div className="block-top-role-list-user-menu-mobile"
                                                         onClick={() => this.menuOpen('expert')}>
                                                        <p className="title-top-role-list-user-menu-mobile">
                                                            Expert
                                                        </p>
                                                    </div>
                                                </div>
                                                }
                                                <div
                                                    className={`block-role-list-user-menu-mobile ${this.state.openedRole === 'accelerator' && 'active'}`}>
                                                    <div className="block-top-role-list-user-menu-mobile"
                                                         onClick={() => this.OpenRole('accelerator')}>
                                                        <p className="title-top-role-list-user-menu-mobile">
                                                            Accelerator
                                                        </p>
                                                    </div>
                                                    <div className="block-body-role-list-user-menu-mobile"
                                                         style={this.state.openedRole !== 'accelerator' ? {height: '0px'} : {height: 'auto'}}>
                                                        {(data.user.acceleratorList.map((fund, i) => {
                                                            return <ProfileToList
                                                                key={i}
                                                                id={fund.id}
                                                                type={'accelerator'}
                                                                name={fund.title}
                                                                avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                                isCompleted={fund.isFinished}
                                                            />
                                                        }))}
                                                        <div className='icon-add'
                                                             onClick={() => window.open('/start_accelerator', '_blank')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="link-settings-menu-mobile">
                                                {/*<a href="">Настройки</a>*/}
                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile"
                                                       onClick={this.showPopup.bind(this)}>
                                                        Settings
                                                    </p>
                                                </div>
                                                <div className='block-settings-menu-mobile'>
                                                    <p className="title-top-role-list-user-menu-mobile" onClick={() => {
                                                        this.logout()
                                                    }}>
                                                        Logout
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        {data.user.logoFileId && <div className='menu-block-avatar bottom-50'
                                                                      style={data.user.logoFileId ? {backgroundImage: `url(${`${config.proxy}/file/${data.user.logoFileId}/view`})`} : null}
                                                                      onClick={() => {
                                                                          (process.env.NODE_ENV === 'production') ? this.showPopup.bind(this) : console.log()
                                                                      }}
                                                                      onMouseEnter={() => {
                                                                          (process.env.NODE_ENV !== 'production') ? $('.user-settings-popup').stop().fadeIn(300) : console.log()
                                                                      }}
                                                                      onMouseLeave={() => {
                                                                          (process.env.NODE_ENV !== 'production') ? $('.user-settings-popup').stop().fadeOut(300) : console.log()
                                                                      }}
                                        /> ||

                                        <div className='menu-block-avatar bottom-50'
                                             onClick={() => {
                                                 (process.env.NODE_ENV === 'production') ? this.showPopup.bind(this) : console.log()
                                             }}
                                        >
                                            {createAvatar('min', data.user.firstName || 'R')}
                                        </div>
                                        }
                                        <div className='user-settings-popup'
                                             onMouseEnter={() => {
                                                 (process.env.NODE_ENV !== 'production') ? $('.user-settings-popup').stop().fadeIn(300) : console.log()
                                             }}
                                             onMouseLeave={() => {
                                                 (process.env.NODE_ENV !== 'production') ? $('.user-settings-popup').stop().fadeOut(300) : console.log()
                                             }}>

                                            <div
                                                className='settings-popup-line-name settings-popup-name'>{data.user.firstName} {data.user.lastName}</div>
                                            <div className='settings-popup-line settings-popup-title' onClick={() => {
                                                this.goToSettings('profile')
                                            }}>Profile
                                            </div>
                                            <div className='settings-popup-line settings-popup-title' onClick={() => {
                                                this.goToSettings('account')
                                            }}>Account
                                            </div>
                                            <div className='settings-popup-line settings-popup-title' onClick={() => {
                                                this.logout()
                                            }}>Logout
                                            </div>
                                        </div>
                                    </div>

                                    <div className='like-menu-block'>
                                        <div className="block-button-hamburger-like">
                                            <div className="button-hamburger-new" onClick={() => this.menuClose()}>
                                                <span className="line-hamburger-new line-new-1"/>
                                                <span className="line-hamburger-new line-new-2"/>
                                                <span className="line-hamburger-new line-new-3"/>
                                                <span className="circle-state"/>
                                            </div>
                                            <div className='title-mob'>Likes</div>
                                        </div>
                                        <div className='title'>Likes</div>
                                        <Scrollbars>

                                            {data.user.likes.map((i, k) => {
                                                if (i.Project) {
                                                    return (
                                                        <NewLikeBlockToList key={k}
                                                                            idStartup={i.Project.id}
                                                                            avatar={i.Project.logoFileId ? `${config.proxy}/file/${i.Project.logoFileId}/view` : null}
                                                                            name={i.Project.title}
                                                                            descr={i.Project.shortDescription}
                                                                            startup={i.Project}
                                                                            time={moment(i.updatedAt).fromNow()}
                                                        />
                                                    )
                                                } else {

                                                }
                                            })}
                                        </Scrollbars>
                                    </div>

                                    <div className='startup-menu-block roles-menu-block'>
                                        <Scrollbars>
                                            <div className='role-container'>
                                                <div className='title'>Your startups</div>

                                                {(data.user.startupList.map((startup, i) => {
                                                    return <ProfileToList
                                                        key={i}
                                                        id={startup.id}
                                                        type={'startup'}
                                                        name={startup.title}
                                                        avatar={startup.logoFileId ? `${config.proxy}/file/${startup.logoFileId}/view` : null}
                                                        isCompleted={startup.isFinished}
                                                    />
                                                }))}
                                                <div className='icon-add'
                                                     onClick={() => window.open('/registration/startup/1', '_blank')}/>
                                            </div>
                                        </Scrollbars>
                                    </div>

                                    <div className='accelerator-menu-block roles-menu-block'>
                                        <Scrollbars>
                                            <div className='role-container'>
                                                <div className='title'>Accelerator</div>

                                                {(data.user.acceleratorList.map((fund, i) => {
                                                    return <ProfileToList
                                                        key={i}
                                                        id={fund.id}
                                                        type={'accelerator'}
                                                        name={fund.title}
                                                        avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                        isCompleted={fund.isFinished}
                                                    />
                                                }))}
                                                <div className='icon-add'
                                                     onClick={() => window.open('/start_accelerator', '_blank')}/>


                                            </div>
                                        </Scrollbars>
                                    </div>

                                    <div className='investor-menu-block roles-menu-block'>
                                        <Scrollbars>
                                            <div className='role-container'>
                                                <div className='title first-title'>Investor</div>

                                                {data.user.investor ? <ProfileToList
                                                    id={data.user.investor.id}
                                                    type={'investor'}
                                                    name={`${data.user.firstName} ${data.user.lastName}`}
                                                    avatar={data.user.logoFileId ? `${config.proxy}/file/${data.user.logoFileId}/view` : null}
                                                    isCompleted={data.user.investor.isFinished}
                                                /> : <div className='icon-add'
                                                          onClick={() => window.open('/start_reg?type=1', '_blank')}
                                                />
                                                }
                                            </div>
                                            <div className='role-container'>
                                                <div className='title'>Fund</div>
                                                {(data.user.fundList.map((fund, i) => {
                                                    return <ProfileToList
                                                        key={i}
                                                        id={fund.id}
                                                        type={'fund'}
                                                        name={fund.title}
                                                        avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                        isCompleted={fund.isFinished}
                                                    />
                                                }))}
                                                <div className='icon-add'
                                                     onClick={() => window.open('/create/fund/1', '_blank')}/>

                                            </div>
                                            <div className='role-container'>
                                                <div className='title'>Angel Network</div>
                                                {(data.user.angelNetworkList.map((fund, i) => {
                                                    return <ProfileToList
                                                        key={i}
                                                        id={fund.id}
                                                        type={'angel_network'}
                                                        name={fund.title}
                                                        avatar={fund.logoFileId ? `${config.proxy}/file/${fund.logoFileId}/view` : null}
                                                        isCompleted={fund.isFinished}
                                                    />
                                                }))}
                                                <div className='icon-add'
                                                     onClick={() => window.open('/create/angel_network/1', '_blank')}/>
                                            </div>
                                        </Scrollbars>
                                    </div>

                                    {data.user.expert && <div className='expert-menu-block roles-menu-block'>
                                        <div className="block-logo-roles-menu-block">
                                            <div className='menu-block-dao'>
                                                <div className='icon'/>
                                            </div>
                                        </div>
                                        <div className="block-button-back-roles-menu-block">
                                            <div className='menu-block-arrow-back' onClick={() => {
                                                this.menuClose()
                                            }}/>
                                        </div>


                                        <Scrollbars>
                                            <div className='block-expert-menu-header'>
                                                <div className='expert-header-avatar'
                                                     style={data.user.logoFileId ? {backgroundImage: `url(${`${config.proxy}/file/${data.user.logoFileId}/view`})`} : null}/>
                                                <div className='expert-header-text'>
                                                    <div
                                                        className='expert-header-40-simbols'>{data.user.expert.professionalIdentification}
                                                    </div>
                                                    <div className='expert-header-balance'></div>
                                                </div>
                                                <div className='expert-header-profile-button'>
                                                    <BlackButtonProfile
                                                        onClick={() => window.open(`/expert/${data.user.expert.id}`, '_blank')}
                                                        ButtonTitle='See profile'
                                                    />
                                                </div>
                                                <div className='expert-header-edit-button'>
                                                    <BlackButtonProfile
                                                        onClick={() => history.push('/edit/expert', {active: true})}
                                                        ButtonTitle='Edit'
                                                    />
                                                </div>
                                            </div>

                                            {data.user.expert && <div className='block-expert-evaluation-param'>
                                                <div className='expert-eval-param-title'>Evaluation parameters</div>
                                                {(data.user.expert.parameters.map((p, i) => <EvalParamToList
                                                    key={i}
                                                    name={p.RoleParameter.title}
                                                    paramCount={0}
                                                    position={p.RoleParameter.type}
                                                    onClick={() => {
                                                        history.push(`/edit/expert/achievement/${p.RoleParameter.id}`, {active: true})
                                                    }}
                                                />))}

                                                <div className='icon-add expert-icon-add'
                                                     onClick={this.openParameters}/>


                                                <div
                                                    className={`block-list-add-parameters ${this.state.openParameters ? 'open' : ''}`}
                                                    style={{height: this.state.openParameters ? `calc((40px * ${this.state.listParameters.length - data.user.expert.parameters.length}) + 15px)` : 0}}>
                                                    {
                                                        this.state.listParameters.map((p) => {
                                                                if (!data.user.expert.parameters.find((item) => p.id === item.parameterId)) {
                                                                    return <div className="block-item-add-parameters" key={p.id}
                                                                                onClick={() => this.createParameter(p.id)}>
                                                                        <p className="title-item-add-parameters">
                                                                            {p.title}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            }
                                                        )
                                                    }
                                                </div>


                                                <div className='block-expert-tasks'>
                                                    <div className='menu-title'>Expert tasks</div>
                                                    <div className='tasks-count'>
                                                        <div>0/0</div>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className='block-expert-menu-bottom'>
                                                <div className='menu-title'>
                                                    <Link to={'/knowledge_base'}>
                                                        Knowledge base
                                                    </Link>
                                                </div>
                                                <div className='menu-title'>FAQ</div>
                                            </div>
                                        </Scrollbars>
                                    </div>}
                                </div>

                                {this.state.show &&
                                <Popup type={'user'} step={'step1'} object={{title: 'User basic information'}}
                                       data={data.user} close={this.closePopup.bind(this)}/>
                                }

                            </div>
                        )
                    }

                }}
            </Query>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state
});

ProfileMenuFrame.propTypes = {
    user: PropTypes.object,
};

export default connect(mapStateToProps, {updateMenu})(ProfileMenuFrame);
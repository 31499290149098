import React from 'react';
import './style.css'

export default class Categorie extends React.Component {

    render() {
        let {
            TitleCategorie,
            EventOnClick,
            active,
            count,
        } = this.props;

        let classList = active?"block-button-categorie active":"block-button-categorie"
        let counter = count?<span className="counter">{count}</span>:null;

        return (
            <div className={classList} onClick={EventOnClick}>
                <span className="left"/>
                <p className="title">{TitleCategorie}</p>
                {counter}
            </div>
        );
    }
}


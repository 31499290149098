import React from 'react';
import './PageViewControls.css'

import ViewButton from './ViewButton'
import ViewInput from './ViewInput'
import ViewCategorie from './ViewCategorie'
import ViewTabs from './ViewTabs'
import ViewText from './ViewText'
import ViewSocialButton from './ViewSocialButton'
import ViewSelect from './ViewSelect'
import ViewSwipe from './ViewSwipe'
import ViewMarketplace from './ViewMarketplace'
import ViewCartAnim from './ViewCartAnim'

export default class PageViewControls extends React.Component {
    constructor(props){
        super();
        this.state = {
            activeTabPage:'ViewCartAnim'
        }
        this.tabControl = this.tabControl.bind(this);
    }

    tabControl(e) {
        this.setState({
            activeTabPage: e,
        });
    }

    render() {

        let page = <ViewButton/>
        if (this.state.activeTabPage == 'viewButton') {
            page = <ViewButton/>
        }
        if (this.state.activeTabPage == 'viewInput') {
            page = <ViewInput/>
        }
        if (this.state.activeTabPage == 'viewCategorie') {
            page = <ViewCategorie/>
        }
        if (this.state.activeTabPage == 'viewTabs') {
            page = <ViewTabs/>
        }
        if (this.state.activeTabPage == 'ViewText') {
            page = <ViewText/>
        }
        if (this.state.activeTabPage == 'ViewSocialButton') {
            page = <ViewSocialButton/>
        }
        if (this.state.activeTabPage == 'ViewSelect') {
            page = <ViewSelect/>
        }
        if (this.state.activeTabPage == 'ViewSwipe') {
            page = <ViewSwipe/>
        }
        if (this.state.activeTabPage == 'ViewMarketplace') {
            page = <ViewMarketplace/>
        }
        if (this.state.activeTabPage == 'ViewCartAnim') {
            page = <ViewCartAnim/>
        }

        return (
            <div className="page-view-controls">
                <div className="block-left-view-controls">

                    <div className={this.state.activeTabPage == 'viewButton'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('viewButton')}>
                        <p className="title">Кнопки</p>
                    </div>
                    <div className={this.state.activeTabPage == 'viewInput'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('viewInput')}>
                        <p className="title">Инпуты</p>
                    </div>
                    <div className={this.state.activeTabPage == 'viewCategorie'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('viewCategorie')}>
                        <p className="title">Кнопки категории</p>
                    </div>
                    <div className={this.state.activeTabPage == 'viewTabs'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('viewTabs')}>
                        <p className="title">Кнопки табы</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewText'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewText')}>
                        <p className="title">Текст</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewSocialButton'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewSocialButton')}>
                        <p className="title">Кнопки регистрации через соц</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewSelect'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewSelect')}>
                        <p className="title">Select (DropDown)</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewSwipe'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewSwipe')}>
                        <p className="title">Swipe</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewMarketplace'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewMarketplace')}>
                        <p className="title">ViewMarketplace</p>
                    </div>
                    <div className={this.state.activeTabPage == 'ViewCartAnim'? "block-button-page-view-control active":"block-button-page-view-control"} onClick={() => this.tabControl('ViewCartAnim')}>
                        <p className="title">ViewCartAnim</p>
                    </div>
                </div>
                <div className="block-right-view-controls">
                    {page}
                </div>
            </div>
        );
    }
}

import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {updateStartup} from "../../../actions/Login";
import ButtonLoad from './../../controls3.0/ButtonLoad';
import {COUNTRIES, LANGUAGES} from '../../../utils/countriesLanguages'
import Select from "../../controls3.0/select/Select";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import human from '../../../images/svg/human.svg'
import {getLastProject, updateProject} from "../../../actions/Project";
import {getMoneyView} from '../../../utils/transformation'

class Step7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            valuationCap: 0,
            value: 10
        }
    }

    componentDidMount() {
        if(!this.state.project.id){
            this.props.getLastProject()
        } else {
            this.calculate()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project}, () => this.calculate())
        }
    }

    onChange = (name, value) => {
        this.setState({project: {...this.state.project, [name]: value}}, this.calculate())
    }

    onChange2 = (e) => {
        let name = e.target.name,
            value = e.target.value;
        let project = this.state.project,
            MAXCS

        value = value.replace(/\D/, "");

        if (project.round == 'Pre-Seed') {
            MAXCS = 500000
        } else if (project.round == 'Seed') {
            MAXCS = 5000000
        } else if (project.round == 'Series A' || project.round == 'Series B') {
            MAXCS = 10000000
        }

        switch (name){
            case 'capitalSeeking':
                if(Number(value) > MAXCS) {
                    value = MAXCS;
                }

                if(typeof value == 'string') value = value.replace(/\s+/g, '')

                break
            case 'entityOffering':
                if(Number(value) > 100) value = 100;
                break

        }



        this.setState({project: {...this.state.project, [name]: value}}, () => this.calculate())
    }



    calculate = () => {
        let project = this.state.project,
            MAXCS

        let capitalSeeking = project.capitalSeeking
        if(typeof capitalSeeking == 'string') capitalSeeking = capitalSeeking.replace(/\s+/g, '')

        if (project.round == 'Pre-Seed') {
            MAXCS = 500000
        } else if (project.round == 'Seed') {
            MAXCS = 5000000
        } else if (project.round == 'Series A' || project.round == 'Series B') {
            MAXCS = 10000000
        }

        console.log('capitalSeeking', capitalSeeking)


        if(Number(capitalSeeking) > MAXCS) {
            capitalSeeking = MAXCS;
            this.setState({project: {...this.state.project, capitalSeeking: capitalSeeking}})
        }

        let entityOffering = project.entityOffering,
            valuationCap = Math.round((capitalSeeking / entityOffering) * 100)

        if (valuationCap == 'NaN' || valuationCap == 'Infinity') valuationCap = 0

        this.setState({valuationCap: valuationCap})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            capitalSeeking: project.capitalSeeking,
            entityOffering: Number(project.entityOffering)
        }

         obj.event_type = 'startup_vc_equity_8';

        this.setState({loading: true})

        this.props.updateStartup(obj, onlyStep, nextStep).catch(err => {
            this.setState({loading: false})
        })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render() {
        let {project} = this.state;
        if (!project.capitalSeeking) project.capitalSeeking = ''
        if (!project.entityOffering) project.entityOffering = ''

        let MAXCS

        if (project.round == 'Pre-Seed') {
            MAXCS = 500000
        } else if (project.round == 'Seed') {
            MAXCS = 5000000
        } else if (project.round == 'Series A' || project.round == 'Series B') {
            MAXCS = 10000000
        }


        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 margin-0 color-text-reg-black shell-margin-bottom-30">
                                    Capital & equity
                                </p>
                                <p className="text-button  margin-0 color-text-reg-black shell-margin-bottom-30">
                                    Now it’s time to determine the exact amount of money you need to attract for
                                    further <br/>
                                    development and promotion of the project.
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-50">
                                    Equity share you are ready to offer for potential investors should be indicated as
                                    well.
                                </p>

                                {/*<div>Capital seeking: {project.capitalSeeking}$</div>*/}
                                {/*<input type="range" name="capitalSeeking" value={project.capitalSeeking}*/}
                                       {/*onChange={this.onChange} min="0" max={MAXCS}/>*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<div>Equity offering: {project.entityOffering}%</div>*/}
                                {/*<input type="range" name="entityOffering" value={project.entityOffering}*/}
                                       {/*onChange={this.onChange} min="0" max="100"/>*/}
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<div>Valuation Cap : {this.state.valuationCap}$</div>*/}


                                <div className="block-input-range slider slider-green shell-margin-bottom-40">
                                    <p className="title-input-range">Capital seeking, $<span className="value">
                                        <input type="text" name="capitalSeeking" value={parseInt(project.capitalSeeking).moneyFormat()} onChange={this.onChange2} />
                                    </span></p>
                                    <Slider
                                        min={0}
                                        max={MAXCS}
                                        step={500}
                                        tooltip={false}
                                        value={project.capitalSeeking}
                                        onChange={(value) => this.onChange('capitalSeeking', value)}
                                    />
                                    <p className="title-left-right-input-range">
                                        <span className="left">0</span>
                                        <span className="right">{getMoneyView(MAXCS)}</span>
                                    </p>
                                </div>

                                <div className="block-input-range slider slider-black shell-margin-bottom-40">
                                    <p className="title-input-range">Equity offering, %<span className="value">
                                        <input type="text" name="entityOffering" value={project.entityOffering} onChange={this.onChange2} />
                                    </span></p>
                                    <Slider
                                        min={0}
                                        max={100}
                                        tooltip={false}
                                        value={project.entityOffering}
                                        onChange={(value) => this.onChange('entityOffering', value)}
                                    />
                                    <p className="title-left-right-input-range">
                                        <span className="left">0 %</span>
                                        <span className="right">100 %</span>
                                    </p>
                                </div>

                                <p className="title-value-cap">Valuation Cap:
                                    <span className="value">{(project.capitalSeeking && project.entityOffering) ? ` ${getMoneyView(this.state.valuationCap)} $` : ' --' }</span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(6, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(8)}
                                paddingButton={'54'}
                                disabled={(!project.capitalSeeking || !project.entityOffering)}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step7.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step7)
import React from 'react'
import ReactModal from 'react-modal';
import './loginRegister.css'

import ButtonGray from '../controls3.0/button/ButtonGray'
import ButtonBlue from '../controls3.0/button/ButtonBlue'
import {Scrollbars} from "react-custom-scrollbars";

export default class ModalDocuments extends React.Component {

    constructor(props) {
        super();
        this.state = {
            toBottom: false
        }

    }

    onScroll = e => {
        if(e && e.target) {
            let scrollHeight = e.target.scrollHeight,
                scrollTop = e.target.scrollTop,
                clientHeight = e.target.clientHeight

            this.setState({test: scrollHeight - scrollTop})
            this.setState({test2: e.target.clientHeight})

            if(scrollHeight - scrollTop == clientHeight) {
                this.setState({toBottom: true})
            }
        }
    }

    componentWillUnmount = () => {
        this.setState({toBottom: false})
    }


    render() {
        return (

            <ReactModal
                isOpen={this.props.showModal}
                contentLabel="onRequestClose Example"
                onRequestClose={this.props.handleCloseModal}
                className="Modal modal-login block-modal-doc-reg-expert"
                overlayClassName="Overlay"
            >

                {this.props.type === 1 &&
                <React.Fragment>


                    <div className="block-top-doc-reg-expert">
                        <p className="title-top-doc text-h1">
                            Terms of Service
                        </p>
                    </div>
                    <div className="block-body-doc-reg-expert">

                        <Scrollbars onScroll={this.onScroll}>

                            <p className="description bold">
                                Welcome to Rocket DAO!
                            </p>
                            <br/>
                            <p className="description">
                                Thanks for choosing our platform and services (“Services”). The Services are provided
                                under the
                                brand-name Rocket DAO (“Rocket DAO”).
                            </p>
                            <br/>
                            <p className="description">
                                By using our Services, you are agreeing to these terms. Please read them carefully
                            </p>
                            <br/>
                            <p className="description">
                                This Terms of Use ("Agreement") is a binding contract between you, an individual user
                                ("you"),
                                and the applicable entity set forth in Section 16 ("Rocket DAO", "we", "us" or "our"),
                                and
                                governs your use of any website that links to these terms, including <a
                                href="https://rocketdao.io">https://rocketdao.io</a> (and all
                                related subdomains) ("Site").
                            </p>
                            <br/>
                            <p className="description bold">
                                BY ACCESSING OR USING THE SITE, YOU AGREE THAT YOU HAVE READ,
                                UNDERSTOOD AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO
                                NOT AGREE TO THIS AGREEMENT, THEN YOU MUST NOT ACCESS OR USE THE
                                SITE.
                            </p>
                            <br/>
                            <p className="description">
                                If you are accessing the Site on behalf of a business or corporate entity
                                ("Organization"), then
                                you hereby represent and warrant that you have the authority to bind that Organization
                                and your
                                acceptance of this Agreement will be treated as acceptance by the Organization. In that
                                event,
                                "User" (defined in Section 1.a) and "you" in this Agreement will refer to the
                                Organization.
                            </p>
                            <br/>
                            <p className="description dot">
                                You consent to the collection, use and disclosure of information in accordance with the
                                Rocket DAO <a href="https://test.rocketico.io/Rocket%20DAO_Privacy%20Policy.pdf">Privacy Policy</a> ("Privacy Policy");
                            </p>
                            <p className="description dot bold">
                                THE SITE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND,
                                ROCKET DAO'S LIABILITY TO YOU IN CONNECTION WITH THE SITE IS
                                LIMITED, AND YOU BEAR ALL RISKS ASSOCIATED WITH ANY
                                INVESTMENTS THAT YOU MAKE ON THE SITE;
                            </p>
                            <br/>
                            <p className="description">
                                all
                            </p>

                            <p className="description dot">
                                We will resolve disputes arising under this Agreement through binding arbitration.
                                <span className="bold">BY
                                ACCEPTING THIS AGREEMENT, AS PROVIDED IN GREATER DETAIL IN
                                SECTION 9 OF THIS AGREEMENT, YOU AND ROCKET DAO ARE EACH
                                WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                                CLASS ACTION</span>
                            </p>
                            <br/>
                            <p className="description bold">
                                1. GENERAL TERMS AND CONDITIONS.
                            </p>
                            <br/>
                            <p className="description">
                                You must follow any policies made available to you within the Services. Do not misuse
                                our
                                Services, for example, do not interfere with our Services or try to access them using a
                                method
                                other than the interface and the instructions that we provide. You may use our Services
                                only as
                                permitted by law, including applicable control laws and regulations. We may suspend or
                                stop
                                providing our Services to you if you do not comply with our terms or policies or if we
                                are
                                investigating suspected misconduct.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Description.</span><br/>
                                The public-facing part of the Site provides Users with general information about the
                                products
                                and services of Rocket DAO (the "Decentralized Funding Platform").
                            </p>
                            <br/>
                            <p className="description">
                                The authorized LOGIN/PASSWORD part of the Site provides extended access to the Rocket
                                DAO services due to the chosen User role: INVESTOR, STARTUP, EXPERT
                            </p>
                            <br/>
                            <p className="description bold">
                                Rocket DAO for BACKER/INVESTOR:
                            </p>

                            <p className="description dot">
                                presents selected investment opportunities to individuals acting on their own behalf or
                                as
                                the representative of an Organization (each such individual or Organization, an
                                "Backer/Investor"), who have registered Accounts (defined in Section 2) for the Rocket
                                DAO Platform (defined in Section 2);
                            </p>

                            <p className="description dot">
                                allows to create new or join an existing Venture Funds fuelled by Ethereum
                                smartcontracts;
                            </p>

                            <p className="description dot">
                                provides a safe, efficient and hustle-free environment for co-investment into audited
                                and
                                most prospective projects;
                            </p>

                            <p className="description dot">
                                allows to choose projects and vote for desired allocation;
                            </p>
                            <br/>
                            <p className="description bold">
                                Rocket DAO for STARTUP:
                            </p>

                            <p className="description dot">
                                makes project review by auditing the project information by the DAO’s expert’s
                                community according to well-defined methodologies;
                            </p>

                            <p className="description dot">
                                connects projects with De-centralised Venture Funds;
                            </p>

                            <p className="description dot">
                                helps the valuated projects to have an efficient way to attract investors minimizing
                                their
                                marketing costs;
                            </p>
                            <br/>
                            <p className="description bold">
                                Rocket DAO for EXPERT:
                            </p>

                            <p className="description dot">
                                allows to develop an evaluation methodology to audit of the blockchain startups;
                            </p>

                            <p className="description dot">
                                makes tasks for project evaluation in the field of expertise;
                            </p>

                            <p className="description dot">
                                provides the opportunity to monetize Expert skills and knowledge in the way of the fixed
                                and additional profit;
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Disclaimers and Other Terms.</span><br/>
                                TO THE FULLEST EXTENT PERMITTED BY LAW, AND WITHOUT LIMITING ANY
                                OTHER TERMS IN THIS AGREEMENT, THE FOLLOWING TERMS APPLY:
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Limitations on Liability.</span><br/>
                                NONE OF THE ROCKET DAO PARTIES WILL HAVE ANY LIABILITY TO YOU IN
                                CONNECTION WITH:
                            </p>

                            <p className="description dot">
                                ANY OUTAGE OR UNAVAILABILITY OF THE SITE OR ANY PROBLEMS YOU
                                MAY HAVE WITH FUNDING INVESTMENTS THAT YOU MAKE ON THE SITE;
                            </p>

                            <p className="description dot">
                                ANY SECURITY BREACH AFFECTING ANY FEATURE, CONTENT OR SERVICES
                                AVAILABLE ON THE SITE; AND/OR
                            </p>

                            <p className="description dot">
                                THE PERFORMANCE OF ANY INVESTMENT THAT YOU MAKE ON THE SITE.
                                YOU BEAR ALL RISKS OF USING THE SITE, AND YOU SHOULD ONLY INVEST
                                AMOUNTS YOU ARE WILLING AND ABLE TO LOSE.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">No Guarantee.</span><br/>
                                NONE OF THE ROCKET DAO PARTIES MAKES ANY REPRESENTATIONS REGARDING
                                THE LIKELIHOOD OR PROBABILITY THAT ANY INVESTMENT MADE WITH THE
                                HELP OF THE SITE WILL ACHIEVE A PARTICULAR INVESTMENT OUTCOME OR
                                GOAL. PAST PERFORMANCE IS NOT A GUARANTEE OF FUTURE SUCCESS, AND
                                VOLATILITY MEANS THAT RETURNS IN ANY PERIOD MAY BE FAR ABOVE OR
                                BELOW THOSE OF PREVIOUS PERIODS. YOU MAY LOSE ALL OR PART OF ANY
                                INVESTMENT YOU MAKE WITH THE HELP OF THE SITE. YOU FURTHER
                                ACKNOWLEDGE AND AGREE THAT NONE OF THE ROCKET DAO PARTIES MAKES
                                ANY GUARANTEES OR OTHER COMMITMENTS ABOUT YOUR ABILITY TO ACCESS
                                OR USE THE SITE.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Information Submitted to Us; Identity Checks.</span><br/>
                                YOU ARE SOLELY RESPONSIBLE FOR ENSURING THE ACCURACY AND
                                COMPLETENESS OF ALL INFORMATION AND MATERIALS THAT YOU PROVIDE TO
                                US IN CONNECTION WITH YOUR USE OF THE SITE, INCLUDING, WITHOUT
                                LIMITATION, ALL INFORMATION AND MATERIAL THAT YOU PROVIDE TO US IN
                                CONNECTION WITH US CHECKING YOUR BACKGROUND AS FURTHER DESCRIBED
                                BELOW. YOU HEREBY REPRESENT AND WARRANT THAT:
                            </p>
                            <p className="description dot">
                                ALL SUCH INFORMATION AND MATERIALS ARE TRUE, ACCURATE AND
                                COMPLETE IN ALL RESPECTS, COMPLY WITH ALL APPLICABLE LAWS,
                                RULES AND REGULATIONS AND DO NOT VIOLATE OR INFRINGE ANY THIRD
                                PARTY RIGHTS; AND
                            </p>

                            <p className="description dot">
                                YOU WILL IMMEDIATELY NOTIFY US ABOUT, AND CORRECT, ANY
                                INACCURACY IN ANY SUCH MATERIALS OR INFORMATION.
                            </p>
                            <br/>
                            <p className="description">
                                YOU ACKNOWLEDGE THAT WE WILL CHECK YOUR BACKGROUND AND IDENTITY
                                AS REQUIRED BY APPLICABLE LAWS IN CONNECTION WITH CERTAIN USES OF
                                THE SITE. YOU HEREBY AUTHORIZE US TO, DIRECTLY OR THROUGH THIRD
                                PARTIES (TELEGRAM.PASSPORT PROJECT, FOR EXAMPLE), MAKE ANY INQUIRIES
                                AND CONDUCT ANY INVESTIGATION WE CONSIDER NECESSARY OR HELPFUL TO
                                VERIFY YOUR IDENTITY AND TO TAKE ANY ACTIONS WE DEEM NECESSARY OR
                                HELPFUL BASED ON THE RESULTS OF SUCH INQUIRIES AND INVESTIGATIONS.
                                YOU FURTHER AUTHORIZE ANY AND ALL THIRD PARTIES TO WHICH ANY SUCH
                                INQUIRIES OR INVESTIGATIONS MAY BE DIRECTED TO FULLY RESPOND TO SUCH
                                INQUIRIES OR INVESTIGATIONS. YOU ACKNOWLEDGE AND AGREE THAT WE MAY,
                                IN OUR SOLE DISCRETION, DENY YOU THE RIGHT TO USE THE SITE. NONE OF THE
                                ROCKET DAO PARTIES WILL HAVE ANY LIABILITY TO YOU FOR ANY LIABILITY OR
                                OTHER LOSSES ARISING FROM ANY INQUIRIES OR INVESTIGATIONS ARISING
                                UNDER THIS SECTION.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">External Wallets.</span><br/>
                                YOU ACKNOWLEDGE AND AGREE THAT:
                            </p>

                            <p className="description dot">
                                WE HAVE NO CONTROL OVER ANY WALLET THAT YOU MAY USE IN
                                CONNECTION WITH YOUR USE OF THE SITE ("WALLET");
                            </p>

                            <p className="description dot">
                                ALL WALLETS ARE PROVIDED BY THIRD PARTIES THAT WE HAVE NO RIGHT
                                OR ABILITY TO CONTROL; AND
                            </p>

                            <p className="description dot">
                                ANY DISPUTE BETWEEN YOU AND ANY THIRD PARTY WALLET PROVIDER IS
                                BETWEEN YOU AND THAT THIRD PARTY WALLET PROVIDER.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Investors.</span><br/>
                                THE ONLY PEOPLE WHO ARE AUTHORIZED TO INVEST USING THE SITE ARE
                                SOPHISITICATED INVESTORS WITH PERSONAL OR PROFESSIONAL EXPERIENCE
                                ASSESSING THE LONG TERM BUSINESS PROSPECTS OF INVESTMENTS THAT MAY
                                CONTAIN A HIGH DEGREE OF RISK. INVESTORS MUST UNDERSTAND THAT
                                INVESTMENTS AVAILABLE ON THE SITE ARE SUBJECT TO A HIGH LIKELIHOOD OF
                                LOSS AND LONG PERIOD OF ILLIQUIDITY. IN ADDITION, IF YOU ARE USING THE
                                SITE AS AN INVESTOR IN THE UNITED STATES, YOU MUST QUALIFY AS AN
                                "ACCREDITED INVESTOR" AS DEFINED IN RULE 501 OF REGULATION D UNDER
                                THE SECURITEIS ACT OF 1933, AND BE SOPHISTICATED ENOUGH TO PROTECT
                                YOUR OWN INTERESTS. WE MAY ASK YOU FOR INFORMATION NEEDED TO
                                CONFIRM YOUR STATUS AS AN ACCREDITED INVESTOR, OR TO CONFIRM OTHER
                                INFORMATION ABOUT YOUR STATUS PRIOR TO ALLOWING YOU TO INVEST
                                THROUGH THE SITE OR ANYTIME THEREAFTER.
                            </p>

                            <p className="description">
                                <span className="bold">Additional Terms.</span><br/>
                                We may require you to agree to additional terms and/or policies from time-to-time in
                                connection
                                with your use of the Site ("Additional Terms"). Such Additional Terms may include terms
                                that
                                govern your rights in connection with any investments you make as Sponsors and with any
                                advice you receive from the Investment Adviser, including, without limitation, the terms
                                of the
                                Investment Advisory Agreement you must execute with the Investment Adviser prior to
                                accessing the Decentralized Funding Platform ("Investment Terms"), as those Investment
                                Terms
                                are not subject to this Agreement. Except as expressly stated otherwise in Additional
                                Terms, any
                                Additional Terms are hereby incorporated into and subject to this Agreement, and this
                                Agreement
                                will control in the event of any conflict or inconsistency with the Additional Terms to
                                the extent
                                of the conflict or inconsistency; provided, however, that any Investment Terms are not
                                incorporated into this Agreement, and if there is any conflict or inconsistency between
                                this
                                Agreement and any Investment Terms, then the Investment Terms will control to the extent
                                of the
                                conflict or inconsistency.
                            </p>
                            <br/>
                            <p className="description">
                                In connection with your use of the Services, we may send you service announcements,
                                administrative messages and other information. You may opt out of some of those
                                communications.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Changes to this Agreement.</span><br/>
                                You understand and agree that Rocket DAO may change this Agreement at any time without
                                prior notice. You may read a current, effective copy of this Agreement at any time by
                                selecting
                                the appropriate link on the Site. The revised Agreement will become effective at the
                                time of
                                posting, and your use of the Site after such time will constitute your acceptance of the
                                revised
                                Agreement. If any change to this Agreement is not acceptable to you, then your sole
                                remedy is to
                                stop using the Site. Notwithstanding the preceding sentences of this Section, no
                                revisions to this
                                Agreement will apply to any dispute between you and Rocket DAO that arose prior to the
                                effective date of those revisions.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Consideration.</span><br/>
                                Rocket DAO currently provides you with access to the Site for free. In return for
                                enjoying this
                                free access, you acknowledge and agree that we may generate revenues, increase goodwill
                                or
                                otherwise increase the value of Rocket DAO from your use of the Site, and you will have
                                no
                                right to share in any such revenues, goodwill or value whatsoever. However, we may
                                charge you
                                fees for certain uses of the Site. If we do, then we will notify you of those fees
                                before you have
                                an obligation to pay them.

                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Privacy Policy.</span><br/>
                                Use of the Site is also subject to Rocket DAO’s Privacy Policy, which is incorporated
                                into this
                                Agreement by reference.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Jurisdictional Issues.</span><br/>
                                Rocket DAO makes no representation that materials on the Site are appropriate, lawful or
                                available for use in any locations other than the Gibraltar. Those who choose to access
                                or use the
                                Site from locations outside the Gibraltar do so on their own initiative and are
                                responsible for
                                compliance with local laws, if and to the extent local laws are applicable.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Eligibility.</span><br/>
                                THE SITE IS NOT FOR PERSONS UNDER THE AGE OF 13 OR FOR ANY USERS
                                PREVIOUSLY SUSPENDED OR BLOCKED FROM THE SITE BY ROCKET DAO. IF YOU
                                ARE UNDER 13 YEARS OF AGE, THEN YOU MUST NOT USE OR ACCESS THE SITE AT
                                ANY TIME OR IN ANY MANNER. Furthermore, by using the Site, you affirm that you are at
                                least 18 years of age and otherwise have the legal capacity to contract.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Mobile Services.</span><br/>
                                Some of our Services are available on mobile devices. Do not use such Services in a way
                                that
                                distracts you and prevents you from obeying traffic or safety laws.
                            </p>
                            <br/>
                            <p className="description">
                                The Site will be accessible via a mobile phone, tablet or other wireless device
                                (collectively,
                                "Mobile Services"). Your mobile carrier’s normal messaging, data and other rates and
                                fees will
                                apply to your use of the Mobile Services. In addition, downloading, installing or using
                                certain
                                Mobile Services may be prohibited or restricted by your mobile carrier, and not all
                                Mobile
                                Services may work with all carriers or devices. Therefore, you are solely responsible
                                for
                                checking with your mobile carrier to determine if the Mobile Services are available for
                                your
                                mobile device(s), what restrictions, if any, may be applicable to your use of the Mobile
                                Services,
                                and how much they will cost you. Nevertheless, all use of the Site and the related
                                Mobile
                                Services must be in accordance with this Agreement.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                2. REGISTRATION. ACCOUNTS.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Log-In Credentials.</span><br/>
                                While you may always browse public-facing portions of the Site without registering with
                                us, in
                                order to access the password-protected portion of the Site (the "Decentralized Funding
                                Platform") and be able to use the advantages of the Rocket DAO platform in its entirety,
                                you
                                need to register an account with us (an "Account").
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Account Security.</span><br/>
                                To protect your Rocket DAO Account, keep your password confidential. Try not to reuse
                                your
                                Rocket DAO Account password on third-party applications.
                            </p>
                            <br/>
                            <p className="description">
                                You are responsible for the security of your Account, and are fully responsible for all
                                activities
                                that occur through the use of your credentials RocketDAO Account. You agree to notify us
                                immediately at <a href="mailto:support@rocketdao.io">support@rocketdao.io</a> if you suspect or know of any
                                unauthorized use of your
                                log-in credentials or any other breach of security with respect to your Account. We will
                                not be
                                liable for any loss or damage arising from unauthorized use of your credentials.
                                Separate log-in
                                credentials may be required to access External Sites (defined in Section 7 below).
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Accuracy of Information.</span><br/>
                                When creating an Account, you will provide true, accurate, current and complete
                                information as
                                we request. You will update the information about yourself promptly, and as necessary,
                                to keep it
                                current and accurate. We reserve the right to disallow, cancel, remove or reassign
                                certain
                                usernames and permalinks in appropriate circumstances, as determined by us in our sole
                                discretion, and may, with or without prior notice, suspend or terminate your Account if
                                activities
                                occur on your Account which, in our sole discretion, would or might constitute a
                                violation of this
                                Agreement, cause damage to or impair the Site, infringe or violate any third party
                                rights, damage
                                or bring into disrepute the reputation of Rocket DAO, or violate any applicable laws or
                                regulations. If messages sent to the e-mail address you provide are returned as
                                undeliverable,
                                then we may terminate your Account immediately without notice to you and without any
                                liability
                                to you or any third party.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                3. INTELLECTUAL PROPERTY RIGHTS.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">License.</span><br/>
                                Subject to your complete and ongoing compliance with this Agreement, Rocket DAO hereby
                                grants you a revocable, non-exclusive, non-transferable, non-sublicensable, royalty-free
                                and
                                worldwide right and license to access and use the Site solely in strict compliance with
                                the
                                provisions of this Agreement and as permitted by the functionalities of the Site
                                available to you.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Content.</span><br/>
                                The content that Rocket DAO provides to you on the Site, including, without limitation,
                                any text,
                                graphics, software, interactive features, information or other materials, is protected
                                by copyright
                                or other intellectual property rights and owned by Rocket DAO or its licensors
                                (collectively, the
                                "Rocket DAO Content"). Moreover, Rocket DAO or its licensors own all design rights,
                                database
                                and compilation rights and other intellectual property rights in and to the Site, in
                                each case
                                whether registered or unregistered, and any related goodwill.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Marks.</span><br/>
                                The Rocket DAO trademarks, service marks and logos (collectively, the "Rocket DAO
                                Trademarks") used and displayed on the Site are Rocket DAO’s registered and/or
                                unregistered
                                trademarks or service marks. Any other product and service names located on any part of
                                the Site
                                may be trademarks or service marks owned by third parties (collectively with the Rocket
                                DAO
                                Trademarks, the "Trademarks"). Except as otherwise permitted by law, you may not use the
                                Trademarks to disparage Rocket DAO or the applicable third party, Rocket DAO’s or a
                                third
                                party’s products or services, or in any manner (using commercially reasonable judgment)
                                that
                                may damage any goodwill in the Trademarks. You may not use any Trademarks as part of a
                                link
                                to or from any website without Rocket DAO’s prior express written consent. All goodwill
                                generated from the use of any Rocket DAO Trademark will inure solely to Rocket DAO’s
                                benefit.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Restrictions.</span><br/>
                                Rocket DAO hereby reserves all rights not expressly granted to you in this Section 3.
                                Accordingly, nothing in this Agreement or on the Site will be construed as granting to
                                you, by
                                implication, estoppel or otherwise, any additional license rights in and to the Site or
                                any Rocket
                                DAO Content or Trademarks located or displayed on or within the Site.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                4. CONTENT
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">General.</span><br/>
                                Certain features on the Site may allow you to submit or upload (collectively, "Submit")
                                content
                                to the Site, such as User profile content or other materials subject to intellectual
                                property or
                                similar laws ("User Content"). For all User Content that you Submit to the Site, you
                                hereby grant
                                us (and those we work with) a worldwide license to use, exploit, host, store, transmit,
                                reproduce,
                                modify, create derivative works of (such as those resulting from changes we make so that
                                your
                                User Content works better with our Site), publish, publicly perform and display and
                                distribute
                                such content; provided that we will not share with other Users any User Content that you
                                Submit
                                to the Site that is not viewable by other Users based on any privacy settings available
                                on the Site.
                                The rights you grant in this Section are for the purpose of operating, promoting, and
                                improving
                                our Site and business and this license continues even if you stop using our Site.

                            </p>
                            <br/>
                            <p className="description">
                                You Must Have Rights to the Content You Submit. You represent and warrant that:
                            </p>

                            <p className="description dot">
                                you own the User Content Submitted by you or otherwise have the right to grant the
                                license set forth in this Agreement;
                            </p>

                            <p className="description dot">
                                the Submission of your User Content and the use of the same as contemplated in this
                                Agreement does not and will not violate any right of any third party;
                            </p>

                            <p className="description dot">
                                the Submission of your User Content will not require us to pay any amounts or provide
                                any attribution to any third parties; and
                            </p>

                            <p className="description dot">
                                the Submission of your User Content does not result in a breach of contract between you
                                and a third party.
                            </p>

                            <p className="description">
                                <span className="bold">Disclaimer. Your Content in our Services</span><br/>
                                Our Services allow you to upload, submit, store, send or receive content You retain
                                ownership of
                                any intellectual property rights that you hold in that content. In short, what belongs
                                to you stays
                                yours.
                            </p>
                            <br/>
                            <p className="description">
                                When you upload, submit, store, send or receive content to or through our Services, you
                                give
                                Rocket DAO (and those we work with) a worldwide license to use, host, store, reproduce,
                                modify, create derivative works (such as those resulting from translations, adaptations
                                or other
                                changes that we make so that your content works better with our Services), communicate,
                                publish, publicly perform, publicly display and distribute such content. The rights that
                                you grant
                                in this license are for the limited purpose of operating, promoting and improving our
                                Services,
                                and to develop new ones. This license continues even if you stop using our Services.
                                Some
                                Services may offer you ways to access and remove content that has been provided to that
                                Service. Also, in some of our Services, there are terms or settings that narrow the
                                scope of our
                                use of the content submitted in those Services. Make sure that you have the necessary
                                rights to
                                grant us this license for any content that you submit to our Services.
                            </p>
                            <br/>
                            <p className="description">
                                If you have a RocketDAO Account, we may display your Profile name, Profile photo and
                                actions
                                you take on RocketDAO or on third-party applications connected to your RocketDAO Account
                                (such as you write and comments you post) in our Services, including displaying in ads
                                and other
                                commercial contexts. We will respect the choices you make to limit sharing or visibility
                                settings
                                in your RocketDAO Account. For example, you can choose your settings so that your name
                                and
                                photo do not appear in ads.
                            </p>
                            <br/>
                            <p className="description">
                                You can find more information about how RocketDAO uses and stores content in the Privacy
                                Policy or additional terms for particular Services. If you submit feedback or
                                suggestions about
                                our Services, we may use your feedback or suggestions without obligation to you.
                            </p>
                            <br/>
                            <p className="description">
                                We are under no obligation to edit or control User Content that you Submit, and will not
                                be in
                                any way responsible or liable for User Content. Rocket DAO may, however, at any time and
                                without prior notice, screen, remove, edit or block any User Content that in our sole
                                judgment
                                violates this Agreement or is otherwise objectionable, such as, without limitation, User
                                Content
                                that Rocket DAO determines is or could be interpreted to be abusive, bigoted,
                                defamatory,
                                harassing, harmful, infringing, obscene, offensive, pornographic, racist, threatening,
                                unlawful,
                                vulgar or otherwise inappropriate (collectively, "Objectionable Content"). Further, we
                                may, in
                                our sole discretion, take any action we deem necessary and/or appropriate against any
                                User who
                                Submits Objectionable Content, including, but not limited to, warning the User or
                                suspending or
                                terminating the User’s Account.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                5. NOTICE AND PROCEDURE FOR MAKING CLAIMS OF INTELLECTUAL
                                PROPERTY INFRINGMENTS.
                            </p>
                            <br/>
                            <p className="description">
                                Respect of Third Party Rights.
                                We comply with the provisions of the Digital Millennium Copyright Act applicable to
                                Internet
                                service providers (17 U.S.C. §512, as amended). If you have an intellectual property
                                rightsrelated complaint about material appearing on the Site, then you may contact our
                                Designated
                                Agent by e-mail: <a href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/>
                            <p className="description">
                                Any notice alleging that materials hosted by or distributed through the Site infringe
                                intellectual
                                property rights ("Notification of Claimed Infringement") must include the following
                                information:
                            </p>

                            <p className="description dot">
                                an electronic or physical signature of the person authorized to act on behalf of the
                                owner
                                of the copyright or other right being infringed;
                            </p>

                            <p className="description dot">
                                a description of the copyrighted work or other intellectual property that you claim has
                                been infringed;
                            </p>

                            <p className="description dot">
                                a description of the material that you claim is infringing and where it is located on
                                the
                                Site;
                            </p>

                            <p className="description dot">
                                your address, telephone number and email address;
                            </p>

                            <p className="description dot">
                                a statement by you that you have a good faith belief that the use of the materials on
                                the
                                Site of which you are complaining is not authorized by the copyright owner, its agent or
                                the law; and
                            </p>

                            <p className="description dot">
                                a statement by you that the above information in your notice is accurate and that, under
                                penalty of perjury, you are the copyright or intellectual property owner or authorized
                                to
                                act on the copyright or intellectual property owner’s behalf.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Repeat Infringer Policy.</span><br/>
                                Rocket DAO’s intellectual property policy is to:
                            </p>

                            <p className="description dot">
                                remove or disable access to material that Rocket DAO believes in good faith, upon notice
                                from an intellectual property owner or his or her agent, is infringing the intellectual
                                property of a third party by being made available through the Site;
                            </p>

                            <p className="description dot">
                                remove any User Content Submitted by Users who are determined to be "repeat
                                infringers"; and
                            </p>

                            <p className="description dot">
                                promptly terminate the Accounts of repeat infringers.
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO currently considers a "repeat infringer" to be any User that has Submitted
                                User
                                Content and for whom Rocket DAO has received more than two takedown notices compliant
                                with respect to such User Content. Rocket DAO has discretion, however, to terminate the
                                Account of any User after receipt of a single Notification of Claimed Infringement (as
                                defined in
                                Section 5) or upon Rocket DAO’s own determination.

                            </p>
                            <br/><br/>
                            <p className="description bold">
                                6. RESTRICTION ON USE OF THE SITE.
                            </p>
                            <br/>
                            <p className="description">
                                Without limiting any other terms of this Agreement, you agree not to (and not to attempt
                                to):
                            </p>

                            <p className="description dot">
                                decipher, decompile, disassemble or reverse engineer any of the software or source code
                                comprising or making up the Site;
                            </p>

                            <p className="description dot">
                                use any device, software or routine to interfere or attempt to interfere with the proper
                                working of the Site, or any activity conducted thereon;
                            </p>

                            <p className="description dot">
                                delete or alter any material Rocket DAO makes available on the Site;
                            </p>

                            <p className="description dot">
                                frame or link to any of the materials or information available on the Site;
                            </p>

                            <p className="description dot">
                                use or exploit any Trademarks or Rocket DAO Content in any manner that is not
                                expressly authorized by this Agreement;
                            </p>

                            <p className="description dot">
                                access, tamper with or use non-public areas of the Site, Rocket DAO’s (and its hosting
                                company’s) computer systems and infrastructure or the technical delivery systems of
                                Rocket DAO’s providers;
                            </p>

                            <p className="description dot">
                                provide any false information to Rocket DAO;
                            </p>

                            <p className="description dot">
                                create a false identity or impersonate another person or entity in any way;
                            </p>

                            <p className="description dot">
                                restrict, discourage or inhibit any person from using the Site;
                            </p>

                            <p className="description dot">
                                use the Site, without Rocket DAO’s prior express written consent, for any unauthorized
                                purpose;
                            </p>

                            <p className="description dot">
                                gain unauthorized access to the Site, other Users’ Accounts or to other computers or
                                websites connected or linked to the Site;
                            </p>

                            <p className="description dot">
                                transmit to the Site any virus, worm, spyware or any other computer code, file or
                                program that may or is intended to disable, overburden, impair, damage or hijack the
                                operation of any hardware, software or telecommunications equipment, or any other
                                aspect of the Site or communications equipment and computers connected thereto;
                            </p>

                            <p className="description dot">
                                violate any federal, state or local laws or regulations or the terms of this Agreement;
                                or
                            </p>

                            <p className="description dot">
                                assist or permit any person in engaging in any of the activities described above.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                7. EXTERNAL SITES.
                            </p>
                            <br/>
                            <p className="description">
                                The Site may contain links to other websites or other online properties that are not
                                owned or
                                controlled by Rocket DAO (collectively, "External Sites"). Rocket DAO does not make any
                                representations regarding the content or accuracy of any materials on External Sites.
                                You should
                                contact the site administrator or Webmaster for External Sites if you have any concerns
                                regarding
                                content located on those External Sites. You should take precautions when downloading
                                files
                                from all websites to protect your devices from viruses and other destructive programs.
                                If you
                                decide to access any External Sites, then you do so at your own risk. Further, you will
                                be solely
                                responsible for compliance with any terms of service or similar terms imposed by any
                                External
                                Site in connection with your use of External Sites.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                8. FEEDBACK.
                            </p>
                            <br/>
                            <p className="description">
                                If you choose to provide us with input or suggestions regarding problems with or
                                proposed
                                modifications or improvements to the Site ("Feedback"), then you hereby grant to us a
                                non-
                                exclusive, perpetual, irrevocable, transferable, sublicensable (through multiple tiers),
                                worldwide
                                and royalty-free right to use and exploit the Feedback in any manner and for any purpose
                                without
                                any restriction, credit, attribution or fees due to you.
                            </p>
                            <br/><br/>
                            <p className="description">
                                9. DISPUTE RESOLUTION.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">General.</span><br/>
                                In the interest of resolving disputes between you and Rocket DAO in the most expedient
                                and
                                cost effective manner, you and Rocket DAO agree that any dispute arising out of or in
                                any way
                                related to this Agreement or your use of the Site will be resolved by binding
                                arbitration.
                                Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral
                                arbitrator instead of a
                                judge or jury, may allow for more limited discovery than in court, and can be subject to
                                very
                                limited review by courts. Arbitrators can award the same damages and relief that a court
                                can
                                award. This agreement to arbitrate disputes includes all claims arising out of or in any
                                way
                                related to this Agreement or your use of the Site, whether based in contract, tort,
                                statute, fraud,
                                misrepresentation or any other legal theory, and regardless of whether a claim arises
                                during or
                                after the termination of this Agreement. YOU UNDERSTAND AND AGREE THAT, BY
                                ENTERING INTO THIS AGREEMENT, YOU AND ROCKET DAO ARE EACH WAIVING
                                THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION AND
                                THAT THIS AGREEMENT SHALL BE SUBJECT TO AND GOVERNED BY THE LAW OF
                                GIBRALTAR.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Exceptions.</span><br/>
                                Notwithstanding Section 9 above, nothing in this Agreement will be deemed to waive,
                                preclude
                                or otherwise limit the right of either party to:
                            </p>

                            <p className="description dot">
                                bring an individual action in small claims court;
                            </p>

                            <p className="description dot">
                                pursue an enforcement action through the applicable federal, state or local agency if
                                that
                                action is available;
                            </p>

                            <p className="description dot">
                                seek injunctive relief in aid of arbitration from a court of competent jurisdiction; or
                            </p>

                            <p className="description dot">
                                to file suit in a court of law to address an intellectual property infringement claim.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Notice and Process.</span><br/>
                                A party who intends to seek arbitration must first send a written notice of the dispute
                                to the other
                                party ("Notice") by email at <a href="mailto:support@rocketdao.io">support@rocketdao.io</a> Attn: DISPUTE NOTICE.
                                The Notice must:
                            </p>

                            <p className="description dot">
                                describe the nature and basis of the claim or dispute; and
                            </p>

                            <p className="description dot">
                                set forth the specific relief sought ("Demand").
                            </p>
                            <br/>
                            <p className="description">
                                The parties will make good faith efforts to resolve the claim directly, but if the
                                parties do not
                                reach an agreement to do so within 30 days after the Notice is received, then you or
                                Rocket DAO
                                may commence an arbitration proceeding. During the arbitration, the amount of any
                                settlement
                                offer made by you or Rocket DAO must not be disclosed to the arbitrator until after the
                                arbitrator
                                makes a final decision and award, if any. If the dispute is finally resolved through
                                arbitration in
                                your favor with a monetary award that exceeds the last written settlement amount offered
                                by
                                Rocket DAO prior to selection of an arbitrator, then Rocket DAO will pay you the highest
                                of the
                                following:
                            </p>

                            <p className="description dot">
                                the amount awarded by the arbitrator, if any;
                            </p>

                            <p className="description dot">
                                the last written settlement amount offered by Rocket DAO in settlement of the dispute
                                prior to the arbitrator’s award; or
                            </p>

                            <p className="description dot">
                                $5,000.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Fees.</span><br/>
                                If you commence arbitration in accordance with this Agreement, then Rocket DAO will
                                reimburse you for your payment of the filing fee, unless your claim is for more than
                                $5,000 or as
                                set forth below, in which case the payment of any fees will be decided. Any arbitration
                                hearing
                                will take place at a location to be agreed upon in New York, New York, but if the claim
                                is for
                                $15,000 or less, then you may choose whether the arbitration will be conducted:
                            </p>

                            <p className="description dot">
                                solely on the basis of documents submitted to the arbitrator;
                            </p>

                            <p className="description dot">
                                through a non-appearance based telephone hearing; or
                            </p>

                            <p className="description dot">
                                by an in-person hearing as established in the county (or parish) of your billing
                                address.
                            </p>
                            <br/>
                            <p className="description">
                                If the arbitrator finds that either the substance of your claim or the relief sought in
                                the Demand is
                                frivolous or brought for an improper purpose, then the payment of all fees will be
                                governed by
                                the Law of Gibraltar. In that case, you agree to reimburse Rocket DAO for all monies
                                previously
                                disbursed by it that are otherwise your obligation to pay. Regardless of the manner in
                                which the
                                arbitration is conducted, the arbitrator must issue a reasoned written decision
                                sufficient to explain
                                the essential findings and conclusions on which the decision and award, if any, are
                                based. Each
                                party agrees that such written decision, and information exchanged during arbitration,
                                will be
                                kept confidential except to the extent necessary to enforce or permit limited judicial
                                review of
                                the award. The arbitrator may make rulings and resolve disputes as to the payment and
                                reimbursement of fees or expenses at any time during the proceeding and upon request
                                from
                                either party made within 14 days of the arbitrator’s ruling on the merits.
                                Notwithstanding
                                anything in this Agreement to the contrary, and for the avoidance of doubt, the
                                arbitrator can
                                award injunctive relief as a remedy in any arbitration required under these dispute
                                resolution
                                provisions.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">No Class Actions.</span><br/>
                                YOU AND ROCKET DAO AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
                                OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                                PROCEEDING. Further, unless both you and Rocket DAO agree otherwise, the arbitrator may
                                not consolidate more than one person’s claims, and may not otherwise preside over any
                                form of a
                                representative or class proceeding.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Modifications to this Arbitration Provision.</span><br/>
                                Except as otherwise provided in this Agreement, if Rocket DAO makes any future change to
                                this
                                arbitration provision, other than a change to Rocket DAO’s address for Notice, then you
                                may
                                reject the change by sending us written notice within 30 days of the change to Rocket
                                DAO’s
                                address for Notice, in which case this arbitration provision, as in effect immediately
                                prior to the
                                changes you rejected, will continue to govern any disputes between you and Rocket DAO.
                            </p>
                            <br/><br/>
                            <p className="description">
                                10. LIMITATION OF LIABILITY AND DISCLAIMER OF WARRANTIES.
                            </p>
                            <br/>
                            <p className="description">
                                THE TERMS OF THIS SECTION 10 APPLY TO THE FULLEST EXTENT PERMITTED BY
                                LAW:
                            </p>

                            <p className="description dot">
                                NONE OF THE ROCKET DAO PARTIES MAKES ANY WARRANTIES OR
                                REPRESENTATIONS ABOUT THE SITE OR ANY CONTENT THEREON.
                                ACCORDINGLY, THE SITE AND ALL CONTENT THEREON ARE PROVIDED ON
                                AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF
                                ANY KIND, AND THE ROCKET DAO PARTIES HEREBY DISCLAIM ALL
                                WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF
                                TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTY RIGHTS
                                AND FITNESS FOR A PARTICULAR PURPOSE.
                            </p>

                            <p className="description dot">
                                WITHOUT LIMITING THE SECTION ABOVE, THE ROCKET DAO PARTIES DO
                                NOT WARRANT THAT THE SITE AND ANY CONTENT THEREON ARE FREE OF
                                ERRORS, COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR
                                DESTRUCTIVE FEATURES. IF YOUR USE OF THE SITE OR ANY CONTENT
                                THEREON RESULTS IN THE NEED FOR SERVICING OR REPLACING
                                EQUIPMENT OR DATA, THEN NO ROCKET DAO PARTY WILL BE
                                RESPONSIBLE FOR THOSE COSTS.
                            </p>

                            <p className="description dot">
                                IN NO EVENT WILL ANY ROCKET DAO PARTY BE LIABLE FOR ANY SPECIAL,
                                INDIRECT, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST
                                PROFITS OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
                                INTERRUPTION RESULTING FROM, OR IN CONNECTION WITH, THE SITE
                                AND ANY CONTENT THEREON, WHETHER BASED ON WARRANTY,
                                CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL
                                THEORY, EVEN IF THE ROCKET DAO PARTY HAS BEEN ADVISED OF THE
                                POSSIBILITY OF SUCH DAMAGES. ROCKET DAO’S LIABILITY, AND THE
                                LIABILITY OF ANY OF THE OTHER ROCKET DAO PARTIES, TO YOU OR ANY
                                THIRD PARTIES IN ANY CIRCUMSTANCE ARISING FROM THIS AGREEMENT
                                IS LIMITED TO U.S. $100.
                            </p>

                            <p className="description dot">
                                THERE MAY BE INADVERTENT TECHNICAL OR FACTUAL INACCURACIES
                                AND TYPOGRAPHICAL ERRORS IN INFORMATION OR MATERIALS ON THE
                                SITE, AND NONE OF THE ROCKET DAO PARTIES MAKES ANY WARRANTIES
                                REGARDING THE ACCURACY, COMPLETENESS OR TIMELINESS OF SUCH
                                INFORMATION OR MATERIALS. NONE OF THE ROCKET DAO PARTIES
                                PROVIDES ANY GUARANTEES AGAINST THE POSSIBILITY OF DELETION,
                                MIS-DELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED
                                SETTINGS, OR OTHER DATA. THE ROCKET DAO PARTIES HEREBY
                                EXPRESSLY DISCLAIM ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR
                                THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION OR
                                MATERIALS CONTAINED ON THE SITE.
                            </p>

                            <p className="description dot">
                                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
                                BY YOU FROM ANY OF THE ROCKET DAO PARTIES OR OTHERWISE
                                THROUGH THE SITE, WILL CREATE ANY WARRANTY NOT EXPRESSLY
                                STATED IN THESE TERMS.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                11. THIRD PARTY DISPUTES.
                            </p>
                            <br/>
                            <p className="description">
                                ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY, INCLUDING OTHER USERS, IN
                                CONNECTION WITH YOUR USE OF THE SITE IS DIRECTLY BETWEEN YOU AND
                                SUCH THIRD PARTY. ACCORDINGLY, TO THE FULLEST EXTENT PERMITTED BY
                                LAW, YOU HEREBY IRREVOCABLY RELEASE THE ROCKET DAO PARTIES FROM
                                ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND
                                CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
                                ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                12. INDEMNIFICATION.
                            </p>
                            <br/>
                            <p className="description">
                                To the fullest extent permitted by law, you agree to defend, indemnify and hold harmless
                                the
                                Rocket DAO Parties from and against any claims, actions or demands, including, without
                                limitation, reasonable legal and accounting fees, arising or resulting from:
                            </p>
                            <br/>
                            <p className="description dot">
                                your breach of this Agreement; or
                            </p>

                            <p className="description dot">
                                your access to, use or misuse of the Rocket DAO Content, Trademarks or any part of the
                                Site; or
                            </p>

                            <p className="description dot">
                                any false, inaccurate or misleading information you provide to Rocket DAO.
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO will provide notice to you of any such claim, suit or proceeding. Rocket DAO
                                reserves the right, in its sole discretion, to assume the exclusive defense and control
                                of any
                                matter which is subject to indemnification under this Section at your sole expense if
                                Rocket
                                DAO believes that you are unwilling or incapable of defending Rocket DAO’s interests. In
                                such
                                case, you agree to cooperate with any reasonable requests assisting Rocket DAO’s defense
                                of
                                such matter at your sole expense. Notwithstanding the foregoing, nothing contained in
                                this
                                Agreement shall constitute a waiver by any investor of any of his, her or its legal
                                rights under
                                applicable Gibraltar laws or any other laws whose applicability is not permitted to be
                                contractually waived.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                13. AGREEMENT TERM AND TERMINATION.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Term.</span><br/>
                                As between you and Rocket DAO, the term of this Agreement commences on your first use of
                                the Site and continues until the termination of this Agreement by either you or Rocket
                                DAO.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Suspension, Termination and Cancellation.</span><br/>
                                You may terminate this Agreement by sending written notification to us at <a
                                href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                                and terminating your use of the Site. We reserve the right, in our sole discretion, to
                                restrict,
                                suspend, or terminate your access to all or any part of the Site or to terminate this
                                Agreement at
                                any time without prior notice or liability if you breach any provision of this Agreement
                                or violate
                                the rights of any third party on or through the Site. We reserve the right to change,
                                suspend, or
                                discontinue all or any part of the Site at any time without prior notice or liability.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Modifying and Terminating our Services</span><br/>
                                We are constantly changing and improving our Services. We may add or remove
                                functionalities
                                or features and we may suspend or stop a Service altogether. You can stop using our
                                Services at
                                any time, although we would be sorry to see you go. Rocket DAO may also stop providing
                                Services to you or add or create new limits to our Services at any time.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                14. CONCENT TO ELECTRONIC COMMUNICATIONS.
                            </p>
                            <br/>
                            <p className="description">
                                By using the Site, you consent to receiving certain electronic communications from us as
                                further
                                described in the <a href="https://test.rocketico.io/Rocket%20DAO_Privacy%20Policy.pdf">Privacy Policy</a> and, if you use the Site as an Investor,
                                the onboarding
                                questionnaire that you must submit when registering for the Decentralized Funding
                                Platform.
                                You agree that any notices, agreements, disclosures or other communications that we send
                                to you
                                electronically will satisfy any legal communication requirements, including that such
                                communications be in writing.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                15. MISCELLANEOUS.
                            </p>
                            <br/>
                            <p className="description">
                                This Agreement is governed by the internal substantive laws of Gibraltar without respect
                                to its
                                conflict of laws provisions. You agree that no joint venture, partnership, employment or
                                agency
                                relationship exists between you and Rocket DAO as a result of this Agreement or use of
                                the Site.
                                If any provision of this Agreement is found to be invalid by any court or arbitrator
                                having
                                competent jurisdiction, then the invalidity of such provision will not affect the
                                validity of the
                                remaining provisions of this Agreement, which will remain in full force and effect.
                                Failure of
                                Rocket DAO to act on or enforce any provision of this Agreement will not be construed as
                                a
                                waiver of that provision or any other provision in this Agreement. No waiver will be
                                effective
                                against Rocket DAO unless made in writing, and no such waiver will be construed as a
                                waiver in
                                any other or subsequent instance. This Agreement constitutes the entire agreement
                                between you
                                and Rocket DAO with respect to the subject matter hereof, and supersedes all previous or
                                contemporaneous agreements, whether written or oral, between the parties with respect to
                                the
                                subject matter hereof. The Section headings are provided merely for convenience and will
                                not be
                                given any legal import. This Agreement will inure to the benefit of our successors and
                                assigns.
                                You may not assign this Agreement or any of the rights or licenses granted hereunder
                                without the
                                prior express written consent of Rocket DAO. "Assignment" as used in the prior sentence
                                includes any changes of control or sale of stock or assets of any Organization. Rocket
                                DAO may
                                assign this Agreement, including all its rights hereunder, without restriction. This
                                Agreement
                                may only be amended in a writing signed by you and an authorized representative of
                                Rocket
                                DAO, except as provided in Section 1. You acknowledge and agree that you have had the
                                opportunity to consult legal counsel in connection with this Agreement even if you chose
                                not to
                                do so, and this Agreement will not be construed against you or Rocket DAO as drafter.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                16. CONTRACTING PARTY.
                            </p>
                            <br/>
                            <p className="description">
                                If you use the Site then you are contracting with Rocket DAO platform on the name of
                                SOFTECH LTD.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                17. CONTACT US.
                            </p>
                            <br/>
                            <p className="description">
                                If you would like to contact us for any reason e-mail us at <a
                                href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/>
                            <br/>
                            <br/>


                        </Scrollbars>

                    </div>


                </React.Fragment>
                }
                {this.props.type === 2 &&
                <React.Fragment>
                    <div className="block-top-doc-reg-expert">
                        <p className="title-top-doc text-h1">
                            Privacy policy
                        </p>
                    </div>
                    <div className="block-body-doc-reg-expert">

                        <Scrollbars onScroll={this.onScroll}>

                            <p className="description bold">
                                GENERAL
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO, together with its affiliates ("Rocket DAO", "we", "us") values the privacy
                                of
                                individuals who visit our Rocket DAO Platform (the "Decentralized Funding Platform"),
                                available at <a href="https://rocketdao.io/">https://Rocket DAO.io</a> and any of our other websites,
                                applications, or services that
                                link to this Privacy Policy (collectively, the "Platform"). The Platform allows backers
                                and
                                investors that have created an account with us ("Investors") to make investments in
                                token sales
                                with blockchain projects listed on the Platform ("Startups").
                            </p>
                            <br/>
                            <p className="description">
                                The present Privacy Policy ("Policy") is designed to explain how we collect, use, and
                                share
                                information from users of the including Investors and Token Developers. This Policy is
                                incorporated by reference into our <a href="https://test.rocketico.io/Rocket%20DAO_Terms%20of%20Service.pdf">Terms of Service</a>. By agreeing to this
                                Policy in your account
                                setup or by using the Service, you agree to the terms and conditions of this Policy.
                            </p>
                            <br/>
                            <p className="description">
                                In accepting these Terms, You acknowledge that You have read them, understand them, and
                                had
                                an opportunity to seek an independent financial and/or legal advice prior to agreeing to
                                them. If
                                You have any questions regarding these Terms, please contact us at <a
                                href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/>
                            <p className="description">
                                Please read our Privacy Policy carefully to get a clear understanding of how we collect,
                                use,
                                protect or store Your personal data in our website (<a href="https://rocketdao.io/">https://Rocket DAO.io</a>).
                            </p>
                            <br/>
                            <p className="description">
                                As the owner of this website, Rocket DAO becomes the owner and responsible for the files
                                with
                                the personal data voluntarily supplied by You. We have adopted all the security measures
                                legally
                                required for the protection of Your data and Your privacy in compliance with the laws
                                applicable
                                on the protection of privacy in relation to the processing of personal data.
                            </p>
                            <br/>
                            <p className="description">
                                Before participating in Rocket DAO You might have to provide certain personal
                                information
                                about Yourself (this may include, but is not limited to, Your name, contact details,
                                date of birth,
                                residency, or any other information, that may be required to meet the legal requirements
                                according to the applicable laws or regulations as of the moment of requesting the
                                information)
                                to enable us to verify Your identity.
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO may determine, in its sole discretion, the list of information, that shall be
                                requested
                                from You (this may include, but is not limited to, Your name, contact details, date of
                                birth,
                                residency, or any other information, that may be required to meet the legal requirements
                                according to the applicable laws or regulations as of the moment of requesting the
                                information)
                                in order to comply with the current or future applicable laws or regulations.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                COLLECTION AND PROCESSING OF PERSONAL DATA
                            </p>
                            <br/>
                            <p className="description">
                                Following are some examples of data that this website may ask You to provide and their
                                purposes:
                            </p>
                            <br/>
                            <p className="description dot">
                                The user name, e-mail addresses and phone numbers will be requested for subsequent
                                user administration;
                            </p>

                            <p className="description dot">
                                Company and personal details, tariffs, time-sheets, etc. These data may be collected
                                during registration on the website, when processing invoices, by means of transactions
                                or
                                otherwise and will be used to ensure good quality of the services provided;
                            </p>

                            <p className="description dot">
                                Additional official identification data (such as national registration number, passport
                                copy) as well as all other information required by law will be requested for subsequent
                                user administration and/or specifically to the services and transactions via the
                                website.
                            </p>
                            <br/>
                            <p className="description">
                                In addition, the website collescts and processes personal data for the following
                                purposes: manage
                                contractual and pre-contractual relationships, prevent abuse and fraud, establish
                                statistics and
                                tests, train staff, monitor service quality, commercial prospection or direct marketing
                                in relation
                                to products or financial services or other products or services promoted by the website.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Registration as Investor:</span><br/>
                                If you register on the Platform as an Investor, you are required to provide us with your
                                name and
                                email address and to designate a password. In order to participate as an Investor in any
                                Rocket
                                DAO Funds, we also may require certain information to confirm your status as an
                                accredited
                                investor if it obligatory by the applicable Legal Law. For example, Investors hoping to
                                participate in Rocket DAO Funds in an individual capacity, and not on behalf of a
                                corporation or
                                trust, must provide their full name, social security number, phone number, address, date
                                of birth,
                                and proof of required assets (such as an asset appraisal) or income (such as tax filings
                                or pay
                                stubs). More information regarding investor accreditation is available <a
                                href="https://coinlist.co/help">here</a>. We also require
                                Investors to complete a short questionnaire regarding their interest in specific types
                                of Startup
                                Projects.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Registration as Startup:</span><br/>
                                Startups will provide us with information about their project and token offering during
                                the course
                                of our diligence process, expert valuation and audit certain of which will be provided
                                to potential
                                Investors to assist in making an investment decision.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Financial Information:</span><br/>
                                Investors may invest in token sales using traditional currency and/or cryptocurrency. To
                                facilitate
                                these transactions we will collect necessary financial information, such as your bank's
                                routing
                                number and your account number.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Communications:</span><br/>
                                If you contact us directly, we may receive additional information about you. For
                                example, when
                                you email our Customer Support Team, we will receive your email address, the contents of
                                a
                                message or attachments that you may send to us, and other information you choose to
                                provide.
                            </p>
                            <br/>
                            <p className="description">
                                <span className="bold">Personal Data Storage</span><br/>
                                Personal data is stored in a secure environment that is not accessible to the public in
                                accordance
                                with the applicable laws.
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO will process your Personal Data in accordance with the Data Protection Act
                                2004
                                and EU Legislation/International Agreements Directive 95/46/EC, and You agree that we,
                                as the
                                data controller, may directly or through our service providers or agents process your
                                Personal
                                Data for any one or more of the following purposes:
                            </p>

                            <p className="description dot">
                                the purchase the Tokens pursuant to these Terms;
                            </p>

                            <p className="description dot">
                                providing You with information about us and our range of services;
                            </p>

                            <p className="description dot">
                                compliance with relevant "Know Your Client" and "Anti-Money Laundering"
                            </p>

                            <p className="description dot">
                                requirements under applicable law;
                            </p>

                            <p className="description dot">
                                management of enquiries and complaints;
                            </p>

                            <p className="description dot">
                                processing of transactions related to the Token Sale;
                            </p>

                            <p className="description dot">
                                opening, maintaining or operating a bank account in the Company’s name;
                            </p>

                            <p className="description dot">
                                resolving any disputes with you;
                            </p>

                            <p className="description dot">
                                producing summary information for statistical, regulatory and audit purposes; or
                            </p>

                            <p className="description dot">
                                any other reasonable purposes in accordance with applicable law.
                            </p>
                            <br/>
                            <p className="description">
                                The data that Rocket DAO collect from the User will be transferred to, and stored at, in
                                the EU
                                and may be transferred and/or stored to other places outside the European Economic Area
                                (EEA). It may also be processed by staff, operating outside the EEA, who works for
                                Rocket
                                DAO or for one of its service providers.

                            </p>
                            <br/>
                            <p className="description">
                                The website cannot guarantee the security of the data during transmission via the
                                Internet and
                                You must be aware of the risks related to the specificities of the Internet networks, in
                                particular
                                that information relating to personal data can be captured and/or transferred without
                                our
                                knowledge.
                            </p>
                            <br/>
                            <p className="description">
                                All the data collected is stored for a period no longer than necessary for providing or
                                using the
                                requested service. Beyond that period, the data will be deleted, made anonymous or kept
                                for
                                statistical purposes. When assessing how long personal data shall be stored, retention
                                requirements might be stipulated by other applicable laws (e.g. anti-money laundering
                                law).
                                Stored personal data can serve as legal evidence in litigation.
                            </p>
                            <br/>
                            <p className="description">
                                <span
                                    className="bold">Information We Collect Automatically From Your Use of the Service</span>
                                When you use our Service, we may collect information from you through automated means,
                                such
                                as cookies, web beacons, and web server logs. By using the Service, you consent to the
                                placement of cookies, beacons, and similar technologies in your browser and on emails in
                                accordance with this Policy. The information collected in this manner includes IP
                                address,
                                browser characteristics, device IDs and characteristics, operating system version,
                                language
                                preferences, referring URLs, and information about the usage of our Service.
                            </p>
                            <br/>
                            <p className="description">
                                We may use this information, for example, to ensure that the Service functions properly,
                                to
                                determine how many users have visited certain pages or opened messages, or to prevent
                                fraud.
                                We also work with analytics providers which use cookies and similar technologies to
                                collect and
                                analyze information about use of the Service and report on activities and trends. If you
                                do not
                                want information collected through the use of cookies, there is a procedure in most
                                browsers that
                                allows you to automatically decline cookies or be given the choice of declining or
                                accepting the
                                transfer to your computer of a particular cookie (or cookies) from a particular site.
                                You may also
                                wish to refer to <a href="http://www.allaboutcookies.org/manage-cookies/index.html">http://www.allaboutcookies.org/manage-cookies/index.html</a>.
                                If, however, you do
                                not accept cookies, you may experience some inconvenience in your use of the Service.
                            </p>
                            <br/>
                            <p className="description bold">
                                How We Use Your Information
                            </p>
                            <br/>
                            <p className="description">
                                We may use the information we collect:
                            </p>

                            <p className="description dot">
                                To verify the identity and accreditation status of investors, which may include
                                conducting
                                a soft pull on your credit history, and to facilitate investments in listed tokens.
                            </p>

                            <p className="description dot">
                                To operate, maintain, enhance and provide features of the Service, to provide services
                                and
                                information that you request, to respond to comments and questions, and otherwise to
                                provide support to users.
                            </p>

                            <p className="description dot">
                                To understand and analyze the usage trends and preferences of our users, to improve the
                                Service, and to develop new products, services, features, and functionality.
                            </p>

                            <p className="description dot">
                                To contact you for administrative or informational purposes, including by providing
                                customer service or sending communications, including changes to our terms, conditions,
                                and policies.
                            </p>

                            <p className="description dot">
                                For marketing purposes, such as developing and providing promotional and advertising
                                materials that may be useful, relevant, valuable or otherwise of interest.
                            </p>

                            <p className="description dot">
                                To personalize your experience on the Service by presenting tailored content.
                            </p>

                            <p className="description dot">
                                We may aggregate data collected through the Service and may use and disclose it for any
                                purpose.
                            </p>

                            <p className="description dot">
                                For our business purposes, such as audits, security, compliance with applicable laws and
                                regulations, fraud monitoring and prevention.
                            </p>

                            <p className="description dot">
                                To enforce our Terms of Service or other legal rights.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                How We Share Your Information
                            </p>
                            <br/>
                            <p className="description">
                                We may disclose information that we collect through the Service to third parties in the following
                                circumstances:
                            </p>

                            <p className="description dot">
                                To credit bureaus and other third parties who provide Know Your Customer and AntiMoney Laundering services, including verifying users as accredited investors.
                            </p>

                            <p className="description dot">
                                Information collected from and about Investors may be shared with Token Developers to
                                facilitate Investor directed investments.
                            </p>

                            <p className="description dot">
                                To our third party service providers who provide services such as website hosting, data
                                analysis, customer service, email delivery, auditing, and other services.
                            </p>

                            <p className="description dot">
                                To a potential or actual acquirer, successor, or assignee as part of any reorganization,
                                merger, sale, joint venture, assignment, transfer or other disposition of all or any portion
                                of our business, assets or stock (including in bankruptcy or similar proceedings).
                            </p>

                            <p className="description dot">
                                If required to do so by law or in the good faith belief that such action is appropriate: (a)
                                under applicable law, including laws outside your country of residence; (b) to comply
                                with legal process; (c) to respond to requests from public and government authorities,
                                including public and government authorities outside your country of residence; (d) to
                                enforce our terms and conditions; (e) to protect our operations or those of any of our
                                affiliates; (f) to protect our rights, privacy, safety or property, and/or that of our affiliates,
                                you or others; and (g) to allow us to pursue available remedies or limit the damages that
                                we may sustain.
                            </p>
                            <br/>
                            <p className="description">
                                In addition, we may use third party analytics vendors to evaluate and provide us with
                                information about your use of the Service. We do not share your information with these third
                                parties, but theses analytics service providers may set and access their own cookies, pixel tags
                                and similar technologies on the Service and they may otherwise collect or have access to
                                information about you which they may collect over time and across different websites. For
                                example, we use Google Analytics to collect and process certain analytics data. Google provides
                                some additional privacy options described at <a href="https://www.google.com/policies/privacy/partners">https://www.google.com/policies/privacy/partners</a>.
                                We may use and disclose aggregate information that does not identify or otherwise relate to an
                                individual for any purpose, unless we are prohibited from doing so under applicable law.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                THIRD PARTIES
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO may outsource part of its IT infrastructure and the processing of Your data to third
                                parties if necessary. For example, the transfer of providing from You data to outsourcer company
                                to verify its authenticity (checking the passports validity, the absence of changes to graphic files,
                                etc.), such as TELEGRAM.PASSPORT. By submitting any of Your personal data, You agree to
                                this transfer or processing. Rocket DAO from its side will take all steps reasonably necessary to
                                ensure that Your data is treated securely and in accordance with these Terms.
                            </p>
                            <br/>
                            <p className="description">
                                Our services may, from time to time, contain links to and from the websites of our partner
                                networks, advertisers, and affiliates (including, but not limited to). If You follow a link to any of
                                these websites, please note that these websites and any services that may be accessible through
                                them have their own privacy policies and that We do not accept any responsibility or liability for
                                these policies or for any personal information that may be collected through these websites or
                                services, such as contact and location data. Such partners may be located in other countries
                                where the laws on processing personal information may be less stringent than in your country.
                                Please check their policies before You submit any personal data to their websites or use their
                                services.
                            </p>
                            <br/>
                            <p className="description">
                                As we may display third-party content on the Platform. Third-party content may use cookies,
                                web beacons, or other mechanisms for obtaining data in connection with your viewing of and/or
                                interacting with the third-party content on the Platform. This Policy does not address, and we are
                                not responsible for, the privacy, information or other practices of any third parties, including any
                                third party operating any site or service to which the Platform link. The inclusion of a link on the
                                Service does not imply endorsement of the linked site or service by us or by our affiliates.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                SECURITY MEASURES
                            </p>
                            <br/>
                            <p className="description">
                                We use certain physical, organizational, and technical safeguards that are designed to maintain
                                the integrity and security of information that we collect. Please be aware that no security
                                measures are perfect or impenetrable and thus we cannot and do not guarantee the security of
                                your data. It is important that you maintain the security and control of your account credentials,
                                and not share your password with anyone. In addition, we offer our users two-factor
                                authentication which is designed to protect their account.
                            </p>
                            <br/>
                            <p className="description bold">
                                Confidentiality of Account and Password
                            </p>
                            <br/>
                            <p className="description">
                                If You set, or are provided with, a user identification code, password or any other piece of
                                information as part of our security procedures, You must treat such information as confidential.
                                You must not disclose it to any third party.
                            </p>
                            <br/>
                            <p className="description">
                                You are solely responsible for maintaining the confidentiality of Your passwords and/or any
                                information in connection with Your user profile. You must log off and close the browser
                                window at the end of Your session, because this will prevent others from accessing Your personal
                                data without Your consent.
                            </p>
                            <br/>
                            <p className="description">
                                If You know or suspect that anyone other than You knows Your user identification code or
                                password, You must promptly notify us at <a href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                RIGHT TO PROVIDE AND TO ACCESS TO PERSONAL DATA
                            </p>
                            <br/>
                            <p className="description">
                                You may, of course, decline to share certain information with us, in which case we may not be
                                able to provide to you some of the features and functionality of the Service. From time to time,
                                we send marketing e-mail messages to our users. If you no longer want to receive such emails
                                from us on a going forward basis, you may opt-out via the "unsubscribe" link provided in each
                                such email.
                            </p>
                            <br/>
                            <p className="description">
                                Under the Data Protection Act 2004 You have a right to access your Personal Data held by us,
                                and it is your responsibility to inform us of any changes to your Personal Data to ensure such
                                data remains accurate. You also have a right to object to your Personal Data being processed for
                                the purposes of direct marketing. You agree to provide a written request to us should You wish to
                                enforce these rights.
                            </p>
                            <br/>
                            <p className="description">
                                You can exercise Your rights of access, modification, deletion or opposition by sending us a
                                request by e-mail to <a href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/><br/>
                            <p className="description bold" style={{color:"#E43A70"}}>
                                ATTENTION: CHILDREN.
                            </p>
                            <br/>
                            <p className="description">
                                MINORS (THOSE UNDER THE AGE OF 18) MAY NOT USE THE SITE. WE DO NOT
                                KNOWINGLY COLLECT PERSONAL INFORMATION FROM ANYONE UNDER THE AGE
                                OF 18, AND NO PART OF THE SITE IS DESIGNED TO ATTRACT ANYONE UNDER THE
                                AGE OF 18. BECAUSE WE DO NOT INTENTIONALLY COLLECT ANY INFORMATION
                                FROM CHILDREN UNDER THE AGE OF 18, WE ALSO DO NOT KNOWINGLY SHARE
                                OR DISCLOSE SUCH INFORMATION TO THIRD PARTIES. IF YOU HAVE REASON TO
                                BELIEVE THAT A CHILD UNDER 18 YEARS OF AGE HAS PROVIDED US WITH
                                PERSONAL INFORMATION, PLEASE CONTACT US AT <a href="mailto:support@rocketdao.io">support@rocketdao.io</a> AND WE
                                WILL TAKE APPROPRIATE ACTION UNDER APPLICABLE LAW.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                UPDATES AND CHANGES IN PRIVACY POLICY
                            </p>
                            <br/>
                            <p className="description">
                                We reserve the right to change this Privacy Policy at any time (for example due to the applicable
                                legislation changes, etc). These changes will be set on this page so that You always know what
                                data is collected, how that information can be used and how we handle Your personal data.
                                Therefore, we encourage You to read this Privacy Policy regularly.
                            </p>
                            <br/>
                            <p className="description">
                                The "Effective Date" date at the top of this page indicates when this Policy was last revised. If
                                we make material changes, we may notify you through the Service or by sending you an email or
                                other communication. We encourage you to read this Policy periodically to stay up-to-date about
                                our privacy practices. Your use of the Service following these changes means that you accept the
                                revised Policy.
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                CONSENT TO THE PROCESSING OF PERSONAL DATA
                            </p>
                            <br/>
                            <p className="description">
                                I, further on as the "User", pursuant to the requirements of applicable Law, I give my consent for
                                processing of my personal data specified during registration by filling out any web forms on the
                                websites of Rocket DAO and confirming that by giving such consent I act in my own will and in
                                my interest.

                            </p>
                            <br/>
                            <p className="description">
                                Consent applies to the following information: surname, first name and patronymic; former
                                surname, name and patronymic (in case of changing user profile data); the photo; date of birth,
                                telephone number; E-mail address; nationality and other personal information that I provided in
                                web forms on any Rocket DAO websites.
                            </p>
                            <br/>
                            <p className="description">
                                Consent is granted for the implementation of any actions with respect to personal data that are
                                necessary to achieve the above objectives, including without limitation: collection,
                                systematization, accumulation, storage, clarification (updating, modification), use, distribution
                                (including transfer), depersonalization, blocking , destruction, as well as the implementation of
                                any other actions with personal data in accordance with applicable law.
                            </p>
                            <br/>
                            <p className="description">
                                The processing of the User's personal data is carried out solely for the purposes of registering the
                                User in the Rocket DAO database with subsequent sending to the User of e-mail messages and
                                SMS notifications, including advertising content, from Rocket DAO, its affiliates and / or
                                subcontractors, news and newsletters, invitations to the activities of Rocket DAO and other
                                information of advertising and news content, as well as to verify the identity of the User when
                                attending Rocket DAO events.
                            </p>
                            <br/>
                            <p className="description">
                                The date of issue of consent to the processing of the User's personal data is the date of sending
                                the registration web-form from the sites of Rocket DAO.
                            </p>
                            <br/>
                            <p className="description">
                                Rocket DAO performs KYC and AML procedures. If You supply Rocket DAO with the personal
                                information described above and any other personal data (including IP address), You agree that
                                Rocket DAO will therefore process Your information for AML purposes, and can provide Your
                                information to the state authorities in the countries Rocket DAO operates.
                            </p>
                            <br/>
                            <p className="description">
                                In accepting these Terms, You acknowledge and guarantee that any kind of funds that will be
                                transferred with the help of Rocket DAO Platform, are not obtained by criminal means, and that
                                Your Rocket DAO Token purchase is not aimed at money laundering. You hereby authorize
                                Rocket DAO to, directly or through third parties, make any inquiries We consider necessary to
                                verify Your identity and/or protect against fraud, including to query identity information
                                contained in public domains or reports, and to take any action We reasonably deem necessary
                                based on the results of such inquiries or reports. You further authorize any and all third parties to
                                whom such inquiries or requests may be directed to fully respond to such inquiries or requests.
                            </p>
                            <br/>
                            <p className="description">
                                The said consent is valid for an unlimited period from the moment of data provision and can be
                                withdrawn by the User by submitting an application to the administration of the Rocket DAO
                                sites by sending the appropriate order to the User in simple written form to the e-mail address
                                <a href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/><br/>
                            <p className="description bold">
                                CONTACT US.
                            </p>
                            <br/>
                            <p className="description">
                                If you would like to contact us for any reason e-mail us at <a href="mailto:support@rocketdao.io">support@rocketdao.io</a>
                            </p>
                            <br/><br/><br/>

                        </Scrollbars>

                    </div>
                </React.Fragment>
                }


                <div className="block-bottom-doc-reg-expert bottom-doc-reg-adaptive">
                    <ButtonGray
                        TitleButton={'Close'}
                        EventOnClick={this.props.handleCloseModal}
                        paddingButton={'55'}
                        disabled={false}
                    />
                    {!this.props.isAgree &&
                    <ButtonBlue
                        TitleButton={'I Agree'}
                        EventOnClick={this.props.addAgree}
                        paddingButton={'75'}
                        disabled={false && !this.state.toBottom}
                    />
                    }
                </div>
            </ReactModal>


        )
    }
}


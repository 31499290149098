import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {updateStartup} from "../../../actions/Login";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import {getLastProject, updateProject} from "../../../actions/Project";
import Slider from 'react-rangeslider'
import {getMoneyViewSpaces} from '../../../utils/transformation'
import NumberFormat from 'react-number-format';

class Step19 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project: props.project || {},
            hardCap: 0,
            errors: {}
        }
    }
    componentDidMount() {
        if(!this.state.project.id){
            this.props.getLastProject()
        }  else {
            this.calculate()
        }
    }
    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project}, () => this.calculate())
        }
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        value = value.replace(/\D/, "");

        switch (name){
            case 'tokensCount':

                break
            case 'tokensForSale':
                if(value > 100) value = 100;
                break
            case 'tokensPrice':

                break

        }

        this.setState({project: {...this.state.project, [name]: value}}, () => this.calculate())
    }

    onChangeTokensCount = obj => {
        this.setState({project: {...this.state.project, ['tokensCount']: obj.floatValue}}, () => this.calculate())
    }

    onChangeTokensForSale = obj => {
        let value = obj.floatValue
        if(value > 100) value = 100;
        this.setState({project: {...this.state.project, ['tokensForSale']: value}}, () => this.calculate())
    }

    onChangeTokensPrice = obj => {
        this.setState({project: {...this.state.project, ['tokensPrice']: obj.floatValue}}, () => this.calculate())
    }



    onChange2 = (name, value) => {
        this.setState({project: {...this.state.project, [name]: value}}, this.calculate())
    }

    calculate = () => {
        let { tokensCount, tokensForSale, tokensPrice }  = this.state.project;

        let hardCap = Math.round(Number(tokensCount) * Number(tokensForSale) * Number(tokensPrice) /100)

        if (hardCap == 'undefined' || hardCap == 'NaN' || hardCap == 'Infinity') hardCap = '--'

        this.setState({hardCap: hardCap})
    }

    update = (nextStep, onlyStep) => {
        let project = this.state.project
        let obj = {
            id: project.id,
            tokensCount: project.tokensCount,
            tokensForSale: project.tokensForSale,
            tokensPrice: project.tokensPrice
        }
        if (nextStep === 8 && onlyStep) {
            obj.event_data = {
                skip: true
            }
        }
        obj.event_type = 'startup_tokensale_8';



        this.setState({loading: true})
        this.props.updateStartup(obj , onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    updateS = (onlyStep, nextStep) => {
        this.props.updateStartup({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }



    render() {
        let {project, errors} = this.state;

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-30">
                                    Token sale info
                                </p>
                                <p className="text-button color-text-reg-gray margin-0 shell-margin-bottom-30">
                                    Crowdfunding has proven itself to be one of the most popular tools for attracting funding for startups. Great choice!
                                </p>
                                <p className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-50">
                                    How many tokens have you issued? <br/>
                                    Which percentage of these tokens have you allocated to sell to the investors?
                                </p>

                                <div className="block-input-range slider slider-green shell-margin-bottom-40 wrap-for-input-105">
                                    <p className="title-input-range">
                                        <span className="s19-t">Total tokens number</span>
                                        <span className="value">
                                        <NumberFormat thousandSeparator={' '} value={project.tokensCount} onValueChange={this.onChangeTokensCount}/>
                                    </span>
                                    </p>
                                </div>

                                <div className="block-input-range slider slider-black shell-margin-bottom-40">
                                    <p className="title-input-range wrap-for-input-105">
                                        <span className="s19-t">Tokens for sale</span>
                                        <span className="value">
                                            <NumberFormat suffix={' %'} value={project.tokensForSale} onValueChange={this.onChangeTokensForSale} />
                                        </span>
                                    </p>
                                    <div style={{marginTop: '20px'}}>
                                        <Slider
                                            min={0}
                                            max={100}
                                            tooltip={false}
                                            value={project.tokensForSale}
                                            onChange={(value) => this.onChange2('tokensForSale', value)}
                                        />
                                    </div>

                                    <p className="title-left-right-input-range">
                                        <span className="left">0 %</span>
                                        <span className="right">100 %</span>
                                    </p>
                                </div>


                                <div className={'step-19-tp'}>
                                    <div className="block-input-range slider slider-green shell-margin-bottom-40">
                                        <p className="title-input-range wrap-for-input-105">Token price:<span className="value">
                                        <NumberFormat suffix={' $'} thousandSeparator={' '} value={project.tokensPrice} onValueChange={this.onChangeTokensPrice} />
                                    </span>
                                        </p>
                                    </div>

                                    <p className="title-value-cap">Hard Cap:
                                        <span className="value">{(project.tokensForSale && project.tokensPrice) ? ` ${getMoneyViewSpaces(this.state.hardCap)} $` : ' --' }</span>
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(18, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 16px'}}>
                            <p className="title-block-button-reg-expert">
                                If you don’t have <br/>
                                any tokens yet
                            </p>
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(8, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(8)}
                                paddingButton={'54'}
                                disabled={!this.state.hardCap}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step19.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step19)
import React, {useState, useEffect} from 'react';
import './styles.css'
import CheckBox from "../../../controls3.0/checkBox/checkBox";
import Select from "../../../controls3.0/select/SelectWithAdd";
import Input from "../../../controls3.0/input/Input";
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import ListMailMore from "../../../controls3.0/listMailMore/ListMailMore";
import axios from "axios";
import {sendInvitation} from "../../../../actions/Accelerator";

// import accelerator from "../../steps/accelerator";
import {regexEmail} from "../../../../utils/RegExp";
import {Scrollbars} from "react-custom-scrollbars";

const AcceleratorInvitePopup = ({accelerator, close, parentCallback}) => {

    const [email, setEmail] = useState('');
    const [team, setTeam] = useState([]);
    const [positionList, setPositionList] = useState([]);
    const [position, setPosition] = useState('');
    const [sended, setSended] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFounder, setIsFounder] = useState(false);

    const onChange = event => {
        const {value} = event.target;

        if (regexEmail.test(value)) {
            delete errors.email
        } else {
            errors.email = 'Invalid e-mail address'
        }

        if (accelerator.team.find((item) => item.email === value) || accelerator.User.email === value || team.find((item) => item.email === value)) {
            errors.email = 'Exist address'
        }

        setEmail(value);
    };

    useEffect(() => {
        getPositionList('A');
    }, []);

    const getPositionList = function (filter) {
        if (filter) {
            axios
                .get('/v2/positions/' + filter)
                .then(r => {
                    setPositionList(r.data);
                })
        }
    };

    const onKeyDown1 = (e) => {
        getPositionList(e.target.value);
    };
    const onChangePosition = Position => {
        setPosition(Position)
    };

    const addToList = () => {
        const obj = {email, position: position[0].Name, isFounder};
        setTeam([...team, obj]);
        setEmail('');
        setPosition({...{}});
    };

    const send = () => {
        sendInvitation(accelerator.id, {members: team})
            .then((res) => {
                setSended(true);
            })
            .catch(error => {

            })
    };

    const removeFromList = email => {
        const filtered = team.filter((item) => {
            return item.email !== email
        });

        setTeam([...filtered]);
    }

    return (
        <React.Fragment>
            <div className={`profile-edit-popup-block`}>
                <div className="block-members-popup block-invite-popup">
                    <Scrollbars style={{height: '80vh'}}>
                        <div style={{padding: '0 40px 0 0'}}>
                            <div className='close-title'>
                                <div className='close' onClick={close}/>
                            </div>

                            {!sended && <div className="block-add-more-team">
                                <div className="block-section-gradient">
                                    <div className='text-members-invite font-weight-500 shell-margin-bottom-30'>Add more
                                        team members to the public profile of your accelerator
                                    </div>
                                    <div className="block-list-input-email">
                                        <div className="block-left-list-input-email shell-margin-bottom-50"
                                             style={{width: '100%'}}>
                                            <div className="block-input-reg">
                                                <p className="title-input-reg">Enter email</p>
                                                <Input
                                                    type={'text'}
                                                    name={'email'}
                                                    value={email}
                                                    onChange={onChange}
                                                    error={errors.email}
                                                    placeholder={''}
                                                />
                                            </div>
                                            <div className="block-select-reg">
                                                <p className="title-input-reg">Select position</p>
                                                <Select
                                                    placeholder={'Select'}
                                                    data={positionList}
                                                    value={position}
                                                    onKeyUp={(e) => onKeyDown1(e)}
                                                    onChange={(e) => onChangePosition(e)}
                                                    error={''}
                                                />
                                            </div>
                                            <div className="block-button-list-input-email">

                                                <ButtonBlue
                                                    TitleButton={'Invite'}
                                                    EventOnClick={() => addToList()}
                                                    paddingButton={'48'}
                                                    disabled={!email || !position || Object.keys(errors).length}
                                                    loader={null}
                                                />

                                                <p className="check-box-user-profile-reg-startup"
                                                   onClick={() => setIsFounder(!isFounder)}>
                                                    <CheckBox
                                                        active={isFounder}
                                                        // onClick={()=> setIsFounder(!isFounder)}
                                                    />
                                                    Founder
                                                </p>
                                            </div>

                                        </div>

                                        {team.length && <div className="block-right-list-input-email"
                                                             style={{float: 'none', width: '100%'}}>
                                            <p className="right-list-title">Here is the list of emails we will send
                                                invitations to:</p>
                                            <div style={{height: '230px'}}>
                                                {team.length && <ListMailMore
                                                    data={team}
                                                    onChange={(e) => console.log(e)}
                                                    viewCountItem={3}
                                                    removeFromList={removeFromList}
                                                /> || ''}
                                            </div>
                                        </div> || ''}
                                        <div className='send-button-line margin-top-25'>
                                            <ButtonBlue
                                                TitleButton={(team.length > 1) ? 'Send invitations' : 'Send invitation'}
                                                EventOnClick={() => send()}
                                                paddingButton={'31'}
                                                disabled={!team || !team.length}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {sended && <React.Fragment>
                                <div className="block-send-popup-bg"/>
                                <div
                                    className='members-text-title text-align-center font-weight-500'>{(team.length > 1) ? 'Invitations have' : 'Invitation has'} been
                                    successfully sent!
                                </div>
                                <div className='members-text-little-subtitle text-align-center margin-top-15'>
                                    You will be notified when your
                                    team {(team.length > 1) ? 'members register ' : 'member registers '}
                                    on the platform via your inviation link.
                                </div>
                                <div
                                    className="members-text-subtitle margin-top-65 margin-bottom-20 text-align-center">We
                                    have
                                    sent {(team.length > 1) ? 'invitations to these emails:' : 'invitation to this email:'}</div>
                                <div className='list-mail-inv-send'>
                                    <div className='mail-block-border'>
                                        {team.map((i, k) =>
                                            <div className='text-mail' key={k}>{i.email}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='send-button-line margin-top-40'>
                                    <ButtonBlue
                                        TitleButton={'Continue'}
                                        EventOnClick={() => close()}
                                        paddingButton={'58'}
                                        disabled={false}
                                    />
                                </div>
                            </React.Fragment>}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </React.Fragment>
    )
};

export default AcceleratorInvitePopup;

import React from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {logout, tokenLogin} from "../../actions/Login";

import Header from '../header'
import Footer from '../footer/Footer'
import Routes from './routes'


class RegistrationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id
        }
    }

    componentDidMount() {
        if(localStorage.RocketToken){
            this.props.tokenLogin(localStorage.RocketToken);
        }

    }

    render() {
        return (
            <React.Fragment>
                <div className='reg-wrap'>
                    <Header user={this.props.user}/>
                    <div className="container-100-reg user-container">
                        <Routes user={this.props.user}/>
                    </div>
                    <Footer/>
                </div>
                <NotificationContainer/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
})

RegistrationContainer.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, {tokenLogin})(RegistrationContainer)
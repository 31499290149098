import React, {useState, useEffect, useRef} from 'react';
import {createAvatar} from "../../user/createAvatar";
import config from '../../../config';
import {Row} from "../Grid/Row"
import {VerificationIcon} from "../VerificationIcon";
import {isExist} from "../../../utils/helper";

export const AngelNetworkListItem = ({key, href, item, onClick, listIndustries}) => {
    const _avatar = item.logoFileId ?
        <div className="avatar"
             style={{backgroundImage: `url(${`${config.proxy}/file/${item.logoFileId}/view?w=${config.image.logo.sm}`})`}}/>
        :
        createAvatar('avatar', item.title);

    return (
        <div key={key} className={'col-12 col-sm-6 col-md-4'}>
            <a href={href}>
                <div className='all-card-block slide-up'
                 onClick={onClick}>
                <div className='all-card-top'>
                    {_avatar}

                    <div className='info-conteiner'>
                        <div className='title'>Angel network</div>
                        <div className='name'>{item.title}</div>
                        <VerificationIcon type={'angel_network'} className={'fly'}/>
                        {/*<span className="check">*/}
                        {/*    <i className="fal fa-check"/>*/}
                        {/*</span>*/}
                    </div>
                </div>
                <div className='all-card-content'>
                    <div className='description subtitle'>{item.shortDescription.descriptionFormat()}</div>
                    <div className='location subtitle'>{item.location}</div>
                    <div className='industries title'>Industries</div>

                    {listIndustries && item.industry &&
                    <div className="industries-list">
                        {item.industry.slice(0, 4).map(id => {
                            return listIndustries.find((a) => a.id === id) ?
                                <span>{listIndustries.find((a) => a.id === id).title}</span> : ''
                        })}
                        {item.industry.length > 4 && <span> {item.industry.length} more… </span>}
                    </div>
                    }
                </div>
                <Row className='all-card-bottom'>
                    <div className='col-6 fund-size'>
                        <div className='title'>Members</div>
                        <div className='subtitle'>{item.currentTeamCount || 0}</div>
                    </div>

                    {item.fundSize &&
                    <div className='col-6 investment-size'>
                        <div className='title'>Investment size</div>
                        <div
                            className='subtitle'>
                            { isExist(() => item.fundSize.investmentSize.min) && isExist(() => item.fundSize.investmentSize.max) &&
                            `$${item.fundSize.investmentSize.min.toMoneyFormat2()}-${item.fundSize.investmentSize.max.toMoneyFormat2()}` }</div>
                    </div>
                    }
                </Row>
            </div>
            </a>
        </div>
    )
};
import React from "react";
import './style.scss';

export const VerificationIcon = ({type, className=''}) => {

    const getType = (type) => {
        switch (type) {
            case 'investor' :
            case 'angel_network' :
            case 'fund' :
            case 'accelerator' : return 'blue-icon';

            case 'expert' : return 'green-icon';
            case 'startup' : return 'black-icon';
            default: return 'blue-icon'
        }
    };

    return (
        <div className={`verification ${getType(type)} ${className}`}/>
    );
};

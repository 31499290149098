import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateFund} from "../../../actions/Login";
import {getListFocuses} from "../../../actions/Project";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import CubeCheckBox from "../../controls3.0/checkBox/cubeCheckBox";


class Step9 extends React.Component {
    constructor(props) {
        super();
        let fund = props.fund,
            investor = fund || {};
        if(!investor.marketFocus) investor.marketFocus = []
        this.state = {
            fund: fund,
            investor: investor,
            errors: {},
            listF: []
        }
    }

    componentDidMount () {
        getListFocuses()
            .then((res) => {
                this.setState({listF: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    onChange = item => {
        let marketFocus = this.state.investor.marketFocus

        let isFound = marketFocus.find(x => x === item.id)
        if (!isFound) {
            marketFocus.push(item.id)
        } else {
            marketFocus = marketFocus.filter(x => x !== item.id)
        }

        if (item.title == 'All') {
            if(isFound){
                marketFocus = []
            } else {
                marketFocus = this.state.listF.map((item) => item.id);
            }
        } else {
            marketFocus = marketFocus.filter(id => id != 18)
        }

        this.setState({investor: {...this.state.investor, marketFocus: marketFocus}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if(onlyStep){
            obj = {}
        }

        const fundId = this.props.fund.id;

        if(!onlyStep) this.setState({loading: true})
        this.props.updateFund(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        let {fund, investor, errors} = this.state

        let list = this.state.listF.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup"  onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CubeCheckBox
                        onClick={null}
                        active={investor.marketFocus.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup">{item.title}</div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Geographic market focus
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-10" style={{maxWidth:'720px'}}>
                                    Do you prefer to work with and invest in the startups originating from specific countries?
                                </p>
                                <div className="text-button-2 color-text-reg-gray margin-0 shell-margin-bottom-40">
                                    Choose the most preferable options from the below.
                                </div>

                                <div className="block-list-check-box-reg-startup bl-min-height">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(7, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(9)}
                                paddingButton={'54'}
                                disabled={!this.state.investor.marketFocus.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    fund: state.fund
})

Step9.propTypes = {
    updateFund: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateFund})(Step9)
import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {logout, tokenLogin, updateUser} from '../../actions/Login'
import Header from '../header'
import Footer from '../footer/Footer'
import '../test-controls/Page2.0/css/style.css'
import '../test-controls/Page2.0/css/StartPage.css'
import '../home/home.css'
import ButtonTransBlue2_0 from '../controls2.0/Button2.0/ButtonTransBlue2.0'
import '../controls2.0/ProgressBar2.0/ProgressBar2.0.css';
import Loader from './../test-controls/preloader/preloader'
import Folder from '../controls2.0/Folder/Folder'
import File from '../controls2.0/File/File'
import moment from "moment/moment";
import Tag2 from '../controls2.0/Tag2.0/Tag2.0'
import {getKnowledge, getKnowledgeOne, deleteKnowledgeOne, getKnowledgeFilter} from "../../actions/Profile";
import MetaTags from 'react-meta-tags';


class KnowledgeBase extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loaded: false,
            showModal: false,
            parentDOM: [],
            tagsSearch: [],
            listKnowlendgeBase: [],
            countSearch:{}
        };
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        this.getParent()
    }

    getParent = () => {
        getKnowledge()
            .then((res) => {
                let resData = res.data;
                this.setState({listKnowlendgeBase: resData})
                let arr = [];
                resData.forEach((item, key) => {
                    let thing = this.getThingByType(item);
                    arr.push(thing)
                })
                this.setState({parentDOM: arr})
                this.setState({loaded: true})
            })
            .catch(error => {
                this.setState({loaded: true})
            })
    }

    onChangeSearch = e => {
        let value = e.target.value;
        if (value) {
            let obj = {search: value, tags: this.state.tagsSearch}
            this.getKnowledgeFilter(obj)
        } else {
            this.getParent()
        }


    }

    getFilterByTag = tag => {
        let tags = this.state.tagsSearch;
        if(tags.length > 2) return
        tags.push(tag)
        let uniq = [...new Set(tags)];
        this.setState({tagsSearch: uniq})
        let obj = {tags: uniq}
        this.getKnowledgeFilter(obj)
        this.setState({hasFilter: true})
    }

    getKnowledgeFilter = obj => {
        this.setState({loaded: false})
        obj.tags = obj.tags.join(',')

        getKnowledgeFilter(obj)
            .then((res) => {
                console.log(res.data)
                let resData = res.data.data || [];

                this.setState({listKnowlendgeBase: resData, countSearch: res.data.count})
                let arr = [];
                resData.forEach((item, key) => {
                    let thing = this.getThingByType(item);
                    arr.push(thing)
                })
                this.setState({parentDOM: arr})
                this.setState({loaded: true})
            })
            .catch(error => {
                this.setState({loaded: true})
            })
    }

    getThingByType = item => {
        let newItem;
        switch (item.type) {
            case 'file':
            case 'href':
                newItem = this.fileLinkPrototype(item)
                break;
            case 'dir':
                newItem = this.folderPrototype(item)
                break;
            default:
        }
        return newItem
    }

    addNewThingToDOM = item => {
        let newItem = this.getThingByType(item);
        let arr = this.state.parentDOM;
        arr.push(newItem);
        this.setState({parentDOM: arr})
    }

    fileLinkPrototype = item => {
        let file =
            <File
                key={item.id}
                file={item}
                nestingFile="0"
                delete={this.delete}
                getFilterByTag={this.getFilterByTag}
            />

        return file;
    }
    folderPrototype = item => {
        let folder =
            <Folder
                key={item.id}
                folder={item}
                addNewThing={this.addNewThingToDOM}
                nestingFolder="0"
                parent={false}
                delete={this.delete}
                getFilterByTag={this.getFilterByTag}
            >
            </Folder>

        return folder
    }

    delete = item => {
        if(window.confirm ('DELETE. are you sure?')) {
            this.setState({loaded: false})
            deleteKnowledgeOne(item.id)
                .then((res) => {
                    let arr = this.state.parentDOM;
                    let newArr = arr.filter(thing => (item.id != thing.key))
                    this.setState({parentDOM: newArr, loaded: true})
                })
                .catch(error => {
                    this.setState({loaded: true})
                })
        }
    }

    returnFromFilter = () => {
        this.setState({hasFilter: false, tagsSearch: [], countSearch: {}})
        this.getParent()
    }

    removeTag = (item, key) => {
        let tags = this.state.tagsSearch;
        tags.splice(key, 1)
        this.setState({tagsSearch: tags})
        let obj = {tags: tags}
        this.setState({loaded: false})
        this.getKnowledgeFilter(obj)
        this.setState({hasFilter: true})
    }


    render() {
        let user = this.props.user || {};


        let searchTagsList = this.state.tagsSearch.map(function (item, key) {
            return (
                <div className="block-tag-search" key={key}>
                    <i className="fal fa-tag"></i>
                    <p className="title">{item}</p>
                    <span className="close" onClick={() => this.removeTag(item, key)}></span>
                </div>
            )
        }.bind(this))


        return (

            <div className="block-team-2-0 background-white">

                {/*<Header user={user} bgcColor={'#0028DC'}></Header>*/}

                <div className="header-container-footer container-DAO" style={{'paddingBottom': '50px', marginTop:'0'}}>


                    <div className="shell-top-page-knowlege-base" style={{margin:'0'}}>
                        <div className="block-top-page-knowlege-base">
                            {/*<p>*/}
                                {/*{this.state.hasFilter &&*/}
                                {/*<a onClick={() => this.returnFromFilter()}><i*/}
                                    {/*className="fal fa-long-arrow-left"></i></a>*/}
                                {/*}*/}

                                {/*Knowledge base*/}

                                {/*{this.state.hasFilter &&*/}
                                {/*<span className="search-title-link">Searching results:*/}
                                    {/*<a className="link-search">Folders <span className="count-search">{this.state.countSearch.folders}</span></a>*/}
                                    {/*<a className="link-search">Files<span className="count-search">{this.state.countSearch.files}</span></a>*/}
                                    {/*<a className="link-search">Links<span className="count-search">{this.state.countSearch.hrefs}</span></a>*/}
                                {/*</span>*/}
                                {/*}*/}
                            {/*</p>*/}

                            <div className="block-search-input-DAO" style={{width:'320px', float:'left'}}>
                                <i className="fal fa-search"></i>
                                <input type="text" placeholder="Search" value={this.state.search}
                                       onChange={this.onChangeSearch}/>

                                {/*.block-search-input-DAO i
                                Юра когда будешь делать эти теги
                                сделай плиз функцию которая будет при добавлении нового тега
                                поле input задавать ширену
                                input.width = block-search-input-DAO.width - block-list-tag-search.width - 36
                                и пока не добавлено ни одного тега не делать block-search-input-DAO в этом классе актив

                                когда сделаешь или так что сообщи мне, я уберу статичную ширену инпута и посмотрю что получилось=)
                                */}



                                <div className="block-list-tag-search">
                                    {searchTagsList}
                                </div>

                            </div>
                        </div>
                    </div>

                    {/*<div className="margin-top-100"></div>*/}


                    <div className="shell-grid-DAO no-margin">
                        <div className="block-grid-DAO">
                            <div className="block-header-grid-DAO">
                                <div className="block-column-grid-DAO block-column-title-folder-grid-knowlege-edit">
                                    <p className="title-header"></p>
                                </div>
                                <div className="block-column-grid-DAO block-column-tag-folder-grid-knowlege-edit">
                                    <p className="title-header">Tags</p>
                                </div>
                                <div className="block-column-grid-DAO block-column-date-folder-grid-knowlege-edit">
                                    <p className="title-header">Last update</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div data-id="parent">
                        {this.state.parentDOM}
                    </div>


                    <Folder
                        folder={{}}
                        parent={true}
                        id='parent'
                        addNewThing={this.addNewThingToDOM}
                    >
                    </Folder>


                </div>

                {/*<Footer></Footer>*/}
                <Loader loaded={this.state.loaded}></Loader>

                <MetaTags>
                    <title>Knowledge base - rocketdao.io</title>
                    <meta property="og:title" content={'Knowledge base - rocketdao.io'}/>
                </MetaTags>


            </div>

        )

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

KnowledgeBase.propTypes = {
    tokenLogin: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(KnowledgeBase)
import React from 'react';
import './stepsStyle.css'
import moment from "moment/moment";
import ButtonBlue from '../../../controls3.0/button/ButtonBlue'
import {notifyFollowers} from '../../../../actions/Project'
import {showMessage} from "../../../../utils/showMessage";
import {Button} from "../../../v4/Button";

const types = {
    'startup':'Edit startup profile',
    'investor':'Edit investor profile',
    'expert':'Edit expert profile',
    'fund':'Edit fund profile',
    'angel_network':'Edit angel network profile',
    'accelerator':'Edit accelerator profile'
};

export default class Step extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , steps: []
            , notifyFollowersAccess: props.data.notifyFollowersAccess == null || props.data.notifyFollowersAccess
            , notifyFollowersLoader: false
        }
    }

    typesEdit(type){

        if(!this.props.data){
            return ''
        }

        switch (type){
            case 'startup':
                return (
                <div>
                    <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
                </div> );

            case 'investor': return (
                <div>
                    <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.investor.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
                </div>);

            case 'expert': return (<div>
                <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.expert.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
            </div>);

            case 'fund': return ( <div>
                <div>
                    <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
                </div>
            </div>);

            case 'angel_network': return (<div>
                <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
            </div>);

            case 'accelerator': return (
                <div>
                    <div className='text-edited margin-top-3'>Last edited: {moment(this.props.data.updatedAt).format("DD MMM, YYYY [at] h:mm a")}</div>
                </div> );
        }
    }

    async componentDidMount() {
        const steps = await import(`../${this.state.type}`);
        this.setState({steps: steps.default});
    }

    async componentDidUpdate(prevProps){
        if(prevProps.type !== this.props.type){
            const steps = await import(`../${this.props.type}`);
            this.setState({steps: steps.default, type: this.props.type});
        }

    }

    checkFundraising(step){
        try{
            return step.isICO.indexOf(this.props.data.fundraisingInstruments) !== -1;
        } catch (e) {
            return false;
        }
    }

    notify = async () => {
        if(this.state.type === 'startup' && !this.state.notifyFollowersLoader){
            this.setState({notifyFollowersLoader: true});

            notifyFollowers(this.state.data.id).then(r => {
                this.setState({notifyFollowersAccess: false});
                this.setState({notifyFollowersLoader: false});
                showMessage({type: 'info', data: 'Sended to followers '})
            }).catch(e => {
                this.setState({notifyFollowersLoader: false})
            })
        }
    }

    render() {
        const {steps, type} = this.state;
        return (
            this.props.data && <div className='edit-profile-list'>
                <div className='row-b' style={{marginRight: 0, justifyContent:'space-between'}}>
                    <div className={`${this.state.type === 'startup'?'col-12 col-md-5':'col'}`}>
                        <div className='edit-profile-main-title'>
                            <div className={`${(this.state.type === 'fund') ? '' : ''}`}>
                                <div className='text-edit-list-title font-weight-500'>{types[this.state.type]}</div>
                            </div>
                            {this.typesEdit(this.state.type)}
                        </div>
                    </div>
                    <div className='col-auto'>
                        {this.state.type === 'startup' && <Button
                            TitleButton={''}
                            onClick={() => this.notify()}
                            height={36}
                            loader={this.state.notifyFollowersLoader}
                            disabled={!this.state.notifyFollowersAccess}
                        >Share updates with the followers</Button>}
                    </div>
                </div>


                <div className='edit-profile-table'>{
                    Object.keys(steps).map((i) => {
                        if(!steps[i].noShow && steps[i].active || this.checkFundraising(steps[i])){
                            return <div key={i} className='edit-profile-table-line text-left' onClick={ (e) => {
                                this.props.onClick(e, i, type, steps[i]);}}>
                                <span>{steps[i].title}</span>
                                <div className='table-edit-button'><span>Edit</span></div>
                            </div>}
                        }
                        )
                }</div>
            </div> || ''

        )
    }
}
 import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup, createStartup} from "../../../actions/Login";
import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import {getLastProject} from "../../../actions/Project";
import {goToStep} from "../../../utils/regNavigation";

class Step1 extends React.Component {
    constructor(props) {
        super();
        this.state = {
            project: {}
        }
    }

    update = (nextStep, onlyStep) => {
        goToStep(2);
    }

    back = () => {
        //localStorage.removeItem('RocketToken');
        //window.location.href = '/login?type=3'
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && nextProps.user.id) {

        }
    }

    componentWillUpdate(nextProps) {

    }

    componentDidMount () {
        //if(!this.state.project.id) this.props.getLastProject()
    }

    render () {
        let user = this.props.user || {}

        return (
            <React.Fragment>
                <div className="step-1 is-startup">
                    <div className="wrap-white">
                        <div className="container-full-reg" style={{position:'relative'}}>
                            <p className="text-h1">
                                &#8212; Welcome, {user.firstName}
                            </p>
                            <div className={'pg-left'}>
                                <p className="text-h5">
                                    It’s time to take a fresh look at your startup!
                                </p>
                                <p className="text-h6">
                                    In just a few steps you will become a part of Rocket DAO - an international venture community and a platform where startups get expert support and connect with investors.
                                    <br/><br/>
                                    Please note that registration takes about 15 minutes. You will be able to continue registration or edit your profile at any convenient time.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="container-full-reg container-bottom block-gradient-gray-reg padding-reg" style={{position:'relative'}}>
                        <div className="block-list-cart-start-reg startup-cart-start-reg">
                            <div className="block-item-cart-start-reg" onClick={() => this.update(2, true)}>
                                <div className='numb active'>01</div>
                                <p className='text-h4 test'>
                                    Create <br/>
                                    startup profile
                                </p>
                                <div className={'btm-text'}>
                                    Fill in some basic and still
                                    very important and
                                    crucial information about
                                    your project, tell your
                                    story.
                                </div>
                            </div>
                            <div className={'div-flex'}>
                                <span></span>
                            </div>
                            <div className="block-item-cart-start-reg">
                                <div className='numb'>02</div>
                                <p className='text-h4 no-active'>
                                    Get <br/>
                                    feedback
                                </p>
                                <div className={'btm-text no-active'}>
                                    In few days our manager will
                                    contact you about your project
                                    if it fits the criteria of our focus.
                                </div>
                            </div>
                            <div className={'div-flex'}>
                                <span></span>
                            </div>
                            <div className="block-item-cart-start-reg">
                                <div className='numb '>03</div>
                                <p className='text-h4 no-active'>
                                    Show <br/>
                                    your traction
                                </p>
                                <div className={'btm-text no-active'}>
                                    Share key metrics and further
                                    events of your startup development.
                                    Investors love startups that show growth.
                                </div>
                            </div>
                            <div className={'div-flex'}>
                                <span></span>
                            </div>
                            <div className="block-item-cart-start-reg">
                                <div className='numb '>04</div>
                                <p className='text-h4 no-active'>
                                    Start <br/>
                                    fundraising
                                </p>
                                <div className={'btm-text no-active'}>
                                    With all the preparations done,
                                    confidently start your successful fundraising campaign.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-one">
                    <div className="container-full-reg">
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Ok, got it!'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'80'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step1.propTypes = {
    updateStartup: PropTypes.func.isRequired,
    createStartup: PropTypes.func.isRequired,
    getLastProject: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {updateStartup, createStartup, getLastProject})(Step1)
export const checkAccess = (user, role) => {
    let access = true
    // let access = false
    // switch (role) {
    //     case 'investor':
    //         if(user && (typeof user.investor === 'object' && user.investor !== null) && user.investor.registrationStep === 24) {
    //             access = true
    //         }
    //         break;
    //     case 'expert':
    //         if(user && (typeof user.expert === 'object' && user.expert !== null) && user.expert.registrationStep === 24 ) {
    //             if(user.kycStatus === "Approved") access = true
    //         }
    //         break;
    //     case 'startup':
    //         if(user && user.startup && user.startup.id) {
    //
    //         }
    //         break;
    // }

    return access
}

export const checkRegistration = (user, role) => {
    let location = '/start_reg';

    switch (role) {
        case 'investor':
            if(user && user.investor && user.investor.id) {
                location = `/registration/investor/${user.investor.registrationStep}`
            } else {
                location = '/start_reg?type=1'
            }
            break;
        case 'expert':
            if(user && user.expert && user.expert.id) {
                location = `/registration/expert/${user.expert.registrationStep}`
            } else {
                location = '/start_reg?type=2'
            }
            break;
        case 'startup':
            if(user && user.startup && user.startup.id) {
                location = `/registration/startup/${user.startup.registrationStep}`
            } else {
                location = '/start_reg?type=3'
            }
            break;
    }
    window.location.href = location;
}

export const checkTitleOfButton = (user, role, text) => {
    let title = 'Become an expert ';

    switch (role) {
        case 'investor':
            if(user && user.investor && user.investor.id) {
                title = 'Continue registration'
                // if (user.expert.registrationStep !== 24){
                //
                // }
            }
            break;
        case 'expert':
            if(user && user.expert && user.expert.id) {
                title = 'Continue registration'
                // if (user.expert.registrationStep !== 24){
                //
                // }
            }
            break;
        case 'startup':
            if(user && user.startup && user.startup.id) {
                title = 'Continue registration'
                // if (user.expert.registrationStep !== 24){
                //
                // }
            }
            break;
    }

    return title
}



import React from 'react';
import Input from '../../controls3.0/input/Input'
import {getComments, likeUp, sendComment, deleteComment} from '../../../actions/Comment'
import './style.css';
import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import PopupLogin from '../popup/login';

export class Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
            , comments: []
            , currentComment: ''
            , error: {}
            , showLogin: false
            , popupLoginProps: {}
        }
        this.closePopup = this.closePopup.bind(this);
    }

    setComments = () => {
        getComments(this.state.type, this.state.id)
            .then(r => {
                this.setState({comments: r.data})
            })
    }

    componentDidMount() {
        this.setComments();
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.id !== nextProps.id){
            this.setState({...nextProps}, () => {
                this.setComments();
            });
        }
    }

    setComment = (e) => {
        this.setState({currentComment: e.currentTarget.value})
    }
    closePopup(){
        this.setState({showLogin: false, popupLoginProps: {}});
    }


    commentSendCheck = (e) => {
        if(this.props.user && this.props.user.id){
            const {currentComment, type, id} = this.state;

            if (e.key === 'Enter' && currentComment.length) {
                const data = {
                    text: currentComment
                }

                if (currentComment.length) {
                    this.setState({currentComment: ''});
                    sendComment(type, id, data)
                        .then(r => {
                            this.setComments();
                        })
                }
            }
        } else {
            if (e.key === 'Enter'){
                console.log('this.props.location', this.props.location);
                this.setState({
                    popupLoginProps: {
                        text: "Only registered users can leave comments!",
                        commandState: {
                            to: '/',
                            type: this.state.type,
                            id: this.props.id,
                            prevLocation: this.props.location ? this.props.location.pathname : null
                        }
                    }
                }, () => {
                    this.setState({showLogin: true});
                });
            }
        }
    }

    like = (id) => {
        likeUp(id, {value: 1})
            .then(r => {
                this.setComments();
            })
    }

    deleteMyComment = (id) => {
        deleteComment(id).then(r => {
            this.setComments();
        })
    }

    render() {
        let {comments, user} = this.state;
        if(!user){
            user = {};
        }
        return (
            <div className="block-comments">
                <div className="block-comments-gradient"/>
                <p className="title-comments">
                    Сomments
                    {comments.length > 0 && <span className="count-comments">{comments.length}</span>}
                </p>
                {comments.map(comment => {
                    const active = this.props.user && comment.Likes.find((o) => o.userId === this.props.user.id) ? {'color': 'red'} : {}

                    return <div className="block-item-comments">
                        <p className="title-item-comments">
                            {comment.user.lastName} {comment.user.firstName}
                            {/*<div className='time-block'>
                                <Tooltip content={moment(comment.createdAt).format('LLLL')} direction="up-start">
                                    <span className="time">
                                            {comment.fromNow}
                                    </span>
                                </Tooltip>
                            </div>*/}
                        </p>
                        <p className="description-item-comments">
                            {comment.text}

                            {this.props.user && comment.userId === this.props.user.id && <span className="delete-comments" onClick={() => this.deleteMyComment(comment.id)}/> }

                            <span className="like-comments">
                                                <span className="block-icon-like active"
                                                      onClick={() => this.like(comment.id)}>
                                                    <i className="fas fa-heart" style={active}></i>
                                                    <i className="far fa-heart" style={active}></i>
                                                </span>

                                                <span
                                                    className='like-count'>{comment.Likes.length ? comment.Likes.length : ' '}</span>
                                            </span>
                        </p>
                    </div>
                })}

                <div className="block-input-button-sent-comments">
                    <Input
                        type={'text'}
                        name={'text'}
                        value={this.state.currentComment}
                        onChange={this.setComment}
                        onKeyPress={this.commentSendCheck}
                        error={this.state.error.title}
                        placeholder={'Message…'}
                        required={true}
                    />
                    <ButtonBlue
                        TitleButton={'Send'}
                        EventOnClick={() => this.commentSendCheck({key: 'Enter'})}
                        paddingButton={'54'}
                        disabled={!this.state.currentComment}
                        loader={false}
                        onMouseEnter={null}
                        onMouseLeave={null}
                    />
                </div>
                {this.state.showLogin &&
                <PopupLogin
                    {...this.state.popupLoginProps}
                    user={this.props.user}
                    onClose={this.closePopup}/>
                }
            </div>
        );
    }
}
import React from 'react';


export const listSocialsUserForTeam = (user) => {
    return (
        <div className='list-social'>
            {user.facebook && <a href={user.facebook.toUrl()}><i className="fab fa-facebook"/></a>}
            {user.twitter && <a href={user.twitter.toUrl()}><i className="fab fa-twitter"/></a>}
            {user.instagram && <a href={user.instagram.toUrl()}><i className="fab fa-instagram"/></a>}
            {user.linkedin && <a href={user.linkedin.toUrl()}><i className="fab fa-linkedin"/></a>}
            {user.medium && <a href={user.medium.toUrl()}><i className="fab fa-medium"/></a>}
            {user.telegram && <a href={user.telegram.toUrl()}><i className="fab fa-telegram"/></a>}
        </div>
    );
};
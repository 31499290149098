import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateInvestor} from "../../../actions/Login";
import {updateMenu} from "../../../actions/menu";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import RegAvatar from './../../controls3.0/regAvatar/regAvatar';
import ic_picture from "../../../images/svg/ic_picture.svg";
import ModalBackgroundImage from '../../modal/ModalBackgroundImage'
import history from "../../../history";
import config from '../../../config';


class Step2 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            background: {}
        }
    }

    componentDidMount () {

    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({ investor: {...this.state.investor, [name]: value} })
    }


    update = (nextStep, onlyStep) => {
        if(onlyStep){
            history.push('/start_investor')
        } else {
            let data = new FormData(),
                user = this.state.user;

            if(user.backgroundImageId) data.append('backgroundImageId', user.backgroundImageId);
            if (this.state.background.name) data.append('background', this.state.background)
            data.append("registrationStep", nextStep);
            data.append("role", 'investor');

            let avatarState = this.avatarEditor.state;
            if (avatarState.image) {
                this.avatarEditor.editor.getImageScaledToCanvas().toBlob(function (blob) {
                    data.append("avatar", blob, "imageFilename.png");
                    this.sendToServer(data, onlyStep, nextStep)
                }.bind(this), 'image/jpeg', 1);
            } else {
                this.sendToServer(data, onlyStep, nextStep)
            }
        }
    }

    sendToServer = (data, onlyStep, nextStep) => {
        if(!onlyStep) this.setState({loading: true})
        this.props.updateInvestor(data , onlyStep, nextStep)
            .then(r => {
                this.props.updateMenu({type: 'update'})
            })
            .catch(err => {
                this.setState({loading: false})
            })
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }
    handleCloseModal = () => {
        this.setState({showModal: false})
    }

    setBG = (item) => {
        if (item.name) {
            this.setState({background: item})
        } else {
            this.setState({user: {...this.state.user, backgroundImageId: item}});
            this.setState({background: {}})
        }
        this.handleCloseModal()
    }

    validateNext = () => {
        const {user, background, hasLogo} = this.state;
        const ifLogo = (this.logoEditor && this.logoEditor.state.image) || user.logoFileId || hasLogo;
        const ifBg = user.backgroundImageId || background.preview;
        return !!ifLogo && !!ifBg;
    }

    render () {
        let {user, background} = this.state


        return (
            <React.Fragment>
                <div className="step-1 is-startup">


                    <div className="container-full-reg padding-reg">
                        <div className="container-step-2-reg">
                            <p className="text-h1 color-text-reg-black margin-0 shell-margin-bottom-15">
                                Your profile
                            </p>

                            <p className="text-button color-text-reg-black margin-0 shell-margin-bottom-20">
                                Make your profile notable.
                            </p>
                            <p className="text-button-2 color-text-reg-black margin-0 shell-margin-bottom-15" style={{maxWidth:'590px'}}>
                                Adding your photo will help other investors, startups and experts find you faster
                                so that you can discuss any urgent matter or establish business relations.
                            </p>




                            <div className="block-cart-load-img-reg-expert">
                                <div className="block-left-cart-load-img-reg-expert">
                                    <p className="title-top-page-reg-expert text-h5 left-text color-text-reg-black shell-margin-bottom-10">
                                        Your photo
                                    </p>
                                    <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-black shell-margin-bottom-10" style={{maxWidth:'270px'}}>
                                        Are there any requirements? Actually no.
                                        Just a photo of you.
                                    </p>
                                </div>
                                <div className="block-right-cart-load-img-reg-expert test-fix">
                                    <RegAvatar
                                        fileId={user.logoFileId}
                                        title={user.logoFileId ? 'Add new' : 'Add photo'}
                                        description='Not less than 180*180 pixels'
                                        onLoadSuccess={() => this.setState({hasLogo: true})}
                                        ref={(avatarEditor) => {
                                            this.avatarEditor = avatarEditor
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="block-cart-load-img-reg-expert">
                                <div className="block-left-cart-load-img-reg-expert">
                                    <p className="title-top-page-reg-expert text-h5 left-text color-text-reg-black shell-margin-bottom-10">
                                        Background photo
                                    </p>
                                    <p className="title-top-page-reg-expert text-sub-title left-text color-text-reg-black shell-margin-bottom-10" style={{maxWidth:'310px'}}>
                                        When it comes to stand out, the details matter.
                                        Photos help us tell the stories. <br/>
                                        Сhoose an image that represents what you do
                                        and who you are.
                                    </p>
                                </div>
                                <div className="block-right-cart-load-img-reg-expert">
                                    <div className="shell-load-img-cart">
                                        <div className="block-load-img big">
                                            {!background.preview && !user.backgroundImageId &&
                                            <img className="logo-load-img" src={ic_picture} alt=""/>}

                                            {background.preview &&
                                            <div className={'bgc-img-3'}
                                                 style={{
                                                     background: `url(${background.preview}) no-repeat`,
                                                     backgroundSize: 'cover'
                                                 }}>
                                            </div>
                                            }
                                            {/*{background.preview && <img className="load-img" src={background.preview} alt=""/>}*/}

                                            {!background.preview && user.backgroundImageId && <img className='load-img'
                                                                                                   src={`${config.proxy}/file/${user.backgroundImageId}/view`}
                                                                                                   alt={user.backgroundImageId}/>}

                                            <div className="block-button-load-img">
                                                <p className="title-button-load-img"
                                                   onClick={() => this.handleShowModal()}>Add photo</p>
                                            </div>
                                        </div>
                                        <p className="title-load-img">
                                            Not less than <br/>
                                            851 pixels width and 315 pixels height
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>





                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(1, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Done'}
                                EventOnClick={() => this.update(3)}
                                paddingButton={'93'}
                                disabled={!this.validateNext()}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
                <ModalBackgroundImage
                    showModal={this.state.showModal}
                    handleCloseModal={this.handleCloseModal}
                    setBG={(item) => this.setBG(item)}
                    bgId={user.backgroundImageId}
                    type={1}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step2.propTypes = {
    updateInvestor: PropTypes.func.isRequired,
    updateMenu: PropTypes.func,
}

export default connect(mapStateToProps, {updateInvestor, updateMenu})(Step2)
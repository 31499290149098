import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import './ListTitleDescriptionMore.css'


export default class ButtonLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCloseList: false
        }
        this.myRef = React.createRef();
        this.handleClock = this.handleClock.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClock)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClock)
    }

    handleClock(event) {
        if (!this.myRef || !this.myRef.current) return
        if (!this.myRef.current.contains(event.target)) {
            this.setState({
                openCloseList: false,
            });
        } else {
            this.setState({
                openCloseList: !this.state.openCloseList,
            });
        }
    }


    render() {
        let {
            data, //[{Mail:'',Pos:''}, {Mail:'',Pos:''}]
            onChange,
            titleMore,
            viewCountItem,
            onClick,
        } = this.props;

        let countItem = -1;
        let items_first = this.props.data.map((item, key) => {
            countItem++;
            if (countItem < viewCountItem) {
                return (
                    <div className="block-item-title-description-more" key={key} >
                        <div className="block-left-item-title-description-more" onClick={() => this.props.onClick(item.id)}>
                            <p className="title-item-title-description-more">
                                {item.title}
                            </p>
                            <p className="description-item-title-description-more">
                                {item.description}
                            </p>
                        </div>

                        <div className="block-button-delete-title-description-more"
                             onClick={() => this.props.delete(key)}/>
                    </div>
                );
            }


        })
        countItem = -1;
        let items_last = this.props.data.map((item, key) => {
            countItem++;
            if (countItem >= viewCountItem) {
                return (
                    <div className="block-item-title-description-more" key={key} >
                        <div className="block-left-item-title-description-more" onClick={() => this.props.onClick(item.id)}>
                            <p className="title-item-title-description-more">
                                {item.title}
                            </p>
                            <p className="description-item-title-description-more">
                                {item.description}
                            </p>
                        </div>
                        <div className="block-button-delete-title-description-more"
                             onClick={() => this.props.delete(key)}/>
                    </div>
                );
            }

        })

        return (
            <div className="block-list-title-description-more">
                <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={window.innerHeight - 200}>
                    <div className="block-list-first-item-title-description-more">
                        {items_first}
                    </div>


                    {this.props.data.length > viewCountItem &&
                    <div
                        className={this.state.openCloseList ? "block-list-last-item-title-description-more active" : "block-list-last-item-title-description-more"}
                    >
                        <div className="block-button-title-description-more" ref={this.myRef}>
                            <p className="title-button-title-description-more">
                                {this.state.openCloseList ? `Show less (${this.props.data.length - viewCountItem})` : `Show all experiences (${this.props.data.length - viewCountItem})`}
                            </p>
                            <div className="block-button-open-close-title-description-more"/>
                            <div className="block-no-item-title-description-more"></div>
                            <div className="block-no-item-title-description-more"></div>
                        </div>
                        <div className="block-list-item-anim-title-description-more"
                             style={{height: this.state.openCloseList ? 'auto' : '0px'}}
                        >
                            {items_last}
                        </div>
                    </div>
                    }

                </Scrollbars>
            </div>
        );
    }
}





import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment/moment";
import Header from "../header";
import Footer from "../footer/Footer";
import { generateEvent } from "../../actions/amplitude";
import history from "../../history";
import "./style.scss";
import "../test-controls/Page2.0/css/style.css";
import "../test-controls/Page2.0/css/StartPage.css";
import "./home.css";
import "../controls2.0/ProgressBar2.0/ProgressBar2.0.css";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../test-controls/newStartPage/startStyle.css";

import { logout, tokenLogin, updateUser } from "../../actions/Login";
import { updateMarket } from "../../actions/Market";

import startup_jedi from "../../images/ecosystem/startup-jedi.svg";
import founders_club from "../../images/ecosystem/founders-club.svg";
import investclub_vc from "../../images/ecosystem/investclub-vc.svg";

import Input from "../controls3.0/input/Input";
import ButtonBlue from "../controls3.0/button/ButtonBlue";
import axios from "axios";

import "./styleMarcketplace.css";
import Baner from "../../images/ilustaciya/Baner-rocket_dao.png";

import { getListIndustries } from "../../actions/Project";

import marketplaceBanner1 from "../../images/marketplace/ecosystem-members-link-img1.png";
import marketplaceBannerMob1 from "../../images/marketplace/ecosystem-members-link-img1-mob.png";
import marketplaceBanner2 from "../../images/marketplace/ecosystem-members-link-img2.png";
import marketplaceBannerMob2 from "../../images/marketplace/ecosystem-members-link-img2-mob.png";
import marketplaceBanner3 from "../../images/marketplace/ecosystem-members-link-img3.png";
import marketplaceBannerMob3 from "../../images/marketplace/ecosystem-members-link-img3-mob.png";
import marketplaceBanner4 from "../../images/marketplace/ecosystem-members-link-img4.png";
import marketplaceBannerMob4 from "../../images/marketplace/ecosystem-members-link-img4-mob.png";

import { VideoPopUp } from "../v3/videoPopupPage";
import { CartPopUp } from "../v3/startupPopupPage";
import { getNotification } from "../../actions/api";

import MetaTags from "react-meta-tags";
import config from "../../config";
import { StartupListCart } from "../v4/startupListCart";
import { StartupListScoreItem } from "../v4/startupListScoreItem";
import { StartupListPeItem } from "../v4/startupListPeItem";
import { FundListItem } from "../v4/fundListItem";
import { AngelNetworkListItem } from "../v4/angelNetworkListItem";
import { InvestorListItem } from "../v4/investorListItem";
import { ExpertListItem } from "../v4/expertListItem";
import { AcceleratorListItem } from "../v4/acceleratorListItem";
import { Row, Container } from "../v4/Grid";
import { loader } from "graphql.macro";
import ApolloClient from "apollo-boost";
import { Query } from "react-apollo";
import { StartupListVideoPitchItem } from "../v4/startupListVideoPitchItem";
import { isExist } from "../../utils/helper";
import { getViewStatus } from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";

Number.prototype.toMoneyFormat2 = function () {
  if (this >= 1000000) {
    return (this / 1000000).toFixed(2).replace(/\.00$/, "") + "M";
  }

  if (this >= 1000) {
    return (this / 1000).toFixed(2).replace(/\.00$/, "") + "K";
  }

  return this || 0;
};

String.prototype.descriptionFormat = function (len = 125) {
  if (this.length > len) {
    return this.substring(0, len) + "...";
  } else {
    return this;
  }
};

const subscribeTypes = {
  1: "startup",
  2: "investor",
  3: "expert",
  4: "other",
  5: "metrics",
  6: "newsletter",
};

const expertParams = {
  23: {
    //
    7: {
      //Team
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
    4: {
      //Tokenomics
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 0,
        Since: 0,
      },
      line: 100,
    },
  },
  69: {
    // Дробышевский
    10: {
      //id	10 title	Market
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
    2: {
      //id	2 title	Marketing and PR
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  28: {
    // Koltun
    3: {
      //id	3 title	Technologies
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  25: {
    // Mikhailov
    8: {
      //id	8 title	Risk
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
    30: {
      //id	8 title	fina
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  31: {
    // Kuryan
    9: {
      //id	9 title	Product
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
    1: {
      //id	9 title	Product
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  216: {
    // Sanchuk
    6: {
      //id	6 title	Legal
      Expert: {
        Projects_evaluated: 1,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  30: {
    // Grablevski
    4: {
      //id	6 title	Legal
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  34: {
    // Inga
    4: {
      //id	6 title	Legal
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 0,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 1,
        Since: 0,
      },
      line: 100,
    },
  },
  221: {
    // Torri
    1: {
      //id	1 title	Business model
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 1,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 0,
        Methodologies_in_use: 0,
        Since: 0,
      },
      line: 10,
    },
  },
  252: {
    // Skvorc
    9: {
      //id	1 title	Business model
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 1,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 0,
        Methodologies_in_use: 0,
        Since: 0,
      },
      line: 10,
    },
  },
  327: {
    // Dominik Franek
    10: {
      //id
      Expert: {
        Projects_evaluated: 0,
        Platform_task: 1,
        Since: 0,
      },
      Supervisor: {
        Experts_evaluated: 0,
        Since: 0,
      },
      Methodologist: {
        Methodologies_developed: 1,
        Methodologies_in_use: 0,
        Since: 0,
      },
      line: 100,
    },
  },
};

const GET_SEARCH = loader("../../graphql/marketplace.graphql");
const GET_STARTUP = loader("../../graphql/startupList.graphql");

const client = new ApolloClient({
  uri: `${config.proxy}/api`,
  request: async (operation) => {
    const token = await localStorage.getItem("RocketToken");
    if (token) {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    }
  },
});

const BlockTitle = ({ h2, h3 }) => {
  return (
    <React.Fragment>
      <h2 style={{ fontSize: "28px", margin: "20px 0" }}>{h2}</h2>
      <h3>{h3}</h3>
    </React.Fragment>
  );
};

class Mp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showModal: false,
      listIndustries: [],
      expertsId: [618, 700, 131, 625],
      parentDOM: [],
      showMProjectCreate: false,
      showModalFundCreate: false,
      showModalTokenSale: false,
      landingType: "Fund",
      activeDot: "2",
      stateClickDots: false,
      openPopup: "0",
      savedEmail: localStorage.savedEmail || null,
      savedEmail2: localStorage.savedEmail2 || null,
      isSubscribe: false,
      isSubscribe2: !!localStorage.savedEmail2,
      currentEmail: "",
      error: "",
      currentDot: "1",
      statistic: {}, //{ experts: 14, projects: 5, investors: 12 }
      showVideoPopup: false,
      showCartPopup: false,
      showVideoId: null,
      cartId: null,
      CartPopUpType: null,
      expertPage: 0,
      startupPage: 0,
      angelPage: 0,
      fundPage: 0,
      acceleratorPage: 0,
      startupVideoPage: 0,
      startupPreliminaryEvaluationPage: 0,
      showMoreExperts: true,
      showMoreStartups: true,
      showMoreStartupsVideo: true,
      showMoreInvestors: true,
      showMoreFunds: true,
      showMoreAccelerators: true,
      showMoreStartupsPreliminaryEvaluation: true,
      notificationsLog: [],
      blogList: [],
      showLoginPopup: "hide",
      storiesList: [],
    };
    this.activeDot = this.activeDot.bind(this);
    this.StateClickDot = this.StateClickDot.bind(this);
    this.openCloseForm = this.openCloseForm.bind(this);
  }

  getLimit() {
    return {
      startup: 6,
      startup_pe: 3,
      startup_dd: 3,
      startup_popular: 3,
      fund: 3,
      angel_investor: 3,
      expert: 3,
      accelerator: 3,
    };
  }

  getParamVal(expertId, paramId, type, ach, line) {
    try {
      if (!line) return expertParams[expertId][paramId][type][ach];
      else return expertParams[expertId][paramId].line;
    } catch (e) {
      return 0;
    }
  }

  componentDidMount() {
    if (localStorage.RocketToken) {
      this.props.tokenLogin(localStorage.RocketToken);
    }

    this.getNotification(9986400000);

    getListIndustries()
      .then((res) => {
        // console.log(res.data)
        this.setState({ listIndustries: res.data });
      })
      .catch((error) => {
        //alert('listIndustries e', error);
        // console.log(error);
        this.setState({ loading: false });
      });

    if (this.state.savedEmail) {
      this.checkSubscribe(this.state.savedEmail);
    }

    axios.get("/v2/default/statistic_total").then((r) => {
      this.setState({ statistic: r.data });
    });

    axios.get("/v1/blog/last").then((r) => {
      this.setState({ blogList: r.data });
    });

    let arr_link = [
      `https://startupjedi.vc/content/anti-dating-what-waka-project-raised-investment-and-what-wrong-existing-dating-apps`,
      `https://startupjedi.vc/content/117-conversion-meetings-investments-pre-seed-startup-edudo-raised-170000`,
      `https://startupjedi.vc/content/easy-bloggers-raised-investments-investclubvc-and-became-product-month-product-hunt`,
      `https://startupjedi.vc/content/belarusian-startup-gamepad-raised-65-000-investclubvc-members-volat-capital-business-angels`,
    ];

    axios
      .get("/v1/blog", { params: { href: arr_link } })
      .then((r) => this.setState({ storiesList: r.data }));

    this.newAnimateImage();

    try {
      if (
        isExist(() => this.props.location.state) &&
        isExist(() => this.props.location.state.startup)
      ) {
        this.togglePopupCart(
          this.props.location.state.startup,
          "startup",
          {},
          "New Startups"
        );
        history.replace({ ...history.location, state: undefined });
      }

      const com = this.props.location.state;

      if (com && com.to === "/") {
        if (com.id && com.type && com.f) {
          localStorage.removeItem("commandProps");
          history.replace({ ...history.location, state: undefined });

          if (com.f === "tpc") {
            this.togglePopupCart(com.id, com.type, null);
          }

          if (com.f === "tp") {
            this.togglePopup(com.id, null);
          }

          if (com.f === "g") {
            this.goTo(com.to, null);
          }
        }
      }
    } catch (e) {
      history.replace({ ...history.location, state: undefined });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isExist(() => prevProps.user.id) &&
      isExist(() => this.props.user.id)
    ) {
      if (
        localStorage.commandProps &&
        !this.state.showCartPopup &&
        !this.state.showVideoPopup
      ) {
        const com = JSON.parse(localStorage.commandProps);
        if (com.to === "/") {
          if (com.id && com.type && com.f) {
            localStorage.removeItem("commandProps");

            if (com.f === "tpc") {
              this.togglePopupCart(com.id, com.type, null);
            }

            if (com.f === "tp") {
              this.togglePopup(com.id, null);
            }
          }
        }
      }
    }

    console.log(this.props);

    if (isExist(() => this.props.Market.object.type) === "update") {
      if (this.refetch) {
        this.refetch();
      }
      this.props.updateMarket(null);
    }
  }

  getNotification = (period) => {
    getNotification(period)
      .then((res) => {
        function compare(a, b) {
          if (moment(a.createdAt).unix() < moment(b.createdAt).unix()) {
            return 1;
          }
          if (moment(a.createdAt).unix() > moment(b.createdAt).unix()) {
            return -1;
          }
          return 0;
        }

        const notificationsLog = res.data.sort(compare);

        this.setState({ notificationsLog: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openCloseForm(key) {
    switch (key) {
      case "1": {
        generateEvent("start_registration", {
          role: "startup",
          source: "animation",
        });
        //history.push('/start_reg?type=3');
        history.push("/start_reg?type=8");
        break;
      }
      case "2": {
        generateEvent("start_registration", {
          role: "investor",
          source: "animation",
        });
        //history.push('/start_investor');
        history.push("/start_reg?type=8");
        break;
      }
      case "3": {
        generateEvent("start_registration", {
          role: "expert",
          source: "animation",
        });
        //history.push('/start_reg?type=2');
        history.push("/start_reg?type=8");
        break;
      }
    }
  }

  closeForm(key) {
    this.setState({ openPopup: key });
  }

  togglePopup(id, e, type2) {
    if (e && e.preventDefault) e.preventDefault();

    if (!getViewStatus() && id) {
      const commandProps = {
        to: "/",
        id: id,
        type: "video",
        f: "tp",
      };

      localStorage.removeItem("rFrom");
      localStorage.removeItem("referer");
      localStorage.removeItem("referer_startup");

      localStorage.setItem("commandProps", JSON.stringify(commandProps));

      this.setState({ showLoginPopup: "popup" });
    } else {
      this.setState({
        showVideoId: id,
        CartPopUpType2: type2,
        showVideoPopup: !this.state.showVideoPopup,
      });
    }
  }

  togglePopupCart(id, type, e, type2) {
    if (e && e.preventDefault) e.preventDefault();

    if (!getViewStatus() && id) {
      const commandProps = {
        to: "/",
        id: id,
        type: type,
        f: "tpc",
      };

      localStorage.removeItem("rFrom");
      localStorage.removeItem("referer");
      localStorage.removeItem("referer_startup");

      localStorage.setItem("commandProps", JSON.stringify(commandProps));

      this.setState({ showLoginPopup: "popup" });
    } else {
      this.setState({
        cartId: id,
        CartPopUpType: type,
        CartPopUpType2: type2,
        showCartPopup: !this.state.showCartPopup,
      });
    }
  }

  goTo(url, e) {
    if (e) {
      e.preventDefault();
    }

    if (!getViewStatus()) {
      const commandProps = {
        to: url,
        id: 0,
        type: "goto",
        f: "g",
      };

      localStorage.removeItem("rFrom");
      localStorage.removeItem("referer");
      localStorage.removeItem("referer_startup");

      localStorage.setItem("commandProps", JSON.stringify(commandProps));

      this.setState({ showLoginPopup: "popup" });
    } else {
      window.open(url, "_blank");
    }
  }

  newAnimateImage() {
    let active = 1;
    let timer = setInterval(() => {
      this.setState({ activeDot: active.toString() });
      active++;
      if (active === 4) {
        active = 1;
      }
      // clearInterval(timer);
    }, 8000);
  }

  activeDot(e, v2) {
    this.setState({
      activeDot: e,
    });

    if (v2) this.setState({ currentDot: v2 });
  }

  StateClickDot() {
    this.setState({
      stateClickDots: true,
    });
  }

  handleCloseModal = () => {
    this.setState({
      showMProjectCreate: false,
      showLoginForm: false,
      showModalFundCreate: false,
      showModalTokenSale: false,
      forProject: false,
      forExpert: false,
    });
  };

  resetItem = () => {
    this.setState({ showLoginForm: false });
  };

  setCurrentEmail = (e) => {
    const currentEmail = e.target.value.toLowerCase();
    this.setState({ currentEmail: currentEmail });
    this.validateEmail(currentEmail);
  };

  validateEmail = (email) => {
    const regexp = /\S+@\S+\.\S+/;
    if (regexp.test(email)) {
      this.setState({ error: "" });
      return true;
    } else {
      this.setState({ error: "Not valid email" });
      return false;
    }
  };

  checkSubscribe = (email) => {
    axios.post("/v1/users/subscribe/check", { email: email }).then((res) => {
      this.setState({ isSubscribe: res.data.isSubscribe });
    });
  };

  subscribe = (type) => {
    const email = this.state.currentEmail;

    if (this.validateEmail(email)) {
      axios
        .post(`/v1/users/subscribe/${type}`, { email: email })
        .then((res) => {
          if (res.data.status === 400) {
            if (type === "metrics") {
              localStorage.setItem("savedEmail2", email);
              this.setState({
                isSubscribe2: true,
                savedEmail2: email,
                currentEmail: "",
              });
            } else {
              localStorage.setItem("savedEmail", email);
              this.setState({
                isSubscribe: true,
                savedEmail: email,
                currentEmail: "",
              });
            }
          } else {
            if (type === "metrics") {
              localStorage.setItem("savedEmail2", email);
              this.setState({
                isSubscribe2: true,
                savedEmail2: email,
                currentEmail: "",
              });
            } else {
              localStorage.setItem("savedEmail", email);
              this.setState({
                isSubscribe: true,
                savedEmail: email,
                currentEmail: "",
              });
            }
          }
        });
    }
  };

  scrollTo = (id) => {
    var element = document.getElementById(id);
    element.scrollIntoView(false);
  };

  render() {
    let user = this.props.user || {};
    const { statistic, listIndustries } = this.state;
    let startupList = [],
      startupListScore = [],
      startupListPreliminaryEvaluation = [],
      fundList = [],
      investorList = [],
      startupListVideo = [],
      expertList = [],
      startupListPopular = [],
      acceleratorList = [];

    if (!listIndustries) {
      listIndustries.push([]);
    }

    const fundType = {
      simple: "Fund",
      angels: "Angel Network",
    };

    const fundSpType = {
      simple: "fund",
      angels: "angel",
    };

    const mainBlog = this.state.blogList[0] || {};
    const blogList = this.state.blogList.slice(1, 4) || [];

    const mainHrefStories = `https://startupjedi.vc/content/anti-dating-what-waka-project-raised-investment-and-what-wrong-existing-dating-apps`;
    const mainStories = this.state.storiesList.find(el => el.href === mainHrefStories) || {};
    const storiesList = this.state.storiesList.filter(el => el.href !== mainHrefStories) || [];

    const setting = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 3000,
    };

    return (
      <React.Fragment>
        <MetaTags>
          <title>Rocket DAO - uniting startups, investors and experts</title>
          <meta
            property="og:title"
            content="Rocket DAO - uniting startups, investors and experts"
          />
          <meta
            name="description"
            content="Rocket DAO connects digital startups and investors under the supervision of an independent expert community."
          />
          <meta
            property="og:description"
            content="Rocket DAO connects digital startups and investors under the supervision of an independent expert community."
          />
        </MetaTags>

        <Header user={user} />

        <Container mod={"-fluid"} className={"fullscreen marketplace"}>
          <section className="ecosystem-section">
            <h1 className="ecosystem-section-title">
              Rocket DAO is a startup ecosystem
            </h1>
            <p className="ecosystem-section-text">
              We help startups grow and raise investments thanks to our
              ecosystem elements and their close interconnetion:
            </p>
            <div className="ecosystem-block">
              <div className="ecosystem-elem">
                <div className="ecosystem-elem-inner">
                  <div className="ecosystem-elem-img-block">
                    <img
                      className="ecosystem-elem-img"
                      width="126"
                      height="81"
                      src={startup_jedi}
                      alt="Startup Jedi"
                    />
                  </div>
                  <p className="ecosystem-elem-text">Media for startupers</p>
                  <div className="ecosystem-elem-btn-block">
                    <a
                      className="ecosystem-elem-btn"
                      target="_blank"
                      href="http://startupjedi.vc/"
                    >
                      Go to website
                    </a>
                    <a
                      className="ecosystem-elem-link"
                      target="_blank"
                      href="https://www.facebook.com/StartupJedi"
                    >
                      Subscribe
                    </a>
                  </div>
                </div>
              </div>
              <div className="ecosystem-elem">
                <div className="ecosystem-elem-inner">
                  <div className="ecosystem-elem-img-block">
                    <img
                      className="ecosystem-elem-img"
                      width="116"
                      height="39"
                      src={founders_club}
                      alt=""
                    />
                  </div>
                  <p className="ecosystem-elem-text">Startup studio</p>
                  <div className="ecosystem-elem-btn-block">
                    <a
                      className="ecosystem-elem-btn"
                      target="_blank"
                      href="https://t.me/rocketdaobot/?start=am"
                    >
                      Apply to join
                    </a>
                    <a
                      className="ecosystem-elem-link"
                      target="_blank"
                      href="https://foundersclub.vc/"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div className="ecosystem-elem">
                <div className="ecosystem-elem-inner">
                  <div className="ecosystem-elem-img-block">
                    <img
                      className="ecosystem-elem-img"
                      width="162"
                      height="19"
                      src={investclub_vc}
                      alt=""
                    />
                  </div>
                  <p className="ecosystem-elem-text">Business angels network</p>
                  <div className="ecosystem-elem-btn-block">
                    <a
                      className="ecosystem-elem-btn"
                      target="_blank"
                      href="https://t.me/rocketdaobot/?start=am"
                    >
                      Apply to join
                    </a>
                    <a
                      className="ecosystem-elem-link"
                      target="_blank"
                      href="https://investclub.vc/"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div className="ecosystem-elem">
                <div className="ecosystem-elem-inner">
                  <div className="ecosystem-elem-img-block">
                    <p className="ecosystem-elem-img-text">Fund (soon)</p>
                  </div>
                  <p className="ecosystem-elem-text">Early stage VC fund</p>
                  <div className="ecosystem-elem-btn-block">
                    <a
                      className="ecosystem-elem-btn"
                      href="mailto:yo@investclub.vc?subject=Fund partnership"
                    >
                      Become a partner
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="industries-section">
            <div className="industries-section-block">
              <div className="industries-section-info">
                <h2 className="industries-section-title">
                  <span>WHAT WE LOVE</span>
                </h2>
                <p
                  className="industries-section-text"
                  style={{ color: "#0ECFAA" }}
                >
                  We want to help startups grow in the niches and spheres that
                  we are experienced in.
                </p>
              </div>
              <div className="industries-section-tags">
                <span className="industries-section-tag">Software</span>
                <span className="industries-section-tag">Global Market</span>
                <span className="industries-section-tag">Early stage</span>
                <span className="industries-section-tag turquoise">
                  Education
                </span>
                <span className="industries-section-tag turquoise">
                  Entertainment
                </span>
                <span className="industries-section-tag turquoise">
                  Messengers & Communities
                </span>
                <span className="industries-section-tag turquoise">Bots</span>
                <span className="industries-section-tag turquoise">
                  Mental health
                </span>
                <span className="industries-section-tag turquoise">
                  Influencers
                </span>
                <span className="industries-section-tag turquoise">
                  Game mechanics
                </span>
                <span className="industries-section-tag turquoise">
                  Tokenomics
                </span>
                <span className="industries-section-tag turquoise">
                  Intelligence
                </span>
              </div>
            </div>
          </section>

          <Query
            query={GET_STARTUP}
            variables={{
              type: "all_startups",
              params: { startup_ids: ["1117", "704", "624"] },
              limit: {},
            }}
            client={client}
            fetchPolicy={"cache-first"}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data, refetch, fetchMore, networkStatus }) => {
              this.data = data;
              this.refetch = refetch;

              if (error) return null;

              if (isExist(() => data.search)) {
                if (data.search.startupList)
                  startupList = data.search.startupList.rows.map(
                    (item, key) => {
                      console.log(item);
                      return (
                        <StartupListCart
                          key={key}
                          item={item}
                          href={`/startup/${item.id}`}
                          listIndustries={listIndustries}
                          onClick={(e) =>
                            this.togglePopupCart(
                              item.id,
                              "startup",
                              e,
                              "New Startups"
                            )
                          }
                        />
                      );
                    }
                  );
              }

              if (
                !isExist(() => data.search) ||
                (loading && networkStatus !== 3 && networkStatus !== 7)
              )
                return null;
              return (
                <React.Fragment>
                  {!!startupList.length && (
                    <Container id="startups">
                      <BlockTitle
                        h2={"Fundraising now"}
                        h3={"Be the first to discover rising stars!"}
                      />
                      <Row>{startupList}</Row>

                      {/* <p
                        className="more-items"
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/search/all_startups",
                            state: { noOpen: true },
                          })
                        }
                      >
                        More startups
                        <i className="far fa-angle-right" />
                        <span
                          className="new-black-tool-tip"
                          data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups at the idea stage (projects with an idea and literally nothing more), whereas 10 goes to startups at the B Series round and higher. "
                        ></span>
                      </p> */}
                    </Container>
                  )}
                </React.Fragment>
              );
            }}
          </Query>

          <Container className={"mt-60"}>
            <div className="block-list-articles">
              <p className="title-latest-articles">Success stories</p>
              <div className="latest-articles-block">
                <div className="block-big-post-blog">
                  <a href={mainStories.href} target={"_blank"}>
                    <div className="block-left-big-post-blog">
                      <div
                        className="bg-big-post-blog"
                        style={{ backgroundImage: `url(${mainStories.img})` }}
                      />
                    </div>
                    <div className="block-right-big-post-blog">
                      <p className="date-big-post-blog">
                        {moment(mainStories.date).format("DD MMM, YYYY")}
                      </p>
                      <p className="title-big-post-blog">{mainStories.title}</p>
                      <p className="description-big-post-blog">
                        {isExist(
                          () => mainStories.shortDescription.length > 210
                        )
                          ? mainStories.shortDescription.slice(0, 200).trim() +
                            "..."
                          : mainStories.shortDescription}
                      </p>
                    </div>
                  </a>
                </div>

                <Row>
                  {storiesList.map((o, i) => (
                    <div className={`col-12 col-md-4`}>
                      <div className={`bottom-post`}>
                        <a href={o.href} target={"_blank"}>
                          <div
                            className="bottom-post-img"
                            style={{ backgroundImage: `url(${o.img})` }}
                          />
                          <div className="date-title">
                            {moment(o.date).format("DD MMM, YYYY")}
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </Row>

                <div className="latest-articles-block-button">
                  <ButtonBlue
                    TitleButton={" More stories"}
                    EventOnClick={() =>
                      window.open(
                        "https://startupjedi.vc/ru/ROCKET_DAO",
                        "_blank"
                      )
                    }
                    paddingButton={"0"}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </Container>

          {/*<section className="success-stories-slider-block">
                        <h2 className="success-stories-slider-title">Success stories</h2>
                        <Slider{...setting}>
                            <a href="/startup/586" target="_blank" className="success-stories-slider-elem">
                                <img className="success-stories-slider-img" src={successStory1} alt="Caer Sidi"/>
                            </a>
                            <a href="/startup/624" target="_blank" className="success-stories-slider-elem">
                                <img className="success-stories-slider-img" src={successStory2} alt="GamePad"/>
                            </a>
                            <a href="/startup/704" target="_blank" className="success-stories-slider-elem">
                                <img className="success-stories-slider-img" src={successStory3} alt="Easy Bloggers"/>
                            </a>
                            <a href="/startup/708" target="_blank" className="success-stories-slider-elem">
                                <img className="success-stories-slider-img" src={successStory4} alt="Perk"/>
                            </a>
                            <a href="/startup/829" target="_blank" className="success-stories-slider-elem">
                                <img className="success-stories-slider-img" src={successStory5} alt="EduDo"/>
                            </a>
                        </Slider>
                    </section>*/}

          <div
            className="block-top-title-button-marketplace mt-45"
            style={{ position: "relative", zIndex: "2" }}
          >
            <div className="block-top-marketplace">
              <a href="/" className="link-top-marketplace">
                rocketdao.io
              </a>
              <h2 className="title-top-marketplace">
                Rocket DAO is a venture community with hundreds of registered
                members
              </h2>
              <a
                href="https://rocketdao.io/start_reg?type=8"
                className="btn-top-marketplace"
              >
                Join now!
              </a>
            </div>
            <div className="block-list-button-top-marketplace">
              {/*<a onClick={() => this.props.history.push({pathname: '/search/all_startups', state: {noOpen: true}})}>*/}
              <div className="block-item-button">
                <p className="title-item-button">
                  Startups
                  {statistic && (
                    <span className="right">{statistic.projects}</span>
                  )}
                </p>
              </div>
              {/*</a>*/}
              {/*<a onClick={() => this.props.history.push({pathname: '/search/expert', state: {noOpen: true}})}>*/}
              <div className="block-item-button">
                <p className="title-item-button">
                  Experts
                  {statistic && (
                    <span className="right">{statistic.experts}</span>
                  )}
                </p>
              </div>
              {/*</a>*/}
              {/*<a onClick={() => this.props.history.push({pathname: '/search/angel_investor', state: {noOpen: true}})}>*/}
              <div className="block-item-button">
                <p className="title-item-button">
                  Investors
                  {statistic && (
                    <span className="right">{statistic.investors}</span>
                  )}
                </p>
              </div>
              {/*</a>*/}
              {/*<a onClick={() => this.props.history.push({pathname: '/search/accelerator', state: {noOpen: true}})}>*/}
              <div className="block-item-button">
                <p className="title-item-button">
                  Accelerators
                  {statistic && (
                    <span className="right">{statistic.accelerators}</span>
                  )}
                </p>
              </div>
              {/*</a>*/}
            </div>
          </div>

          <Query
            query={GET_SEARCH}
            variables={{
              type: "marketplace",
              params: {},
              limit: this.getLimit(),
            }}
            client={client}
            fetchPolicy={"cache-first"}
            notifyOnNetworkStatusChange={true}
          >
            {({ loading, error, data, refetch, fetchMore, networkStatus }) => {
              this.data = data;
              this.refetch = refetch;

              if (error) return null;

              if (isExist(() => data.search)) {
                if (data.search.startupList)
                  startupList = data.search.startupList.rows.map(
                    (item, key) => (
                      <StartupListCart
                        key={key}
                        item={item}
                        href={`/startup/${item.id}`}
                        listIndustries={listIndustries}
                        onClick={(e) =>
                          this.togglePopupCart(
                            item.id,
                            "startup",
                            e,
                            "New Startups"
                          )
                        }
                      />
                    )
                  );

                if (data.search.startupListVideo)
                  startupListVideo = data.search.startupListVideo.rows.map(
                    (item, key) => (
                      <StartupListVideoPitchItem
                        key={key}
                        href={`/startup/${item.id}`}
                        item={item}
                        onClick={(e) =>
                          this.togglePopup(item.id, e, "Video pitches")
                        }
                      />
                    )
                  );

                if (data.search.startupListPopular)
                  startupListPopular = data.search.startupListPopular.rows.map(
                    (item, key) => (
                      <StartupListCart
                        key={key}
                        href={`/startup/${item.id}`}
                        item={item}
                        listIndustries={listIndustries}
                        onClick={(e) =>
                          this.togglePopupCart(
                            item.id,
                            "startup",
                            e,
                            "New Startups"
                          )
                        }
                      />
                    )
                  );
                if (data.search.startupListScore)
                  startupListScore = data.search.startupListScore.rows.map(
                    (item, key) => (
                      <StartupListScoreItem
                        key={key}
                        href={`/startup/${item.id}`}
                        item={item}
                        listIndustries={listIndustries}
                        onClick={(e) => this.goTo(`/startup/${item.id}`, e)}
                      />
                    )
                  );
                if (data.search.startupListPreliminaryEvaluation)
                  startupListPreliminaryEvaluation = data.search.startupListPreliminaryEvaluation.rows.map(
                    (item, key) => (
                      <StartupListPeItem
                        key={key}
                        href={`/startup/${item.id}`}
                        item={item}
                        listIndustries={listIndustries}
                        onClick={(e) => this.goTo(`/startup/${item.id}`, e)}
                      />
                    )
                  );
                if (data.search.fundAngelList)
                  fundList = data.search.fundAngelList.rows
                    .slice(0, 3)
                    .map((item, key) =>
                      item.fundType === "simple" ? (
                        <FundListItem
                          key={key}
                          item={item}
                          href={`/fund/${item.id}`}
                          listIndustries={listIndustries}
                          onClick={(e) =>
                            this.togglePopupCart(
                              item.id,
                              item.fundType === "simple" ? "fund" : "angel",
                              e,
                              "Venture Funds and Angel Networks"
                            )
                          }
                        />
                      ) : (
                        <AngelNetworkListItem
                          key={key}
                          href={`/angel_network/${item.id}`}
                          item={item}
                          listIndustries={listIndustries}
                          onClick={(e) =>
                            this.togglePopupCart(
                              item.id,
                              item.fundType === "simple" ? "fund" : "angel",
                              e,
                              "Venture Funds and Angel Networks"
                            )
                          }
                        />
                      )
                    );
                if (data.search.investorList)
                  investorList = data.search.investorList.rows.map(
                    (item, key) => (
                      <InvestorListItem
                        key={key}
                        href={`/investor/${item.id}`}
                        item={item}
                        listIndustries={listIndustries}
                        onClick={(e) =>
                          this.togglePopupCart(
                            item.id,
                            "investor",
                            e,
                            "Angel Investors"
                          )
                        }
                      />
                    )
                  );
                if (data.search.expertList)
                  expertList = data.search.expertList.rows.map((item, key) => (
                    <ExpertListItem
                      key={key}
                      href={`/expert/${item.id}`}
                      item={item}
                      listIndustries={listIndustries}
                      onClick={(e) =>
                        this.togglePopupCart(item.id, "expert", e, "Experts")
                      }
                    />
                  ));
                if (data.search.acceleratorList)
                  acceleratorList = data.search.acceleratorList.rows
                    .slice(0, 3)
                    .map((item, key) => (
                      <AcceleratorListItem
                        key={key}
                        href={`/accelerator/${item.id}`}
                        item={item}
                        listIndustries={listIndustries}
                        onClick={(e) =>
                          this.togglePopupCart(
                            item.id,
                            "accelerator",
                            e,
                            "Accelerators"
                          )
                        }
                      />
                    ));
              }

              if (
                !isExist(() => data.search) ||
                (loading && networkStatus !== 3 && networkStatus !== 7)
              )
                return null;
              return (
                <React.Fragment>
                  {!!startupList.length && (
                    <Container id="startups">
                      <BlockTitle
                        h2={"Startups"}
                        h3={"Be the first to discover rising stars!"}
                      />
                      <Row>{startupList}</Row>

                      {data.search.startupList.count > 3 && (
                        <p
                          className="more-items"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/search/all_startups",
                              state: { noOpen: true },
                            })
                          }
                        >
                          More startups
                          <i className="far fa-angle-right" />
                          <span
                            className="new-black-tool-tip"
                            data-title="We use an absolute 10-point scale to assess startups: 0 is given to startups at the idea stage (projects with an idea and literally nothing more), whereas 10 goes to startups at the B Series round and higher. "
                          ></span>
                        </p>
                      )}
                    </Container>
                  )}

                  {/*Banner Founders Club*/}
                  <Container className={"mt-60"}>
                    <a
                      href="https://t.me/rocketdaobot/?start=am"
                      target="_blank"
                    >
                      <picture>
                        <source
                          media="(min-width:768px)"
                          srcSet={marketplaceBanner1}
                        />
                        <img
                          className="marketplace-new-b-img marketplace-new-b-img-br10"
                          src={marketplaceBannerMob1}
                          alt="Flowers"
                        />
                      </picture>
                    </a>
                  </Container>
                  {/*/!*Banner startup pack*!/*/}
                  {/*<Container className={'mt-60 startup-pack-banner-container'}>*/}
                  {/*<Link to='/evaluation/landing/startup' target='_blank'>*/}
                  {/*<div className='img-wrapper'>*/}
                  {/*<div className='title'>Get <span>free services worth $6000</span> <br/> for your startup from our partners on:</div>*/}
                  {/*<Row className='details-wrapper m8'>*/}
                  {/*<div className='col-12 col-sm-4 col-md'>*/}
                  {/*<div className='details-block'>*/}
                  {/*<div className='sum-title'>$2 000</div>*/}
                  {/*<div className='descr'>Legal consultancy</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className='col-12 col-sm-4 col-md'>*/}
                  {/*<div className='details-block'>*/}
                  {/*<div className='sum-title'>$2 000</div>*/}
                  {/*<div className='descr'>Video production</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className='col-12 col-sm-4 col-md'>*/}
                  {/*<div className='details-block'>*/}
                  {/*<div className='sum-title'>$1 000</div>*/}
                  {/*<div className='descr'>Design and prototyping</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className='col-12 col-sm-4 col-md'>*/}
                  {/*<div className='details-block'>*/}
                  {/*<div className='sum-title'>$1 000</div>*/}
                  {/*<div className='descr'>Software development</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*<div className='col-12 col-lg-auto col-sm-4 last-col'>*/}
                  {/*<div className='details-block last-block'>*/}
                  {/*<div className='descr'>See the details</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*</Row>*/}
                  {/*</div>*/}
                  {/*</Link>*/}
                  {/*</Container>*/}

                  {/*Startups with Video Pitches*/}

                  {!!startupListVideo.length && (
                    <Container className={"mt-60"}>
                      <BlockTitle
                        h2={"Startups with Video Pitches"}
                        h3={
                          "See the list of featured startups which published their video presentations"
                        }
                      />

                      <Row>{startupListVideo}</Row>

                      {data.search.startupListVideo.count > 3 && (
                        <p
                          className="more-items"
                          onClick={() =>
                            this.props.history.push(
                              "/search/all_startups?vp=true",
                              { noOpen: true }
                            )
                          }
                        >
                          More startups with video pitches
                          <i className="far fa-angle-right" />
                        </p>
                      )}
                    </Container>
                  )}

                  {/*{!!startupListPopular.length && <Container className={'mt-60'}>*/}
                  {/*<BlockTitle*/}
                  {/*h2={'Popular startups'}*/}
                  {/*h3={'Startups with the greatest number of followers'}/>*/}

                  {/*<Row>{startupListPopular}</Row>*/}

                  {/*{data.search.startupListPopular.count > 6 &&*/}
                  {/*<p className="more-items" onClick={() => this.props.history.push({pathname: '/search/startup_popular', state: {noOpen: true}})}>*/}
                  {/*More popular startups<i className="far fa-angle-right"/>*/}
                  {/*</p>*/}
                  {/*}*/}
                  {/*</Container>*/}
                  {/*}*/}

                  {/*Banner viral*/}
                  <Container className={"mt-60"}>
                    <a href="https://rocketdao.io/startup_test" target="_blank">
                      <picture>
                        <source
                          media="(min-width:768px)"
                          srcSet={marketplaceBanner2}
                        />
                        <img
                          className="marketplace-new-b-img marketplace-new-b-img-br8 marketplace-new-b-img-sh"
                          src={marketplaceBannerMob2}
                          alt="Flowers"
                        />
                      </picture>
                    </a>
                  </Container>

                  {/*PRELIMINARY EVALUATION*/}

                  {/*{!!startupListPreliminaryEvaluation.length && <Container id="evaluation" className={'mt-60'}>*/}
                  {/*<BlockTitle*/}
                  {/*h2={'Startups with preliminary evaluation'}*/}
                  {/*h3={'Preliminary evaluation of startups conducted by Rocket DAO expert community'}/>*/}
                  {/*<Row>{startupListPreliminaryEvaluation}</Row>*/}

                  {/*{data.search.startupListPreliminaryEvaluation.count > 3 &&*/}
                  {/*<p className="more-items" onClick={() => this.props.history.push({ pathname: '/search/startup_pe', state: {noOpen: true} })}>*/}
                  {/*All startups with preliminary evaluation<i className="far fa-angle-right"/>*/}
                  {/*</p>*/}
                  {/*}*/}
                  {/*</Container>}*/}

                  {/*DUE DILIGENCE*/}

                  {/*{!!startupListScore.length && <Container className={'mt-60'}>*/}
                  {/*<BlockTitle*/}
                  {/*h2={'Startups with Due Diligence'}*/}
                  {/*h3={'See the list of featured startups which published their business plans'}/>*/}

                  {/*<Row>{startupListScore}</Row>*/}

                  {/*{data.search.startupListScore.count > 3 &&*/}
                  {/*<p className="more-items" onClick={() => this.props.history.push({pathname: '/search/startup_dd', state: {noOpen: true}})}>*/}
                  {/*All startups with Due Diligence<i className="far fa-angle-right"/>*/}
                  {/*</p>*/}
                  {/*}*/}
                  {/*</Container>}*/}
                  {/*DUE DILIGENCE*/}

                  {/*<Container>*/}
                  {/*<div className="block-banner-marketplace cursor-pointer" onClick={() => {*/}
                  {/*handleDD(403)*/}
                  {/*}} style={{margin: '80px 0 80px'}}>*/}
                  {/*<div className="block-left-banner-marketplace">*/}
                  {/*<p className="title-banner-marketplace">*/}
                  {/*Due Diligence report*/}
                  {/*</p>*/}
                  {/*<p className="description-banner-marketplace">*/}
                  {/*Rocket DAO experts conducted the first public Due Diligence.*/}
                  {/*</p>*/}
                  {/*<ButtonGreen*/}
                  {/*TitleButton={'Here is the full report'}*/}
                  {/*EventOnClick={() => handleDD(403)}*/}
                  {/*paddingButton={'20'}*/}
                  {/*disabled={false}*/}
                  {/*/>*/}
                  {/*</div>*/}
                  {/*<div className="block-right-banner-marketplace">*/}
                  {/*<img src={DD_1234} alt=""/>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*</Container>*/}

                  {!!data.search.fundAngelList && (
                    <Container id="investors" className={"mt-60"}>
                      <BlockTitle
                        h2={"Venture funds and Angel networks"}
                        h3={"Top investment funds and networks of the month"}
                      />
                      <Row>{fundList}</Row>

                      {data.search.fundAngelList.count > 3 && (
                        <p
                          className="more-items"
                          onClick={() =>
                            this.props.history.push(
                              "/search/investor?investor_type[0]=2&investor_type[1]=3",
                              { noOpen: true }
                            )
                          }
                        >
                          More Venture funds and Angel networks
                          <i className="far fa-angle-right" />
                        </p>
                      )}
                    </Container>
                  )}

                  {/*Banner Investclub.vc*/}
                  <Container className={"mt-60"}>
                    <a href="https://investclub.vc/" target="_blank">
                      <picture>
                        <source
                          media="(min-width:768px)"
                          srcSet={marketplaceBanner3}
                        />
                        <img
                          className="marketplace-new-b-img marketplace-new-b-img-br10 marketplace-new-b-img-shd"
                          src={marketplaceBannerMob3}
                          alt="Flowers"
                        />
                      </picture>
                    </a>
                  </Container>

                  {/*ANGEL INVESTORS*/}

                  {!!data.search.investorList && (
                    <Container id="investors" className={"mt-60"}>
                      <BlockTitle
                        h2={"Angel investors"}
                        h3={
                          "Top business angels and public individual investors"
                        }
                      />
                      <Row>{investorList}</Row>

                      {data.search.investorList.count > 3 && (
                        <p
                          className="more-items"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/search/angel_investor",
                              state: { noOpen: true },
                            })
                          }
                        >
                          More angel investors
                          <i className="far fa-angle-right" />
                        </p>
                      )}
                    </Container>
                  )}

                  <Container>
                    {(!this.state.isSubscribe2 && (
                      <div
                        className="block-new-banner-get-email"
                        style={{ backgroundImage: `url(${Baner})` }}
                      >
                        <p className="title-new-banner-get-email">
                          Get list of metrics that every startup and investor
                          should know and track!
                        </p>
                        <div className="block-input-button-new-banner-get-email">
                          <Input
                            type={"email"}
                            name={"email"}
                            value={this.state.currentEmail}
                            onChange={this.setCurrentEmail}
                            placeholder={"Your email"}
                            error={this.state.error}
                          />
                          <ButtonBlue
                            TitleButton={"Get my copy"}
                            EventOnClick={() =>
                              this.subscribe(subscribeTypes["5"])
                            }
                            paddingButton={"30"}
                            disabled={false}
                            loader={false}
                          />
                        </div>
                      </div>
                    )) || (
                      <div
                        className="block-new-banner-get-email"
                        style={{ backgroundImage: `url(${Baner})` }}
                      >
                        <p className="title-new-banner-get-email">
                          Your copy of our 'Top startups metrics for you to
                          track' is on its way right now.
                        </p>
                        <div className="block-input-button-new-banner-get-email">
                          Check out your email. You are cool!
                        </div>
                      </div>
                    )}
                  </Container>

                  {/*EXPERTS*/}

                  {!!expertList.length && (
                    <Container id="experts" className={"mt-60"}>
                      <BlockTitle
                        h2={"Experts"}
                        h3={"Experts in startups evaluation and valuation"}
                      />

                      <Row>{expertList}</Row>

                      {data.search.expertList.count > 3 && (
                        <p
                          className="more-items"
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/search/expert",
                              state: { noOpen: true },
                            })
                          }
                        >
                          More experts
                          <i className="far fa-angle-right" />
                        </p>
                      )}
                    </Container>
                  )}

                  {/*Banner Startup Jedi*/}
                  <Container className={"mt-60"}>
                    <a href="https://startupjedi.vc/" target="_blank">
                      <picture>
                        <source
                          media="(min-width:768px)"
                          srcSet={marketplaceBanner4}
                        />
                        <img
                          className="marketplace-new-b-img marketplace-new-b-img-br10"
                          src={marketplaceBannerMob4}
                          alt="Flowers"
                        />
                      </picture>
                    </a>
                  </Container>

                  {/*ACCELERATORS*/}

                  {!!data.search.acceleratorList &&
                    data.search.acceleratorList.count > 0 && (
                      <Container id="accelerators" className={"mt-60"}>
                        <BlockTitle
                          h2={"Accelerators"}
                          h3={
                            "Best supporters and educators of early-stage, growth-driven companies"
                          }
                        />

                        <Row>{acceleratorList}</Row>

                        {data.search.acceleratorList.count > 3 && (
                          <p
                            className="more-items"
                            onClick={() =>
                              this.props.history.push({
                                pathname: "/search/accelerator",
                                state: { noOpen: true },
                              })
                            }
                          >
                            More accelerators
                            <i className="far fa-angle-right" />
                          </p>
                        )}
                      </Container>
                    )}
                </React.Fragment>
              );
            }}
          </Query>

          {/*<div className="block-list-success-stories gradient">*/}
          {/*<p className="title-latest-articles">Community feedback</p>*/}

          {/*<div className="list-item-success-stories">*/}
          {/*<div className="block-item-success-stories left-item-success-stories">*/}
          {/*<div className="block-left-item-success-stories">*/}
          {/*<div className="img-item-success-stories"*/}
          {/*style={{backgroundImage: `url(${avatar_1})`}}/>*/}
          {/*</div>*/}
          {/*<div className="block-right-item-success-stories">*/}
          {/*<p className="title-item-success-stories">*/}
          {/*"I was really impressed by the level of competencies of the experts who*/}
          {/*conducted Due Diligence for our project."*/}
          {/*</p>*/}
          {/*<p className="description-item-success-stories">*/}
          {/*<a href="https://www.linkedin.com/in/vadimicus/" className="no-undeground">Vadim*/}
          {/*Makovsky</a>, <span className="gray">CEO</span> <a*/}
          {/*className="no-undeground" href="https://rocketdao.io/startup/445"><span*/}
          {/*className="blue">Multy</span></a>*/}
          {/*</p>*/}
          {/*/!*<a href="" className="link-item-success-stories">*!/*/}
          {/*/!*kateholmes.com*!/*/}
          {/*/!*</a>*!/*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="block-item-success-stories right-item-success-stories">*/}
          {/*<div className="block-left-item-img">*/}
          {/*<div className="img-item-success-stories"*/}
          {/*style={{backgroundImage: `url(${avatar_2})`}}/>*/}
          {/*</div>*/}
          {/*<div className="block-left-item-success-stories">*/}
          {/*<p className="title-item-success-stories">*/}
          {/*"Rocket DAO evaluation standard helps startups and investors communicate on*/}
          {/*a single, understandable language."*/}
          {/*</p>*/}
          {/*<p className="description-item-success-stories">*/}
          {/*<a href="https://www.linkedin.com/in/valentine/" className="no-undeground">Valentine*/}
          {/*Zavgorodnev</a>, <span className="gray">Business angel</span>*/}
          {/*</p>*/}
          {/*/!*<a href="" className="link-item-success-stories">*!/*/}
          {/*/!*kateholmes.com*!/*/}
          {/*/!*</a>*!/*/}
          {/*</div>*/}
          {/*<div className="block-right-item-success-stories">*/}
          {/*<div className="img-item-success-stories"*/}
          {/*style={{backgroundImage: `url(${avatar_2})`}}/>*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*<div className="block-item-success-stories left-item-success-stories">*/}
          {/*<div className="block-left-item-success-stories">*/}
          {/*<div className="img-item-success-stories"*/}
          {/*style={{backgroundImage: `url(${avatar_3})`}}/>*/}
          {/*</div>*/}
          {/*<div className="block-right-item-success-stories">*/}
          {/*<p className="title-item-success-stories">*/}
          {/*"I am honored to be a contributor to the first ever viable decentralized*/}
          {/*expert community."*/}
          {/*</p>*/}
          {/*<p className="description-item-success-stories">*/}
          {/*<a href="https://rocketdao.io/expert/31" className="no-undeground">Andrew*/}
          {/*Kuryan</a>, <span className="gray">TRIZ trainer in EPAM Systems</span>*/}
          {/*</p>*/}
          {/*/!*<a href="" className="link-item-success-stories">*!/*/}
          {/*/!*kateholmes.com*!/*/}
          {/*/!*</a>*!/*/}
          {/*</div>*/}
          {/*</div>*/}
          {/*</div>*/}

          {/*</div>*/}

          <Container className={"mt-60"}>
            <div className="block-list-articles">
              <p className="title-latest-articles">
                Latest articles from Startup Jedi
              </p>
              <div className="latest-articles-block">
                <div className="block-big-post-blog">
                  <a href={mainBlog.href} target={"_blank"}>
                    <div className="block-left-big-post-blog">
                      <div
                        className="bg-big-post-blog"
                        style={{ backgroundImage: `url(${mainBlog.img})` }}
                      />
                    </div>
                    <div className="block-right-big-post-blog">
                      <p className="date-big-post-blog">
                        {moment(mainBlog.date).format("DD MMM, YYYY")}
                      </p>
                      <p className="title-big-post-blog">{mainBlog.title}</p>
                      <p className="description-big-post-blog">
                        {isExist(() => mainBlog.shortDescription.length > 210)
                          ? mainBlog.shortDescription.slice(0, 200).trim() +
                            "..."
                          : mainBlog.shortDescription}
                      </p>
                    </div>
                  </a>
                </div>

                <Row>
                  {blogList.map((o, i) => (
                    <div className={`col-12 col-md-4`}>
                      <div className={`bottom-post`}>
                        <a href={o.href} target={"_blank"}>
                          <div
                            className="bottom-post-img"
                            style={{ backgroundImage: `url(${o.img})` }}
                          />
                          <div className="date-title">
                            {moment(o.date).format("DD MMM, YYYY")}
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </Row>

                <div className="latest-articles-block-button">
                  <ButtonBlue
                    TitleButton={"Explore Startup Jedi"}
                    EventOnClick={() =>
                      window.open("https://startupjedi.vc/", "_blank")
                    }
                    paddingButton={"0"}
                    disabled={false}
                  />
                </div>
              </div>
            </div>
          </Container>

          {/*<div className="block-want-know-more">*/}
          {/*<p className="title-want-know-more">*/}
          {/*Want to know more?*/}
          {/*</p>*/}
          {/*<p className="description-want-know-more">*/}
          {/*Rocket DAO is an ecosystem product for more effective interaction between startups and*/}
          {/*investors under the supervision of the expert community.*/}
          {/*</p>*/}
          {/*<ButtonBlue*/}
          {/*TitleButton={'Learn more about Rocket DAO'}*/}
          {/*EventOnClick={() => window.open('/about', "_blank")}*/}
          {/*paddingButton={'40'}*/}
          {/*disabled={false}*/}
          {/*/>*/}
          {/*</div>*/}
        </Container>

        {this.state.showLoginPopup !== "hide" && (
          <LoginPopup
            close={() => {
              this.setState({ showLoginPopup: "hide" });
            }}
            state={this.state.showLoginPopup}
          />
        )}

        <Footer />

        {this.state.showVideoPopup ? (
          <VideoPopUp
            id={this.state.showVideoId}
            user={user}
            close={() => this.togglePopup(null)}
          />
        ) : null}

        {this.state.showCartPopup ? (
          <CartPopUp
            id={this.state.cartId}
            user={user}
            type={this.state.CartPopUpType}
            type2={this.state.CartPopUpType2}
            close={() => this.togglePopupCart(null, null)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  user: state.auth.user,
});

Mp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  tokenLogin: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  tokenLogin,
  updateUser,
  logout,
  updateMarket,
})(Mp);

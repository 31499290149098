import React from 'react';
import ButtonBlue from "../../../controls3.0/button/ButtonBlue";
import './styles.css';
import {Scrollbars} from "react-custom-scrollbars";

export default class AcceleratorSendPopup extends React.Component {

    render() {
        const {
            member
        } = this.props;


        return (
            <div className={`profile-edit-popup-block`}>
                <div className="block-members-popup block-send-popup">
                    <div className='close-title'>
                        <Scrollbars style={{height: '80vh'}}>
                            <div style={{padding: '0 40px 0 0'}}>
                                <div className='close' onClick={() => this.props.close()}/>

                                <div className="block-send-popup-bg"/>
                                <div className='members-text-title text-align-center font-weight-500'>Invitation has
                                    been successfully sent!
                                </div>
                                <div className='members-text-little-subtitle text-align-center margin-top-15'>
                                    You will be notified when your team member registers
                                    on the platform via your invitation link.
                                </div>
                                <div
                                    className="members-text-subtitle margin-top-65 margin-bottom-20 text-align-center">We
                                    have sent invitation to this email:
                                </div>
                                <div className='list-mail-inv-send'>
                                    <div className='mail-block-border'>
                                        <div className='text-mail'>{member.email}</div>
                                    </div>
                                </div>
                                <div className='send-button-line margin-top-40'>
                                    <ButtonBlue
                                        TitleButton={'Continue'}
                                        EventOnClick={() => this.props.close()}
                                        paddingButton={'58'}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        );
    }
}
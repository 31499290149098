import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAccelerator} from "../../../actions/Accelerator";
import {getListIndustries} from "../../../actions/Project";

import ButtonBlue from '../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import CheckBox from '../../controls3.0/checkBox/cubeCheckBox'
import {isExist} from "../../../utils/helper";


class Step7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accelerator: props.accelerator || {
                industry: []
            },
            errors: {},
            listI: []
        }
    }

    componentDidMount () {
        getListIndustries()
            .then((res) => {
                this.setState({listI: res.data})
            })
            .catch(error => {
                this.setState({loading: false})
            })
    }

    onChange = item => {
        let industry = this.state.accelerator.industry

        let isFound = industry.find(x => x === item.id)
        if (!isFound) {
            industry.push(item.id)
        } else {
            industry = industry.filter(x => x !== item.id)
        }
        this.setState({accelerator: {...this.state.accelerator, industry: industry}})
    }

    update = (nextStep, onlyStep) => {
        let obj = {
            industry: this.state.accelerator.industry
        }

        console.log(this.state.accelerator.industry);
        console.log(this.state.accelerator);

        const acceleratorId = this.state.accelerator.id || alert('Not Set accelerator');

        if(onlyStep){
            obj = {}
        }

        if(!onlyStep) this.setState({loading: true})
        this.props.updateAccelerator(obj, acceleratorId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render () {
        const {accelerator, errors} = this.state;

        if(!accelerator.industry){
            accelerator.industry = [];
        }

        let list = this.state.listI.map((item, key) =>
            <div key={key} className="block-item-check-box-reg-startup" onClick={() => this.onChange(item)}>
                <div className="block-left-check-box-reg-startup">
                    <CheckBox
                        onClick={null}
                        active={accelerator.industry.includes(item.id)}
                    />
                </div>
                <div className="block-right-check-box-reg-startup">{item.title}</div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>
                            <div className="container-step-1-reg">
                                <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                    Industry
                                </p>
                                <p className="text-h4 color-text-reg-gray margin-0 shell-margin-bottom-10" style={{maxWidth:'570px'}}>
                                    What are the business areas and industries you are looking for the startups from?
                                </p>
                                <div className="text-button color-text-reg-gray margin-0 shell-margin-bottom-25 font-weight-400">
                                    Choose from the below.
                                </div>

                                <div className="block-list-check-box-reg-startup bl-fix-height">
                                    {list}
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(6, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(8)}
                                paddingButton={'54'}
                                disabled={! isExist(() => this.state.accelerator.industry.length) }
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    accelerator: state.accelerator
})

Step7.propTypes = {
    updateAccelerator: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAccelerator})(Step7)
import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import Dropzone from 'react-dropzone'

import {getAchievement, createAchievementInvestorAngel, editAchievementInvestorAngel, deleteAchievementInvestorAngel, updateAngel} from "../../../actions/Login";
import {getListParametersAngels} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";

// import packageJSON from './../../../../package.json';
// import Categorie from "../../controls3.0/categorie/Categorie";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import TabsCounter from "../../controls3.0/tabs/TabsCounter";
import Input from "../../controls3.0/input/Input";
import {regexUrl} from "../../../utils/RegExp";
// import {ACHIEVEMENTS_INVESTOR} from '../../../utils/staticData'
// import {updateUserInfo} from "../../../actions/AuthActions";
// import {goToStep} from "../../../utils/regNavigation";
import axios from "axios/index";
import TextArea from "../../v3/Textarea";

class Step7 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        if (!Array.isArray(angel.parameters)) {
            angel.parameters = [];
        } else {
            //angel.parameters = angel.parameters;
        }

        this.state = {
            user: user,
            angel: angel,
            // types: ACHIEVEMENTS_INVESTOR,
            types: [],
            listAchievements: [],
            activeAchs: [],
            activeParam: {},
            activeType: {},
            newA: {
                link: '',
                description: ''
            },
            editA: {},
            errors: {},
            errors2: {},
            isActiveForm: false, //angel.parameters.length > 0,
            show2: false
        }
    }

    componentDidMount() {

       //this.props.getLastFund()

        let obj = {}
        // getListParametersInvestor()
        getListParametersAngels()
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({types: res.data})
                this.setState({loaded: true})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loaded: true})
            })

        try {
            Promise.all(
                this.state.angel.parameters.map(async (item, key) => {
                    let achievement = await getAchievement('fund', item.parameterId, this.state.angel.id)
                    obj[item.parameterId] = achievement.data
                    console.log('obj', obj);
                    return (achievement.data)
                })

        ).then(results => {
                console.log('listAchievements', obj)
                this.setState({listAchievements: obj})
            })
        } catch (e) {

        }
    }


    create = () => {
        let {activeType, newA, errors, angel} = this.state;
        let data = {
            achievementType: 0,
            role: 'fund',
            link: newA.link,
            description: newA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors: {...this.state.errors, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})


        createAchievementInvestorAngel(angel.id, activeType.parameterId, data)
            .then(res => {
                this.addToList(res.data)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    edit = () => {
        let {activeParam, activeType, editA} = this.state;
        let data = {
            parameterId: activeParam.id,
            achievementType: activeType.parameterId,
            role: 'angel',
            link: editA.link,
            description: editA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors2: {...this.state.errors2, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})
        editAchievementInvestorAngel(this.state.angel.id, this.state.activeType.parameterId, editA.id, data)
            .then(res => {
                this.addToList(res.data, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    delete = () => {
        deleteAchievementInvestorAngel(this.state.angel.id, this.state.activeType.parameterId, this.state.editA.id)
            .then(res => {
                this.addToList(this.state.editA, false, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                this.setState({errors: {...this.state.errors, link : ''}})
            } else {
                this.setState({errors: {...this.state.errors, link : 'Wrong link format'}});
            }
        }
        if(!value){
            this.setState({errors: {...this.state.errors, [name] : 'Field is required'}})
        }

        if (value.length > 100 && name == 'description') {
            this.setState({errors: {...this.state.errors, [name] : 'The text is too long, 100 symbols max'}});
            value = value.slice(0, 200);
        }
        this.setState({newA: {...this.state.newA, [name]: value}})
    }
    onChange2 = e => {
        let name = e.target.name,
            value = e.target.value;
        value = value.cleanWhitespace();
        if(name == 'link') {
            value = value.trim();
            if (regexUrl.test(value)) {
                this.setState({errors2: {...this.state.errors2, link : ''}})
            } else {
                this.setState({errors2: {...this.state.errors2, link : 'Wrong link format'}});
            }
        }
        if(!value){
            this.setState({errors2: {...this.state.errors2, [name] : 'Field is required'}})
        }

        if (value.length > 100 && name == 'description') {
            this.setState({errors2: {...this.state.errors2, [name] : 'The text is too long, 100 symbols max'}});
            return;
        }


        this.setState({editA: {...this.state.editA, [name]: value}})
    }


    setActiveType = (type) => {
        let activeAchs = this.state.angel.parameters;

        type.parameterId = type.id

        let isFound = activeAchs.find(x => x.parameterId === type.id)
        if (!isFound) {
            activeAchs.push(type)
        } else {
            activeAchs = activeAchs.filter(x => x.parameterId !== type.id)
        }

        this.setState({ angel: {...this.state.angel, parameters: activeAchs} })
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        if(onlyStep) obj = {}

        if (!onlyStep) {
            this.setState({loading: true})
        }
        const fundId = this.state.angel.id;

        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    addToList = (item, edit, _delete) => {
        let arr = this.state.listAchievements[item.parameterId] || [];

        if(edit) {
            let index;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) arr[index] = item;
            this.setState({editA: {}})
        } else if(_delete) {
            let index = -1;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) arr.splice(index,index+1);
            this.setState({editA: {}})
            this.setState({listAchievements: {...this.state.listAchievements, [item.parameterId]: arr}})
            this.handleClearNewA()
        } else {
            arr.push(item)
        }

        this.setState({listAchievements: {...this.state.listAchievements, [item.parameterId]: arr}})
        this.handleClearNewA()
    }

    handleClearNewA = () => {
        this.setState({loadingCreate: false})
        this.setState({
            newA: {
                link: '',
                description: ''
            },
            isActiveForm: false
        })
    }

    next = () => {
        let obj = {
            parameters: this.state.angel.parameters,
            role: 'angel' //'fund'
        }
        let arr = [];

        const fundId = this.state.angel.id || this.props.angel.id;

        if(!fundId){
            //alert('Not Fund Id');
        }

        this.setState({loadingNext: true})


        axios
            .put(`/v2/angels/${fundId}`, obj)
            .then(res => {

                console.log({obj});

                this.setState({angel: {...this.state.angel, parameters: obj.parameters}}, () => {
                    try {
                        Promise.all(
                            this.state.angel.parameters.map(async (item, key) => {
                                let achievement = await getAchievement('fund', item.parameterId, fundId)
                                arr[item.parameterId] = achievement.data
                                return (achievement.data)
                            })
                        ).then(results => {
                            this.setState({listAchievements: arr})
                            this.setState({activeType: this.state.angel.parameters[0]})
                            this.setState({show2: !this.state.show2})
                            this.setState({loadingNext: false})
                        })
                    } catch (e) {
                        this.setState({loadingNext: false})
                        console.log(e)
                    }
                })
            })
            .catch(err => {
                console.log(err);
                showMessage({type: 'error', data: err})
                throw err
            })
    }

    render() {
        let {user, angel, types, newA, editA, activeAchs, activeParam, isActiveForm, activeType, listAchievements, errors, errors2} = this.state;

        let isNoEmpty;
        for (let key in listAchievements) {
            if(listAchievements[key].length) isNoEmpty = true
        }

        const dropStyle = {
            'display': 'inline-block',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }
        return (
            <React.Fragment>

                {!this.state.show2 &&
                <React.Fragment>
                    <div className="container-full-reg" style={{position: 'relative'}}>
                        <div className="container-step-2-reg"
                             style={{position: 'relative', margin: '0 0 0 auto'}}>

                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                                Achievements
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-20" style={{maxWidth:'480px'}}>
                                In order to increase angel network’s credibility, please tell us about your previous activities. Choose the categories of achievements you can provide the information for.
                            </p>

                        </div>
                    </div>

                    <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                        <div className="container-step-2-reg" style={{position: 'relative', margin: '0 0 0 auto'}}>
                            <div className="block-cart-list-activity-reg-expert">

                                <div className="block-left-cart-list-activity-reg-expert">
                                    {
                                        types.map((item, key) => {
                                            if (key <= 2) {
                                                return (
                                                    <div key={key} className={`block-item-cart-activity-reg-expert ${angel.parameters.find((x) => x.parameterId === item.id) ? 'active' : ''}`}
                                                         onClick={() => this.setActiveType(item)}>
                                                        <p className="title-top-page-reg-expert text-18-500 left-text margin-0 color-text-reg-black"
                                                           style={{margin: '0 0 3px 0'}}>
                                                            {item.title}
                                                        </p>
                                                        <p className="title-top-page-reg-expert text-sub-title left-text margin-0 color-text-reg-white-gray">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="block-right-cart-list-activity-reg-expert">
                                    {
                                        types.map((item, key) => {
                                            if (key >= 3) {
                                                return (
                                                    <div key={key} className={`block-item-cart-activity-reg-expert ${angel.parameters.find((x) => x.parameterId === item.id) ? 'active' : ''}`}
                                                         onClick={() => this.setActiveType(item)}>
                                                        <p className="title-top-page-reg-expert text-18-500 left-text margin-0 color-text-reg-black"
                                                           style={{margin: '0 0 3px 0'}}>
                                                            {item.title}
                                                        </p>
                                                        <p className="title-top-page-reg-expert text-sub-title left-text margin-0 color-text-reg-white-gray">
                                                            {item.description}
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {!this.state.wantSkip &&
                    <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                        <div className="container-full-reg" style={{textAlign:'right'}}>
                            <div className="block-button-left">
                                <ButtonGray
                                    TitleButton={'Back'}
                                    EventOnClick={() => this.update(5, true)}
                                    paddingButton={'55'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    This is very important, but please <br/>
                                    let me fill it in later on my profile
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Skip this step'}
                                    EventOnClick={() => this.setState({wantSkip: true})}
                                    paddingButton={'35'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Next'}
                                    EventOnClick={() => this.next()}
                                    paddingButton={'56'}
                                    disabled={!angel.parameters.length}
                                    loader={this.state.loadingNext}
                                />
                            </div>
                        </div>
                    </div>


                    }
                    {this.state.wantSkip &&
                    <div className="block-fix-button-reg-bottom fix-button-double-details-double achieve-details">
                        <div className="container-full-reg" style={{textAlign:'right'}}>

                            <div className="block-details" style={{padding: '0 16px'}}>
                                <p className="title-block-button-reg-expert">
                                    We will remind you to fill in the data later <br/>
                                    if you lack time right now.
                                </p>
                            </div>
                            <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                                <ButtonGray
                                    TitleButton={'Continue with registration'}
                                    EventOnClick={() => this.update(7, true)}
                                    paddingButton={'20'}
                                    disabled={false}
                                />
                            </div>
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Provide my achievements'}
                                    EventOnClick={() => this.setState({wantSkip: false})}
                                    paddingButton={'22'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
                }


                {this.state.show2 &&
                <React.Fragment>
                    <div className="container-full-reg" style={{position: 'relative'}}>

                        <div className="block-button-circle-title">
                            <div className="block-button-circle" style={{marginRight:'0px'}} onClick={() => this.next()}>
                                <i className="fal fa-long-arrow-left"/>
                            </div>
                            <p className="title">
                                Investor <br/>
                                Achievements
                            </p>
                        </div>

                        <div className="container-step-2-reg"
                             style={{position: 'relative', margin: '0 0 0 auto', padding:'0 0 250px 0'}}>
                            <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15 new-mar-40-top">
                                Achievements
                            </p>

                            <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                                Please provide the links to websites, blog posts and news, certificates and <br/>documents scan copies as proofs of the declared achievements.
                            </p>

                            <div className="block-list-tabs-counter-reg-expert">
                                {angel.parameters.map((item, key) =>
                                    <TabsCounter
                                        key={key}
                                        TitleTab={item.title}
                                        TypeTab={'big'}
                                        EventOnClick={() => this.setState({activeType: item, isActiveForm: !listAchievements[item.parameterId].length?true:false})}
                                        active={activeType.parameterId === item.parameterId}
                                        count={(listAchievements[item.parameterId] && listAchievements[item.parameterId].length) ? listAchievements[item.parameterId].length : '0' }
                                    />
                                )}
                            </div>

                            { (listAchievements[activeType.parameterId].length && !this.state.isActiveForm)  &&
                                <div className={'block-add-activity-reg-exp'}>
                                    <ButtonBlue
                                        TitleButton={`Add ${activeType.title}`}
                                        EventOnClick={() => this.setState({isActiveForm: true})}
                                        paddingButton={'54'}
                                        disabled={false}
                                    />
                                </div> || ''
                            }
                            { (!listAchievements[activeType.parameterId].length || this.state.isActiveForm) &&
                            <div className="block-form-add-edit-modal-reg-expert">
                                <div className="block-input-item-form-add-edit-modal-reg-expert">
                                    <p className="title">Insert the link</p>
                                    <Input
                                        type={'text'}
                                        name={'link'}
                                        value={newA.link}
                                        onChange={this.onChange}
                                        placeholder={''}
                                        error={errors.link}
                                    />
                                </div>
                                <div className="block-text-area-form-add-edit-modal-reg-expert">
                                    <p className="title">Leave comments</p>
                                    <TextArea name={'description'} val={newA.description}
                                              cols="30" rows="10"
                                              onChange={this.onChange}
                                              error={errors.description}
                                    />
                                    {/*<textarea name="description" cols="30" rows="10" value={newA.description}*/}
                                    {/*onChange={this.onChange}></textarea>*/}
                                </div>
                                <div className="block-button-form-add-edit-modal-reg-expert">
                                    <ButtonBlue
                                        TitleButton={`Add ${activeType.title}`}
                                        EventOnClick={() => this.create()}
                                        paddingButton={'54'}
                                        disabled={!newA.link || !newA.description}
                                        loader={this.state.loadingCreate}
                                    />
                                </div>
                            </div>
                            }

                            {listAchievements && listAchievements[activeType.parameterId] &&
                            <div className="block-list-active-activity-reg-expert">
                                <div className="block-list-item-active-activity-reg-expert">
                                    {listAchievements[activeType.parameterId].map((ach, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <div className="block-item-active-activity-reg-expert">
                                                    <div className="block-left-item-active-activity-reg-expert">
                                                        <p className="title">
                                                            {ach.link}
                                                        </p>
                                                        <p className="description">
                                                            {ach.description}
                                                        </p>
                                                    </div>
                                                    <div className="block-right-item-active-activity-reg-expert">
                                                        <p className="button-edit"
                                                           onClick={() => this.setState({editA: ach})}>
                                                            Edit
                                                        </p>
                                                    </div>
                                                </div>

                                                {editA.id === ach.id &&
                                                <div className="block-form-add-edit-modal-reg-expert"
                                                     style={{marginBottom: '12px'}}>
                                                    <div
                                                        className="block-input-item-form-add-edit-modal-reg-expert">
                                                        <p className="title">Insert the link</p>
                                                        <Input
                                                            type={'text'}
                                                            name={'link'}
                                                            value={editA.link}
                                                            onChange={this.onChange2}
                                                            placeholder={''}
                                                            error={errors2.link}
                                                        />
                                                    </div>
                                                    <div
                                                        className="block-text-area-form-add-edit-modal-reg-expert">
                                                        <p className="title">Leave comments</p>
                                                        <TextArea name={'description'}
                                                                  val={editA.description}
                                                                  cols="30" rows="10"
                                                                  onChange={this.onChange2}
                                                                  error={errors2.description}
                                                        />
                                                    </div>
                                                    <div
                                                        className="block-button-form-add-edit-modal-reg-expert">
                                                        <ButtonBlue
                                                            TitleButton={`Edit`}
                                                            EventOnClick={() => this.edit()}
                                                            paddingButton={'54'}
                                                            disabled={!editA.link || !editA.description}
                                                        />

                                                        <p className="button-delete" onClick={() => this.delete()}>
                                                            Delete
                                                        </p>
                                                    </div>
                                                </div>
                                                }

                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {true &&
                    <div className="block-fix-button-reg-bottom fix-button-one">
                        <div className="container-full-reg">
                            <div className="block-button-right">
                                <ButtonBlue
                                    TitleButton={'Save & Continue'}
                                    EventOnClick={() => this.update(7, true)}
                                    paddingButton={'54'}
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
                }


            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step7.propTypes = {
    updateAngel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {updateAngel})(Step7)


export const regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

// export const regexUrl = new RegExp(/(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/)
export const regexUrl = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)

// export const regexPass = new RegExp(/(?=^.{8,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*^\S*$/)

export const regexPass = new RegExp(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})/)

export const regexCyrillic = new RegExp(/[а-яА-ЯёЁ]/);

export const regexNumber = new RegExp(/^\d+$/)

export const regexPhone = new RegExp(/[0-9\\+]/)

export const regexSite = new RegExp(/@^(http\:\/\/|https\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$@i/);

// export const regexLinkedin = new RegExp(/http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/(A-z 0-9 _ -)\/?/)
// export const regexTelegram = new RegExp(/https?:\/\/(t(elegram)?\.me|telegram\.org)\/([a-z0-9\_]{5,32})\/?/)
// export const regexFacebook = new RegExp(/http(s)?:\/\/(www\.)?(facebook|fb)\.com\/(A-z 0-9 _ - \.)\/?/)
// export const regexTwitter = new RegExp(/http(s)?:\/(.*\.)?twitter\.com\/[A-z 0-9 _]+\/?/)

export const abi = [{
    'constant': false,
    'inputs': [],
    'name': 'unpause',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'paused',
    'outputs': [{
        'name': '',
        'type': 'bool'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [],
    'name': 'renounceOwnership',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [],
    'name': 'pause',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'owner',
    'outputs': [{
        'name': '',
        'type': 'address'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{
        'name': '',
        'type': 'uint256'
    }],
    'name': 'approvedProjects',
    'outputs': [{
        'name': '',
        'type': 'uint256'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{
        'name': '_newOwner',
        'type': 'address'
    }],
    'name': 'transferOwnership',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'inputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'constructor'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': false,
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'ProjectCreated',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': false,
        'name': '_projectId',
        'type': 'uint256'
    }, {
        'indexed': false,
        'name': '_baker',
        'type': 'address'
    }, {
        'indexed': false,
        'name': '_amount',
        'type': 'uint256'
    }],
    'name': 'ProjectDeposited',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': false,
        'name': '_projectId',
        'type': 'uint256'
    }, {
        'indexed': false,
        'name': '_weiRaised',
        'type': 'uint256'
    }],
    'name': 'ProjectFunded',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': false,
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'ProjectRefunded',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [],
    'name': 'Pause',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [],
    'name': 'Unpause',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': true,
        'name': 'previousOwner',
        'type': 'address'
    }],
    'name': 'OwnershipRenounced',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{
        'indexed': true,
        'name': 'previousOwner',
        'type': 'address'
    }, {
        'indexed': true,
        'name': 'newOwner',
        'type': 'address'
    }],
    'name': 'OwnershipTransferred',
    'type': 'event'
}, {
    'constant': false,
    'inputs': [{
        'name': '_goal',
        'type': 'uint256'
    }, {
        'name': '_projectInfoHash',
        'type': 'string'
    }, {
        'name': '_escrow',
        'type': 'address[]'
    }, {
        'name': '_confirmationsNumber',
        'type': 'uint256'
    }, {
        'name': '_closingTime',
        'type': 'uint256'
    }],
    'name': 'createProject',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'getProject',
    'outputs': [{
        'name': '',
        'type': 'uint256'
    }, {
        'name': '',
        'type': 'uint256'
    }, {
        'name': '',
        'type': 'string'
    }, {
        'name': '',
        'type': 'address'
    }, {
        'name': '',
        'type': 'uint256'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }, {
        'name': '_backer',
        'type': 'address'
    }],
    'name': 'getBakerFunds',
    'outputs': [{
        'name': '',
        'type': 'uint256'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'fundProject',
    'outputs': [],
    'payable': true,
    'stateMutability': 'payable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'claimRefund',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'goalReached',
    'outputs': [{
        'name': '',
        'type': 'bool'
    }],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{
        'name': '_projectId',
        'type': 'uint256'
    }],
    'name': 'finalize',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}]


export const youtubeParser = (url = '') => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from './../../controls3.0/button/ButtonGray'
import Child from '../../../images/child.jpg'

import graph_gray from '../../../images/svg/graph_gray.svg'
import graph_green from '../../../images/svg/graph_green.svg'
import graph_black from '../../../images/svg/graph_black.svg'
import graph_blue from '../../../images/svg/graph_blue.svg'

import ic_expert from '../../../images/svg/ic_expert.svg'
import ic_expert_active from '../../../images/svg/ic_expert_active.svg'
import ic_supervisor from '../../../images/svg/ic_supervisor.svg'
import ic_supervisor_active from '../../../images/svg/ic_supervisor_active.svg'
import ic_methodologist from '../../../images/svg/ic_methodologist.svg'
import ic_methodologist_active from '../../../images/svg/ic_methodologist_active.svg'


import {updateExpert} from "../../../actions/Login";


class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _graphActive: 'null'
        }
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    }

    componentDidMount() {


    }

    update = (nextStep, onlyStep) => {
        let loading = nextStep == 1 ? 'loadingBack' : 'loading'
        this.setState({[loading]: true})
        this.props.updateExpert({}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    onMouseEnterHandler(e) {
        this.setState({
            _graphActive: e,
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            _graphActive: 'null',
        });
    }


    render() {
        return (
            <React.Fragment>


                <div className="block-graph-left">
                    <img className={this.state._graphActive == 'null' ? "img-graph-gray active" : "img-graph-gray"}
                         src={graph_gray} alt=""/>
                    <img className={this.state._graphActive == 'green' ? "img-graph-green active" : "img-graph-green"}
                         src={graph_green} alt=""/>
                    <img className={this.state._graphActive == 'black' ? "img-graph-black active" : "img-graph-black"}
                         src={graph_black} alt=""/>
                    <img className={this.state._graphActive == 'blue' ? "img-graph-blue active" : "img-graph-blue"}
                         src={graph_blue} alt=""/>
                </div>


                <div className="container-full-reg padding-reg" style={{position:'sticky'}}>

                    <div className="block-top-graph-step-2">
                        <div className="block-progress-graph-step-2">
                            <div className={this.state._graphActive == 'blue' ? "block-step-graph-step-2 img-graph-blue active" : "block-step-graph-step-2 img-graph-blue"} style={{width:'15%'}}>
                                <p className="title-progress">15%</p>
                            </div>
                            <div className={this.state._graphActive == 'green' ? "block-step-graph-step-2 img-graph-green active" : "block-step-graph-step-2 img-graph-green"} style={{width:'48%',margin:'0 1%'}}>
                                <p className="title-progress">50%</p>
                            </div>
                            <div className={this.state._graphActive == 'black' ? "block-step-graph-step-2 img-graph-black active" : "block-step-graph-step-2 img-graph-black"} style={{width:'35%'}}>
                                <p className="title-progress">35%</p>
                            </div>
                        </div>
                    </div>

                    <div className="step-2">
                        <p className="text-h1">Project evaluation</p>
                        <div className="block-center">
                            <div className="text-desc">
                                Startup teams apply for evaluation and pay a fixed fee to the group of experts who audit
                                the project. <br/>
                                <span
                                    style={{fontWeight: '600'}}> 10 methodologists, 10 experts and 10 supervisors</span> take
                                part in the evaluation process so that investors receive a detailed objective project
                                review. <br/>
                                The remuneration for evaluation is distributed as follows:
                            </div>

                            <div className="block-list-cart-state-reg-expert">
                                <div className="block-item-cart-state-reg-expert"
                                     onMouseEnter={() => this.onMouseEnterHandler('green')}
                                     onMouseLeave={() => this.onMouseLeaveHandler()}>
                                    <img src={this.state._graphActive == 'green' ? ic_expert_active : ic_expert}
                                         alt="logo"/>
                                    <p className="text-h4">Expert</p>
                                    <p className="text-desc-2">
                                        Data verification & <br/>
                                        project evaluation
                                    </p>
                                    <p className='text-btm'>Team of 10</p>
                                </div>
                                <div className="block-item-cart-state-reg-expert"
                                     onMouseEnter={() => this.onMouseEnterHandler('black')}
                                     onMouseLeave={() => this.onMouseLeaveHandler()}>
                                    <img src={this.state._graphActive == 'black' ? ic_supervisor_active : ic_supervisor}
                                         alt="logo"/>
                                    <p className="text-h4">Supervisors</p>
                                    <p className="text-desc-2">
                                        Assessment & training <br/>
                                        of other experts
                                    </p>
                                    <p className='text-btm'>Team of 10</p>
                                </div>
                                <div className="block-item-cart-state-reg-expert"
                                     onMouseEnter={() => this.onMouseEnterHandler('blue')}
                                     onMouseLeave={() => this.onMouseLeaveHandler()}>
                                    <img
                                        src={this.state._graphActive == 'blue' ? ic_methodologist_active : ic_methodologist}
                                        alt="logo"/>
                                    <p className="text-h4">Methodologists</p>
                                    <p className="text-desc-2">
                                        Evaluation methodologies & <br/>
                                        templates development
                                    </p>
                                    <p className='text-btm'>Team of 10</p>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(1, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Ok, got it!'}
                                EventOnClick={() => this.update(5, true)}
                                paddingButton={'80'}
                                disabled={false}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step2.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step2)
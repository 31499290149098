export function goToCart(id, type, tab) {
    if(type === 'startup_score' || type === 'startup_evaluation') {
        type = 'startup';
    }
    if(type === 'angel'){
        type = 'angel_network';
    }
    if(tab){
        window.open(`/${type}/${id}`, '_blank');
    } else {
        window.location.href = `/${type}/${id}`;
    }
}

export function redirect(url, tab) {
    if(tab){
        window.open(url, '_blank');
    } else {
        window.location.href = url;
    }
}

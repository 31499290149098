import React from 'react'
import ReactModal from 'react-modal';
import '../modal/loginRegister.css'
import ButtonGreen2 from '../controls2.0/Button2.0/ButtonGreen2.0'
import Loader from './../test-controls/preloader/preloader'
import packageJSON from './../../../package.json'
import {createSection} from '../../actions/Forum'

export default class ModalNewSection extends React.Component {

    constructor(props) {
        super();
        this.state = {
            data: {
                title: ''
            },
            errors: {},
            loaded: true

        };
    }
    onChange = e => {
        let name = e.target.name,
            value = e.target.value,
            errors = this.state.errors


        switch (name) {
            case 'title':
                if (!value.length) {
                    errors[name] = 'Field is required'
                } else {
                    delete errors[name]
                }
                if(value.length > 20) {
                    errors[name] = 'max length 20 characters';
                    value = value.slice(0,20);
                    setTimeout(function() {
                        delete errors[name]
                        this.setState({errors})
                    }.bind(this), 3000);
                } else {
                    delete errors[name]
                }
                break;
        }

        this.setState({data: {...this.state.data, [name]: value}})
        this.setState({errors})
    }


    validate = data => {
        const errors = {}
        for (let key in data) {
            let value = data[key];
            switch (key) {
                case 'title':
                    if(value.length > 20) {
                        errors[key] = 'max length 20 characters';
                    } else {
                        delete errors[key]
                    }
                    if (!value.trim()) errors[key] = 'Field is required'
                    break;
            }
        }
        return errors;
    }

    send = () => {
        let checkdata = this.state.data;
        const errors = this.validate(checkdata);
        this.setState({errors})

        if (!Object.keys(errors).length) {
            let data = this.state.data;

            let obj = {
                title: data.title
            }

            this.setState({loaded: false})

            createSection(obj)
                .then((res) => {
                    this.props.addNewSection(res.data)
                    this.setState({loaded: true})
                })
                .catch(error => {
                    this.setState({serverError: error.response.data.error, loaded: true})
                    setTimeout(function () {
                        this.setState({serverError: null})
                    }.bind(this), 5000);

                })
        }
    }


    render() {
        let data = this.state.data,
            errors = this.state.errors;

        let user = this.props.user || {},
            type = this.props.type;

        const dropStyle = {
            'width': '100%',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        return (
            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login modal-register"
                    overlayClassName="Overlay"
                >
                    <div className="modal-login-block modal-register-block">
                        <div className="block-top">
                            <div className="sr-title">
                                Create new section
                            </div>

                            <img src="../../img/x.svg" alt="" className="close-icon"
                                 onClick={this.props.handleCloseModal}/>
                        </div>
                        <div className="sr-description">
                            <div className="block-add-new-forum">
                                <div className="block-left-add-new-forum">
                                    <div className="block-name-user">
                                        <p>{user.firstName + ' ' +  user.lastName}</p>
                                    </div>
                                    <div className="block-img-user">
                                        <img src={packageJSON.proxy + '/file/' + (user.logoFileId) + '/view'} ></img>
                                    </div>
                                </div>
                                <div className="block-right-add-new-forum">

                                    <div className="block-title-input">
                                        <p className="capitalize">Section title:</p>
                                        <input type="text" name="title" value={data.title} onChange={this.onChange} placeholder=""/>
                                        <p className="error">{errors.title}</p>
                                    </div>

                                    {this.props.type == 'file' &&
                                    <div className="block-description-title">
                                        <p>Post:</p>
                                        <textarea></textarea>
                                    </div>
                                    }

                                    {this.props.type == 'file' &&
                                    <div className="block-link">
                                        <a href="">Attach Image</a>
                                        <p>img</p>
                                    </div>
                                    }


                                    <div className="block-button-add-forum">
                                        <ButtonGreen2
                                            TitleButton='Add section'
                                            EventOnClick={() => this.send()}
                                            sizeButton="big"
                                            paddingButton="60"
                                        />
                                        <p className="response-server-error">{this.state.serverError}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <Loader loaded={this.state.loaded}></Loader>

                </ReactModal>
            </div>


        )
    }
}



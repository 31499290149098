import React from 'react';

import ButtonBlue from '../button/ButtonBlue'
import ButtonBlack from '../button/ButtonBlack'
import ButtonGreen from '../button/ButtonGreen'

import './_style.css'
import './style.scss'
import config from "../../../config";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {isExist} from "../../../utils/helper";

export default class HeaderStickyCard extends React.Component {

    render() {
        let {
            name,
            activeCheck,
            urlAvatar,
            type, // expert, fund, investor, startup, network
            titleType,
            description,
            location,
            languageOrSize,
            eventButton,
            customButton,
            eventLike, // only startup
            liked,
            countLike,
            eventWaitList, // only startup
            waited,
            countWaitList,
            eventShareLN, // only startup
            sharedUrlLN,
            eventShareFB, // only startup
            sharedUrlFB,
            eventShareTW, // only startup
            sharedUrlTW
        } = this.props;



        return (
            <div>


                <div className="container p-0">
                    <div className="row-m0 shell-header-sticky-role">
                        <div className="col-lg-1 p-0">
                            <div
                                className={`block-avatar ${urlAvatar ? 'url' : 'create'} ${type ? type.toLowerCase() : ''}`}
                                style={urlAvatar ? {backgroundImage: `url(${urlAvatar}`} : null}>
                                {urlAvatar === null &&
                                <p className={`symbol-avatar ${type ? type.toLowerCase() : ''}`}>
                                    {isExist(() => name[0].toUpperCase()) }
                                </p>
                                }
                            </div>
                        </div>
                        <div className="col-lg-5 p-0">
                            <div className="block-name-type-description">
                                <div className="block-top-name-type-description">
                                    <p className="name-role">
                                        <span className="title-name-role">
                                            {name}
                                        </span>
                                        {activeCheck &&
                                        <span className={`check-role ${type ? type.toLowerCase() : ''}`}>
                                            <i className="far fa-check"/>
                                        </span>
                                        }
                                    </p>
                                </div>
                                <div className="block-bottom-name-type-description">

                                    <div className="block-type-role">
                                        <p className={`type-role ${type ? type.toLowerCase() : ''}`}>
                                            {titleType !== null ?
                                                titleType : type ? type.toLowerCase() : 'null'
                                            }
                                        </p>
                                    </div>
                                    {description !== null &&
                                    <div className="block-description-role">
                                        <p className="description-role">
                                            {description}
                                        </p>
                                    </div>
                                    }


                                </div>
                            </div>
                        </div>

                        {type.toLowerCase() === 'startup' &&
                        <div className="col-lg-4 p-0">

                            <div className="block-buttons-startup">
                                <div className="button-startup like" onClick={eventLike}>
                                    {liked &&
                                    <i className="fas fa-heart"/>
                                    || <i className="far fa-heart"/>}

                                </div>
                                <div className="button-startup white-list" onClick={eventWaitList}>

                                    {waited &&
                                    <i className="fas fa-clock"/>
                                    || <i className="far fa-clock"/>}


                                </div>
                                <div className="button-startup-social share">
                                    <div className="block-front-button-startup-social">
                                        <i className="far fa-share-alt"/>
                                    </div>
                                    <div className="block-back-button-startup-social">
                                        <div className="button-social" onClick={eventShareLN}>
                                            <LinkedinShareButton
                                                url={sharedUrlLN}
                                            >
                                                <i className="fab fa-linkedin-in"/>
                                            </LinkedinShareButton>
                                        </div>
                                        <div className="button-social" onClick={eventShareFB}>
                                            <FacebookShareButton
                                                url={sharedUrlFB}
                                            >
                                                <i className="fab fa-facebook-square"/>
                                            </FacebookShareButton>
                                        </div>
                                        <div className="button-social" onClick={eventShareTW}>
                                            <TwitterShareButton
                                                url={sharedUrlTW}
                                            >
                                                <i className="fab fa-twitter"/>
                                            </TwitterShareButton>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        }
                        {type.toLowerCase() !== 'fund' && type.toLowerCase() !== 'startup' && type.toLowerCase() !== 'network' && type.toLowerCase() !== 'accelerator' &&
                        <div className="col-lg-4 p-0">
                            <div className="row-m0 no-gutters">
                                <div className="col-6 p-0">
                                    <p className="title-role-info">
                                        Location
                                    </p>
                                    <p className="description-role-info">
                                        {location}
                                    </p>
                                </div>
                                <div className="col-6 p-0">
                                    <p className="title-role-info">
                                        Language
                                    </p>
                                    <p className="description-role-info">
                                        {languageOrSize}
                                    </p>
                                </div>
                            </div>
                        </div>
                        }
                        {type.toLowerCase() === 'fund' &&
                        <div className="col-lg-4 p-0">
                            <div className="row-m0 no-gutters">
                                <div className="col-8 p-0">
                                    <p className="title-role-info">
                                        Location
                                    </p>
                                    <p className="description-role-info">
                                        {location}
                                    </p>
                                </div>
                                <div className="col-4 p-0">
                                    <p className="title-role-info">
                                        Fund size
                                    </p>
                                    <p className="description-role-info">
                                        {languageOrSize}
                                    </p>
                                </div>
                            </div>
                        </div>
                        }
                        {type.toLowerCase() === 'accelerator' &&
                        <div className="col-lg-4 p-0">
                            <div className="row-m0 no-gutters">
                                <div className="col-7 p-0">
                                    <p className="title-role-info">
                                        Location
                                    </p>
                                    <p className="description-role-info">
                                        {location}
                                    </p>
                                </div>
                                <div className="col-5 p-0">
                                    <p className="title-role-info">
                                        Funding up to
                                    </p>
                                    <p className="description-role-info">
                                        {languageOrSize}
                                    </p>
                                </div>
                            </div>
                        </div>
                        }
                        {type.toLowerCase() === 'network' &&
                        <div className="col-lg-4 p-0">
                            <div className="row-m0 no-gutters">
                                <div className="col-8 p-0">
                                    <p className="title-role-info">
                                        Startup investment size
                                    </p>
                                    <p className="description-role-info">
                                        {location}
                                    </p>
                                </div>
                                <div className="col-4 p-0">
                                    <p className="title-role-info">
                                        Members
                                    </p>
                                    <p className="description-role-info">
                                        {languageOrSize}
                                    </p>
                                </div>
                            </div>
                        </div>
                        }

                        <div className="col-lg-2 p-0">

                            {type.toLowerCase() === 'expert' &&
                            <div>
                                {customButton === null &&
                                <ButtonGreen
                                    TitleButton={'Send message'}
                                    EventOnClick={eventButton}
                                />
                                }
                                {customButton !== null &&
                                customButton
                                }
                            </div>


                            }
                            {type.toLowerCase() === 'startup' &&
                            <div>
                                {customButton === null &&
                                <ButtonBlack
                                    TitleButton={'Send message'}
                                    EventOnClick={eventButton}
                                />
                                }
                                {customButton !== null &&
                                customButton
                                }
                            </div>

                            }
                            {(type.toLowerCase() === 'investor' || type.toLowerCase() === 'fund' || type.toLowerCase() === 'network' || type.toLowerCase() === 'accelerator') &&
                            <div>
                                {customButton === null &&
                                <ButtonBlue
                                    TitleButton={'Send message'}
                                    EventOnClick={eventButton}
                                />
                                }
                                {customButton !== null &&
                                customButton
                                }
                            </div>
                            }


                        </div>
                    </div>
                </div>


            </div>
        );
    }
}
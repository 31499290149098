import React from 'react'
import SignupForm from './SignupForm'
import SignupPurpose from './SignupPurpose'
import SignupInterests from './SignupInterests'
import SignupPhoto from './SignupPhoto'
import PropTypes from 'prop-types'
import {signup} from '../../actions/UserSignup'
import {login} from '../../actions/Login'
import {connect} from 'react-redux'
import './auth.css'


class JoinPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      credentials:{
        file:''
      }
    }
    // this.updateCredentials = this.updateCredentials.bind(this)
  }

  nextStep = (name, data) => this.setState({credentials:{...this.state.credentials, [name]:data}, step:this.state.step+1})

  render() {
    return (
      <div className="main-bg">
        <div className="header-title">
          Step {this.state.step}/4
        </div>

        {/*<a className="top-logo">*/}
          {/*<img src="images/logo.png" alt=""/>*/}
        {/*</a>*/}
        <div className="container">
          <div className="row justify-content-center">
            {this.state.step === 1 && <SignupForm history={this.props.history} get={this.nextStep}/>}
            {this.state.step === 2 && <SignupPurpose get={this.nextStep}/>}
            {this.state.step === 3 && <SignupInterests get={this.nextStep}/>}
            {this.state.step === 4 && <SignupPhoto history={this.props.history} credentials={this.state.credentials}/>}
          </div>
        </div>
      </div>
    );
  }
}

JoinPage.propTypes = {
  history:PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login:PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired
}

export default connect(null,{signup, login})(JoinPage)
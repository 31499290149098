import React from 'react';
export default class FundWaitlist extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className='block-fund-waitlist block-page'>
                <div className='profile-dashboard-main-title font-weight-500'>Startups</div>
                <div>
                    <div className='profile-dashboard-18-title font-weight-300'><br/>Here you can manage your startups pipeline.<br/>Coming soon...</div>
                </div>
            </div>
        )
    }
}
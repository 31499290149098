import React from 'react';
import './style.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


export default class RocketDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    componentWillReceiveProps (nextProps) {

    }

    componentWillUnmount() {

    }


    render() {
        let {name, value, onChange, minDate, maxDate, dateFormat, peekNextMonth, showMonthDropdown, showYearDropdown, yearDropdownItemNumber, placeholderText, error} = this.props;

        return (
            <DatePicker
                customInput={<CustomInput error={error}/>}
                name={name}
                selected={value}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat={dateFormat}
                peekNextMonth={peekNextMonth}
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                yearDropdownItemNumber={yearDropdownItemNumber}
                placeholderText={placeholderText}
                dropdownMode="select"
            />
        );
    }
}

class CustomInput extends React.Component {

    render () {

        console.log(this.props.error);
        return (
            <div
                className={`datepicker-custom-input ${this.props.value? 'active' : ''} ${this.props.error?'error':''}`}
                onClick={this.props.onClick}
            >
                <span>{this.props.value? this.props.value : 'YYYY / MM / DD'}</span>
                <i className={`fa fa-calendar`} aria-hidden="true"/>
            </div>
        )
    }
}





import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateStartup} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import {regexUrl} from "../../../utils/RegExp";
import {showMessage} from "../../../utils/showMessage";
import {getLastProject, updateProject, getProjectAchievements, postProjectAchievements, putProjectAchievements, delProjectAchievements} from "../../../actions/Project";
import TabsCounter from "../../controls3.0/tabs/TabsCounter";

import {TYPES} from '../../../utils/staticData'
import TextArea from "../../v3/Textarea";

class Step22 extends React.Component {
    constructor(props) {
        super(props);
        let startup = props.user.startup || {};
        this.state = {
            project: props.project || {team: []},
            listParameters: [],
            startup: startup,
            types: TYPES,
            listAchievements: {},
            activeParam: {},
            activeType: {},
            newA: {
                link: '',
                description: ''
            },
            editA: {},
            errors: {},
            errors2: {},
            isActiveForm: false
        }
    }

    componentDidMount() {
        if(!this.state.project.id){
            this.props.getLastProject()
            this.getProjectAch()
        } else {
            this.getProjectAch()
        }


    }

    getProjectAch = () => {
        let obj = {}
        try {
            Promise.all(
                this.state.project.parameters.map(async (item, key) => {
                    let achievement = await getProjectAchievements(this.state.project.id, item.parameterId)
                    obj[item.parameterId] = achievement.data
                    return (achievement.data)
                })
            ).then(results => {
                this.setState({listAchievements: obj})
                this.setActiveType(this.state.project.parameters[0])
            })
        } catch (e) {
            console.log('error', e)
        }
    }


    componentWillReceiveProps (nextProps) {
        if(nextProps.project && nextProps.project.id) {
            this.setState({project: nextProps.project}, () => this.getProjectAch())
        }
    }


    create = () => {
        let {activeType, newA, errors} = this.state;
        let data = {
            parameterId: activeType.parameterId,
            achievementType: activeType.id,
            role: 'startup',
            link: newA.link,
            description: newA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors: {...this.state.errors, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})

        postProjectAchievements(this.state.project.id, data.parameterId, data)
            .then(res => {
                this.addToList(res.data)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    edit = () => {
        let {activeParam, activeType, editA} = this.state;
        let data = {
            parameterId: activeType.id,
            achievementType: activeType.id,
            role: 'startup',
            link: editA.link,
            description: editA.description
        }
        if (!regexUrl.test(data.link)) {
            this.setState({errors2: {...this.state.errors2, link : 'Wrong link format'}})
            return;
        }
        this.setState({loadingCreate: true})

        putProjectAchievements(this.state.project.id, activeType.parameterId, editA.id, data)
            .then(res => {
                this.addToList(res.data, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    delete = () => {
        delProjectAchievements(this.state.project.id, this.state.activeType.parameterId, this.state.editA.id)
            .then(res => {
                this.addToList(this.state.editA, false, true)
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loadingCreate: false})
            })
    }

    onChange = e => {
        let name = e.target.name,
            value = e.target.value;

        if(name == 'link'){
            value = value.trim()
        }

        if(name == 'description'){
            value = value.cleanWhitespace();
        }

        if(!value){
            this.setState({errors: {...this.state.errors, [name] : 'Field is required'}})
        } else {
            this.setState({errors: {...this.state.errors, [name] : ''}})
        }
        if(name == 'link') {
            if (regexUrl.test(value)) {
                this.setState({errors: {...this.state.errors, link : ''}})
            }
        }
        this.setState({newA: {...this.state.newA, [name]: value}})
    }
    onChange2 = e => {
        let name = e.target.name,
            value = e.target.value;

        if(name == 'link'){
            value = value.trim()
        }

        if(name == 'description'){
            value = value.cleanWhitespace();
        }

        if(!value){
            this.setState({errors2: {...this.state.errors2, [name] : 'Field is required'}})
        } else {
            this.setState({errors2: {...this.state.errors2, [name] : ''}})
        }
        if(name == 'link') {
            if (regexUrl.test(value)) {
                this.setState({errors2: {...this.state.errors2, link : ''}})
            }
        }
        this.setState({editA: {...this.state.editA, [name]: value}})
    }


    setActiveParam = (param) => {
        this.setState({activeParam: param})
    }
    setActiveType = (type) => {
        if(!type) type = {}
        this.setState({activeType: type})
    }

    addToList = (item, edit, _delete) => {
        console.log(item)
        let arr = this.state.listAchievements[item.parameterId]

        if(edit) {
            let index;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            if(index >= 0) arr[index] = item;
            this.setState({editA: {}})
        } else if(_delete) {
            let index ;
            arr.map((x, key) => {
                if(x.id === item.id) index = key
            })
            // if(index >= 0) delete arr[index];
            if(index >= 0) arr.splice(index, 1);


            this.setState({editA: {}})
        } else {
            arr.push(item)
        }

        this.setState({listAchievements: {...this.state.listAchievements, [item.parameterId]: arr}})
        this.handleClearNewA()
    }

    handleClearNewA = () => {
        this.setState({loadingCreate: false})
        this.setState({
            newA: {
                link: '',
                description: ''
            },
            isActiveForm: false
        })
    }




    update = (nextStep, onlyStep) => {
        let project = this.state.project;

        if(!onlyStep) this.setState({loading: true})

        this.props.updateStartup(project, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }


    render () {
        let {project, types, newA, editA, activeParam, activeType, listAchievements, errors, errors2} = this.state;

        if(!project.parameters) project.parameters = []

        let isNoEmpty;
        for (let key in listAchievements) {
            if(listAchievements[key].length) isNoEmpty = true
        }

        const dropStyle = {
            'display': 'inline-block',
            'height': 'auto',
            'margin': '0 auto',
            'border': 'none'
        }

        return (
            <React.Fragment>

                <div className="container-full-reg padding-reg" style={{position: 'relative'}}>
                    <div className="container-step-2-reg"
                         style={{position: 'relative', margin: '0 0 0 auto'}}>
                        <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15" style={{marginTop: '20px'}}>
                            Achievements
                        </p>

                        <p className="title-top-page-reg-expert text-button left-text shell-margin-bottom-30">
                            Please provide the links to websites, blog posts and news, certificates and <br/>
                            documents scan copies as proofs of the declared achievements.
                        </p>

                        <div className="block-list-tabs-counter-reg-expert">
                            {project.parameters.map((item, key) => {
                                // console.log('re render', listAchievements[item.parameterId]?  listAchievements[item.parameterId].length : 're')

                                    return (
                                        <TabsCounter
                                            key={key}
                                            TitleTab={item.title}
                                            TypeTab={'big'}
                                            EventOnClick={() => this.setState({activeType: item, isActiveForm: false})}
                                            active={activeType.id === item.id}
                                            count={listAchievements[item.parameterId] ? listAchievements[item.parameterId].length : 0}
                                        />
                                    )
                            }
                            )}
                        </div>

                        {/*<div className={'block-add-activity-reg-exp'}>*/}
                            {/*<ButtonBlue*/}
                                {/*TitleButton={`Add ${activeType.title}`}*/}
                                {/*EventOnClick={() => this.setState({isActiveForm: true})}*/}
                                {/*paddingButton={'30'}*/}
                                {/*disabled={false}*/}
                            {/*/>*/}
                        {/*</div>*/}

                        <div className="block-form-add-edit-modal-reg-expert">
                            <div className="block-input-item-form-add-edit-modal-reg-expert">
                                <p className="title">Insert the link</p>
                                <Input
                                    type={'text'}
                                    name={'link'}
                                    value={newA.link}
                                    onChange={this.onChange}
                                    placeholder={''}
                                    error={errors.link}
                                />
                            </div>
                            <div className="block-text-area-form-add-edit-modal-reg-expert">
                                <p className="title">Name the achievement</p>
                                <TextArea
                                    name={'description'}
                                    val={newA.description}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className="block-button-form-add-edit-modal-reg-expert">
                                <ButtonBlue
                                    TitleButton={`Add ${activeType.title}`}
                                    EventOnClick={() => this.create()}
                                    paddingButton={'54'}
                                    disabled={!newA.link || !newA.description}
                                    loader={this.state.loadingCreate}
                                />
                            </div>
                        </div>

                        {activeType.id &&
                        <div className="block-list-active-activity-reg-expert">
                            <div className="block-list-item-active-activity-reg-expert">
                                {listAchievements[activeType.parameterId].map((ach, k) => {
                                    if (activeType.id === ach.achievementType) {
                                        return (
                                            <React.Fragment key={k}>
                                                <div className="block-item-active-activity-reg-expert">
                                                    <div className="block-left-item-active-activity-reg-expert">
                                                        <p className="title">
                                                            {ach.link}
                                                        </p>
                                                        <p className="description">
                                                            {ach.description}
                                                        </p>
                                                    </div>
                                                    <div className="block-right-item-active-activity-reg-expert">
                                                        <p className="button-edit" onClick={() => this.setState({editA: ach})}>
                                                            Edit
                                                        </p>
                                                    </div>
                                                </div>

                                                {editA.id === ach.id &&
                                                <div className="block-form-add-edit-modal-reg-expert"
                                                     style={{marginBottom: '12px'}}>
                                                    <div
                                                        className="block-input-item-form-add-edit-modal-reg-expert">
                                                        <p className="title">Insert the link</p>
                                                        <Input
                                                            type={'text'}
                                                            name={'link'}
                                                            value={editA.link}
                                                            onChange={this.onChange2}
                                                            placeholder={''}
                                                            error={errors2.link}
                                                        />
                                                    </div>
                                                    <div
                                                        className="block-text-area-form-add-edit-modal-reg-expert">
                                                        <p className="title">Name the achievement</p>
                                                        <textarea name="description" cols="30" rows="10"
                                                                  value={editA.description}
                                                                  onChange={this.onChange2}/>
                                                    </div>
                                                    <div
                                                        className="block-button-form-add-edit-modal-reg-expert">
                                                        <ButtonBlue
                                                            TitleButton={`Edit`}
                                                            EventOnClick={() => this.edit()}
                                                            paddingButton={'54'}
                                                            disabled={!editA.link || !editA.description}
                                                        />

                                                        <p className="button-delete" onClick={() => this.delete()}>
                                                            Delete
                                                        </p>
                                                    </div>
                                                </div>
                                                }

                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        }

                    </div>
                </div>




                <div className="block-fix-button-reg-bottom fix-button-double-details-double">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(21, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-details" style={{padding: '0 16px'}}>
                            <p className="title-block-button-reg-expert">
                                This is very important, but please <br/>
                                let me fill it in later on my profile
                            </p>
                        </div>
                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(16, true)}
                                paddingButton={'35'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(16, true)}
                                paddingButton={'56'}
                                disabled={!isNoEmpty}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>



            </React.Fragment>

        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
    project: state.project.project
})

Step22.propTypes = {
    updateStartup: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateStartup, getLastProject, updateProject})(Step22)


import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import async from "async";

import {updateInvestor} from "../../../actions/Login";
import {getListFunds} from "../../../actions/Profile";
import {showMessage} from "../../../utils/showMessage";


import ButtonBlue from './../../controls3.0/button/ButtonBlue'
import ButtonGray from "../../controls3.0/button/ButtonGray";
import Input from "../../controls3.0/input/Input";
import packageJSON from './../../../../package.json'
import {getTransaction} from "../../../actions/FundActions";
import imgBG from '../../../images/main-bg.jpg'
import {redirect} from '../../../utils/innerLinks';
import i18n from "../../../i18n";


class Step16 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            investor = user.investor || {};
        this.state = {
            user: user,
            investor: investor,
            errors: {},
            foundFunds: [],
            chosenFunds: []
        }

    }

    componentDidMount() {

    }


    onChange = e => {
        let name = e.target.name,
            value = e.target.value;
            value = value.cleanWhitespace();

        if(value.length){
            this.setState({[name]: value}, () => {
                if (value) {
                    this.getByString(value)
                } else {
                    this.setState({foundFunds: []})
                }

            })
        } else {
            this.setState({foundFunds: []});
            this.setState({[name]: value});
        }
    }

    onChangeEmail = e => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({[name]: value})
    }

    getByString = str => {
        this.setState({loading2: true})
        getListFunds(str)
            .then((res) => {
                if (Array.isArray(res.data)) this.setState({foundFunds: res.data})
                this.setState({loading2: false})
            })
            .catch(err => {
                showMessage({type: 'error', data: err})
                this.setState({loading2: false})
            })
    }

    invite = () => {
        this.setState({invited: !this.state.invited})
    }

    add = fund => {
        this.setState({foundFunds: [], inputSearch: ''})
        this.setState({chosenFunds: [...this.state.chosenFunds, fund]})
    }

    update = (nextStep, onlyStep) => {
        let obj = this.state.investor;
        if (onlyStep) {
            obj = {}
            this.next(obj, onlyStep, nextStep)
        } else {
            this.setState({loading: true})
            obj.invitedEmail = this.state.email;

            async.each(this.state.chosenFunds, function (fund, callback) {
                    axios
                        .post(`/v2/funds/${fund.id}/team/join`)
                        .then(res => {
                            callback();
                        })
                }, function (err) {
                    if (err) {
                        console.log("Error grabbing data");
                    } else {
                        axios.get(`/v2/funds/invite-owner?email=${this.state.email}`)
                        this.next(obj, onlyStep, nextStep)
                    }
                }.bind(this)
            );
        }
    }

    next = (obj, onlyStep, nextStep) => {
        this.props.updateInvestor(obj, onlyStep, nextStep)
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, investor, errors, loading2} = this.state

        let foundFunds = this.state.foundFunds.map((item, key) => {
            if(item.userId !== user.id){
                return (
                    <div className="block-item-net-work-fund" key={key} onClick={() => this.add(item)}>
                        <div className="block-left-item-net-work-fund">
                            <p className="title-item-net-work-fund">{item.title}</p>
                            <p className="description-item-net-work-fund">{i18n.t('member', {count: 1 + item.teamCount})}</p>
                        </div>
                        <div className="block-right-item-net-work-fund">
                            <div className="block-img-item-net-work-fund"
                                 style={{background: 'url(' + packageJSON.proxy + '/file/' + item.logoFileId + '/view' + ')'}}/>
                        </div>
                        <div className="block-active-net-work-fund">
                            <p className="title-active-net-work-fund">This is it!</p>
                        </div>
                    </div>
                )
            }
        });

        let chosenFunds = null;
        chosenFunds = this.state.chosenFunds.map((item, key) =>
            <div className="block-item-net-work-fund" key={key}>
                <div className="block-left-item-net-work-fund">
                    <p className="title-item-net-work-fund">{item.title}</p>
                    <p className="description-item-net-work-fund">{i18n.t('member', {count: 1 + item.teamCount})}</p>
                </div>
                <div className="block-right-item-net-work-fund">
                    <div className="block-img-item-net-work-fund"
                         style={{background: 'url(' + packageJSON.proxy + '/file/' + item.logoFileId + '/view' + ')'}}/>
                </div>
                <div className="block-active-net-work-fund-del">
                    <div className="block-del-button-active-net-work-fund"/>
                </div>
            </div>
        )

        return (
            <React.Fragment>
                <div className="step-1 is-startup">

                    <div className="block-reg-startup-step-2 padding-reg">
                        <div className="container-full-reg" style={{position: 'relative'}}>


                            <div className="block-net-work-fund">
                                <div className="block-top-net-work-fund">
                                    <p className="text-h1 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                        Angel networks and venture funds
                                    </p>


                                    {!!chosenFunds.length &&
                                    <div>
                                        <p className="text-body shell-margin-bottom-15">
                                            <b>If the Fund / Network is already registered on Rocket DAO, this
                                                information will be added to your profile.</b>
                                        </p>
                                        <div className="block-list-active-net-work-fund">
                                            {chosenFunds}
                                        </div>
                                    </div>
                                    }

                                </div>
                                <div className="block-body-net-work-fund block-gradient-gray-reg">
                                    <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                        Name the networks, funds or syndicates that you belong to.
                                    </p>
                                    <div className="block-input-net-work-fund shell-margin-bottom-20">
                                        <div className="shell-input-reg">
                                            <Input
                                                type={'text'}
                                                name={'inputSearch'}
                                                value={this.state.inputSearch}
                                                onChange={this.onChange}
                                                placeholder={''}
                                                iconClass={null}
                                            />
                                        </div>
                                    </div>
                                    {!!foundFunds.length && !!this.state.inputSearch.length &&
                                    <div>
                                        <p className="text-h6 color-text-reg-gray margin-0 shell-margin-bottom-15">
                                            Hmm, we found a match already registered on the platform. Is it yours?
                                        </p>
                                        <div className="block-list-net-work-fund">
                                            {foundFunds}
                                        </div>
                                    </div>
                                    }

                                    {!foundFunds.length && !!this.state.inputSearch && !this.state.invited &&
                                    <div>
                                        <p className="text-h5 shell-margin-bottom-15">Oops, there are no matches on the
                                            platform.</p>
                                        <div className="block-no-net-work-fund">
                                            <div className="block-left-no-net-work-fund">
                                                <div className="block-title-no-net-work-fund">
                                                    <p className="title-no-net-work-fund">
                                                        Well, now you can create a new profile of the Network / Fund if
                                                        you are an official representative of it.
                                                    </p>
                                                </div>
                                                <div className="block-list-button-no-net-work-fund">
                                                    <ButtonBlue
                                                        TitleButton={'Create Fund'}
                                                        EventOnClick={() => redirect(`/create/fund/1?name=${this.state.inputSearch}`, true)}
                                                        paddingButton={'31'}
                                                        disabled={false}
                                                    />
                                                    <ButtonBlue
                                                        TitleButton={'Create Network'}
                                                        EventOnClick={() => redirect(`/create/angel_network/1?name=${this.state.inputSearch}`, true) }
                                                        paddingButton={'19'}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="block-right-no-net-work-fund">
                                                <div className="block-title-no-net-work-fund">
                                                    <p className="title-no-net-work-fund">
                                                        Or you may send an invitation to an authorized person instead.
                                                    </p>
                                                </div>
                                                <div className="block-add-invite-no-net-work-fund">
                                                    <div className="shell-input-reg">
                                                        <Input
                                                            type={'text'}
                                                            name={'email'}
                                                            value={this.state.email}
                                                            onChange={this.onChangeEmail}
                                                            placeholder={''}
                                                            iconClass={null}
                                                        />
                                                    </div>
                                                    <ButtonBlue
                                                        TitleButton={'Invite'}
                                                        EventOnClick={() => this.invite()}
                                                        paddingButton={'24'}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {!!this.state.invited &&

                                        <div style={{maxWidth:'345px'}}>

                                            <p className="text-h6 margin-0 shell-margin-bottom-10">
                                                An invitation was successfully sent. You will be notified as soon as your organization is registered.
                                            </p>
                                            {/*<p className="text-sub-title shell-margin-bottom-20">*/}
                                                {/*You will be notified if your friends register on the platform via your invitation link.*/}
                                            {/*</p>*/}
                                            <div className="block-button-small">
                                                <ButtonBlue
                                                    TitleButton={'New invite'}
                                                    EventOnClick={() => this.invite()}
                                                    paddingButton={'24'}
                                                    disabled={false}
                                                />
                                            </div>

                                        </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="block-fix-button-reg-bottom fix-button-double-details-one">
                    <div className="container-full-reg" style={{textAlign:'right'}}>
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(15, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>

                        <div className="block-details" style={{padding: '0 40px 0 0', verticalAlign: 'top'}}>
                            <ButtonGray
                                TitleButton={'Skip this step'}
                                EventOnClick={() => this.update(17, true)}
                                paddingButton={'24'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Save & Continue'}
                                EventOnClick={() => this.update(17)}
                                paddingButton={'54'}
                                disabled={!chosenFunds.length}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step16.propTypes = {
    updateInvestor: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateInvestor})(Step16)
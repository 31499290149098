import React from 'react'
import ReactModal from 'react-modal';
import './loginRegister.css'
import Loader  from './../test-controls/preloader/preloader'
import {getBGI} from "../../actions/Profile";
import Dropzone from 'react-dropzone'
import packageJSON from './../../../package.json'
import moment from "moment/moment";
import config from "../../config";



export default class ModalBGC extends React.Component {

    constructor(props){
        super();
        this.state = {
            loaded: false,
            list: []
        };
    }



    componentWillReceiveProps () {
        this.setState({errors: {}})

        getBGI(this.props.type)
            .then((res) => {
                this.setState({list: res.data, loaded: true})
            })
            .catch(error => {
                console.error('error', error.response.data.error);
                this.setState({errors: error.response.data.error, loaded: true})
            })

    }


    addFileBackground(accepted, rejected) {
        if (accepted.length) {
            this.setState({background: accepted[0]})
            this.props.setBG(accepted[0])

            // this.setState({ loaded: false })
            // sendFile(accepted[0])
            //     .then((res) => {
            //         this.setState({'backgroundImageId': res.data.id, loaded: true})
            //     })
            //     .catch(error => {
            //         this.setState({ loaded: true })
            //         console.error('error', error.response.data.error);
            //         this.setState({errors: error.response.data.error})
            //     })
        }
        if (rejected.length) {
            this.setState({errorFile: 'The uploaded file seems to have incorrect format'})
        }
    }

    setProfileBGI = (item) => {
        this.props.setBG(item)
    }



    render() {

        const dropStyle = {
            'height': '75px',
            'margin': '0 auto',
            'border': 'none',
            'display': 'block'
        }

        const list = this.state.list.map(function (item, key) {
            return (
                <div key={key} className={'block-item-img-background ' + (this.props.bgId == item ? 'active' : '')} onClick={() => this.setProfileBGI(item)}>
                    <img src={`${config.proxy}/file/${item}/view?w=150`} alt='img'></img>

                    <i className="fal fa-check-circle"></i>
                </div>
            )
        }.bind(this))


        return (

            <div>
                <ReactModal
                    isOpen={this.props.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.props.handleCloseModal}
                    className="Modal modal-login modal-background-560"
                    overlayClassName="Overlay"
                >

                    <div className="modal-login-block">
                        <div className="block-top fix">
                            <div className='sr-title'>Background Profile images </div>

                            <img src="../../img/x.svg" alt="" className="close-icon" onClick={this.props.handleCloseModal}/>
                        </div>



                        <div className="shell-list-img-background">
                            <div className="block-list-img-background">
                                {list}


                                {this.state.loaded &&
                                <div className="block-item-img-background add-new">
                                    <Dropzone style={dropStyle}
                                              multiple={false}
                                              onDrop={this.addFileBackground.bind(this)}
                                              accept="image/jpeg, image/png, image/gif"
                                    >
                                    </Dropzone>
                                </div>
                                }


                            </div>
                        </div>



                    </div>

                    <Loader
                        loaded={this.state.loaded}
                        isLocal={true}
                        style = {
                            {
                                minHeight: 'auto',
                                position: 'absolute',
                                borderRadius: '16px'
                            }
                        }
                    >
                    </Loader>

                </ReactModal>
            </div>


        )
    }
}

import React from 'react';
import "./style.css"


export default class GeneralTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <div className={`document`}>
                <h1 className="heading-1">
                    General Terms and Conditions of use
                </h1>
                <p className="description">
                    This page tells you the terms on which you may use this website (rocketdao.io) and mobile
                    applications (the "Site") and this terms are considered as a public offer. Any action the portal
                    automatically confirms agreement with the paragraphs of these terms. If you do not agree to these
                    terms and conditions of use, please refrain from using this Site.
                </p>
                <h2 className="heading-1">
                    Information about us
                </h2>
                <p className="description">
                    www.rocketdao.io is a Site operated by OU Rocketdao ("We", “Rocket DAO”). We are registered in
                    Estonia, Tallinn, Lasnamäe linnaosa, Sepapaja tn 6, 15551.
                </p>
                <h2 className="heading-1">
                    Accessing our site
                </h2>
                <p className="description">
                    Access to this Site is permitted on a temporary basis, and we reserve the right to withdraw or amend
                    the service we provide on this Site without notice. We will not be liable if for any reason our site
                    is unavailable at any time or for any period.
                </p>
                <p className="description">
                    From time to time, we may restrict your access to some or all of this Site.
                </p>
                <p className="description">
                    You are responsible for making all arrangements necessary for you to have access to this Site. You
                    are also responsible for ensuring that all persons who access this Site through your internet
                    connection are aware of these terms, and that they comply with them.
                </p>
                <h2 className="heading-1">
                    Intellectual property rights
                </h2>
                <p className="description">
                    We are the owner or the licensee of all intellectual property rights in this Site, and in the
                    material published on it. Those works are protected by copyright laws. All such rights are reserved.
                </p>
                <p className="description">
                    You must not print off copies or download any extracts from any part of the Site unless expressly
                    authorised by us to do so.
                </p>
                <p className="description">
                    If you print off, copy or download any part of our site in breach of these terms of use, your right
                    to use our site will cease immediately and you must, at our option, return or destroy any copies of
                    the materials you have made.
                </p>
                <p className="description">
                    We are not responsible for content posted by third parties on our website (including content posted
                    by investees and investors), including the content of pitches, pitch videos, images and content in
                    the discussions. If you believe that any content on our site infringes your intellectual property
                    rights, please send a letter to us with the following information: 1) evidence of your ownership; 2)
                    the exact location of the infringing content and any copies. We will review your notice and take
                    appropriate action, including removing such content where appropriate.
                </p>
                <h2 className="heading-1">
                    Reliance on information posted
                </h2>
                <p className="description">
                    Commentary and other materials posted on this Site are not intended to amount to advice. We are not
                    liable or responsible for any reliance placed on such materials by you or anyone who you may inform
                    of any of its contents.
                </p>
                <h2 className="heading-1">
                    Changes to the site
                </h2>
                <p className="description">
                    We aim to update this Site regularly, and may change the content at any time. If the need arises, we
                    may suspend access to the Site, or close it indefinitely.
                </p>
                <h2 className="heading-1">
                    Our liability
                </h2>
                <p className="description">
                    The material displayed on this Site is provided without any guarantees, conditions or warranties as
                    to its accuracy. To the extent permitted by law, we, other members of our group of companies and
                    third parties connected to us hereby expressly exclude:
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    All conditions, warranties and other terms which might otherwise be implied by statute, common law
                    or the law of equity.
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    Any liability for any direct, indirect or consequential loss or damage incurred by any user in
                    connection with our site or in connection with the use, inability to use, or results or the use of
                    this Site, any websites linked to it and any materials posted on it, including, without limitation
                    any liability for:
                </p>
                <p className="description" style={{margin:'0 0 18px 0', padding:'0 0 0 17px'}}>
                    — Loss of income or revenue; <br/>
                    — Loss of business; <br/>
                    — Loss of profits or contracts; <br/>
                    — Loss of anticipated savings; <br/>
                    — Loss of data; <br/>
                    — Loss of goodwill; <br/>
                    — Wasted management or office time;​
                </p>
                <p className="description-dots">
                    <span className="dot"/>
                    And for any other loss or damage of any kind, however arising and whether caused by tort (including
                    negligence), breach of contract or otherwise, even if foreseeable.
                </p>
                <h2 className="heading-1">
                    Information about you and your visits to our site
                </h2>
                <p className="description">
                    We process information about you in accordance with our Privacy Policy. By using this Site, you
                    consent to such processing and you warrant that all data provided by you is accurate.
                </p>
                <h2 className="heading-1">
                    Viruses, hacking and other offences
                </h2>
                <p className="description">
                    You must not misuse this Site by knowingly introducing viruses, trojans, worms, logic bombs or other
                    material which is malicious or technologically harmful (together “<b>Viruses</b>”). You must not
                    attempt to gain unauthorised access to our site, the server on which our site is stored or any
                    server, computer or database connected to this Site. You must not attack this Site via a
                    denial-of-service attack.
                </p>
                <p className="description">
                    By breaching this provision, you may commit a criminal offence under the Computer Misuse Act 1990.
                    We will report any such breach to the relevant law enforcement authorities and we will cooperate
                    with those authorities by disclosing your identity to them. In the event of such a breach, your
                    right to use this Site will cease immediately.
                </p>
                <p className="description">
                    We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that
                    may infect your computer equipment, computer programs, data or other proprietary material due to
                    your use of this Site or to your downloading of any material posted on it, or on any website linked
                    to it.
                </p>
                <h2 className="heading-1">
                    Linking to our site and scraping
                </h2>
                <p className="description">
                    You may not scrape content from this site and repost such content, either manually or automatically,
                    without our prior written consent.
                </p>
                <p className="description">
                    This site must not be framed on any other site without our prior written permission.
                </p>
                <p className="description">
                    We reserve the right to withdraw linking, scraping and framing permission without notice.
                </p>
                <p className="description">
                    If you wish to make any use of material on this Site other than that set out above, please address
                    us your request.
                </p>
                <h2 className="heading-1">
                    Links from our site
                </h2>
                <p className="description">
                    Where this Site links to other sites and resources provided by third parties, these links are
                    provided for your information only. We have no control over the contents of those sites or
                    resources, and accept no responsibility for them or for any loss or damage that may arise from your
                    use of them.
                </p>
                <h2 className="heading-1">
                    Jurisdiction and applicable law
                </h2>
                <p className="description">
                    The Estonian courts will have non-exclusive jurisdiction over any claim arising from, or related to,
                    a visit to our site although we retain the right to bring proceedings against you for breach of
                    these conditions in your country of residence or any other relevant country. We have priority
                    opportunity to choose different jurisdictions depending on the type of dispute.
                </p>
                <h2 className="heading-1">
                    Variations
                </h2>
                <p className="description">
                    We may revise these terms of use at any time by amending this page. You are expected to check this
                    page from time to time to take notice of any changes we make, as they are binding on you.
                </p>
                <h2 className="heading-1">
                    Your concerns
                </h2>
                <p className="description">
                    If you have any concerns about material which appears on our site, please contact
                    <a href="mailto:support@rocketdao.io" target="_blank"> support@rocketdao.io</a>
                </p>
                <h3 className="heading-1">
                    Thank you for visiting our site.
                </h3>
            </div>
        );
    }
}

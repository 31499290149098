import React from 'react';

import './styleViewEval.css'
import "../../../libs/grid/grid.css";

export default class ViewEvalLeftBar extends React.Component {



    render() {
        let {
            rewiewScore,
            evalClick
        } = this.props;


        return (
            <div className='expert-review-score text-align-center'>
                <div className='row-0 expert-main-score-block'>
                    <div className='row-0 review-main-title text-center color-rgba-88 font-weight-500'>Preliminary evaluation</div>
                    <div className='row-0 review-score text-center'>
                        <div className='new-col expert-main-score font-weight-500'>{rewiewScore[0]}</div>
                        <div className='new-col expert-main-score-ten' style={{marginTop:'24px'}}>/10</div>
                    </div>
                    {/*<div className='row-0 main-score-title color-rgba-88' style={{marginTop:'-4px'}}>Defined round: Seed</div>*/}
                </div>
                <div className='row-0 margin-top-10' style={{display:'inline-block'}}>
                    <div className='new-col second-score-block cursor-pointer' onClick={()=>{if(evalClick) evalClick(0)}}>
                        <div className='row-0 expert-second-score'>{rewiewScore[1]}</div>
                        <div className='row-0 second-score-title color-rgba-88'>Product</div>
                    </div>
                    <div className='new-col second-score-block cursor-pointer' onClick={()=>{if(evalClick) evalClick(1)}}>
                        <div className='row-0 expert-second-score'>{rewiewScore[2]}</div>
                        <div className='row-0 second-score-title color-rgba-88'>Market</div>
                    </div>
                    <div className='new-col second-score-block cursor-pointer' onClick={()=>{if(evalClick) evalClick(2)}}>
                        <div className='row-0 expert-second-score'>{rewiewScore[3]}</div>
                        <div className='row-0 second-score-title color-rgba-88'>B.Model</div>
                    </div>
                    <div className='new-col second-score-block cursor-pointer' onClick={()=>{if(evalClick) evalClick(3)}}>
                        <div className='row-0 expert-second-score'>{rewiewScore[4]}</div>
                        <div className='row-0 second-score-title color-rgba-88'>Team</div>
                    </div>
                </div>
            </div>
        )
    }
}
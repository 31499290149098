import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateAngel} from "../../../actions/Login";

// import ButtonBlue from './../../controls3.0/button/ButtonBlue'
// import ButtonGray from "../../controls3.0/button/ButtonGray";
import FooterImg from "../../../images/svg/RocketDAO-finish.svg";

class Step18 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            angel = props.angel || {};
        this.state = {
            user: user,
            angel: angel,
            errors: {}
        }
    }

    componentDidMount() {
        // this.update(1, true);
    }

    onChange = (e) => {
        let name = e.target.name,
            value = e.target.value;

        this.setState({angel: {...this.state.angel, [name]: value}})
    }


    update = (nextStep, onlyStep) => {
        let obj = this.state.angel;
        obj.isFinished = true;

        if (onlyStep) {
            obj = {}
        }

        if (!onlyStep) this.setState({loading: true})
        const fundId = this.state.angel.id;
        this.props.updateAngel(obj, fundId, onlyStep, nextStep)
            .then(r => {
                window.location.href = '/';
            })
            .catch(err => {
                this.setState({loading: false})
            })
    }

    render() {
        let {user, angel, errors} = this.state

        return (
            <React.Fragment>
                <div className="container-step-1-reg"
                     style={{position: 'relative', margin: '0 0 0 auto'}}>
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-15">
                        We welcome you to Rocket DAO!
                    </p>

                    <p className="title-top-page-reg-expert text-h6 left-text shell-margin-bottom-15">
                        Your angel network {angel.title} <br/>
                        has been successfully registered on our platform!
                    </p>
                    <p className="title-top-page-reg-expert text-body left-text shell-margin-bottom-60">
                        It is a pleasure for us to welcome you on Rocket DAO.
                        In case of any queries regarding the platform's tools please feel free to contact us and we will
                        gladly assist you.
                        <br/><br/>
                        Best regards, Rocket DAO team.
                    </p>

                    <div className="shell-button-reg-expert">
                        <div className="block-button-big-reg-expert" style={{width: '240px'}}
                             onClick={() => window.location.href='/'}>
                            <p className="title">Go to rocketdao.io</p>
                        </div>
                    </div>
                </div>
                <div className={'block-img-footer'} >
                    <img src={FooterImg} style={{position:'relative'}} alt="footer_img"/>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    angel: state.angel
})

Step18.propTypes = {
    updateAngel: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateAngel})(Step18)
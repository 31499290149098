import React from 'react';
import {connect} from "react-redux";
import ButtonGreen from '../controls3.0/button/ButtonGreen'
import _bg from './pic/bg.jpg'
import avatar from './pic/profilephoto.webp';
import './style.css'
import TabsCounter from '../controls3.0/tabs/TabsCounter'
import $ from "jquery";
import packageJSON from './../../../package.json'
import Loader from '../test-controls/preloader/preloader'
import axios from "axios";
import {getAchievementExpert, tokenLogin} from "../../actions/Login";
import EditMyPageButton from '../v3/editMyPage';


import {Comments} from '../v3/comments';
import {createAvatar} from "../user/createAvatar";
import MetaTags from "react-meta-tags";
import history from '../../history';
import {UserLang} from "../v3/userLang";
import HeaderStickyRole from "../controls3.0/headerStickyRole";
import {getShortLang, isExist} from "../../utils/helper";
import {viewCounter} from "../../utils/viewCounter";
import LoginPopup from "../v4/loginPopup";
import {VerificationIcon} from "../v4/VerificationIcon";

const TYPES = [];
TYPES[0] = {
    id: 0,
    title: 'Articles',
    description: 'Links to your articles, as well as to documents containing your articles, scientific researches and other papers.'
};
TYPES[1] = {id: 1, title: 'Blog', description: 'Links to your professional blogs (including video blogs)'};
TYPES[2] = {
    id: 2,
    title: 'Diploma',
    description: 'Documents and any documentary proof of your educational background.'
};
TYPES[3] = {
    id: 3,
    title: 'Projects',
    description: 'Documents and links to the projects which you contributed to as an expert in a concrete evaluation parameter.'
};
TYPES[4] = {
    id: 4,
    title: 'Mass media',
    description: 'Links to the articles about you or about your professional experience.'
};
TYPES[5] = {
    id: 5,
    title: 'Recommendations',
    description: 'Reviews of your expert works and professional collaboration.'
}


const expertParams = {
    23: { //
        7: { //Team
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        4: { //Tokenomics
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    },
    69: { // Дробышевский
        10: { //id	10 title	Market
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        2: { //id	2 title	Marketing and PR
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    28: { // Koltun
        3: { //id	3 title	Technologies
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    25: { // Mikhailov
        8: { //id	8 title	Risk
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        30: { //id	8 title	fina
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    31: { // Kuryan
        9: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        },
        1: { //id	9 title	Product
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    216: { // Sanchuk
        6: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 1,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    30: { // Grablevski
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    34: { // Inga
        4: { //id	6 title	Legal
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 0,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 1,
                Since: 0
            },
            line: 100
        }
    },
    221: { // Torri
        1: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    252: { // Skvorc
        9: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 0,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 10
        }
    },
    327: { // Dominik Franek
        10: { //id	1 title	Business model
            Expert: {
                Projects_evaluated: 0,
                Platform_task: 1,
                Since: 0
            },
            Supervisor: {
                Experts_evaluated: 0,
                Since: 0
            },
            Methodologist: {
                Methodologies_developed: 1,
                Methodologies_in_use: 0,
                Since: 0
            },
            line: 100
        }
    }
}


Date.prototype.toShortFormat = function () {

    const month_names = ["Jan", "Feb", "Mar",
        "Apr", "May", "Jun",
        "Jul", "Aug", "Sep",
        "Oct", "Nov", "Dec"];

    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();

    return "" + day + " " + month_names[month_index] + " " + year;
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expert: {},
            user: props.user || {},
            logo: avatar,
            listAchievements: [],
            bg: _bg,
            experience: [],
            id: this.props.id || props.match.params.id,
            activeAchivements: '',
            comments: [],
            currentComment: '',
            showWork: false,
            activeSticky: false,
            showLoginPopup: 'hide'
        }
        this.OpenCloseContentClick = this.OpenCloseContentClick.bind(this);
        this.onActiveAchivementsu = this.onActiveAchivementsu.bind(this);
    }

    getParamVal(expertId, paramId, type, ach, line) {
        try {
            if (!line)
                return expertParams[expertId][paramId][type][ach];
            else
                return expertParams[expertId][paramId].line;
        } catch (e) {
            return 0;
        }
    }

    componentDidMount() {

        if( !viewCounter() ) {
            if(this.props.id){

                const commandProps = {
                    to: '/',
                    id: this.props.id,
                    type: 'expert',
                    f: 'tpc'
                };

                localStorage.removeItem("rFrom");
                localStorage.removeItem("referer");
                localStorage.removeItem("referer_startup");

                localStorage.setItem("commandProps", JSON.stringify(commandProps));

                this.setState({showLoginPopup: 'content'});
            } else {
                this.setState({showLoginPopup: 'popup'});
            }
        }

        axios.get(`/v2/experts/${this.state.id}`)
            .then(r => {
                this.setState({expert: r.data});
                const logo = r.data.role.logoFileId ? `${packageJSON.proxy}/file/${r.data.role.logoFileId}/view` : avatar;
                const bg = r.data.role.backgroundImageId ? `${packageJSON.proxy}/file/${r.data.role.backgroundImageId}/view` : _bg;
                this.setState({logo, bg});

                try {
                    let obj = [];
                    Promise.all(
                        r.data.parameters.map(async (item, key) => {
                            let achievement = await getAchievementExpert('expert', item.parameterId, this.state.expert.userId);
                            obj[item.parameterId] = [];
                            achievement.data.map((a => {
                                if (!obj[item.parameterId][a.achievementType]) {
                                    obj[item.parameterId][a.achievementType] = [a];
                                } else {
                                    obj[item.parameterId][a.achievementType].push(a);
                                }
                            }));

                            return (achievement.data)
                        })
                    ).then(results => {
                        this.setState({listAchievements: obj})
                    })
                } catch (e) {
                    console.log(e);
                }

                axios
                    .get(`/users/${this.state.expert.userId}/experience`)
                    .then(r => {
                        this.setState({experience: r.data})
                    })

            }).catch(e => {
            if (e.response.status === 404) {
                history.push('/404');
            }
        })
        // this.stickyHeaderCart();
        if(process.env.NODE_ENV !== 'production'){
            window.addEventListener('scroll',this.stickyBG);
        }
    }
    componentWillUnmount() {
        if(process.env.NODE_ENV !== 'production') {
            window.removeEventListener('scroll', this.stickyBG);
        }
    }

    // stickyHeaderCart = () => {
    //     let timer = setInterval(() => {
    //
    //         if (window.pageYOffset > 200) {
    //             this.setState({activeSticky: true})
    //         } else {
    //             this.setState({activeSticky: false})
    //         }
    //         // clearInterval(timer);
    //     }, 250);
    //
    // }
    stickyBG = () => {
        if (window.pageYOffset > 174) {
            $('.card-wrapper').addClass('on-scroll');
            this.setState({activeSticky:true})
        } else {
            $('.card-wrapper').removeClass('on-scroll');
            this.setState({activeSticky:false})
        }
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.id) {
            if (nextProps.id != this.state.id) {
                const cleanState = {
                    expert: {},
                    listAchievements: [],
                    experience: [],
                    activeAchivements: '',
                    comments: [],
                    currentComment: '',
                    showWork: false
                }

                this.setState({...cleanState, ...nextProps}, () => {
                    this.componentDidMount();
                })
            }
        } else {
            if (nextProps.id != this.state.id) {
                this.setState({user: nextProps.user})
            }
        }
    }

    OpenCloseContentClick(e, classBlock) {

        if ('.open-close-block' === classBlock) {
            this.setState({showWork: !this.state.showWork});
        }

        $(e.currentTarget).parent().children(classBlock).toggle('normal');
        if ($(e.currentTarget).parent().hasClass('active')) {
            $(e.currentTarget).parent().removeClass('active')
        } else {
            $(e.currentTarget).parent().addClass('active')
        }


        const current = e.currentTarget.getAttribute('data-pId');

        if (current) { //for Achivments
            const keys = Object.keys(this.state.listAchievements[current]);

            if (keys.length) {
                this.setState({
                    activeAchivements: TYPES[keys[0]].title
                });
            }
        }
    }

    onActiveAchivementsu(e) {
        this.setState({
            activeAchivements: e
        });
    }

    getImgAlt = (title) => {
        let replaced = title.replace(/ /gi, '_');
        return replaced;
    };


    render() {
        const {expert, logo, experience, listAchievements, comments, user} = this.state;

        const _avatar = (expert.role && expert.role.logoFileId) ?
            <div className="photo-cart-expert"
                 style={{backgroundImage: `url(${`${packageJSON.proxy}/file/${expert.role.logoFileId}/view?W=200`})`}}>
                <img src={`${packageJSON.proxy}/file/${expert.role.logoFileId}/view?W=200`}
                     alt={'expert ' + this.getImgAlt(expert.role.firstName + ' ' + expert.role.lastName)}/>
            </div>
            :
            createAvatar('photo-cart-expert', expert.role ? expert.role.firstName : 'R');

        if (!expert.id) {
            return (<React.Fragment>
                <Loader loaded={!!expert.id} isLocal={!!this.props.id}/>
            </React.Fragment>);
        } else {
            return (
                <React.Fragment>
                    {!this.props.id && <MetaTags>
                        <title>{'Expert ' + this.getImgAlt(expert.role.firstName + ' ' + expert.role.lastName) + ' - Rocket DAO - uniting startups, investors and experts'}</title>
                        <meta property="og:title"
                              content={expert.role.firstName + ' ' + expert.role.lastName + ' - Rocket DAO - uniting startups, investors and experts'}/>
                        <meta name="description" content={expert.professionalDescription}/>
                        <meta property="og:description" content={expert.professionalDescription}/>
                    </MetaTags>}


                    {this.state.showLoginPopup !== 'content' && <div className='card-wrapper'>

                        <div className={`block-sticky-role-cart ${this.state.activeSticky ? 'active' : ''}`}>
                            <HeaderStickyRole

                                name={expert.role.firstName + ' ' + expert.role.lastName}
                                urlAvatar={(expert.role && expert.role.logoFileId) ? `${packageJSON.proxy}/file/${expert.role.logoFileId}/view?W=80` : null}


                                type={'expert'} // expert, fund, investor, startup
                                titleType={null}
                                description={expert.professionalIdentification}
                                location={expert.role.country}
                                languageOrSize={expert.role.lang ? expert.role.lang.map(lang => getShortLang(lang)).join(', ') : 'no information'}
                                eventButton={null} // only startup
                                customButton={((user && user.id !== expert.userId) || !user) &&
                                <ButtonGreen
                                    TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                    EventOnClick={null}
                                    paddingButton={'0'}
                                    disabled={true}
                                    onMouseEnter={() => this.setState({mouseEnter: true})}
                                    onMouseLeave={() => this.setState({mouseEnter: false})}
                                /> || <EditMyPageButton type={'expert'}/>}


                            />

                        </div>


                        <div className="block-background-expert">
                            <img src={this.state.bg} alt={'load'}/>
                        </div>
                        
                        <div className="block-container-expert">
                            <div className="block-box-shadow-cart-startup"/>


                            <div className="block-cart-expert-mobile">
                                <div className="block-top-cart-expert-mobile">
                                    <div className="block-avatar-name-top-cart-expert-mobile">
                                        <div className="block-left-top-cart-expert-mobile">
                                            {_avatar}
                                        </div>
                                        <div className="block-right-top-cart-expert-mobile">
                                            <h1 className="title-top-cart-expert-mobile">
                                                {expert.role.firstName} {expert.role.lastName}
                                                <VerificationIcon type={'expert'} className='sticky'/>
                                            </h1>
                                            <span className="round-prof expert">
                                                Expert
                                            </span>
                                        </div>
                                    </div>
                                    <p className="description-top-cart-expert-mobile">
                                        {expert.professionalDescription}
                                    </p>
                                </div>
                                <div className="block-body-cart-expert-mobile">
                                    <div className="block-list-button-cart-expert-mobile">
                                        {((user && user.id !== expert.userId) || !user) &&
                                        <ButtonGreen
                                            TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                            EventOnClick={null}
                                            paddingButton={'26'}
                                            disabled={true}
                                            onMouseEnter={() => this.setState({mouseEnter: true})}
                                            onMouseLeave={() => this.setState({mouseEnter: false})}
                                        /> || <EditMyPageButton type={'expert'}/>}
                                    </div>
                                    <div className="block-list-state-cart-expert-mobile">
                                        <div className="block-title-description-left-expert">
                                            <p className="title-l">Location</p>
                                            <p className="description-l">{expert.role.country}</p>
                                        </div>
                                        <UserLang data={expert.role.lang || []}/>
                                    </div>
                                    <div className="block-list-info-cart-expert-mobile">
                                        <div className="block-skills-expert">
                                            <p className="title-block-expert">
                                                Professional skills
                                            </p>
                                            <p className="list-skills-expert">
                                                {expert.role.Skills.map(skill => {
                                                    return <span className="skill">{skill.title}</span>
                                                })}
                                            </p>
                                        </div>

                                        <div className="block-work-experience">
                                            {/*<p className="title-block-expert">*/}
                                            {/*Work experience*/}
                                            {/*</p>*/}

                                            {!!experience.length ?
                                                <div className="block-work-experience">
                                                    <p className="title-block-expert">
                                                        Work experience
                                                    </p>

                                                    {
                                                        experience.slice(0, 3).map((exp => {
                                                            return <div className="block-item-work-experience">
                                                                <div style={{display: 'table', width: '100%'}}
                                                                     onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                    <div className="block-left-work-experience">
                                                                        <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                    </div>
                                                                    <div className="block-center-work-experience">
                                                                        <p className="description-work-experience">
                                                                            <h2>{exp.position}</h2>
                                                                            — <span
                                                                            className="gray">{exp.company}</span></p>
                                                                    </div>
                                                                    <div className="block-right-work-experience">
                                                                        <div
                                                                            className="block-button-open-close-work-experience">
                                                                            <i className="far fa-chevron-down"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="open-close-block-description-item-work-experience">
                                                                    <div
                                                                        className="block-description-item-work-experience">
                                                                        <div className="block-left-work-experience">
                                                                            <p className="title-work-experience">
                                                                                {exp.city}
                                                                            </p>
                                                                        </div>
                                                                        <div className="block-center-work-experience">
                                                                            {!!exp.companyLocation &&
                                                                            <a href="" className="investor">
                                                                                {exp.companyLocation}
                                                                            </a>
                                                                            }

                                                                            <p className="description-work-experience">
                                                                                {exp.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }))
                                                    }

                                                    {experience.length > 3 ?
                                                        <div className="1block-item-work-experience">
                                                            <div className="open-close-block">
                                                                {
                                                                    experience.slice(3, experience.length).map((exp => {
                                                                        return <div
                                                                            className="block-item-work-experience">
                                                                            <div style={{
                                                                                display: 'table',
                                                                                width: '100%'
                                                                            }}
                                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                                <div
                                                                                    className="block-left-work-experience">
                                                                                    <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-center-work-experience">
                                                                                    <p className="description-work-experience">{exp.position} — <span
                                                                                        className="gray">{exp.company}</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-right-work-experience">
                                                                                    <div
                                                                                        className="block-button-open-close-work-experience">
                                                                                        <i className="far fa-chevron-down"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="open-close-block-description-item-work-experience">
                                                                                <div
                                                                                    className="block-description-item-work-experience">
                                                                                    <div
                                                                                        className="block-left-work-experience">
                                                                                        <p className="title-work-experience">
                                                                                            {exp.city}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="block-center-work-experience">
                                                                                        {!!exp.companyLocation &&
                                                                                        <a href="" className="investor">
                                                                                            {exp.companyLocation}
                                                                                        </a>
                                                                                        }

                                                                                        <p className="description-work-experience">
                                                                                            {exp.description}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }))
                                                                }
                                                            </div>
                                                            <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')}
                                                               className="show-all-work-experience investor">
                                                                {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                                {!this.state.showWork ? `(+${experience.length - 3})` : ''}
                                                            </a>
                                                        </div>
                                                        : ''
                                                    }
                                                </div> : ''
                                            }

                                            {/*{*/}
                                            {/*experience.map((exp => {*/}
                                            {/*return <div className="block-item-work-experience">*/}
                                            {/*<div style={{display: 'table', width: '100%'}}*/}
                                            {/*onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>*/}
                                            {/*<div className="block-left-work-experience">*/}
                                            {/*<p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>*/}
                                            {/*</div>*/}
                                            {/*<div className="block-center-work-experience">*/}
                                            {/*<p className="description-work-experience">{exp.position} — <span*/}
                                            {/*className="gray">{exp.company}</span></p>*/}
                                            {/*</div>*/}
                                            {/*<div className="block-right-work-experience">*/}
                                            {/*<div className="block-button-open-close-work-experience">*/}
                                            {/*<i className="far fa-chevron-down"/>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            {/*<div className="open-close-block-description-item-work-experience">*/}
                                            {/*<div className="block-description-item-work-experience">*/}
                                            {/*<div className="block-left-work-experience">*/}
                                            {/*<p className="title-work-experience">*/}
                                            {/*{exp.city}*/}
                                            {/*</p>*/}
                                            {/*</div>*/}
                                            {/*<div className="block-center-work-experience">*/}
                                            {/*{!!exp.companyLocation &&*/}
                                            {/*<a href="">*/}
                                            {/*{exp.companyLocation}*/}
                                            {/*</a>*/}
                                            {/*}*/}
                                            {/*<p className="description-work-experience">*/}
                                            {/*{exp.description}*/}
                                            {/*</p>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            {/*</div>*/}
                                            {/*}))*/}
                                            {/*}*/}

                                            {/*{experience.length > 3 ?*/}
                                            {/*<a href="" className="show-all-work-experience">Show all work experience*/}
                                            {/*({experience.length})</a> : ''}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="block-cart-expert">
                                <div className="block-left-cart-expert">
                                    <div className="block-photo-cart-expert">
                                        {/*<div className="photo-cart-expert" style={{background: `url(${logo})`}}/>*/}
                                        {_avatar}
                                    </div>
                                    <div className="block-title-description-left-expert">
                                        <p className="title-l">Location</p>
                                        <p className="description-l">{expert.role.country}</p>
                                    </div>
                                    <UserLang data={expert.role.lang || []}/>
                                </div>
                                <div className="block-right-cart-expert">
                                    <div className="block-info-cart-expert">
                                        <p className="name-expert">
                                            <h1>{expert.role.firstName} {expert.role.lastName}</h1>
                                            <VerificationIcon type={'expert'} className='sticky'/>
                                        </p>
                                        <p className="title-prof">
                                        <span className="round-prof expert">
                                            Expert
                                        </span>
                                            {expert.professionalIdentification}
                                        </p>
                                        <h2 className="description-expert">
                                            {expert.professionalDescription}
                                        </h2>

                                        {((user && user.id !== expert.userId) || !user) && <ButtonGreen
                                            TitleButton={this.state.mouseEnter ? 'Coming soon' : 'Send message'}
                                            EventOnClick={null}
                                            paddingButton={'26'}
                                            disabled={true}
                                            onMouseEnter={() => this.setState({mouseEnter: true})}
                                            onMouseLeave={() => this.setState({mouseEnter: false})}
                                        /> || <EditMyPageButton type={'expert'}/>}


                                    </div>
                                    <div className="block-skills-expert">
                                        <p className="title-block-expert">
                                            Professional skills
                                        </p>
                                        <p className="list-skills-expert">
                                            {expert.role.Skills.map(skill => {
                                                return <span className="skill">{skill.title}</span>
                                            })}
                                        </p>
                                    </div>

                                    <div className="block-work-experience">
                                        {/*<p className="title-block-expert">*/}
                                        {/*Work experience*/}
                                        {/*</p>*/}

                                        {!!experience.length ?
                                            <div className="block-work-experience">
                                                <p className="title-block-expert">
                                                    Work experience
                                                </p>

                                                {
                                                    experience.slice(0, 3).map((exp => {
                                                        return <div className="block-item-work-experience">
                                                            <div style={{display: 'table', width: '100%'}}
                                                                 onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                <div className="block-left-work-experience">
                                                                    <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                </div>
                                                                <div className="block-center-work-experience">
                                                                    <p className="description-work-experience">
                                                                        <h2>{exp.position}</h2>
                                                                        — <span
                                                                        className="gray">{exp.company}</span></p>
                                                                </div>
                                                                <div className="block-right-work-experience">
                                                                    <div
                                                                        className="block-button-open-close-work-experience">
                                                                        <i className="far fa-chevron-down"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="open-close-block-description-item-work-experience">
                                                                <div className="block-description-item-work-experience">
                                                                    <div className="block-left-work-experience">
                                                                        <p className="title-work-experience">
                                                                            {exp.city}
                                                                        </p>
                                                                    </div>
                                                                    <div className="block-center-work-experience">
                                                                        {!!exp.companyLocation &&
                                                                        <a href="" className="investor">
                                                                            {exp.companyLocation}
                                                                        </a>
                                                                        }

                                                                        <p className="description-work-experience">
                                                                            {exp.description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }))
                                                }

                                                {experience.length > 3 ?
                                                    <div className="1block-item-work-experience">
                                                        <div className="open-close-block">
                                                            {
                                                                experience.slice(3, experience.length).map((exp => {
                                                                    return <div className="block-item-work-experience">
                                                                        <div style={{display: 'table', width: '100%'}}
                                                                             onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block-description-item-work-experience')}>
                                                                            <div className="block-left-work-experience">
                                                                                <p className="title-work-experience">{new Date(exp.periodFrom).getFullYear()} - {exp.currentJob ? 'Present' : new Date(exp.periodTo).getFullYear()}</p>
                                                                            </div>
                                                                            <div
                                                                                className="block-center-work-experience">
                                                                                <p className="description-work-experience">{exp.position} — <span
                                                                                    className="gray">{exp.company}</span>
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className="block-right-work-experience">
                                                                                <div
                                                                                    className="block-button-open-close-work-experience">
                                                                                    <i className="far fa-chevron-down"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="open-close-block-description-item-work-experience">
                                                                            <div
                                                                                className="block-description-item-work-experience">
                                                                                <div
                                                                                    className="block-left-work-experience">
                                                                                    <p className="title-work-experience">
                                                                                        {exp.city}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-center-work-experience">
                                                                                    {!!exp.companyLocation &&
                                                                                    <a href="" className="investor">
                                                                                        {exp.companyLocation}
                                                                                    </a>
                                                                                    }

                                                                                    <p className="description-work-experience">
                                                                                        {exp.description}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }))
                                                            }
                                                        </div>
                                                        <a onClick={(e) => this.OpenCloseContentClick(e, '.open-close-block')}
                                                           className="show-all-work-experience investor">
                                                            {this.state.showWork ? 'Show less' : 'Show all work experience'}
                                                            {!this.state.showWork ? `(+${experience.length - 3})` : ''}
                                                        </a>
                                                    </div>
                                                    : ''
                                                }
                                            </div> : ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="block-body-expert">
                                {
                                    expert.parameters.map(parameter => {

                                        if(isExist(() => listAchievements[parameter.parameterId].length))
                                            return <div className="block-cart-achievements">
                                            <div className="block-top-cart-achievements"
                                                 onClick={(e) => this.OpenCloseContentClick(e, '.block-body-cart-achievements')}
                                                 data-pId={parameter.parameterId}
                                            >
                                                <div className="block-rating-cart-achievements">
                                                    <span className="rating-cart-achievements">0</span>
                                                </div>
                                                <div className="block-title-cart-achievements">
                                                    {/*<p className="title-cart-achievements">{parameter.RoleParameter.title}</p>*/}
                                                    <h2 className="title-cart-achievements">{parameter.RoleParameter.title}</h2>
                                                </div>
                                                <div className="block-progress-cart-achievements">
                                                    <div className="block-progress-bar-cart-achievements">
                                                        <div className="block-progress-cart-achievements expert"
                                                             style={{width: `${this.getParamVal(expert.id, parameter.RoleParameter.id, '', '', true)}%`}}/>
                                                    </div>
                                                </div>
                                                <div className="block-description-cart-achievements">
                                                    <p className="description-cart-achievements">Achievements: {
                                                        listAchievements[parameter.parameterId] ?
                                                            listAchievements[parameter.parameterId].reduce((sum, element) => sum + element.length, 0) : ''}</p>
                                                </div>
                                                <div className="block-button-open-close-cart-achievements">
                                                    <i className="far fa-chevron-down"/>
                                                </div>
                                            </div>

                                            <div className="block-body-cart-achievements">
                                                <div className="block-list-state-cart-achievements">
                                                    <div className="block-left-state-cart-achievements">
                                                        <p className="title-state-cart-achievements">
                                                            Expert
                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Projects evaluated
                                                            <span
                                                                className="rating">{this.getParamVal(expert.id, parameter.RoleParameter.id, 'Expert', 'Projects_evaluated')}</span>
                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Platform task
                                                            <span
                                                                className="rating">{this.getParamVal(expert.id, parameter.RoleParameter.id, 'Expert', 'Platform_task')}</span>
                                                        </p>
                                                        <p className="description-state-cart-achievements">

                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Since
                                                            <span className="rating">2019</span>
                                                        </p>
                                                    </div>
                                                    <div className="block-center-state-cart-achievements">
                                                        <p className="title-state-cart-achievements">
                                                            Supervisor
                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Experts evaluated
                                                            <span
                                                                className="rating expert">{this.getParamVal(expert.id, parameter.RoleParameter.id, 'Supervisor', 'Experts_evaluated')}</span>
                                                        </p>
                                                        <p className="description-state-cart-achievements">

                                                        </p>
                                                        <p className="description-state-cart-achievements">

                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Since
                                                            <span className="rating">2019</span>
                                                        </p>
                                                    </div>
                                                    <div className="block-right-state-cart-achievements">
                                                        <p className="title-state-cart-achievements">
                                                            Methodologist
                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Methodologies developed
                                                            <span
                                                                className="rating">{this.getParamVal(expert.id, parameter.RoleParameter.id, 'Methodologist', 'Methodologies_developed')}</span>
                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Methodologies in use
                                                            <span
                                                                className="rating">{this.getParamVal(expert.id, parameter.RoleParameter.id, 'Methodologist', 'Methodologies_in_use')}</span>
                                                        </p>
                                                        <p className="description-state-cart-achievements">

                                                        </p>
                                                        <p className="description-state-cart-achievements">
                                                            Since
                                                            <span className="rating">2019</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="block-list-achievements-cart-achievements">
                                                    <p className="title-list-achievements-cart-achievements">
                                                        Achievements
                                                    </p>
                                                    <div className="list-achievements-tab">
                                                        {
                                                            (() => {
                                                                if (listAchievements.length && listAchievements[parameter.parameterId]) {
                                                                    return listAchievements[parameter.parameterId].map((item, key) => {
                                                                        return <TabsCounter
                                                                            TitleTab={TYPES[key].title}
                                                                            TypeTab={'big'}
                                                                            EventOnClick={() => this.onActiveAchivementsu(TYPES[key].title)}
                                                                            active={this.state.activeAchivements === TYPES[key].title ? true : false}
                                                                            count={item.length}
                                                                        />
                                                                    })
                                                                }
                                                            })()
                                                        }
                                                    </div>

                                                    {
                                                        (() => {
                                                            if (listAchievements.length && listAchievements[parameter.parameterId]) {
                                                                return listAchievements[parameter.parameterId].map((item, key) => {
                                                                    let inItems = null;
                                                                    if (this.state.activeAchivements === TYPES[key].title) {
                                                                        inItems = item.map((j, i) => {
                                                                            return <div
                                                                                className={`cursor-pointer block-item-achievements-cart-share ${i === 0 ? 'active' : ''}`}
                                                                                onClick={() => {
                                                                                    window.open(j.link.toUrl())
                                                                                }}>
                                                                                <div
                                                                                    className="block-left-achievements-cart-share">
                                                                                    <p className="title-achievements-cart-share">
                                                                                        {j.description}
                                                                                    </p>
                                                                                </div>
                                                                                <div
                                                                                    className="block-right-achievements-cart-share">
                                                                                    <a href={j.link.toUrl()}
                                                                                       rel="noopener noreferrer"
                                                                                       target="_blank"> <i
                                                                                        className="fas fa-share-square"/>
                                                                                    </a>
                                                                                </div>
                                                                            </div>


                                                                        });
                                                                    } else {
                                                                        return null
                                                                    }
                                                                    return <div
                                                                        className="list-achievements-cart-share">
                                                                        {inItems}
                                                                    </div>
                                                                })
                                                            }
                                                        })()
                                                    }
                                                </div>


                                            </div>
                                        </div>;
                                        else return null;
                                    })
                                }
                            </div>
                            <div className="block-social-link-expert">

                                <p className="title-social-link">
                                    Follow me:

                                    {expert.role.twitterLink ? <a target="_blank" href={expert.role.twitterLink.toUrl()}><i
                                        className="fab fa-twitter"/></a> : ''}
                                    {expert.role.facebookLink ? <a target="_blank" href={expert.role.facebookLink.toUrl()}><i
                                        className="fab fa-facebook"/></a> : ''}
                                    {expert.role.linkedInLink ? <a target="_blank" href={expert.role.linkedInLink.toUrl()}><i
                                        className="fab fa-linkedin"/></a> : ''}
                                    {expert.role.mediumLink ? <a target="_blank" href={expert.role.mediumLink.toUrl()}><i
                                        className="fab fa-medium"/></a> : ''}
                                    {expert.role.telegramLink ? <a target="_blank" href={expert.role.telegramLink.toUrl()}><i
                                        className="fab fa-telegram"/></a> : ''}
                                    {expert.role.contacts && <div>
                                        {expert.role.contacts.map((contact) => {
                                            let classn = 'fa fa-link';
                                            if (/twitter/.test(contact)) {
                                                classn = 'fa-twitter'
                                            }
                                            if (/facebook/.test(contact)) {
                                                classn = 'fa-facebook'
                                            }
                                            if (/linked/.test(contact)) {
                                                classn = 'fa-linkedin'
                                            }
                                            if (/medium/.test(contact)) {
                                                classn = 'fa-medium'
                                            }
                                            if (/telegram/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }
                                            if (/t.me/.test(contact)) {
                                                classn = 'fa-telegram'
                                            }

                                            return <a href={contact.toUrl()} target="_blank"><i
                                                className={`fab ${classn}`}/></a>
                                        })}
                                    </div>}


                                </p>
                                <p className="description-social-link">Registered {new Date(expert.createdAt).toShortFormat()}</p>
                            </div>

                            <Comments id={expert.id} user={user} type='expert'/>
                        </div>
                    </div>}
                    {this.state.showLoginPopup !== 'hide' && <LoginPopup
                        close={()=>{
                            this.setState({showLoginPopup:'hide'});
                            if(!localStorage.RocketToken){
                                history.push('/');
                            }
                        }}
                        state={this.state.showLoginPopup}
                    />}
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    expert: state,
})

Main.propTypes = {
    // getExpert: PropTypes.func.isRequired
    // updateFund: PropTypes.func.isRequired,
    // getLastFund: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {tokenLogin})(Main)
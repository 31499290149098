import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux"
import {logout, tokenLogin, updateUser} from '../../actions/Login'
import Loader from './../test-controls/preloader/preloader'
import '../test-controls/Page2.0/css/style.css'
import '../test-controls/Page2.0/css/StartPage.css'
import '../home/home.css'
import '../css/grid.css'
import '../css/container.css'
import '../css/Input.css'
import './styleForum.css'
import ButtonTransBlue2 from '../controls2.0/Button2.0/ButtonTransBlue2.0'
import {getListSections, deleteSection, editSection,  deleteThread} from '../../actions/Forum'
import ModalNewSection from './modalNewSection'
import ModalNewThread from './modalNewThread'
import moment from "moment/moment";
import MetaTags from 'react-meta-tags';
import { checkRegistration, checkAccess} from "../../utils/checkRegistration";

const ISADMIN = localStorage.getItem("isAdmin");

class ForumPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            listSections: [],
            showModalNewSection: false,
            showModalNewThread: false,
            typeModal: '',
            editiableSection: {},
            loaded: true
        };
    }

    componentDidMount() {
        if (localStorage.RocketToken) {
            this.props.tokenLogin(localStorage.RocketToken)
        }
        this.getListSections()
    }

    getListSections = () => {
        this.setState({loaded: false})
        getListSections()
            .then((res) => {
                this.setState({listSections: res.data})
                this.setState({loaded: true})
            })
            .catch(error => {
                this.setState({loaded: true})
            })
    }

    handleShowModalNewSection = () => {
        this.setState({showModalNewSection: true})
    }
    handleShowModalNewThread = (section) => {
        let user = this.props.user;
        let hasAccess = checkAccess(user, 'expert')
        if(hasAccess) {
            this.setState({sectionId: section.id ,showModalNewThread: true})
        } else {
            checkRegistration(user, 'expert')
        }
    }

    handleCloseModal = () => {
        this.setState({showModalNewSection: false, showModalNewThread: false})
    }

    addNewSection = (item) => {
        let listSections = this.state.listSections
        listSections.push(item)
        this.setState({listSections: listSections})
        this.handleCloseModal()
    }

    addNewThread = (item) => {
        console.log(item)
        this.getListSections()
        this.handleCloseModal()
    }

    openThree = (section, thread, e) => {
        if(e.target.className == 'link-delete-one') return;
        window.location.href = `/forum/sections/${section.id}/threads/${thread.id}`
    }

    deleteSection = (item, index) => {
        if(window.confirm ('DELETE. are you sure?')) {
            this.setState({loaded: false})
            deleteSection(item.id)
                .then((res) => {
                    let listSections = this.state.listSections
                    listSections.splice(index, 1);
                    this.setState({listSections: listSections, loaded: true});
                })
                .catch(error => {
                    this.setState({loaded: true})
                })
        }
    }

    editSection = (item) => {
        this.setState({editiableSection: item})
    }

    onChange = e => {
        this.setState({editiableSection:{...this.state.editiableSection, [e.target.name]: e.target.value}});
    }

    saveSection = (item, index) => {
        let obj = {
            title: this.state.editiableSection.title
        }
        this.setState({loaded: false})
        editSection(item.id, obj)
            .then((res) => {
                let listSections = this.state.listSections
                listSections[index] = res.data;
                this.setState({listSections: listSections, editiableSection: {}, loaded: true});
            })
            .catch(error => {
                this.setState({loaded: true})
            })

    }

    deleteThread = (threadId, sectionId) => {
        if(window.confirm ('DELETE. are you sure?')) {
            this.setState({loaded: false})
            deleteThread(sectionId, threadId)
                .then((res) => {
                    this.getListSections()
                })
                .catch(error => {
                    this.setState({loaded: true})
                })
        }
    }


    render() {
        let user = this.props.user || {};
        let isExpert = user.expertProfile && user.expertProfile.role != 'new' && user.expertProfile.role != 'block';


        let listSections = this.state.listSections.map((item, key) => {
            return (
                <div key={key} className="block-list-section-forum">
                    <div className="block-top-list-section-forum">
                        <div className="block-title">
                            {this.state.editiableSection.id != item.id &&
                                <p>{item.title}</p>
                            }

                            {this.state.editiableSection.id == item.id &&
                            <div className="block-flex-top-title-folder" style={{'display': 'block', 'width': '100%'}}>
                                <input type="text" name="title" className="is-click-link" value={this.state.editiableSection.title} onChange={this.onChange} style={{'display': 'block', 'width': '100%'}} />
                            </div>
                            }

                            {ISADMIN && this.state.editiableSection.id != item.id &&
                            <span className='links-absolute-position'>
                                <span className='link-delete-one' style={{'color': 'blue'}} onClick={() => this.editSection(item)}>edit</span>
                                <span className='link-delete-one' onClick={() => this.deleteSection(item, key)}>delete</span>
                            </span>
                            }

                            {ISADMIN && this.state.editiableSection.id == item.id &&
                            <span className='links-absolute-position'>
                                <span className='link-delete-one' style={{'color': 'green'}} onClick={() => this.saveSection(item, key)}>save</span>
                                <span className='link-delete-one' style={{'color': 'black'}} onClick={() => this.setState({editiableSection: {}})}>cancel</span>
                            </span>
                            }

                        </div>
                        <div className="block-button-section-forum">
                            {ISADMIN &&
                            <ButtonTransBlue2
                                TitleButton="Add new thread"
                                EventOnClick={() => this.handleShowModalNewThread(item)}
                                sizeButton="small"
                                paddingButton="20"
                            />
                            }
                        </div>
                    </div>
                    <div className="block-list-thread-section-forum">
                        {
                            item.threads.map((item2, key2) => {
                                return (
                                    <div key={key2} className="block-item-thread-section-forum" onClick={(e) => this.openThree(item, item2, e)}>
                                        <div className="block-column-state-thread-section-forum">
                                            <div className="block-state">
                                                <span className="state state-blue"></span>
                                            </div>
                                        </div>
                                        <div className="block-column-title-thread-section-forum">
                                            <p className="title">{item2.title}</p>

                                            {ISADMIN &&
                                            <span className='links-absolute-position'>
                                                <span className='link-delete-one' onClick={() => this.deleteThread(item2.id, item.id)}>delete</span>
                                            </span>
                                            }


                                            <p className="description">{item2.description}</p>
                                        </div>
                                        <div className="block-column-user-thread-section-forum">
                                            <a href="" className="user-link">{item2.User.firstName + ' ' + item2.User.lastName}</a>
                                            <p className="up-date">{moment(item2.createdAt).format('DD.MM.YYYY HH:mm')}</p>
                                        </div>
                                        <div className="block-column-info-thread-section-forum">
                                            <p>{item2.topicsCount}</p>
                                            <p>{item2.commentsCount}</p>
                                        </div>
                                    </div>
                                )
                            }, this)
                        }
                    </div>

                </div>

            )
        }, this)


        return (

            <div className="block-team-2-0">

                {/*<Header user={user} bgcColor={'#0028DC'}></Header>*/}

                <div className="block-container-1140 header-container-footer">
                    <div className="block-top-title-search-experts-comming">
                        <div className="float-left-80">

                                <div className="block-input-DAO-new-icon" style={{ width: '320px'}}>
                                    <p className="title"></p>
                                    <div className="block-input-new-icon">
                                        <input type="text" name="search" placeholder="Search "/>
                                        <i className="fal fa-search"></i>
                                    </div>
                                    <p className="error"></p>
                                </div>

                        </div>
                        <div className="float-right-20">
                            {ISADMIN &&
                                <span style={{float:'right', padding:'15px 0 0 0'}}>
                                     <ButtonTransBlue2
                                         TitleButton="Add new section"
                                         EventOnClick={() => this.handleShowModalNewSection()}
                                         sizeButton="small"
                                         paddingButton="20"
                                     />
                                </span>

                            }
                        </div>
                    </div>

                    {listSections}


                </div>

                {/*<Footer></Footer>*/}
                <Loader loaded={this.state.loaded}></Loader>

                {this.state.showModalNewSection &&
                <ModalNewSection
                    showModal={this.state.showModalNewSection}
                    handleCloseModal={this.handleCloseModal}
                    addNewSection={this.addNewSection}
                    user={this.props.user}
                />
                }

                {this.state.showModalNewThread &&
                <ModalNewThread
                    showModal={this.state.showModalNewThread}
                    handleCloseModal={this.handleCloseModal}
                    addNewThread={this.addNewThread}
                    user={this.props.user}
                    sectionId={this.state.sectionId}
                />
                }

                <MetaTags>
                    <title>Forum - rocketdao.io</title>
                    <meta property="og:title" content={'Forum - rocketdao.io'}/>
                </MetaTags>


            </div>

        )

    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

ForumPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    tokenLogin: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {tokenLogin, updateUser, logout})(ForumPage)
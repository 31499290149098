import React from 'react';
import "./style.css"


export default class RiskWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {


        return (
            <div className={`document`}>
                <h1 className="heading-1">
                    Risk warning
                </h1>
                <p className="description-number">
                    <span className="number">1</span>
                    ROCK token is a ERC20 Service-Backed Token of Rocket DAO platform. Its purpose is to grant access to
                    the platform's functionality. It is a utility token, not a security token. ROCK token doesn't
                    guarantee returns.
                </p>
                <p className="description">
                    Security tokens are cryptographic tokens that represent a security as defined by the local
                    regulations. Security tokens offer tangible benefits and represent a share in the company behind the
                    token.
                </p>
                <p className="description">
                    Utility tokens are services or units of services that can be bought. These are the digital assets
                    designed to be spent within a certain blockchain ecosystem. Utility tokens are not intended to give
                    their holders the ability to control how decisions are made in a company.
                </p>
                <p className="description-number">
                    <span className="number">2</span>
                    Startup evaluation conducted by Rocket DAO experts (both preliminary evaluation and due diligence)
                    does not give any investment advice, it is not an element of the investment procedure, it is only a
                    recommendation.
                </p>
                <p className="description">
                    All calculations and data presented in Rocket DAO due diligence reports are deemed to be accurate,
                    but accuracy is not guaranteed. We make no representations or warranties that any investor will, or
                    is likely to, achieve any because hypothetical or simulated performance is not necessarily
                    indicative of future results.
                </p>
                <p className="description">
                    Investments in private companies are particularly risky and may result in total loss of invested
                    capital. All investments carry risk and all investment decisions of an individual remain the
                    responsibility of that individual. There is no guarantee that systems, indicators, or signals will
                    result in profits or that they will not result in losses. All investors are advised to fully
                    understand all risks associated with any kind of investing they choose to do. Don’t enter any
                    investment without fully understanding the worst-case scenarios of that investment.
                </p>
                <p className="description-number">
                    <span className="number">3</span>
                    Financial remuneration made on our website rocketdao.io for the Expert evaluation is non-refundable.
                </p>
                <p className="description-number">
                    <span className="number">4</span>
                    Rocket DAO has the right to abstain from distribution of the evaluation report to Business angels,
                    Partners, Venture funds and etc in case the Project’s total Rocket DAO Evaluation Report’s score is
                    less than 4.5 out of 10 for the current Project’s investment round determined by the experts.
                </p>
            </div>
        );
    }
}

import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {updateExpert} from "../../../actions/Login";
import ButtonBlue from "../../controls3.0/button/ButtonBlue";
import ButtonBorder from "../../controls3.0/button/ButtonBorder"

import CheckBox from "../../controls3.0/checkBox/checkBox";
import ButtonGray from "../../controls3.0/button/ButtonGray";

import iconExpert from '../../../images/reg/ic_expert.svg'
import iconExpertActive from '../../../images/reg/ic_expert_active.svg'
import iconSupervisor from '../../../images/reg/ic_supervisor.svg'
import iconSupervisorActive from '../../../images/reg/ic_supervisor_active.svg'
import iconMethodologist from '../../../images/reg/ic_methodologist.svg'
import iconMethodologistActive from '../../../images/reg/ic_methodologist_active.svg'


class Step5 extends React.Component {
    constructor(props) {
        super();
        let user = props.user,
            expert = user.expert || {};
        if (!Array.isArray(expert.expertRoles)) {
            expert.expertRoles = [];
        }
        this.state = {
            user: user,
            expert: expert,
            loaded: true
        }
    }

    componentDidMount() {

    }

    set = num => {
        let arr;
        switch (num) {
            case 1:
                arr = [1]
                break;
            case 2:
                arr = [1, 2]
                break;
            case 3:
                arr = [1, 2, 3]
                break;
        }

        // let expert = this.state.expert;
        // if (expert.expertRoles.includes(num)) {
        //     expert.expertRoles = expert.expertRoles.filter(e => e !== num)
        // } else {
        //     expert.expertRoles.push(num)
        // }
        this.setState({expert: {...this.state.expert, expertRoles: arr}})
    }
    reset = num => {
        let arr;
        switch (num) {
            case 1:
                arr = []
                break;
            case 2:
                arr = [1]
                break;
            case 3:
                arr = [1, 2]
                break;
        }
        this.setState({expert: {...this.state.expert, expertRoles: arr}})
    }


    update = (nextStep, onlyStep) => {
        let loading = nextStep == 2 ? 'loadingBack' : 'loading'
        this.setState({[loading]: true});


        this.props.updateExpert({...this.state.expert, event_type: 'expert_role_2'}, onlyStep, nextStep)
            .catch(err => {
                this.setState({[loading]: false})
            })
    }


    render() {
        let user = this.state.user,
            expert = this.state.expert,
            active1 = expert.expertRoles.includes(1),
            active2 = expert.expertRoles.includes(2),
            active3 = expert.expertRoles.includes(3)

        return (
            <React.Fragment>

                <div className="container-full-reg">
                    <p className="title-top-page-reg-expert text-h1 left-text shell-margin-bottom-20">
                        Expert roles
                    </p>
                    <p className="description-page-reg-expert text-button left-text shell-margin-bottom-20">
                        We divided experts into 3 groups according to the functions performed. Do all of <br/> these
                        roles
                        seem interesting to you? Choose several options then.
                    </p>
                    <p className="description-page-reg-expert text-button-2 left-text shell-margin-bottom-20">
                        Note that every user starts his/her way on the platform as a candidate, only after having
                        passed <br/>
                        through some tutorials and having completed a test task he/she will be nominated an expert role.
                    </p>


                    <div className="block-list-cart-choose padding-reg" style={{textAlign:'center'}}>

                        <div className="block-cart-choose left">

                            <div style={{display: `${!active1? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.set(1)}
                                        active={false}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <p className="text-h4">Expert</p>
                                    <p className="text-sub-title">
                                        Data verification & <br/>
                                        project evaluation
                                    </p>
                                    <p className="text-caption-2">
                                        An expert may: <br/>
                                        - verify the accuracy of projects’ data provided;<br/>
                                        - evaluate projects using in-house methodologies and give relevant
                                        recommendations.
                                    </p>
                                </div>
                                <ButtonBlue
                                    TitleButton={'Choose'}
                                    EventOnClick={() => this.set(1)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                            </div>

                            <div style={{display: `${active1? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.reset(1)}
                                        active={true}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <img src={iconExpert} alt="logo"/>
                                    <p className="text-h4">Expert</p>
                                    <p className="text-sub-title">
                                        Data verification & <br/>
                                        project evaluation
                                    </p>

                                </div>
                                <ButtonBorder
                                    TitleButton={'Cancel'}
                                    EventOnClick={() => this.reset(1)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                            </div>


                        </div>

                        <div className="block-cart-choose center">

                            <div style={{display: `${!active2? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.set(2)}
                                        active={false}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <p className="text-h4">Supervisor</p>
                                    <p className="text-sub-title">
                                        Assessment & training <br/>
                                        of other experts
                                    </p>
                                    <p className="text-caption-2">
                                        Supervisor is responsible for <br/>
                                        - attestation of  expert candidates; <br/>
                                        - evaluation of the work of other experts; <br/>
                                        - mentoring of newcomers.
                                    </p>
                                </div>
                                <ButtonBlue
                                    TitleButton={'Choose'}
                                    EventOnClick={() => this.set(2)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                            </div>

                            <div style={{display: `${active2? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.reset(2)}
                                        active={true}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <img src={iconSupervisor} alt="logo"/>
                                    <p className="text-h4">Supervisor</p>
                                    <p className="text-sub-title">
                                        Assessment & training <br/>
                                        of other experts
                                    </p>

                                </div>
                                <ButtonBorder
                                    TitleButton={'Cancel'}
                                    EventOnClick={() => this.reset(2)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                            </div>




                        </div>

                        <div className="block-cart-choose right">

                            <div style={{display: `${!active3? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.set(3)}
                                        active={false}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <p className="text-h4">Methodologist</p>
                                    <p className="text-sub-title">
                                        Evaluation methodologies & <br/>
                                        templates development
                                    </p>
                                    <p className="text-caption-2">
                                        Methodologists develop evaluation <br/>
                                        instructions.
                                    </p>
                                </div>
                                <ButtonBlue
                                    TitleButton={'Choose'}
                                    EventOnClick={() => this.set(3)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                                {/*<ButtonBorder*/}
                                    {/*TitleButton={'Choose'}*/}
                                    {/*EventOnClick={() => this.update(5, true)}*/}
                                    {/*paddingButton={'53'}*/}
                                    {/*disabled={false}*/}
                                {/*/>*/}
                            </div>

                            <div style={{display: `${active3? 'block': 'none'}`}}>
                                <div className="block-top-cart-choose">
                                    <CheckBox
                                        onClick={() => this.reset(3)}
                                        active={true}
                                    />
                                </div>
                                <div className="block-body-cart-choose">
                                    <img src={iconMethodologist} alt="logo"/>
                                    <p className="text-h4">Methodologist</p>
                                    <p className="text-sub-title">
                                        Evaluation methodologies & <br/>
                                        templates development
                                    </p>

                                </div>
                                <ButtonBorder
                                    TitleButton={'Cancel'}
                                    EventOnClick={() => this.reset(3)}
                                    paddingButton={'53'}
                                    disabled={false}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="block-fix-button-reg-bottom fix-button-double">
                    <div className="container-full-reg">
                        <div className="block-button-left">
                            <ButtonGray
                                TitleButton={'Back'}
                                EventOnClick={() => this.update(2, true)}
                                paddingButton={'55'}
                                disabled={false}
                            />
                        </div>
                        <div className="block-button-right">
                            <ButtonBlue
                                TitleButton={'Register me'}
                                EventOnClick={() => this.update(7)}
                                paddingButton={'80'}
                                disabled={!active1}
                                loader={this.state.loading}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user
})

Step5.propTypes = {
    updateExpert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, {updateExpert})(Step5)
import React from 'react';
export default class InvestorWaitlist extends React.Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className='block-waitlist-temp-text block-page'>
                <div className='profile-dashboard-main-title font-weight-500'>Waitlist</div>

                <div>
                    <div className='profile-dashboard-18-title'>Waitlist tab is now being developed. Here you will see the list of startups whose waitlists you have joined with the date and sum of the investment.</div>
                </div>
                <div className='block-waitlist-temp-content'/>
            </div>
        )
    }
}
import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Tabs from "../../controls3.0/tabs/Tabs";
import {COUNTRIES} from "../../../utils/countriesLanguages";
import Select from "../../controls3.0/select/Select";
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();


        this.state = {
            myCountries:[]
        };
    }


    componentDidMount() {

        console.log('Locatin Mount', this.props.searchSettings['location']);

        if (this.props.searchSettings) {
            this.setState({myCountries: this.props.searchSettings['location']})
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }

    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    removeCountry(item){
        if(item) {
            let filtered = this.state.myCountries.filter(x => x !== item);
            this.setState({myCountries:filtered});
        }
    }

    onChangeCountry(e){
        let _myCountries = this.state.myCountries;
        _myCountries.push(e[0].Name);
        this.setState({myCountries:_myCountries});

    }



    render() {

        let {mobileHeight} = this.props;
        let checkedCountries = this.state.myCountries.map((item, key) => {
            return (
                <Tabs
                    key={key}
                    TitleTab={item}
                    TypeTab={'small'}
                    EventOnClick={null}
                    active={true}
                    removeTab={() => this.removeCountry(item)}
                />
            )
        });


        return (
            <div className='profile-edit-popup-block' onClick={()=>{if (isPopupShouldClose) {};this.ClosePopup()}}>
                <Scrollbars>
                <div className='search-popup search-popup-location row-m0 no-gutters' id='location' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        {/*<div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>*/}
                    </div>

                    {/*<div style={{minHeight: 'calc(100% - 135px)', height: 'calc(100% - 135px)', width: '100%'}} className={'scroll-this scroll-this-display'}>*/}
                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this scroll-this-display'}>
                            <div style={{maxHeight: '90%', padding: '20px 25px 180px'}}>
                                {this.state.myCountries.length !== 0 && <div className='row-m0 w-100 checked-countries-list-wrapper no-gutters margin-bottom-15'>
                                    <div className='row-m0 search-location-title w-100'>You have chosen already:</div>
                                    <div className='row-m0 checked-countries-block w-100'>{checkedCountries}</div>
                                </div>}
                                <div className='row-m0 w-100 no-gutters'>
                                    <div className='row-m0 w-100 search-location-title'>What location are you looking for?</div>
                                    <div className='row-m0 w-100 scroll-this'>
                                        <Select
                                            placeholder={'Countries'}
                                            data={COUNTRIES}
                                            value={''}
                                            onChange={(e) => this.onChangeCountry(e)}
                                            error={''}
                                            inputPlaceholder={'Countries'}
                                        />
                                    </div>
                                </div>

                                {this.state.myCountries.length !== 0 && <div className='row-m0 w-100 checked-countries-list-wrapper-mobile no-gutters margin-top-15'>
                                    <div className='row-m0 search-location-title w-100'>You have chosen already:</div>
                                    <div className='row-m0 checked-countries-block w-100'>{checkedCountries}</div>
                                </div>}
                            </div>
                        </Scrollbars>
                    {/*</div>*/}


                    <div className='not-scroll-this'>
                        {this.state.myCountries.length !== 0 && <div className='row-m0 w-100 checked-countries-list-wrapper no-gutters margin-bottom-15'>
                            <div className='row-m0 search-location-title w-100'>You have chosen already:</div>
                            <div className='row-m0 checked-countries-block w-100'>{checkedCountries}</div>
                        </div>}
                        <div className='row-m0 w-100 no-gutters'>
                            <div className='row-m0 w-100 search-location-title'>What location are you looking for?</div>
                            <div className='row-m0 w-100 scroll-this'>
                                <Select
                                    placeholder={'Countries'}
                                    data={COUNTRIES}
                                    value={''}
                                    onChange={(e) => this.onChangeCountry(e)}
                                    error={''}
                                    inputPlaceholder={'Countries'}
                                />
                            </div>
                        </div>

                        {this.state.myCountries.length !== 0 && <div className='row-m0 w-100 checked-countries-list-wrapper-mobile no-gutters margin-top-15'>
                            <div className='row-m0 search-location-title w-100'>You have chosen already:</div>
                            <div className='row-m0 checked-countries-block w-100'>{checkedCountries}</div>
                        </div>}
                    </div>

                    <div className='row-m0 buttons-line margin-top-12 no-gutters w-100'>
                        {/*<div className='col-3 font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>*/}
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('location',this.state.myCountries); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>


                    <div className='mobile-popup-footer' style={{marginTop:'-5px'}}>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('location',this.state.myCountries);this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
                </Scrollbars>
            </div>
        )


    }
}




import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Swipe from '../../controls3.0/swipe/Swipe'
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();
        this.state = {
            BModelList:[
                {id:'B2B', Name:'B2B', descr:'Business-to-Business'},
                {id:'B2C', Name:'B2C', descr:'Business-to-Consumer'},
                {id:'B2G', Name:'B2G', descr:'Business-to-Government'},
                {id:'C2B', Name:'C2B', descr:'Consumer-to-Business'},
                {id:'C2C', Name:'C2C', descr:'Consumer-to-Consumer'},
                {id:'C2G', Name:'C2G', descr:'Consumer-to-Government'}
                ],

            BModelChecked:[],

        };
    }

    componentDidMount() {
        if (this.props.searchSettings) {
            this.setState({BModelChecked: this.props.searchSettings['business_model'].map(r => r)})
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }

    ResetPopup(){
        this.setState({ BModelChecked:[]})
    }


    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    bmodelCheck(item) {
        let _BModelChecked = this.state.BModelChecked;

        let isFound = _BModelChecked.find(x => x === item.id);
        if (!isFound) {
            _BModelChecked.push(item.id)
        } else {
            _BModelChecked = _BModelChecked.filter(x => x !== item.id)
        }
        this.setState({BModelChecked:_BModelChecked});

    }

    render() {

        let {mobileHeight} = this.props;

        const list = this.state.BModelList.map((item, key) =>{
                return (
                    <div className='row-m0 business-model-list-search no-gutters w-100' {...key}>
                        <div className='col-8'>
                            <div className='row-m0 bmodel-list-name w-100'>{item.Name}</div>
                            <div className='row-m0 bmodel-list-descr w-100'>{item.descr}</div>
                        </div>

                        <div className='col-4 text-right'>
                            <Swipe
                                onClick={() => {this.bmodelCheck(item)}}
                                active={this.state.BModelChecked.includes(item.id)}
                            />
                        </div>
                    </div>
                )

        }
        );

        return (
            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                <div className='search-popup search-popup-bmodel new-search-popup-bmodel row-m0 no-gutters' id='business-model' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>

                    <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this'}>
                        <div style={{maxHeight: '90%', padding: '13px 25px 20px 25px'}}>
                            {list}
                        </div>
                    </Scrollbars>

                    {/*<div style={{minHeight: '100%', height: '100%', width: '100%'}} className={'scroll-this scroll-this-display'}>*/}
                       {/**/}
                    {/*</div>*/}

                    {/*<div className='not-scroll-this row-m0 no-gutters'>*/}
                        {/*{list}*/}
                    {/*</div>*/}

                    <div className='row-m0 buttons-line no-gutters w-100' style={{marginTop:'12px', padding:'0 25px'}}>
                        <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('business_model',this.state.BModelChecked); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>

                    <div className='mobile-popup-footer'>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('business_model',this.state.BModelChecked); this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
            </div>
        )


    }
}




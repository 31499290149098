import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {validateField} from '../../actions/UserSignup'
import ReCAPTCHA from "react-google-recaptcha"
import InputUserInfoProfile from '../controls/InputUserInfoProfile/InputUserInfoProfile'
import FacebookLogin from 'react-facebook-login';
import {signUpFacebook} from '../../actions/Login'
import {signUpGoogle} from '../../actions/Login'
import {connect} from 'react-redux'
import GoogleLogin from 'react-google-login';


class SignupForm extends React.Component {

  submit = data => this.props.signUpFacebook(data.accessToken).then(
    ()=>this.props.history.push('/')
  )

  submit2 = data => this.props.signUpGoogle(data.accessToken).then(
    ()=>this.props.history.push('/')
  )



  constructor(){
    super();
    this.state = {
      data:{
        email:'',
        firstName:'',
        lastName:'',
        password: '',
        passwordConfirm: '',
        'g-recaptcha-response': ''
      },
      errors:{},
      serverError:'',
      isLoading:false,
      regexPass: new RegExp(/(?=^.{8,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*^\S*$/)
    };
    this.onChange = this.onChange.bind(this)
    this.changePass = this.changePass.bind(this)
    this.changePassConfirm = this.changePassConfirm.bind(this)
    this.onFocus = this.onFocus.bind(this)

    this.recaptchaChange = this.recaptchaChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.responseFacebook = this.responseFacebook.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  responseFacebook(response){
    this.submit(response)
  }

  responseGoogle(response){
    this.submit2(response)
  }


  onChange(e){
    this.setState({data:{...this.state.data, [e.target.name]:e.target.value}})
  }
  changePass(val){
    this.setState({data:{...this.state.data, ['password']: val}});
    this.validatePass(val)
  }
  changePassConfirm(val){
    this.setState({data:{...this.state.data, ['passwordConfirm']: val}});
    this.confirmPass(val)
  }

  onFocus(e){
    this.setState({errors:{...this.state.errors, [e.target.name]: null}})
  }

  recaptchaChange (value){
    this.setState({data:{...this.state.data, ['g-recaptcha-response']: value}})
  }

  validatePass(value) {
    if(!this.state.regexPass.test(value)) {
      this.setState({errors:{...this.state.errors, ['password']: 'Minimum 8 characters - lower and upper case symbols and digit(s)'}})
    }else {
      this.setState({errors:{...this.state.errors, ['password']: null}})
    }
  }

  confirmPass(value) {
    if(this.state.data.password){
      if(this.state.data.password != value) {
        this.setState({errors:{...this.state.errors, ['passwordConfirm']: true}})
      }else {
        this.setState({errors:{...this.state.errors, ['passwordConfirm']: false}})
      }
    }
  }


  onSubmit(e){
    e.preventDefault()
    const errors = this.validate(this.state.data)
    this.setState({ errors })
    if(!Object.keys(errors).length){
      this.setState({ isLoading: true })

      validateField(this.state.data).then((res)=>{
        this.props.get('data', this.state.data)
      })
        .catch(error => {
          // console.error('error', error.response.data.error);
          this.setState({isLoading: false })
        })
    }
  }

  validate(data){
    const errors = {}
    for (let key in data) {
      // data[key] = data[key].trim();

      if(!data[key].length) {
        switch (key) {
          case 'firstName':
            errors[key] = 'Field is required';
            break;
          case 'lastName':
            errors[key] = 'Field is required';
            break;
          case 'email':
            errors[key] = 'Empty or invalid e-mail address';
            break;
          case 'password':
            errors[key] = 'Minimum 8 characters - lower and upper case symbols and digit(s)';
            break;
        }
      }
      if(data[key].length > 100) errors[key] = 'max length 100 characters';
    }
    delete errors['g-recaptcha-response'];


    if(this.state.data.password != this.state.data.passwordConfirm) {
      errors ['passwordConfirm'] = true
    }

    return errors;
  }

  render() {
    const {data, errors} = this.state;
    return (
      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 login-form--wrap">
        {this.state.serverError && <div>{this.state.serverError}</div>}
        <form className="login-form" onSubmit={this.onSubmit} noValidate >
          <div className="login-form__variant">
            <span className="login-form__link_login"><Link to='login'>Log in</Link></span><span>&nbsp;or&nbsp;</span>
            <span className="auth_active">Register</span>
          </div>
          <input
            className="login-form__field"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={data.firstName}
            onChange={this.onChange}
            onFocus={this.onFocus}
          />
          {errors.firstName && <div className="reg_error_field">{errors.firstName}</div>}
          <input
            className="login-form__field"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={data.lastName}
            onChange={this.onChange}
            onFocus={this.onFocus}
          />
          {errors.lastName && <div className="reg_error_field">{errors.lastName}</div>}
          <input
            className="login-form__field"
            type="email"
            id="email"
            name="email"
            placeholder="E-mail"
            value={data.email}
            onChange={this.onChange}
            onFocus={this.onFocus}
          />
          {errors.email && <div className="reg_error_field">{errors.email}</div>}
          {/*<input*/}
            {/*className="login-form__field less_margin"*/}
            {/*type="password"*/}
            {/*id="password"*/}
            {/*name="password"*/}
            {/*placeholder="Password"*/}
            {/*value={data.password}*/}
            {/*onChange={this.onChange}*/}
            {/*onFocus={this.onFocus}*/}
          {/*/>*/}


          <InputUserInfoProfile
            typeInputUserInfoProfile="password"
            placeholderInputUserInfoProfile="Password"
            valueInputUserInfoProfile={data.password}
            nameInputUserInfoProfile="password"
            changeInputUserInfoProfile={this.changePass}
            focusInputUserInfoProfile={this.onFocus}
          />


          {errors.password && <div className="reg_error_field">{errors.password}</div>}

          {/*<input*/}
            {/*className="login-form__field less_margin"*/}
            {/*type="password"*/}
            {/*id="passwordConfirm"*/}
            {/*name="passwordConfirm"*/}
            {/*placeholder="Retype password"*/}
            {/*value={data.passwordConfirm}*/}
            {/*onChange={this.onChange}*/}
            {/*onFocus={this.onFocus}*/}
          {/*/>*/}

          <InputUserInfoProfile
            typeInputUserInfoProfile="password"
            placeholderInputUserInfoProfile="Retype password"
            valueInputUserInfoProfile={data.passwordConfirm}
            nameInputUserInfoProfile="passwordConfirm"
            changeInputUserInfoProfile={this.changePassConfirm}
            focusInputUserInfoProfile={this.onFocus}
          />




          {errors.passwordConfirm && <div className="reg_error_field">Passwords don't match</div>}
          <div className="under-input"></div>


          <div className="login-form__captcha">
            {/*<ReCAPTCHA*/}
              {/*ref="recaptcha"*/}
              {/*sitekey="6LfbqDYUAAAAAPttsxFg5GE5JjUvoqyQU-dlsS-r"*/}
              {/*onChange={this.recaptchaChange}*/}
            {/*/>*/}

            <ReCAPTCHA
              ref="recaptcha"
              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              onChange={this.recaptchaChange}
            />


          </div>
          {/*sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"*/}
          {/*sitekey="6LfbqDYUAAAAAPttsxFg5GE5JjUvoqyQU-dlsS-r"*/}
          <button className="login-form__btn login-form__btn_join" disabled={this.state.isLoading || !this.state.data['g-recaptcha-response']}>Next</button>

          <div className="login-form__policy">
            By clicking «Join» I agree to RocketDAO <a href="">Terms&nbsp;of&nbsp;Service</a>,&ensp;<a href="">Confidentialy&nbsp;and&nbsp;Policy </a>agreements</div>
          <div className="login-form__devider"><span>or</span></div>

          {/*<a className="login-form__btn login-form__btn_fb no-underline" href="https://api.rocketico.io/auth/facebook">*/}
            {/*<i className="fa fa-facebook-official"></i>*/}
            {/*<span className="login-form__btn-text">Sign up  via Facebook</span>*/}
          {/*</a>*/}

          <FacebookLogin
            appId="340838513264681"
            autoLoad={false}
            fields="name,email,picture"
            redirectUri={window.location.href}
            callback={this.responseFacebook}>

            <button className="login-form__btn login-form__btn_fb no-underline">
              <i className="fa fa-facebook-official"></i>
              <span className="login-form__btn-text">Sign up  via Facebook</span>
            </button>
          </FacebookLogin>

          <div className="block-google">
            <GoogleLogin
              clientId="839740061712-mi7grbk5uvgst4qo55ml9kvk3ic8a9e2.apps.googleusercontent.com"
              buttonText="Sign up via Gmail"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              className="login-form__btn login-form__btn_fb no-underline btn-google"
            >
            </GoogleLogin>
          </div>




          {/*<a className="login-form__btn login-form__btn_linkedin" href="">*/}
            {/*<span className="login-form__btn-icon"></span>*/}
            {/*<span className="login-form__btn-text">Sign up  via Linkedin</span>*/}
          {/*</a>*/}
        </form>
      </div>
    );
  }
}

SignupForm.propTypes = {
  history:PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  signUpFacebook:PropTypes.func.isRequired,
  signUpGoogle:PropTypes.func.isRequired
}

export default connect(null,{signUpFacebook, signUpGoogle})(SignupForm)

// SignupForm.propTypes = {
//   history:PropTypes.shape({
//     push: PropTypes.func.isRequired
//   }).isRequired,
//   login:PropTypes.func.isRequired
// }

// export default SignupForm
import React from 'react';
import './style.css'
import load from './button-loader.svg'

export default class ButtonBlack extends React.Component {

    render() {
        let {
            TitleButton,
            EventOnClick,
            paddingButton, //0, '' inline , >0 padding
            disabled,
            loader,
            onMouseEnter,
            onMouseLeave,
        } = this.props;
        let classSize = disabled ? ' disabled' : ''
        let styleButton;
        if (paddingButton) {
            if (paddingButton > 0) {
                styleButton = {
                    display: 'inline-block',
                    paddingLeft: paddingButton + 'px',
                    paddingRight: loader ? paddingButton > 51 ? paddingButton + 'px' : 51 + 'px' : paddingButton + 'px'
                }
            }
            else {
                styleButton = {display: 'inline-block', width: '100%'}
            }
        } else {
            styleButton = {display: 'inline-block', width: '100%'}
        }
        classSize += loader ? 'loader-button' : ''
        return (
            <div className={"block-button-3-0 button-3-0-black " + classSize} style={styleButton}
                 onClick={!disabled ? EventOnClick : null}
                 onMouseEnter={onMouseEnter? onMouseEnter : null}
                 onMouseLeave={onMouseLeave? onMouseLeave : null}
            >
                <p className="block-button-3-0-title">{TitleButton}</p>
                {loader && <span className="loader-button"><img src={load} alt=""/></span>}
            </div>
        );
    }
}


import React from 'react';
import '../Header.css';

import '../../test-controls/newMenuTop/new-menu-top.css'
import Swipe from '../../controls3.0/swipe/Swipe'
import {Scrollbars} from "react-custom-scrollbars";
import ButtonBlack from "../../controls3.0/button/ButtonBlack";


let isPopupShouldClose = true;

export default class Header extends React.Component {
    constructor(props) {
        super();
        this.state = {
            Acceltypes:[
                {id:'Private',      Name:'Private', descr:'Non-corporate accelerating programs, usually with follow-up investments'},
                {id:'Corporate',    Name:'Corporate', descr:'Backed by larger corporations accelerators and incubators'},
                {id:'Government',   Name:'Government', descr:'Non-profit accelerators, funded by the governments (usually)'}
            ],
            Checkedtypes:[]
        };
    }


    componentDidMount() {
        if (this.props.searchSettings) {
            this.setState({Checkedtypes: [...this.props.searchSettings['type']]})
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.props.resizeMobile);
    }


    ResetPopup(){
        this.setState({ Checkedtypes:[]})
    }


    ClosePopup () {
        if (isPopupShouldClose) {
            window.removeEventListener('resize', this.props.resizeMobile);
            this.props.handleClick(this.props.TabNum);
        }

        isPopupShouldClose = true;
    }

    bmodelCheck(item) {
        let _Checkedtypes = this.state.Checkedtypes;

        let isFound = _Checkedtypes.find(x => x === item.id);
        if (!isFound) {
            _Checkedtypes.push(item.id)
        } else {
            _Checkedtypes = _Checkedtypes.filter(x => x !== item.id)
        }
        this.setState({Checkedtypes:_Checkedtypes});

    }

    render() {
        let {mobileHeight} = this.props;

        const list = this.state.Acceltypes.map((item, key) =>{
                return (
                    <div className='row-m0 business-model-list-search accel-type-list-search no-gutters w-100'>
                        <div className='row-m0 w-100 no-gutters'>
                            <div className='col-8 bmodel-list-name'>{item.Name}</div>
                            <div className='col-4 text-right'>
                                <Swipe
                                    onClick={() => {this.bmodelCheck(item)}}
                                    active={this.state.Checkedtypes.includes(item.id)}
                                />
                            </div>
                        </div>

                        <div className='row-m0 imodel-list-descr accel-list-descr w-100 no-gutters'>{item.descr}</div>

                    </div>
                )

            }
        );

        return (
            <div className='profile-edit-popup-block' onClick={()=>{this.ClosePopup()}}>
                <Scrollbars>
                <div className='search-popup search-popup-bmodel row-m0 no-gutters' id='accel-type' onClick={()=>{isPopupShouldClose = false;}}>
                    <div className='mobile-popup-header'>
                        <div className='close-button' onClick={ () => {this.ClosePopup()}}/>
                        <div className='popup-header-reset-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                    </div>

                        <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={mobileHeight?mobileHeight - 140:window.innerHeight - 140} className={'scroll-this scroll-this-display'}>
                            <div style={{maxHeight: '90%', marginTop:'8px', padding: '13px 25px 20px 25px'}}>
                                {list}
                            </div>
                        </Scrollbars>

                    <div className='not-scroll-this'>
                        {list}
                    </div>

                    <div className='row-m0 buttons-line margin-top-15 no-gutters w-100'>
                        <div className='col font-button' onClick={()=>{this.ResetPopup()}}>Reset</div>
                        <div className='col font-button'
                             onClick={()=>{this.props.setSearchSettings('type',this.state.Checkedtypes); this.ClosePopup()}}
                             style={{color:'#1BC8A7', marginRight:'0'}}>Apply</div>
                    </div>

                    <div className='mobile-popup-footer'>
                        <ButtonBlack
                            TitleButton={'Show results'}
                            EventOnClick={() => {this.props.setSearchSettings('type',this.state.Checkedtypes); this.ClosePopup()}}
                            paddingButton={'1'}
                            disabled={false}
                        />
                    </div>
                </div>
                </Scrollbars>
            </div>
        )


    }
}



